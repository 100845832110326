// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { Box, CheckBox, FormField, TextInput } from 'grommet'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { CircleAlert, Close, StatusWarning } from 'grommet-icons'
import debounce from 'lodash/debounce'

import {
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  Button,
  CCSForm,
  Dropdown,
  NoDataInfo,
  Notification
} from '../../../../components'
import { get } from '../../../../utils/api-utils'
import {
  displayApiError,
  getApiErrorMessage
} from '../../../../utils/error-handling-utils'
import { permissionCustomRoleUseEnum } from '../constants'
import { getGLCPAppDetails } from '../../utils'

import Markdown from './Markdown'

const AddPermissionModal = ({
  setShowPermissionDialog,
  setPermissionsList = () => {},
  permissionsList = [],
  selectedApplication = {},
  setSelectedApplication = () => {},
  hideServiceDropdown = false,
  isDetailsPage = false
}) => {
  const [apiError, setApiError] = useState('')
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authz', 'common', 'iam'])
  const [selectedService, setSelectedService] = useState(selectedApplication)
  const [serviceOptions, setServiceOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [serviceLoading, setServiceLoading] = useState(false)
  const [addPermissionModelList, setAddPermissionModelList] = useState([])
  const [dataWithCheckedStatus, setDataWithCheckedStatus] = useState([])
  const [searchInput, setSearchInput] = useState({
    searchInputValue: '',
    isSearchResultsFound: false
  })
  const baseMargin = { left: 'small', top: 'small' }
  const margin = searchInput?.searchInputValue
    ? { ...baseMargin, bottom: 'large' }
    : baseMargin

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCustomSearch = useCallback(
    debounce((searchValue) => {
      if (!searchValue.trim()) {
        setAddPermissionModelList(dataWithCheckedStatus)
      } else {
        const lowerCaseSearchValue = searchValue?.toLowerCase()
        const filteredData = dataWithCheckedStatus?.map((item) => {
          const filteredPermissions = item?.permissions.filter(
            (permission) =>
              permission.permission_name
                ?.toLowerCase()
                .includes(lowerCaseSearchValue) ||
              permission.permission_description
                ?.toLowerCase()
                .includes(lowerCaseSearchValue)
          )
          return { ...item, permissions: filteredPermissions }
        })
        const emptyData = filteredData.filter(
          (item) => item?.permissions.length > 0
        )
        const updatedList =
          emptyData.length > 0 ? emptyData : dataWithCheckedStatus

        setAddPermissionModelList(updatedList)
        setSearchInput({
          searchInputValue: searchValue,
          isSearchResultsFound: emptyData?.length > 0
        })
      }
    }, 500),
    [dataWithCheckedStatus]
  )

  useEffect(() => {
    if (selectedService?.application_id) {
      setAddPermissionModelList([])
      setDataWithCheckedStatus([])
    }
  }, [selectedService?.application_id])

  useEffect(() => {
    const retrieveApplications = async () => {
      setServiceLoading(true)
      try {
        const response = await get(
          '/ui-doorway/ui/v1/applications/provisions',
          {
            provision_status: 'PROVISIONED'
          },
          oidcUser?.access_token
        )
        if (response?.data) {
          const appOptions = []
          const appIds = []
          const provisionedServices = response?.data?.provisions
          provisionedServices?.forEach((service) => {
            // don't list multiples b/c of app instances
            if (!appIds.includes(service?.application_id)) {
              appIds.push(service?.application_id)
              appOptions.push({
                label: service?.name,
                value: service
              })
            }
          })
          const appDetails = getGLCPAppDetails()
          appOptions.push({
            label: appDetails.glcpApplicationName,
            value: {
              application_id: appDetails.glcpApplicationId,
              name: appDetails.glcpApplicationName
            }
          })
          setServiceOptions(appOptions)
        }
        setServiceLoading(false)
        setApiError(null)
      } catch (error) {
        setServiceLoading(false)
        setApiError(displayApiError(error, t, setApiError))
      }
    }
    if (isEmpty(selectedApplication) || !hideServiceDropdown) {
      retrieveApplications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updatePermissionList = (list, id, value) => {
    return list?.map((item) => {
      return {
        ...item,
        permissions: item?.permissions?.map((permission) => {
          if (permission?.permission_id === id) {
            return { ...permission, isChecked: value }
          }
          return { ...permission }
        })
      }
    })
  }

  useEffect(() => {
    const retrieveRolePermissionData = async () => {
      setLoading(true)
      try {
        const response = await get(
          `/internal-platform-tenant-ui/v2/permissions`,
          {
            application_id: selectedService?.application_id
          }
        )
        if (response?.data) {
          let permissionsData = response?.data?.resource_permissions?.map(
            (item) => {
              return {
                ...item,
                permissions: item?.permissions
                  ?.filter(
                    (permission) =>
                      permission?.permission_custom_role_use !==
                      permissionCustomRoleUseEnum?.DENY
                  )
                  ?.map((permission) => {
                    return {
                      ...permission,
                      isChecked: !!permissionsList.find(
                        (perms) =>
                          perms?.permission_id === permission?.permission_id ||
                          perms?.permission_name === permission?.permission_name
                      )
                    }
                  })
              }
            }
          )
          setDataWithCheckedStatus(permissionsData)
          if (isDetailsPage) {
            permissionsData = permissionsData
              ?.map((item) => {
                return {
                  ...item,
                  permissions: item?.permissions?.filter(
                    (permission) => !permission?.isChecked
                  )
                }
              })
              .filter((provider) => provider?.permissions?.length)
          }
          setAddPermissionModelList(permissionsData)
        }
        setLoading(false)
        setApiError(null)
      } catch (error) {
        setLoading(false)
        setApiError(getApiErrorMessage(error, t))
      }
    }
    if (selectedService?.application_id) {
      retrieveRolePermissionData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService?.application_id])

  const updateCheckedStatus = (id, value) => {
    const updatedList = updatePermissionList(dataWithCheckedStatus, id, value)
    setDataWithCheckedStatus(updatedList)
    const updatedAddPermissionModelList = updatePermissionList(
      addPermissionModelList,
      id,
      value
    )
    setAddPermissionModelList(updatedAddPermissionModelList)
  }

  const getButtonLabel = () => {
    let label
    if (isDetailsPage) label = t('common:save')
    else if (hideServiceDropdown) label = t('common:apply')
    else label = t('common:add')

    return label
  }

  const addPermissions = () => {
    const checkedPermission = dataWithCheckedStatus
      .map((item) =>
        item?.permissions.filter((permission) => permission?.isChecked === true)
      )
      .flat(1)

    if (checkedPermission.length === 0) {
      setPermissionsList([])
      setSelectedApplication(null)
    } else {
      setPermissionsList(checkedPermission)
      setSelectedApplication(selectedService)
    }
    setShowPermissionDialog(false)
  }

  const checkPermission = (item) => {
    return item?.permissions?.length === 0
  }

  const selectedApplicationName = selectedApplication?.name

  // Calculate totalPermissions and selectedCount
  const totalPermissions = dataWithCheckedStatus?.reduce(
    (acc, item) => acc + item?.permissions?.length,
    0
  )
  const selectedCount = dataWithCheckedStatus
    ?.flatMap((item) => item?.permissions)
    ?.filter((permission) => permission?.isChecked)?.length

  const handleClearSearch = () => {
    setSearchInput({ searchInputValue: '', isSearchResultsFound: false })
    handleCustomSearch('')
  }

  const noResults =
    searchInput?.searchInputValue && !searchInput?.isSearchResultsFound

  return (
    <ModalDialog
      content={
        <Box margin={{ vertical: 'xsmall' }}>
          {serviceLoading ? (
            <Loader testId="add-permission-modal-loader" />
          ) : (
            <Box direction="column">
              <Box margin={{ top: 'small' }}>
                <CCSForm errorMessage="" testId="add-user-form">
                  {!hideServiceDropdown || isEmpty(selectedApplication) ? (
                    <FormField
                      label={t('authz:roles.service')}
                      required
                      data-testid="select-service"
                    >
                      <Dropdown
                        name="service"
                        placeholder={t('authz:api_client.select')}
                        labelKey="label"
                        valueKey={{ key: 'value', reduce: true }}
                        value={selectedService}
                        options={serviceOptions}
                        onChange={({ option }) => {
                          setSelectedService(option?.value)
                        }}
                        testId="groups-multi-select-box"
                      />
                    </FormField>
                  ) : null}
                </CCSForm>
                {loading && (
                  <Box justify="center" flex direction="row">
                    <Loader testId="add-permission-container-loader" />
                  </Box>
                )}
                {!isEmpty(selectedService) &&
                  !isEmpty(addPermissionModelList) &&
                  !addPermissionModelList.every(checkPermission) && (
                    <Box
                      round="xsmall"
                      margin={{ top: 'small' }}
                      overflow="auto"
                      border
                    >
                      <Box
                        round="xsmall"
                        direction="row"
                        align="center"
                        margin="small"
                        border
                        pad="xsmall"
                        style={{ minHeight: '36px' }}
                      >
                        <TextInput
                          plain
                          focusIndicator={false}
                          value={searchInput?.searchInputValue}
                          onChange={(e) => {
                            setSearchInput({
                              searchInputValue: e.target.value,
                              isSearchResultsFound: false
                            })
                            handleCustomSearch(e.target.value)
                          }}
                          name="search-input"
                          placeholder={t('common:search')}
                          data-testid="search-input"
                          pad={{ left: 'xsmall' }}
                        />
                        {searchInput?.searchInputValue && (
                          <Button
                            icon={<Close />}
                            onClick={handleClearSearch}
                            data-testid="clear-search"
                          />
                        )}
                      </Box>
                      <Box margin={margin}>
                        <Typography type="text" size="small">
                          {selectedCount} {t('common:selected_of')}{' '}
                          {totalPermissions}
                        </Typography>
                      </Box>
                      {noResults ? (
                        <Box justify="center" align="center" pad="medium">
                          <Typography type="text" size="medium">
                            {t('common:search_no_result')}
                          </Typography>
                        </Box>
                      ) : (
                        addPermissionModelList?.map((item) => (
                          <Box
                            pad={
                              searchInput?.searchInputValue
                                ? { left: 'small', top: '0' }
                                : { left: 'small', top: 'medium' }
                            }
                            display="row"
                            flex={{ shrink: 0 }}
                            data-testid="resource-permissions-container"
                            key={item?.resource_provider_id}
                            margin={
                              searchInput?.searchInputValue
                                ? { top: '0' }
                                : { top: 'medium' }
                            }
                          >
                            {item?.permissions?.length ? (
                              <>
                                {!searchInput?.searchInputValue && (
                                  <>
                                    <Typography
                                      level="3"
                                      style={{ whiteSpace: 'nowrap' }}
                                      type="heading"
                                      weight="bold"
                                    >
                                      {item?.resource_provider_name}
                                    </Typography>
                                    <Typography
                                      size="small"
                                      testId="dialog-subtitle"
                                      type="text"
                                    >
                                      {item?.resource_provider_description}
                                    </Typography>
                                  </>
                                )}
                              </>
                            ) : null}
                            {item?.permissions.map((permission) => {
                              return (
                                <Box key={permission?.permission_id}>
                                  <CheckBox
                                    checked={permission?.isChecked}
                                    label={
                                      <Box margin={{ top: 'small' }}>
                                        <Typography type="text" size="medium">
                                          {permission?.permission_description}
                                        </Typography>
                                        <Typography
                                          type="text"
                                          size="small"
                                          testId="app-terms-checkbox-label"
                                        >
                                          {permission?.permission_name}
                                        </Typography>
                                      </Box>
                                    }
                                    onChange={(event) => {
                                      updateCheckedStatus(
                                        permission?.permission_id,
                                        event.target.checked
                                      )
                                    }}
                                  />
                                </Box>
                              )
                            })}
                          </Box>
                        ))
                      )}
                    </Box>
                  )}
                {!isEmpty(selectedService) &&
                  !isEmpty(addPermissionModelList) &&
                  addPermissionModelList.every(checkPermission) && (
                    <Box justify="center" height="xxlarge">
                      <NoDataInfo
                        title={t(
                          'iam:roles_permission.no_permission_available_title'
                        )}
                        subtitle={t(
                          'iam:roles_permission.no_permission_available_subtitle'
                        )}
                        icon={<CircleAlert size="large" />}
                        testId="no-data-permissions-modal"
                      />
                    </Box>
                  )}
                {!loading &&
                  !apiError &&
                  isDetailsPage &&
                  isEmpty(addPermissionModelList) && (
                    <Box justify="center" height="xxlarge">
                      <NoDataInfo
                        title={t(
                          'iam:roles_permission.permissions_added_title'
                        )}
                        subtitle={t(
                          'iam:roles_permission.permissions_added_subtitle',
                          {
                            serviceName: selectedService?.name
                          }
                        )}
                        icon={<CircleAlert size="large" />}
                        testId="no-data-permissions-modal"
                      />
                    </Box>
                  )}
              </Box>
              {apiError && (
                <Box margin={{ top: 'small' }}>
                  <Notification
                    type="inline"
                    margin={{ top: 'xsmall' }}
                    icon={<StatusWarning color="text-strong" size="large" />}
                    backgroundColor="status-warning"
                    testId="warning-inline-notification"
                    status="warning"
                    text={apiError}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          left={
            <>
              {!serviceLoading && (
                <Box direction="row" justify="start" gap="small">
                  {isDetailsPage && isEmpty(addPermissionModelList) ? (
                    <Button
                      primary
                      label={t('common:close')}
                      testId="cancel-btn"
                      onClick={() => setShowPermissionDialog(false)}
                    />
                  ) : (
                    <>
                      <Button
                        primary
                        testId="add-permission-btn"
                        label={getButtonLabel()}
                        onClick={() => addPermissions()}
                        margin={{ right: 'small' }}
                      />
                      <Button
                        default
                        label={t('common:cancel')}
                        testId="cancel-btn"
                        onClick={() => setShowPermissionDialog(false)}
                      />
                    </>
                  )}
                </Box>
              )}
            </>
          }
        />
      }
      header={
        <ModalHeader
          title={
            <>
              <Typography
                level="2"
                style={{ whiteSpace: 'nowrap' }}
                testId="dialog-title"
                type="heading"
                weight="bold"
              >
                {isDetailsPage || !hideServiceDropdown
                  ? t('authz:assignments.add_permissions')
                  : t('iam:roles_permission.edit_permission_label')}
              </Typography>

              <Typography type="text" testId="dialog-subtitle">
                {!hideServiceDropdown ? (
                  t('authz:roles.add_permissions_subtitle')
                ) : (
                  <Markdown>
                    {t('authz:roles.edit_permissions_subtitle', {
                      selectedApplicationName
                    })}
                  </Markdown>
                )}
              </Typography>
            </>
          }
          onClose={() => setShowPermissionDialog(false)}
        />
      }
      height="100%"
      width="medium"
      onClose={() => setShowPermissionDialog(false)}
      position="right"
      testId="side-drawer-dialog"
    />
  )
}

AddPermissionModal.propTypes = {
  setShowPermissionDialog: PropTypes.func.isRequired,
  setPermissionsList: PropTypes.func,
  permissionsList: PropTypes.array,
  selectedApplication: PropTypes.object,
  setSelectedApplication: PropTypes.func,
  hideServiceDropdown: PropTypes.bool,
  isDetailsPage: PropTypes.bool
}

export default AddPermissionModal
