// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { isGLOP, Typography } from '../shims/imports'
import { Dropdown } from '../../components'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const MyServicesWrapper = ({
  regions,
  setSelectedRegion,
  resource,
  permission
}) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <Box
      direction="row"
      justify="between"
      align="top"
      pad={{ bottom: isGLOP() ? 'none' : 'large' }}
    >
      <Box width="small" fill="horizontal" gap="xsmall">
        <Typography type="heading" level={2}>
          {t('my_services.title')}
        </Typography>
        <Typography type="paragraph">{t('my_services.subtitle')}</Typography>
      </Box>
      <VisibilityWrapper
        rbac={{
          resource,
          permission
        }}
      >
        <Box>
          <Dropdown
            options={regions}
            onChangeDropdown={(e) => {
              setSelectedRegion(e)
            }}
            defaultVal={regions[0]?.value}
            testId="service-centric-my-services-dropdown"
          />
        </Box>
      </VisibilityWrapper>
    </Box>
  )
}

MyServicesWrapper.propTypes = {
  regions: PropTypes.array.isRequired,
  setSelectedRegion: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired
}

export default MyServicesWrapper
