// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useCallback, useEffect, useState } from 'react'
import { Box, Tip } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { StatusCritical, Dropbox, FormSchedule } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import orderBy from 'lodash/orderBy'

import { get, getErrorMessage } from '../../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'
import { customRenderer } from '../../../../../commoncomponents/CustomRenderer'
import {
  DataTable,
  Notification,
  ActionButton,
  Typography,
  NoDataInfo,
  Loader
} from '../../../../../components'
import { convertUTCToLocalTime } from '../utils'

import DeletePost from './DeletePost'
import ApprovalModel from './ApprovalModel'

const MyRequestsDataTable = ({ refreshCount, queryParam }) => {
  const { t } = useTranslation(['manage', 'common'])
  const navigate = useNavigate()
  const [showDeleteModel, setDeleteModel] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState({})
  const [successMessage, setSuccessMessage] = useState(false)
  const [loading, setLoading] = useState(true)
  const { oidcUser } = useReactOidc()
  const [viewData, setViewData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const [showApprovalModel, setApprovalModel] = useState(false)
  const [operation, setOperation] = useState('')

  const fetchPostData = useCallback(() => {
    setLoading(true)
    setViewData([])
    get(
      `/whatsnew/v1/review/blogs?q=${queryParam}`,
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        setErrorMessage(null)
        setLoading(false)
        if (response?.data?.blogs) {
          let formattedData = response?.data?.blogs || []
          formattedData = orderBy(formattedData, ['updatedAtUnix'], ['desc'])
          setViewData(
            formattedData.map((val) => ({
              ...val,
              serviceCategoryList: join(val?.serviceCategory, ', '),
              publishedDate: convertUTCToLocalTime(val?.publishDate),
              updatedAt: convertUTCToLocalTime({
                date: val?.updatedAt.substring(0, 10),
                time: val?.updatedAt.substring(11)
              })
            }))
          )
          setLoading(false)
        }
      },
      (error) => {
        setViewData([])
        setLoading(false)
        setErrorMessage(getErrorMessage(error, t))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, queryParam, refreshCount, t])

  useEffect(() => {
    fetchPostData()
  }, [fetchPostData, refreshCount])

  const handleClickRow = (datum) => {
    if (queryParam === 'my_approval') {
      navigate(
        `/manage-ccs/whats-new/${datum.blogID}?edit=false&state=true&approval=true`
      )
    } else {
      navigate(
        `/manage-ccs/whats-new/${datum.blogID}?edit=false&state=true&approval=false`
      )
    }
  }

  const columns = [
    {
      property: 'title',
      type: 'string',
      header: t('whats_new.create_post_form.title_field_label'),
      render: (datum) => (
        <Typography
          type="text"
          size="medium"
          emphasis
          testId="whatsNew-post-title"
        >
          {datum.title}
        </Typography>
      )
    },
    {
      property: 'shortDescription',
      type: 'string',
      header: t('whats_new.create_post_form.description'),
      render: (datum) => (
        <Typography
          type="paragraph"
          testId="short-description"
          size="medium"
          truncate
        >
          {datum.shortDescription}
        </Typography>
      )
    },
    {
      property: 'serviceCategoryList',
      type: 'string',
      header: t('whats_new.create_post_form.service_catalog')
    },
    {
      property: 'publisher',
      type: 'string',
      header: t('whats_new.create_post_form.publisher')
    },
    {
      property: 'state',
      type: 'string',
      header: t('whats_new.approval.state')
    },
    {
      property: 'publishedDate',
      type: 'date',
      header: t('whats_new.create_post_form.publish_date'),
      render: (datum) => (
        <>
          {datum?.active ? (
            <Typography type="text" size="medium" testId="publish-date">
              {datum?.publishedDate}
            </Typography>
          ) : (
            <Tip
              content={
                <Typography type="text" testId="schedule-date-tool-tip">
                  {t('whats_new.create_post_form.scheduled_desc', {
                    publishedDate: datum?.publishedDate
                  })}
                </Typography>
              }
            >
              <Box direction="row">
                <Typography
                  type="text"
                  size="medium"
                  icon={<FormSchedule />}
                  reverse
                  testId="schedule-date"
                >
                  {datum?.publishedDate}
                </Typography>
              </Box>
            </Tip>
          )}
        </>
      )
    },
    {
      property: 'updatedAt',
      type: 'date',
      header: t('whats_new.create_post_form.updated_at')
    },
    {
      property: '',
      header: t('whats_new.create_post_form.actions'),
      render: (datum) => (
        <ActionButton
          actions={
            queryParam === 'my_approval'
              ? [
                  {
                    label: t('whats_new.approval.approve'),
                    onClick: () => {
                      setSelectedRowData(datum)
                      setOperation('Approved')
                      setApprovalModel(true)
                    },
                    testId: 'approve-post-btn'
                  },
                  {
                    label: t('whats_new.approval.reject'),
                    onClick: () => {
                      setSelectedRowData(datum)
                      setOperation('Rejected')
                      setApprovalModel(true)
                    },
                    testId: 'reject-post-btn'
                  }
                ]
              : [
                  {
                    label: t('whats_new.edit_post'),
                    visibility: {
                      rbac: {
                        permission: 'ccs.whats-new.edit',
                        resource: '/ccs/whats-new'
                      }
                    },
                    onClick: () => {
                      navigate(
                        `/manage-ccs/whats-new/${datum.blogID}?edit=true&state=true&approval=false`
                      )
                    },
                    testId: 'edit-post-btn'
                  },
                  {
                    label: t('whats_new.delete_post'),
                    visibility: {
                      rbac: {
                        permission: 'ccs.whats-new.delete',
                        resource: '/ccs/whats-new'
                      }
                    },
                    onClick: () => {
                      setSelectedRowData(datum)
                      setDeleteModel(true)
                    },
                    testId: 'delete-post-btn'
                  }
                ]
          }
          showOneActionAsDropDown
          testId="post-table-action-btn"
          customRenderer={customRenderer}
        />
      )
    }
  ]

  return (
    <Box pad={{ horizontal: 'xxsmall', top: 'small' }}>
      {loading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="programs-loader-icon" />
        </Box>
      ) : (
        <>
          {!isEmpty(viewData) ? (
            <Box direction="column" gap="medium">
              <Box overflow="auto">
                <DataTable
                  grid={{
                    columns,
                    resizeable: true,
                    data: viewData,
                    onClickRow: (e) => handleClickRow(e.datum)
                  }}
                  testId="my-request-data-table"
                />
              </Box>
            </Box>
          ) : (
            <Box pad={{ vertical: 'large' }}>
              <NoDataInfo
                icon={<Dropbox size="large" color="text-weak" />}
                subtitle={t('whats_new.no_data')}
                testId="no-available-posts"
              />
            </Box>
          )}
        </>
      )}

      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical size="medium" />}
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
        />
      )}
      {successMessage && (
        <Notification
          onClose={() => setSuccessMessage(false)}
          testId="status-good-notification"
          text={successMessage}
        />
      )}
      {showDeleteModel && (
        <DeletePost
          postData={selectedRowData}
          setErrorMessage={setErrorMessage}
          refreshDataTable={() => fetchPostData()}
          setDeletePostMessage={setSuccessMessage}
          state
          closeDeleteModal={() => {
            setDeleteModel(false)
          }}
        />
      )}

      {showApprovalModel && (
        <ApprovalModel
          operation={operation}
          postData={selectedRowData}
          setErrorMessage={setErrorMessage}
          closeApprovalModal={() => {
            setApprovalModel(false)
          }}
          refreshDataTable={() => fetchPostData()}
        />
      )}
    </Box>
  )
}

MyRequestsDataTable.propTypes = {
  refreshCount: PropTypes.number.isRequired,
  queryParam: PropTypes.string.isRequired
}
export { MyRequestsDataTable }
