// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { StatusCritical } from 'grommet-icons'

import { Typography, NoDataInfo, ButtonGroup } from '../../components'
import { SUPPORT_URL } from '../../utils/error-utils'
import { Layout } from '../../commoncomponents/layout/Layout'

const InvalidLink404ErrorPage = () => {
  const navigate = useNavigate()

  return (
    <Box align="center">
      <Box width="large" pad="large">
        <NoDataInfo
          icon={<StatusCritical color="status-critical" size="xxlarge" />}
          title={
            <Typography type="heading" level="1" testId="invalid-link-title">
              404 Error
            </Typography>
          }
          subtitle={
            <Box align="center" pad={{ left: 'medium', right: 'medium' }}>
              <Typography
                type="text"
                size="large"
                testId="invalid-link-description"
                margin={{ bottom: 'small' }}
                textAlign="center"
              >
                Oops! It seems this link doesn&apos;t point anywhere. Please
                review it for any errors or typos. If the issue persists,
                don&apos;t hesitate to contact our support team for assistance.
              </Typography>
            </Box>
          }
          action={
            <ButtonGroup
              buttonList={[
                {
                  label: 'Return to sign in',
                  secondary: true,
                  testId: 'return-to-signin-btn',
                  onClick: () => {
                    navigate('/sign-out')
                  }
                },
                {
                  label: 'Contact support',
                  primary: true,
                  testId: 'contact-support-btn',
                  onClick: () => {
                    window.open(SUPPORT_URL, '_blank')
                  }
                }
              ]}
            />
          }
          testId="invalid-link-404-error"
        />
      </Box>
    </Box>
  )
}

const InvalidLink404Error = () => {
  return (
    <Layout hideHeaderOptions={['nav', 'bell', 'help', 'apps', 'user']}>
      <InvalidLink404ErrorPage />
    </Layout>
  )
}

export default InvalidLink404Error
