// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, DropButton, FormField, Select, RadioButtonGroup } from 'grommet'
import { Descend } from 'grommet-icons'
import PropTypes from 'prop-types'

const SortDropButton = ({ stateV = {}, dispatchNotifications = () => {} }) => {
  const { t } = useTranslation(['manage'])
  return (
    <Box width="xxsmall">
      <DropButton
        kind="toolbar"
        margin={{ right: 'small' }}
        // flex={false}
        pad={{
          left: 'xsmall',
          right: 'xsmall',
          // custom button hence custom padding works the same in all responsive slides
          top: '7px',
          bottom: '3px'
        }}
        label={<Descend color="black" />}
        testId="sort-drop-button"
        dropAlign={{ top: 'bottom', left: 'left' }}
        dropContent={
          <Box pad="small">
            <FormField label={t('notification.sort_by_title')}>
              <Select
                value="Date and time"
                options={[
                  {
                    label: t('notification.sort_date_time'),
                    value: 'Date and time'
                  }
                ]}
              />
            </FormField>
            <FormField label={t('notification.sort_direction_title')}>
              <RadioButtonGroup
                name="radio-button-sort"
                value={stateV.sortDirection}
                options={[
                  {
                    label: t('notification.sort_ascending'),
                    value: 'asc'
                  },
                  {
                    label: t('notification.sort_descending'),
                    value: 'desc'
                  }
                ]}
                onChange={(event) => {
                  dispatchNotifications({
                    type: 'SORT',
                    sortDirection: event?.target?.value
                  })
                }}
              />
            </FormField>
          </Box>
        }
      />
    </Box>
  )
}

SortDropButton.propTypes = {
  stateV: PropTypes.object,
  dispatchNotifications: PropTypes.func
}

export { SortDropButton }
