// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box, Page, PageContent } from 'grommet'
import { Previous } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useNavigate } from 'react-router-dom'

import { Typography, Button, Notification, Tabs } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { get } from '../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'

import { WhatsNewDataTable } from './components/WhatsNewDataTable'
import { MyRequestsDataTable } from './components/MyRequestsDataTable'
import CreatePostWizard from './components/create-post/CreatePostWizard'

const WhatsNewPage = () => {
  const { t } = useTranslation(['manage'])

  const [newPostWizard, setNewPostWizard] = useState(false)
  const [viewCreateButton, setViewCreateButton] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [activeTabIdx, setActiveTabIdx] = useState(0)
  const { oidcUser } = useReactOidc()
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const [isSuperUser, setIsSuperUser] = useState(false)
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const mvSvsToSaHomeLD = LDFlags['glcp-sa-mv-svcs']
  useEffect(() => {
    get(
      `/whatsnew/v1/is-super-user`,
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then((response) => {
      if (response?.data) {
        setIsSuperUser(response?.data)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, platformCustomerId, t])

  const handleCreatePostBtnClick = () => {
    setNewPostWizard(true)
  }

  const [refreshCount, setRefreshCount] = useState(0)
  const refreshDataTable = () => {
    setRefreshCount(refreshCount + 1)
  }

  const handleBackBtnClick = () => {
    navigate('/manage-ccs/home')
  }

  const renderTabs = () => (
    <Tabs
      activeIndex={activeTabIdx}
      onActive={(i) => setActiveTabIdx(i)}
      pad="small"
      tabsList={[
        {
          content: (
            <WhatsNewDataTable
              testId="whats-new-datatable"
              setViewCreateButton={setViewCreateButton}
              refreshCount={refreshCount}
              isSuperUser={isSuperUser}
            />
          ),
          id: 1,
          label: t('whats_new.approval.all_published'),
          testId: 'whatsNew-all-published-tab'
        },
        {
          content: (
            <MyRequestsDataTable
              testId="whats-new-request-datatable"
              refreshCount={refreshCount}
              queryParam="all_pending"
            />
          ),
          id: 2,
          label: t('whats_new.approval.all_pending'),
          testId: 'whatsNew-all-pending-tab'
        },
        {
          content: (
            <MyRequestsDataTable
              testId="whats-new-request-datatable"
              refreshCount={refreshCount}
              queryParam="Rejected"
            />
          ),
          id: 3,
          label: t('whats_new.approval.all_rejected'),
          testId: 'whatsNew-all-rejected-tab'
        },
        {
          content: (
            <MyRequestsDataTable
              testId="whats-new-request-datatable"
              refreshCount={refreshCount}
              queryParam="my_request"
            />
          ),
          id: 4,
          label: t('whats_new.approval.my_request'),
          testId: 'whatsNew-my-requests-tab'
        },
        {
          content: (
            <MyRequestsDataTable
              testId="whats-new-request-datatable"
              refreshCount={refreshCount}
              queryParam="my_approval"
            />
          ),
          id: 5,
          label: t('whats_new.approval.my_approval'),
          testId: 'whatsNew-my-approval-tab'
        }
      ]}
      testId="whats-new-tabs"
    />
  )

  const renderContent = () => (
    <>
      {mvSvsToSaHomeLD && (
        <Box direction="row" justify="start" margin={{ left: '--small' }}>
          <Button
            default
            label={t('common:nav_bar.home')}
            icon={<Previous />}
            onClick={handleBackBtnClick}
            testId="manage-account-btn"
          />
        </Box>
      )}
      <Box pad="xsmall" width="xxlarge">
        <Box direction="row" justify="between">
          <Box direction="column" gap="small">
            <Typography
              level="1"
              type="heading"
              weight="normal"
              testId="whats-new-title"
            >
              {t('whats_new.title')}
            </Typography>
            <Box direction="row" justify="between" margin={{ bottom: 'large' }}>
              <Typography
                size="xlarge"
                weight="400"
                type="text"
                testId="whats-new-desc"
              >
                {t('whats_new.page_desc')}
              </Typography>
            </Box>
          </Box>
          {viewCreateButton && (
            <Box>
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.whats-new.edit',
                  resource: '/ccs/whats-new'
                }}
              >
                <Button
                  label={t('whats_new.create_post_btn')}
                  testId="create-post-btn"
                  primary
                  onClick={handleCreatePostBtnClick}
                />
              </VisibilityWrapper>
            </Box>
          )}
        </Box>
        <Box>
          {LDFlags['glcp-whatsnew-approval'] ? (
            renderTabs()
          ) : (
            <WhatsNewDataTable
              testId="whats-new-datatable"
              setViewCreateButton={setViewCreateButton}
              refreshCount={refreshCount}
            />
          )}
        </Box>
      </Box>
    </>
  )

  return (
    <VisibilityWrapper
      hideFor={{ feature: 'glcp-whats-new', deployment: ['COP'] }}
      rbac={{
        resource: '/ccs/whats-new',
        permission: 'ccs.whats-new.view'
      }}
    >
      {mvSvsToSaHomeLD ? (
        <Page>
          <PageContent>{renderContent()}</PageContent>
        </Page>
      ) : (
        renderContent()
      )}
      {successMessage && (
        <Notification
          onClose={() => setSuccessMessage(false)}
          testId="status-good-notification"
          text={successMessage}
        />
      )}
      {newPostWizard && (
        <CreatePostWizard
          refreshDataTable={refreshDataTable}
          setAddPostMessage={setSuccessMessage}
          isSuperUser={isSuperUser}
          closeWizard={() => setNewPostWizard(false)}
        />
      )}
    </VisibilityWrapper>
  )
}

export default WhatsNewPage
