// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import { checkGotoChooseAccount } from '../../../utils/common-utils'
import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import useFetchIsCCSManager from '../../../hooks/ccs-manager/useFetchIsCCSManager'

const NotificationLogs = lazy(() => import('./NotificationLogs'))

// new router for notifcation logs and for its future components

const NotificationLogsRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)
  const { isCCSManager, isLoading } = useFetchIsCCSManager()

  return (
    <Suspense fallback={<LazyLoading />}>
      {isLoading && <LazyLoading />}
      {!isLoading && (
        <Routes>
          {isCCSManager && navigate('/manage-ccs/notifications')}
          <Route exact path="/" element={<NotificationLogs />} />
          <Route path="/*" element={<Navigate to="/not-found" />} />
        </Routes>
      )}
    </Suspense>
  )
}

export default NotificationLogsRouter
