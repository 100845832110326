// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import {
  ModalDialog,
  Button,
  ModalFooter,
  Typography
} from '../../../components'

const SuccessModal = ({ setUpdateSuccess }) => {
  const navigate = useNavigate()
  return (
    <ModalDialog
      width="medium"
      testId="progress-modal-dialog"
      content={
        <Box>
          <Typography type="heading" level="1" testId="progress-modal-title">
            Password
          </Typography>
          <Typography
            type="text"
            size="medium"
            testId="success-modal-description"
          >
            Your password has been reset successfully please login again
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Button
              primary
              label="LogIn"
              onClick={() => {
                setUpdateSuccess(false)
                navigate('/sign-out')
              }}
              testId="close-button"
            />
          }
        />
      }
      onClose={() => setUpdateSuccess(false)}
    />
  )
}

SuccessModal.propTypes = {
  setUpdateSuccess: PropTypes.func.isRequired
}

export default SuccessModal
