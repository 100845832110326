// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useState, useEffect, useContext } from 'react'
import {
  Box,
  FormField,
  TextInput,
  Text,
  Notification,
  RadioButtonGroup
} from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import lodashGet from 'lodash/get'
import { useNavigate, useParams } from 'react-router-dom'

import { WizardContext } from '../../../../../../components/wizard/WizardContext'
import { Anchor, Dropdown, Typography } from '../../../../../../components'
import { get, getErrorMessage } from '../../../../../../utils/api-utils'
import { displayNotification } from '../../../../../../utils/notificiation-utils'
import { dispositionEnum, ssoModeEnum } from '../../../constants'

import { validateGeneralFields } from './utils/validation-utils'

const General = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { t } = useTranslation(['iam'])
  const [domains, setDomains] = useState([])
  const { attemptedAdvance, formValues, setFormValues } =
    useContext(WizardContext)
  const [fieldsError, setFieldsError] = useState({
    profileName: '',
    ccsAttribute: '',
    authMethodChangeConfirmation: '',
    disposition: ''
  })

  const usersDispositions = [
    {
      label: (
        <div>
          <Trans
            i18nKey="sso_profiles.view_edit_create.least_secure_disposition"
            t={t}
          >
            <strong />
          </Trans>
        </div>
      ),
      value: dispositionEnum.LEAST_SECURE
    },
    {
      label: (
        <div>
          <Trans
            i18nKey="sso_profiles.view_edit_create.most_secure_disposition"
            t={t}
          >
            <strong />
          </Trans>
        </div>
      ),
      value: dispositionEnum.MOST_SECURE
    }
  ]

  const authorizationMethods = [
    {
      label: t('iam:sso_profiles.view_edit_create.authorization_method_local'),
      value: ssoModeEnum.AUTHENTICATION_ONLY
    },
    {
      label: t('iam:sso_profiles.view_edit_create.authorization_method_saml'),
      value: ssoModeEnum.AUTHORIZATION
    }
  ]

  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  // The list of domains includes only unclaimed domains. In the edit scenario, we need to include the current domain (which is already claimed) in the list.
  const getDomains = () => {
    const domainList = domains
    if (formValues.currentDomain) {
      const domainExists = domainList.find(
        (item) => item.name === formValues.currentDomain
      )
      if (!domainExists) {
        const domain = {
          id: formValues.currentDomain,
          name: formValues.currentDomain
        }
        domainList.push(domain)
      }
    }
    return domainList
  }

  useEffect(() => {
    let isMounted = true
    const fetchUnclaimedDomains = async () => {
      try {
        const response = await get(
          `/internal-platform-tenant-ui/v2/unclaimed-domains`
        )
        if (isMounted) {
          setDomains(response?.data?.items)
        }
      } catch (error) {
        if (isMounted) {
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setDomains([])
        }
      }
    }

    fetchUnclaimedDomains()

    return () => {
      isMounted = false
    }
  }, [t, formValues])

  useEffect(() => {
    if (attemptedAdvance) {
      setFieldsError(validateGeneralFields(formValues, t))
    }
  }, [formValues, attemptedAdvance, t])

  return (
    <Box gap="medium" pad={{ top: 'xxsmall', bottom: 'medium' }}>
      <Box width="large" gap="medium">
        <Box gap="xsmall">
          <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
            {t('iam:sso_profiles.view_edit_create.profile_name')}*
          </Text>
          <Text size="xsmall">
            {t('iam:sso_profiles.view_edit_create.profile_name_description')}
          </Text>
          <FormField
            htmlFor="profile_name"
            name="profile_name"
            error={fieldsError.profileName}
            data-testid="profile-name-form-field"
            required
          >
            <TextInput
              value={lodashGet(formValues, 'general.profileName')}
              id="profile_name"
              name="profile_name_value"
              data-testid="profile-name-input"
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  general: {
                    ...formValues.general,
                    profileName: e.target.value
                  }
                })
              }}
            />
          </FormField>
          <Notification
            status="info"
            message={
              <Typography type="text" testId="domain-page">
                <Trans
                  i18nKey="iam:sso_profiles.view_edit_create.domain_not_listed"
                  t={t}
                >
                  <Anchor
                    label={t('iam:sso_profiles.view_edit_create.domain_page')}
                    weight="bold"
                    onClick={() =>
                      navigate('/manage-account/organization/domains')
                    }
                  />
                </Trans>
              </Typography>
            }
          />
          <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
            {t('iam:sso_profiles.view_edit_create.domain')}
          </Text>
          <Text size="xsmall">
            {t('iam:sso_profiles.view_edit_create.domain_description')}
          </Text>
          <FormField
            htmlFor="domain"
            name="domain"
            data-testid="domain-form-field"
          >
            <Dropdown
              options={getDomains()}
              clear={{
                label: t('iam:sso_profiles.view_edit_create.clear_selection')
              }}
              placeholder={t('iam:sso_profiles.view_edit_create.select')}
              testId="domain-dropdown"
              labelKey="name"
              valueKey="name"
              value={formValues.domain}
              onChangeDropdown={(values) => {
                setFormValues({
                  ...formValues,
                  domain: values?.name
                })
              }}
            />
          </FormField>
          {lodashGet(formValues, 'domain') && (
            <FormField
              label={`${t(
                'iam:sso_profiles.view_edit_create.choose_authorization_method'
              )}*`}
              data-testid="authorization-method-form-field"
            >
              <RadioButtonGroup
                name="authorization_method"
                options={authorizationMethods}
                value={lodashGet(formValues, 'general.authorizationMethod')}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    general: {
                      ...formValues.general,
                      authorizationMethod: e.target.value
                    }
                  })
                }}
                data-testid="authorization-method-input"
              />
            </FormField>
          )}
          {lodashGet(formValues, 'domain') &&
            lodashGet(formValues, 'general.authorizationMethod') ===
              ssoModeEnum.AUTHORIZATION && (
              <>
                <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
                  {t('iam:sso_profiles.view_edit_create.saml_attribute_name')}*
                </Text>
                <Text size="xsmall">
                  {t(
                    'iam:sso_profiles.view_edit_create.saml_attribute_description'
                  )}
                </Text>
                <FormField
                  htmlFor="ccs_attribute"
                  name="ccs_attribute"
                  error={fieldsError.ccsAttribute}
                  data-testid="ccs-attribute-form-field"
                  required
                >
                  <TextInput
                    value={lodashGet(
                      formValues,
                      'attribute_mapping.ccsAttribute'
                    )}
                    id="ccs_attribute"
                    name="ccs_attribute_value"
                    data-testid="ccs-attribute-input"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        attribute_mapping: {
                          ...formValues.attribute_mapping,
                          ccsAttribute: e.target.value
                        }
                      })
                    }}
                  />
                </FormField>
              </>
            )}
          {id &&
            lodashGet(formValues, 'domain') &&
            formValues.currentDomain &&
            lodashGet(formValues, 'general.authorizationMethod') !==
              lodashGet(formValues, 'general.currentAuthorizationMethod') &&
            lodashGet(formValues, 'general.authorizationMethod') ===
              ssoModeEnum.AUTHORIZATION && (
              <Notification
                status="info"
                margin={{ top: 'xsmall', bottom: 'xsmall' }}
                message={
                  <Typography type="text" testId="delete-time-warning">
                    <Trans
                      i18nKey="iam:sso_profiles.view_edit_create.local_roles_removal_notice"
                      t={t}
                    />
                  </Typography>
                }
              />
            )}
          {id &&
            lodashGet(formValues, 'domain') &&
            formValues.currentDomain &&
            lodashGet(formValues, 'general.authorizationMethod') !==
              lodashGet(formValues, 'general.currentAuthorizationMethod') &&
            lodashGet(formValues, 'general.authorizationMethod') ===
              ssoModeEnum.AUTHENTICATION_ONLY && (
              <>
                <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
                  {t('iam:sso_profiles.view_edit_create.disposition')}*
                </Text>

                <FormField
                  label={`${t(
                    'iam:sso_profiles.view_edit_create.disposition_description'
                  )}`}
                  data-testid="disposition-form-field"
                  error={fieldsError.disposition}
                >
                  <RadioButtonGroup
                    name="disposition"
                    options={usersDispositions}
                    value={lodashGet(formValues, 'general.usersDisposition')}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        general: {
                          ...formValues.general,
                          usersDisposition: e.target.value
                        }
                      })
                    }}
                    data-testid="disposition-input"
                  />
                </FormField>
              </>
            )}
          {id &&
            lodashGet(formValues, 'domain') &&
            formValues.currentDomain &&
            lodashGet(formValues, 'general.authorizationMethod') !==
              lodashGet(formValues, 'general.currentAuthorizationMethod') && (
              <>
                <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
                  {t('iam:sso_profiles.delete.confirmation_title')}*
                </Text>
                <Text size="xsmall">
                  {t(
                    'iam:sso_profiles.view_edit_create.auth_method_update_confirmation_description'
                  )}
                </Text>
                <FormField
                  htmlFor="auth_method_update_confirmation"
                  name="auth_method_update_confirmation"
                  data-testid="delete-confirmation-form-field"
                  required
                  error={fieldsError.authMethodChangeConfirmation}
                >
                  <TextInput
                    padding="xxsmall"
                    value={lodashGet(
                      formValues,
                      'general.authMethodChangeConfirmation'
                    )}
                    id="auth_method_update_confirmation"
                    name="auth_method_update_confirmation_value"
                    data-testid="auth-method-update-confirmation-input"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        general: {
                          ...formValues?.general,
                          authMethodChangeConfirmation: e.target.value
                        }
                      })
                    }}
                  />
                </FormField>
              </>
            )}
        </Box>
      </Box>
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

export default General
