// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import useLD from '../../../../../hooks/ld/useLD'
import {
  ActionButton,
  Button,
  Tile,
  Typography,
  Loader
} from '../../../../../components'
import {
  RemoveApplicationModal,
  DeleteCustomerAccountModal,
  UnassignTenantDevicesModal
} from '../../../components'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { AccountLogo } from '../../../../../commoncomponents/account-logo/AccountLogo'
import { get, del, post } from '../../../../../utils/api-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'
import { loadCustomerAccount } from '../../../../../utils/account-utils'
import { useVisibilityContext } from '../../../../../context/visibility-context'
import AccessErrorPage from '../../../../error/AccessError'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../../utils/common-utils'
import { useUPSContext } from '../../../../../context/ups-context'
import useLogger from '../../../../../hooks/logs/useLogger'

import ViewDetails from './ViewDetails'

const ViewCustomerAccount = ({ customerAccountDetails, customerId }) => {
  const { t } = useTranslation(['authn', 'common'])
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const [deleteAccountModal, setDeleteAccountModal] = useState(false)
  const [removeApplicationModal, setRemoveApplicationModal] = useState(false)
  const [provisionedAppCount, setProvisionedAppCount] = useState(null)
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(false)
  const LDFlags = useFlags()
  const ld = useLD()
  const logger = useLogger()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const logoDetails = {
    ...customerAccountDetails,
    customer_logo: {
      logo: customerAccountDetails.logo,
      logo_filename: customerAccountDetails.logoFilename
    }
  }
  const [gtsError, setGTSError] = useState(null)
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchUPSContext } = useUPSContext()
  const custAccountLoaded = getCustomerAccount()

  // pagination for post call to fetch devices list
  const limit = 1
  const offset = 0
  const [assignedDeviceCount, setAssignedDeviceCount] = useState(null)
  const [openUnassignDeviceModal, setOpenUnassignDeviceModal] = useState(false)

  const launchAccount = () => {
    setLoading(true)
    const {
      companyName,
      description,
      countryCode,
      city,
      state,
      streetAddress,
      zip,
      logo,
      logoFilename
    } = customerAccountDetails
    sessionStorage.setItem('swapMspAccount', JSON.stringify(custAccountLoaded))
    const account = {
      account_type: 'TENANT',
      address: {
        city,
        country_code: countryCode,
        state_or_region: state,
        street_address: streetAddress,
        zip
      },
      customer_logo: { logo, logo_filename: logoFilename },
      platform_customer_id: customerId,
      company_name: companyName,
      description
    }

    setGTSError(null)
    loadCustomerAccount(
      account,
      oidcUser,
      dispatchVisibilityContext,
      navigate,
      null,
      null,
      ld,
      dispatchUPSContext,
      logger
    ).then(
      () => {
        setLoading(false)
      },
      (error) => {
        if (error?.response?.status === 451) {
          setGTSError(error)
        } else {
          setShowNotification(displayApiError(error, t, setShowNotification))
        }
        setLoading(false)
      }
    )
  }

  // Below func is used to handle the Drop Button Delete Account Action
  // It checks if Tenant has assigned devices & provisoned App
  // If yes appropriate modal is displayed to take action else Delete Tenant Modal will be displayed
  const handleDropActionDeleteAccount = async () => {
    let checkProvisonedApp = false
    await post(
      `/ui-doorway/ui/v1/devices/filter?limit=${limit}&offset=${offset}`,
      {
        tenant_platform_customer_ids: [customerId],
        archive_visibility: 'HIDE_ARCHIVED'
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.pagination?.total_count) {
          setAssignedDeviceCount(response.data?.pagination?.total_count)
          setOpenUnassignDeviceModal(true)
        } else {
          checkProvisonedApp = true
        }
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
    if (checkProvisonedApp) {
      get(
        `/ui-doorway/ui/v1/applications/provisions/${customerId}`,
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          if (response?.data?.pagination?.total_count) {
            setProvisionedAppCount(response.data?.pagination?.total_count)
            setRemoveApplicationModal(true)
          } else {
            setDeleteAccountModal(true)
          }
        },
        (error) => {
          setShowNotification(displayApiError(error, t, setShowNotification))
        }
      )
    }
  }

  // Below func is used to handle the Delete Account Button action in Delete Tenant Modal
  const handleModalDeleteAccountAction = () => {
    del(
      `/accounts/ui/v1/managed-service/tenant/${customerId}`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        navigate('/customer-account')
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
  }

  return (
    <>
      {showNotification !== null && showNotification}
      {gtsError ? (
        <AccessErrorPage backToMSP />
      ) : (
        <>
          <Box
            margin={{ left: 'large' }}
            border="bottom"
            pad={{ bottom: 'small' }}
          >
            <Tile
              key={customerId}
              testId="customer-account-detail"
              boxShadow={false}
              layout="row"
              actionBtn={
                <Box direction="row" gap="medium">
                  <ActionButton
                    actions={[
                      {
                        label: t('customer_account.delete_account', {
                          account: getWorkspaceString(
                            showWorkspaceString,
                            t,
                            WKSPC_CAPITALIZED
                          )
                        }),
                        onClick: handleDropActionDeleteAccount,
                        visibility: {
                          rbac: {
                            resource: '/ccs/accounts/platform/customer',
                            permission: 'ccs.accounts.platform.customer.delete'
                          }
                        }
                      },
                      {
                        label: t('customer_account.launch'),
                        onClick: launchAccount,
                        visibility: {
                          rbac: null
                        }
                      }
                    ]}
                    testId="customer-account-detail-action-btn"
                    dropAlign={{
                      right: 'right',
                      top: 'bottom'
                    }}
                    customRenderer={(actionBtn, visibility, idx) => {
                      return (
                        <VisibilityWrapper
                          key={idx} // eslint-disable-line react/no-array-index-key
                          rbac={visibility.rbac}
                        >
                          {actionBtn}
                        </VisibilityWrapper>
                      )
                    }}
                  />
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/accounts/platform/customer',
                      permission: 'ccs.accounts.platform.customer.edit'
                    }}
                  >
                    <Button
                      primary
                      label={t('customer_account.edit_account', {
                        account: getWorkspaceString(
                          showWorkspaceString,
                          t,
                          WKSPC_CAPITALIZED
                        )
                      })}
                      testId="edit-account-btn"
                      onClick={() =>
                        navigate(`/customer-account/edit/${customerId}`)
                      }
                    />
                  </VisibilityWrapper>
                </Box>
              }
              logo={
                <AccountLogo
                  account={logoDetails}
                  avatarSize="large"
                  title={customerAccountDetails.companyName}
                  testId="tenant-detail"
                />
              }
              title={
                <Typography
                  level="2"
                  testId="customer-account-company-name"
                  type="heading"
                  weight="bold"
                >
                  {customerAccountDetails.companyName}
                </Typography>
              }
            />
          </Box>

          <Box
            gridArea="content"
            direction="column"
            margin={{ left: 'large', right: 'xlarge', top: 'medium' }}
          >
            <Box border="bottom" direction="row" pad={{ bottom: 'small' }}>
              <Typography
                type="heading"
                weight="bold"
                level="3"
                testId="account-details-title"
                truncate="tip"
              >
                {t('customer_account.account_details', {
                  account: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
              </Typography>
            </Box>
            <ViewDetails customerAccountDetails={customerAccountDetails} />
          </Box>
        </>
      )}
      {deleteAccountModal && (
        <DeleteCustomerAccountModal
          handleCancel={() => setDeleteAccountModal(false)}
          customer={{
            customer_id: customerId,
            company_name: customerAccountDetails.companyName
          }}
          handleDeleteCustomerAccount={handleModalDeleteAccountAction}
        />
      )}
      {removeApplicationModal && (
        <RemoveApplicationModal
          handleClose={() => setRemoveApplicationModal(false)}
          customer={{
            customer_id: customerId,
            company_name: customerAccountDetails.companyName
          }}
          provisionedAppCount={provisionedAppCount}
        />
      )}
      {loading && (
        <Loader
          modal
          testId="loader-modal"
          size="xxsmall"
          modalTitle={t('common:launching')}
          modalSubTitle={t('common:loader_dialog_message')}
        />
      )}
      {openUnassignDeviceModal && (
        <UnassignTenantDevicesModal
          handleCancel={() => setOpenUnassignDeviceModal(false)}
          handleViewAssignedDevices={() =>
            navigate('/devices/inventory', {
              state: {
                accountFilter: [
                  {
                    customer_id: customerId,
                    company_name: customerAccountDetails.companyName
                  }
                ],
                archive_visibility: ['HIDE_ARCHIVED']
              }
            })
          }
          assignedDeviceCount={assignedDeviceCount}
        />
      )}
    </>
  )
}

ViewCustomerAccount.propTypes = {
  customerAccountDetails: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired
}

export default ViewCustomerAccount
