// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Layout } from '../../../commoncomponents/layout/Layout'
import { Wizard, Typography } from '../../../components'
import { get, post, getErrorResponse } from '../../../utils/api-utils'

import {
  defaultGatewayDetails,
  gatewayInfoValidate,
  appInfoValidate,
  convertAppToId
} from './utils'
import GatewayDetails from './steps/step-1/Details'
import ConfigureApp from './steps/step-2/ConfigureApp'
import ReviewAndSubmit from './steps/step-3/ReviewAndSubmit'
import CreationErrorModal from './CreationErrorModal'

const CreateGatewayWizard = () => {
  const { t } = useTranslation(['local-gateway', 'common'])
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const [credsError, setCredsError] = useState(false)
  const [gatewayError, setGatewayError] = useState(false)

  const [savedGatewayDetails, setSavedGatewayDetails] = useState()
  const [gatewayList, setGatewayList] = useState()

  useEffect(() => {
    get(`/platform/acpmgr/getGateways`, {}, oidcUser.access_token).then(
      (response) => {
        if (response.data) {
          setGatewayList(response.data.gateways)
        }
      }
    )
  }, [oidcUser])
  const addCredential = ({ gatewayId, appId, username, password }) => {
    setCredsError(false)
    const requestBody = {
      app: {},
      creds: {
        username: username.trim(),
        password
      }
    }

    post(
      `/opg-proxy/v1/gateways/${gatewayId}/apps/${appId}/creds`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        navigate(`/manage-account/local-gateway/register/${gatewayId}`)
      },
      () => {
        setCredsError(true)
      }
    )
  }

  const addGateway = ({ gatewayDetails }) => {
    const appId = convertAppToId()
    const requestBody = {
      appTypes: [appId],
      name: gatewayDetails.name.trim(),
      description: gatewayDetails.description.trim()
    }
    post(
      '/platform/acpmgr/addGateway',
      requestBody,
      oidcUser.access_token
    ).then(
      (response) => {
        const gatewayId = response.data.id
        setSavedGatewayDetails({ ...gatewayDetails, gatewayId })

        addCredential({
          gatewayId,
          appId,
          username: gatewayDetails.username,
          password: gatewayDetails.password
        })
      },
      (error) => {
        const { code } = getErrorResponse(error, t)
        const nameError = code === 409

        const errorData = {
          gatewayName: gatewayDetails.name,
          nameError,
          setShowModal: () => {
            setGatewayError(undefined)
            // we dont redirect if we have a name collision so the user can change the name and resubmit if they want
            if (!nameError) {
              navigate('/manage-account/local-gateway')
            }
          }
        }

        setGatewayError(errorData)
      }
    )
  }

  const handleSubmitClick = (formvalues) => {
    addGateway({ gatewayDetails: formvalues?.gatewayDetails })
  }

  const handleContinueClick = () => {
    navigate(
      `/manage-account/local-gateway/register/${savedGatewayDetails?.gatewayId}`
    )
  }

  return (
    <Layout>
      <Box fill>
        <Wizard
          title={t('wizard_title')}
          buttonLabels={{
            finish: credsError
              ? t('common:continue')
              : t('gateway_wizard_complete_button_text'),
            // we want to add a second button on the final step but only on a credential failure
            ...(credsError && {
              otherActions: [
                {
                  showInStep: 3,
                  label: t('edit'),
                  handleOnClick: () => {
                    navigate(
                      `/manage-account/local-gateway/details/${savedGatewayDetails?.gatewayId}`
                    )
                  },
                  testId: 'error-edit-btn'
                }
              ]
            })
          }}
          formDefaultValues={{
            ...defaultGatewayDetails
          }}
          actionOnExit={() => navigate(-1)}
          actionOnSubmit={
            credsError
              ? handleContinueClick
              : (formValues) => handleSubmitClick(formValues)
          }
          cancelStrings={{
            continueLabel: t('step_continue_label'),
            exitLabel: t('step_exit_label'),
            heading: t('step_heading'),
            text: t('step_cancel_text')
          }}
          steps={[
            {
              childComponents: <GatewayDetails />,
              description: (
                <Typography type="text" size="medium" testId="step1-desc">
                  {t('step_1_subtitle')}
                </Typography>
              ),
              title: t('step_1_title'),
              validateForm: (formValues) => ({
                then: (resolve, reject) => {
                  const { message, isValid } = gatewayInfoValidate(
                    formValues,
                    t,
                    gatewayList && gatewayList.map((gateway) => gateway.name)
                  )
                  if (isValid) {
                    resolve()
                  } else reject(new Error(message))
                }
              })
            },
            {
              childComponents: <ConfigureApp />,
              description: (
                <Typography type="text" size="medium" testId="step1-desc">
                  {t('step_2_subtitle')}
                </Typography>
              ),
              title: t('step_2_title'),
              validateForm: (formValues) => ({
                then: (resolve, reject) => {
                  const { message, isValid } = appInfoValidate(
                    formValues.gatewayDetails,
                    t
                  )
                  if (isValid) {
                    resolve()
                  } else {
                    reject(new Error(message))
                  }
                }
              })
            },
            {
              childComponents: <ReviewAndSubmit credsError={credsError} />,
              description: (
                <Typography type="text" size="medium" testId="step1-desc">
                  {t('step_3_subtitle')}
                </Typography>
              ),
              title: t('step_3_title'),
              validateForm: () => ({
                then: (resolve) => {
                  resolve()
                }
              })
            }
          ]}
          testId="review-devices-wizard"
        />
        {gatewayError && <CreationErrorModal {...gatewayError} />}
      </Box>
    </Layout>
  )
}
export default CreateGatewayWizard
