// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { lazy, Suspense } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const System = lazy(() => import('./System'))
const NodeDetailsPage = lazy(() => import('./performance/pages/NodeDetails'))

const SystemRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/performance/:ip" element={<NodeDetailsPage />} />
        <Route exact path="/*" element={<System />} />
      </Routes>
    </Suspense>
  )
}

export default SystemRouter
