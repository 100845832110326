// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Navigate, Route, Routes } from 'react-router-dom'
import React, { lazy } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

const AssignToApplication = lazy(() =>
  import('./assign-to-application/AssignToApplication')
)
const DeviceDetailsPage = lazy(() => import('./device-details/DeviceDetails'))
const ApplySubscriptionWizardFromDevices = lazy(() =>
  import('./apply-subscription/ApplySubscriptionWizardFromDevices')
)
const AddDeviceWizard = lazy(() => import('./add-devices/AddDeviceWizard'))
const AddDeviceSubscriptionWizard = lazy(() =>
  import('./add-device-subscriptions/AddDeviceSubscriptionWizard')
)
const NewApplySubscription = lazy(() =>
  import('./apply-subscription/steps/NewApplySubscription')
)
const ReplaceSubscription = lazy(() =>
  import('./replace-subscription/ReplaceSubscription')
)

const InventoryDetailsRouter = () => {
  const LDFlags = useFlags()
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/devices/inventory-list" />} />
      <Route path="/add-devices" element={<AddDeviceWizard />} />
      <Route
        path="/add-device-subscriptions"
        element={<AddDeviceSubscriptionWizard />}
      />
      <Route path="/assign-to-application" element={<AssignToApplication />} />
      <Route path="/replace-subscription" element={<ReplaceSubscription />} />
      <Route
        path="/apply-subscription"
        element={
          LDFlags['glcp-multi-license-support'] ? (
            <NewApplySubscription />
          ) : (
            <ApplySubscriptionWizardFromDevices />
          )
        }
      />
      <Route
        path="/:colonDelimitSerialPartDevicetype"
        element={<DeviceDetailsPage />}
      />
    </Routes>
  )
}

export default InventoryDetailsRouter
