// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import {
  ModalDialog,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  Typography
} from '../../../components'
import { CCSActions } from '../../../context/ccs-context'

const AccessRestrictedModal = ({
  logoutOnIpRestriction = false,
  dispatchCCSContext = () => {}
}) => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  return (
    <ModalDialog
      testId="access-restricted-modal"
      width="medium"
      onClose={() => {}}
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="modal-title"
              type="heading"
              weight="bold"
            >
              {t('access_restricted')}
            </Typography>
          }
          subtitle={t('access_restricted_msg')}
        />
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 1,
                  label: t('close'),
                  primary: true,
                  testId: 'close-modal-btn',
                  onClick: () =>
                    logoutOnIpRestriction
                      ? navigate('/sign-out')
                      : dispatchCCSContext({
                          type: CCSActions.SET_IS_IP_RESTRICTED,
                          data: false
                        })
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
    />
  )
}

AccessRestrictedModal.propTypes = {
  logoutOnIpRestriction: PropTypes.bool,
  dispatchCCSContext: PropTypes.func
}

export { AccessRestrictedModal }
