// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react'
import {
  Anchor,
  Page,
  PageContent,
  PageHeader,
  Grid,
  ResponsiveContext
} from 'grommet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Previous } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Layout } from '../../../commoncomponents/layout/Layout'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { Typography, Card } from '../../../components'

const AutomationPage = () => {
  const LDFlags = useFlags()
  const showWebhook = LDFlags['glcp-eventing-webhook']
  const { t } = useTranslation(['automations'])
  const navigate = useNavigate()
  const size = useContext(ResponsiveContext)
  const childGrid = {
    columns: {
      small: ['flex'],
      medium: { count: 2, size: 'flex' },
      large: { count: 3, size: 'auto' },
      xlarge: { count: 3, size: 'auto' }
    }
  }

  const handleBackBtnClick = () => {
    navigate('/manage-account')
  }

  const handleAccountCardClick = () => {
    const mainRouter = '/manage-account/automations/webhooks'
    const routeTo = `${mainRouter}`
    navigate(routeTo)
  }

  const manageAutomationsList = [
    {
      id: 1,
      icon: '',
      title: t('automations:webhooks.title'),
      description: t('automations:webhooks.subtitle'),
      routeTo: 'account-details',
      testId: 'text-webhooks-title',
      isHidden: !showWebhook,
      rbac: {
        resource: '/ccs/events/webhook',
        permission: 'ccs.events.webhook.read'
      }
    }
  ]

  return (
    <Layout>
      <Page data-testid="automations-card">
        <PageContent>
          <PageHeader
            responsive
            parent={
              <Anchor
                label={`${t('automations:automations.previous_page_title')}`}
                icon={<Previous />}
                onClick={handleBackBtnClick}
                data-testid="automations-page-title"
              />
            }
            pad={{ top: 'medium' }}
            title={
              <Typography level="1" type="heading" testId="page-title">
                {t('automations:automations.title')}
              </Typography>
            }
            subtitle={t('automations:automations.description')}
          />

          <Grid columns={childGrid.columns[size]}>
            {manageAutomationsList &&
              manageAutomationsList.map((item) =>
                !item.isHidden ? (
                  <VisibilityWrapper
                    key={item.id}
                    hideFor={item.hideFor}
                    rbac={item.rbac}
                  >
                    <Card
                      key={item.id}
                      cardWidth="auto"
                      description={item.description}
                      descriptionEllipsis
                      icon={item.icon}
                      testId={item.testId}
                      title={item.title}
                      titleEllipsis
                      titleSize="medium"
                      margin={{ top: 'medium', bottom: 'medium' }}
                      height="small"
                      onClick={() => handleAccountCardClick()}
                    />
                  </VisibilityWrapper>
                ) : null
              )}
          </Grid>
        </PageContent>
      </Page>
    </Layout>
  )
}

export default AutomationPage
