// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Anchor, Box, NameValueList, NameValuePair } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Typography } from '../../../components'

const ViewDetails = ({ data = {}, inCCSManager = false }) => {
  const { t } = useTranslation(['device'])
  const navigate = useNavigate()
  return (
    <Box>
      <NameValueList
        valueProps={{ width: ['auto', 'medium'] }}
        data-testid="folder-details"
      >
        {Object.entries(data).map(([name, value]) => {
          return name === t('devices_tab') ? (
            <NameValuePair
              key={name}
              name={
                <Typography
                  size="medium"
                  type="text"
                  weight={500}
                  testId={`${name}-field`}
                >
                  {name}
                </Typography>
              }
            >
              {value ? (
                <Box direction="row" gap="xxsmall">
                  <Typography
                    size="medium"
                    color="text-strong"
                    testId="details-value"
                    type="text"
                  >
                    {value}
                  </Typography>
                  <Anchor
                    data-testid="devices-anchor-btn"
                    label={<strong>{t('view_devices')}</strong>}
                    onClick={() => {
                      if (inCCSManager) {
                        navigate('/manage-ccs/customers/customer-details', {
                          state: {
                            tabName: 'devices',
                            folderDetail: {
                              folder_id: data?.[t('id')],
                              folder_name: data?.[t('name')]
                            }
                          }
                        })
                      } else {
                        navigate('/manage-account/activate/devices-list', {
                          state: {
                            folderDetail: {
                              folder_id: data?.[t('id')],
                              folder_name: data?.[t('name')]
                            }
                          }
                        })
                      }
                    }}
                  />
                </Box>
              ) : (
                '--'
              )}
            </NameValuePair>
          ) : (
            <NameValuePair
              key={name}
              name={
                <Typography
                  size="medium"
                  type="text"
                  weight={500}
                  testId={`${name}-field`}
                >
                  {name}
                </Typography>
              }
              data-testid={`${name}`}
            >
              {value || '--'}
            </NameValuePair>
          )
        })}
      </NameValueList>
    </Box>
  )
}

ViewDetails.propTypes = {
  data: PropTypes.object,
  inCCSManager: PropTypes.bool
}

export { ViewDetails }
