// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Box, FormField, Select, Anchor, CheckBox } from 'grommet'
import { FormPrevious } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  Button,
  Typography,
  CCSForm,
  Loader,
  FormInput
} from '../../../../components'
import { get, post, getErrorMessage } from '../../../../utils/api-utils'
import {
  validateCompanyName,
  validateCity,
  validateStreetAddress,
  validateZipCode,
  validateEmail
} from '../../../../utils/validation-utils'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'

// TODO: CCS-1620 - move to common components
const BackButton = () => {
  const { t } = useTranslation(['authn'])
  const navigate = useNavigate()
  const handleRoute = () => {
    navigate(-1)
  }
  return (
    <Button
      alignSelf="start"
      icon={<FormPrevious />}
      margin="xsmall"
      gap="xsmall"
      label={t('acct_onboarding.back')}
      onClick={handleRoute}
      testId="back-button"
    />
  )
}

const SetUpAccountForm = () => {
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common', 'authn'])
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      company_name: '',
      country_code: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip_code: '',
      phone_number: '',
      email: ''
    }
  )

  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    let params = { status: 'AVAILABLE' }
    get('/ui-doorway/ui/v1/status', {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response?.data?.flags?.gts_compliance_flag) {
            params = {}
          }
        },
        (error) => {
          const backendErrorMessage = getErrorMessage(error, t)
          setErrorMessage(backendErrorMessage)
        }
      )
      .finally(() => {
        get('/geo/ui/v1/countries', params).then(
          (response) => {
            setListOfCountries(response.data.countries)
            setCountries(response.data.countries)
          },
          (error) => {
            const backendErrorMessage = getErrorMessage(error, t)
            setErrorMessage(backendErrorMessage)
          }
        )
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token])

  const doPostAccount = (requestBody) => {
    post(
      '/support-assistant/v1alpha1/workspace',
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setSubmitted(false)
        navigate('/manage-ccs/customers')
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
        setSubmitted(false)
      }
    )
  }
  const handleSetUpAccount = () => {
    setErrorMessage('')
    setSubmitted(true)
    const requestBody = {
      company_name: state.company_name,
      workspace_name: state.company_name,
      created_by: oidcUser.profile.email,
      address: {
        street_address: state.street_address,
        street_address_2: state.street_address_2,
        city: state.city?.trim(),
        state_or_region: state.state?.trim(),
        zip: state.zip_code?.trim(),
        country_code: state.country_code
      },
      email: oidcUser.profile.email,
      phone_number: state.phone_number,
      workspace_type: 'BASIC_ORGANIZATION'
    }
    // TODO: This is a temporary fix to unblock ST. This has to be fixed in BE
    if (state.email) {
      requestBody.email = state.email
    }

    setTimeout(() => {
      doPostAccount(requestBody)
    }, 100)
  }

  const setUpAccountButton = (
    <Button
      primary
      type="submit"
      label={t('authn:customer_account.createAccount', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      })}
      fill="horizontal"
      size="large"
      margin={{ vertical: 'small' }}
      testId="set-up-account-submit"
      isLoading={submitted}
    />
  )

  return (
    <>
      <BackButton />
      <Box align="center" justify="center">
        <Box width="medium">
          <Typography
            type="heading"
            level="1"
            margin={{ top: 'medium', bottom: 'small' }}
            testId="set-up-account-header"
          >
            Setup Organization Workspace
          </Typography>

          <Typography
            type="text"
            size="xlarge"
            margin={{ bottom: 'medium' }}
            testId="set-up-account-subheader"
          >
            {t('authn:acct_onboarding.fill_details', {
              account: getWorkspaceString(showWorkspaceString, t, WKSPC)
            })}
          </Typography>

          <CCSForm
            value={state}
            buttons={setUpAccountButton}
            onSubmit={handleSetUpAccount}
            errorMessage={errorMessage}
            validate="blur"
            testId="set-up-account-form"
          >
            <>
              <FormInput
                label={t('authn:customer_account.company_name', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                name="company_name"
                required
                validate={(value) => {
                  if (validateCompanyName(value))
                    return t('authn:customer_account.tenant_name_error_message')
                  return true
                }}
                testId="set-up-account-company-name-form"
                disabled={submitted}
                value={state.company_name}
                inputType="text"
                placeholder={t('authn:customer_account.company_placeholder', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                onChange={(event) =>
                  dispatch({
                    value: event.target.value,
                    field: 'company_name',
                    type: 'CHANGE_FIELD'
                  })
                }
              />

              <FormField
                label={t('authn:account_details.country_lbl', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                name="country_code"
                required
                data-testid="set-up-account-country-form-field"
                disabled={submitted}
              >
                <Select
                  name="country_code"
                  placeholder={t('authn:customer_account.country_placeholder')}
                  options={countries}
                  multiple={false}
                  labelKey="name"
                  value={state.country_code}
                  valueKey={{ key: 'code', reduce: true }}
                  searchPlaceholder={t('authn:customer_account.country')}
                  emptySearchMessage={t(
                    'authn:customer_account.country_empty_search_message'
                  )}
                  onSearch={(searchText) => {
                    const regexp = new RegExp(searchText, 'i')
                    setCountries(
                      listOfCountries.filter((o) => o.name.match(regexp))
                    )
                  }}
                  onChange={({ option }) => {
                    dispatch({
                      value: option.code,
                      field: 'country_code',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  onClose={() => setCountries(listOfCountries)}
                  data-testid="set-up-account-country-select"
                  disabled={submitted}
                />
              </FormField>

              <FormInput
                label={t('authn:customer_account.street_address')}
                name="street_address"
                margin={{ bottom: 'none' }}
                required
                validate={(value) => {
                  if (!validateStreetAddress(value))
                    return t('max_chars_exceeded')
                  return true
                }}
                testId="set-up-account-street-address-form"
                disabled={submitted}
                inputType="text"
                value={state.street_address}
                placeholder={t('authn:customer_account.street_address')}
                onChange={(event) =>
                  dispatch({
                    value: event.target.value,
                    field: 'street_address',
                    type: 'CHANGE_FIELD'
                  })
                }
              />
              <VisibilityWrapper
                hideFor={{
                  feature: 'glcp-msp-add-customer-field'
                }}
              >
                <FormInput
                  label={t('authn:account_details.street_address_2_lbl')}
                  name="street_address_2"
                  margin={{ bottom: 'none' }}
                  validate={(value) => {
                    if (!validateStreetAddress(value))
                      return t('max_chars_exceeded')
                    return true
                  }}
                  testId="set-up-account-street-address-2-form"
                  disabled={submitted}
                  inputType="text"
                  value={state.street_address_2}
                  placeholder={t('authn:customer_account.apt_suite_building')}
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'street_address_2',
                      type: 'CHANGE_FIELD'
                    })
                  }
                />
              </VisibilityWrapper>

              <Box direction="row-responsive" gap="xsmall">
                <Box direction="column" basis="1/2" height={{ min: 'auto' }}>
                  <FormInput
                    label={t('authn:account_details.city')}
                    required
                    name="city"
                    validate={(city) => {
                      if (city && !validateCity(city))
                        return t('max_chars_exceeded_city')
                      return true
                    }}
                    testId="set-up-account-city-form"
                    disabled={submitted}
                    inputType="text"
                    value={state.city}
                    placeholder={t('authn:account_details.city')}
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'city',
                        type: 'CHANGE_FIELD'
                      })
                    }
                  />
                </Box>
                <Box direction="column" basis="1/2" height={{ min: 'auto' }}>
                  <FormInput
                    label={t('authn:account_details.state')}
                    required
                    name="state"
                    testId="set-up-account-state-form"
                    disabled={submitted}
                    inputType="text"
                    value={state.state}
                    placeholder={t('authn:account_details.state')}
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'state',
                        type: 'CHANGE_FIELD'
                      })
                    }
                  />
                </Box>
              </Box>

              <FormInput
                label={t('authn:customer_account.zip')}
                required
                name="zip_code"
                validate={(value) => {
                  if (!validateZipCode(value)) return t('max_chars_exceeded')
                  return true
                }}
                testId="set-up-account-zip-code-form"
                disabled={submitted}
                inputType="text"
                value={state.zipCode}
                placeholder={t('authn:customer_account.zip')}
                onChange={(event) =>
                  dispatch({
                    value: event.target.value,
                    field: 'zip_code',
                    type: 'CHANGE_FIELD'
                  })
                }
              />
              <FormInput
                label={t('authn:customer_account.phone_number')}
                name="phone_number"
                testId="set-up-account-phone-number-form"
                disabled={submitted}
                inputType="text"
                value={state.phone_number}
                placeholder={t(
                  'authn:account_details.phone_number_placeholder',
                  {
                    company: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  }
                )}
                onChange={(event) =>
                  dispatch({
                    value: event.target.value,
                    field: 'phone_number',
                    type: 'CHANGE_FIELD'
                  })
                }
              />

              <Box>
                <FormInput
                  label={t('authn:account_details.email_lbl')}
                  name="email"
                  validate={(value) => {
                    return validateEmail(value)
                  }}
                  testId="set-up-account-email-form"
                  disabled={submitted}
                  inputType="text"
                  value={state.email}
                  placeholder={t('authn:account_details.email_address_opt_lbl')}
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'email',
                      type: 'CHANGE_FIELD'
                    })
                  }
                />
              </Box>
              <Box>
                <FormField
                  margin={{ bottom: 'medium' }}
                  name="legal_terms"
                  required
                  data-testid="set-up-account-legal-terms-form-field"
                  disabled={submitted}
                >
                  <CheckBox
                    pad="none"
                    data-testid="set-up-account-legal-terms-checkbox"
                    name="legal_terms"
                    checked={state.legal_terms}
                    label={
                      <Typography
                        type="text"
                        size="medium"
                        testId="setup-company-terms"
                      >
                        <Trans
                          i18nKey="authn:acct_onboarding.check_legal_terms"
                          t={t}
                        >
                          <Anchor
                            label={t(
                              'authn:acct_onboarding.legal_terms_anchor'
                            )}
                            href="https://www.hpe.com/us/en/about/legal/ccs-terms.html"
                            target="_blank"
                          />
                        </Trans>
                      </Typography>
                    }
                    onChange={(event) =>
                      dispatch({
                        value: event.target.checked,
                        field: 'legal_terms',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    disabled={submitted}
                  />
                </FormField>
              </Box>
            </>
          </CCSForm>
        </Box>
      </Box>
      {submitted && (
        <Loader
          modal
          testId="loader-modal"
          size="xxsmall"
          modalTitle={t('common:launching')}
          modalSubTitle={t('common:loader_dialog_message')}
          modalTimeout={1000}
        />
      )}
    </>
  )
}

const SetUpAccountPage = () => {
  return <SetUpAccountForm />
}

export default SetUpAccountPage
