// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState, useEffect, useCallback } from 'react'
import { Anchor, Box, Heading, PageContent, PageHeader } from 'grommet'
import { Cycle } from 'grommet-icons'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  Button,
  Typography,
  NoDataInfo,
  Loader
} from '../../../../../components'
import { get } from '../../../../../utils/api-utils'
import {
  ProgressModal,
  SubscriptionStatsCard
} from '../../../../../commoncomponents/device-management'
import ExportModal from '../../../../../commoncomponents/export-csv/ExportModal'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import { CS_INVOICING_MODEL } from '../../constants'
import { isServiceCentric } from '../../../../../utils/account-utils'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import {
  getDateByMonthAbvDayYear,
  getSubscriptionTiers
} from '../../../../../utils/dm-sm-common-utils'
import { SecondStepProgressModal } from '../../../../device-management/components/SecondStepProgressModel'
import { isGLOP } from '../../../../../utils/feature-flag-utils'
import ImportResourcesLayer from '../../../../../dashboard/components/ImportResourcesLayer'
import {
  AUDIT_LOG_CATEGORY,
  getPaginationShowIdx
} from '../../../../../utils/common-utils'
import { getExportCSVColumns } from '../licenses/utils'
import { handleExport } from '../../utils'

import {
  ActivationModal,
  AddServiceSubscription,
  DataTable,
  DuplicateOfferingWarning
} from './components'

const ServiceSubscriptions = () => {
  const [serviceSubs, setServiceSubs] = useState([])
  const [appData, setAppData] = useState()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['device', 'licensing', 'common'])
  const navigate = useNavigate()
  const [showAddSubscriptionModal, setAddSubscriptionModal] = useState(false)
  const [showImportResourcesLayer, setShowImportResourcesLayer] =
    useState(false)
  const [
    openDuplicateOfferingWarningModal,
    setOpenDuplicateOfferingWarningModal
  ] = useState(false)
  const [openActivationModal, setOpenActivationModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const {
    'glcp-subscriptions-expiry-sorting': subscriptionExpirationSortLD,
    'glcp-subscrption-tags': subscriptionTagsFlag,
    'glcp-dm-uxi': dmUXIFlag,
    'glcp-dm-pce': dmPCEFlag,
    'glcp-dm-silver-peak': dmSilverPeakFlag,
    'glcp-service-subscription-tiers': serviceSubscriptionTiersLD,
    'glcp-reportfw-dashboard': reportingDashboardFlag,
    'glcp-subscription-expiry': subscriptionExpirationLD
  } = useFlags()

  // To store filter option under service subscription datatable
  const [filterOptions, setFilterOptions] = useState({
    ...(location?.state?.filterOptions || []),
    ...(subscriptionExpirationLD
      ? { subscription_visibility: ['UNEXPIRED_ONLY'] }
      : {})
  })
  const [isLoading, setIsLoading] = useState(true)
  const [successMessage, setSuccessMessage] = useState(null)
  const [selectedKey, setSelectedKey] = useState('')
  const [addLicenseSuccessModalOpen, setAddLicenseSuccessModalOpen] = useState(
    useLocation()?.state?.addSubsrciptionSucccess
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [showNotification, setNotification] = useState(null) // to display success notifications

  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [sortProps, setSortProps] = useState({
    property: 'expiration',
    direction: 'asc',
    external: true
  })
  // No. of subscriptions wrt current Search/filter
  const [totalSubscriptions, setTotalSubscriptions] = useState(50)
  const [errorMessage, setErrorMessage] = useState(null)
  const [subscriptionTierList, setSubscriptionTierList] = useState([])
  const [openProgressModal, setOpenProgressModal] = useState(false)
  const [refreshDataTable, setRefreshDataTable] = useState(false)
  const [showInitCount, setInitCount] = useState(0)
  // No of subscriptions in the User Workspace irrespective of Search/filter
  const [totalSubscriptionsCount, setTotalSubscriptionsCount] = useState(0)
  // To refresh count when adding a new subscription
  const [pullRefresh, setPullRefresh] = useState(true)

  const getServiceSubType = (prodAttributes, subscriptionType) => {
    const subType = prodAttributes?.find(
      (attr) => attr?.name === CS_INVOICING_MODEL
    )?.value_display
    return subType || subscriptionType
  }

  const [tabName, setTabName] = useState(
    location?.state?.activeTab || 'totalSubscriptions'
  )

  const formattable = new Set([
    'activation_date',
    'cancellation_date',
    'reactivation_date',
    'subscription_end',
    'subscription_start',
    'suspension_date'
  ])

  const serviceOnClickReport = handleExport(
    'SERVICE',
    filterOptions,
    setLoading,
    sortProps,
    searchTerm,
    reportingDashboardFlag,
    oidcUser,
    setNotification,
    setShowExportModal,
    t
  )

  // state to hold sort by expiration date
  const formatValue = (key, value) => {
    if (formattable.has(key)) {
      return dayjs(value).format('MMMM D, YYYY')
    }
    return value
  }

  const getFilterOptions = () => {
    let filterQueryParams = {}
    if (Object.keys(filterOptions).length) {
      Object.entries(filterOptions).forEach((val) => {
        if (val[0] === 'subscription_visibility') {
          switch (val[1]?.toString()) {
            case 'UNEXPIRED_ONLY':
              filterQueryParams.expire_date_cut_off_in_millis =
                dayjs().valueOf()
              break
            case 'EXPIRED_ONLY':
              if (!filterOptions?.end_date_in_millis?.toString()) {
                filterQueryParams.expire_date_cut_off_in_millis = 1
                filterQueryParams.end_date_in_millis = dayjs().valueOf()
              }
              break
            default:
              break
          }
        } else if (val[0] === 'end_date_in_millis') {
          filterQueryParams[val[0]] =
            dayjs().valueOf() + val[1]?.toString() * 24 * 60 * 60 * 1000 // current timestamp + days in milliseconds 30*24*60*60*1000
        } else if (val[0] === 'tags') {
          let constructedval = ''
          val[1]?.forEach((tag) => {
            const tagPair = tag?.value.split(',')
            constructedval = `${constructedval}'${tagPair[0]}' eq '${tagPair[1]}' or `
          })
          // remove the last or
          filterQueryParams['filter-tags'] = `${constructedval.slice(0, -4)}`
        } else if (val[0] === 'subscription_tier') {
          let constructedval = ''
          val[1]?.forEach((tier) => {
            constructedval = `${constructedval}${tier.value},`
          })
          // remove the last comma
          filterQueryParams.subscription_tier = `${constructedval.slice(0, -1)}`
        } else if (val[0] === 'evaluation_type') {
          filterQueryParams = {
            ...filterQueryParams,
            evaluation_type: val[1][0]
          }
        } else filterQueryParams = { ...filterQueryParams, [val[0]]: val[1] }
      })
    }
    return filterQueryParams
  }

  const fetchServiceSubscriptionsCount = useCallback(() => {
    get(
      '/ui-doorway/ui/v1/license/service-subscriptions?product_type=SERVICE&product_type=ONPREM_SERVICE',
      {
        limit: 1,
        offset: 0
      },
      oidcUser.access_token
    ).then(
      (resp) => {
        setTotalSubscriptionsCount(resp?.data?.pagination?.total_count)
      },
      (error) => {
        setErrorMessage(error)
      }
    )
  }, [oidcUser.access_token])

  // TODO: Replace API endpoint and method
  const fetchServiceSubscriptions = useCallback(
    (isCurrent) => {
      const url = `/ui-doorway/ui/v1/license/service-subscriptions?product_type=SERVICE&product_type=ONPREM_SERVICE`
      get(
        url,
        {
          limit: itemsPerPage,
          offset: (page - 1) * itemsPerPage,
          ...(searchTerm?.length && {
            subscription_key_pattern: encodeURIComponent(searchTerm)
          }),
          ...((subscriptionExpirationSortLD || isGLOP()) && {
            sort_by: sortProps.property,
            direction: sortProps.direction
          }),
          ...getFilterOptions()
        },
        oidcUser.access_token
      )
        .then(
          (resp) => {
            const { pagination, ...rest } = resp.data
            let { subscriptions: subs } = resp.data
            subs = subs.map((sub) => {
              sub.appointments = Object.keys(sub.appointments).reduce(
                (obj, curr) => {
                  obj[curr] = formatValue(curr, sub.appointments[curr])
                  return obj
                },
                {}
              )
              sub.subscription_type = getServiceSubType(
                sub.product_attributes,
                sub.subscription_type
              )
              sub.expiration = getDateByMonthAbvDayYear(
                sub?.appointments?.subscription_end
              )
              return sub
            })
            if (!isCurrent) return
            setAppData(rest)
            if (subs.length) setInitCount(showInitCount + 1)
            setServiceSubs(subs)
            setTotalSubscriptions(pagination.total_count)
            setIsLoading(false)
          },
          (error) => {
            setServiceSubs([])
            setTotalSubscriptions(0)
            setErrorMessage(error)
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      oidcUser.access_token,
      page,
      searchTerm,
      subscriptionExpirationSortLD,
      filterOptions,
      sortProps.property,
      sortProps.direction,
      refreshDataTable
    ]
  )

  // To get total subscription count to display export total count
  useEffect(() => {
    fetchServiceSubscriptionsCount()
  }, [fetchServiceSubscriptionsCount])

  useEffect(() => {
    let isCurrent = true
    fetchServiceSubscriptions(isCurrent)
    return () => {
      isCurrent = false
    }
  }, [fetchServiceSubscriptions])

  useEffect(() => {
    let isCurrent = true
    getSubscriptionTiers({ LDFlags, oidcUser }).then(
      (response) => {
        const filteredSubscriptionTierList = response.filter(
          (tier) =>
            serviceSubscriptionTiersLD?.serviceSubscriptionTiers?.includes(
              tier?.valueName
            ) || isGLOP()
        )
        if (!isCurrent) return
        setSubscriptionTierList(filteredSubscriptionTierList || [])
      },
      (error) => {
        setErrorMessage(error)
      }
    )
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, dmSilverPeakFlag, dmPCEFlag, dmUXIFlag]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitSuccess = () => {
    fetchServiceSubscriptions()
    setOpenProgressModal(true)
    setPullRefresh(!pullRefresh)
  }

  const onDuplicateOffering = (val) => {
    setSelectedKey(val)
    setOpenDuplicateOfferingWarningModal(true)
  }

  const onDelayedActivation = (val) => {
    setSelectedKey(val)
    setOpenActivationModal(true)
  }

  const getAddServiceSubscriptionBtn = (isEmpty) => {
    if (isGLOP()) {
      return (
        <Button
          label={t('import_resources_btn_lbl')}
          primary
          testId="import-device-subscription-btn"
          style={{ flexShrink: 0, whiteSpace: 'nowrap' }}
          onClick={() => {
            setShowImportResourcesLayer(true)
          }}
        />
      )
    }
    return (
      <Button
        label={t('add_service_subscription')}
        primary={!(newDashboardFlag && isEmpty)}
        secondary={!!(newDashboardFlag && isEmpty)}
        testId="add-service-subscription-btn"
        style={{ whiteSpace: 'nowrap' }}
        onClick={() => {
          if (subscriptionTagsFlag && newDashboardFlag) {
            navigate('/services/add-service-subscriptions')
          } else {
            setAddSubscriptionModal(true)
          }
        }}
      />
    )
  }
  const pageIdxInfo = getPaginationShowIdx(
    page,
    totalSubscriptions,
    itemsPerPage,
    t
  )

  const getServiceSubscriptions = () => {
    if (!showInitCount && totalSubscriptionsCount === 0) {
      return (
        <Box gap="small" margin={{ top: 'large', horizontal: 'auto' }}>
          <NoDataInfo
            icon={<Cycle color="text" size="large" />}
            title={t('add_service_subscriptions_desc')}
            action={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/device-management',
                  permission: 'ccs.device-management.edit'
                }}
              >
                {getAddServiceSubscriptionBtn(showInitCount)}
              </VisibilityWrapper>
            }
            testId="no-data-info-service-subscriptions"
          />
        </Box>
      )
    }
    return (
      <Box gap="medium">
        {!isGLOP() && (
          <SubscriptionStatsCard
            productType="SERVICE"
            tabName={tabName}
            setTabName={setTabName}
            setFilterOptions={setFilterOptions}
            pullRefresh={pullRefresh}
          />
        )}
        <DataTable
          serviceSubs={serviceSubs}
          appData={appData}
          pagination={{
            totalItems: totalSubscriptions,
            totalCount: totalSubscriptionsCount,
            itemsPerPage,
            page,
            setPage,
            pageIdxInfo,
            ...(isGLOP() && {
              rowDropDownLabel: t('common:row_drop_down_label'),
              defaultRowsValue: 20,
              setItemsPerPage: (numOfRows) => {
                setItemsPerPage(numOfRows)
                setRefreshDataTable(!refreshDataTable)
              }
            })
          }}
          setRefreshDataTable={setRefreshDataTable}
          refreshDataTable={refreshDataTable}
          setSearchTerm={setSearchTerm}
          setPage={setPage}
          tabName={tabName}
          setShowExportModal={setShowExportModal}
          sortProps={sortProps}
          setSortProps={setSortProps}
          setNotification={setNotification}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          subscriptionTierList={subscriptionTierList}
        />
      </Box>
    )
  }

  return (
    <PageContent>
      <PageHeader
        data-testid="service-subscriptions-page-header"
        responsive
        pad={{ top: 'none', bottom: 'medium' }}
        title={
          <Heading level="2" margin="0px">
            {t('service_subscriptions')}
          </Heading>
        }
        subtitle={
          <Typography type="text">
            {newDashboardFlag || isGLOP() ? (
              <Trans i18nKey="service_subscriptions_subheading" t={t}>
                <Anchor
                  label={t('here')}
                  style={{
                    whiteSpace: 'wrap',
                    textDecoration: isGLOP() ? 'none' : 'underline'
                  }}
                  color={isGLOP() ? 'brand' : undefined}
                  onClick={() => {
                    navigate('/devices/subscriptions')
                  }}
                />
              </Trans>
            ) : (
              t('service_subscriptions_subtitle')
            )}
          </Typography>
        }
        actions={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/device-management',
              permission: 'ccs.device-management.edit'
            }}
          >
            {!!totalSubscriptionsCount &&
              getAddServiceSubscriptionBtn(totalSubscriptionsCount)}
          </VisibilityWrapper>
        }
      />
      <Box>
        {successMessage &&
          displayNotification(
            successMessage,
            'info',
            setSuccessMessage,
            null,
            true
          )}
        {isLoading ? (
          <Box direction="row" align="center" justify="center">
            <Loader testId="service-subscription-loader" />
          </Box>
        ) : (
          getServiceSubscriptions()
        )}
      </Box>
      {showImportResourcesLayer && (
        <Box>
          <ImportResourcesLayer
            handleClose={() => {
              setShowImportResourcesLayer(false)
            }}
            onSuccess={() => {
              fetchServiceSubscriptionsCount()
              fetchServiceSubscriptions(true)
              setSuccessMessage(
                t('dashboard:dashboard.import_resources.upload_success')
              )
            }}
          />
        </Box>
      )}
      {showExportModal && (
        <ExportModal
          setShowModal={setShowExportModal}
          title={t('licensing:export.service_subscription_title')}
          description={t('licensing:export.description')}
          columns={{
            header: t('licensing:export.service_subscription_header'),
            data: getExportCSVColumns(t)
          }}
          includedEntries={{
            all_entries_label: t(
              'licensing:export.entire_subscription_inventory',
              {
                count: totalSubscriptionsCount
              }
            ),
            searched_entries_label: t(
              'licensing:export.current_search_results',
              {
                count: totalSubscriptions
              }
            )
          }}
          filteredDevices={totalSubscriptions}
          onClickGenerateReport={serviceOnClickReport}
          loading={loading}
          setLoading={(val) => {
            setLoading(val)
          }}
          checkProgressReports={false}
        />
      )}
      {showNotification}
      {showAddSubscriptionModal && (
        <AddServiceSubscription
          onSetOpen={setAddSubscriptionModal}
          onSubmitSuccess={onSubmitSuccess}
          onDuplicateOffering={onDuplicateOffering}
          onDelayedActivation={onDelayedActivation}
        />
      )}
      {openDuplicateOfferingWarningModal && (
        <DuplicateOfferingWarning
          onSetOpen={setOpenDuplicateOfferingWarningModal}
          setErrorMessage={setErrorMessage}
          selectedKey={selectedKey}
          onDelayedActivation={onDelayedActivation}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
      {openActivationModal && (
        <ActivationModal
          onSetOpen={setOpenActivationModal}
          setErrorMessage={setErrorMessage}
          selectedKey={selectedKey}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
      {openProgressModal && (
        <ProgressModal
          onCloseModal={() => setOpenProgressModal(false)}
          onSetOpen={setOpenProgressModal}
          title={t('add_subscription_keys_title')}
          subtitle={t('licensing:add_subscription_progress_subtitle')}
          auditLogCategory={AUDIT_LOG_CATEGORY.SUBSCRIPTION_MANAGEMENT}
        />
      )}
      {addLicenseSuccessModalOpen && (
        <SecondStepProgressModal
          onSetOpen={setAddLicenseSuccessModalOpen}
          title={t('add_subscription_keys_title')}
          onCloseModal={() => {
            setAddLicenseSuccessModalOpen(false)
            navigate('/services/service-subscriptions')
          }}
          subtitle={t('licensing:add_subscription_progress_subtitle')}
        />
      )}
      {errorMessage && displayApiError(errorMessage, t, setErrorMessage)}
    </PageContent>
  )
}

export { ServiceSubscriptions }
