// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Select } from 'grommet'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const CreateSelect = ({
  allOptions,
  onValueChange,
  onCreate,
  onSearch = undefined,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(allOptions)
  const { t } = useTranslation(['device'])
  const { 'glcp-subscrption-tags': iSubscriptionTagFlag } = useFlags()

  const memoizedCreatePrefix = useCallback(
    (text) => {
      if (iSubscriptionTagFlag) {
        return {
          value: t('tags.create_action', {
            tag_text: text ? `'${text}'` : ''
          })
        }
      }
      return {
        value: t('tags.create_action', {
          tag_text: text
        })
      }
    },
    [t, iSubscriptionTagFlag]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((text) => {
      onSearch(text)
    }, 500),
    []
  )

  const updateCreateOption = (textValue, options) => {
    const tmpFilteredOptions = [...options]
    const text = textValue.trim()
    if (
      tmpFilteredOptions.length > 0 &&
      tmpFilteredOptions[options.length - 1].value &&
      tmpFilteredOptions[options.length - 1].value.includes(
        memoizedCreatePrefix('').value
      )
    ) {
      // remove Create option before adding an updated one
      tmpFilteredOptions.pop()
    }

    if (!text || text.length === 0) {
      setFilteredOptions(tmpFilteredOptions)
      return
    }

    const found = tmpFilteredOptions.find(
      (val) => val.value?.trim() === text?.trim()
    )

    if (!found) {
      tmpFilteredOptions.unshift(memoizedCreatePrefix(text))
    }
    setFilteredOptions(tmpFilteredOptions)
  }

  useEffect(() => {
    setFilteredOptions(allOptions)
    updateCreateOption(searchValue, allOptions)
  }, [allOptions, searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!onSearch) {
      let options = []
      if (!searchValue || searchValue.trim().length === 0) {
        options = allOptions
      } else {
        options = allOptions.filter(
          (o) =>
            o.value
              ?.toLowerCase()
              ?.trim()
              ?.indexOf(searchValue.toLocaleLowerCase().trim()) !== -1
        )
      }
      updateCreateOption(searchValue, options)
    }
  }, [searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      {...rest}
      labelKey="value"
      options={filteredOptions}
      onChange={({ option }) => {
        if (
          option &&
          option.value &&
          option.value.includes(memoizedCreatePrefix('').value)
        ) {
          // create was selected
          filteredOptions.pop() // remove Create option
          const newOption = { value: searchValue, persisted: false }
          filteredOptions.push(newOption)
          onCreate(newOption)
        } else {
          onValueChange(option)
        }
        setSearchValue('')
      }}
      onOpen={() => {
        const newValue = searchValue
        setSearchValue(newValue)
      }}
      onSearch={(text) => {
        if (
          rest?.isSubscriptionTagsModal &&
          rest?.tagNameMaxLength <= text?.length
        ) {
          setSearchValue(text.substring(0, rest.tagNameMaxLength))
        } else {
          setSearchValue(text)
        }
        if (onSearch) {
          handleDebouncedSearchValue(text)
        }
      }}
      multiple={false}
      focusIndicator
    />
  )
}

CreateSelect.propTypes = {
  allOptions: PropTypes.array.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onSearch: PropTypes.func
}
