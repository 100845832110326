// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { FormCheckmark } from 'grommet-icons'

import { Notification } from '../notification/Notification'
import { Typography } from '../typography/Typography'

const Step = ({ step, number, isActive, statusColor }) => {
  let border = {
    color: statusColor.default,
    size: '3px',
    side: 'left'
  }

  if (isActive || step.status === 'in_progress') {
    border = {
      color: statusColor.active,
      size: '3px',
      side: 'left'
    }
  } else if (step.status === 'failed') {
    border = {
      color: statusColor.failed,
      size: '3px',
      side: 'left'
    }
  }

  return (
    <Box direction="row" margin={{ bottom: 'medium' }}>
      <Box
        margin={step.status !== 'complete' ? { left: 'xsmall' } : {}}
        width={step.status !== 'complete' ? '36px' : '42px'}
        border={border}
        flex={false}
      >
        {step.status === 'complete' ? (
          <FormCheckmark size="large" color={statusColor.active} />
        ) : null}
      </Box>
      <Box
        direction="column"
        gap="xsmall"
        align="start"
        data-testid={`step${number}`}
      >
        <Typography size="small" type="text" testId="step">
          <>STEP {number}</>
        </Typography>
        <Typography level={3} type="heading" testId="title">
          {step.title}
        </Typography>
        <Typography size="small" type="text" testId="description">
          {step.description}
        </Typography>
        {isActive ? (
          <Box direction="row" pad={{ top: 'medium' }}>
            {step.button}
          </Box>
        ) : null}
        {step.status === 'in_progress' ? step.progress : null}
        {step.status === 'failed' ? (
          <Notification
            type="inline"
            text={step.error}
            backgroundColor="status-critical"
            testId="err-inline-notification"
          />
        ) : null}
      </Box>
    </Box>
  )
}

const stepPropTypes = {
  name: PropTypes.string,
  status: PropTypes.oneOf([
    'not_started',
    'in_progress',
    'failed',
    'complete',
    'unknown'
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  button: PropTypes.element,
  progress: PropTypes.element,
  error: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
}

Step.propTypes = {
  step: PropTypes.shape(stepPropTypes).isRequired,
  number: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  statusColor: PropTypes.shape({
    default: PropTypes.string,
    active: PropTypes.string,
    failed: PropTypes.string
  }).isRequired
}

export const StepNavigation = ({ steps, statusColor = undefined, testId }) => {
  const firstNotStartedStepIndex = steps.findIndex((step) => {
    return step.status === 'not_started'
  })

  const inProgressStepIndex = steps.findIndex((step) => {
    return step.status === 'in_progress'
  })

  const failedStepIndex = steps.findIndex((step) => {
    return step.status === 'failed'
  })

  const color = {
    default: statusColor?.default || 'transparent',
    active: statusColor?.active || 'status-ok',
    failed: statusColor?.failed || 'status-critical'
  }

  const stepEls = steps.map((step, idx) => {
    const isActive =
      firstNotStartedStepIndex === idx &&
      !(inProgressStepIndex !== -1) &&
      !(failedStepIndex !== -1)

    return (
      <Step
        step={step}
        number={idx + 1}
        isActive={isActive}
        key={step.name || idx}
        statusColor={color}
      />
    ) // eslint-disable-line react/no-array-index-key
  })

  return (
    <Box direction="column" data-testid={testId}>
      {stepEls}
    </Box>
  )
}

StepNavigation.propTypes = {
  /**
   * array of step objects
   */
  steps: PropTypes.arrayOf(PropTypes.shape(stepPropTypes)).isRequired,

  /**
   * An object containing color codes for different step statuses (default, active, failed).
   */
  statusColor: PropTypes.shape({
    default: PropTypes.string,
    active: PropTypes.string,
    failed: PropTypes.string
  }),

  /**
   * data-testid, will be used for testing
   */
  testId: PropTypes.string.isRequired
}
