// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Markdown, InfiniteScroll } from 'grommet'
import PropTypes from 'prop-types'

import { getprimaryField, getActionField, getRedirectPath } from '../utils'
import { Typography, Loader } from '../../../../components'

const DefaultComponent = ({
  content,
  handleActionClick,
  renderHint,
  setOpenDropdown,
  onMoreHandler,
  loadingPagination
}) => {
  const primaryField = getprimaryField(renderHint?.renderingHints)
  const actionField = getActionField(renderHint?.renderingHints)
  const { historyObject = {} } = document.querySelector('greenlake-header')
  return (
    <Box direction="column" gap="small">
      <InfiniteScroll
        items={content?.results}
        onMore={() => onMoreHandler(content?.pagination, content?.category)}
      >
        {(item, index) => (
          <Box
            pad={{ horizontal: 'xsmall', vertical: 'xsmall' }}
            direction="column"
            align="start"
            alignContent="start"
            hoverIndicator
            focusIndicator={false}
            onClick={() => {
              const path = getRedirectPath(actionField, item)
              if (item.user_status === 'UNVERIFIED') {
                historyObject.push('/manage-account/identity/users', {
                  state: {
                    search_string: item.email
                  }
                })
              } else {
                historyObject.push(path)
              }
              handleActionClick(item[primaryField], content?.category)
              setOpenDropdown(false)
            }}
          >
            <Typography
              type="text"
              testId={`gs-default-title-${index}`}
              emphasis
            >
              {item[primaryField]}
            </Typography>

            {item?.highlight && (
              <Typography
                type="text"
                size="small"
                testId={`gs-default-desc-${index}`}
              >
                <Markdown type="text" testId={`gs-default-desc-${index}`}>
                  {item?.highlight[Object.keys(item?.highlight)[0]]?.[0]}
                </Markdown>
              </Typography>
            )}
          </Box>
        )}
      </InfiniteScroll>
      {loadingPagination && (
        <Box align="center">
          <Loader testId="gs-refresh-onMore" />
        </Box>
      )}
    </Box>
  )
}

DefaultComponent.propTypes = {
  content: PropTypes.any.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  renderHint: PropTypes.any.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  onMoreHandler: PropTypes.func.isRequired,
  loadingPagination: PropTypes.bool.isRequired
}

export { DefaultComponent }
