// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Button as GrommetButton, Box } from 'grommet'

import { Loader } from '../loader/Loader'

export const Button = ({
  type = 'button',
  label = '',
  testId,
  isLoading = false,
  ...rest
}) => {
  return isLoading ? (
    <Box direction="row" gap="small" flex={{ shrink: 0 }}>
      <Loader size="24px" testId={`${testId}-loader`} />
      <GrommetButton
        type={type}
        label={label}
        data-testid={testId}
        disabled
        {...rest}
      />
    </Box>
  ) : (
    <GrommetButton type={type} label={label} data-testid={testId} {...rest} />
  )
}

Button.propTypes = {
  /**
   * Button type. This can be either 'submit' or 'button'. Default is 'button'
   */
  type: PropTypes.string,
  /**
   * Button label. This can be a string or <Typography /> component.
   */
  label: PropTypes.node,
  /**
   * Button loading. This can be a boolean if the button cick action is loading.
   */
  isLoading: PropTypes.bool,
  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired
}
