// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { React, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

import AssetManagementDashboard from './AssetManagementDashboard'
import AssetRouter from './assets/router'
import { Workloads } from './workloads/Workloads'

const AssetManagementRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<AssetManagementDashboard />} />
        <Route exact path="/assets/*" element={<AssetRouter />} />
        <Route exact path="/workloads" element={<Workloads />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default AssetManagementRouter
