// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, NameValueList, NameValuePair } from 'grommet'
import { FormDown, Previous } from 'grommet-icons'
import { ThemeContext } from 'styled-components'
import { pick } from 'lodash'

import {
  Typography,
  Button,
  Loader,
  ActionButton
} from '../../../../components'
import { Layout } from '../../../../commoncomponents/layout/Layout'
import { get, getErrorMessage } from '../../../../utils/api-utils'
import { AccountLogo } from '../../../../commoncomponents/account-logo/AccountLogo'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  renameKeys,
  joinCityState,
  getCountryName,
  areGuidsEqual,
  removeAllHyphensFromString
} from '../../../../utils/common-utils'
import {
  getCustomerAccount,
  setCustomerAccount,
  getOrganizationId,
  isAssociateWorkspace
} from '../../../../utils/feature-flag-utils'
import { useVisibilityContext } from '../../../../context/visibility-context'
import RemoveAccountModal from '../../../manage-account/account-details/commoncomponent/RemoveAccountModal'

import { EditWorkspaceSideDrawer } from './EditWorkspaceDetailsPage'

export const getFormattedObject = (keys, obj) => {
  const filtered = pick(obj, Object.keys(keys))
  return renameKeys(keys, filtered)
}
const IgcWorkspaceDetails = () => {
  const navigate = useNavigate()
  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)
  const { workspaceId } = useParams()
  const [isAssociated, setIsAssociated] = useState(false)
  const { t } = useTranslation(['common', 'iam', 'manage', 'dashboard'])
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(true)
  const [workspaceName, setWorkspaceName] = useState()
  const [logoObject, setLogoObject] = useState({})
  const [workspaceDescription, setWorkspaceDescription] = useState()
  const openSidePanel = () => setShowSidePanel(true)
  const [workspaceDetails, setWorkspaceDetails] = useState({})
  const [orgName, setOrgName] = useState('')
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isRootOrgWorkspace, setIsRootWorkspace] = useState(false)

  const [customerData, setCustomerData] = useState({})
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const rbacDeleteOrgWkspcPerm = 'organization.workspace.delete'
  const workspaceDetailsKeys = {
    workspaceName: t('manage:org_workspace.company_name'),
    description: t('manage:org_workspace.description'),
    workspaceType: t('manage:org_workspace.type'),
    country: t('manage:org_workspace.country'),
    address: t('manage:org_workspace.address'),
    phoneNumber: t('manage:org_workspace.phone_number'),
    email: t('manage:org_workspace.email')
  }
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }
  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsRootWorkspace(
              res?.data?.associatedWorkspace?.id ===
                removeAllHyphensFromString(workspaceId)
            )
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getOrganizationName = () => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (response) => {
          setOrgName(response?.data?.name ? response?.data?.name : orgId)
        },
        (error) => {
          setOrgName(orgId)
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
  }

  const getWorkspaceDetails = useCallback(
    async (countryList) => {
      await get(`/organizations/v2alpha1/workspaces/${workspaceId}`).then(
        async (response) => {
          const responseData = response?.data
          setWorkspaceName(responseData.workspaceName)
          setWorkspaceDescription(responseData.description)
          if (responseData.logo && responseData.logo?.logoUrl !== '') {
            setLogoObject({
              platform_customer_id: workspaceId,
              customer_logo: {
                logo: responseData.logo?.logoUrl
              }
            })
          }
          let currentAccount = getCustomerAccount()
          if (
            areGuidsEqual(currentAccount?.platform_customer_id, workspaceId)
          ) {
            currentAccount = {
              ...currentAccount,
              customer_logo: {
                logo: responseData.logo?.logoUrl,
                logo_filename: responseData.logo?.logoFileName
              }
            }
            setCustomerAccount(currentAccount)
          }

          getOrganizationName()
          setCustomerData(responseData)
          const workspaceType = t(
            `dashboard:workspace_types.${responseData?.workspaceType}`
          )

          const {
            workspaceName: companyName,
            description: companyDescription,
            address: {
              countryCode,
              streetAddress,
              streetAddressComplement: streetAddress2,
              city,
              stateOrRegion: stateRegion,
              zip
            } = {},
            phoneNumber,
            email
          } = responseData || ''

          let addressList = [
            streetAddress,
            streetAddress2,
            joinCityState(city, stateRegion),
            zip
          ]

          addressList = addressList?.filter((val) => val)

          const details = {
            workspaceName: companyName,
            workspaceType,
            description: companyDescription,
            country: await getCountryName(countryCode, countryList),
            address: addressList,
            phoneNumber,
            email
          }

          setWorkspaceDetails(getFormattedObject(workspaceDetailsKeys, details))
          setLoadingDetails(false)
        },
        async (error) => {
          const backendErrorMessage = await getErrorMessage(error, t)
          setErrorMessage(backendErrorMessage)
          setLoadingDetails(false)
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, showSidePanel]
  )

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      async (response) => {
        await getWorkspaceDetails(response?.data?.countries)
      },
      async (error) => {
        // if the GET countries call fails, an empty country list will be passed.
        await getWorkspaceDetails([])
        const backendErrorMessage = await getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
        setLoadingDetails(false)
      }
    )
  }, [t, getWorkspaceDetails])

  const tableActions = () => {
    const actions = [
      {
        label: t('common:edit'),
        onClick: () => {
          openSidePanel()
        },
        testId: 'edit-workspace-btn'
      },
      {
        label: t('iam:delete_workspace.title', {
          workspace: t('common:business_object.wkspc')
        }),
        testId: 'delete-org-workspace-btn',
        hidden:
          (!isAssociated &&
            !v2RbacPolicies?.includes(rbacDeleteOrgWkspcPerm)) ||
          (isAssociated &&
            !v2RbacRootWkspcPolicies?.includes(rbacDeleteOrgWkspcPerm)),
        onClick: () => {
          setShowDeleteModal(true)
        }
      }
    ]

    return actions
  }

  return (
    <>
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
      <Box margin={{ vertical: 'medium' }} alignSelf="center" justify="center">
        <>
          {loadingDetails ? (
            <Box
              direction="row-responsive"
              align="center"
              margin={{ bottom: 'medium' }}
            >
              <Box direction="column" margin={{ left: 'small' }}>
                <Box direction="row-responsive" justify="between" gap="xlarge">
                  <Box margin={{ right: 'xlarge' }}>
                    <Loader testId="workspace-details-loader" />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              {showErrorNotification}
              {!workspaceName ? (
                <Box direction="row" margin={{ bottom: 'xsmall' }}>
                  <Button
                    default
                    pad={{ left: 'small' }}
                    label={t('common:business_object.wkspc_plural_capitalized')}
                    icon={<Previous />}
                    onClick={() => {
                      navigate('/manage-account/organization/workspaces')
                    }}
                    testId="workspace-details-manage-account-btn"
                  />
                </Box>
              ) : (
                <>
                  <Box direction="row" margin={{ bottom: 'xsmall' }}>
                    <Button
                      default
                      pad={{ left: 'small' }}
                      label={t('iam:organization.workspace_card_title', {
                        workspaces: t('common:business_object.wkspc_plural')
                      })}
                      icon={<Previous />}
                      onClick={() => {
                        navigate('/manage-account/organization/workspaces')
                      }}
                      testId="workspace-details-manage-account-btn"
                    />
                  </Box>
                  <Box
                    direction="row-responsive"
                    align="center"
                    margin={{ bottom: 'medium' }}
                  >
                    <AccountLogo
                      logoOnly
                      account={logoObject}
                      logoSize="medium"
                      avatarSize="xlarge"
                      title={workspaceName?.trim()}
                      testId="workspace-details-logo"
                    />
                    <Box direction="column" margin={{ left: 'small' }}>
                      <Box
                        direction="row-responsive"
                        justify="between"
                        gap="xlarge"
                      >
                        <Box margin={{ right: 'xlarge' }}>
                          <Typography
                            type="heading"
                            level="1"
                            testId="workspace-details-title"
                          >
                            {workspaceName}
                          </Typography>
                        </Box>
                        <Box direction="row">
                          {((isAssociated &&
                            v2RbacRootWkspcPolicies?.includes(
                              'organization.workspace.update'
                            )) ||
                            (!isAssociated &&
                              v2RbacPolicies?.includes(
                                'organization.workspace.update'
                              ))) && (
                            <>
                              <ActionButton
                                label={t('iam:domains.details.actions_btn')}
                                actions={tableActions()}
                                reverse
                                showOneActionAsDropDown
                                testId="Domain-action-btn"
                                icon={<FormDown />}
                                margin={{ left: 'large' }}
                              />
                              {showSidePanel && (
                                <EditWorkspaceSideDrawer
                                  workspaceId={workspaceId}
                                  workspaceDetails={customerData}
                                  setWorkspaceDetails={setCustomerData}
                                  setStatus={setStatus}
                                  setLoadingDetails={setLoadingDetails}
                                  onClose={(e) => setShowSidePanel(e)}
                                />
                              )}
                              {status
                                ? displayNotification(
                                    status?.message,
                                    status?.severity,
                                    setStatus,
                                    status?.title
                                  )
                                : null}
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box width="medium" margin={{ top: 'xsmall' }}>
                        <Typography
                          type="paragraph"
                          testId="workspace-details-subtitle"
                        >
                          {workspaceDescription}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box margin={{ top: 'medium' }}>
                    <Box margin={{ top: 'medium' }}>
                      <NameValueList
                        data-testid="workspace-details-list"
                        layout
                      >
                        {Object?.entries(workspaceDetails)?.map((datum) => (
                          <NameValuePair
                            name={
                              <Typography
                                testId={`${datum[0].toLowerCase()}-key`}
                                type="text"
                                {...name}
                              >
                                {datum[0]}
                              </Typography>
                            }
                            key={datum[0]}
                          >
                            {datum[0] === workspaceDetailsKeys?.address &&
                            datum[1]?.length ? (
                              datum[1]?.map((data, idx) => (
                                <Box direction="column">
                                  <Typography
                                    testId={`${datum[0].toLowerCase()}-${idx}-value`}
                                    type="text"
                                  >
                                    {data}
                                  </Typography>
                                </Box>
                              ))
                            ) : (
                              <Typography
                                testId={`${datum[0].toLowerCase()}-value`}
                                type="text"
                              >
                                {datum[1]?.length ? datum[1] : '--'}
                              </Typography>
                            )}
                          </NameValuePair>
                        ))}
                        <NameValuePair
                          name={
                            <Typography
                              testId="workspace-details-organization-key"
                              type="text"
                              {...name}
                            >
                              {t('common:business_object.organization')}
                            </Typography>
                          }
                        >
                          <Typography
                            testId="workspace-details-organization-value"
                            type="text"
                          >
                            {orgName}
                          </Typography>
                        </NameValuePair>
                      </NameValueList>
                    </Box>
                  </Box>
                </>
              )}
              {errorMessage &&
                displayNotification(errorMessage, 'error', setErrorMessage)}
            </>
          )}
        </>
      </Box>
      {showDeleteModal && (
        <RemoveAccountModal
          onSetOpen={setShowDeleteModal}
          accountToRemove={{
            company_name: workspaceName,
            platform_customer_id: workspaceId
          }}
          orgName={orgName}
          setNotificationInfo={setNotificationInfo}
          isOrgDelete
          isRootOrgWorkspace={isRootOrgWorkspace}
        />
      )}
    </>
  )
}

const IgcWorkspaceDetailsPage = () => {
  return (
    <Layout>
      <IgcWorkspaceDetails align="center" justify="center" />
    </Layout>
  )
}

export default IgcWorkspaceDetailsPage
