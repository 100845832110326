// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import Webhooks from './Webhooks'

const WebhookRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Webhooks />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export default WebhookRouter
