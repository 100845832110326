// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Trans } from 'react-i18next'

import { Anchor, Typography } from '../../../components'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'

const getNameAndType = (scopeResource, scopesData) => {
  let name = ''
  const scopeInstance = scopesData?.find(
    (scopeData) =>
      scopeData?.bundledData?.application_instance_id ===
      scopeResource?.application_instance_id
  )
  const matchedChild = scopeInstance?.bundledData?.children?.find((child) => {
    const foundPf = child?.predefined_filters?.find((pf) => {
      return pf.slug === scopeResource.slug
    })
    if (foundPf) {
      name = foundPf?.name
      return true
    }
    const foundSri = child?.scope_resource_instances?.find((sri) => {
      return sri.slug === scopeResource.slug
    })
    if (foundSri) {
      name = foundSri?.name
      return true
    }
    return false
  })
  const matchedType = scopeInstance?.bundledData?.scope_resources?.find(
    (sr) => sr?.slug === matchedChild?.scope
  )
  if (name === '') {
    return {}
  }
  return {
    name,
    scope_type_name: matchedType?.name || '',
    scope_type_slug: matchedType?.slug,
    type: matchedType?.slug
  }
}

const getUpdatedScopeMapFromScopesResponse = (
  onModalScopesMap,
  scopeResources,
  scopeTypeSlug,
  scopeTypeName
) => {
  onModalScopesMap.forEach((value, key) => {
    const slug = key.split('~')[1]
    if (
      !value?.name ||
      value?.name === 'generated_via_migration' ||
      value?.name === slug ||
      !value?.scope_type_name ||
      value?.scope_type_name === ''
    ) {
      const matchedScope = scopeResources.find((val) => val.slug === slug)
      if (matchedScope)
        onModalScopesMap.set(key, {
          ...value,
          ...{
            name: matchedScope?.name,
            scope_type_slug: scopeTypeSlug,
            scope_type_name: scopeTypeName,
            type: scopeTypeSlug
          }
        })
    }
  })
  return onModalScopesMap
}

const getSelectedScopeMap = (scopeResources = [], scopesData) => {
  const scopeMap = new Map()
  scopeResources.forEach((scopeResource) => {
    let foundScopeItem = {}
    const { application_instance_id: applicationInstanceId, slug } =
      scopeResource
    if (
      !scopeResource.name ||
      scopeResource?.name === 'generated_via_migration' ||
      scopeResource?.name === slug ||
      !scopeResource?.scope_type_name ||
      scopeResource?.scope_type_name === '' ||
      (scopeResource?.name === '' && scopesData)
    ) {
      foundScopeItem = getNameAndType(scopeResource, scopesData)
    }
    const key = `${applicationInstanceId}~${slug}`
    scopeMap.set(key, { ...scopeResource, ...foundScopeItem })
  })
  return scopeMap
}

const getEditRRPSuccessText = (appName, t) => {
  return (
    <Trans
      i18nKey="authz:rrp.edit_rrp_success_notification_text"
      t={t}
      values={{
        appName
      }}
      components={[<strong />]}
    />
  )
}

const mapRoleAssignments = (roleArray, scopeArray, roleAssignmentList) => {
  const roleAssignments = []
  roleArray?.forEach((role) => {
    scopeArray?.forEach((scope) => {
      if (
        !roleAssignmentList?.find(
          (assignment) =>
            assignment.role_id === role.role_id && assignment.scope === scope
        )
      ) {
        const primaryKey = role?.role_id?.concat(scope)
        roleAssignments?.push({
          primaryKey,
          role_id: role?.role_id,
          role_name: role?.role_name,
          role_display_name: role?.role_display_name,
          role_grn: role?.role_grn,
          service_id: role?.service_id,
          service_name: role?.service_name,
          custom_role_service_id: role?.custom_role_service_id,
          custom_role_service_name: role?.custom_role_service_name,
          managedBy: role?.managedBy,
          scope
        })
      }
    })
  })
  return roleAssignments
}

const replaceEndWith = (original, target, replacement) => {
  if (!original) return ''
  const lastIndex = original.lastIndexOf(target)
  return lastIndex === -1
    ? original
    : original.substring(0, lastIndex) + replacement
}

const getResourceTypeName = (resType) => {
  // name attribute from UID, maps to BE displayName or '' if no display name was provided
  // display name is currently optional; use slug if not provided
  return resType?.name || replaceEndWith(resType?.slug, '/{uuid}', '')
}

const getAllInstancesOfResourceTypeSlug = (resourceType) => {
  return replaceEndWith(resourceType?.value?.slug, '{uuid}', '*')
}

const filterScopelessResourceTypes = (resourceTypes) => {
  const resTypeOptions = []
  resourceTypes?.forEach((resType) => {
    // filter scopeless resource types, ie ones which don't end with '{uuid}'
    if (resType?.slug?.endsWith('{uuid}'))
      resTypeOptions.push({
        label: getResourceTypeName(resType),
        value: resType
      })
  })
  return resTypeOptions
}

const getScopeInfo = (datum, navigate, t) => {
  let scopeInfo = ''
  if (datum?.scopeGroupName) {
    scopeInfo = (
      <>
        <Anchor
          href="#"
          label={datum?.scopeGroupName}
          testId="assignment-anchor"
          onClick={(event) => {
            event.preventDefault()
            navigate(
              `/manage-account/identity/scopegroups/${datum?.scopeGroupId}`
            )
          }}
        />
        <Typography type="text">{datum?.scope}</Typography>
      </>
    )
  } else if (datum?.scope) {
    const entireWorkspaceGrn = `grn:glp/workspaces/${
      getCustomerAccount()?.platform_customer_id
    }`
    if (datum?.scope === entireWorkspaceGrn) {
      scopeInfo = (
        <>
          <Typography type="text" emphasis>
            {t('iam:assign_roles_side_panel.access_all_workspace_label', {
              workspace: t('common:business_object.wkspc')
            })}
          </Typography>
          <Typography type="text">{datum?.scope}</Typography>
        </>
      )
    } else {
      scopeInfo = datum?.scope
    }
  }
  return scopeInfo
}

const authzSAMLSSOMode = [
  'AUTHORIZATION',
  'AUTHORIZATION_MSP_MANAGED',
  'AUTHORIZATION_MSP_MANAGED_TENANT_ONLY'
]

const isSAMLAuthzUser = (userInfo) => {
  return (
    authzSAMLSSOMode?.includes(userInfo?.ssoMode) ||
    authzSAMLSSOMode.includes(userInfo?.sso_mode)
  )
}

const mspDefaultRRPId = '11111111-1111-1111-1111-111111111111'
const nonScopedDefaultRRPId = '00000000-0000-0000-0000-000000000002'

export {
  getSelectedScopeMap,
  getUpdatedScopeMapFromScopesResponse,
  mspDefaultRRPId,
  nonScopedDefaultRRPId,
  getEditRRPSuccessText,
  mapRoleAssignments,
  getResourceTypeName,
  filterScopelessResourceTypes,
  getAllInstancesOfResourceTypeSlug,
  getScopeInfo,
  isSAMLAuthzUser
}
