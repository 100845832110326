// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useContext } from 'react'
import { Box } from 'grommet'
import { CircleAlert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '../../../../components'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { AddAddressModal } from '../../AddAddress/AddAddressModal'

export const validateAddress = (formValues, t) => {
  const streetAdd = formValues.addresses.find((add) => {
    return add.type === 'street'
  })
  const isValid = streetAdd !== undefined
  return {
    error: isValid ? '' : t('streetAddressError'),
    isValid
  }
}

export const LocationAddress = () => {
  const { t } = useTranslation(['location'])

  const { formValues, setFormError, setFormValues } = useContext(WizardContext)
  const [streetAddress, setStreetAddress] = useState(null)
  const [shippingAddress, setShippingAddress] = useState(null)

  const [nextStepError, setNextStepError] = useState({
    isValid: true,
    error: ''
  })

  const updateAddressForModal = () => {
    const streetAdd = formValues.addresses.find((add) => {
      return add.type === 'street'
    })
    if (streetAdd) {
      setStreetAddress(streetAdd)
    } else {
      setStreetAddress(null)
    }
    const shippingAdd = formValues.addresses.find((add) => {
      return add.type === 'shipping_receiving'
    })
    if (shippingAdd) {
      setShippingAddress(shippingAdd)
    } else {
      setShippingAddress(null)
    }
  }

  useEffect(() => {
    updateAddressForModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    if (streetAddress === null) {
      const emptyAddress = {
        country: '',
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        postal_code: '',
        type: 'street'
      }
      setStreetAddress(emptyAddress)
    }
    setOpen(true)
  }

  const handleClose = () => {
    updateAddressForModal() // revert to previous addresses which in form values
    setOpen(false)
  }

  const handleAddAddresses = () => {
    setFormError('')
    setNextStepError({ isValid: true, error: '' })
    const addresses = []
    if (streetAddress !== null) {
      addresses.push(streetAddress)
    }
    if (shippingAddress !== null) {
      addresses.push(shippingAddress)
    }
    setFormValues({
      ...formValues,
      addresses
    })
    setOpen(false)
  }

  return (
    <>
      <Box
        fill
        direction="row"
        justify="between"
        align="center"
        pad={{ top: 'small', bottom: 'small' }}
        border={{ side: 'bottom', color: 'border' }}
      >
        <Typography type="heading" level={2}>
          {t('addresses')}
        </Typography>
        <Button
          label={
            formValues.addresses.length > 0
              ? t('edit_address')
              : t('add_address')
          }
          secondary
          testId="open-add-address-modal"
          onClick={handleOpen}
        />
      </Box>
      <hr />
      {open && (
        <AddAddressModal
          handleClose={handleClose}
          setShippingAddress={setShippingAddress}
          setStreetAddress={setStreetAddress}
          streetAddress={streetAddress}
          shippingAddress={shippingAddress}
          addAddresses={handleAddAddresses}
          isEdit={formValues.addresses.length > 0}
        />
      )}
      {formValues.addresses.length === 0 && (
        <Box pad={{ bottom: 'small' }}>
          <Typography type="text">{t('no_addresses_added')}</Typography>
        </Box>
      )}
      {formValues.addresses.length > 0 && (
        <>
          {streetAddress !== null && (
            <Box pad={{ bottom: 'small' }} direction="column" fill>
              <Typography type="text" emphasis>
                {t('street_address')}
              </Typography>
              <Typography data-testid="street-address" type="text">
                {streetAddress.street_address}
              </Typography>
              <Typography data-testid="street-address-2" type="text">
                {streetAddress.street_address2}
              </Typography>
              <Box direction="row" gap="xsmall">
                <Typography data-testid="street-address-city" type="text">
                  {streetAddress.city}
                </Typography>
                <Typography data-testid="street-address-state" type="text">
                  {streetAddress.state}
                </Typography>
                <Typography
                  data-testid="street-address-postal-code"
                  type="text"
                >
                  {streetAddress.postal_code}
                </Typography>
              </Box>
              <Typography data-testid="street-address-country" type="text">
                {streetAddress.country}
              </Typography>
            </Box>
          )}
          {shippingAddress !== null && (
            <Box pad={{ bottom: 'small' }} direction="column" fill>
              <Typography type="text" emphasis>
                {t('shipping_and_receiving')}
              </Typography>
              <Typography
                data-testid="shipping-address-street"
                type="paragraph"
              >
                {shippingAddress.street_address}
              </Typography>
              <Typography
                data-testid="shipping-address-street-2"
                type="paragraph"
              >
                {shippingAddress.street_address2}
              </Typography>
              <Box direction="row" gap="xsmall">
                <Typography
                  data-testid="shipping-address-city"
                  type="paragraph"
                >
                  {shippingAddress.city}
                </Typography>
                <Typography
                  data-testid="shipping-address-state"
                  type="paragraph"
                >
                  {shippingAddress.state}
                </Typography>
                <Typography
                  data-testid="shipping-address-postal-code"
                  type="paragraph"
                >
                  {shippingAddress.postal_code}
                </Typography>
              </Box>
              <Typography
                data-testid="shipping-address-country"
                type="paragraph"
              >
                {shippingAddress.country}
              </Typography>
            </Box>
          )}
        </>
      )}
      {!nextStepError.isValid && (
        <Box
          background="validation-critical"
          pad="small"
          round="4px"
          margin={{ bottom: 'medium' }}
          gap="xsmall"
          direction="row"
          align="center"
          data-testid="street-address-error"
        >
          <CircleAlert size="small" />
          <Typography
            size="xsmall"
            testId="street-address-error-message"
            type="paragraph"
          >
            {nextStepError.error}
          </Typography>
        </Box>
      )}
    </>
  )
}
