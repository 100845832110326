// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Text, TextInput } from 'grommet'
import { Search } from 'grommet-icons'
import debounce from 'lodash-es/debounce'
import { useTranslation } from 'react-i18next'

import FilterPanel from './FilterPanel'

const SearchBox = ({
  setSearch,
  filterCount = 0,
  checkedCount,
  setFilters
}) => {
  const { t } = useTranslation('unified_wellness')
  const [value, setRawValue] = useState('')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchValue = useCallback(
    debounce((debouncedValue) => {
      setSearch(debouncedValue)
    }, 400),
    []
  )
  const setValue = (searchValue) => {
    setRawValue(searchValue)
    if (searchValue) {
      debouncedSearchValue(searchValue.toLowerCase())
    } else {
      debouncedSearchValue('')
    }
  }
  return (
    <>
      <Box>
        <Box direction="row" margin={{ bottom: 'xsmall' }}>
          <Box width="medium">
            <TextInput
              placeholder={t('search.search')}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              icon={<Search />}
              reverse
              data-testid="search-input"
            />
          </Box>

          <FilterPanel setFilters={setFilters} />
        </Box>
        <div>
          <Text size="xsmall" data-testid="search-count-text">
            <>
              {checkedCount > 0 && (
                <>
                  <Text data-testid="checked-count" weight="bold">
                    {checkedCount}
                  </Text>
                  {` ${t('search.of')} `}
                </>
              )}
              <Text data-testid="events-count" weight="bold">
                {filterCount}
              </Text>{' '}
              {filterCount === 1 ? t('search.item') : t('search.items')}
              {checkedCount > 0 && <>{` ${t('search.selected')}`}</>}
            </>
          </Text>
        </div>
      </Box>
    </>
  )
}

SearchBox.propTypes = {
  setSearch: PropTypes.func.isRequired,
  filterCount: PropTypes.number,
  checkedCount: PropTypes.number.isRequired,
  setFilters: PropTypes.func.isRequired
}

export default SearchBox
