// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, DateInput, FormField, TextInput } from 'grommet'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ModalDialog,
  Typography,
  CCSForm,
  ModalHeader,
  ButtonGroup,
  FormInput
} from '../../../../components'
import { getErrorMessage, post } from '../../../../utils/api-utils'

const ModifySubscriptionModal = ({
  subscriptionData,
  setShowModal,
  onSuccess = () => {}
}) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const [endDate, setEndDate] = useState(
    (subscriptionData?.end_date_in_millis
      ? dayjs(subscriptionData?.end_date_in_millis)
      : dayjs()
    ).format('MM/DD/YYYY')
  )

  const [quantity, setQuantity] = useState(subscriptionData.quantity)
  const [errorMessage, setErrorMessage] = useState('')

  const handleModify = () => {
    const isExtendDateChanged =
      dayjs(subscriptionData?.end_date_in_millis).format('MM/DD/YYYY') !==
      endDate
    const url = `/support-assistant/v1alpha1/subscription-modification`
    post(
      url,
      {
        subscription_key: subscriptionData.subscription_key,
        platform_customer_id: subscriptionData.platform_customer_id,
        extend_end_seconds: isExtendDateChanged
          ? dayjs(endDate).unix() -
            dayjs(subscriptionData?.end_date_in_millis || 0).unix()
          : 0,
        increase_quantity_by:
          parseInt(quantity, 10) - (subscriptionData.quantity || 0)
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage('')
          setShowModal(false)
          onSuccess()
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
      }
    )
  }

  const getEndDateValue = () => {
    try {
      return new Date(endDate)?.toISOString()
    } catch (e) {
      return endDate
    }
  }

  return (
    <ModalDialog
      width="medium"
      testId="modify-subscription-modal-dialog"
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="modify-subscription-title"
              type="heading"
            >
              {t('modify_subscription')}
            </Typography>
          }
        />
      }
      content={
        <CCSForm
          errorMessage={errorMessage}
          testId="with-error"
          validate="blur"
          onSubmit={handleModify}
          buttons={
            <Box justify="end" margin={{ top: 'medium' }}>
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    secondary: true,
                    testId: 'cancel-btn',
                    onClick: () => setShowModal(false)
                  },
                  {
                    id: 1,
                    label: t('modify'),
                    primary: true,
                    testId: 'modify-btn',
                    type: 'submit'
                  }
                ]}
                testId="transfer-subscription-buttons"
              />
            </Box>
          }
        >
          <>
            <FormField
              data-testid="subscription-key-form-field"
              label={t('subscription_key')}
              name="subscription_key"
            >
              <TextInput
                data-testid="subscription-key-form-input"
                name="subscription_key"
                value={subscriptionData.subscription_key}
                disabled
              />
            </FormField>
            <FormInput
              inputType="text"
              label={t('customer_details.start_date')}
              name="start_date"
              testId="start-date-input"
              value={
                dayjs(subscriptionData?.start_date)?.format('MM/DD/YYYY') ||
                '--'
              }
              disabled
            />
            <FormField
              data-testid="end-date-form-field"
              label={t('end_date')}
              name="end_date"
              validate={(value) => {
                // validating that the end date cannot be less than start date
                if (
                  dayjs(value).unix() >
                  Math.floor(subscriptionData?.start_date_in_millis / 1000)
                )
                  return true
                return t('invalid_end_date_error_message')
              }}
            >
              <DateInput
                format="mm/dd/yyyy"
                value={getEndDateValue()}
                onChange={({ value }) => {
                  setEndDate(value)
                }}
                name="end_date"
              />
            </FormField>
            <FormField
              data-testid="customer-id-form-field"
              label={t('customer_id')}
              name="customer_id"
            >
              <TextInput
                data-testid="customer-id-form-input"
                name="customer_id"
                value={subscriptionData.platform_customer_id}
                disabled
              />
            </FormField>
            <FormField data-testid="sku-form-field" label={t('sku')} name="sku">
              <TextInput
                data-testid="sku-form-input"
                name="sku"
                value={subscriptionData.product_sku}
                disabled
              />
            </FormField>
            <FormField
              data-testid="quantity-form-field"
              label={t('quantity')}
              name="quantity"
              validate={(value) => {
                // validating the quantity to be a postive integer
                if (
                  value >>> 0 === // eslint-disable-line no-bitwise
                  parseFloat(value)
                )
                  return true
                return t('not_a_valid_number')
              }}
            >
              <TextInput
                data-testid="quantity-form-input"
                name="quantity"
                value={quantity}
                onChange={(event) => setQuantity(event.target.value)}
              />
            </FormField>
          </>
        </CCSForm>
      }
      onClose={() => setShowModal(false)}
    />
  )
}

ModifySubscriptionModal.propTypes = {
  subscriptionData: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

export { ModifySubscriptionModal }
