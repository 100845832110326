// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, DataTable } from 'grommet'
import { PropTypes } from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Typography } from '../../../../../../../../components'
import { getCurrencySymbol } from '../currencyFormatter'
import {
  cleanPricePlans,
  displayRatesFormatter,
  isValidMinCommit
} from '../utils'

import RateInputBox from './RateInputBox'
import RateDateInput from './RateDateInput'

const RateInput = ({
  pricePlan = undefined,
  formValues,
  setFormValues,
  resetGlobalErrMsg = undefined,
  ratesDateLimitStart
}) => {
  dayjs.extend(utc)
  const { t, i18n } = useTranslation(['device', 'common'])

  const handleCapacityRevisionsChange = (
    currentPlan,
    targetEffectiveDate,
    newEffectiveDate
  ) => {
    if (currentPlan?.capacityRevisions?.length > 0) {
      currentPlan?.capacityRevisions?.forEach((el) => {
        if (el.effectiveDate === targetEffectiveDate) {
          el.effectiveDate = newEffectiveDate
        }
      })
    }
  }

  const handleFormValueChange = (
    rateRevisionId,
    tiersKey,
    newValue,
    isDate,
    isFormatted
  ) => {
    const data = { ...formValues.ratesData }
    data.pricePlans.forEach((plan) => {
      plan.rateRevisions.forEach((rateRevision) => {
        if (rateRevision.id === rateRevisionId) {
          if (isDate) {
            handleCapacityRevisionsChange(
              plan,
              rateRevision.effectiveDate,
              newValue
            )
            rateRevision.effectiveDate = newValue
          } else if (isFormatted) {
            rateRevision.tiers[tiersKey] = displayRatesFormatter(newValue)
          } else {
            rateRevision.tiers[tiersKey] = newValue
          }
        }
      })
    })
    const newRatesPayload = {
      ...formValues.ratesPayload,
      pricePlans: cleanPricePlans([...data.pricePlans])
    }
    setFormValues({
      ...formValues,
      ratesData: data,
      ratesPayload: newRatesPayload
    })
  }

  const getEffectiveDateValue = (rateRevision) => {
    const todayStr = dayjs().utc().format('YYYY-MM-DD')
    if (
      rateRevision?.effectiveDate &&
      dayjs(rateRevision?.effectiveDate).isValid()
    ) {
      try {
        return rateRevision?.effectiveDate
      } catch (e) {
        return todayStr
      }
    }
    return todayStr
  }

  const getColumns = (rateRevision) => [
    {
      property: 'bands',
      header: (
        <Typography
          emphasis
          type="text"
          size="small"
          margin={{ top: '0px', bottom: '0px' }}
          testId={`showback-rates-input-table-header-bands-${pricePlan?.uom}`}
        >
          {t('showback_rates.wizard_meters_form_bands')}
        </Typography>
      ),
      render: (datum) => (
        <Typography
          type="text"
          margin={{ top: '0px', bottom: '0px' }}
          testId={`showback-rates-input-table-header-bands-value-${
            datum.isSecond ? 'second' : 'first'
          }-${pricePlan?.uom}`}
        >
          {datum.bands}
        </Typography>
      ),
      primary: true
    },
    {
      property: 'start',
      size: '70px',
      header: (
        <Typography
          emphasis
          type="text"
          size="small"
          margin={{ top: '0px', bottom: '0px' }}
          testId={`showback-rates-input-table-header-start-${pricePlan?.uom}`}
        >
          {t('showback_rates.wizard_meters_form_start')}
        </Typography>
      ),
      render: (datum) => (
        <Typography
          type="text"
          margin={{ top: '0px', bottom: '0px' }}
          testId={`showback-rates-input-table-header-start-value-${
            datum.isSecond ? 'second' : 'first'
          }-${pricePlan?.uom}`}
        >
          {datum.start}
        </Typography>
      )
    },
    {
      property: 'spacer',
      size: '70px',
      header: '',
      render: (datum) => (
        <Typography
          type="text"
          margin={{ top: '0px', bottom: '0px' }}
          testId={`showback-rates-input-table-header-spacer-${
            datum.isSecond ? 'second' : 'first'
          }-${pricePlan?.uom}`}
        >
          {datum.spacer}
        </Typography>
      )
    },
    {
      property: 'end',
      size: '70px',
      header: (
        <Typography
          emphasis
          type="text"
          size="small"
          margin={{ top: '0px', bottom: '0px' }}
          testId={`showback-rates-input-table-header-end-${pricePlan?.uom}`}
        >
          {t('showback_rates.wizard_meters_form_end')}
        </Typography>
      ),
      render: (datum) => (
        <Typography
          type="text"
          margin={{ top: '0px', bottom: '0px' }}
          testId={`showback-rates-input-table-header-end-value-${
            datum.isSecond ? 'second' : 'first'
          }-${pricePlan?.uom}`}
        >
          {datum.end}
        </Typography>
      )
    },
    {
      property: 'rate',
      size: '120px',
      header: (
        <Typography
          emphasis
          type="text"
          size="small"
          margin={{ top: '0px', bottom: '0px' }}
          wordBreak="break-all"
          testId={`showback-rates-input-table-header-per-uom-${pricePlan?.uom}`}
        >
          {`${t('showback_rates.wizard_meters_form_per')} ${
            pricePlan?.uom
          }(${getCurrencySymbol(
            formValues.ratesPayload.currency,
            i18n.language
          )})`}
        </Typography>
      ),
      render: (datum) => (
        <RateInputBox
          key={`showback-rates-input-table-inputs-${rateRevision?.id}-${datum.tierKey}`}
          testId={`showback-rates-input-table-rate-input-${
            datum.isSecond ? 'second' : 'first'
          }-${pricePlan?.uom}`}
          value={datum.value}
          onBlur={(inputValue) => {
            handleFormValueChange(
              rateRevision?.id,
              datum.tierKey,
              inputValue,
              false,
              true
            )
          }}
          resetGlobalErrMsg={resetGlobalErrMsg}
          error={
            formValues.ratesErrors[rateRevision?.id] &&
            formValues.ratesErrors[rateRevision?.id][datum.tierKey]
          }
        />
      )
    }
  ]

  const getData = (rateRevision) => {
    const tiers = rateRevision.tiers || {}
    const starts = {
      first: '0.0',
      second: Object.keys(tiers).filter((key) => {
        return key !== '0.0'
      })[0]
    }
    const values = {
      first: tiers[starts.first],
      second: starts.second ? tiers[starts.second] : undefined
    }

    if (!isValidMinCommit(rateRevision?.minCommit)) {
      return [
        {
          tierKey: starts.first,
          bands: t('showback_rates.wizard_meters_no_reserve_commit'),
          start: starts.first,
          spacer: '',
          end: t('showback_rates.wizard_meters_form_and_up'),
          value: values.first,
          isSecond: false,
          effectiveDate: rateRevision.effectiveDate,
          hasNoMinCommit: true
        }
      ]
    }

    const firstTierData = {
      tierKey: starts.first,
      bands: t('showback_rates.wizard_meters_form_up_to_commit'),
      start: starts.first,
      spacer: `${t('showback_rates.wizard_meters_form_to')} <=`,
      end: rateRevision?.minCommit,
      value: values.first,
      isSecond: false,
      effectiveDate: rateRevision.effectiveDate
    }

    if (!starts.second) {
      return [firstTierData]
    }
    if (starts.second) {
      return [
        firstTierData,
        {
          tierKey: starts.second,
          bands: t('showback_rates.wizard_meters_form_beyond_commit'),
          start: `> ${starts.second}`,
          spacer: '',
          end: t('showback_rates.wizard_meters_form_and_up'),
          value: values.second,
          isSecond: true,
          effectiveDate: rateRevision.effectiveDate
        }
      ]
    }
    return []
  }

  const renderRateRevision = (rateRevision) => {
    return (
      <Box
        key={rateRevision.id}
        direction="row"
        gap="xsmall"
        pad={{ left: 'xsmall' }}
        fill="horizontal"
      >
        <Box width="small">
          <Typography
            type="text"
            size="small"
            testId={`showback-rates-input-effective-date-label-${pricePlan?.uom}`}
          >
            {t('showback_rates.wizard_meters_form_effective_start_date')}
          </Typography>

          <FormField
            testId={`showback-rates-input-effective-date-form-field-${pricePlan?.uom}`}
            name="scheduleDate"
            error={null}
          >
            <RateDateInput
              key={`meter-date-input-${rateRevision.id}`}
              ratesDateLimitStart={ratesDateLimitStart}
              format="mm/dd/yyyy"
              value={getEffectiveDateValue(rateRevision)}
              onChange={(value) => {
                handleFormValueChange(rateRevision.id, null, value, true)
              }}
              resetGlobalErrMsg={resetGlobalErrMsg}
              placeholder="mm/dd/yyyy"
              data-testid={`showback-rates-input-effective-date-input-${pricePlan?.uom}`}
            />
          </FormField>
        </Box>
        <Box fill="horizontal" pad={{ left: 'small', right: 'medium' }}>
          <DataTable
            columns={getColumns(rateRevision)}
            data={getData(rateRevision)}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      direction="column"
      gap="small"
      pad={{ left: 'small' }}
      margin={{ bottom: 'small' }}
    >
      <Box direction="row" fill="horizontal" justify="between">
        <Box>
          <Typography
            emphasis
            type="text"
            size="small"
            margin={{ top: '0px', bottom: '0px' }}
            testId={`showback-rates-input-uom-${pricePlan?.uom}`}
          >
            {pricePlan?.uom}
          </Typography>
        </Box>
      </Box>

      {pricePlan.rateRevisions.map((rateRevision) => {
        return renderRateRevision(rateRevision)
      })}
    </Box>
  )
}

RateInput.propTypes = {
  pricePlan: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  resetGlobalErrMsg: PropTypes.func,
  ratesDateLimitStart: PropTypes.string.isRequired
}

export { RateInput }
