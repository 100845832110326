// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../../../utils/common-utils'

import UsersPage from './Users'
import UserDetailsPage from './UserDetails'

const WorkspaceUserRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)
  return (
    <Routes>
      <Route exact path="/" element={<UsersPage />} />
      <Route exact path="/:userId" element={<UserDetailsPage />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export { WorkspaceUserRouter }
