// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { Previous } from 'grommet-icons'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { get } from '../../../utils/api-utils'
import {
  getWorkspaceString,
  WKSPC_PLURAL_CAPITALIZED
} from '../../../utils/common-utils'
import DiscardModal from '../components/DiscardModal'

import { EditCustomerAccount } from './pages/edit-customer-account'
import { ViewCustomerAccount } from './pages/view-customer-account'

const ViewCustomerDetails = () => {
  const { pathname } = useLocation()

  const { t } = useTranslation(['authn', 'common'])
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { customerId } = useParams()
  const [customerAccountDetails, setCustomerAccountDetails] = useState(null)
  const [logoDetails, setLogoDetails] = useState(null)
  const [showDiscardModal, setDiscardModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const isInEditMode = () => {
    return pathname.includes('edit')
  }

  const handleDiscard = () => {
    setDiscardModal(false)
    navigate(-1)
  }
  const handleContinueEdit = () => {
    setDiscardModal(false)
  }
  const handleDiscardInEditPage = () => {
    setDiscardModal(true)
  }

  const discardModalButtons = [
    {
      id: 1,
      label: 'Continue Editing',
      secondary: true,
      testId: 'continue-edit-btn',
      onClick: handleContinueEdit
    },
    {
      id: 2,
      label: 'Discard Changes',
      primary: true,
      testId: 'discard-changes-modal-btn',
      onClick: handleDiscard
    }
  ]

  const getCustomerDetails = useCallback(() => {
    get(
      `/accounts/ui/v1/managed-service/tenant/${customerId}`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        const {
          address: {
            street_address: streetAddress,
            city,
            state_or_region: stateOrRegion,
            zip,
            country_code: countryCode
          } = {},
          company_name: companyName,
          description,
          email: emailId,
          phone_number: phoneNumber,
          created_at: createdAt,
          updated_at: updatedAt,
          customer_logo: { logo, logo_filename: logoFilename } = {}
        } = response.data || ''

        const data = {
          streetAddress,
          city,
          state: stateOrRegion,
          companyName,
          countryCode,
          description,
          zip,
          email: emailId || '',
          phone: phoneNumber || '',
          createdAt,
          updatedAt,
          logo,
          logoFilename
        }
        const logoData = {
          logo,
          logoFilename
        }
        setCustomerAccountDetails(data)
        setLogoDetails(logoData)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [oidcUser.access_token, customerId])

  useEffect(() => {
    getCustomerDetails()
  }, [getCustomerDetails])

  const handleBackBtnClick = () => {
    if (isInEditMode() && !submitted) setDiscardModal(true)
    else navigate(`/customer-account`)
  }

  return (
    <Box
      data-testid="customer-account-details"
      gap="xsmall"
      margin={{ left: 'large', top: 'medium', right: 'xlarge' }}
    >
      <DiscardModal
        showDiscardModal={showDiscardModal}
        buttonList={discardModalButtons}
        setDiscardModal={setDiscardModal}
      />
      <Box direction="row" justify="start">
        <Button
          default
          label={t('customer_account.title', {
            accounts: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_PLURAL_CAPITALIZED
            )
          })}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="back-to-customer-account-page"
        />
      </Box>
      {customerAccountDetails &&
        (isInEditMode() ? (
          <EditCustomerAccount
            submitted={submitted}
            setSubmitted={setSubmitted}
            customerAccountDetails={customerAccountDetails}
            customerId={customerId}
            getCustomerDetails={getCustomerDetails}
            handleDiscard={handleDiscardInEditPage}
            logoDetails={logoDetails}
          />
        ) : (
          <ViewCustomerAccount
            customerAccountDetails={customerAccountDetails}
            customerId={customerId}
          />
        ))}
    </Box>
  )
}

const CustomerDetails = () => {
  return (
    <Layout>
      <ViewCustomerDetails align="start" justify="start" />
    </Layout>
  )
}
export default CustomerDetails
