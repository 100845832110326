// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid, ResponsiveContext } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Button, Card, Typography } from '../../../components'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import Markdown from '../../../commoncomponents/Markdown'
import { displayNotification } from '../../../utils/notificiation-utils'

import { HomeStaticCardData } from './quick-actions-data'

export const QuickActions = () => {
  const size = useContext(ResponsiveContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation(['common', 'authz', 'location', 'tutorial'])
  const [modalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState(undefined)
  const staticHomeCards = HomeStaticCardData(setModalOpen)
  const [successMessage, setSuccessMessage] = useState(null)
  const [successTitle, setSuccessTitle] = useState(null)

  useEffect(() => {
    setSuccessTitle(location?.state?.notificationTitle || null)
    const message = location?.state?.notificationDesc ? (
      <Markdown>{location?.state?.notificationDesc}</Markdown>
    ) : null
    setSuccessMessage(message)
    navigate(location.pathname, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  const handleCardClick = (routeTo, modalDialog, hrefTo) => {
    // Modal takes priority. A card should not have both a modal
    // and a routeTo.
    if (modalDialog) {
      setModal(modalDialog)
      setModalOpen(true)
    } else if (routeTo) {
      navigate(routeTo)
    } else if (hrefTo) {
      window.open(hrefTo, '_blank')
    }
  }

  const getStaticCardPanel = (item) => {
    return (
      <VisibilityWrapper key={item.id} hideFor={item.hideFor} rbac={item.rbac}>
        <Card
          key={item.id}
          action={
            <Button
              color={item.actionBtnColor}
              label={t(item.actionBtnLbl)}
              secondary
              type="button"
              testId={`${item.testId}-btn`}
              onClick={() =>
                handleCardClick(item.routeTo, item.modal, item.hrefTo)
              }
            />
          }
          foregroundColor={item.foreground}
          background={item.background}
          cardWidth="100%"
          description={t(item.description)}
          icon={item.icon}
          testId={item.testId}
          title={
            <Typography
              emphasis
              type="heading"
              level="3"
              testId={`${item.testId}-title`}
            >
              {t(item.title)}
            </Typography>
          }
        />
      </VisibilityWrapper>
    )
  }

  const grid = {
    columns: {
      small: 'auto',
      medium: ['flex', 'flex'],
      large: ['flex', 'flex'],
      xlarge: ['flex', 'flex']
    },
    gap: 'medium'
  }

  return (
    <>
      <Typography
        type="heading"
        level="2"
        testId="activities_n_task"
        margin={{ bottom: 'medium', top: 'none' }}
      >
        {t('authz:quick_actions')}
      </Typography>
      <Grid columns={grid.columns[size]} gap={grid.gap}>
        {modalOpen && modal}
        {staticHomeCards &&
          staticHomeCards.map(
            (item) => !item?.hidden && getStaticCardPanel(item)
          )}
      </Grid>
      {successMessage &&
        displayNotification(
          successMessage,
          'info',
          setSuccessMessage,
          successTitle
        )}
    </>
  )
}
