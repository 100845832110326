// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, FormField, TextInput, CheckBox } from 'grommet'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Dropdown } from '../../../../components'
import { getAllMac } from '../../../../pages/manage-account/utils'

const IAPSwitchControllerFormField = ({
  state,
  dispatch,
  customerId = undefined,
  inCCSManager = undefined
}) => {
  const { t } = useTranslation(['device'])
  const { oidcUser } = useReactOidc()
  const [listOfMac, setListOfMac] = useState([])
  const [listOfMacResponse, setListOfMacResponse] = useState([])

  useEffect(() => {
    const param = {
      action: 'QUERY',
      mode: 'MC',
      ...(inCCSManager && { platform_customer_id: customerId })
    }
    getAllMac({
      token: oidcUser.access_token,
      param,
      inCCSManager
    }).then((data) => {
      setListOfMac(data)
      setListOfMacResponse(data)
    })
  }, [customerId, inCCSManager, oidcUser.access_token])

  return (
    <Box
      margin={{ bottom: 'xxsmall' }}
      direction="column"
      justify="between"
      gap="small"
    >
      {['iap', 'switch_to_amp', 'contrl_to_amp'].includes(state.sub_type) && (
        <FormField
          data-testid="amp-ip-form-field"
          label={t('amp_ip')}
          name="amp_ip"
          width="medium"
        >
          <TextInput
            data-testid="amp-ip-input"
            name="amp_ip"
            value={state.amp_ip}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: 'amp_ip',
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>
      )}
      {['iap', 'switch_to_amp'].includes(state.sub_type) && (
        <FormField
          data-testid="shared-secret-form-field"
          label={t('shared_secret')}
          name="shared_secret"
          width="medium"
        >
          <TextInput
            data-testid="shared-secret-input"
            name="shared_secret"
            value={state.shared_secret}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: 'shared_secret',
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>
      )}
      {['iap', 'switch_to_amp', 'contrl_to_amp'].includes(state.sub_type) && (
        <FormField
          data-testid="organization-form-field"
          label={state.sub_type === 'iap' ? t('organization') : t('amp_group')}
          name="organization"
          width="medium"
        >
          <TextInput
            data-testid="organization-input"
            name="organization"
            value={state.organization}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: 'organization',
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>
      )}

      <FormField
        data-testid="ap-group-form-field"
        label={
          (state.sub_type === 'iap' && t('amp_cn')) ||
          (state.sub_type === 'iap_to_thirdparty' && t('server')) ||
          (['switch_to_amp', 'contrl_to_amp'].includes(state.sub_type) &&
            t('amp_folder')) ||
          t('ap_group')
        }
        name="ap_group"
        width="medium"
      >
        <TextInput
          data-testid="ap-group-input"
          name="ap_group"
          value={state.ap_group}
          onChange={(event) =>
            dispatch({
              value: event.target.value,
              field: 'ap_group',
              type: 'CHANGE_FIELD'
            })
          }
        />
      </FormField>

      {state.sub_type !== 'contrl_to_amp' && (
        <FormField
          data-testid="controller-form-field"
          label={
            state.sub_type === 'iap_to_thirdparty'
              ? t('protocol')
              : t('controller')
          }
          name="controller"
          width="medium"
        >
          <TextInput
            data-testid="controller-input"
            name="controller"
            value={state.controller}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: 'controller',
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>
      )}

      {state.sub_type === 'switch_to_amp' && (
        <FormField
          data-testid="backup-controller-ip-form-field"
          label={t('backup_controller_ip')}
          name="backup_controller_ip"
          width="medium"
        >
          <TextInput
            data-testid="backup-controller-ip-input"
            name="backup_controller_ip"
            value={state.backup_controller_ip}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: 'backup_controller_ip',
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>
      )}

      {['iap', 'iap_to_rap'].includes(state.sub_type) && (
        <FormField
          data-testid="controller-mac-form-field"
          label={t('conductor_mac')}
          name="value"
          width="medium"
        >
          <Dropdown
            name="value"
            placeholder={t('select_primary')}
            options={listOfMac}
            value={state.value}
            onChangeDropdown={(val) => {
              dispatch({
                value: val,
                field: 'value',
                type: 'CHANGE_FIELD'
              })
            }}
            testId="controller-mac-input"
            onSearch={(searchText) => {
              setListOfMac(
                listOfMacResponse.filter((value) => value === searchText)
              )
            }}
            onClose={() => {
              setListOfMac(listOfMacResponse)
            }}
          />
        </FormField>
      )}

      {state.sub_type === 'iap_to_cap' && (
        <CheckBox
          data-testid="persist-controller-ip-input"
          name="persist_controller_ip"
          reverse
          label={t('persist_conductor_ip')}
          checked={state.persist_controller_ip}
          onChange={(event) =>
            dispatch({
              value: event.target.checked,
              field: 'persist_controller_ip',
              type: 'CHANGE_FIELD'
            })
          }
        />
      )}
    </Box>
  )
}

IAPSwitchControllerFormField.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}

export { IAPSwitchControllerFormField }
