// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Page, PageContent, Box } from 'grommet'

import { Layout } from '../../commoncomponents/layout/Layout'

import FeatureFlagsStatus from './pages/FeatureFlagsStatus'

const LdFlagsHomePage = () => {
  return (
    <Layout>
      <Page kind="wide">
        <PageContent>
          <Box pad={{ horizontal: 'large', vertical: 'medium' }}>
            <FeatureFlagsStatus />
          </Box>
        </PageContent>
      </Page>
    </Layout>
  )
}

export default LdFlagsHomePage
