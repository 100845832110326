// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useContext } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { FormInput } from '../../../../components'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { post } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  validateUserInput,
  NAME_MAX_LEN,
  DESC_MAX_LEN
} from '../../../../utils/location-validation-utils'

import { LocationAddress } from './LocationAddress'

export const locationDetailsValidate = async (
  formValues,
  t,
  oidcUser,
  setShowErrorMessage,
  customerId,
  isCCSManager,
  locationId,
  isAddressFlagEnabled
) => {
  if (formValues.name.trim() === '') {
    return {
      errorMessage: t('requiredFieldError'),
      isValid: false
    }
  }
  // We need to make sure wizard next button stay disabled if there are field error
  // so need return isValid false when field have invalid characters
  const nameValidation = validateUserInput(
    formValues.name,
    isAddressFlagEnabled
  )
  if (nameValidation !== undefined) {
    return {
      isValid: false
    }
  }
  if (formValues.description !== '') {
    const desValidation = validateUserInput(
      formValues.description,
      isAddressFlagEnabled
    )
    if (desValidation !== undefined) {
      return {
        isValid: false
      }
    }
  }

  const streetAdd = formValues.addresses.find((add) => {
    return add.type === 'street'
  })
  const isValid = streetAdd !== undefined

  if (!isValid) {
    return {
      errorMessage: t('streetAddressError'),
      isValid
    }
  }

  let returnVal

  const url = isCCSManager
    ? '/support-assistant/v1alpha1/location-id'
    : '/ui-doorway/ui/v1/locations/get-location-id'

  await post(
    url,
    {
      names: [formValues.name],
      ...(isCCSManager && { platform_customer_id: customerId })
    },
    oidcUser.access_token
  ).then(
    (response) => {
      if (response?.data?.length === 0) {
        returnVal = {
          isValid: true
        }
      } else if (
        response?.data?.length === 1 &&
        response.data[0].id === locationId
      ) {
        returnVal = {
          isValid: true
        }
      } else {
        returnVal = {
          errorMessage: t('duplicate_loc_name_err'),
          isValid: false
        }
      }
    },
    (error) => {
      setShowErrorMessage(displayApiError(error, t, setShowErrorMessage))
      // Allow user to move to next step, so user is not blocked.
      // if by any chance name is duplicate Create API will give error anyway
      return {
        isValid: true
      }
    }
  )

  return returnVal
}

export const LocationDetails = () => {
  const { t } = useTranslation(['location'])
  const {
    formValues,
    attemptedAdvance,
    setAttemptedAdvance,
    formError,
    setFormError,
    setFormValues
  } = useContext(WizardContext)
  const [nameError, setNameError] = useState('')
  const [descError, setDescError] = useState('')
  const LDFlags = useFlags()

  useEffect(() => {
    if (attemptedAdvance && formError) {
      if (formError !== t('streetAddressError')) {
        setFormError('')
        setNameError(formError)
      }
      setAttemptedAdvance(false)
    }
  }, [formError, setAttemptedAdvance, attemptedAdvance, setFormError, t])

  return (
    <Box>
      <Box width="medium">
        <FormInput
          inputType="text"
          label={t('location_name')}
          name="name"
          testId="location-name"
          error={nameError}
          required
          onChange={(e) => {
            // clear any previous error
            setNameError('')
            if (e.target.value !== '') {
              const nameValidation = validateUserInput(
                e.target.value,
                LDFlags['glcp-sdi-address-doctor']
              )
              if (nameValidation !== undefined) {
                setNameError(
                  t(nameValidation.message, {
                    notAllowedChars: nameValidation.notAllowedChars
                  })
                )
              }
            }
            setFormValues({
              ...formValues,
              name: e.target.value
            })
          }}
          maxLength={NAME_MAX_LEN}
        />
        <FormInput
          inputType="text"
          label={t('description')}
          name="description"
          testId="location-name-description"
          error={descError}
          onChange={(e) => {
            // clear any previous error
            setDescError('')
            if (e.target.value !== '') {
              const desValidation = validateUserInput(
                e.target.value,
                LDFlags['glcp-sdi-address-doctor']
              )
              if (desValidation !== undefined) {
                setDescError(
                  t(desValidation.message, {
                    notAllowedChars: desValidation.notAllowedChars
                  })
                )
              }
            }
            setFormValues({
              ...formValues,
              description: e.target.value
            })
          }}
          maxLength={DESC_MAX_LEN}
        />
      </Box>
      <LocationAddress />
    </Box>
  )
}
