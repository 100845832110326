// Copyright 2024 Hewlett Packard Enterprise Development LP
import {
  Box,
  Accordion,
  AccordionPanel,
  Page,
  PageHeader,
  PageContent
} from 'grommet'
import React, { useEffect, useState } from 'react'
import { Previous } from 'grommet-icons'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Anchor, Loader, Typography } from '../../../../../components'
import { get } from '../../../../../utils/api-utils'

import RunBlock from './components/RunBlock'

const DocumentCuration = () => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const { docId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const documentType = location.state?.doc_type
  const [loading, setLoading] = useState(false)
  const [documentDetails, setDocumentDetails] = useState()

  useEffect(() => {
    setLoading(true)
    get(
      `/global-search-administration/v1alpha1/curation/run/${docId}`,
      {},
      oidcUser.access_token
    ).then((response) => {
      setDocumentDetails(response.data)
      setLoading(false)
    })
  }, [docId, oidcUser.access_token])

  return (
    <Page>
      <PageContent>
        {loading && (
          <Box align="center" margin="medium">
            <Loader label="Loading" testId="loader" />
          </Box>
        )}
        {!loading && !isEmpty(documentDetails) && (
          <>
            <PageHeader
              title={
                <Typography level="1" type="heading" testId="identity-title">
                  {documentDetails.doc_title || ''}
                </Typography>
              }
              subtitle={
                <Typography type="text" testId="sa-description">
                  <Anchor
                    testId="doc-link"
                    margin={{ right: 'xsmall' }}
                    target="_blank"
                    href={documentDetails.doc_link}
                    label="Click here"
                  />
                  {t(
                    'global_search_administration.document_curation.doc_link_text'
                  )}
                </Typography>
              }
              parent={
                <Anchor
                  label="Documents"
                  icon={<Previous />}
                  onClick={() => {
                    navigate('/manage-ccs/search-administration')
                  }}
                  testId="manage-account-btn"
                />
              }
            />
            <Box fill margin="medium">
              <Box>
                <Accordion multiple data-testid="questions">
                  {documentDetails?.run.map((run) => (
                    <AccordionPanel
                      data-testid={`${run.id}-accordion-panel`}
                      label={`${run.queries[0].query}`}
                      key={run.id}
                    >
                      <RunBlock
                        runDetails={run}
                        documentDetails={{
                          doc_id: documentDetails.doc_id,
                          doc_version: documentDetails.doc_version,
                          doc_link: documentDetails.doc_link,
                          type: documentType
                        }}
                      />
                    </AccordionPanel>
                  ))}
                </Accordion>
              </Box>
            </Box>
          </>
        )}
      </PageContent>
    </Page>
  )
}

export default DocumentCuration
