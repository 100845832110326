// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Text } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  Wizard,
  ModalDialog,
  Typography,
  Notification
} from '../../../../../../components'
import { getErrorMessage, post } from '../../../../../../utils/api-utils'
import Markdown from '../../../common-components/Markdown'

import GeneralRoleInfo, { validateRoleInfo } from './steps/GeneralRoleInfo'
import AddPermissions, { validatePermissions } from './steps/AddPermissions'
import ReviewAndCreate from './steps/ReviewAndCreate'

const CreateRoleWizard = ({
  isSetOpen,
  onSuccess = () => {},
  roleToDuplicate = undefined
}) => {
  const { t } = useTranslation(['apps', 'common', 'iam'])
  const [isFinish, setIsFinish] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleFinish = (formValues, setFormError) => {
    const { roleName, newRoleName, description, permissions } = formValues
    const requestBody = {}
    requestBody.permissions = permissions.map(
      (permission) => permission?.permission_name
    )
    requestBody.displayName = formValues?.isCreateNewRole
      ? roleName?.trim()
      : newRoleName?.trim()
    requestBody.name = requestBody?.displayName?.replaceAll(' ', '-')
    requestBody.description = description?.trim()
    requestBody.kind = 'CUSTOM'
    requestBody.managedBy = ''
    requestBody.releaseStage = ''

    return new Promise((resolve) => {
      const name = formValues?.isCreateNewRole
        ? formValues?.roleName
        : formValues?.newRoleName

      post('/authorization/v2alpha1/roles', requestBody).then(
        () => {
          const message = (
            <Markdown>
              {t('iam:roles.create_role_success_msg', {
                role: t('common:business_object.role'),
                roleName: name
              })}
            </Markdown>
          )
          onSuccess(message)
          resolve()
          setIsFinish(true)
        },
        (error) => {
          let message = ''
          if (error?.response?.data?.httpStatusCode === 409) {
            switch (error?.response?.data?.errorCode) {
              case 'HPE_GL_AUTHORIZATION_ALREADY_CREATED':
                message = t(
                  'authz:409_error_messages.role_already_created_error'
                )

                break
              case 'HPE_AUTHZ_RESOURCE_CONFLICT':
                message = (
                  <Markdown
                    components={{
                      strong: {
                        component: Text,
                        props: { size: 'xsmall', weight: 500 }
                      }
                    }}
                  >
                    {t('authz:409_error_messages.role_name_conflict_error', {
                      name
                    })}
                  </Markdown>
                )
                break
              default:
                message = getErrorMessage(error, t)
                break
            }
          } else {
            message = getErrorMessage(error, t)
          }
          setFormError(message)
          resolve()
        }
      )
    })
  }

  const handleLoadingChange = (loading) => {
    setIsLoading(loading)
  }

  useEffect(() => {
    if (isFinish) {
      isSetOpen(false)
    }
  }, [isFinish, isSetOpen])

  return (
    <ModalDialog
      testId="create-role-wizard-modal"
      position="center"
      width="100%"
      height="100%"
      onClose={() => {}}
      content={
        <>
          <Wizard
            testId="create-role-wizard"
            title={t('iam:roles.create_role_btn')}
            isApiLoading={isLoading}
            actionOnExit={() => {
              isSetOpen(false)
            }}
            actionOnSubmit={(formValues, setFormError) => {
              return new Promise((resolve) => {
                handleFinish(formValues, setFormError).then(() => resolve())
              })
            }}
            formDefaultValues={{
              isCreateNewRole: false,
              roleName: '',
              newRoleName: !roleToDuplicate
                ? ''
                : t('iam:roles:duplicate_role_name', {
                    duplicatedRoleName: `${roleToDuplicate.displayName}`
                  }),
              description: !roleToDuplicate ? '' : roleToDuplicate.description,
              serviceName: '',
              appId: '',
              application: undefined,
              permissions: [],
              roleToDuplicate,
              duplicateRoleFromAction: !!roleToDuplicate,
              editInStep1: true
            }}
            buttonLabels={{
              finish: t('iam:finish_btn')
            }}
            disableFinishOnSubmit
            cancelStrings={{
              continueLabel: t('iam:leaving_without_finishing.stay_btn'),
              exitLabel: t('iam:leaving_without_finishing.leave_btn'),
              heading: t(
                'iam:leaving_without_finishing.exit_without_finishing_title'
              ),
              text: t(
                'iam:leaving_without_finishing.exit_without_finishing_msg'
              )
            }}
            steps={[
              {
                childComponents: (
                  <GeneralRoleInfo
                    roleToDuplicate={roleToDuplicate}
                    onLoadingChange={handleLoadingChange}
                  />
                ),
                title: t('iam:roles.general_information_title'),
                description: (
                  <Typography
                    type="text"
                    size="xlarge"
                    testId="step-description"
                  >
                    {t('iam:roles.create_role_modal_subtitle', {
                      role: t('common:business_object.role')
                    })}
                  </Typography>
                ),
                validateForm: (formValues) => validateRoleInfo(formValues, t)
              },
              {
                childComponents: <AddPermissions />,
                title: t('iam:add_permission_btn'),
                description: (
                  <>
                    <Typography
                      type="text"
                      size="xlarge"
                      testId="step-description"
                    >
                      {t('iam:roles.add_role_permission_subtitle', {
                        role: t('common:business_object.role')
                      })}
                    </Typography>
                    <Notification
                      margin={{
                        bottom: 'small',
                        top: 'small'
                      }}
                      status="info"
                      type="inline"
                      text={t(
                        'iam:roles_permission.mandatory_permission_notification',
                        {}
                      )}
                      testId="step-description-notification"
                    />
                  </>
                ),
                validateForm: (formValues) => validatePermissions(formValues, t)
              },
              {
                childComponents: <ReviewAndCreate />,
                title: t('iam:roles.review_and_create_title'),
                description: (
                  <Typography
                    type="text"
                    size="xlarge"
                    testId="step-description"
                  >
                    {t('iam:roles.review_and_create_subtitle', {
                      role: t('common:business_object.role'),
                      Roles: t('common:business_object.role_plural_capitalized')
                    })}
                  </Typography>
                ),
                validateForm: () => {
                  return new Promise((resolve) => resolve())
                }
              }
            ]}
          />
        </>
      }
    />
  )
}

CreateRoleWizard.propTypes = {
  /**
   * Callback function to close delete group modal
   */
  isSetOpen: PropTypes.func.isRequired,

  /**
   * OnSuccess callback function for notification, navigation, etc...
   */
  onSuccess: PropTypes.func,

  /**
   * Role to be duplicate
   */

  roleToDuplicate: PropTypes.object
}

export default CreateRoleWizard
