// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  getMSPCustomerAccount,
  isInventoryOwnedWorkspace
} from '../../utils/feature-flag-utils'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { useVisibilityContext } from '../../context/visibility-context'

import AddProductsWizardPhase2 from './magic-link-phase2/AddProductsWizardPhase2'
import { InvalidLink } from './components'
import { AddProductsSuccessTemplate } from './subscriptions/components'
import AddProductsWizard from './subscriptions/AddProductsWizard'

const MagicLinkRouter = () => {
  const swappedMSPAccount = getMSPCustomerAccount()
  const LDFlags = useFlags()
  const { hideWhen } = useVisibilityContext()
  return (
    <Routes>
      <Route exact path="/invalid-link" element={<InvalidLink />} />
      <Route exact path="/success" element={<AddProductsSuccessTemplate />} />
      <Route
        exact
        path="/:productSquid"
        element={
          !swappedMSPAccount && isInventoryOwnedWorkspace() ? (
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/device-management',
                permission: 'ccs.device-management.edit'
              }}
              hideFor={{ account: 'TAC' }}
              fallbackComponent={
                <Navigate
                  to={
                    hideWhen?.account === 'TAC' ? '/manage-ccs/home' : '/home'
                  }
                  state={{ isMagicAccessDenied: true }}
                />
              }
            >
              {LDFlags['glcp-magic-link-phase2'] ? (
                <AddProductsWizardPhase2 />
              ) : (
                <AddProductsWizard />
              )}
            </VisibilityWrapper>
          ) : (
            <Navigate to="/home" state={{ isMagicAccessDenied: true }} />
          )
        }
      />
    </Routes>
  )
}

export default MagicLinkRouter
