// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { Box, RadioButtonGroup, FormField } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  Typography,
  ModalDialog,
  ModalHeader,
  FormInput,
  Button,
  CCSForm
} from '../../../../components'
import { getPermission } from '../../utils'

const ModifyAccessPermissionModalSA = ({ onSetOpen, detail = {}, cb }) => {
  const access_type = getPermission(detail?.role_name)
  const { t } = useTranslation(['support_access_manager', 'common'])
  const accessTypeOptions = [
    {
      label: t('support_access_manager:read_access'),
      value: 'read',
      disabled: access_type === 'read'
    },
    {
      label: t('support_access_manager:read_and_write_access'),
      value: 'write',
      disabled: access_type === 'write'
    }
  ]
  const [supportInfo, setSupportInfo] = useState({
    name: `${detail?.user_first_name} ${detail.user_last_name}` || '',
    email: detail?.user_name || '',
    access_type: accessTypeOptions.find((option) => !option.disabled)?.value
  })

  const closeDetailsModal = () => {
    onSetOpen(false)
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box>
              <Typography level="2" testId="audit-header-title" type="heading">
                {t('support_access_manager:modify_access.title')}
              </Typography>
              <Typography
                margin={{ bottom: 'small', top: 'small' }}
                type="text"
                testId="audit-log-details"
              >
                {t('support_access_manager:modify_access.sub_title')}
              </Typography>
            </Box>
          }
          onClose={closeDetailsModal}
        />
      }
      content={
        <Box
          direction="column"
          gap="small"
          justify="between"
          align="start"
          margin={{ top: 'small' }}
        >
          <CCSForm
            value={supportInfo}
            onChange={setSupportInfo}
            errorMessage=""
            testId="sam-sa-form"
            validate="blur"
            onSubmit={() => {
              cb(supportInfo)
              closeDetailsModal()
            }}
            buttons={
              <Box direction="row" justify="end" margin={{ top: 'small' }}>
                <Button
                  primary
                  testId="confirm-change-permission-btn"
                  type="submit"
                  label={t(
                    'support_access_manager:modify_access.confim_modify'
                  )}
                />

                <Button
                  default
                  testId="close-sa-btn"
                  alignSelf="end"
                  label={t('common:cancel')}
                  onClick={closeDetailsModal}
                />
              </Box>
            }
          >
            <FormInput
              testId="email-form-field"
              required
              label={t('common:email')}
              disabled
              defaultValue={supportInfo?.user_name || ''}
              name="email"
              inputType="text"
            />
            <Box>
              <FormField
                htmlFor="access-rights-form-field"
                name="access_type"
                data-testid="access-rights-form-field-id"
                label={t('support_access_manager:support_access_rights')}
                info={t('support_access_manager:access_info')}
              >
                <RadioButtonGroup
                  name="access_type"
                  options={accessTypeOptions}
                  testId="role-radio-btn-group"
                  value={
                    accessTypeOptions.find((option) => !option.disabled)?.value
                  }
                />
              </FormField>
            </Box>
          </CCSForm>
        </Box>
      }
      position="right"
      height="100%"
      onClose={closeDetailsModal}
      width="medium"
      testId="audit-detail-dialog"
    />
  )
}

export default ModifyAccessPermissionModalSA

ModifyAccessPermissionModalSA.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  detail: PropTypes.object,
  cb: PropTypes.func.isRequired
}
