// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box, Page, PageContent, Heading } from 'grommet'
import useSWR, { SWRConfig } from 'swr'
import { useTranslation } from 'react-i18next'

import { Layout } from '../../../commoncomponents/layout/Layout'
import EventList from '../components/eventList/EventList'
import WellnessSummary from '../components/summary/WellnessSummary'
import { useEventFetcher } from '../utils/data'
import Wizard from '../components/wizard/Wizard'
import NoEvents from '../components/errorPages/NoEvents'
// import NotEnabled from '../components/errorPages/NotEnabled'

const Home = () => {
  const { t } = useTranslation('unified_wellness')
  // For this request we only care about whether there are any events are not, but it is convenient from a
  // caching point of view to use the same limit and style of request that the table uses
  const eventFetcher = useEventFetcher()
  const { data: response, error } = useSWR(
    '/wellness/v2beta1/events?wanted-page=1&limit=10&current-page=1&next=',
    eventFetcher
  )
  // GLCP launch darkly doesnt even load the page so this may not be necessary
  // const enabled = true
  // if (!enabled) {
  //   return <NotEnabled />
  // }
  if (!error && response && !response.total) {
    return <NoEvents />
  }
  return (
    <>
      <Box pad={{ top: 'medium', bottom: 'medium' }}>
        <Heading level={3} margin="none" data-testid="page-heading">
          {t('heading')}
        </Heading>
      </Box>
      <WellnessSummary />
      <EventList />
      <Wizard />
    </>
  )
}
const HomeContainer = () => {
  return (
    <SWRConfig>
      <Layout>
        <Page kind="wide">
          <PageContent>
            <Home />
          </PageContent>
        </Page>
      </Layout>
    </SWRConfig>
  )
}

export default HomeContainer
