// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { getPaginationShowIdx } from '../../../../utils/common-utils'
import { roleKindMapping } from '../../utils'
import { Button, DataTable, Typography } from '../../../../components'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { get } from '../../../../utils/api-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayNotification } from '../../../../utils/notificiation-utils'

import CreateRoleWizard from './components/create-role-wizard/CreateRoleWizard'

const RolesDataTable = () => {
  const location = useLocation()
  const { t } = useTranslation(['common', 'iam', 'auditlogs'])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [viewData, setViewData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [openCreateRoleWizard, setOpenCreateRoleWizard] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [showStatusNotification, setShowStatusNotification] = useState(false)

  const showRoleCreateStatus = useCallback(
    (message) => {
      setShowStatusNotification(
        displayNotification(
          message,
          'info',
          setShowStatusNotification,
          t('iam:roles.create_role_success_title', {
            Role: t('common:business_object.role_capitalized')
          })
        )
      )
    },
    [t]
  )
  const [searchVal, setSearchVal] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalCount, itemsPerPage, t)
  const handleRowClick = ({ datum }) => {
    navigate(
      `/manage-account/identity/roles/${encodeURIComponent(datum?.grn)}`,
      {
        state: {
          name: datum?.name,
          description: datum?.description,
          kind: datum?.type
        }
      }
    )
  }
  const getRoles = () => {
    setIsLoading(true)
    get('/internal-platform-tenant-ui/v2/roles', {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      service: 'all',
      ...(searchVal.trimStart().length > 0 && {
        search: searchVal.trimStart()
      })
    }).then(
      (response) => {
        if (response?.data) {
          const data = []
          if (response?.data?.roles) {
            response?.data?.roles?.map((role) =>
              data.push({
                id: role?.role_id,
                grn: role?.role_grn,
                name: role?.role_display_name,
                description: role?.role_description || '--',
                service: role?.service_name || '--',
                type: roleKindMapping.get(role?.type)
              })
            )
          }
          setIsLoading(false)
          setViewData(data)
          setTotalCount(response?.data?.total)
        }
      },
      (error) => {
        setViewData([])
        setTotalCount(0)
        setIsLoading(false)
        setErrorMessage(error)
      }
    )
  }

  const columns = [
    {
      property: 'name',
      header: t('iam:roles_table_headers.name'),
      primary: true,
      render: (datum) => {
        return (
          <Box direction="row" align="center" gap="small">
            <Typography
              type="text"
              color="black"
              weight={500}
              wordBreak="break-word"
              testId="role-name"
            >
              {datum?.name}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'description',
      header: t('iam:roles_table_headers.description')
    },
    {
      property: 'service',
      header: t('iam:roles_table_headers.service')
    },
    {
      property: 'type',
      header: t('iam:roles_table_headers.type')
    }
  ]
  useEffect(() => {
    getRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount, searchVal, itemsPerPage, page])
  useEffect(() => {
    if (location?.state?.message) {
      const {
        state: { message }
      } = location
      if (message) {
        showRoleCreateStatus(message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.message, showRoleCreateStatus])

  return (
    <Box pad={{ horizontal: 'xxsmall', top: 'medium' }}>
      <DataTable
        grid={{
          columns,
          data: viewData,
          pad: {
            top: 'xxsmall',
            bottom: 'xxsmall',
            left: 'small',
            right: 'xlarge'
          },
          primaryKey: 'id',
          onClickRow: (datum) => handleRowClick(datum)
        }}
        loading={isLoading}
        pagination={{
          totalItems: totalCount,
          itemsPerPage,
          setItemsPerPage,
          rowDropDownLabel: t('auditlogs:items_per_page'),
          defaultRowsValue: itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        search={{
          onSearchValueChange: (value) => {
            setSearchVal(value)
          },
          placeholder: t('common:search'),
          width: 'large'
        }}
        button={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.edit'
            }}
          >
            <Button
              secondary
              label={t('iam:roles.create_role_btn')}
              onClick={() => {
                setOpenCreateRoleWizard(true)
              }}
              testId="create-role-btn"
              style={{ whiteSpace: 'nowrap' }}
            />
          </VisibilityWrapper>
        }
        summary={{
          entityName: t('item_plural')
        }}
        testId="roles-dataTable"
      />
      {openCreateRoleWizard && (
        <CreateRoleWizard
          isSetOpen={setOpenCreateRoleWizard}
          onSuccess={(message) => {
            setRefreshCount((prevRefreshCount) => prevRefreshCount + 1)
            showRoleCreateStatus(message)
          }}
        />
      )}
      {showStatusNotification}
      {errorMessage && displayApiError(errorMessage, t, setErrorMessage)}
    </Box>
  )
}

export { RolesDataTable }
