// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'
import { StatusGood } from 'grommet-icons'

import {
  ButtonGroup,
  Notification,
  ToggleButton,
  Typography
} from '../../../../components'
import { getErrorMessage, put } from '../../../../utils/api-utils'

const EditMFADetails = ({
  customerDataResponse,
  setCustomerDataResponse,
  setShowModal,
  fetchCustomerDetails,
  dataChanged,
  setDataChanged
}) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const [mfaData, setMfaData] = useState(
    customerDataResponse.multi_fa_enabled || false
  )

  const [errorMessage, setErrorMessage] = useState(null)
  const [showSuccessNotification, setShowSuccessNotification] = useState(false)

  const handleDiscardChanges = () => {
    setMfaData(customerDataResponse.multi_fa_enabled || false)
  }

  const handleSaveChanges = () => {
    const requestBody = {
      customer_id: customerDataResponse.id,
      multi_fa_enabled: mfaData
    }
    const url = `/support-assistant/v1alpha1/customer-preferences`
    put(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage(null)
          setCustomerDataResponse({
            ...customerDataResponse,
            multi_fa_enabled: mfaData
          })
          setDataChanged(true)
          setShowSuccessNotification(true)
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        handleDiscardChanges()
      }
    )
  }

  const isDataEdited = () => {
    return mfaData === (customerDataResponse.multi_fa_enabled || false)
  }
  return (
    <Box
      style={{ width: '100%' }}
      direction="column"
      justify="between"
      gap="xlarge"
    >
      <Box>
        <Typography level="2" type="heading" weight="normal" testId="MFA-title">
          {t('multi_factor_auth')}
        </Typography>
        <Typography
          type="text"
          size="medium"
          testId="MFA-subtitle"
          margin={{ bottom: 'small' }}
        >
          {t('multi_factor_auth_subtitle')}
        </Typography>
        <Box margin={{ top: 'medium' }}>
          <Typography size="xsmall" testId="MFA-status-label" type="text">
            {t('multi_factor_authentication_mfa')}
          </Typography>
          <FormField
            name="mfa-form-field"
            width="medium"
            data-testid="mfa-form-field"
          >
            <ToggleButton
              checked={mfaData}
              label={t('enabled_with_google_authenticator')}
              onChange={(event) => {
                setMfaData(event.target.checked)
              }}
              testId="toggle-btn"
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        {errorMessage && (
          <Notification
            backgroundColor="status-critical"
            onClose={() => {}}
            testId="edit-mfa-error"
            text={errorMessage}
            type="inline"
          />
        )}
        <Box direction="row" justify="end" pad={{ top: 'medium' }}>
          <ButtonGroup
            buttonList={[
              {
                id: 1,
                label: t('close'),
                secondary: true,
                testId: 'close-btn',
                onClick: () => {
                  setShowModal(false)
                  if (dataChanged) fetchCustomerDetails()
                }
              },
              {
                id: 2,
                label: t('discard_changes'),
                secondary: true,
                testId: 'discard-changes-btn',
                onClick: handleDiscardChanges,
                disabled: isDataEdited()
              },
              {
                id: 3,
                label: t('save_changes'),
                primary: true,
                testId: 'save-changes-btn',
                onClick: handleSaveChanges,
                disabled: isDataEdited()
              }
            ]}
            testId="two-buttons"
          />
        </Box>
        {showSuccessNotification && (
          <Notification
            icon={<StatusGood color="text-strong" />}
            onClose={() => setShowSuccessNotification(false)}
            testId="status-good-notification"
            text={t('mfa_details_updated_successfully')}
          />
        )}
      </Box>
    </Box>
  )
}

EditMFADetails.propTypes = {
  customerDataResponse: PropTypes.object.isRequired,
  setCustomerDataResponse: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  fetchCustomerDetails: PropTypes.func.isRequired,
  dataChanged: PropTypes.bool.isRequired,
  setDataChanged: PropTypes.func.isRequired
}

export { EditMFADetails }
