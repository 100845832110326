// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes, Navigate } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const PortalCustomization = lazy(() => import('./PortalCustomization'))

const PortalCustomizationRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<PortalCustomization />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default PortalCustomizationRouter
