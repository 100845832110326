// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import { checkGotoChooseAccount } from '../../utils/common-utils'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import useFetchIsCCSManager from '../../hooks/ccs-manager/useFetchIsCCSManager'

import Preferences from './components/preferences/Preferences'

const NotificationPage = lazy(() => import('./Notifications'))
const NotificationDetails = lazy(() =>
  import('./components/NotificationDetails')
)
const EventDetails = lazy(() =>
  import('./NotificationLogs/DetailsPage/EventDetails')
)

const NotificationRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)
  const { isCCSManager, isLoading } = useFetchIsCCSManager()

  return (
    <Suspense fallback={<LazyLoading />}>
      {isLoading && <LazyLoading />}
      {!isLoading && (
        <Routes>
          {isCCSManager && navigate('/manage-ccs/notifications')}
          <Route exact path="/preferences" element={<Preferences />} />
          <Route
            exact
            path="/email-preferences"
            element={<NotificationPage />}
          />
          <Route exact path="/:id" element={<NotificationDetails />} />
          <Route exact path="/" element={<NotificationPage />} />
          <Route exact path="/alerts/:id" element={<EventDetails />} />
          <Route exact path="/tasks/:id" element={<EventDetails />} />
          <Route path="/*" element={<Navigate to="/not-found" />} />
        </Routes>
      )}
    </Suspense>
  )
}

export default NotificationRouter
