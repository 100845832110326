// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button } from '../../components'
import { getMSPCustomerAccount } from '../../utils/feature-flag-utils'
import { getWorkspaceString, WKSPC_CAPITALIZED } from '../../utils/common-utils'

const SwitchAccountButton = ({ ...rest }) => {
  const { t } = useTranslation(['common'])
  const swappedMSPAccount = getMSPCustomerAccount()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const navigate = useNavigate()
  const handleSwitchAccountClick = () => {
    navigate('/switch-account')
  }

  const handleReturnToMSPClick = () => {
    navigate('/post-onboarding/choose-account')
  }

  return swappedMSPAccount ? (
    <Button
      label={t('common:nav_bar.return_to_MSP', {
        workspace: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      })}
      onClick={handleReturnToMSPClick}
      secondary
      testId="return-to-msp-account-btn"
      type="button"
      {...rest}
    />
  ) : (
    <Button
      label={t('common:nav_bar.switch_account', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      })}
      onClick={handleSwitchAccountClick}
      secondary
      testId="switch-account-btn"
      type="button"
      {...rest}
    />
  )
}

export default SwitchAccountButton
