// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { Hpe } from 'grommet-icons'
import { filter, includes } from 'lodash'

import { MainNav } from '../main-nav/MainNav'
import { RightNav } from '../right-nav/RightNav'
import { GLCPHeader } from '../../../components'
import { BellNewNotification } from '../bell-notification/BellNewNotification'
import { GLCPManagerNav } from '../glcp-manager-nav/GLCPManagerNav'
import { ContextHelp } from '../context-help/ContextHelp'

const HPELogo = <Hpe color="brand" size="large" />
const defaultBrand = {
  logo: HPELogo,
  logoLink: '/',
  orgName: 'HPE',
  appName: 'GreenLake'
}
const filteredDropMenus = (hideHeaderOptions) => {
  return (
    (hideHeaderOptions?.length &&
      filter(RightNav, (menu) => !includes(hideHeaderOptions, menu.id))) ||
    RightNav
  )
}
const HeaderWithBrandOnly = () => {
  const handleBrandClick = () => {
    window.location.reload()
  }
  return (
    <GLCPHeader
      brand={{ ...defaultBrand, onClickBrand: handleBrandClick }}
      testId="glcp-header-brand-only"
    />
  )
}

const HeaderWithBrandAndHelp = ({ options }) => {
  const navigate = useNavigate()
  const handleBrandClick = () => {
    navigate(`/post-onboarding/choose-account`)
  }
  return (
    <GLCPHeader
      brand={{ ...defaultBrand, onClickBrand: handleBrandClick }}
      dropMenu={filteredDropMenus(options)}
      testId="glcp-header-brand-n-help"
    />
  )
}
HeaderWithBrandAndHelp.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired
}

const HeaderWithDropMenus = ({ options }) => {
  const navigate = useNavigate()
  const handleBrandClick = () => {
    navigate(`/post-onboarding/choose-account`)
  }
  return (
    <GLCPHeader
      brand={{ ...defaultBrand, onClickBrand: handleBrandClick }}
      dropMenu={filteredDropMenus(options)}
      testId="glcp-header-brand-n-profile"
    />
  )
}
HeaderWithDropMenus.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired
}

const HeaderWithAllOptions = ({ options }) => {
  const navigate = useNavigate()
  const handleBrandClick = () => {
    navigate('/home')
  }
  return (
    <GLCPHeader
      brand={{ ...defaultBrand, onClickBrand: handleBrandClick }}
      notification={<BellNewNotification />}
      navigation={<MainNav />}
      dropMenu={filteredDropMenus(options)}
      contextHelp={<ContextHelp />}
      testId="glcp-header-all"
    />
  )
}
HeaderWithAllOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired
}

const GLCPManagerHeader = ({ options }) => {
  const navigate = useNavigate()
  const handleBrandClick = () => {
    navigate('/manage-ccs/home')
  }
  return (
    <GLCPHeader
      brand={{ ...defaultBrand, onClickBrand: handleBrandClick }}
      notification={<BellNewNotification />}
      navigation={<GLCPManagerNav />}
      dropMenu={filteredDropMenus(options)}
      testId="glcp-manager-header"
    />
  )
}
GLCPManagerHeader.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired
}

export {
  HeaderWithBrandOnly,
  HeaderWithBrandAndHelp,
  HeaderWithDropMenus,
  HeaderWithAllOptions,
  GLCPManagerHeader
}
