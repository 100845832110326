// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React from 'react'
import PropTypes from 'prop-types'
import { DocumentImage } from 'grommet-icons'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import omit from 'lodash/omit'

import { Typography } from '../../../components'
import { CUSTOMER_READ_MODE, getCountryName, NO_DATA } from '../utils'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

import { CustomerDetailsPageHeader } from './CustomerDetailsPageHeader'

const ViewCustomerDetails = ({
  customerAccountDetails,
  countryList,
  setMode
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const viewDetailsi18n = {
    customer_logo: t('customer_account.account_logo', {
      account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
    }),
    company_name: t('customer_account.company_name', {
      company: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
    }),
    description: t('customer_account.description'),
    address: t('customer_account.address'),
    customer_contact: t('customer_account.customer_contact'),
    creation_date: t('customer_account.creation_date'),
    last_updated: t('customer_account.last_updated')
  }
  const getAccountLogo = (logoContent) => {
    return logoContent?.logo_filename ? (
      <Box direction="row-responsive" gap="small">
        <DocumentImage />
        <Typography
          size="medium"
          color="text-strong"
          testId="details-value"
          type="text"
        >
          {logoContent?.logo_filename}
        </Typography>
      </Box>
    ) : (
      NO_DATA
    )
  }
  const getAddress = (address) => {
    return (
      <>
        <Typography type="text" size="medium" testId="country-value">
          {getCountryName(address?.country_code, countryList)}
        </Typography>
        <Typography type="text" size="medium" testId="street-value">
          {address?.street_address}
        </Typography>
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-msp-add-customer-field'
          }}
        >
          <Typography type="text" size="medium" testId="street-2-value">
            {address?.street_address_2}
          </Typography>
        </VisibilityWrapper>
        <Typography type="text" size="medium" testId="city-state-value">
          {address?.city} {address?.state_or_region}
        </Typography>
        <Typography type="text" size="medium" testId="zip-value">
          {address?.zip}
        </Typography>
      </>
    )
  }

  const getContact = (contact) => {
    return contact?.email || contact?.phone ? (
      <>
        <Typography type="text" size="medium" testId="country-value">
          {contact?.email}
        </Typography>
        <Typography type="text" size="medium" testId="street-value">
          {contact?.phone}
        </Typography>
      </>
    ) : (
      NO_DATA
    )
  }

  const getFormattedData = (key, value) => {
    switch (key) {
      case 'customer_logo':
        return getAccountLogo(value)
      case 'address':
        return getAddress(value)
      case 'customer_contact':
        return getContact(value)
      case 'creation_date':
      case 'last_updated':
        return dayjs(value).format('MMMM D, YYYY')
      default:
        return (
          <Typography
            size="medium"
            color="text-strong"
            testId="details-value"
            type="text"
            wordBreak="break-all"
          >
            {value}
          </Typography>
        )
    }
  }
  return (
    <>
      <CustomerDetailsPageHeader
        customerAccountDetails={customerAccountDetails}
        mode={CUSTOMER_READ_MODE}
        setMode={setMode}
      />
      <Box direction="column" justify="start" alignSelf="center" width="large">
        <>
          <Box direction="column" pad={{ bottom: 'large' }}>
            <Box alignSelf="start" justify="between" direction="column">
              {Object.entries(
                omit(customerAccountDetails, ['operational_mode'])
              )?.map((datum, index) => (
                <Box
                  direction="row-responsive"
                  justify="start"
                  key={datum[0]}
                  data-testid={`${datum[0]}-field`}
                  margin={{ top: index === 0 ? 'none' : 'small' }}
                >
                  <Box width="medium">
                    <Typography
                      size="medium"
                      weight="bold"
                      testId="details-key"
                      type="text"
                    >
                      {viewDetailsi18n[datum[0]]}
                    </Typography>
                  </Box>
                  <Box
                    width="large"
                    margin={{ left: 'medium' }}
                    pad={{ left: 'small' }}
                  >
                    {datum[1] ? getFormattedData(datum[0], datum[1]) : NO_DATA}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      </Box>
    </>
  )
}

ViewCustomerDetails.propTypes = {
  customerAccountDetails: PropTypes.object.isRequired,
  countryList: PropTypes.array.isRequired,
  setMode: PropTypes.func.isRequired
}

export { ViewCustomerDetails }
