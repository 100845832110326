// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { lazy, React, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const LocationDetailsPage = lazy(() =>
  import(
    '../../../commoncomponents/location-management/location-details/LocationDetailsPage'
  )
)
const CreateLocationPage = lazy(() => import('./CreateLocationPage'))
const ValidateLocationPage = lazy(() => import('./ValidateLocationPage'))
const LocationManagementPage = lazy(() => import('./LocationManagementPage'))

const LocationManagementRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route
          path="/create-location"
          element={
            <VisibilityWrapper
              fallbackComponent={<Navigate to="/home" />}
              hideFor={{
                deployment: ['COP', 'GLOP']
              }}
              rbac={{
                resource: '/ccs/location-management',
                permission: 'ccs.location-management.edit'
              }}
            >
              <CreateLocationPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/validate-location/:locationId"
          element={
            <VisibilityWrapper
              fallbackComponent={<Navigate to="/home" />}
              hideFor={{
                deployment: ['COP', 'GLOP']
              }}
              rbac={{
                resource: '/ccs/location-management',
                permission: 'ccs.location-management.edit'
              }}
            >
              <ValidateLocationPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/:locationId"
          element={
            <VisibilityWrapper
              fallbackComponent={<Navigate to="/home" />}
              hideFor={{
                deployment: ['COP', 'GLOP']
              }}
              rbac={{
                resource: '/ccs/location-management',
                permission: 'ccs.location-management.view'
              }}
            >
              <LocationDetailsPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/"
          element={
            <VisibilityWrapper
              fallbackComponent={<Navigate to="/home" />}
              hideFor={{
                deployment: ['COP', 'GLOP']
              }}
              rbac={{
                resource: '/ccs/location-management',
                permission: 'ccs.location-management.view'
              }}
            >
              <LocationManagementPage />
            </VisibilityWrapper>
          }
        />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default LocationManagementRouter
