// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'

let modifiedResponse
export const modifiedLocationResponses = (locationResponse) => {
  const primaryContact = locationResponse?.contacts?.find(
    (value) => value.type === 'primary'
  )
  let primary_contact = ''
  if (primaryContact?.name === 'NONGLP') {
    primary_contact = primaryContact?.email
  } else {
    primary_contact =
      primaryContact?.name || primaryContact?.email
        ? `${primaryContact?.name || '--'} (${primaryContact?.email || ''})`
        : '--'
  }
  const streetAddress = locationResponse?.addresses?.find(
    (value) => value.type === 'street'
  )
  const shippingReceivingAddress = locationResponse?.addresses?.find(
    (value) => value.type === 'shipping_receiving'
  )
  modifiedResponse = {
    name: locationResponse?.name || '--',
    description: locationResponse?.description || '--',
    primary_contact,
    street_address: streetAddress || '--',
    shipping_receiving: shippingReceivingAddress || '--'
  }
  return modifiedResponse
}

export const getLocationDetails = (
  locationId,
  accessToken,
  setErrorMessage,
  t,
  isCCSManager,
  customerId
) => {
  const url = isCCSManager
    ? `/support-assistant/v1alpha1/location`
    : '/ui-doorway/ui/v1/locations'
  const reqBody = {}
  if (isCCSManager) reqBody.platform_customer_id = customerId
  return get(`${url}/${locationId}`, reqBody, accessToken).then(
    (response) => {
      const locationResponse = response?.data
      if (locationResponse) {
        modifiedLocationResponses(locationResponse)
        return modifiedResponse
      }
      return null
    },
    (error) => {
      setErrorMessage(displayApiError(error, t, setErrorMessage))
    }
  )
}
