// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import {
  Box,
  FormField,
  Grommet,
  TextArea,
  TextInput,
  ResponsiveContext
} from 'grommet'
import React, { useEffect, useReducer, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useParams } from 'react-router-dom'
import { Trash } from 'grommet-icons'
import styled from 'styled-components'
import { hpe } from 'grommet-theme-hpe'
import { deepMerge } from 'grommet/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  CCSForm,
  Dropdown,
  Typography,
  FileInput,
  Button
} from '../../../components'
import {
  CUSTOMER_EDIT_MODE,
  DESCRIPTION_MAX_LIMIT,
  PHONE_PLACEHOLDER
} from '../utils'
import {
  validateTenantName,
  validateStreetAddress,
  validateZipCode,
  validateCity,
  IMAGE_FILE_SIZE,
  validateFileInput,
  validateMaxLength,
  validateEmail,
  validateTenantDescription,
  ALLOWED_IMAGE_TYPES
} from '../../../utils/validation-utils'
import { getApiErrorMessage } from '../../../utils/error-handling-utils'
import { put } from '../../../utils/api-utils'
import {
  fileToBase64,
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { CustomerDetailsPageHeader } from './CustomerDetailsPageHeader'

const StyledFormField = styled(FormField)`
  & > div {
    border: none;
  }
`

const EditCustomerDetails = ({
  customerAccountDetails,
  countryList,
  setMode,
  onSuccess
}) => {
  const { customerId } = useParams()
  const { oidcUser } = useReactOidc()
  const [countries, setCountries] = useState(countryList || [])
  const [loader, setLoader] = useState(false)
  const [originalLogo, setOriginalLogo] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const size = useContext(ResponsiveContext)

  const { t } = useTranslation(['authn', 'common'])
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        case 'SET_STATE':
          return { ...action.value }
        case 'CHANGE_LOGO':
          return {
            ...currentState,
            logo: action.value?.[0] || null,
            logo_filename: action.value?.[1] || null
          }
        default:
          return currentState
      }
    },
    {
      logo: '',
      logo_filename: '',
      company_name: '',
      description: '',
      country_code: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state_or_region: '',
      zip: '',
      email: '',
      phone: ''
    }
  )

  useEffect(() => {
    setLoader(true)
    const {
      customer_logo: { logo, logo_filename: logoFilename } = {},
      address: {
        street_address: streetAddress,
        street_address_2: streetAddress2,
        city,
        state_or_region: stateOrRegion,
        zip,
        country_code: countryCode
      } = {},
      company_name: companyName,
      description,
      customer_contact: { email, phone } = {}
    } = customerAccountDetails || ''
    const newState = {
      logo: null,
      logo_filename: null,
      company_name: companyName,
      description,
      country_code: countryCode || '',
      street_address: streetAddress || '',
      street_address_2: streetAddress2 || '',
      city: city || '',
      state_or_region: stateOrRegion || '',
      zip: zip || '',
      email: email || '',
      phone: phone || ''
    }
    setOriginalLogo({
      logo: logo || '',
      logo_filename: logoFilename || ''
    })
    dispatch({
      value: newState,
      type: 'SET_STATE'
    })
    setLoader(false)
  }, [customerAccountDetails])

  const setLogo = async (requestBody, logo) => {
    // To check if user has selected new logo
    if (logo instanceof File) {
      const customerLogo = {
        logo: await fileToBase64(logo),
        logo_filename: logo.name
      }
      requestBody.customer_logo = customerLogo
    } // To check if user has previously uploaded logo
    else if (customerAccountDetails?.customer_logo?.logo) {
      // To check if user has not deleted previously uploaded logo
      if (logo) {
        requestBody.customer_logo = {
          logo: customerAccountDetails?.customer_logo?.logo,
          logo_filename: customerAccountDetails?.customer_logo?.logo_filename
        }
      } else {
        requestBody.customer_logo = { logo: null, logo_filename: null }
      }
    }
  }

  const handleSave = async () => {
    setLoader(true)
    const requestBody = {
      customer_id: customerId,
      company_name: state.company_name,
      description: state.description,
      address: {
        street_address: state.street_address,
        street_address_2: state.street_address_2,
        city: state.city || null,
        state_or_region: state.state_or_region || null,
        zip: state.zip || null,
        country_code: state.country_code
      },
      email: state.email || null,
      phone_number: state.phone || null
    }
    const newLogo = state.logo || originalLogo
    await setLogo(requestBody, newLogo)

    put(
      `/accounts/ui/v1/managed-service/tenant`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setLoader(false)
        onSuccess()
      },
      (error) => {
        setLoader(false)
        const backendErrorMessage = getApiErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  const getFieldLabel = (label, testId, disclaimer) => {
    return (
      <Box width="medium" justify="start">
        <Typography
          type="text"
          size="medium"
          weight="bold"
          testId={`${testId}-label`}
          color={loader ? 'status-disabled' : 'text'}
        >
          {label}
        </Typography>
        {disclaimer && (
          <Typography
            type="text"
            size="xsmall"
            color={loader ? 'status-disabled' : 'text'}
            testId={`${testId}-disclaimer`}
          >
            {disclaimer}
          </Typography>
        )}
      </Box>
    )
  }

  const getFormInput = (name, placeholder, validate, required) => {
    return (
      <Box width="large">
        <FormField
          required={required}
          name={name}
          validate={validate}
          data-testid={`${name}-form-field`}
        >
          <TextInput
            value={state[name]}
            name={name}
            placeholder={placeholder}
            data-testid={`${name}-form-input`}
            disabled={loader}
            onChange={(event) =>
              dispatch({
                value: event.target.value,
                field: name,
                type: 'CHANGE_FIELD'
              })
            }
          />
        </FormField>
      </Box>
    )
  }

  return (
    <CCSForm
      value={state}
      onReset={() => {}}
      onSubmit={handleSave}
      errorMessage={errorMessage}
      validate="change"
      testId="edit-customer-form"
    >
      <>
        <CustomerDetailsPageHeader
          customerAccountDetails={customerAccountDetails}
          mode={CUSTOMER_EDIT_MODE}
          setMode={setMode}
          loader={loader}
        />
        <Box direction="column" justify="start" pad={{ bottom: 'large' }}>
          <Box gap="small">
            {/* Account Image form field */}
            <Box
              direction={
                size === 'small' || size === 'xsmall' ? 'column' : 'row'
              }
              gap={size === 'small' || size === 'xsmall' ? 'none' : 'large'}
            >
              {getFieldLabel(
                t('customer_account.account_image', {
                  account: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                }),
                'account-image',
                t('customer_account.customer_logo_disclaimer')
              )}
              <Box direction="column" gap="xsmall">
                <StyledFormField
                  width="large"
                  data-testid="account-image-form-field"
                  validate={() =>
                    validateFileInput(
                      state.logo,
                      IMAGE_FILE_SIZE.logo,
                      ALLOWED_IMAGE_TYPES,
                      t
                    )
                  }
                  disabled={loader}
                >
                  <FileInput
                    pad="xsmall"
                    testId="account-image-input"
                    accept={ALLOWED_IMAGE_TYPES}
                    onChange={(event) => {
                      dispatch({
                        value: event?.target?.files?.length
                          ? [event.target.files[0], event.target.files[0].name]
                          : null,
                        type: 'CHANGE_LOGO'
                      })
                    }}
                    disabled={loader}
                  />
                </StyledFormField>

                {originalLogo?.logo && !state.logo ? (
                  <Box
                    direction="row"
                    align="center"
                    width="medium"
                    justify="between"
                  >
                    <Typography
                      type="text"
                      size="medium"
                      testId="existing-logo-filename"
                      color={loader ? 'status-disabled' : 'text'}
                    >
                      {originalLogo?.logo_filename}
                    </Typography>
                    <Button
                      onClick={() => {
                        dispatch({
                          value: null,
                          field: 'logo',
                          type: 'CHANGE_FIELD'
                        })
                        setOriginalLogo(null)
                      }}
                      testId="delete-existing-logo-filename-btn"
                      disabled={loader}
                    >
                      <Trash
                        size="medium"
                        color={loader ? 'status-disabled' : 'text'}
                      />
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>

            {/* Company Name Form Field */}
            <Box
              direction={
                size === 'small' || size === 'xsmall' ? 'column' : 'row'
              }
              gap={size === 'small' || size === 'xsmall' ? 'none' : 'large'}
            >
              {getFieldLabel(
                t('customer_account.compant_name_required', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                }),
                'company-name'
              )}
              {getFormInput(
                'company_name',
                t('customer_account.company_placeholder', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                }),
                (value) => {
                  if (validateTenantName(value))
                    return t('customer_account.tenant_name_error_message')
                  return true
                },
                true
              )}
            </Box>

            {/* Description Form Field */}
            <Box
              direction={
                size === 'small' || size === 'xsmall' ? 'column' : 'row'
              }
              gap={size === 'small' || size === 'xsmall' ? 'none' : 'large'}
            >
              {getFieldLabel(
                t('customer_account.description_required'),
                'description',
                !validateMaxLength(state?.description, DESCRIPTION_MAX_LIMIT)
                  ? t('customer_account.customer_description_help_text', {
                      max_limit: DESCRIPTION_MAX_LIMIT
                    })
                  : t('customer_account.add_customer_description_error_msg')
              )}
              <Box width="large">
                <Grommet
                  theme={deepMerge(hpe, {
                    formField: {
                      error: {
                        color: 'status-critical',
                        container: {
                          direction: 'row',
                          justify: 'end'
                        },
                        icon: <></>
                      },
                      info: {
                        container: { direction: 'row', justify: 'end' },
                        color: loader ? 'status-disabled' : 'text'
                      }
                    }
                  })}
                >
                  <FormField
                    name="description"
                    data-testid="description-form-field"
                    required
                    validate={() => {
                      return validateTenantDescription(
                        state?.description,
                        DESCRIPTION_MAX_LIMIT,
                        t('customer_account.customer_description_info_text', {
                          current_length: state?.description?.length,
                          max_limit: DESCRIPTION_MAX_LIMIT
                        })
                      )
                    }}
                    disabled={loader}
                  >
                    <TextArea
                      fill
                      data-testid="description-input"
                      name="description"
                      placeholder={t(
                        'customer_account.description_placeholder'
                      )}
                      value={state.description}
                      onChange={(e) => {
                        dispatch({
                          value: e.target.value,
                          field: 'description',
                          type: 'CHANGE_FIELD'
                        })
                      }}
                      disabled={loader}
                    />
                  </FormField>
                </Grommet>
              </Box>
            </Box>

            {/* Address Form Field */}
            <Box
              direction={
                size === 'small' || size === 'xsmall' ? 'column' : 'row'
              }
              gap={size === 'small' || size === 'xsmall' ? 'none' : 'large'}
            >
              {getFieldLabel(t('customer_account.address_required'), 'address')}
              <Box direction="column" width="large">
                {/* Country Form Field */}
                <FormField
                  name="country_code"
                  required
                  data-testid="country-form-field"
                  disabled={loader}
                >
                  <Dropdown
                    name="country_code"
                    placeholder={t('customer_account.country')}
                    options={countries}
                    labelKey="name"
                    value={state.country_code}
                    valueKey={{ key: 'code', reduce: true }}
                    searchPlaceholder={t('customer_account.country')}
                    emptySearchMessage={t(
                      'customer_account.country_empty_search_message'
                    )}
                    onSearch={(searchText) => {
                      const regexp = new RegExp(searchText, 'i')
                      setCountries(
                        countryList?.filter((o) => o?.name?.match(regexp))
                      )
                    }}
                    onChange={({ option }) => {
                      dispatch({
                        value: option?.code,
                        field: 'country_code',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    onClose={() => setCountries(countryList || [])}
                    noBorder
                    testId="country-input"
                    disabled={loader}
                  />
                </FormField>

                {/* Street Address Form Field */}
                {getFormInput(
                  'street_address',
                  t('customer_account.street_address'),
                  (value) => {
                    if (!validateStreetAddress(value))
                      return t('customer_account.max_chars_exceeded')
                    return true
                  },
                  true
                )}
                {/* Apt, Suite, Building Form Field */}
                <VisibilityWrapper
                  hideFor={{
                    feature: 'glcp-msp-add-customer-field'
                  }}
                >
                  {getFormInput(
                    'street_address_2',
                    t('customer_account.apt_suite_building'),
                    (value) => {
                      if (!validateStreetAddress(value))
                        return t('customer_account.max_chars_exceeded')
                      return true
                    }
                  )}
                </VisibilityWrapper>

                <Box direction="row-responsive" gap="small">
                  {/* City Form Field */}
                  {getFormInput('city', t('customer_account.city'), (value) => {
                    if (!validateCity(value))
                      return t('customer_account.max_chars_exceeded_city')
                    return true
                  })}

                  {/* Region Form Field */}
                  {getFormInput(
                    'state_or_region',
                    t('customer_account.region')
                  )}
                </Box>

                {/* Zip Form Field */}
                {getFormInput(
                  'zip',
                  t('customer_account.zip_placeholder'),
                  (value) => {
                    if (!validateZipCode(value))
                      return t('customer_account.max_chars_exceeded')
                    return true
                  }
                )}
              </Box>
            </Box>

            {/* Email Form Field */}
            <Box
              direction={
                size === 'small' || size === 'xsmall' ? 'column' : 'row'
              }
              gap={size === 'small' || size === 'xsmall' ? 'none' : 'large'}
            >
              {getFieldLabel(t('customer_account.email'), 'email')}
              {getFormInput(
                'email',
                t('customer_account.email_placeholder'),
                (value) => {
                  return validateEmail(value)
                }
              )}
            </Box>

            {/* Phone Form Field */}
            <Box
              direction={
                size === 'small' || size === 'xsmall' ? 'column' : 'row'
              }
              gap={size === 'small' || size === 'xsmall' ? 'none' : 'large'}
            >
              {getFieldLabel(t('customer_account.phone_number'), 'phone')}
              {getFormInput('phone', PHONE_PLACEHOLDER)}
            </Box>
          </Box>
        </Box>
      </>
    </CCSForm>
  )
}

EditCustomerDetails.propTypes = {
  customerAccountDetails: PropTypes.object.isRequired,
  countryList: PropTypes.array.isRequired,
  setMode: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export { EditCustomerDetails }
