// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'

import CustomerAccountPage from './customer-account'
import { CustomerDetails } from './customer-details'

const CustomerAccountRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <Routes>
      <Route exact path="/edit/:customerId" element={<CustomerDetails />} />
      <Route exact path="/view/:customerId" element={<CustomerDetails />} />

      {/* NOTE: Always add any routes above this route */}
      <Route path="/" element={<CustomerAccountPage />} />
    </Routes>
  )
}

export default CustomerAccountRouter
