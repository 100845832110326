// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { LinkNext } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Button, Typography } from '../shims/imports'

const ServiceCatalogCard = ({
  service: { serviceSlug, name, detailsPath, description },
  t
}) => {
  const navigate = useNavigate()
  return (
    <Button
      testId="service-catalog-card"
      key={name}
      a11yTitle={`${t(`dashboard:common.view_details`)} ${name}`}
      onClick={(e) => {
        e.preventDefault()
        navigate(`/services/service-catalog/${detailsPath}`)
      }}
      hoverIndicator
    >
      <Box fill gap="small">
        <Typography type="heading" level={4}>
          {name}
        </Typography>
        <Typography type="text" size="small">
          {t(`services:${serviceSlug}.short_description`, description)}
        </Typography>
        <LinkNext color="brand" />
      </Box>
    </Button>
  )
}

ServiceCatalogCard.propTypes = {
  service: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default ServiceCatalogCard
