// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
// import { StatusWarningSmall } from 'grommet-icons'
import { Anchor, Box, Text } from 'grommet'
import {
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusWarningSmall
} from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { get } from '../../../../utils/api-utils'
import { PopUpError } from '../../../device-management/device-map/components/PopUpError'
import { retryPopUpRender } from '../../../device-management/utils'
import { Typography } from '../../../../components'
import { AssetPopupSkeletionLoader } from '../utils'

export const SingleAssetData = ({ asset }) => {
  const { oidcUser } = useReactOidc()
  const [pinData, setPinData] = useState(null)
  const [popUpCase, setPopUpCase] = useState(null)
  const { t } = useTranslation(['manage'])
  const navigate = useNavigate()

  const getPopUpData = useCallback(() => {
    const params = {
      lat: asset?.location?.lat,
      long: asset?.location?.long
    }
    get('/internal-assets/v1beta1/assets', params, oidcUser.access_token).then(
      (response) => {
        setPinData(response.data)
        setPopUpCase('ASSET_SUMMARY_DATA')
      },
      (error) => {
        setPinData([])
        setPopUpCase('ASSET_SUMMARY_ERROR')
        console.log(error)
      }
    )
  }, [asset?.location?.lat, asset?.location?.long, oidcUser?.access_token])

  useEffect(() => {
    getPopUpData()
  }, [getPopUpData])

  const PopupRender = () => {
    switch (popUpCase) {
      case 'ASSET_SUMMARY_ERROR':
        return (
          <PopUpError
            heading={t('asset_management.assets_map.risk_summary')}
            onAnchorClick={() =>
              retryPopUpRender(setPinData, setPopUpCase, getPopUpData)
            }
          />
        )
      case 'ASSET_SUMMARY_DATA':
        return (
          <Box margin={{ horizontal: 'small' }} width="medium">
            <Text
              margin={{ left: 'small', top: 'small' }}
              weight={500}
              size="large"
              data-testid="device-summary-heading"
            >
              {pinData?.items[0]?.serial}
            </Text>
            {pinData?.items[0]?.riskSupportLevel === 1 &&
            pinData?.items[0]?.riskAgeLevel === 1 ? (
              <Box
                margin={{ vertical: 'xsmall' }}
                pad={{ top: 'xsmall' }}
                direction="row"
                align="center"
                basis="full"
              >
                <Box align="center" margin={{ right: 'xsmall' }}>
                  <StatusGoodSmall size="small" color="status-ok" />
                </Box>
                <Typography
                  type="text"
                  data-testid="cluster-single-red-support"
                >
                  {t('asset_management.assets_map.single.green')}
                </Typography>
              </Box>
            ) : (
              <Box
                margin={{ horizontal: 'xsmall', vertical: 'xsmall' }}
                pad={{ top: 'xsmall' }}
                gap="xsmall"
                data-testid="cluster-single-list"
              >
                {pinData.items[0].riskSupportLevel === 3 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusCriticalSmall
                        size="small"
                        color="status-critical"
                      />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-single-red-support"
                    >
                      {t('asset_management.assets_map.single.red_support')}
                    </Typography>
                  </Box>
                )}
                {pinData.items[0].riskAgeLevel === 3 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusCriticalSmall
                        size="small"
                        color="status-critical"
                      />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-single-red-age"
                    >
                      {t('asset_management.assets_map.single.red_age')}
                    </Typography>
                  </Box>
                )}
                {pinData.items[0].riskSupportLevel === 2 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusWarningSmall size="small" color="status-warning" />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-single-yellow-support"
                    >
                      {t('asset_management.assets_map.single.yellow_support')}
                    </Typography>
                  </Box>
                )}
                {pinData.items[0].riskAgeLevel === 2 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusWarningSmall size="small" color="status-warning" />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-single-yellow-age"
                    >
                      {t('asset_management.assets_map.single.yellow_age')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}

            <Typography
              type="text"
              size="medium"
              margin={{ left: 'small', top: 'xsmall', bottom: 'small' }}
            >
              <Anchor
                href="#"
                label={t('view_details')}
                onClick={() => {
                  navigate(
                    `/manage-account/asset-management/assets/${pinData?.items[0]?.id}`
                  )
                }}
                data-testid="go-to-asset-details"
                color="black !important"
              />
            </Typography>
          </Box>
        )
      default:
        return null
    }
  }

  return asset.assetStats.total === 1 && pinData ? (
    PopupRender()
  ) : (
    <AssetPopupSkeletionLoader />
  )
}

SingleAssetData.propTypes = {
  asset: PropTypes.object.isRequired
}
