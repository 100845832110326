// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { find } from 'lodash'

import { resetAuditLogContext } from '../context/auditlogs-context/reducer'
import { CCSActions, useCCSContext } from '../context/ccs-context'
import { useUPSContext } from '../context/ups-context'
import {
  useVisibilityContext,
  VisibilityActions
} from '../context/visibility-context'
import useLD from '../hooks/ld/useLD'
import useLogger from '../hooks/logs/useLogger'

import { updateRBACPolicies, updateV2RBACPolicies } from './rbac-api-utils'
import { updateUPSData } from './ups-utils'
import { del, get, post, setupInterceptor } from './api-utils'
import { v2EndSessionCleanup } from './client-storage-cleaning-utils'
import {
  isV2,
  getMSPCustomerAccount,
  getNumCustomerAccounts,
  isCoP,
  isGLOP,
  setCustomerAccount,
  updateTACFlags
} from './feature-flag-utils'
import { V2_LOGGER } from './log-util'

const getGlpxId = () => {
  return document?.cookie?.split('; ')?.find((row) => row.startsWith('GLPXID'))
}

const v2Logger = {
  page: 'account-utils',
  ...V2_LOGGER,
  section: 'session-mgmt'
}

// Function to end v2 session
const callEndOrgSession = async (dispatchCCSContext) => {
  // end org session
  try {
    await del(`/internal-sessions/v1alpha1/my-ui-session`)
    if (dispatchCCSContext) {
      dispatchCCSContext({
        type: CCSActions.SET_CSRF_TOKEN,
        data: null
      })
    }
  } catch (err) {
    // Ignore 404 errors
    if (err?.response?.status !== 404) {
      console.log(
        'If the problem persists, try logout and logging back in.',
        err
      )
    }
  }
}

export const establishV2SessionOnLaunch = (
  oidcUser,
  custAccount,
  csrfToken,
  successLocation,
  navigate,
  dispatchCCSContext,
  dispatchVisibilityContext,
  logger
) => {
  logger?.log({
    ...v2Logger,
    msg: `landed in establishV2SessionOnLaunch function`,
    type: 'info'
  })
  const v2Redirection = () => {
    logger?.log({
      ...v2Logger,
      msg: `in v2Redirection`,
      type: 'info'
    })
    const post_url = `${
      window.$settings.orgBaseUrl
    }/internal-sessions/v1alpha1/my-ui-session/set-scope?login-hint=${encodeURIComponent(
      oidcUser?.profile?.email
    )}&request-scope=workspace&scope-id=${encodeURIComponent(
      custAccount?.platform_customer_id
    )}&success-location=${encodeURIComponent(successLocation)}`
    // Note: UI cannot take advantage of failure-location query param provided by BE yet because of the way we UI sets account in stroage. Leaving it empty will default to '/home'.

    const form = document.createElement('form')
    form.id = 'org-v2-session-form'
    form.method = 'post'
    form.action = post_url
    document.body.appendChild(form)

    // if csrfToken exists from previous failed deleted v2 session, add the token
    // as hidden form input field
    // csrf token is required when changing an org id (a new id is set or a switch of org to org workspace)
    // csrf token is not required if we already have an active BE session and we are just asking for the old csrf value
    if (csrfToken) {
      const hiddenCsrfTokenInput = document.createElement('input')
      hiddenCsrfTokenInput.setAttribute('type', 'hidden')
      hiddenCsrfTokenInput.setAttribute('name', 'X-CSRF-TOKEN')
      hiddenCsrfTokenInput.setAttribute('value', csrfToken)
      document
        .getElementById('org-v2-session-form')
        .appendChild(hiddenCsrfTokenInput)
    }
    form.submit()
  }

  // Call .../my-ui-session to get workspace id for the currrent active session on the server.
  // If workspace id matchs the curr workspace being launched then BE will not redirect on set-scope call (this can happen if account was
  // deleted from session storage and user was sent to choose-account and is re-launching)
  // Since no redirect (no CORs issue), we can use axios to make the call to set-scope instead of the usual form submit
  get('/internal-sessions/v1alpha1/my-ui-session').then(
    (response) => {
      const retrievedWorkspaceId = response?.data?.scope?.workspaceId
      if (retrievedWorkspaceId === custAccount?.platform_customer_id) {
        // Here, the previous v2 session is for the same workspace, so we can make an AJAX call to get the 204 and push the user to the home page instead of waiting for a 302 redirection
        post(
          `/internal-sessions/v1alpha1/my-ui-session/set-scope?login-hint=${encodeURIComponent(
            oidcUser?.profile?.email
          )}&request-scope=workspace&scope-id=${encodeURIComponent(
            custAccount?.platform_customer_id
          )}`
        ).then(
          () => {
            const glpxid = getGlpxId()
            let cookieCsrfToken
            if (glpxid) {
              const [, ...rest] = glpxid.split('=')
              cookieCsrfToken = rest.join('=')
              dispatchCCSContext({
                type: CCSActions.SET_CSRF_TOKEN,
                data: cookieCsrfToken
              })
            }
            const constructedGrn = `grn:glp/workspaces/${retrievedWorkspaceId}`
            dispatchCCSContext({
              type: CCSActions.SET_WORKSPACE_GRN,
              data: constructedGrn
            })
            setupInterceptor(
              dispatchCCSContext,
              cookieCsrfToken,
              navigate,
              true
            )
            dispatchVisibilityContext({
              type: VisibilityActions.SET_HIDE_WHEN,
              data: {
                key: 'account',
                value: custAccount.account_type
              }
            })
            logger?.log({
              ...v2Logger,
              msg: `v2 session established`,
              type: 'success'
            })
            // Push the user to the home page
            navigate('/home')
          },
          (error) => {
            // Error Code 1 is the generic error code for any error that occurs during the establishment of the v2 session, i18n has 'Unable to launch into workspace' as the message for this error code.
            const orgObj = {
              workspace_id: custAccount?.platform_customer_id,
              error_code: 1,
              error_message: error
            }
            sessionStorage.setItem('orgLaunchInfo', JSON.stringify(orgObj))
            callEndOrgSession(dispatchCCSContext)
            v2EndSessionCleanup(logger)
            navigate('/post-onboarding/choose-account')
          }
        )
      } else {
        // Here, since the previous v2 session is for a different workspace we can do the 302 redirection to establish a new v2 session
        logger?.log({
          ...v2Logger,
          msg: `establishing new v2 session - different workspace`,
          type: 'info'
        })
        v2Redirection()
      }
    },
    (error) => {
      // A 404 error means that there is no v2 session, so we can let the user launch into v2 via form redirection
      if (error?.response?.status === 404) {
        logger?.log({
          ...v2Logger,
          msg: `404 error - user launch into v2 via form redirection`,
          type: 'error',
          data: error
        })
        v2Redirection()
      } else {
        const orgObj = {
          workspace_id: custAccount?.platform_customer_id,
          error_code: 1
        }
        logger?.log({
          ...v2Logger,
          msg: `error in launching v2 session`,
          type: 'error',
          data: orgObj
        })
        sessionStorage.setItem('orgLaunchInfo', JSON.stringify(orgObj))
        callEndOrgSession(dispatchCCSContext)
        v2EndSessionCleanup(logger)
        navigate('/post-onboarding/choose-account')
      }
    }
  )
}

export const getSubscriptionSquid = (LDFlags) => {
  const subscriptionProductSquid =
    LDFlags['glcp-subscriptions-magic-link'] ||
    LDFlags['glcp-magic-link-phase2']
      ? sessionStorage.getItem('subscriptionProductSquid')
      : null
  // removing subscription product squid from session
  sessionStorage.removeItem('subscriptionProductSquid')
  return subscriptionProductSquid
}

export const WORKSPACE_STATUS_ENUM = {
  IN_PROGRESS: 'IN_PROGRESS'
}

export const loadCustomerAccount = (
  custAccount,
  oidcUser,
  dispatchVisibilityContext,
  navigate,
  csrfToken,
  dispatchCCSContext,
  ld,
  dispatchUPSContext,
  logger
) => {
  return new Promise((resolve, reject) => {
    if (custAccount === null) reject(new Error('No current account!'))

    const profileCountryUpdate = JSON.parse(
      sessionStorage.getItem('defaultedProfileCountryCode')
    )
    // launchWorkspace only deals with the launching of a v1 workspace, if the flag is false the else block calls only this function and bypasses the workflow of checking if the workspace is v2 enabled and launching it.
    const launchWorkspace = () => {
      get(
        `/accounts/ui/v1/user/load-account/${custAccount.platform_customer_id}`,
        {},
        oidcUser.access_token
      ).then(
        () => {
          let resTimeout = 0
          // for Cypress test timeout: set response timeout to test loading modal
          if (window.Cypress && window.Cypress?.reqTimeout > 0) {
            resTimeout = window.Cypress.reqTimeout
          }
          setTimeout(async () => {
            if (custAccount) {
              const v2 = custAccount?.organization_id
              if (v2 && window.NODE_ENV !== 'development') {
                ld.update(custAccount).then((LDFlagsResolved) => {
                  const LDFlags = LDFlagsResolved
                  if (LDFlagsResolved && LDFlagsResolved !== 'failed') {
                    if (LDFlagsResolved['glcp-ups-phase-1']) {
                      updateUPSData(oidcUser, dispatchUPSContext, LDFlags)
                    }
                    logger?.log({
                      ...v2Logger,
                      msg: `launching v2 enabled Workspace`,
                      type: 'info'
                    })
                    // Handle v2 workspace launch
                    setCustomerAccount(custAccount)
                    establishV2SessionOnLaunch(
                      oidcUser,
                      custAccount,
                      csrfToken,
                      '/home',
                      navigate,
                      dispatchCCSContext,
                      dispatchVisibilityContext,
                      logger
                    )
                  } else {
                    const orgObj = {
                      workspace_id: custAccount?.platform_customer_id
                    }
                    sessionStorage.setItem(
                      'orgLaunchInfo',
                      JSON.stringify(orgObj)
                    )
                    callEndOrgSession(dispatchCCSContext)
                    v2EndSessionCleanup(logger)
                    navigate('/post-onboarding/choose-account')
                  }
                })
              } else {
                // V1 launch
                setCustomerAccount(custAccount)
                await updateRBACPolicies(
                  oidcUser,
                  custAccount,
                  dispatchVisibilityContext
                )
                dispatchVisibilityContext({
                  type: VisibilityActions.SET_HIDE_WHEN,
                  data: {
                    key: 'account',
                    value: custAccount.account_type
                  }
                })
                let LDFlags = {}
                if (!isCoP() && !isGLOP()) {
                  const LDFlagsResolved = await ld.update(custAccount)
                  if (LDFlagsResolved && LDFlagsResolved !== 'failed') {
                    LDFlags = LDFlagsResolved // eslint-disable-line react-hooks/exhaustive-deps
                    if (LDFlagsResolved['glcp-ups-phase-1']) {
                      updateUPSData(oidcUser, dispatchUPSContext, LDFlags)
                    }
                  }
                }
                const subscriptionProductSquid = getSubscriptionSquid(LDFlags)
                // UI can only end the v2 session based on the current information, if the workspace is not v2 enabled it will trigger callEndOrgSession(). This will therefore be called for v1-v1 or v2-v1 transition. In the v1-v1, the api returns a 204 saying No session to log out.
                if (!v2) {
                  callEndOrgSession(dispatchCCSContext)
                }
                updateTACFlags(
                  oidcUser,
                  dispatchVisibilityContext,
                  custAccount
                ).then((isTAC) => {
                  const postLoginPath = sessionStorage.getItem(
                    'postLoginRedirectURI'
                  )
                  if (postLoginPath) {
                    // removing redirect url from session
                    sessionStorage.removeItem('postLoginRedirectURI')

                    if (sessionStorage.getItem('is-mfe-auth')) {
                      sessionStorage.removeItem('is-mfe-auth')
                      // MFE apps need to re-render the page in order for the MFE Host proxy to render the expected MFE app
                      window.location.assign(postLoginPath)
                    } else {
                      // redirect user to desired url
                      navigate(postLoginPath)
                    }
                  } else if (
                    (custAccount.defaulted_country_code ||
                      profileCountryUpdate) &&
                    !isCoP()
                  ) {
                    // take user to country update page based on country update flag
                    navigate(`/post-onboarding/choose-country`)
                  } else if (subscriptionProductSquid) {
                    // redirect user to magic link ui
                    navigate(`/add-products/${subscriptionProductSquid}`)
                  } else if (isTAC) {
                    // if it is TAC account, go to ccs-manager home page
                    navigate('/manage-ccs/home')
                  } else if (
                    sessionStorage.getItem('navigateToDevicesOnClick') &&
                    custAccount?.account_type === 'TENANT' &&
                    custAccount?.operational_mode === 'CUSTOMER_OWNED_INVENTORY'
                  ) {
                    // if it is coming from the MSP customers page
                    navigate('/devices/inventory')
                    sessionStorage.removeItem('navigateToDevicesOnClick')
                  } else {
                    // take user to home page
                    navigate(`/home`)
                  }
                })
              }
              // reset the auditlog_context in session storage after a workspace is loaded / switched asd
              resetAuditLogContext()
              resolve()
            }
          }, resTimeout)
        },
        (error) => {
          const numCustAccounts = getNumCustomerAccounts()
          if (numCustAccounts === 1) {
            sessionStorage?.removeItem('account')
          }

          const swappedMSPAccount = getMSPCustomerAccount()
          if (swappedMSPAccount) {
            setCustomerAccount(swappedMSPAccount)
            sessionStorage.removeItem('swapMspAccount')
          }
          reject(error)
        }
      )
    }
    logger?.log({
      ...v2Logger,
      msg: `launching v2 enabled Workspace`,
      type: 'info'
    })
    if (custAccount?.iam_v2_workspace && !custAccount?.organization_id) {
      v2EndSessionCleanup()
      reject(new Error(WORKSPACE_STATUS_ENUM.IN_PROGRESS))
    } else {
      launchWorkspace()
    }
  })
}

// custom hook to handle load account
export const useLoadCustomerAccount = (custAccount, onError) => {
  const { oidcUser } = useReactOidc()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchUPSContext } = useUPSContext()
  const { dispatchCCSContext } = useCCSContext()
  const navigate = useNavigate()
  const ld = useLD()
  const [isLoading, setIsLoading] = useState(false)
  const { csrfToken } = useCCSContext()
  let LDFlags = useFlags()
  const logger = useLogger()
  useEffect(() => {
    let isCurrent = true
    if (custAccount === null) {
      setIsLoading(false)
    } else if (!isLoading) {
      setIsLoading(true)
      const profileCountryUpdate = JSON.parse(
        sessionStorage.getItem('defaultedProfileCountryCode')
      )
      const launchWorkspacefromLandingPage = () => {
        get(
          `/accounts/ui/v1/user/load-account/${custAccount.platform_customer_id}`,
          {},
          oidcUser.access_token
        ).then(
          async () => {
            if (!isCurrent) return // to unsubscribe if unmounted
            if (custAccount) {
              const v2 = custAccount?.organization_id
              if (v2 && window.NODE_ENV !== 'development') {
                ld.update(custAccount).then((LDFlagsResolved) => {
                  if (LDFlagsResolved && LDFlagsResolved !== 'failed') {
                    if (LDFlagsResolved['glcp-ups-phase-1']) {
                      updateUPSData(oidcUser, dispatchUPSContext, LDFlags)
                    }
                    // Handle v2 workspace launch
                    setCustomerAccount(custAccount)
                    logger?.log({
                      ...v2Logger,
                      msg: `launching v2 enabled Workspace`,
                      type: 'info'
                    })
                    establishV2SessionOnLaunch(
                      oidcUser,
                      custAccount,
                      csrfToken,
                      '/home',
                      navigate,
                      dispatchCCSContext,
                      dispatchVisibilityContext,
                      logger
                    )
                  } else {
                    const launchError = new Error({ error_code: 1 })
                    logger?.log({
                      ...v2Logger,
                      msg: `V2 session couldn't be established`,
                      type: 'error',
                      data: launchError
                    })
                    onError(launchError)
                  }
                })
              } else {
                // V1 Launch
                logger?.log({
                  ...v2Logger,
                  msg: `Launching v1 session`,
                  type: 'warning'
                })
                await updateRBACPolicies(
                  oidcUser,
                  custAccount,
                  dispatchVisibilityContext
                )
                dispatchVisibilityContext({
                  type: VisibilityActions.SET_HIDE_WHEN,
                  data: {
                    key: 'account',
                    value: custAccount.account_type
                  }
                })
                updateTACFlags(
                  oidcUser,
                  dispatchVisibilityContext,
                  custAccount
                ).then(async (isTAC) => {
                  const postLoginPath = sessionStorage.getItem(
                    'postLoginRedirectURI'
                  )
                  if (postLoginPath) {
                    // removing redirect url from session
                    sessionStorage.removeItem('postLoginRedirectURI')

                    if (sessionStorage.getItem('is-mfe-auth')) {
                      sessionStorage.removeItem('is-mfe-auth')
                      // MFE apps need to re-render the page in order for the MFE Host proxy to render the expected MFE app
                      window.location.assign(postLoginPath)
                    } else {
                      // redirect user to desired url
                      navigate(postLoginPath)
                    }
                  }
                  if (!isCurrent) return // to unsubscribe if unmounted
                  sessionStorage.setItem('account', JSON.stringify(custAccount))
                  if (!isCoP() && !isGLOP()) {
                    await ld.update(custAccount).then((LDFlagsResolved) => {
                      if (LDFlagsResolved && LDFlagsResolved !== 'failed') {
                        LDFlags = LDFlagsResolved // eslint-disable-line react-hooks/exhaustive-deps
                        if (LDFlagsResolved['glcp-ups-phase-1']) {
                          updateUPSData(oidcUser, dispatchUPSContext, LDFlags)
                        }
                      }
                    })
                  }
                  const subscriptionProductSquid = getSubscriptionSquid(LDFlags)
                  // update LD context with new account
                  if (!isGLOP()) ld.update(custAccount)
                  // sessionStorage.removeItem is for Swap MSP Navigation
                  // Needs to be done here or the loading page won't show
                  if (
                    custAccount?.account_type === 'MSP' &&
                    getMSPCustomerAccount()
                  ) {
                    sessionStorage.removeItem('swapMspAccount')
                  }
                  if (
                    (custAccount.defaulted_country_code ||
                      profileCountryUpdate) &&
                    !isCoP()
                  ) {
                    // take user to country update page based on country update flag
                    navigate(`/post-onboarding/choose-country`)
                  } else if (subscriptionProductSquid) {
                    // redirect user to desired url
                    navigate(`/add-products/${subscriptionProductSquid}`)
                  } else if (isTAC) {
                    // if it is TAC account, go to ccs-manager home page
                    navigate('/manage-ccs/home')
                  } else {
                    // take user to home page
                    navigate(`/home`)
                  }
                })
              }
            }
          },
          (error) => {
            const numCustAccounts = getNumCustomerAccounts()
            if (numCustAccounts === 1) {
              sessionStorage?.removeItem('account')
            }

            const swappedMSPAccount = getMSPCustomerAccount()
            if (swappedMSPAccount) {
              sessionStorage.setItem(
                'account',
                JSON.stringify(swappedMSPAccount)
              )
              sessionStorage.removeItem('swapMspAccount')
            }
            if (!isCurrent) return // to unsubscribe if unmounted
            setIsLoading(false)
            onError(error)
          }
        )
      }

      if (isCurrent) {
        if (custAccount?.iam_v2_workspace && !custAccount?.organization_id) {
          v2EndSessionCleanup()
          onError(new Error(WORKSPACE_STATUS_ENUM.IN_PROGRESS))
        } else {
          launchWorkspacefromLandingPage()
        }
      }
    }
    return () => {
      isCurrent = false
    }
  }, [custAccount]) // eslint-disable-line react-hooks/exhaustive-deps

  return { isLoading }
}

export const isServiceCentric = (LDFlags) => {
  return isV2() ? true : LDFlags?.['glcp-service-centric-experience-phase-1']
}

export const handleErrorRedirect = async (
  queryStr,
  custAccount,
  navigate,
  dispatchCCSContext,
  logger
) => {
  if (
    queryStr.includes('error-code=1') ||
    queryStr.includes('error-code=2') ||
    queryStr.includes('error-code=3')
  ) {
    const params = new URLSearchParams(queryStr)
    const errorCode = parseInt(params.get('error-code'), 10)
    const orgObj = {
      workspace_id: custAccount?.platform_customer_id,
      error_code: errorCode
    }
    logger?.log({
      ...v2Logger,
      msg: `error in establishing v2 Session after failed redirection`,
      type: 'error',
      data: orgObj
    })
    sessionStorage.setItem('orgLaunchInfo', JSON.stringify(orgObj))
    callEndOrgSession(dispatchCCSContext)
    v2EndSessionCleanup(logger)
    navigate('/post-onboarding/choose-account')
  }
}

const refreshV2Setup = (
  dispatchCCSContext,
  dispatchVisibilityContext,
  navigate,
  cookieCsrfToken,
  custAccountLoaded,
  setApiError,
  logger
) => {
  logger?.log({
    ...v2Logger,
    msg: `Inside refreshV2Setup`,
    type: 'info'
  })
  get('/internal-sessions/v1alpha1/my-ui-session').then(
    (response) => {
      const constructedGrn = `grn:glp/workspaces/${response?.data?.scope?.workspaceId}`
      dispatchCCSContext({
        type: CCSActions.SET_WORKSPACE_GRN,
        data: constructedGrn
      })
    },
    (error) => {
      setApiError(error)
      v2EndSessionCleanup(logger)
      navigate('/post-onboarding/choose-account')
    }
  )
  setupInterceptor(dispatchCCSContext, cookieCsrfToken, navigate, true)
  dispatchVisibilityContext({
    type: VisibilityActions.SET_HIDE_WHEN,
    data: {
      key: 'account',
      value: custAccountLoaded.account_type
    }
  })
  if (!isGLOP()) {
    updateV2RBACPolicies(
      dispatchVisibilityContext,
      cookieCsrfToken,
      setApiError
    )
  }
  // if the user manually types /onboarding/choose-account in the url, we need to redirect to /home
  if (window.location?.href?.includes('post-onboarding/choose-account')) {
    navigate('/home')
  }
}

export const setupV2OnSessionCookieReceived = (
  userEmail,
  custAccountLoaded,
  dispatchCCSContext,
  setApiError,
  dispatchVisibilityContext,
  navigate,
  logger
) => {
  logger?.log({
    ...v2Logger,
    msg: `landed in setupV2OnSessionCookieReceived method`,
    type: 'info'
  })
  const cookieGlpxId = getGlpxId()
  let cookieCsrfToken
  if (!cookieGlpxId) {
    post(
      `/internal-sessions/v1alpha1/my-ui-session/set-scope?login-hint=${encodeURIComponent(
        userEmail
      )}&request-scope=workspace&scope-id=${encodeURIComponent(
        custAccountLoaded?.platform_customer_id
      )}`
    ).then(
      () => {
        const glpxid = getGlpxId()
        if (glpxid) {
          const [, ...rest] = glpxid.split('=')
          cookieCsrfToken = rest.join('=')
          dispatchCCSContext({
            type: CCSActions.SET_CSRF_TOKEN,
            data: cookieCsrfToken
          })
        }
        refreshV2Setup(
          dispatchCCSContext,
          dispatchVisibilityContext,
          navigate,
          cookieCsrfToken,
          custAccountLoaded,
          setApiError,
          logger
        )
      },
      (error) => {
        setApiError(error)
        callEndOrgSession(dispatchCCSContext)
        v2EndSessionCleanup(logger)
        navigate('/post-onboarding/choose-account')
      }
    )
  } else {
    /**
     * This scenario is needed due to the lifetime of the cookie in the document
     * The cookie has a lifetime of 10 seconds. In the case that a user refreshes before the cookie expires,
     * the cookie will still exist in the document. Hence, we can save the cookie to context without
     * making an API call again to get the cookie
     */
    const [, ...rest] = cookieGlpxId.split('=')
    cookieCsrfToken = rest.join('=')
    dispatchCCSContext({
      type: CCSActions.SET_CSRF_TOKEN,
      data: cookieCsrfToken
    })
    refreshV2Setup(
      dispatchCCSContext,
      dispatchVisibilityContext,
      navigate,
      cookieCsrfToken,
      custAccountLoaded,
      setApiError,
      logger
    )
  }
}

export const sessionChannel = new BroadcastChannel('session')

export const getSlugData = (dataItem, slug, parentName = '') => {
  let returnData = null
  find(dataItem, (parent) => {
    const separator = parentName === '' ? '' : '/'
    const slugPath = parentName + separator + parent.name
    if (parent.children && parent.children.length > 0) {
      returnData = getSlugData(parent.children, slug, slugPath)
    }
    if (parent.subs && parent.subs.length > 0) {
      find(parent.subs, (sub) => {
        if (`${parent.slug}~${sub.slug}` === slug) {
          sub.matcher = `${parent.slug}~${sub.slug}`
          sub.path = slugPath
          sub.parentKey = parent.slug
          sub.type = parent.name
          returnData = sub
        }
      })
    }
    if (returnData) {
      return true
    }
    return false
  })
  return returnData
}

export const createResourcePolicy = (permissions, oldPermissions) => {
  const currentRole = []
  permissions.forEach((rp) => {
    if (currentRole.length > 0 && rp) {
      const resourcePolicyIndex = currentRole.findIndex((resourcePolicy) => {
        return resourcePolicy.resource.matcher === rp.parentKey
      })
      if (
        resourcePolicyIndex !== -1 &&
        currentRole[resourcePolicyIndex].permissions.filter(
          (selectedRolePermission) => {
            return selectedRolePermission.slug === rp.slug
          }
        ).length === 0
      ) {
        currentRole[resourcePolicyIndex].permissions.push({
          slug: rp.slug,
          name: rp.name
        })
      } else {
        currentRole.push({
          effect: 'ALLOW',
          permissions: [{ slug: rp.slug, name: rp.name }],
          resource: { matcher: rp.parentKey, name: rp.type }
        })
      }
    } else if (rp) {
      currentRole.push({
        effect: 'ALLOW',
        permissions: [{ slug: rp.slug, name: rp.name }],
        resource: { matcher: rp.parentKey, name: rp.type }
      })
    }
  })

  oldPermissions.forEach((resourcePolicy) => {
    if (
      !permissions.find((filteredResourcePolicy) => {
        return (
          filteredResourcePolicy.parentKey === resourcePolicy.resource.matcher
        )
      })
    ) {
      currentRole.push({
        effect: 'ALLOW',
        permissions: [],
        resource: {
          matcher: resourcePolicy.resource.matcher,
          name: resourcePolicy.resource.name
        }
      })
    }
  })

  return currentRole
}
