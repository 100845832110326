// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { COPCertificateRedirection } from './COPCertificateRedirection'

const COPCertifiateRedirectionRouterContent = () => {
  return (
    <Routes>
      <Route path="/" element={<COPCertificateRedirection />} />
      <Route
        path="/signup"
        element={
          <COPCertificateRedirection routerPath="/accept-certificate/sign-up" />
        }
      />
      <Route
        path="/login-banner"
        element={
          <COPCertificateRedirection routerPath="/terms-and-condition" />
        }
      />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

const COPCertifiateRedirectionRouter = () => {
  return <COPCertifiateRedirectionRouterContent />
}

export default COPCertifiateRedirectionRouter
