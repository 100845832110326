// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Notification } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'
import dayjs from 'dayjs'
import isNull from 'lodash/isNull'

import { useVisibilityContext } from '../../../context/visibility-context'
import { Button } from '../../../components'
import { isCoP, getCustomerAccount } from '../../../utils/feature-flag-utils'
import { get } from '../../../utils/api-utils'
import useFetchIsCCSManager from '../../../hooks/ccs-manager/useFetchIsCCSManager'
import { displayNotification } from '../../../utils/notificiation-utils'

import { BellNotificationIcon } from './icon'

const BellNewNotification = () => {
  const { t } = useTranslation(['manage'])
  const [hasNew, setHasNew] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const [showNotification, setShowNotification] = useState(null)
  const { rbacPolicies } = useVisibilityContext()
  const { isCCSManager } = useFetchIsCCSManager()
  const custAccountLoaded = getCustomerAccount()
  const notificationsRbac = {
    permission: 'ccs.notification.edit',
    resource: '/ccs/notification'
  }

  const handleBellIconClick = () => {
    setTrigger(!trigger)
    const showNotificationTab =
      rbacPolicies?.effects?.[notificationsRbac.resource]?.[
        notificationsRbac.permission
      ]

    if (isCCSManager && showNotificationTab) {
      navigate('/manage-ccs/notifications')
    } else if (isCCSManager && !showNotificationTab) {
      setShowNotification(
        displayNotification(
          t('notifications_permission_msg'),
          'error',
          setShowNotification
        )
      )
    } else {
      navigate('/notifications')
    }
  }

  useEffect(() => {
    let refreshPollTimer = null
    async function polling() {
      const lastInteractionTime = sessionStorage.getItem(
        'lastInteractionTimeLayout'
      )

      const diff = dayjs().diff(lastInteractionTime)

      if (diff < 60000 * 3 && !isNull(lastInteractionTime)) {
        refreshPollTimer = setTimeout(() => {
          polling()
        }, 60000 * 3 - diff)
      } else if (isNull(lastInteractionTime) || diff >= 60000 * 3) {
        if (
          oidcUser &&
          oidcUser?.access_token &&
          oidcUser?.access_token !== null &&
          custAccountLoaded !== null
        ) {
          let url = ''
          if (LDFlags['glcp-notifications-feature-alerts-tasks-kobe']) {
            url =
              '/notifications-svc/ui/v1alpha1/has-new-events?has_new_type=periodic'
          } else if (LDFlags['glcp-notifications-feature-edit-istanbul']) {
            url = '/notifications-svc/ui/v2/has-new?has_new_type=periodic'
          } else {
            url = '/notifications-svc/ui/v1alpha1/has-new?has_new_type=periodic'
          }
          get(url, {}, oidcUser?.access_token).then(
            (response) => {
              sessionStorage.setItem('lastInteractionTimeLayout', dayjs())
              polling()
              if (response?.data) {
                setHasNew(true)
              } else {
                setHasNew(false)
              }
            },
            (error) => {
              polling()
              sessionStorage.setItem('lastInteractionTimeLayout', dayjs())
              console.log(error)
              setHasNew(false)
            }
          )
        }
      }
    }

    polling()

    return () => {
      setHasNew(false) // cancelled all subscriptions and asynchronous tasks in a useEffect cleanup functions
      clearTimeout(refreshPollTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])

  if (!isCoP()) {
    return (
      <>
        {showNotification}
        {hasNew && !isCCSManager ? (
          <Box>
            <Button
              default
              icon={<BellNotificationIcon />}
              onClick={handleBellIconClick}
              testId="bell-notification-red-dot"
            />
          </Box>
        ) : (
          <Box>
            <Button
              default
              icon={<Notification color="text" />}
              onClick={handleBellIconClick}
              testId="bell-notification"
            />
          </Box>
        )}
      </>
    )
  }
  return null
}
export { BellNewNotification }
