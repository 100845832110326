// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import {
  VerifyUser,
  SignUpPage,
  VerifyEmailPage,
  ResetPasswordPage,
  ResetPasswordSuccessPage
} from '.'

const OnboardingRouterContent = () => {
  return (
    <Routes>
      <Route
        exact
        path="/choose-account"
        element={<Navigate to="/post-onboarding/choose-account" />}
      />
      <Route
        path="/set-up-account"
        element={<Navigate to="/post-onboarding/set-up-account" />}
      />
      <Route exact path="/verify-user" element={<VerifyUser />} />
      <Route exact path="/sign-up" element={<SignUpPage />} />
      <Route exact path="/verify-email/:email" element={<VerifyEmailPage />} />
      <Route exact path="/reset-password" element={<ResetPasswordPage />} />
      <Route
        exact
        path="/update-password"
        element={<VerifyUser updatePassword />}
      />
      <Route
        exact
        path="/reset-password-success"
        element={<ResetPasswordSuccessPage />}
      />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

const OnboardingRouter = () => {
  return <OnboardingRouterContent />
}

export default OnboardingRouter
