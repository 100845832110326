// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import { Typography, MenuList } from '../../components'
import { Layout } from '../../commoncomponents/layout/Layout'

const TutorialHomeContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['tutorial'])
  const { pathname } = useLocation()

  const tutorialMenuList = [
    {
      id: 1,
      label: t('book_inventory'),
      routeTo: '/tutorial/books',
      testId: 'tutorial-inventory-link'
    },
    {
      id: 2,
      label: 'Feature Flagging',
      routeTo: '/tutorial/feature-flagging',
      testId: 'tutorial-ld-link'
    }
  ]
  const getSelectedListId = () => {
    const data = tutorialMenuList.find((item) => item.routeTo === pathname)
    return data ? data.id : 1
  }
  const handleMenuItemClick = (item) => {
    navigate(item.routeTo)
  }
  return (
    <Box
      data-testid="book-inventory-card"
      pad={{ horizontal: 'xlarge', top: 'medium' }}
    >
      <Box direction="column" gap="xsmall" pad="small">
        <Typography type="heading" testId="publish-app-page-title">
          {t('tutorial_home')}
        </Typography>
        <Typography type="text" size="large" testId="publish-app-page-subtitle">
          {t('tutorial_subheading')}
        </Typography>
      </Box>

      <Box gap="xlarge" pad={{ vertical: 'small' }} direction="row-responsive">
        <MenuList
          defaultActiveId={getSelectedListId()}
          highlightOnSelect
          menuData={tutorialMenuList}
          menuItemPadding={{
            horizontal: 'small',
            vertical: 'xsmall'
          }}
          testId="book-app-menu-list"
          width="small"
          onClickMenuItem={handleMenuItemClick}
        />
      </Box>
    </Box>
  )
}
const TutorialHome = () => {
  return (
    <Layout>
      <TutorialHomeContent align="start" justify="start" />
    </Layout>
  )
}

export default TutorialHome
