// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Pagination } from '../../../../components/pagination/Pagination'
import { Ruler } from '../../../../components/ruler/Ruler'
import { eventPropType } from '../../utils/data'

import Row from './Row'
import SkeletonList from './SkeletonList'
import Tabs from './Tabs'

const PaginatedList = ({
  events,
  mutateEvent,
  clearCache,
  checked,
  setChecked,
  setEventForDetails,
  tab,
  setTab,
  search,
  page,
  setPage,
  itemsPerPage,
  total = undefined,
  setItemsPerPage,
  loading
}) => {
  const { t } = useTranslation('unified_wellness')
  total = typeof total !== 'undefined' ? total : itemsPerPage + 1
  return (
    <>
      {!search ? (
        <Tabs setTab={setTab} />
      ) : (
        <Ruler
          orientation="horizontal"
          testId="ruler"
          margin={{ top: 'medium' }}
        />
      )}
      {!loading ? (
        events.map((event) => (
          <Row
            event={event}
            mutateEvent={mutateEvent}
            clearCache={clearCache}
            key={event.uuid}
            checked={checked.has(event.uuid)}
            setChecked={(check) => {
              const updatedChecked = new Set(checked)
              if (check) {
                updatedChecked.add(event.uuid)
              } else {
                updatedChecked.delete(event.uuid)
              }
              setChecked(updatedChecked)
            }}
            setEventForDetails={setEventForDetails}
            tab={tab}
            search={search}
          />
        ))
      ) : (
        <SkeletonList rowCount={itemsPerPage} />
      )}

      <Box pad={{ vertical: 'small' }}>
        <Pagination
          itemsPerPage={itemsPerPage}
          page={page}
          pageIdxInfo={`${t('pagination.showing')} ${
            page * itemsPerPage - itemsPerPage + 1
          }-${Math.min(page * itemsPerPage, total)} ${t(
            'pagination.of'
          )} ${total}`}
          setItemsPerPage={setItemsPerPage}
          setPage={(i) => {
            if (i.page && i.page !== page) {
              setPage(i.page)
            }
          }}
          testId="pagination-object"
          totalItems={total}
          customRender={undefined}
          rowDropDownLabel={undefined}
          defaultRowsValue={undefined}
        />
      </Box>
    </>
  )
}

PaginatedList.propTypes = {
  events: PropTypes.arrayOf(eventPropType).isRequired,
  mutateEvent: PropTypes.func.isRequired,
  clearCache: PropTypes.func.isRequired,
  checked: PropTypes.instanceOf(Set).isRequired,
  setChecked: PropTypes.func.isRequired,
  setEventForDetails: PropTypes.func.isRequired,
  tab: PropTypes.oneOf(['inbox', 'flagged', 'archived']).isRequired,
  setTab: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  total: PropTypes.number,
  loading: PropTypes.bool.isRequired
}

export default PaginatedList
