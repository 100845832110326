// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes } from 'react-router-dom'
import React from 'react'

import LocalGatewayList from './LocalGatewayList'
import WelcomePage from './Welcome'
import CreateGatewayWizard from './GatewayWizard'
import RegistrationWizard from './RegistrationWizard'
import GatewayDetails from './GatewayDetails'
import AppDetails from './AppDetails'

const LocalGatewayRouter = () => {
  return (
    <Routes>
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/create" element={<CreateGatewayWizard />} />
      <Route path="/register/:gatewayId" element={<RegistrationWizard />} />
      <Route path="/details/:gatewayId/:appId" element={<AppDetails />} />
      <Route path="/details/:gatewayId" element={<GatewayDetails />} />
      {/* NOTE: Always add any routes above this route */}
      <Route path="" element={<LocalGatewayList />} />
    </Routes>
  )
}

export default LocalGatewayRouter
