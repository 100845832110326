// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, FormField } from 'grommet'
import { isEmpty } from 'lodash'

import {
  Button,
  CCSForm,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  MultiSelectBox,
  Typography
} from '../../../components'
import { get, getErrorMessage } from '../../../utils/api-utils'
import { GROUP_SCHEMAS } from '../groups/constants'

import { debounceSearch } from './utils'

const AssignGroupsToUserModal = ({
  onSetOpen,
  onSubmit,
  setNotificationInfo = () => {},
  preselectedValues = [],
  user = undefined
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const [groups, setGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [defaultOptions, setDefaultOptions] = useState()

  const closeModal = () => {
    onSetOpen(false)
  }

  const template = (option) => (
    <Box pad={{ top: 'xsmall' }}>
      <Typography size="small" type="paragraph">
        {option?.displayName}
      </Typography>
      <Typography size="xsmall" type="paragraph">
        {option[GROUP_SCHEMAS.PARTIAL]?.groupDescription || ''}
      </Typography>
    </Box>
  )

  useEffect(() => {
    let isMounted = true
    const getGroupsAssignedToUser = async (id) => {
      setLoading(true)
      let groupsAssignedToUser
      await get(
        `/identity/v2alpha1/scim/v2/extensions/Users/${id}/groups`
      ).then(
        (response) => {
          if (!isMounted) return
          setLoading(false)
          groupsAssignedToUser = response?.data?.Resources
        },
        (error) => {
          if (!isMounted) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
      return groupsAssignedToUser
    }

    const getGroups = async () => {
      const searchQuery =
        searchValue?.trim()?.length > 0
          ? encodeURI(`?filter=displayName sw "${searchValue?.trim()}"`)
          : ''
      setLoading(true)
      setGroups([])
      await get(`/identity/v2alpha1/scim/v2/Groups${searchQuery}`).then(
        async (response) => {
          let groupsToDisplay = []
          if (response?.data && response?.data?.Resources) {
            groupsToDisplay = response?.data?.Resources
          }
          if (user) {
            const groupsAssignedToUser = await getGroupsAssignedToUser(user?.id)
            const groupsAssignedToUserIds = groupsAssignedToUser?.map(
              (group) => group?.id
            )
            groupsToDisplay = groupsToDisplay?.filter(
              (group) => !groupsAssignedToUserIds?.includes(group?.id)
            )
          }
          if (!isMounted) return
          if (isEmpty(searchValue?.trim())) {
            setDefaultOptions(groupsToDisplay)
          }
          setGroups(groupsToDisplay)
          setLoading(false)
        },
        (error) => {
          if (!isMounted) return
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
    }
    getGroups()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, searchValue, t])

  return (
    <ModalDialog
      height="100%"
      overflow="hidden"
      position="right"
      header={
        <ModalHeader
          title={
            <Box direction="column">
              <Typography
                type="heading"
                level="2"
                margin={{ bottom: 'small' }}
                emphasis
              >
                {t('iam:igc_users.assign_to_group_title')}
              </Typography>
              <Box width="medium">
                <Typography type="text">
                  {t('iam:igc_users.assign_to_group_subtitle')}
                </Typography>
              </Box>
            </Box>
          }
          onClose={closeModal}
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <CCSForm errorMessage="" testId="assign-groups-to-user-form">
            <FormField
              required
              label={t('iam:igc_users.assign_to_group_group_select_title')}
              help={t('iam:igc_users.assign_to_group_group_select_help')}
              error={errorMessage}
            >
              <MultiSelectBox
                options={groups}
                defaultValue={preselectedValues}
                labelKey="displayName"
                valueKey="id"
                placeholder={t('common:select')}
                onChange={(group) => {
                  setSelectedGroups(group)
                  if (selectedGroups) {
                    setErrorMessage('')
                  }
                }}
                onClose={() => setGroups(defaultOptions)}
                onSearch={(searchText) =>
                  debounceSearch(searchText, setSearchValue)
                }
                searchPlaceholder={t('common:search')}
                emptySearchMessage={
                  loading
                    ? t('common:searching')
                    : t('common:search_no_matches')
                }
                dropHeight="medium"
                disabled={loading}
                testId="assign-groups-to-user-multiselect"
              >
                {template}
              </MultiSelectBox>
            </FormField>
          </CCSForm>
          <Box align="center" margin={{ top: 'small' }}>
            {loading && <Loader testId="assign-group-to-user-loader" />}
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          left={
            <Box direction="row" justify="start" gap="small">
              <Button
                primary
                label={t('common:add')}
                onClick={() => {
                  if (selectedGroups?.length === 0) {
                    setErrorMessage(t('common:this_is_required'))
                  } else {
                    onSubmit(selectedGroups)
                  }
                }}
                testId="assign-groups-to-user-add-btn"
              />
              <Button
                default
                label={t('common:cancel')}
                onClick={closeModal}
                testId="assign-groups-to-user-cancel-btn"
              />
            </Box>
          }
        />
      }
      onClose={closeModal}
      onClickOutside={closeModal}
      testId="assign-group-to-user-modal"
    />
  )
}

AssignGroupsToUserModal.propTypes = {
  /**
   * Flag to display modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * onSubmit handler
   * Needed since the onSubmit behavior is different for the add user dialog and user details page
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Set notification info
   */
  setNotificationInfo: PropTypes.func,

  /**
   * Array of selected groups
   * Needed to preselect multiselect box
   */
  preselectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ),

  /**
   * The user - used to get all the groups assigned to this user
   * Needed to make API call to filter out groups already assigned to user
   */
  user: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
}

export default AssignGroupsToUserModal
