// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { del, getErrorMessage } from '../../../../utils/api-utils'
import {
  Button,
  ModalDialog,
  ModalFooter,
  Loader,
  Typography
} from '../../../../components'
import { updateV2RBACPolicies } from '../../../../utils/rbac-api-utils'
import { useVisibilityContext } from '../../../../context/visibility-context'
import { useCCSContext } from '../../../../context/ccs-context'

const DeleteRoleAssignmentConfirmation = ({
  setConfirm = () => {},
  retrievedRA = {},
  deleteFromBackend = true,
  onRemoved = () => {},
  setNotificationInfo = () => {},
  title,
  subtitle
}) => {
  const { csrfToken } = useCCSContext()
  const { t } = useTranslation(['authz'])
  const { dispatchVisibilityContext } = useVisibilityContext()
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    setConfirm(false)
  }

  const deleteRoleAssignment = () => {
    if (deleteFromBackend) {
      setLoading(true)
      del(`/authorization/v2alpha1/role-assignments/${retrievedRA?.id}`).then(
        () => {
          setConfirm(false)
          setLoading(false)
          onRemoved()
          // This could affect the current user's permissions hence why we're calling updateV2RBACPolicies
          // updateV2RBACPolicies will not run on local as csrfToken will be null
          updateV2RBACPolicies(dispatchVisibilityContext, csrfToken)
        },
        (error) => {
          setConfirm(false)
          setLoading(false)
          setNotificationInfo(getErrorMessage(error, t), 'error')
        }
      )
    } else {
      setLoading(false)
      onRemoved()
    }
  }

  return (
    <ModalDialog
      content={
        !loading ? (
          <Box gap="small">
            <Typography
              type="heading"
              level="2"
              testId="delete-role-assignment-confirm-title"
            >
              {title}
            </Typography>
            {subtitle}
          </Box>
        ) : (
          <Box fill align="center" justify="center" pad={{ vertical: 'small' }}>
            <Loader
              orientation="horizontal"
              label={t('common:removing')}
              testId="remove-role-assignment-loader"
            />
          </Box>
        )
      }
      footer={
        !loading ? (
          <ModalFooter
            right={
              <Box direction="row" gap="small">
                <Button
                  label={t('common:cancel')}
                  onClick={closeModal}
                  testId="cancel-btn"
                />
                <Button
                  label={t(
                    'iam:role_assignment.remove_role_assignment_secondary_title_v2',
                    {
                      roleAssignment: t(
                        'common:business_object.role_assignment'
                      )
                    }
                  )}
                  primary
                  onClick={deleteRoleAssignment}
                  disabled={loading}
                  testId="remove-role-assignment-btn"
                />
              </Box>
            }
          />
        ) : null
      }
      onClose={closeModal}
      width={loading ? 'small' : 'large'}
      testId="remove-role-assignment-modal-dialog"
    />
  )
}

export default DeleteRoleAssignmentConfirmation

DeleteRoleAssignmentConfirmation.propTypes = {
  setConfirm: PropTypes.func,
  setNotificationInfo: PropTypes.func,
  retrievedRA: PropTypes.object,

  /**
   * if true this component will call API to delete
   * the role assignment, otherwise it simple call refreshParent callback
   */
  deleteFromBackend: PropTypes.bool,
  /**
   * callback when role assignment successfully removed
   */
  onRemoved: PropTypes.func,

  title: PropTypes.string.isRequired,
  subtitle: PropTypes.element.isRequired
}
