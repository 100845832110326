// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import { checkGotoChooseAccount } from '../../../utils/common-utils'

import Groups from './Groups'
import GroupDetails from './GroupDetails'

const GroupsRouter = ({ isWorkspace = false }) => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)
  return (
    <Routes>
      <Route exact path="/" element={<Groups isWorkspace={isWorkspace} />} />
      <Route
        exact
        path="/:groupId"
        element={<GroupDetails isWorkspace={isWorkspace} />}
      />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

GroupsRouter.propTypes = {
  /**
   * True if called from v2 workspace, false if called from IGC pages
   */
  isWorkspace: PropTypes.bool
}

export default GroupsRouter
