// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { isGLOP, isCoP } from './utils/feature-flag-utils'

const flagKeys = [
  { key: 'glcp-wc-header', defaultVal: false },
  { key: 'glcp-infra-enable-new-relic', defaultVal: false },
  { key: 'glcp-amplitude-analytics-istanbul', defaultVal: false },
  {
    key: 'glp-analytics-config',
    defaultVal: {
      debugMessages: false,
      allowConsentOverrides: false,
      autoAnalytics: {
        analyticsIds: [''],
        idPatternsToTrack: [''],
        idPatternsToIgnore: [''],
        urlsToTrack: ['']
      },
      plugins: {
        amplitude: {}
      }
    }
  }
]

/**
 * This method used to inject multiple external script inside the head section of index.html
 * @param {string} scriptSrc provide the external script path to be injected inside the head of index.html
 */
const bootstrap = (scriptSrc, type = 'text/javascript') => {
  const script = document.createElement('script')
  script.type = type
  script.async = true
  script.onload = () => {
    // remote script has loaded
    // add any code here to be executed onload of the external script
    // console.log(`Added script for ${scriptSrc}`)
  }
  script.src = `${window.location.origin}${scriptSrc}`
  document.getElementsByTagName('head')[0].appendChild(script)
}
/**
 * Get the current flag value from LD for header
 * @param {boolean} flagValue
 */
// const setUpHeader = (flagValue) => {
//   const header = document.querySelector('greenlake-header')
//   window.showWCHeader = flagValue

//   // This is used in the edge case when the window.showWCHeader is updated
//   // after the React header is already shown.
//   if (window.setUseWCHeader) {
//     window.setUseWCHeader(flagValue)
//   }
//   if (flagValue && !header) {
//     const newHeader = document.createElement('greenlake-header')
//     const root = document.getElementById('root')
//     document.body.insertBefore(newHeader, root)
//   } else if (header) {
//     document.body.removeChild(header)
//   }
// }
/**
 * Injecting the New Relic script into index.html
 */
const injectNR = () => {
  bootstrap('/js/external/glcp-nr.js')
}

const isVercelActive = () => {
  try {
    // @ts-ignore
    return JSON.parse(document.getElementById('is-vercel-active').textContent)
  } catch (error) {
    return false
  }
}

const getVercelMetadata = () => {
  try {
    // @ts-ignore
    const projectId = document.getElementById('vercel-project-id').textContent
    // @ts-ignore
    const orgId = document.getElementById('vercel-org-id').textContent
    return { projectId, orgId }
  } catch (error) {
    return {}
  }
}

// TODO: Add project, branch and team dynamically from REACT_APP env variables
if (window.location.hostname === 'localhost' && isVercelActive()) {
  const { projectId, orgId } = getVercelMetadata()
  document.addEventListener('DOMContentLoaded', () => {
    const script = document.createElement('script')
    script.src = 'https://vercel.live/_next-live/feedback/feedback.js'
    script.setAttribute('data-explicit-opt-in', 'true')
    // @ts-ignore
    script.setAttribute('data-owner-id', orgId)
    // @ts-ignore
    script.setAttribute('data-project-id', projectId)
    document.getElementsByTagName('body')[0].appendChild(script)
  })
}
/**
 * Setup LaunchDarkly JavaScript SDK
 */
const injectScript = async (clientSideID) => {
  if (isGLOP() || isCoP()) return
  const context = { key: 'dummy' }
  // eslint-disable-next-line no-undef
  const ldClient = LDClient.initialize(
    clientSideID,
    context,
    isVercelActive() ? { baseUrl: '/launch-darkly' } : {}
  )

  const render = () => {
    let flagVariation = null
    flagKeys.forEach(({ key, defaultVal }) => {
      flagVariation = ldClient.variation(key, defaultVal)
      // if (key === 'glcp-wc-header') {
      //   setUpHeader(flagVariation)
      // }
      if (key === 'glcp-infra-enable-new-relic' && flagVariation) {
        injectNR()
      }
      if (key === 'glcp-amplitude-analytics-istanbul') {
        sessionStorage.setItem(
          'glcp-amplitude-analytics-istanbul',
          `${flagVariation}`
        )
      }
      if (key === 'glp-analytics-config') {
        sessionStorage.setItem(
          'glp-analytics-config',
          JSON.stringify(flagVariation)
        )
      }
    })
  }
  if (ldClient) {
    ldClient.on('ready', render)
    ldClient.on('change', render)
  }
}

bootstrap('/greenlake-header/greenlake-header.js', 'module')
export default injectScript
