// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import {
  getCustomerAccount,
  isCoP,
  isGLOP,
  isMSP,
  isTenant,
  isOkta,
  isStandalone,
  isOrgWorkSpace
} from '../../../utils/feature-flag-utils'

const headerTypes = Object.freeze({
  PUBLIC: 'publicDefault',
  TENANT: 'isTenant',
  COP: 'isCop',
  GLOP: 'isGlop',
  MSP: 'isMsp',
  STANDALONE: 'secureDefault',
  MANAGER: 'isManager',
  ORG: 'isOrg'
})

const headerElements = Object.freeze({
  header: 'greenlake-header',
  brand: 'hpe-brand',
  nav: 'center-nav',
  bell: 'notifications',
  help: 'help-menu',
  apps: 'apps-menu',
  user: 'user-menu',
  context: 'context-help'
})

export const updateHeader = (hideHeaderOptions, oidcUser, rbacPolicies) => {
  const headerRootNode = document.querySelector('greenlake-header')
  const custAccountLoaded = getCustomerAccount()
  const isCopUser = isCoP()
  const isGlopUser = isGLOP()
  const isMspUser = isMSP()
  const isTenantUser = isTenant()
  const isOktaUser = isOkta()
  const isStandaloneUser = isStandalone()
  const isOrg = isOrgWorkSpace()
  const isManager =
    hideHeaderOptions?.length === 1 && hideHeaderOptions[0] === 'apps'

  const styleId = 'wc-header-elements'
  const notificationsId = 'notifications'
  const servicesId = 'nav-header-nav-applications'
  const customersId = 'nav-header-nav-customers'
  const devicesId = 'nav-header-nav-devices'
  const manageUserId = 'ws-workspace-menu-manage-users'
  const rolesPermissionsId = 'ws-workspace-menu-roles-permissions'

  const updateHeaderStyle = (id, newCssCode) => {
    // reset header style
    const styleTag = document.getElementById(id)
    if (styleTag) {
      document.getElementsByTagName('head')[0].removeChild(styleTag)
    }
    // update header style
    if (newCssCode) {
      const style = document.createElement('style')
      style.id = id
      style.innerHTML = newCssCode
      document.getElementsByTagName('head')[0].appendChild(style)
    }
  }

  // Show publicDefault header if user is not logged in.
  // Show secureDefault header if user is logged in but customer account is not loaded.
  // Show header based on user account if user is logged in and customer account is loaded. Default to secureDefault.
  if (!oidcUser) {
    headerRootNode.platform = headerTypes.PUBLIC
  } else if (oidcUser && !custAccountLoaded) {
    headerRootNode.platform = headerTypes.STANDALONE
  } else {
    let currentHeaderType = headerTypes.STANDALONE
    if (isOktaUser && isStandaloneUser) {
      currentHeaderType = headerTypes.STANDALONE
    }
    if (isMspUser) {
      currentHeaderType = headerTypes.MSP
    }
    if (isTenantUser) {
      currentHeaderType = headerTypes.TENANT
    }
    if (isManager) {
      currentHeaderType = headerTypes.MANAGER
    }
    if (isCopUser) {
      currentHeaderType = headerTypes.COP
    }
    if (isGlopUser) {
      currentHeaderType = headerTypes.GLOP
    }
    if (isOrg) {
      currentHeaderType = headerTypes.ORG
    }
    headerRootNode.platform = currentHeaderType
  }

  headerRootNode.linkbaseurl = document.location.origin

  const { given_name, family_name, email } = oidcUser?.profile || {}
  const user = {
    firstName: given_name || '',
    lastName: family_name || '',
    email: email || ''
  }

  headerRootNode.config = {
    ...headerRootNode.config,
    user
  }

  const hiddenElements = hideHeaderOptions
    ? hideHeaderOptions?.map((el) => headerElements[el])
    : [headerElements.header]

  if (
    hiddenElements.length === 1 &&
    hiddenElements[0] === headerElements.header
  ) {
    const noHeaderCssCode = `greenlake-header::part(greenlake-header) { display: none;}`
    updateHeaderStyle(styleId, noHeaderCssCode)
  } else {
    let cssSelector
    let cssCode

    if (hiddenElements.length > 0) {
      const cssSelectorArray = hiddenElements.map(
        (el) => `greenlake-header::part(${el})`
      )
      cssSelector =
        cssSelectorArray.length > 1
          ? cssSelectorArray.join(', ')
          : cssSelectorArray[0]
      cssCode = `${cssSelector}{display:none;}`
    }
    updateHeaderStyle(styleId, cssCode)
  }

  function handleElementVisibility(
    elementId,
    policy,
    cssCode,
    condition = true
  ) {
    const hideElement =
      !rbacPolicies?.effects?.[policy.resource]?.[policy.permission]

    if (hideElement && condition) {
      updateHeaderStyle(elementId, cssCode)
    } else {
      const element = document.getElementById(elementId)
      if (element) {
        document.getElementsByTagName('head')[0].removeChild(element)
      }
    }
  }

  if (rbacPolicies?.effects) {
    const showSystemNavLink = Object.entries(rbacPolicies?.effects).some(
      ([key, value]) =>
        key.includes('/ccs/system-mgmt/') &&
        Object.values(value).some((permission) => permission)
    )

    /**
     * permissionToViewServicesTab checks the several permissions related to the "Applications"
     * header link, or "Services" link if the user has Service-Centric UI enabled. See Sagar's page on
     * the services registry migration for more here:
     * https://hpe.atlassian.net/wiki/spaces/HPEUCP/pages/1959625004/Service+Registry+Design#Visibility-of-the-%E2%80%9CServices%E2%80%9D-header-in-the-UI
     * Effectively, this:
     *  - filters the effects from rbacPolicies for the necessary permission families
     *  - Gathers the values from each permission family tuple (a permissions object)
     *    - filters for permissions with "view" in the key specifically and flatten those
     *  - Take the values of those flattened tuples and removes any falsy values
     * If there are any truthy values, the user has permission to view the link.
     */

    const permissionToViewServicesTab = Object.entries(rbacPolicies.effects)
      .filter(([key]) =>
        [
          '/ccs/app-provision/provisions',
          '/ccs/app-catalog',
          '/ccs/app-catalog/application',
          '/ccs/service-catalog',
          '/ccs/service-catalog/service-catalog',
          '/ccs/service-catalog/service-provision'
        ].includes(key)
      )
      .map(([, values]) =>
        Object.entries(values)
          .filter(([key]) => key.includes('view'))
          .flat()
      )
      .map(([, values]) => values)
      .filter(Boolean)
      .includes(true)

    const visibilityConfigs = [
      // 'Services' option on the header
      {
        elementId: servicesId,
        policy: {
          permission: null,
          resource: null
        },
        cssCode: `greenlake-header::part(nav-header-nav-applications) { display: none;}`,
        condition: !permissionToViewServicesTab
      },
      // 'Customers' tab
      {
        elementId: customersId,
        policy: {
          permission: 'ccs.accounts.platform.customer.view',
          resource: '/ccs/accounts/platform/customer'
        },
        cssCode: `greenlake-header::part(nav-header-nav-customers) { display: none;}`
      },
      // 'Devices' tab
      {
        elementId: devicesId,
        policy: {
          permission: 'ccs.device-management.view',
          resource: '/ccs/device-management'
        },
        cssCode: `greenlake-header::part(nav-header-nav-devices) { display: none;}`
      },
      // 'Manage Users' on the workspace dropdown
      {
        elementId: manageUserId,
        policy: {
          permission: 'ccs.accounts.users.view.all',
          resource: '/ccs/accounts/user'
        },
        cssCode: `greenlake-header::part(ws-workspace-menu-manage-users) { display: none;}`
      },
      // 'Roles & Permissions' on the workspace dropdown
      {
        elementId: rolesPermissionsId,
        policy: {
          permission: 'ccs.authorization.view',
          resource: '/ccs/authorization'
        },
        cssCode: `greenlake-header::part(ws-workspace-menu-roles-permissions) { display: none;}`
      },
      // Notification bell
      {
        elementId: notificationsId,
        policy: {
          permission: 'ccs.notification.edit',
          resource: '/ccs/notification'
        },
        cssCode: `greenlake-header::part(notifications) { display: none;}`,
        condition: isManager
      },
      // System management nav link visibility
      {
        elementId: 'nav-header-nav-system',
        policy: {
          permission: null,
          resource: null
        },
        cssCode: `greenlake-header::part(nav-header-nav-system) { display: none;}`,
        condition: !showSystemNavLink && isGlopUser
      }
    ]

    visibilityConfigs.forEach(
      ({ elementId, policy, cssCode, condition = true }) => {
        handleElementVisibility(elementId, policy, cssCode, condition)
      }
    )
  }
}
