// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useState, useEffect } from 'react'
import {
  Organization,
  Group,
  // AppsRounded,
  System,
  History,
  Server,
  Brush,
  Cycle,
  Key,
  Tag,
  ShieldSecurity,
  LineChart,
  Location,
  Technology,
  Shop,
  Scorecard,
  Services,
  PersonalComputer,
  Inherit,
  DocumentDownload,
  Risk
} from 'grommet-icons'
import {
  Box,
  Grid,
  Page,
  PageContent,
  PageHeader,
  ResponsiveContext
} from 'grommet'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Button, Card, Typography } from '../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import {
  isMSP,
  isCoP,
  getMSPCustomerAccount,
  isInventoryOwnedWorkspace,
  isV2,
  isGLOP
} from '../../utils/feature-flag-utils'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../utils/common-utils'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { isServiceCentric } from '../../utils/account-utils'
import { get } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import AccountDetailsInfo from '../../commoncomponents/account-details/AccountDetailsInfo'

import useIsBillingExportAvailable from './billing-export/useIsBillingExportAvailable'

const getManageAccountList = (
  swappedMSPAccount,
  LDFlags,
  t,
  showWorkspaceString,
  isV2Enabled,
  aliases,
  onPremWorkspaces,
  isBillingExportAvailable
) => {
  const showWebhook = LDFlags['glcp-eventing-webhook']
  const subscriptionTags = LDFlags['glcp-subscrption-tags']
  const manageAccountList = [
    {
      id: 1,
      icon: <Organization aria-hidden size="medium" />,
      title: t('common:manage_account.account_details_title', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      }),
      description: t('common:manage_account.account_details_subtitle', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC)
      }),
      routeTo: 'account-details',
      testId: 'account_details',
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.view'
      }
    },
    {
      id: 2,
      icon: <Group aria-hidden size="medium" />,
      title: t('iam:workspace_identity.title', {
        Workspace: t('common:business_object:wkspc_capitalized')
      }),
      description: t('iam:workspace_identity.desc', {
        workspace: t('common:business_object:wkspc')
      }),
      routeTo: 'identity',
      testId: 'identity',
      // TODO rbac for organization workspace IAM
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.view'
      }
    },
    {
      id: 3,
      icon: <Inherit aria-hidden size="medium" />,
      title: t('iam:organization.governance_title'),
      description: t('iam:organization.card_desc', {
        workspaces: t('common:business_object:wkspc_plural')
      }),
      routeTo: 'organization',
      testId: 'organization',
      // TODO rbac for organization governance card
      isHidden: !isV2Enabled
    },
    // Commenting out Authentication tile and we can un-comment when we do enforcement policies. Please delete this tile once we are done with enforcement policies
    // {
    //   id: 5,
    //   icon: <Key size="medium" />,
    //   title: 'Authentication',
    //   description: 'Improve security SAML SSO, 2FA, and session timeout.',
    //   routeTo: 'authentication',
    //   testId: 'authentication'
    // },
    // {
    //  id: 6,
    //  icon: <AppsRounded size="medium" />,
    //  title: 'App Management',
    //  description: 'Manage and provision apps for your account',
    //  routeTo: 'app-management',
    //  testId: 'app_management'
    // },
    {
      id: 9,
      icon: <History aria-hidden size="medium" />,
      title: t('common:manage_account.auditlog_title'),
      description: t('common:manage_account.auditlog_subtitle'),
      routeTo: 'auditlogs',
      testId: 'audit-logs',
      rbac: {
        resource: '/ccs/audit-trail',
        permission: 'ccs.audit-trail.view'
      }
    },
    {
      id: 10,
      icon: <Key aria-hidden size="medium" />,
      title: t('common:manage_account.authentication_title'),
      description: isCoP()
        ? t('common:manage_account.authentication_subtitle')
        : t('common:manage_account.sso_subtitle'),
      routeTo: 'authentication',
      testId: 'authentication',
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.view'
      },
      isHidden: isV2Enabled
    },
    {
      id: 11,
      icon: <System aria-hidden size="medium" />,
      title: t('common:manage_account.activate_title'),
      description: t('common:manage_account.activate_subtitle'),
      routeTo: 'activate/devices-list',
      testId: 'activate',
      rbac: {
        resource: '/ccs/activate/customer',
        permission: 'ccs.activate.view'
      }
    },
    {
      id: 13,
      icon: <Server aria-hidden size="medium" />,
      title: LDFlags['glcp-api-client-system-jhansi']
        ? t('common:manage_account.personal_api_title')
        : t('common:manage_account.api_title'),
      description: t('common:manage_account.api_subtitle'),
      routeTo: 'api',
      testId: 'api',
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.view'
      },
      isHidden: isCoP()
    },
    {
      id: 14,
      icon: <Brush aria-hidden size="medium" />,
      title: t('common:manage_account.portal_customization_title'),
      description: t('common:manage_account.portal_customization_subtitle'),
      routeTo: 'portal-customization',
      testId: 'portal-customization',
      isHidden: !isMSP() || isV2Enabled,
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.view'
      }
    },
    {
      id: 16,
      icon: <ShieldSecurity aria-hidden size="medium" />,
      title: t('common:manage_account.ip_access_rules_title'),
      hideFor: {
        deployment: ['COP']
      },
      description: t('common:manage_account.ip_access_rules_subtitle'),
      routeTo: 'ip-access-rules',
      testId: 'ip-access-rules',
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.view'
      }
    },
    {
      id: 17,
      icon: <Technology aria-hidden size="medium" />,
      title: t('common:local_gateway.tile_title'),
      description: t('common:local_gateway.tile_subtitle'),
      routeTo: 'local-gateway',
      testId: 'local-gateway',
      hideFor: {
        feature: 'glcp-local-gateway-feature',
        deployment: ['COP', 'GLOP']
      },
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    },
    {
      id: 18,
      icon: <Location aria-hidden size="medium" />,
      title: t('location:location_title'),
      description: t('location:location_subtitle'),
      routeTo: 'locations',
      testId: 'locations',
      hideFor: {
        deployment: ['COP', 'GLOP']
      },
      rbac: {
        resource: '/ccs/location-management',
        permission: 'ccs.location-management.view'
      },
      isHidden: !isInventoryOwnedWorkspace()
    },
    {
      id: 19,
      icon: <LineChart aria-hidden size="medium" />,
      title: t('common:manage_account.usage_monitoring_title'),
      description: t('common:manage_account.usage_reporting_subtitle'),
      routeTo: 'usage-monitoring',
      testId: 'usage-monitoring',
      hideFor: {
        deployment: ['COP']
      },
      rbac: {
        resource: '/ccs/consumption-data',
        permission: 'ccs.consumption-data.view'
      }
    },
    {
      id: 20,
      icon: <Services aria-hidden size="medium" />,
      isHidden: !showWebhook,
      title: t('automations:automations.title'),
      description: t('automations:automations.description'),
      routeTo: 'automations',
      testId: 'automations',
      rbac: {
        resource: '/ccs/events/webhook',
        permission: 'ccs.events.webhook.read'
      }
    },
    {
      id: 21,
      icon: <Scorecard aria-hidden size="medium" />,
      title: t('common:manage_account.reporting_title'),
      description: t('common:manage_account.reporting_subtitle'),
      hideFor: {
        feature: 'glcp-reportfw-dashboard',
        deployment: ['COP', 'GLOP']
      },
      routeTo: 'reporting',
      rbac: {
        resource: '/ccs/reporting/dashboard',
        permission: 'ccs.reporting.view'
      },
      testId: 'reporting'
    },
    {
      id: 22,
      icon: <PersonalComputer aria-hidden size="medium" />,
      title: t('common:manage_account.zto_title'),
      description: t('common:manage_account.zto_subtitle'),
      routeTo: 'zero-touch-onboarding',
      isHidden: !isInventoryOwnedWorkspace() || !aliases?.length,
      hideFor: {
        feature: 'glcp-zto',
        deployment: ['GLOP', 'COP']
      },
      rbac: {
        resource: '/ccs/device-management/zto',
        permission: 'ccs.device-subscription-zto.edit'
      },
      testId: 'zto'
    },
    {
      id: 23,
      icon: <Server aria-hidden size="medium" />,
      title: t('manage:onprem_workspace.onprem_workspace_title'),
      description: t('manage:onprem_workspace.onprem_workspace_subtitle'),
      routeTo: 'on-prem-workspaces',
      isHidden: !onPremWorkspaces?.length,
      hideFor: {
        feature: 'glcp-dscc-dm-sm',
        deployment: ['GLOP', 'COP']
      },
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      },
      testId: 'on-prem-workspaces'
    },
    {
      id: 24,
      icon: <DocumentDownload aria-hidden size="medium" />,
      title: t('cds_billing_export:main_page.title'),
      description: t('cds_billing_export:main_page.tile_des'),
      routeTo: 'billing-export',
      testId: 'billing-export',
      isHidden: !isBillingExportAvailable,
      hideFor: {
        feature: 'glcp-cds-billing-export'
      },
      rbac: {
        resource: '/ccs/billing-export',
        permission: 'ccs.billing-export.manage'
      }
    },
    {
      id: 25,
      icon: <Risk aria-hidden size="medium" />,
      title: t('manage:asset_management.asset_management_title'),
      description: t('manage:asset_management.asset_management_subtitle'),
      routeTo: 'asset-management',
      testId: 'asset-management',
      hideFor: {
        feature: 'glcp-asset-lifecycle-management',
        deployment: ['GLOP', 'COP']
      },
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    }
  ]
  const retiringTiles = [
    {
      id: 12,
      icon: <Tag aria-hidden size="medium" />,
      title: t('common:manage_account.tags_title'),
      description: t('common:manage_account.tags_subtitle'),
      routeTo: 'tags',
      testId: 'tags',
      isHidden: isV2Enabled,
      hideFor: {
        account: ['TENANT']
      },
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    },
    {
      id: 15,
      icon: <Cycle aria-hidden size="medium" />,
      title: t('common:manage_account.subscriptions_title'),
      description: t('common:manage_account.subscriptions_subtitle'),
      routeTo: 'subscriptions/device-subscriptions',
      testId: 'subscriptions',
      isHidden: !isInventoryOwnedWorkspace() || isV2Enabled,
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      },
      hideFor: {
        deployment: ['GLOP']
      }
    }
  ]

  const tilesToAdd = [
    {
      id: 12,
      icon: <Tag aria-hidden size="medium" />,
      title: t('common:manage_account.tags_title'),
      description: t('common:manage_account.tags_subtitle'),
      routeTo: 'tags',
      testId: 'tags',
      isHidden: !isInventoryOwnedWorkspace() || !subscriptionTags,
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    },
    {
      id: 15,
      icon: <Shop aria-hidden size="medium" />,
      title: t('licensing:order_history.order_history'),
      description: t('common:manage_account.order_history_subtitle'),
      routeTo: 'subscriptions/order-history',
      testId: 'order-history',
      isHidden: swappedMSPAccount,
      hideFor: {
        account: ['TENANT'],
        feature: 'glcp-service-catalog',
        deployment: ['COP']
      },
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    }
  ]

  if (isServiceCentric(LDFlags)) {
    tilesToAdd.forEach((tileData) => manageAccountList.push(tileData))
  } else {
    retiringTiles.forEach((tileData) => manageAccountList.push(tileData))
  }
  return manageAccountList
}

const ManageAccountContent = () => {
  const { t } = useTranslation([
    'authn',
    'common',
    'location',
    'licensing',
    'automations',
    'support_access_manager',
    'iam',
    'cds_billing_export',
    'manage'
  ])
  const { oidcUser } = useReactOidc()
  const [aliases, setAliases] = useState([])
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const LDFlags = useFlags()
  const ztoFlag = LDFlags['glcp-zto']

  const isV2Enabled = isV2()
  const [onPremWorkspaces, setOnPremWorkspaces] = useState([])
  const onPremWorkspaceFlag = LDFlags['glcp-dscc-dm-sm']
  const isBillingExportAvailable = useIsBillingExportAvailable()

  useEffect(() => {
    let isCurrent = true
    if (ztoFlag) {
      get('/ui-doorway/ui/v1/customer-aliases', {}, oidcUser.access_token).then(
        (resp) => {
          if (!isCurrent) return
          setAliases(resp?.data?.customer_aliases)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, t, ztoFlag])

  useEffect(() => {
    if (onPremWorkspaceFlag) {
      get(
        '/ui-doorway/ui/v1/onprem-workspaces',
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          setOnPremWorkspaces(response?.data?.items)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, onPremWorkspaceFlag])

  const swappedMSPAccount = getMSPCustomerAccount()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const manageAccountList = getManageAccountList(
    swappedMSPAccount,
    LDFlags,
    t,
    showWorkspaceString,
    isV2Enabled,
    aliases,
    onPremWorkspaces,
    isBillingExportAvailable
  )
  const navigate = useNavigate()
  const handleAccountCardClick = (currentItem) => {
    const mainRouter = '/manage-account/'
    const routeTo = `${mainRouter}${currentItem.routeTo}`
    navigate(routeTo)
  }
  const size = useContext(ResponsiveContext)
  const parentGrid = {
    columns: {
      small: ['auto'],
      medium: ['flex'],
      large: ['medium', 'auto'],
      xlarge: ['medium', 'auto']
    }
  }
  const childGrid = {
    columns: {
      small: ['flex'],
      medium: { count: 2, size: 'flex' },
      large: { count: 3, size: 'auto' },
      xlarge: { count: 3, size: 'auto' }
    }
  }
  return (
    <Page>
      <PageContent data-testid="manage_account">
        <Box
          direction="row"
          gap="medium"
          data-testid="manage-account-pageHeader"
        >
          <PageHeader
            pad={{ top: 'none' }}
            responsive
            title={t('common:button.manage_account', {
              account: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_CAPITALIZED
              )
            })}
            subtitle={
              showWorkspaceString || isGLOP()
                ? t('common:manage_act_msg_workspace')
                : t('common:manage_act_msg2')
            }
          />
        </Box>
        <Grid columns={parentGrid.columns[size]} rows="auto" gap="large">
          <Box width={{ min: 'medium' }}>
            <Card
              cardWidth="auto"
              testId="manage-account-type"
              margin={{ top: 'medium' }}
              CustomContent={<AccountDetailsInfo />}
              action={
                isV2Enabled ? (
                  <></>
                ) : (
                  <VisibilityWrapper
                    hideFor={{
                      account: ['TENANT'],
                      deployment: ['COP']
                    }}
                    rbac={{
                      resource: '/ccs/accounts/platform/customer/toggle-msp',
                      permission: 'ccs.accounts.platform.customer.edit'
                    }}
                  >
                    <Box flex={false}>
                      <Button
                        secondary
                        fill
                        label={t('authn:account_type.title', {
                          account: getWorkspaceString(
                            showWorkspaceString,
                            t,
                            WKSPC_CAPITALIZED
                          )
                        })}
                        // disabled={custAccountLoaded.account_type === 'MSP'}
                        onClick={() =>
                          navigate('/manage-account/account-type-overview')
                        }
                        testId="manage-account-type-btn"
                      />
                    </Box>
                  </VisibilityWrapper>
                )
              }
            />
          </Box>
          <Grid
            columns={childGrid.columns[size]}
            gap="medium"
            margin={{ top: 'medium', bottom: 'large' }}
          >
            {manageAccountList &&
              manageAccountList.map((item) =>
                !item.isHidden ? (
                  <VisibilityWrapper
                    key={item.id}
                    hideFor={item.hideFor}
                    rbac={item.rbac}
                  >
                    <Card
                      key={item.id}
                      cardWidth="auto"
                      description={item.description}
                      descriptionEllipsis
                      icon={item.icon}
                      testId={item.testId}
                      title={
                        <Typography
                          type="text"
                          weight="bold"
                          wordBreak="break-word"
                        >
                          {item.title}
                        </Typography>
                      }
                      titleEllipsis
                      titleSize="medium"
                      height="small"
                      onClick={() => handleAccountCardClick(item)}
                    />
                  </VisibilityWrapper>
                ) : null
              )}
          </Grid>
        </Grid>
      </PageContent>
      {showErrorNotification}
    </Page>
  )
}

const ManageAccountPage = () => {
  return (
    <Layout>
      <ManageAccountContent align="start" justify="start" />
    </Layout>
  )
}
export default ManageAccountPage
