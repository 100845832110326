// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box } from 'grommet'
import { Add } from 'grommet-icons'

import { Typography } from '../../../components'
import { getUserName } from '../../../utils/common-utils'

export const getNumNetworkingDevices = (
  dmUXIFlag,
  dmBridgeFlag,
  deviceStats
) => {
  const sensorStats = dmUXIFlag ? deviceStats.SENSOR : 0
  const bridgeStats = dmBridgeFlag ? deviceStats.BRIDGE : 0
  return (
    deviceStats.AP +
    deviceStats.GATEWAY +
    deviceStats.SWITCH +
    sensorStats +
    bridgeStats
  )
}

export const getSDIDropdownOptions = (option, t) => {
  if (option?.contact?.isNewContact) {
    // Check if it's a new contact
    return (
      <Typography icon={<Add />} type="text" testId="non-glp-option">
        {`${t('common:add')} ${option.contact.email}`}
      </Typography>
    )
  }
  return (
    <Box>
      <Typography emphasis type="text" testId="dropdown-option">
        {option?.contact?.contact_type === 'NONGLP'
          ? option?.contact?.email
          : getUserName(
              option?.contact?.first_name,
              option?.contact?.last_name,
              option?.contact?.email
            )}
      </Typography>
      <Typography
        weight={400}
        size="small"
        type="text"
        testId="dropdown-option-info"
      >
        {option?.contact?.contact_type === 'NONGLP'
          ? t('common:email_outside_platform')
          : option?.contact?.email}
      </Typography>
    </Box>
  )
}

export const getServiceDeliveryContacts = (data, t) => {
  const NO_VALUE = '--'
  let sdcName = NO_VALUE
  let sdcId = NO_VALUE
  const contactType = data?.contact_type
  if (contactType === 'NONGLP') {
    sdcName = data?.contact_id || NO_VALUE
    sdcId = t('common:email_outside_platform')
  } else {
    sdcName = data?.contact_name || NO_VALUE
    sdcId = data?.contact_id || NO_VALUE
  }
  return [sdcName, sdcId]
}
