// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Trash } from 'grommet-icons'
import isEqual from 'lodash/isEqual'

import { Typography, Button } from '../../../components'

function LocationDetails({ data = {}, title, handleDelete, placeholder }) {
  const { t } = useTranslation(['device'])

  return (
    <Box>
      <Box
        margin={{ top: 'medium' }}
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        border={{ side: 'bottom', color: 'border-weak' }}
      >
        <Typography level="4" type="heading" testId="assigned-location-header">
          {title}
        </Typography>
      </Box>
      {isEqual(data, {}) && (
        <Box margin={{ top: 'medium', bottom: 'small' }}>
          <Typography
            level="5"
            size="default"
            type="text"
            testId="no-location-assigned-content"
          >
            {placeholder}
          </Typography>
        </Box>
      )}
      <Box justify="between" direction="column" margin={{ top: 'small' }}>
        <Box direction="row" width="large">
          <Box>
            {Object.entries(data).map((datum) => (
              <Box
                direction="row-responsive"
                align="start"
                justify="start"
                margin={{ top: 'small' }}
                key={datum[0]}
              >
                <Box width="small" align="start">
                  <Typography
                    type="text"
                    size="medium"
                    weight="bold"
                    testId={`${datum[0]}-key`}
                  >
                    {t(datum[0])}
                  </Typography>
                </Box>
                {typeof datum[1] === 'object' ? (
                  <Box
                    width="medium"
                    align="start"
                    margin={{ left: 'medium' }}
                    pad={{ left: 'small' }}
                    data-testid={`${datum[0]}-value`}
                  >
                    <Typography
                      type="text"
                      size="medium"
                      testId="pcode-street-value"
                    >
                      {datum[1].postal_code} {datum[1].street_address}
                    </Typography>
                    <Typography
                      type="text"
                      size="medium"
                      testId="city-state-value"
                    >
                      {datum[1].city}/{datum[1].state}
                    </Typography>
                    <Typography
                      type="text"
                      size="medium"
                      testId="country-value"
                    >
                      {datum[1].country}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    width="medium"
                    align="start"
                    margin={{ left: 'medium' }}
                    pad={{ left: 'small' }}
                  >
                    <Typography
                      type="text"
                      size="medium"
                      testId={`${datum[0]}-value`}
                    >
                      {datum[1]}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          {!isEqual(data, {}) && (
            <Box
              justify="start"
              align="flex-end"
              pad={{ left: 'xlarge', top: 'xxsmall' }}
            >
              <Button
                size="medium"
                default
                icon={<Trash size="medium" />}
                testId="trash-btn"
                style={{ textDecorationLine: 'underline' }}
                onClick={handleDelete}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

LocationDetails.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired
}

export default React.memo(LocationDetails)
