// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  ButtonGroup,
  CCSForm,
  FormInput,
  FormTextArea,
  Loader,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../components'
import {
  validateEmail,
  validateOrganizationName,
  validateOrganizationDescription,
  validateOrganizationPhoneNumber,
  CHARACTER_LENGTH
} from '../../../utils/validation-utils'
import { getErrorMessage, post } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import Markdown from '../../../commoncomponents/Markdown'
import { getOrganizationId } from '../../../utils/feature-flag-utils'
import { updateV2RBACPolicies } from '../../../utils/rbac-api-utils'
import { useCCSContext } from '../../../context/ccs-context'
import { useVisibilityContext } from '../../../context/visibility-context'

const CreateOrganizationModal = ({
  showDialog,
  setNotification = () => {},
  refreshParent = () => {},
  refreshRouter = () => {}
}) => {
  const { csrfToken } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { t } = useTranslation(['common', 'iam'])
  const [loading, setLoading] = useState(null)
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action?.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action?.field]: action?.value }
        default:
          return currentState
      }
    },
    {
      name: '',
      description: '',
      phoneNumber: '',
      email: ''
    }
  )

  const closeDialog = () => {
    showDialog(false)
  }

  const handleSubmit = () => {
    setLoading(true)
    const orgId = getOrganizationId()
    const requestBody = {
      name: state?.name,
      description: state?.description,
      phoneNumber: state?.phoneNumber,
      email: state?.email
    }
    if (orgId && orgId !== '') {
      post(
        `/organizations/v2alpha1/organizations/${orgId}/activate`,
        requestBody
      ).then(
        (response) => {
          // This could affect the current user's permissions hence why we're calling updateV2RBACPolicies
          // updateV2RBACPolicies will not run on local as csrfToken will be null
          updateV2RBACPolicies(dispatchVisibilityContext, csrfToken)
          setNotification(
            displayNotification(
              <Markdown>
                {t(
                  'iam:organization.join_organization.create_new_org.success_message',
                  { organizationName: response?.data?.name }
                )}
              </Markdown>,
              'info',
              setNotification
            )
          )
          refreshRouter()
          refreshParent()
          closeDialog()
          setLoading(false)
        },
        (error) => {
          setNotification(
            displayNotification(
              getErrorMessage(error, t),
              'error',
              setNotification
            )
          )
          setLoading(false)
        }
      )
    }
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box pad={{ bottom: 'small' }}>
              <Typography type="heading" level="2" testId="create-org-header">
                {t('iam:organization.join_organization.create_new_org.title')}
              </Typography>
            </Box>
          }
          subtitle={
            <Box pad={{ bottom: 'small' }}>
              <Typography type="paragraph" testId="create-org-subtitle">
                {t(
                  'iam:organization.join_organization.create_new_org.description',
                  {
                    workspaces: t('common:business_object.wkspc_plural')
                  }
                )}
              </Typography>
            </Box>
          }
          onClose={closeDialog}
        />
      }
      content={
        <CCSForm
          errorMessage=""
          testId="create-org-form"
          onSubmit={handleSubmit}
          validate="blur"
          buttons={
            <Box pad={{ top: 'medium' }}>
              <ButtonGroup
                buttonList={[
                  {
                    id: 1,
                    label: loading
                      ? '∙  ∙  ∙'
                      : t(
                          'iam:organization.join_organization.create_new_org.title'
                        ),
                    type: 'submit',
                    primary: true,
                    disabled: loading,
                    testId: 'create-org-submit-btn'
                  },
                  {
                    id: 2,
                    label: t('common:cancel'),
                    type: 'reset',
                    testId: 'create-org-cancel-btn',
                    disabled: loading,
                    onClick: closeDialog
                  }
                ]}
                justifyGroup="start"
                testId="create-org-btns"
              />
              {loading && (
                <Box direction="row" gap="small" pad={{ top: 'medium' }}>
                  <Loader testId="create-org-loader" />
                  <Typography type="text" testId="loader-msg">
                    {t(
                      'iam:organization.join_organization.create_new_org.creating_organization'
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          }
        >
          <Box direction="column" gap="xsmall" pad={{ bottom: 'medium' }}>
            <FormInput
              name="name"
              required
              label={t('common:organization_landing.Organization_name')}
              inputType="text"
              value={state?.name}
              maxLength={CHARACTER_LENGTH?.ORG_NAME}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'name',
                  type: 'CHANGE_FIELD'
                })
              }}
              validate={(value) => {
                if (!validateOrganizationName(value)) {
                  return t(
                    'iam:organization.join_organization.create_new_org.name_char_validation_message'
                  )
                }
                return true
              }}
              testId="create-org-name"
            />
            <FormTextArea
              name="description"
              label={t('common:organization_landing.description')}
              value={state?.description}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'description',
                  type: 'CHANGE_FIELD'
                })
              }}
              maxLength={CHARACTER_LENGTH?.ORG_DESC}
              validate={(value) => {
                if (!validateOrganizationDescription(value)) {
                  return t(
                    'iam:organization.join_organization.create_new_org.description_char_validation_message'
                  )
                }
                return true
              }}
              testId="create-org-desc"
            />
            <FormInput
              name="phoneNumber"
              label={t(
                'common:organization_landing.Organization_contact_phone'
              )}
              inputType="text"
              value={state?.phoneNumber}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'phoneNumber',
                  type: 'CHANGE_FIELD'
                })
              }}
              maxLength={CHARACTER_LENGTH?.ORG_PHONE}
              validate={(value) => {
                if (!validateOrganizationPhoneNumber(value)) {
                  return t(
                    'iam:organization.join_organization.create_new_org.phoneNumber_char_validation_message'
                  )
                }
                return true
              }}
              testId="create-org-phone-number"
            />
            <FormInput
              name="email"
              label={t(
                'common:organization_landing.Organization_contact_email'
              )}
              inputType="text"
              value={state?.email}
              onChange={(event) => {
                dispatch({
                  value: event?.target?.value,
                  field: 'email',
                  type: 'CHANGE_FIELD'
                })
              }}
              maxLength={CHARACTER_LENGTH?.ORG_EMAIL}
              validate={(value) => {
                return validateEmail(value, t('common:invalid_email'))
              }}
              testId="create-org-email"
            />
          </Box>
        </CCSForm>
      }
      pad="medium"
      position="right"
      height="100%"
      onClose={closeDialog}
      testId="create-org-dialog"
    />
  )
}

CreateOrganizationModal.propTypes = {
  // Callback function to display dialog
  showDialog: PropTypes.func.isRequired,
  // Function to set success notification on parent page
  setNotification: PropTypes.func,
  refreshParent: PropTypes.func,
  refreshRouter: PropTypes.func
}

export default CreateOrganizationModal
