// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Notification, Typography } from '../../components'

const GtsInfo = ({ message }) => {
  return (
    <Box flex={{ shrink: 0 }} width="100%" data-testid="gts-info">
      <Notification
        backgroundColor="status-warning"
        testId="gts-account-check"
        type="inline"
        pad="xsmall"
        text={
          <Typography type="text" size="medium" testId="gts-info-message">
            <>{`${message} `}</>
          </Typography>
        }
      />
    </Box>
  )
}
GtsInfo.propTypes = {
  message: PropTypes.string.isRequired
}

export default GtsInfo
