// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Button, Markdown } from 'grommet'
import { useTranslation } from 'react-i18next'

import { del } from '../../../utils/api-utils'
import { Typography, ModalDialog } from '../../../components'
import { displayNotification } from '../../../utils/notificiation-utils'

const DeleteSubscriptionDialog = ({ onSetOpen, subscriptionId }) => {
  const { t } = useTranslation(['authz', 'automations'])
  const { oidcUser } = useReactOidc()
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const onDeleteApiCall = () => {
    del(
      `/events/v1beta1/subscriptions/${subscriptionId.id}`,
      {},
      oidcUser?.access_token
    ).then(
      () => {
        onSetOpen(true)
      },
      (error) => {
        setShowErrorNotification(
          displayNotification(error, t, setShowErrorNotification)
        )
      }
    )
  }

  const onCloseDialog = () => {
    onSetOpen(false)
  }

  return (
    <ModalDialog
      header={
        <Typography type="heading" level="2">
          {t('automations:webhooks.remove_event_subscription_del_dialog')}
        </Typography>
      }
      content={
        <Box margin={{ top: 'medium' }} width={{ min: 'full', max: 'medium' }}>
          {showErrorNotification}
          <Box margin={{ bottom: 'medium' }}>
            <Typography type="text" weight="bold">
              {t('automations:webhooks.subscription_label_del_dialog')}
            </Typography>
            <Markdown>{subscriptionId.eventType}</Markdown>
          </Box>

          <Box margin={{ bottom: 'medium' }}>
            <Typography type="text" weight="bold">
              {t('automations:webhooks.identity_label_del_dialog')}
            </Typography>
            <Markdown>{subscriptionId.createdBy}</Markdown>
          </Box>

          <Box margin={{ bottom: 'medium' }}>
            <Markdown>
              {t('automations:webhooks.remove_subs_label_del_dialog')}
            </Markdown>
          </Box>

          <Box
            direction="row"
            justify="end"
            gap="medium"
            margin={{ top: 'small' }}
          >
            <Button
              label="Cancel"
              onClick={onCloseDialog}
              testId="subscription-cancel-btn"
            />
            <Button
              primary
              type="button"
              label="Remove subscription"
              onClick={onDeleteApiCall}
              data-testid="subscription-submit-btn"
            />
          </Box>
        </Box>
      }
      onClose={onCloseDialog}
      testId="subscription-webhook-modal"
    />
  )
}

export default DeleteSubscriptionDialog
