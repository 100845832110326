// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../../utils/common-utils'

import AddSsoProfile from './components/add-sso-profile/AddSsoProfileWizard'
import ViewSsoProfile from './components/ViewSsoProfile'
import SsoProfilesPage from './SsoProfilesPage'

const SsoProfilesRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <Routes>
      <Route exact path="/" element={<SsoProfilesPage />} />
      <Route exact path="/add-sso-profile" element={<AddSsoProfile />} />
      <Route exact path="/edit-sso-profile/:id" element={<AddSsoProfile />} />
      <Route path="/:id" element={<ViewSsoProfile />} />
    </Routes>
  )
}

export default SsoProfilesRouter
