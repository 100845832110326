// Copyright 2024 Hewlett Packard Enterprise Development LP
import { DateInput } from 'grommet'
import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

const RateDateInput = ({
  value,
  onChange,
  resetGlobalErrMsg = undefined,
  ratesDateLimitStart,
  ...rest
}) => {
  dayjs.extend(utc)
  const [renderValue, setRenderValue] = useState('')

  useEffect(() => {
    if (!renderValue) {
      setRenderValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpStreamValueUpdate = (inputValue) => {
    let currentValue = value
    if (inputValue && dayjs(inputValue).isValid()) {
      currentValue = dayjs.utc(inputValue).format('YYYY-MM-DD')
    }
    if (currentValue !== value) {
      onChange(currentValue)
    }
    if (!inputValue || !dayjs(inputValue).isValid()) {
      setRenderValue(value)
    }
  }

  return (
    <DateInput
      value={renderValue}
      onChange={(event) => {
        if (resetGlobalErrMsg) {
          resetGlobalErrMsg()
        }
        setRenderValue(event.value)
      }}
      onBlur={() => {
        handleUpStreamValueUpdate(renderValue)
      }}
      calendarProps={{
        bounds: [ratesDateLimitStart, dayjs.utc().toISOString()]
      }}
      {...rest}
    />
  )
}

RateDateInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  resetGlobalErrMsg: PropTypes.func,
  ratesDateLimitStart: PropTypes.string.isRequired
}

export default RateDateInput
