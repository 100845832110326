// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { StatusCritical } from 'grommet-icons'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'
import dayjs from 'dayjs'

import {
  ActionButton,
  DataTable,
  FilterButton,
  Notification
} from '../../../components'
import { isCoP } from '../../../utils/feature-flag-utils'
import { getPaginationShowIdx } from '../../../utils/common-utils'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { tableKeys } from '../pages/customers/customer-details'
import {
  getAllSubscriptionTiers,
  getTableData
} from '../pages/customers/customer-apis'

import SideDetailsPanel from './SideDetailsPanel'
import {
  CancelSubscriptionModal,
  ModifySubscriptionModal,
  TransferSubscriptionModal
} from './subscription-actions'
import { UnclaimSubscriptionModal } from './subscription-actions/UnclaimSubscriptionModal'

const SubscriptionsDataTable = ({
  customerId = undefined,
  refreshCount = 0
}) => {
  const type = 'subscriptions'
  const { t } = useTranslation(['manage', 'common', 'licensing', 'device'])
  const { oidcUser } = useReactOidc()
  const [viewData, setViewData] = useState([])

  // for modals and side details panel
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showUnclaimModel, setShowUnclaimModel] = useState(false)
  const [showModifyModal, setShowModifyModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDetailsPanel, setShowDetailsPanel] = useState(false)

  // for table data, actions
  const [selectedRowData, setSelectedRowData] = useState({})
  const [searchVal, setSearchVal] = useState('')
  const [filterOptions, setFilterOptions] = useState({
    subscription_visibility: ['ALL']
  })
  const [rowDetails, setRowDetails] = useState(null)
  const [subscriptionTiers, setSubscriptionTiers] = useState([])

  const [errorMessage, setErrorMessage] = useState(null)
  const [subTiersError, setSubTiersError] = useState(null)

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const { 'glcp-dm-uxi': dmUXIFlag, 'glcp-dm-silver-peak': dmSilverPeakFlag } =
    useFlags()

  const LDFlags = useFlags()
  const unclaimFeatureFlag = LDFlags['glcp-sa-unclaim-actions']
  const columns = [
    {
      property: 'subscription_key',
      type: 'string',
      header: t('key'),
      primary: true
    },
    {
      property: 'subscription_tier_description',
      type: 'string',
      header: t('tier')
    },
    ...(customerId
      ? [
          {
            property: 'start_date',
            type: 'date',
            header: t('customer_details.start_date')
          },
          {
            property: 'end_date',
            type: 'date',
            header: t('customer_details.end_date')
          },
          {
            property: 'product_sku',
            type: 'string',
            header: t('customer_details.sku')
          }
        ]
      : [
          {
            property: 'end_date',
            type: 'date',
            header: t('expiration_date')
          }
        ]),
    {
      property: 'quantity',
      type: 'numeric',
      header: t('quantity')
    },

    {
      property: 'available_quantity',
      type: 'numeric',
      header: t('open_seats')
    },
    {
      property: 'evaluation_type',
      type: 'string',
      header: t('evaluation_type'),
      render: (datum) => {
        return datum.evaluation_type === 'NONE'
          ? t('PAID')
          : datum.evaluation_type
      }
    },
    ...(customerId
      ? []
      : [
          {
            property: 'platform_customer_id',
            type: 'string',
            header: t('customer_details.platform_customer_id')
          }
        ]),
    {
      property: 'actions',
      header: '',
      render: (datum) => (
        <VisibilityWrapper
          // TODO: replace with SM resource and permission once available
          rbac={{
            resource: '/ccs/device-management',
            permission: 'ccs.device-management.edit'
          }}
        >
          <ActionButton
            actions={[
              {
                label: t('Unclaim'),
                onClick: () => {
                  setSelectedRowData(datum)
                  setShowUnclaimModel(true)
                },
                hidden:
                  datum.platform_customer_id == null || !unclaimFeatureFlag
              },
              {
                label: t('transfer'),
                onClick: () => {
                  setSelectedRowData(datum)
                  setShowTransferModal(true)
                }
              },
              {
                label: t('modify'),
                onClick: () => {
                  setSelectedRowData(datum)
                  setShowModifyModal(true)
                }
              },
              {
                label: t('cancel'),
                onClick: () => {
                  setSelectedRowData(datum)
                  setShowCancelModal(true)
                }
              }
            ]}
            testId="subscriptions-action-btn"
          />
        </VisibilityWrapper>
      )
    }
  ]
  const getSubscriptionVisibilityFilter = (subscriptionVisibility) => {
    switch (subscriptionVisibility) {
      case 'UNEXPIRED_ONLY':
        return { expire_date_cut_off_in_millis: dayjs().valueOf() }
      case 'EXPIRED_ONLY':
        return {
          expire_date_cut_off_in_millis: 1,
          end_date_in_millis: dayjs().valueOf()
        }
      default:
        return {}
    }
  }
  const getSubscriptionsData = useCallback(() => {
    // formatting filterOptions to key value pairs of strings
    const filters = omitBy(
      Object.fromEntries(
        Object.entries(filterOptions).map(([key, val]) => [
          key,
          val.map((v) => v.trimStart()).join()
        ])
      ),
      (v) => v.length === 0
    )
    const request = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      ...(customerId && {
        platform_customer_id: customerId
      }),
      ...(filters?.expiring_days && {
        end_date_in_millis:
          dayjs().valueOf() + filters.expiring_days * 24 * 60 * 60 * 1000,
        expire_date_cut_off_in_millis: dayjs().valueOf()
      }),
      ...getSubscriptionVisibilityFilter(filters?.subscription_visibility),
      ...omit(filters, ['expiring_days', 'subscription_visibility']),
      ...(searchVal.trimStart().length > 0 && {
        subscription_key_pattern: encodeURIComponent(searchVal.trimStart())
      })
    }

    getTableData(
      type,
      oidcUser.access_token,
      request,
      setTotalItems,
      setErrorMessage,
      t
    ).then((data) => {
      setViewData(data)
    })
  }, [oidcUser.access_token, searchVal, filterOptions, page, t, customerId])

  useEffect(() => {
    getSubscriptionsData()
  }, [getSubscriptionsData, refreshCount])

  useEffect(() => {
    getAllSubscriptionTiers(
      oidcUser.access_token,
      setSubTiersError,
      t,
      LDFlags
    ).then((data) => {
      const tiers =
        data?.supported_subscription_tiers_by_device_type?.reduce(
          (prev, curr) => {
            return { ...prev, ...curr.subscription_tiers_description }
          },
          {}
        ) || {}
      const tierOptions = Object.entries(tiers).map(
        ([valueName, valueLabel]) => ({
          valueName,
          valueLabel
        })
      )
      setSubscriptionTiers(tierOptions || [])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t, dmUXIFlag, dmSilverPeakFlag])

  const createRowdata = (datum) => {
    const modifiedData = []
    Object.entries(
      omit(datum, ['end_date_in_millis', 'start_date_in_millis', 'tagIds'])
    ).forEach(([key, value]) => {
      if (tableKeys[type] && tableKeys[type][key] && value) {
        // if the key is present in table keys, it will be an array of objects
        // so add the heading and values
        modifiedData.push({
          key: t(`customer_details.${key}`),
          value: key,
          isHeading: true
        })
        if (value.length) {
          // if the value for that key has length, push the formatted data to modifiedData, otherwise no data found
          if (tableKeys[type][key].customKeys) {
            const { keys } = tableKeys[type][key]
            value.forEach((v) => {
              keys?.forEach((keyValue) => {
                modifiedData.push({
                  key: t(`customer_details.${keyValue}`),
                  value: v[keyValue],
                  ...(keys.at(-1) === keyValue && { addRuler: true })
                })
              })
            })
          } else {
            value.forEach((v) => {
              modifiedData.push({
                key: v[tableKeys[type][key].key],
                value: v[tableKeys[type][key].value]
              })
            })
          }
        } else {
          modifiedData.push({
            key: t(`customer_details.${key}`),
            value: '',
            noDataFound: true
          })
        }
      } else {
        modifiedData.push({
          key: t(`customer_details.${key}`),
          value
        })
      }
    })
    return modifiedData
  }
  return (
    <>
      {showUnclaimModel && (
        <UnclaimSubscriptionModal
          currentCustomerId={selectedRowData?.platform_customer_id}
          setShowUnclaimModal={setShowUnclaimModel}
          subscriptionKey={selectedRowData?.subscription_key}
          onSuccess={getSubscriptionsData}
        />
      )}
      {showTransferModal && (
        <TransferSubscriptionModal
          currentCustomerId={selectedRowData.platform_customer_id}
          setShowTransferModal={setShowTransferModal}
          subscriptionKey={selectedRowData.subscription_key}
          onSuccess={getSubscriptionsData}
        />
      )}
      {showModifyModal && (
        <ModifySubscriptionModal
          subscriptionData={selectedRowData}
          setShowModal={setShowModifyModal}
          onSuccess={getSubscriptionsData}
        />
      )}
      {showCancelModal && (
        <CancelSubscriptionModal
          customerId={selectedRowData.platform_customer_id}
          subscriptionKey={selectedRowData.subscription_key}
          setShowModal={setShowCancelModal}
          onSuccess={getSubscriptionsData}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
      {subTiersError && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setSubTiersError(null)}
          testId="critical-notification"
          text={subTiersError}
          icon={<StatusCritical size="medium" />}
        />
      )}
      {showDetailsPanel ? (
        <SideDetailsPanel
          data={rowDetails}
          onClose={(e) => setShowDetailsPanel(e)}
          type={type}
        />
      ) : null}
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        filterButton={
          <FilterButton
            initSelectedFilterValues={filterOptions}
            filterAttributes={[
              ...(customerId
                ? []
                : [
                    {
                      label: t('customer_id'),
                      name: 'platform_customer_id',
                      selectionType: 'text'
                    }
                  ]),
              {
                label: t('sku'),
                name: 'sku',
                selectionType: 'text'
              },
              {
                label: t('evaluation_type'),
                name: 'evaluation_type',
                selectionType: 'radio',
                values: [
                  {
                    valueLabel: t('PAID'),
                    valueName: 'NONE'
                  },
                  { valueLabel: t('EVAL'), valueName: 'EVAL' }
                ]
              },
              {
                label: t('customer_details.subscription_tier'),
                name: 'subscription_tier',
                selectionType: 'radio',
                values: subscriptionTiers,
                height: 'small'
              },
              {
                label: t('device_type'),
                name: 'device_type',
                values: [
                  { valueLabel: t('access_points'), valueName: 'AP' },
                  { valueLabel: t('switches'), valueName: 'SWITCH' },
                  { valueLabel: t('gateways'), valueName: 'GATEWAY' },
                  { valueLabel: t('bridge'), valueName: 'BRIDGE' },
                  ...(!isCoP() && dmSilverPeakFlag
                    ? [
                        {
                          valueLabel: t('device:sd_wan_gateways'),
                          valueName: 'SD_WAN_GW'
                        }
                      ]
                    : []),
                  {
                    valueLabel: t('servers'),
                    valueName: 'COMPUTE,DHCI_COMPUTE'
                  },
                  {
                    valueLabel: t('storage'),
                    valueName: 'STORAGE,DHCI_STORAGE'
                  },
                  ...(!isCoP() && dmUXIFlag
                    ? [{ valueLabel: t('sensors'), valueName: 'SENSOR' }]
                    : [])
                ]
              },
              {
                label: t('customer_details.expiration_date'),
                name: 'expiring_days',
                selectionType: 'radio',
                values: [
                  {
                    valueLabel: t('customer_details.less_than_30days'),
                    valueName: '30'
                  },
                  {
                    valueLabel: t('customer_details.less_than_60days'),
                    valueName: '60'
                  },
                  {
                    valueLabel: t('customer_details.less_than_90days'),
                    valueName: '90'
                  }
                ]
              },
              {
                label: t('licensing:subscription_visibility'),
                name: 'subscription_visibility',
                selectionType: 'radio',
                values: [
                  {
                    valueLabel: t('licensing:show_all_subscriptions'),
                    valueName: 'ALL'
                  },
                  {
                    valueLabel: t('licensing:hide_expired_subscriptions'),
                    valueName: 'UNEXPIRED_ONLY'
                  },
                  {
                    valueLabel: t('licensing:show_expired_subscriptions_only'),
                    valueName: 'EXPIRED_ONLY'
                  }
                ]
              }
            ]}
            onFilterValuesChange={(filters) => {
              setFilterOptions(filters)
              setPage(1)
            }}
            testId="subscriptions-filter-btn"
          />
        }
        grid={{
          columns,
          data: viewData,
          onClickRow: (data) => {
            setRowDetails(createRowdata(data.datum))
            setShowDetailsPanel(true)
          }
        }}
        search={{
          onSearchValueChange: (value) => {
            setPage(1)
            setSearchVal(value)
          },
          placeholder: t('search_subscription_key')
        }}
        summary={{
          entityName: t('susbscription_table_summary')
        }}
        testId="generic-data-table"
      />
    </>
  )
}

SubscriptionsDataTable.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refreshCount: PropTypes.number
}

export { SubscriptionsDataTable }
