// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useReducer, useEffect } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Preferences } from '../../../commoncomponents/profile-preferences/Preferences'
import { PreferencesIdleTimeout } from '../../../commoncomponents/profile-preferences/PreferencesIdleTimeout'
import { Typography, CCSForm } from '../../../components'
import { put, getErrorMessage } from '../../../utils/api-utils'
import {
  getLocaleMap,
  setDayJSLang,
  useIdleTimeout
} from '../../../utils/common-utils'
import ProfileButtons from '../ProfileButtons'
import FormSuccessInfo from '../../../commoncomponents/form-success/FormSuccessInfo'
import { getPreferences } from '../../../commoncomponents/profile-preferences/common-utils'
import { CCSActions, useCCSContext } from '../../../context/ccs-context'

const ProfilePreferences = () => {
  const { oidcUser } = useReactOidc()
  const [originalState, setOriginalState] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const { t, i18n } = useTranslation(['authn'])
  const minIdleTimeout = 5
  const maxIdleTimeout = 1440
  const [idleTimeout, setIdleTimeout] = useState(null)
  const { dispatchCCSContext } = useCCSContext()
  const [reset] = useIdleTimeout()
  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleTimeout])

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return {
            ...currentState,
            [action.field]: action.value,
            edited: true
          }
        case 'FORM_EDITED':
          return { ...currentState, edited: action.value }
        case 'SET_STATE':
          return { ...action.value, edited: false }
        default:
          return currentState
      }
    },
    {
      edited: false
    }
  )

  useEffect(() => {
    if (originalState.language) {
      const lang = getLocaleMap(originalState.language)
      // This will translate dayJS strings to desired language
      setDayJSLang(originalState.language)
      i18n.changeLanguage(lang)
      // TODO: keep this console log until we fully integrate i18n
      console.log(`INFO: User preference locale code is '${lang}'`)
    }
  }, [originalState.language, i18n])

  useEffect(() => {
    getPreferences(t, oidcUser, dispatch, setOriginalState, setErrorMessage)
  }, [t, oidcUser.access_token, oidcUser])
  // CCS-2747 - Uncomment for the theme field
  // const themeOptions = ['Light', 'Dark', 'Auto']

  const handleDiscardChangesClick = () => {
    dispatch({
      value: originalState,
      type: 'SET_STATE'
    })
  }
  const handleSubmit = () => {
    setErrorMessage('')
    const timeout = state?.timeout_number * 60
    put(
      '/accounts/ui/v1/user/profile/preferences',
      {
        // include all of the fields, even ununsed ones, since this API is a PUT
        language: state.language,
        idle_timeout: timeout,
        theme: state.theme,
        maintenance_notifications: state.maintenance_notifications,
        update_notifications: state.update_notifications,
        multi_fa_enabled: state.multi_fa_enabled,
        multi_fa_registration: state.multi_fa_registration
        // TODO: CCS-2709 - Add support for console settings
        // console_interface: 'Clean',
        // console_zoom_accessibility: '100% (Default)',
      },
      oidcUser.access_token
    ).then(
      (response) => {
        setOriginalState(state)
        dispatch({
          value: false,
          type: 'FORM_EDITED'
        })
        // TODO: CCS-2501 - show toast notifications
        if (response && response.data && response.data.message) {
          setUpdateSuccess(true)
          dispatchCCSContext({
            type: CCSActions.SET_IDLE_TIMEOUT,
            data: timeout * 1000
          })
          setIdleTimeout(timeout * 1000)
        }
        // TODO: CCS-2677 - use banner until toast notifications are implemented
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  return (
    // TODO: CCS-2747 - Set the width to xlarge to allow the Display column
    <Box width="medium" margin={{ top: 'small' }}>
      <Typography
        level="2"
        type="heading"
        weight="normal"
        testId="preferences-title"
      >
        {t('preferences.title')}
      </Typography>
      <Typography
        size="medium"
        type="text"
        weight="normal"
        testId="preferences-description"
      >
        {t('preferences.description')}
      </Typography>
      <CCSForm
        testId="profile-preferences-form"
        validate="blur"
        onSubmit={handleSubmit}
        onReset={handleDiscardChangesClick}
        errorMessage={errorMessage}
        buttons={
          <ProfileButtons updateLabel="Save Changes" edited={state.edited} />
        }
      >
        <Box direction="row" gap="xlarge">
          <Box direction="column" width="medium">
            <Preferences dispatch={dispatch} state={state} />
            <Typography
              level="3"
              type="heading"
              weight="normal"
              margin={{ top: 'none', bottom: 'small' }}
              testId="session-timeout-subtitle"
            >
              {t('preferences.timeout_subhdr')}
            </Typography>
            <Box gap="small">
              <PreferencesIdleTimeout
                dispatch={dispatch}
                state={state}
                minIdleTimeout={minIdleTimeout}
                maxIdleTimeout={maxIdleTimeout}
              />
            </Box>
            {updateSuccess && (
              <FormSuccessInfo message={t('saml_sso.view_edit.upd_success')} />
            )}
          </Box>
          {/* TODO: CCS-2747 - Uncomment the column header and the theme field */}
          {/* <Box direction="column" width="medium">
            <Typography
              level="3"
              type="heading"
              weight="normal"
              margin={{ top: 'medium', bottom: 'small' }}
              testId="display-subtitle"
            >
              Display
            </Typography>
            <FormField
              margin={{ bottom: 'medium' }}
              label="Theme"
              name="theme"
              required
              data-testid="theme-form-field"
            >
              <Select
                name="theme"
                options={themeOptions}
                multiple={false}
                value={state.theme}
                onChange={({ option }) => {
                  dispatch({
                    value: option,
                    field: 'theme',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            </FormField>

            <FormField
              margin={{ bottom: 'medium' }}
              label="Console Interface"
              name="console-interface"
              required
              data-testid="console-interface-form-field"
            >
              <Select
                name="console-interface"
                options={['Clean']}
                multiple={false}
                value={state.console_interface}
                onChange={({ option }) => {
                  dispatch({
                    value: option.code,
                    field: 'console_interface',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            </FormField>
            <FormField
              margin={{ bottom: 'medium' }}
              label="Console Zoom Accessibility"
              name="console-zoom-accessibility"
              required
              data-testid="console-zoom-accessibility-form-field"
            >
              <Select
                name="console-zoom-accessibility"
                options={['100% (Default)']}
                multiple={false}
                value={state.console_zoom_accessibility}
                onChange={({ option }) => {
                  dispatch({
                    value: option.code,
                    field: 'console_zoom_accessibility',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            </FormField>
          </Box> */}
        </Box>
      </CCSForm>
    </Box>
  )
}

export default ProfilePreferences
