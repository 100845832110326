// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Dropdown, Typography } from '../../../components'

import { ldFlagsStatusLang } from './utils'

const FeatureFlagsStatusTableHeader = ({
  envKey,
  label = '',
  headerFilter,
  updateHeaderFilter
}) => {
  const [clickingCount, setClickingCount] = useState(0)

  useEffect(() => {
    if (!headerFilter[envKey]) {
      setClickingCount(0)
    }
  }, [headerFilter, envKey])

  const statusOptions = [
    { label: ldFlagsStatusLang.ALL, value: 0 },
    { label: ldFlagsStatusLang.ON, value: 1 },
    { label: ldFlagsStatusLang.OFF, value: 2 },
    { label: ldFlagsStatusLang.on_rules, value: 3 },
    { label: ldFlagsStatusLang.off_rules, value: 4 }
  ]

  return (
    <Box direction="column" gap="small" width={{ max: '150px' }}>
      <Box>
        <Typography
          type="text"
          emphasis
          testId={`headerFilter-${envKey}-label`}
          truncate
        >
          {label}
        </Typography>
      </Box>
      <Box>
        <Dropdown
          testId={`headerFilter-${envKey}-dropdown`}
          options={statusOptions}
          value={clickingCount}
          onChangeDropdown={(option) => {
            setClickingCount(option)
            updateHeaderFilter(envKey, option)
          }}
        />
      </Box>
    </Box>
  )
}

FeatureFlagsStatusTableHeader.propTypes = {
  envKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  headerFilter: PropTypes.object.isRequired,
  updateHeaderFilter: PropTypes.func.isRequired
}
export default FeatureFlagsStatusTableHeader
