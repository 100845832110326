// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useState } from 'react'

import { FormInput, Loader } from '../../../../components'
import { validateSAMLAuthzDomain } from '../../../manage-account/utils'

const EmailTextInput = ({
  isSubmitting,
  errorMsg = '',
  setErrorMsg = () => {},
  setNotificationInfo = () => {}
}) => {
  const { t } = useTranslation(['common'])
  const { oidcUser } = useReactOidc()
  const [loading, setLoading] = useState(false)

  return (
    <>
      <FormInput
        name="email"
        inputType="text"
        label={t('common:email')}
        labelHelper={t('iam:igc_users.email_text_input')}
        required
        onChange={(value) => {
          if (!isEmpty(value?.target?.value)) {
            setErrorMsg('')
          }
        }}
        validate={(value) => {
          validateSAMLAuthzDomain(
            value,
            oidcUser,
            t,
            setLoading,
            setNotificationInfo,
            () =>
              setErrorMsg(
                t('iam:ccs_attribute.add_saml_user_to_org_workspace_warning', {
                  user: t('common:business_object.user'),
                  domain: t('common:business_object.domain'),
                  organization: t(
                    'common:business_object.organization_lowercase'
                  ),
                  workspaces: t('common:business_object.wkspc_plural')
                })
              )
          )

          return true
        }}
        error={errorMsg}
        disabled={isSubmitting}
        testId="add-user-email"
      />
      {!isSubmitting && loading && (
        <Loader size="20px" testId="domain-validation-loader" />
      )}
    </>
  )
}

EmailTextInput.propTypes = {
  /**
   * Button disable flag
   */
  isSubmitting: PropTypes.bool.isRequired,

  /**
   * Error message to display
   */
  errorMsg: PropTypes.string,

  /**
   * Set error message to display
   */
  setErrorMsg: PropTypes.func,

  /**
   * Set error message to display
   */
  setNotificationInfo: PropTypes.func
}

export default EmailTextInput
