// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Box, FormField, Select } from 'grommet'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  Notification,
  CCSForm,
  Button
} from '../../components'
import { post, get, patch } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { displayDeviceTypeEnum, getUserName } from '../../utils/common-utils'
import { validateEmail } from '../../utils/validation-utils'

import {
  getServiceDeliveryContacts,
  getSDIDropdownOptions
} from './utils/common-methods'

const NewServiceDeliveryContactModal = ({
  setModalOpen,
  datum,
  openSuccessModal,
  isCCSManager = false,
  customerId = undefined
}) => {
  const [serviceDeliveryContactName, setContactName] = useState(
    datum[0].contact_name
  )
  const [serviceDeliveryContactEmail, setContactEmail] = useState(
    datum[0].contact_id
  )
  const [removeCurrentContact, setRemoveCurrentContact] = useState(false)

  const handleRemoveCurrentContact = () => {
    setRemoveCurrentContact(true)
    setContactName('')
    setContactEmail('')
  }
  useEffect(() => {
    if (datum[0].contact_name && datum[0].contact_id) {
      setContactName(datum[0].contact_name)
      setContactEmail(datum[0].contact_id)
    }
  }, [datum])
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()

  const [assignedDevicesCount, setAssignedDevicesCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [listOfContact, setListOfContact] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [assignedContact, setAssignedContact] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [formError, setFormError] = useState('')
  const [showServiceDeliveryModal, setShowServiceDeliveryModal] = useState(true)
  const [appendList, setAppendList] = useState(true)
  // for paginating on scroll contact
  const itemsPerPage = 50
  const [page, setPage] = useState(0)
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const LDFlags = useFlags()

  useEffect(() => {
    // API call to get assigned contact stats
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/stats`
      : '/ui-doorway/ui/v1/devices/stats'

    const reqBody = {
      devices:
        datum?.map((value) => {
          return {
            serial_number: value?.serial_number,
            part_number: value?.part_number,
            mac_address: value?.mac_address || ''
          }
        }) || [],
      filter: { assigned_contact: true }
    }
    if (isCCSManager) reqBody.platform_customer_id = customerId
    post(url, reqBody, oidcUser.access_token).then(
      (response) => {
        if (response?.data?.count) {
          setAssignedDevicesCount(response?.data?.count)
        } else setShowServiceDeliveryModal(true)
      },
      () => {
        setAssignedDevicesCount('ERROR')
      }
    )
  }, [datum, oidcUser.access_token]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // API call to get list of contact
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/users`
      : '/ui-doorway/ui/v1/um/users'

    const request = {
      limit: itemsPerPage,
      offset: (page || 0) * itemsPerPage,
      include_unverified: false,
      ...(searchTerm?.trimStart()?.length && {
        search_string: searchTerm?.trimStart()
      })
    }
    if (isCCSManager) request.platform_customer_id = customerId
    get(url, request, oidcUser.access_token).then(
      (response) => {
        const contactResponse = response?.data?.users
        if (contactResponse) {
          let modifiedContactResponse = []
          if (appendList) {
            modifiedContactResponse = [...listOfContact, ...contactResponse]
            if (selectedContact)
              modifiedContactResponse = [
                ...modifiedContactResponse,
                selectedContact
              ]
            modifiedContactResponse = uniqBy(
              modifiedContactResponse,
              'contact.email'
            )
          } else modifiedContactResponse = contactResponse
          if (
            modifiedContactResponse?.length === 0 &&
            searchTerm &&
            validateEmail(searchTerm) === true
          ) {
            modifiedContactResponse.push({
              contact: {
                first_name: '',
                last_name: '',
                email: searchTerm,
                isNewContact: true
              }
            })
          }
          setListOfContact(modifiedContactResponse)
          setTotalItems(response?.data?.pagination?.total_count || totalItems)
        }
      },
      (error) => {
        setErrorMessage(displayApiError(error, t, setErrorMessage))
      }
    )
  }, [oidcUser.access_token, page, searchTerm, t]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/devices`
      : '/ui-doorway/ui/v1/devices'

    const reqBody = {
      devices:
        datum?.map((value) => {
          return {
            serial_number: value?.serial_number,
            ...([
              displayDeviceTypeEnum.STORAGE,
              displayDeviceTypeEnum.DHCI_STORAGE,
              displayDeviceTypeEnum.COMPUTE,
              displayDeviceTypeEnum.DHCI_COMPUTE,
              displayDeviceTypeEnum.PCE
            ].includes(value?.display_device_type)
              ? { part_number: value?.part_number }
              : { mac_address: value?.mac_address }),
            device_type: value?.device_type,
            contact_id:
              removeCurrentContact && !assignedContact
                ? ''
                : assignedContact?.contact?.email,
            contact_name:
              removeCurrentContact && !assignedContact
                ? ''
                : getUserName(
                    assignedContact?.contact?.first_name,
                    assignedContact?.contact?.last_name,
                    assignedContact?.contact?.email
                  ),
            contact_type:
              removeCurrentContact &&
              !assignedContact &&
              LDFlags['glcp-pdl-as-sdi']
                ? null
                : assignedContact?.contact?.contact_type
          }
        }) || []
    }
    if (isCCSManager) reqBody.platform_customer_id = customerId
    patch(url, reqBody, oidcUser.access_token).then(
      () => {
        setModalOpen(false)
        openSuccessModal()
      },
      (error) => {
        setErrorMessage(displayApiError(error, t, setErrorMessage))
      }
    )
  }

  const buttonList = [
    {
      label: t('common:cancel'),
      default: true,
      testId: 'cancel-btn',
      onClick: () => setModalOpen(false)
    },
    {
      label: t('common:save'),
      primary: true,
      testId: 'save-btn',
      onClick: handleSave
    }
  ]

  const handleDebouncedSearchValue = debounce((value) => {
    setAppendList(false)
    setPage(0)
    setSearchTerm(value)
  }, 500)

  const isDifferentContact = (contacts) => {
    const firstDatamContactName = contacts[0].contact_name
    return contacts.some((item) => item.contact_name !== firstDatamContactName)
  }

  const isValidContact = (name, emailId) => {
    return name !== '--' && emailId !== '--'
  }

  const getNotificationText = () => {
    let notificationText
    // NOSONAR
    if (assignedDevicesCount === 'ERROR') {
      notificationText =
        datum?.length > 1
          ? t('assign_contact_status_error_multi')
          : t('assign_contact_status_error_single')
    } else if (
      (assignedDevicesCount === 1 && datum.length === 1) ||
      (assignedDevicesCount > 1 && !isDifferentContact(datum))
    ) {
      notificationText = t('single_device_sdc_assigned')
    } else {
      notificationText = (
        <Trans i18nKey="multiple_device_sdi_assigned_new" t={t}>
          <strong>
            {{
              devicesAssigned: assignedDevicesCount,
              totalSelectedDevices: datum.length
            }}
          </strong>
        </Trans>
      )
    }
    return notificationText
  }

  const showServiceDeliveryContacts = () => {
    const sdcContacts = getServiceDeliveryContacts(datum[0], t)
    if (
      // multiple devices with different contacts
      !removeCurrentContact &&
      datum?.length > 1 &&
      isDifferentContact(datum)
    ) {
      return (
        <Typography
          size="large"
          type="text"
          margin={{
            top: 'medium',
            right: 'xlarge',
            bottom: 'large'
          }}
        >
          {t('multiple_contacts')}
        </Typography>
      )
    }
    if (
      // single device or multiple devices with same contacts
      !removeCurrentContact &&
      isValidContact(sdcContacts[0], sdcContacts[1]) &&
      ((datum?.length > 1 && !isDifferentContact(datum)) || datum?.length === 1)
    ) {
      return (
        <>
          <Typography
            size="small"
            level="2"
            type="heading"
            margin={{
              top: 'medium',
              right: 'xlarge'
            }}
          >
            {sdcContacts[0]}
          </Typography>
          <Typography
            size="small"
            type="text"
            margin={{
              right: 'xlarge',
              bottom: 'medium'
            }}
          >
            {sdcContacts[1]}
          </Typography>
        </>
      )
    }
    return (
      <Typography type="text" size="large" margin={{ bottom: 'medium' }}>
        {LDFlags['glcp-pdl-as-sdi']
          ? t('no_service_delivery_contact_assigned')
          : t('no_contact_assigned')}
      </Typography>
    )
  }
  return (
    <>
      {showServiceDeliveryModal && (
        <ModalDialog
          width="large"
          testId="service-delivery-contact-modal-dialog"
          onClose={() => {
            setModalOpen(false)
          }}
          header={
            <ModalHeader
              title={
                <Typography
                  type="heading"
                  level="1"
                  weight="bold"
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                  testId="service-delivery-contact-modal-title"
                >
                  {t('service_delivery_contact')}
                </Typography>
              }
            />
          }
          content={
            <Box flex={{ shrink: 0 }}>
              <>
                <Typography
                  type="text"
                  size="large"
                  margin={{ top: 'xsmall' }}
                  testId="service-delivery-contact-modal-subtitle"
                >
                  {t('service_delivery_contact_description')}
                </Typography>
                {assignedDevicesCount ? (
                  <Box margin={{ top: 'medium' }}>
                    <Notification
                      backgroundColor="status-warning"
                      testId="warning-inline-notification"
                      type="inline"
                      text={getNotificationText()}
                    />
                  </Box>
                ) : null}
                <Box
                  direction="row-responsive"
                  align="center"
                  margin={{ top: 'xsmall' }}
                >
                  <Typography
                    type="heading"
                    level="2"
                    weight="bold"
                    style={{
                      whiteSpace: 'nowrap'
                    }}
                    margin={{ top: 'medium', bottom: 'none' }}
                  >
                    {t('current_service_delivery_contact')}
                  </Typography>
                  {!removeCurrentContact &&
                  isValidContact(
                    serviceDeliveryContactName,
                    serviceDeliveryContactEmail
                  ) &&
                  assignedDevicesCount >= 1 ? (
                    <Button
                      label={
                        <Typography type="heading" size="small">
                          {t('remove_current_contact')}
                        </Typography>
                      }
                      secondary
                      margin={{ left: 'auto', top: 'medium' }}
                      onClick={handleRemoveCurrentContact}
                      testId="remove_current_contact"
                    />
                  ) : null}
                </Box>

                {showServiceDeliveryContacts()}

                <Typography level="2" type="heading" margin={{ top: 'small' }}>
                  {t('change_to')}
                </Typography>

                <CCSForm
                  validate="submit"
                  errorMessage=""
                  testId="service-delivery-contact-form"
                >
                  <Box
                    margin={{ top: 'xsmall', bottom: 'xxsmall' }}
                    direction="row-responsive"
                    gap="medium"
                  >
                    <FormField
                      data-testid="service-delivery-contact-field"
                      label={t('service_delivery_contact')}
                      name="name"
                      width="xxlarge"
                      required={{ indicator: false }}
                      error={formError || undefined}
                    >
                      <Select
                        name="name"
                        placeholder={t('sdi_select_placeholder')}
                        emptySearchMessage={t('sdi_select_search_msg')}
                        options={listOfContact}
                        labelKey={(option) => {
                          return getUserName(
                            option?.contact?.first_name,
                            option?.contact?.last_name,
                            option?.contact?.email
                          )
                        }}
                        valueKey={(option) => {
                          return option?.contact?.email
                        }}
                        value={selectedContact || ''}
                        searchPlaceholder={t('search')}
                        onSearch={(searchText) =>
                          handleDebouncedSearchValue(searchText)
                        }
                        onChange={({ option }) => {
                          setFormError('')
                          let contactType = 'GLP'
                          if (option.contact.isNewContact) {
                            // Check if it's a new contact
                            // Add the new contact to listOfContact
                            setListOfContact((prevListOfContact) => [
                              ...prevListOfContact,
                              {
                                ...option,
                                contact: {
                                  ...option.contact,
                                  isNewContact: false, // Set isNewContact to false
                                  contact_type: 'NONGLP'
                                }
                              }
                            ])
                            contactType = 'NONGLP'
                          }

                          setSelectedContact({
                            ...option,
                            contact: {
                              ...option.contact,
                              contact_type: contactType
                            }
                          })
                          setAssignedContact({
                            ...option,
                            contact: {
                              ...option.contact,
                              contact_type: contactType
                            }
                          })
                        }}
                        noBorder
                        onClose={() => {
                          if (searchTerm) {
                            setListOfContact([])
                            setPage(0)
                            setSearchTerm('')
                            setAppendList(true)
                          }
                        }}
                        data-testid="service-delivery-contact-input"
                        onMore={() => {
                          if (
                            totalItems > itemsPerPage &&
                            page < totalItems / itemsPerPage - 1
                          ) {
                            setAppendList(true)
                            setPage(page + 1)
                          }
                        }}
                      >
                        {(option, { selected }) => (
                          <Box>
                            <Button
                              kind="option"
                              label={getSDIDropdownOptions(option, t)}
                              selected={selected}
                              fill="horizontal"
                              align="start"
                              testId={option.contact.email}
                            />
                          </Box>
                        )}
                      </Select>
                    </FormField>
                  </Box>
                </CCSForm>
              </>
            </Box>
          }
          footer={
            <ModalFooter
              right={
                <ButtonGroup
                  buttonList={buttonList}
                  testId="modal-action-btns"
                />
              }
            />
          }
        />
      )}
      {errorMessage}
    </>
  )
}

NewServiceDeliveryContactModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  datum: PropTypes.array.isRequired,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string
}

export { NewServiceDeliveryContactModal }
