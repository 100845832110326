// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useLocation, useNavigate } from 'react-router-dom'

import { Typography } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'

import AlertsTasksPage from './AlertsEvents'
import AnnouncementsPage from './Announcements'
import Menu, { BackButton } from './menu'

/**
 * NotificationLogs component
 * To DO :
 * 1. Disperse the useReducer into groups to make code more readable
 * 2. Optimize pagination eg : nextSearchURI, nextURI use simultaneously
 * 3. To look for local state variable, from useReducer
 */
const NotificationLogs = () => {
  const { t } = useTranslation(['manage'])
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const initialPage = queryParams.get('view') || 'alerts-tasks'
  const [view, setView] = useState(initialPage)

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search)
    if (currentParams.get('view') !== view) {
      currentParams.set('view', view)
      navigate(
        {
          pathname: location.pathname,
          search: `?${currentParams.toString()}`
        },
        { replace: true }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  return (
    <Layout>
      <Box gap="medium" pad={{ left: 'small' }}>
        <BackButton />
        <Box pad={{ left: 'small', bottom: 'small', top: 'small' }}>
          <Typography
            level={1}
            type="heading"
            testId="notifications-logs-user-page-title"
          >
            {t('notification.alerts_tasks.heading')}
          </Typography>
        </Box>
        <Box direction="row">
          <Menu
            id={view === 'announcements' ? 2 : 1}
            setId={(id) => setView(id === 2 ? 'announcements' : 'alerts-tasks')}
          />
          {view === 'announcements' ? (
            <AnnouncementsPage />
          ) : (
            <AlertsTasksPage />
          )}
        </Box>
      </Box>
    </Layout>
  )
}
export default NotificationLogs
