// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
const ChatActions = {
  UPDATE_CHAT: 'UPDATE_CHAT',
  OPEN_CHAT: 'OPEN_CHAT',
  CLEAR_CHAT: 'CLEAR_CHAT',
  ERROR_CONNECTING_CHAT: 'ERROR_CONNECTING_CHAT',
  REMOVE_LAST_MESSAGE: 'REMOVE_LAST_MESSAGE',
  UPDATE_SESSION_ID: 'UPDATE_SESSION_ID',
  UPDATE_CURRENT_POLL: 'UPDATE_CURRENT_POLL',
  SET_STATE: 'SET_STATE',
  CLEAR_STATE: 'CLEAR_STATE',
  UPDATE_SELECTED_BUTTONS: 'UPDATE_SELECTED_BUTTONS',
  SYNC_USER_INPUT_TO_BUTTONS: 'SYNC_USER_INPUT_TO_BUTTONS'
}

export default ChatActions
