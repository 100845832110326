// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../../../commoncomponents/LazyLoading/LazyLoading'

const ConfigureAuthenticationPage = lazy(() =>
  import('./ConfigureAuthenticationPage')
)

const ConfigureAuthenticationRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route path="/" element={<ConfigureAuthenticationPage />} />
      </Routes>
    </Suspense>
  )
}

export default ConfigureAuthenticationRouter
