// Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Box, Anchor, Layer } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  Loader
} from '../../../../components'
import { post } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { useUPSContext } from '../../../../context/ups-context'
import { displayNotification } from '../../../../utils/notificiation-utils'

import TemplateWidgets from './TemplateWidgets'

export const AddWidgetsModal = ({
  onClose,
  setNotification,
  isEditModal = false
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common'])
  const { homewidgets: homeWidgetsFromGet, dispatchUPSContext } =
    useUPSContext()
  const letUsKnowLink =
    'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=gf'

  const [showLoader, setLoader] = useState(false)
  const [selectedWidgets, setSelectedWidgets] = useState([])

  const handleSelectedWidgets = (widgets) => {
    setSelectedWidgets(widgets)
  }

  const handleWidgetSaveChanges = async () => {
    setNotification(null)
    setLoader(true)
    try {
      const category = 'homewidgets'
      const updatedWidgets = homeWidgetsFromGet?.map((widget) => {
        if (selectedWidgets.includes(widget.widgetKey)) {
          return { ...widget, visible: true }
        }
        return { ...widget, visible: false }
      })
      const requestBody = {
        category,
        data: updatedWidgets
      }
      const response = await post(
        `/user-prefs/v1alpha1/save-preferences`,
        requestBody,
        oidcUser.access_token
      )
      if (response?.status === 200) {
        dispatchUPSContext({
          type: 'SET_UPS_DATA',
          data: {
            upsData: { homewidgets: updatedWidgets }
          }
        })
        setNotification(
          displayNotification(
            t('dashboard.widgets.widget_success'),
            'info',
            setNotification
          )
        )
        onClose()
      }
    } catch (error) {
      setNotification(displayApiError(error, t, setNotification))
    } finally {
      setLoader(false)
    }
  }

  return (
    <>
      {showLoader && (
        <Layer position="center" data-testid="widget-loader-layer" plain>
          <Loader testId="loader-spinner" />
        </Layer>
      )}
      <ModalDialog
        position="center"
        testId="add-widget-modal"
        header={
          <ModalHeader
            title={
              <Box align="start" pad={{ top: 'small', bottom: 'small' }}>
                <Typography level="1" type="heading">
                  {isEditModal
                    ? t('dashboard.widgets.add_edit_modal.edit_title')
                    : t('dashboard.widgets.add_edit_modal.title')}
                </Typography>
              </Box>
            }
          />
        }
        content={
          <Box
            pad={{ bottom: 'large' }}
            border={{
              side: 'bottom',
              color: 'light-2',
              size: 'small'
            }}
          >
            <Typography type="text">
              {t('dashboard.widgets.add_edit_modal.subtitle')}
            </Typography>
            <Box
              direction="row"
              pad={{ top: 'large', left: 'xxsmall', right: 'xxsmall' }}
            >
              <TemplateWidgets
                handleSelectedWidgets={handleSelectedWidgets}
                testId="widget-card"
              />
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            left={
              <Typography type="text">
                <Trans i18nKey="dashboard.widgets.add_edit_modal.footer" t={t}>
                  <Anchor
                    label={t('dashboard.widgets.add_edit_modal.footer_link')}
                    href={letUsKnowLink}
                    target="_blank"
                    testId="let-us-know-anchor"
                  />
                </Trans>
              </Typography>
            }
            right={
              <Box direction="row" gap="small">
                <Button
                  data-testid="add-widget-modal-close-btn"
                  label={t('close')}
                  default
                  onClick={() => onClose()}
                />
                <Button
                  data-testid="add-widget-modal-save-btn"
                  label={t('dashboard.widgets.buttons.save_changes')}
                  primary
                  onClick={() => handleWidgetSaveChanges()}
                />
              </Box>
            }
          />
        }
        onClose={onClose}
      />
    </>
  )
}

AddWidgetsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  isEditModal: PropTypes.bool
}
