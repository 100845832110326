// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { StatusCritical, Previous, FormDown, View } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Loader,
  Notification,
  Button,
  Typography,
  ActionButton
} from '../../../../components'
import { get, getErrorMessage } from '../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import PreviewPost from './components/PreviewPost'
import PreviewAnnouncement from './components/PreviewAnnouncement'
import { getFormattedData, getSubHeading } from './utils'
import DetailsBox from './components/DetailsBox'
import DeletePost from './components/DeletePost'
import EditPost from './components/EditPost'
import ApprovalDetails from './components/ApproveDetails'
import ApprovalModel from './components/ApprovalModel'

const WhatsNewPostDetails = () => {
  const { t } = useTranslation(['manage'])
  const { blogID } = useParams()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const edit = params.get('edit')
  const state = params.get('state')
  const approval = JSON.parse(params.get('approval'))
  const [showEdit, setShowEdit] = useState(JSON.parse(edit))
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState(null)
  const [postDetails, setPostDetails] = useState({})
  const [showPreview, setShowPreview] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showEditModal, setEditModal] = useState(false)
  const [showDeleteModel, setDeleteModel] = useState(false)
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const [refreshCount, setRefreshCount] = useState(0)
  const [newAPICall] = useState(JSON.parse(state))
  const LDFlags = useFlags()
  const [showApprovalModel, setApprovalModel] = useState(false)
  const [operation, setOperation] = useState('')
  const [showPreviewAnnouncement, setShowPreviewAnnouncement] = useState(false)

  const refreshData = () => {
    setRefreshCount(refreshCount + 1)
  }

  const handleBackBtnClick = () => {
    navigate('/manage-ccs/whats-new')
  }

  const handleEditBtnClick = () => {
    setEditModal(true)
    setShowEdit(true)
  }

  const handlePreviewBtnClick = () => {
    setShowPreview(true)
  }

  useEffect(() => {
    let url = `/whatsnew/v1/manage/blogs/${blogID}`
    if (newAPICall) {
      url = `/whatsnew/v1/review/blogs/${blogID}`
    }
    setLoading(true)
    get(
      url,
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        if (response?.data) {
          setLoading(false)
          setPostDetails(response?.data)
          setEditModal(showEdit)
        }
      },
      (error) => {
        setPostDetails({})
        setLoading(false)
        setErrorMessage(getErrorMessage(error, t))
      }
    )
  }, [oidcUser.access_token, blogID, refreshCount, t])

  return (
    <VisibilityWrapper
      rbac={{
        resource: '/ccs/whats-new',
        permission: 'ccs.whats-new.view'
      }}
      hideFor={{ feature: 'glcp-whats-new', deployment: ['COP'] }}
    >
      <Box width="large" gap="small" alignSelf="center" justify="center">
        <Box direction="row" align="start">
          <Button
            default
            label={
              <Typography type="text" testId="whats-new-back-btn-label">
                {t('whats_new.whats_new_page')}
              </Typography>
            }
            icon={<Previous />}
            onClick={handleBackBtnClick}
            testId="whats-new-back-btn"
          />
        </Box>

        {loading ? (
          <Box direction="row" align="center" justify="center">
            <Loader testId="loader-spinner" />
          </Box>
        ) : (
          <Box
            justify="between"
            align="start"
            alignSelf="center"
            margin="small"
            width="inherit"
          >
            <Box direction="column" width="inherit">
              <Box
                gap="small"
                direction="row"
                alignSelf="start"
                justify="between"
                pad="small"
                width="inherit"
              >
                <Typography
                  type="heading"
                  level="1"
                  testId="post-details-title"
                >
                  {postDetails.title}
                </Typography>
                {approval ? (
                  <ActionButton
                    actions={[
                      {
                        label: t('whats_new.approval.approve'),
                        onClick: () => {
                          setOperation('Approved')
                          setApprovalModel(true)
                        },
                        testId: 'approve-detail-post-btn'
                      },
                      {
                        label: t('whats_new.approval.reject'),
                        onClick: () => {
                          setOperation('Rejected')
                          setApprovalModel(true)
                        },
                        testId: 'reject-detail-post-btn'
                      }
                    ]}
                    label={t('whats_new.action_btn')}
                    icon={<FormDown />}
                    reverse
                    showOneActionAsDropDown
                    testId="approval-detail-action-btn"
                  />
                ) : (
                  <VisibilityWrapper
                    rbac={{
                      permission: 'ccs.whats-new.delete',
                      resource: '/ccs/whats-new'
                    }}
                  >
                    <ActionButton
                      actions={[
                        {
                          label: t('whats_new.delete_post'),
                          onClick: () => {
                            setDeleteModel(true)
                          }
                        }
                      ]}
                      label={t('whats_new.action_btn')}
                      icon={<FormDown />}
                      reverse
                      showOneActionAsDropDown
                      testId="action-btn"
                    />
                  </VisibilityWrapper>
                )}
              </Box>
              <Box
                gap="small"
                direction="row"
                alignSelf="start"
                justify="between"
                margin={{ vertical: 'medium' }}
                pad="small"
                border={{ side: 'bottom', color: 'border' }}
                width="inherit"
              >
                <Typography type="heading" level="2" testId="detail-heading">
                  {t('whats_new.detail')}
                </Typography>

                {!showEditModal && !approval && (
                  <>
                    {((newAPICall && LDFlags['glcp-whatsnew-approval']) ||
                      (!newAPICall && !LDFlags['glcp-whatsnew-approval'])) && (
                      <VisibilityWrapper
                        rbac={{
                          permission: 'ccs.whats-new.edit',
                          resource: '/ccs/whats-new'
                        }}
                      >
                        <Button
                          secondary
                          label={t('whats_new.edit_post')}
                          onClick={handleEditBtnClick}
                          testId="whats-new-edit-btn"
                        />
                      </VisibilityWrapper>
                    )}
                  </>
                )}
              </Box>
              {showEditModal && showEdit ? (
                <Box width="100%" pad="small">
                  <EditPost
                    postData={getFormattedData(postDetails)}
                    refreshData={() => {
                      setEditModal(false)
                      setShowEdit(false)
                      refreshData()
                    }}
                    closeEdit={() => {
                      setEditModal(false)
                      setShowEdit(false)
                    }}
                  />
                </Box>
              ) : (
                <Box width="100%" pad="small">
                  <DetailsBox
                    data={getFormattedData(postDetails)}
                    detailsSubHeading={getSubHeading(
                      postDetails,
                      LDFlags['glcp-whatsnew-istanbul'],
                      t
                    )}
                  />
                  <Box align="end">
                    <Button
                      default
                      label={t('whats_new.create_post_form.preview_post')}
                      icon={<View size="small" />}
                      onClick={handlePreviewBtnClick}
                      testId="preview-btn"
                      reverse
                    />
                    {LDFlags['glcp-whatsnew-istanbul'] &&
                      postDetails?.summaryFlag && (
                        <Button
                          default
                          label={t(
                            'whats_new.create_post_form.preview_announcement_btn'
                          )}
                          icon={<View size="small" />}
                          onClick={() => {
                            setShowPreviewAnnouncement(true)
                          }}
                          testId="preview-announcement-btn"
                          reverse
                        />
                      )}
                  </Box>
                  {LDFlags['glcp-whatsnew-approval'] && newAPICall && (
                    <Box>
                      <ApprovalDetails
                        postDetails={getFormattedData(postDetails)}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
        {errorMessage && (
          <Notification
            backgroundColor="status-critical"
            onClose={() => setErrorMessage(null)}
            testId="critical-inline-notification"
            text={errorMessage}
            icon={<StatusCritical size="medium" />}
          />
        )}
        {showPreview && (
          <PreviewPost
            item={getFormattedData(postDetails)}
            closePreview={() => {
              setShowPreview(false)
            }}
          />
        )}
        {showPreviewAnnouncement && (
          <PreviewAnnouncement
            Post={getFormattedData(postDetails)}
            closeModel={() => {
              setShowPreviewAnnouncement(false)
            }}
          />
        )}

        {showDeleteModel && (
          <DeletePost
            postData={postDetails}
            setErrorMessage={setErrorMessage}
            refreshDataTable={() => {}}
            setDeletePostMessage={() => {}}
            state={newAPICall}
            closeDeleteModal={() => {
              setDeleteModel(false)
            }}
          />
        )}
        {showApprovalModel && (
          <ApprovalModel
            operation={operation}
            postData={postDetails}
            setErrorMessage={setErrorMessage}
            closeApprovalModal={() => {
              setApprovalModel(false)
            }}
            refreshDataTable={() => refreshData()}
          />
        )}
      </Box>
    </VisibilityWrapper>
  )
}

export default WhatsNewPostDetails
