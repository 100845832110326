// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Anchor, Box, Page, PageContent, PageHeader } from 'grommet'
import { FormDown, Previous } from 'grommet-icons'

import { get } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { ActionButton, Loader, Notification } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { Layout } from '../../../../commoncomponents/layout/Layout'

import {
  EditOnPremWorkspaceDetails,
  ExportWorkspaceResources,
  OnPremDetailSection,
  OnPremResourceTabs
} from './components'

const OnPremWorkspaceDetailsContent = () => {
  const { t } = useTranslation(['manage', 'common'])
  const { workspaceRId } = useParams()
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const [apiErrorMsg, setApiErrorMsg] = useState(null)
  const [workspaceDetails, setWorkspaceDetails] = useState({})
  const [editWorkspacePanel, setEditWorkspacePanel] = useState(false)
  const [exportWorkspaceModal, setExportWorkspaceModal] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [pullDetailsRefresh, setPullDetailsRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // To fetch onprem workspace details
  useEffect(() => {
    setIsLoading(true)
    get(
      `/ui-doorway/ui/v1/onprem-workspaces/${workspaceRId}`,
      {},
      oidcUser.access_token
    )
      .then(
        (response) => {
          setWorkspaceDetails(response?.data)
        },
        (error) => {
          setApiErrorMsg(error)
        }
      )
      .finally(() => setIsLoading(false))
  }, [workspaceRId, oidcUser.access_token, pullDetailsRefresh])

  const handleBackBtnClick = () => {
    navigate('/manage-account/on-prem-workspaces')
  }

  // To perform export onprem workspace resources action
  const handleExportWorkspace = () => {
    get(
      `/ui-doorway/ui/v1/onprem-workspaces/${workspaceDetails?.id}/export`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        setExportWorkspaceModal(true)
      },
      (error) => {
        setApiErrorMsg(error)
      }
    )
  }
  return (
    <Page kind="narrow">
      <PageContent>
        {isLoading ? (
          <Box align="center" justify="center" alignSelf="center">
            <Loader testId="onprem-workspace-detail-loader" />
          </Box>
        ) : (
          <>
            <PageHeader
              title={workspaceDetails?.onPremWorkspaceName}
              parent={
                <Anchor
                  size="medium"
                  label={t('onprem_workspace.onprem_workspace_title')}
                  icon={<Previous size="small" />}
                  onClick={handleBackBtnClick}
                  data-testid="onprem-workspaces-back-btn"
                />
              }
              actions={
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/device-management',
                    permission: 'ccs.device-management.edit'
                  }}
                  hideFor={{
                    deployment: ['COP', 'GLOP']
                  }}
                >
                  <ActionButton
                    actions={[
                      {
                        label: t('onprem_workspace.export_resources'),
                        onClick: () => {
                          handleExportWorkspace()
                        },
                        testId: 'export-resources-btn'
                      },
                      {
                        label: t('onprem_workspace.edit_workspace_details'),
                        onClick: () => {
                          setEditWorkspacePanel(true)
                        },
                        testId: 'edit-workspace-details-btn'
                      }
                    ]}
                    testId="onprem-workspace-action-btn"
                    dropAlign={{
                      right: 'right',
                      top: 'bottom'
                    }}
                    label={t('common:actions')}
                    icon={<FormDown />}
                    reverse
                  />
                </VisibilityWrapper>
              }
              data-testid="onprem-workspace-detail-page-header"
            />
            <OnPremDetailSection workspaceDetails={workspaceDetails} />
            <OnPremResourceTabs />
          </>
        )}

        {apiErrorMsg && displayApiError(apiErrorMsg, t, setApiErrorMsg)}
        {editWorkspacePanel && (
          <EditOnPremWorkspaceDetails
            setEditWorkspacePanel={setEditWorkspacePanel}
            onPremData={workspaceDetails}
            onSuccess={() => {
              setPullDetailsRefresh(!pullDetailsRefresh)
              setSuccessMsg(
                t('onprem_workspace.workspace_name_edit_success_msg')
              )
            }}
          />
        )}
        {exportWorkspaceModal && (
          <ExportWorkspaceResources
            setExportWorkspaceModal={setExportWorkspaceModal}
            onPremWorkspaceName={workspaceDetails?.onPremWorkspaceName}
          />
        )}
        {successMsg && (
          <Notification
            onClose={() => {
              setSuccessMsg('')
            }}
            testId="workspace-name-edit-success-notification"
            text={successMsg}
          />
        )}
      </PageContent>
    </Page>
  )
}
const OnPremWorkspaceDetails = () => {
  return (
    <Layout>
      <OnPremWorkspaceDetailsContent align="start" justify="start" />
    </Layout>
  )
}

export { OnPremWorkspaceDetails }
