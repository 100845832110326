// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
export const statusList = ['critical', 'warning', 'ok', 'information']
export const readStatus = ['read', 'unread']
export const dateFrameList = [
  'Past 24 hours',
  'Past 3 days',
  'Past 7 days',
  'Past 30 days'
]

export const colsDisplayed = [
  'severity',
  'target',
  'state',
  'owner',
  'createdAt',
  'source',
  'id',
  'url',
  'url_text',
  'delivery'
]
export const reviewColumnsBannerFlorence = [
  'emailAddresses',
  'accounts',
  'target',
  'regions',
  'instance',
  'role',
  'status',
  'category',
  'delivery',
  'banner_summary',
  'banner_additional_content',
  'url',
  'url_text',
  'state'
]

export const reviewColumnsBanner = [
  'target',
  'status',
  'category',
  'delivery',
  'dismissable',
  'url',
  'url_text',
  'state'
]

export const reviewColumnsEmail = ['target', 'status', 'banner']

export const reviewColumnsEmailFlorence = [
  'emailAddresses',
  'accounts',
  'target',
  'regions',
  'instance',
  'role',
  'status',
  'delivery',
  'category',
  'banner',
  'email_bool',
  'email_subject',
  'email_content',
  'url',
  'url_text',
  'state'
]

export const reviewColumnsBannerEmail = [
  'emailAddresses',
  'accounts',
  'target',
  'regions',
  'instance',
  'role',
  'status',
  'category',
  'delivery',
  'banner_summary',
  'banner_additional_content',
  'email_bool',
  'email_subject',
  'email_content',
  'url',
  'url_text',
  'state'
]

export const reviewColumnsPortal = [
  'emailAddresses',
  'accounts',
  'target',
  'regions',
  'instance',
  'role',
  'status',
  'category',
  'message',
  'additional_content',
  'url',
  'url_text',
  'state',
  'delivery'
]

export const reviewColumnsPortalNewFlow = [
  'emailAddresses',
  'accounts',
  'service',
  'regions',
  'instance',
  'role',
  'status',
  'url',
  'url_text',
  'state',
  'delivery'
]

export const reviewColumnsBannerEmailNewFlow = [
  'emailAddresses',
  'accounts',
  'service',
  'regions',
  'instance',
  'role',
  'status',
  'delivery',
  'email_bool',
  'url',
  'url_text',
  'state'
]

export const reviewColumnsBannerNewFlow = [
  'emailAddresses',
  'accounts',
  'service',
  'regions',
  'instance',
  'role',
  'status',
  'delivery',
  'url',
  'url_text',
  'state'
]

export const reviewColumnsEmailNewFlow = [
  'emailAddresses',
  'accounts',
  'service',
  'regions',
  'instance',
  'role',
  'status',
  'banner',
  'email_bool',
  'url',
  'url_text',
  'state',
  'delivery'
]

export const statusOptions = ['Critical', 'Warning', 'Ok', 'Information']

export const targetOptions = [
  {
    label: 'HPE GreenLake platform',
    value: 'PLATFORM'
  },
  {
    label: 'Aruba Central',
    value: 'ARUBA_CENTRAL'
  },
  {
    label: 'Compute Ops Management',
    value: 'COMPUTE_CENTRAL'
  },
  {
    label: 'Data Services Cloud Console',
    value: 'STORAGE_CENTRAL'
  },
  {
    label: 'OpsRamp',
    value: 'OPS_RAMP'
  },
  {
    label: 'Sustainability Insights Center',
    value: 'SUSTAINABLITY_IC'
  },
  {
    label: 'HPE GreenLake Flex Solutions',
    value: 'GREENLAKE_FLEX'
  }
]

export const statusArr = ['Information', 'Critical', 'Warning', 'Ok']

export const TYPE_MY_REQUEST = 'MY_REQUEST'
export const TYPE_ALL_PENDING = 'ALL_PENDING'
export const TYPE_ALL_REJECTED = 'ALL_REJECTED'
export const TYPE_MY_APPROVAL = 'MY_APPROVAL'
export const TYPE_SCHEDULED = 'SCHEDULED'

export const STATE_REJECTED = 'REJECTED'
export const STATE_PENDING = 'PENDING'

export const CREATE_REQUEST_TYPE = 'CREATE_REQUEST'
export const REVIEW_REQUEST_TYPE = 'REVIEW_REQUEST'

export const UPDATE_REQUEST_TYPE = 'UPDATE_NOTIFICATION_REQUEST'
export const UPDATE_REQUEST_TYPE_WITH_APPROVAL =
  'UPDATE_NOTIFICATION_REQUEST_WITH_APPROVAL'

export const UPDATE_FOR_UPDATE_REVIEW_REQUEST =
  'UPDATE_NOTIFICATION_APPROVAL_REQUEST_FOR_UPDATE'
export const UPDATE_FOR_CREATE_REVIEW_REQUEST =
  'UPDATE_NOTIFICATION_APPROVAL_REQUEST'

export const targetOptionsWithPCE = [
  ...targetOptions,
  {
    label: 'Private Cloud Enterprise',
    value: 'PCE'
  }
]

export const scheduleColumns = ['date', 'time']

export const categoryColumns = [
  'service',
  'sub_service',
  'topics',
  'sub_topics'
]

export const notificationContentColumns = [
  'summary_title',
  'additional_content',
  'email_subject',
  'email_content'
]
