// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Anchor, Notification, Typography } from '../../components'
import { getWorkspaceString, WKSPC } from '../../utils/common-utils'

const LoadAccountError = () => {
  const { t } = useTranslation(['authn'])
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  return (
    <Box flex={{ shrink: 0 }} width="100%">
      <Notification
        backgroundColor="status-critical"
        testId="customer-account-error"
        pad="xsmall"
        text={
          <Typography
            type="text"
            testId="customer-account-not-available-msg"
            size="medium"
          >
            <Trans
              i18nKey="authn:acct_onboarding.account_unavailable"
              t={t}
              values={{
                account: getWorkspaceString(showWorkspaceString, t, WKSPC)
              }}
            >
              <Anchor
                label={t('authn:acct_onboarding.contact_support')}
                weight="bold"
                href="https://h41390.www4.hpe.com/support/index.html?form=glsupport"
                target="_blank"
                testId="contact-support-anchor"
              />
              <Anchor
                label={t('authn:acct_onboarding.sign_in')}
                weight="bold"
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/sign-out')
                }}
                testId="sign-out-anchor"
              />
            </Trans>
          </Typography>
        }
        type="inline"
      />
    </Box>
  )
}

export default LoadAccountError
