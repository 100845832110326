// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { v4 as uuidv4 } from 'uuid'
import isEqual from 'lodash/isEqual'

import { validateForm } from '../../../../../../../utils/validation-utils'
import { get, post } from '../../../../../../../utils/api-utils'
import {
  extractUomCommitData,
  getCapacityRevision
} from '../../../../../../../utils/dm-sm-common-utils'

import { countDecimalPlaces } from './currencyFormatter'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
export const isEmpty = (value) => {
  const isEmptyString = `${value} `.trim().length < 1
  return value === undefined || value === null || value === '' || isEmptyString
}

export const isNotNumber = (value) => {
  const number = Number(value)
  return Number.isNaN(number) || number === Infinity || number === -Infinity
}

const cleanErrors = (errors) => {
  Object.entries(errors).forEach(([revisionId, errorObj]) => {
    Object.keys(errorObj)
      .filter((key) => errorObj[key] === undefined)
      .forEach((key) => delete errorObj[key])

    if (Object.keys(errorObj).length === 0) {
      delete errors[revisionId]
    }
  })
  return errors
}

export const getErrorMsgFromSingleTier = (t, val) => {
  let error
  if (isEmpty(val)) {
    error = t('common:required')
  }
  if (!isEmpty(val) && isNotNumber(val)) {
    error = t('showback_rates.wizard_meters_validation_number')
  }
  if (!isEmpty(val) && !isNotNumber(val) && val <= 0) {
    error = t('showback_rates.wizard_meters_validation_number_negative')
  }
  return error
}

export const validateShowbackRates = (formValues, i18nTranslate) => {
  const allErrors = {}
  if (formValues.isRatesStepOn && formValues.ratesData) {
    formValues.ratesData.pricePlans.forEach((plan) => {
      plan.rateRevisions.forEach((revision) => {
        Object.keys(revision.tiers).forEach((tierKey) => {
          const error = getErrorMsgFromSingleTier(
            i18nTranslate,
            revision.tiers[tierKey]
          )
          if (!allErrors[revision.id]) {
            allErrors[revision.id] = {}
          }
          allErrors[revision.id][tierKey] = error
        })
      })
    })
  }

  return cleanErrors(allErrors)
}

export const getRatesValidationResult = (
  ratesValidationErrors,
  formValues,
  i18nTranslate,
  isForManage
) => {
  const validateInputs = () => {
    if (
      formValues.isRatesStepOn &&
      Object.keys(ratesValidationErrors).length > 0
    ) {
      return { isValid: false }
    }
    return {
      isValid: true
    }
  }
  return isForManage
    ? validateInputs()
    : validateForm(formValues, i18nTranslate, validateInputs)
}

const getLegacyUomConc = (productAttrs) => {
  let value = ''
  const uomAttrList = []
  const commitValueList = []
  productAttrs?.forEach((attr) => {
    if (attr?.name?.includes('CS_USAGE_UOM')) uomAttrList.push(attr)
    if (attr?.name?.includes('COMMITMENT_VALUE')) commitValueList.push(attr)
  })
  const legacyUomCommitMap = {}
  uomAttrList.forEach((uomAttr) => {
    const uomNameKey = uomAttr?.name?.charAt(uomAttr?.name?.length - 1)
    const targetCommitAttr = commitValueList.find((valueAttr) => {
      return valueAttr?.name?.includes(`VALUE${uomNameKey}`)
    })
    legacyUomCommitMap[uomAttr.value] = targetCommitAttr?.value || null
  })

  if (Object.keys(legacyUomCommitMap).length > 0) {
    value = Object.keys(legacyUomCommitMap)
      .map((key) => {
        return legacyUomCommitMap[key]
          ? `${key},${legacyUomCommitMap[key]}`
          : `${key}`
      })
      .join('|')
  }
  return { value }
}

export const getUomCommitMap = (productAttrs) => {
  if (productAttrs) {
    const uomConcAttr = productAttrs?.find((attr) => {
      return attr?.name === 'CS_CCM_MULTIV_UOM_CONC'
    })
    if (uomConcAttr) {
      return extractUomCommitData(uomConcAttr)
    }
    const convertedLegacyUomConcAttr = getLegacyUomConc(productAttrs)
    return extractUomCommitData(convertedLegacyUomConcAttr)
  }
  return null
}

export const isBadOrderData = (data) => {
  const uomCommitMap = getUomCommitMap(
    data?.entitlements && data?.entitlements[0]?.product?.attributes
  )
  const isBadUomCommitMap = () => {
    if (!uomCommitMap) {
      return true
    }
    if (!uomCommitMap[0]) return true
    return uomCommitMap?.length < 1
  }

  return (
    data?.entitlements?.length < 1 ||
    data?.entitlements[0]?.licenses?.length < 1 ||
    !data?.entitlements[0]?.product ||
    !data?.entitlements[0]?.product?.sku ||
    isBadUomCommitMap()
  )
}

export const isBadRatesData = (data) => {
  return (
    !data?.pricePlans ||
    !data?.contractStartDate ||
    !data?.contractEndDate ||
    !dayjs(data?.contractStartDate).isValid() ||
    !dayjs(data?.contractEndDate).isValid() ||
    data?.pricePlans?.length < 1
  )
}

export const isOrderDateValid = (inputDate) => {
  return dayjs(inputDate, 'DD.MM.YYYY hh:mm:ss').isValid()
}

export const formateDateFromOrder = (inputDate) => {
  if (inputDate && isOrderDateValid(inputDate)) {
    return dayjs.utc(inputDate, 'DD.MM.YYYY hh:mm:ss').format('YYYY-MM-DD')
  }
  return dayjs.utc().format('YYYY-MM-DD')
}

export const getRatesPayload = (entitlement, licenseKey, currency) => {
  const license = entitlement.licenses[0]
  return {
    contractEndDate: formateDateFromOrder(
      license?.appointments?.subscriptionEnd
    ),
    contractStartDate: formateDateFromOrder(
      license?.appointments?.subscriptionStart
    ),
    currency: currency || 'USD',
    licenseKey
  }
}

export const cleanPricePlans = (pricePlans) => {
  return pricePlans.map((plan) => {
    const rateRevisions = plan.rateRevisions.map(
      ({ id, minCommit, ...rest }) => ({
        ...rest,
        tiers: Object.fromEntries(
          Object.entries(rest.tiers).map(([key, value]) => [key, Number(value)])
        )
      })
    )
    return { ...plan, rateRevisions }
  })
}

export const displayRatesFormatter = (value) => {
  if (value !== 0 && !value) {
    return ''
  }
  if (Number.isNaN(Number(value))) {
    return value
  }
  const decimalPlacesCount = countDecimalPlaces(value)
  if (decimalPlacesCount < 2) {
    const roundedValue = Math.round(Number(value) * 100) / 100
    return roundedValue.toFixed(2)
  }
  if (decimalPlacesCount > 6) {
    const roundedValue = Math.round(Number(value) * 1000000) / 1000000
    return roundedValue.toFixed(6)
  }
  return value
}

export const getRatesDataFromCdsRatesRes = (ratesData) => {
  // build standard rates data with id, minCommit and formatted value
  if (ratesData?.pricePlans?.length < 1) {
    return ratesData
  }
  const ratesDataCopy = { ...ratesData }
  ratesDataCopy.pricePlans.forEach((plan) => {
    plan.rateRevisions.forEach((revision) => {
      if (!revision?.tiers) {
        return
      }
      const tiersKeys = Object.keys(revision.tiers)
      const minCommitKey = tiersKeys.find((key) => key !== '0.0')
      revision.id = uuidv4()
      revision.minCommit = minCommitKey || null
      tiersKeys.forEach((key) => {
        revision.tiers[key] = displayRatesFormatter(revision.tiers[key])
      })
    })
  })
  return ratesDataCopy
}

export const getDefaultStartDate = (licenses) => {
  if (licenses?.length > 0 && licenses[0].appointments?.subscriptionStart) {
    return formateDateFromOrder(licenses[0].appointments?.subscriptionStart)
  }
  return formateDateFromOrder()
}

export const isValidMinCommit = (input) => {
  if (input === null || input === 0) return false
  if (typeof input === 'string') {
    const inputStr = input.toString().trim()
    if (!Number.isNaN(inputStr) && parseFloat(inputStr) === 0) return false
    if (Number.isNaN(inputStr) && inputStr === '') return false
  }
  return true
}

export const buildIniDataFromOrder = (orderData, subscriptionKey) => {
  const uomCommitMap = getUomCommitMap(
    orderData?.entitlements && orderData?.entitlements[0]?.product?.attributes
  )
  const effectiveDate = getDefaultStartDate(orderData?.entitlements[0].licenses)
  const pricePlans = uomCommitMap.map((el) => {
    const tiers = isValidMinCommit(el.minCommit)
      ? {
          '0.0': '',
          [el.minCommit]: ''
        }
      : {
          '0.0': ''
        }
    return {
      uom: el.uom,
      hasCapacities: true,
      rateRevisions: [
        {
          id: uuidv4(),
          minCommit: el.minCommit,
          effectiveDate,
          tiers
        }
      ],
      capacityRevisions: [getCapacityRevision(effectiveDate, el.minCommit)]
    }
  })
  return {
    ratesPayload: getRatesPayload(
      orderData?.entitlements[0],
      subscriptionKey,
      orderData?.currency
    ),
    ratesData: {
      currency: orderData?.currency,
      pricePlans
    }
  }
}

export const hasNoRatesChange = async (ratesData, payload) => {
  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  await wait(500)
  return (
    !payload?.pricePlans ||
    isEqual(
      cleanPricePlans(ratesData.pricePlans),
      cleanPricePlans(payload.pricePlans)
    )
  )
}

export const convertToCreatingRatesPayload = (normalRatesPayload) => {
  if (!normalRatesPayload?.pricePlans) {
    return {}
  }
  return {
    ...normalRatesPayload,
    pricePlans: normalRatesPayload.pricePlans.map((plan) => {
      const planCopy = { ...plan }
      delete planCopy.rateRevisions
      delete planCopy.capacityRevisions
      return {
        ...planCopy,
        rateRevision: plan.rateRevisions[0],
        capacityRevision: plan.capacityRevisions[0]
      }
    })
  }
}

const loadSku = (skuArray, orderData, token, setRatesOrderData) => {
  return post(`/cds/v1/skus`, skuArray, token)
    .then((res) => {
      if (res?.status === 200 && res?.data) {
        const isRatesStepAvailable = skuArray.every(
          (item) => res?.data[item] === true
        )
        if (isRatesStepAvailable) {
          setRatesOrderData(orderData)
        }
        setRatesOrderData(null)
        return isRatesStepAvailable
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export const checkRatesAvailability = (
  subscriptionKey,
  token,
  setRatesOrderData
) => {
  return get(`/ui-doorway/ui/v1/license/${subscriptionKey}/order`, {}, token)
    .then((res) => {
      if (res?.status === 200 && res?.data) {
        if (isBadOrderData(res?.data)) {
          return Promise.resolve(false)
        }
        const sku = res?.data?.entitlements[0]?.product?.sku
        return loadSku([sku], res?.data, token, setRatesOrderData)
      }
      return Promise.resolve(false)
    })
    .catch(() => {
      return Promise.resolve(false)
    })
}

export const getServiceFromOrder = (orderData) => {
  return orderData?.entitlements[0]?.product?.description
}

export const getShowBackRatesStep = (
  isRatesStepAvailable,
  isShowbackRatesVisible,
  showbackRatesStep,
  addShowbackRateRef,
  t
) => {
  return isRatesStepAvailable && isShowbackRatesVisible && showbackRatesStep
    ? [
        {
          childComponents: showbackRatesStep,
          validateForm: async (formValues) => {
            const ratesValidationErrors = validateShowbackRates(formValues, t)
            return new Promise((res, rej) =>
              getRatesValidationResult(
                ratesValidationErrors,
                formValues,
                t
              ).then(
                () => {
                  res()
                },
                () => {
                  addShowbackRateRef.current.handleFormValidationError(
                    ratesValidationErrors
                  )
                  rej(
                    new Error(
                      t(
                        'showback_rates.wizard_meters_validation_overall_with_uncheck'
                      )
                    )
                  )
                }
              )
            )
          },
          submitFormOnChange: false,
          description: t('showback_rates.wizard_des'),
          title: t('showback_rates.wizard_title')
        }
      ]
    : []
}
