// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  ButtonGroup
} from '../../../../components'
import { TagsEdit } from '../../../tags/TagsEdit'
import { updateLocationTags } from '../../updateTagsUtils'
import { patch } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'

const ManageLocationTags = ({
  initialTags,
  locationId,
  setShowEditTagsModal,
  setShowErrorNotification,
  setRefreshTagsData = undefined
}) => {
  const { t } = useTranslation(['location', 'device'])

  const [updatedTags, setUpdatedTags] = useState(initialTags)

  const [isNewAssignment, setIsNewAssignment] = useState(false)

  const { oidcUser } = useReactOidc()

  return (
    <ModalDialog
      width="large"
      header={
        <ModalHeader
          title={
            <>
              <Typography
                level={2}
                testId="locations-tags-edit-title"
                type="heading"
              >
                {t('device:manage_tags')}
              </Typography>
              <Typography testId="header-title" type="text">
                {t('location:assign_tags_desc')}
              </Typography>
            </>
          }
        />
      }
      content={
        <Box pad={{ top: 'medium' }}>
          <Typography type="text">{t('assign_tags_location')}</Typography>
          <TagsEdit
            onChange={(tags) => setUpdatedTags(tags)}
            initialTags={initialTags || []}
            isNewAssignment={isNewAssignment}
            setIsNewAssignment={setIsNewAssignment}
          />
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  testId: 'edit-tags-cancel-btn',
                  onClick: () => {
                    setShowEditTagsModal(false)
                  }
                },
                {
                  id: 1,
                  label: t('save'),
                  primary: true,
                  testId: 'edit-tags-save-btn',
                  onClick: () => {
                    // identify remove and create and send to BE
                    const { assigned, unassigned } = updateLocationTags(
                      initialTags,
                      updatedTags
                    )
                    // if no changes, show error
                    if (unassigned.length === 0 && assigned.length === 0) {
                      setIsNewAssignment(true)
                    } else {
                      patch(
                        '/ui-doorway/ui/v1/locations/tags',
                        {
                          createTags: [...assigned],
                          deleteTags: [...unassigned],
                          locationId
                        },
                        oidcUser.access_token,
                        {}
                      ).then(
                        () => {
                          setShowEditTagsModal(false)
                          if (setRefreshTagsData) {
                            setRefreshTagsData(true)
                          }
                        },
                        (error) => {
                          setShowEditTagsModal(false)
                          setShowErrorNotification(
                            displayApiError(error, t, setShowErrorNotification)
                          )
                        }
                      )
                    }
                  }
                }
              ]}
              testId="locations-tags-edit-buttons"
            />
          }
        />
      }
      testId="edit-location-tags-modal"
    />
  )
}

ManageLocationTags.propTypes = {
  locationId: PropTypes.string.isRequired,
  initialTags: PropTypes.array.isRequired,
  setShowEditTagsModal: PropTypes.func.isRequired,
  setShowErrorNotification: PropTypes.func.isRequired,
  setRefreshTagsData: PropTypes.func
}

export { ManageLocationTags }
