// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Markdown as GrommetMarkdown } from 'grommet'
import { Copy } from 'grommet-icons'
import { useTranslation, Trans } from 'react-i18next'

import { Button, Typography, Tooltip, Anchor } from '../../../../components'

const ViewDomainClaimContent = ({ domainClaimTXT = '', domainName = '' }) => {
  const { t } = useTranslation('iam', 'common')
  const domainVerificationGuideUrl =
    'www.hpe.com/info/Domain-verification-guide'
  const stepVerification = [
    t('domains.verification_step1'),
    t('domains.verification_step2'),
    t('domains.verification_step3', { domainName }),
    t('domains.verification_step4'),
    t('domains.verification_step5')
  ]
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value)
  }

  return (
    <Box flex={false}>
      <Box>
        <Box>
          <Typography
            type="text"
            size="large"
            weight={400}
            testId="start-domain-claim-desc"
          >
            <GrommetMarkdown
              components={{
                a: ({ children }) => (
                  <Trans i18nKey="common.learn_more" t={t}>
                    <Anchor
                      data-testid="domain-verification"
                      label={children}
                      weight="bold"
                      onClick={() => dispatchEvent(new Event('context-help'))}
                    />
                  </Trans>
                )
              }}
            >
              {t('domains.view_text_modal_subtitle', { domainName })}
            </GrommetMarkdown>
          </Typography>
        </Box>
        <Typography
          type="heading"
          level={3}
          data-testid="add-verification-record-title"
          color="light"
          margin={{ top: 'medium', bottom: 'none' }}
        >
          {t('domains.add_verification_record')}
        </Typography>

        <ol
          style={{
            paddingLeft: '30px',
            marginTop: '6px',
            marginBottom: '0'
          }}
        >
          {stepVerification.map((step, id) => (
            <GrommetMarkdown
              key={step}
              components={{
                liMark: ({ children }) => <li>{children}</li>,
                strong: ({ children }) => (
                  <span style={{ fontWeight: '500' }}>{children}</span>
                )
              }}
            >
              {t(`${step}`, {
                number: id + 1,
                symbol: '.'
              })}
            </GrommetMarkdown>
          ))}
        </ol>
      </Box>

      <Box pad={{ top: 'medium' }}>
        <Typography
          type="text"
          emphasis
          data-testid="verification-text-record-title"
        >
          {t('domains.verification_text_record_title')}
        </Typography>

        <Box direction="row" align="center">
          <Typography
            type="text"
            emphasis
            data-testid="text-record-data"
            color="light"
          >
            {domainClaimTXT}
          </Typography>

          <Button
            default
            onClick={() => handleCopy(domainClaimTXT)}
            hoverIndicator="none"
            testId="domain-text-record-copy-btn"
          >
            {({ hover, focus }) => (
              <>
                <Tooltip
                  dropProps={{ align: { left: 'right' } }}
                  info={
                    <>
                      {hover && !focus ? t('authz:copy') : null}
                      {hover && focus ? t('authz:copied') : null}
                    </>
                  }
                  testId="copy-tooltip"
                >
                  <Copy size="medium" />
                </Tooltip>
              </>
            )}
          </Button>
        </Box>
      </Box>

      <Box pad={{ top: 'medium' }}>
        <Box direction="row" align="center" justify="between">
          <Typography
            type="heading"
            level={2}
            data-testid="text-record-administrator-title"
            color="light"
          >
            {t('domains.not_the_dns_administrator')}
          </Typography>

          <Box direction="row" align="center">
            <Typography
              type="text"
              data-testid="domain-verification-guide-title"
              size="small"
              weight={600}
              color="light"
            >
              {t('domains.domain_verification_guide')}
            </Typography>

            <Button
              default
              onClick={() => {
                handleCopy(domainVerificationGuideUrl)
              }}
              hoverIndicator="none"
              testId="domain-verification-guide-copy-btn"
            >
              {({ hover, focus }) => (
                <>
                  <Tooltip
                    dropProps={{ align: { left: 'right' } }}
                    info={
                      <>
                        {hover && !focus ? t('authz:copy') : null}
                        {hover && focus ? t('authz:copied') : null}
                      </>
                    }
                    testId="copy-tooltip"
                  >
                    <Copy size="medium" />
                  </Tooltip>
                </>
              )}
            </Button>
          </Box>
        </Box>

        <Typography type="text">
          {t('domains.domain_verification_copy_guide')}
        </Typography>
      </Box>
    </Box>
  )
}

ViewDomainClaimContent.propTypes = {
  domainClaimTXT: PropTypes.string,
  domainName: PropTypes.string
}

export default ViewDomainClaimContent
