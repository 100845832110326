// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Tile, AvatarInfo, Logo } from '../../components'

const AccountLogo = ({
  account,
  logoOnly = true,
  title = '',
  subTitle = '',
  logoSize = 'small',
  avatarSize = 'medium',
  testId = ''
}) => {
  // tenant account has no platform_customer_id, set to ''
  const platformId = account.platform_customer_id || ''
  const displayLogo = () => {
    return (
      <Logo
        url={account.customer_logo.logo}
        testId={`account-logo-${account.platform_customer_id}-${testId}`}
        size={logoSize}
        noShade
      />
    )
  }

  const displayTile = () => {
    return (
      <Tile
        pad="none"
        logo={
          <Logo
            url={account.customer_logo.logo}
            testId={`account-logo-${platformId}-${testId}`}
            size={logoSize}
            noShade
          />
        }
        layout="row"
        testId={`account-logo-tile-${testId}`}
        title={
          <Typography
            type="heading"
            level="1"
            testId={`account-logo-${platformId}-title-${testId}`}
          >
            {title}
          </Typography>
        }
        subTitle={
          <Typography
            type="text"
            size="large"
            testId={`account-logo-${platformId}-subtitle-${testId}`}
          >
            {subTitle}
          </Typography>
        }
      />
    )
  }
  const displayAvatar = () => {
    return (
      <AvatarInfo
        avatarSrc={null}
        avatarSize={avatarSize}
        avatarChar="one"
        primaryInfo={title}
        secondaryInfo={subTitle}
        avatarOnly={logoOnly}
        testId={`account-avatar-${account.platform_customer_id}-${testId}`}
      />
    )
  }

  return (
    <>
      {account.customer_logo?.logo && logoOnly && displayLogo()}
      {account.customer_logo?.logo && !logoOnly && displayTile()}
      {!account.customer_logo?.logo && displayAvatar()}
    </>
  )
}
AccountLogo.propTypes = {
  account: PropTypes.object.isRequired,
  logoOnly: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  logoSize: PropTypes.string,
  avatarSize: PropTypes.string,
  testId: PropTypes.string
}

export { AccountLogo }
