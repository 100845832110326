// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { setupInterceptor, updateCoreData } from '../utils/api-utils'
import { SignOutPage, EndSession } from '../pages/acct-onboarding'
import { useCCSContext } from '../context/ccs-context'
import {
  VisibilityActions,
  useVisibilityContext
} from '../context/visibility-context'
import { formatLDFlags } from '../utils/launchdarkly-utils'
import COPCertifiateRedirectionRouter from '../pages/acct-onboarding/cop-redirection/router'
import useLogger from '../hooks/logs/useLogger'
import { setFromCentralSessionStorage } from '../utils/common-utils'
import { isV2, isCoP } from '../utils/feature-flag-utils'
import SREMaintenancePage from '../pages/error/SREMaintenance'
import LoginTermsConditionModal from '../pages/acct-onboarding/login-terms-condition/LoginTermsConditionModal'
import { ActivateMagicLinkRedirection } from '../pages/acct-onboarding/activate-magic-link/ActivateMagicLink'
import OnboardingRouter from '../pages/acct-onboarding/router'
import ErrorRouter from '../pages/error/router'

import PostAuthAppRouter from './post-auth-app-router'

const CommonAppRouter = () => {
  const navigate = useNavigate()
  const { dispatchCCSContext, csrfToken } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const LDFlags = useFlags()
  const logger = useLogger()
  const pageInfoLog = {
    page: CommonAppRouter.name,
    section: 'Initialize',
    type: 'success',
    count: 'Calling:common-app-router'
  }
  logger?.log({ ...pageInfoLog, msg: 'Common App Router loaded successfully!' })

  const sreManualMaintenance = LDFlags && LDFlags['glcp-sre-manual-maintenance']

  useEffect(() => {
    setupInterceptor(dispatchCCSContext, csrfToken, navigate, isV2())
    updateCoreData({
      name: 'flags',
      data: LDFlags
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isCoP()) {
      if (sreManualMaintenance) {
        navigate('/sre-maintenance')
      } else if (
        window?.location?.href?.endsWith('/sre-maintenance') &&
        sreManualMaintenance !== undefined
      ) {
        navigate('/')
      }
    }
  }, [sreManualMaintenance]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set the session storage to determine if it is from central
    // needed for COP
    if (isCoP()) setFromCentralSessionStorage()
  }, [])

  useEffect(() => {
    dispatchVisibilityContext({
      type: VisibilityActions.SET_HIDE_WHEN,
      data: {
        key: 'feature',
        value: formatLDFlags(LDFlags)
      }
    })
  }, [LDFlags, dispatchVisibilityContext])

  return (
    <Routes>
      {/* Un-authenticated routes like sign-up will go here */}
      <Route
        path="/accept-certificate/*"
        element={<COPCertifiateRedirectionRouter />}
      />

      <Route
        path="/terms-and-condition"
        element={<LoginTermsConditionModal />}
      />
      <Route path="/onboarding/*" element={<OnboardingRouter />} />
      <Route path="/sign-out" element={<SignOutPage />} />
      <Route path="/end-session" element={<EndSession />} />
      <Route path="/error/*" element={<ErrorRouter />} />

      <Route exact path="/sre-maintenance" element={<SREMaintenancePage />} />
      <Route
        exact
        path="/activate/*"
        element={<ActivateMagicLinkRedirection />}
      />
      <Route path="/*" element={<PostAuthAppRouter />} />
    </Routes>
  )
}

export default CommonAppRouter
