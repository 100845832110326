// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'

import {
  isGLOP,
  isOrg,
  isInventoryOwnedWorkspace,
  isOrgWorkSpace
} from '../../../utils/feature-flag-utils'
import { AssignRoleModal } from '../../manage-account/identity/common-components/assign-roles-modal'
import { isServiceCentric } from '../../../utils/account-utils'
import {
  WKSPC_CAPITALIZED,
  getWorkspaceString
} from '../../../utils/common-utils'
import InviteUser from '../../../commoncomponents/identity/users/InviteUser'

const HomeQuickActionsData = (setModalOpen) => {
  const LDFlags = useFlags()
  const { t } = useTranslation(['authn', 'common'])
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const mspCustomerOwnLd = LDFlags['glcp-msp-customer-own']
  const newDashboardFlag = isServiceCentric(LDFlags)
  const multiple_accounts = JSON.parse(
    sessionStorage.getItem('multiple-accounts')
  )
  const quickActionsData = [
    {
      id: 1,
      preTitle: 'dashboard.quick_links_card.title',
      title: 'dashboard.quick_links_card.manage_workspace',
      routeTo: '/manage-account',
      testId: 'manage-workspace',
      margin: { left: 'small', top: 'small' }
    },
    {
      id: 2,
      title:
        newDashboardFlag || isGLOP()
          ? 'dashboard.quick_links_card.device_inventory'
          : 'dashboard.quick_actions_card.add_device',
      routeTo: '/devices/inventory',
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      },
      hidden: !isInventoryOwnedWorkspace(),
      testId: 'onboard-devices-anchor',
      margin: { left: 'small', top: 'small' }
    },
    {
      id: 3,
      title: newDashboardFlag
        ? 'dashboard.quick_links_card.service_subscriptions'
        : 'dashboard.quick_actions_card.add_subscriptions',
      routeTo: newDashboardFlag
        ? '/services/service-subscriptions'
        : '/manage-account/subscriptions/device-subscriptions',
      testId: 'add-subscriptions-anchor',
      margin: { left: 'small', top: 'small' },
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      },
      hidden: !isInventoryOwnedWorkspace() || isGLOP()
    },
    {
      id: 4,
      title: 'dashboard.quick_actions_card.assign_roles',
      modal: <AssignRoleModal setModal={setModalOpen} />,
      routeTo: '/manage-account/identity/roles',
      testId: 'assign-roles-anchor',
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.edit'
      },
      margin: { left: 'small', top: 'small' },
      hidden: newDashboardFlag || isGLOP()
    },
    {
      id: 5,
      title: 'dashboard.quick_actions_card.invite_user',
      modal: (
        <InviteUser inviteUserOpen path="/home" onSetOpen={setModalOpen} />
      ),
      routeTo: null,
      testId: 'invite-user-anchor',
      margin: { left: 'small', top: 'small' },
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.edit'
      },
      hidden: newDashboardFlag || isGLOP()
    }
  ]
  if (isGLOP()) {
    quickActionsData.push({
      id: 6,
      title: t('customer_account.create_customer_account', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      }),

      routeTo: '/customer-account',
      testId: 'add-customer-anchor',
      margin: { left: 'small', top: 'small' },
      hideFor: { account: ['TENANT', 'STANDALONE', 'BASIC_ORGANIZATION'] },
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.edit'
      }
    })
    quickActionsData.push({
      id: 7,
      title: 'dashboard.quick_links_card.user_management',
      routeTo: isOrgWorkSpace()
        ? '/manage-account/identity/users-and-groups'
        : '/manage-account/identity/users',
      testId: 'manage-iam-anchor',
      margin: { left: 'small', top: 'small' },
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.view'
      }
    })
    quickActionsData.push({
      id: 9,
      title: 'dashboard.quick_links_card.switch_workspace',
      routeTo: '/switch-account',
      testId: 'switch-workspace',
      margin: { left: 'small', top: 'small' },
      hidden: !multiple_accounts
    })
  }
  if (newDashboardFlag) {
    quickActionsData.push({
      id: 6,
      title: mspCustomerOwnLd
        ? t('customer_account.create_customer_account', {
            account: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_CAPITALIZED
            )
          })
        : t('dashboard.quick_actions_card.add_customer'),
      routeTo: '/customer-account',
      testId: 'add-customer-anchor',
      margin: { left: 'small', top: 'small' },
      hideFor: { account: ['TENANT', 'STANDALONE', 'BASIC_ORGANIZATION'] },
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.edit'
      }
    })
    quickActionsData.push({
      id: 7,
      title: 'dashboard.quick_links_card.user_management',
      routeTo: isOrgWorkSpace()
        ? '/manage-account/identity/users-and-groups'
        : '/manage-account/identity/users',
      testId: 'manage-iam-anchor',
      margin: { left: 'small', top: 'small' },
      rbac: {
        resource: isOrg() ? undefined : '/ccs/authorization',
        permission: isOrg()
          ? 'ccs.accounts.user.view-all'
          : 'ccs.authorization.view'
      }
    })
    quickActionsData.push({
      id: 8,
      title: 'dashboard.quick_links_card.locations',
      routeTo: '/manage-account/locations',
      testId: 'create-location-anchor',
      margin: { left: 'small', top: 'small' },
      rbac: {
        resource: '/ccs/location-management',
        permission: 'ccs.location-management.view'
      },
      hidden: isGLOP() || !isInventoryOwnedWorkspace()
    })
    quickActionsData.push({
      id: 9,
      title: 'dashboard.quick_links_card.switch_workspace',
      routeTo: '/switch-account',
      testId: 'switch-workspace',
      margin: { left: 'small', top: 'small' },
      hidden: !multiple_accounts
    })
    quickActionsData.push({
      id: 10,
      title: t('common:manage_account.reporting_title'),
      routeTo: '/manage-account/reporting',
      testId: 'reporting-anchor',
      margin: { left: 'small', top: 'small' },
      hideFor: {
        feature: 'glcp-reportfw-dashboard'
      },
      rbac: {
        resource: '/ccs/reporting/dashboard',
        permission: 'ccs.reporting.view'
      }
    })
    quickActionsData.push({
      id: 11,
      title: 'dashboard.quick_links_card.feedback',
      hrefTo:
        'https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=gf',
      testId: 'feedback',
      margin: { left: 'small', top: 'small' }
    })
    quickActionsData.push({
      id: 12,
      title: 'dashboard.quick_links_card.support_hub',
      routeTo: '/support',
      testId: 'support-hub',
      margin: { left: 'small', top: 'small' },
      hideFor: {
        account: ['MSP', 'TENANT', 'BASIC_ORGANIZATION'],
        deployment: ['COP', 'GLOP'],
        feature: 'glcp-support-home'
      }
    })
  }
  return quickActionsData
}

export { HomeQuickActionsData }
