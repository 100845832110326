// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { MenuList } from '../../components'
import { isInventoryOwnedWorkspace } from '../../utils/feature-flag-utils'
import { customRenderer } from '../../commoncomponents/CustomRenderer'
import { useVisibilityContext } from '../../context/visibility-context'

const SideMenu = ({ view = 'my-services' }) => {
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const LDFlags = useFlags()
  const smAutoReassignFlag = LDFlags['glcp-sm-auto-reassign']
  const dmEnableDefaultRegion = LDFlags['glcp-dm-enable-default-region']
  const { rbacPolicies } = useVisibilityContext()
  const [serviceCatalogRbacResource, setServiceCatalogRbacResource] =
    useState(undefined)
  const [serviceCatalogRbacPermission, setServiceCatalogRbacPermission] =
    useState(undefined)

  useEffect(() => {
    if (rbacPolicies?.effects) {
      const potentialPermissions = LDFlags['glcp-service-registry']
        ? [
            '/ccs/app-catalog',
            '/ccs/app-catalog/application',
            '/ccs/service-catalog',
            '/ccs/service-catalog/service-catalog'
          ]
        : ['/ccs/app-provision/provisions']

      const permissionsToViewServiceCatalog = Object.entries(
        rbacPolicies.effects
      )
        .filter(([key]) => potentialPermissions.includes(key))
        .filter(
          ([, values]) =>
            Object.keys(values).filter((permissionKey) =>
              permissionKey.includes('view')
            ).length
        )
        .map(([key, values]) => [
          key,
          [
            ...Object.keys(values).filter((permissionKey) =>
              permissionKey.includes('view')
            )
          ][0]
        ])

      const [serviceCatalogResource, serviceCatalogPermission] =
        permissionsToViewServiceCatalog[0]

      setServiceCatalogRbacResource(serviceCatalogResource)
      setServiceCatalogRbacPermission(serviceCatalogPermission)
    }
  }, [rbacPolicies, LDFlags])

  /**
   * TODO: enable RBAC-based visibility for My Services when we can provide fallback routes
   * My Services is currently the base path we visit when a user clicks on Services in the
   * header. However, if a user doesn't have permission to view My Services, we don't currenlty
   * have a mechanism to fallback to the next relevant route. Until we establish that, we need
   * to let the user view the page, but not view the relevant My Services data.
   */
  // Service pages permissions
  //   |
  //   + --- My-services ()
  //              /ccs/app-provision/provisions - ccs.app-provision.view
  //              /ccs/service-catalog/service-provision - ccs.service-catalog.view
  //              /ccs/service-catalog/my-services - ccs.service-catalog.view
  //   |
  //   + --- Service Subscriptions
  //              /ccs/authorization - ccs.authorization.view
  //   |
  //   + --- Service-Catalog
  //              /ccs/app-catalog - ccs.app-catalog.view
  //              /ccs/app-catalog/application - ccs.app-catalog.view
  //              /ccs/service-catalog - ccs.service-catalog.view
  //              /ccs/service-catalog/service-catalog - ccs.service-catalog.view

  const myServiceDataObject = {
    id: 1,
    label: t('common.my_services'),
    path: '/services/my-services',
    active: 'my-services',
    testId: 'service-centric-menulist-my-services-link'
  }

  const serviceRegistryBasedChanges = LDFlags['glcp-service-registry']
    ? {
        isHidden: false
      }
    : {
        visibility: {
          rbac: {
            resource: '/ccs/app-provision/provisions',
            permission: 'ccs.app-provision.view'
          }
        }
      }

  const myServiceMenuData = [
    {
      ...myServiceDataObject,
      ...serviceRegistryBasedChanges
    },
    {
      id: 2,
      label: t('common.subscriptions'),
      path: '/services/service-subscriptions',
      active: 'subscriptions',
      testId: 'service-centric-menulist-service-subscriptions-link',
      visibility: {
        rbac: {
          resource: '/ccs/authorization',
          permission: 'ccs.authorization.view'
        }
      },
      isHidden: () => !isInventoryOwnedWorkspace()
    },
    {
      id: 3,
      label: t('common.catalog'),
      path: '/services/service-catalog',
      active: 'catalog',
      testId: 'service-centric-menulist-service-catalog-link',
      visibility: {
        rbac: {
          resource: serviceCatalogRbacResource,
          permission: serviceCatalogRbacPermission
        }
      }
    }
  ]

  const devicesMenuData = [
    {
      id: 1,
      label: t('common.inventory'),
      path: '/devices/inventory',
      active: 'inventory'
    },
    {
      id: 2,
      label: t('common.tags'),
      path: '/devices/tags',
      active: 'tags'
    },
    {
      id: 3,
      label: t('common.device_subscriptions'),
      path: '/devices/subscriptions',
      active: 'subscriptions'
    },
    ...(smAutoReassignFlag || dmEnableDefaultRegion
      ? [
          {
            id: 4,
            label: t('common.device_configuration'),
            routeTo: '/devices/device-configuration',
            testId: 'device-configuration-link'
          }
        ]
      : [
          {
            id: 4,
            label: t('common.auto_subscribe'),
            path: '/devices/auto-subscribe',
            active: 'auto-subscribe'
          }
        ])
  ]

  const chosenSideMenuData =
    view === 'my-services' ? myServiceMenuData : devicesMenuData

  const getSelectedListId = () => {
    const data = chosenSideMenuData.find((item) => pathname.includes(item.path))
    return data ? data.id : 1
  }

  return (
    <MenuList
      defaultActiveId={getSelectedListId()}
      highlightOnSelect
      menuData={chosenSideMenuData}
      menuItemStyles={{ weight: 500 }}
      testId="service-centric-menulist"
      onClickMenuItem={({ path }) => {
        navigate(path)
      }}
      navStyles={{ padding: 'none', gap: 'small' }}
      customRenderer={customRenderer}
    />
  )
}

SideMenu.propTypes = {
  view: PropTypes.oneOf(['my-services', 'devices'])
}

export default SideMenu
