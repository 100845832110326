// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import axios from 'axios'
import axiosRetry from 'axios-retry'
import lodashGet from 'lodash/get'
import lodashSet from 'lodash/set'

import { CCSActions } from '../context/ccs-context'

import { localStorageWhitelistWithoutPII } from './local-storage-utils'
import { cleanAccountSessionStorage } from './client-storage-cleaning-utils'
import { commonMessages, formatErrorObject } from './error-handling-utils'

// withCredentials allows cross-site to read cookies
// frontend and authN backend are on different domains
axios.defaults.withCredentials = true

export const isCoP = () => {
  const showCOPFeature = lodashGet(window, '$settings.isCoP')
  return showCOPFeature
}

const createHeaders = (accessToken) => {
  const headers = { Accept: 'application/json' }

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return headers
}

const getApiBaseUrl = (url) => {
  const orgApi =
    url &&
    (url.startsWith('/identity/v1alpha1/') ||
      url.startsWith('/identity/v2alpha1/') ||
      url.startsWith('/authorization/v2alpha1/') ||
      url.startsWith('/internal-authorization/v2alpha1') ||
      url.startsWith('/internal-platform-tenant-ui') ||
      url.startsWith('/internal-identity/v1alpha1') ||
      url.startsWith('/internal-sessions/v1alpha1') ||
      url.startsWith('/organizations/v2alpha1') ||
      url.startsWith('/authorization/v2/oauth2') ||
      url.startsWith('/workspaces/v2alpha1/workspaces/'))
  return orgApi ? window.$settings.orgBaseUrl : window.$settings.baseUrl
}

const parseWWWAuthenticate = (authenticateHeader) => {
  const regex = /(\w+)="(.*?)"/g

  return Object.fromEntries(
    [...authenticateHeader.matchAll(regex)].map(([, key, value]) => [
      key,
      value
    ])
  )
}

const isInvalidToken = (response) => {
  return (
    parseWWWAuthenticate(response?.headers?.['www-authenticate'])?.error ===
    'invalid_token'
  )
}

export function getBaseUrl(url = undefined) {
  const apiBaseUrl = getApiBaseUrl(url)
  const API_HOST_MAP = {
    'glcp,greenlake': window.$settings.newAPIHost,
    'ccs,common,cloud': apiBaseUrl
  }

  const currentHost = window.location.host?.split('.')[1]

  const baseUrl =
    currentHost && window.$settings.newAPIHost
      ? Object.keys(API_HOST_MAP)
          .map((v) => v?.includes(currentHost) && API_HOST_MAP[v?.toString()])
          .filter(Boolean)[0]
      : apiBaseUrl
  return baseUrl
}

export const post = (
  url,
  body = {},
  accessToken = undefined,
  customHeaders = undefined,
  responseType = undefined,
  unintercepted = undefined
) => {
  let headers = createHeaders(accessToken)
  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
  }
  let config = { headers }
  if (responseType) {
    config = { ...config, ...responseType }
  }

  const fullUrl = `${getBaseUrl(url)}${url}`

  if (unintercepted) {
    const uninterceptedAxiosInstance = axios.create()
    return uninterceptedAxiosInstance.post(fullUrl, body, config)
  }
  return axios.post(fullUrl, body, config)
}
export const axiosRetryPost = (
  url,
  body = {},
  accessToken = undefined,
  customHeaders = undefined,
  responseType = undefined,
  retryConfig = {}
) => {
  const retryInstance = axios.create({ baseURL: getBaseUrl(url) })
  axiosRetry(retryInstance, {
    ...retryConfig,
    retryDelay: retryConfig.retryDelay || axiosRetry.exponentialDelay
  })
  let headers = createHeaders(accessToken)
  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
  }
  let config = { headers }
  if (responseType) {
    config = { ...config, ...responseType }
  }

  const fullUrl = `${getBaseUrl(url)}${url}`

  return retryInstance.post(fullUrl, body, config)
}
export const del = (
  url,
  body = {},
  accessToken = undefined,
  customHeaders = undefined
) => {
  let headers = createHeaders(accessToken)
  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
  }

  const fullUrl = `${getBaseUrl(url)}${url}`

  return axios.delete(fullUrl, {
    data: body,
    headers
  })
}

export const put = (
  url,
  body = {},
  accessToken = undefined,
  customHeaders = undefined,
  params = {}
) => {
  let headers = createHeaders(accessToken)
  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
  }

  const fullUrl = `${getBaseUrl(url)}${url}`

  return axios.put(fullUrl, body, { headers, params })
}

export const patch = (
  url,
  body = {},
  accessToken = undefined,
  customHeaders = undefined
) => {
  let headers = createHeaders(accessToken)
  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
  }
  const fullUrl = `${getBaseUrl(url)}${url}`

  return axios.patch(fullUrl, body, { headers })
}

export const get = (
  url,
  params = {},
  accessToken = undefined,
  removeBaseUrl = false,
  options = {},
  customHeaders = undefined
) => {
  let headers = createHeaders(accessToken)

  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
  }

  let fullUrl = url

  if (!removeBaseUrl) {
    fullUrl = `${getBaseUrl(url)}${url}`
  }
  const config = {
    headers,
    params,
    ...options
  }

  return axios.get(fullUrl, config)
}

export async function getFile(url) {
  axios.defaults.withCredentials = false
  const headers = {
    'Access-Control-Allow-Origin': '*'
  }
  return axios.get(url, {
    headers
  })
}

export async function getAll(allGetCalls) {
  const allCalls = []
  allGetCalls.forEach((api) => {
    const { params } = api
    allCalls.push(
      get(`${api.url}`, params, `${api.accessToken}`).catch(() => null)
    )
  })
  return axios.all(allCalls)
}

export const getErrorResponse = (apiError, i18nTranslate) => {
  /*
    CCS-2803 - Error response from Backend is not consistent
    Few API's have `message` and few have `Message`
    Remove - `apiError.response.data.Message` - once CCS-2803 is fixed.
  */
  let message =
    apiError?.response?.data?.error_description ||
    apiError?.response?.data?.message ||
    apiError?.response?.data?.Message ||
    apiError?.response?.data?.detail ||
    apiError?.response?.data?.response ||
    apiError?.message ||
    apiError?.detail
  // Verifying if error message is from error-handing-util, if so skip showing the msg code
  // This is temporary, need to remove once all messages handled using error-handling-util
  message =
    typeof message === 'string' &&
    !Object.values(commonMessages).includes(message)
      ? message
      : null
  if (!message) {
    // If Backend doesn't send message, set message from error code
    if (apiError?.response?.status === 500) {
      message = `${i18nTranslate('common:unknown_error')}`
    } else if (apiError?.response?.status === 403) {
      message = `${i18nTranslate('common:access_forbidden')}`
    } else {
      // all other errors
      message = `${i18nTranslate('common:bad_request')}`
    }
  }
  // Showing error message for toast notification when session timedout
  if (apiError?.request?.requestError) {
    message = i18nTranslate('common:error_messages.ERR_session_timedout')
    lodashSet(apiError, 'request.requestError', false)
  }
  return {
    message,
    code: apiError?.response?.status,
    transactionId: apiError?.response?.headers?.['ccs-transaction-id']
  }
}

export const getErrorMessage = (apiError, i18nTranslate) => {
  const { message } = getErrorResponse(apiError, i18nTranslate)
  return message
}

const handleAllAPIErrors = async (
  error,
  dispatchCCSContext,
  navigate,
  isV2
) => {
  // Error
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // DO NOT remove the below console.log
    // console.error('%API Error Handling - error.response', error)
    // console.log(error.response.data)
    // console.log(error.response.status, 'error status')
    // console.log(error.response.headers)
    // navigate('/error/access')
    // TODO: move all the common error handlers here 500's and 400's
    // backend is sending 403 for some API's when there is session timeout so skipping formatting of the error
    // v2 session timeout
    if (isV2 && error?.response?.status === 401) {
      try {
        await get(`/internal-sessions/v1alpha1/my-ui-session`)
      } catch (err) {
        if (err?.response?.status === 404) {
          // session does not exists
          dispatchCCSContext({
            type: CCSActions.SET_CSRF_TOKEN,
            data: null
          })
          navigate('/sign-out') // sign out component takes care of clearing session/local storage
        } else if (err?.response?.status === 401) {
          // session unauthorized; call delete session
          await del(`/internal-sessions/v1alpha1/my-ui-session`)
          // del can return 204 - NO CONTENT if session doesn't exist
          // del can return 500 for any other issues
          // del API is guaranteed by BE not to send a 401 status
          dispatchCCSContext({
            type: CCSActions.SET_CSRF_TOKEN,
            data: null
          })
          navigate('/sign-out') // sign out component takes care of clearing session/local storage
        }
      }
    }
    // TODO: Combine this with the above block to create a single 401 error handling block.
    // If v1 API call is returning a 401 with error code for session timeout or invalid token, trigger a signout.
    if (
      error?.response?.status === 401 &&
      (error?.response?.data?.errorCode === 'HPE_GL_V1_SESSION_NOT_FOUND' ||
        isInvalidToken(error?.response))
    ) {
      dispatchCCSContext({
        type: CCSActions.SET_CSRF_TOKEN,
        data: null
      })
      navigate('/sign-out')
    }

    if (error.response.status !== 403) {
      formatErrorObject(error)
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // In GLCP after the token/session became invalid, subsequent API calls are getting failed in
    // istio/proxy server and unable to throw any response/error code, instead throws CORS error
    // and shows blank screen
    // DO NOT remove the below console.log
    const failedAPIURL = error.config.url
    localStorage.setItem(
      localStorageWhitelistWithoutPII.LAST_REQ_ERROR,
      error.stack
    )
    if (
      failedAPIURL &&
      (failedAPIURL.includes('/accounts/ui/v1/user/profile/country') ||
        failedAPIURL.includes('/authn/v1/session/end-session'))
    ) {
      console.error('API Error Handling - error.request', error)
    } else {
      // Setting requestError flag in response to use in error handling methods to show toast notification
      console.error('API Error Handling - error.request', error)
      lodashSet(error, 'request.requestError', true)
    }

    if (
      !(
        (error?.message === 'Request aborted' &&
          error?.code === 'ECONNABORTED') ||
        (error?.message === 'Network Error' && error?.code === 'ERR_NETWORK')
      )
    ) {
      // In firefox browser, During page navigation when API is cancelled
      // it is captured as ns_binding_aborted error and hits the response interceptor thus clearing the session storage
      // This results in page redirection to /choose-account page
      // Above check ensures session storage is cleared for all the error except for API cancelled

      // clean session storage data and delete v2 session, when UI throws a CORS error and shows blank screen
      if (isV2) {
        try {
          await del(`/internal-sessions/v1alpha1/my-ui-session`)
          cleanAccountSessionStorage()
          dispatchCCSContext({
            type: CCSActions.SET_CSRF_TOKEN,
            data: null
          })
        } catch (err) {
          navigate('/sign-out')
        }
      } else {
        cleanAccountSessionStorage()
      }
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    // This has to be fixed in UI
    // DO NOT remove the below console.log
    console.error('API Error Handling** ', error.message)
  }
}

export const setupInterceptor = (
  dispatchCCSContext,
  csrfToken,
  navigate,
  isV2,
  axiosInstance = axios
) => {
  // add response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      // handle IP restriction
      if (
        error?.response?.status === 451 &&
        lodashGet(error, 'response.data.reason') === 'IP_ACCESS_BLOCK'
      ) {
        dispatchCCSContext({
          type: CCSActions.SET_IS_IP_RESTRICTED,
          data: true
        })
        error.response.data.message = error?.response?.data?.reason
      }
      // handle generic GTS error code
      if (
        error?.response?.status === 451 &&
        lodashGet(error, 'response.data.contact_support', false)
      ) {
        window.location.replace('/error/access')
      }

      handleAllAPIErrors({ ...error }, dispatchCCSContext, navigate, isV2)

      return Promise.reject(error)
    }
  )

  // add request interceptor
  axiosInstance.interceptors.request.use(
    (request) => {
      if (
        csrfToken &&
        request?.url.startsWith(window.$settings.orgBaseUrl) &&
        request?.method !== 'get' &&
        request.headers
      ) {
        request.headers['X-CSRF-TOKEN'] = csrfToken
      }
      return request
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

const getPcid = () =>
  JSON.parse(sessionStorage?.getItem('account'))?.platform_customer_id

export const parseDataCacheId = (token) => {
  try {
    const pcid = getPcid()
    const jti = JSON.parse(atob(token?.split('.')[1]))?.jti
    return `${jti}-${pcid}`
  } catch (error) {
    console.error('Failed to decode token:', error)

    // need to return undefined if token is invalid here otherwise atob will throw an error
    // eslint-disable-next-line prettier/prettier, no-useless-return, consistent-return
    return
  }
}

export const updateCoreData = async ({ name, token, data }) => {
  // get current cache from session storage
  const coreDataCache = JSON.parse(sessionStorage.getItem('core-data'))

  // get new cache id from token (uses jti of access_token with pcid)
  const cacheId = token && parseDataCacheId(token)

  // set data to session storage
  sessionStorage.setItem(
    'core-data',
    JSON.stringify({
      ...coreDataCache,
      [name]: {
        ...data,
        ...(cacheId && { cacheId })
      }
    })
  )
}
