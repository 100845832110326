// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { FormField, Select } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { getSupportedLocales } from '../../utils/common-utils'

export const Preferences = ({ dispatch = {}, state = '' }) => {
  const { t } = useTranslation(['authn'])
  return (
    <FormField
      margin={{ bottom: 'medium' }}
      label={t('preferences.lang_lbl')}
      name="language"
      required
      data-testid="language-form-field"
    >
      <Select
        name="language"
        placeholder={t('preferences.select_language_placeholder')}
        options={getSupportedLocales()}
        multiple={false}
        labelKey="name"
        value={state.language}
        valueKey={{ key: 'code', reduce: true }}
        onChange={({ option }) => {
          dispatch({
            value: option.code,
            field: 'language',
            type: 'CHANGE_FIELD'
          })
        }}
      />
    </FormField>
  )
}
Preferences.propTypes = {
  dispatch: PropTypes.object,
  state: PropTypes.string
}
