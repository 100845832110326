// Copyright 2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import { FormField, Select } from 'grommet'
import { useTranslation } from 'react-i18next'

const TopicField = ({ topics = [] }) => {
  const { t } = useTranslation(['support_hub_feedback'])
  return (
    <FormField
      required
      name="topic"
      htmlFor="topic"
      label={t('topic_field.label')}
      data-testid="topic-field"
    >
      <Select
        id="topic"
        name="topic"
        options={topics}
        labelKey="label"
        valueKey={{ key: 'value', reduce: true }}
        placeholder={t('topic_field.placeholder')}
      />
    </FormField>
  )
}

TopicField.propTypes = {
  topics: PropTypes.array
}

export { TopicField }
