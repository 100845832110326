// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
export const subjectTypesEnum = {
  USER: 'USER',
  USER_GROUP: 'USER_GROUP'
}

export const glcpServiceID = '00000000-0000-0000-0000-000000000000'

export const attributeDefinitionsEnum = Object.freeze({
  GROUP_SCHEMA: 'urn:ietf:params:scim:schemas:core:2.0:Group',
  PATCH_OP_SCHEMA: 'urn:ietf:params:scim:api:messages:2.0:PatchOp'
})

export const permissionCustomRoleUseEnum = Object.freeze({
  ALLOW: 'ALLOW',
  DENY: 'DENY'
})

export const assignmentSubjectEnum = Object.freeze({
  user: 'User',
  'user-group': 'Group',
  'Api Client': 'API client'
})

export const HPE_MY_ACCOUNT = 'HPE MyAccount'

export const USER_SCHEMAS = Object.freeze({
  EXTENSIONS: 'urn:ietf:params:scim:schemas:extensions:hpe-greenlake:2.0:User'
})

export const roleAssignmentEnum = {
  DIRECT: 'Direct',
  SSO: 'SSO'
}

export const providerNameEnum = {
  IDENTITY: 'identity'
}

export const userStatus = {
  VERIFIED: 'VERIFIED'
}
