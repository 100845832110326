// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import dayjs from 'dayjs'

import {
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Notification,
  ObjectList,
  Typography
} from '../../components'
import { renameKeys } from '../../utils/common-utils'
import { getRuleDetails } from '../../utils/manage-account-utils'

const ViewRuleDetails = ({
  data,
  handleClose,
  inCCSManager = undefined,
  customerId = undefined
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const [ruleDetails, setRuleDetails] = useState(null)
  const [ruleDetailsResponse, setRuleDetailsResponse] = useState(null)
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const ProvisioningTypeEnum = {
    iap: t('iap_to_airwave'),
    iap_to_rap: t('iap_to_rap'),
    iap_to_cap: t('iap_to_cap'),
    iap_to_thirdparty: t('iap_to_third_party_server'),
    switch_to_amp: t('switch_to_airwave'),
    contrl_to_amp: t('conductor_to_airwave'),
    branch_to_master_controller: t('branch_to_mobility_conductor'),
    managed_node_to_master_controller: t(
      'managed_device_to_mobility_conductor'
    ),
    iap_to_athena: t('iap_to_central')
  }

  const commonI18Keys = {
    rule_name: t('rule_name'),
    rule_type: t('rule_type'),
    created_date: t('created_date'),
    created_by: t('created_by')
  }

  const ValueKey = {
    category: t('category'),
    part: t('family'),
    custpo: t('contains'),
    billto: t('contains'),
    externalip: t('ip_range')
  }

  const getProvisioningRuleI18Keys = (subType) => {
    switch (subType) {
      case 'iap':
        return {
          amp_ip: t('amp_ip'),
          shared_secret: t('shared_secret'),
          organization: t('organization'),
          ap_group: t('amp_cn'),
          controller: t('conductor'),
          value: t('conductor_mac')
        }
      case 'iap_to_rap':
        return {
          ap_group: t('ap_group'),
          controller: t('conductor'),
          value: t('conductor_mac')
        }
      case 'iap_to_cap':
        return {
          ap_group: t('ap_group'),
          controller: t('conductor'),
          persist_controller_ip: t('persist_conductor_ip')
        }
      case 'iap_to_thirdparty':
        return {
          ap_group: t('server'),
          controller: t('protocol')
        }
      case 'switch_to_amp':
        return {
          amp_ip: t('amp_ip'),
          shared_secret: t('shared_secret'),
          organization: t('amp_group'),
          ap_group: t('amp_folder'),
          controller: t('conductor'),
          backup_controller_ip: t('backup_controller_ip')
        }
      case 'contrl_to_amp':
        return {
          amp_ip: t('amp_ip'),
          organization: t('amp_group'),
          ap_group: t('amp_folder')
        }
      case 'branch_to_master_controller':
        return {
          controller: t('primary_conductor'),
          value: t('primary_ctrl_ip'),
          backup_controller: t('backup_conductor'),
          backup_controller_ip: t('backup_controller_ip'),
          country_code: t('country_code'),
          config_group: t('branch_config_group')
        }
      case 'iap_to_athena':
        return {
          value: t('central_ip')
        }
      default:
        // managed_node_to_master_controller
        return {
          redundancy_level: t('redundancy_level'),
          config_node_path: t('config_node_path'),
          country_code: t('country_code'),
          ...(ruleDetailsResponse.redundancy_level !== 'L3' && {
            controller: t('mobility_conductor'),
            value: t('mobility_conductor_ip'),
            ...(ruleDetailsResponse.redundancy_level === 'L2' && {
              backup_controller: t('backup_mobility_conductor')
            }),
            vpn_mac: t('vpn_concentrator_mac'),
            vpn_ip: t('vpn_concentrator_ip'),
            backup_vpn_mac: t('backup_vpn_concentrator_mac')
          }),
          primary_data_center: {
            controller: t('mobility_conductor'),
            value: t('mobility_conductor_ip'),
            backup_controller: t('backup_mobility_conductor'),
            vpn_mac: t('vpn_concentrator_mac'),
            vpn_ip: t('vpn_concentrator_ip'),
            backup_vpn_mac: t('backup_vpn_concentrator_mac')
          },
          secondary_data_center: {
            controller2: t('mobility_conductor'),
            primary_ctrl_ip2: t('mobility_conductor_ip'),
            backup_controller2: t('backup_mobility_conductor'),
            vpn_mac2: t('vpn_concentrator_mac'),
            vpn_ip2: t('vpn_concentrator_ip'),
            backup_vpn_mac2: t('backup_vpn_concentrator_mac')
          }
        }
    }
  }

  const i18Keys = {
    notification: {
      ...commonI18Keys,
      sub_type: t('email_on'),
      value: t('email_to')
    },
    moveToFolder: {
      ...commonI18Keys,
      move_to_folder_name: t('move_to_folder'),
      sub_type: t('move_by')
    },
    provisioningRule: {
      ...commonI18Keys,
      folder_name: t('parent_folder'),
      sub_type: t('provisioning_type')
    }
  }

  const getFormattedObject = (keys, obj) => {
    const filtered = pick(obj, Object.keys(keys))
    return renameKeys(keys, filtered)
  }

  useEffect(() => {
    if (data) {
      getRuleDetails({
        param: data,
        token: oidcUser.access_token,
        t,
        setRuleDetails: setRuleDetailsResponse,
        setLoading,
        setErrorMessage,
        inCCSManager,
        customerId
      })
    }
  }, [data, oidcUser, t, inCCSManager, customerId])

  useEffect(() => {
    if (ruleDetailsResponse && ruleDetailsResponse.created_date) {
      ruleDetailsResponse.created_date = dayjs(
        ruleDetailsResponse.created_date
      ).format('MM/DD/YYYY HH:mm')
    }
    if (ruleDetailsResponse !== null && errorMessage.length === 0) {
      let ruleObj = {}
      if (ruleDetailsResponse.rule_type === 'email') {
        const notificationKeys = {
          ...i18Keys.notification,
          ...((ruleDetailsResponse.sub_type === 'provision' ||
            ruleDetailsResponse.sub_type === 'folder') && {
            reference_rule_name: t('for_rule')
          }),
          ...(ruleDetailsResponse.sub_type === 'firmware' && {
            folder_name: t('parent_folder')
          })
        }
        ruleObj = getFormattedObject(notificationKeys, ruleDetailsResponse)
        ruleObj[t('email_on')] = t(
          `list_of_email_on_option.${ruleObj[t('email_on')]}`
        )
      } else if (ruleDetailsResponse.rule_type === 'folder') {
        const moveToFolderKeys = {
          ...i18Keys.moveToFolder,
          value: ValueKey[ruleDetailsResponse.sub_type]
        }
        ruleObj = getFormattedObject(moveToFolderKeys, ruleDetailsResponse)
        ruleObj[t('move_by')] = t(
          `list_of_move_type_option.${ruleObj[t('move_by')]}`
        )
        if (ruleDetailsResponse.sub_type === 'category')
          ruleObj[t('category')] = t(`${ruleObj[t('category')]}`)
      } else {
        const provisioningRuleKeys = {
          ...i18Keys.provisioningRule,
          ...getProvisioningRuleI18Keys(ruleDetailsResponse.sub_type)
        }
        if (
          ruleDetailsResponse.sub_type ===
            'managed_node_to_master_controller' &&
          ruleDetailsResponse.redundancy_level === 'L3'
        ) {
          const primaryDataCenterKeys = {
            ...getProvisioningRuleI18Keys(ruleDetailsResponse.sub_type)
              .primary_data_center
          }
          const primaryDataCenterData = getFormattedObject(
            primaryDataCenterKeys,
            ruleDetailsResponse
          )
          const secondaryDataCenterKeys = {
            ...getProvisioningRuleI18Keys(ruleDetailsResponse.sub_type)
              .secondary_data_center
          }
          const secondaryDataCenterData = getFormattedObject(
            secondaryDataCenterKeys,
            ruleDetailsResponse
          )
          const commonKeys = {
            ...i18Keys.provisioningRule,
            redundancy_level: t('redundancy_level'),
            config_node_path: t('config_node_path')
          }
          const commonData = getFormattedObject(commonKeys, ruleDetailsResponse)
          ruleObj = {
            ...commonData,
            primary_data_center: primaryDataCenterData,
            secondary_data_center: secondaryDataCenterData
          }
        } else
          ruleObj = getFormattedObject(
            provisioningRuleKeys,
            ruleDetailsResponse
          )
        ruleObj[t('provisioning_type')] =
          ProvisioningTypeEnum[ruleObj[t('provisioning_type')]] ||
          ruleObj[t('provisioning_type')]
        if (ruleObj[t('shared_secret')]) {
          ruleObj[t('shared_secret')] = {
            value: ruleDetailsResponse.shared_secret,
            type: 'password'
          }
        }
      }
      ruleObj[t('rule_type')] = t(`rule_type_option.${ruleObj[t('rule_type')]}`)
      setRuleDetails(ruleObj)
    }
  }, [ruleDetailsResponse]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderContent = (details) => {
    if (loading === true) return <Loader testId="loader" />
    if (errorMessage.length !== 0 || !details) return null
    if (
      details[t('provisioning_type')] ===
        t('managed_device_to_mobility_conductor') &&
      details[t('redundancy_level')] === 'L3'
    ) {
      return (
        <Box direction="column" gap="small">
          <ObjectList
            data={omit(details, [
              'primary_data_center',
              'secondary_data_center'
            ])}
            testId="obj-list-1"
          />
          <Typography testId="header-title" type="text" weight="bold">
            {t('primary_data_center')}
          </Typography>
          <ObjectList data={details.primary_data_center} testId="obj-list-2" />
          <Typography testId="header-title" type="text" weight="bold">
            {t('secondary_data_center')}
          </Typography>
          <ObjectList
            data={details.secondary_data_center}
            testId="obj-list-3"
          />
        </Box>
      )
    }
    return <ObjectList data={details} testId="obj-list-1" />
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              style={{ whiteSpace: 'nowrap' }}
              testId="header-title"
              type="heading"
              weight="bold"
            >
              {t('rule_details')}
            </Typography>
          }
          onClose={handleClose}
        />
      }
      content={
        <Box margin={{ top: 'small' }}>{renderContent(ruleDetails)}</Box>
      }
      footer={
        <ModalFooter
          left={
            <Box>
              {errorMessage.length > 0 && (
                <Notification
                  backgroundColor="status-critical"
                  testId="critical-inline-notification"
                  text={errorMessage}
                  type="inline"
                />
              )}
            </Box>
          }
        />
      }
      height="100%"
      onClose={handleClose}
      position="right"
      testId="side-drawer-dialog"
    />
  )
}

ViewRuleDetails.propTypes = {
  data: PropTypes.shape({
    folder_name: PropTypes.string.isRequired,
    rule_name: PropTypes.string.isRequired,
    folder_id: PropTypes.string,
    rule_id: PropTypes.string
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  inCCSManager: PropTypes.bool
}

export { ViewRuleDetails }
