// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import groupBy from 'lodash/groupBy'

const handleRedirect = (url, flags, callback) => {
  // This will resolve to a pathname, regardless if the url is relative or absolute.
  // Because undefined is a valid value for the url in pushState and replaceState, we
  // need to check if the url is truthy before trying to parse it.
  if (url) {
    const objectUrl = new URL(url, window.location.origin)
    const { pathname, href } = objectUrl ?? {}
    // namespace is the first part of the pathname
    const namespace = pathname && `/${pathname.split('/')[1]}`
    // apps is an array of objects with oldPath and newPath properties
    const mfeAppConfig = flags['glcp-mfe-config']?.apps
    // create a map so we can key on the oldPath for quick lookups
    const appsByOldPath = mfeAppConfig ? groupBy(mfeAppConfig, 'oldPath') : {}
    // get the app config for the current namespace
    const [appConfig] = appsByOldPath[namespace] ?? []

    // Redirect to MFE new path if the old path is found in the glcp-mfe-config flag
    // and the separate boolean flag is true for the service cutover
    if (appConfig?.newPath && flags[`glcp-mfe-${appConfig?.id}`]) {
      window.location.assign(href.replace(namespace, appConfig.newPath))
      return
    }
  }

  // ensures that all potential redirects are handled first without any race conditions
  if (callback) {
    callback()
  }
}

export const createMfeRedirectHandler = (flags) => {
  // handle initial redirect if necessary
  handleRedirect(window.location.href, flags)

  // patch pushState and replaceState to handle redirects
  const originalPushState = window.history.pushState
  const originalReplaceState = window.history.replaceState

  // eslint-disable-next-line func-names
  window.history.pushState = function (_state, _title, url) {
    handleRedirect(url, flags, () =>
      originalPushState.apply(this, [_state, _title, url])
    )
  }
  // eslint-disable-next-line func-names
  window.history.replaceState = function (_state, _title, url) {
    handleRedirect(url, flags, () =>
      originalReplaceState.apply(this, [_state, _title, url])
    )
  }
}
