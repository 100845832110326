// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Box, FormField, TextInput } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import dayjs from 'dayjs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StatusCriticalSmall, StatusGoodSmall } from 'grommet-icons'

import {
  ToggleButton,
  Typography,
  CCSForm,
  Dropdown,
  Tooltip
} from '../../../../../../components'
import { getFolderList } from '../../../../../../utils/manage-account-utils'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayNotification } from '../../../../../../utils/notificiation-utils'
import { useVisibilityContext } from '../../../../../../context/visibility-context'

import { ToggleGTStatus } from './ToggleGTStatus'

function ActivateDevicesDetailsSection({
  mode,
  deviceDetailsData = {},
  editableDeviceDetailsData = {},
  setEditDeviceDetailsData,
  customerId = undefined
}) {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const [folderListResponse, setFolderListResponse] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const { rbacPolicies } = useVisibilityContext()
  const gtStatusFlag = LDFlags['glcp-be-activate-gt-phase2']
  // for paginating on scroll
  const itemsPerPage = 50
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(0)
  const [gtStatus, setGTStatus] = useState(false)
  const [gtState, setGTState] = useState(true)
  const [disableOverrideToggle, setDisableOverrideToggle] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const deviceDetailsKeys = gtStatusFlag
    ? {
        gts_status: t('device:global_trade.gt_firmware_status'),
        override_status: t('device:global_trade.override_gt_firmware_status'),
        mac_address: 'mac_address',
        serial_number: 'serial_number',
        part_number: 'part_number',
        location_name: 'location',
        contact_name: 'service_delivery_contact',
        device_type: 'device_type',
        imei: 'imei',
        inv_name: 'device_name',
        inv_full_name: 'full_name',
        inv_description: 'description',
        folder_folder_name: 'folder_name',
        folder_folder_id: 'folder_id',
        rule_rule_name: 'rule_name',
        rule_ap_group: 'ap_group',
        rule_controller: 'controller',
        mode: 'mode',
        display_device_type: 'display_device_type',
        device_model: 'device_model',
        archived: 'archived',
        entitlement_id: 'entitlement_id',
        application_id: 'application_id',
        application_instance_id: 'application_instance_id',
        activate_customer_id: 'activate_customer_id',
        platform_customer_id: 'platform_customer_id',
        tenant_platform_customer_id: 'tenant_platform_customer_id',
        provisioning_image: 'provisioning_image',
        json_data: 'json_data',
        create_at: 'create_at',
        updated_at: 'updated_at'
      }
    : {
        mac_address: 'mac_address',
        serial_number: 'serial_number',
        part_number: 'part_number',
        location_name: 'location',
        contact_name: 'service_delivery_contact',
        device_type: 'device_type',
        imei: 'imei',
        inv_name: 'device_name',
        inv_full_name: 'full_name',
        inv_description: 'description',
        folder_folder_name: 'folder_name',
        folder_folder_id: 'folder_id',
        rule_rule_name: 'rule_name',
        rule_ap_group: 'ap_group',
        rule_controller: 'controller',
        mode: 'mode',
        display_device_type: 'display_device_type',
        device_model: 'device_model',
        archived: 'archived',
        entitlement_id: 'entitlement_id',
        application_id: 'application_id',
        application_instance_id: 'application_instance_id',
        activate_customer_id: 'activate_customer_id',
        platform_customer_id: 'platform_customer_id',
        tenant_platform_customer_id: 'tenant_platform_customer_id',
        provisioning_image: 'provisioning_image',
        json_data: 'json_data',
        create_at: 'create_at',
        updated_at: 'updated_at'
      }

  const modeOptions = [
    { label: t('list_of_device_mode_option.branch'), value: 'BRANCH' },
    {
      label: t('list_of_device_mode_option.cloud_conductor'),
      value: 'CLOUD_CONTROLLER'
    },
    {
      label: t('list_of_device_mode_option.mobility_conductor'),
      value: 'MASTER_CONTROLLER'
    },
    {
      label: t('list_of_device_mode_option.managed_device'),
      value: 'MANAGED_DEVICE'
    },
    {
      label: t('list_of_device_mode_option.managed_device_vpnc'),
      value: 'MANAGED_DEVICE_VPNC'
    }
  ]

  useEffect(() => {
    const param = {
      limit: itemsPerPage,
      page,
      platform_customer_id: customerId
    }
    getFolderList({
      token: oidcUser.access_token,
      param,
      t,
      setErrorMessage: setErrorMsg,
      isCCSManager: true
    }).then((data) => {
      const foldersResponse = data?.folders
      if (foldersResponse) {
        setFolderListResponse((prevState) => [...prevState, ...foldersResponse])
        setTotalItems(data?.pagination?.total_count || itemsPerPage)
      }
    })
  }, [oidcUser.access_token, t, customerId, page])

  const formatValue = (key, value) => {
    const isDate = new Set(['create_at', 'updated_at'])
    if (isDate.has(key))
      return value ? dayjs(value).format('MMMM D, YYYY') : '--'
    if (typeof value === 'boolean') return value ? 'True' : 'False'
    return value
  }

  useEffect(() => {
    if (
      (deviceDetailsData.gts_status &&
        Object.values(deviceDetailsData).some((value) =>
          ['GT_UNLOCK', 'GT_LOCK'].includes(value)
        )) ||
      (!['GT_FIRMWARE_LOCK', 'GT_UNLOCK', 'GT_LOCK', null].includes(
        deviceDetailsData?.gts_status
      ) &&
        deviceDetailsData.gts_status)
    ) {
      setDisableOverrideToggle(true)
    }
  }, [deviceDetailsData])

  const filteredData = Object.keys(deviceDetailsData).length
    ? Object.entries(deviceDetailsKeys).reduce((prev, [key, value]) => {
        if (
          ['location_name', 'contact_name'].includes(key) &&
          !LDFlags['glcp-glasgow-sdi-ccs-manager']
        )
          return prev
        prev[value] = deviceDetailsData[key]
          ? formatValue(key, deviceDetailsData[key])
          : '--'
        return prev
      }, {})
    : {}

  const checkGTPermissions = (permission) => {
    let output = false
    const permissions = rbacPolicies?.effects['/ccs/activate/global-trade']
    if (
      permission === 'edit' &&
      permissions['ccs.activate.global-trade.edit'] === true
    ) {
      output = true
    }
    return output
  }

  const getGTStatusMessage = () => {
    let message = ''

    if (deviceDetailsData?.gts_status === 'GT_FIRMWARE_LOCK') {
      message = t('device:global_trade.gt_firmware_lock_status_msg')
    } else if (deviceDetailsData?.gts_status === 'GT_UNLOCK') {
      message = t('device:global_trade.gt_unlock_status_msg')
    } else if (deviceDetailsData?.gts_status === 'GT_LOCK') {
      message = t('device:global_trade.gt_lock_status_msg')
    } else if (
      !['GT_FIRMWARE_LOCK', 'GT_UNLOCK', 'GT_LOCK', null].includes(
        deviceDetailsData?.gts_status
      )
    ) {
      message = t('device:global_trade.gt_unknown_status_msg')
    }
    return (
      <Typography size="small" color="text-strong" type="text">
        {message}
      </Typography>
    )
  }

  return (
    <Box>
      <Box
        direction="row"
        justify="between"
        border={{ side: 'bottom', color: 'border-weak' }}
      >
        <Box margin={{ bottom: 'small' }}>
          <Typography level="3" type="heading" testId="device-details-title">
            {t('details')}
          </Typography>
        </Box>
      </Box>
      <Box alignSelf="start" justify="between" direction="column">
        {Object.entries(filteredData).map((datum) => (
          <Box flex direction="row" margin={{ top: 'small' }} key={datum[0]}>
            {(() => {
              if (
                datum[0] === t('device:global_trade.gt_firmware_status') ||
                datum[0] ===
                  t('device:global_trade.override_gt_firmware_status')
              ) {
                return (
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/activate/global-trade',
                      permission: 'ccs.activate.global-trade.view'
                    }}
                  >
                    <Typography
                      size="xsmall"
                      type="text"
                      testId={`${datum[0]}-key`}
                    >
                      {t(datum[0])}
                    </Typography>
                  </VisibilityWrapper>
                )
              }

              return (
                <Box width="small" align="start">
                  <Typography
                    size="xsmall"
                    type="text"
                    testId={`${datum[0]}-key`}
                  >
                    {t(datum[0])}
                  </Typography>
                </Box>
              )
            })()}
            <Box
              width="medium"
              align="start"
              margin={{ left: 'medium' }}
              pad={{ left: 'small' }}
            >
              {(() => {
                switch (datum[0]) {
                  case t('device:global_trade.override_gt_firmware_status'):
                    return (
                      <VisibilityWrapper
                        rbac={{
                          resource: '/ccs/activate/global-trade',
                          permission: 'ccs.activate.global-trade.view'
                        }}
                      >
                        <ToggleButton
                          margin={{
                            top: 'small',
                            bottom: 'small'
                          }}
                          pad={{ top: 'small', bottom: 'small' }}
                          checked={deviceDetailsData?.override_status}
                          onChange={(e) => {
                            setGTStatus(true)
                            setGTState(e.target.checked)
                          }}
                          testId={`${datum[0]}-toggle`}
                          disabled={
                            checkGTPermissions('edit')
                              ? disableOverrideToggle
                              : true
                          }
                        />
                        {gtStatus && (
                          <ToggleGTStatus
                            gtState={gtState}
                            setGTStatus={setGTStatus}
                            openSuccessModal={(newStatus) => {
                              deviceDetailsData.override_status = gtState
                              if (newStatus) {
                                setSuccessMessage(
                                  t(
                                    'device:global_trade.gt_enable_notification'
                                  )
                                )
                              } else {
                                setSuccessMessage(
                                  t(
                                    'device:global_trade.gt_disable_notification'
                                  )
                                )
                              }
                            }}
                            DeviceData={deviceDetailsData}
                          />
                        )}
                      </VisibilityWrapper>
                    )

                  case t('device:global_trade.gt_firmware_status'):
                    return (
                      <VisibilityWrapper
                        rbac={{
                          resource: '/ccs/activate/global-trade',
                          permission: 'ccs.activate.global-trade.view'
                        }}
                      >
                        <Box margin={{ left: 'small' }}>
                          <Typography
                            size="small"
                            color="text-strong"
                            type="text"
                            icon={
                              datum[1] === 'GT_UNLOCK' || datum[1] === '--' ? (
                                <StatusGoodSmall color="status-ok" />
                              ) : (
                                <StatusCriticalSmall color="status-critical" />
                              )
                            }
                          >
                            {deviceDetailsData?.gts_status !== null ? (
                              <Tooltip
                                dropProps={{
                                  align: { top: 'bottom', left: 'right' }
                                }}
                                content={getGTStatusMessage(datum)}
                                testId="default-tooltip"
                              >
                                {datum[1] === 'GT_UNLOCK' || datum[1] === '--'
                                  ? t('device:global_trade.clear')
                                  : t('device:global_trade.blocked')}
                              </Tooltip>
                            ) : (
                              <>
                                {datum[1] === 'GT_UNLOCK' || datum[1] === '--'
                                  ? t('device:global_trade.clear')
                                  : t('device:global_trade.blocked')}
                              </>
                            )}
                          </Typography>
                        </Box>
                      </VisibilityWrapper>
                    )

                  default:
                    return (
                      <Typography
                        size="medium"
                        color="text-strong"
                        testId={`${datum[0]}-value`}
                        type="text"
                      >
                        {datum[1] ? datum[1] : '--'}
                      </Typography>
                    )
                }
              })()}
            </Box>
          </Box>
        ))}
      </Box>
      {mode === 'edit' && (
        <Box alignSelf="start" justify="between" direction="column">
          <CCSForm
            errorMessage={errorMsg}
            validate="blur"
            onSubmit={() => {}}
            testId="editable-device-details-form"
            style={{ width: '100%' }}
          >
            <>
              <FormField
                data-testid="device-name-form-field"
                label={t('device_name')}
                name="device_name"
                margin={{ bottom: 'small' }}
              >
                <TextInput
                  data-testid="device-name-input"
                  name="device_name"
                  value={editableDeviceDetailsData.device_name}
                  onChange={(e) =>
                    setEditDeviceDetailsData({
                      ...editableDeviceDetailsData,
                      device_name: e.target.value
                    })
                  }
                />
              </FormField>

              <FormField
                data-testid="full-name-form-field"
                label={t('full_name')}
                margin={{ bottom: 'small' }}
              >
                <TextInput
                  data-testid="full-name-input"
                  name="full_name"
                  value={editableDeviceDetailsData.full_name}
                  onChange={(e) =>
                    setEditDeviceDetailsData({
                      ...editableDeviceDetailsData,
                      full_name: e.target.value
                    })
                  }
                />
              </FormField>

              <FormField
                data-testid="description-form-field"
                label={t('description')}
                name="description_name"
                margin={{ bottom: 'small' }}
              >
                <TextInput
                  data-testid="description-input"
                  name="description_name"
                  value={editableDeviceDetailsData.description}
                  onChange={(e) =>
                    setEditDeviceDetailsData({
                      ...editableDeviceDetailsData,
                      description: e.target.value
                    })
                  }
                />
              </FormField>

              <FormField
                data-testid="folder-name-form-field"
                label={t('folder_name')}
                name="folder_name"
                margin={{ bottom: 'small' }}
              >
                <Dropdown
                  testId="folder-name-input"
                  labelKey="folder_name"
                  valueKey={{ key: 'folder_id', reduce: true }}
                  name="folder_name"
                  options={folderListResponse}
                  value={editableDeviceDetailsData.folder_id}
                  onChange={({ option }) =>
                    setEditDeviceDetailsData({
                      ...editableDeviceDetailsData,
                      folder_name: option.folder_name,
                      folder_id: option.folder_id
                    })
                  }
                  size="small"
                  onMore={() => {
                    if (
                      totalItems > itemsPerPage &&
                      page < totalItems / itemsPerPage - 1
                    )
                      setPage(page + 1)
                  }}
                />
              </FormField>

              {deviceDetailsData.display_device_type !== 'AP' && (
                <FormField
                  data-testid="mode-form-field"
                  label={t('mode')}
                  name="mode"
                >
                  <Dropdown
                    testId="mode-dropdown"
                    name="mode"
                    options={modeOptions}
                    labelKey="label"
                    valueKey={{ key: 'value', reduce: true }}
                    value={
                      editableDeviceDetailsData.mode
                        ? editableDeviceDetailsData.mode
                        : ''
                    }
                    onChange={({ option }) =>
                      setEditDeviceDetailsData({
                        ...editableDeviceDetailsData,
                        mode: option.value
                      })
                    }
                  />
                </FormField>
              )}
            </>
          </CCSForm>
        </Box>
      )}
      {successMessage &&
        displayNotification(successMessage, 'info', setSuccessMessage)}
    </Box>
  )
}

ActivateDevicesDetailsSection.propTypes = {
  mode: PropTypes.string.isRequired,
  deviceDetailsData: PropTypes.object,
  editableDeviceDetailsData: PropTypes.object,
  setEditDeviceDetailsData: PropTypes.func.isRequired,
  customerId: PropTypes.string
}

export default React.memo(ActivateDevicesDetailsSection)
