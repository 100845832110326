// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useReactOidc } from '@axa-fr/react-oidc-context'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { get } from '../../utils/api-utils'
import { Notification } from '../../components'
import { displayApiError } from '../../utils/error-handling-utils'
import { getCustomerAccount } from '../../utils/feature-flag-utils'

/**
 * There is only one use case for this banner at the moment for COP:
 * 'Upgrade from 2.5.4 to 2.5.5 requires SAML and IDP to be re-configured for SSO users to login.'
 * This message will be cleared after the next release.
 */
const COPBannerNotification = () => {
  const [message, setMessage] = useState(null)
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['common'])
  const [apiError, setApiError] = useState(null)
  const [custAccountLoaded, setCustAccountLoaded] = useState({})

  if (
    JSON.stringify(custAccountLoaded) === '{}' ||
    custAccountLoaded?.platform_customer_id !==
      JSON.parse(sessionStorage.getItem('account'))?.platform_customer_id
  ) {
    setCustAccountLoaded(getCustomerAccount())
  }

  useEffect(() => {
    let isCurrent = true
    if (oidcUser && oidcUser?.access_token && custAccountLoaded !== null) {
      get(`/authn/v1/saml/show-notification`, {}, oidcUser?.access_token).then(
        (response) => {
          if (!isCurrent) return
          if (response?.data?.message) {
            setMessage(response?.data?.message)
          } else {
            setMessage(null)
          }
        },
        (error) => {
          setMessage(null)
          setApiError(error)
        }
      )
    }
    return () => {
      isCurrent = false
    }
  }, [oidcUser, custAccountLoaded])

  return (
    <>
      {apiError && displayApiError(apiError, t, setApiError)}
      {message && (
        <Notification
          type="inline"
          backgroundColor="status-warning"
          testId="cop-notification-banner"
          text={message}
        />
      )}
    </>
  )
}

export { COPBannerNotification }
