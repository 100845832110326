// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import {
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusWarningSmall
} from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { get } from '../../../../utils/api-utils'
import { retryPopUpRender } from '../../../device-management/utils'
import { Typography } from '../../../../components'
import { PopUpError } from '../../../device-management/device-map/components/PopUpError'
import { AssetPopupSkeletionLoader } from '../utils'

export const AggregateClusterData = ({
  boundingBoxTopLeftLat,
  boundingBoxTopLeftLong,
  boundingBoxBottomRightLat,
  boundingBoxBottomRightLong,
  childMarkers,
  token
}) => {
  const [clusterData, setClusterData] = useState(null)
  const [isGreen, setIsGreen] = useState(false)
  const [clusterCount, setClusterCount] = useState(null)
  const [popUpCase, setPopUpCase] = useState(null)
  const { t } = useTranslation(['manage'])
  const getPopUpData = useCallback(() => {
    const params = {
      boundingBoxTopLeftLat,
      boundingBoxTopLeftLong,
      boundingBoxBottomRightLat,
      boundingBoxBottomRightLong
    }

    get('/internal-assets/v1beta1/locations', params, token).then(
      (response) => {
        const clusterAPIData = response?.data?.items?.map(
          (asset) => asset?.assetStats
        )

        // riskAggregatedData has the below format
        // { red: { support: 0, age: 0 }, yellow: { support: 0, age: 0 } }

        const riskAggregatedData = clusterAPIData?.reduce(
          (accumulator, currentValue) => {
            const {
              riskSupportLevel,
              riskSupportAssetCount,
              riskAgeLevel,
              riskAgeAssetCount
            } = currentValue
            if (riskSupportLevel) {
              const key = { 3: 'red', 2: 'yellow' }[riskSupportLevel]
              if (accumulator[key]) {
                accumulator[key].support += riskSupportAssetCount
              } else {
                accumulator[key] = {
                  support: riskSupportAssetCount,
                  age: 0
                }
              }
            }
            if (riskAgeLevel) {
              const key = { 3: 'red', 2: 'yellow' }[riskAgeLevel]
              if (accumulator[key]) {
                accumulator[key].age += riskAgeAssetCount
              } else {
                accumulator[key] = {
                  support: 0,
                  age: riskAgeAssetCount
                }
              }
            }
            return accumulator
          },
          {}
        )

        // Set the cluster count inside the cluster popup
        setClusterCount(
          childMarkers.reduce(
            (count, marker) => count + marker?.options?.data?.assetStats?.total,
            0
          )
        )
        // If riskAggregatedData is empty, there are no risks hence isGreen is set to true
        if (Object?.keys(riskAggregatedData)?.length === 0) {
          setIsGreen(true)
        } else {
          setClusterData(riskAggregatedData)
          // console.log(riskAggregatedData)
        }
        setPopUpCase('CLUSTER_AGGREGATE_DATA')
      },
      (error) => {
        setClusterData([])
        setPopUpCase('CLUSTER_AGGREGATE_ERROR')
        console.log(error)
      }
    )
  }, [
    token,
    boundingBoxTopLeftLat,
    boundingBoxTopLeftLong,
    boundingBoxBottomRightLat,
    boundingBoxBottomRightLong,
    childMarkers
  ])

  useEffect(() => {
    getPopUpData()
  }, [getPopUpData])

  const popupRender = () => {
    switch (popUpCase) {
      case 'CLUSTER_AGGREGATE_ERROR':
        return (
          <PopUpError
            heading={t('asset_management.assets_map.risk_summary')}
            onAnchorClick={() =>
              retryPopUpRender(setClusterData, setPopUpCase, getPopUpData)
            }
          />
        )
      case 'CLUSTER_AGGREGATE_DATA':
        return (
          <Box data-testid="cluster-aggregate-summary">
            <Box border={{ side: 'bottom', color: 'border' }}>
              <Text
                margin={{ horizontal: 'xsmall', top: 'small' }}
                weight={500}
                size="large"
                data-testid="cluster-aggregate-heading"
              >
                {t('asset_management.assets_map.risk_summary')}
              </Text>
              <Text
                margin={{
                  horizontal: 'xsmall',
                  top: 'xsmall',
                  bottom: 'small'
                }}
                data-testid="cluster-aggregate-sub-heading"
              >
                {`${clusterCount} ${t(`asset_management.assets_map.assets`)}`}
              </Text>
            </Box>
            {isGreen ? (
              <Box
                margin={{ horizontal: 'xsmall', vertical: 'small' }}
                direction="row"
                align="center"
                basis="full"
              >
                <Box align="center" margin={{ right: 'xsmall' }}>
                  <StatusGoodSmall size="small" color="status-ok" />
                </Box>
                <Typography type="text" data-testid="cluster-aggregate-green">
                  {t('asset_management.assets_map.multiple.green')}
                </Typography>
              </Box>
            ) : (
              <Box
                margin={{ horizontal: 'xsmall', vertical: 'small' }}
                pad={{ top: 'xsmall' }}
                gap="xsmall"
                data-testid="cluster-aggregate-list"
              >
                {clusterData?.red?.support > 0 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusCriticalSmall
                        size="small"
                        color="status-critical"
                      />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-aggregate-red-support"
                    >
                      {`${clusterData?.red?.support} ${t(
                        `asset_management.assets_map.multiple.red_support`
                      )}`}
                    </Typography>
                  </Box>
                )}
                {clusterData?.red?.age > 0 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusCriticalSmall
                        size="small"
                        color="status-critical"
                      />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-aggregate-red-age"
                    >
                      {`${clusterData?.red?.age} ${t(
                        `asset_management.assets_map.multiple.red_age`
                      )}`}
                    </Typography>
                  </Box>
                )}
                {clusterData?.yellow?.support > 0 &&
                  !clusterData?.red?.support && (
                    <Box direction="row" align="start" basis="full">
                      <Box margin={{ top: 'xsmall', right: 'xsmall' }}>
                        <StatusWarningSmall
                          size="small"
                          color="status-warning"
                        />
                      </Box>
                      <Typography
                        type="text"
                        data-testid="cluster-aggregate-yellow-support"
                      >
                        {`${clusterData?.yellow?.support} ${t(
                          `asset_management.assets_map.multiple.yellow_support`
                        )}`}
                      </Typography>
                    </Box>
                  )}
                {clusterData?.yellow?.age > 0 && !clusterData?.red?.age && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusWarningSmall size="small" color="status-warning" />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-aggregate-yellow-age"
                    >
                      {`${clusterData?.yellow?.age} ${t(
                        `asset_management.assets_map.multiple.yellow_age`
                      )}`}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )
      default:
        return null
    }
  }

  return clusterData || isGreen ? popupRender() : <AssetPopupSkeletionLoader />
}

AggregateClusterData.propTypes = {
  boundingBoxTopLeftLat: PropTypes.number.isRequired,
  boundingBoxTopLeftLong: PropTypes.number.isRequired,
  boundingBoxBottomRightLat: PropTypes.number.isRequired,
  boundingBoxBottomRightLong: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  childMarkers: PropTypes.array.isRequired
}
