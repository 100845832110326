// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tag } from 'grommet'
import { useTranslation } from 'react-i18next'

import { DataTable, Typography } from '../../../../components'

export const PillItemAdderSubscription = ({
  addForm,
  selectedLicenseData = [],
  itemList = [],
  heading,
  onDelete,
  testId,
  tagsAssigned = ''
}) => {
  const { t } = useTranslation(['device', 'licensing', 'common'])
  const isDeviceSubscriptionOpen = window.location
    .toString()
    .includes('/devices/subscriptions')
  const columns = [
    {
      property: isDeviceSubscriptionOpen ? 'key' : 'subscription_key',
      type: 'string',
      header: t('subscription_key'),
      primary: true,
      render: (datum) => {
        return (
          <Box direction="row" align="center">
            <Typography
              type="text"
              wordBreak="break-all"
              testId="subscription-key"
            >
              {isDeviceSubscriptionOpen ? datum?.key : datum?.subscription_key}
            </Typography>
          </Box>
        )
      }
    },
    ...(!isDeviceSubscriptionOpen
      ? [
          {
            property: 'name',
            type: 'string',
            header: t('name'),
            primary: true,
            render: (datum) => {
              return (
                <Box direction="row" align="center">
                  <Typography
                    type="text"
                    wordBreak="break-all"
                    testId="subscription-key"
                  >
                    {datum?.subscription_tier_description}
                  </Typography>
                </Box>
              )
            }
          }
        ]
      : []),
    {
      property: isDeviceSubscriptionOpen ? 'tier_desc' : 'subscription_tier',
      type: 'string',
      header: t('tier'),
      primary: true,
      render: (datum) => {
        return (
          <Box direction="row" align="center">
            <Typography
              type="text"
              wordBreak="break-all"
              testId="subscription-key"
            >
              {isDeviceSubscriptionOpen
                ? datum?.tier_desc
                : datum?.subscription_tier}
            </Typography>
          </Box>
        )
      }
    }
  ]
  const tagsPill = itemList.map((element, idx) => {
    return (
      <Box
        margin="xsmall"
        gap="medium"
        alignSelf="start"
        data-testid={`assigned-tags-subscription-${idx}`}
        key={`pill-${idx}`} /* eslint-disable-line react/no-array-index-key */
      >
        <Tag
          testId={`${element?.name}-${element?.value}`}
          name={element?.name}
          value={element?.value}
          onRemove={() => {
            onDelete(element)
          }}
        />
      </Box>
    )
  })
  return (
    <Box data-testid={testId}>
      <Box
        margin={{ top: 'medium', bottom: 'small' }}
        border={{ side: 'bottom', color: 'border-weak' }}
      >
        <Typography level="2" type="heading" testId="selected-tags-header">
          {heading}
        </Typography>
      </Box>

      <Box margin={{ bottom: 'small' }}>
        {itemList?.length ? (
          <Box
            data-testid="assigned-tags"
            direction="row"
            wrap
            style={{ overflow: 'auto' }}
          >
            {tagsPill}
          </Box>
        ) : (
          <Box data-testid="no-tags-assigned-description">{tagsAssigned}</Box>
        )}
      </Box>
      <Box height={{ max: 'small' }} margin={{ bottom: 'small' }}>
        <DataTable
          testId="subscription-tag-details-table"
          grid={{
            columns,
            data: selectedLicenseData
          }}
        />
      </Box>
      {addForm}
    </Box>
  )
}

PillItemAdderSubscription.propTypes = {
  /**
   * Add Component of type AddForm
   */
  addForm: PropTypes.element.isRequired,

  /**
   * Pill data
   */
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string
    })
  ),

  /**
   * Heading string for section showing added items/pills
   */
  heading: PropTypes.string.isRequired,
  tagsAssigned: PropTypes.string,

  /**
   * Delete handler
   */
  onDelete: PropTypes.func.isRequired,

  /**
   * Test id
   */
  testId: PropTypes.string.isRequired,
  selectedLicenseData: PropTypes.array
}
