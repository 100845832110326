// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Layout } from '../../commoncomponents/layout/Layout'

import CustomerDetails from './components/CustomerDetails'
import CustomerAccount from './CustomerAccount'

const CustomerAccountRouterContent = () => {
  return (
    <Routes>
      <Route exact path="/:customerId" element={<CustomerDetails />} />

      {/* NOTE: Always add any routes above this route */}
      <Route path="/" element={<CustomerAccount />} />
    </Routes>
  )
}

const CustomerAccountRouter = () => {
  return (
    <Layout>
      <CustomerAccountRouterContent />
    </Layout>
  )
}

export default CustomerAccountRouter
