// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Anchor, Box } from 'grommet'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Typography } from '../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import { GTS_SUPPORT_URL } from '../../utils/error-utils'

function AccessError({ backToMSP = false }) {
  const { t } = useTranslation(['apps'])
  const navigate = useNavigate()
  return (
    <Box
      align="center"
      justify="center"
      alignContent="center"
      data-testid="no-access"
      pad="xlarge"
    >
      <Box width="large">
        <Typography
          type="text"
          size="xlarge"
          margin={{ bottom: 'medium' }}
          testId="first-no-access-subtitle"
        >
          {t('access_error')}
        </Typography>
        <Typography
          type="text"
          size="xlarge"
          testId="second-no-access-subtitle"
        >
          <Trans i18nKey="access_error_help" t={t}>
            <Anchor
              label={t('access_error_sub_link')}
              onClick={() => {
                window.open(GTS_SUPPORT_URL, '_blank')
              }}
              data-testid="support-anchor"
            />
          </Trans>
        </Typography>
        {backToMSP ? (
          <Button
            primary
            label={t('return_to_msp_account')}
            size="large"
            margin={{ vertical: 'small' }}
            onClick={() => {
              navigate('/home')
            }}
            testId="back-to-msp"
          />
        ) : (
          <Button
            primary
            label={t('back_to_sign_in')}
            size="large"
            margin={{ vertical: 'small' }}
            onClick={() => {
              navigate('/sign-out')
            }}
            testId="back-to-signin"
          />
        )}
      </Box>
    </Box>
  )
}

AccessError.propTypes = {
  backToMSP: PropTypes.bool
}

const AccessErrorPage = ({ backToMSP = false }) => {
  return (
    <Layout hideHeaderOptions={['no-header']}>
      <AccessError backToMSP={backToMSP} />
    </Layout>
  )
}

AccessErrorPage.propTypes = {
  backToMSP: PropTypes.bool
}

export default AccessErrorPage
