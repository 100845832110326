// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes, Navigate } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'
import { Box } from 'grommet'

import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const OverviewPage = lazy(() => import('./pages/overview/Overview'))
const CustomersPage = lazy(() => import('./pages/customers/Customers'))
const OrdersPage = lazy(() => import('./pages/orders/Orders'))
const UsersPage = lazy(() => import('./pages/users/Users'))
const SubscriptionsPage = lazy(() =>
  import('./pages/subscriptions/Subscriptions')
)
const DevicesPage = lazy(() => import('./pages/devices/Devices'))
const FirmWarePage = lazy(() => import('./pages/firmware/FirmWare'))
const NotificationPage = lazy(() =>
  import('./pages/notifications/Notifications')
)
const ApplicationsPage = lazy(() =>
  import('./pages/applications/ApplicationsPage')
)
const WhatsNewPage = lazy(() => import('./pages/whats-new/WhatsNew'))

const ManageCcsRouter = () => {
  return (
    <Suspense
      fallback={
        <Box width="100%">
          <LazyLoading />
        </Box>
      }
    >
      <Routes>
        <Route exact path="/overview" element={<OverviewPage />} />
        <Route
          exact
          path="/customers"
          element={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/accounts/platform/customer',
                permission: 'ccs.accounts.platform.customer.view'
              }}
            >
              <CustomersPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/orders"
          element={
            <VisibilityWrapper
              rbac={{
                permission: 'ccs.activate.view',
                resource: '/ccs/activate/tac'
              }}
            >
              <OrdersPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/users"
          element={
            <VisibilityWrapper
              rbac={{
                permission: 'ccs.accounts.users.view.all',
                resource: '/ccs/accounts/user'
              }}
            >
              <UsersPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/subscriptions/*"
          element={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/device-management',
                permission: 'ccs.device-management.view'
              }}
            >
              <SubscriptionsPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/devices"
          element={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/device-management',
                permission: 'ccs.device-management.view'
              }}
            >
              <DevicesPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/firmware"
          element={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/activate/firmware',
                permission: 'ccs.activate.firmware.view'
              }}
            >
              <FirmWarePage />
            </VisibilityWrapper>
          }
        />
        <Route exact path="/notifications" element={<NotificationPage />} />
        <Route
          exact
          path="/whats-new"
          element={
            <VisibilityWrapper
              rbac={{
                permission: 'ccs.whats-new.view',
                resource: '/ccs/whats-new'
              }}
            >
              <WhatsNewPage />
            </VisibilityWrapper>
          }
        />
        <Route
          exact
          path="/applications"
          element={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/app-catalog/application',
                permission: 'ccs.app-catalog.view'
              }}
            >
              <ApplicationsPage />
            </VisibilityWrapper>
          }
        />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default ManageCcsRouter
