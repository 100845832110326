// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  useResolvedPath,
  Navigate
} from 'react-router-dom'
import { React, Suspense, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from 'grommet'

import { useVisibilityContext } from '../../context/visibility-context'
import { Loader } from '../../components'
import { checkGotoChooseAccount } from '../../utils/common-utils'
import {
  isGLOP,
  isInventoryOwnedWorkspace
} from '../../utils/feature-flag-utils'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'
import { isServiceCentric } from '../../utils/account-utils'

import InventoryDetailsRouter from './inventory-details-router'
import SubscriptionRouter from './subscription-router'

const DeviceManagementPage = lazy(() => import('./DeviceManagement'))

const DeviceManagementRouter = () => {
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const path = useResolvedPath('').pathname
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)
  const { t } = useTranslation(['common'])
  const { rbacPolicies } = useVisibilityContext()
  useEffect(() => {
    if (path === '/manage-account') {
      if (pathname.includes('/manage-account/device-management/devices')) {
        const uiPath = pathname.replace(
          '/manage-account/device-management/devices',
          '/devices/inventory'
        )
        navigate(uiPath)
      } else {
        const uiPath = pathname.replace(
          '/manage-account/device-management',
          '/devices'
        )
        navigate(uiPath)
      }
    }
  }, [path, pathname, navigate])

  return (
    <>
      {rbacPolicies ? (
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/device-management',
            permission: 'ccs.device-management.view'
          }}
          fallbackComponent={
            <Navigate
              to="/home"
              state={{ errorMessage: t('permission_denied') }}
            />
          }
        >
          {isInventoryOwnedWorkspace() ? (
            <Suspense fallback={<LazyLoading />}>
              <Routes>
                <Route
                  path="/inventory/*"
                  element={<InventoryDetailsRouter />}
                />
                {(isGLOP() || newDashboardFlag) && (
                  <Route
                    path="/subscriptions/*"
                    element={<SubscriptionRouter />}
                  />
                )}
                <Route path="/*" element={<DeviceManagementPage />} />
              </Routes>
            </Suspense>
          ) : (
            navigate('/home', {
              state: { errorMessage: t('permission_denied') }
            })
          )}
        </VisibilityWrapper>
      ) : (
        <Box
          direction="row"
          align="center"
          justify="center"
          margin={{ top: 'large' }}
        >
          <Loader
            orientation="horizontal"
            label={t('common:loading')}
            testId="rbac-loader"
          />
        </Box>
      )}
    </>
  )
}

export default DeviceManagementRouter
