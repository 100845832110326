// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, CheckBox, SelectMultiple } from 'grommet'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const MultiSelectBox = ({ testId, ...rest }) => {
  const { defaultValue, labelKey, placeholder, children } = rest
  const [valueMultiple, setValueMultiple] = useState(defaultValue || [])

  /**
   * function to display component with the displayedText
   *
   * @param {object} selected Array of selected options
   * @returns component with displayedText based on the number
   * of items selected
   */
  const renderValueLabel = (selected) => {
    let displayedText = null
    if (selected?.length === 1) {
      displayedText = selected[0][labelKey]
    } else if (selected?.length > 1) {
      displayedText = `${selected?.length} items`
    } else if (!selected?.length && placeholder) {
      displayedText = placeholder
    }

    return displayedText ? (
      <Box pad="xsmall" data-testid="displayed-placeholder">
        {displayedText}
      </Box>
    ) : null
  }

  const renderOption = (option, index, options, state) => {
    return (
      <Box
        style={{ color: state.disabled ? '#bbb' : null }}
        direction="row-responsive"
        align="start"
        gap="xsmall"
        pad={{ vertical: 'xsmall' }}
      >
        <CheckBox disabled={state.disabled} checked={state.selected} />
        {children(option, index, options, state)}
      </Box>
    )
  }

  return (
    <Box fill align="left" data-testid={testId}>
      <SelectMultiple
        {...rest}
        valueLabel={renderValueLabel}
        value={valueMultiple}
        onChange={({ value }) => {
          setValueMultiple(value)
          if (rest.onChange) rest.onChange(value)
        }}
      >
        {children ? renderOption : null}
      </SelectMultiple>
    </Box>
  )
}

MultiSelectBox.propTypes = {
  /**
   * Test id for the component
   */
  testId: PropTypes.string.isRequired
}
