// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import isUndefined from 'lodash/isUndefined'
import { Box } from 'grommet'

import { Typography } from '../../../../components/typography/Typography'
import { get, getErrorMessage, post, put } from '../../../../utils/api-utils'
import {
  getCategoryValue,
  getEnumValueState,
  getEnumValueStatus
} from '../../../../commoncomponents/notifications-dashboard/utils'
import { paginateNext, errorResponseHandling } from '../utils'

export const fetchNotificationCount = (
  oidcUser,
  setCount,
  stateV,
  dispatchNotifications
) => {
  post(
    '/notifications-svc/ui/v1alpha1/notification-counter',
    {},
    oidcUser.access_token,
    false
  ).then(
    (response) => {
      const countData = response.data
      setCount({
        INFORMATION: countData.INFORMATION || 0,
        WARNING: countData.WARNING || 0,
        CRITICAL: countData.CRITICAL || 0
      })
      dispatchNotifications({
        type: 'COUNT_INFO',
        countInfo: {
          ...stateV.countInfo,
          totalCount: countData.TOTAL_COUNT || null
        }
      })
    },
    (error) => {
      console.log(error)
      setCount({
        CRITICAL: '-',
        WARNING: '-',
        INFORMATION: '-'
      })
      dispatchNotifications({
        type: 'COUNT_INFO',
        countInfo: {
          ...stateV.countInfo,
          totalCount: null
        }
      })
    }
  )
}

export const fetchCategories = (oidcUser, dispatchNotifications) => {
  get(
    `/notifications-svc/ui/v1alpha1/categories`,
    {},
    oidcUser.access_token
  ).then(
    (response) => {
      const appList = []
      const renderList = []
      const categoryData = response.data
      // service to Topics mapping
      Object.entries(categoryData).forEach((service) => {
        // Sub-Service to Topics mapping
        // keyVal[0] => Services
        // keyVal[1] => SubServices
        Object.entries(service[1]).forEach((sub_serv) => {
          // ss[0] => Sub-Services
          // ss[1] => Topics
          let loc_service = 'Default'
          Object.entries(sub_serv[1]).forEach((topic) => {
            loc_service = sub_serv[0] === 'Default' ? service[0] : sub_serv[0]
            appList.push([loc_service, topic[0]])
          })
        })
      })
      appList.sort((first, second) => {
        return first[0] < second[0] ? -1 : 1
      })
      appList.forEach((lists) => {
        const app = {
          valueLabel: (
            <Box>
              <Typography type="text" emphasis>
                {lists[1]}
              </Typography>{' '}
              <Typography type="text">{lists[0]}</Typography>
            </Box>
          ),
          valueName: lists[0].concat('~', lists[1]),
          searchableLabels: lists
        }
        renderList.push(app)
      })
      dispatchNotifications({
        type: 'CATEGORIES',
        categories: renderList
      })
    },
    (error) => {
      dispatchNotifications({
        type: 'CATEGORIES',
        categories: []
      })
      console.log(error)
    }
  )
}

// Common for both Alerts as well as Tasks
const fetchNotificationDetails = (
  setNotificationDetails,
  oidcUser,
  setReadStatus,
  setAlertState,
  setErrorMessage,
  setLoader,
  url,
  t
) => {
  setLoader(true)
  get(url, {}, oidcUser.access_token).then(
    (response) => {
      setNotificationDetails(response?.data)
      setReadStatus(response?.data?.isRead)
      setAlertState(response?.data?.alertState) // undefined incase of tasks Condition to be checked
      setErrorMessage(null)
      setLoader(false)
    },
    (error) => {
      setLoader(true)
      setNotificationDetails([])
      setErrorMessage(getErrorMessage(error, t))
    }
  )
}

const fetchFilteredNotifications_advanceFilter = ({
  nextPageURI,
  currentPage,
  stateV,
  dispatchNotifications,
  itemsPerPage,
  oidcUser,
  setErrorMessage,
  t
}) => {
  const sortBy = 'updatedAt'
  dispatchNotifications({
    type: 'NOTIFICATION_EMPTY',
    notificationEmpty: false
  })
  dispatchNotifications({
    type: 'HIDE_PAGINATION',
    hidePagination: true
  })
  dispatchNotifications({
    type: 'PAGE_DATA',
    pageData: []
  })
  dispatchNotifications({
    type: 'LOADING_WITH_FILTER',
    loadingWithFilter: true
  })
  const params = {
    status: getEnumValueStatus(stateV.filterValues.Status),
    state: getEnumValueState(stateV.filterValues.State),
    category: getCategoryValue(stateV.filterValues.Categories),
    fromDate: stateV.timeFrame[0],
    toDate: stateV.timeFrame[1],
    sortDirection: stateV.sortDirection,
    read: stateV.read,
    ...(stateV.searchConfirm.trim().length && {
      search: stateV.searchConfirm.trim()
    })
  }
  if (isUndefined(nextPageURI)) {
    nextPageURI = ''
  }

  function getRequestBodyAnnouncements() {
    const filters = {
      search: params.search,
      state: params.state,
      status: params.status,
      category: params.category,
      fromDate: params.fromDate,
      toDate: params.toDate,
      limit: itemsPerPage,
      sort: sortBy,
      sort_direction: params.sortDirection,
      next: `${nextPageURI?.replace('&next=', '')}`,
      channel: [`PORTAL`]
    }
    if (params.read === true) {
      filters.read = false
    }
    return filters
  }

  post(
    `/notifications-svc/ui/v2/get-notifications`,
    getRequestBodyAnnouncements(),
    oidcUser.access_token,
    false
  ).then(
    (response) => {
      paginateNext(
        response,
        nextPageURI,
        currentPage,
        dispatchNotifications,
        stateV
      )
      // response without filters
      dispatchNotifications({
        type: 'COUNT_INFO',
        countInfo: {
          ...stateV.countInfo,
          totalHits: response?.data?.totalHits
        }
      })
    },
    (error) => {
      errorResponseHandling(error, dispatchNotifications, setErrorMessage, t)
    }
  )
}

const fetchNotifications = ({
  nextPageURI,
  currentPage,
  stateV,
  dispatchNotifications,
  itemsPerPage,
  oidcUser,
  setErrorMessage,
  t,
  setNotificationsExists,
  setIsLoader
}) => {
  dispatchNotifications({
    type: 'HIDE_PAGINATION',
    hidePagination: true
  })
  dispatchNotifications({
    type: 'LOADING_WITHOUT_FILTER',
    loadingWithOutFilter: true
  })
  dispatchNotifications({
    type: 'NOTIFICATION_EMPTY',
    notificationEmpty: false
  })
  dispatchNotifications({
    type: 'PAGE_DATA',
    pageData: []
  })
  if (isUndefined(nextPageURI)) {
    nextPageURI = ''
  }
  const isFirstPage = nextPageURI === '' ? 1 : 0
  const sortBy = 'updatedAt'
  dispatchNotifications({
    type: 'IS_LOADING',
    isLoading: false
  })
  get(
    `/notifications-svc/ui/v1alpha1/notifications?channel=PORTAL&sort=${sortBy}&limit=${itemsPerPage}${nextPageURI}`,
    '',
    oidcUser.access_token
  ).then(
    (response) => {
      if (response?.data?.count === 0) {
        // key place to check for day zero scenario and responsible in setting the loader
        setNotificationsExists(false)
      } else {
        setNotificationsExists(true)
      }
      paginateNext(
        response,
        nextPageURI,
        currentPage,
        dispatchNotifications,
        stateV
      )

      // update the last seen notification id to the backend
      if (response?.data?.count && isFirstPage) {
        const notificationData = response?.data?.items
        const lastSeenID = notificationData[0].id
        const notiUpdatedAt = notificationData[0].updatedAt
        let params = {}
        params = {
          notification_id: lastSeenID,
          updated_at: notiUpdatedAt
        }
        put(
          `/notifications-svc/ui/v1alpha1/last-seen`,
          params,
          oidcUser.access_token
        ).then(
          () => {},
          () => {}
        )
      }
    },
    (error) => {
      setIsLoader(false)
      errorResponseHandling(error, dispatchNotifications, setErrorMessage, t)
    }
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
}

export {
  fetchFilteredNotifications_advanceFilter,
  fetchNotificationDetails,
  fetchNotifications
}
