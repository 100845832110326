// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, CheckBox, FormField, Markdown } from 'grommet'
import isArray from 'lodash/isArray'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import { get } from '../../utils/api-utils'
import FormInput from '../form-input/FormInput'
import { Typography } from '../typography/Typography'
import { Dropdown } from '../dropdown/Dropdown'
import { Anchor } from '../anchor/Anchor'

const showErrorMessage = (str1, str2) => {
  if (str1 === 'emailaddress' && str2 === 'email') {
    return str1.includes(str2)
  }
  return str2?.includes(str1)
}

const FormSelectField = ({
  t,
  obj,
  value,
  setValues,
  error = null,
  setSubmitDisabled
}) => {
  const translatedOptions = obj.allowedValues.map((option) => {
    return {
      name:
        obj.name !== 'serverFamily' && option.i18nKey
          ? t(option.i18nKey)
          : option.name,
      code: option.code
    }
  })
  if (obj.allowedValues.length > 1) {
    return (
      <FormField
        name={obj.name}
        label={i18next.exists(obj.i18nKey) ? t(obj.i18nKey) : obj.displayName}
        help={obj.description}
        width="100%"
        key={obj.name}
        required={obj.mandatory || false}
        error={error}
      >
        <Dropdown
          testId={`${obj.name}`}
          defaultVal={value || ''}
          placeholder={
            i18next.exists('common:select_option')
              ? t('common:select_option', {
                  optionName: t(obj.i18nKey)
                })
              : `Select ${obj.displayName}`
          }
          options={translatedOptions}
          labelKey="name"
          valueKey="code"
          onChangeDropdown={(option) => {
            setValues(obj.name, option.code)
            setSubmitDisabled(false)
          }}
          noBorder
        />
      </FormField>
    )
  }
  return (
    <FormField
      name={obj.name}
      label={i18next.exists(obj.i18nKey) ? t(obj.i18nKey) : obj.displayName}
      help={obj.description}
      width="100%"
      key={obj.name}
      required={obj.mandatory || false}
      error={error}
    >
      <Box width="100%" pad="xsmall" background="light-2" round="xsmall">
        <Typography
          size="medium"
          type="text"
          testId={`${obj.name}-default-value`}
        >
          {obj.allowedValues[0].name}
        </Typography>
      </Box>
    </FormField>
  )
}

FormSelectField.propTypes = {
  t: PropTypes.func.isRequired,
  obj: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setSubmitDisabled: PropTypes.func.isRequired
}

const FormSelectCountryField = ({
  t,
  obj,
  value,
  setValues,
  error = null,
  setSubmitDisabled
}) => {
  const [listOfCountries, setlistOfCountries] = useState([])
  const [countries, setCountries] = useState([])
  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then((response) => {
      setlistOfCountries(response.data.countries)
      setCountries(response.data.countries)
    })
  }, [])
  return (
    <FormField
      name={obj.name}
      label={i18next.exists(obj.i18nKey) ? t(obj.i18nKey) : obj.displayName}
      width="100%"
      key={obj.name}
      required={obj.mandatory || false}
      error={error}
    >
      <Dropdown
        testId={`${obj.name}`}
        defaultVal={value || ''}
        placeholder={
          i18next.exists('common:select_option')
            ? t('common:select_option', {
                optionName: t(obj.i18nKey)
              })
            : `Select ${obj.displayName}`
        }
        options={countries}
        labelKey="name"
        valueKey="code"
        searchPlaceholder={t('authn:customer_account.country')}
        emptySearchMessage={t('dashboard:eval.no_country_error')}
        onSearch={(searchText) => {
          const regexp = new RegExp(searchText, 'i')
          setCountries(listOfCountries.filter((o) => o.name.match(regexp)))
        }}
        onClose={() => setCountries(listOfCountries)}
        onChangeDropdown={(option) => {
          setValues(obj.name, option.code)
          setSubmitDisabled(false)
        }}
        noBorder
      />
    </FormField>
  )
}

FormSelectCountryField.propTypes = {
  t: PropTypes.func.isRequired,
  obj: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setSubmitDisabled: PropTypes.func.isRequired
}

const FormTextInputField = ({
  t,
  obj,
  value,
  setValues,
  error = null,
  setSubmitDisabled
}) => {
  return (
    <Box width="100%" key={obj.name}>
      <FormInput
        name={obj.name}
        validate={(val) => {
          if (val && obj?.validation?.regex) {
            const isValid = new RegExp(obj?.validation?.regex).test(val)
            if (!isValid) {
              return i18next.exists('common:invalid_value')
                ? t('common:invalid_value', { name: t(obj.i18nKey) })
                : `Invalid ${obj.displayName}`
            }
          }
          return undefined
        }}
        label={i18next.exists(obj.i18nKey) ? t(obj.i18nKey) : obj.displayName}
        labelHelper={obj.description}
        inputType="text"
        testId={`${obj.name}`}
        type={obj.type}
        value={value || ''}
        placeholder={
          i18next.exists('common:enter_value')
            ? t('common:enter_value', { valueName: t(obj.i18nKey) })
            : `Enter ${obj.displayName}`
        }
        onChange={(event) => {
          setValues(obj.name, event.target.value)
          setSubmitDisabled(false)
        }}
        required={obj.mandatory || false}
        error={
          showErrorMessage(
            obj?.name.toLowerCase(),
            error?.field?.toLowerCase().split('.').at(1)
          ) && error?.message
        }
        disabled={obj?.editable === false}
      />
    </Box>
  )
}

FormTextInputField.propTypes = {
  t: PropTypes.func.isRequired,
  obj: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setSubmitDisabled: PropTypes.func.isRequired
}

const FormNumberInputField = ({
  t,
  obj,
  value,
  setValues,
  error = null,
  setSubmitDisabled
}) => {
  return (
    <Box width="100%" key={obj.name}>
      <FormInput
        label={i18next.exists(obj.i18nKey) ? t(obj.i18nKey) : obj.displayName}
        labelHelper={obj.description}
        inputType="text"
        testId={`${obj.name}`}
        type="Number"
        min={1}
        value={value || ''}
        placeholder={t('common:enter_value', { valueName: t(obj.i18nKey) })}
        onChange={(event) => {
          setValues(obj.name, Number(event.target.value))
          setSubmitDisabled(false)
        }}
        required={obj.mandatory || false}
        error={
          showErrorMessage(
            obj?.name.toLowerCase(),
            error?.field?.toLowerCase().split('.').at(1)
          ) && error?.message
        }
        disabled={obj?.editable === false}
      />
    </Box>
  )
}

FormNumberInputField.propTypes = {
  t: PropTypes.func.isRequired,
  obj: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  setValues: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setSubmitDisabled: PropTypes.func.isRequired
}

const FormCheckboxGroupField = ({ t, obj, value, setValues, error = null }) => {
  const translatedOptions = obj.allowedValues.map((option) => {
    return {
      name: option.i18nKey ? t(option.i18nKey) : option.name,
      code: option.code
    }
  })
  return (
    <Box width="100%" key={obj.name}>
      <FormInput
        label={i18next.exists(obj.i18nKey) ? t(obj.i18nKey) : obj.displayName}
        labelHelper={obj.description}
        inputType="checkbox"
        labelKey="name"
        valueKey="code"
        options={translatedOptions}
        testId={`${obj.name}`}
        value={value || ''}
        onChange={({ value: val }) => {
          setValues(obj.name, val)
        }}
        error={error}
      />
    </Box>
  )
}

FormCheckboxGroupField.propTypes = {
  t: PropTypes.func.isRequired,
  obj: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

const FormCheckboxField = ({ t, obj, value, setValues, error = null }) => {
  return (
    <Box width="100%" key={obj.name}>
      <FormField name={obj.name} required help={obj.description} error={error}>
        <CheckBox
          data-testid={`${obj.name}`}
          label={
            <Markdown
              components={{
                a: {
                  component: Anchor,
                  props: {
                    target: '_blank',
                    testId: `${obj.name}-option-link`,
                    label: ''
                  }
                }
              }}
            >
              {i18next.exists(obj.i18nKey) ? t(obj.i18nKey) : obj.displayName}
            </Markdown>
          }
          value={value || false}
          onChange={(event) => {
            setValues(obj.name, event.target.checked)
          }}
        />
      </FormField>
    </Box>
  )
}

FormCheckboxField.propTypes = {
  t: PropTypes.func.isRequired,
  obj: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
  setValues: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

const GroupHeader = ({ t, obj }) => {
  const heading = obj?.heading
  const subtitle = obj?.subtitle
  return (
    <Box margin={{ top: 'small' }}>
      <Typography type="heading" size="small">
        {i18next.exists(heading?.i18nKey)
          ? t(heading?.i18nKey)
          : heading.displayName}
      </Typography>
      {subtitle && (
        <Typography type="text" size="small">
          {i18next.exists(subtitle?.i18nKey)
            ? t(subtitle?.i18nKey)
            : subtitle.displayName}
        </Typography>
      )}
    </Box>
  )
}

GroupHeader.propTypes = {
  t: PropTypes.func.isRequired,
  obj: PropTypes.object.isRequired
}

const getFormFields = (
  t,
  schema,
  values,
  setValues,
  apiErrors,
  setSubmitDisabled
) => {
  const components = []
  if (!isArray(schema)) {
    console.error('Invalid schema passed')
    return components
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of schema) {
    // only fields with userVisibility=true falg will be rendered
    if (obj.userVisibility) {
      switch (obj.type) {
        case 'preselectValue':
          // Select menu input
          if (obj.allowedValues) {
            components.push(
              <FormSelectField
                key={obj.name}
                t={t}
                obj={obj}
                value={values[obj?.name]}
                setValues={setValues}
                error={apiErrors[obj?.name]}
                setSubmitDisabled={setSubmitDisabled}
              />
            )
            break
          }

          // Text field input
          components.push(
            <FormTextInputField
              key={obj.name}
              t={t}
              obj={obj}
              value={values[obj?.name]}
              setValues={setValues}
              error={apiErrors[obj?.name]}
            />
          )
          break

        case 'integer':
          components.push(
            <FormNumberInputField
              key={obj.name}
              obj={obj}
              value={values[obj?.name]}
              setValues={setValues}
              error={apiErrors}
              setSubmitDisabled={setSubmitDisabled}
            />
          )
          break

        case 'string':
          components.push(
            <FormTextInputField
              key={obj.name}
              t={t}
              obj={obj}
              value={values[obj?.name]}
              setValues={setValues}
              error={apiErrors}
              setSubmitDisabled={setSubmitDisabled}
            />
          )
          break

        case 'checkboxGroup':
          components.push(
            <FormCheckboxGroupField
              key={obj.name}
              t={t}
              obj={obj}
              value={values[obj?.name]}
              setValues={setValues}
              error={apiErrors[obj?.name]}
            />
          )
          break

        case 'boolean':
          components.push(
            <FormCheckboxField
              key={obj.name}
              t={t}
              obj={obj}
              value={values[obj?.name]}
              setValues={setValues}
              error={apiErrors[obj?.name]}
            />
          )
          break

        case 'group-header':
          components.push(<GroupHeader key={obj.name} t={t} obj={obj} />)
          break

        case 'country':
          components.push(
            <FormSelectCountryField
              key={obj.name}
              t={t}
              obj={obj}
              value={values[obj?.name]}
              setValues={setValues}
              error={apiErrors[obj?.name]}
              setSubmitDisabled={setSubmitDisabled}
            />
          )
          break

        default:
          console.error(`Unsupported field type ${obj.type}`)
      }
    }
  }
  return components
}

export default getFormFields
