// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes, Navigate } from 'react-router-dom'
import React from 'react'

import WhatsNewCustomerPage from './WhatsNew'

const WhatsNewRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<WhatsNewCustomerPage />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export default WhatsNewRouter
