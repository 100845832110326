// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Page, PageContent, PageHeader, Box, Markdown } from 'grommet'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Previous, CircleInformation } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Loader, Typography, Button, Anchor } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { displayApiError } from '../../../utils/error-handling-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { get } from '../../../utils/api-utils'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import CreateAndEditEventWebhookSidePanel from './CreateAndEditEventWebhookSidePanel'
import { WebhookDataTable } from './WebhookDataTable'

const WebhooksPage = () => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['automations'])
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [eventWebhookOpen, setEventWebhookOpen] = useState(false)
  const [successTitle, setSuccessTitle] = useState(null)
  const [message, setMessage] = useState(null)
  const [toastType, setToastType] = useState('info')
  const [showNotification, setShowNotification] = useState(null)
  const [webhookData, setWebhookData] = useState([])
  const isPermission = true

  const fetchData = useCallback(() => {
    setLoading(true)
    get(
      `/events/v1beta1/webhooks`,
      {
        limit: 5,
        offset: 0
      },
      oidcUser.access_token,
      false,
      {}
    ).then(
      (response) => {
        setWebhookData(response?.data?.items ?? [])
        setLoading(false)
      },
      (error) => {
        setShowNotification(displayApiError(error, t))
        setLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, oidcUser])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (location.state) {
      const { notificationDesc } = location.state
      setMessage(<Markdown>{notificationDesc}</Markdown>)
      setToastType('info')
      navigate(location.pathname, { replace: true })
    }
  }, [location, navigate])

  const handleBackBtnClick = () => {
    navigate('/manage-account/automations')
  }

  const webhookCreateSuccess = ({ title, notificationMessage, severity }) => {
    fetchData()
    setSuccessTitle(title)
    setMessage(notificationMessage)
    setToastType(severity)
  }

  return (
    <Box width="xxlarge" margin="auto">
      <Page data-testid="roles-card">
        <PageContent>
          <PageHeader
            responsive
            parent={
              <Anchor
                label={`${t('automations:webhooks.previous_page_title')}`}
                icon={<Previous />}
                onClick={handleBackBtnClick}
                data-testid="identity-back-btn"
              />
            }
            pad={{ top: 'medium' }}
            title={
              <Typography level="1" type="heading" testId="page-title">
                {t('automations:webhooks.card_title')}
              </Typography>
            }
          />
          {loading ? (
            <Loader testId="webhook-groups-loader" />
          ) : (
            <Box color="text" style={{ fontSize: 20, marginTop: 20 }}>
              {isPermission ? (
                <>
                  <Box>{t('automations:webhooks.card_register_webhook')}</Box>
                  <Box style={{ flexDirection: 'row' }}>
                    {t('automations:webhooks.learn_webhook')}
                    <PageHeader
                      responsive
                      style={{ paddingTop: '0', width: 'auto' }}
                      parent={
                        <Anchor
                          href="https://developer.greenlake.hpe.com/docs/greenlake/standards/draft/event/event_format/"
                          target="_blank"
                          rel="noreferrer"
                          color="brand"
                          weight="bold"
                          style={{
                            marginLeft: 5,
                            textDecoration: 'none'
                          }}
                        >
                          {t('automations:webhooks.greenlake_events')}
                        </Anchor>
                      }
                    />
                  </Box>
                </>
              ) : null}

              {!isPermission ? (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 40
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <CircleInformation size="medium" />
                    <Box
                      style={{
                        fontSize: 20,
                        fontWeight: '500',
                        color: 'dark-1',
                        marginTop: 10
                      }}
                      data-testid="no-webhook-registered"
                    >
                      {t('automations:webhooks.no_permission')}
                    </Box>
                    <Typography
                      type="paragraph"
                      style={{ textAlign: 'center', fontSize: 18 }}
                    >
                      {t('automations:webhooks.no_permission_desc')}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  {!webhookData.length ? (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 40
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <CircleInformation size="medium" />
                        <Box
                          style={{
                            fontSize: 20,
                            fontWeight: '500',
                            color: 'dark-1',
                            marginTop: 10,
                            marginBottom: 10
                          }}
                          data-testid="no-webhook-registered"
                        >
                          {t('automations:webhooks.no_webhooks_registered')}
                        </Box>
                        <VisibilityWrapper
                          rbac={{
                            resource: '/ccs/events/webhook',
                            permission: 'ccs.events.webhook.create'
                          }}
                        >
                          <Button
                            onClick={() => setEventWebhookOpen(true)}
                            primary
                            label={t(
                              'automations:webhooks.create_event_panel_title'
                            )}
                            testId="create-webhook"
                          />
                        </VisibilityWrapper>
                      </Box>
                    </Box>
                  ) : (
                    <WebhookDataTable
                      data={webhookData}
                      onOpenDrawerHandler={setEventWebhookOpen}
                    />
                  )}
                </>
              )}
            </Box>
          )}
          {showNotification}
          {eventWebhookOpen && (
            <CreateAndEditEventWebhookSidePanel
              isCreateWebhookSidePanel
              onSetOpen={setEventWebhookOpen}
              onToastNotification={webhookCreateSuccess}
              setLoading={setLoading}
            />
          )}

          {message &&
            displayNotification(message, toastType, setMessage, successTitle)}
        </PageContent>
      </Page>
    </Box>
  )
}

const Events = () => {
  return (
    <Layout>
      <WebhooksPage />
    </Layout>
  )
}

export default Events
