// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { StatusCritical } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography, NoDataInfo, ButtonGroup } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { isServiceCentric } from '../../../utils/account-utils'

const InvalidLinkPage = () => {
  const { t } = useTranslation(['licensing', 'common'])
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)

  const goToServiceSubscription = () => {
    const serviceSubscriptionRoute = newDashboardFlag
      ? '/services/service-subscriptions'
      : '/manage-account/subscriptions/service-subscriptions'
    navigate(serviceSubscriptionRoute)
  }

  const goToDeviceSubscription = () => {
    const deviceSubscriptionRoute = newDashboardFlag
      ? '/devices/subscriptions'
      : '/manage-account/subscriptions/device-subscriptions'
    navigate(deviceSubscriptionRoute)
  }
  return (
    <Box align="center">
      <Box width="large" pad="large">
        <NoDataInfo
          icon={<StatusCritical color="status-critical" size="xxlarge" />}
          title={
            <Typography type="heading" level="1" testId="invalid-link-title">
              {t('common:invalid_link')}
            </Typography>
          }
          subtitle={
            <Box align="center" pad={{ left: 'medium', right: 'medium' }}>
              <Typography
                type="text"
                size="large"
                testId="invalid-link-description"
                margin={{ bottom: 'small' }}
                textAlign="center"
              >
                {t('common:invalid_link_desc')}
              </Typography>
            </Box>
          }
          action={
            <ButtonGroup
              testId="invalid-link-actions"
              buttonList={[
                {
                  label: t('add_service_subscription'),
                  secondary: true,
                  testId: 'add-service-subscription-btn',
                  onClick: goToServiceSubscription
                },
                {
                  label: t('add_device_subscription'),
                  primary: true,
                  testId: 'add-device-subscription-btn',
                  onClick: goToDeviceSubscription
                }
              ]}
            />
          }
          testId="invalid-link-404-error"
        />
      </Box>
    </Box>
  )
}

const InvalidLink = () => {
  return (
    <Layout>
      <InvalidLinkPage />
    </Layout>
  )
}

export { InvalidLink }
