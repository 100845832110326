// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'

import { Typography } from '../../../components'
import { ThemeMode } from '../../../components/ThemeMode'
import { Layout } from '../../../commoncomponents/layout/Layout'

// Below component will be used by OIDC when fetching user profile fails.
// If we are not providing this component client library will use default component
// default components provided by library are in french
const NotAuthorizedContent = () => {
  return (
    <Box
      margin={{ top: 'xlarge' }}
      alignSelf="center"
      width="large"
      data-testid="not-authorized"
    >
      <Typography
        margin={{ top: 'large', bottom: 'small' }}
        type="heading"
        level="1"
        testId="authorization-title"
      >
        Authorization
      </Typography>
      <Typography type="text" size="xxlarge" testId="not-authorized-subtitle">
        You are not authorized to access this resource.
      </Typography>
    </Box>
  )
}

const NotAuthorized = () => {
  return (
    /* Wrapping up with grommet theme because,
       App will not be initialized when this comp is displayed
       This component will be displayed by OIDC library.
    */
    <ThemeMode>
      <Layout hideHeaderOptions={['no-header']}>
        <NotAuthorizedContent />
      </Layout>
    </ThemeMode>
  )
}

export default NotAuthorized
