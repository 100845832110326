// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import {
  Box,
  CheckBox,
  FormField,
  Notification,
  RadioButtonGroup,
  SelectMultiple,
  Text
} from 'grommet'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import debounce from 'lodash/debounce'
import { Trans, useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Anchor,
  ButtonGroup,
  CCSForm,
  Dropdown,
  Loader,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../components'
import { validateForm } from '../../../utils/validation-utils'
import { get, getErrorMessage } from '../../../utils/api-utils'
import {
  getCustomerAccount,
  isMSP as isMSPAccountLoaded
} from '../../../utils/feature-flag-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import {
  isActivateAdmin,
  getAllAccessRulesList
} from '../../../utils/manage-account-utils'
import { getCCSAppDetails } from '../../../utils/ccs-manager-utils'
import {
  getRoleAssignmentSuccessText,
  allScopesRRPName
} from '../../../utils/manage-account-identity-utils'
import { getUserName } from '../../../utils/common-utils'
import { useAUTHZContext } from '../../../context/authz-context'
import useFetchUserRoles from '../../../hooks/ui-doorway/useFetchUserRoles'
import { isServiceCentric } from '../../../utils/account-utils'
import Markdown from '../../Markdown'

import { AbandonProgressModal } from './AbandonProgressModal'
import { UserInput } from './UserInput'
import { RRPInput } from './RRPInput'
import { AssignRoleDropdownCustomRender } from './AssignRoleDropdownCustomRender'
import { AssignRoleConfirmationModal } from './AssignRoleConfirmationModal'

const AssignRoleModal = ({
  userData: userDataProp = null,
  setModal,
  reloadUsersTable = () => {},
  existingRoleAssignments: existingRoleAssignmentsProp = null,
  inCCSManager = false,
  customerDetails = {},
  setShowNotification = () => {},
  onSuccessAssign = () => {}
}) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn', 'authz', 'common', 'iam'])
  const { ccsApplicationId } = getCCSAppDetails()
  // update userCCSRoles - used for determining if the user is activate admin/tac admin or not
  const { userCCSRoles } = useAUTHZContext() // used for determining if the user is activate admin/tac admin
  useFetchUserRoles()

  const custAccountLoaded = getCustomerAccount()
  const { platform_customer_id: pcid } = custAccountLoaded
  const sessionPlatformCid = custAccountLoaded?.platform_customer_id || null

  const platformCid = inCCSManager ? customerDetails?.id : sessionPlatformCid
  const isMsp = inCCSManager
    ? customerDetails?.account_type === 'MSP'
    : isMSPAccountLoaded()

  // for paginating on scroll
  const itemsPerPage = 50
  const selectionLimit = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [workspaceSearchString, setWorkspaceSearchString] = useState('')
  const [loading, setLoading] = useState(false)
  const LDFlags = useFlags()
  const isTenantWorkspaceGroup = LDFlags['tenant-workspace-group'] && isMsp
  const rrpWorkForMSP = LDFlags['glcp-msp-rrp'] && isMsp
  const newDashboardFlag = isServiceCentric(LDFlags)
  const [userData, setUserData] = useState(userDataProp)
  const [appRoles, setAppRoles] = useState([]) // to store all the available apps with available roles
  const [selectedDetails, setSelectedDetails] = useState({
    application_id: '',
    application_name: '',
    role_slug: '',
    role_name: '',
    predefined: false,
    access_rules: {
      msp: false,
      tenants: []
    },
    access_rules_index: undefined,
    msp_role_assignment: 'no',
    workspace_role_assignment: '',
    limit_resource_access: false,
    resource_restriction_policies: [],
    workspace_groups: [],
    user_email: userDataProp?.email || '',
    support_one_role_only: false
  })
  const [allScopesRRPId, setAllScopesRRPId] = useState('')
  const [allRolesList, setAllRolesList] = useState([])
  const [rolesList, setRolesList] = useState([])
  const [roleSelectionModal, setRoleSelectionModal] = useState(true) // modal where we select the application, role,rrp
  const [assignRoleConfirmationModal, setAssignRoleConfirmationModal] =
    useState(false) // confirmation modal for role assignment
  const [existingRoleAssignments, setExistingRoleAssignments] = useState(
    existingRoleAssignmentsProp || null
  )
  const [errorMessages, setErrorMessages] = useState({
    // for storing error messages, if there is any error message, we disable assign roles button
    getAppRoles: '',
    getExistingRoleAssignments: ''
  })
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [showAbandonProgressModal, setShowAbandonProgressModal] =
    useState(false)
  const [workspaceGroup, setWorkspaceGroup] = useState([])

  const customerWorkspaceValidation = (formValues, i18nTranslate) => {
    const { msp_role_assignment, workspace_role_assignment } = formValues
    const errorMessage = i18nTranslate(
      t(
        'authz:workspace_group.error_msg_for_invalid_customer_workspace_selection'
      )
    )
    if (msp_role_assignment === 'no' && workspace_role_assignment === 'none') {
      setFormErrorMessage(errorMessage)
      return Promise.reject(new Error(errorMessage))
    }
    return validateForm(formValues, i18nTranslate)
  }

  const handleValidation = async (formValues, i18nTranslate) => {
    return customerWorkspaceValidation(formValues, i18nTranslate)
      .then(() => {
        setFormErrorMessage('')
      })
      .catch((error) => {
        setFormErrorMessage(error.message)
        throw error
      })
  }

  const workspaceRoleAssignmentOptions = [
    {
      value: 'none',
      label: 'None'
    },
    {
      value: 'ALL',
      label: 'All'
    },
    {
      value: 'specific',
      label: 'Specific workspace groups'
    }
  ]

  const accessRulesList = getAllAccessRulesList(t, isTenantWorkspaceGroup)

  useEffect(() => {
    if (!isTenantWorkspaceGroup) return

    // Define the endpoint and headers based on `inCCSManager`
    const endpoint = inCCSManager
      ? '/support-assistant/v1alpha1/groups'
      : '/groups-management/v1alpha1/groups'

    const headers = inCCSManager
      ? { platform_customer_id: platformCid }
      : { 'X-workspaceID': pcid }

    // defining the query parameters
    const queryParams = {
      ...(workspaceSearchString?.trim()?.length && {
        filter: workspaceSearchString.trim()
      }),
      'group-type': 'Workspace',
      ...(inCCSManager && { platform_customer_id: platformCid })
    }

    // fetching data
    get(endpoint, queryParams, oidcUser.access_token, false, {}, headers).then(
      (response) => {
        setWorkspaceGroup(response?.data?.items ?? [])
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowErrorNotification))
      }
    )
  }, [
    t,
    oidcUser,
    setShowNotification,
    workspaceSearchString,
    isTenantWorkspaceGroup,
    pcid,
    setWorkspaceGroup,
    inCCSManager,
    platformCid
  ])

  // used only for ccs-manager
  const isTACCustomerSelected = customerDetails?.id === sessionPlatformCid

  useEffect(() => {
    // get all role assignments
    let url = ''
    let queryParams = ''
    let tags = ''

    if (inCCSManager) {
      // show only TAC roles(tac-admin, tac-operator) if TAC customer is selected -- { tag_rules: 'tac' }
      // show all other roles for other customers -- { all non-hidden roles are shown by default tag_rules: 'activate'}
      tags = isTACCustomerSelected ? 'tag_rules=tac' : 'tag_rules=activate'
      queryParams = `platform_customer_id=${platformCid}&${tags}`
      url = `/support-assistant/v1alpha1/app-role-assignments?${queryParams}`
    } else {
      url = `/ui-doorway/ui/v2/um/role-assignments?provision_status=PROVISIONED`
    }

    if (userCCSRoles) {
      get(url, {}, oidcUser.access_token).then(
        (response) => {
          if (response.status === 200 || response.status === 204) {
            setErrorMessages((val) => ({
              ...val,
              getAppRoles: ''
            }))
            setAppRoles(response?.data || [])
          }
        },
        (error) => {
          const errorMsg = getErrorMessage(error, t)
          setErrorMessages((val) => ({
            ...val,
            getAppRoles: errorMsg
          }))
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
  }, [
    oidcUser.access_token,
    t,
    userCCSRoles,
    inCCSManager,
    isTACCustomerSelected,
    platformCid,
    LDFlags
  ])

  useEffect(() => {
    if (userData?.email && !existingRoleAssignments && platformCid) {
      // get user's existing role assignments
      const url = inCCSManager
        ? `/support-assistant/v1alpha1/user-role-assignments`
        : `/authorization/ui/v2/customers/${platformCid}/users/${userData?.email}/role_assignments`

      get(
        url,
        {
          ...(inCCSManager && {
            platform_customer_id: platformCid,
            username: userData?.email
          })
        },
        oidcUser.access_token
      ).then(
        (response) => {
          if (response.status === 200 || response.status === 204) {
            setErrorMessages((val) => ({
              ...val,
              getExistingRoleAssignments: ''
            }))
            const roles = response?.data?.roles
            setExistingRoleAssignments(roles)
          }
        },
        (error) => {
          const errorMsg = getErrorMessage(error, t)
          setErrorMessages((val) => ({
            ...val,
            getExistingRoleAssignments: errorMsg
          }))
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
  }, [
    oidcUser.access_token,
    t,
    userData,
    platformCid,
    inCCSManager,
    existingRoleAssignments,
    LDFlags
  ])

  useEffect(() => {
    // to update rolesList when appid is changed
    if (selectedDetails.application_id) {
      if (inCCSManager) {
        const res = appRoles.filter((value) => {
          return value.application_id === selectedDetails.application_id
        })
        let roles = res[0]?.roles || []
        // a user without TAC Admin role should not assign TAC admin role, so we are filtering it
        if (!userCCSRoles?.includes('ccs.tac-admin')) {
          roles = roles?.filter((role) => role?.slug !== 'ccs.tac-admin')
        }

        // Ensure the selected role is included in the roles list
        const selectedRoleInList = roles.find(
          (role) => role.slug === selectedDetails.role_slug
        )
        if (selectedDetails.role_slug && !selectedRoleInList) {
          const selectedRole = {
            slug: selectedDetails.role_slug,
            name: selectedDetails.role_name
          }
          roles = [selectedRole, ...roles]
        }

        setAllRolesList(roles)
        setRolesList(roles)
      } else {
        const param = {
          limit: itemsPerPage,
          offset: page === 1 ? 0 : offset,
          ...(searchString?.trim()?.length && { name: searchString?.trim() }),
          ...(isActivateAdmin(userCCSRoles) &&
            selectedDetails?.application_id === ccsApplicationId && {
              tag_rules: 'activate'
            })
        }
        get(
          `/authorization/ui/v1/customers/${platformCid}/applications/${selectedDetails.application_id}/roles`,
          param,
          oidcUser.access_token
        ).then(
          (response) => {
            const { data } = response
            const roles = data?.roles || []

            // Update pagination states
            setTotalItems(data?.pagination?.total_count || itemsPerPage)
            setOffset(data?.pagination?.offset || 0)

            // Map roles to dropdown options
            const rolesObj = roles.map((role, index) => ({
              label: role.name,
              name: role.name,
              value: index,
              description: role.description,
              application_id: role.application_id,
              slug: role.slug
            }))

            // Ensure the selected role is included in the roles list
            const selectedRoleInList = rolesObj.find(
              (role) => role.slug === selectedDetails.role_slug
            )
            if (selectedDetails.role_slug && !selectedRoleInList) {
              const selectedRole = {
                label: selectedDetails.role_name,
                name: selectedDetails.role_name,
                slug: selectedDetails.role_slug
              }
              rolesObj.unshift(selectedRole)
            }

            // Append or reset the roles list based on the current page
            if (page !== 1) {
              setRolesList((prevRoles) => [...prevRoles, ...rolesObj])
              setAllRolesList((prevRoles) => [...prevRoles, ...rolesObj])
            } else {
              setRolesList(rolesObj)
              setAllRolesList(rolesObj)
            }

            setLoading(false)
          },
          (error) => {
            setLoading(false)
            setShowErrorNotification(
              displayApiError(error, t, setShowErrorNotification)
            )
          }
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDetails.application_id,
    searchString,
    appRoles,
    ccsApplicationId,
    existingRoleAssignments,
    isMsp,
    inCCSManager,
    userCCSRoles,
    page
  ])

  useEffect(() => {
    // get all scopes RRP id
    setAllScopesRRPId('')
    if (
      selectedDetails.application_id &&
      selectedDetails.application_id !== ccsApplicationId &&
      !isMsp
    ) {
      const url = inCCSManager
        ? `/support-assistant/v1alpha1/resource-restrictions`
        : `/authorization/ui/v1/resource_restrictions`

      get(
        url,
        {
          application_id: selectedDetails.application_id,
          limit: 1,
          offset: 0,
          name: allScopesRRPName,
          exact_match: true,
          ...(inCCSManager && {
            platform_cid: customerDetails?.id,
            match_name: true
          })
        },
        oidcUser.access_token
      ).then(
        (response) => {
          if (response.status === 200 || response.status === 204) {
            const rrpData = response?.data?.policies
            if (rrpData[0]) {
              setAllScopesRRPId(rrpData[0].resource_restriction_policy_id)
              if (!selectedDetails.limit_resource_access) {
                setSelectedDetails((prev) => ({
                  ...prev,
                  resource_restriction_policies: [
                    {
                      id: rrpData[0].resource_restriction_policy_id,
                      name: allScopesRRPName
                    }
                  ]
                }))
              }
            } else if (!selectedDetails.limit_resource_access) {
              setSelectedDetails((prev) => ({
                ...prev,
                resource_restriction_policies: []
              }))
            }
          }
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, selectedDetails.application_id, t])

  useEffect(() => {
    setFormErrorMessage('')
  }, [
    selectedDetails.application_id,
    selectedDetails.role_slug,
    selectedDetails.limit_resource_access,
    selectedDetails.resource_restriction_policies
  ])

  const showRRPFields = () => {
    // Check if 'Tenants only' is selected when the tenant-workspace-group flag is disabled
    const isTenantsOnly =
      !isTenantWorkspaceGroup &&
      !inCCSManager &&
      isMsp &&
      (accessRulesList[selectedDetails.access_rules_index] === undefined || // Check for undefined: when there is no user selection
        accessRulesList[selectedDetails.access_rules_index]?.label ===
          'Tenants Only') // Check for 'Tenants Only'

    // Return the visibility of RRP fields
    return (
      selectedDetails.application_id !== ccsApplicationId && // Exclude CCS application
      (!isMsp || rrpWorkForMSP) && // Include MSP-specific logic
      selectedDetails.role_slug && // Ensure role is selected
      ((isTenantWorkspaceGroup &&
        selectedDetails.msp_role_assignment === 'yes') ||
        // Check MSP role assignment for tenant-workspace-group
        (!isTenantWorkspaceGroup && !isTenantsOnly)) // Hide fields if 'Tenants only' is selected
    )
  }

  const handleDebouncedSearchValue = debounce((value) => {
    setSearchString(value)
    setPage(1)
    setAllRolesList([])
  }, 1000)

  const handleDebouncedWorkspaceSearchValue = debounce((value) => {
    setWorkspaceSearchString(value)
  }, 500)

  const isRoleAssignmentAlreadyExists = () => {
    if (rrpWorkForMSP) {
      return existingRoleAssignments.filter(
        (data) =>
          data?.application_id === selectedDetails.application_id &&
          data?.slug === selectedDetails.role_slug &&
          data.access_rules &&
          isEqual(selectedDetails.access_rules, data.access_rules) &&
          // skipping rrp for msp check for HPE GreenLake Platform for assign role confirmation
          isEqual(
            selectedDetails.application_id === ccsApplicationId
              ? []
              : data?.resource_restriction_policies
                  ?.map((rrp) => rrp?.resource_restriction_policy_id)
                  ?.sort(),
            selectedDetails?.resource_restriction_policies
              ?.map((rrp) => rrp?.id)
              ?.sort()
          )
      )?.length
    }
    if (isMsp) {
      const existingRoleAccessRules = existingRoleAssignments
        .filter(
          (data) =>
            data.application_id === selectedDetails.application_id &&
            data.slug === selectedDetails.role_slug &&
            data.access_rules
        )
        .map((data) => data.access_rules)
      return existingRoleAccessRules.some((val) =>
        isEqual(selectedDetails.access_rules, val)
      )
    }
    if (selectedDetails.application_id === ccsApplicationId) {
      const existingCCSRoles =
        existingRoleAssignments
          ?.filter((data) => data.application_id === ccsApplicationId)
          ?.map((data) => data.slug) || []
      return existingCCSRoles.includes(selectedDetails.role_slug)
    }
    if (
      selectedDetails.application_id &&
      selectedDetails.role_slug &&
      selectedDetails.resource_restriction_policies
    ) {
      return existingRoleAssignments.filter(
        (data) =>
          data?.application_id === selectedDetails.application_id &&
          data?.slug === selectedDetails.role_slug &&
          isEqual(
            data?.resource_restriction_policies
              ?.map((rrp) => rrp?.resource_restriction_policy_id)
              ?.sort(),
            selectedDetails?.resource_restriction_policies
              ?.map((rrp) => rrp?.id)
              ?.sort()
          )
      )?.length
    }
    return false
  }

  const getAccessRulesIndex = () => {
    let access_rules_index
    if (
      selectedDetails.msp_role_assignment === 'yes' &&
      selectedDetails?.workspace_role_assignment === 'ALL'
    ) {
      access_rules_index = 0
    } else if (
      selectedDetails.msp_role_assignment === 'yes' &&
      selectedDetails?.workspace_role_assignment === 'none'
    ) {
      access_rules_index = 1
    } else if (
      selectedDetails.msp_role_assignment === 'no' &&
      selectedDetails?.workspace_role_assignment === 'ALL'
    ) {
      access_rules_index = 2
    } else if (
      selectedDetails.msp_role_assignment === 'yes' &&
      selectedDetails?.workspace_role_assignment === 'specific'
    ) {
      access_rules_index = 3
    } else if (
      selectedDetails.msp_role_assignment === 'no' &&
      selectedDetails?.workspace_role_assignment === 'specific'
    ) {
      access_rules_index = 4
    }
    return access_rules_index
  }

  const singleRoleAssignmentNotificationMsg = (i18nKey) => (
    <Trans
      i18nKey={i18nKey}
      t={t}
      values={{ applicationName: selectedDetails?.application_name }}
      // Grommet text component is needed otherwise the Box
      // from Typography component forces a line break
      components={[<Text weight={500} color="text-strong" />]}
    />
  )

  const getSubHeading = () => {
    let subHeading
    if (userDataProp) {
      subHeading = (
        <Markdown>
          {t('users.assign_role_desc', {
            userName: getUserName(
              userData?.first_name,
              userData?.last_name,
              userData?.email
            )
          })}
        </Markdown>
      )
    } else {
      subHeading = (
        <Typography type="text" size="large" testId="header-title">
          {t('users.assign_role_modal_service_subheading')}
        </Typography>
      )
    }
    return subHeading
  }

  return (
    <>
      {roleSelectionModal && (
        <ModalDialog
          header={
            <ModalHeader
              title={
                <>
                  <Typography type="heading" level={2} testId="header-title">
                    {t('authz:api_client.assign_role')}
                  </Typography>
                  <Box margin={{ top: 'small' }}>{getSubHeading()}</Box>
                </>
              }
            />
          }
          content={
            <Box margin={{ top: 'medium' }}>
              <CCSForm
                value={selectedDetails}
                errorMessage={formErrorMessage}
                testId="assign-role-form"
                validate="submit"
                onSubmit={async () => {
                  const submissionValues = {
                    msp_role_assignment: selectedDetails?.msp_role_assignment,
                    workspace_role_assignment:
                      selectedDetails?.workspace_role_assignment
                  }
                  try {
                    await handleValidation(submissionValues, t)
                    if (
                      selectedDetails?.application_id !== ccsApplicationId &&
                      selectedDetails.limit_resource_access &&
                      !selectedDetails.resource_restriction_policies?.length
                    ) {
                      setFormErrorMessage(
                        t('authz:rrp.no_rrp_selected_error_msg')
                      )
                    } else if (isRoleAssignmentAlreadyExists()) {
                      const message = getRoleAssignmentSuccessText(
                        selectedDetails?.role_name,
                        getUserName(
                          userData?.first_name,
                          userData?.last_name,
                          userData?.email
                        ),
                        t
                      )
                      setShowNotification(
                        displayNotification(
                          message,
                          'info',
                          setShowNotification
                        )
                      )
                      setModal(false)
                    } else {
                      if (isTenantWorkspaceGroup) {
                        const access_rules_index = getAccessRulesIndex()
                        setSelectedDetails({
                          ...selectedDetails,
                          access_rules_index
                        })
                      }
                      if (!isMsp) {
                        setSelectedDetails({
                          ...selectedDetails,
                          access_rules: null
                        })
                      }
                      setRoleSelectionModal(false)
                      setAssignRoleConfirmationModal(true)
                    }
                  } catch (error) {
                    setFormErrorMessage(error.message)
                    setRoleSelectionModal(true)
                    setAssignRoleConfirmationModal(false)
                  }
                }}
                style={{ width: '100%' }}
                buttons={
                  <Box
                    direction="row"
                    justify="start"
                    margin={{ top: 'medium' }}
                  >
                    <ButtonGroup
                      buttonList={[
                        {
                          id: 2,
                          label: t('users.assign_role'),
                          primary: true,
                          testId: 'assign-role-btn',
                          type: 'submit',
                          disabled: !!Object.keys(errorMessages).find(
                            // button should be disabled if any of the error messages length is >1
                            (key) => !!errorMessages[key].length
                          )
                        },
                        {
                          id: 1,
                          label: t('users.cancel'),
                          testId: 'cancel-btn',
                          onClick: () => setModal(false)
                        }
                      ]}
                      testId="two-buttons"
                    />
                  </Box>
                }
              >
                <Box direction="column" gap="small">
                  {!userDataProp && (
                    <UserInput
                      setUserData={setUserData}
                      selectedDetails={selectedDetails}
                      setSelectedDetails={setSelectedDetails}
                    />
                  )}
                  <FormField
                    label={
                      newDashboardFlag
                        ? t('users.service_manager')
                        : t('users.application')
                    }
                    name="application_id"
                    required
                    data-testid="select-application-form-field"
                    info={
                      (selectedDetails?.application_id &&
                        selectedDetails?.support_one_role_only && (
                          <Notification
                            status="info"
                            message={
                              isMsp
                                ? singleRoleAssignmentNotificationMsg(
                                    'users.msp_support_one_role_only_msg'
                                  )
                                : singleRoleAssignmentNotificationMsg(
                                    'users.non_msp_support_one_role_only_msg'
                                  )
                            }
                          />
                        )) ||
                      ''
                    }
                  >
                    <Dropdown
                      id="applications-dropdown"
                      emptySearchMessage
                      testId="applications-dropdown"
                      name="application_id"
                      dropProps={{
                        width: 'small'
                      }}
                      options={appRoles?.map((value) => {
                        return {
                          application_id: value.application_id,
                          application_name: value.application_name,
                          support_one_role_only:
                            value?.support_one_role_only || false
                        }
                      })}
                      multiple={false}
                      labelKey="application_name"
                      value={selectedDetails.application_id}
                      valueKey={{ key: 'application_id', reduce: true }}
                      placeholder={
                        newDashboardFlag
                          ? t('users.select_service_manager')
                          : t('users.select_application')
                      }
                      onChange={({ option }) => {
                        setSelectedDetails({
                          ...selectedDetails,
                          application_id: option.application_id,
                          application_name: option.application_name,
                          role_slug: '',
                          role_name: '',
                          predefined: false,
                          resource_restriction_policies: [],
                          support_one_role_only:
                            option?.support_one_role_only || false
                        })
                        setRolesList([])
                        setAllRolesList([])
                        if (
                          !inCCSManager &&
                          selectedDetails.application_id !==
                            option.application_id
                        ) {
                          setLoading(true)
                        }
                        setPage(1)
                      }}
                      noBorder
                    />
                  </FormField>
                  {loading ? (
                    <Loader testId="user-assign-loader" />
                  ) : (
                    selectedDetails.application_id && (
                      <FormField
                        label={`${t('users.role')}*`}
                        name="role_slug"
                        data-testid="select-role-form-field"
                        validate={(value) => {
                          if (value && value.length) {
                            return true
                          }
                          return t('users.please_selecr_a_valid_role')
                        }}
                      >
                        <Dropdown
                          id="roles-dropdown"
                          testId="roles-dropdown"
                          name="role_slug"
                          options={rolesList}
                          multiple={false}
                          labelKey="name"
                          value={selectedDetails.role_slug}
                          valueKey={{ key: 'slug', reduce: true }}
                          placeholder={t('users.select_role')}
                          onChange={({ option }) => {
                            setSelectedDetails({
                              ...selectedDetails,
                              role_slug: option.slug,
                              role_name: option.name,
                              predefined: option.predefined,
                              access_rules: {
                                msp: false,
                                tenants: []
                              },
                              access_rules_index: undefined,
                              ...(selectedDetails.limit_resource_access && {
                                resource_restriction_policies: []
                              })
                            })
                          }}
                          onMore={() => {
                            if (!inCCSManager && totalItems > offset) {
                              setPage(page + 1)
                            }
                          }}
                          onSearch={(searchText) => {
                            if (inCCSManager) {
                              setRolesList(
                                allRolesList.filter((o) =>
                                  o.name
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                )
                              )
                            } else {
                              handleDebouncedSearchValue(searchText)
                            }
                          }}
                          onClose={() => {
                            setSearchString('')
                          }}
                          customRender={(option) => (
                            <AssignRoleDropdownCustomRender
                              name={option.name}
                              description={option.description || ''}
                              predefined={option.predefined}
                            />
                          )}
                          noBorder
                          size="small"
                        />
                      </FormField>
                    )
                  )}
                  {isMsp &&
                    selectedDetails.role_slug &&
                    (!isTenantWorkspaceGroup ? (
                      <FormField
                        label={t('ip_access_rules.access_rule')}
                        name="access_rules_index"
                        required
                        data-testid="access-rules-form-field"
                      >
                        <Dropdown
                          id="access-rules-dropdown"
                          testId="access-rules-dropdown"
                          name="access_rules_index"
                          options={accessRulesList}
                          multiple={false}
                          labelKey="label"
                          value={selectedDetails.access_rules_index}
                          valueKey={{ key: 'value', reduce: true }}
                          placeholder={t('users.select_access_rule')}
                          onChange={({ option }) => {
                            setSelectedDetails({
                              ...selectedDetails,
                              access_rules: option.access_rules,
                              access_rules_index: option.value
                            })
                          }}
                          noBorder
                        />
                      </FormField>
                    ) : (
                      <>
                        <Box margin={{ bottom: 'small' }}>
                          <Typography
                            type="heading"
                            level={3}
                            testId="sub-header-title"
                          >
                            {t(
                              'authz:workspace_group.sub_header_scope_of_role_assignment'
                            )}
                          </Typography>
                        </Box>
                        <FormField
                          name="msp_role_assignment"
                          data-testid="check-box-form-field"
                        >
                          <CheckBox
                            name="msp_role_assignment"
                            label={t(
                              'authz:workspace_group.role_assignment_applies_to_msp_label'
                            )}
                            checked={
                              selectedDetails.msp_role_assignment === 'yes'
                            }
                            onChange={(event) => {
                              const newMSPRoleAssignment = event.target.checked
                                ? 'yes'
                                : 'no'
                              setSelectedDetails({
                                ...selectedDetails,
                                access_rules: {
                                  ...selectedDetails.access_rules,
                                  msp: event.target.checked
                                },
                                msp_role_assignment: newMSPRoleAssignment
                              })
                              if (event.target.checked) {
                                setFormErrorMessage('')
                              }
                            }}
                            data-testid="access-rules-checkbox"
                          />
                        </FormField>
                        <FormField
                          label="What customer workspaces does the role assignment apply to?"
                          name="workspace_role_assignment"
                          validate={(value) => {
                            if (value?.length) {
                              return true
                            }
                            return t('common:this_is_required')
                          }}
                          data-testid="workspace-rules-form-field"
                        >
                          <RadioButtonGroup
                            name="workspace_role_assignment"
                            options={workspaceRoleAssignmentOptions}
                            value={selectedDetails.workspace_role_assignment}
                            onChange={(event) => {
                              let tenants = event.target.value
                              if (event.target.value === 'ALL') {
                                tenants = ['ALL']
                                setFormErrorMessage('')
                              } else if (event.target.value === 'none') {
                                tenants = []
                              } else {
                                // clear the form error message if the selected values combo is not 'no' and 'none'
                                setFormErrorMessage('')
                              }
                              setSelectedDetails({
                                ...selectedDetails,
                                access_rules: {
                                  ...selectedDetails.access_rules,
                                  tenants
                                },
                                workspace_role_assignment:
                                  event.target.value || null
                              })
                            }}
                            data-testid="workspace-rules-radiobutton"
                          />
                        </FormField>
                      </>
                    ))}
                  {selectedDetails?.workspace_role_assignment ===
                    'specific' && (
                    <FormField
                      label="Workspace groups"
                      name="workspace_groups"
                      required
                      data-testid="select-workspace-groups-form-field"
                    >
                      <SelectMultiple
                        id="workspace_groups"
                        name="workspace_groups"
                        options={workspaceGroup}
                        labelKey="displayName"
                        value={selectedDetails?.workspace_groups}
                        valueKey="id"
                        limit={selectionLimit}
                        onChange={({ value: nextValue }) => {
                          setSelectedDetails({
                            ...selectedDetails,
                            workspace_groups: nextValue,
                            access_rules: {
                              ...selectedDetails?.access_rules,
                              tenants: nextValue?.map((res) => res?.id)
                            }
                          })
                        }}
                        placeholder={t('iam:users.select_up_to_msg', {
                          limit: selectionLimit
                        })}
                        onSearch={(searchText) => {
                          handleDebouncedWorkspaceSearchValue(searchText)
                        }}
                        searchPlaceholder={t('common:search')}
                        onClose={() => {
                          setWorkspaceSearchString('')
                        }}
                        dropHeight="medium"
                        noBorder
                        data-testid="workspace-groups-dropdown"
                      />
                    </FormField>
                  )}
                  {showRRPFields() && (
                    <Box>
                      <FormField
                        label={t('users.additional_resource_restrictions')}
                        data-testid="rrp-title"
                        contentProps={{ border: false, margin: '0' }}
                      >
                        <Typography
                          type="text"
                          testId="rrp-subtitle"
                          size="xsmall"
                        >
                          <Trans
                            i18nKey="users.resource_restriction_policies_info"
                            t={t}
                          >
                            <Anchor
                              label={t('users.resource_restriction_policies')}
                              href="https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us"
                              target="_blank"
                              testId="rrp-documentation-anchor"
                            />
                          </Trans>
                        </Typography>
                      </FormField>
                      <RRPInput
                        allScopesRRPId={allScopesRRPId}
                        selectedDetails={selectedDetails}
                        setSelectedDetails={setSelectedDetails}
                        existingRoleAssignments={existingRoleAssignments || []}
                        customerDetails={customerDetails}
                        inCCSManager={inCCSManager}
                        formErrorMessage={formErrorMessage}
                        setFormErrorMessage={setFormErrorMessage}
                        setShowParentModal={setRoleSelectionModal}
                        setShowAbandonProgressModal={
                          setShowAbandonProgressModal
                        }
                      />
                    </Box>
                  )}
                </Box>
              </CCSForm>
            </Box>
          }
          onClose={() => setModal(false)}
          data-testid="assign-role-modal-dialog"
          position="right"
          height="100%"
          width={{ min: 'full', max: 'medium' }}
        />
      )}
      {assignRoleConfirmationModal && (
        <AssignRoleConfirmationModal
          userData={userData}
          setModal={setModal}
          selectedRoleDetails={selectedDetails}
          existingRoleAssignments={existingRoleAssignments}
          reloadUsersTable={reloadUsersTable}
          accessRulesList={accessRulesList}
          inCCSManager={inCCSManager}
          customerId={customerDetails?.id}
          setShowNotification={setShowNotification}
          onSuccessAssign={onSuccessAssign}
        />
      )}
      {showAbandonProgressModal && (
        <AbandonProgressModal
          setShowModal={setShowAbandonProgressModal}
          setShowParentModal={setRoleSelectionModal}
        />
      )}
      {showErrorNotification}
    </>
  )
}

AssignRoleModal.propTypes = {
  userData: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  reloadUsersTable: PropTypes.func,
  inCCSManager: PropTypes.bool,
  customerDetails: PropTypes.object,
  existingRoleAssignments: PropTypes.array,
  setShowNotification: PropTypes.func,
  onSuccessAssign: PropTypes.func
}

export { AssignRoleModal }
