// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Anchor, Box, PageHeader } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { Previous, CircleAlert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography, Button, Loader } from '../../../../components'
import { get } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { getPaginationShowIdx } from '../../../../utils/common-utils'

import SupportAccessDatatable from './SupportAccessDatatable'
import SearchCustomerCaseModal from './SearchCustomerCaseModal'

const SupportAccess = () => {
  const { t } = useTranslation(['common', 'support_access_manager'])
  const { oidcUser } = useReactOidc()
  const [notifyComponent, setNotifyComponent] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [searchCustomerCaseModal, setSearchCustomerCaseModal] = useState(false)
  const [customerCase, setCustomerCase] = useState([])
  const [caseDetails, setCaseDetails] = useState({})
  // Allow HPE theme styling to spread onto Typography instance
  const {
    pageHeader: { subtitle }
  } = useContext(ThemeContext)

  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)

  const [itemsPerPageAccessed, setItemsPerPageAccessed] = useState(10)
  const [pageAccessed, setPageAccessed] = useState(1)
  const [totalItemsAccessed, setTotalItemsAccessed] = useState(0)
  const [historicalSupportCases, setHistoricalSupportCases] = useState([])
  const isFirstRun = useRef(true)
  const LDFlags = useFlags()
  const samPhase2 = LDFlags['glcp-support-access-manager-phase2']
  const navigate = useNavigate()
  const handleBackBtnClick = () => {
    navigate('/manage-ccs/support-access')
  }

  useEffect(() => {
    if (!samPhase2) {
      return
    }
    if (isFirstRun.current) {
      setShowLoader(true)
      isFirstRun.current = false
    }
    get(
      `/support-assistant/v1alpha1/accessed-support-cases?limit=${itemsPerPageAccessed}&offset=${
        (pageAccessed - 1) * itemsPerPageAccessed
      }`,
      {},
      oidcUser.access_token
    )
      .then((response) => {
        setShowLoader(false)
        if (response?.data?.support_cases) {
          setTotalItemsAccessed(response?.data?.pagination?.total_count)
          setHistoricalSupportCases(response?.data?.support_cases)
        }
      })
      .catch((error) => {
        setHistoricalSupportCases([])
        setShowLoader(false)
        const errorComp = displayApiError(error, t, setNotifyComponent)
        setNotifyComponent(errorComp)
      })
  }, [itemsPerPageAccessed, oidcUser.access_token, pageAccessed, t]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!caseDetails?.pcid || !caseDetails?.case_id) {
      return
    }
    setShowLoader(true)
    get(
      `/support-assistant/v1alpha1/support-enabled-workspaces/${
        caseDetails?.pcid || ''
      }/support-cases/${
        encodeURIComponent(caseDetails?.case_id) || ''
      }?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`,
      {},
      oidcUser.access_token
    )
      .then((response) => {
        setShowLoader(false)
        if (response?.data?.support_cases) {
          setTotalItems(response?.data?.pagination?.total_count)
          setCustomerCase(response?.data?.support_cases)
        }
      })
      .catch((error) => {
        setCustomerCase([])
        setShowLoader(false)
        const errorComp = displayApiError(error, t, setNotifyComponent)
        setNotifyComponent(errorComp)
      })
  }, [caseDetails, itemsPerPage, oidcUser.access_token, page, t]) // eslint-disable-line react-hooks/exhaustive-deps

  const searchCustomerCaseCb = (caseInfo) => {
    setCaseDetails(caseInfo)
    setPage(1)
  }
  return (
    <Box pad={{ horizontal: 'xlarge' }}>
      {notifyComponent}
      <Box data-testid="support-access-page">
        <PageHeader
          title={
            <Typography level="1" type="heading" testId="sa-title">
              {t('support_access_manager:worksapce_sa_title')}
            </Typography>
          }
          subtitle={
            <Typography type="text" testId="sa-desc" {...subtitle}>
              {t('support_access_manager:workspace_sa_desc')}
            </Typography>
          }
          parent={
            <Anchor
              label={t('support_access_manager:support_access_title')}
              icon={<Previous />}
              onClick={handleBackBtnClick}
              data-testid="manage-account-btn"
            />
          }
        />
        <Box data-testid="support-access" flex direction="column">
          {showLoader && customerCase.length === 0 ? (
            <Box align="center">
              <Loader testId="loader-spinner" />
            </Box>
          ) : (
            ''
          )}
          {!showLoader &&
          customerCase?.length === 0 &&
          historicalSupportCases?.length === 0 ? (
            <Box flex align="center" justify="center">
              <Box margin={{ bottom: 'medium' }}>
                <CircleAlert size="medium" />
              </Box>
              <Box>
                <Typography type="text" weight="bold" size="medium">
                  {t('support_access_manager:search_customer_case_modal.title')}
                </Typography>
              </Box>
              <Box margin={{ bottom: 'medium' }}>
                <Typography type="text" size="small">
                  {t('support_access_manager:workspace_sa_info')}
                </Typography>
              </Box>
              <Box margin={{ bottom: 'medium' }}>
                <Button
                  primary
                  testId="search-customer-btn"
                  alignSelf="end"
                  label={t(
                    'support_access_manager:search_customer_case_modal.search_case'
                  )}
                  onClick={() => {
                    setSearchCustomerCaseModal(true)
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box>
              <Button
                primary
                testId="search-customer-btn"
                alignSelf="end"
                label={t(
                  'support_access_manager:search_customer_case_modal.search_case'
                )}
                onClick={() => {
                  setSearchCustomerCaseModal(true)
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {Object.keys(customerCase).length > 0 && (
        <Box flex justify="between" margin={{ top: 'medium' }}>
          <SupportAccessDatatable
            pagination={{
              totalItems,
              itemsPerPage,
              page,
              setPage,
              pageIdxInfo: getPaginationShowIdx(
                page,
                totalItems,
                itemsPerPage,
                t
              ),
              defaultRowsValue: 10,
              setItemsPerPage: (numOfRows) => {
                setItemsPerPage(numOfRows)
              }
            }}
            caseInfo={caseDetails}
            customerCase={customerCase}
            setNotifyComponent={setNotifyComponent}
          />
          <Box margin={{ top: 'small', bottom: 'large' }} />
        </Box>
      )}
      {Object.keys(historicalSupportCases).length > 0 && (
        <Box
          flex
          justify="between"
          margin={{ top: 'medium', bottom: 'medium' }}
        >
          <Box margin={{ bottom: 'medium' }} justify="right">
            <Typography
              type="heading"
              level="2"
              margin={{ top: 'high', bottom: 'high' }}
            >
              {t('support_access_manager:historical_cases')}
            </Typography>
          </Box>
          <SupportAccessDatatable
            pagination={{
              totalItems: totalItemsAccessed,
              itemsPerPage: itemsPerPageAccessed,
              page: pageAccessed,
              setPage: setPageAccessed,
              pageIdxInfo: getPaginationShowIdx(
                pageAccessed,
                totalItemsAccessed,
                itemsPerPageAccessed,
                t
              ),
              defaultRowsValue: 10,
              setItemsPerPage: (numOfRows) => {
                setItemsPerPageAccessed(numOfRows)
              }
            }}
            caseInfo={{}}
            customerCase={historicalSupportCases}
            setNotifyComponent={setNotifyComponent}
          />
        </Box>
      )}

      {searchCustomerCaseModal && (
        <SearchCustomerCaseModal
          onSetOpen={setSearchCustomerCaseModal}
          cb={searchCustomerCaseCb}
        />
      )}
    </Box>
  )
}

const SupportAccessPage = () => {
  return <SupportAccess />
}

export default SupportAccessPage
