// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useCallback, useEffect, useState } from 'react'
import { Box, Tip } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
import { StatusCritical, Dropbox, FormSchedule } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'

import { get, getErrorMessage } from '../../../../../utils/api-utils'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getPaginationShowIdx } from '../../../../../utils/common-utils'
import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'
import { customRenderer } from '../../../../../commoncomponents/CustomRenderer'
import {
  DataTable,
  Notification,
  ActionButton,
  Typography,
  NoDataInfo,
  Loader,
  Button
} from '../../../../../components'
import { convertUTCToLocalTime } from '../utils'

import CreatePostWizard from './create-post/CreatePostWizard'
import DeletePost from './DeletePost'

const WhatsNewDataTable = ({ setViewCreateButton, refreshCount }) => {
  const { t } = useTranslation(['manage'])
  const navigate = useNavigate()
  const [showDeleteModel, setDeleteModel] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState({})
  const [successMessage, setSuccessMessage] = useState(false)
  const [loading, setLoading] = useState(true)
  const { oidcUser } = useReactOidc()
  const [viewData, setViewData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [newPostWizard, setNewPostWizard] = useState(false)
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const LDFlags = useFlags()

  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const handleCreatePostBtnClick = () => {
    setNewPostWizard(true)
  }

  const fetchPostData = useCallback(() => {
    const request = {
      size: itemsPerPage,
      start: (page - 1) * itemsPerPage
    }

    setLoading(true)
    get(
      '/whatsnew/v1/manage/blogs',
      request,
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        setErrorMessage(null)
        setLoading(false)
        if (response?.data?.blogs) {
          const formattedData = response?.data?.blogs || []
          setViewData(
            formattedData.map((val) => ({
              ...val,
              serviceCategoryList: join(val?.serviceCategory, ', '),
              publishedDate: convertUTCToLocalTime(val?.publishDate),
              updatedAt: convertUTCToLocalTime({
                date: val?.updatedAt.substring(0, 10),
                time: val?.updatedAt.substring(11)
              })
            }))
          )
          if (!isEmpty(formattedData)) {
            setViewCreateButton(true)
          } else {
            setViewCreateButton(false)
          }
          setLoading(false)
          setTotalItems(response.data.totalCount)
        }
      },
      (error) => {
        setViewData([])
        setLoading(false)
        setErrorMessage(getErrorMessage(error, t))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, page, refreshCount, t])

  useEffect(() => {
    fetchPostData()
  }, [fetchPostData, refreshCount])

  // const navigate = useNavigate()
  const handleClickRow = (datum) => {
    navigate(
      `/manage-ccs/whats-new/${datum.blogID}?edit=false&state=false&approval=false`
    )
  }

  const columns = [
    {
      property: 'title',
      type: 'string',
      header: t('whats_new.create_post_form.title_field_label'),
      render: (datum) => (
        <Typography type="text" size="medium" emphasis testId="whatsNew-title">
          {datum.title}
        </Typography>
      )
    },
    {
      property: 'shortDescription',
      type: 'string',
      header: t('whats_new.create_post_form.description'),
      render: (datum) => (
        <Typography
          type="paragraph"
          testId="account-details-title"
          size="medium"
          truncate
        >
          {datum.shortDescription}
        </Typography>
      )
    },
    {
      property: 'serviceCategoryList',
      type: 'string',
      header: t('whats_new.create_post_form.service_catalog')
    },
    {
      property: 'publisher',
      type: 'string',
      header: t('whats_new.create_post_form.publisher')
    },
    {
      property: 'publishedDate',
      type: 'date',
      header: t('whats_new.create_post_form.publish_date'),
      render: (datum) => (
        <>
          {datum?.active ? (
            <Typography type="text" size="medium">
              {datum?.publishedDate}
            </Typography>
          ) : (
            <Tip
              content={
                <Typography type="text">
                  {t('whats_new.create_post_form.scheduled_desc', {
                    publishedDate: datum?.publishedDate
                  })}
                </Typography>
              }
            >
              <Box direction="row">
                <Typography
                  type="text"
                  size="medium"
                  icon={<FormSchedule />}
                  reverse
                >
                  {datum?.publishedDate}
                </Typography>
              </Box>
            </Tip>
          )}
        </>
      )
    },
    {
      property: 'updatedAt',
      type: 'date',
      header: t('whats_new.create_post_form.updated_at')
    },
    {
      property: '',
      header: t('whats_new.create_post_form.actions'),
      render: (datum) => (
        <ActionButton
          actions={[
            ...(!LDFlags['glcp-whatsnew-approval']
              ? [
                  {
                    label: t('whats_new.edit_post'),
                    visibility: {
                      rbac: {
                        permission: 'ccs.whats-new.edit',
                        resource: '/ccs/whats-new'
                      }
                    },
                    onClick: () => {
                      navigate(
                        `/manage-ccs/whats-new/${datum.blogID}?edit=true&state=false&approval=false`
                      )
                    },
                    testId: 'edit-post-btn'
                  }
                ]
              : []),
            {
              label: t('whats_new.delete_post'),
              visibility: {
                rbac: {
                  permission: 'ccs.whats-new.delete',
                  resource: '/ccs/whats-new'
                }
              },
              onClick: () => {
                setSelectedRowData(datum)
                setDeleteModel(true)
              },
              testId: 'delete-post-btn'
            }
          ]}
          showOneActionAsDropDown
          testId="post-table-action-btn"
          customRenderer={customRenderer}
        />
      )
    }
  ]

  return (
    <Box pad={{ horizontal: 'xxsmall', top: 'small' }}>
      {loading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="programs-loader" />
        </Box>
      ) : (
        <>
          {!isEmpty(viewData) ? (
            <Box direction="column" gap="medium">
              <Box overflow="auto">
                <DataTable
                  grid={{
                    columns,
                    resizeable: true,
                    data: viewData,
                    onClickRow: (e) => handleClickRow(e.datum)
                  }}
                  pagination={{
                    totalItems,
                    itemsPerPage,
                    page,
                    setPage,
                    pageIdxInfo
                  }}
                  testId="whats-new-datatable"
                />
              </Box>
            </Box>
          ) : (
            <Box pad={{ vertical: 'large' }}>
              <NoDataInfo
                action={
                  <VisibilityWrapper
                    rbac={{
                      permission: 'ccs.whats-new.edit',
                      resource: '/ccs/whats-new'
                    }}
                  >
                    <Button
                      margin={{ top: 'medium' }}
                      label={t('whats_new.create_post_btn')}
                      primary
                      testId="create-post-btn"
                      onClick={handleCreatePostBtnClick}
                    />
                  </VisibilityWrapper>
                }
                icon={<Dropbox size="large" color="text-weak" />}
                subtitle={t('whats_new.no_data')}
                testId="no-available-posts"
              />
            </Box>
          )}
        </>
      )}

      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical size="medium" />}
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
        />
      )}
      {successMessage && (
        <Notification
          onClose={() => setSuccessMessage(false)}
          testId="status-good-notification"
          text={successMessage}
        />
      )}
      {newPostWizard && (
        <CreatePostWizard
          refreshDataTable={() => fetchPostData()}
          setAddPostMessage={setSuccessMessage}
          closeWizard={() => {
            setNewPostWizard(false)
          }}
        />
      )}
      {showDeleteModel && (
        <DeletePost
          postData={selectedRowData}
          setErrorMessage={setErrorMessage}
          refreshDataTable={() => fetchPostData()}
          setDeletePostMessage={setSuccessMessage}
          state={false}
          closeDeleteModal={() => {
            setDeleteModel(false)
          }}
        />
      )}
    </Box>
  )
}

WhatsNewDataTable.propTypes = {
  refreshCount: PropTypes.number.isRequired,
  setViewCreateButton: PropTypes.func.isRequired
}
export { WhatsNewDataTable }
