// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useState, useEffect } from 'react'
import { Box } from 'grommet'
import {
  Install,
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusWarningSmall
} from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  DataTable,
  Loader,
  NoDataInfo,
  Typography
} from '../../../../components'
import { get } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  getPaginationShowIdx,
  getDeviceTypeLabel
} from '../../../../utils/common-utils'
import { getDateByMonthAbvDayYear } from '../../../../utils/dm-sm-common-utils'

const AssetsDatatable = () => {
  const { t } = useTranslation(['device', 'manage', 'common'])
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const [assets, setAssets] = useState([])
  const [searchString, setSearchString] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showNoData, setShowNoData] = useState(false)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const riskLevelIcon = {
    1: <StatusGoodSmall size="small" color="status-ok" />,
    2: <StatusWarningSmall size="small" color="status-warning" />,
    3: <StatusCriticalSmall size="small" color="status-critical" />
  }

  const columns = [
    {
      property: 'serial',
      type: 'string',
      header: t('manage:asset_management.assets_table.serial'),
      primary: true
    },
    {
      property: 'model',
      type: 'string',
      header: t('manage:asset_management.assets_table.model')
    },
    {
      property: 'category',
      type: 'string',
      header: t('manage:asset_management.assets_table.type'),
      render: (datum) => {
        return getDeviceTypeLabel(t, datum?.category)
      }
    },
    {
      property: 'location.name',
      type: 'string',
      header: t('manage:asset_management.assets_table.location')
    },
    {
      property: 'age',
      type: 'string',
      header: t('manage:asset_management.assets_table.age')
    },
    {
      property: 'warranty.endDate',
      type: 'date',
      header: t('manage:asset_management.assets_table.support_end_date'),
      render: (datum) => {
        const risk = datum?.riskSupportLevel
        return (
          <Box>
            <Typography
              icon={riskLevelIcon[risk]}
              testId="risk-level-icon"
              type="text"
              truncate="tip"
            >
              {getDateByMonthAbvDayYear(datum.warranty.endDate)}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'warranty.supportStatus',
      type: 'string',
      header: t('manage:asset_management.assets_table.support'),
      render: (datum) => {
        if (datum.warranty.supportStatus) {
          return t('manage:asset_management.assets_table.active')
        }
        return t('manage:asset_management.assets_table.inactive')
      }
    },
    {
      property: 'warranty.serviceLevel',
      type: 'string',
      header: t('manage:asset_management.assets_table.support_level')
    },
    {
      property: 'manufactured_date',
      type: 'date',
      header: t('manage:asset_management.assets_table.manufactured_date'),
      render: (datum) => {
        return getDateByMonthAbvDayYear(datum.manufacturedDate)
      }
    }
  ]

  useEffect(() => {
    get(
      `/internal-assets/v1beta1/assets`,
      {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        ...(searchString?.length && {
          searchString
        })
      },
      oidcUser.access_token
    )
      .then(
        (response) => {
          setTotalItems(response?.data?.total)
          if (response?.data?.items?.length) setShowNoData(true)
          if (response?.data?.items) {
            setAssets(response?.data?.items)
          } else {
            setAssets([])
          }
        },
        (error) => {
          setAssets([])
          setErrorMessage(error, t)
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [oidcUser.access_token, t, page, searchString, itemsPerPage])

  const handleTableRowClick = (item) => {
    navigate(`/manage-account/asset-management/assets/${item?.id}`)
  }

  const getAssets = () => {
    if (!showNoData) {
      return (
        <NoDataInfo
          subtitle={t('no_data_found')}
          icon={<Install size="large" />}
          testId="no-data-assets"
        />
      )
    }
    return (
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          setItemsPerPage,
          page,
          setPage,
          pageIdxInfo,
          defaultRowsValue: 10,
          rowCountOptions: [10, 20, 50, 100]
        }}
        grid={{
          columns,
          data: assets,
          onClickRow: (data) => {
            handleTableRowClick(data?.datum)
          }
        }}
        search={{
          onSearchValueChange: (val) => {
            setPage(1)
            setSearchString(val)
          },
          placeholder: t(
            'manage:asset_management.assets_table.search_placeholder'
          ),
          responsive: true
        }}
        summary={{
          entityName: 'items'
        }}
        testId="assets-table"
      />
    )
  }
  return (
    <>
      {errorMessage && displayApiError(errorMessage, t, setErrorMessage)}
      <Box>
        {isLoading ? (
          <Box direction="row" align="center" justify="center">
            <Loader testId="assets-loader" />
          </Box>
        ) : (
          getAssets()
        )}
      </Box>
    </>
  )
}

export { AssetsDatatable }
