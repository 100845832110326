// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { isEmpty } from 'lodash'

import {
  Button,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import { del, get, getErrorMessage } from '../../../../utils/api-utils'
import Markdown from '../../../../commoncomponents/Markdown'

const RemoveUserModal = ({ onSetOpen, userToRemove, setNotificationInfo }) => {
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['iam', 'common'])
  const [user, setUser] = useState(userToRemove)
  const [loading, setLoading] = useState(false)
  const [sourceLoading, setSourceLoading] = useState(false)

  const isOwnAccount = userToRemove?.userName === oidcUser?.profile?.email
  const closeModal = () => {
    onSetOpen(false)
  }

  useEffect(() => {
    let isCurrent = true

    const getUserSource = async () => {
      try {
        setSourceLoading(true)
        const userDomain = user?.userName?.split('@')[1]
        const ssoRoutingRuleData = await get(
          `/internal-identity/v1alpha1/sso-routing-rules?domain=${userDomain}`
        )

        if (ssoRoutingRuleData?.data?.items) {
          const ssoProfileId =
            ssoRoutingRuleData?.data?.items[0]?.actions?.idp?.ssoProfileId
          if (ssoProfileId) {
            const ssoProfileData = await get(
              `/identity/v1alpha1/sso-profiles/${ssoProfileId}`
            )
            if (!isCurrent) return
            setUser({
              ...user,
              sourceId: ssoProfileData?.data?.id
            })
          }
        }
        setSourceLoading(false)
      } catch (error) {
        if (!isCurrent) return
        setNotificationInfo(
          getErrorMessage(error, t),
          'error',
          t('iam:igc_users.details_section_get_source_failed_title')
        )
        setSourceLoading(false)
      }
    }

    if (!isOwnAccount) {
      getUserSource()
    }
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const userName = user?.displayName || user?.userName
  const getModalContent = () => {
    let content
    if (isOwnAccount) {
      content = (
        <Typography type="text" margin={{ top: 'medium' }}>
          {t('iam:igc_users.remove_user_own_account_not_allowed_msg')}
        </Typography>
      )
    } else if (loading || sourceLoading) {
      content = (
        <Box
          fill
          align="center"
          justify="center"
          pad={{ vertical: 'small', horizontal: 'large' }}
        >
          <Loader
            orientation="horizontal"
            label={sourceLoading ? t('common:loading') : t('common:removing')}
            testId="remove-user-loader"
          />
        </Box>
      )
    } else {
      content = (
        <Box width="medium" margin={{ top: 'medium' }}>
          <Markdown>
            {isEmpty(user?.sourceId)
              ? t('iam:igc_users.remove_local_user_msg', {
                  userName
                })
              : t('iam:igc_users.remove_federated_user_msg', {
                  userName
                })}
          </Markdown>
        </Box>
      )
    }
    return content
  }
  const removeUser = async () => {
    setLoading(true)
    del(`/identity/v2alpha1/scim/v2/Users/${user?.id}`).then(
      () => {
        navigate('/manage-account/organization/users', {
          state: {
            notificationTitle: t('iam:igc_users.remove_user_success_title'),
            notificationText: t('iam:igc_users.remove_user_success_msg_v2', {
              userName
            })
          }
        })
      },
      (error) => {
        closeModal()
        setLoading(false)
        setNotificationInfo(
          getErrorMessage(error, t),
          'error',
          t('iam:igc_users.remove_user_failed_msg')
        )
      }
    )
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2">
              {t('iam:igc_users.remove_user_title')}
            </Typography>
          }
        />
      }
      content={getModalContent()}
      footer={
        !loading && !sourceLoading && !isOwnAccount ? (
          <ModalFooter
            right={
              <Box direction="row" gap="medium">
                <Button
                  label={t('common:cancel')}
                  onClick={closeModal}
                  testId="remove-igc-user-cancel-btn"
                />
                <Button
                  primary
                  label={t('iam:igc_users.remove_user_btn')}
                  onClick={removeUser}
                  testId="remove-igc-user-remove-btn"
                />
              </Box>
            }
          />
        ) : (
          <ModalFooter
            right={
              <Box direction="row" gap="medium">
                <Button
                  primary
                  label={t('common:close')}
                  onClick={closeModal}
                  testId="remove-igc-user-close-btn"
                />
              </Box>
            }
          />
        )
      }
      onClose={closeModal}
      onClickOutside={closeModal}
      testId="remove-igc-user-modal"
    />
  )
}

RemoveUserModal.propTypes = {
  /**
   * Callback function to close modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * User to remove
   */
  userToRemove: PropTypes.object.isRequired,

  /**
   * set notification info
   */
  setNotificationInfo: PropTypes.func.isRequired
}

export default RemoveUserModal
