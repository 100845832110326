// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Button } from 'grommet'
import { ShareRounded } from 'grommet-icons'
import PropTypes from 'prop-types'

import { INTERNAL_REDIRECT_SERVICES } from '../../../../dashboard/constants'

const StaticLaunchButton = ({
  serviceSlug,
  staticLaunchUrl,
  setRecentServices,
  t
}) => (
  <Button
    primary
    data-testid={`${serviceSlug}-service-detail-static-launch-button`}
    label={t('dashboard:common.launch')}
    icon={
      !INTERNAL_REDIRECT_SERVICES.includes(serviceSlug) ? <ShareRounded /> : ''
    }
    reverse
    href={staticLaunchUrl}
    target={
      INTERNAL_REDIRECT_SERVICES.includes(serviceSlug) ? '_self' : '_blank'
    }
    onClick={() => {
      setRecentServices(serviceSlug)
    }}
  />
)

StaticLaunchButton.propTypes = {
  serviceSlug: PropTypes.string.isRequired,
  staticLaunchUrl: PropTypes.string.isRequired,
  setRecentServices: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export default StaticLaunchButton
