// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormField } from 'grommet'

import { Button, FormOverallAlert, Typography } from '../../../../components'

export const AddFormSubscription = ({
  fields,
  addBtnLabel,
  onAdd,
  errorMessage,
  heading
}) => {
  const formFieldEls =
    fields &&
    fields.map(({ label, name, error, valueComp, testId }, idx) => {
      return (
        <FormField
          key={idx} /* eslint-disable-line react/no-array-index-key */
          data-testid={`${testId}-form-field`}
          label={label}
          name={name}
          htmlFor={name}
          width="medium"
          error={error}
        >
          {valueComp}
        </FormField>
      )
    })

  return (
    <>
      <Box border={{ side: 'bottom', color: 'border-weak' }}>
        <Typography level="2" type="heading" testId="selected-tags-header">
          {heading}
        </Typography>
      </Box>
      <Box
        direction="row"
        gap="small"
        margin={{ top: 'medium', bottom: 'xxsmall' }}
      >
        {formFieldEls}
        <Box width="small" margin={{ top: 'xsmall' }}>
          <Button
            testId="enter-btn"
            label={addBtnLabel}
            secondary
            onClick={() => onAdd()}
            margin={{ top: 'medium' }}
          />
        </Box>
      </Box>
      {errorMessage?.length > 0 && <FormOverallAlert message={errorMessage} />}
    </>
  )
}

AddFormSubscription.propTypes = {
  /**
   * Array of objects representing label and value component
   */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      valueComp: PropTypes.element.isRequired
    }).isRequired
  ).isRequired,

  /**
   * button label for add/assign button
   */
  addBtnLabel: PropTypes.string.isRequired,

  /**
   * Add handler
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Error Message
   */
  errorMessage: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired
}
