// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { React, Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const AddDevicesToLicenses = lazy(() =>
  import('./pages/licenses/add-devices-to-licenses/AddDevicesToLicenses')
)
const LicenseDetailsPage = lazy(() =>
  import('./pages/licenses/license-details/LicenseDetails')
)
const Subscriptions = lazy(() => import('./Subscriptions'))

const OrderDetailsPage = lazy(() =>
  import('./pages/order-history/order-details/OrderDetails')
)

const SubscriptionsRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route
          exact
          path="/device-subscriptions/:licenseKey"
          element={<LicenseDetailsPage />}
        />
        <Route
          exact
          path="/device-subscriptions/:licenseKey/add-devices-to-subscription/*"
          element={<AddDevicesToLicenses />}
        />
        <Route
          exact
          path="/order-history/:referenceId"
          element={<OrderDetailsPage />}
        />
        <Route path="/*" element={<Subscriptions />} />
      </Routes>
    </Suspense>
  )
}

export default SubscriptionsRouter
