// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import isEmpty from 'lodash/isEmpty'
/* eslint-enable */
import {
  Box,
  FormField,
  TextInput,
  Select,
  Anchor,
  CheckBox,
  Markdown
} from 'grommet'
import styled from 'styled-components'
import { CircleInformation, FormPrevious, StatusWarning } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Button,
  Typography,
  CCSForm,
  Loader,
  Notification
} from '../../../components'
import useLD from '../../../hooks/ld/useLD'
import { get, post, getErrorMessage } from '../../../utils/api-utils'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { validateEmail } from '../../../utils/validation-utils'
import { loadCustomerAccount } from '../../../utils/account-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import { useUPSContext } from '../../../context/ups-context'
import { WORKSPACE_TYPE } from '../../manage-account/account-details/constants'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED,
  LIFECYCLE_STATE
} from '../../../utils/common-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { GTS_STATUS_ENUM, hashFunction } from '../utils'
import {
  getCustomerAccount,
  getOrganizationId
} from '../../../utils/feature-flag-utils'
import useLogger from '../../../hooks/logs/useLogger'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { v2EndSessionCleanup } from '../../../utils/client-storage-cleaning-utils'
import { useCCSContext } from '../../../context/ccs-context'
import { V2_LOGGER } from '../../../utils/log-util'

import { MspModal } from './msp-modal-confirmation'

//  CCS-1620 - move to common components
const BackButton = () => {
  const { t } = useTranslation(['authn', 'iam'])
  const navigate = useNavigate()

  const ld = useLD()
  const logger = useLogger()
  const v2Logger = {
    ...V2_LOGGER,
    page: BackButton?.name,
    section: 'set-up',
    type: 'info'
  }

  const handleRoute = () => {
    const account = getCustomerAccount()
    if (account) {
      logger?.log({
        ...v2Logger,
        msg: `updating ld context with account data`
      })
      ld.update(account).then(
        () => {
          navigate('/switch-account')
        },
        (error) => {
          console.log(error)
        }
      )
    } else {
      navigate('/post-onboarding/choose-account')
    }
  }
  return (
    <Button
      alignSelf="start"
      icon={<FormPrevious />}
      margin="xsmall"
      gap="xsmall"
      label={t('acct_onboarding.back')}
      onClick={handleRoute}
      testId="back-button"
    />
  )
}

const SetUpAccountForm = () => {
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const ld = useLD()
  const logger = useLogger()
  const { oidcUser } = useReactOidc()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchUPSContext } = useUPSContext()
  const { t } = useTranslation(['common', 'authn', 'iam'])
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const mspWorkspaceCreation = LDFlags['glcp-workspace-creation']
  const iamV2WorkspaceCreation = LDFlags['glcp-iam-v2-workspace']
  const streetAddressField = LDFlags['glcp-street-address-field']
  const { csrfToken, dispatchCCSContext } = useCCSContext()
  const v2Logger = {
    ...V2_LOGGER,
    page: SetUpAccountForm?.name,
    section: 'set-up'
  }

  const [checkedCreateV2workspace, setCheckedCreateV2workspace] =
    useState(false)
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      ...((mspWorkspaceCreation || iamV2WorkspaceCreation) && {
        workspace_type: 'STANDALONE'
      }),
      company_name: '',
      country_code: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip_code: '',
      phone_number: '',
      email: ''
    }
  )
  const AnchorCustom = styled(Anchor)`
    display: inline;
  `
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [account, setAccount] = useState()
  const [pollingStatus, setPollingStatus] = useState(null)
  const [mspModal, setMspModal] = useState(false)
  const [isActiveOrg, setIsActiveOrg] = useState(false)
  const [isRootWorkspace, setIsRootWorkspace] = useState(false)
  const [errorNotification, setErrorNotification] = useState(null)
  const [loading, setLoading] = useState(false)

  const orgId = getOrganizationId()
  useEffect(() => {
    if (getCustomerAccount()) {
      const pageInfoLog = {
        page: SetUpAccountForm?.name,
        section: 'Load'
      }
      hashFunction(oidcUser?.profile?.email).then((encryptedEmail) => {
        ld.update({ platform_customer_id: encryptedEmail }).then(
          (LDFlagsResolved) => {
            if (LDFlagsResolved && LDFlagsResolved !== 'failed') {
              logger?.log({
                ...pageInfoLog,
                msg: `LD updated with user Context`,
                data: { LDFlagsResolved }
              })
            }
          },
          (error) => {
            logger?.log({
              ...pageInfoLog,
              msg: `LD update failed`,
              data: { error },
              type: 'error'
            })
          }
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iamV2WorkspaceCreation])

  useEffect(() => {
    if (
      iamV2WorkspaceCreation &&
      orgId &&
      orgId !== '' &&
      sessionStorage?.getItem('account')
    ) {
      // Check if the user is coming from the switch workspace page and is in the root workspace in an active org
      setLoading(true)
      get(`/organizations/v2alpha1/organizations/${orgId}`)
        .then((response) => {
          const organization = response?.data
          if (organization?.lifecycleState === LIFECYCLE_STATE.ACTIVE) {
            const { platform_customer_id } = JSON.parse(
              sessionStorage?.getItem('account')
            )
            if (platform_customer_id === organization?.workspaceId) {
              setIsRootWorkspace(true)
            }
          }
          setLoading(false)
        })
        .catch((error) => {
          setErrorNotification(displayApiError(error, t, setErrorNotification))
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (iamV2WorkspaceCreation) {
      setLoading(true)
      get(`/organizations/v2alpha1/organizations`, {}, oidcUser?.access_token)
        .then((response) => {
          const activeOrgData = response.data?.items.find(
            (item) => item.lifecycleState === LIFECYCLE_STATE.ACTIVE
          )
          if (activeOrgData) {
            setIsActiveOrg(true)
          }
          setLoading(false)
        })
        .catch((error) => {
          setErrorNotification(displayApiError(error, t, setErrorNotification))
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const customRender = (datam) => {
    return (
      <Box>
        <Box pad="small">
          <Typography type="text" emphasis>
            {datam.name}
          </Typography>
          <Typography type="text">{datam.description}</Typography>
        </Box>
      </Box>
    )
  }
  const returnToPreviousPage = () => {
    if (getCustomerAccount()) {
      navigate('/switch-account')
    } else {
      navigate('/post-onboarding/choose-account', {
        state: { refresh: true }
      })
    }
  }

  const createWorkspaceWarning = (rootWkspc = false) => {
    return (
      <Notification
        type="inline"
        margin={{ top: 'xsmall' }}
        icon={<StatusWarning color="status-warning" size="large" />}
        backgroundColor="status-warning"
        testId="edit-warning-workspace-creation-notification"
        status="warning"
        text={
          <>
            <Typography type="text">
              <Markdown
                components={{
                  a: {
                    component: Anchor,
                    props: {
                      onClick: (event) => {
                        event?.preventDefault()
                        if (rootWkspc) {
                          navigate('/manage-account/organization/workspaces', {
                            state: { showSidePanel: true }
                          })
                        } else {
                          dispatchEvent(new Event('context-help'))
                        }
                      }
                    }
                  }
                }}
                data-testid={
                  rootWkspc ? 'root-wkspce-warning' : 'non-root-wkspce-warning'
                }
              >
                {rootWkspc
                  ? t('iam:create_workspace.root_workspace_warning_msg')
                  : t('iam:create_workspace.active_org_warning_msg_v2', {
                      workspaces: t('common:business_object:wkspc_plural'),
                      workspace: t('common:business_object:wkspc')
                    })}
              </Markdown>
            </Typography>
            <Typography type="text">
              {t('iam:create_workspace.continued_warning_msg')}
            </Typography>
          </>
        }
      />
    )
  }

  const getCreateWorkspaceWarning = () => {
    let warning = null
    if (!loading) {
      // if user belong to active org
      if (isActiveOrg) {
        // if switch account && root workspace
        if (!isEmpty(orgId) && isRootWorkspace) {
          warning = (
            <VisibilityWrapper
              rbac={{
                permission: 'organization.workspace.create'
              }}
              fallbackComponent={createWorkspaceWarning()}
            >
              {createWorkspaceWarning(true)}
            </VisibilityWrapper>
          )
        } else {
          warning = createWorkspaceWarning()
        }
      }
    }
    return warning
  }

  const handleAPIError = (error) => {
    const backendErrorMessage = getErrorMessage(error, t)
    setErrorMessage(backendErrorMessage)
    setSubmitted(false)
  }

  const loadAccountAndUpdateLD = (custAccount) => {
    loadCustomerAccount(
      custAccount,
      oidcUser,
      dispatchVisibilityContext,
      navigate,
      csrfToken,
      dispatchCCSContext,
      ld,
      dispatchUPSContext,
      logger
    ).then(
      () => {
        ld.update(custAccount)
      },
      (error) => {
        if (
          error?.response?.status === 451 ||
          error?.response?.status === 423
          // Status Code 451 represents account is BLOCKED/CONFIRMED_BLOCK and status 423 means PENDING/TIMEOUT
        ) {
          if (sessionStorage.getItem('account')) {
            navigate('/switch-account')
          } else {
            navigate('/post-onboarding/choose-account?blocked=true')
          }
        } else {
          handleAPIError(error)
        }
      }
    )
  }

  const loadV2Account = async (custAccount) => {
    const V2_POLLING_MAX_RETRIES = 4
    const V2_POLLING_INTERVAL = 15000
    const V2_POLLING_INITIAL_DELAY = 5000
    const retryAndLoadAccount = (numRetry) => {
      get(
        `/accounts/ui/v1/customer/list-accounts?search_string=${encodeURIComponent(
          custAccount?.company_name
        )}`,
        {},
        oidcUser.access_token
      ).then(
        async (response) => {
          const newAccount = response?.data?.customers?.find(
            (customer) => customer?.company_name === custAccount?.company_name
          )
          if (newAccount) {
            if (newAccount?.organization_id) {
              loadAccountAndUpdateLD(newAccount)
            } else if (numRetry <= 1) {
              v2EndSessionCleanup()
              navigate('/post-onboarding/choose-account', {
                state: {
                  apiError: {
                    error: {},
                    customErrMsg: t(
                      'authn:org_launch.timeout_for_workspace_ready',
                      {
                        workspace: t('common:business_object:wkspc'),
                        name: custAccount?.company_name
                      }
                    ),
                    markdown: true,
                    severity: 'critical'
                  }
                }
              })
            } else {
              await new Promise((resolveDetails) =>
                setTimeout(resolveDetails, V2_POLLING_INTERVAL)
              )
              retryAndLoadAccount(numRetry - 1)
            }
          }
        },
        (error) => {
          handleAPIError(error)
        }
      )
    }
    // delay a bit before poll
    await new Promise((resolveDetails) =>
      setTimeout(resolveDetails, V2_POLLING_INITIAL_DELAY)
    )
    retryAndLoadAccount(V2_POLLING_MAX_RETRIES)
  }

  const loadAccount = (custAccount = account) => {
    if (!checkedCreateV2workspace) {
      loadAccountAndUpdateLD(custAccount)
    } else {
      loadV2Account(custAccount)
    }
  }

  const startTime = useRef(null)
  // startTime is used to stop polling after 60 seconds.

  useEffect(() => {
    let refreshTimer = null
    // Polling continues only for status PENDING or TIMEOUT
    const polling = () => {
      if (
        pollingStatus === GTS_STATUS_ENUM.PENDING ||
        pollingStatus === GTS_STATUS_ENUM.TIMEOUT
      ) {
        get(
          `/internal-gts-compliance/v1alpha1/records/${account?.gts_id}`,
          {},
          oidcUser.access_token
        ).then(
          (response) => {
            if (response?.status === 200) {
              const gtsStatus = response?.data?.gts_status
              if (gtsStatus === GTS_STATUS_ENUM.APPROVED) {
                // For APPROVED status, account will be launched.
                loadAccount()
              } else if (
                gtsStatus === GTS_STATUS_ENUM.BLOCKED ||
                gtsStatus === GTS_STATUS_ENUM.CONFIRMED_BLOCKED
              ) {
                // For BLOCKED & CONFIRMED_BLOCKED status, user will go back to previous page.
                returnToPreviousPage()
              } else if (gtsStatus === GTS_STATUS_ENUM.PENDING) {
                // For PENDING status, polling will continue.
                setPollingStatus(gtsStatus)
              } else {
                // This Case should never happen -  Assert
                throw Error(
                  `Invalid GTS Status is returned from API: ${gtsStatus} `
                )
              }
            }
          },
          (error) => {
            const errorStatus = error?.response?.status
            // This is Timeout case.
            if (
              errorStatus === 503 ||
              errorStatus === 504 ||
              errorStatus === 304
            ) {
              // For TIMEOUT status, polling will continue.
              setPollingStatus(GTS_STATUS_ENUM.TIMEOUT)
            } else {
              // All other error scenario.
              const customErrMsg = `${account?.company_name}: ${t(
                'authn:gtcaas.gts_pending_message'
              )}`
              const url = getCustomerAccount()
                ? '/switch-account'
                : '/post-onboarding/choose-account'
              navigate(url, {
                state: {
                  refresh: true,
                  apiError: {
                    error: error.message,
                    customErrMsg,
                    severity: 'warning'
                  }
                }
              })
            }
          }
        )
        refreshTimer = setTimeout(() => {
          if (new Date().getTime() - startTime.current > 60000) {
            clearTimeout(refreshTimer)
            // After 60 seconds, polling will stop and user goes back to previous page.
            returnToPreviousPage()
            return
          }
          polling()
        }, 3000)
      }
    }
    polling()
    return () => {
      clearTimeout(refreshTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingStatus, oidcUser.access_token])

  useEffect(() => {
    let params = { status: 'AVAILABLE' }
    get('/ui-doorway/ui/v1/status', {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response?.data?.flags?.gts_compliance_flag) {
            params = {}
          }
        },
        (error) => {
          const backendErrorMessage = getErrorMessage(error, t)
          setErrorMessage(backendErrorMessage)
        }
      )
      .finally(() => {
        get('/geo/ui/v1/countries', params).then(
          (response) => {
            setListOfCountries(response.data.countries)
            setCountries(response.data.countries)
          },
          (error) => {
            const backendErrorMessage = getErrorMessage(error, t)
            setErrorMessage(backendErrorMessage)
          }
        )
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token])

  const doPostAccount = (requestBody) => {
    post(
      '/accounts/ui/v1/customer/signup',
      requestBody,
      oidcUser.access_token
    ).then(
      (response) => {
        const custAccount = response?.data
        setAccount(custAccount)
        if (LDFlags['glcp-gtcaas']) {
          if (response?.data?.gts_id) {
            startTime.current = new Date().getTime()
            setPollingStatus(GTS_STATUS_ENUM.PENDING)
          } else {
            returnToPreviousPage()
          }
        } else {
          loadAccount(custAccount)
        }
        logger?.log({
          ...v2Logger,
          msg: `signup successful.`,
          type: 'info',
          data: requestBody
        })
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
        logger?.log({
          ...v2Logger,
          msg: `Error while signing up`,
          data: backendErrorMessage,
          type: 'error'
        })
        setSubmitted(false)
      }
    )
  }

  const workspaceTypes = [
    {
      name: t('authn:customer_account.standalone_option'),
      value: WORKSPACE_TYPE.STANDALONE,
      description: t('iam:create_workspace.standard_workspace_desc')
    },
    ...(mspWorkspaceCreation
      ? [
          {
            name: t('authn:customer_account.msp_option'),
            value: WORKSPACE_TYPE.MSP,
            description: t('iam:create_workspace.msp_workspace_desc')
          }
        ]
      : [])
  ]
  const handleSetUpAccount = () => {
    setErrorMessage('')
    setSubmitted(true)
    const requestBody = {
      ...((mspWorkspaceCreation || iamV2WorkspaceCreation) && {
        workspace_type: state.workspace_type
      }),
      email: state?.email,
      company_name: state?.company_name,
      address: {
        street_address: state?.street_address,
        street_address_2: state?.street_address_2,
        city: state?.city?.trim(),
        state_or_region: state?.state?.trim(),
        zip: state?.zip_code?.trim(),
        country_code: state?.country_code
      },
      phone_number: state?.phone_number,
      default_country_code: false,
      customer_logo: {
        logo: null,
        logo_filename: null
      },
      iam_v2_workspace:
        state.workspace_type === WORKSPACE_TYPE.MSP
          ? false
          : checkedCreateV2workspace
    }
    setTimeout(() => {
      doPostAccount(requestBody)
    }, 100)
  }

  const setUpAccountButton = (
    <Button
      primary
      type="submit"
      label={t('authn:customer_account.createAccount', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      })}
      fill="horizontal"
      size="large"
      margin={{ vertical: 'small' }}
      testId="set-up-account-submit"
      isLoading={submitted}
    />
  )

  return (
    <>
      {errorNotification}
      <BackButton />
      {loading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="set-up-account-loader" />
        </Box>
      ) : (
        <Box align="center" justify="center">
          <Box width="medium">
            <Typography
              type="heading"
              level="1"
              size="medium"
              margin={{ top: 'medium', bottom: 'small' }}
              testId="set-up-account-header"
            >
              {t('iam:create_workspace.create_workspace_header', {
                company: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
            </Typography>

            <Typography
              type="text"
              size="medium"
              margin={{ bottom: 'xxsmall' }}
              testId="set-up-account-subheader"
            >
              <Markdown
                components={{
                  a: ({ children }) => (
                    <AnchorCustom
                      label={children}
                      onClick={() => dispatchEvent(new Event('context-help'))}
                    />
                  )
                }}
              >
                {t('iam:create_workspace.create_workspace_sub_header', {
                  account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                })}
              </Markdown>
            </Typography>

            <CCSForm
              value={state}
              buttons={setUpAccountButton}
              onSubmit={handleSetUpAccount}
              errorMessage={errorMessage}
              validate="blur"
              testId="set-up-account-form"
            >
              <>
                {(mspWorkspaceCreation || iamV2WorkspaceCreation) && (
                  <FormField
                    label={t('iam:create_workspace.create_workspace_label', {
                      company: getWorkspaceString(
                        showWorkspaceString,
                        t,
                        WKSPC_CAPITALIZED
                      )
                    })}
                    name="workspace_type"
                    required
                    data-testid="set-up-account-workspace-type-form-field"
                    disabled={submitted}
                  >
                    <Select
                      name="workspace_type"
                      placeholder={t('common:select')}
                      options={workspaceTypes}
                      dropProps={{ width: 'small' }}
                      multiple={false}
                      labelKey="name"
                      value={state.value}
                      valueKey={{ key: 'value', reduce: true }}
                      onChange={({ option }) => {
                        if (option.value === WORKSPACE_TYPE.MSP) {
                          setMspModal(true)
                          dispatch({
                            value: option.value,
                            field: 'workspace_type',
                            type: 'CHANGE_FIELD'
                          })
                        } else {
                          dispatch({
                            value: option.value,
                            field: 'workspace_type',
                            type: 'CHANGE_FIELD'
                          })
                        }
                      }}
                      data-testid="set-up-account-workspace-type-select"
                      disabled={submitted}
                    >
                      {customRender}
                    </Select>
                  </FormField>
                )}
                {state.workspace_type === WORKSPACE_TYPE.STANDALONE &&
                  iamV2WorkspaceCreation && (
                    <Box>
                      <FormField
                        name="account_iam_v2_terms"
                        data-testid="set-up-account-type-form-field"
                        contentProps={{ border: false }}
                      >
                        <CheckBox
                          pad="none"
                          data-testid="set-up-account-type-checkbox"
                          name="account_terms"
                          checked={checkedCreateV2workspace}
                          disabled={submitted}
                          label={t(
                            'iam:create_workspace.create_workspace_iam_desc'
                          )}
                          onChange={(event) => {
                            setCheckedCreateV2workspace(event.target.checked)
                            dispatch({
                              value: event.target.checked
                            })
                          }}
                        />
                      </FormField>
                      {getCreateWorkspaceWarning()}
                    </Box>
                  )}
                <FormField
                  label={t('iam:create_workspace.workspace_name_label', {
                    company: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                  name="company_name"
                  required
                  data-testid="set-up-account-company-name-form-field"
                  disabled={submitted}
                >
                  <TextInput
                    value={state.company_name}
                    name="company_name"
                    data-testid="set-up-account-company-name-input"
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'company_name',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    disabled={submitted}
                    maxLength={100}
                  />
                </FormField>
                <FormField
                  label={t('iam:create_workspace.workspace_country_label', {
                    company: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                  name="country_code"
                  required
                  data-testid="set-up-account-country-form-field"
                  disabled={submitted}
                >
                  <Select
                    name="country_code"
                    placeholder={t('common:select')}
                    options={countries}
                    multiple={false}
                    labelKey="name"
                    value={state.country_code}
                    valueKey={{ key: 'code', reduce: true }}
                    searchPlaceholder={t('authn:customer_account.country')}
                    emptySearchMessage={t(
                      'authn:customer_account.country_empty_search_message'
                    )}
                    onSearch={(searchText) => {
                      const regexp = new RegExp(searchText, 'i')
                      setCountries(
                        listOfCountries.filter((o) => o.name.match(regexp))
                      )
                    }}
                    onChange={({ option }) => {
                      dispatch({
                        value: option.code,
                        field: 'country_code',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    onClose={() => setCountries(listOfCountries)}
                    data-testid="set-up-account-country-select"
                    disabled={submitted}
                  />
                </FormField>
                <FormField
                  required={!streetAddressField}
                  label={t('iam:igc_workspaces.street_address_label')}
                  name="street_address"
                  margin={{ bottom: 'none' }}
                  data-testid="set-up-account-street-address-form-field"
                  disabled={submitted}
                >
                  <TextInput
                    value={state.street_address}
                    name="street_address"
                    data-testid="set-up-account-street-address-input"
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'street_address',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    disabled={submitted}
                    maxLength={220}
                  />
                </FormField>
                {LDFlags['glcp-msp-add-customer-field'] ? (
                  <FormField
                    label={t('iam:create_workspace.street_address_2_label')}
                    name="street_address_2"
                    help={t('iam:create_workspace.street_address_2_sub_label')}
                    margin={{ bottom: 'none' }}
                    data-testid="set-up-account-street-address-2-form-field"
                    disabled={submitted}
                  >
                    <TextInput
                      value={state.street_address_2}
                      name="street_address_2"
                      data-testid="set-up-account-street-address-2-input"
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'street_address_2',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={submitted}
                      maxLength={220}
                    />
                  </FormField>
                ) : null}
                <Box direction="row-responsive" gap="xsmall">
                  <Box direction="column" basis="1/2" height={{ min: 'auto' }}>
                    <FormField
                      label={t('iam:create_workspace.city_label')}
                      name="city"
                      data-testid="set-up-account-city-form-field"
                      disabled={submitted}
                    >
                      <TextInput
                        value={state.city}
                        name="city"
                        data-testid="set-up-account-city-input"
                        onChange={(event) =>
                          dispatch({
                            value: event.target.value,
                            field: 'city',
                            type: 'CHANGE_FIELD'
                          })
                        }
                        disabled={submitted}
                        maxLength={40}
                      />
                    </FormField>
                  </Box>
                  <Box direction="column" basis="1/2" height={{ min: 'auto' }}>
                    <FormField
                      label={t('iam:create_workspace.state_label')}
                      name="state"
                      data-testid="set-up-account-state-form-field"
                      disabled={submitted}
                    >
                      <TextInput
                        value={state.state}
                        name="state"
                        data-testid="set-up-account-state-input"
                        onChange={(event) =>
                          dispatch({
                            value: event.target.value,
                            field: 'state',
                            type: 'CHANGE_FIELD'
                          })
                        }
                        disabled={submitted}
                      />
                    </FormField>
                  </Box>
                </Box>
                <FormField
                  label={t('iam:create_workspace.zip_label')}
                  name="zip_code"
                  data-testid="set-up-account-zip-code-form-field"
                  disabled={submitted}
                >
                  <TextInput
                    value={state.zipCode}
                    name="zip_code"
                    data-testid="set-up-account-zip-code-input"
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'zip_code',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    disabled={submitted}
                    maxLength={10}
                  />
                </FormField>
                <FormField
                  label={t('iam:create_workspace.workspace_phone_label', {
                    company: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                  name="phone_number"
                  data-testid="set-up-account-phone-number-form-field"
                  disabled={submitted}
                >
                  <TextInput
                    value={state.phone_number}
                    name="phone_number"
                    data-testid="set-up-account-phone-number-input"
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'phone_number',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    disabled={submitted}
                  />
                </FormField>
                <Box>
                  <FormField
                    label={t('iam:create_workspace.workspace_email_label')}
                    name="email"
                    validate={(value) => {
                      return validateEmail(value)
                    }}
                    data-testid="set-up-account-email-form-field"
                    disabled={submitted}
                  >
                    <TextInput
                      value={state.email}
                      name="email"
                      data-testid="set-up-account-email-input"
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'email',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={submitted}
                    />
                  </FormField>
                </Box>
                <Box>
                  <FormField
                    margin={{ bottom: 'small' }}
                    name="legal_terms"
                    required
                    data-testid="set-up-account-legal-terms-form-field"
                    disabled={submitted}
                  >
                    <CheckBox
                      pad="none"
                      data-testid="set-up-account-legal-terms-checkbox"
                      name="legal_terms"
                      checked={state.legal_terms}
                      label={
                        <Typography
                          type="text"
                          size="medium"
                          testId="setup-company-terms"
                        >
                          <Trans
                            i18nKey="authn:acct_onboarding.check_legal_terms"
                            t={t}
                          >
                            <Anchor
                              label={t(
                                'authn:acct_onboarding.legal_terms_anchor'
                              )}
                              href="https://www.hpe.com/us/en/about/legal/terms-of-use.html"
                              target="_blank"
                            />
                          </Trans>
                          {/* <>
                          By checking this box, you accept the{' '}
                          <Anchor
                            label={
                              <Text weight="bold" size="xsmall">
                                Legal Terms
                              </Text>
                            }
                            href="https://www.hpe.com/us/en/about/legal/ccs-terms.html"
                            target="_blank"
                          />{' '}
                          on behalf of your organization.
                        </> */}
                        </Typography>
                      }
                      onChange={(event) =>
                        dispatch({
                          value: event.target.checked,
                          field: 'legal_terms',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={submitted}
                    />
                  </FormField>
                </Box>
                {LDFlags['glcp-gtcaas'] && (
                  <Box margin={{ bottom: 'small' }}>
                    <Notification
                      status="info"
                      icon={
                        <Box margin={{ top: 'xxsmall' }}>
                          <CircleInformation color="black" size="medium" />
                        </Box>
                      }
                      testId="status-info-workspace-creation"
                      text={
                        <Typography color="black" type="text">
                          {t('authn:gtcaas.workspace_creation_message')}
                        </Typography>
                      }
                      type="inline"
                    />
                  </Box>
                )}
              </>
            </CCSForm>
            {mspModal && (
              <MspModal
                onSetOpen={setMspModal}
                onSuccess={() => {
                  setMspModal(false)
                  dispatch({
                    value: workspaceTypes[0].value,
                    field: 'workspace_type',
                    type: 'CHANGE_FIELD'
                  })
                }}
              />
            )}
          </Box>
        </Box>
      )}
      {submitted && (
        <Loader
          modal
          testId="loader-modal"
          size="xxsmall"
          modalTitle={t('common:launching')}
          modalSubTitle={t('common:loader_dialog_a_min_message')}
          modalTimeout={1000}
        />
      )}
    </>
  )
}

const SetUpAccountPage = () => {
  return (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps']}>
      <SetUpAccountForm />
    </Layout>
  )
}

export default SetUpAccountPage
