// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes, Navigate } from 'react-router-dom'

import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import SupportFeedbackPageRouter from '../support-feedback/router'
import SearchPageRouter from '../support-search/router'
import CaseCreationPage from '../support-cases/CaseCreationPage'

import SupportPage from './Support'

const SupportPageRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <VisibilityWrapper
            hideFor={{
              feature: 'glcp-support-home',
              deployment: ['COP', 'GLOP']
            }}
            fallbackComponent={<Navigate to="/home" />}
          >
            <SupportPage />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/search/*"
        element={
          <VisibilityWrapper
            hideFor={{
              feature: 'glcp-support-search',
              deployment: ['COP', 'GLOP']
            }}
            fallbackComponent={<Navigate to="/home" />}
          >
            <SearchPageRouter />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/cases/*"
        element={
          <VisibilityWrapper
            hideFor={{
              feature: 'glcp-support-cases',
              deployment: ['COP', 'GLOP']
            }}
            fallbackComponent={<Navigate to="/support" />}
          >
            <CaseCreationPage />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/feedback"
        element={
          <VisibilityWrapper
            hideFor={{
              feature: 'glcp-support-feedback',
              deployment: ['COP', 'GLOP']
            }}
            fallbackComponent={<Navigate to="/home" />}
          >
            <SupportFeedbackPageRouter />
          </VisibilityWrapper>
        }
      />
      <Route path="*" element={<Navigate to="/not-found" />} />
      {/* NOTE: Always add any routes above this route */}
    </Routes>
  )
}
export default SupportPageRouter
