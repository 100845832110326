// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const BillingExportPage = lazy(() => import('./BillingExportPage'))

const BillingExportRouterContent = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route path="/" element={<BillingExportPage />} />
      </Routes>
    </Suspense>
  )
}

const BillingExportRouter = () => {
  return <BillingExportRouterContent />
}

export default BillingExportRouter
