// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Grommet } from 'grommet'
import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { hpe } from 'grommet-theme-hpe'
import { deepMerge } from 'grommet/utils'

import { Typography, NoDataInfo, DataTable } from '../../../../components'
import { get, getErrorMessage } from '../../../../utils/api-utils'

const WorkspaceMembership = ({ setNotificationInfo }) => {
  const { t } = useTranslation(['iam', 'common'])
  const ref = useRef(false)
  const { userId } = useParams()
  const [loading, setLoading] = useState(false)
  const [userWorkspaces, setUserWorkspaces] = useState()

  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [page, setPage] = useState(1)

  const getPageIndexInfo = () => {
    const total = userWorkspaces?.total || 0
    if (total > 0) {
      const startIndex = 1 + (page - 1) * itemsPerPage
      const endIndex = itemsPerPage * page < total ? itemsPerPage * page : total
      return t('common:data_table_showing_label', {
        startIndex,
        endIndex,
        total
      })
    }
    return ''
  }

  useEffect(() => {
    ref.current = true
    setLoading(true)
    const getUserWorkspaces = async () => {
      const queryFilter = encodeURI(`filter=userId eq '${userId}'`)
      await get(
        `/organizations/v2alpha1/workspaces?${queryFilter}&offset=${
          (page - 1) * itemsPerPage
        }&limit=${itemsPerPage}`
      ).then(
        (response) => {
          if (!ref.current) return
          if (response?.data?.items) {
            setUserWorkspaces(response?.data)
          }
          setLoading(false)
        },
        (error) => {
          if (!ref.current) return
          setNotificationInfo(getErrorMessage(error), 'error')
          setLoading(false)
        }
      )
    }
    getUserWorkspaces()
    return () => {
      ref.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, page, userId])

  const workspaceMembershipColumns = [
    {
      property: 'workspaceName',
      header: '',
      align: 'start'
    }
  ]
  return (
    <>
      <Box
        direction="row"
        justify="between"
        gap="medium"
        pad={{ top: 'large' }}
        width={isEmpty(userWorkspaces?.items) ? 'xxlarge' : 'large'}
      >
        <Typography
          type="heading"
          level="2"
          testId="org-workspace-membership-title"
        >
          {t('iam:igc_users.orgs_workspace_membership_section_title')}
        </Typography>
      </Box>
      {!isEmpty(userWorkspaces?.items) || loading ? (
        <Box width="large" margin={{ top: 'small' }}>
          <Grommet
            theme={deepMerge(hpe, {
              dataTable: {
                header: {
                  border: {
                    color: 'none'
                  }
                }
              }
            })}
          >
            <DataTable
              pagination={{
                totalItems: userWorkspaces?.total || 0,
                itemsPerPage,
                setItemsPerPage,
                page,
                setPage,
                rowDropDownLabel: t('common:row_drop_down_label'),
                pageIdxInfo: getPageIndexInfo(),
                rowCountOptions: [5, 10, 20]
              }}
              grid={{
                data: userWorkspaces?.items,
                columns: workspaceMembershipColumns
              }}
              loading={loading}
              testId="workspace-membership-datatable"
            />
          </Grommet>
        </Box>
      ) : (
        <Box pad={{ top: 'large' }}>
          <NoDataInfo
            title={t(
              'iam:igc_users.orgs_workspace_membership_section_no_data_info'
            )}
            testId="no-available-workspace"
          />
        </Box>
      )}
    </>
  )
}

WorkspaceMembership.propTypes = {
  setNotificationInfo: PropTypes.func.isRequired
}

export default WorkspaceMembership
