// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box, Text, FormField, Heading } from 'grommet'
import { CircleAlert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'

import { FormInput, Button, Dropdown } from '../../../components'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import {
  validateLocationPhoneNum,
  PHONE_MAX_LEN
} from '../../../utils/location-validation-utils'
import { NON_GLP_USER_NAME } from '../utils'
import { NonGLPUserWarning } from '../../sdi-contact/NonGLPUserWarning'

import { ContactList } from './ContactList'
import { renderContactsOptions, updateUsersList } from './AssignContactsUtils'

export const validateContactList = (contacts, t) => {
  let numPrimaryContacts = 0
  contacts.forEach((contact) => {
    if (contact.type === 'primary') {
      numPrimaryContacts += 1
    }
  })
  const isValid = numPrimaryContacts === 1
  return {
    error: isValid ? '' : t('onePrimaryContactError'),
    isValid
  }
}

export const AssignLocationContacts = ({
  contactList,
  setFormError,
  setContactList,
  isCCSManager = false,
  customerId = null
}) => {
  const { t } = useTranslation(['location', 'common'])
  const { oidcUser } = useReactOidc()

  const [userNamesOptions, setUserNamesOptions] = useState([])
  const [selectedUserDetails, setSelectedUserDetails] = useState({
    type: '',
    name: '',
    email: '',
    phone: ''
  })

  const [fieldErrors, setFieldErrors] = useState({
    type: '',
    name: '',
    phone: ''
  })

  const [nextStepError, setNextStepError] = useState({
    isValid: true,
    error: ''
  })
  const [showErrorNotification, setShowErrorNotification] = useState(null)

  const itemsPerPage = 100
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const [searchVal, setSearchVal] = useState('')
  const [warn, setWarn] = useState(false)
  const LDFlags = useFlags()

  const assignContact = (details) => {
    const contact = {
      type: details.type,
      name: details.name,
      phone_number: details.phone,
      email: details.email
    }
    const newList = [...contactList, contact]
    setContactList(newList)
    setSelectedUserDetails({
      type: '',
      name: '',
      email: '',
      phone: ''
    })
  }

  const assignContactsValidation = () => {
    setFormError('')
    setNextStepError({
      error: '',
      isValid: true
    })
    let areFieldsValid = true
    if (selectedUserDetails.type === '') {
      setFieldErrors((prevState) => ({
        ...prevState,
        ...{ type: 'required' }
      }))
      areFieldsValid = false
    }
    if (selectedUserDetails.email === '') {
      setFieldErrors((prevState) => ({
        ...prevState,
        ...{ name: 'required' }
      }))
      areFieldsValid = false
    }

    // Phone number is not required. Only validate if it is not empty
    if (selectedUserDetails.phone !== '') {
      const phoneValidation = validateLocationPhoneNum(
        selectedUserDetails.phone
      )
      if (phoneValidation !== undefined) {
        setFieldErrors((prevState) => ({
          ...prevState,
          ...{ phone: t(phoneValidation.message) }
        }))
        areFieldsValid = false
      }
    }
    if (areFieldsValid) {
      setFieldErrors((prevState) => ({
        ...prevState,
        ...{ name: '', type: '', phone: '' }
      }))
      // check if user added multiple primary contacts
      if (selectedUserDetails.type === 'primary') {
        const primaryContact = contactList.find((contact) => {
          return contact.type === 'primary'
        })
        if (primaryContact !== undefined) {
          setNextStepError({
            error: t('multiple_primary_contact'),
            isValid: false
          })
          return
        }
      }
      // check for duplicates
      const duplicateContact = contactList.find((contact) => {
        return (
          selectedUserDetails.email === contact.email &&
          selectedUserDetails.type === contact.type
        )
      })
      if (duplicateContact !== undefined) {
        setNextStepError({
          error: t('duplicate_contacts'),
          isValid: false
        })
        return
      }
      setWarn(false)
      assignContact(selectedUserDetails)
    }
  }

  useEffect(() => {
    const url = isCCSManager
      ? '/support-assistant/v1alpha1/users'
      : '/ui-doorway/ui/v2/um/users'
    get(
      url,
      {
        limit: itemsPerPage,
        offset: page === 1 ? 0 : (page - 1) * itemsPerPage,
        include_unverified: false,
        ...(searchVal.trimStart().length > 0 && {
          search_string: searchVal.trimStart()
        }),
        ...(isCCSManager && { platform_customer_id: customerId })
      },
      oidcUser.access_token
    ).then(
      (response) => {
        setTotalItems(response?.data?.pagination?.total_count || itemsPerPage)
        let users = []
        users = response?.data?.users?.map((CCSuser) => {
          const user = isCCSManager ? CCSuser.contact : CCSuser
          return {
            value: user.email,
            label: LDFlags['glcp-lm-nonglp-contacts']
              ? `${user.first_name}  ${user.last_name}`
              : `${user.first_name}  ${user.last_name} (${user.email})`,
            name: `${user.first_name} ${user.last_name}`
          }
        })
        updateUsersList(
          users,
          searchVal,
          selectedUserDetails,
          LDFlags['glcp-lm-nonglp-contacts']
        )
        if (page !== 1) {
          setUserNamesOptions((prevOptions) => {
            const combinedOptions = [...prevOptions, ...users]
            return uniqBy(combinedOptions, 'value')
          })
        } else {
          setUserNamesOptions(users)
        }
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t, isCCSManager, customerId, page, searchVal])

  const handleDebouncedSearchValue = debounce((value) => {
    setSearchVal(value)
    setPage(1)
  }, 500)

  return (
    <>
      <Box
        pad={{ bottom: 'small' }}
        direction="row"
        align="start"
        gap="xsmall"
        width="100%"
      >
        <Box width="28%">
          <FormField
            label={t('type')}
            error={fieldErrors.type}
            data-testid="contact-type-field"
            required
          >
            <Dropdown
              size="small"
              placeholder={t('select')}
              multiple={false}
              noBorder
              onChange={({ option }) => {
                const updatedUserDetails = {
                  type: option.value
                }
                setSelectedUserDetails((prevState) => ({
                  ...prevState,
                  ...updatedUserDetails
                }))
                if (option !== '') {
                  setFieldErrors((prevState) => ({
                    ...prevState,
                    ...{ type: '' }
                  }))
                }
                setFormError('')
                setNextStepError({
                  error: '',
                  isValid: true
                })
              }}
              value={selectedUserDetails.type}
              options={[
                { label: t('primary'), value: 'primary' },
                {
                  label: t('shipping_and_receiving'),
                  value: 'shipping_receiving'
                },
                { label: t('security'), value: 'security' },
                { label: t('operations'), value: 'operations' }
              ]}
              testId="contact-type-dropdown"
            />
          </FormField>
        </Box>
        <Box width="35%">
          <FormField
            label={t('name_email')}
            error={fieldErrors.name}
            data-testid="contact-name-field"
            required
          >
            <Dropdown
              placeholder={
                LDFlags['glcp-lm-nonglp-contacts']
                  ? t('select_user_email')
                  : t('select_user')
              }
              size="small"
              multiple={false}
              noBorder
              onChange={(event) => {
                setWarn(false)
                const userName = userNamesOptions.find(
                  (obj) => obj.value === event.target.value
                )
                setSelectedUserDetails((prevState) => ({
                  ...prevState,
                  ...{
                    name: userName.name,
                    email: event.target.value
                  }
                }))
                if (event.target.value !== '') {
                  setFieldErrors((prevState) => ({
                    ...prevState,
                    ...{ name: '' }
                  }))
                }
                setFormError('')
                setNextStepError({
                  error: '',
                  isValid: true
                })
                if (userName.name === NON_GLP_USER_NAME) {
                  setWarn(true)
                }
              }}
              labelKey="label"
              value={selectedUserDetails.email}
              options={userNamesOptions}
              onSearch={(searchText) => {
                handleDebouncedSearchValue(searchText)
              }}
              onClose={() => {
                if (searchVal) {
                  setPage(1)
                  setSearchVal('')
                }
              }}
              testId="contact-name-dropdown"
              onMore={() => {
                if (totalItems > page * itemsPerPage) {
                  setPage(page + 1)
                }
              }}
              customRender={
                LDFlags['glcp-lm-nonglp-contacts']
                  ? (option) => renderContactsOptions(option, t)
                  : null
              }
              dropProps={
                LDFlags['glcp-lm-nonglp-contacts'] ? { width: 'small' } : {}
              }
            />
          </FormField>
          {warn && <NonGLPUserWarning t={t} />}
        </Box>
        <Box width="25%">
          <FormInput
            inputType="text"
            placeholder={t('phone_placeholder')}
            label={t('phone')}
            value={selectedUserDetails.phone}
            testId="contact-phone"
            onChange={(e) => {
              setFieldErrors((prevState) => ({
                ...prevState,
                ...{ phone: '' }
              }))
              setSelectedUserDetails((prevState) => ({
                ...prevState,
                ...{ phone: e.target.value }
              }))
            }}
            error={fieldErrors.phone}
            maxLength={PHONE_MAX_LEN}
            size="small"
          />
        </Box>
        <Box width="12%">
          <Button
            onClick={assignContactsValidation}
            label={t('assign')}
            secondary
            margin={{ top: 'medium' }}
            testId="assign-contact-btn"
          />
        </Box>
      </Box>
      <Heading level={2} margin="none">
        {t('users_to_be_assigned')}
      </Heading>
      <hr />
      {contactList.length === 0 && (
        <Box pad={{ bottom: 'large' }}>
          <Text data-testid="no-users-assigned-msg">
            {t('no_users_assigned')}
          </Text>
        </Box>
      )}
      <ContactList
        setFormError={setFormError}
        contactList={contactList}
        setContactList={setContactList}
        setNextStepError={setNextStepError}
      />
      {!nextStepError.isValid && (
        <Box
          background="validation-critical"
          pad="small"
          round="4px"
          margin={{ bottom: 'medium' }}
          gap="xsmall"
          direction="row"
          align="center"
          data-testid="assign-contact-error"
        >
          <CircleAlert size="small" />
          <Text size="xsmall" testId="assign-contact-error-message">
            {nextStepError.error}
          </Text>
        </Box>
      )}
      {showErrorNotification}
    </>
  )
}
AssignLocationContacts.propTypes = {
  contactList: PropTypes.array.isRequired,
  setFormError: PropTypes.func.isRequired,
  setContactList: PropTypes.func.isRequired,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string
}
