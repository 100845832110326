// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import { isTenant } from '../../../utils/feature-flag-utils'

import { AccountTypeContextProvider } from './context/AccountTypeContext'

const AccountTypeOverview = lazy(() => import('./AccountTypeOverview'))
const CheckEligibilityWizard = lazy(() =>
  import('./check-eligibility/CheckEligibility')
)
const ReviewDevicesWizard = lazy(() => import('./review-devices/ReviewDevices'))
const ReviewApplicationsWizard = lazy(() =>
  import('./review-applications/ReviewApplications')
)

const AccountTypesRouter = () => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  return (
    <AccountTypeContextProvider>
      <Suspense fallback={<LazyLoading />}>
        {!isTenant() ? (
          <Routes>
            <Route exact path="/" element={<AccountTypeOverview />} />
            <Route
              path="/check-eligibility"
              element={<CheckEligibilityWizard />}
            />
            <Route path="/review-devices" element={<ReviewDevicesWizard />} />
            <Route
              path="/review-applications"
              element={<ReviewApplicationsWizard />}
            />
            <Route path="/*" element={<Navigate to="/not-found" />} />
          </Routes>
        ) : (
          navigate('/home', { state: { errorMessage: t('permission_denied') } })
        )}
      </Suspense>
    </AccountTypeContextProvider>
  )
}

export default AccountTypesRouter
