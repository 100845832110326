// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { React, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import LazyLoading from '../../../../commoncomponents/LazyLoading/LazyLoading'

import { Assets } from './Assets'
import { AssetsDetailsPage } from './AssetsDetailsPage'

const AssetRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<Assets />} />
        <Route exact path="/:id" element={<AssetsDetailsPage />} />
      </Routes>
    </Suspense>
  )
}

export default AssetRouter
