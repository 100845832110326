// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Button } from 'grommet'
import { Previous } from 'grommet-icons'
import PropTypes from 'prop-types'

const BackButton = ({
  screenSize,
  isLargeScreen,
  fromServiceSubs,
  navigate,
  t
}) => (
  <Box
    direction="row"
    fill="horizontal"
    pad={{
      horizontal: screenSize === 'xlarge' ? 'medium' : 'xsmall',
      vertical: 'medium'
    }}
  >
    <Button
      {...(!isLargeScreen && { pad: { horizontal: 'small' } })}
      icon={<Previous />}
      as="a"
      href={
        fromServiceSubs
          ? '/services/service-subscriptions'
          : '/services/service-catalog'
      }
      onClick={(e) => {
        e.preventDefault()
        navigate(
          fromServiceSubs
            ? '/services/service-subscriptions'
            : '/services/service-catalog/'
        )
      }}
      label={
        fromServiceSubs
          ? t('device:service_subscriptions')
          : t('dashboard:service_catalog.title')
      }
      direction="row"
    />
  </Box>
)

export default BackButton

BackButton.propTypes = {
  screenSize: PropTypes.string.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  fromServiceSubs: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
