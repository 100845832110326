// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box, PageHeader } from 'grommet'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Previous } from 'grommet-icons'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
// import { isBoolean } from 'lodash'

import { get, getErrorMessage } from '../../../utils/api-utils'
import {
  Anchor,
  Button,
  DataTable,
  Loader,
  Typography
} from '../../../components'
import { displayNotification } from '../../../utils/notificiation-utils'
import { Layout } from '../../../commoncomponents/layout/Layout'
import Markdown from '../../../commoncomponents/Markdown'
import { useVisibilityContext } from '../../../context/visibility-context'
import NoViewPermission from '../commoncomponents/NoViewPermission'
import useOrganizationAssociation from '../commoncomponents/useOrganizationAssociation'
import { getPaginationShowIdx } from '../../../utils/common-utils'

import AddUserModal from './AddUserModal'

const UsersContent = () => {
  const { t } = useTranslation(['common', 'iam', 'auditlogs'])
  const location = useLocation()
  const navigate = useNavigate()
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  // Note: need to implement like this for RBAC OR logic
  const v2UserReadPerm = v2RbacPolicies?.includes('identity.users.read')
  const rootWkspReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.users.read'
  )

  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAddUserDialog, setShowAddUserDialog] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [filter, setFilter] = useState('')
  // for pagination
  const [itemsPerPage, setItemsPerPage] = useState(100)
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)
  // Notifications
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const { isAssociated, isAssociateLoader } =
    useOrganizationAssociation(setNotificationInfo)

  const handleRowClick = (datum) => {
    navigate(`/manage-account/organization/users/${datum?.id}`)
  }

  const handleBackBtnClick = () => {
    navigate('/manage-account/organization')
  }

  const columns = [
    {
      property: 'displayName',
      header: t('iam:users_table_headers.name'),
      render: (datum) => {
        const displayName = datum.displayName || '--'
        return (
          <Box direction="row" align="center" gap="small">
            <Typography
              type="text"
              wordBreak="break-all"
              testId="user-display-name"
              emphasis
            >
              {displayName}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'userName',
      primary: true,
      header: t('iam:users_table_headers.email')
    },
    {
      property: 'verified',
      header: t('iam:users_table_headers.verified'),
      render: (datum) => {
        return datum?.verified === true
          ? t('iam:verified_status_yes')
          : t('iam:verified_status_no')
      }
    },
    {
      property: 'lastLogin',
      header: t('iam:users_table_headers.last_login'),
      render: (datum) => {
        return datum?.lastLogin
          ? `${dayjs(datum?.lastLogin, 'YYYY-MM-DD hh:mm A').format(
              'MMM DD, YYYY'
            )}, ${dayjs(datum?.lastLogin, 'YYYY-MM-DD hh:mm A').format(
              'hh:mm A'
            )}`
          : '--'
      }
    }
  ]

  useEffect(() => {
    if (location?.state?.notificationText) {
      const message = <Markdown>{location?.state?.notificationText}</Markdown>
      setNotificationInfo(message, 'info', location?.state?.notificationTitle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.notificationText])

  useEffect(() => {
    let isCurrent = true
    const pagination = {
      count: itemsPerPage,
      startIndex: (page - 1) * itemsPerPage + 1
    }

    const searchValueTrimmed = filter?.trim()
    const nameQuery =
      searchValueTrimmed?.length > 0
        ? `?filter=${encodeURIComponent(
            `displayName sw "${searchValueTrimmed}" or userName sw "${searchValueTrimmed}"`
          )}`
        : ''
    setLoading(true)
    get(`/internal-platform-tenant-ui/v2/users${nameQuery}`, pagination)
      .then((response) => {
        if (!isCurrent) return
        dayjs.extend(customParseFormat)
        if (response?.data?.items) {
          setUsersList(response.data?.items)
          setTotalItems(response?.data?.totalResults)
        }
      })
      .catch((error) => {
        if (isCurrent) {
          setNotificationInfo(getErrorMessage(error), 'error')
        }
      })
      .finally(() => {
        setLoading(false)
      })
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount, filter, page, itemsPerPage])

  return (
    <Box
      data-testid="users-page"
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <PageHeader
        responsive
        title={
          <Typography type="heading" level="1" testId="users-page-title">
            {t('iam:organization.organization_users_card_title')}
          </Typography>
        }
        subtitle={
          ((!isAssociated && v2UserReadPerm) ||
            (isAssociated && rootWkspReadPerm)) && (
            <Typography
              type="paragraph"
              size="xlarge"
              testId="users-page-subtitle"
            >
              {t('iam:igc_users.subtitle')}
            </Typography>
          )
        }
        parent={
          <Anchor
            label={t('iam:organization.governance_title')}
            icon={<Previous />}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              handleBackBtnClick()
            }}
            margin={{ bottom: 'xsmall' }}
            testId="users-back-btn"
          />
        }
        margin={{ bottom: 'medium' }}
      />
      {!isAssociateLoader &&
      ((!isAssociated && v2UserReadPerm) ||
        (isAssociated && rootWkspReadPerm)) ? (
        <DataTable
          pagination={{
            totalItems,
            itemsPerPage,
            setItemsPerPage,
            rowDropDownLabel: t('auditlogs:items_per_page'),
            defaultRowsValue: itemsPerPage,
            page,
            setPage,
            pageIdxInfo,
            rowCountOptions: [20, 50, 100, 200]
          }}
          grid={{
            columns,
            data: usersList,
            onClickRow: (event) => {
              handleRowClick(event?.datum)
            }
          }}
          loading={loading}
          search={{
            onSearchValueChange: (value) => {
              setFilter(value)
            },
            placeholder: t('common:search'),
            width: 'large'
          }}
          button={
            <Box align="end" width="medium">
              {((isAssociated &&
                v2RbacRootWkspcPolicies?.includes('identity.users.create')) ||
                (!isAssociated &&
                  v2RbacPolicies?.includes('identity.users.create'))) && (
                <Button
                  secondary
                  label={t('iam:igc_users.add_user_btn')}
                  onClick={() => {
                    setShowAddUserDialog(true)
                  }}
                  testId="data-table-add-user-btn"
                />
              )}
            </Box>
          }
          testId="user-data-table"
        />
      ) : (
        <>
          {/* comparing it with false as rbacPolicies are sometimes not loaded in time from visibilityContext and could be undefined/null */}
          {!isAssociateLoader &&
          ((!isAssociated && v2UserReadPerm === false) ||
            (isAssociated && rootWkspReadPerm === false)) ? (
            <NoViewPermission />
          ) : (
            <Box direction="row" align="center" justify="center">
              <Loader testId="no-permission-loader" />
            </Box>
          )}
        </>
      )}
      {showAddUserDialog && (
        <AddUserModal
          onSetOpen={setShowAddUserDialog}
          refreshParent={() => {
            setRefreshCount((prevCount) => prevCount + 1)
          }}
          setParentNotification={setNotificationInfo}
        />
      )}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const Users = () => {
  return (
    <Layout>
      <UsersContent align="start" justify="start" />
    </Layout>
  )
}

export default Users
