// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes } from 'react-router-dom'
import React from 'react'

import GetStartedPage from './GetStarted'

const GetStartedRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<GetStartedPage />} />
    </Routes>
  )
}

export default GetStartedRouter
