// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import { AuditLogsContextProvider } from '../../../context/auditlogs-context'

const AuditLogsPage = lazy(() => import('./AuditLogs'))

const AuditLogsRouterContent = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<AuditLogsPage />} />
      </Routes>
    </Suspense>
  )
}

const AuditLogsRouter = () => {
  return (
    <AuditLogsContextProvider>
      <AuditLogsRouterContent />
    </AuditLogsContextProvider>
  )
}

export default AuditLogsRouter
