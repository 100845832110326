// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Text } from 'grommet'
import PropTypes from 'prop-types'
import { Subtract } from 'grommet-icons'

import { ChatActions } from './context/chat-context'
import { startDrag } from './Draggable'

const ChatHeader = ({ chatContainerRef = undefined, closeChatBot }) => {
  const handleDrag = (e) => {
    e.preventDefault()
    startDrag(e, chatContainerRef)
  }

  const onCloseChatBot = () => {
    closeChatBot({ type: ChatActions.OPEN_CHAT, payload: { open: false } })
  }

  return (
    <Box
      data-testid="chatbot-header-box"
      background="background-front"
      direction="row"
      align="center"
      pad={{ top: 'xsmall', left: 'medium' }}
      justify="between"
      border={{ color: 'lightGray', size: 'xsmall', side: 'bottom' }}
      onMouseDown={handleDrag}
      style={{ cursor: 'move', height: 'large' }}
    >
      <Box direction="row" gap="small" align="center" justify="between">
        <Text
          style={{ fontWeight: '600', fontSize: '16px' }}
          id="chatbot-header-title"
          data-testid="chatbot-header-title"
        >
          Virtual Assistant
        </Text>
      </Box>

      <Box
        direction="row"
        gap="medium"
        pad="small"
        align="center"
        justify="space-evenly"
      >
        <Subtract
          data-testid="chat-header-minimize"
          id="chat-header-minimize"
          size="medium"
          onClick={onCloseChatBot}
          style={{ cursor: 'pointer' }}
          color="rgba(51, 51, 51, 1)"
        />
      </Box>
    </Box>
  )
}

ChatHeader.propTypes = {
  chatContainerRef: PropTypes.shape({
    current: PropTypes.object
  }),
  closeChatBot: PropTypes.func.isRequired
}
export default ChatHeader
