// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import { Box, Grid } from 'grommet'
import { useNavigate } from 'react-router-dom'

import { Widget } from '../../../../components'

import { AssetsWidgetsData } from './assets-widgets-data'
import AssetsByWorkLoad from './assetsByWorkload'

const WidgetSection = () => {
  const navigate = useNavigate()
  return (
    <Box data-testid="assets-widget-section">
      <Grid
        key="assets-widget-grid-container"
        data-testid="assets-widget-grid-container"
        columns="1/3"
        gap="medium"
      >
        {AssetsWidgetsData().map((widget) =>
          widget?.chartType === 'bar' ? (
            <AssetsByWorkLoad
              chartType={widget?.chartType}
              data={widget?.widgetData?.listData}
              subTitle={widget?.subtitle}
              title={widget?.title}
              key={widget?.widgetKey}
              testId={widget?.testId}
              onSelectClick={null}
              action={
                widget.actionUrl ? () => navigate(widget.actionUrl) : null
              }
              url={widget?.url}
            />
          ) : (
            <Widget
              chartType={widget?.chartType}
              data={widget?.widgetData?.listData}
              subTitle={widget?.subtitle}
              title={widget?.title}
              key={widget?.widgetKey}
              testId={widget?.testId}
              onSelectClick={null}
              action={
                widget.actionUrl ? () => navigate(widget.actionUrl) : null
              }
              url={widget?.url}
              widgetBodyCustomStyle={{ justify: 'start' }}
            >
              {widget.children}
            </Widget>
          )
        )}
      </Grid>
    </Box>
  )
}

export default WidgetSection
