// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Install, Trash } from 'grommet-icons'
import isEqual from 'lodash/isEqual'

import {
  ActionButton,
  Button,
  ButtonGroup,
  DataTable,
  FormInput,
  Loader,
  NoDataInfo,
  Typography
} from '../../../../components'
import { get, put } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'

const EditCustomerPrograms = ({ setShowModal, customerId }) => {
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()

  const [programsResponse, setProgramsResponse] = useState([])
  const [programsData, setProgramsData] = useState([])
  const [showNotification, setShowNotification] = useState(false)
  const [loading, setLoading] = useState(false)

  const [input, setInput] = useState('')
  const [inputError, setInputError] = useState('')

  const fetchProgramsData = useCallback(() => {
    setLoading(true)
    const url = `/support-assistant/v1alpha1/customer-programs/${customerId}`
    get(url, {}, oidcUser.access_token).then(
      (response) => {
        setProgramsResponse(response?.data?.customer_programs || [])
        setProgramsData(response?.data?.customer_programs || [])
        setLoading(false)
        setShowNotification(null)
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
        setLoading(false)
      }
    )
  }, [customerId, oidcUser.access_token, t])

  useEffect(() => {
    fetchProgramsData()
  }, [fetchProgramsData])

  const isDataEdited = () => {
    return isEqual(programsResponse, programsData)
  }

  const validateInput = () => {
    if (input?.trim()?.length) {
      if (programsData.includes(input?.trim())) {
        setInputError(t('duplicate_value'))
        return false
      }
    } else {
      setInputError(t('invalid_input'))
      return false
    }
    return true
  }

  const handleAdd = () => {
    if (validateInput()) {
      setProgramsData([...programsData, input])
      setInput('')
    }
  }

  const handleSaveChanges = () => {
    setLoading(true)
    const url = `/support-assistant/v1alpha1/customer-programs`
    put(
      url,
      { customer_id: customerId, customer_programs: programsData },
      oidcUser.access_token
    ).then(
      () => {
        setShowNotification(
          displayNotification(
            t('customer_programs_updated_successfully'),
            'info',
            setShowNotification
          )
        )
        setProgramsResponse(programsData)
        setLoading(false)
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
        setProgramsData(programsResponse)
        setLoading(false)
      }
    )
  }

  const handleDiscardChanges = () => {
    setProgramsData(programsResponse)
  }

  return (
    <Box style={{ width: '100%' }} direction="column">
      <Box>
        <Typography level="2" type="heading" testId="customer-programs-title">
          {t('programs')}
        </Typography>
        <Typography
          type="text"
          size="medium"
          testId="programs-subtitle"
          margin={{ bottom: 'small' }}
        >
          {t('programs_subtitle')}
        </Typography>
        <Box margin={{ top: 'medium' }}>
          {loading ? (
            <Box align="center" justify="center" width="small" height="small">
              <Loader testId="programs-loader" />
            </Box>
          ) : (
            <>
              <Box
                direction="row-responsive"
                align="center"
                gap="small"
                width="medium"
              >
                <Box fill="horizontal">
                  <FormInput
                    error={inputError}
                    inputType="text"
                    label={t('program')}
                    placeholder={t('enter_a_program')}
                    testId="program-input"
                    value={input}
                    onChange={(event) => {
                      setInput(event.target.value)
                      setInputError('')
                    }}
                  />
                </Box>
                <Box
                  margin={inputError ? undefined : { top: 'medium' }}
                  flex={{ shrink: 0 }}
                >
                  <Button
                    secondary
                    label={t('common:add')}
                    testId="add-programs-btn"
                    onClick={handleAdd}
                  />
                </Box>
              </Box>
              {programsData?.length ? (
                <Box
                  height="small"
                  overflow="auto"
                  margin={{ top: 'small' }}
                  width="medium"
                >
                  <DataTable
                    grid={{
                      columns: [
                        {
                          header: '',
                          type: 'string',
                          property: 'name',
                          render: (datum) => (
                            <Box width="small">
                              <Typography type="text" size="large">
                                {programsResponse?.includes(datum?.name)
                                  ? datum?.name
                                  : `${datum?.name} *`}
                              </Typography>
                            </Box>
                          )
                        },
                        {
                          header: '',
                          property: '',
                          align: 'end',
                          render: (datum) => {
                            return (
                              <ActionButton
                                actions={[
                                  {
                                    icon: <Trash size="small" />,
                                    onClick: () =>
                                      setProgramsData(
                                        programsData?.filter(
                                          (val) => val !== datum?.name
                                        )
                                      )
                                  }
                                ]}
                                testId="delete-btn"
                              />
                            )
                          }
                        }
                      ],
                      data: programsData?.map((name) => ({ name })),
                      pad: { header: 'none' }
                    }}
                    testId="programs-datatable"
                  />
                </Box>
              ) : (
                <Box align="start" margin={{ top: 'medium' }}>
                  <NoDataInfo
                    icon={<Install size="medium" />}
                    subtitle={t('no_programs_added')}
                    testId="no-programs-added-message"
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box>
        <Box direction="row" justify="end" pad={{ top: 'medium' }}>
          <ButtonGroup
            buttonList={[
              {
                id: 1,
                label: t('close'),
                secondary: true,
                testId: 'close-btn',
                onClick: () => {
                  setShowModal(false)
                }
              },
              {
                id: 2,
                label: t('discard_changes'),
                secondary: true,
                testId: 'discard-changes-btn',
                onClick: handleDiscardChanges,
                disabled: isDataEdited() || loading
              },
              {
                id: 3,
                label: t('save_changes'),
                primary: true,
                testId: 'save-changes-btn',
                onClick: handleSaveChanges,
                disabled: isDataEdited() || loading
              }
            ]}
            testId="two-buttons"
          />
        </Box>
        {showNotification}
      </Box>
    </Box>
  )
}
EditCustomerPrograms.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired
}

export { EditCustomerPrograms }
