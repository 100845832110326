// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Anchor, Box } from 'grommet'
import { useNavigate } from 'react-router-dom'

import { Typography } from '../../../../components'
import {
  getMSPCustomerAccount,
  setCustomerAccount
} from '../../../../utils/feature-flag-utils'
import { ThemeMode } from '../../../../components/ThemeMode'
import { resetAuditLogContext } from '../../../../context/auditlogs-context/reducer'

export const SwapMSPNavigation = () => {
  const { t } = useTranslation(['authn'])
  const navigate = useNavigate()

  const swappedMSPAccount = getMSPCustomerAccount()
  setCustomerAccount(swappedMSPAccount)

  // reset the auditlog_context in session storage after a workspace is loaded / switched
  resetAuditLogContext()

  return (
    <ThemeMode>
      <Box fill pad="xlarge" justify="center" data-testid="swap-msp">
        <Typography
          margin={{ top: 'large', bottom: 'small' }}
          type="heading"
          level="1"
          testId="swap-msp-title"
        >
          {t('authn:acct_onboarding:returning_to')}
        </Typography>
        <Typography type="text" size="xlarge" testId="swap-msp-subtitle">
          <Trans i18nKey="acct_onboarding.not_redirected" t={t}>
            <Anchor
              data-testid="anchor-to-choose-account"
              label={t('acct_onboarding.click_here')}
              href="/"
              onClick={(e) => {
                e.preventDefault()
                navigate('/post-onboarding/choose-account')
              }}
              weight="bold"
              testId="click-her-to-account"
            />
          </Trans>
        </Typography>
      </Box>
    </ThemeMode>
  )
}
