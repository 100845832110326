// Copyright 2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

export const LDCodeSamples = ({ codeString = '' }) => {
  return (
    <SyntaxHighlighter language="javascript" style={atomOneDark}>
      {codeString}
    </SyntaxHighlighter>
  )
}
LDCodeSamples.propTypes = {
  codeString: PropTypes.string
}
