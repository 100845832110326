// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import dayjs from 'dayjs'

import { get } from '../../../utils/api-utils'
import getServiceDefinitions from '../../../dashboard/shims/serviceDefinitions'
import { isCoP } from '../../../utils/feature-flag-utils'
import { getFormattedTagsString } from '../../device-management/utils'
import {
  displayApiError,
  getApiErrorMessage
} from '../../../utils/error-handling-utils'
import { getDateInMilliseconds } from '../../../utils/dm-sm-common-utils'

import { generateReportForSubscriptionsAPICall } from './pages/licenses/utils'

export const getDeviceTypeValues = (
  t,
  dmSilverPeakFlag,
  dmPCEFlag,
  dmUXIFlag,
  dmBridgeFlag
) => {
  return [
    { label: t('access-points'), value: 'AP' },
    { label: t('switches'), value: 'SWITCH' },
    { label: t('gateways'), value: 'GATEWAY' },
    ...(!isCoP() && dmPCEFlag
      ? [{ label: t('private_cloud_enterprise'), value: 'PCE' }]
      : []),
    ...(!isCoP() && dmSilverPeakFlag
      ? [{ label: t('sd_wan_gateways'), value: 'SD_WAN_GW' }]
      : []),
    ...(!isCoP() && dmUXIFlag
      ? [{ label: t('sensors'), value: 'SENSOR' }]
      : []),
    ...(!isCoP() && dmBridgeFlag
      ? [{ label: t('bridge'), value: 'BRIDGE' }]
      : []),
    ...(isCoP()
      ? [{ label: t('third_party'), value: 'NW_THIRD_PARTY' }]
      : [
          { label: t('servers'), value: 'COMPUTE,DHCI_COMPUTE' },
          { label: t('storage'), value: 'STORAGE,DHCI_STORAGE' }
        ])
  ]
}

export const LICENSE_PORTAL_LINK = 'https://lms.arubanetworks.com/'

export const getServiceDefinitionNameBySlug = (LDFlags, appData) => {
  const serviceDefinitionList = getServiceDefinitions(LDFlags)
  const serviceDefinitionDataBySlug = serviceDefinitionList.find(
    (val) => val.serviceSlug === appData?.application?.slug
  )
  return serviceDefinitionDataBySlug?.name || appData?.application?.name
}

export const getSubscriptionVisibilityProps = (filterOptions) => {
  const props = {}
  if (Object.keys(filterOptions).length) {
    if (filterOptions?.subscription_visibility) {
      switch (filterOptions?.subscription_visibility?.toString()) {
        case 'UNEXPIRED_ONLY':
          props.expire_date_cut_off_in_millis = dayjs().valueOf()
          break
        case 'EXPIRED_ONLY':
          if (!filterOptions?.end_date_in_millis?.toString()) {
            props.expire_date_cut_off_in_millis = 1
            props.end_date_in_millis = dayjs().valueOf()
          }
          break
        default:
          break
      }
    }
  }
  return props
}

const getTagsRequestField = (tags) => {
  let modifiedData = ''
  tags?.forEach((tag) => {
    const tagPair = tag?.value.split(',')
    modifiedData = `${modifiedData}'${tagPair[0]}' eq '${tagPair[1]}' or `
  })
  return modifiedData.slice(0, -4)
}

const generateReport = ({
  report_name,
  description,
  email,
  all_entries,
  columns: csvColumns,
  setApiError,
  productType,
  setLoading,
  filterOptions,
  sortProps,
  searchTerm,
  reportingDashboardFlag,
  oidcUser,
  setNotification,
  setShowExportModal,
  t
}) => {
  setLoading(true)
  // subscription_visibility, tags & evaluation_type needs to be manually formatted.
  // Hence removing it from auto format object
  const {
    subscription_visibility,
    tags,
    evaluation_type,
    ...remainingOptions
  } = filterOptions
  const filterOptionsExcludingUnFormattedFilters = remainingOptions
  const request = {
    delivery: {
      emailComponents: {
        recipientEmail: email
      }
    },
    query_elements: {
      datasource_name: ['subscriptions'],
      columns: csvColumns,
      filters: all_entries
        ? {
            product_type: productType,
            sort_by: sortProps.property,
            direction: sortProps.direction
          }
        : {
            product_type: productType,
            sort_by: sortProps.property,
            direction: sortProps.direction,
            ...(searchTerm?.length && {
              subscription_key_pattern: searchTerm
            }),
            ...(Object.keys(filterOptionsExcludingUnFormattedFilters)?.length &&
              filterOptionsExcludingUnFormattedFilters),
            ...(filterOptions?.end_date_in_millis && {
              end_date_in_millis: getDateInMilliseconds(
                filterOptions?.end_date_in_millis?.[0]
              )
            }),
            ...(filterOptions?.subscription_visibility &&
              getSubscriptionVisibilityProps(filterOptions)),
            ...(filterOptions?.device_type && {
              device_type: filterOptions.device_type.join(',')
            }),
            ...(filterOptions?.subscription_tier?.length && {
              subscription_tier: filterOptions?.subscription_tier
                ?.map((tier) => tier?.value)
                ?.join(',')
            }),
            ...(filterOptions?.evaluation_type?.length && {
              evaluation_type: filterOptions?.evaluation_type?.toString()
            }),
            ...(filterOptions?.tags?.length && {
              'filter-tags': getTagsRequestField(filterOptions?.tags)
            })
          }
    }
  }
  if (reportingDashboardFlag) {
    request.name = report_name?.trim()
    request.description = description?.trim()
  }

  generateReportForSubscriptionsAPICall(
    request,
    oidcUser.access_token,
    setNotification,
    () => {
      setShowExportModal(false)
      setLoading(false)
    },
    (val) => {
      if (!reportingDashboardFlag) {
        setShowExportModal(false)
      } else {
        setShowExportModal(true)
        setApiError(val)
      }
      setLoading(false)
    },
    t,
    reportingDashboardFlag
  )
}

export const getTags = (
  oidcUser,
  t,
  setAllTagsList,
  setShowErrorNotification,
  isServiceSubscription = false,
  searchTagsFilterText = ''
) => {
  if (searchTagsFilterText.trim().length === 0) {
    setAllTagsList([])
  } else {
    get(
      '/ui-doorway/ui/v1/license/tags',
      {
        ...(searchTagsFilterText && {
          search_string: searchTagsFilterText.trim()
        })
      },
      oidcUser
    ).then(
      (response) => {
        if (response?.data?.tags) {
          const filteredTags = response.data.tags || []
          const subscriptionType = isServiceSubscription ? 'SERVICE' : 'DEVICE'
          const filteredTagsList = []
          filteredTags.forEach((tag) => {
            const includeTag = tag?.subscriptions.some((subscription) => {
              return subscription?.productType === subscriptionType
            })
            if (includeTag) {
              filteredTagsList.push(tag)
            }
          })
          const formattedTagsList = filteredTagsList.map(({ name, value }) => {
            const labelValueString = getFormattedTagsString(name, value)
            return {
              valueName: `${name},${value}`,
              valueLabel: labelValueString
            }
          })
          setAllTagsList(formattedTagsList)
        }
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
  }
}

export const checkDuplicateWarningStatus = (oidcUser, subscriptionKey, t) => {
  return get(
    `/ui-doorway/ui/v1/license/${subscriptionKey}/is-duplicate`,
    {},
    oidcUser.access_token
  ).then(
    (response) => {
      return response?.data
    },
    (error) => {
      if (error?.response?.status === 404) {
        return Promise.reject(new Error('INVALID_KEY'))
      }
      return Promise.reject(new Error(getApiErrorMessage(error, t)))
    }
  )
}

export const checkDelayedActivationStatus = (oidcUser, subscriptionKey, t) => {
  return get(
    `/ui-doorway/ui/v1/license/${subscriptionKey}/delayedactivation/status`,
    {},
    oidcUser.access_token
  ).then(
    (response) => {
      return response?.data?.response === 'true'
    },
    (error) => {
      return Promise.reject(new Error(getApiErrorMessage(error, t)))
    }
  )
}

export const checkOnpremWorkspace = (oidcUser, subscriptionKey, t) => {
  return get(
    `/ui-doorway/ui/v1/license/${subscriptionKey}/is-silvercreek-sku`,
    {},
    oidcUser.access_token
  ).then(
    (response) => {
      return response?.data
    },
    (error) => {
      return Promise.reject(new Error(getApiErrorMessage(error, t)))
    }
  )
}
export const getAssignToOnPremWorkSpacePayload = (selectedRows) => {
  if (selectedRows?.length) {
    const rows = [...selectedRows]
    const updatedRows = rows.map((datum) => {
      return { subscriptionKey: datum }
    })

    return {
      subscriptions: updatedRows
    }
  }
  return { subscriptions: [] }
}

export const getAssignToOnPremWorkSpacePayloadInDetails = (key) => {
  return {
    subscriptions: [
      {
        subscriptionKey: key
      }
    ]
  }
}

export const getSubsExportPermission = (rbacPolicies) => {
  const platformCustEdit =
    rbacPolicies?.effects?.['/ccs/accounts/platform/customer']?.[
      'ccs.accounts.platform.customer.edit'
    ]
  const reportingEdit =
    rbacPolicies?.effects?.['/ccs/reporting/dashboard']?.['ccs.reporting.edit']
  return platformCustEdit && reportingEdit
}

export const handleExport =
  (
    ptype,
    filterOptions,
    setLoading,
    sortProps,
    searchTerm,
    reportingDashboardFlag,
    oidcUser,
    setNotification,
    setShowExportModal,
    t
  ) =>
  (selectedDetails, setApiError, setShowValidationWarning) => {
    if (!selectedDetails?.all_entries && filterOptions?.tags?.length) {
      setShowValidationWarning(t('licensing:tags_export_validation_warning'))
      setLoading(false)
    } else
      generateReport({
        ...selectedDetails,
        setApiError,
        productType: ptype,
        setLoading,
        filterOptions,
        sortProps,
        searchTerm,
        reportingDashboardFlag,
        oidcUser,
        setNotification,
        setShowExportModal,
        t
      })
  }
