// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Scorecard } from 'grommet-icons'

import { Typography } from '../../../../../components'

const EmptyStateChart = ({ emptyStateMsg = '' }) => (
  <Box margin={{ bottom: 'medium' }} align="center" justify="center">
    <Box>
      <Scorecard size="large" />
    </Box>
    <Box width="medium">
      <Typography
        size="large"
        type="text"
        textAlign="center"
        weight="normal"
        testId="page-description"
        margin={{ top: 'medium' }}
      >
        {emptyStateMsg}
      </Typography>
    </Box>
  </Box>
)
export default EmptyStateChart

EmptyStateChart.propTypes = {
  emptyStateMsg: PropTypes.string
}
