import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Typography, Button, ModalDialog } from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'

const DeleteBookModal = ({
  onSetOpen,
  onSetClose,
  selectedBooks,
  setSelectedRows
}) => {
  const { oidcUser } = useReactOidc()
  const [notifyComponent, setNotifyComponent] = useState(null)
  const { t } = useTranslation(['tutorial'])
  const selectBooksIDList = selectedBooks.map((val) => val?.book_id)

  const handleDeleteBook = () => {
    del(
      `/book-catalog/ui/v1/book`,
      { books: selectBooksIDList },
      oidcUser.access_token
    ).then(
      () => {
        onSetOpen(false)
        onSetClose(true)
        setSelectedRows([])
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        console.error(backendErrorMessage)
        const errorComp = displayApiError(error, t, setNotifyComponent)
        setNotifyComponent(errorComp)
      }
    )
  }
  return (
    <>
      {notifyComponent}
      <ModalDialog
        width="medium"
        testId="delete-book-modal-dialog"
        content={
          <Box>
            <Typography type="heading" level="1" testId="delete-book-title">
              {t('delete_books_header')}
            </Typography>
            <Typography type="text" size="large" textId="delete-book-message">
              {t('delete_book_modal_bulk')}
            </Typography>
          </Box>
        }
        footer={
          <Box direction="row" justify="end" gap="medium">
            <Button
              default
              label={t('cancel')}
              onClick={() => {
                onSetOpen(false)
              }}
              testId="cancel-book-delete-btn"
            />
            <Button
              primary
              type="submit"
              label={t('delete_books_btn')}
              testId="delete-book-btn"
              onClick={() => {
                onSetOpen(false)
                handleDeleteBook()
              }}
            />
          </Box>
        }
        onClose={() => onSetOpen(false)}
      />
    </>
  )
}

DeleteBookModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  selectedBooks: PropTypes.array.isRequired,
  onSetClose: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired
}

export { DeleteBookModal }
