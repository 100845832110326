// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Trans } from 'react-i18next'

import { Typography } from '../components'

import { displayNotification } from './notificiation-utils'

export const getStatusForMultiStatusResponse = (response) => {
  let status = 'info'

  if (
    Boolean(response?.data?.failed?.length > 0) &&
    Boolean(response.data?.success?.length > 0)
  ) {
    status = 'warning'
  } else if (
    Boolean(response?.data?.failed?.length > 0) &&
    (!response?.data?.success || Boolean(response?.data?.success?.length === 0))
  ) {
    status = 'error'
  }
  return status
}

export const getNotificationMessageForMultiStatusResponse = (
  smsg,
  stype,
  fmsg,
  ftype
) => {
  return (
    <>
      {smsg && stype && (
        <>
          <Typography type="text" testId="sa-notification-desc" weight="bold">
            {stype}
          </Typography>

          <Typography
            level="7"
            wordBreak="break-word"
            color="text-strong"
            type="text"
            testId="sa-notification-title"
          >
            {smsg}
          </Typography>
        </>
      )}

      {fmsg && ftype && (
        <>
          <Typography type="text" testId="sa-notification-desc" weight="bold">
            {ftype}
          </Typography>

          <Typography
            level="7"
            wordBreak="break-word"
            color="text-strong"
            type="text"
            testId="sa-notification-title"
          >
            {fmsg}
          </Typography>
        </>
      )}
    </>
  )
}

export const CheckResourcePermissions = (roleDetails, rbacPolicies) => {
  let output = true
  Object.entries(roleDetails).forEach(([resource, permissionsArray]) => {
    const permissions = rbacPolicies?.effects?.[resource]
    if (permissions) {
      permissionsArray.forEach((permission) => {
        if (permissions[permission] === false) {
          output = false
        }
      })
    } else {
      output = false
    }
  })
  return output
}

export const multiStatusNotification = (
  response,
  setNotifyComponent,
  successMessage,
  partialApprovalMessage,
  successHeading,
  errorHeading,
  t
) => {
  if (!errorHeading) {
    errorHeading = t('manage:error')
  } else if (!successHeading) {
    successHeading = t('manage:success')
  }
  const { success, failed } = response.data
  const successCases = success?.join(', ')
  const failedCases = failed
    ?.map((failedUser) => {
      const [caseId, errorMsg] = Object.entries(failedUser)[0]
      return t('{{errorMsg}}', { case: caseId, errorMsg })
    })
    .join('\n')

  const approveSuccessMsg = successCases && (
    <Trans i18nKey={successMessage} values={{ cases: successCases }} />
  )

  const approveFailedMsg = failedCases && `\n ${failedCases}`

  const status = getStatusForMultiStatusResponse(response)
  const autoClose = !(failed?.length > 0 && success?.length > 0)

  let notifyHeading = ''
  if (success?.length > 0 && failed?.length > 0) {
    notifyHeading = t(partialApprovalMessage)
  } else if (success?.length > 0) {
    notifyHeading = t(successHeading)
  }

  const notification = displayNotification(
    getNotificationMessageForMultiStatusResponse(
      approveSuccessMsg,
      notifyHeading,
      approveFailedMsg,
      t(errorHeading)
    ),
    status,
    setNotifyComponent,
    '',
    autoClose
  )

  return notification
}

export const getCCSAppDetails = () => {
  return {
    ccsApplicationId: '00000000-0000-0000-0000-000000000000',
    ccsApplicationName: 'Common Cloud Service'
  }
}
