// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useContext
} from 'react'
import {
  Box,
  FormField,
  Text,
  TextArea,
  PageHeader,
  ResponsiveContext
} from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import isEmpty from 'lodash/isEmpty'
import { CircleInformation } from 'grommet-icons'

import {
  Typography,
  Loader,
  FormInput,
  ButtonGroup,
  CCSForm
} from '../../../../../components'
import { get, put, getErrorMessage } from '../../../../../utils/api-utils'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import {
  validateNumberRange,
  validateMaxLength
} from '../../../../../utils/validation-utils'
import { SaveChangesModal } from '../components'
import {
  INACTIVITY_TIMEOUT_DEFAULT_VAL,
  WEB_INACTIVITY_TIMEOUT_RANGE,
  CLI_INACTIVITY_TIMEOUT_RANGE,
  TOTAL_CONCURRENT_SESSIONS_RANGE,
  LOGIN_BANNER_MAX_LENGTH
} from '../constants'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { DiscardChangesModal } from '../../../../../commoncomponents/discard-changes-modal/DiscardChangesModal'

const initialState = {
  web_inactivity_timeout: INACTIVITY_TIMEOUT_DEFAULT_VAL,
  cli_inactivity_timeout: INACTIVITY_TIMEOUT_DEFAULT_VAL,
  web_total_concurrent_sessions: '',
  cli_total_concurrent_sessions: '',
  web_total_concurrent_sessions_per_user: '',
  login_banner: ''
}

const Sessions = () => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn'])
  const { t: commonT } = useTranslation(['common'])
  const [responseData, setResponseData] = useState(initialState)
  const [successMessage, setSuccessMessage] = useState(null)
  const [apiErrorMessage, setApiErrorMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [openDiscardModal, setOpenDiscardModal] = useState(false)
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const size = useContext(ResponsiveContext)
  const boxWidth = size === 'xsmall' ? 'small' : 'medium'

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value, edited: true }
        case 'SET_STATE':
          return { ...initialState, ...action.value, edited: false }
        default:
          return currentState
      }
    },
    {
      ...initialState,
      edited: false
    }
  )

  const getSessionsData = useCallback(() => {
    setLoading(true)
    get('/accounts/ui/v1/cop-auth/session-policy', {}, oidcUser.access_token)
      .then(
        (response) => {
          if (!isEmpty(response && response?.data)) {
            dispatch({
              type: 'SET_STATE',
              value: {
                web_inactivity_timeout:
                  response?.data?.web_inactivity_timeout || '',
                cli_inactivity_timeout:
                  response?.data?.cli_inactivity_timeout || '',
                web_total_concurrent_sessions:
                  response?.data?.web_total_concurrent_sessions || '',
                cli_total_concurrent_sessions:
                  response?.data?.cli_total_concurrent_sessions || '',
                web_total_concurrent_sessions_per_user:
                  response?.data?.web_total_concurrent_sessions_per_user || '',
                login_banner: response?.data?.login_banner || ''
              }
            })
            setResponseData(response.data)
          }
        },
        (error) => {
          setApiErrorMessage(error)
        }
      )
      .then(() => setLoading(false))
  }, [oidcUser.access_token])

  useEffect(() => {
    getSessionsData()
  }, [getSessionsData, oidcUser.access_token])

  const handleSave = () => {
    setOpenSaveModal(false)
    setLoading(true)
    const requestBody = {
      web_inactivity_timeout:
        state.web_inactivity_timeout || INACTIVITY_TIMEOUT_DEFAULT_VAL,
      cli_inactivity_timeout:
        state.cli_inactivity_timeout || INACTIVITY_TIMEOUT_DEFAULT_VAL,
      web_total_concurrent_sessions:
        state.web_total_concurrent_sessions || null,
      cli_total_concurrent_sessions:
        state.cli_total_concurrent_sessions || null,
      web_total_concurrent_sessions_per_user:
        state.web_total_concurrent_sessions_per_user || null,
      login_banner: state.login_banner
    }

    put(
      '/accounts/ui/v1/cop-auth/session-policy',
      requestBody,
      oidcUser.access_token
    )
      .then(
        () => {
          getSessionsData()
          setSuccessMessage(t('cop_local_authentication.updated_successfuly'))
        },
        (error) => {
          setErrorMessage(getErrorMessage(error, commonT))
        }
      )
      .then(() => setLoading(false))
  }

  const handleDiscard = () => {
    setOpenDiscardModal(false)
    dispatch({
      type: 'SET_STATE',
      value: responseData
    })
  }
  return (
    <Box width="large">
      {loading ? (
        <Box
          height="large"
          width="full"
          align="center"
          justify="center"
          alignSelf="center"
        >
          <Loader testId="loader-spinner" />
        </Box>
      ) : (
        <CCSForm
          errorMessage={errorMessage}
          testId="sessions-form"
          validate="blur"
          onSubmit={() => (state.edited ? setOpenSaveModal(true) : null)}
          onReset={() => (state.edited ? setOpenDiscardModal(true) : null)}
        >
          <>
            <Box direction="row-responsive" justify="between">
              <PageHeader
                pad={{ top: 'xsmall' }}
                responsive
                title={t('cop_local_authentication.sessions')}
                subtitle={t('cop_local_authentication.sessions_desc')}
                data-testid="sessions-title"
                actions={
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/accounts/platform/customer',
                      permission: 'ccs.accounts.platform.customer.edit'
                    }}
                  >
                    <ButtonGroup
                      buttonList={[
                        {
                          id: 2,
                          label: commonT('cancel'),
                          default: true,
                          type: 'reset',
                          testId: 'sessions-cancel-btn'
                        },
                        {
                          id: 1,
                          label: t('cop_local_authentication.update_button'),
                          primary: true,
                          type: 'submit',
                          testId: 'sessions-save-changes-btn'
                        }
                      ]}
                      testId="sessions-action-buttons"
                    />
                  </VisibilityWrapper>
                }
              />
            </Box>

            <Box margin={{ top: 'large' }}>
              <Box
                direction="row-responsive"
                gap="medium"
                data-testid="time-box"
                wrap={size === 'xsmall'}
              >
                <Box direction="row" gap="small" width={boxWidth}>
                  <FormInput
                    info={
                      <Box direction="row" gap="xsmall" align="center">
                        <CircleInformation size="small" />
                        <Text size="small" data-testid="web-activity-info">
                          {t(
                            'cop_local_authentication.inactivity_timeout_info'
                          )}
                        </Text>
                      </Box>
                    }
                    inputType="text"
                    label={t('cop_local_authentication.web_inactivity_timeout')}
                    name="web_inactivity_timeout"
                    value={state.web_inactivity_timeout}
                    onChange={(e) => {
                      dispatch({
                        value: e.target.value?.trim(),
                        field: 'web_inactivity_timeout',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    validate={() =>
                      validateNumberRange(
                        state.web_inactivity_timeout,
                        WEB_INACTIVITY_TIMEOUT_RANGE.start,
                        WEB_INACTIVITY_TIMEOUT_RANGE.end,
                        t(
                          'cop_local_authentication.invalid_inactivity_timeout',
                          {
                            startRange: WEB_INACTIVITY_TIMEOUT_RANGE.start,
                            endRange: WEB_INACTIVITY_TIMEOUT_RANGE.end
                          }
                        )
                      )
                    }
                    testId="web-inactivity-timeout-input"
                  />
                  <Typography type="text" textAlign="center">
                    {t('minutes')}
                  </Typography>
                </Box>
                <Box direction="row" gap="small" width={boxWidth}>
                  <FormInput
                    inputType="text"
                    label={t('cop_local_authentication.cli_inactivity_timeout')}
                    name="cli_inactivity_timeout"
                    value={state.cli_inactivity_timeout}
                    onChange={(e) => {
                      dispatch({
                        value: e.target.value?.trim(),
                        field: 'cli_inactivity_timeout',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    validate={() =>
                      validateNumberRange(
                        state.cli_inactivity_timeout,
                        CLI_INACTIVITY_TIMEOUT_RANGE.start,
                        CLI_INACTIVITY_TIMEOUT_RANGE.end,
                        t(
                          'cop_local_authentication.invalid_inactivity_timeout',
                          {
                            startRange: CLI_INACTIVITY_TIMEOUT_RANGE.start,
                            endRange: CLI_INACTIVITY_TIMEOUT_RANGE.end
                          }
                        )
                      )
                    }
                    testId="cli-inactivity-timeout-input"
                  />
                  <Typography type="text" textAlign="center">
                    {t('minutes')}
                  </Typography>
                </Box>
              </Box>

              <Box
                direction="row-responsive"
                gap="medium"
                wrap={size === 'xsmall'}
              >
                <Box width={boxWidth}>
                  <FormInput
                    inputType="text"
                    label={t(
                      'cop_local_authentication.web_total_concurrent_sessions'
                    )}
                    placeholder={t(
                      'cop_local_authentication.total_concurrent_sessions_placeholder'
                    )}
                    name="web_total_concurrent_sessions"
                    value={state.web_total_concurrent_sessions}
                    onChange={(e) => {
                      dispatch({
                        value: e.target.value?.trim(),
                        field: 'web_total_concurrent_sessions',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    validate={() =>
                      state.web_total_concurrent_sessions === '' ||
                      validateNumberRange(
                        state.web_total_concurrent_sessions,
                        TOTAL_CONCURRENT_SESSIONS_RANGE.start,
                        TOTAL_CONCURRENT_SESSIONS_RANGE.end,
                        t(
                          'cop_local_authentication.invalid_concurrent_sessions',
                          {
                            startRange: TOTAL_CONCURRENT_SESSIONS_RANGE.start,
                            endRange: TOTAL_CONCURRENT_SESSIONS_RANGE.end
                          }
                        )
                      )
                    }
                    testId="web-total-concurrent-sessions-input"
                  />
                </Box>
                <Box width={boxWidth}>
                  <FormInput
                    inputType="text"
                    label={t(
                      'cop_local_authentication.cli_total_concurrent_sessions'
                    )}
                    placeholder={t(
                      'cop_local_authentication.total_concurrent_sessions_placeholder'
                    )}
                    name="cli_total_concurrent_sessions"
                    value={state.cli_total_concurrent_sessions}
                    onChange={(e) => {
                      dispatch({
                        value: e.target.value?.trim(),
                        field: 'cli_total_concurrent_sessions',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    validate={() =>
                      state.cli_total_concurrent_sessions === '' ||
                      validateNumberRange(
                        state.cli_total_concurrent_sessions,
                        TOTAL_CONCURRENT_SESSIONS_RANGE.start,
                        TOTAL_CONCURRENT_SESSIONS_RANGE.end,
                        t(
                          'cop_local_authentication.invalid_concurrent_sessions',
                          {
                            startRange: TOTAL_CONCURRENT_SESSIONS_RANGE.start,
                            endRange: TOTAL_CONCURRENT_SESSIONS_RANGE.end
                          }
                        )
                      )
                    }
                    testId="cli-total-concurrent-sessions-input"
                  />
                </Box>
              </Box>

              <Box width={boxWidth}>
                <FormInput
                  inputType="text"
                  label={t(
                    'cop_local_authentication.concurrent_sessions_per_user'
                  )}
                  placeholder={t(
                    'cop_local_authentication.concurrent_sessions_per_user_placeholder'
                  )}
                  name="web_total_concurrent_sessions_per_user"
                  value={state.web_total_concurrent_sessions_per_user}
                  onChange={(e) => {
                    dispatch({
                      value: e.target.value?.trim(),
                      field: 'web_total_concurrent_sessions_per_user',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  validate={() =>
                    state.web_total_concurrent_sessions_per_user === '' ||
                    validateNumberRange(
                      state.web_total_concurrent_sessions_per_user,
                      TOTAL_CONCURRENT_SESSIONS_RANGE.start,
                      TOTAL_CONCURRENT_SESSIONS_RANGE.end,
                      t(
                        'cop_local_authentication.invalid_concurrent_sessions',
                        {
                          startRange: TOTAL_CONCURRENT_SESSIONS_RANGE.start,
                          endRange: TOTAL_CONCURRENT_SESSIONS_RANGE.end
                        }
                      )
                    )
                  }
                  width="medium"
                  testId="web-concurrent-sessions-per-user-input"
                />
              </Box>

              <FormField
                name="login_banner"
                label={t('cop_local_authentication.login_banner')}
                validate={() =>
                  validateMaxLength(
                    state.login_banner,
                    LOGIN_BANNER_MAX_LENGTH,
                    t('cop_local_authentication.invalid_login_banner', {
                      length: LOGIN_BANNER_MAX_LENGTH
                    })
                  )
                }
                width={boxWidth}
                data-testid="login-banner-form-field"
              >
                <TextArea
                  name="login_banner"
                  resize="vertical"
                  placeholder={t(
                    'cop_local_authentication.login_banner_placeholder'
                  )}
                  value={state.login_banner}
                  onChange={(e) => {
                    dispatch({
                      value: e.target.value,
                      field: 'login_banner',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                  data-testid="login-banner-input"
                />
              </FormField>
            </Box>
          </>
        </CCSForm>
      )}
      {openDiscardModal && (
        <DiscardChangesModal
          closeModal={() => setOpenDiscardModal(false)}
          onContinue={handleDiscard}
        />
      )}
      {openSaveModal && (
        <SaveChangesModal
          closeModal={() => setOpenSaveModal(false)}
          onSave={handleSave}
        />
      )}
      {apiErrorMessage &&
        displayApiError(apiErrorMessage, commonT, setApiErrorMessage)}
      {successMessage &&
        displayNotification(successMessage, 'info', setSuccessMessage)}
    </Box>
  )
}

export default Sessions
