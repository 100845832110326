// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  ModalHeader,
  Tabs,
  Typography
} from '../../../../../../components'
import AssignedUsers from '../../../../../../commoncomponents/identity/AssignedUsers'
import { getCustomerRoleDetailsV2 } from '../../../../utils'
import { displayApiError } from '../../../../../../utils/error-handling-utils'

import { RoleDetailsTab } from './RoleDetailsTab'
import { AssignedUsersTab } from './AssignedUsersTab'

const RoleDetailSidePanel = ({
  roleDetails,
  setShowPanel,
  isTACCustomerSelected = false,
  setShowErrorNotification,
  customerId
}) => {
  const LDFlags = useFlags()
  const { oidcUser } = useReactOidc()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']
  const custRolesV2LDFlag = LDFlags['glcp-kobe-sa-customer-roles-flag']
  const { t } = useTranslation(['manage'])
  const [details, setDetails] = useState(
    custRolesV2LDFlag && !isTACCustomerSelected
      ? {
          name: roleDetails?.name,
          description: roleDetails?.description,
          application_name: roleDetails?.application_name,
          predefined: roleDetails?.predefined
        }
      : roleDetails
  )

  useEffect(() => {
    if (custRolesV2LDFlag && !isTACCustomerSelected) {
      const request = {
        application_id: roleDetails?.application_id,
        platform_customer_id: customerId
      }
      getCustomerRoleDetailsV2(
        request,
        oidcUser.access_token,
        (response) => {
          setDetails(response?.data || [])
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        },
        roleDetails?.slug
      )
    }
  }, [
    custRolesV2LDFlag,
    oidcUser.access_token,
    roleDetails,
    t,
    setShowErrorNotification,
    customerId,
    isTACCustomerSelected
  ])

  useEffect(() => {
    if (!custRolesV2LDFlag) {
      setDetails(roleDetails)
    }
  }, [custRolesV2LDFlag, roleDetails])
  return (
    <ModalDialog
      onClickOutside={() => setShowPanel(false)}
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="header-title"
              type="heading"
              weight="bold"
            >
              {details?.name}
            </Typography>
          }
          onClose={() => setShowPanel(false)}
        />
      }
      content={
        <Box margin={{ vertical: 'medium' }} direction="column">
          {Object.keys(details).length > 0 && (
            <Tabs
              justify="start"
              alignControls="start"
              testId="role-details-tabs"
              flex={{ shrink: 0 }}
              tabsList={[
                {
                  content: (
                    <Box>
                      <RoleDetailsTab roleDetails={details} />
                    </Box>
                  ),
                  id: 1,
                  label: t('customer_roles.details'),
                  testId: 'role-details-tab'
                },
                {
                  content: (
                    <Box>
                      {saCustomRoleLDFlag && isTACCustomerSelected ? (
                        <AssignedUsers role={details} inCCSManager />
                      ) : (
                        <AssignedUsersTab
                          roleDetails={details}
                          customerId={customerId}
                        />
                      )}
                    </Box>
                  ),
                  id: 2,
                  label: t('customer_roles.assigned_users'),
                  testId: 'assigned-users-tab'
                }
              ]}
            />
          )}
        </Box>
      }
      height="100%"
      width="medium"
      onClose={() => setShowPanel(false)}
      position="right"
      testId="role-details-side-panel"
    />
  )
}

RoleDetailSidePanel.propTypes = {
  roleDetails: PropTypes.object.isRequired,
  setShowPanel: PropTypes.func.isRequired,
  isTACCustomerSelected: PropTypes.bool,
  setShowErrorNotification: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired
}

export { RoleDetailSidePanel }
