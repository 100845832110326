// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useState, useEffect, useRef } from 'react'
import { Box, TextArea, Anchor } from 'grommet'
import PropTypes from 'prop-types'
import { Send } from 'grommet-icons'
import styled from 'styled-components'

import { Typography } from '../../components'

import { ChatActions, useChatContext } from './context/chat-context'

const StyledTextArea = styled(TextArea)`
  all: unset;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::placeholder {
    pointer-events: none;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`
const StyledBox = styled(Box)`
  background-color: ${(props) =>
    props.messageLength > 250
      ? `${props.theme.global.colors['status-critical'].light}1f`
      : `${props.theme.global.colors['background-front'].light}`};
`

const ChatFooter = ({ client, closeChatBot, isChatEmpty }) => {
  const [message, setMessage] = useState('')
  const [showScrollbar, setShowScrollbar] = useState(false)
  const [numberOfLines, setNumberOfLines] = useState(0)
  const [prevMessageLength, setPrevMessageLength] = useState(0)
  const [isResizing, setIsResizing] = useState(false)
  const [over, setOver] = useState(false)
  const CHAR_MAX_LIMIT = 250

  const {
    sessionId,
    dispatchChatContext,
    disableInput,
    textAreaHeight,
    setTextAreaHeight
  } = useChatContext()

  const textareaRef = useRef()
  useEffect(() => {
    const savedMessage = sessionStorage.getItem('chatInputMessage')
    if (savedMessage) {
      setMessage(savedMessage)
    }
  }, [])

  useEffect(() => {
    textareaRef.current?.focus()

    sessionStorage.setItem('chatInputMessage', message)
  })

  const sendMessage = () => {
    const time = new Date()
    if (message.length > 0 && message.trim() !== '') {
      const payload = {
        from: 'You',
        text: message,
        time: `${time.getHours()}:${time.getMinutes()}`
      }
      if (sessionId) {
        dispatchChatContext({ type: ChatActions.UPDATE_CHAT, payload })
        dispatchChatContext({
          type: ChatActions.SYNC_USER_INPUT_TO_BUTTONS,
          payload
        })
        client.send(message)
      } else dispatchChatContext({ type: ChatActions.ERROR_CONNECTING_CHAT })
      setMessage('')
      sessionStorage.removeItem('chatInputMessage')
    }
  }
  const onClearChatBot = async () => {
    setMessage('')
    sessionStorage.removeItem('chatInputMessage')
    closeChatBot({ type: ChatActions.CLEAR_CHAT })
  }

  const handleTextAreaChange = (e) => {
    setMessage(e.target.value)
  }

  const handleMouseDown = () => {
    setIsResizing(true)
  }

  const handleMouseUp = () => {
    setIsResizing(false)
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isResizing) {
        const textArea = textareaRef.current
        const newHeight = Math.max(24, textArea.clientHeight - event.movementY)
        const maxHeight = 300
        textArea.style.height = `${Math.min(newHeight, maxHeight)}px`
        setTextAreaHeight(textArea.style.height)
      }
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing, setTextAreaHeight])

  useEffect(() => {
    const textArea = textareaRef.current
    const currentMessageLength = message.length

    const calculatedNumberOfLines = Math.floor(textArea.scrollHeight / 24)

    if (currentMessageLength < prevMessageLength) {
      const newBoxHeight = Math.max(24, textArea.clientHeight - 24)
      textArea.style.height = `${newBoxHeight}px`
    }
    if (message.trim() === '') {
      setTextAreaHeight(24)
      setNumberOfLines(0)
      setShowScrollbar(false)
    } else {
      if (calculatedNumberOfLines > 1) {
        textArea.style.height = `${Math.min(textArea.scrollHeight, 150)}px`
        setTextAreaHeight(textArea.style.height)
        setNumberOfLines(calculatedNumberOfLines)
        setShowScrollbar(calculatedNumberOfLines > 4)
      }
      setPrevMessageLength(currentMessageLength)
    }
  }, [message, prevMessageLength, setTextAreaHeight, numberOfLines])

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      message.length <= CHAR_MAX_LIMIT
    ) {
      event.preventDefault()
      sendMessage()
    }
  }

  const count = message.length
  const overLimit = count - CHAR_MAX_LIMIT
  const setDownBackground = () => {
    if (disableInput || overLimit > 0 || isChatEmpty) {
      return '#01A98284'
    }
    if (over) {
      return '#007159'
    }
    return 'rgb(1, 169, 130)'
  }
  return (
    <Box
      data-testid="MouseDownUp"
      direction="column"
      pad={{ top: 'xsmall' }}
      background="background-back"
      style={{ position: 'relative' }}
    >
      <Box
        data-testid="chat-footer"
        direction="row"
        align="center"
        pad={{ horizontal: 'small', vertical: 'small' }}
        justify="start"
        background="background-back"
        gap="0px"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          bottom: '0px',
          position: 'relative',
          width: '100%'
        }}
      >
        <StyledBox
          messageLength={message.length}
          flex="grow"
          direction="row"
          align="initial"
          round="6px"
          pad={{ right: 'small', left: 'small', top: '6px', bottom: '6px' }}
          border={{ color: 'lightgray' }}
          style={{
            minHeight: '24px',
            height: 'auto',
            minWidth: '294px'
          }}
        >
          <StyledTextArea
            ref={textareaRef}
            placeholder="Type your issue..."
            plain
            focusIndicator={false}
            resize={false}
            value={message}
            onChange={handleTextAreaChange}
            onKeyDown={handleKeyDown}
            disabled={disableInput || isChatEmpty}
            data-testid="textarea"
            style={{
              fontSize: '18px',
              width: '100%',
              minHeight: 'inherit',
              minWidth: 'inherit',
              fontWeight: '400',
              display: 'inline-block',
              verticalAlign: 'middle',
              wordWrap: 'break-word',
              lineHeight: `24px`,
              height: `${textAreaHeight}px`,
              overflowY: showScrollbar ? 'scroll' : 'hidden',
              padding: '0px',
              alignContent: 'center',
              cursor: isChatEmpty || disableInput ? 'not-allowed' : 'text'
            }}
          />
        </StyledBox>
        <Box
          data-testid="chat-send-icon"
          flex="shrink"
          pad="9px"
          background="brand"
          round="full"
          align="center"
          justify="center"
          margin={{ left: '6px' }}
          width="auto"
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
          style={{
            minWidth: '36px',
            minHeight: '36px',
            zIndex: '1',
            background: setDownBackground()
          }}
        >
          <Send
            size="18px"
            id="chat-send-ico"
            data-testid="send-icon"
            onClick={sendMessage}
            style={{
              cursor:
                isChatEmpty || disableInput || overLimit > 0
                  ? 'not-allowed'
                  : 'pointer',
              pointerEvents:
                isChatEmpty || disableInput || overLimit > 0 ? 'none' : 'auto'
            }}
          />
        </Box>
      </Box>
      <Box
        direction="column"
        pad={{ horizontal: 'small', bottom: 'medium' }}
        align="center"
        justify="center"
        style={{ cursor: 'default' }}
      >
        {count > CHAR_MAX_LIMIT / 2 && (
          <>
            <Box
              direction="column"
              pad={{ horizontal: 'xxsmall', bottom: 'small' }}
              data-testid="characterCount"
              align="center"
              justify="center"
              style={{ marginRight: 'auto' }}
            >
              <Typography
                margin={{ right: '400px' }}
                type="text"
                size="xsmall"
                color={250 - count >= 0 ? 'text-weak' : 'status-critical'}
                style={{
                  lineHeight: '10px',
                  whiteSpace: 'nowrap'
                }}
              >
                {250 - count < 0
                  ? `${overLimit} characters over limit`
                  : `${250 - count} characters left`}
              </Typography>
            </Box>
          </>
        )}

        <Box
          direction="row"
          align="center"
          justify="between"
          fill="horizontal"
          pad={{ horizontal: 'xxsmall' }}
        >
          <Typography
            type="text"
            size="xsmall"
            color="text-weak"
            weight="500"
            data-testid="clear-chat-span"
            onClick={() => {
              if (!isChatEmpty || disableInput) {
                onClearChatBot()
              }
            }}
            style={{
              cursor: !isChatEmpty || disableInput ? 'pointer' : 'not-allowed',
              marginRight: 'auto',
              textDecoration: 'underline'
            }}
          >
            New conversation
          </Typography>

          <Typography type="text" size="xsmall" color="text-weak">
            <Anchor
              color="rgba(117, 117, 117)"
              href="https://www.hpe.com/us/en/legal/privacy.html#privacystatement"
              label="HPE Privacy Statement"
              target="_blank"
              style={{ marginLeft: 'auto' }}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatFooter

ChatFooter.propTypes = {
  client: PropTypes.object.isRequired,
  closeChatBot: PropTypes.func.isRequired,
  isChatEmpty: PropTypes.object.isRequired
}
