// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, FormField, RadioButtonGroup } from 'grommet'
/* eslint-disable import/no-unresolved */
/* eslint-enable */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Typography,
  CCSForm,
  Button,
  ModalDialog,
  ModalHeader,
  FormInput
} from '../../../../components'
import { post, getErrorMessage } from '../../../../utils/api-utils'
import { validateEmail } from '../../../../utils/validation-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import {
  getStatusForMultiStatusResponse,
  getNotificationMessageForMultiStatusResponse
} from '../../../../utils/ccs-manager-utils'

const SAInviteUserModal = ({
  onSetOpen,
  onSuccess = () => {},
  setNotifyComponent
}) => {
  const [formValues, setFormValues] = useState({
    emails: '',
    role: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [errorOccurred, setErrorOccurred] = useState('')
  const { t } = useTranslation([
    'authn',
    'common',
    'support_access_manager',
    'manage'
  ])
  const { oidcUser } = useReactOidc()
  const [selectedOption, setSelectedOption] = useState('read')
  const LDFlags = useFlags()
  const samLDFlagPhase2 = LDFlags['glcp-support-access-manager-phase2']
  const scopeOptions = [
    { label: t('support_access_manager:read_access'), value: 'read' },
    { label: t('support_access_manager:read_and_write_access'), value: 'write' }
  ]
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const inviteUser = () => {
    const { emails } = formValues
    const requestBody = {
      username: emails.length ? emails.split(', ') : '',
      access_type: selectedOption
    }
    setIsSubmitting(true)

    const url = '/support-assistant/v1alpha1/invite-support-access-user'

    post(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response && response?.data) {
          let invitedUsersMsg = ''
          let inviteFailedMsg = '\n '
          if (response?.data?.success && response?.data?.success.length > 0) {
            invitedUsersMsg = t(
              'support_access_manager:invite_user.invite_user_success_msg',
              {
                users: response?.data?.success.join(', ')
              }
            )
          }
          if (response?.data?.failed && response?.data?.failed?.length > 0) {
            inviteFailedMsg = inviteFailedMsg.concat(
              response?.data?.failed.map((failedUser) => {
                return t(
                  'support_access_manager:invite_user.invite_user_error_msg',
                  {
                    user: Object.keys(failedUser)[0],
                    errorMsg: Object.values(failedUser)[0]
                  }
                )
              })
            )
          }

          const status = getStatusForMultiStatusResponse(response)

          const autoClose = !(
            Boolean(response?.data?.failed?.length > 0) &&
            Boolean(response.data?.success?.length > 0)
          )
          const notification = displayNotification(
            getNotificationMessageForMultiStatusResponse(
              invitedUsersMsg,
              t('manage:success'),
              inviteFailedMsg === '\n ' ? '' : inviteFailedMsg,
              t('manage:error')
            ),
            status,
            setNotifyComponent,
            '',
            autoClose
          )

          setNotifyComponent(notification)
          onSetOpen(false)
          onSuccess()
        }
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorOccurred(backendErrorMessage)
        setIsSubmitting(false)
      }
    )
  }

  return (
    <ModalDialog
      onClickOutside={() => onSetOpen(false)}
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2" testId="invite-title">
              {t('support_access_manager:invite_user.modal_title')}
            </Typography>
          }
        />
      }
      content={
        <Box gap="medium" margin={{ top: 'xsmall' }}>
          <Typography type="text" size="large" testId="invite-subtitle">
            {t('support_access_manager:invite_user.invite_user_subtitle')}
          </Typography>
          <CCSForm
            value={formValues}
            onChange={setFormValues}
            errorMessage={errorOccurred}
            testId="invite-user-form"
            validate="submit"
            onSubmit={inviteUser}
            buttons={
              <Box direction="row" justify="end" gap="medium">
                <Button
                  default
                  label={t('users.cancel')}
                  onClick={() => {
                    onSetOpen(false)
                  }}
                  testId="cancel-invite-btn"
                />
                <Button
                  primary
                  type="submit"
                  disabled={isSubmitting}
                  label={t('users.sendInvite')}
                  testId="send-invite-btn"
                />
              </Box>
            }
          >
            {samLDFlagPhase2 && (
              <Box gap="small" margin={{ bottom: 'small' }}>
                <FormField
                  name="form-sample"
                  label={t('support_access_manager:support_access_rights')}
                  requiredlabel={t(
                    'support_access_manager:support_access_rights'
                  )}
                >
                  <RadioButtonGroup
                    value={selectedOption}
                    labelKey="label"
                    valueKey="value"
                    options={scopeOptions}
                    onChange={handleOptionChange}
                    disabled={false}
                  />
                </FormField>
              </Box>
            )}

            <Box gap="small" margin={{ bottom: 'small' }}>
              <Box>
                <FormInput
                  inputType="text"
                  name="emails"
                  label={t('users.emailFormLabel')}
                  help=""
                  required
                  validate={(value) => {
                    let msg
                    value.split(', ').some((email) => {
                      msg = validateEmail(email)
                      // allow only HPE mails to be invited to TAC account
                      const isNotHpeEmail = !email
                        .toLowerCase()
                        .endsWith('@hpe.com')
                      if (isNotHpeEmail) {
                        msg = t('users.only_hpe_users_in_ccs_manager_msg')
                      }
                      return isNotHpeEmail
                    })

                    return msg
                  }}
                  testId="email-form-field"
                  placeholder="user1@hpe.com, user2@hpe.com"
                />
                <Typography type="paragraph" size="small" testId="invite-note">
                  {t('users.emailInfo')}
                </Typography>
              </Box>
            </Box>
          </CCSForm>
        </Box>
      }
      onClose={() => onSetOpen(false)}
      testId="invite-user-modal"
      width="medium"
    />
  )
}

SAInviteUserModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  setNotifyComponent: PropTypes.func.isRequired
}

export { SAInviteUserModal }
