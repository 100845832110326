// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { Hpe } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { DataTable, Logo, Typography } from '../../../../../../components'
import { getPaginationShowIdx } from '../../../../../../utils/common-utils'
import { displayApiError } from '../../../../../../utils/error-handling-utils'
import { RoleDetailSidePanel } from '../role-details/RoleDetailSidePanel'
import {
  getCustomerRoles,
  getCustomerRolesForHpeGreenlakeSupport,
  getCustomerRolesV2
} from '../../../../utils'
import { getCustomerAccount } from '../../../../../../utils/feature-flag-utils'

const RolesDataTable = ({ customerId }) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])

  const [rolesDataResponse, setRolesDataResponse] = useState([])
  const [rolesViewData, setRolesViewData] = useState([])
  const [searchString, setSearchString] = useState('')
  const [showErrorNotification, setShowErrorNotification] = useState(null)

  // for side panel
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [selectedRowDetails, setSelectedRowDetails] = useState({})

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const LDFlags = useFlags()
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']
  const custRolesV2LDFlag = LDFlags['glcp-kobe-sa-customer-roles-flag']
  const custAccountLoaded = getCustomerAccount()
  const isTACCustomerSelected =
    customerId === custAccountLoaded?.platform_customer_id

  const ccsApplicationId = '00000000-0000-0000-0000-000000000000'

  useEffect(() => {
    let isCurrent = true

    if (saCustomRoleLDFlag && isTACCustomerSelected) {
      const request = {
        platform_cid: customerId,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage
      }
      getCustomerRolesForHpeGreenlakeSupport(
        request,
        oidcUser.access_token,
        custAccountLoaded?.platform_customer_id,
        ccsApplicationId,
        (response) => {
          if (!isCurrent) return
          setRolesViewData(response?.data?.roles || [])
          setTotalItems(response?.data?.pagination?.total_count)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        } // setLoading
      )
    }

    return () => {
      isCurrent = false
    }
  }, [
    customerId,
    oidcUser.access_token,
    t,
    page,
    custAccountLoaded?.platform_customer_id,
    isTACCustomerSelected,
    saCustomRoleLDFlag
  ])

  useEffect(() => {
    let isCurrent = true
    if (!isTACCustomerSelected && !custRolesV2LDFlag) {
      const request = {
        platform_cid: customerId
      }
      getCustomerRoles(
        request,
        oidcUser.access_token,
        (response) => {
          if (!isCurrent) return
          setRolesDataResponse(response?.data?.roles || [])
          setTotalItems(response?.data?.pagination?.total_count)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        } // setLoading
      )
    }

    return () => {
      isCurrent = false
    }
  }, [
    customerId,
    oidcUser.access_token,
    t,
    custAccountLoaded?.platform_customer_id,
    isTACCustomerSelected,
    saCustomRoleLDFlag,
    custRolesV2LDFlag
  ])

  useEffect(() => {
    let isCurrent = true
    if (!isTACCustomerSelected && custRolesV2LDFlag) {
      const request = {
        platform_customer_id: customerId,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        ...(searchString.length && { search_string: searchString })
      }
      getCustomerRolesV2(
        request,
        oidcUser.access_token,
        (response) => {
          if (!isCurrent) return
          setTotalItems(response?.data?.pagination?.total_count)
          setRolesViewData(response?.data?.roles)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        } // setLoading
      )
    }

    return () => {
      isCurrent = false
    }
  }, [
    customerId,
    oidcUser.access_token,
    t,
    custAccountLoaded?.platform_customer_id,
    isTACCustomerSelected,
    saCustomRoleLDFlag,
    custRolesV2LDFlag,
    searchString,
    page
  ])

  // TODO: API returns wrong pagination and does not support search
  // until then we are doing UI based search and pagination
  useEffect(() => {
    if (
      rolesDataResponse.length &&
      !isTACCustomerSelected &&
      !custRolesV2LDFlag
    ) {
      let roles = [...rolesDataResponse]
      if (searchString?.length) {
        const searchStr = searchString?.toLowerCase()
        roles = rolesDataResponse.filter(
          (val) =>
            val?.name?.toLowerCase()?.includes(searchStr) ||
            val?.description?.toLowerCase()?.includes(searchStr) ||
            val?.application_name?.toLowerCase()?.includes(searchStr)
        )
      }
      setTotalItems(roles?.length || 0)
      const offset = (page - 1) * itemsPerPage
      setRolesViewData(roles.splice(offset, itemsPerPage))
    }
  }, [
    rolesDataResponse,
    page,
    searchString,
    isTACCustomerSelected,
    custRolesV2LDFlag
  ])

  const renderUsersCount = (datum) => {
    let returnVal =
      datum?.users?.length > 0
        ? `${datum?.users?.length} ${
            datum?.users?.length > 1
              ? t('customer_roles.users')
              : t('customer_roles.user')
          }`
        : '--'
    if (custRolesV2LDFlag) {
      returnVal =
        datum?.total_users > 0
          ? `${datum?.total_users} ${
              datum?.total_users > 1
                ? t('customer_roles.users')
                : t('customer_roles.user')
            }`
          : '--'
    }
    return returnVal
  }
  const columns = [
    {
      property: 'name',
      type: 'string',
      header: t('customer_roles.name'),
      sortable: true
    },
    {
      property: 'description',
      type: 'string',
      header: t('customer_roles.description')
    },
    {
      property: 'type',
      type: 'string',
      header: t('customer_roles.role_type'),
      render: (datum) =>
        datum?.predefined
          ? t('customer_roles.built_in')
          : t('customer_roles.custom')
    },
    ...(saCustomRoleLDFlag && isTACCustomerSelected
      ? []
      : [
          {
            property: 'users',
            type: 'numeric',
            header: t('customer_roles.assigned_users'),
            render: (datum) => {
              return renderUsersCount(datum)
            }
          }
        ]),

    {
      property: 'application_name',
      type: 'string',
      header: t('customer_roles.application'),
      render: (datum) => (
        <Box align="center" gap="small" direction="row">
          {datum?.application_id === '00000000-0000-0000-0000-000000000000' ? (
            <Hpe color="brand" size="small" />
          ) : (
            <Logo
              size="xxsmall"
              url={datum?.application_logo}
              testId="application-logo"
            />
          )}
          <Typography size="medium" type="text">
            {datum?.application_name || '--'}
          </Typography>
        </Box>
      )
    },
    {
      property: 'u_id',
      primary: true,
      render: () => <></>,
      hidden: true
    }
  ]

  return (
    <Box margin={{ top: 'small', bottom: 'medium' }}>
      <DataTable
        grid={{
          columns,
          data: rolesViewData,
          onClickRow: ({ datum }) => {
            setSelectedRowDetails(datum)
            setShowSidePanel(true)
          }
        }}
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        {...(saCustomRoleLDFlag && isTACCustomerSelected
          ? {}
          : {
              search: {
                onSearchValueChange: (value) => {
                  setPage(1)
                  setSearchString(value?.trim())
                },
                placeholder: t('customer_roles.search_roles')
              }
            })}
        summary={{
          entityName: t('customer_roles.roles')
        }}
        testId="customer-roles-data-table"
      />
      {showSidePanel && (
        <RoleDetailSidePanel
          isTACCustomerSelected={isTACCustomerSelected}
          roleDetails={selectedRowDetails}
          setShowPanel={setShowSidePanel}
          setShowErrorNotification={setShowErrorNotification}
          customerId={customerId}
        />
      )}
      {showErrorNotification}
    </Box>
  )
}

RolesDataTable.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

export { RolesDataTable }
