// Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { isEmpty } from 'lodash'

import {
  ButtonGroup,
  CCSForm,
  Dropdown,
  FormInput,
  Loader,
  ModalDialog,
  ModalHeader,
  Notification,
  Typography
} from '../../../components'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import Markdown from '../../../commoncomponents/Markdown'
import { getErrorMessage, post, del, get } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { establishV2SessionOnLaunch } from '../../../utils/account-utils'
import { useCCSContext, CCSActions } from '../../../context/ccs-context'
import { useVisibilityContext } from '../../../context/visibility-context'
import useLogger from '../../../hooks/logs/useLogger'
import { V2_LOGGER } from '../../../utils/log-util'
import { cleanAccountSessionStorage } from '../../../utils/client-storage-cleaning-utils'
import { LIFECYCLE_STATE } from '../../../utils/common-utils'

import { ERROR_CODE } from './constants'

const JoinOrganizationModal = ({
  showDialog,
  setNotification = () => {},
  refreshParent = () => {}
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const { csrfToken } = useCCSContext()
  const { dispatchCCSContext } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const logger = useLogger()
  const [initialLoading, setInitialLoading] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(null)
  const [errorMsg, setErrorMsg] = useState('')
  const [showPermissionError, setShowPermissionError] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [inputText, setInputText] = useState('')
  const custAccountLoaded = getCustomerAccount()
  const [orgList, setOrgList] = useState(null)
  const [next, setNext] = useState(null)

  const markdownStyle = {
    strong: {
      props: {
        size: 'xsmall'
      }
    }
  }

  const closeDialog = () => {
    showDialog(false)
  }

  const reestablishV2Session = (account) => {
    if (process.env.NODE_ENV !== 'development') {
      logger?.log({
        ...V2_LOGGER,
        section: 'end',
        msg: `ending v2 session prior to joining new org`
      })
      del(`/internal-sessions/v1alpha1/my-ui-session`).then(
        () => {
          establishV2SessionOnLaunch(
            oidcUser,
            account,
            csrfToken,
            '/manage-account/organization',
            navigate,
            dispatchCCSContext,
            dispatchVisibilityContext,
            logger
          )
        },
        (error) => {
          logger?.log({
            msg: `error in ending v2 session prior to joining new org`,
            type: 'error',
            data: error
          })
          cleanAccountSessionStorage()
          dispatchCCSContext({
            type: CCSActions.SET_CSRF_TOKEN,
            data: null
          })
          navigate('/post-onboarding/choose-account')
        }
      )
    } else {
      refreshParent()
      closeDialog()
    }
  }

  const handleSubmit = () => {
    const requestBody = {
      organizationId: selectedOption?.id
    }
    if (isEmpty(inputText?.trim())) setErrorMsg(t('common:this_is_required'))
    else if (
      inputText?.trim() &&
      inputText?.trim() !==
        t('iam:organization.join_organization.join_existing_org.join_keyword')
    ) {
      setErrorMsg(t('common:invalid_keyword'))
    } else if (selectedOption?.id && selectedOption?.id !== '') {
      setSubmitLoading(true)
      post(
        `/organizations/v2alpha1/workspaces/${custAccountLoaded?.platform_customer_id}/join-organization`,
        requestBody
      ).then(
        () => {
          setNotification(
            displayNotification(
              <Markdown>
                {t(
                  'iam:organization.join_organization.join_existing_org.success_message',
                  {
                    Workspace: t('common:business_object.wkspc_capitalized'),
                    workspaceName: custAccountLoaded?.company_name,
                    organizationName: selectedOption?.name,
                    joinKeyword: t(
                      'iam:organization.join_organization.join_existing_org.join_keyword'
                    )
                  }
                )}
              </Markdown>,
              'info',
              setNotification
            )
          )
          const account = JSON.parse(sessionStorage.getItem('account'))
          if (account) {
            account.organization_id = selectedOption?.id
            sessionStorage.setItem('account', JSON.stringify(account))
          }
          reestablishV2Session(account)
          setSubmitLoading(false)
        },
        (error) => {
          if (
            error?.response?.status === 403 &&
            error?.response?.data?.errorCode ===
              ERROR_CODE.ORGANIZATIONS_ERR_FORBIDDEN
          ) {
            setShowPermissionError(true)
          } else {
            setNotification(
              displayNotification(
                getErrorMessage(error, t),
                'error',
                setNotification
              )
            )
          }
          setSubmitLoading(false)
        }
      )
    }
  }

  useEffect(() => {
    if (!next) setInitialLoading(true)
    const queryFilter = `filter=${encodeURI(
      `lifecycleState eq '${LIFECYCLE_STATE.ACTIVE}'`
    )}`

    const queryLimit = `&limit=200`
    get(
      `/organizations/v2alpha1/organizations?${queryFilter}${queryLimit}${
        next ? `&next=${encodeURI(next)}` : ''
      }`
    ).then(
      (response) => {
        if (orgList) {
          response.data.items = [...orgList.items, ...response.data.items]
          setOrgList(response?.data)
        } else {
          setOrgList(response?.data)
        }
        if (response?.data?.items?.length === 1) {
          setSelectedOption(response?.data?.items[0])
        }

        setInitialLoading(false)
      },
      (error) => {
        setNotification(
          displayNotification(
            getErrorMessage(error, t),
            'error',
            setNotification
          )
        )
        setInitialLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, next, setNotification])

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box pad={{ bottom: 'small' }}>
              <Typography
                type="heading"
                level="2"
                testId="join-org-modal-header"
              >
                {t('iam:organization.join_organization.label')}
              </Typography>
            </Box>
          }
          subtitle={
            <Box pad={{ bottom: 'small' }}>
              <Typography type="paragraph" testId="join-org-modal-subtitle">
                {t(
                  'iam:organization.join_organization.join_existing_org.description',
                  {
                    workspace: t('common:business_object.wkspc')
                  }
                )}
              </Typography>
            </Box>
          }
          onClose={closeDialog}
        />
      }
      content={
        <CCSForm
          errorMessage=""
          testId="join-org-form"
          onSubmit={handleSubmit}
          validate="blur"
          style={{ paddingRight: '1rem' }}
          buttons={
            <Box pad={{ top: 'medium' }}>
              <ButtonGroup
                buttonList={[
                  {
                    id: 1,
                    label: initialLoading
                      ? '∙  ∙  ∙'
                      : t('iam:organization.join_organization.label'),
                    type: 'submit',
                    primary: true,
                    disabled: initialLoading,
                    testId: 'join-org-submit-btn'
                  },
                  {
                    id: 2,
                    label: t('common:cancel'),
                    type: 'reset',
                    testId: 'join-org-cancel-btn',
                    disabled: initialLoading,
                    onClick: closeDialog
                  }
                ]}
                justifyGroup="start"
                testId="join-org-modal-btns"
              />
              {submitLoading && (
                <Box direction="row" gap="small" pad={{ top: 'medium' }}>
                  <Loader testId="join-org-modal-loader" />
                  <Typography type="text" testId="loader-msg">
                    {t(
                      'iam:organization.join_organization.join_existing_org.joining_organization'
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          }
        >
          <Box direction="column" gap="xsmall" pad={{ bottom: 'medium' }}>
            {initialLoading ? (
              <Loader testId="join-org-modal-dropdown-loader" />
            ) : (
              <FormField
                required
                data-testid="join-org-modal-option"
                label={t('common:business_object.organization')}
                name="name"
              >
                <Dropdown
                  name="name"
                  labelKey="name"
                  valueKey="name"
                  value={
                    orgList?.items?.length === 1
                      ? orgList?.items
                      : orgList?.items?.name
                  }
                  placeholder={
                    orgList?.items?.length === 1
                      ? orgList?.items[0]?.name
                      : t('common:select')
                  }
                  options={orgList?.items}
                  onChange={({ option }) => {
                    setSelectedOption(option)
                  }}
                  onMore={() => {
                    if (orgList?.next) {
                      setNext(orgList?.next)
                    }
                  }}
                  dropHeight="medium"
                  testId="join-org-modal-dropdown"
                />
              </FormField>
            )}
            <Notification
              status="info"
              testId="join-org-modal-inline-notification"
              text={
                <>
                  <Typography type="text">
                    {t(
                      'iam:organization.join_organization.join_existing_org.info_1'
                    )}
                  </Typography>
                  <Typography type="text">
                    {t(
                      'iam:organization.join_organization.join_existing_org.info_2'
                    )}
                  </Typography>
                </>
              }
              type="inline"
            />
            {selectedOption && (
              <FormInput
                required
                name="confirmation"
                inputType="text"
                label={t(
                  'iam:organization.join_organization.join_existing_org.confirmation_field'
                )}
                labelHelper={
                  <Typography type="text" size="xsmall">
                    <Markdown components={markdownStyle}>
                      {t(
                        'iam:organization.join_organization.join_existing_org.confirmation',
                        {
                          workspace: t('common:business_object.wkspc'),
                          workspaceName: custAccountLoaded?.company_name,
                          organizationName:
                            orgList?.items?.length === 1
                              ? orgList?.items[0]?.name
                              : selectedOption?.name,
                          joinKeyword: t(
                            'iam:organization.join_organization.join_existing_org.join_keyword'
                          )
                        }
                      )}
                    </Markdown>
                  </Typography>
                }
                error={errorMsg}
                value={inputText}
                onChange={(event) => {
                  setInputText(event.target.value)
                  setErrorMsg('')
                }}
                testId="confirm-join"
              />
            )}
            {showPermissionError && (
              <Box margin={{ top: 'small' }}>
                <Notification
                  status="critical"
                  testId="join-org-permission-error"
                  text={
                    <Typography type="text">
                      {t(
                        'iam:organization.join_organization.join_existing_org.permission_error',
                        {
                          workspace: t('common:business_object.wkspc')
                        }
                      )}
                    </Typography>
                  }
                  type="inline"
                />
              </Box>
            )}
          </Box>
        </CCSForm>
      }
      pad="medium"
      position="right"
      height="100%"
      width="medium"
      onClose={closeDialog}
      testId="join-org-dialog"
    />
  )
}

JoinOrganizationModal.propTypes = {
  showDialog: PropTypes.func.isRequired,
  setNotification: PropTypes.func,
  refreshParent: PropTypes.func
}

export default JoinOrganizationModal
