// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'

import { LDCodeSamples } from './LDCodeSamples'

const FeatureFlaggingPage = () => {
  const custAccountLoaded = getCustomerAccount()
  const PCID = custAccountLoaded?.platform_customer_id || ''
  const LDFlags = useFlags()

  const LDCodeSnippets = {
    usingLDHook: `
    // Pre-req: Make sure to add your feature flag in glcp-ld-feature-flags.js
    // 1. importing LaunchDarkly (LD) hook
    import { useFlags } from 'launchdarkly-react-client-sdk'
    // 2. initializing the LD hook
    const LDFlags = useFlags()
    // 3. handle the logic based on the feature flag variation
    // here 'glcp-testing' is set as boolean variant in LD
    {LDFlags['glcp-testing'] ? 
        <p> Show when 'glcp-testing' flag is set to TRUE in LD</p> :
        <p> Hide when 'glcp-testing' flag is set to FALSE in LD</p>
    }
    `,
    usingVisibilityWrapper: `
    // Pre-req: Make sure to add your feature flag in glcp-ld-feature-flags.js
    // 1. importing VisibilityWrapper from commoncomponents (this path varies based where the component resides)
    import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
    // 2. handle the logic based on the feature flag variation
    <VisibilityWrapper hideFor={{ feature: 'glcp-testing' }}>
      <!--Content goes here-->
    </VisibilityWrapper>
    `,
    usingJSONVariation: `
    // Pre-req: Make sure to add your feature flag in glcp-ld-feature-flags.js
    // 1. importing LaunchDarkly (LD) hook
    import { useFlags } from 'launchdarkly-react-client-sdk'
    // 2. initializing the LD hook
    const LDFlags = useFlags()
    // 3. handle the logic based on the feature flag variation
    // here 'glcp-test-pcid-json' is set as JSON variant in LD as below
    // {
    //   "pcids": [
    //     "26a76e0456c111ecbe70ca15ba50c503",
    //     "1234"
    //   ]
    // }
    {LDFlags['glcp-test-pcid-json']?.pcids?.includes(PCID) ? (
        <p> Show when 'glcp-test-pcid-json' flag is matching with your PCID </p> :
        <p> Hide when 'glcp-test-pcid-json' flag is NOT matching with your PCID</p>
    }
    `
  }
  return (
    <Box width="100%" margin={{ right: 'large' }}>
      <Box direction="row" justify="between">
        <Typography
          level="2"
          type="heading"
          weight="normal"
          testId="feature-flagging-page-title"
        >
          Feature Flagging
        </Typography>
      </Box>
      <Typography
        type="paragraph"
        size="medium"
        testId="feature-flagging-page-subtitle"
        border={{ side: 'bottom', color: '#CCCCCC' }}
      >
        Understand and test feature flagging using LaunchDarkly.{' '}
        <strong>
          Toggle flags in LD dashboard to visualize the changes here.
        </strong>
      </Typography>
      <Box margin={{ top: 'medium' }}>
        <Box>
          {/* LaunchDarkly using hook */}
          {LDFlags['glcp-testing'] ? (
            <Typography type="text" color="brand" size="xlarge">
              Show when <strong>glcp-testing</strong> flag is turned ON in LD
            </Typography>
          ) : (
            <Typography type="text" color="red" size="xlarge">
              Hide when <strong>glcp-testing</strong> flag is turned OFF in LD
            </Typography>
          )}
          <LDCodeSamples codeString={LDCodeSnippets.usingLDHook} />
        </Box>
        <Box>
          {/* LaunchDarkly using VisibilityWrapper */}
          {!LDFlags['glcp-testing'] && (
            <Typography type="text" size="xlarge" weight="bold">
              Testing Feature Flag using VisibilityWrapper (recommended)
            </Typography>
          )}
          <VisibilityWrapper hideFor={{ feature: 'glcp-testing' }}>
            <Typography type="text" color="brand" size="xlarge">
              Show when <strong>glcp-testing</strong> flag is turned ON in LD
              and the target matching.
            </Typography>
          </VisibilityWrapper>
          <LDCodeSamples codeString={LDCodeSnippets.usingVisibilityWrapper} />
        </Box>
        <Box>
          {/* LaunchDarkly using JSON variations */}
          {LDFlags['glcp-test-pcid-json']?.pcids?.includes(PCID) ? (
            <Typography
              type="text"
              testId="ld-pcid-test"
              color="brand"
              size="xlarge"
            >
              Show when <strong>glcp-test-pcid-json</strong> flag is turned ON
              and the JSON type variation - PCID {PCID} is matching!!
            </Typography>
          ) : (
            <Typography
              type="text"
              testId="ld-pcid-test2"
              color="red"
              size="xlarge"
            >
              Hide when <strong>glcp-test-pcid-json</strong> flag is turned OFF
              and the JSON type variation - PCID {PCID} is not matching!!
            </Typography>
          )}
          <LDCodeSamples codeString={LDCodeSnippets.usingJSONVariation} />
        </Box>
      </Box>
    </Box>
  )
}

export { FeatureFlaggingPage }
