// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Grid } from 'grommet'
import { Previous, StatusCritical } from 'grommet-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import omit from 'lodash/omit'
import dayjs from 'dayjs'

import {
  AvatarInfo,
  Button,
  Loader,
  Notification,
  ObjectList,
  Typography
} from '../../../../../components'
import { renameKeys } from '../../../../../utils/common-utils'
import { getApplicationDetails } from '../utils'
import { AppInstancesDataTable } from '../components'

const AppDetailsPage = () => {
  const { t } = useTranslation(['manage', 'common'])
  const navigate = useNavigate()
  const { appId } = useParams()
  const { oidcUser } = useReactOidc()

  const [appDetails, setAppDetails] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [appLoading, setAppLoading] = useState(true)

  useState(() => {
    setAppLoading(true)
    let isCurrent = true
    // get app details
    getApplicationDetails({
      applicationId: appId,
      token: oidcUser.access_token,
      setErrorMessage,
      t
    }).then((data) => {
      if (!isCurrent) return
      if (data) {
        setAppDetails(data)
        setAppLoading(false)
      } else {
        setAppDetails({})
        setAppLoading(false)
      }
    })

    return () => {
      isCurrent = false
    }
  }, [appId, oidcUser.access_token, t])

  const handleBackBtnClick = () => {
    navigate('/manage-ccs/applications')
  }

  const renderAppDetails = () => {
    const formattedData = {
      ...omit(appDetails, [
        'name',
        'company_name',
        'logo',
        'email_addresses',
        'rule',
        'created_at',
        'updated_at'
      ]),
      email_addresses: appDetails?.email_addresses?.join(', '),
      rule_algorithm: appDetails?.rule?.algorithm,
      rule_description: appDetails?.rule?.rule_description,
      created_at: appDetails?.created_at
        ? dayjs(appDetails?.created_at).format('MM/DD/YYYY HH:mm')
        : null,
      updated_at: appDetails?.updated_at
        ? dayjs(appDetails?.updated_at).format('MM/DD/YYYY HH:mm')
        : null
    }
    const keysMap = {}
    Object.keys(formattedData).forEach((key) => {
      keysMap[key] = t(`applications.${key}`)
    })
    return (
      <Box margin={{ vertical: 'medium' }}>
        <Box
          direction="row-responsive"
          justify="between"
          border={{ side: 'bottom', color: 'border-weak' }}
          margin={{ bottom: 'medium' }}
        >
          <Box margin={{ bottom: 'small' }}>
            <Typography level="3" type="heading" testId="details-header">
              {t('details')}
            </Typography>
          </Box>
        </Box>
        {appLoading ? (
          <Loader testId="app-details-loader" />
        ) : (
          <ObjectList
            data={renameKeys(keysMap, formattedData)}
            testId="app-details-list"
          />
        )}
      </Box>
    )
  }

  return (
    <Box gap="small">
      <Box
        direction="row"
        justify="start"
        pad="small"
        margin={{ bottom: 'medium' }}
      >
        <Button
          default
          icon={<Previous />}
          label={t('applications.title')}
          testId="applications-back-btn"
          onClick={handleBackBtnClick}
        />
      </Box>
      <Box margin={{ horizontal: 'xlarge' }}>
        {appLoading ? (
          <Loader testId="loader-spinner" />
        ) : (
          <Box direction="row" align="center" gap="small">
            <AvatarInfo
              avatarSize="large"
              avatarChar="one"
              primaryInfo={appDetails?.name || ''}
              secondaryInfo=""
              testId="app-logo"
              avatarOnly
              {...(appDetails?.logo && { avatarSrc: appDetails?.logo })}
            />
            <Box justify="start">
              <Typography
                level="1"
                type="heading"
                weight="normal"
                testId="app-name-title"
              >
                {appDetails?.name}
              </Typography>
              <Typography
                size="medium"
                type="text"
                weight="normal"
                testId="app-comapny-name-subtitle"
              >
                {appDetails?.company_name}
              </Typography>
            </Box>
          </Box>
        )}
        <Grid
          rows={['full']}
          columns={[['medium', '1/4'], '3/4']}
          areas={[['details', 'app-instances']]}
          align="start"
          fill
          margin={{ top: 'medium' }}
        >
          <Box gridArea="details" margin={{ right: 'large' }}>
            {renderAppDetails()}
          </Box>
          <Box
            gridArea="app-instances"
            direction="column"
            margin={{ vertical: 'medium', left: 'xlarge' }}
          >
            <Box margin={{ bottom: 'small' }} justify="between" direction="row">
              <Typography
                level="3"
                type="heading"
                testId="app-instances-table-header"
              >
                {t('applications.app_instances')}
              </Typography>
            </Box>
            <AppInstancesDataTable appId={appId} />
          </Box>
        </Grid>
      </Box>
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

export { AppDetailsPage }
