import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, Text } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'

import { FormOverallAlert, Button, Anchor } from '../../../../components'
import { PRODUCT_NUMBER_SUPPORT_URL } from '../../utils'

export const AddForm = ({ fields, addBtnLabel, onAdd, errorMessage }) => {
  const LDFlags = useFlags()
  const showCapExTooltip = LDFlags['glcp-capex-tooltip']
  const { t } = useTranslation(['device'])
  const formFieldEls =
    fields &&
    fields.map(({ label, name, valueComp, testId }, idx) => {
      return (
        <Box>
          <FormField
            key={idx} /* eslint-disable-line react/no-array-index-key */
            data-testid={`${testId}-form-field`}
            label={label}
            name={name}
            width="medium"
          >
            {valueComp}
          </FormField>
          {label === t('product_number') && showCapExTooltip && (
            <Text size="small">
              <Anchor
                label={t('product_number_help')}
                href={PRODUCT_NUMBER_SUPPORT_URL}
                testId="product-number-tooltip-link"
                target="_blank"
              />
            </Text>
          )}
        </Box>
      )
    })
  const showCapExFlow = LDFlags['glcp-capex-subscription']

  return (
    <>
      <Box
        direction={showCapExFlow ? 'row' : 'column'}
        gap={showCapExFlow ? 'medium' : 'none'}
        margin={{ top: 'medium', bottom: 'xxsmall' }}
      >
        {formFieldEls}
        <Box
          width={showCapExFlow ? 'xsmall' : 'small'}
          margin={{ top: 'xsmall' }}
        >
          <Button
            testId="enter-btn"
            label={addBtnLabel}
            secondary
            onClick={() => onAdd()}
            margin={{ top: 'medium' }}
          />
        </Box>
      </Box>
      {errorMessage.length > 0 && <FormOverallAlert message={errorMessage} />}
    </>
  )
}

AddForm.propTypes = {
  /**
   * Array of objects representing label and value component
   */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      valueComp: PropTypes.element.isRequired
    }).isRequired
  ).isRequired,

  /**
   * button label for add/assign button
   */
  addBtnLabel: PropTypes.string.isRequired,

  /**
   * Add handler
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Error Message
   */
  errorMessage: PropTypes.string.isRequired
}
