// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Anchor, Box, Text } from 'grommet'
import { StatusWarningSmall } from 'grommet-icons'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'

export const PopUpError = ({ onAnchorClick, heading }) => {
  const { t } = useTranslation(['device'])
  return (
    <Box
      width="medium"
      margin={{ horizontal: 'small', top: 'small' }}
      data-testid="popup-error"
    >
      <Text weight={500} size="large" data-testid="popup-error-heading">
        {heading}
      </Text>
      <Box direction="row" gap="xsmall" margin={{ vertical: 'small' }}>
        <Box margin={{ top: '3px' }}>
          <StatusWarningSmall color="status-warning" />
        </Box>
        <Box data-testid="popup-error-message">
          <Text weight={500} alignSelf="start">
            {t('device_map.popup_error_message')}
          </Text>
          <Text data-testid="popup-error-text" size="medium">
            <Trans i18nKey="device_map.popup_error_action" t={t}>
              <Anchor
                onClick={onAnchorClick}
                label={t('device_map.popup_retry_anchor')}
                data-testid="popup-error-anchor"
              />
              .
            </Trans>
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

PopUpError.propTypes = {
  onAnchorClick: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired
}
