// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CircleInformation, StatusCritical } from 'grommet-icons'
import { Box, Tip } from 'grommet'
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Wizard, Notification, Typography } from '../../../../../../components'
import { post, getErrorMessage, get } from '../../../../../../utils/api-utils'
import {
  WKSPC,
  WKSPC_PLURAL_CAPITALIZED,
  getWorkspaceString
} from '../../../../../../utils/common-utils'
import { getTargetEnum } from '../../utils'

import NotificationTarget, {
  validateCreateFields
} from './steps/NotificationTarget'
import Review, { validateFormFields } from './steps/Review'
import BannerNotification, {
  validateBannerMessage
} from './steps/BannerNotification'
import EmailNotification, {
  validateEmailMessageFlorence
} from './steps/EmailNotification'
import PortalNotification, {
  validatePortalMessage
} from './steps/PortalNotification'
import NotificationType, {
  validateNotificationTypeFields
} from './steps/NotificationType'
import EmailType, { validateEmailTypeFields } from './steps/EmailType'
import AccountsType, { validateAccountTypeFields } from './steps/AccountsType'
import AccountTypeTarget, {
  validateAccountTypeTargetFields
} from './steps/AccountTypeTarget'
import Category, { validateCategoryFields } from './steps/Category'
import { ConfirmationModal } from './ConfirmationModal'
import { SubmitForReviewModal } from './SubmitForReviewModal'
import NotificationContent, {
  validateNotificationContent
} from './steps/NotificationContent'
import ScheduleNotification, {
  validateScheduleNotification
} from './steps/scheduleNotification'

const CreateNotificationWizard = () => {
  const { t } = useTranslation(['manage', 'common'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const [bannerCheck, setBannerCheck] = useState(false)
  const [emailCheck, setEmailCheck] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [regionList, setRegionList] = useState([])
  const [roleList, setRoleList] = useState([])
  const [instanceList, setInstanceList] = useState([])
  const [selectedInstance, setSelectedInstance] = useState([])
  const [selectedRole, setSelectedRole] = useState([])
  const [selectedType, setSelectedType] = useState(t('notification.services'))
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [wizardFormValues, setWizardFormValues] = useState({})
  const [categoryResponse, setCategoryResponse] = useState({})
  const [appSlug, setAppSlug] = useState('')
  const [targetList, setTargetList] = useState([])

  const [isSuperUser, setIsSuperUser] = useState(false)
  const [showApproversToUser, setShowApproversToUser] = useState(false)
  let stepCount = 0

  const formExpiration = (formValues) => {
    dayjs.extend(utc)

    const { expirationDate, expirationTime } = formValues
    const expDate = dayjs(expirationDate).format('MM/DD/YYYY')
    const withExpTime = dayjs(`${expDate} ${expirationTime}`).unix()
    const inMilli = withExpTime

    return inMilli
  }

  const formScheduleDate = (formValues) => {
    dayjs.extend(utc)

    const { start_date, start_time } = formValues
    const scheduleDate = dayjs(start_date).format('MM/DD/YYYY')
    const withExpTime = dayjs(`${scheduleDate} ${start_time}`).unix()
    const inMilli = withExpTime

    return inMilli
  }

  const getStatusEnum = (severity) => {
    let element
    switch (severity) {
      case 'INFORMATION':
        element = 0
        break
      case 'CRITICAL':
        element = 1
        break
      case 'WARNING':
        element = 2
        break
      case 'OK':
        element = 3
        break
      default:
        element = 0
    }
    return element
  }

  const getAccountTarget = (accountArray) => {
    const accountTarget = []
    accountArray?.forEach((account) => {
      if (LDFlags['glcp-notifications-account-role-target']) {
        const accountRoles = [...new Set(account?.accountRoles)]
        let accountObj = {
          id: account?.accountName?.value,
          roles: accountRoles
        }
        if (LDFlags['glcp-notifications-feature-engineering-kobe']) {
          accountObj = {
            accountName: account?.accountName?.label,
            accountRoleNames: account?.accountRoleNames,
            ...accountObj
          }
        }
        accountTarget.push(accountObj)
      } else {
        accountTarget.push({ id: account?.value })
      }
    })
    return accountTarget
  }

  const handleFinish = (formValues, reviewers, createType, isDirectPublish) => {
    let notificationType = []

    if (formValues?.banner && formValues?.email) {
      notificationType = ['PORTAL', 'BANNER', 'EMAIL']
    } else if (formValues?.banner && !formValues?.email) {
      notificationType = ['PORTAL', 'BANNER']
    } else if (!formValues?.banner && formValues?.email) {
      notificationType = ['PORTAL', 'EMAIL']
    } else {
      notificationType = ['PORTAL']
    }

    const sendTtl = formExpiration(formValues)

    const sendRegions =
      formValues?.region[0] === t('notification.all_regions')
        ? []
        : formValues?.region
    const sendInstances =
      formValues?.instance[0] === t('notification.all_instances')
        ? []
        : formValues?.instance
    const sendRoles =
      formValues?.role[0] === t('notification.all_roles')
        ? []
        : formValues?.role

    let requestBody = {}

    requestBody = {
      type: createType
    }
    if (LDFlags['glcp-notifications-approval-feature'] && !isDirectPublish) {
      requestBody = {
        approvers: reviewers,
        ...requestBody
      }
    }

    if (!LDFlags['glcp-notifications-feature-glasgow-admin']) {
      requestBody = {
        severity: getStatusEnum(formValues?.status?.toUpperCase()),
        target: getTargetEnum(
          LDFlags,
          formValues?.target,
          sendRegions,
          sendInstances,
          sendRoles
        ),
        channel: notificationType,
        ...requestBody
      }
    } else if (selectedType === t('notification.services')) {
      requestBody = {
        severity: getStatusEnum(formValues?.status?.toUpperCase()),
        target: getTargetEnum(
          LDFlags,
          formValues?.target,
          sendRegions,
          sendInstances,
          sendRoles
        ),
        channel: notificationType,
        ...requestBody
      }
      if (LDFlags['glcp-notifications-feature-engineering-kobe']) {
        requestBody = {
          appInstanceNames: formValues?.instanceIdNameMapping,
          appRoleNames: formValues?.roleSlugNameAppMap,
          ...requestBody
        }
      }
    } else if (selectedType === t('notification.email_address')) {
      const userArray = formValues?.emailAddresses?.replaceAll(' ', '')

      requestBody = {
        severity: getStatusEnum(formValues?.status?.toUpperCase()),
        target: {
          type: 'USERS',
          ids: userArray?.split(',')?.filter((item) => item !== '')
        },
        channel: notificationType,
        ...requestBody
      }
    } else {
      requestBody = {
        severity: getStatusEnum(formValues?.status?.toUpperCase()),
        target: {
          type: 'ACCOUNTS',
          accountTarget: getAccountTarget(formValues?.selectedAccountName)
        },
        channel: notificationType,
        ...requestBody
      }
    }

    if (LDFlags['glcp-notifications-feature-category-istanbul']) {
      let category
      if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        category = {
          topics: formValues?.topic,
          service: formValues?.categoryService
        }
      } else {
        category = {
          topics: formValues?.topic,
          service: formValues?.appSlug,
          application_name: formValues?.appName
        }
      }

      if (!isEmpty(formValues?.subTopic)) {
        category = {
          sub_topics: formValues?.subTopic,
          ...category
        }
      }

      if (
        !isEmpty(formValues?.subService) &&
        formValues?.subService !== t('notification.default')
      ) {
        category = { sub_service: formValues?.subService, ...category }
      }
      requestBody = { category, ...requestBody }
    }

    if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
      if (formValues?.start_date_checkbox) {
        const scheduleTime = formScheduleDate(formValues)
        requestBody = { startTime: scheduleTime, ...requestBody }
      }
    }

    if (formValues?.banner && formValues?.email) {
      requestBody = {
        ttl: sendTtl,
        summary: formValues?.summary,
        description: formValues?.description,
        url: formValues?.url,
        url_text: formValues?.url_text,
        ...requestBody
      }
      if (
        formValues?.emailContentDetails?.toLowerCase() !==
        formValues?.description?.toLowerCase()
      ) {
        requestBody = {
          emailContentDetails: formValues?.emailContentDetails,
          ...requestBody
        }
      }
      if (
        formValues?.emailContentTitle?.toLowerCase() !==
        formValues?.summary?.toLowerCase()
      ) {
        requestBody = {
          emailContentTitle: formValues?.emailContentTitle,
          ...requestBody
        }
      }
    } else if (formValues?.banner && !formValues?.email) {
      requestBody = {
        ttl: sendTtl,
        summary: formValues?.summary,
        description: formValues?.description,
        url: formValues?.url,
        url_text: formValues?.url_text,
        ...requestBody
      }
    } else if (!formValues?.banner && formValues?.email) {
      requestBody = {
        summary: formValues?.summary,
        description: formValues?.description,
        url: formValues?.url,
        url_text: formValues?.url_text,
        emailContentTitle: formValues?.emailContentTitle,
        emailContentDetails: formValues?.emailContentDetails,
        ...requestBody
      }
    } else {
      requestBody = {
        summary: formValues?.summary,
        description: formValues?.description,
        url: formValues?.url,
        url_text: formValues?.url_text,
        ...requestBody
      }
    }
    post(
      '/notifications-svc/ui/v1alpha1/notifications',
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        navigate('/manage-ccs/notifications')
      },
      (error) => {
        let detailResponse = error?.response?.request?.response
        detailResponse = JSON.parse(detailResponse)
        if (error?.response?.request?.response && detailResponse?.detail) {
          const message = getErrorMessage(error, t)
          const errorMsg = (
            <Box>
              <Typography size="medium" testId="error-message" type="text">
                {message}
              </Typography>
              <Typography size="medium" testId="error-message" type="text">
                {detailResponse?.detail}
              </Typography>
            </Box>
          )
          setErrorMessage(errorMsg)
        } else {
          setErrorMessage(getErrorMessage(error, t))
        }
      }
    )
  }

  const fetchCategories = useCallback(
    () => {
      get(
        `/notifications-svc/ui/v1alpha1/categories`,
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          setCategoryResponse(response.data)
        },
        (error) => {
          console.log(error)
        }
      )
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oidcUser.access_token, t]
  )

  const fetchTargets = useCallback(() => {
    get(
      `/notifications-svc/ui/v1alpha1/get-all-apps`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        const allApps = response?.data
        const appList = [
          {
            label: 'HPE GreenLake platform',
            value: 'PLATFORM',
            slug: 'PLATFORM'
          }
        ]
        Object.entries(allApps).forEach((keyVal) => {
          const app = {
            label: keyVal[1]?.name,
            value: keyVal[0],
            slug: keyVal[1]?.slug
          }
          appList.push(app)
        })
        setTargetList(appList)
      },
      () => {}
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  useEffect(() => {
    if (LDFlags['glcp-notifications-feature-category-istanbul']) {
      fetchCategories()
      if (LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        fetchTargets()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategories])

  let eligibleStep
  if (!LDFlags['glcp-notifications-feature-glasgow-admin']) {
    if (bannerCheck && emailCheck) {
      eligibleStep = [
        {
          childComponents: (
            <NotificationTarget
              setBannerCheck={setBannerCheck}
              setEmailCheck={setEmailCheck}
              regionList={regionList}
              roleList={roleList}
              setRegionList={setRegionList}
              setRoleList={setRoleList}
              setInstanceList={setInstanceList}
              instanceList={instanceList}
              setSelectedRole={setSelectedRole}
              setSelectedInstance={setSelectedInstance}
              appSlug={appSlug}
              setAppSlug={setAppSlug}
              targetList={targetList}
            />
          ),
          description: t('notification.step1_description'),
          title: t('notification.step1_title'),
          validateForm: (formValues) => validateCreateFields(formValues, t)
        },
        {
          childComponents: <PortalNotification />,
          description: t('notification.step2_description_portal'),
          title: t('notification.step2_title_portal'),
          validateForm: (formValues) => validatePortalMessage(formValues, t)
        },
        {
          childComponents: <BannerNotification />,
          description: t('notification.step2_description_banner'),
          title: t('notification.step2_title_banner'),
          validateForm: (formValues) => validateBannerMessage(formValues, t)
        },
        {
          childComponents: <EmailNotification bannerCheck={bannerCheck} />,
          description: t('notification.step2_description_email'),
          title: t('notification.step2_title_email'),
          validateForm: (formValues) =>
            validateEmailMessageFlorence(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
      stepCount += 5
    } else if (bannerCheck && !emailCheck) {
      eligibleStep = [
        {
          childComponents: (
            <NotificationTarget
              setBannerCheck={setBannerCheck}
              setEmailCheck={setEmailCheck}
              regionList={regionList}
              roleList={roleList}
              setRegionList={setRegionList}
              setRoleList={setRoleList}
              setInstanceList={setInstanceList}
              instanceList={instanceList}
              setSelectedRole={setSelectedRole}
              setSelectedInstance={setSelectedInstance}
              appSlug={appSlug}
              setAppSlug={setAppSlug}
              targetList={targetList}
            />
          ),
          description: t('notification.step1_description'),
          title: t('notification.step1_title'),
          validateForm: (formValues) => validateCreateFields(formValues, t)
        },
        {
          childComponents: <PortalNotification />,
          description: t('notification.step2_description_portal'),
          title: t('notification.step2_title_portal'),
          validateForm: (formValues) => validatePortalMessage(formValues, t)
        },
        {
          childComponents: <BannerNotification />,
          description: t('notification.step2_description_banner'),
          title: t('notification.step2_title_banner'),
          validateForm: (formValues) => validateBannerMessage(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
      stepCount += 4
    } else if (!bannerCheck && emailCheck) {
      eligibleStep = [
        {
          childComponents: (
            <NotificationTarget
              setBannerCheck={setBannerCheck}
              setEmailCheck={setEmailCheck}
              regionList={regionList}
              roleList={roleList}
              setRegionList={setRegionList}
              setRoleList={setRoleList}
              setInstanceList={setInstanceList}
              instanceList={instanceList}
              setSelectedRole={setSelectedRole}
              setSelectedInstance={setSelectedInstance}
              appSlug={appSlug}
              setAppSlug={setAppSlug}
              targetList={targetList}
            />
          ),
          description: t('notification.step1_description'),
          title: t('notification.step1_title'),
          validateForm: (formValues) => validateCreateFields(formValues, t)
        },
        {
          childComponents: <PortalNotification />,
          description: t('notification.step2_description_portal'),
          title: t('notification.step2_title_portal'),
          validateForm: (formValues) => validatePortalMessage(formValues, t)
        },
        {
          childComponents: <EmailNotification bannerCheck={bannerCheck} />,
          description: t('notification.step2_description_email'),
          title: t('notification.step2_title_email'),
          validateForm: (formValues) =>
            validateEmailMessageFlorence(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
      stepCount += 4
    } else {
      eligibleStep = [
        {
          childComponents: (
            <NotificationTarget
              setBannerCheck={setBannerCheck}
              setEmailCheck={setEmailCheck}
              regionList={regionList}
              roleList={roleList}
              setRegionList={setRegionList}
              setRoleList={setRoleList}
              setInstanceList={setInstanceList}
              instanceList={instanceList}
              setSelectedRole={setSelectedRole}
              setSelectedInstance={setSelectedInstance}
              appSlug={appSlug}
              setAppSlug={setAppSlug}
              targetList={targetList}
            />
          ),
          description: t('notification.step1_description'),
          title: t('notification.step1_title'),
          validateForm: (formValues) => validateCreateFields(formValues, t)
        },

        {
          childComponents: <PortalNotification />,
          description: t('notification.step2_description_portal'),
          title: t('notification.step2_title_portal'),
          validateForm: (formValues) => validatePortalMessage(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
      stepCount += 3
    }
  } else {
    let notificationType
    let stepDescription = ''
    let stepTitle = ''
    let formValidation

    if (selectedType === t('notification.services')) {
      stepDescription = t('notification.service_step1_description')
      stepTitle = t('notification.service_step1_title')
      formValidation = validateCreateFields
      notificationType = (
        <NotificationTarget
          setBannerCheck={setBannerCheck}
          setEmailCheck={setEmailCheck}
          regionList={regionList}
          roleList={roleList}
          setRegionList={setRegionList}
          setRoleList={setRoleList}
          setInstanceList={setInstanceList}
          instanceList={instanceList}
          setSelectedRole={setSelectedRole}
          setSelectedInstance={setSelectedInstance}
          appSlug={appSlug}
          setAppSlug={setAppSlug}
        />
      )
    } else if (selectedType === t('notification.email_address')) {
      stepDescription = t('notification.email_address_step1_description')
      stepTitle = t('notification.email_address_step1_title')
      formValidation = validateEmailTypeFields
      notificationType = (
        <EmailType
          setBannerCheck={setBannerCheck}
          setEmailCheck={setEmailCheck}
          regionList={regionList}
          roleList={roleList}
          setRegionList={setRegionList}
          setRoleList={setRoleList}
          setInstanceList={setInstanceList}
          instanceList={instanceList}
          setSelectedRole={setSelectedRole}
          setSelectedInstance={setSelectedInstance}
        />
      )
    } else {
      stepDescription = t('notification.accounts_step1_description')
      stepTitle = t('notification.accounts_step1_title', {
        Accounts: getWorkspaceString(
          showWorkspaceString,
          t,
          WKSPC_PLURAL_CAPITALIZED
        )
      })
      formValidation = validateAccountTypeFields
      notificationType = (
        <AccountsType
          setBannerCheck={setBannerCheck}
          setEmailCheck={setEmailCheck}
          regionList={regionList}
          roleList={roleList}
          setRegionList={setRegionList}
          setRoleList={setRoleList}
          setInstanceList={setInstanceList}
          instanceList={instanceList}
          setSelectedRole={setSelectedRole}
          setSelectedInstance={setSelectedInstance}
        />
      )
    }

    let additionalStep = []
    if (
      selectedType ===
      getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL_CAPITALIZED)
    ) {
      additionalStep = [
        {
          childComponents: notificationType,
          description: stepDescription,
          title: stepTitle,
          validateForm: (formValues) => formValidation(formValues, t)
        },
        {
          childComponents: (
            <AccountTypeTarget
              setBannerCheck={setBannerCheck}
              setEmailCheck={setEmailCheck}
              regionList={regionList}
              roleList={roleList}
              setRegionList={setRegionList}
              setRoleList={setRoleList}
              setInstanceList={setInstanceList}
              instanceList={instanceList}
              setSelectedRole={setSelectedRole}
              setSelectedInstance={setSelectedInstance}
            />
          ),
          description: t('notification.step3_subtitle_account_target'),
          title: t('notification.step3_title_account_target'),
          validateForm: (formValues) =>
            validateAccountTypeTargetFields(formValues, t)
        }
      ]
      stepCount += 2
    } else {
      additionalStep = [
        {
          childComponents: notificationType,
          description: stepDescription,
          title: stepTitle,
          validateForm: (formValues) => formValidation(formValues, t)
        }
      ]
      stepCount += 1
    }
    const initialStep = [
      {
        childComponents: (
          <NotificationType
            setSelectedType={setSelectedType}
            selectedType={selectedType}
          />
        ),
        description: t('notification.step1_description'),
        title: t('notification.step1_title'),
        validateForm: (formValues) =>
          validateNotificationTypeFields(formValues, t)
      }
    ]

    if (bannerCheck && emailCheck) {
      let categoryStep = []
      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        categoryStep = [
          {
            childComponents: (
              <Category
                selectedType={selectedType}
                categoryResponse={categoryResponse}
                appSlug={appSlug}
                targetList={targetList}
              />
            ),
            description: t('notification.step_category_description'),
            title: (
              <Box direction="row" justify="center">
                {t('notification.step_category_title')}
                <Box
                  justify="center"
                  margin={{ top: 'xxsmall', left: 'xsmall' }}
                >
                  <Tip content={t('notification.category_tip')}>
                    <CircleInformation size="medium" />
                  </Tip>
                </Box>
              </Box>
            ),
            validateForm: (formValues) => validateCategoryFields(formValues, t)
          }
        ]
      }
      let endSteps = []
      endSteps = [
        {
          childComponents: <PortalNotification />,
          description: t('notification.step2_description_portal'),
          title: t('notification.step2_title_portal'),
          validateForm: (formValues) => validatePortalMessage(formValues, t)
        },
        {
          childComponents: <BannerNotification />,
          description: t('notification.step2_description_banner'),
          title: t('notification.step2_title_banner'),
          validateForm: (formValues) => validateBannerMessage(formValues, t)
        },
        {
          childComponents: <EmailNotification bannerCheck={bannerCheck} />,
          description: t('notification.step2_description_email'),
          title: t('notification.step2_title_email'),
          validateForm: (formValues) =>
            validateEmailMessageFlorence(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]

      if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
        endSteps = [
          {
            childComponents: <NotificationContent />,
            description: t('notification.notification_content_description'),
            title: t('notification.notification_content'),
            validateForm: (formValues) =>
              validateNotificationContent(formValues, t)
          },
          {
            childComponents: <ScheduleNotification />,
            description: t('notification.schedule_description'),
            title: t('notification.schedule'),
            validateForm: (formValues) =>
              validateScheduleNotification(formValues, t)
          },
          {
            childComponents: (
              <Review
                bannerCheck={bannerCheck}
                emailCheck={emailCheck}
                selectedRole={selectedRole}
                selectedInstance={selectedInstance}
                selectedType={selectedType}
              />
            ),
            description: t('notification.step3_description'),
            title: t('notification.step3_title'),
            validateForm: (formValues) => validateFormFields(formValues, t)
          }
        ]
      }

      eligibleStep = initialStep
        .concat(additionalStep)
        .concat(categoryStep)
        .concat(endSteps)
      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
          stepCount += 5
        } else {
          stepCount += 6
        }
      } else {
        stepCount += 5
      }
    } else if (bannerCheck && !emailCheck) {
      let categoryStep = []
      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        categoryStep = [
          {
            childComponents: (
              <Category
                selectedType={selectedType}
                categoryResponse={categoryResponse}
                appSlug={appSlug}
                targetList={targetList}
              />
            ),
            description: t('notification.step_category_description'),
            title: (
              <Box direction="row" justify="center">
                {t('notification.step_category_title')}
                <Box
                  justify="center"
                  margin={{ top: 'xxsmall', left: 'xsmall' }}
                >
                  <Tip content={t('notification.category_tip')}>
                    <CircleInformation size="medium" />
                  </Tip>
                </Box>
              </Box>
            ),
            validateForm: (formValues) => validateCategoryFields(formValues, t)
          }
        ]
      }
      let endSteps = []
      endSteps = [
        {
          childComponents: <PortalNotification />,
          description: t('notification.step2_description_portal'),
          title: t('notification.step2_title_portal'),
          validateForm: (formValues) => validatePortalMessage(formValues, t)
        },
        {
          childComponents: <BannerNotification />,
          description: t('notification.step2_description_banner'),
          title: t('notification.step2_title_banner'),
          validateForm: (formValues) => validateBannerMessage(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
      if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
        endSteps = [
          {
            childComponents: <NotificationContent />,
            description: t('notification.notification_content_description'),
            title: t('notification.notification_content'),
            validateForm: (formValues) =>
              validateNotificationContent(formValues, t)
          },
          {
            childComponents: <ScheduleNotification />,
            description: t('notification.schedule_description'),
            title: t('notification.schedule'),
            validateForm: (formValues) =>
              validateScheduleNotification(formValues, t)
          },
          {
            childComponents: (
              <Review
                bannerCheck={bannerCheck}
                emailCheck={emailCheck}
                selectedRole={selectedRole}
                selectedInstance={selectedInstance}
                selectedType={selectedType}
              />
            ),
            description: t('notification.step3_description'),
            title: t('notification.step3_title'),
            validateForm: (formValues) => validateFormFields(formValues, t)
          }
        ]
      }
      eligibleStep = initialStep
        .concat(additionalStep)
        .concat(categoryStep)
        .concat(endSteps)

      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        stepCount += 5
      } else {
        stepCount += 4
      }
    } else if (!bannerCheck && emailCheck) {
      let categoryStep = []
      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        categoryStep = [
          {
            childComponents: (
              <Category
                selectedType={selectedType}
                categoryResponse={categoryResponse}
                appSlug={appSlug}
                targetList={targetList}
              />
            ),
            description: t('notification.step_category_description'),
            title: (
              <Box direction="row" justify="center">
                {t('notification.step_category_title')}
                <Box
                  justify="center"
                  margin={{ top: 'xxsmall', left: 'xsmall' }}
                >
                  <Tip content={t('notification.category_tip')}>
                    <CircleInformation size="medium" />
                  </Tip>
                </Box>
              </Box>
            ),
            validateForm: (formValues) => validateCategoryFields(formValues, t)
          }
        ]
      }
      let endSteps = []
      endSteps = [
        {
          childComponents: <PortalNotification />,
          description: t('notification.step2_description_portal'),
          title: t('notification.step2_title_portal'),
          validateForm: (formValues) => validatePortalMessage(formValues, t)
        },
        {
          childComponents: <EmailNotification bannerCheck={bannerCheck} />,
          description: t('notification.step2_description_email'),
          title: t('notification.step2_title_email'),
          validateForm: (formValues) =>
            validateEmailMessageFlorence(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
      if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
        endSteps = [
          {
            childComponents: <NotificationContent />,
            description: t('notification.notification_content_description'),
            title: t('notification.notification_content'),
            validateForm: (formValues) =>
              validateNotificationContent(formValues, t)
          },
          {
            childComponents: <ScheduleNotification />,
            description: t('notification.schedule_description'),
            title: t('notification.schedule'),
            validateForm: (formValues) =>
              validateScheduleNotification(formValues, t)
          },
          {
            childComponents: (
              <Review
                bannerCheck={bannerCheck}
                emailCheck={emailCheck}
                selectedRole={selectedRole}
                selectedInstance={selectedInstance}
                selectedType={selectedType}
              />
            ),
            description: t('notification.step3_description'),
            title: t('notification.step3_title'),
            validateForm: (formValues) => validateFormFields(formValues, t)
          }
        ]
      }
      eligibleStep = initialStep
        .concat(additionalStep)
        .concat(categoryStep)
        .concat(endSteps)
      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        stepCount += 5
      } else {
        stepCount += 4
      }
    } else {
      let categoryStep = []
      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        categoryStep = [
          {
            childComponents: (
              <Category
                selectedType={selectedType}
                categoryResponse={categoryResponse}
                appSlug={appSlug}
                targetList={targetList}
              />
            ),
            description: t('notification.step_category_description'),
            title: (
              <Box direction="row" justify="center">
                {t('notification.step_category_title')}
                <Box
                  justify="center"
                  margin={{ top: 'xxsmall', left: 'xsmall' }}
                >
                  <Tip content={t('notification.category_tip')}>
                    <CircleInformation size="medium" />
                  </Tip>
                </Box>
              </Box>
            ),
            validateForm: (formValues) => validateCategoryFields(formValues, t)
          }
        ]
      }
      let endSteps = []
      endSteps = [
        {
          childComponents: <PortalNotification />,
          description: t('notification.step2_description_portal'),
          title: t('notification.step2_title_portal'),
          validateForm: (formValues) => validatePortalMessage(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
      if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
        endSteps = [
          {
            childComponents: <NotificationContent />,
            description: t('notification.notification_content_description'),
            title: t('notification.notification_content'),
            validateForm: (formValues) =>
              validateNotificationContent(formValues, t)
          },
          {
            childComponents: <ScheduleNotification />,
            description: t('notification.schedule_description'),
            title: t('notification.schedule'),
            validateForm: (formValues) =>
              validateScheduleNotification(formValues, t)
          },
          {
            childComponents: (
              <Review
                bannerCheck={bannerCheck}
                emailCheck={emailCheck}
                selectedRole={selectedRole}
                selectedInstance={selectedInstance}
                selectedType={selectedType}
              />
            ),
            description: t('notification.step3_description'),
            title: t('notification.step3_title'),
            validateForm: (formValues) => validateFormFields(formValues, t)
          }
        ]
      }
      eligibleStep = initialStep
        .concat(additionalStep)
        .concat(categoryStep)
        .concat(endSteps)

      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
          stepCount += 5
        } else {
          stepCount += 4
        }
      } else {
        stepCount += 3
      }
    }
  }

  useEffect(() => {
    // API call to get list of superuser
    get(
      '/notifications-svc/ui/v1alpha1/super-users',
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.length > 0) {
          const found = response?.data?.some(
            (item) => item.id === oidcUser?.profile?.email
          )
          setIsSuperUser(found)
        }
      },
      () => {
        console.log('error while getting super user')
      }
    )
  }, [oidcUser.access_token]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Wizard
        testId="create-notication"
        title={t('notification.add_notification')}
        actionOnExit={() => {
          navigate('/manage-ccs/notifications')
        }}
        actionOnSubmit={(formValues) => {
          setShowApproversToUser(true)
          setWizardFormValues(formValues)
          setOpenConfirmation(true)
        }}
        buttonLabels={{
          finish: LDFlags['glcp-notifications-approval-feature']
            ? t('notification.approval.submit_for_approval')
            : t('common:finish'),

          ...(LDFlags['glcp-notifications-approval-feature'] &&
            isSuperUser && {
              otherActions: [
                {
                  showInStep: stepCount,
                  label: t('notification.approval.publish_notification'),
                  handleOnClick: (formValues) => {
                    setShowApproversToUser(false)
                    setWizardFormValues(formValues)
                    setOpenConfirmation(true)
                  },
                  testId: 'publish-notification-btn',
                  primary: 'primary'
                }
              ]
            })
        }}
        formDefaultValues={{
          target: '',
          status: '',
          banner: false,
          expirationDate: '',
          expirationTime: '',
          description: '',
          summary: '',
          url: '',
          url_text: '',
          instance: [t('notification.all_instances')],
          role: [t('notification.all_roles')],
          region: [t('notification.all_regions')],
          emailContentTitle: '',
          emailContentDetails: '',
          email: false,
          emailAddresses: '',
          selectedInstanceName: '',
          instanceIdNameMapping: {},
          roleSlugNameAppMap: {},
          selectedRoleName: '',
          accounts: [],
          selectedAccountName: [],
          workspaceMessage: t('notification.account_validation', {
            account: getWorkspaceString(showWorkspaceString, t, WKSPC),
            an: showWorkspaceString
              ? t('common:article.a')
              : t('common:article.an')
          }),
          categoryService: '',
          subService: '',
          subTopic: '',
          topic: '',
          subServiceOptions: [],
          topicOptions: [],
          isEdit: false,
          isUpdateFlow: false,
          target_name: '',
          appSlug: '',
          appName: '',
          isDynamic: LDFlags['glcp-notifications-feature-dynamic-app-istanbul'],
          targetType: LDFlags['glcp-notifications-feature-dynamic-app-istanbul']
            ? 'SERVICES'
            : 'CENTRALS',
          url_checkbox: false,
          start_date_checkbox: false,
          start_date: '',
          start_time: ''
        }}
        steps={eligibleStep}
      />
      {openConfirmation &&
        (LDFlags['glcp-notifications-approval-feature'] &&
        showApproversToUser ? (
          <SubmitForReviewModal
            formValues={wizardFormValues}
            handleFinish={handleFinish}
            onSetOpen={setOpenConfirmation}
            isEdit={false}
          />
        ) : (
          <ConfirmationModal
            onSetOpen={setOpenConfirmation}
            handleFinish={handleFinish}
            formValues={wizardFormValues}
            isEdit={false}
          />
        ))}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

export default CreateNotificationWizard
