// Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const TagsDetails = lazy(() => import('./components/TagsDetails'))

const TagsPage = lazy(() => import('./TagsPage'))

const TagsRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route path="/tag-details/:id" element={<TagsDetails />} />
        <Route path="/" element={<TagsPage />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default TagsRouter
