// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import { Trash } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { isEmpty } from 'lodash'

import RemovePermissionModal from '../components/RemovePermissionModal'
import { get, put } from '../../../../../utils/api-utils'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import { getGLCPAppDetails } from '../../../utils'
import {
  Button,
  DataTable,
  Loader,
  Notification,
  Typography
} from '../../../../../components'
import AddPermissionModal from '../../common-components/AddPermissionModal'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import Markdown from '../../common-components/Markdown'
import { updateV2RBACPolicies } from '../../../../../utils/rbac-api-utils'
import { useVisibilityContext } from '../../../../../context/visibility-context'
import { useCCSContext } from '../../../../../context/ccs-context'

const PermissionsTab = ({
  showPermissionDialog,
  setShowPermissionDialog,
  setStatusNotification = () => {}
}) => {
  /**
   * We need to decode then encode again when we make the API call or it will fail for some reason.
   */
  let { grn } = useParams()
  grn = decodeURIComponent(grn)
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authz, iam', 'common'])
  const { csrfToken } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const [loading, setLoading] = useState(false)
  const [initialRender, setInitialRender] = useState(true)
  const [applicationId, setApplicationId] = useState()
  const [applicationName, setApplicationName] = useState()
  const [isLoading, setIsLoading] = useState(true)

  // Permission information
  const [role, setRole] = useState({})
  const [custom, setCustom] = useState(false)
  const [linked, setLinked] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [permissionToDelete, setPermissionToDelete] = useState('')
  const [updatedPermissionsList, setUpdatedPermissionsList] = useState([])
  const [permissionDetails, setPermissionDetails] = useState([])
  // Notifications
  const [successNotification, setSuccessNotification] = useState(null)
  const [apiError, setApiError] = useState(null)

  // Modals
  const [showRemovePermissionModal, setShowRemovePermissionModel] =
    useState(false)

  const mandatoryPermissionsList = [
    'ccs.geo.region.view',
    'ccs.geo.language.view',
    'ccs.app-catalog.application.view',
    'ccs.app-catalog.app-instance.view',
    'ccs.geo.country.view',
    'ccs.geo.time-zone.view'
  ]

  const retrieveRoleData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await get(
        `/internal-platform-tenant-ui/v2/roles?grn=${encodeURIComponent(grn)}`
      )
      if (response?.data && response?.data?.items?.length) {
        const roleData = response?.data?.items[0]
        setRole(roleData)

        if (roleData?.kind === 'CUSTOM') {
          setCustom(true)
        }

        if (roleData?.kind === 'LINKED') {
          setLinked(true)
        }
        const updatedPermissions = roleData?.permissions
          ?.sort((p1, p2) => {
            return p1?.name?.localeCompare(p2?.name)
          })
          ?.map((permission) => {
            return { permission_name: permission?.name }
          })

        setPermissionDetails(roleData?.permissions)
        setPermissions(updatedPermissions)
        setLoading(false)
        setInitialRender(false)
      }
    } catch (error) {
      setApiError(error)
    } finally {
      setIsLoading(false)
      setLoading(false)
    }
  }, [grn])

  useEffect(() => {
    retrieveRoleData()
  }, [retrieveRoleData])

  const getApplicationID = () => {
    const resourceProviderName = permissions[0]?.permission_name.split('.')[0]
    get('/authorization/v2alpha1/resource-providers', {
      name: resourceProviderName
    }).then(
      (response) => {
        if (response?.data) {
          const appId = response?.data?.items?.[0]?.applicationId
          if (appId) {
            setApplicationId(appId)
            const appDetails = getGLCPAppDetails()
            if (appId === appDetails.glcpApplicationId) {
              setApplicationName(appDetails.glcpApplicationName)
              setIsLoading(false)
            } else {
              get(
                '/ui-doorway/ui/v1/applications/provisions',
                {
                  application_id: appId
                },
                oidcUser.access_token
              ).then(
                (res) => {
                  if (res.status === 200) {
                    setApplicationName(res?.data?.provisions[0]?.name)
                  }
                  setIsLoading(false)
                },
                (error) => {
                  setApiError(error)
                  setIsLoading(false)
                }
              )
            }
          } else {
            setIsLoading(false)
          }
        }
      },
      (error) => {
        setIsLoading(false)
        setApiError(error)
      }
    )
  }

  useEffect(() => {
    if (custom && !isEmpty(permissions)) {
      getApplicationID()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, oidcUser.access_token])

  const onSuccessRemovePermission = () => {
    retrieveRoleData()
    setSuccessNotification(
      displayNotification(
        <Markdown>
          {t('iam:roles_permission.remove_permission_confirmation_msg', {
            permissionToDelete
          })}
        </Markdown>,
        'info',
        setSuccessNotification,
        t('iam:roles_permission.remove_permission_confirmation_title')
      )
    )
  }

  const columns = [
    {
      property: 'name',
      header: t('iam:roles_permission.permission_header'),
      primary: true
    },
    {
      property: 'description',
      header: t('authz:description'),
      primary: true
    },
    {
      property: 'providerName',
      header: t('iam:roles_details.resource_provider'),
      primary: true
    },
    {
      property: 'fixedScope',
      type: 'string',
      sortable: true,
      header: t('iam:roles_permission.column_scoping'),
      render: (datum) =>
        datum?.fixedScope
          ? t(
              'iam:roles_permission.column_scoping_value_applies_to_entire_workspace',
              {
                workspace: t('common:business_object.wkspc')
              }
            )
          : t(
              'iam:roles_permission.column_scoping_value_supports_granular_scoping'
            )
    },
    {
      property: '',
      header: '',
      render: (datum) => {
        return (
          custom && (
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/authorization',
                permission: 'ccs.authorization.edit'
              }}
            >
              {!mandatoryPermissionsList?.includes(datum?.name) ? (
                <Button
                  alignSelf="end"
                  icon={<Trash />}
                  pad="none"
                  onClick={() => {
                    setPermissionToDelete(datum?.name)
                    setShowRemovePermissionModel(true)
                  }}
                  testId="permissions-table-delete-btn"
                />
              ) : (
                <Text alignSelf="end">{t('common:required')}</Text>
              )}
            </VisibilityWrapper>
          )
        )
      }
    }
  ]

  useEffect(() => {
    const updatePermissions = () => {
      const payload = {
        id: role?.id,
        name: role?.name,
        displayName: role?.displayName,
        description: role?.description,
        releaseStage: '',
        kind: role?.kind,
        managedBy: role?.managedBy,
        permissions: updatedPermissionsList.map(
          (permission) => permission?.permission_name
        )
      }
      put(`/authorization/v2alpha1/roles/${role?.id}`, payload).then(
        (response) => {
          if (response?.data) {
            retrieveRoleData()
          }
          const notification = {
            message: (
              <Markdown>
                {t(`iam:roles_permission.add_permissions_success_msg`, {
                  roleName: role?.displayName
                })}
              </Markdown>
            ),
            severity: 'info',
            setStatusNotification,
            title: t('iam:roles_permission.add_permissions_success_title')
          }
          setStatusNotification(notification)
          // This could affect the current user's permissions hence why we're calling updateV2RBACPolicies
          // updateV2RBACPolicies will not run on local as csrfToken will be null
          updateV2RBACPolicies(dispatchVisibilityContext, csrfToken)
        },
        (error) => {
          setApiError(error)
        }
      )
    }
    if (!initialRender) {
      updatePermissions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPermissionsList])

  return loading ? (
    <Box flex align="center" justify="center">
      <Loader testId="role-permission-tab-loader" />
    </Box>
  ) : (
    <Box width="xlarge">
      <Box
        direction="row"
        justify="between"
        pad={{ top: 'large', bottom: 'medium' }}
      >
        <Box pad={{ left: 'xxsmall' }}>
          <Typography
            level="2"
            size="medium"
            type="heading"
            weight="normal"
            testId="permissions-role-page-title"
          >
            {t('iam:roles_permission.permission_title')}
          </Typography>
        </Box>
        {custom && (
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.edit'
            }}
          >
            <Box>
              <Button
                label={t('iam:roles_permission.add_permission_title')}
                isLoading={isLoading}
                secondary
                testId="add-permissions-btn"
                onClick={() => {
                  setInitialRender(false)
                  setShowPermissionDialog(true)
                }}
              />
            </Box>
          </VisibilityWrapper>
        )}
      </Box>
      {linked ? (
        <Box>
          <Notification
            status="info"
            type="inline"
            text={t('iam:roles_permission.permission_linked_role', {
              role: t('common:business_object.role')
            })}
            testId="linked-permission-notification"
          />
        </Box>
      ) : (
        <Box>
          <DataTable
            grid={{
              columns,
              data: permissionDetails,
              pad: {
                vertical: 'small',
                horizontal: 'small'
              }
            }}
            loading={isLoading}
            testId="permissions-table"
          />
          {showRemovePermissionModal && (
            <RemovePermissionModal
              onSetOpen={setShowRemovePermissionModel}
              role={role}
              permissionName={permissionToDelete}
              onSuccess={onSuccessRemovePermission}
              onError={setApiError}
            />
          )}
        </Box>
      )}
      {successNotification}
      {apiError && displayApiError(apiError, t, setApiError)}
      {showPermissionDialog && applicationId && applicationName && (
        <AddPermissionModal
          setShowPermissionDialog={setShowPermissionDialog}
          setPermissionsList={setUpdatedPermissionsList}
          permissionsList={permissions}
          selectedApplication={{
            application_id: applicationId,
            name: applicationName
          }}
          setSelectedApplication={() => {}}
          hideServiceDropdown
          isDetailsPage
        />
      )}
    </Box>
  )
}

PermissionsTab.propTypes = {
  /**
   * Value that show the Add Permission modal
   */
  showPermissionDialog: PropTypes.bool.isRequired,
  /**
   * Callback to set the show modal back to caller.
   */
  setShowPermissionDialog: PropTypes.func.isRequired,
  /**
   * Set status notification
   */
  setStatusNotification: PropTypes.func
}

export { PermissionsTab }
