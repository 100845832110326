// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { Box, Button } from 'grommet'
import { Like, Dislike, Close } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Typography, FormTextArea } from '../../../../components'

const FeedbackComponent = ({ i18nTranslation, handleFeedBack }) => {
  const [addComment, setAddComment] = useState(false)
  const [isLiked, setIsLiked] = useState(false)
  const [isDisLiked, setIsDisLiked] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [formError, setFormError] = useState('')

  const commentMaxLength = 1000

  const validateFeedbackComments = (data) => {
    let errorLabel
    if (!data?.userFeedback?.isThumbsUp) {
      if (!data?.comment?.trim()) {
        errorLabel = i18nTranslation('Required')
      }
    }
    return errorLabel
  }

  const clearForm = () => {
    setIsLiked(false)
    setIsDisLiked(false)
    setAddComment(false)
    setFormValues({})
    setFormError('')
  }

  return (
    <Box
      direction="Column"
      background={{ color: 'background' }}
      border={{ color: 'border-weak', size: 'small', side: 'top' }}
      pad={{ vertical: 'small', horizontal: 'small' }}
      width="100%"
      gap="small"
    >
      <Box direction="row" justify="between" width="100%">
        <Box direction="row" gap="small" align="center">
          <Box
            background={isLiked ? 'border' : 'background'}
            border={{ color: 'border-weak', size: 'xsmall' }}
            align="center"
            round="xsmall"
            pad="xsmall"
            data-testid="gs-like-button"
            focusIndicator={false}
            hoverIndicator={{ background: 'border' }}
            onClick={() => {
              setIsLiked(true)
              setIsDisLiked(false)
              setAddComment(true)
            }}
          >
            <Like size="small" />
          </Box>
          <Box
            background={isDisLiked ? 'border' : 'background'}
            border={{ color: 'border-weak', size: 'xsmall' }}
            align="center"
            round="xsmall"
            pad="xsmall"
            focusIndicator={false}
            hoverIndicator={{ background: 'border' }}
            data-testid="gs-unlike-button"
            onClick={() => {
              setIsLiked(false)
              setIsDisLiked(true)
              setAddComment(true)
            }}
          >
            <Dislike size="small" />
          </Box>
          <Box gap="xsmall" align="center">
            {!addComment ? (
              <Typography type="text" size="small" testId="gs-feedback-title">
                {i18nTranslation('global_search.feedback_title')}
              </Typography>
            ) : (
              <Typography type="text" size="small" testId="gs-feedback-desc">
                {i18nTranslation(
                  isLiked
                    ? 'global_search.feedback_like_desc'
                    : 'global_search.feedback_dislike_desc'
                )}
              </Typography>
            )}
          </Box>
        </Box>
        {addComment && (
          <Box
            align="center"
            pad="xsmall"
            focusIndicator={false}
            data-testid="gs-close-button"
            hoverIndicator
            onClick={() => {
              clearForm()
            }}
          >
            <Close size="small" />
          </Box>
        )}
      </Box>
      {addComment && (
        <Box margin={{ top: 'small' }}>
          <FormTextArea
            width="100%"
            rows={8}
            placeholder={i18nTranslation('global_search.feedback_placeholder')}
            maxLength={1000}
            label=""
            inputType="text"
            value={formValues.comment || ''}
            name="comment"
            error={formError}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                comment: event.target.value
              })
              setFormError('')
            }}
            testId="gs-feedback-comment"
          />
          <Box
            justify="between"
            direction="row"
            width="100%"
            margin={{ bottom: 'small', top: 'xsmall' }}
          >
            <Button
              data-testid="gs-submit-feedback-button"
              pad={{ vertical: 'none', horizontal: 'small' }}
              label={
                <Typography type="text" size="medium" weight="bold" emphasis>
                  {i18nTranslation('global_search.feedback_submit')}
                </Typography>
              }
              default
              alignSelf="start"
              reverse
              align="baseline"
              onClick={() => {
                const data = {
                  userFeedback: {
                    isThumbsUp: isLiked
                  },
                  comment: formValues.comment
                }
                const validateRes = validateFeedbackComments(data)
                if (validateRes) {
                  setFormError(validateRes)
                } else {
                  handleFeedBack(data)
                  clearForm()
                }
              }}
            />
            <Typography
              type="text"
              size="xsmall"
              color="text-weak"
              testId="gs-comment-count"
            >
              {formValues?.comment?.length || 0} /{commentMaxLength}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
FeedbackComponent.propTypes = {
  i18nTranslation: PropTypes.any.isRequired,
  handleFeedBack: PropTypes.func.isRequired
}
export { FeedbackComponent }
