// Copyright 2024 Hewlett Packard Enterprise Development LP
import lodashGet from 'lodash/get'
import { isEmpty } from 'lodash'

import { get, getErrorMessage, post } from '../../utils/api-utils'
import { getApiErrorMessage } from '../../utils/error-handling-utils'
import { getCCSAppDetails } from '../../utils/ccs-manager-utils'

const getFolder = async ({ token, folderId, t, setErrorMessage }) => {
  const url = `/ui-doorway/ui/v1/activate/folders/${folderId}`
  return get(url, {}, token).then(
    (response) => {
      return response
    },
    (error) => {
      const backendErrorMessage = getApiErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
      return []
    }
  )
}

const actionCreateFolder = ({
  token,
  t,
  requestBody,
  onSetOpen,
  setErrorMessage,
  refreshDataTable,
  isCCSManager
}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/folder'
    : '/ui-doorway/ui/v1/activate/folders'

  post(url, requestBody, token).then(
    (response) => {
      if (response.status === 200) {
        onSetOpen(false)
        refreshDataTable()
      }
    },
    (error) => {
      setErrorMessage(getApiErrorMessage(error, t))
    }
  )
}

const getAllParts = ({ token, param, isCCSManager } = {}) => {
  const url = isCCSManager
    ? '/support-assistant/v1alpha1/activate-part'
    : `/ui-doorway/ui/v1/activate/part`

  return get(url, param, token).then(
    (response) => {
      return response.data
    },
    () => {
      return []
    }
  )
}

const getAllMac = ({ token, param, inCCSManager } = {}) => {
  const url = inCCSManager
    ? `/support-assistant/v1alpha1/deviceSummary`
    : `/ui-doorway/ui/v1/activate/devices/mac`

  return get(url, param, token).then(
    (response) => {
      return lodashGet(response, 'data.mac_addresses', [])
    },
    () => {
      return []
    }
  )
}

const getAllCountries = (
  t,
  isCurrent,
  setListOfCountries,
  setCountries,
  setErrorMessage
) => {
  get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
    (response) => {
      if (!isCurrent) return
      setListOfCountries(response.data.countries)
      setCountries(response.data.countries)
    },
    (error) => {
      const backendErrorMessage = getErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  )
}

const getCurrentCCSRoles = (roles) => {
  const { ccsApplicationId } = getCCSAppDetails()
  return roles
    .filter((role) => role.application_id === ccsApplicationId)
    .map((role) => role.slug)
}

export const roleKindMapping = new Map([
  ['LINKED', 'Linked'],
  ['CUSTOM', 'Custom'],
  ['PREDEFINED', 'Built-in'],
  ['MANAGED', 'Managed']
])

export const showActionDropdown = (policies, actionList) => {
  return actionList.some(
    (actions) =>
      policies?.effects?.[actions?.visibility?.rbac?.resource]?.[
        actions?.visibility?.rbac?.permission
      ]
  )
}

export const showV2ActionDropdown = (policies, actionList) => {
  return actionList?.some((action) =>
    policies?.includes(`${action?.visibility?.rbac?.permission}`)
  )
}

export const showContent = (policies, rbac) => {
  return policies?.effects?.[rbac?.resource]?.[rbac?.permission]
}

export const getGLCPAppDetails = () => {
  return {
    glcpApplicationId: '00000000-0000-0000-0000-000000000000',
    glcpApplicationName: 'HPE GreenLake Platform'
  }
}

export const getUserName = (data) => {
  if (data?.subject_type === 'user') {
    if (data?.subject_name !== '')
      return `${data?.subject_name} (${data?.subject_email})`
    return data?.subject_email
  }
  return data?.subject_name
}

const updateStatusName = (name) => {
  switch (name) {
    case 'pending':
      return 'Pending'

    case 'warning':
      return 'Warning'

    case 'disabled':
      return 'Disabled'

    case 'active':
      return 'Active'

    case 'error':
      return 'Critical'

    default:
      return name || ''
  }
}

const validateSAMLAuthzDomain = (
  email,
  oidcUser,
  t,
  setLoading,
  onAPIError,
  onSAMLAuthz = () => {},
  onNonSAMLAuthz
) => {
  const domain = email?.trim()?.split('@')?.pop()
  if (!isEmpty(domain)) {
    setLoading(true)
    get(
      `/ui-doorway/ui/v1/saml/claims?domains=${encodeURIComponent(domain)}`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        if (response?.data?.sso_mode) {
          onSAMLAuthz()
        } else if (onNonSAMLAuthz) {
          onNonSAMLAuthz()
        }
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        onAPIError(getErrorMessage(error, t), 'error')
      }
    )
  }
}

export {
  getFolder,
  actionCreateFolder,
  getAllMac,
  getAllParts,
  getAllCountries,
  getCurrentCCSRoles,
  updateStatusName,
  validateSAMLAuthzDomain
}
