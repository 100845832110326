// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useLocation } from 'react-router-dom'

import LocationWizard from '../../../../commoncomponents/location-management/location-wizard/LocationWizard'

const CreateLocationPage = () => {
  const { state: location } = useLocation()
  const customerId = location?.customerId

  return (
    <LocationWizard customerId={customerId} isCCSManager wizardType="create" />
  )
}

export default CreateLocationPage
