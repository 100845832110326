// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from 'react'

import {
  getOrganizationId,
  isAssociateWorkspace
} from '../../../utils/feature-flag-utils'
import { get, getErrorMessage } from '../../../utils/api-utils'

const useOrganizationAssociation = (setNotificationInfo) => {
  const [isAssociated, setIsAssociated] = useState(false)
  const [isAssociateLoader, setIsAssociateLoader] = useState(true)

  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`)
        .then((res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        })
        .catch((err) => {
          setNotificationInfo(getErrorMessage(err), 'error')
        })
        .finally(() => {
          setIsAssociateLoader(false)
        })
    } else {
      setIsAssociateLoader(false)
    }
  }, [setNotificationInfo])

  return { isAssociated, isAssociateLoader }
}

export default useOrganizationAssociation
