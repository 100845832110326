// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { string } from 'yup'

import { Typography } from '../../../components'
import { NON_GLP_USER_NAME } from '../utils'

export const renderContactsOptions = (option, t) => {
  if (option.name === NON_GLP_USER_NAME) {
    return (
      <Box pad="small">
        <Typography type="text" emphasis>
          {`+ ${t('common:add')} ${option.label}`}
        </Typography>
      </Box>
    )
  }
  return (
    <Box
      align="start"
      gap="xsmall"
      pad={{ horizontal: 'small', bottom: 'small' }}
    >
      <Typography type="text" emphasis>
        {option.name}
      </Typography>
      <Typography type="text" size="small">
        {option.value}
      </Typography>
    </Box>
  )
}

export const updateUsersList = (
  users,
  searchVal,
  selectedUserDetails,
  isNonGLPUserFlagEnabled
) => {
  // if user is not found in the dropdown, add it to the dropdown
  // check for flag to allow email outside of the platform
  if (
    isNonGLPUserFlagEnabled &&
    searchVal &&
    !users.find(
      (user) =>
        user.value.trim().toLocaleLowerCase() ===
        searchVal.trim().toLocaleLowerCase()
    ) &&
    string().email().isValidSync(searchVal)
  ) {
    users.unshift({
      value: searchVal,
      name: NON_GLP_USER_NAME,
      label: searchVal
    })
  }
  // when dropdown is closed previously selected user is not part of the dropdown
  // add it to the dropdown and use uniqBY (similar approach followed at other places where dropdown with pagination used)
  if (
    selectedUserDetails.email !== '' &&
    !users.find((user) => user.value === selectedUserDetails.email)
  ) {
    users.push({
      value: selectedUserDetails.email,
      name: selectedUserDetails.name,
      label:
        selectedUserDetails.name !== NON_GLP_USER_NAME
          ? ` ${selectedUserDetails.name} (${selectedUserDetails.email})`
          : selectedUserDetails.email
    })
  }
}
