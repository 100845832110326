// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Text, CheckBox, List } from 'grommet'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  getIcons,
  getState,
  getStateAlerts,
  getStateTasks
} from '../../../../commoncomponents/notifications-dashboard/utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const ListNotifications = ({ stateV, dispatchNotifications, type }) => {
  const { t } = useTranslation(['manage'])
  const navigate = useNavigate()
  const onCheck = (event, value) => {
    if (event.target.checked) {
      dispatchNotifications({
        type: 'CHECKED',
        checked: [...stateV.checked, value]
      })
      dispatchNotifications({
        type: 'SELECT',
        select: [...stateV.checked, value]
      })
    } else {
      dispatchNotifications({
        type: 'CHECKED',
        checked: stateV.checked.filter((item) => item !== value)
      })
      dispatchNotifications({
        type: 'SELECT',
        select: stateV.checked.filter((item) => item !== value)
      })
    }
  }
  return (
    <List
      data={stateV.pageData}
      border={{
        side: 'bottom',
        color: 'border-weak'
      }}
      data-testid="notifications-list"
      onClickItem={(event) => {
        event.stopPropagation()
        if (event.item?.eventType?.includes('ALERT')) {
          navigate(`/notifications/alerts/${event.item.id}`)
        } else if (event.item?.eventType?.includes('TASK')) {
          navigate(`/notifications/tasks/${event.item.id}`)
        } else {
          navigate(`/notifications/${event.item.id}`)
        }
      }}
      pad={{ vertical: 'small' }}
    >
      {(datum, i) => (
        <>
          <Box
            direction="row-responsive"
            data-testid={`notifications-list-${i}`}
          >
            <Box direction="row-responsive" width="xxlarge">
              <CheckBox
                key={datum?.id}
                checked={stateV.checked.includes(datum?.id)}
                onClick={(event) => {
                  event.stopPropagation()
                  onCheck(event, datum?.id)
                }}
                testId="checkbox"
              />
              {getIcons(datum.severity, 'large', t, type)}
              <Box direction="column" justify="center">
                <Text weight={datum?.isRead ? 'normal' : 'bold'} size="medium">
                  {datum?.summary}
                  <br />
                  <Text weight="normal" size="small">
                    {datum?.category?.service}
                  </Text>
                </Text>
              </Box>
            </Box>
            <Box
              margin={{ bottom: 'xsmall', right: 'small' }}
              direction="column"
              width="medium"
              alignSelf="end"
            >
              <Text textAlign="end">
                {datum.updatedAt
                  ? `${dayjs(datum?.updatedAt * 1000).format(
                      'MMM DD, YYYY HH:mm A'
                    )}`
                  : '--'}
              </Text>
              <VisibilityWrapper
                hideFor={{
                  feature: 'glcp-notifications-feature-filters-jhansi',
                  deployment: ['GLOP']
                }}
              >
                <Text weight="normal" textAlign="end">
                  {t(getStateAlerts[datum?.alertState]) ||
                    t(getStateTasks[datum?.taskState]) ||
                    getState(datum?.state, t) ||
                    '--'}
                </Text>
              </VisibilityWrapper>
            </Box>
          </Box>
        </>
      )}
    </List>
  )
}

ListNotifications.propTypes = {
  stateV: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchNotifications: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}
export { ListNotifications }
