// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ModalDialog,
  ModalFooter,
  Typography,
  ButtonGroup,
  ModalHeader
} from '../../components'
import { patch } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'

const NewDetachSubscriptionModal = ({
  onSetOpen,
  datum,
  openSuccessModal,
  detachSubscriptionData,
  mode
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState(null)
  const [isConfirmationModal, setIsConfirmationModal] = useState(false)

  // To detach base subscription from the device
  const handleDetach = () => {
    const deviceData = datum.map((device) => {
      let subscriptionResourceIds = []
      if (detachSubscriptionData?.key_type === 'ADD_ON') {
        subscriptionResourceIds = device?.subscriptions
          ?.filter((subscription) => {
            return (
              subscription?.resource_id !== detachSubscriptionData?.resource_id
            )
          })
          .map((subscription) => {
            return subscription?.resource_id
          })
      }
      return {
        device_type: device?.device_type,
        part_number: device?.part_number,
        serial_number: device?.serial_number,
        device_resource_id: device?.device_resource_id,
        subscription_resource_ids: subscriptionResourceIds
      }
    })
    patch(
      '/ui-doorway/ui/v1/license/devices',
      deviceData,
      oidcUser.access_token
    ).then(
      () => {
        onSetOpen(false)
        openSuccessModal()
      },
      (error) => {
        setErrorMessage(displayApiError(error, t, setErrorMessage))
      }
    )
  }

  const getDetachDescription = () => {
    let isAddonExist = false
    if (detachSubscriptionData?.key_type === 'BASE')
      return t('detach_base_subscription')
    if (detachSubscriptionData?.key_type === 'ADD_ON')
      return t('detach_addon_subscription')
    if (datum?.length === 1) {
      isAddonExist = datum[0]?.subscriptions?.find(
        (val) => val.key_type === 'ADD_ON'
      )
    } else {
      isAddonExist = datum?.some((dev) =>
        dev?.subscriptions?.find((val) => val.key_type === 'ADD_ON')
      )
    }
    if (isAddonExist) return t('detach_multi_license_with_base_addon')
    return t('detach_multi_license_with_base')
  }

  return (
    <ModalDialog
      width="medium"
      testId="detach-devices-modal-dialog"
      header={
        <ModalHeader
          title={
            <Box>
              <Typography
                type="heading"
                level="2"
                testId="detach-devices-title"
              >
                {t('detach_subscription')}
              </Typography>
              {mode !== 'SINGLE' && (
                <Typography type="text">
                  {datum?.length === 1
                    ? t('device_selected_stats', { deviceCount: datum?.length })
                    : t('devices_selected_stats', {
                        deviceCount: datum?.length
                      })}
                </Typography>
              )}
            </Box>
          }
          onClose={() => onSetOpen(false)}
        />
      }
      content={
        <Box>
          <Typography
            type="text"
            size="small"
            margin={{ top: 'medium' }}
            testId="detach-devices-description"
          >
            {getDetachDescription()}
          </Typography>
          {isConfirmationModal && (
            <Typography
              type="text"
              size="small"
              margin={{ top: 'medium' }}
              testId="detach-devices-confirmation"
            >
              {t('confirm_detach_subscription')}
            </Typography>
          )}
          {errorMessage}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  label: t('cancel'),
                  default: true,
                  secondary: true,
                  testId: 'cancel-btn',
                  onClick: () => onSetOpen(false)
                },
                isConfirmationModal
                  ? {
                      label: t('yes_detach'),
                      primary: true,
                      testId: 'detach-btn',
                      onClick: handleDetach
                    }
                  : {
                      label: t('detach_subscription_sentence_case'),
                      primary: true,
                      testId: 'detach-btn',
                      onClick: () => setIsConfirmationModal(true)
                    }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

NewDetachSubscriptionModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  datum: PropTypes.array.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['BULK', 'SINGLE']),
  detachSubscriptionData: PropTypes.object
}

NewDetachSubscriptionModal.defaultProps = {
  mode: 'BULK',
  detachSubscriptionData: null
}

export { NewDetachSubscriptionModal }
