// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useMemo } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { StatusCritical } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Typography,
  DataTable,
  Notification,
  Loader,
  ToggleButton
} from '../../../components'
import { getErrorMessage } from '../../../utils/api-utils'
import { getPaginationShowIdx } from '../../../utils/common-utils'

import FeatureFlagsStatusDetails from './FeatureFlagsStatusDetails'
import FeatureFlagsStatusTableHeader from './FeatureFlagsStatusTableHeader'
import {
  ldFlagsStatusPaginate,
  ldFlagsStatusSearch,
  ldFlagsStatusLang,
  getAllLDEnvData,
  getEnvFilteredData,
  envDisplayNameConversion
} from './utils'
import FeatureFlagsStatusCell from './FeatureFlagsStatusCell'

const FeatureFlagsStatusTable = () => {
  const {
    oidcUser: { access_token }
  } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])
  const pageSize = 10
  const [isLoading, setIsLoading] = useState(false)
  const [ldFlagsStatusData, setLDFlagsStatusData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [fileName, setFileName] = useState('')
  const [ldFlagsStatusPerPage, setLdFlagsStatusPerPage] = useState(pageSize)
  const [headerFilter, setHeaderFilter] = useState({})
  const [selectedFlag, setSelectedFlag] = useState()

  // for pagination
  const [page, setPage] = useState(1)
  const [totalNoOfLDStatus, setTotalNoOfLDStatus] =
    useState(ldFlagsStatusPerPage)

  const pageIdxInfo = useMemo(() => {
    return getPaginationShowIdx(
      page,
      totalNoOfLDStatus,
      ldFlagsStatusPerPage,
      t
    )
  }, [page, totalNoOfLDStatus, ldFlagsStatusPerPage, t])

  const updateHeaderFilter = (envKey, clickingCount) => {
    setHeaderFilter({ [envKey]: clickingCount })
    setPage(1)
  }

  const envDisplayNames = useMemo(() => {
    const result = {}
    if (ldFlagsStatusData?.items?.length > 0) {
      const environments = ldFlagsStatusData?.items[0].environments
      Object.keys(environments).forEach((envKey) => {
        const { _environmentName: rawEnvName } = environments[envKey]
        result[envKey] = envDisplayNameConversion(rawEnvName)
      })
    }
    return result
  }, [ldFlagsStatusData])

  const columns = useMemo(() => {
    return [
      {
        property: 'key',
        type: 'string',
        header: (
          <Typography
            type="text"
            emphasis
            testId="headerFilter-flag-key-label"
            truncate
          >
            {ldFlagsStatusLang.key}
          </Typography>
        ),
        primary: true
      },
      {
        property: 'glcp-dev',
        type: 'string',
        header: (
          <FeatureFlagsStatusTableHeader
            key="header-glcp-dev"
            envKey="glcp-dev"
            label={envDisplayNames['glcp-dev']}
            headerFilter={headerFilter}
            updateHeaderFilter={updateHeaderFilter}
          />
        ),
        render: (datum) => {
          return (
            <FeatureFlagsStatusCell
              key="cell-glcp-dev"
              flagStatusCellData={datum['glcp-dev']}
            />
          )
        }
      },
      {
        property: 'glcp-dev-integration',
        header: (
          <FeatureFlagsStatusTableHeader
            key="header-glcp-dev-integration"
            envKey="glcp-dev-integration"
            label={envDisplayNames['glcp-dev-integration']}
            headerFilter={headerFilter}
            updateHeaderFilter={updateHeaderFilter}
          />
        ),
        render: (datum) => {
          return (
            <FeatureFlagsStatusCell
              key="cell-glcp-triton"
              flagStatusCellData={datum['glcp-dev-integration']}
            />
          )
        }
      },
      {
        property: 'glcp-qa',
        header: (
          <FeatureFlagsStatusTableHeader
            key="header-glcp-qa"
            envKey="glcp-qa"
            label={envDisplayNames['glcp-qa']}
            headerFilter={headerFilter}
            updateHeaderFilter={updateHeaderFilter}
          />
        ),
        render: (datum) => {
          return (
            <FeatureFlagsStatusCell
              key="cell-glcp-mira"
              flagStatusCellData={datum['glcp-qa']}
            />
          )
        }
      },
      {
        property: 'glcp-stage',
        header: (
          <FeatureFlagsStatusTableHeader
            key="header-glcp-stage"
            envKey="glcp-stage"
            label={envDisplayNames['glcp-stage']}
            headerFilter={headerFilter}
            updateHeaderFilter={updateHeaderFilter}
          />
        ),
        render: (datum) => {
          return (
            <FeatureFlagsStatusCell
              key="cell-glcp-pavo"
              flagStatusCellData={datum['glcp-stage']}
            />
          )
        }
      },
      {
        property: 'glcp-pre-prod',
        header: (
          <FeatureFlagsStatusTableHeader
            key="header-glcp-pre-prod"
            envKey="glcp-pre-prod"
            label={envDisplayNames['glcp-pre-prod']}
            headerFilter={headerFilter}
            updateHeaderFilter={updateHeaderFilter}
          />
        ),
        render: (datum) => {
          return (
            <FeatureFlagsStatusCell
              key="cell-glcp-dracomr"
              flagStatusCellData={datum['glcp-pre-prod']}
            />
          )
        }
      },
      {
        property: 'glcp-production',
        header: (
          <FeatureFlagsStatusTableHeader
            key="header-glcp-production"
            envKey="glcp-production"
            label={envDisplayNames['glcp-production']}
            headerFilter={headerFilter}
            updateHeaderFilter={updateHeaderFilter}
          />
        ),
        render: (datum) => {
          return (
            <FeatureFlagsStatusCell
              key="cell-glcp-prod"
              flagStatusCellData={datum['glcp-production']}
            />
          )
        }
      },
      {
        property: 'glcp-hotfix',
        header: (
          <FeatureFlagsStatusTableHeader
            key="header-glcp-hotfix"
            envKey="glcp-hotfix"
            label={envDisplayNames['glcp-hotfix']}
            headerFilter={headerFilter}
            updateHeaderFilter={updateHeaderFilter}
          />
        ),
        render: (datum) => {
          return (
            <FeatureFlagsStatusCell
              key="cell-glcp-hotfix"
              flagStatusCellData={datum['glcp-hotfix']}
            />
          )
        }
      }
    ]
  }, [envDisplayNames, headerFilter])

  const fetchLDFlagsStatus = () => {
    setIsLoading(true)
    getAllLDEnvData(access_token)
      .then((res) => {
        if (res.data) {
          setLDFlagsStatusData(res.data)
          setTotalNoOfLDStatus(res?.data.items?.length || 0)
          setErrorMessage(null)
        }
      })
      .catch((error) => {
        setLDFlagsStatusData([])
        setErrorMessage(getErrorMessage(error, t))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchLDFlagsStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getHasRulesFromEnvData = (envData) => {
    const { _summary: summary } = envData
    if (!summary.variations) {
      return false
    }
    const variationsValues = Object.values(summary.variations)
    return variationsValues.some((variationValue) => {
      return variationValue?.rules > 0
    })
  }

  const getStatusFromEnvs = (envsInfo) => {
    const envKeys = Object.keys(envsInfo)
    const result = {}
    envKeys.forEach((key) => {
      result[key] = {
        status: envsInfo[key].on ? 'ON' : 'OFF',
        hasRules: getHasRulesFromEnvData(envsInfo[key])
      }
    })

    return result
  }

  const ldFlagsStatusRenderingData = useMemo(() => {
    const renderingData = ldFlagsStatusData?.items?.map((ldFlagItem) => {
      const statusCrossingEnvs =
        getStatusFromEnvs(ldFlagItem.environments) || {}
      return {
        key: ldFlagItem.key,
        kind: ldFlagItem.kind,
        ...statusCrossingEnvs
      }
    })
    return renderingData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldFlagsStatusData])

  const renderingDataWithPagination = useMemo(() => {
    if (ldFlagsStatusRenderingData?.length > 0) {
      const filteredData = getEnvFilteredData(
        ldFlagsStatusRenderingData,
        headerFilter
      )
      const searchableData = ldFlagsStatusSearch(fileName, filteredData)
      setTotalNoOfLDStatus(searchableData.length)
      return ldFlagsStatusPaginate(searchableData, ldFlagsStatusPerPage, page)
    }
    setTotalNoOfLDStatus(0)
    return []
  }, [
    fileName,
    ldFlagsStatusPerPage,
    ldFlagsStatusRenderingData,
    page,
    headerFilter
  ])

  const isToggleChecked = useMemo(() => {
    return ldFlagsStatusPerPage !== pageSize
  }, [ldFlagsStatusPerPage])

  return (
    <>
      {isLoading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="programs-loader" />
        </Box>
      ) : (
        <Box direction="column">
          <Box
            width={{ min: '100%' }}
            margin={{ bottom: '-30px' }}
            data-testid="ld-status-table-control-bar"
            justify="end"
            direction="row"
            gap="small"
          >
            <Box>
              <ToggleButton
                testId="ld-status-single-page"
                checked={isToggleChecked}
                label="Single Page"
                onChange={() => {
                  if (isToggleChecked) {
                    setLdFlagsStatusPerPage(pageSize)
                  } else {
                    setLdFlagsStatusPerPage(5000)
                  }
                }}
              />
            </Box>
          </Box>
          <DataTable
            pagination={{
              totalItems: totalNoOfLDStatus,
              itemsPerPage: ldFlagsStatusPerPage,
              page,
              setPage,
              pageIdxInfo
            }}
            grid={{
              columns,
              data: renderingDataWithPagination,
              onClickRow: (data) => {
                setSelectedFlag(data.datum.key)
              }
            }}
            search={{
              onSearchValueChange: (value) => {
                setPage(1)
                setFileName(value)
              },
              placeholder: ldFlagsStatusLang.search_placeholder
            }}
            summary={{
              entityName: ldFlagsStatusLang.table_summary
            }}
            testId="ldFlagsStatus-data-table"
          />
        </Box>
      )}

      {selectedFlag && (
        <FeatureFlagsStatusDetails
          flag={selectedFlag}
          envDisplayNames={envDisplayNames}
          rowData={ldFlagsStatusRenderingData.find((flagData) => {
            return flagData?.key === selectedFlag
          })}
          onClose={() => {
            setSelectedFlag()
            setErrorMessage(null)
          }}
          setErrorMessage={setErrorMessage}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </>
  )
}
FeatureFlagsStatusTable.propTypes = {}
export default FeatureFlagsStatusTable
