// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { get } from '../../utils/api-utils'
import { setCustomerAccount } from '../../utils/feature-flag-utils'

export const launchAccount = (custAccountLoaded, customer, navigate) => {
  sessionStorage.setItem('swapMspAccount', JSON.stringify(custAccountLoaded))
  customer.platform_customer_id = customer.customer_id
  customer.account_type = 'TENANT'
  delete customer.customer_id
  setCustomerAccount(customer)
  navigate('/post-onboarding/choose-account')
}

export const getExportReportStatus = (id, status, setStatus, token) => {
  get(`/accounts/ui/v1/async-task-tracker/${id}`, {}, token).then(
    (response) => {
      if (response.status === 200) {
        const { status: responseStatus } = response.data
        if (responseStatus && responseStatus !== status) {
          setStatus(responseStatus)
        }
      }
    },
    (error) => {
      if (error.response.status === 404 && status === 'IN_PROGRESS') {
        // if 404, the task is expired, we can stop polling
        setStatus(null)
      }
    }
  )
}
