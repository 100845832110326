// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState, useContext } from 'react'
import { Box, Grid, PageHeader, ResponsiveContext } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { SideMenu, MyServicesWrapper } from '../../../dashboard/components'
import { Loader, Notification } from '../../../components'
import { getErrorMessage } from '../../../utils/api-utils'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { useAxiosAuth } from '../../../dashboard/hooks'
import NewTogglableMyServices from '../components/NewTogglableMyServices'
import { useVisibilityContext } from '../../../context/visibility-context'

const NewMyServices = () => {
  const [myServicesData, setMyServicesData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [regions, setRegions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState('all')
  const { t } = useTranslation(['dashboard'])
  const size = useContext(ResponsiveContext)
  const axios = useAxiosAuth()
  const LDFlags = useFlags()
  const { rbacPolicies } = useVisibilityContext()
  const [myServicesResource, setMyServicesResource] = useState('')
  const [myServicesPermission, setMyServicesPermission] = useState('')
  const [myServicesRbacValue, setMyServicesRbacValue] = useState(false)

  useEffect(() => {
    let potentialPermissions = {
      '/ccs/app-provision/provisions': 'ccs.app-provision.view'
    }

    if (LDFlags['glcp-service-registry']) {
      potentialPermissions = {
        ...potentialPermissions,
        '/ccs/service-catalog/service-provision': 'ccs.service-catalog.view',
        '/ccs/service-catalog/my-services': 'ccs.service-catalog.view'
      }
    }

    if (rbacPolicies?.effects) {
      const permissionsToViewMyServices = Object.entries(rbacPolicies.effects)
        .filter(([resourceKey]) =>
          Object.keys(potentialPermissions).includes(resourceKey)
        )
        .map(([resource, permissions]) => {
          const permission = potentialPermissions[resource]
          const value = permissions[potentialPermissions[resource]]
          return {
            resource,
            permission,
            value
          }
        })

      // Return the key-value pair if one is true. Otherwise, return the first that's false.
      const { resource, permission, value } =
        permissionsToViewMyServices.find(
          ({ value: permissionValue }) => permissionValue
        ) ||
        permissionsToViewMyServices.find(
          ({ value: permissionValue }) => permissionValue === false
        )

      setMyServicesResource(resource)
      setMyServicesPermission(permission)
      setMyServicesRbacValue(value)
    }
  }, [rbacPolicies, LDFlags])

  useEffect(() => {
    axios
      .get('/service-catalog/v1alpha1/my-services', {
        params: { include_omnipresent: true }
      })
      .then(({ data: { items } }) => {
        setMyServicesData(items || [])
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error, t))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [axios, t])

  useEffect(() => {
    const regionsValue = myServicesData?.map((item) => item.region)
    const regionsName = ['all', ...new Set(regionsValue)]
    const regionsToSet = regionsName.map((region) => ({
      label: region === 'all' ? t('common.all_regions') : region,
      value: region
    }))
    setRegions(regionsToSet)
  }, [myServicesData, t])

  return (
    <Layout>
      <Box>
        <Box pad={{ horizontal: 'xlarge', vertical: 'xsmall' }}>
          {errorMessage && (
            <Notification
              backgroundColor="status-critical"
              icon={<StatusCritical />}
              onClose={() => setErrorMessage('')}
              position="top"
              testId="my-services-error-notification"
              text={errorMessage}
            />
          )}
          <Box margin={{ bottom: 'medium' }}>
            <PageHeader
              title={t('common.services')}
              subtitle={t('common.services_subtitle')}
              testId="service-centric-services-header"
            />
          </Box>
          <Grid
            rows={['auto']}
            columns={size === 'xlarge' ? ['small', 'flex'] : ['full']}
            gap="large"
          >
            <SideMenu active="my-services" />
            <Box>
              <MyServicesWrapper
                regions={regions}
                setSelectedRegion={setSelectedRegion}
                resource={myServicesResource}
                permission={myServicesPermission}
              />
              {isLoading && !errorMessage && (
                <Box align="center" justify="center" alignSelf="center">
                  <Loader testId="loader-spinner" />
                </Box>
              )}
              {!isLoading && (
                <NewTogglableMyServices
                  myServicesData={myServicesData}
                  selectedRegion={selectedRegion}
                  permissionToView={myServicesRbacValue}
                />
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

export default NewMyServices
