// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'

import { Wizard } from '../../../components'
import { validateContactList } from '../AssignContacts/AssignLocationContacts'
import { post, put, patch } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { updateLocationTags } from '../updateTagsUtils'

import {
  LocationDetails,
  locationDetailsValidate
} from './steps/LocationDetails'
import { AssignContacts } from './steps/AssignContacts'
import { ReviewLocationDetails } from './steps/ReviewLocationDetails'
import { ValidateLocation } from './steps/validateLocation'
import { compareLocAddresses, compareLocContacts } from './updateLocationUtils'
import { AssignTags } from './steps/AssignTags'

const LocationWizard = ({
  customerId = null,
  isCCSManager = false,
  wizardType,
  locationDetailsData = undefined,
  initialTags = []
}) => {
  const { t } = useTranslation(['location'])
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const [showErrorMessage, setShowErrorMessage] = useState(null)
  const [requestBody, setRequestBody] = useState(null)
  const [previousLocDetailsData, setPreviousLocDetailsData] = useState(null)
  const { locationId } = useParams()
  const LDFlags = useFlags()

  useEffect(() => {
    if (locationDetailsData) {
      setPreviousLocDetailsData(cloneDeep(locationDetailsData))
    }
  }, [locationDetailsData])

  const formDefaultValues = locationDetailsData || {
    name: '',
    description: '',
    type: 'building',
    addresses: [],
    contacts: [],
    ...(!isCCSManager && {
      validation_cycle: '6'
    })
  }
  if (!isCCSManager && LDFlags['glcp-locations-tags']) {
    formDefaultValues.tags = initialTags || []
  }

  const onCreateLocation = () => {
    const reqBody = {
      name: requestBody.name,
      description: requestBody.description,
      type: requestBody.type,
      addresses: requestBody.addresses,
      contacts: requestBody.contacts,
      ...(!isCCSManager && {
        validated: true,
        validation_cycle: requestBody.validation_cycle,
        validated_by_email: oidcUser.profile.email,
        validated_by_name: `${oidcUser.profile.given_name} ${oidcUser.profile.family_name}`
      })
    }
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/location`
      : '/ui-doorway/ui/v1/locations'

    post(
      url,
      {
        ...reqBody,
        ...(isCCSManager && { platform_customer_id: customerId })
      },
      oidcUser.access_token
    ).then(
      (response) => {
        // assign tags if tags are present after creation
        if (
          !isCCSManager &&
          LDFlags['glcp-locations-tags'] &&
          requestBody?.tags?.length > 0
        ) {
          patch(
            '/ui-doorway/ui/v1/locations/tags',
            {
              createTags: requestBody.tags,
              locationId: response.data.location_id
            },
            oidcUser.access_token,
            {}
          ).then(
            () => {
              navigate('/manage-account/locations')
            },
            (error) => {
              setShowErrorMessage(
                displayApiError(error, t, setShowErrorMessage)
              )
            }
          )
        } else {
          navigate(
            isCCSManager
              ? {
                  pathname: '/manage-ccs/customers/customer-details',
                  tabName: 'locations'
                }
              : '/manage-account/locations'
          )
        }
      },
      (err) => {
        setShowErrorMessage(displayApiError(err, t, setShowErrorMessage))
      }
    )
  }
  const onValidateLocation = () => {
    const addresses = compareLocAddresses(
      previousLocDetailsData.addresses,
      requestBody.addresses
    )
    // check contacts
    const contacts = compareLocContacts(
      previousLocDetailsData.contacts,
      requestBody.contacts
    )
    // validate location is not available for CCS manager so no need to check
    const url = `/ui-doorway/ui/v1/locations/${locationId}`
    const promises = []
    promises.push(
      put(
        url,
        {
          name: requestBody.name,
          description: requestBody.description,
          type: requestBody.type,
          addresses,
          contacts,
          ...(!isCCSManager && {
            validated: true,
            validation_cycle: requestBody.validation_cycle,
            validated_by_email: oidcUser.profile.email,
            validated_by_name: `${oidcUser.profile.given_name} ${oidcUser.profile.family_name}`
          })
        },
        oidcUser.access_token
      )
    )
    if (LDFlags['glcp-locations-tags']) {
      const { assigned, unassigned } = updateLocationTags(
        initialTags,
        requestBody.tags
      )
      // only need to call API if there are changes
      if (unassigned.length !== 0 || assigned.length !== 0) {
        promises.push(
          patch(
            '/ui-doorway/ui/v1/locations/tags',
            {
              createTags: [...assigned],
              deleteTags: [...unassigned],
              locationId
            },
            oidcUser.access_token,
            {}
          )
        )
      }
    }
    Promise.all(promises).then(
      () => {
        navigate(`/manage-account/locations/${locationId}`)
      },
      (err) => {
        setShowErrorMessage(displayApiError(err, t, setShowErrorMessage))
      }
    )
  }

  const submitHandler = () => {
    if (wizardType === 'create') {
      onCreateLocation()
    } else {
      onValidateLocation()
    }
  }

  return (
    <Box>
      <Wizard
        title={
          wizardType === 'create'
            ? t('create_location')
            : t('validate_location')
        }
        formDefaultValues={formDefaultValues}
        steps={[
          {
            title:
              wizardType === 'create'
                ? t('create_location')
                : t('validate_location'),
            description: (
              <Box width="medium">{t('create_location_description')}</Box>
            ),
            childComponents: <LocationDetails />,
            validateForm: async (formValues) => ({
              then: async (resolve, reject) => {
                const validation = await locationDetailsValidate(
                  formValues,
                  t,
                  oidcUser,
                  setShowErrorMessage,
                  customerId,
                  isCCSManager,
                  locationId,
                  LDFlags['glcp-sdi-address-doctor']
                )
                if (validation.isValid) {
                  setRequestBody(formValues)
                  resolve()
                } else {
                  reject(new Error(validation.errorMessage))
                }
              }
            })
          },
          {
            title: t('assign_contacts'),
            description: '',
            childComponents: (
              <AssignContacts
                isCCSManager={isCCSManager}
                customerId={customerId}
              />
            ),
            // simulate returning a promise
            validateForm: (formValues) => ({
              then: (resolve, reject) => {
                const validation = validateContactList(formValues.contacts, t)
                if (validation.isValid) {
                  setRequestBody(formValues)
                  resolve()
                } else reject(new Error(validation.error))
              }
            })
          },
          ...(!isCCSManager && LDFlags['glcp-locations-tags']
            ? [
                {
                  title: t('assign_tags_optional'),
                  description: t('assign_tags_desc'),
                  childComponents: <AssignTags />,
                  // simulate returning a promise
                  validateForm: (formValues) => ({
                    then: (resolve) => {
                      setRequestBody(formValues)
                      resolve()
                    }
                  })
                }
              ]
            : []),
          ...(!isCCSManager
            ? [
                {
                  title: t('validate_location'),
                  description: t('Validate_loc_selectmsg'),
                  childComponents: <ValidateLocation />,
                  // simulate returning a promise
                  validateForm: (formValues) => ({
                    then: (resolve) => {
                      setRequestBody(formValues)
                      resolve()
                    }
                  })
                }
              ]
            : []),

          {
            title: t('review_create_location'),
            description: '',
            childComponents: (
              <ReviewLocationDetails isCCSManager={isCCSManager} />
            ),
            // simulate returning a promise
            validateForm: () => ({
              then: (resolve) => {
                resolve()
              }
            })
          }
        ]}
        actionOnExit={() => {
          navigate(-1)
        }}
        actionOnSubmit={submitHandler}
        testId="create-location-wizard"
        buttonLabels={{
          finish:
            wizardType === 'create'
              ? t('create_location')
              : t('validate_location')
        }}
      />
      {showErrorMessage}
    </Box>
  )
}

LocationWizard.propTypes = {
  customerId: PropTypes.string,
  isCCSManager: PropTypes.bool,
  wizardType: PropTypes.oneOf(['create', 'validate']).isRequired,
  locationDetailsData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    addresses: PropTypes.array.isRequired,
    contacts: PropTypes.array.isRequired,
    validated: PropTypes.bool,
    validated_at: PropTypes.string,
    validation_expired: PropTypes.bool,
    validation_cycle: PropTypes.string
  }),
  initialTags: PropTypes.array
}

export default LocationWizard
