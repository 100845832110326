// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { StatusCritical } from 'grommet-icons'

import { Notification, Typography } from '../../../components'
import { Widget } from '../components/Widget'

import { NotificationsLogsPage } from './components/NotificationLogsPage'
import { NotificationLoader, RenderNoDataInfo } from './utils'

const Announcements = () => {
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState(null)
  const [count, setCount] = useState({
    INFORMATION: null,
    CRITICAL: null,
    WARNING: null
  })
  const [isLoader, setIsLoader] = useState(true)
  const [showNotificationComponents, setShowNotificationComponents] =
    useState(false)
  const [notificationsExists, setNotificationsExists] = useState(null)
  const [isDayZero, setIsDayZero] = useState(false)

  // when both the response from API and the count of alerts are available, set the loader to false
  useEffect(() => {
    if (notificationsExists !== null && count.CRITICAL !== null) {
      setIsLoader(false)
      if (notificationsExists) {
        setShowNotificationComponents(true)
      } else {
        setIsDayZero(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsExists, count])

  return (
    <>
      <Box direction="column" justifyContent="space-between" flex>
        <Typography level="3" testId="t15" type="heading" size="large">
          {t('notification.alerts_tasks.sub_heading_announcements')}
        </Typography>
        {showNotificationComponents ? (
          <Widget
            text1={t('notification.status_critical')}
            text2={t('notification.status_warning')}
            text3={t('notification.status_informational')}
            cnt1={count.CRITICAL}
            cnt2={count.WARNING}
            cnt3={count.INFORMATION}
            display="Active"
          />
        ) : null}
        {isDayZero && <RenderNoDataInfo t={t} />}
        {isLoader && <NotificationLoader />}
        <Box
          margin={{ top: 'medium' }}
          width="100%"
          direction="column"
          pad={{ right: 'small' }}
        >
          <NotificationsLogsPage
            testId="notifications-datatable"
            setErrorMessage={setErrorMessage}
            type="ANNOUNCEMENTS"
            setCount={setCount}
            showNotificationComponents={showNotificationComponents}
            setNotificationsExists={setNotificationsExists}
            setIsLoader={setIsLoader}
          />
        </Box>
      </Box>
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </>
  )
}

const AnnouncementsPage = () => {
  return <Announcements />
}

export default AnnouncementsPage
