// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Page,
  PageContent,
  Button,
  Grid,
  Heading,
  ResponsiveContext
} from 'grommet'
import { ShareRounded, LinkNext, StatusCritical } from 'grommet-icons'
import {
  useNavigate,
  useLocation,
  Route,
  Routes // ,
  //  Navigate
} from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { keyBy } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { localStorageWhitelistWithoutPII } from '../../../utils/local-storage-utils'
import TogglableRecentServices from '../../components/TogglableRecentServices'
import NewRecentServices from '../../../pages/services/components/NewRecentServices'
import FeaturedServices from '../../components/FeaturedServices'
import NewFeaturedServices from '../../../pages/services/components/NewFeaturedServices'
import { useLocalStorage, useRecentServices, useServices } from '../../hooks'
import {
  Layout,
  VisibilityWrapper,
  RightPanel,
  QuickLinks,
  RecommendedCardData,
  SwitchAccountButton,
  getCustomerAccount,
  autoLaunch,
  doAutoLaunchAppAction,
  isGLOP,
  PostModel
} from '../../shims/imports'
import GettingStarted from '../../components/GettingStarted'
import { AUTHZContextProvider } from '../../../context/authz-context'
import { AppCatalogContextProvider } from '../../../context/app-catalog-context'
import {
  Loader,
  Card,
  Typography,
  Notification,
  Anchor
} from '../../../components'
import {
  PCAI,
  HCIPOC,
  OTHER,
  DEEP_LINKING,
  DISPLAY_RECENT_SERVICES_LENGTH,
  VCF
} from '../../constants'
import GetStartedRouter from '../../../pages/home/get-started/router'
import {
  useVisibilityContext,
  VisibilityActions
} from '../../../context/visibility-context'
import { CheckResourcePermissions } from '../../../utils/ccs-manager-utils'
import { useUPSContext } from '../../../context/ups-context'
import { isServiceCentric } from '../../../utils/account-utils'
import { get } from '../../../utils/api-utils'
import {
  getDataBasedOnSetting,
  isUPSSupported,
  updateUPSData
} from '../../../utils/ups-utils'
import { WidgetsSection } from '../../../pages/data-virtualization/widgets/WidgetsSection'
import { checkGotoChooseAccount } from '../../../utils/common-utils'

const DashboardHomeContent = () => {
  const {
    commonpreferences,
    upsDataResolved,
    upsGetApiCallErrored,
    dispatchUPSContext
  } = useUPSContext()
  const showGettingStarted = getDataBasedOnSetting(
    commonpreferences,
    'showGettingStarted'
  )
  const showWidgets = getDataBasedOnSetting(commonpreferences, 'showWidgets')
  const showGettingStartedUPS = showGettingStarted?.value
  const showWidgetsUPS = showWidgets?.value
  const [showGetStarted, setShowGetStarted] = useLocalStorage(
    localStorageWhitelistWithoutPII.DASHBOARD_GET_STARTED,
    true
  )
  const {
    'glcp-ups-phase-1': glcpUPSPhase1LD,
    'glcp-customize-widgets': glcpCustomizeWidgetsLD,
    'glcp-customize-quick-links': glcpQuickLinksLD
  } = useFlags()
  const [recentServices] = useRecentServices()

  const { t } = useTranslation(['dashboard', 'common', 'services', 'authn'])
  const size = useContext(ResponsiveContext)
  const { services, isLoaded } = useServices()
  const servicesBySlug = keyBy(services, 'serviceSlug')

  const recommendations = RecommendedCardData()

  // auto launch feature
  // URL: /home?app=application_id, if only one app provisioned,
  // should direct launch to app
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const serviceRegistryFlag = LDFlags['glcp-service-registry']
  const enableServiceDeepLinking = LDFlags['glcp-service-slugs-deep-linking']
  const handleLearnCardClick = (link) =>
    link.routeTo ? navigate(link.routeTo) : window.open(link.hrefTo, '_blank')

  const { oidcUser } = useReactOidc()
  const { rbacPolicies, dispatchVisibilityContext } = useVisibilityContext()
  const [errorMessage, setErrorMessage] = useState(
    useLocation()?.state?.errorMessage
  )
  const [redirecting, setRedirecting] = useState(false)
  const query = new URLSearchParams(sessionStorage.getItem('redirect-query'))
  const redirectUrl =
    query && !isEmpty(query.get('redirect_uri'))
      ? query.get('redirect_uri')
      : null
  const app = query && !isEmpty(query.get('app')) ? query.get('app') : null
  const custAccountLoaded = getCustomerAccount()
  const accountType = custAccountLoaded?.account_type || ''
  const setClearAll = () => {
    setErrorMessage(null)
    setRedirecting(false)
  }
  const [showModel, setShowModel] = useState(false)
  const [postItem, setPostItems] = useState([])
  const [showNotification, setNotification] = useState(null)

  const whatsNewData = getDataBasedOnSetting(commonpreferences, 'showWhatsNew')

  useEffect(() => {
    if (LDFlags['glcp-support-access-manager'] && rbacPolicies) {
      const isSupportEngineer = CheckResourcePermissions(
        {
          '/ccs/workspace-access-se': ['ccs.workspace-access-se.view']
        },
        rbacPolicies
      )

      dispatchVisibilityContext({
        type: VisibilityActions.SET_IS_SUPPORT_ENGINEER,
        data: isSupportEngineer
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LDFlags, rbacPolicies])

  useEffect(() => {
    // popup whatsnew modal when enabled LD and not auto launch
    if (
      LDFlags['glcp-whatsnew-istanbul'] &&
      app === null &&
      redirectUrl === null &&
      whatsNewData?.value &&
      upsDataResolved &&
      !redirecting
    ) {
      if (!sessionStorage.getItem('NewPostModel')) {
        get('/whatsnew/v1/dashboard/blogs', {}, oidcUser.access_token).then(
          (response) => {
            if (response?.data) {
              sessionStorage.setItem('NewPostModel', true)
              if (!isEmpty(response.data.blogs)) {
                setPostItems(response.data.blogs)
                setShowModel(true)
              }
            }
          }
        )
      } else {
        setShowModel(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, LDFlags, upsDataResolved])

  useEffect(() => {
    let refreshTimer = null
    const polling = async () => {
      updateUPSData(oidcUser, dispatchUPSContext, LDFlags, true)
    }

    if (upsGetApiCallErrored) {
      refreshTimer = setTimeout(() => {
        polling()
      }, 5000)
    } else {
      clearTimeout(refreshTimer)
    }

    return () => {
      if (refreshTimer) clearTimeout(refreshTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsGetApiCallErrored, upsDataResolved])

  useEffect(() => {
    if (app !== null && !isEmpty(app)) {
      setRedirecting(true)
      // For AT&T special case only,
      // the app is not provisioned/no app instance id/no customer id
      // check if app instance id is in allowed list, if yes, do redirect withOut check provisioned or not
      if (
        !isEmpty(redirectUrl) &&
        LDFlags['glcp-white-list-app-provision']?.appInstanceIDs?.includes(app)
      ) {
        doAutoLaunchAppAction(
          app,
          redirectUrl,
          oidcUser.access_token,
          null,
          accountType,
          setErrorMessage
        )
      } else {
        autoLaunch(
          app,
          redirectUrl,
          oidcUser.access_token,
          accountType,
          setErrorMessage,
          t
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser, LDFlags])

  const dateSort = (a, b) => {
    const date1 = a.utc || new Date(0)
    const date2 = b.utc || new Date(0)
    return Date.parse(date2) - Date.parse(date1)
  }

  const filteredRecentServices = services
    .filter(
      ({
        regions,
        serviceSlug,
        serviceManagerSlug,
        isServiceManager,
        redirectUrl: serviceRedirectUrl,
        featuresSupported
      }) =>
        (regions?.some(
          ({ provisionStatus }) => provisionStatus === 'PROVISIONED'
        ) ||
          (recentServices.find(
            ({ serviceSlug: slug }) => slug === serviceSlug
          ) &&
            typeof serviceRedirectUrl === 'function')) &&
        (isServiceManager ||
          serviceManagerSlug === OTHER ||
          serviceSlug === VCF ||
          serviceSlug === PCAI ||
          (enableServiceDeepLinking && // only show service managers or those services without a service manager
            featuresSupported.includes(DEEP_LINKING)))
    )
    .filter(
      ({ serviceSlug }) =>
        !LDFlags['glcp-service-pcai'] ||
        serviceSlug !== HCIPOC ||
        !LDFlags['glcp-service-vcf']
    )
    .map(({ serviceSlug }) => {
      const localStorageItem = recentServices.find(
        ({ serviceSlug: slug }) => slug === serviceSlug
      )

      const localizedDate = localStorageItem
        ? new Date(localStorageItem.utc)
        : ''

      return { ...servicesBySlug[serviceSlug], utc: localizedDate }
    })
    .sort(dateSort)
    .slice(0, DISPLAY_RECENT_SERVICES_LENGTH)

  const grid = {
    columns: {
      small: ['auto'],
      medium: ['auto'],
      large: ['flex', ['small', 'medium']],
      xlarge: ['flex', 'medium']
    },
    gap: {
      small: 'large',
      medium: 'medium',
      large: 'large',
      xlarge: 'large'
    }
  }

  // whether or not to render the AppCatalogContextProvider
  const showRedirectPage = () => {
    return (
      <Box fill pad="xlarge" justify="center" data-testid="find-accounts">
        <Typography
          margin={{ top: 'large', bottom: 'small' }}
          type="heading"
          level="1"
          testId="find-accounts-title"
        >
          {newDashboardFlag
            ? t('authn:acct_onboarding.redirecting_to_service')
            : t('authn:acct_onboarding.redirecting_to_app')}
        </Typography>
        <Typography type="paragraph" testId="find-accounts-subtitle">
          <Trans i18nKey="authn:acct_onboarding.cancel_redirect" t={t}>
            <Anchor
              label={t('authn:acct_onboarding.click_here')}
              href="/"
              onClick={() => setClearAll()}
              weight="bold"
              testId="click-to-home"
            />
          </Trans>
        </Typography>
      </Box>
    )
  }

  const renderGettingStartedUPS = () => {
    if (upsDataResolved) {
      if (showGettingStartedUPS) {
        return <GettingStarted value={showGettingStartedUPS} />
      }
      return null
    }
    return <GettingStarted toggle={setShowGetStarted} value={showGetStarted} />
  }

  const renderQuickLinks = () => {
    if (glcpQuickLinksLD && glcpUPSPhase1LD && isLoaded) {
      if (!upsGetApiCallErrored && upsDataResolved) {
        return <QuickLinks />
      }
      return (
        <Box align="center" justify="center" alignSelf="center">
          <Loader testId="loader-spinner" />
        </Box>
      )
    }
    return <RightPanel />
  }
  return (
    <Page>
      {showNotification}
      {upsGetApiCallErrored && (
        <Notification
          backgroundColor="status-warning"
          testId="warning-ups-inline-notification"
          text={t('dashboard:common.ups_error_msg')}
          type="inline"
        />
      )}
      <PageContent>
        <>
          {redirecting && !errorMessage ? (
            showRedirectPage()
          ) : (
            <>
              <Box
                justify="end"
                alignContent="center"
                direction="row"
                testId="cust-info-home"
              >
                <VisibilityWrapper
                  hideFor={{
                    deployment: ['COP', 'GLOP'],
                    feature: 'glcp-service-centric-experience-phase-1'
                  }}
                >
                  {!LDFlags['glcp-wc-header'] && <SwitchAccountButton />}
                </VisibilityWrapper>
              </Box>
              {/* TODO: Remove this <Box> block later. Understanding purpose only */}
              {/* <Box>
              <VisibilityWrapper
                hideFor={{
                  connectivity: 'GLOP-CONNECTED',
                  feature: 'glcp-service-centric-experience-phase-1'
                }}
              >
                Fully disconnected/ DARK/ OFFLINE/ AIR GAPPED
              </VisibilityWrapper>
              <VisibilityWrapper
                hideFor={{
                  connectivity: 'GLOP-DISCONNECTED',
                  feature: 'glcp-service-centric-experience-phase-1'
                }}
              >
                Partially connected/ DIM/ ONLINE/ NON-AIR GAPPED
              </VisibilityWrapper>
            </Box> */}
              <Grid
                columns={grid.columns[size]}
                rows="auto"
                gap={grid.gap[size]}
              >
                <Box>
                  {['xsmall', 'small', 'medium'].includes(size) && (
                    <Box margin={{ bottom: 'large' }}>{renderQuickLinks()}</Box>
                  )}
                  {(!glcpUPSPhase1LD || !isUPSSupported()) &&
                    showGetStarted && (
                      <GettingStarted
                        toggle={setShowGetStarted}
                        value={showGetStarted}
                      />
                    )}
                  {glcpUPSPhase1LD && isUPSSupported()
                    ? renderGettingStartedUPS()
                    : null}
                  {isLoaded &&
                    !errorMessage &&
                    !serviceRegistryFlag &&
                    filteredRecentServices.length > 0 && (
                      <>
                        <TogglableRecentServices
                          services={servicesBySlug}
                          glcpLastAccessed={filteredRecentServices}
                        />
                      </>
                    )}
                  {isLoaded && !errorMessage && serviceRegistryFlag && (
                    // remove glcpLastAccessed property
                    // when last accessed available in /v1alpha1/my-services api
                    <NewRecentServices />
                  )}
                  {isLoaded &&
                    glcpUPSPhase1LD &&
                    glcpCustomizeWidgetsLD &&
                    showWidgetsUPS &&
                    !upsGetApiCallErrored && (
                      <WidgetsSection setNotification={setNotification} />
                    )}
                  {!isGLOP() && isLoaded ? ( // eslint-disable-line no-nested-ternary
                    serviceRegistryFlag ? (
                      <NewFeaturedServices t={t} />
                    ) : (
                      <FeaturedServices
                        services={services}
                        t={t}
                        glcpLastAccessed={filteredRecentServices}
                      />
                    )
                  ) : (
                    !isGLOP() && (
                      <Box align="center" justify="center" alignSelf="center">
                        <Loader testId="loader-spinner" />
                      </Box>
                    )
                  )}
                </Box>
                <Box gap="large">
                  {['large', 'xlarge'].includes(size) && (
                    <Box>{renderQuickLinks()}</Box>
                  )}
                  {!isGLOP() && (
                    <Box>
                      <Heading level={2}>{t('dashboard:common.learn')}</Heading>
                      <Box gap="medium">
                        {recommendations?.map(
                          (item) =>
                            !item.hidden && (
                              <VisibilityWrapper
                                key={item.id}
                                hideFor={item.hideFor}
                                rbac={item.rbac}
                              >
                                <Card
                                  rbac={item.rbac}
                                  margin={{ top: 'none' }}
                                  align="start"
                                  direction="row"
                                  action={
                                    <Button
                                      a11yTitle={t(`common:${item.title}`)}
                                      icon={
                                        item.hrefTo ? (
                                          <ShareRounded color="brand" />
                                        ) : (
                                          <LinkNext color="brand" />
                                        )
                                      }
                                      plain
                                      onClick={() => handleLearnCardClick(item)}
                                    />
                                  }
                                  description={t(`common:${item.description}`)}
                                  testId={item.testId}
                                  title={
                                    <Typography type="heading" level={3}>
                                      {t(`common:${item.title}`)}
                                    </Typography>
                                  }
                                />
                              </VisibilityWrapper>
                            )
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
                <VisibilityWrapper
                  hideFor={{ feature: 'glcp-whatsnew-istanbul' }}
                >
                  {showModel && (
                    <PostModel
                      Posts={postItem}
                      closeModel={() => setShowModel(false)}
                    />
                  )}
                </VisibilityWrapper>
              </Grid>
            </>
          )}
          {errorMessage ? (
            <Notification
              backgroundColor="status-critical"
              icon={<StatusCritical color="text-strong" />}
              onClose={() => setClearAll()}
              position="top"
              testId="error-notification"
              text={errorMessage}
            />
          ) : null}
        </>
      </PageContent>
    </Page>
  )
}

const DashboardHome = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <AUTHZContextProvider>
      <AppCatalogContextProvider>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Layout>
                <DashboardHomeContent />
              </Layout>
            }
          />
          <Route path="/get-started" element={<GetStartedRouter />} />
        </Routes>
      </AppCatalogContextProvider>
    </AUTHZContextProvider>
  )
}

export default DashboardHome
