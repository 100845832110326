// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Anchor, Box, Page, PageContent, PageHeader, Text } from 'grommet'
import {
  // CircleInformation,
  // CircleQuestion,
  Previous,
  FormDown,
  StatusGoodSmall,
  StatusUnknownSmall
} from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useEffect, useState } from 'react'

import {
  WKSPC_CAPITALIZED,
  // getPaginationShowIdx,
  getWorkspaceString
} from '../../../utils/common-utils'
import {
  // DataTable,
  // Loader,
  // NoDataInfo,
  // Tooltip,
  Typography,
  ActionButton
} from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { get, post } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { EnableDisableZtoModal } from './components/EnableDisableZtoModal'

const ZeroTouchOnboardingContent = () => {
  const LDFlags = useFlags()
  const { oidcUser } = useReactOidc()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const { t } = useTranslation(['manage', 'common'])
  const navigate = useNavigate()
  // TODO: Remove commented code to display aliases on UI
  //
  // const [itemsPerPage, setItemsPerPage] = useState(20)
  // const [totalItems, setTotalItems] = useState(itemsPerPage)
  // const [page, setPage] = useState(1)
  // const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)
  const handleBackBtnClick = () => {
    navigate('/manage-account')
  }
  // const [searchVal, setSearchVal] = useState('')
  // const [aliases, setAliases] = useState([])
  // const [showNoDataInfo, setShowNoDataInfo] = useState(false)
  // const rowCountOptions = [10, 20, 50]
  // const [isLoading, setIsLoading] = useState(true)
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  // const columns = [
  //   {
  //     property: 'alias',
  //     type: 'string',
  //     header: (
  //       <Typography
  //         type="text"
  //         weight={500}
  //         color="dark-1"
  //         icon={
  //           <Tooltip
  //             testId="alias-tip"
  //             info={
  //               <Box width="small">
  //                 <Typography type="text" testId="alias-info">
  //                   {t('zto.alias_info')}
  //                 </Typography>
  //               </Box>
  //             }
  //           >
  //             <CircleQuestion color="black" />
  //           </Tooltip>
  //         }
  //         reverse
  //       >
  //         {t('aliases.alias_name')}
  //       </Typography>
  //     ),
  //     primary: true
  //   },
  //   {
  //     property: 'type',
  //     type: 'string',
  //     header: t('aliases.type')
  //   }
  // ]
  // useEffect(() => {
  //   setShowNoDataInfo(false)
  //   const request = {
  //     limit: itemsPerPage,
  //     offset: (page - 1) * itemsPerPage,
  //     ...(searchVal.trimStart().length > 0 && {
  //       search_string: searchVal.trimStart()
  //     })
  //   }
  //   let isCurrent = true
  //   get('/ui-doorway/ui/v1/customer-aliases', request, oidcUser.access_token)
  //     .then(
  //       (resp) => {
  //         if (!isCurrent) return
  //         if (!resp?.data?.total && !searchVal) setShowNoDataInfo(true)
  //         setAliases(resp?.data?.items)
  //         setTotalItems(resp?.data?.total)
  //       },
  //       (error) => {
  //         setShowErrorNotification(
  //           displayAPIError(error, t, setShowErrorNotification)
  //         )
  //         setShowNoDataInfo(true)
  //       }
  //     )
  //     .finally(() => {
  //       setIsLoading(false)
  //     })
  //   return () => {
  //     isCurrent = false
  //   }
  // }, [searchVal, oidcUser.access_token, page, itemsPerPage, t])
  // const getContent = () => {
  //   let content = (
  //     <NoDataInfo
  //       icon={<CircleInformation size="large" color="black" />}
  //       title={t('zto.no_data_info')}
  //       testId="empty-zto-info"
  //     />
  //   )
  //   if (!showNoDataInfo) {
  //     content = isLoading ? (
  //       <Box direction="row" align="center" justify="center">
  //         <Loader testId="devices-loader" />
  //       </Box>
  //     ) : (
  //       <DataTable
  //         grid={{
  //           columns,
  //           data: aliases
  //         }}
  //         pagination={{
  //           totalItems,
  //           itemsPerPage,
  //           setItemsPerPage,
  //           page,
  //           setPage,
  //           pageIdxInfo,
  //           rowCountOptions
  //         }}
  //         search={{
  //           onSearchValueChange: (value) => {
  //             setSearchVal(value)
  //           },
  //           placeholder: t('aliases.search_placeholder')
  //         }}
  //         summary={{
  //           entityName: t('aliases.summary')
  //         }}
  //         testId="zto-aliases-data-table"
  //       />
  //     )
  //   }
  //   return content
  // }
  const [showEnableDisableZtoModal, setShowEnableDisableZtoModal] =
    useState(false)

  const [ztoState, setZtoState] = useState(true)
  const [ztoId, setZtoId] = useState(' ')
  useEffect(() => {
    let isCurrent = true
    get('/ui-doorway/ui/v1/zto-settings', null, oidcUser.access_token)
      .then((resp) => {
        if (!isCurrent) return
        if (!resp?.data?.items[0]?.id) {
          post(
            '/ui-doorway/ui/v1/zto-settings',
            { enabled: true },
            oidcUser.access_token
          )
            .then((postResp) => {
              setZtoId(postResp?.data?.id)
            })
            .catch((postError) => {
              setShowErrorNotification(
                displayApiError(postError, t, setShowErrorNotification)
              )
            })
        } else {
          setZtoId(resp?.data?.items[0]?.id)
          setZtoState(resp?.data?.items[0]?.enabled)
        }
      })
      .catch((error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      })

    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, t])
  return (
    <Page>
      <PageContent>
        <PageHeader
          title={t('common:manage_account.zto_title')}
          subtitle={
            <>
              <Text size="large" data-testid="zto-subtitle">
                <Trans i18nKey="zto.subtitle_1" t={t} />
                <Anchor
                  data-testid="contextual-help-link"
                  label={t('zto.subtitle_2')}
                  onClick={() => dispatchEvent(new Event('context-help'))}
                />
              </Text>
            </>
          }
          parent={
            <Anchor
              label={t('common:manage_account_label', {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
              onClick={handleBackBtnClick}
              icon={<Previous />}
              data-testid="manage-wkspc-btn"
            />
          }
          responsive
          data-testid="zto-page-header"
        />
        {
          /* totalItems > 0 && !isLoading && */ <Box
            direction="row"
            align="center"
          >
            <Box direction="row" align="center" margin={{ right: 'xlarge' }}>
              <Typography
                type="heading"
                level="3"
                margin={{ right: 'xlarge' }}
                testId="zto-state"
              >
                {t('manage:zto.zto_state')}
              </Typography>
              {ztoState && (
                <>
                  <StatusGoodSmall color="status-ok" size="small" />
                  <Typography
                    type="text"
                    level="3"
                    margin={{ right: 'xlarge', left: 'xsmall' }}
                    testId="zto-enabled"
                  >
                    {t('manage:zto.zto_enabled')}
                  </Typography>
                </>
              )}
              {!ztoState && (
                <>
                  <StatusUnknownSmall color="status-unknown" size="small" />
                  <Typography
                    type="text"
                    level="3"
                    margin={{ right: 'xlarge', left: 'xsmall' }}
                    testId="zto-disabled"
                  >
                    {t('manage:zto.zto_disabled')}
                  </Typography>
                </>
              )}
            </Box>
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/device-management/zto',
                permission: 'ccs.device-subscription-zto.edit'
              }}
            >
              <ActionButton
                label={t('common:actions')}
                icon={<FormDown />}
                reverse
                alignSelf="center"
                margin={{ left: 'xlarge' }}
                dropAlign={{ top: 'bottom', right: 'right' }}
                actions={[
                  {
                    label: t('manage:zto.disable_zto'),
                    hidden: !ztoState,
                    onClick: () => {
                      setShowEnableDisableZtoModal(true)
                    },
                    testId: 'disable-zto'
                  },
                  {
                    label: t('manage:zto.enable_zto'),
                    hidden: ztoState,
                    onClick: () => {
                      setShowEnableDisableZtoModal(true)
                    },
                    testId: 'enable-zto'
                  }
                ]}
                testId="actions-btn"
              />
            </VisibilityWrapper>
          </Box>
        }
        {showEnableDisableZtoModal && (
          <EnableDisableZtoModal
            onSuccess={(status) => {
              setZtoState(status)
            }}
            onSetOpen={setShowEnableDisableZtoModal}
            ztoState={ztoState}
            ztoId={ztoId}
          />
        )}

        {/* {getContent()} */}
      </PageContent>
      {showErrorNotification}
    </Page>
  )
}

const ZeroTouchOnboarding = () => (
  <Layout>
    <ZeroTouchOnboardingContent />
  </Layout>
)

export default ZeroTouchOnboarding
