// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useRef } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ChatMessage from './ChatMessage'

const CustomScrollBox = styled(Box)`
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 4px;
  ${({ isFirefox }) => isFirefox && { 'scrollbar-width': 'thin' }};

  ::-webkit-scrollbar {
    width: 8px;
    height: 116px;
    color: #cccccc;
  }
  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 90px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`

const ChatBody = ({ chat, client }) => {
  const chatBodyRef = useRef()
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  return (
    <CustomScrollBox
      isFirefox={isFirefox}
      pad={{ top: 'small', right: 'xsmall', bottom: 'small', left: 'small' }}
      align="start"
      data-testid="chat-message"
      ref={chatBodyRef}
    >
      {chat?.map((chatResponse) => (
        <ChatMessage
          chatResponse={chatResponse}
          client={client}
          key={chatResponse?.id}
          chatBodyRef={chatBodyRef}
        />
      ))}
      <Box ref={chatBodyRef} />
    </CustomScrollBox>
  )
}

export default ChatBody

ChatBody.propTypes = {
  chat: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired
}
