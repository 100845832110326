// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Navigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { AppCatalogContextProvider } from '../context/app-catalog-context'
import NewMyServices from '../pages/services/my-services/NewMyServices'
import NewServiceCatalog from '../pages/services/service-catalog/NewServiceCatalog'
import NewServiceDetail from '../pages/services/service-catalog/service-detail/NewServiceDetail'
import AddServiceSubscriptionWizard from '../pages/manage-account/subscriptions/pages/service-subscriptions/components/AddServiceSubscriptionWizard'
import ServiceSubscriptionDetailsPage from '../pages/manage-account/subscriptions/pages/service-subscriptions/components/ServiceSubscriptionDetails'

import { Route, Routes } from './shims/imports'
import ServiceCatalog from './pages/services/service-catalog'
import ServiceDetail from './pages/services/service-catalog/[service]'
import MyServices from './pages/services/my-services'
import ServiceSubscriptions from './pages/services/service-subscriptions'
import PrivateCloud from './pages/services/service-catalog/private-cloud'

const ServiceCatalogRouterContent = () => {
  const serviceRegistryFlag = useFlags()['glcp-service-registry']
  return (
    <Routes>
      <Route
        exact
        path="/*"
        element={<Navigate to="/services/my-services" />}
      />
      <Route
        exact
        path="/my-apps"
        element={<Navigate to="/services/my-services" />}
      />
      <Route
        exact
        path="/available-apps"
        element={<Navigate to="/services/service-catalog" />}
      />
      <Route
        exact
        path="/my-services"
        element={serviceRegistryFlag ? <NewMyServices /> : <MyServices />}
      />
      <Route
        exact
        path="/service-catalog"
        element={
          serviceRegistryFlag ? <NewServiceCatalog /> : <ServiceCatalog />
        }
      />
      <Route
        exact
        path="/service-catalog/private-cloud"
        element={<PrivateCloud />}
      />
      <Route
        exact
        path="/installed-apps/:id"
        element={<Navigate to="/services/my-services" />}
      />
      <Route
        exact
        path="/app-details/:id"
        element={<Navigate to="/services/service-catalog" />}
      />
      <Route
        exact
        path={`/service-catalog/:${serviceRegistryFlag ? 'id' : 'service'}`}
        element={serviceRegistryFlag ? <NewServiceDetail /> : <ServiceDetail />}
      />
      <Route
        exact
        path={`/service-catalog/:${
          serviceRegistryFlag ? 'id' : 'service'
        }/:fromServiceSubs`}
        element={serviceRegistryFlag ? <NewServiceDetail /> : <ServiceDetail />}
      />
      <Route
        exact
        path="/service-subscriptions/:subscriptionKey"
        element={<ServiceSubscriptionDetailsPage />}
      />
      <Route
        exact
        path="/service-subscriptions"
        element={<ServiceSubscriptions />}
      />
      <Route
        exact
        path="/add-service-subscriptions"
        element={<AddServiceSubscriptionWizard />}
      />
      {/* Navigate to not-found is added because, if any users tries to enter an invalid URL for services, like /services/my-services/123 it would show not found page instead of blank page. */}
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

const ServiceCatalogRouter = () => {
  return (
    <AppCatalogContextProvider>
      <ServiceCatalogRouterContent />
    </AppCatalogContextProvider>
  )
}

export { ServiceCatalogRouter }
