// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react'
import { FormDown, FormUp } from 'grommet-icons'
import PropTypes from 'prop-types'
import { Box, Tag } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../components'
import { NO_VALUE } from '../utils'

const TruncateButton = ({
  datum,
  setShowAllServices = () => {},
  type = ''
}) => {
  const { t } = useTranslation('common')
  const [showAll, setShowAll] = useState(false)
  const MAX_LIST_LENGTH = 3
  const TRUNC_LIST_LENGTH = 3
  const navigate = useNavigate()
  return (
    <>
      {datum?.tags?.length ? (
        <Box margin={{ top: 'xxsmall' }} direction="row" gap="xxsmall" wrap>
          {datum?.tags
            .slice(
              0,
              showAll || datum?.tags?.length < MAX_LIST_LENGTH
                ? datum?.tags?.length
                : TRUNC_LIST_LENGTH
            )
            .map((item, idx) => {
              return (
                <Box
                  margin={{ bottom: 'xsmall' }}
                  key={`pill-${idx}`} /* eslint-disable-line react/no-array-index-key */
                  direction="column"
                >
                  <Tag
                    size="xsmall"
                    name={item.name}
                    alignSelf="start"
                    value={item.value}
                    data-testid={`${item.name}-${idx}`}
                  />
                </Box>
              )
            })}
        </Box>
      ) : (
        NO_VALUE
      )}
      {datum?.tags?.length >= MAX_LIST_LENGTH && (
        <Button
          key={datum?.subscription_key}
          alignSelf="start"
          size="small"
          label={!showAll ? t('view_all') : t('view_less')}
          testId={`truncate-btn-${datum?.subscription_key}`}
          onClick={(event) => {
            event.stopPropagation()
            switch (type) {
              case 'DeviceInventory':
                {
                  const {
                    serial_number: serialNumber,
                    part_number: partNumber,
                    device_type: deviceType
                  } = datum
                  navigate(
                    `/devices/inventory/${serialNumber}:${partNumber}:${deviceType}`
                  )
                }
                break
              case 'DeviceLicense':
                navigate(`/devices/subscriptions/${datum.key}`)
                break
              case 'ServiceLicense':
                navigate(
                  `/services/service-subscriptions/${datum?.subscription_key}`
                )
                break
              default:
                setShowAll(!showAll)
                setShowAllServices(!showAll)
            }
          }}
          icon={!showAll ? <FormDown /> : <FormUp />}
          reverse
        />
      )}
    </>
  )
}
TruncateButton.propTypes = {
  datum: PropTypes.array.isRequired,
  setShowAllServices: PropTypes.func,
  type: PropTypes.string
}
export default TruncateButton
