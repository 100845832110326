// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { Box, PageHeader } from 'grommet'
import { useTranslation } from 'react-i18next'
import { StatusCritical } from 'grommet-icons'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  Button,
  Dropdown,
  Typography,
  Notification,
  Loader
} from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { ClaimDomainModal } from '../saml-sso/components/ClaimDomainModal'
import { AcknowledgeDomainModal } from '../saml-sso/components/AcknowledgeDomainModal'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { get, post, put, getErrorMessage } from '../../../../utils/api-utils'
import { isCoP, isGLOP } from '../../../../utils/feature-flag-utils'
import { useVisibilityContext } from '../../../../context/visibility-context'

import ConfigureRadius from './configure-authmethods/ConfigureRadius'
import ConfigureRadsec from './configure-authmethods/ConfigureRadsec'
import ConfigureSaml from './configure-authmethods/ConfigureSaml'
import ProtocolchangeModal from './ProtocolchangeModal'
import RadsecProtocolChangeModal from './RadsecProtocolChangeModal'

const ConfigureAuthentication = () => {
  const [editMode, setEditMode] = useState(!isCoP() && !isGLOP())
  const { t } = useTranslation(['authn', 'common'])
  const [notifyComponent, setNotifyComponent] = useState(null)
  const [showAuthProtocolChangeModal, setShowAuthProtocolChangeModal] =
    useState(false)
  const [showRadsecProtocolChangeModal, setShowRadsecProtocolChangeModal] =
    useState(false)
  const [data, setData] = useState([])
  const { oidcUser } = useReactOidc()
  const [radiusServerDetails, setRadiusServerDetails] = useState([])
  const [onLoadRadiusServerDetails, setOnLoadRadiusServerDetails] = useState([])
  const [isRadiusConfigured, setIsRadiusConfigured] = useState(false)

  const [radsecServerDetails, setRadsecServerDetails] = useState([])
  const [onLoadRadsecServerDetails, setOnLoadRadsecServerDetails] = useState([])
  const [isRadsecConfigured, setIsRadsecConfigured] = useState(false)
  const [showRadsecWarningForCertUpload, setShowRadsecWarningForCertUpload] =
    useState(false)
  const [selectedAuthMethod, setSelectedAuthMethod] = useState('')
  const [addServerModal, setAddServerModal] = useState(false)
  const [onLoadAuthMethod, setOnLoadAuthMethod] = useState('')
  const [notifyUserDomain, setNotifyUserDomain] = useState()
  const [notifyUsersModal, setNotifyUsersModal] = useState(false)
  const [claimDomainModal, setClaimDomainModal] = useState(false)
  const [ackDomainModal, setAckDomainModal] = useState(false)
  const [domain, setDomain] = useState('')
  const [claimedAccountsCount, setClaimedAccountsCount] = useState()
  const [smartCardChecked, setSmartCardChecked] = useState(false)
  const [isSmartCardEdited, isSetSmartCardEdited] = useState(false)

  const [showSaveErrorNotification, setShowSaveErrorNotification] =
    useState(false)
  const [saveErrorNotificationMessage, setSaveErrorNotificationMessage] =
    useState('')

  const navigate = useNavigate()
  const { rbacPolicies } = useVisibilityContext()

  const selectedMethodContentMap = {
    localdb: {
      title: t('manage_account.localdb.title'),
      description: t('manage_account.localdb.description'),
      configuration: false
    },
    saml: {
      title: t('manage_account.sso.title'),
      description: t('manage_account.sso.description'),
      configuration: true
    },
    radius: {
      title: t('manage_account.radius.title'),
      description: t('manage_account.radius.description'),
      configuration: true
    },
    radsec: {
      title: t('manage_account.radsec.title'),
      description: t('manage_account.radsec.description'),
      configuration: true
    }
  }

  const authMethodValues = {
    saml: 'saml',
    radius: 'radius',
    localdb: 'localdb',
    radsec: 'radsec'
  }

  const authMethodDropdownValMap = {
    saml: t('manage_account.sso.title'),
    radius: t('manage_account.radius.title'),
    localdb: t('manage_account.localdb.title'),
    radsec: t('manage_account.radsec.dropdownTitle')
  }

  const getAuthMethodFromKeyMap = {
    [t('manage_account.sso.title')]: 'saml',
    [t('manage_account.radius.title')]: 'radius',
    [t('manage_account.localdb.title')]: 'localdb',
    [t('manage_account.radsec.dropdownTitle')]: 'radsec'
  }

  const getAllSAML = useCallback(() => {
    get('/authn/v1/saml/config', {}, oidcUser.access_token)
      .then((response) => {
        const tableData = response.data.domains.map((d) => {
          return {
            ...d,
            created_at: dayjs(d.created_at).format('MMMM DD, YYYY')
          }
        })
        setData(tableData)

        const queryParams = new URLSearchParams(window.location.search)
        const notifydomain = queryParams.get('notifyUser')
        if (notifydomain) {
          setNotifyUsersModal(true)
          setNotifyUserDomain(notifydomain)
        }
      })
      .catch((error) => {
        setData([])
        const backendErrorMessage = getErrorMessage(error, t)
        setSaveErrorNotificationMessage(backendErrorMessage)
      })
  }, [oidcUser.access_token, t])

  const getRadiusConfig = useCallback(() => {
    get('/authn/v1/radius/config', {}, oidcUser.access_token)
      .then((response) => {
        const respData = response.data
        if (!respData || !respData.primary_server_hostname) {
          setRadiusServerDetails([])
          setOnLoadRadiusServerDetails([])
          return
        }

        if (respData.primary_server_hostname) {
          setIsRadiusConfigured(true)
        } else {
          setIsRadiusConfigured(false)
        }

        if (respData.smartcard) {
          setSmartCardChecked(respData.smartcard)
        }

        const serverDetail = [
          {
            ip: respData.primary_server_hostname,
            port: respData.primary_server_port,
            authentication: respData.primary_server_auth_protocol,
            serverSecret: respData.primary_server_shared_secret,
            confirmServerSecret: respData.primary_server_shared_secret,
            add_message_authenticator: respData.add_message_authenticator
          }
        ]

        if (respData.is_secondary_server_configured) {
          serverDetail.push({
            ip: respData.secondary_server_hostname,
            port: respData.secondary_server_port,
            authentication:
              respData.secondary_server_auth_protocol ||
              respData.primary_server_auth_protocol,
            serverSecret: respData.secondary_server_shared_secret,
            confirmServerSecret: respData.secondary_server_shared_secret
          })
        }

        setRadiusServerDetails(serverDetail)
        setOnLoadRadiusServerDetails(serverDetail)
      })
      .catch((error) => {
        setRadiusServerDetails([])
        setOnLoadRadiusServerDetails([])
        const backendErrorMessage = getErrorMessage(error, t)
        setSaveErrorNotificationMessage(backendErrorMessage)
      })
  }, [oidcUser.access_token, t])

  const getRadsecConfig = useCallback(() => {
    get('/authn/v1/radsec/config', {}, oidcUser.access_token)
      .then((response) => {
        const respData = response.data
        if (!respData || !respData.primary_server_hostname) {
          setRadsecServerDetails([])
          setOnLoadRadsecServerDetails([])
          return
        }

        if (respData.primary_server_hostname) {
          setIsRadsecConfigured(true)
        } else {
          setIsRadsecConfigured(false)
        }

        const serverDetail = [
          {
            ip: respData.primary_server_hostname,
            port: respData.primary_server_port,
            authentication: respData.primary_server_auth_protocol,
            serverSecret: respData.primary_server_shared_secret,
            confirmServerSecret: respData.primary_server_shared_secret
          }
        ]

        if (respData.is_secondary_server_configured) {
          serverDetail.push({
            ip: respData.secondary_server_hostname,
            port: respData.secondary_server_port,
            authentication:
              respData.secondary_server_auth_protocol ||
              respData.primary_server_auth_protocol,
            serverSecret: respData.secondary_server_shared_secret,
            confirmServerSecret: respData.secondary_server_shared_secret
          })
        }

        setRadsecServerDetails(serverDetail)
        setOnLoadRadsecServerDetails(serverDetail)
      })
      .catch((error) => {
        setRadsecServerDetails([])
        setOnLoadRadsecServerDetails([])
        const backendErrorMessage = getErrorMessage(error, t)
        setSaveErrorNotificationMessage(backendErrorMessage)
      })
  }, [oidcUser.access_token, t])

  const checkCertificateValidity = useCallback(() => {
    get('/authn/v1/radsec/verify/certificates', {}, oidcUser.access_token)
      .then((response) => {
        setShowRadsecWarningForCertUpload(response?.status === '200') // NOSONAR
      })
      .catch((error) => {
        const errorComp = displayApiError(error, t, setNotifyComponent)
        setNotifyComponent(errorComp)
        setShowRadsecWarningForCertUpload(true)
      })
  }, [oidcUser.access_token, t])

  useEffect(() => {
    if (isCoP()) {
      get('/authn/v1/external-auth/method', {}, oidcUser.access_token)
        .then((response) => {
          if (response && response.data) {
            setSelectedAuthMethod(
              response.data.method || authMethodValues.localdb
            )
            setOnLoadAuthMethod(
              response.data.method || authMethodValues.localdb
            )

            if (response.data.method === authMethodValues.radsec) {
              checkCertificateValidity()
            }
          }
        })
        .catch((error) => {
          setSelectedAuthMethod(authMethodValues.localdb)
          setOnLoadAuthMethod(authMethodValues.localdb)
          const backendErrorMessage = getErrorMessage(error, t)
          setSaveErrorNotificationMessage(backendErrorMessage)
        })
    } else {
      setSelectedAuthMethod(authMethodValues.saml)
      setOnLoadAuthMethod(authMethodValues.saml)
    }
  }, [
    oidcUser.access_token,
    t,
    setSelectedAuthMethod,
    checkCertificateValidity,
    authMethodValues.localdb,
    authMethodValues.saml,
    authMethodValues.radsec
  ])

  useEffect(() => {
    if (selectedAuthMethod === authMethodValues.saml) {
      getAllSAML()
    } else if (selectedAuthMethod === authMethodValues.radius) {
      getRadiusConfig()
    } else if (selectedAuthMethod === authMethodValues.radsec) {
      getRadsecConfig()
    }
  }, [
    getAllSAML,
    getRadiusConfig,
    getRadsecConfig,
    selectedAuthMethod,
    authMethodValues.saml,
    authMethodValues.radius,
    authMethodValues.radsec
  ])

  const getServerDetails = (details) => {
    setRadiusServerDetails(details)
    setEditMode(true)
  }

  const getRadsecServerDetails = (details) => {
    setRadsecServerDetails(details)
    setEditMode(true)
  }

  const updateAuthenticationMethodApi = (cbData, setEditModeFn) => {
    if (isCoP() || isGLOP()) {
      post(
        '/authn/v1/external-auth/method',
        { method: selectedAuthMethod },
        oidcUser.access_token
      )
        .then(() => {
          cbData.cb(cbData.val)
          setEditModeFn(false)
          if (onLoadAuthMethod !== selectedAuthMethod) {
            const notification = displayNotification(
              t('manage_account.authentication.saved_changes'),
              'info',
              setNotifyComponent
            )
            setNotifyComponent(notification)
          }
          setOnLoadAuthMethod(selectedAuthMethod)
        })
        .catch((error) => {
          const errorComp = displayApiError(error, t, setNotifyComponent)
          setNotifyComponent(errorComp)
        })
    } else {
      cbData.cb(cbData.val)
    }
  }

  const isServerDetailModified = (apiServerDetails, currentServerDetails) => {
    let flag = false
    if (apiServerDetails.length !== currentServerDetails.length) {
      return true
    }

    apiServerDetails.every((apiServerDetail, index) => {
      const apiKeys = Object.keys(apiServerDetail)
      apiKeys.every((apiKey) => {
        flag = Boolean(
          apiServerDetail[apiKey] !== currentServerDetails[index][apiKey]
        )
        return !flag
      })

      return !flag
    })

    return flag
  }

  const checkauthMethodsaml = (cbData, setEditModeFn) => {
    if (isCoP) {
      if (onLoadAuthMethod !== selectedAuthMethod) {
        updateAuthenticationMethodApi(cbData, setEditModeFn)
      } else {
        cbData.cb(cbData.val)
      }
    }
    if (isGLOP) {
      updateAuthenticationMethodApi(cbData, setEditModeFn)
    }
  }

  const saveAuthenticationProtocol = (cbData, setEditModeFn) => {
    switch (selectedAuthMethod) {
      case authMethodValues.saml: {
        checkauthMethodsaml(cbData, setEditModeFn)
        break
      }

      case authMethodValues.radius: {
        const isServerDetailModifiedFlag = isServerDetailModified(
          onLoadRadiusServerDetails,
          radiusServerDetails
        )
        if (isServerDetailModifiedFlag || isSmartCardEdited) {
          const isSecondaryPortConfigured = radiusServerDetails.length > 1
          const body = {
            primary_server_hostname: radiusServerDetails[0].ip,
            primary_server_port: parseInt(radiusServerDetails[0].port, 10),
            primary_server_auth_protocol: radiusServerDetails[0].authentication,
            primary_server_shared_secret: radiusServerDetails[0].serverSecret,
            secondary_server_hostname: isSecondaryPortConfigured
              ? radiusServerDetails[1].ip
              : '',
            secondary_server_port: isSecondaryPortConfigured
              ? parseInt(radiusServerDetails[1].port, 10)
              : null,
            secondary_server_shared_secret: isSecondaryPortConfigured
              ? radiusServerDetails[1].serverSecret
              : '',
            is_secondary_server_configured: isSecondaryPortConfigured,
            smartcard: smartCardChecked,
            add_message_authenticator:
              radiusServerDetails[0].add_message_authenticator
          }

          const apiCallType = isRadiusConfigured ? put : post

          apiCallType('/authn/v1/radius/config', body, oidcUser.access_token)
            .then(() => {
              cbData.cb(cbData.val)
              setOnLoadRadiusServerDetails(radiusServerDetails)
              if (radiusServerDetails[0].ip) {
                setIsRadiusConfigured(true)
              } else {
                setIsRadiusConfigured(false)
              }
              if (
                onLoadAuthMethod !== selectedAuthMethod ||
                isSmartCardEdited
              ) {
                updateAuthenticationMethodApi(cbData, setEditModeFn)
              } else {
                setEditModeFn(false)
                const notification = displayNotification(
                  t('manage_account.authentication.saved_changes'),
                  'info',
                  setNotifyComponent
                )
                setNotifyComponent(notification)
              }
            })
            .catch((error) => {
              const errorComp = displayApiError(error, t, setNotifyComponent)
              setNotifyComponent(errorComp)
            })
          return
        }

        if (onLoadAuthMethod !== selectedAuthMethod) {
          updateAuthenticationMethodApi(cbData, setEditModeFn)
        } else {
          setEditModeFn(false)
        }
        break
      }

      case authMethodValues.radsec: {
        const isServerDetailModifiedFlag = isServerDetailModified(
          onLoadRadsecServerDetails,
          radsecServerDetails
        )
        if (isServerDetailModifiedFlag) {
          const isSecondaryPortConfigured = radsecServerDetails.length > 1
          const body = {
            is_radsec: true,
            primary_server_hostname: radsecServerDetails[0].ip,
            primary_server_port: parseInt(radsecServerDetails[0].port, 10),
            primary_server_auth_protocol: radsecServerDetails[0].authentication,
            primary_server_shared_secret: radsecServerDetails[0].serverSecret,
            secondary_server_hostname: isSecondaryPortConfigured
              ? radsecServerDetails[1].ip
              : '',
            secondary_server_port: isSecondaryPortConfigured
              ? parseInt(radsecServerDetails[1].port, 10)
              : null,
            secondary_server_shared_secret: isSecondaryPortConfigured
              ? radsecServerDetails[1].serverSecret
              : '',
            is_secondary_server_configured: isSecondaryPortConfigured
          }

          const apiCallType = isRadsecConfigured ? put : post

          apiCallType('/authn/v1/radsec/config', body, oidcUser.access_token)
            .then(() => {
              cbData.cb(cbData.val)
              setOnLoadRadsecServerDetails(radsecServerDetails)
              if (radsecServerDetails[0].ip) {
                setIsRadsecConfigured(true)
              } else {
                setIsRadsecConfigured(false)
              }
              if (
                onLoadAuthMethod !== selectedAuthMethod ||
                isSmartCardEdited
              ) {
                updateAuthenticationMethodApi(cbData, setEditModeFn)
              } else {
                setEditModeFn(false)
                const notification = displayNotification(
                  t('manage_account.authentication.saved_changes'),
                  'info',
                  setNotifyComponent
                )
                setNotifyComponent(notification)
              }

              checkCertificateValidity()
            })
            .catch((error) => {
              const errorComp = displayApiError(error, t, setNotifyComponent)
              setNotifyComponent(errorComp)
            })
          return
        }

        if (onLoadAuthMethod !== selectedAuthMethod) {
          updateAuthenticationMethodApi(cbData, setEditModeFn)
        } else {
          setEditModeFn(false)
        }

        break
      }

      case authMethodValues.localdb: {
        if (onLoadAuthMethod !== selectedAuthMethod) {
          updateAuthenticationMethodApi(cbData, setEditModeFn)
        }
        break
      }

      default: {
        cbData.cb(cbData.val)
        break
      }
    }
  }

  const closeNotifyUsersModalCb = () => {
    setNotifyUsersModal(false)
    const href = new URL(window.location.href)
    href.searchParams.delete('notifyUser')
    navigate(href.pathname)
  }

  const renderSelectedMethodContent = (authmethod) => {
    return (
      <>
        <PageHeader
          responsive
          title={selectedMethodContentMap[authmethod].title}
          subtitle={selectedMethodContentMap[authmethod].description}
          actions={
            <>
              {authmethod === authMethodValues.radius &&
              radiusServerDetails.length === 1 &&
              editMode ? (
                <Box direction="row" justify="end">
                  <Button
                    size="small"
                    secondary
                    label={t('manage_account.radius.setup_radius_server')}
                    testId="setup-radius-btn"
                    onClick={() => {
                      setAddServerModal(true)
                    }}
                  />
                </Box>
              ) : null}

              {authmethod === authMethodValues.saml &&
              data.length > 0 &&
              editMode ? (
                <Box direction="row" justify="end">
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/accounts/platform/customer/saml',
                      permission: 'ccs.accounts.platform.customer.edit'
                    }}
                  >
                    <Button
                      secondary
                      label={t('manage_account.sso.add_domain')}
                      testId="set-samlsso-connection"
                      onClick={() => {
                        setClaimDomainModal(true)
                      }}
                    />
                  </VisibilityWrapper>
                </Box>
              ) : (
                ''
              )}
            </>
          }
          size="small"
          data-testid="authmethod-title"
        />
        {authmethod === authMethodValues.saml ? (
          <ConfigureSaml
            ssoData={data}
            domain={notifyUserDomain}
            showNotifyUsers={notifyUsersModal}
            setClaimDomainModal={setClaimDomainModal}
            hideNotifyUsers={closeNotifyUsersModalCb}
          />
        ) : (
          ''
        )}
        {authmethod === authMethodValues.radius ? (
          <ConfigureRadius
            setNotifyComponent={setNotifyComponent}
            radiusServerDetails={radiusServerDetails}
            cb={getServerDetails}
            editMode={editMode}
            showAddServerModal={addServerModal}
            closeAddServerModal={setAddServerModal}
            setSmartCardChecked={setSmartCardChecked}
            smartCardChecked={smartCardChecked}
            isSetSmartCardEdited={isSetSmartCardEdited}
          />
        ) : (
          ''
        )}
        {authmethod === authMethodValues.radsec ? (
          <ConfigureRadsec
            setNotifyComponent={setNotifyComponent}
            radiusServerDetails={radsecServerDetails}
            showRadsecWarningForCertUpload={showRadsecWarningForCertUpload}
            checkCertificateValidity={checkCertificateValidity}
            cb={getRadsecServerDetails}
            editMode={editMode}
            showAddServerModal={addServerModal}
            closeAddServerModal={setAddServerModal}
            testId="authentication-page-header"
            setSmartCardChecked={setSmartCardChecked}
            smartCardChecked={smartCardChecked}
            isSetSmartCardEdited={isSetSmartCardEdited}
          />
        ) : (
          ''
        )}
      </>
    )
  }

  const renderAuthMethods = () => {
    let showSamlDropdown = true
    if (rbacPolicies?.effects) {
      // hide saml dropdown for observer and operator roles
      const samlRbac = {
        permission: 'ccs.accounts.platform.customer.edit',
        resource: '/ccs/accounts/platform/customer/saml'
      }
      showSamlDropdown =
        rbacPolicies?.effects?.[samlRbac.resource]?.[samlRbac.permission]
    }
    return (
      <>
        <Box flex>
          {isCoP() || isGLOP() ? (
            <>
              <Box direction="row" margin={{ top: 'medium', bottom: 'small' }}>
                <Box margin={{ right: 'medium' }} width="medium">
                  <Typography
                    testId="auth-method-header"
                    type="text"
                    weight="bold"
                    size="xsmall"
                  >
                    {t('manage_account.authentication.auth_method')}
                  </Typography>
                </Box>
                <Box width="large">
                  {editMode ? (
                    <>
                      <Dropdown
                        width="large"
                        testId="authMethods-dropdown"
                        placeholder="Select"
                        defaultVal={
                          authMethodDropdownValMap[selectedAuthMethod]
                        }
                        options={
                          isCoP()
                            ? [
                                t('manage_account.localdb.title'),
                                showSamlDropdown &&
                                  t('manage_account.sso.title'),
                                t('manage_account.radius.title'),
                                t('manage_account.radsec.dropdownTitle')
                              ]
                            : isGLOP() && [t('manage_account.sso.title')]
                        }
                        multiple={false}
                        onChangeDropdown={(option) => {
                          setSelectedAuthMethod(getAuthMethodFromKeyMap[option])
                        }}
                      />

                      {(isCoP() || isGLOP()) &&
                        (selectedAuthMethod === authMethodValues.radius ||
                          selectedAuthMethod === authMethodValues.radsec) && (
                          <Box>
                            <Typography
                              type="text"
                              size="small"
                              testId="smartcard-authn-message"
                            >
                              {t('manage_account.radius.smart_card_authn_msg')}
                            </Typography>
                          </Box>
                        )}
                    </>
                  ) : (
                    <Typography type="text" default margin={{ left: 'xlarge' }}>
                      {' '}
                      {selectedMethodContentMap[selectedAuthMethod].title}
                    </Typography>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            ''
          )}

          {renderSelectedMethodContent(selectedAuthMethod)}
        </Box>
      </>
    )
  }

  const handleAcknowledgeAndContinue = (domainName) => {
    setAckDomainModal(false)
    navigate(`/manage-account/sso/sso-connection`, {
      state: { domain: domainName || domain }
    })
  }

  const handleSaveEditAuthenticationMethod = () => {
    if (editMode) {
      switch (selectedAuthMethod) {
        case authMethodValues.saml: {
          if (selectedAuthMethod !== onLoadAuthMethod) {
            if (data.length === 0) {
              const msg = t(
                'manage_account.authentication.no_saml_connections_notification_msg'
              )

              if (msg) {
                setSaveErrorNotificationMessage(msg)
              }
              setShowSaveErrorNotification(true)
            } else {
              setShowAuthProtocolChangeModal(true)
            }
          } else {
            saveAuthenticationProtocol(
              {
                cb: function fn() {},
                val: false
              },
              setEditMode
            )
          }
          break
        }

        case authMethodValues.radius: {
          if (selectedAuthMethod !== onLoadAuthMethod) {
            if (radiusServerDetails.length === 0) {
              const msg = t(
                'manage_account.authentication.no_radius_server_notification_msg'
              )

              if (msg) {
                setSaveErrorNotificationMessage(msg)
              }
              setShowSaveErrorNotification(true)
            } else {
              setShowAuthProtocolChangeModal(true)
            }
          } else {
            saveAuthenticationProtocol(
              {
                cb: function fn() {},
                val: false
              },
              setEditMode
            )
          }
          break
        }

        case authMethodValues.radsec: {
          if (selectedAuthMethod !== onLoadAuthMethod) {
            if (radsecServerDetails.length === 0) {
              const msg = t(
                'manage_account.authentication.no_radsec_server_notification_msg'
              )

              if (msg) {
                setSaveErrorNotificationMessage(msg)
              }
              setShowSaveErrorNotification(true)
            } else {
              // setShowAuthProtocolChangeModal(true)
              setShowRadsecProtocolChangeModal(true)
            }
          } else {
            saveAuthenticationProtocol(
              {
                cb: function fn() {},
                val: false
              },
              setEditMode
            )
          }

          break
        }

        case authMethodValues.localdb: {
          if (selectedAuthMethod !== onLoadAuthMethod) {
            setShowAuthProtocolChangeModal(true)
          }
          break
        }
        default: {
          break
        }
      }
    } else {
      setEditMode(true)
    }
  }

  const authMethodProtocolChangeCb = (isRadsec) => {
    if (isRadsec) {
      setShowRadsecProtocolChangeModal(false)
    } else {
      setShowAuthProtocolChangeModal(false)
    }

    saveAuthenticationProtocol(
      {
        cb: setOnLoadAuthMethod,
        val: selectedAuthMethod
      },
      setEditMode
    )
    // setOnLoadAuthMethod(selectedAuthMethod)
  }

  return (
    <Box data-testid="authentication-card">
      {notifyComponent}
      {showAuthProtocolChangeModal && (
        <ProtocolchangeModal
          setShow={setShowAuthProtocolChangeModal}
          cb={authMethodProtocolChangeCb}
          selectedAuthMethod={authMethodDropdownValMap[selectedAuthMethod]}
        />
      )}
      {showRadsecProtocolChangeModal && (
        <RadsecProtocolChangeModal
          setShow={setShowRadsecProtocolChangeModal}
          cb={authMethodProtocolChangeCb}
        />
      )}
      {claimDomainModal && (
        <ClaimDomainModal
          onSetOpen={setClaimDomainModal}
          onSetAckDomainOpen={setAckDomainModal}
          setDomain={setDomain}
          setClaimedAccountsCount={setClaimedAccountsCount}
          handleAcknowledgeAndContinue={handleAcknowledgeAndContinue}
        />
      )}
      {ackDomainModal && (
        <AcknowledgeDomainModal
          onSetOpen={setAckDomainModal}
          domainName={domain}
          claimedAccountsCount={claimedAccountsCount}
          handleAcknowledgeAndContinue={handleAcknowledgeAndContinue}
        />
      )}
      {showSaveErrorNotification && (
        <Notification
          icon={<StatusCritical color="text-strong" />}
          onClose={() => {
            setShowSaveErrorNotification(false)
            setSaveErrorNotificationMessage('')
          }}
          backgroundColor="status-unknown"
          testId="status-good-notification"
          text={saveErrorNotificationMessage}
        />
      )}
      <Box flex align="center">
        <Box width="large" align="left">
          <Box
            pad={{ bottom: 'medium' }}
            border={{ side: 'bottom', color: 'border-weak' }}
          >
            <Box flex direction="row" justify="between" align="left">
              <Box flex>
                <PageHeader
                  responsive
                  pad={{ top: 'xsmall' }}
                  title={t('manage_account.authentication.title')}
                  subtitle={
                    isCoP() || isGLOP()
                      ? t(
                          'manage_account.authentication.auth_method_setup_msg_cop'
                        )
                      : t('manage_account.authentication.auth_method_setup_msg')
                  }
                  data-testid="auth-header"
                />
              </Box>
              <Box justify="center">
                {!editMode && (isCoP() || isGLOP()) && (
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/accounts/platform/customer',
                      permission: 'ccs.accounts.platform.customer.edit'
                    }}
                  >
                    <Button
                      secondary={!editMode}
                      primary={!!editMode}
                      label={t('manage_account.authentication.edit')}
                      onClick={() => handleSaveEditAuthenticationMethod()}
                      testId="action-btn"
                    />
                  </VisibilityWrapper>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            {selectedAuthMethod ? (
              <>
                {renderAuthMethods()}

                <Box
                  direction="row"
                  justify="end"
                  gap="medium"
                  pad={{ top: 'small', bottom: 'small' }}
                  border={{ side: 'top', color: 'border-weak' }}
                >
                  {editMode && (isCoP() || isGLOP()) && (
                    <Box direction="row" justify="end" gap="medium">
                      <Button
                        default
                        label={t('manage_account.authentication.cancel')}
                        onClick={() => {
                          setEditMode(false)
                        }}
                        testId="cancel"
                      />

                      <VisibilityWrapper
                        rbac={{
                          resource: '/ccs/accounts/platform/customer',
                          permission: 'ccs.accounts.platform.customer.edit'
                        }}
                      >
                        {editMode ? (
                          <Button
                            secondary={!editMode}
                            primary={!!editMode}
                            label={t(
                              'manage_account.authentication.save_change'
                            )}
                            onClick={() => handleSaveEditAuthenticationMethod()}
                            testId="action-save-btn"
                          />
                        ) : (
                          <Button
                            secondary={!editMode}
                            primary={!!editMode}
                            label={t('manage_account.authentication.edit')}
                            onClick={() => handleSaveEditAuthenticationMethod()}
                            testId="action-edit-btn"
                          />
                        )}
                      </VisibilityWrapper>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Box align="center" margin={{ top: 'medium' }}>
                <Loader testId="loader-spinner" />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const ConfigureAuthenticationPage = () => {
  return <ConfigureAuthentication align="start" justify="start" />
}

export default ConfigureAuthenticationPage
