// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, NameValueList, NameValuePair } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Typography } from '../../../components'

const LocationInfo = ({ pickedLocationDetails }) => {
  const { t } = useTranslation(['device', 'common'])
  return (
    <NameValueList data-testid="location-details">
      {Object?.entries(pickedLocationDetails)?.map((pickedLocation) => {
        return (
          <NameValuePair
            name={
              <Typography
                testId={`${pickedLocation[0]}-key`}
                type="text"
                weight={500}
              >
                {t(pickedLocation[0])}
              </Typography>
            }
            key={pickedLocation[0]}
          >
            {typeof pickedLocation[1] === 'object' ? (
              <Box data-testid={`${pickedLocation[0]}-value`}>
                <Typography
                  type="text"
                  size="medium"
                  testId="pcode-street-value"
                >
                  {`${pickedLocation[1]?.postal_code} ${pickedLocation[1]?.street_address}`}
                </Typography>
                <Typography type="text" size="medium" testId="city-state-value">
                  {pickedLocation[1]?.city}/{pickedLocation[1].state}
                </Typography>
                <Typography type="text" size="medium" testId="country-value">
                  {pickedLocation[1]?.country}
                </Typography>
              </Box>
            ) : (
              <Typography
                type="text"
                size="medium"
                testId={`${pickedLocation[0]}-value`}
              >
                {pickedLocation[1]}
              </Typography>
            )}
          </NameValuePair>
        )
      })}
    </NameValueList>
  )
}

LocationInfo.propTypes = {
  pickedLocationDetails: PropTypes.object.isRequired
}

export default LocationInfo
