// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Install } from 'grommet-icons'
import PropTypes from 'prop-types'

import { DataTable, NoDataInfo, Typography } from '../../../components'
import { getDeviceList } from '../../../utils/manage-account-utils'
import { getPaginationShowIdx } from '../../../utils/common-utils'

const DevicesDataTable = ({
  folderId = undefined,
  inCCSManager = false,
  setDevicesErrorMessage
}) => {
  const { t } = useTranslation(['device', 'common'])
  const [searchTerm, setSearchTerm] = useState('')
  const { oidcUser } = useReactOidc()
  const [viewData, setViewData] = useState([])
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)
  const [showInitCount, setInitCount] = useState(0)

  useEffect(() => {
    if (folderId) {
      const param = {
        limit: itemsPerPage,
        page: page - 1,
        folder_id: folderId,
        ...(searchTerm?.length && { search_string: searchTerm })
      }
      getDeviceList({
        token: oidcUser.access_token,
        t,
        param,
        setTotalItems,
        setErrorMessage: setDevicesErrorMessage,
        inCCSManager
      }).then((data) => {
        setViewData(data)
        if (data?.length) setInitCount(showInitCount + 1)
      })
    }
  }, [oidcUser.access_token, t, searchTerm, page, folderId]) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      property: 'serial_number',
      type: 'string',
      header: t('serial_number'),
      primary: true,
      render: (datum) => (
        <Typography
          size="medium"
          testId="serial-number"
          type="text"
          weight="bold"
          wordBreak="break-all"
        >
          {datum.serial_number}
        </Typography>
      )
    },
    {
      property: 'mac_address',
      type: 'string',
      header: t('mac_address')
    },
    {
      property: 'inv_name',
      type: 'string',
      header: t('device_name')
    }
  ]

  return !showInitCount ? (
    <NoDataInfo
      subtitle={t('common:no_devices_info')}
      icon={<Install size="large" />}
      testId="no-data-info-without-title-devices"
    />
  ) : (
    <DataTable
      pagination={{
        totalItems,
        itemsPerPage,
        page,
        setPage,
        pageIdxInfo
      }}
      search={{
        width: 'medium',
        onSearchValueChange: (val) => {
          setPage(1)
          setSearchTerm(val)
        },
        placeholder: t('search_by_serial_mac_device_name')
      }}
      grid={{
        columns,
        data: viewData
      }}
      summary={{
        entityName: t('devices')
      }}
      testId="devices-table"
    />
  )
}

DevicesDataTable.propTypes = {
  folderId: PropTypes.string,
  inCCSManager: PropTypes.bool,
  setDevicesErrorMessage: PropTypes.func.isRequired
}

export { DevicesDataTable }
