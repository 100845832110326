// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useReducer } from 'react'

const PageType = {
  AlertsTasks: 'AlertsTasks',
  Alerts: 'Alerts',
  Tasks: 'Tasks',
  Announcements: 'Announcements'
}
const initialContext = {
  debouncedSearchTerm: '',
  selectedReadStatus: '',
  selectedDateFrame: 'All',
  select: [],
  itemsPerPage: 10,
  page: 1,
  filterValues: {},
  pageData: [],
  allData: [],
  nextURI: '',
  nextSearchURI: '',
  maintainNextURI: [],
  maintainNextSearchURI: [],
  hideNext: '',
  isLoading: true,
  loadingWithFilter: false,
  triggerFilterRequest: false,
  triggerNotificationRequest: false,
  initialTrigger: true,
  checked: [],
  selectedCheckBoxStatus: '',
  openConfirmation: false,
  confirmAction: false,
  searchConfirm: '',
  showWarningBanner: false,
  hidePagination: false,
  loadingWithOutFilter: false,
  patchLoader: false,
  notificationEmpty: false,
  noLoader: true,
  disabledAction: [],
  timeFrame: [],
  read: undefined,
  categories: [],
  workspaces: [],
  selectedType: [],
  showDate: false,
  sortDirection: 'desc',
  countInfo: {
    totalCount: null,
    totalHits: null
  }
}

const NotificationsReducer = (state, action) => {
  switch (action.type) {
    case 'TYPE':
      return {
        ...state,
        type: action.type
      }
    case 'CATEGORIES':
      return {
        ...state,
        categories: action.categories
      }
    case 'WORKSPACES':
      return {
        ...state,
        workspaces: action.workspaces
      }
    case 'DEBOUNCED_SEARCH_TERM':
      return {
        ...state,
        debouncedSearchTerm: action.debouncedSearchTerm
      }
    case 'READ':
      return {
        ...state,
        read: action.read
      }
    case 'TIME_FRAME':
      return {
        ...state,
        timeFrame: action.timeFrame
      }
    case 'FILTER_VALUES':
      return {
        ...state,
        filterValues: action.filterValues
      }
    case 'SELECTED_READ_STATUS':
      return {
        ...state,
        selectedReadStatus: action.selectedReadStatus
      }
    case 'SELECTED_DATE_FRAME':
      return {
        ...state,
        selectedDateFrame: action.selectedDateFrame
      }
    case 'SELECT':
      return {
        ...state,
        select: action.select
      }
    case 'ITEMS_PER_PAGE':
      return {
        ...state,
        itemsPerPage: action.itemsPerPage
      }
    case 'PAGE':
      return {
        ...state,
        page: action.page
      }
    case 'PAGE_DATA':
      return {
        ...state,
        pageData: action.pageData
      }
    case 'ALL_DATA':
      return {
        ...state,
        allData: action.allData
      }
    case 'DISABLED_ACTION':
      return {
        ...state,
        disabledAction: action.disabledAction
      }
    case 'NEXT_URI':
      return {
        ...state,
        nextURI: action.nextURI
      }
    case 'NEXT_SEARCH_URI':
      return {
        ...state,
        nextSearchURI: action.nextSearchURI
      }
    case 'MAINTAIN_NEXT_URI':
      return {
        ...state,
        maintainNextURI: action.maintainNextURI
      }
    case 'HIDE_NEXT':
      return {
        ...state,
        hideNext: action.hideNext
      }
    case 'LOADING_WITH_FILTER':
      return {
        ...state,
        loadingWithFilter: action.loadingWithFilter
      }
    case 'INITIAL_TRIGGER':
      return {
        ...state,
        initialTrigger: action.initialTrigger
      }
    case 'CHECKED':
      return {
        ...state,
        checked: action.checked
      }
    case 'SELECTED_CHECKBOX_STATUS':
      return {
        ...state,
        selectedCheckBoxStatus: action.selectedCheckBoxStatus
      }
    case 'OPEN_CONFIRMATION':
      return {
        ...state,
        openConfirmation: action.openConfirmation
      }
    case 'CONFIRM_ACTION':
      return {
        ...state,
        confirmAction: action.confirmAction
      }
    case 'SEARCH_CONFIRM':
      return {
        ...state,
        searchConfirm: action.searchConfirm
      }
    case 'LOADING_WITHOUT_FILTER':
      return {
        ...state,
        loadingWithOutFilter: action.loadingWithOutFilter
      }
    case 'MAINTAIN_NEXT_SEARCH_URI':
      return {
        ...state,
        maintainNextSearchURI: action.maintainNextSearchURI
      }
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'TRIGGER_NOTIFICATION_REQUEST':
      return {
        ...state,
        triggerNotificationRequest: action.triggerNotificationRequest
      }
    case 'HIDE_PAGINATION':
      return {
        ...state,
        hidePagination: action.hidePagination
      }
    case 'TRIGGER_FILTER_REQUEST':
      return {
        ...state,
        triggerFilterRequest: action.triggerFilterRequest
      }
    case 'PATCH_LOADER':
      return {
        ...state,
        patchLoader: action.patchLoader
      }
    case 'NOTIFICATION_EMPTY':
      return {
        ...state,
        notificationEmpty: action.notificationEmpty
      }
    case 'NO_LOADER':
      return {
        ...state,
        noLoader: action.noLoader
      }
    case 'SHOW_WARNING_BANNER':
      return {
        ...state,
        showWarningBanner: action.showWarningBanner
      }
    case 'SELECTED_TYPE':
      return {
        ...state,
        selectedType: action.selectedType
      }
    case 'SORT':
      return {
        ...state,
        sortDirection: action.sortDirection
      }
    case 'SHOW_DATE':
      return {
        ...state,
        showDate: action.showDate
      }
    case 'BULK_UPDATE_RESET':
      return {
        ...state,
        patchLoader: false,
        loadingWithOutFilter: true,
        allData: [],
        pageData: [],
        maintainNextURI: [],
        nextURI: '',
        page: 1,
        select: [],
        checked: [],
        selectedReadStatus: '',
        selectedCheckBoxStatus: '',
        confirmAction: false,
        noLoader: true,
        disabledAction: [],
        nextSearchURI: '',
        hideNext: true
      }
    case 'COUNT_INFO':
      return {
        ...state,
        countInfo: action.countInfo
      }
    default:
      return state
  }
}

const useNotificationsReducer = () => {
  const [stateV, dispatchNotifications] = useReducer(
    NotificationsReducer,
    initialContext
  )
  return { stateV, dispatchNotifications }
}

export default useNotificationsReducer
export { PageType }
