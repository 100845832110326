// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useState } from 'react'
import { Anchor, Box, Page, PageContent, PageHeader } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { Previous, Group, UserAdd } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useVisibilityContext } from '../../../../context/visibility-context'
import { Typography, Card, Button } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../../utils/common-utils'
import { InviteUserModal } from '../../common-components/user-actions/InviteUserModal'
import { samRbac, isInviteSEAccessAvailableForWorkspace } from '../../utils'

const SA = () => {
  const { t } = useTranslation(['support_access_manager', 'common'])
  const [inviteUserModal, setInviteUserModal] = useState(false)
  const [showNotification] = useState(null)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  // Allow HPE theme styling to spread onto Typography instance
  const {
    pageHeader: { subtitle }
  } = useContext(ThemeContext)

  const { rbacPolicies } = useVisibilityContext()

  const showInviteEngineerQuickAction = isInviteSEAccessAvailableForWorkspace(
    rbacPolicies?.effects?.[samRbac.resource]
  )

  const navigate = useNavigate()
  const handleAccountCardClick = (currentItem) => {
    const mainRouter = '/manage-ccs/support-access/'
    const routeTo = `${mainRouter}${currentItem.routeTo}`
    navigate(routeTo)
  }
  const handleBackBtnClick = () => {
    navigate('/manage-ccs/home')
  }

  const manageSupportAccessList = [
    {
      id: 1,
      icon: <Group size="medium" />,
      title: t('common:manage_account.sa_sam_card_title'),
      description: t('common:manage_account.sa_sam_card_subtitle'),
      routeTo: 'search-incident',
      testId: 'support-access'
    }
  ]

  const actionList = [
    {
      id: 1,
      icon: <UserAdd size="medium" />,
      title: t('support_access_manager:invite_user.title'),
      btn_title: t('support_access_manager:invite_user.title'),
      description: t('support_access_manager:invite_user.desc'),
      routeTo: 'users',
      testId: 'invite-users'
    }
  ]
  return (
    <Page>
      <PageContent>
        {inviteUserModal && <InviteUserModal onSetOpen={setInviteUserModal} />}
        <Box data-testid="sa-page">
          <PageHeader
            title={
              <Typography level="1" type="heading" testId="identity-title">
                {t('support_access_manager:support_access_title')}
              </Typography>
            }
            subtitle={
              <Typography type="text" testId="sa-description" {...subtitle}>
                {t('support_access_manager:support_access_desc')}
              </Typography>
            }
            parent={
              <Anchor
                label={t('common:button.manage_account', {
                  account: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                icon={<Previous />}
                onClick={handleBackBtnClick}
                data-testid="manage-account-btn"
              />
            }
          />
          <Box data-testid="identity-access" gap="large" margin="medium">
            <Box>
              <Typography
                type="heading"
                level="2"
                testId="sa_manage_section_title"
              >
                {t('support_access_manager:manage')}
              </Typography>
              <Box direction="row" wrap>
                {manageSupportAccessList &&
                  manageSupportAccessList.map((item) =>
                    !item.isHidden ? (
                      <VisibilityWrapper
                        key={item.id}
                        hideFor={item.hideFor}
                        rbac={item.rbac}
                      >
                        <Card
                          key={item.id}
                          cardWidth="medium"
                          testId={item.testId}
                          margin={{ top: 'medium', right: 'medium' }}
                          height="small"
                          onClick={() => handleAccountCardClick(item)}
                          CustomContent={
                            <Box gap="small" flex={false}>
                              {item.icon}
                              <Box
                                data-testid={`${item.testId}-text-info`}
                                gap="xsmall"
                              >
                                <Typography
                                  type="heading"
                                  level="3"
                                  testId={`${item.testId}-title`}
                                >
                                  {t(item.title)}
                                </Typography>
                                <Typography
                                  type="text"
                                  testId={`${item.testId}-summary`}
                                >
                                  {t(item.description)}
                                </Typography>
                              </Box>
                            </Box>
                          }
                        />
                      </VisibilityWrapper>
                    ) : null
                  )}
              </Box>
            </Box>
            {showInviteEngineerQuickAction && (
              <Box>
                <Typography
                  type="heading"
                  level="2"
                  testId="sa_quick_actions_title"
                >
                  {t('support_access_manager:quick_actions')}
                </Typography>
                <Box direction="row" wrap margin={{ bottom: 'small' }}>
                  {actionList &&
                    actionList.map((item) =>
                      !item.isHidden ? (
                        <VisibilityWrapper
                          key={item.id}
                          hideFor={item.hideFor}
                          rbac={item.rbac}
                        >
                          <Card
                            key={item.id}
                            action={
                              <Button
                                secondary
                                label={t(item.btn_title)}
                                onClick={() => handleAccountCardClick(item)}
                                testId={`${item.btn_title}`}
                              />
                            }
                            cardWidth="medium"
                            margin={{ top: 'medium', right: 'medium' }}
                            testId={item.testId}
                            CustomContent={
                              <Box gap="small" flex={false}>
                                <Box
                                  pad="xsmall"
                                  round="xsmall"
                                  width="fit-content"
                                >
                                  {item.icon}
                                </Box>
                                <Box
                                  data-testid={`${item.testId}-text-info`}
                                  gap="xsmall"
                                >
                                  <Typography
                                    type="heading"
                                    level="3"
                                    testId={`${item.testId}-title`}
                                  >
                                    {t(item.title)}
                                  </Typography>
                                  <Typography
                                    type="text"
                                    testId={`${item.testId}-summary`}
                                  >
                                    {t(item.description)}
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          />
                        </VisibilityWrapper>
                      ) : null
                    )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {showNotification}
      </PageContent>
    </Page>
  )
}

const SupportAccess = () => {
  return <SA />
}

export default SupportAccess
