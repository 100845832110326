// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Anchor } from 'grommet'
import { Trans } from 'react-i18next'

import { get } from '../../utils/api-utils'
import { getApiErrorMessage } from '../../utils/error-handling-utils'
import { Typography } from '../../components'

export const validateSubKey = (
  subscriptionKey,
  oidcUser,
  resetState,
  setValidationErrorMessage,
  t,
  navigate
) => {
  const supportLinkClickHandler = (e) => {
    e.preventDefault()
    navigate('/support')
  }
  return get(
    `/ui-doorway/ui/v1/license/${subscriptionKey}/preclaim`,
    {},
    oidcUser.access_token
  ).then(
    (response) => {
      const data = response?.data
      if (data?.claim_status === 'CLAIMED') {
        setValidationErrorMessage(t('subscription_claimed_in_same_workspace'))
        return
      }
      resetState(subscriptionKey)
    },
    (err) => {
      const errorCode = err.response?.data?.errorCode
      let workspaceName = ''
      switch (errorCode) {
        case 'HPE_GL_SM_SUBSCRIPTION_CLAIMED_DIFF_CUSTOMER':
          setValidationErrorMessage(
            <Typography
              type="text"
              size="medium"
              testId="app-terms-checkbox-label"
            >
              <Trans i18nKey="subscription_claimed_diff_customer" t={t}>
                <Anchor
                  href="/support"
                  onClick={supportLinkClickHandler}
                  data-testid="support-link"
                />
              </Trans>
            </Typography>
          )
          break

        case 'HPE_GL_SM_SUBSCRIPTION_CLAIMED_ADMIN':
          workspaceName =
            err.response?.data?.errorDetails[0]?.metadata?.workspaceName
          setValidationErrorMessage(
            t('subscription_claimed_admin', { workspace: workspaceName })
          )
          break

        case 'HPE_GL_SM_SUBSCRIPTION_CLAIMED_NON_ADMIN':
          workspaceName =
            err.response?.data?.errorDetails[0]?.metadata?.workspaceName
          setValidationErrorMessage(
            t('subscription_claimed_non_admin', { workspace: workspaceName })
          )
          break

        default:
          setValidationErrorMessage(getApiErrorMessage(err, t))
          break
      }
    }
  )
}
