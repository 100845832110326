// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Box,
  FormField,
  Anchor,
  MaskedInput,
  Select,
  ResponsiveContext
} from 'grommet'
import { CircleInformation } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Tooltip, Typography, Loader } from '../../../../components'
import { CaseCreationContext } from '../context/CaseCreationContext'
import { fetchUsers, onSearch, onSort, onSortUnique } from '../utils/FormUtil'
import { Notification } from '../../../../components/notification/Notification'

const ContactElements = ({ contactConfig, caseFormCallBack = () => {} }) => {
  const { t } = useTranslation(['support_cases'])
  const caseFormContextData = useContext(CaseCreationContext)
  const size = useContext(ResponsiveContext)
  const {
    loggedInUserContact,
    contacts,
    setContacts,
    selectedPrimaryContact,
    setSelectedPrimaryContact,
    selectedAlternateContact,
    setSelectedAlternateContact,
    serverSearchEnabled,
    setServerSearchEnabled,
    contactSearchFailed,
    setContactSearchFailed
  } = caseFormContextData
  const contactPreferenceOptions = [
    t('support_cases:contact.email'),
    t('support_cases:contact.phone')
  ]
  const [contactSelected, setContactSelected] = useState(
    contactConfig.contactType === 'Primary'
      ? selectedPrimaryContact
      : selectedAlternateContact
  )
  const [contactRequired, setContactRequired] = useState(
    Object.keys(contactSelected).length !== 0
  )
  const [contactPreferenceRequired, setContactPreferenceRequired] = useState(
    Object.keys(contactSelected).length !== 0
  )
  const [contactPreferenceSelected, setContactPreferenceSelected] = useState(
    contactSelected?.contactPreference || ''
  )
  const [phone, setPhone] = useState(contactSelected?.contactPhone || '')
  const [contactOptions, setContactOptions] = useState([
    ...Array.of(contactSelected)
  ])
  const [contactSearchEnabled, setContactSearchEnabled] = useState(false)
  const [contactDisabled, setContactDisabled] = useState(false)
  const [userAPIErrorMessage, setUserAPIErrorMessage] = useState('')
  const showContactPreference = contactConfig.contactPreferenceVisible
  const { oidcUser } = useReactOidc()
  const [showLoading, setShowLoading] = useState(false)
  const [phoneErrorMsg, setPhoneErrorMsg] = useState('')

  const fetchUsersOnLoad = async () => {
    setShowLoading(true)
    const { usersData, serverSideSearch, errorMessage } = await fetchUsers(
      oidcUser.access_token
    )
    if (errorMessage?.length) {
      setContactDisabled(true)
      setUserAPIErrorMessage(errorMessage)
    } else if (usersData?.length) {
      setServerSearchEnabled(serverSideSearch)
      const sortedUsersData = onSortUnique([
        ...Array.of(loggedInUserContact),
        ...usersData
      ])
      setContactOptions(sortedUsersData)
      setContacts(sortedUsersData)
    }
    setShowLoading(false)
  }

  const filterNonSelectedContacts = (contactsToFilter, selectedContact) => {
    return contactsToFilter.filter(
      (contactOption) => contactOption.value !== selectedContact.value
    )
  }

  const getNonSelectedContacts = (usersData) => {
    const filteredContacts = filterNonSelectedContacts(
      usersData,
      contactConfig.contactType === 'Primary'
        ? selectedAlternateContact
        : selectedPrimaryContact
    )
    setContactOptions(filteredContacts)
  }

  const fetchUsersOnSearch = async (searchString) => {
    const { usersData, errorMessage } = await fetchUsers(
      oidcUser.access_token,
      searchString
    )
    if (errorMessage?.length) {
      setContactSearchFailed(true)
      getNonSelectedContacts(contacts)
    } else {
      setContactSearchFailed(false)
      getNonSelectedContacts(onSort([...usersData]))
    }
  }

  const handleContactSearch = (searchString) => {
    if (serverSearchEnabled) {
      fetchUsersOnSearch(searchString)
    } else {
      const filteredContacts = onSearch(searchString, contacts)
      getNonSelectedContacts(filteredContacts)
    }
  }

  const showContactDetails = (contactOption) =>
    showLoading ? (
      <Box
        height="small"
        align="center"
        justify="center"
        background="background-back"
        width="100%"
      >
        <Loader testId="contact-search-box-loader" width="100%" />
      </Box>
    ) : (
      <Box justify="between" direction="column">
        <Typography type="text" size="medium" weight={500}>
          {contactOption.name}
        </Typography>
        <Typography type="text" size="small">
          {contactOption.email}
        </Typography>
      </Box>
    )
  useEffect(() => {
    if (contactConfig.contactType === 'Primary') {
      setContactPreferenceRequired(true)
      setContactRequired(true)
      setShowLoading(false)
    }
    if (contactOptions.length > 10) {
      setContactSearchEnabled(true)
    }
  }, [contactConfig.contactType, contactOptions, contactSearchEnabled])
  useEffect(() => {
    const checkContactDataCompleted = (selectedContactData) => {
      const { name, contactPreference, contactPhone } = selectedContactData
      let contactCompleted = false
      if (
        name?.length &&
        (!showContactPreference ||
          (showContactPreference &&
            (contactPreference === t('support_cases:contact.email') ||
              (contactPreference === t('support_cases:contact.phone') &&
                contactPhone?.length))))
      ) {
        contactCompleted = true
      }
      return contactCompleted
    }
    let alternateCompleted = true
    if (Object.keys(selectedAlternateContact).length) {
      alternateCompleted = checkContactDataCompleted(selectedAlternateContact)
    }
    caseFormCallBack({
      data: {
        showConfirmCheckbox:
          checkContactDataCompleted(selectedPrimaryContact) &&
          alternateCompleted
      }
    })
  }, [
    caseFormCallBack,
    showContactPreference,
    selectedPrimaryContact,
    selectedAlternateContact,
    t
  ])

  return (
    <>
      <Box direction="row" gap="small">
        <Typography type="text" size="medium" margin={{ right: 'xxsmall' }}>
          {contactConfig.contactTitle}
        </Typography>
        {contactConfig.toolTipRequired && (
          <Tooltip
            info={
              <Box width={{ max: 'small' }} round="xsmall">
                <Typography
                  type="text"
                  size="small"
                  data-testid="contact-tool-tip-content"
                >
                  {t('support_cases:contact.alternate_contact_info')}
                </Typography>
              </Box>
            }
            dropProps={{ align: { bottom: 'top' } }}
            testId="contact-tool-tip"
          >
            <CircleInformation
              style={{ marginTop: '2px' }}
              data-testid="contact-tool-tip-icon"
            />
          </Tooltip>
        )}
      </Box>

      <Box
        direction="row"
        gap="small"
        style={size === 'xsmall' ? { display: 'block' } : { display: 'flex' }}
      >
        <Box direction="column" fill="horizontal">
          <FormField
            htmlFor={contactConfig.contactType}
            label={t('support_cases:contact.name')}
            name={`${contactConfig.contactType} Name`}
            required={contactRequired}
            data-testid={contactConfig.contactType}
          >
            <Select
              id={contactConfig.contactType}
              name={`${contactConfig.contactType} Name`}
              placeholder={t('support_cases:common.select')}
              searchPlaceholder={t('support_cases:common.search')}
              options={contactOptions}
              dropHeight="medium"
              dropProps={{
                stretch: 'align',
                width: 'large'
              }}
              labelKey={(contactOption) => showContactDetails(contactOption)}
              valueKey={{ key: 'name', reduce: true }}
              valueLabel={
                <Box
                  pad={{
                    top: 'xsmall',
                    left: 'small',
                    bottom: 'xsmall'
                  }}
                >
                  <Typography
                    type="text"
                    size="medium"
                    weight={contactSelected.name ? 500 : undefined}
                    color={contactSelected.name ? undefined : 'text-weak'}
                  >
                    {contactSelected.name || t('support_cases:common.select')}
                  </Typography>
                </Box>
              }
              value={contactSelected.name}
              emptySearchMessage={
                <Trans
                  i18nKey="support_cases:contact.contact_not_listed_in_list"
                  t={t}
                >
                  <Anchor
                    href="/manage-account/identity/users"
                    data-testid="contact-anchor-add-user"
                  />
                </Trans>
              }
              disabled={contactDisabled}
              onChange={({ option }) => {
                setContactSelected(option)
                if (
                  Object.keys(contactSelected).length &&
                  contactSelected?.value !== option?.value
                ) {
                  setContactPreferenceSelected('')
                  setPhone('')
                }
                if (contactConfig.contactType === 'Alternate') {
                  setContactRequired(true)
                  setContactPreferenceRequired(true)
                  setSelectedAlternateContact({
                    ...((Object.keys(contactSelected).length === 0 ||
                      contactSelected?.value === option?.value) && {
                      ...selectedAlternateContact
                    }),
                    ...option
                  })
                } else {
                  setSelectedPrimaryContact({
                    ...(contactSelected?.value === option?.value && {
                      ...selectedPrimaryContact
                    }),
                    ...option
                  })
                }
              }}
              onOpen={() => {
                if (
                  !contacts.length &&
                  contactConfig.contactType === 'Primary'
                ) {
                  fetchUsersOnLoad()
                } else {
                  getNonSelectedContacts(contacts)
                }
              }}
              onSearch={
                contactSearchEnabled
                  ? (searchString) => {
                      if (searchString?.length) {
                        handleContactSearch(searchString)
                      } else {
                        setContactOptions(contacts)
                      }
                    }
                  : null
              }
            />
          </FormField>
        </Box>
        {showContactPreference && (
          <Box direction="column" fill="horizontal">
            <FormField
              htmlFor={`${contactConfig.contactType} Contact Preference`}
              label={t('support_cases:contact.contact_preference')}
              name={`${contactConfig.contactType} Contact Preference`}
              data-testid={`${contactConfig.contactType}-contact-preference-select`}
              required={contactPreferenceRequired}
            >
              <Select
                id={`${contactConfig.contactType} Contact Preference`}
                name={`${contactConfig.contactType} Contact Preference`}
                aria-label={t('support_cases:common.select')}
                options={contactPreferenceOptions}
                placeholder={
                  <Typography type="text" size="medium">
                    {t('support_cases:common.select')}
                  </Typography>
                }
                onChange={(event) => {
                  setContactRequired(true)
                  const preferenceValue = event.target.value
                  setContactPreferenceSelected(preferenceValue)
                  if (contactConfig.contactType === 'Primary') {
                    setSelectedPrimaryContact({
                      ...selectedPrimaryContact,
                      contactPreference: preferenceValue
                    })
                  } else {
                    setSelectedAlternateContact({
                      ...selectedAlternateContact,
                      contactPreference: preferenceValue
                    })
                  }
                }}
                value={contactPreferenceSelected || ''}
                style={{
                  paddingTop: window.innerWidth > 768 ? '6px' : '3px',
                  paddingBottom: window.innerWidth > 768 ? '6px' : '3px'
                }}
              />
            </FormField>
          </Box>
        )}
      </Box>
      {showContactPreference &&
        contactPreferenceSelected === t('support_cases:contact.phone') && (
          <FormField
            htmlFor={`${contactConfig.contactType} Contact Phone`}
            label={t('support_cases:contact.phone')}
            name={`${contactConfig.contactType} Contact Phone`}
            required
            error={phoneErrorMsg}
          >
            <MaskedInput
              id={`${contactConfig.contactType} Contact Phone`}
              name={`${contactConfig.contactType} Contact Phone`}
              data-testid={`enter-${contactConfig.contactType}-contact-phone`}
              mask={[
                {
                  fixed: '+'
                },
                {
                  length: 30,
                  regexp: /^\d+$/,
                  placeholder: 'xxxxxxxxxx'
                }
              ]}
              value={phone}
              onChange={(event) => {
                const phoneValue = event.target.value
                if (phoneValue.length === 0 || phoneValue === '+') {
                  setPhoneErrorMsg(
                    t('support_cases:common.this_is_required_field')
                  )
                } else if (phoneValue.length < 4) {
                  setPhoneErrorMsg(t('support_cases:common.invalid_phone'))
                } else {
                  setPhoneErrorMsg('')
                }
                setPhone(phoneValue)
                if (contactConfig.contactType === 'Primary') {
                  setSelectedPrimaryContact({
                    ...selectedPrimaryContact,
                    contactPhone: phoneValue
                  })
                } else {
                  setSelectedAlternateContact({
                    ...selectedAlternateContact,
                    contactPhone: phoneValue
                  })
                }
              }}
            />
          </FormField>
        )}
      {contactDisabled && (
        <Box margin={{ top: 'small' }}>
          <Notification
            status="critical"
            type="inline"
            icon={<CircleInformation />}
            testId="userapi-error-notification"
            text={t(userAPIErrorMessage)}
          />
        </Box>
      )}
      {contactSearchFailed && contactConfig.contactType === 'Alternate' && (
        <Box margin={{ top: 'small' }}>
          <Notification
            status="critical"
            type="inline"
            icon={<CircleInformation />}
            testId="search-userapi-error-notification"
            text={t('support_cases:contact.search_api_failure_msg')}
          />
        </Box>
      )}
    </>
  )
}

ContactElements.propTypes = {
  contactConfig: PropTypes.shape({
    contactTitle: PropTypes.string.isRequired,
    toolTipRequired: PropTypes.bool.isRequired,
    contactType: PropTypes.string.isRequired,
    contactPreferenceVisible: PropTypes.bool.isRequired
  }).isRequired,
  caseFormCallBack: PropTypes.func
}
export default ContactElements
