// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { Previous } from 'grommet-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  AvatarInfo,
  Button,
  Loader,
  Typography
} from '../../../../../../components'
import { useCCSManagerContext } from '../../../../../../context/ccs-manager-context'
import { get } from '../../../../../../utils/api-utils'
import { UserAssignmentsWithRRP } from '../../../../../manage-account/identity/users/commoncomponents/UserAssignmentsWithRRP'
import { displayApiError } from '../../../../../../utils/error-handling-utils'

import { UserDetails } from './components'

const CustomerUserDetailsPage = () => {
  const { t } = useTranslation(['manage', 'common'])
  const { username } = useParams()
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { selectedCustomer: customerData } = useCCSManagerContext()
  const [userData, setUserData] = useState(null)
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const url = '/support-assistant/v1alpha1/users'
    get(
      url,
      {
        limit: 5,
        offset: 0,
        platform_customer_id: customerData.id,
        search_string: username,
        include_unverified: true
      },
      oidcUser.access_token
    ).then(
      (response) => {
        setUserData(
          response?.data?.users?.find(
            (user) => user?.contact?.email === username
          ) || {}
        )
        setLoading(false)
      },
      (error) => {
        setShowNotification(displayApiError(error, t))
        setLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser?.access_token, customerData?.id, username])

  const getUserFullName = () => {
    const firstName = userData?.contact?.first_name
    const lastName = userData?.contact?.last_name
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName || username
  }

  const handleBackBtnClick = () => {
    navigate('/manage-ccs/customers/customer-details', {
      state: { tabName: 'users' }
    })
  }

  return (
    <Box>
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={`${t('customer_details_heading')} - ${customerData?.name}`}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="user-details-page-back-btn"
        />
      </Box>
      <Box pad={{ horizontal: 'xlarge', top: 'medium' }}>
        <Box
          direction="row-responsive"
          align="center"
          justify="start"
          margin={{ bottom: 'large' }}
          border={{ side: 'bottom', color: 'border-weak' }}
          pad={{ bottom: 'small' }}
          gap="medium"
        >
          <AvatarInfo
            primaryInfo={getUserFullName()}
            secondaryInfo=""
            testId="user-info"
            avatarOnly
            avatarSize="large"
          />
          <Box>
            <Box
              gap="small"
              direction="row-responsive"
              align="center"
              justify="start"
            >
              <Typography level="1" type="heading" testId="page-title">
                {getUserFullName()}
              </Typography>
              {userData?.user_status && (
                <Box
                  align="center"
                  justify="center"
                  background="background-back"
                  pad={{ horizontal: 'small', vertical: 'xsmall' }}
                  round="xsmall"
                >
                  <Typography size="small" type="text" testId="user-status">
                    {userData?.user_status === 'VERIFIED' ||
                    userData?.user_status === 'UNVERIFIED'
                      ? t(`user_role_assignments.${userData?.user_status}`)
                      : userData?.user_status}
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography size="large" type="text" testId="user-company-name">
              {customerData?.name}
            </Typography>
          </Box>
        </Box>
        <Box align="center">
          {loading ? (
            <Loader testId="user-details-loader" />
          ) : (
            <>
              <Box direction="column" gap="small" pad="small" align="center">
                <Box pad={{ top: 'xsmall' }} width="large">
                  <UserDetails userData={{ ...userData, email: username }} />
                </Box>
              </Box>
              <Box direction="column" gap="small" pad="xsmall" align="center">
                <Box pad={{ top: 'xsmall' }} width="large">
                  <UserAssignmentsWithRRP
                    user={{ ...userData, username }}
                    customerDetails={customerData}
                    inCCSManager
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {showNotification}
    </Box>
  )
}

export { CustomerUserDetailsPage }
