// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
// Supported tiers to display application field in side panel
export const APP_SUPPORTED_SUBSCRIPTION_TIERS = [
  'FOUNDATION_ALLETRA',
  'ENHANCED_ALLETRA',
  'STANDARD_ALLETRA',
  'FOUNDATION_VM_BACKUP',
  'ENHANCED_VM_BACKUP',
  'STANDARD_VM_BACKUP',
  'FOUNDATION_ZERTO',
  'FOUNDATION_SFM'
]

export const CS_INVOICING_MODEL = 'CS_INVOICING_MODEL'

export const VALID_PRODUCT_ATTR_START_PATTERN = 'CS_CCM_'

export const INVALID_PRODUCT_ATTR_START_PATTERN = 'CS_CCM_MULTIV_UOM'

export const VM_COUNT_PRODUCT_ATTR = 'CS_COMMITMENT_VALUE1'

export const BACKUP_CAPACITY_PRODUCT_ATTR = 'CS_COMMITMENT_VALUE2'
