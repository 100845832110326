// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import { Box, Button, Anchor } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useState } from 'react'
import { keyBy } from 'lodash'

import { useRegions, useServiceRedirect } from '../hooks'
import { getRegionName } from '../utils'
import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../shims/imports'
import { Dropdown } from '../../components'

const ServiceLaunchModal = ({
  services,
  modalInfo,
  setShowModal,
  isServiceDetailLaunch = false,
  isServiceManager = true,
  isDeepLinking = false,
  serviceOffer = undefined
}) => {
  const { serviceName, slug, childServiceName } = modalInfo
  const serviceBySlug = keyBy(services, 'serviceSlug')
  const service = serviceBySlug[slug]
  const { t } = useTranslation(['dashboard', `services`])

  const [selectedRegion, setSelectedRegion] = useState(service.regions[0]?.code)

  const { regions, isLoaded } = useRegions()

  // Only provide regions that are provisioned in the options list
  const regionOptions = service.regions
    .filter(({ provisionStatus }) => provisionStatus === 'PROVISIONED')
    .map(({ code }) => ({
      label: getRegionName(isLoaded, regions, code),
      value: code
    }))

  const serviceRedirect = useServiceRedirect()

  return (
    <ModalDialog
      width={isServiceDetailLaunch ? 'large' : 'medium'}
      header={
        <ModalHeader
          title={
            <Typography textAlign="start" type="heading" level={2}>
              {isServiceDetailLaunch && `${t('dashboard:common.launch')} `}
              {serviceName}
            </Typography>
          }
          subtitle={
            isServiceDetailLaunch
              ? null
              : t(`common.category.${service.category}`)
          }
        />
      }
      content={
        <Box margin={{ top: 'small' }} direction="column" justify="between">
          {isServiceDetailLaunch ? (
            <>
              <Typography type="paragraph" textAlign="start" fill>
                {isServiceManager || isDeepLinking ? (
                  t('dashboard:service_details.service_launch_prompt', {
                    serviceName: `${serviceName}`
                  })
                ) : (
                  <Trans>
                    {t(
                      'dashboard:service_details.service_manager_launch_prompt',
                      {
                        serviceName: `${childServiceName}`,
                        serviceManagerName: `${serviceName}`
                      }
                    )}
                  </Trans>
                )}
              </Typography>

              {!isServiceManager && !isDeepLinking ? (
                <Typography type="text" textAlign="center">
                  <Trans>
                    {t(
                      'dashboard:service_details.service_manager_launch_prompt_note_1',
                      {
                        serviceName: `${childServiceName}`,
                        serviceManagerName: `${serviceName}`
                      }
                    )}
                    <Anchor
                      label={t(
                        'dashboard:service_details.service_manager_launch_prompt_link'
                      )}
                      weight={500}
                      href="https://support.hpe.com/connect/s/createcase"
                      target="_blank"
                      testId="service-manager-launch"
                    />
                    {t(
                      'dashboard:service_details.service_manager_launch_prompt_note_2'
                    )}
                  </Trans>
                </Typography>
              ) : (
                ''
              )}
            </>
          ) : (
            <Typography type="text" margin={{ bottom: 'medium' }}>
              {t(`services:${slug}.short_description`)}
            </Typography>
          )}
          <Box pad={{ top: 'small' }}>
            <Typography type="text">{t('common.deployment_region')}</Typography>
            <Dropdown
              options={regionOptions}
              onChangeDropdown={(value) => {
                setSelectedRegion(value)
              }}
              defaultValue={selectedRegion}
              value={selectedRegion}
              testId="service-centric-launch-dropdown"
            />
          </Box>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" justify="end" gap="small">
              <Button
                label={t('common.cancel')}
                onClick={() => setShowModal(false)}
              />
              <Button
                primary
                label={t('common.launch')}
                onClick={() =>
                  serviceRedirect(service, selectedRegion, serviceOffer)
                }
              />
            </Box>
          }
        />
      }
      onClose={() => {}}
      testId="service-centric-launch-modal"
    />
  )
}

ServiceLaunchModal.propTypes = {
  services: PropTypes.array.isRequired,
  modalInfo: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  isDeepLinking: PropTypes.bool,
  isServiceDetailLaunch: PropTypes.bool,
  isServiceManager: PropTypes.bool,
  serviceOffer: PropTypes.string
}

export default ServiceLaunchModal
