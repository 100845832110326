// Copyright 2024 Hewlett Packard Enterprise Development LP
/**
import { BLOCK_STORAGE } from './constants';
 * Account types:
 *
 * These constants represent the account types available to customers.
 */
export const STANDALONE = 'STANDALONE'
export const MSP = 'MSP'
export const TENANT = 'TENANT'

/**
 * Services:
 *
 * These constants represent the various services represented in the service catalog.
 */
export const CENTRAL = 'CENTRAL'
export const CENTRAL_INTERNAL = 'CEN-INT'
export const DATASVS = 'DATASVS'
export const HPECC = 'HPECC'
export const OTHER = 'OTHER'
export const GLC = 'GLC'
// export const ML_OPS = 'MLOPS'
// export const HPC = 'HPC'
// export const DATA_FABRIC = 'DF'
export const BACKUP_AND_RECOVERY = 'BKUP'
export const BLOCK_STORAGE = 'BS'
export const DATA_OPS_MANAGER = 'DOM'
export const FILE_STORAGE = 'FS'
export const DISASTER_RECOVERY = 'DR'
export const WELLNESS = 'WD'
export const INFOSIGHT = 'INFOSTE'
export const OPSRAMP = 'OPSRAMP'
export const SD = 'SUST' // Sustainability Dashboard
export const PRIVATE_CLOUD_BUSINESS = 'PCBE'
export const PRIVATE_CLOUD_ENTERPRISE = 'PCE'
export const COM_PREPRODUCTION = 'HPECC_a'

export const PRIVATE_CLOUD = 'PRIVATE_CLOUD'
export const UXI = 'UXI'
export const PLATFORM_ANALYTICS = 'PAPROD'
export const SFM = 'SFM'
export const EDGE_CONNECT = 'EDGECNT'
export const MLDX_MS = 'MLDX-MS'
export const PCAI = 'PCAI'
export const HCIPOC = 'SCDEV-K'
export const HOC = 'HOC'
export const PCSM = 'PCSM'
export const CONSUMPTION_ANALYTICS = 'CAP'
export const SCDEVI = 'SCDEV-I' // DSCC slug in Pavo
export const SCAAS = 'SCAAS'
export const ZMSP = 'ZMSP' // Zerto service
export const VCF = 'VCF'

/**
 * Categories:
 *
 * These constants represent the possible categories under which services can be organized.
 */
export const CATEGORY = {
  MANAGEMENT_AND_GOVERNANCE: 'MANAGEMENT_AND_GOVERNANCE',
  MGMT_GOV: 'MGMT_GOV',
  COMPUTE: 'COMPUTE',
  NETWORKING: 'NETWORKING',
  PRIVATE_CLOUD: 'PRIVATE_CLOUD',
  STORAGE: 'STORAGE',
  WORKLOADS: 'WORKLOADS',
  COMMON: 'COMMON',
  RECOMMENDED: 'RECOMMENDED',
  ORIGANIZATION: 'ORIGANIZATION',
  AI_ML_ANALYTICS: 'AI_ML_ANALYTICS'
}

export const ALL_REGION = 'ALL_REGION'

// Used for when the provider status is unkown (e.g. GLCS and it's services)
export const UNKNOWN = 'UNKNOWN'

export const EVAL_STATES = Object.freeze({
  pending: 'PENDING',
  approved: 'APPROVED',
  init: 'INIT',
  null: '',
  expired: 'EXPIRED'
})

export const EVAL_LABEL = 'EVALUATION'

export const SERVICE_FEATURE_FLAGS = {
  [UXI]: 'glcp-dm-uxi',
  [WELLNESS]: 'glcp-unified-wellness-dashboard',
  [SFM]: 'glcp-storage-fabric-management',
  [EDGE_CONNECT]: 'glcp-edge-connect',
  [MLDX_MS]: 'glcp-red-cedar',
  [PCAI]: 'glcp-service-pcai',
  [SCAAS]: 'glcp-service-scaas',
  [ZMSP]: 'glcp-service-zerto',
  [VCF]: 'glcp-service-vcf',
  [BACKUP_AND_RECOVERY]: 'glcp-service-backup-and-recovery'
}
export const DISPLAY_RECENT_SERVICES_LENGTH = 8
export const EVAL_SERVICES = [HPECC, BACKUP_AND_RECOVERY]
export const INTERNAL_REDIRECT_SERVICES = [WELLNESS]
export const EVAL_NO_QUOTE_SERVICES = [SFM]

export const DEEP_LINKING = 'DEEP_LINKING'
export const ORG_SINGLETON_SERVICE_PROVISIONING =
  'ORG_SINGLETON_SERVICE_PROVISIONING'

export const MAX_PROVISION_TIMEOUT = 300000 // 5 minutes

export const SERVICES_DISABLE_LAUNCH_FROM_MSP = [PRIVATE_CLOUD_ENTERPRISE, HOC]
