// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { Previous } from 'grommet-icons'
import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import mapKeys from 'lodash/mapKeys'
import pick from 'lodash/pick'

import { AvatarInfo, Button, Typography } from '../../../../components'
import { useCCSManagerContext } from '../../../../context/ccs-manager-context'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import {
  UserDetailsSection,
  UserLogsDataTable,
  UserCustomerDetails
} from './components'

const UserDetailsPage = () => {
  const { t } = useTranslation(['manage'])
  const { username } = useParams()
  const navigate = useNavigate()
  const { selectedCustomer: customerData, selectedUser: userData } =
    useCCSManagerContext()
  const { state: location } = useLocation()
  const fromCustomerDetailPage = location?.fromCustomerDetailPage

  const handleBackBtnClick = () => {
    if (fromCustomerDetailPage)
      navigate('/manage-ccs/customers/customer-details', {
        state: { tabName: 'users' }
      })
    else navigate('/manage-ccs/users')
  }

  const userDetailsData = () => {
    const addressKeys = [
      'street_address',
      'city',
      'state_or_region',
      'zip',
      'country_code'
    ]
    const addressValues = []
    addressKeys.forEach((element) => {
      if (userData[element]) addressValues.push(userData[element])
    })
    const data = pick(userData, [
      'user_status',
      'phone_number',
      'maintenance_notifications',
      'update_notifications',
      'user_type'
    ])
    return mapKeys(
      { address: addressValues.join(', ') || '--', ...data },
      (value, key) => t(`customer_details.${key}`)
    )
  }

  const getSlicedUserDetails = (data) => {
    const numKeys = Object.keys(data).length
    const num = Math.ceil(numKeys / 2)
    const resultArray = []
    for (let i = 0; i < numKeys; i += num) {
      resultArray.push(
        Object.fromEntries(Object.entries(data).slice(i, i + num))
      )
    }
    return resultArray.map((value, index) => (
      <UserDetailsSection
        data={value}
        key={index} /* eslint-disable-line react/no-array-index-key */
      />
    ))
  }

  const getPageSubtitle = () => {
    const firstName = userData.first_name
    const lastName = userData.last_name
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName || '--'
  }

  return (
    <Box>
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={
            fromCustomerDetailPage
              ? `Customer Details - ${customerData?.name}`
              : t('users_tab')
          }
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="user-details-page-back-btn"
        />
      </Box>
      <Box pad={{ horizontal: 'xlarge', top: 'medium' }}>
        <Box
          direction="row-responsive"
          justify="between"
          border={{ side: 'bottom', color: 'border-weak' }}
        >
          <Box
            pad={{ bottom: 'small' }}
            direction="row"
            gap="small"
            align="center"
          >
            <AvatarInfo
              avatarOnly
              primaryInfo={username}
              secondaryInfo={getPageSubtitle()}
              testId="user-info-avatar-only"
              avatarSize="large"
            />
            <Box align="start" justify="between">
              <Typography level="2" type="heading" testId="page-title">
                {username}
              </Typography>
              <Typography size="medium" type="text" testId="page-subtitle">
                {getPageSubtitle()}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Typography
          level="2"
          type="heading"
          testId="title"
          margin={{ vertical: 'medium' }}
        >
          {t('details')}
        </Typography>
        <Box
          direction="row"
          justify="between"
          align="start"
          alignContent="start"
          wrap
        >
          {getSlicedUserDetails(userDetailsData())}
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/accounts/platform/customer',
              permission: 'ccs.accounts.platform.customer.view'
            }}
          >
            <UserCustomerDetails username={username} />
          </VisibilityWrapper>
        </Box>
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/audit-trail',
            permission: 'ccs.audit-trail.view'
          }}
        >
          <>
            <Box margin={{ bottom: 'small' }}>
              <Typography level="3" type="heading" testId="user-audit-logs">
                {t('user_logs')}
              </Typography>
            </Box>
            <UserLogsDataTable username={username} />
          </>
        </VisibilityWrapper>
      </Box>
    </Box>
  )
}

export default UserDetailsPage
