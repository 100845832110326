// Copyright 2024 Hewlett Packard Enterprise Development LP
import { CircleInformation } from 'grommet-icons'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { NoDataInfo } from '../../../components'

const NoViewPermission = () => {
  const { t } = useTranslation(['iam'])

  return (
    <Box alignSelf="center" width="medium">
      <NoDataInfo
        title={t('iam:igc_general.no_perm_title')}
        titleHeadingLevel={2}
        subtitle={t('iam:igc_general.no_perm_desc')}
        icon={<CircleInformation size="xlarge" />}
        testId="no-user-read-perm-warning"
      />
    </Box>
  )
}

export default NoViewPermission
