// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import { StatusCritical } from 'grommet-icons'
import dayjs from 'dayjs'

import {
  DataTable,
  FilterButton,
  Notification,
  ToggleButton
} from '../../../../../../components'
import { getPaginationShowIdx } from '../../../../../../utils/common-utils'
import {
  getIntervalForTrendChart,
  getTime
} from '../../../../../manage-account/audit-logs/components/advancedFiltersUtil'
import SideDetailsPanel from '../../../../common-components/SideDetailsPanel'
import { tableKeys } from '../../customer-details'
import { getTableData } from '../../customer-apis'
import { get } from '../../../../../../utils/api-utils'
import { TrendChart } from '../../../../../manage-account/audit-logs/components/trendcharts/TrendChart'

const CustomerLogsDataTable = ({ customerId }) => {
  const type = 'logs'
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common'])
  const [tableData, setTableData] = useState([])
  const [showDetailsPanel, setShowDetailsPanel] = useState(false)
  const [rowDetails, setRowDetails] = useState(null)
  const [searchVal, setSearchVal] = useState('')
  const [filterOptions, setFilterOptions] = useState({})
  const [filterOptionsRequest, setFilterOptionsRequest] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [categories, setCategories] = useState([])
  const [isTrendChart, setIsTrendChart] = useState(false)

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  // interval for Trend charts
  const [selectedInterval, setSelectedInterval] = useState()

  useEffect(() => {
    get(`/auditlogs/ui/v1/CCS/categories`, {}, oidcUser.access_token).then(
      (res) => {
        const sortedCategory = res?.data.sort() || []
        setCategories(sortedCategory)
      },
      (error) => {
        console.log(error)
      }
    )
  }, [oidcUser.access_token])

  useEffect(() => {
    let request = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      customer_id: customerId,
      ...filterOptions,
      ...(searchVal.trimStart().length > 0 && {
        search_string: searchVal.trimStart()
      })
    }

    getTableData(
      type,
      oidcUser.access_token,
      request,
      setTotalItems,
      setErrorMessage,
      t
    ).then((data) => {
      setTableData(data)
    })

    if (selectedInterval) {
      request = {
        ...filterOptions,
        interval: selectedInterval,
        ...request
      }
    }

    setFilterOptionsRequest(request)
  }, [
    customerId,
    oidcUser.access_token,
    searchVal,
    filterOptions,
    page,
    t,
    selectedInterval
  ])

  const createRowdata = (datum) => {
    const modifiedData = []
    Object.entries(datum).forEach(([key, value]) => {
      if (tableKeys[type] && tableKeys[type][key]) {
        value.forEach((v) => {
          modifiedData.push({
            key: v[tableKeys[type][key].key],
            value: v[tableKeys[type][key].value]
          })
        })
      } else {
        modifiedData.push({
          key: t(key),
          value
        })
      }
    })
    return modifiedData
  }

  const columns = [
    {
      property: 'audit_id',
      type: 'string',
      header: t('audit_id'),
      primary: true
    },
    {
      property: 'category',
      type: 'string',
      header: t('category')
    },
    {
      property: 'target',
      type: 'string',
      header: t('target')
    },
    {
      property: 'time',
      type: 'date',
      header: t('time')
    },
    {
      property: 'description',
      type: 'string',
      header: t('description')
    },
    {
      property: 'username',
      type: 'string',
      header: t('username')
    }
  ]

  const filterAttributes = [
    {
      label: t('category'),
      name: 'category',
      values: categories.map((val) => {
        return { valueLabel: val, valueName: val }
      }),
      height: 'medium'
    },
    {
      label: t('username'),
      name: 'username',
      selectionType: 'text'
    },
    {
      label: t('description'),
      name: 'description',
      selectionType: 'text'
    },
    {
      label: t('target'),
      name: 'target',
      selectionType: 'text'
    },
    {
      label: t('time'),
      name: 'time',
      selectionType: 'dropdown',
      values: [
        {
          valueName: 'Last Hour',
          valueLabel: 'Last Hour'
        },
        {
          valueName: 'Last 3 Hours',
          valueLabel: 'Last 3 Hours'
        },
        {
          valueName: 'Last Day',
          valueLabel: 'Last Day'
        },
        {
          valueName: 'Last Week',
          valueLabel: 'Last Week'
        },
        {
          valueName: 'Last Month',
          valueLabel: 'Last Month'
        },
        {
          valueName: 'Last 3 Months',
          valueLabel: 'Last 3 Months'
        }
      ]
    },
    {
      label: t('custom_date'),
      name: 'custom_date',
      selectionType: 'date-range'
    }
  ]
  return (
    <Box margin={{ bottom: 'small' }}>
      <Box direction="row-responsive" justify="end" align="end">
        <Box>
          <ToggleButton
            checked={isTrendChart}
            label={t('trend_chart')}
            onChange={(e) => {
              setIsTrendChart(e.target.checked || false)
            }}
            testId="trend-chart-btn"
          />
        </Box>
      </Box>

      {isTrendChart && Object.keys(filterOptionsRequest).length > 0 && (
        <Box width="100%" align="center" height="medium">
          <TrendChart filterOptions={filterOptionsRequest} />
        </Box>
      )}

      <Box>
        <DataTable
          pagination={{
            totalItems,
            itemsPerPage,
            page,
            setPage,
            pageIdxInfo
          }}
          filterButton={
            <Box
              direction="row-responsive"
              justify="between"
              width="xlarge"
              align="center"
            >
              <Box>
                <FilterButton
                  filterAttributes={filterAttributes}
                  onFilterValuesChange={(filters) => {
                    let formattedValues = Object.fromEntries(
                      Object.entries(
                        omit(filters, ['custom_date', 'time'])
                      ).map(([key, val]) => [
                        key,
                        val.map((v) => v.trimStart()).join()
                      ])
                    )
                    if (filters.custom_date) {
                      formattedValues = {
                        ...formattedValues,
                        start_time: dayjs(filters.custom_date[0])
                          .startOf('day')
                          .unix(),
                        end_time: dayjs(filters.custom_date[1])
                          .endOf('day')
                          .unix()
                      }
                    }
                    if (filters.time) {
                      const time = getTime({
                        time: [filters.time]
                      })

                      formattedValues = {
                        start_time: time.startTime,
                        end_time: time.endTime,
                        ...formattedValues
                      }
                      setSelectedInterval(
                        getIntervalForTrendChart(filters.time)
                      )
                    } else {
                      setSelectedInterval('90d')
                    }
                    setFilterOptions(
                      omitBy(formattedValues, (v) => v.length === 0)
                    )
                    setPage(1)
                  }}
                  testId="customer-logs-filter-btn"
                />
              </Box>
            </Box>
          }
          grid={{
            columns,
            data: tableData,
            onClickRow: (data) => {
              setRowDetails(createRowdata(data.datum))
              setShowDetailsPanel(true)
            }
          }}
          search={{
            onSearchValueChange: (value) => {
              setPage(1)
              setSearchVal(value)
            },
            placeholder: t('search_box_placeholder')
          }}
          summary={{ entityName: t(`customer_logs`) }}
          testId="customer-logs-data-table"
        />
      </Box>

      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
      {showDetailsPanel ? (
        <SideDetailsPanel
          data={rowDetails}
          hasDetails={
            rowDetails.find((item) => item.key === 'Has Details').value
          }
          onClose={(e) => setShowDetailsPanel(e)}
          type="customer_logs"
        />
      ) : null}
    </Box>
  )
}

CustomerLogsDataTable.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

export { CustomerLogsDataTable }
