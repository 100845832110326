// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, Grommet, TextArea, TextInput } from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { deepMerge } from 'grommet/utils'
import { hpe } from 'grommet-theme-hpe'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  Typography,
  ButtonGroup,
  CCSForm,
  Dropdown
} from '../../../components'
import { get, post } from '../../../utils/api-utils'
import {
  validateTenantName,
  validateStreetAddress,
  validateZipCode,
  validateCity,
  validateMaxLength,
  validateTenantDescription
} from '../../../utils/validation-utils'
import { DESCRIPTION_MAX_LIMIT } from '../utils'
import { getApiErrorMessage } from '../../../utils/error-handling-utils'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

const AddCustomerModal = ({ setShowCreateCustomerModal, onSuccess }) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn', 'common'])
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [loader, setLoader] = useState(false)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      company_name: '',
      description: '',
      country_code: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state_or_region: '',
      zip: ''
    }
  )

  const handleSetUpAccount = () => {
    setLoader(true)
    setErrorMessage('')
    const requestBody = {
      company_name: state.company_name,
      description: state.description,
      address: {
        street_address: state.street_address,
        street_address_2: state.street_address_2,
        city: state.city,
        state_or_region: state.state_or_region,
        zip: state.zip,
        country_code: state.country_code
      }
    }

    post(
      '/accounts/ui/v1/managed-service/tenant',
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setLoader(false)
        onSuccess()
      },
      (error) => {
        let backendErrorMessage = ''
        setLoader(false)
        if (error?.response?.status === 403)
          backendErrorMessage = t('common:error_messages.ERR_403')
        else backendErrorMessage = getApiErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }
  useEffect(() => {
    let params = { status: 'AVAILABLE' }
    get('/ui-doorway/ui/v1/status', {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response?.data?.flags?.gts_compliance_flag) {
            params = {}
          }
        },
        (error) => {
          const backendErrorMessage = getApiErrorMessage(error, t)
          setErrorMessage(backendErrorMessage)
        }
      )
      .finally(() => {
        get('/geo/ui/v1/countries', params).then(
          (response) => {
            setListOfCountries(response.data.countries)
            setCountries(response.data.countries)
          },
          (error) => {
            const backendErrorMessage = getApiErrorMessage(error, t)
            setErrorMessage(backendErrorMessage)
          }
        )
      })
  }, [t, oidcUser.access_token])
  return (
    <ModalDialog
      width="medium"
      testId="add-customer-modal-dialog"
      content={
        <Box gap="small" pad={{ right: 'medium' }}>
          <Box flex={{ shrink: 0 }}>
            <Typography type="heading" level="1" testId="add-customer-title">
              {t('customer_account.create_customer_account', {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
            </Typography>
            <Typography type="paragraph" testId="add-customer-subtitle">
              {t('customer_account.create_customer_account_subtitle', {
                account: getWorkspaceString(showWorkspaceString, t, WKSPC)
              })}
            </Typography>
          </Box>

          <CCSForm
            value={state}
            onReset={() => {
              setShowCreateCustomerModal(false)
            }}
            onSubmit={handleSetUpAccount}
            errorMessage={errorMessage}
            validate="change"
            testId="add-customer-form"
          >
            <Box gap="small">
              <FormField
                label={t('customer_account.company_name', {
                  company: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                name="company_name"
                required
                data-testid="company-name-form-field"
                validate={(value) => {
                  if (validateTenantName(value))
                    return t('customer_account.tenant_name_error_message')
                  return true
                }}
                disabled={loader}
              >
                <TextInput
                  value={state.company_name}
                  name="company_name"
                  placeholder={t('customer_account.company_placeholder', {
                    company: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                  data-testid="company-name-form-input"
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'company_name',
                      type: 'CHANGE_FIELD'
                    })
                  }
                  disabled={loader}
                />
              </FormField>
              <Grommet
                theme={deepMerge(hpe, {
                  formField: {
                    error: {
                      color: 'status-critical',
                      container: { direction: 'row', justify: 'end' },
                      icon: <></>
                    },
                    info: {
                      container: { direction: 'row', justify: 'end' },
                      color: loader ? 'status-disabled' : 'text'
                    }
                  }
                })}
              >
                <FormField
                  label={t('customer_account.description')}
                  name="description"
                  data-testid="description-form-field"
                  help={
                    <Typography
                      type="text"
                      size="small"
                      color={loader ? 'status-disabled' : 'text'}
                      testId="customer-desc-info"
                    >
                      {!validateMaxLength(
                        state?.description,
                        DESCRIPTION_MAX_LIMIT
                      )
                        ? t('customer_account.customer_description_help_text', {
                            max_limit: DESCRIPTION_MAX_LIMIT
                          })
                        : t(
                            'customer_account.add_customer_description_error_msg'
                          )}
                    </Typography>
                  }
                  required
                  validate={() => {
                    return validateTenantDescription(
                      state?.description,
                      DESCRIPTION_MAX_LIMIT,
                      t('customer_account.customer_description_info_text', {
                        current_length: state?.description?.length,
                        max_limit: DESCRIPTION_MAX_LIMIT
                      })
                    )
                  }}
                  disabled={loader}
                >
                  <TextArea
                    fill
                    data-testid="description-input"
                    name="description"
                    placeholder={t('customer_account.description_placeholder', {
                      company: getWorkspaceString(
                        showWorkspaceString,
                        t,
                        WKSPC_CAPITALIZED
                      )
                    })}
                    value={state.description}
                    onChange={(e) => {
                      dispatch({
                        value: e.target.value,
                        field: 'description',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    disabled={loader}
                  />
                </FormField>
              </Grommet>

              <Box gap="xsmall">
                <FormField
                  label={t('customer_account.company', {
                    company: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  })}
                  name="country_code"
                  required
                  data-testid="country-form-field"
                  disabled={loader}
                >
                  <Dropdown
                    name="country_code"
                    placeholder={t('customer_account.country')}
                    options={countries}
                    multiple={false}
                    labelKey="name"
                    value={state.country_code}
                    valueKey={{ key: 'code', reduce: true }}
                    searchPlaceholder={t('customer_account.country')}
                    emptySearchMessage={t(
                      'customer_account.country_empty_search_message'
                    )}
                    onSearch={(searchText) => {
                      const regexp = new RegExp(searchText, 'i')
                      setCountries(
                        listOfCountries.filter((o) => o?.name?.match(regexp))
                      )
                    }}
                    onChange={({ option }) => {
                      dispatch({
                        value: option?.code,
                        field: 'country_code',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                    onClose={() => setCountries(listOfCountries)}
                    noBorder
                    testId="country-input"
                    disabled={loader}
                  />
                </FormField>

                <FormField
                  name="street_address"
                  required
                  validate={(value) => {
                    if (!validateStreetAddress(value))
                      return t('customer_account.max_chars_exceeded')
                    return true
                  }}
                  data-testid="street-address-form-field"
                  disabled={loader}
                >
                  <TextInput
                    value={state.street_address}
                    name="street_address"
                    placeholder={t('customer_account.street_address')}
                    data-testid="street-address-form-input"
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'street_address',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    disabled={loader}
                  />
                </FormField>

                <VisibilityWrapper
                  hideFor={{
                    feature: 'glcp-msp-add-customer-field'
                  }}
                >
                  <FormField
                    name="street_address_2"
                    validate={(value) => {
                      if (!validateStreetAddress(value))
                        return t('customer_account.max_chars_exceeded')
                      return true
                    }}
                    data-testid="street-address-2-form-field"
                    disabled={loader}
                  >
                    <TextInput
                      value={state.street_address_2}
                      name="street_address_2"
                      data-testid="street-address-2-form-input"
                      placeholder={t('customer_account.apt_suite_building')}
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'street_address_2',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={loader}
                    />
                  </FormField>
                </VisibilityWrapper>

                <Box direction="row-responsive" gap="small">
                  <FormField
                    name="city"
                    validate={(city) => {
                      if (city && !validateCity(city.trim()))
                        return t('customer_account.max_chars_exceeded_city')
                      return true
                    }}
                    data-testid="city-form-field"
                    disabled={loader}
                  >
                    <TextInput
                      value={state.city}
                      name="city"
                      data-testid="city-form-input"
                      placeholder={t('customer_account.city')}
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'city',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={loader}
                    />
                  </FormField>

                  <FormField
                    name="state_or_region"
                    data-testid="state-or-region-form-field"
                    disabled={loader}
                  >
                    <TextInput
                      value={state.state_or_region}
                      name="state_or_region"
                      data-testid="state-or-region-form-input"
                      placeholder={t('customer_account.region')}
                      onChange={(event) =>
                        dispatch({
                          value: event.target.value,
                          field: 'state_or_region',
                          type: 'CHANGE_FIELD'
                        })
                      }
                      disabled={loader}
                    />
                  </FormField>
                </Box>

                <FormField
                  name="zip"
                  data-testid="zip-form-field"
                  validate={(value) => {
                    if (!validateZipCode(value))
                      return t('customer_account.max_chars_exceeded')
                    return true
                  }}
                  disabled={loader}
                >
                  <TextInput
                    value={state.zip}
                    name="zip"
                    data-testid="zip-form-input"
                    placeholder={t('customer_account.zip_placeholder')}
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: 'zip',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    disabled={loader}
                  />
                </FormField>
              </Box>

              <ButtonGroup
                buttonList={[
                  {
                    label: t('customer_account.cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    type: 'reset',
                    style: { flexShrink: 0 }
                  },
                  {
                    label: t('customer_account.createAccount', {
                      account: getWorkspaceString(
                        showWorkspaceString,
                        t,
                        WKSPC_CAPITALIZED
                      )
                    }),
                    primary: true,
                    type: 'submit',
                    testId: 'create-btn',
                    isLoading: loader,
                    style: { flexShrink: 0 }
                  }
                ]}
                testId="add-customer-modal-action-buttons"
              />
            </Box>
          </CCSForm>
        </Box>
      }
      onClose={() => setShowCreateCustomerModal(false)}
    />
  )
}

AddCustomerModal.propTypes = {
  setShowCreateCustomerModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export { AddCustomerModal }
