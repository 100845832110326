// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import { Box, List, Select, Text } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'

import { get } from '../../../../utils/api-utils'
import { Loader } from '../../../../components'

const AssetsByAge = () => {
  const { t } = useTranslation(['manage', 'common'])
  const ageRanges = [
    {
      label: t('asset_management.widgets.assets_by_age.less_than_three_years'),
      min: 0,
      max: 3
    },
    {
      label: t('asset_management.widgets.assets_by_age.three_to_five_years'),
      min: 3,
      max: 5
    },
    {
      label: t('asset_management.widgets.assets_by_age.more_than_five_years'),
      min: 5,
      max: 100
    }
  ]

  const [isLoading, setIsLoading] = React.useState(false)
  const [assetByAgeGroupData, setAssetByAgeGroupData] = React.useState([])
  const [selectedAgeRange, setSelectedAgeRange] = useState(ageRanges[2])
  const { oidcUser } = useReactOidc()

  const url = `/internal-assets/v1beta1/age-stats?minAge=${selectedAgeRange?.min}&maxAge=${selectedAgeRange?.max}`

  const getAssetByAgeGroupData = () => {
    setIsLoading(true)
    get(url, {}, oidcUser?.access_token).then(
      (response) => {
        setIsLoading(false)
        setAssetByAgeGroupData(response?.data)
      },
      (error) => {
        setIsLoading(false)
        console.error('Failed to fetch asset workload data:', error)
      }
    )
  }

  useEffect(() => {
    getAssetByAgeGroupData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, oidcUser?.access_token, selectedAgeRange])

  const filteredItems = assetByAgeGroupData?.items?.slice(0, 5) || []

  const handleAgeRangeChange = (option) => {
    if (option?.label !== selectedAgeRange?.label) {
      setSelectedAgeRange(option)
    }
  }

  return isLoading ? (
    <Loader testId="asset-age-loader" isLoading={isLoading} />
  ) : (
    <Box
      key="assets-by-age-body-container"
      data-testid="assets-by-age-body-container"
      gap="small"
    >
      <Select
        data-testid="assets-by-age-select-dropdown"
        key="assets-by-age-select-dropdown"
        options={ageRanges}
        labelKey="label"
        value={selectedAgeRange}
        onChange={({ option }) => handleAgeRangeChange(option)}
        placeholder={t(
          'asset_management.widgets.assets_by_age.select_age_range'
        )}
      />
      {filteredItems?.length > 0 ? (
        <List
          data-testid="assets-by-age-list"
          primaryKey={(item, index) => (
            <Text data-testid={`${item?.model}_${index}_PrimaryKey`}>
              {item.model}
            </Text>
          )}
          secondaryKey={(item, index) => (
            <Text data-testid={`${item?.model}_${index}_secondaryKey`}>
              <strong>{item?.total}</strong>
            </Text>
          )}
          data={filteredItems}
          pad={{ horizontal: 'none', vertical: 'small' }}
          key={(item, index) => item?.model + index}
        />
      ) : (
        <Text>
          {t(
            'asset_management.widgets.assets_by_age.no_devices_under_this_age'
          )}
        </Text>
      )}
    </Box>
  )
}

export default AssetsByAge
