// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Anchor, Box, Tag } from 'grommet'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isObject from 'lodash/isObject'
import values from 'lodash/values'

import { get } from './api-utils'
import { displayApiError } from './error-handling-utils'
import { isCoP, isGLOP } from './feature-flag-utils'

// fetching tiers & returning modified response to display under Subcription Tier Filter
export const getSubscriptionTiers = ({ LDFlags, oidcUser, magicLink }) => {
  const {
    'glcp-dm-silver-peak': dmSilverPeakFlag,
    'glcp-dm-pce': dmPCEFlag, // PCE LD is added to check PCE workflow in StandAlone Account just for testing purpose untill all services support PCE changes
    'glcp-dm-uxi': dmUXIFlag,
    'glcp-5g-bridge': dmBridgeFlag
  } = LDFlags
  if (!isCoP()) {
    return get(
      `/ui-doorway/ui/v1/license/tiers`,
      {
        device_group: `AP,GATEWAY,SWITCH,COMPUTE,DHCI_COMPUTE,STORAGE,DHCI_STORAGE,SD_SAAS,OPSRAMP_SAAS,MLDE_SAAS${
          dmSilverPeakFlag ? ',SD_WAN_GW' : ''
        }${dmUXIFlag ? ',SENSOR' : ''}${dmPCEFlag ? ',PCE' : ''}${
          dmBridgeFlag ? ',BRIDGE' : ''
        }`
      },
      oidcUser.access_token
    ).then(
      (response) => {
        // To combine tiers from different objects
        const tiers =
          response?.data?.supported_subscription_tiers_by_device_type?.reduce(
            (prev, curr) => {
              return { ...prev, ...curr.subscription_tiers_description }
            },
            {}
          )
        const tierOptions = Object.entries(tiers).map(
          ([valueName, valueLabel]) =>
            magicLink
              ? { label: valueLabel, value: valueName }
              : {
                  valueName,
                  valueLabel
                }
        )
        return Promise.resolve(tierOptions || [])
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }
  return Promise.resolve([])
}

export const getSubscriptionFilterOptions = (
  licenseTierList,
  t,
  allTagsList = [],
  subcriptionTagsFlag = false,
  LDFlags,
  showAllFilters,
  setSearchTagsFilterText
) => {
  const { 'glcp-subscription-expiry': subscriptionExpiryFlag } = LDFlags
  return [
    ...(!isCoP()
      ? [
          {
            label: t('device:subscription_tier'),
            name: 'subscription_tier',
            selectionType: 'select-multiple',
            values: licenseTierList,
            height: 'small',
            width: 'small'
          }
        ]
      : []),

    ...(showAllFilters
      ? [
          {
            label: t('device:expiration_date'),
            name: 'end_date_in_millis',
            selectionType: 'radio',
            values: [
              {
                valueLabel: t('less_than_30days'),
                valueName: '30'
              },
              {
                valueLabel: t('less_than_60days'),
                valueName: '60'
              },
              {
                valueLabel: t('less_than_90days'),
                valueName: '90'
              }
            ]
          },
          {
            label: t('licensing:subscription_visibility'),
            name: 'subscription_visibility',
            selectionType: 'radio',
            values: [
              {
                valueLabel: t('licensing:hide_expired_subscriptions'),
                valueName: 'UNEXPIRED_ONLY'
              },
              {
                valueLabel: t('licensing:show_all_subscriptions'),
                valueName: 'ALL'
              },
              {
                valueLabel: t('licensing:show_expired_subscriptions_only'),
                valueName: 'EXPIRED_ONLY'
              }
            ]
          },
          ...(subscriptionExpiryFlag || isGLOP()
            ? [
                {
                  label: t('licensing:subscription_type'),
                  name: 'evaluation_type',
                  selectionType: 'radio',
                  values: [
                    {
                      valueLabel: t('licensing:evaluation'),
                      valueName: 'EVAL'
                    },
                    {
                      valueLabel: t('licensing:paid'),
                      valueName: 'NONE'
                    }
                  ]
                }
              ]
            : [])
        ]
      : []),
    ...(subcriptionTagsFlag
      ? [
          {
            label: t('tags.tags_filter_title'),
            name: 'tags',
            selectionType: 'search',
            values: allTagsList,
            updateValues: (searchTagsFilterTxt) => {
              setSearchTagsFilterText(searchTagsFilterTxt)
            },
            selectedValuesRenderer: (suggestion, onRemoveSelection) => {
              const tmpArr = suggestion.label.split(':')
              return (
                <Box margin={{ top: 'small' }}>
                  <Tag
                    size="small"
                    alignSelf="start"
                    name={tmpArr[0]?.trim()}
                    value={tmpArr[1]?.trim()}
                    onRemove={onRemoveSelection}
                    data-testid={suggestion.value}
                  />
                </Box>
              )
            }
          }
        ]
      : [])
  ]
}

export const getOnPremWorkSpaces = async (accessToken, t, setErrorMessage) => {
  const url = '/ui-doorway/ui/v1/onprem-workspaces'
  return get(url, {}, accessToken).then(
    (resp) => {
      return resp?.data
    },
    (error) => {
      setErrorMessage(displayApiError(error, t, setErrorMessage))
      return {
        items: []
      }
    }
  )
}

export const NO_VALUE = '--'

export const getValue = (value) => {
  return value || NO_VALUE
}

export const getOnPremWOrkpsaceAnchor = (
  value,
  onPremWorkSpaceResourceId,
  navigate
) => {
  if (!value) {
    return NO_VALUE
  }
  return (
    <Anchor
      label={value}
      data-testid="onprem-workspace-anchor"
      onClick={(event) => {
        event.preventDefault()
        navigate(
          `/manage-account/on-prem-workspaces/${onPremWorkSpaceResourceId}`
        )
      }}
    />
  )
}

export const getDateByMonthAbvDayYear = (dateEpoch) => {
  let dateValue = '--'
  if (dateEpoch && dayjs(dateEpoch).isValid()) {
    dateValue = dayjs(dateEpoch).format('MMM D, YYYY')
  }
  return dateValue
}

// To get day in millis
export const getDateInMilliseconds = (days) => {
  dayjs.extend(duration)
  const currentDateInMilliseconds = dayjs().valueOf()
  const daysInMilliseconds = dayjs
    .duration({ days: days?.toString() })
    .asMilliseconds()
  // current timestamp + days in milliseconds equivalent to 30*24*60*60*1000
  return currentDateInMilliseconds + daysInMilliseconds
}

// To get flattened object in nested object
export const getFlattenedObj = (value) => {
  if (Array.isArray(value)) {
    return value
  }
  if (isObject(value)) {
    return values(value)
  }
  return value
}

export const queryParamsBasedOnTab = (tabName) => {
  const currentDateInMilliseconds = dayjs().valueOf()
  const queryParamObj = {
    expiringIn30Days: {
      expire_date_cut_off_in_millis: currentDateInMilliseconds,
      end_date_in_millis: ['30'],
      evaluation_type: ['NONE']
    },
    expiringIn90Days: {
      expire_date_cut_off_in_millis: getDateInMilliseconds(30),
      end_date_in_millis: ['90'],
      evaluation_type: ['NONE']
    },
    evalsExpiringIn30Days: {
      expire_date_cut_off_in_millis: currentDateInMilliseconds,
      end_date_in_millis: ['30'],
      evaluation_type: ['EVAL']
    },
    totalSubscriptions: {
      subscription_visibility: ['UNEXPIRED_ONLY']
    }
  }
  return queryParamObj[tabName]
}

export const TAG_NAME_MAX_LENGTH = 128

export const extractUomCommitData = (uomConcAttr) => {
  const uomConcAttrValues = uomConcAttr?.value?.split('|')
  const uomConcAttrUomMinCommitPair = uomConcAttrValues.map((el) => {
    return el?.split(',')
  })
  if (uomConcAttrUomMinCommitPair?.length < 1) {
    return null
  }
  const validResult = uomConcAttrUomMinCommitPair.map((el) => {
    const [uom, minCommit] = el
    return {
      uom,
      minCommit: !minCommit ? null : minCommit
    }
  })
  return validResult
}

export const getCapacityRevision = (effectiveDate, minCommit) => {
  return {
    effectiveDate,
    installedCapacity: 0,
    minCommit: minCommit ? Number(minCommit) : 0,
    minCommitPct: 0,
    minCommitDiscount: 0,
    discountPct: 0
  }
}

export const VGW_DEVICE_MODEL = 'VGW'

export const VGW_DEVICE_PARTNUM = 'MC-VA'
