// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { ModalDialog, ModalHeader, Typography } from '../../components'

const AssignToOnPremInfoModal = ({ setOnPremWorkSpaceModal }) => {
  const { t } = useTranslation(['manage'])
  return (
    <ModalDialog
      width="medium"
      testId="service-region-assigned-info-modal"
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2" testId="header-title">
              {t('onprem_workspace.assign_to_onprem')}
            </Typography>
          }
          onClose={() => {
            setOnPremWorkSpaceModal(false)
          }}
        />
      }
      content={
        <Typography type="text" size="medium" testId="header-subtitle-1">
          {t('onprem_workspace.service_region_info')}
        </Typography>
      }
      onClose={() => {
        setOnPremWorkSpaceModal(false)
      }}
    />
  )
}

AssignToOnPremInfoModal.propTypes = {
  setOnPremWorkSpaceModal: PropTypes.func.isRequired
}

export default AssignToOnPremInfoModal
