// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
export const NAME_MAX_LEN = 100
export const DESC_MAX_LEN = 255
export const STREET_ADD_MAX_LEN = 100
export const CITY_STATE_MAX_LEN = 60
export const POSTAL_CODE_MAX_LEN = 20
export const PHONE_MAX_LEN = 20

const findInvalidCharsInString = (regex, string) => {
  // remove repeat char and convert to char array
  const noRepeats = new Set(string)
  const charArray = [...noRepeats]

  // generate not allowed string to pass to i18n
  let notAllowedChars = ''
  charArray.forEach((char) => {
    // when char does not match it is not allowed
    if (!regex.test(char)) {
      notAllowedChars = `${notAllowedChars} ${char} `
    }
  })
  return notAllowedChars
}
export const validateUserInput = (userInput, isAddressFlagEnabled) => {
  const regex = isAddressFlagEnabled
    ? /^[A-Za-z0-9#+,.'\-_&()@%/\s\p{L}〒]*$/u // BE pattern for reference pattern "^[A-Za-z0-9#+,.'\\-_&()@%/\\s\\p{L}〒]*$"
    : /^[A-Za-z0-9.,'\-_#@%&/+\s]*$/

  if (regex.test(userInput)) return undefined

  return {
    message: 'invalid_characters_error',
    status: 'error',
    notAllowedChars: findInvalidCharsInString(regex, userInput)
  }
}

export const validateLocationCityState = (userInput) => {
  const regex = /^[A-Za-z\s]+$/
  if (regex.test(userInput)) return undefined
  return {
    message: 'invalid_characters_error',
    status: 'error',
    notAllowedChars: findInvalidCharsInString(regex, userInput)
  }
}

export const validateLocationPhoneNum = (userInput) => {
  if (/^\+[\d\s]+$/.test(userInput)) return undefined
  return {
    message: 'phone_error',
    status: 'error'
  }
}
