// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const APILandingPage = lazy(() => import('./APILanding'))

const APIRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<APILandingPage />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default APIRouter
