// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../../../utils/common-utils'

import { RolesPage } from './RolesPage'
import { RoleDetails } from './RoleDetails'

const AuthzV2RolesRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <Routes>
      <Route exact path="/" element={<RolesPage />} />
      <Route exact path="/:grn" element={<RoleDetails />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export { AuthzV2RolesRouter }
