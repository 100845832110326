// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react'
import { Box, Text } from 'grommet'
import PropTypes from 'prop-types'

import { Button } from '../../../../components'

const SubscriptionTierList = ({ data }) => {
  const [showMore, setshowMore] = useState(false)
  const handleViewmoreClick = () => {
    setshowMore(!showMore)
  }
  return (
    <Box>
      {data?.slice(0, showMore ? data?.length : 3).map((item, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <Text key={index}>{item?.tiers_description[item?.tier]}</Text> // NOSONAR
      })}
      {data?.length > 3 && !showMore && (
        <Button
          size="xsmall"
          label={!showMore ? `View More(${data.length})` : 'View less'}
          onClick={handleViewmoreClick}
        />
      )}
    </Box>
  )
}

SubscriptionTierList.propTypes = {
  data: PropTypes.array.isRequired
}

export default SubscriptionTierList
