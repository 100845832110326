// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect, useRef, useState } from 'react'
import { FormDown, Previous } from 'grommet-icons'
import { Box, PageHeader } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Anchor, ActionButton, Loader, Typography } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { get, getErrorMessage } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import NoViewPermission from '../commoncomponents/NoViewPermission'
import {
  getOrganizationId,
  isAssociateWorkspace
} from '../../../utils/feature-flag-utils'

import DetailsUserGroup from './components/DetailsUserGroup'
import WorkspaceMembership from './components/WorkspaceMembership'
import { UserDetailDetailsSection } from './components/UserDetailDetailsSection'
import RemoveUserModal from './components/RemoveUserModal'
import { USER_SCHEMAS } from './constants'

const UserDetailsContent = () => {
  const { t } = useTranslation(['common', 'iam'])
  const { userId } = useParams()
  const navigate = useNavigate()
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  // Note: need to implement like this for RBAC OR logic
  const v2UserReadPerm = v2RbacPolicies?.includes('identity.users.read')
  const v2GroupReadPerm = v2RbacPolicies?.includes('identity.user-groups.read')
  const ccsAuthView = v2RbacPolicies?.includes('ccs.authorization.view')
  const v2AssociateUserReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.users.read'
  )
  const v2AssociateGroupReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.user-groups.read'
  )
  const ccsAssociateAuthView = v2RbacRootWkspcPolicies?.includes(
    'ccs.authorization.view'
  )
  const [headerLoading, setHeaderLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const ref = useRef(false)
  const [userDetailsData, setUserDetailsData] = useState({})
  const [showRemoveUserDialog, setShowRemoveUserDialog] = useState(false)
  const [notifTitle, setNotifTitle] = useState('')
  const [notifMsg, setNotifMsg] = useState('')
  const [notifSeverity, setNotifSeverity] = useState('')
  const [isAssociated, setIsAssociated] = useState(false)

  const setNotificationInfo = (message, severity, title = '') => {
    setNotifTitle(title)
    setNotifMsg(message)
    setNotifSeverity(severity)
  }

  const actionBtns = [
    {
      label: t('common:delete'),
      testId: 'remove-user-btn',
      onClick: () => {
        setShowRemoveUserDialog(true)
      }
    }
  ]
  useEffect(() => {
    const orgId = getOrganizationId()
    if (orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (res) => {
          if (res?.data && res?.data?.associatedWorkspace) {
            setIsAssociated(
              isAssociateWorkspace(res?.data?.associatedWorkspace?.id)
            )
          }
        },
        (err) => {
          setNotificationInfo(getErrorMessage(err, t), 'error')
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    ref.current = true

    const getUserDetailsData = async () => {
      try {
        const userData = await get(`/identity/v2alpha1/scim/v2/Users/${userId}`)

        const result = {
          id: userData?.data?.id,
          userName: userData?.data?.userName,
          displayName: userData?.data?.displayName,
          givenName: userData?.data?.name?.givenName,
          familyName: userData?.data?.name?.familyName,
          verified:
            userData?.data[USER_SCHEMAS?.EXTENSIONS]?.primaryEmailVerified,
          lastLogin: userData?.data?.meta?.lastLogin,
          created: userData?.data?.meta?.created,
          lastModified: userData?.data?.meta?.lastModified
        }

        if (!ref.current) return
        setUserDetailsData(result)

        setHeaderLoading(false)

        if (!ref.current) return
        setLoading(false)
      } catch (error) {
        if (!ref.current) return
        setNotificationInfo(getErrorMessage(error, t), 'error')
        setHeaderLoading(false)
        setLoading(false)
      }
    }
    getUserDetailsData()

    return () => {
      ref.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, t])

  return (
    <Box
      pad={{ horizontal: 'xlarge', bottom: 'large' }}
      width="xxlarge"
      alignSelf="center"
    >
      <PageHeader
        title={
          headerLoading ? (
            <Loader testId="user-details-header-loader" />
          ) : (
            ((!isAssociated && (v2UserReadPerm || ccsAuthView)) ||
              (isAssociated &&
                (v2AssociateUserReadPerm || ccsAssociateAuthView))) && (
              <Typography
                type="heading"
                level="1"
                testId="user-details-header-title"
              >
                {userDetailsData?.displayName || userDetailsData?.userName}
              </Typography>
            )
          )
        }
        subtitle={
          headerLoading ||
          isEmpty(userDetailsData?.displayName) ||
          !(!isAssociated && (v2UserReadPerm || ccsAuthView)) ||
          !(isAssociated && (v2AssociateUserReadPerm || ccsAssociateAuthView))
            ? null
            : userDetailsData?.userName
        }
        parent={
          <Anchor
            label={t('iam:organization.organization_users_card_title')}
            href="#"
            icon={<Previous />}
            onClick={(event) => {
              event?.preventDefault()
              navigate('/manage-account/organization/users')
            }}
            testId="user-details-page-back-btn"
          />
        }
        actions={
          ((isAssociated &&
            v2RbacRootWkspcPolicies?.includes('identity.users.delete')) ||
            (!isAssociated &&
              v2RbacPolicies?.includes('identity.users.delete'))) &&
          ((!isAssociated && (v2UserReadPerm || ccsAuthView)) ||
            (isAssociated &&
              (v2AssociateUserReadPerm || ccsAssociateAuthView))) && (
            <ActionButton
              label={t('common:actions')}
              actions={actionBtns}
              icon={<FormDown />}
              reverse // Puts icon at the end instead of in front of label
              showOneActionAsDropDown
              testId="user-details-action-btn"
            />
          )
        }
      />
      {(isAssociated && (v2AssociateUserReadPerm || ccsAssociateAuthView)) ||
      (!isAssociated && (v2UserReadPerm || ccsAuthView)) ? (
        <>
          <UserDetailDetailsSection
            userDetailsData={userDetailsData}
            parentLoading={loading}
          />
          {((isAssociated &&
            v2RbacRootWkspcPolicies?.includes('organization.workspace.read')) ||
            (!isAssociated &&
              v2RbacPolicies?.includes('organization.workspace.read'))) && (
            <WorkspaceMembership
              setNotificationInfo={setNotificationInfo}
              parentLoading={loading}
            />
          )}
          {((isAssociated &&
            (v2AssociateGroupReadPerm || ccsAssociateAuthView)) ||
            (!isAssociated && (v2GroupReadPerm || ccsAuthView))) && (
            <DetailsUserGroup
              user={userDetailsData}
              setNotificationInfo={setNotificationInfo}
              isAssociated={isAssociated}
            />
          )}
        </>
      ) : (
        <NoViewPermission />
      )}
      {showRemoveUserDialog && (
        <RemoveUserModal
          onSetOpen={setShowRemoveUserDialog}
          userToRemove={userDetailsData}
          setNotificationInfo={setNotificationInfo}
        />
      )}
      {notifMsg &&
        displayNotification(notifMsg, notifSeverity, setNotifMsg, notifTitle)}
    </Box>
  )
}

const UserDetails = () => {
  return (
    <Layout>
      <UserDetailsContent />
    </Layout>
  )
}

export { UserDetails }
