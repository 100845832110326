// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, Layer, ResponsiveContext, Button } from 'grommet'
import { Close } from 'grommet-icons'

import { Typography } from '../typography/Typography'

export const ModalDialog = ({
  position = 'center',
  width = undefined,
  height = undefined,
  header = undefined,
  icon = undefined,
  content = undefined,
  footer = undefined,
  onClose,
  testId,
  ...rest
}) => {
  const size = useContext(ResponsiveContext)

  let full
  if (
    (width === '100%' && height === '100%') ||
    size === 'small' ||
    size === 'xsmall'
  ) {
    full = true
  } else if (width === '100%') {
    full = 'horizontal'
  } else if (height === '100%') {
    full = 'vertical'
  } else {
    full = false
  }

  return (
    <Layer
      trapFocus
      position={position}
      full={full}
      modal
      onEsc={onClose}
      data-testid={testId}
      {...rest}
    >
      <Box
        direction="column"
        width={width}
        height={height}
        gap="medium"
        pad={(rest && rest.pad) || 'medium'}
        overflow="auto"
      >
        <Box direction="row" basis="100%" gap="medium">
          {icon && <Box data-testid="icon">{icon}</Box>}
          <Box direction="column" basis="100%">
            {header}
            {content && (
              <Box
                basis="100%"
                data-testid="content"
                overflow="auto"
                elevation={rest.elevation}
              >
                {content}
              </Box>
            )}
          </Box>
        </Box>
        {footer}
      </Box>
    </Layer>
  )
}

const dimensions = [
  'xxsmall',
  'xsmall',
  'small',
  'medium',
  'large',
  'xlarge',
  'xxlarge',
  '100%'
]

ModalDialog.propTypes = {
  /**
   * Position of the modal dialog; default is center
   */
  position: PropTypes.oneOf([
    'bottom',
    'bottom-left',
    'bottom-right',
    'center',
    'end',
    'hidden',
    'left',
    'right',
    'start',
    'top',
    'top-left',
    'top-right'
  ]),

  /**
   * width of the dialog
   */
  width: PropTypes.oneOf(dimensions),

  /**
   * height of the dialog
   */
  height: PropTypes.oneOf(dimensions),

  /**
   * Modal header of type ModalHeader
   */
  header: PropTypes.element,

  /**
   * dialog icon displayed to the left of content
   */
  icon: PropTypes.element,

  /**
   * Content of the dialog - use storybook layout components
   */
  content: PropTypes.element,

  /**
   * Modal footer of type ModalFooter
   */
  footer: PropTypes.element,

  /**
   * Function invoked on escape
   */
  onClose: PropTypes.func.isRequired,

  /**
   * data-testid, will be used for testing
   */
  testId: PropTypes.string.isRequired
}

export const ModalHeader = ({
  title = undefined,
  subtitle = '',
  onClose = null
}) => {
  return (
    <Box direction="row" gap="small" align="start" flex="grow">
      <Box direction="column" basis="100%" data-testid="header">
        {title}
        {subtitle &&
          (typeof subtitle === 'string' ? (
            <Typography type="text" size="medium" testId="header-subtitle">
              {subtitle}
            </Typography>
          ) : (
            subtitle
          ))}
      </Box>
      {onClose && (
        <Box direction="row" pad="none">
          <Button
            icon={<Close />}
            onClick={onClose}
            plain
            data-testid="close-button"
          />
        </Box>
      )}
    </Box>
  )
}

ModalHeader.propTypes = {
  /**
   * Dialog's title - use Typography component
   */
  title: PropTypes.element,

  /**
   * Dialog's subtitle showing under title in smaller font
   */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * hide or show top right corner close button; default is false
   */
  onClose: PropTypes.func
}

export const ModalFooter = ({ left = undefined, right = undefined }) => {
  return (
    <Box direction="row" justifyContent="between" height={{ min: 'auto' }}>
      <Box data-testid="footer-left">{left}</Box>
      <Box flex="grow" />
      <Box data-testid="footer-right">{right}</Box>
    </Box>
  )
}

ModalFooter.propTypes = {
  /**
   * left footer component
   */
  left: PropTypes.element,

  /**
   * right footer component
   */
  right: PropTypes.element
}
