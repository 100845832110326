// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useNavigate } from 'react-router-dom'

export const ActivateMagicLinkRedirection = () => {
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(window.location.search)
  const subscriptionProductSquid = searchParams?.get('squid')
  const workflow = searchParams?.get('flow')
  if (subscriptionProductSquid && workflow === 'subscription') {
    sessionStorage.setItem('subscriptionProductSquid', subscriptionProductSquid)
    navigate('/')
  } else {
    navigate('/error/invalid-link')
  }
  return null
}
