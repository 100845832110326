// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Anchor, Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { FormDown, Install, Filter } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-enable */

import useLD from '../../../hooks/ld/useLD'
import {
  ActionButton,
  Button,
  DataTable,
  NoDataInfo,
  Typography,
  Loader
} from '../../../components'
import { getApiErrorMessage } from '../../../utils/error-handling-utils'
import { get, getErrorMessage } from '../../../utils/api-utils'
import {
  getPaginationShowIdx,
  getWorkspaceString,
  WKSPC_CAPITALIZED,
  WKSPC_PLURAL_CAPITALIZED
} from '../../../utils/common-utils'
import { AccountLogo } from '../../../commoncomponents/account-logo/AccountLogo'
import { customRenderer } from '../../../commoncomponents/CustomRenderer'
import {
  getCountryName,
  getExportCSVColumns,
  getExportReportStatus,
  displayAccountLaunchError,
  sortByEnum,
  deviceSubscriptionOwnershipEnum,
  generateTenantsReportViaReportingAPICall,
  getExportDescriptionBasedOnLd
} from '../utils'
import ExportModal from '../../../commoncomponents/export-csv/ExportModal'
import { displayNotification } from '../../../utils/notificiation-utils'
import { getCustomerAccount, isGLOP } from '../../../utils/feature-flag-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import { useUPSContext } from '../../../context/ups-context'
import { loadCustomerAccount } from '../../../utils/account-utils'
import useLogger from '../../../hooks/logs/useLogger'
import { GTS_STATUS_ENUM } from '../../acct-onboarding/utils'

import { DeleteCustomer } from './DeleteCustomer'
import { FilterDialog } from './FilterDialog'

const CustomerDatatable = ({ refreshCount }) => {
  const { t } = useTranslation(['authn', 'common', 'reporting_dashboard'])
  const { rbacPolicies } = useVisibilityContext()
  const { oidcUser } = useReactOidc()
  const custAccountLoaded = getCustomerAccount()
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const ld = useLD()
  const logger = useLogger()
  const {
    'glcp-switch-to-workspace': showWorkspaceString,
    'glcp-gtcaas': glcpGTCAAS,
    'glcp-msp-customer-own': glcpMSPCustomerOwn,
    'glcp-msp-sort-customer': glcpMSPSortCustomer,
    'glcp-msp-filter': glcpMSPFilter
  } = LDFlags
  const reportingTenantsExportLdFlag = LDFlags['glcp-reportfw-tenants-export']
  const reportingDashboardFlag = LDFlags['glcp-reportfw-dashboard']

  const [customerAccounts, setCustomerAccounts] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  const [customer, setCustomer] = useState({})
  const [loadingCustomerTable, setLoadingCustomerTable] = useState(true)
  const [showNoDataCompo, setShowNoDataCompo] = useState(false)

  const [timeOfDeployment, setTimeOfDeployment] = useState('')
  const [customTime, setCustomTime] = useState([])
  const [filterValues, setFilterValues] = useState({})
  const [applicationInstanceOptions, setApplicationInstanceOptions] = useState(
    []
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [warningNotification, setWarningNotification] = useState('')

  // Search & Sort
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState({
    external: true,
    property: 'accessed_at',
    direction: 'desc'
  })

  // Pagination for fetch tenant list get api
  const itemsPerPage = 20
  const [page, setPage] = useState(1)
  const [currentTotalItems, setCurrentTotalItems] = useState(0) // Total items count irrespective of search & filter
  const [totalItems, setTotalItems] = useState(0) // Total items count without search & filter
  const pageIdxInfo = getPaginationShowIdx(
    page,
    currentTotalItems,
    itemsPerPage,
    t
  )

  // To delete Customer
  const [deleteCustomer, setDeleteCustomer] = useState(false)

  const [showFilterDialog, setFilterDialog] = useState(false)

  // To reload datatable
  const [pullRefresh, setPullRefresh] = useState(false)

  // To Export Customer
  const [showExportModal, setShowExportModal] = useState(false)
  const [pollingTerms, setPollingTerms] = useState({
    status: null,
    id: null
  })
  const [loadingReport, setLoadingReport] = useState(false)
  const [launching, setLaunching] = useState(false)

  const { dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchUPSContext } = useUPSContext()

  // To display notification
  const [successNotification, setSuccessNotification] = useState(null)
  const [errorNotification, setErrorNotification] = useState(null)
  // To fetch available country list
  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setListOfCountries(response?.data?.countries || [])
      },
      (error) => {
        setErrorNotification(getApiErrorMessage(error, t))
      }
    )
  }, [t])

  const handleAccountLaunch = (currentAccount, customerAccountToLaunch) => {
    setLaunching(true)
    sessionStorage.setItem('swapMspAccount', JSON.stringify(currentAccount))
    customerAccountToLaunch.platform_customer_id =
      customerAccountToLaunch.customer_id
    customerAccountToLaunch.account_type = 'TENANT'
    loadCustomerAccount(
      customerAccountToLaunch,
      oidcUser,
      dispatchVisibilityContext,
      navigate,
      null,
      null,
      ld,
      dispatchUPSContext,
      logger
    ).then(
      () => {
        setLaunching(false)
      },
      (error) => {
        setLaunching(false)
        const accStatus = error?.response?.data?.account_status?.split('.')[1]
        let notificationType
        if (
          glcpGTCAAS &&
          error?.response?.status === 423 &&
          (accStatus === GTS_STATUS_ENUM.PENDING ||
            accStatus === GTS_STATUS_ENUM.TIMEOUT)
        ) {
          notificationType = setWarningNotification
        } else {
          notificationType = setErrorNotification
        }
        displayAccountLaunchError(
          error,
          t,
          notificationType,
          customerAccountToLaunch,
          glcpGTCAAS
        )
      }
    )
  }

  const getFilterCount = (filteredObject) => {
    return Object.values(filteredObject).filter((value) => value)?.length
  }

  // To fetch the tenant list
  useEffect(() => {
    setShowNoDataCompo(false)
    const requestBody = {
      offset: (page - 1) * itemsPerPage,
      count_per_page: itemsPerPage,
      ...(searchTerm?.length && { search_string: searchTerm }),
      ...((glcpMSPSortCustomer || isGLOP()) &&
        sortBy?.property && {
          sort_by: sortByEnum[sortBy?.property][sortBy?.direction]
        }),
      ...(filterValues?.applicationInstanceId && {
        application_instance_id: filterValues?.applicationInstanceId
      }),
      ...(filterValues?.applicationInstanceDeployedTime && {
        application_instance_deployed_at_start:
          filterValues?.applicationInstanceDeployedTime?.startTime,
        application_instance_deployed_at_end:
          filterValues?.applicationInstanceDeployedTime?.endTime
      })
    }
    get('/ui-doorway/ui/v1/tenants', requestBody, oidcUser?.access_token).then(
      (response) => {
        setCustomerAccounts(response?.data?.tenants || [])
        if (!searchTerm && !getFilterCount(filterValues)) {
          if (!response?.data?.pagination?.total_count) setShowNoDataCompo(true)
          setTotalItems(response?.data?.pagination?.total_count || 0)
        }
        setCurrentTotalItems(response?.data?.pagination?.total_count || 0)
        setLoadingCustomerTable(false)
      },
      (error) => {
        setShowNoDataCompo(true)
        setErrorNotification(getApiErrorMessage(error, t))
        setLoadingCustomerTable(false)
      }
    )
  }, [
    oidcUser?.access_token,
    sortBy?.property,
    sortBy?.direction,
    searchTerm,
    page,
    refreshCount,
    pullRefresh,
    filterValues,
    glcpMSPSortCustomer,
    t
  ])

  useEffect(() => {
    get(
      `/ui-doorway/ui/v1/applications/provisions`,
      { provision_status: 'PROVISIONED' },
      oidcUser.access_token
    ).then(
      (response) => {
        const applicationOptions = []
        response?.data?.provisions.forEach((provision) => {
          applicationOptions.push({
            label: `${provision?.name} - ${provision?.region_name}`,
            value: provision?.application_instance_id
          })
        })
        setApplicationInstanceOptions(applicationOptions)
      },
      (error) => {
        setErrorMessage(getErrorMessage(error, t))
      }
    )
  }, [t, oidcUser.access_token])

  // BE poll to check the csv generate status
  useEffect(() => {
    let refreshTimer = null
    if (!reportingTenantsExportLdFlag) {
      const polling = () => {
        if (pollingTerms.status === 'IN_PROGRESS' && pollingTerms.id) {
          getExportReportStatus(
            pollingTerms.id,
            pollingTerms.status,
            (val) => setPollingTerms((p) => ({ ...p, status: val })),
            oidcUser.access_token
          )
          refreshTimer = setTimeout(() => {
            polling()
          }, 5000)
        }
      }
      polling()
      if (pollingTerms.status === 'DONE') {
        setSuccessNotification(t('common:export_modal.report_complete'))
      }
    }
    return () => {
      clearTimeout(refreshTimer)
    }
  }, [
    pollingTerms.id,
    pollingTerms.status,
    oidcUser.access_token,
    t,
    reportingTenantsExportLdFlag
  ])

  // BE call with user selected details to generate csv file
  const onGenerateReport = ({
    report_name,
    description,
    email,
    all_entries,
    columns,
    setApiError
  }) => {
    setLoadingReport(true)
    let request = {
      email_address: email,
      ...(!all_entries && {
        ...(searchTerm?.length && { search_string: searchTerm }),
        ...((glcpMSPSortCustomer || isGLOP()) &&
          sortBy?.property && {
            sort_by: sortByEnum[sortBy?.property][sortBy?.direction]
          }),
        ...(filterValues?.applicationInstanceId && {
          application_instance_id: filterValues?.applicationInstanceId
        }),
        ...(filterValues?.applicationInstanceDeployedTime && {
          application_instance_deployed_at_start:
            filterValues?.applicationInstanceDeployedTime?.startTime,
          application_instance_deployed_at_end:
            filterValues?.applicationInstanceDeployedTime?.endTime
        })
      })
    }
    if (!reportingTenantsExportLdFlag) {
      const columnsQuery = columns.map((v) => `column_names=${v}`).join('&')
      get(
        `/ui-doorway/ui/v1/export/tenants?${columnsQuery}`,
        request,
        oidcUser.access_token
      ).then(
        (response) => {
          if (response?.data?.task_tracking_id) {
            setPollingTerms({
              status: 'IN_PROGRESS',
              id: response?.data?.task_tracking_id
            })
          }
          setShowExportModal(false)
          setSuccessNotification(t('common:export_modal.generating_report'))
          setLoadingReport(false)
        },
        (error) => {
          setErrorNotification(getApiErrorMessage(error, t))
          setLoadingReport(false)
        }
      )
    } else {
      const { email_address, ...filters } = request
      request = {
        delivery: {
          emailComponents: {
            recipientEmail: email
          }
        },
        query_elements: {
          datasource_name: ['tenant'],
          columns,
          filters: all_entries
            ? {}
            : {
                ...filters
              }
        }
      }
      if (reportingDashboardFlag) {
        request.name = report_name?.trim()
        request.description = description?.trim()
      }
      setPollingTerms({
        ...pollingTerms,
        status: 'IN_PROGRESS',
        id: 0
      })
      setLoadingReport(true)

      generateTenantsReportViaReportingAPICall(
        request,
        oidcUser.access_token,
        setSuccessNotification,
        setErrorNotification,
        (response) => {
          // on success
          if (response?.data) {
            setPollingTerms({
              ...pollingTerms,
              status: 'DONE',
              id: 0
            })
          }
          setShowExportModal(false)
          setLoadingReport(false)
        },
        (val) => {
          setLoadingReport(false)
          if (!reportingDashboardFlag) {
            setShowExportModal(false)
          } else {
            setShowExportModal(true)
            setApiError(val)
          }
        },
        t,
        reportingDashboardFlag
      )
    }
  }

  const isMSPSortCustOrGLOP = glcpMSPSortCustomer || isGLOP()
  const columns = [
    {
      property: 'company_name',
      type: 'string',
      header: t('customer_account.account', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      }),
      primary: true,
      sortable: isMSPSortCustOrGLOP,
      render: (datum) => {
        return (
          <Box
            direction="row"
            align="center"
            onClick={() => handleAccountLaunch(custAccountLoaded, datum)}
          >
            <Typography
              type="text"
              wordBreak="break-all"
              icon={
                <Box flex={{ shrink: 0 }} margin={{ right: 'xsmall' }}>
                  <AccountLogo
                    account={datum}
                    logoOnly
                    title={datum.company_name}
                    logoSize="xxsmall"
                    avatarSize="small"
                  />
                </Box>
              }
              testId="company-name"
            >
              {datum?.company_name}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'description',
      type: 'string',
      header: t('customer_account.description'),
      size: 'medium',
      render: (datum) => {
        return (
          <Box direction="row" align="center">
            <Typography
              type="text"
              size="medium"
              wordBreak="break-all"
              testId="description"
            >
              {datum?.description}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'address.country_code',
      type: 'string',
      header: t('customer_account.country'),
      render: (datum) =>
        getCountryName(datum?.address?.country_code, listOfCountries)
    },
    {
      property: 'created_at',
      type: 'date',
      sortable: isMSPSortCustOrGLOP,
      header: t('customer_account.created'),
      render: (datum) =>
        datum?.created_at ? dayjs(datum?.created_at).format('MM/DD/YY') : ''
    },
    ...(glcpMSPCustomerOwn || isGLOP()
      ? [
          {
            property: 'device_subscription_ownership',
            type: 'string',
            sortable: isMSPSortCustOrGLOP,
            header: t('customer_account.device_subscription_ownership'),
            render: (datum) => {
              return (
                <Box direction="row" align="center">
                  <Typography
                    type="text"
                    size="medium"
                    wordBreak="break-all"
                    testId="description"
                  >
                    {deviceSubscriptionOwnershipEnum(
                      datum?.operational_mode,
                      t
                    )}
                  </Typography>
                </Box>
              )
            }
          }
        ]
      : []),
    {
      property: 'assigned_devices_count',
      type: 'numeric',
      header: t('customer_account.devices'),
      size: 'small',
      render: (datum) => {
        return datum?.operational_mode === 'CUSTOMER_OWNED_INVENTORY' ? (
          <Anchor
            onClick={() => {
              handleAccountLaunch(custAccountLoaded, datum)
              sessionStorage.setItem('navigateToDevicesOnClick', true)
            }}
          >
            {datum?.assigned_devices_count}
          </Anchor>
        ) : (
          <>{datum?.assigned_devices_count}</>
        )
      }
    },
    {
      property: 'subscribed_devices_count',
      type: 'numeric',
      header: t('customer_account.devices_subscribed'),
      size: 'small',
      align: 'right'
    },
    {
      property: 'accessed_at',
      type: 'date',
      sortable: isMSPSortCustOrGLOP,
      header: t('customer_account.last_visited'),
      render: (datum) => {
        if (datum?.accessed_at) {
          const date = datum?.accessed_at * 1000
          return dayjs(date).format('MM/DD/YY')
        }
        return ''
      },
      size: 'small'
    },
    {
      property: 'actions',
      header: '',
      render: (datum) => (
        <ActionButton
          actions={[
            {
              label: t('customer_account.view_details'),
              onClick: () => {
                navigate(`/customer-account/${datum?.customer_id}`)
              },
              testId: 'view-details-btn'
            },
            {
              label: t('customer_account.delete'),
              onClick: () => {
                setCustomer(datum)
                setDeleteCustomer(true)
              },
              visibility: {
                rbac: {
                  resource: '/ccs/accounts/platform/customer',
                  permission: 'ccs.accounts.platform.customer.delete'
                }
              },
              testId: 'delete-btn'
            }
          ]}
          testId="customer-action-btn"
          customRenderer={customRenderer}
        />
      ),
      size: 'xxsmall'
    }
  ]
  const getBadgeValue = (values) => {
    const count = getFilterCount(values)
    return count ? { value: count, background: { color: 'text' } } : null
  }

  const justifyStyle = glcpMSPFilter || isGLOP() ? 'between' : 'end'

  const tenantUidExport =
    !reportingDashboardFlag ||
    (reportingDashboardFlag && !reportingTenantsExportLdFlag)
  const exportHeader = tenantUidExport
    ? t('customer_account.customer_account_details', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      })
    : t('reporting_dashboard:reporting_export_modal.select_fields')
  return (
    <Box pad={{ top: 'small' }}>
      {!loadingCustomerTable ? (
        <DataTable
          pagination={{
            totalItems: currentTotalItems,
            itemsPerPage,
            page,
            setPage,
            pageIdxInfo
          }}
          filterButton={
            <Box width="xxlarge" direction="row" justify={justifyStyle}>
              {glcpMSPFilter || isGLOP() ? (
                <Box direction="row" gap="medium" align="center">
                  <Button
                    kind="toolbar"
                    icon={<Filter />}
                    onClick={() => setFilterDialog(true)}
                    testId="filter-btn"
                    badge={getBadgeValue(filterValues)}
                  />

                  {getFilterCount(filterValues) > 0 && (
                    <Anchor
                      label={
                        <Typography
                          size="medium"
                          type="text"
                          testId="clear-filters-anchor"
                        >
                          {t('common:clear_filter')}
                        </Typography>
                      }
                      onClick={() => {
                        setFilterValues({})
                        setTimeOfDeployment('')
                        setCustomTime([])
                      }}
                    />
                  )}
                </Box>
              ) : null}
              <Box alignSelf="end">
                <ActionButton
                  actions={[
                    {
                      label: t('customer_account.export'),
                      onClick: () => {
                        setSuccessNotification(null)
                        setErrorNotification(null)
                        setShowExportModal(true)
                      },
                      hidden:
                        !customerAccounts?.length ||
                        !rbacPolicies?.effects?.['/ccs/reporting/dashboard']?.[
                          'ccs.reporting.edit'
                        ],
                      testId: 'export-btn'
                    }
                  ]}
                  showOneActionAsDropDown
                  testId="oneaction-action-btn"
                  label={t('common:actions')}
                  icon={<FormDown />}
                  reverse
                  kind="toolbar"
                />
              </Box>
            </Box>
          }
          search={{
            width: 'medium',
            responsive: true,
            onSearchValueChange: (val) => {
              setPage(1)
              setSearchTerm(val)
            },
            placeholder: t('customer_account.search_customer_account_name', {
              account: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_CAPITALIZED
              )
            })
          }}
          grid={{
            columns,
            data: customerAccounts,
            resizeable: true,
            onSort: (value) => {
              setSortBy(value)
              setPage(1)
            },
            sort: sortBy,
            pad: {
              body: { vertical: 'xsmall', horizontal: 'small' }
            },
            verticalAlign: 'middle'
          }}
          summary={{
            entityName: t('customer_account.items')
          }}
          testId="customer-account-table"
        />
      ) : (
        <Box margin="large" align="center">
          <Loader testId="customer-table-loader" />
        </Box>
      )}
      {showNoDataCompo ? (
        <Box margin="large">
          <NoDataInfo
            subtitle={t('customer_account.no_data_found')}
            icon={<Install size="large" />}
            testId="no-data-info"
          />
        </Box>
      ) : null}
      {deleteCustomer && (
        <DeleteCustomer
          customer={customer}
          setDeleteCustomer={setDeleteCustomer}
          onSuccess={() => {
            setPullRefresh(!pullRefresh)
            setSuccessNotification(
              t('customer_account.customer_deleted_message')
            )
          }}
        />
      )}
      {showFilterDialog && (
        <FilterDialog
          setFilterDialog={setFilterDialog}
          timeOfDeployment={timeOfDeployment}
          setTimeOfDeployment={setTimeOfDeployment}
          customTime={customTime}
          setCustomTime={setCustomTime}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
          applicationInstanceOptions={applicationInstanceOptions}
          errorMessage={errorMessage}
        />
      )}

      {showExportModal && (
        <ExportModal
          setShowModal={setShowExportModal}
          title={
            reportingDashboardFlag && reportingTenantsExportLdFlag
              ? t('reporting_dashboard:reporting_export_modal.tenants_export')
              : t('customer_account.export_customer_accounts_title', {
                  account: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })
          }
          description={getExportDescriptionBasedOnLd(LDFlags, t)}
          columns={{
            header: exportHeader,
            data: getExportCSVColumns(
              t,
              getWorkspaceString,
              showWorkspaceString,
              WKSPC_CAPITALIZED,
              LDFlags
            )
          }}
          includedEntries={{
            all_entries_label: t('customer_account.all_customer_accounts', {
              count: totalItems,
              accounts: getWorkspaceString(
                showWorkspaceString,
                t,
                WKSPC_PLURAL_CAPITALIZED
              )
            }),
            searched_entries_label: t(
              'customer_account.current_search_results',
              { count: currentTotalItems }
            )
          }}
          filteredDevices={currentTotalItems}
          onClickGenerateReport={(selectedDetails, setApiError) =>
            onGenerateReport({ ...selectedDetails, setApiError })
          }
          loading={loadingReport}
          setLoading={setLoadingReport}
          checkProgressReports={Boolean(!reportingTenantsExportLdFlag)}
        />
      )}
      {warningNotification &&
        displayNotification(
          warningNotification,
          'warning',
          setWarningNotification
        )}
      {successNotification &&
        displayNotification(
          successNotification,
          'info',
          setSuccessNotification
        )}
      {errorNotification &&
        displayNotification(errorNotification, 'error', setErrorNotification)}
      {launching && (
        <Loader
          modal
          testId="loader-modal"
          size="xxsmall"
          modalTitle={t('common:launching')}
          modalSubTitle={t('common:loader_dialog_message')}
        />
      )}
    </Box>
  )
}

CustomerDatatable.propTypes = {
  refreshCount: PropTypes.number.isRequired
}

export { CustomerDatatable }
