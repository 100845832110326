// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { omit } from 'lodash'

import {
  ModalDialog,
  ModalFooter,
  Typography,
  Button,
  ModalHeader
} from '../../components'
import VisibilityWrapper from '../visibility-wrapper/VisibilityWrapper'

const ProgressModal = ({
  onSetOpen,
  title,
  subtitle = undefined,
  secondaryButton = undefined,
  secondaryButtonOnClick = () => {},
  onCloseModal,
  width,
  hideAuditLogBtn,
  showOnlyAuditLogbtn,
  auditLogCategory,
  ...rest
}) => {
  const { t } = useTranslation(['device'])
  const navigate = useNavigate()

  const buttonList = [
    ...(hideAuditLogBtn
      ? []
      : [
          {
            id: 1,
            label: t('view_audit_log'),
            secondary: true,
            testId: 'view-audit-log-btn',
            onClick: () => {
              navigate('/manage-account/auditlogs', {
                state: {
                  name: auditLogCategory
                }
              })
            },
            rbac: {
              resource: '/ccs/audit-trail',
              permission: 'ccs.audit-trail.view'
            }
          }
        ]),
    ...(showOnlyAuditLogbtn
      ? []
      : [
          {
            id: 2,
            label: t('close'),
            primary: true,
            testId: 'close-btn',
            onClick: onCloseModal
          }
        ])
  ]

  if (secondaryButton) {
    buttonList.unshift({
      label: secondaryButton.label,
      secondary: true,
      testId: secondaryButton.testId,
      onClick: secondaryButtonOnClick
    })
  }

  return (
    <ModalDialog
      width={width}
      testId="progress-modal-dialog"
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2" testId="progress-modal-title">
              {title}
            </Typography>
          }
          {...(showOnlyAuditLogbtn && { onClose: () => onCloseModal() })}
        />
      }
      content={
        <Box>
          {!subtitle || typeof subtitle === 'string' ? (
            <Typography type="paragraph" testId="progress-modal-description">
              {subtitle || t('progress_update_desc')}
            </Typography>
          ) : (
            subtitle
          )}
        </Box>
      }
      footer={
        rest?.hideFooter ? null : (
          <ModalFooter
            right={
              <Box justify="end" direction="row" gap="medium">
                {buttonList.map((button) => (
                  <VisibilityWrapper
                    key={button.id}
                    rbac={button.rbac || undefined}
                  >
                    <Button {...omit(button, ['rbac'])} />
                  </VisibilityWrapper>
                ))}
              </Box>
            }
          />
        )
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

ProgressModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  secondaryButton: PropTypes.object,
  secondaryButtonOnClick: PropTypes.func,
  onCloseModal: PropTypes.func.isRequired,
  width: PropTypes.string,
  hideAuditLogBtn: PropTypes.bool,
  showOnlyAuditLogbtn: PropTypes.bool,
  auditLogCategory: PropTypes.string
}

export { ProgressModal }
