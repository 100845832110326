// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Text, DropButton, CheckBox } from 'grommet'
import { More } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Typography,
  Tile,
  Notification
} from '../../../../../components'
import { displayNotification } from '../../../../../utils/notificiation-utils'

import AddServer from './AddServer'
import EditServer from './EditServer'

const ConfigureRadsec = ({
  cb,
  editMode = false,
  showAddServerModal = false,
  closeAddServerModal,
  radiusServerDetails,
  showRadsecWarningForCertUpload = true,
  checkCertificateValidity,
  setNotifyComponent,
  setSmartCardChecked,
  smartCardChecked,
  isSetSmartCardEdited
}) => {
  const [addServerModal, setAddServerModal] = useState(showAddServerModal)
  const [editServerModal, setEditServerModal] = useState(false)
  const [serverDetail, setServerDetail] = useState(radiusServerDetails)
  const { t } = useTranslation(['authn', 'common'])
  const serverType = {
    0: 'Primary',
    1: 'Secondary'
  }

  const [editServerCode, setEditServerCode] = useState(0)
  const addServerCb = (data) => {
    setServerDetail(serverDetail.concat(data))
    cb(serverDetail.concat(data))
    checkCertificateValidity()
  }

  useEffect(() => {
    setAddServerModal(showAddServerModal)
  }, [showAddServerModal])

  useEffect(() => {
    setServerDetail(radiusServerDetails)
  }, [radiusServerDetails])

  const editServerCb = (data, serverName) => {
    const detailIndex = serverType[0] === serverName ? 0 : 1
    const clonedServerDetail = [...serverDetail]
    clonedServerDetail[detailIndex] = { ...serverDetail[detailIndex], ...data }
    setServerDetail(clonedServerDetail)
    cb(clonedServerDetail, detailIndex)
    checkCertificateValidity()

    const notification = displayNotification(
      `${serverType[editServerCode]} Server Updated`,
      'info',
      setNotifyComponent
    )
    setNotifyComponent(notification)
  }

  const closeModals = (val) => {
    setAddServerModal(val)
    setEditServerModal(val)
    closeAddServerModal(val)
  }

  const selectedDropdownItem = () => {
    const formattedServerDetail = [...serverDetail]
    formattedServerDetail.pop()
    setServerDetail(formattedServerDetail)
    cb(formattedServerDetail)

    const notification = displayNotification(
      t('manage_account.radius.delete_server_notification'),
      'info',
      setNotifyComponent
    )
    setNotifyComponent(notification)
  }

  return (
    <>
      {addServerModal && serverDetail && (
        <AddServer
          onSetOpen={closeModals}
          serverType="radsec"
          cb={addServerCb}
          serverName={serverType[serverDetail.length === 1 ? 1 : 0]}
          serverInfo={serverDetail[editServerCode] || {}}
          serverDetail={serverDetail}
        />
      )}
      {editServerModal && (
        <EditServer
          onSetOpen={closeModals}
          serverType="radsec"
          cb={editServerCb}
          serverName={serverType[editServerCode]}
          serverInfo={serverDetail[editServerCode] || {}}
          serverDetail={serverDetail}
          editServerCode={editServerCode}
        />
      )}
      {serverDetail.length > 0 ? (
        <Box
          fill
          justify="center"
          margin={{ bottom: 'medium' }}
          data-testid="server-details"
        >
          {serverDetail.map((server, index) => {
            return (
              <Box direction="column" key={server.ip}>
                <Box fill direction="row" flex margin={{ top: 'medium' }}>
                  <Tile
                    boxShadow
                    justify="center"
                    paramTile={null}
                    alignContent="center"
                    align="center"
                    layout="row"
                    logo={<></>}
                    actionBtn={
                      <>
                        {editMode ? (
                          <Box direction="row" align="center">
                            {index > 0 ? (
                              <Box margin={{ right: 'small' }}>
                                {/* <More /> */}
                                <DropButton
                                  data-testid="delete-server-dropdown"
                                  margin={{ top: 'small', right: 'medium' }}
                                  dropProps={{
                                    align: { top: 'bottom', right: 'left' }
                                  }}
                                  dropContent={
                                    <Box pad="xsmall">
                                      <Box
                                        align="start"
                                        pad="xsmall"
                                        onClick={selectedDropdownItem}
                                      >
                                        <Typography
                                          size="xsmall"
                                          type="text"
                                          weight="normal"
                                        >
                                          {t(
                                            'manage_account.radius.delete_server'
                                          )}
                                        </Typography>
                                      </Box>
                                      {index !== 0 && (
                                        <Box
                                          align="start"
                                          pad="xsmall"
                                          onClick={() => {
                                            setEditServerModal(true)
                                            setEditServerCode(index)
                                          }}
                                        >
                                          <Typography
                                            size="xsmall"
                                            type="text"
                                            weight="normal"
                                          >
                                            Modify Server
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  }
                                >
                                  <More />
                                </DropButton>{' '}
                              </Box>
                            ) : (
                              ''
                            )}

                            {index === 0 && (
                              <Box margin={{ left: 'small', right: 'small' }}>
                                <Button
                                  default
                                  label="Modify"
                                  testId="edit-server-btn"
                                  onClick={() => {
                                    setEditServerModal(true)
                                    setEditServerCode(index)
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        ) : (
                          ''
                        )}
                      </>
                    }
                    subTitle={
                      <Box>
                        <Typography type="text" testId="ip-id">
                          {server.ip}
                        </Typography>
                      </Box>
                    }
                    testId="app-tile"
                    title={
                      <Box>
                        <Typography
                          type="text"
                          size="large"
                          weight="bold"
                          testId="server-id"
                        >
                          {`${serverType[index]} Server`}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
                {serverDetail.length === 1 && editMode && (
                  <Box width="small" margin={{ top: 'medium' }}>
                    <Button
                      secondary
                      label={t('manage_account.radsec.add_secondary_server')}
                      onClick={() => setAddServerModal(true)}
                      testId="add-radsec-server"
                    />
                  </Box>
                )}

                <Box margin={{ vertical: 'large' }}>
                  <Typography type="text" size="large" weight="bold">
                    {t('manage_account.radius.smart_card_title')}
                  </Typography>
                  <Typography type="text" size="small">
                    {t('manage_account.radius.smart_card_description')}
                  </Typography>
                  <Box margin={{ top: 'medium' }}>
                    <CheckBox
                      pad="none"
                      checked={smartCardChecked}
                      label={t(
                        'manage_account.radius.smart_card_checkbox_label'
                      )}
                      onChange={(event) => {
                        setSmartCardChecked(event.target.checked)
                        isSetSmartCardEdited(true)
                      }}
                      disabled={!editMode}
                      data-testid="smart-card-checkbox"
                    />
                  </Box>
                </Box>
              </Box>
            )
          })}

          {serverDetail.length > 0 && showRadsecWarningForCertUpload && (
            <Box margin={{ top: 'medium' }}>
              <Notification
                align="center"
                backgroundColor="status-warning"
                testId="warning-banner-notification"
                text={
                  <Box
                    height="1em"
                    align="center"
                    justify="center"
                    direction="row"
                    margin={{ top: 'small', bottom: 'small' }}
                  >
                    <Text type="text" testId="errorNotification">
                      {t('manage_account.radsec.cert_upload')}{' '}
                      <Text
                        type="text"
                        size="small"
                        weight="bold"
                        testId="Aruba Central"
                      >
                        {t('manage_account.radsec.aruba_central')}
                      </Text>{' '}
                      &gt;{' '}
                      <Text
                        type="text"
                        size="small"
                        weight="bold"
                        testId="Global"
                      >
                        {t('manage_account.radsec.global')}
                      </Text>{' '}
                      &gt;{' '}
                      <Text
                        type="text"
                        size="small"
                        weight="bold"
                        testId="Global"
                      >
                        {t('manage_account.radsec.organization')}
                      </Text>{' '}
                      &gt;{' '}
                      <Text
                        type="text"
                        size="small"
                        weight="bold"
                        testId="Global"
                      >
                        {t('manage_account.radsec.certificate')}
                      </Text>{' '}
                      {t('manage_account.radsec.authenticate_radsecServers')}
                    </Text>
                  </Box>
                }
                type="inline"
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box width="small" margin={{ bottom: 'large' }}>
          <Button
            secondary
            label={t('manage_account.radsec.add_rasec_server')}
            onClick={() => setAddServerModal(true)}
            testId="add-radsec-server"
          />
        </Box>
      )}
    </>
  )
}

ConfigureRadsec.propTypes = {
  cb: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  showAddServerModal: PropTypes.bool,
  closeAddServerModal: PropTypes.func.isRequired,
  radiusServerDetails: PropTypes.array.isRequired,
  showRadsecWarningForCertUpload: PropTypes.bool,
  checkCertificateValidity: PropTypes.func.isRequired,
  setNotifyComponent: PropTypes.func.isRequired,
  setSmartCardChecked: PropTypes.func.isRequired,
  smartCardChecked: PropTypes.bool.isRequired,
  isSetSmartCardEdited: PropTypes.func.isRequired
}

export default ConfigureRadsec
