// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Navigate, Route, Routes } from 'react-router-dom'
import React from 'react'

import LicenseDetailsPage from '../manage-account/subscriptions/pages/licenses/license-details/LicenseDetails'
import AddDevicesToLicenses from '../manage-account/subscriptions/pages/licenses/add-devices-to-licenses/AddDevicesToLicenses'

const SubscriptionRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/devices/subscriptions-list" />} />
      <Route
        path="/:licenseKey/add-devices-to-subscription/*"
        element={<AddDevicesToLicenses />}
      />
      <Route path="/:licenseKey" element={<LicenseDetailsPage />} />
    </Routes>
  )
}

export default SubscriptionRouter
