// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

// Funtion to import locales dynamically
const loadI18nFiles = () => {
  const resources = {}

  // Format the locales and assign to resources object
  const formatLocales = (context) => {
    // context type will be 'function' for webpack and 'object' for vite
    const isContextTypeFun = typeof context === 'function'

    // Get locales path based on bundler
    const getLocalespathKey = isContextTypeFun
      ? context?.keys()
      : Object.keys(context)

    // Format locales path based on bundler
    getLocalespathKey?.forEach((key) => {
      const splitPath = key
        .replace(isContextTypeFun ? './' : './locales/', '')
        .split('/')
      const [lng, namespace] = [splitPath[0], splitPath[splitPath.length - 1]]
      if (!resources[lng]) {
        resources[lng] = {}
      }

      // Assign locales to resources obj based on bundler
      resources[lng][namespace.replace('.json', '')] = isContextTypeFun
        ? context(key)
        : context[key]
    })
  }
  // Check for a bundler (vite or webpack)
  if (typeof import.meta !== 'undefined' && import.meta.env) {
    // Dynmically import locales if bundler is vite
    const localeModules = import.meta.glob('./locales/**/*.json', {
      eager: true
    })

    formatLocales(localeModules)
  } else if (
    typeof require.context('./locales', true, /\.json$/) !== 'undefined'
  ) {
    // Dynmically import locales if bundler is webpack
    const localeContext = require.context('./locales', true, /\.json$/)

    formatLocales(localeContext)
  } else {
    throw new Error('Unknown bundler: Unable to load i18n files.')
  }
  return resources
}

const updatedResources = loadI18nFiles()
i18n
  .use(detector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: updatedResources,
    fallbackLng: 'en-US',
    supportedLngs: [
      'en-US',
      'de-DE',
      'es-ES',
      'fr-FR',
      'ja-JP',
      'ko-KR',
      'pt-BR',
      'ru-RU',
      'zh-CN',
      'it-IT'
    ],
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage'],
      caches: ['cookie']
    },
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
      wait: false
    }
  })

export default i18n
