// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Video, Grid } from 'grommet'
import { Previous } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { Typography, Button } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'

import Part1CreatingAndOrginization from './videos/Part1-Logging-on-and-Creating-a-Workspace.mp4'
import Part2AddingAnApplication from './videos/Part2-Deploying-Services.mp4'
import Part3CreatingAssignmentAndScopes from './videos/Part3-Adding-Users-and-Granting-Access.mp4'
import Part4AddingAndAssigningADevice from './videos/Part4-Adding-a-Device.mp4'
import Part5CCSOverview from './videos/CCS-Overview.mp4'
import Part6AddAppAssignDev from './videos/Adding-Applications-and-assigning-devices-to-applications.mp4'

const GetStartedContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const getStartedVideos = [
    {
      title: 'get_started_videos_titles.new_part1_title',
      src: Part1CreatingAndOrginization,
      id: 1
    },
    {
      title: 'get_started_videos_titles.new_part2_title',
      src: Part2AddingAnApplication,
      id: 2
    },
    {
      title: 'get_started_videos_titles.new_part3_title',
      src: Part3CreatingAssignmentAndScopes,
      id: 3
    },
    {
      title: 'get_started_videos_titles.new_part4_title',
      src: Part4AddingAndAssigningADevice,
      id: 4
    },
    {
      title: 'get_started_videos_titles.part5_title',
      src: Part5CCSOverview,
      id: 5
    },
    {
      title: 'get_started_videos_titles.part6_title',
      src: Part6AddAppAssignDev,
      id: 6
    }
  ]
  const handleBackBtnClick = () => {
    navigate('/home/')
  }
  return (
    <>
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          label={t('nav_bar.home')}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="home-btn"
        />
      </Box>
      <Box
        data-testid="get-started-page"
        gap="xsmall"
        margin={{ left: 'xlarge', top: 'medium' }}
      >
        {/* <PageHeader
        primaryHeader="Get Started with HPE GreenLake"
        testId="get-started"
        subHeader="A collection of video tutorials to help you through common tasks."
      /> */}
        <Box direction="row" gap="small" pad="xsmall">
          <Box>
            <Typography
              level="1"
              type="heading"
              weight="normal"
              testId="page-title"
            >
              {t('get_started_videos.title')}
            </Typography>
            <Typography
              size="xsmall"
              type="text"
              weight="normal"
              testId="page-description"
            >
              {t('get_started_videos.description')}
            </Typography>
          </Box>
        </Box>
        <Box direction="row" gap="small" pad="xsmall" justify="start">
          <Grid
            gap="large"
            columns={{ count: 'fill', size: 'medium' }}
            align="start"
          >
            {getStartedVideos &&
              getStartedVideos.map((video) => (
                <Box
                  key={video.id}
                  direction="column"
                  gap="medium"
                  pad="xsmall"
                >
                  <Box>
                    <Typography
                      level="1"
                      size="small"
                      type="text"
                      weight="normal"
                      testId={`deploy-cloud-title-${video.id}`}
                    >
                      {t(video.title)}
                    </Typography>
                  </Box>
                  <Box>
                    <Video
                      controls="over"
                      fit="contain"
                      autoPlay={false}
                      alignSelf="start"
                      data-testId={`video-${video.id}`}
                    >
                      <source
                        data-testId={`video-source-${video.id}`}
                        src={video.src}
                        type="video/mp4"
                      />
                    </Video>
                  </Box>
                </Box>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  )
}
const GetStartedPage = () => {
  return (
    <Layout>
      <GetStartedContent align="start" justify="start" />
    </Layout>
  )
}
export default GetStartedPage
