// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useContext
} from 'react'
import { Box, FormField, Text, PageHeader, ResponsiveContext } from 'grommet'
import { CircleAlert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router-dom'

import {
  Typography,
  Loader,
  FormInput,
  ToggleButton,
  CCSForm
} from '../../../../../components'
import { get, put } from '../../../../../utils/api-utils'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import { passwordComplexityOptions } from '../constants'
import { ActionButtons, ConfirmModal } from '../components'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { DiscardChangesModal } from '../../../../../commoncomponents/discard-changes-modal/DiscardChangesModal'
import {
  getFailedAttemptsLimitError,
  getFailedAttemptsTimeError,
  getInactiveAccountLockoutError,
  getMinimumPasswordAgeError,
  getPasswordExpiryError,
  getPasswordLengthError
} from '../../../../system/utils/validation-utils'

const defaultData = {
  password_length: '',
  password_complexity: [],
  permitted_symbols: '',
  force_password_reset: false,
  enable_failed_attempts_lockout: false,
  failed_attempts_limit: '',
  failed_attempts_lockout_duration: '',
  inactive_account_lockout_duration: '',
  password_expiration_duration: ''
}

const LocalAuthentication = () => {
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn'])
  const { t: commonT } = useTranslation(['common'])
  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState(null)
  const [originalState, setOriginalState] = useState(defaultData)
  const [errorMessage] = useState('')
  const [discardModal, setDiscardModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const size = useContext(ResponsiveContext)
  const boxWidth = size === 'xsmall' ? 'small' : 'medium'

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value, edited: true }
        case 'SET_STATE':
          return { ...defaultData, ...action.value, edited: false }
        default:
          return currentState
      }
    },
    {
      ...defaultData,
      edited: false
    }
  )

  const getLocalAuthenticationData = useCallback(() => {
    return get(
      '/accounts/ui/v1/cop-auth/local-authentication',
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        setLoading(false)
        return response
      },
      (error) => {
        setApiError(error)
        setLoading(false)
        return null
      }
    )
  }, [oidcUser.access_token])

  useEffect(() => {
    getLocalAuthenticationData().then((response) => {
      if (response && !isEmpty(response.data)) {
        dispatch({
          value: response.data,
          type: 'SET_STATE'
        })
        setOriginalState(response.data)
      }
    })
  }, [oidcUser.access_token, getLocalAuthenticationData])

  const handleDiscard = () => {
    setDiscardModal(false)
    dispatch({
      value: originalState,
      type: 'SET_STATE'
    })
  }

  const getSelectOptions = () => {
    const labels = passwordComplexityOptions.map((value) =>
      t(`cop_local_authentication.${value}`)
    )
    return labels.map((k, index) => {
      return { label: k, value: passwordComplexityOptions[index] }
    })
  }

  const showResetFlag = () => {
    const currentPasswordLength = state.password_length
      ? state.password_length.toString()
      : null
    const initialPasswordLength = originalState.password_length
      ? originalState.password_length.toString()
      : null
    const currentPermittedSymbols = state.permitted_symbols
      ? state.permitted_symbols.toString()
      : null
    const initialPermittedSymbols = originalState.permitted_symbols
      ? originalState.permitted_symbols.toString()
      : null
    const currentPasswordComplexity = state.password_complexity
      ? state.password_complexity.toString()
      : null
    const initialPasswordComplexity = originalState.password_complexity
      ? originalState.password_complexity.toString()
      : null
    if (
      currentPasswordLength !== initialPasswordLength ||
      currentPasswordComplexity !== initialPasswordComplexity ||
      currentPermittedSymbols !== initialPermittedSymbols
    ) {
      return true
    }
    return false
  }

  const handleSubmit = () => {
    setApiError('')
    setLoading(true)
    const requestBody = {
      password_length: state.password_length || null,
      password_complexity: state.password_complexity || null,
      permitted_symbols: state.permitted_symbols || null,
      force_password_reset: state.force_password_reset || null,
      enable_failed_attempts_lockout:
        state.enable_failed_attempts_lockout || null,
      failed_attempts_limit: state.failed_attempts_limit || null,
      failed_attempts_lockout_duration:
        state.failed_attempts_lockout_duration || null,
      inactive_account_lockout_duration:
        state.inactive_account_lockout_duration || null,
      password_expiration_duration: state.password_expiration_duration || null,
      minimum_password_age: state.minimum_password_age || null
    }
    dispatch({
      value: requestBody,
      type: 'SET_STATE'
    })

    put(
      '/accounts/ui/v1/cop-auth/local-authentication',
      requestBody,
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200) {
          setOriginalState(state)
          setLoading(false)
          setSuccessMessage(t('cop_local_authentication.updated_successfuly'))
          if (requestBody.force_password_reset) {
            navigate(`/sign-out`)
          }
        }
      },
      (error) => {
        setApiError(error)
        setLoading(false)
        setConfirmModal(false)
      }
    )
  }

  return (
    <Box width="large">
      {loading ? (
        <Box
          height="large"
          width="full"
          align="center"
          justify="center"
          alignSelf="center"
        >
          <Loader testId="loader-spinner" />
        </Box>
      ) : (
        <CCSForm
          errorMessage={errorMessage}
          testId="local-authentication-form"
          validate="blur"
          onSubmit={() => {
            if (!state.force_password_reset) {
              if (state.edited) {
                handleSubmit()
              }
            } else {
              setConfirmModal(true)
            }
          }}
          onReset={() => (state.edited ? setDiscardModal(true) : null)}
        >
          <>
            {apiError && displayApiError(apiError, t, setApiError)}
            <Box width="large" direction="row" justify="between">
              <PageHeader
                responsive
                pad={{ top: 'xsmall' }}
                title={t('cop_local_authentication.title')}
                subtitle={t('cop_local_authentication.description')}
                data-testid="cop-local-auth-title"
                actions={
                  <VisibilityWrapper
                    rbac={{
                      resource: '/ccs/accounts/platform/customer',
                      permission: 'ccs.accounts.platform.customer.edit'
                    }}
                  >
                    <ActionButtons
                      updateLabel={t('cop_local_authentication.update_button')}
                      edited={state.edited}
                      tObj={commonT}
                    />
                  </VisibilityWrapper>
                }
              />
            </Box>
            <Box
              direction="row"
              gap="large"
              pad={{ vertical: 'small' }}
              wrap={size === 'xsmall'}
            >
              <Box width={boxWidth}>
                <Typography
                  level="4"
                  type="heading"
                  weight="normal"
                  testId="cop-local-auth-title"
                >
                  {t('cop_local_authentication.password_complexity')}
                </Typography>
                <FormInput
                  inputType="text"
                  label={t('cop_local_authentication.password_length')}
                  name="minimum-length-password-input"
                  placeholder={t('cop_local_authentication.password_length')}
                  testId="minimum-length-password-input"
                  value={state.password_length}
                  validate={() =>
                    getPasswordLengthError(state.password_length, t)
                  }
                  onChange={(e) => {
                    dispatch({
                      value: e.target.value,
                      field: 'password_length',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                />
                <FormInput
                  checkBoxOptions={getSelectOptions()}
                  inputType="checkbox"
                  label={
                    <Box>
                      <Text size="xsmall">
                        {t('cop_local_authentication.password_complexity')}
                      </Text>
                      <Text size="xsmall">
                        {t('cop_local_authentication.password_complexity_desc')}
                      </Text>
                    </Box>
                  }
                  name="password-complexity-select-input"
                  testId="password-complexity-select-input"
                  value={state.password_complexity || []}
                  onChange={({ value }) => {
                    if (!value.includes('symbols')) {
                      dispatch({
                        value: '',
                        field: 'permitted_symbols',
                        type: 'CHANGE_FIELD'
                      })
                    }
                    dispatch({
                      value: value && value.sort(),
                      field: 'password_complexity',
                      type: 'CHANGE_FIELD'
                    })
                  }}
                />
                {state.password_complexity &&
                  state.password_complexity.includes('symbols') && (
                    <FormInput
                      inputType="text"
                      value={state.permitted_symbols || ''}
                      label={t('cop_local_authentication.permitted_symbols')}
                      name="permitted-symbols-input"
                      placeholder={t(
                        'cop_local_authentication.permitted_symbols_placeholder'
                      )}
                      testId="permitted-symbols-input"
                      onChange={(e) => {
                        dispatch({
                          value: e.target.value,
                          field: 'permitted_symbols',
                          type: 'CHANGE_FIELD'
                        })
                      }}
                    />
                  )}
                {showResetFlag() && (
                  <Box>
                    <FormField name="mfa-form-field" width="medium">
                      <ToggleButton
                        checked={state.force_password_reset || false}
                        label={t(
                          'cop_local_authentication.next_login_toggle_btn'
                        )}
                        testId="next-login-toggle-btn"
                        onChange={(e) => {
                          dispatch({
                            value: e.target.checked,
                            field: 'force_password_reset',
                            type: 'CHANGE_FIELD'
                          })
                        }}
                      />
                    </FormField>
                    {state.force_password_reset && (
                      <Box direction="row" align="center" gap="xsmall">
                        <CircleAlert size="small" />
                        <Text size="xsmall">
                          {t('cop_local_authentication.next_login_toggle_info')}
                        </Text>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box width={boxWidth}>
                <Typography
                  level="4"
                  type="heading"
                  weight="normal"
                  testId="cop-local-auth-title"
                >
                  {t('cop_local_authentication.lockout_expiration')}
                </Typography>
                <FormField name="mfa-form-field" width="medium">
                  <ToggleButton
                    checked={state.enable_failed_attempts_lockout || undefined}
                    label={t('cop_local_authentication.lockout_toggle')}
                    testId="lockout-toggle-btn"
                    onChange={(e) => {
                      dispatch({
                        value: e.target.checked,
                        field: 'enable_failed_attempts_lockout',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                </FormField>
                {state.enable_failed_attempts_lockout && (
                  <>
                    <FormInput
                      inputType="text"
                      label={t(
                        'cop_local_authentication.failed_attempts_limit'
                      )}
                      name="failed-attempts-limit-input"
                      placeholder={t(
                        'cop_local_authentication.failed_attempts_limit'
                      )}
                      testId="failed-attempts-limit-input"
                      value={state.failed_attempts_limit}
                      validate={() =>
                        getFailedAttemptsLimitError(
                          state.failed_attempts_limit,
                          t
                        )
                      }
                      onChange={(e) => {
                        dispatch({
                          value: e.target.value,
                          field: 'failed_attempts_limit',
                          type: 'CHANGE_FIELD'
                        })
                      }}
                    />
                    <Box direction="row" alignContent="start" gap="medium">
                      <FormInput
                        inputType="text"
                        size="small"
                        label={t(
                          'cop_local_authentication.failed_attempts_time'
                        )}
                        name="failed-attempt-time-input"
                        placeholder={t(
                          'cop_local_authentication.failed_attempts_time'
                        )}
                        testId="failed-attempt-time-input"
                        value={state.failed_attempts_lockout_duration}
                        validate={() =>
                          getFailedAttemptsTimeError(
                            state.failed_attempts_lockout_duration,
                            t
                          )
                        }
                        onChange={(e) => {
                          dispatch({
                            value: e.target.value,
                            field: 'failed_attempts_lockout_duration',
                            type: 'CHANGE_FIELD'
                          })
                        }}
                      />
                      <Text margin={{ top: 'auto', bottom: 'small' }}>
                        {t('minutes')}
                      </Text>
                    </Box>
                  </>
                )}
                <Box direction="row" alignContent="start" gap="medium">
                  <FormInput
                    inputType="text"
                    size="small"
                    label={t(
                      'cop_local_authentication.inactive_account_lockout_duration'
                    )}
                    name="inactive-account-lockout-input"
                    placeholder={t(
                      'cop_local_authentication.inactive_account_lockout_duration_placeholder'
                    )}
                    testId="inactive-account-lockout-input"
                    value={state.inactive_account_lockout_duration}
                    validate={() =>
                      getInactiveAccountLockoutError(
                        state.inactive_account_lockout_duration,
                        t
                      )
                    }
                    onChange={(e) => {
                      dispatch({
                        value: e.target.value,
                        field: 'inactive_account_lockout_duration',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                  <Text margin={{ top: 'auto', bottom: 'small' }}>
                    {t('days')}
                  </Text>
                </Box>
                <Box direction="row" alignContent="start" gap="medium">
                  <FormInput
                    inputType="text"
                    size="small"
                    label={t(
                      'cop_local_authentication.password_expiration_duration'
                    )}
                    name="password-expiration-days-input"
                    placeholder={t(
                      'cop_local_authentication.password_expiration_duration_placeholder'
                    )}
                    testId="password-expiration-days-input"
                    value={state.password_expiration_duration}
                    validate={() =>
                      getPasswordExpiryError(
                        state.password_expiration_duration,
                        t
                      )
                    }
                    onChange={(e) => {
                      dispatch({
                        value: e.target.value,
                        field: 'password_expiration_duration',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                  <Text margin={{ top: 'auto', bottom: 'small' }}>
                    {t('days')}
                  </Text>
                </Box>
                <Box direction="row" alignContent="start" gap="medium">
                  <FormInput
                    inputType="text"
                    size="small"
                    label={t('cop_local_authentication.minimum_password_age')}
                    name="minimum-password-age-input"
                    placeholder={t(
                      'cop_local_authentication.minimum_password_age_placeholder'
                    )}
                    testId="minimum-password-age-input"
                    value={state.minimum_password_age}
                    validate={() =>
                      getMinimumPasswordAgeError(
                        state.password_expiration_duration,
                        state.minimum_password_age,
                        t
                      )
                    }
                    onChange={(e) => {
                      dispatch({
                        value: e.target.value,
                        field: 'minimum_password_age',
                        type: 'CHANGE_FIELD'
                      })
                    }}
                  />
                  <Text margin={{ top: 'auto', bottom: 'small' }}>
                    {t('days')}
                  </Text>
                </Box>
              </Box>
            </Box>

            {discardModal && (
              <DiscardChangesModal
                closeModal={() => setDiscardModal(false)}
                onContinue={handleDiscard}
              />
            )}
            {confirmModal && (
              <ConfirmModal
                onSubmit={handleSubmit}
                onClose={() => setConfirmModal(false)}
              />
            )}
          </>
        </CCSForm>
      )}
      {successMessage &&
        displayNotification(successMessage, 'info', setSuccessMessage)}
    </Box>
  )
}

export default LocalAuthentication
