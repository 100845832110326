import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, FormField, SelectMultiple } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import isEmpty from 'lodash/isEmpty'
import { CircleInformation } from 'grommet-icons'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  Loader
} from '../../../../../../components'
import { get } from '../../../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../../../utils/feature-flag-utils'

const SubmitForReviewModal = ({
  formValues,
  selectedReviewers,
  setSelectedReviewers,
  handleFinish,
  isDisabled,
  closeReviewModel
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const [reviewersLoading, setReviewersLoading] = useState(false)
  const [reviewerOptions, setReviewerOptions] = useState([])
  const [defaultReviewerOptions, setDefaultReviewerOptions] = useState([])
  const [showEmptyError, setShowEmptyError] = useState('')
  const [message, setMessage] = useState('')
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''

  const handleCancel = () => {
    closeReviewModel(false)
  }

  const handleSubmit = () => {
    if (!isEmpty(selectedReviewers)) {
      handleFinish(formValues, selectedReviewers)
    } else {
      setShowEmptyError(true)
      setMessage(t('whats_new.approval.no_reviewers_added_error'))
    }
  }

  useEffect(() => {
    setReviewersLoading(true)
    get(
      '/whatsnew/v1/approver',
      {},
      oidcUser.access_token,
      false,
      {},
      {
        'X-Account-ID': platformCustomerId
      }
    ).then(
      (response) => {
        if (response?.data?.length > 0) {
          const reviewerArray = []
          response?.data?.map((value) =>
            reviewerArray.push({
              value: value?.id,
              label: `${value?.firstName} ${value?.lastName} <${value?.id}> ${value?.businessUnit}`
            })
          )
          setReviewerOptions(reviewerArray)
          setDefaultReviewerOptions(reviewerArray)
        }
        setReviewersLoading(false)
      },
      () => {
        setReviewersLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  return (
    <ModalDialog
      position="center"
      testId="submit-review-dialog"
      header={
        <ModalHeader
          title={
            <Box gap="xsmall">
              <Typography
                type="heading"
                level="1"
                testId="submit-approval-title"
              >
                {t('whats_new.approval.submit_for_approval_title')}
              </Typography>
              <Typography
                type="paragraph"
                size="small"
                testId="submit-approval-subtitle"
              >
                {t('whats_new.approval.submit_for_approval_subtitle')}
              </Typography>
            </Box>
          }
        />
      }
      content={
        <Box margin={{ top: 'small' }}>
          {reviewersLoading ? (
            <Box direction="row" align="center" justify="center">
              <Loader testId="reviewer-loader" />
            </Box>
          ) : (
            <FormField
              name="addReviewers"
              data-testid="reviewer-select-formfield"
              label={t('whats_new.approval.form_field_reviewer_label')}
            >
              <SelectMultiple
                data-testid="reviewer-select-field"
                id="reviewer"
                name="reviewer"
                placeholder={t('whats_new.approval.reviewer_placeholder')}
                searchPlaceholder={t(
                  'whats_new.approval.placeholder_reviewer_search'
                )}
                options={reviewerOptions || []}
                value={selectedReviewers}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ value: nextValue }) => {
                  setSelectedReviewers(nextValue)
                  setShowEmptyError('')
                }}
                onSearch={(text) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    '\\$&'
                  )
                  const exp = new RegExp(escapedText, 'i')
                  setReviewerOptions(
                    defaultReviewerOptions.filter((o) => exp.test(o?.label))
                  )
                }}
                onClose={() => setReviewerOptions(defaultReviewerOptions)}
              />
            </FormField>
          )}
          {showEmptyError && (
            <Box
              margin={{ top: 'large' }}
              direction="row"
              background="validation-critical"
              pad="small"
              round="xsmall"
            >
              <Typography
                type="text"
                size="medium"
                testId="review-error-text"
                icon={<CircleInformation />}
              >
                {message}
              </Typography>
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  default: true,
                  testId: 'cancel-add-reviewer-btn',
                  onClick: handleCancel
                },
                {
                  id: 1,
                  label: t('common:submit'),
                  primary: true,
                  testId: 'confirm-add-reviewer-button',
                  disabled: isDisabled,
                  onClick: handleSubmit
                }
              ]}
              testId="submit-for-review-buttons"
            />
          }
        />
      }
      onClose={handleCancel}
      width="large"
    />
  )
}
export { SubmitForReviewModal }

SubmitForReviewModal.propTypes = {
  formValues: PropTypes.object.isRequired,
  selectedReviewers: PropTypes.array.isRequired,
  setSelectedReviewers: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  closeReviewModel: PropTypes.func.isRequired
}
