// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import { StatusCritical, Alert, StatusGood, Checkmark } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'

import { Typography, Loader, NoDataInfo } from '../../../../components'
import { get } from '../../../../utils/api-utils'

const AssetsByRisk = () => {
  const { t } = useTranslation(['manage'])
  const url = `/internal-assets/v1beta1/risks`
  const { oidcUser } = useReactOidc()
  const [isLoading, setIsLoading] = useState(false)
  const [assetByRisksGroupData, setAssetByRisksGroupData] = useState([])
  const riskSummaryEnums = Object.freeze({
    RED_SUPPORT: t('asset_management.assets_map.multiple.red_support'),
    RED_AGE: t('asset_management.assets_map.multiple.red_age'),
    YELLOW_SUPPORT: t('asset_management.assets_map.multiple.yellow_support'),
    YELLOW_AGE: t('asset_management.assets_map.multiple.yellow_age')
  })

  const getAssetByRisksGroupData = () => {
    setIsLoading(true)
    get(url, {}, oidcUser?.access_token).then(
      (response) => {
        setIsLoading(false)
        setAssetByRisksGroupData(response?.data)
      },
      (error) => {
        setIsLoading(false)
        console.error('Failed to fetch asset workload data:', error)
      }
    )
  }

  useEffect(() => {
    getAssetByRisksGroupData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, oidcUser?.access_token])

  const getRiskIcon = (riskLevel) => {
    switch (riskLevel) {
      case 2:
        return (
          <Box
            pad="small"
            size="small"
            background={{ color: 'validation-warning' }}
            round
          >
            <Alert color="status-warning" size="small" />
          </Box>
        )
      case 3:
        return (
          <Box
            pad="small"
            size="small"
            background={{ color: 'validation-critical' }}
            round
          >
            <StatusCritical color="red" size="small" />
          </Box>
        )
      default:
        return (
          <Box
            pad="small"
            size="small"
            background={{ color: 'validation-ok' }}
            round
          >
            <StatusGood color="green" size="small" />
          </Box>
        )
    }
  }

  const getRiskCount = () => {
    let count = 0
    if (assetByRisksGroupData?.riskAgeLevel > 1) count += 1
    if (assetByRisksGroupData?.riskSupportLevel > 1) count += 1
    return count
  }

  const renderRiskItem = (riskLevel, riskCount, riskEnum, riskDefinition) => (
    <Box
      gap="small"
      direction="row"
      align="center"
      pad="small"
      round="small"
      border={{ color: 'background-back', size: 'small' }}
    >
      {getRiskIcon(riskLevel)}
      <Box gap="xsmall" direction="column">
        <Typography type="text" size="medium" weight={500}>
          {`${riskCount} ${riskEnum}`}
        </Typography>
        <Typography type="text" size="small">
          {riskDefinition}
        </Typography>
      </Box>
    </Box>
  )

  return isLoading ? (
    <Loader testId="asset-risks-loader" isLoading={isLoading} />
  ) : (
    <Box data-testid="assets-by-age-body-container" flex={{ grow: 1 }}>
      <Box
        data-testid="assets-risk-header"
        gap="xsmall"
        direction="row"
        align="baseline"
        flex={{ grow: assetByRisksGroupData?.items?.length > 0 ? 0 : 1 }}
        pad={{ bottom: 'medium' }}
      >
        <Typography
          data-testid="assets-risk-summary-value"
          type="text"
          size="3xl"
          emphasis
        >
          {getRiskCount()}
        </Typography>
        <Typography
          data-testid="assets-risk-summary-label"
          type="text"
          size="xsmall"
          emphasis
        >
          {t('asset_management.widgets.assets_by_risk.risk_factors')}
        </Typography>
      </Box>
      {getRiskCount() > 0 ? (
        <Box gap="medium">
          {assetByRisksGroupData?.riskAgeLevel > 1 &&
            renderRiskItem(
              assetByRisksGroupData?.riskAgeLevel,
              assetByRisksGroupData?.riskAgeAssetCount,
              assetByRisksGroupData?.riskAgeLevel > 2
                ? riskSummaryEnums.RED_AGE
                : riskSummaryEnums.YELLOW_AGE,
              t('asset_management.widgets.assets_by_risk.replace_age')
            )}
          {assetByRisksGroupData?.riskSupportLevel > 1 &&
            renderRiskItem(
              assetByRisksGroupData?.riskSupportLevel,
              assetByRisksGroupData?.riskSupportAssetCount,
              assetByRisksGroupData?.riskSupportLevel > 2
                ? riskSummaryEnums.RED_SUPPORT
                : riskSummaryEnums.YELLOW_SUPPORT,
              t('asset_management.widgets.assets_by_risk.upgrade_age')
            )}
        </Box>
      ) : (
        <NoDataInfo
          testId="no-risk-factors"
          gap="small"
          gapTitleSubtitle="false"
          icon={<Checkmark size="large" color="text-weak" />}
          title={t('asset_management.widgets.assets_by_risk.no_risk_factors')}
          subtitle={t(
            'asset_management.widgets.assets_by_risk.no_risk_factors_desc'
          )}
        />
      )}
    </Box>
  )
}

export default AssetsByRisk
