// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Anchor, Notification, Typography } from '../../components'
import { getWorkspaceString, WKSPC_PLURAL } from '../../utils/common-utils'

const AccountsUnavailableError = ({ email }) => {
  const { t } = useTranslation(['authn'])
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  return (
    <Box width="large">
      <Notification
        backgroundColor="status-critical"
        testId="customer-accounts-error"
        text={
          <Typography
            type="text"
            testId="customer-accounts-not-abailable-msg"
            size="medium"
          >
            <Trans
              i18nKey="authn:acct_onboarding.no_accounts_available_error"
              t={t}
              values={{
                email,
                accounts: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_PLURAL
                )
              }}
            >
              <Anchor
                label={t('authn:acct_onboarding.contact_support')}
                weight="bold"
                href="https://h41390.www4.hpe.com/support/index.html?form=glsupport"
                target="_blank"
                testId="contact-support-anchor"
              />
              <Anchor
                label={t('authn:acct_onboarding.signing_back_in')}
                weight="bold"
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/sign-out')
                }}
                testId="contact-support-anchor"
              />
            </Trans>
          </Typography>
        }
        type="inline"
      />
    </Box>
  )
}

AccountsUnavailableError.propTypes = {
  email: PropTypes.string.isRequired
}

export default AccountsUnavailableError
