// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import {
  UserAdd,
  CloudUpload,
  Group,
  Cycle,
  Catalog,
  Location
} from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { AssignRoleModal } from '../../manage-account/identity/common-components/assign-roles-modal'
import { isInventoryOwnedWorkspace } from '../../../utils/feature-flag-utils'
import InviteUser from '../../../commoncomponents/identity/users/InviteUser'

const HomeStaticCardData = (setModalOpen) => {
  const isDev = process.env.NODE_ENV === 'development'
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  return [
    {
      id: 1,
      title: 'invite_users.title',
      icon: <UserAdd color="red" />,
      background: 'background',
      description: showWorkspaceString
        ? 'invite_users.description_workspace'
        : 'invite_users.description',
      actionBtnLbl: 'invite_users.btn_lbl',
      actionBtnColor: '',
      testId: 'invite-user-card',
      modal: (
        <InviteUser inviteUserOpen path="/home" onSetOpen={setModalOpen} />
      ),
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.edit'
      }
    },
    {
      id: 2,
      icon: <Group color="#9B6310" />,
      title: 'assign_user_access.title',
      description: 'assign_user_access.description',
      background: 'background',
      actionBtnLbl: 'assign_user_access.btn_lbl',
      actionBtnColor: '',
      testId: 'assign-user-access-card',
      modal: <AssignRoleModal setModal={setModalOpen} />,
      routeTo: '/manage-account/identity/assignments',
      rbac: {
        resource: '/ccs/authorization',
        permission: 'ccs.authorization.view'
      }
    },
    {
      id: 3,
      icon: <CloudUpload color="purple" />,
      title: 'onboard_devices.title',
      description: 'onboard_devices.description',
      background: 'background',
      actionBtnLbl: 'onboard_devices.btn_lbl',
      actionBtnColor: '',
      testId: 'onboard-devices-card',
      routeTo: '/devices/inventory',
      hidden: !isInventoryOwnedWorkspace(),
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    },
    {
      id: 4,
      icon: <Cycle color="blue" />,
      title: 'manage_subscriptions.title',
      description: 'manage_subscriptions.description',
      background: 'background',
      actionBtnLbl: 'manage_subscriptions.btn_lbl',
      actionBtnColor: '',
      testId: 'add-subscription-card',
      routeTo: '/manage-account/subscriptions/device-subscriptions',
      hidden: !isInventoryOwnedWorkspace(),
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }
    },
    {
      id: 6,
      icon: <Catalog color="red" />,
      title: 'tutorial:tutorial_home',
      description: 'tutorial:learn_by_doing',
      background: 'background',
      actionBtnLbl: 'tutorial:launch',
      actionBtnColor: '',
      testId: 'tutorial-card',
      routeTo: '/tutorial/books',
      hidden: !isDev
    },
    {
      id: 7,
      icon: <Location color="blue" />,
      title: 'location:location_title',
      description: 'location:location_quick_action_description',
      background: 'background',
      actionBtnLbl: 'location:create_location',
      actionBtnColor: '',
      testId: 'location-card',
      routeTo: '/manage-account/locations/create-location',
      hideFor: {
        deployment: ['COP', 'GLOP']
      },
      rbac: {
        resource: '/ccs/location-management',
        permission: 'ccs.location-management.edit'
      },
      hidden: !isInventoryOwnedWorkspace()
    }
  ]
}

export { HomeStaticCardData }
