// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import lodashGet from 'lodash/get'

import ReuseableDiscardChangesModal from '../../discard-changes-modal/ReuseableDiscardChangesModal'

const DiscardChangesModal = ({ closeModal, onContinue, goBack }) => {
  const { t } = useTranslation(['device'])
  const navigate = useNavigate()
  const location = useLocation()
  const { customerData } = useLocation()
  return (
    <ReuseableDiscardChangesModal
      testId="discard-folder-changes-modal-dialog"
      footerButtonslist={[
        {
          label: t('cancel'),
          default: true,
          testId: 'cancel-btn',
          onClick: closeModal
        },
        {
          label: t('continue_label'),
          primary: true,
          testId: 'continue-btn',
          onClick: () => {
            if (goBack) {
              closeModal()
              sessionStorage.removeItem('folderDetailParam')
              if (
                lodashGet(location, 'pathname') ===
                '/manage-ccs/customers/customer-details/folders/folder-details'
              )
                navigate('/manage-ccs/customers/customer-details', {
                  state: { customerData }
                })
              else if (
                location?.pathname ===
                '/manage-account/activate/folders/folder-details'
              ) {
                navigate('/manage-account/activate/folders-list')
              } else navigate(-1)
            } else onContinue()
          }
        }
      ]}
      footerTestId="discard-changes-actions-buttons"
      onClose={closeModal}
    />
  )
}

DiscardChangesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  goBack: PropTypes.bool.isRequired
}

export { DiscardChangesModal }
