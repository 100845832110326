// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

// TODO: refactor this so it has a cleaner schema (i.e. slug)

import { groupBy, omit } from 'lodash'

import {
  GLC,
  ALL_REGION,
  OTHER,
  UNKNOWN,
  SERVICE_FEATURE_FLAGS
} from '../constants'

import getServiceDefinitions from './serviceDefinitions'
import { isGLOP, isStandalone } from './imports'
import { createServiceMocks } from './createServiceMocks'

export const hydrateServiceDefinitions = (provisions, LDFlags) => {
  const provisionsByServiceManagerSlug = groupBy(provisions, 'slug')

  // If service has a service manager then update the region definition with the application instance id, otherwise leave the region definition as is
  const handleRegions = ({ serviceManagerSlug }) => {
    const serviceManager = provisionsByServiceManagerSlug[serviceManagerSlug]

    const provisionRegion = {
      regions: serviceManager
        ? serviceManager.map(
            ({
              region,
              catalog_visible,
              application_instance_id,
              application_customer_id,
              provision_status,
              msp_conversion_status,
              application_id
            }) => ({
              code: region,
              catalog_visible,
              provisionStatus: provision_status,
              serviceManagerId: application_instance_id,
              serviceManagerCustomerId: application_customer_id,
              mspConversionStatus: msp_conversion_status,
              /**
               * The following fields are added in for backward compatibility (e.g. InstalledAppList)
               */
              provision_status,
              application_instance_id,
              application_id
            })
          )
        : [
            {
              code: ALL_REGION,
              catalog_visible: true,
              serviceManagerId: OTHER,
              provisionStatus: UNKNOWN,
              mspConversionStatus: UNKNOWN
            }
          ]
    }

    return Object.assign(provisionRegion, {
      location: serviceManager
        ? serviceManager
            .map(({ location }) => location)
            .filter((location) => location)
            .join()
        : []
    })
  }

  /**
   * If the service has support from the backend, then check if the service is actually
   * returned from the backend to determine whether it be added to the list of services to render
   */
  const services = getServiceDefinitions(LDFlags)
    .filter(({ serviceManagerSlug }) => {
      // Determines whether the backend has returned the service manager in the provisions api response
      const backendEnabledServiceManager = Boolean(
        provisionsByServiceManagerSlug[serviceManagerSlug]
      )

      if (isGLOP()) {
        return backendEnabledServiceManager
      }
      // HACK: should only show "GLCS" and "OTHER" services when we are in standalone. "GLC" can be removed once the all workspaces are migrated
      if (serviceManagerSlug === GLC || serviceManagerSlug === OTHER) {
        return isStandalone()
      }

      return backendEnabledServiceManager
    })
    .map((service) => {
      const serviceManager =
        provisionsByServiceManagerSlug[service.serviceManagerSlug]
      return {
        ...omit(service, ['exceptions']),
        tenant_only_supported: serviceManager?.length
          ? serviceManager[0].tenant_only_supported
          : false,
        ...handleRegions(service)
      }
    })

  return [
    ...createServiceMocks(provisionsByServiceManagerSlug, services),
    ...services
  ]
    .filter(
      ({ serviceManagerSlug, serviceSlug }) =>
        (!SERVICE_FEATURE_FLAGS[serviceManagerSlug] ||
          LDFlags[SERVICE_FEATURE_FLAGS[serviceManagerSlug]]) &&
        (!SERVICE_FEATURE_FLAGS[serviceSlug] ||
          LDFlags[SERVICE_FEATURE_FLAGS[serviceSlug]])
    )
    .filter(
      ({ serviceManagerSlug, serviceSlug }) =>
        !isGLOP() || serviceManagerSlug === serviceSlug
    )
}

/**
 * This shim converts the services list which are unique by service name to a list of services unique by service name + region.
 * Should be removed once the templates can be updated to use the more ideal schema. *
 */
export const legacyShim = (services) =>
  services.flatMap((service) =>
    service.regions.map(
      ({
        code,
        catalog_visible,
        serviceManagerId,
        provisionStatus = UNKNOWN,
        mspConversionStatus
      }) => ({
        ...omit(service, 'regions'),
        serviceManager: service.serviceManagerSlug,
        slug: service.serviceSlug,
        region: code,
        catalog_visible,
        provision_status: provisionStatus,
        msp_conversion_status: mspConversionStatus,
        application_instance_id: serviceManagerId
      })
    )
  )

/**
 * Munges data together between provisioned apps passed in as a parameter from an API
 * call, statically derived services tied to service managers, and statically derived
 * services without a service manager.
 */
export const shimServices = (provisions, LDFlags) => {
  const services = hydrateServiceDefinitions(provisions, LDFlags)
  const servicesLegacy = legacyShim(services)
  return {
    services,
    servicesLegacy
  }
}
