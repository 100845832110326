// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useCallback, useEffect } from 'react'
import { Box } from 'grommet'
import { useTranslation, Trans } from 'react-i18next'
import { Previous, StatusCritical, StatusGood } from 'grommet-icons'
import { useNavigate, useParams } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Button,
  Loader,
  Notification,
  Typography
} from '../../../../../../components'
import {
  get,
  getErrorMessage,
  post,
  del
} from '../../../../../../utils/api-utils'
import Details from '../../../../../../commoncomponents/notifications-dashboard/Details'
import ApprovalDetails from '../../../../../../commoncomponents/notifications-dashboard/ApprovalDetails'
import HistoryDetails from '../../../../../../commoncomponents/notifications-dashboard/HistoryDetails'
import {
  TYPE_ALL_PENDING,
  TYPE_MY_REQUEST,
  TYPE_MY_APPROVAL,
  TYPE_ALL_REJECTED
} from '../../constants'
import { DeleteConfirmationModal } from '../create-notification/DeleteConfirmationModal'

import { QuickApprove } from './ApprovalConfirmationModals/QuickApprove'
import { QuickReject } from './ApprovalConfirmationModals/QuickReject'

const NotificationApprovalDetails = () => {
  const [loading, setLoading] = useState(false)
  const [notificationDetails, setNotificationDetails] = useState([])
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const { notificationId, type } = useParams()
  const { t } = useTranslation(['manage', 'common'])
  const [errorMessage, setErrorMessage] = useState(null)
  const [historyDetails, setHistoryDetails] = useState([])
  const [openApproveConfirmation, setOpenApproveConfirmation] = useState(false)
  const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false)
  const [approveComment, setApproveComment] = useState('')
  const [rejectComment, setRejectComment] = useState('')
  const [approveSuccess, setApproveSuccess] = useState(false)
  const [actionType, setActionType] = useState('')
  const [approveAction, setApproveAction] = useState('')
  const [myRequestAction, setMyRequestAction] = useState('')
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  const approvalOptions = [
    t('notification.approval.approve'),
    t('notification.approval.reject')
  ]

  const myRequestOptions =
    notificationDetails?.approval_state === 'REJECTED'
      ? [t('edit'), t('delete_label')]
      : [t('delete_label')]

  const handleBackBtnClick = () => {
    navigate('/manage-ccs/notifications')
  }

  const fetchNotificationDetails = useCallback(() => {
    setLoading(true)

    const url = `/notifications-svc/ui/v1alpha1/reviews/${notificationId}`

    get(url, {}, oidcUser.access_token).then(
      (response) => {
        setLoading(false)
        setNotificationDetails(response?.data)
      },
      (error) => {
        setNotificationDetails([])
        setLoading(false)
        setErrorMessage(getErrorMessage(error, t))
      }
    )
  }, [oidcUser.access_token, notificationId, t])

  const fetchHistoryDetails = useCallback(() => {
    setLoading(true)

    const url = `/notifications-svc/ui/v1alpha1/reviews/${notificationId}/history`

    get(url, { limit: 10 }, oidcUser.access_token).then(
      (response) => {
        setLoading(false)
        setHistoryDetails(response?.data)
      },
      (error) => {
        setHistoryDetails([])
        setLoading(false)
        setErrorMessage(getErrorMessage(error, t))
      }
    )
  }, [oidcUser.access_token, notificationId, t])

  useEffect(() => {
    fetchNotificationDetails()
    fetchHistoryDetails()
  }, [fetchNotificationDetails, fetchHistoryDetails])

  const handleApprove = () => {
    const requestBody = {
      reviewAction: 'APPROVED',
      comment: approveComment
    }
    post(
      `/notifications-svc/ui/v1alpha1/reviews/${notificationId}`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setApproveSuccess(true)
        setActionType(t('notification.approval.approved'))
        fetchNotificationDetails('')
        fetchHistoryDetails('')
        setApproveComment('')
        setApproveAction('')
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        setApproveSuccess(false)
        setApproveAction('')
      }
    )
  }

  const handleReject = () => {
    const requestBody = {
      reviewAction: 'REJECTED',
      comment: rejectComment
    }
    post(
      `/notifications-svc/ui/v1alpha1/reviews/${notificationId}`,
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        setActionType(t('notification.approval.rejected'))
        setApproveSuccess(true)
        fetchNotificationDetails('')
        fetchHistoryDetails('')
        setRejectComment('')
        setApproveAction('')
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        setApproveSuccess(false)
        setApproveAction('')
      }
    )
  }

  const handleDelete = () => {
    del(
      `/notifications-svc/ui/v1alpha1/reviews/${notificationId}`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        setDeleteSuccess(true)
        fetchNotificationDetails('')
        fetchHistoryDetails('')
        setMyRequestAction('')

        setErrorMessage(null)
      },
      (error) => {
        setDeleteSuccess(false)
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        setMyRequestAction('')
      }
    )
  }

  const handleEditNotificationRequestClick = () => {
    navigate(
      `/manage-ccs/notifications/edit-notification-request/${notificationId}`
    )
  }

  return (
    <Box data-testid="notification-details" justify="center">
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={t('notification.notification_tab')}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="notification-prev-btn"
        />
      </Box>
      <Box
        pad={{ horizontal: 'xlarge', top: 'medium', right: 'xsmall' }}
        width="large"
        justify="between"
        align="start"
        alignSelf="center"
      >
        {loading ? (
          <Loader testId="loader-spinner" />
        ) : (
          <>
            <Details
              notificationDetails={notificationDetails}
              isApprovalView={type === TYPE_MY_APPROVAL}
              isAllPendingView={
                type === TYPE_ALL_PENDING || type === TYPE_ALL_REJECTED
              }
              isMyRequestView={type === TYPE_MY_REQUEST}
              approvalOptions={approvalOptions}
              approveAction={approveAction}
              setApproveAction={setApproveAction}
              setOpenApproveConfirmation={setOpenApproveConfirmation}
              setOpenRejectConfirmation={setOpenRejectConfirmation}
              myRequestOptions={myRequestOptions}
              myRequestAction={myRequestAction}
              setMyRequestAction={setMyRequestAction}
              setOpenDeleteConfirmation={setOpenDeleteConfirmation}
              handleEditNotificationRequestClick={
                handleEditNotificationRequestClick
              }
            />
            <ApprovalDetails notificationDetails={notificationDetails} />
            <HistoryDetails historyDetails={historyDetails} />
          </>
        )}
        {openApproveConfirmation && (
          <QuickApprove
            onSetOpen={setOpenApproveConfirmation}
            handleApprove={handleApprove}
            approveComment={approveComment}
            setApproveComment={setApproveComment}
          />
        )}
        {openRejectConfirmation && (
          <QuickReject
            onSetOpen={setOpenRejectConfirmation}
            handleReject={handleReject}
            rejectComment={rejectComment}
            setRejectComment={setRejectComment}
          />
        )}
        {approveSuccess && (
          <Notification
            icon={<StatusGood color="text-strong" />}
            onClose={() => setApproveSuccess(false)}
            testId="status-good-notification"
            text={
              <Typography type="text" size="medium">
                {t('notification.approval.approve_success_message', {
                  status: actionType
                })}
              </Typography>
            }
          />
        )}
        {openDeleteConfirmation && (
          <DeleteConfirmationModal
            onSetOpen={setOpenDeleteConfirmation}
            handleDelete={handleDelete}
            isReviewNotification
          />
        )}
        {deleteSuccess && (
          <Notification
            icon={<StatusGood color="text-strong" />}
            onClose={() => setDeleteSuccess(false)}
            testId="status-good-notification"
            text={
              <Typography type="text" size="medium">
                <Trans
                  i18nKey="notification.delete_success_message"
                  t={t}
                  boldText={1}
                >
                  <strong>{1}</strong>
                </Trans>
              </Typography>
            }
          />
        )}
        {errorMessage && (
          <Notification
            backgroundColor="status-critical"
            onClose={() => setErrorMessage(null)}
            testId="critical-inline-notification"
            text={errorMessage}
            icon={<StatusCritical size="medium" />}
          />
        )}
      </Box>
    </Box>
  )
}

export default NotificationApprovalDetails
