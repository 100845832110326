// Copyright 2024 Hewlett Packard Enterprise Development LP
/* eslint-disable react/prop-types */
import { Box, Button, Markdown } from 'grommet'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useParams } from 'react-router-dom'

import { Typography, ModalDialog } from '../../../components'
import { patch } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'

const StatusModal = ({
  onSetOpen,
  webhookInfo,
  onSuccess = () => {},
  paused
}) => {
  const { t } = useTranslation(['authz', 'common', 'automations'])
  const { oidcUser } = useReactOidc()
  const [isDisabled, setIsDisabled] = useState(false)
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const { id } = useParams()
  const changeWebhookStatus = async () => {
    try {
      setIsDisabled(true)

      // First patch call
      await patch(
        `/events/v1beta1/webhooks/${id}`,
        {
          paused: webhookInfo?.status === 'ERROR' ? !paused : paused
        },
        oidcUser?.access_token
      )
      if (webhookInfo?.status === 'ERROR') {
        // Second patch call
        await patch(
          `/events/v1beta1/webhooks/${id}`,
          { paused },
          oidcUser?.access_token
        )
      }

      // Handle success
      onSetOpen(false)
      onSuccess({
        notificationDesc: (
          <Markdown>
            {t(
              `automations:webhooks.${paused ? 'disable' : 'enable'}_webhook`,
              {
                webhookName: webhookInfo?.name
              }
            )}
          </Markdown>
        ),
        severity: 'info'
      })
    } catch (error) {
      onSetOpen(false)
      setIsDisabled(false)
      setShowErrorNotification(
        displayNotification(error, t, setShowErrorNotification)
      )
    }
  }

  const onCloseModal = () => {
    onSetOpen(false)
  }

  return (
    <ModalDialog
      header={
        <Typography type="heading" level="2">
          {t(
            `automations:webhooks.${
              paused ? 'disable' : 'enable'
            }_webhook_title`
          )}
        </Typography>
      }
      content={
        <Box margin={{ top: 'medium' }} width={{ min: 'full', max: 'medium' }}>
          {showErrorNotification}
          <Box margin={{ bottom: 'medium' }}>
            <Typography type="text" margin={{ bottom: 'small' }}>
              {t(
                `automations:webhooks.${
                  paused ? 'disable' : 'enable'
                }_webhook_desc`
              )}
            </Typography>
            <Markdown>
              {t(
                `automations:webhooks.${
                  paused ? 'disable' : 'enable'
                }_webhook_question`,
                {
                  webhookName: webhookInfo?.name
                }
              )}
            </Markdown>
          </Box>
          <Box
            direction="row"
            justify="end"
            gap="medium"
            margin={{ top: 'small' }}
          >
            <Button
              label={t('common:cancel')}
              onClick={onCloseModal}
              data-testid={`${
                paused ? 'disable' : 'enable'
              }-webhook-cancel-btn`}
            />
            <Button
              primary
              type="button"
              label={t(
                `automations:webhooks.${
                  paused ? 'disable' : 'enable'
                }_webhook_title`
              )}
              onClick={changeWebhookStatus}
              disabled={isDisabled}
              data-testId={`${
                paused ? 'disable' : 'enable'
              }-webhook-submit-btn`}
            />
          </Box>
        </Box>
      }
      onClose={onCloseModal}
      testId={`${paused ? 'disable' : 'enable'}-webhook-modal`}
    />
  )
}

StatusModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  webhookInfo: PropTypes.shape({
    webhookId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired // Added status prop type
  }).isRequired,
  onSuccess: PropTypes.func,
  paused: PropTypes.bool.isRequired
}

export default StatusModal
