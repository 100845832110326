// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Box } from 'grommet'
import { useParams } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Typography } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  useAppCatalogContext,
  AppCatalogActions
} from '../../../../context/app-catalog-context'
import { get } from '../../../../utils/api-utils'
import { getRegionNameByRegionCode } from '../../utils/utils'
import { displayApiError } from '../../../../utils/error-handling-utils'

import { AppDetailList } from './AppDetailList'
import { AppImages } from './AppImages'

export const AppDetailsOverview = () => {
  const { t } = useTranslation(['common', 'apps'])
  const { oidcUser } = useReactOidc()
  const appDetailData =
    useAppCatalogContext()?.appDetailData ||
    JSON.parse(sessionStorage.getItem('appDetails'))
  const { appId, region } = useParams()
  const [appInstanceData, setAppInstanceData] = useState(null)
  const [regionList, setRegionList] = useState([])
  const { regionListData, dispatchAppCatalogContext } = useAppCatalogContext()
  // setting the current region to region (from params) if it is not undefined else to empty sting
  const [currentRegion, setCurrentRegion] = useState(
    region === undefined ? '' : region
  )
  const [showErrorNotification, setShowErrorNotification] = useState(null)

  useEffect(() => {
    if (regionListData.length === 0) {
      get('/geo/ui/v1/regions', {}, oidcUser.access_token).then(
        (response) => {
          const { regions } = response.data
          if (regions) {
            dispatchAppCatalogContext({
              type: AppCatalogActions.SET_REGION_LIST,
              data: regions
            })
          }
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
  }, [oidcUser.access_token, dispatchAppCatalogContext, regionListData, t])

  useEffect(() => {
    if (regionListData.length > 0) {
      get(
        '/app-catalog/ui/v1/app-instances',
        {
          status: 'ONBOARDED',
          // not sending ccs_region if currentRegion and region dropdown list is empty
          ...(currentRegion !== '' &&
            regionList.length !== 0 && { ccs_region: currentRegion }),
          appid_or_slug: appId
        },
        oidcUser.access_token
      ).then(
        (response) => {
          if (regionList.length === 0) {
            // getting the regions for populating the application detail dropdown
            const regionCodes = [
              ...new Set(
                response.data.instances.map((value) => value.ccs_region)
              )
            ]
            const regList = regionCodes.map((val) => ({
              value: val,
              label: getRegionNameByRegionCode(val, regionListData)
            }))
            setRegionList(regList)
            if (currentRegion === '') {
              setCurrentRegion(regList[0].value)
            }
          }

          // getting the application details
          setAppInstanceData(response.data.instances[0])
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, appId, region, regionListData, currentRegion])

  return (
    <>
      <Grid
        fill
        rows={['flex']}
        columns={['auto', '360px']}
        areas={[
          { name: 'content', start: [0, 0], end: [0, 0] },
          { name: 'detail', start: [1, 0], end: [1, 0] }
        ]}
      >
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/app-catalog/app-instance',
            permission: 'ccs.app-catalog.view'
          }}
        >
          <Box
            gridArea="content"
            direction="column"
            pad={{ top: 'medium', right: 'medium' }}
          >
            <Box direction="row" pad={{ bottom: 'small' }}>
              <Typography type="heading" level="2">
                {t('apps:overview')}
              </Typography>
            </Box>
            <AppImages
              urls={
                appInstanceData && appInstanceData.screenshots
                  ? appInstanceData.screenshots
                  : []
              }
            />
            <Box pad={{ bottom: 'medium' }}>
              <Typography type="paragraph" testId="app-detail-description" fill>
                {appInstanceData && appInstanceData.description
                  ? appInstanceData.description
                  : ''}
              </Typography>
            </Box>
            <Box pad={{ bottom: 'medium' }}>
              <Typography type="paragraph" testId="app-detail-content" fill>
                {appInstanceData && appInstanceData.content
                  ? appInstanceData.content
                  : ''}
              </Typography>
            </Box>
          </Box>

          <Box gridArea="detail" fill pad={{ top: 'medium' }}>
            <AppDetailList
              app={appInstanceData}
              regionList={regionList}
              currentRegion={currentRegion}
              setCurrentRegion={setCurrentRegion}
              appName={appDetailData.name}
            />
          </Box>
        </VisibilityWrapper>
      </Grid>
      {showErrorNotification}
    </>
  )
}
