// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useState, useEffect } from 'react'
import { Box } from 'grommet'
import { Install } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { DataTable, Loader, NoDataInfo } from '../../../../components'
import { get } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { getPaginationShowIdx } from '../../../../utils/common-utils'

const WorkloadsDatatable = () => {
  const { t } = useTranslation(['device', 'manage', 'common'])
  const { oidcUser } = useReactOidc()
  const [workloads, setWorkloads] = useState([])
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showNoData, setShowNoData] = useState(false)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const columns = [
    {
      property: 'name',
      type: 'string',
      header: t('manage:asset_management.workloads_table.name'),
      primary: true
    },
    {
      property: 'type',
      type: 'string',
      header: t('manage:asset_management.workloads_table.type')
    },
    {
      property: 'model',
      type: 'string',
      header: t('manage:asset_management.workloads_table.model')
    },

    {
      property: 'cost',
      type: 'string',
      header: t('manage:asset_management.workloads_table.cost')
    }
  ]

  useEffect(() => {
    get(
      `/internal-assets/v1beta1/workloads`,
      {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage
      },
      oidcUser.access_token
    )
      .then(
        (response) => {
          setTotalItems(response?.data?.total)
          if (response?.data?.items?.length) setShowNoData(true)
          if (response?.data?.items) {
            setWorkloads(response?.data?.items)
          } else {
            setWorkloads([])
          }
        },
        (error) => {
          setWorkloads([])
          setErrorMessage(error)
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [oidcUser.access_token, t, page, itemsPerPage])

  const getWorkloads = () => {
    if (!showNoData) {
      return (
        <NoDataInfo
          subtitle={t('no_data_found')}
          icon={<Install size="large" />}
          testId="no-data-workloads"
        />
      )
    }
    return (
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          setItemsPerPage,
          page,
          setPage,
          pageIdxInfo,
          defaultRowsValue: 10,
          rowCountOptions: [10, 20, 50, 100]
        }}
        grid={{
          columns,
          data: workloads
        }}
        testId="workloads-table"
      />
    )
  }
  return (
    <>
      {errorMessage && displayApiError(errorMessage, t, setErrorMessage)}
      <Box width="large">
        {isLoading ? (
          <Box direction="row" align="center" justify="center">
            <Loader testId="workloads-loader" />
          </Box>
        ) : (
          getWorkloads()
        )}
      </Box>
    </>
  )
}

export { WorkloadsDatatable }
