// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useCallback, useState } from 'react'
import { Box, FormField, Select } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import debounce from 'lodash/debounce'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { FormInput } from '../../../components'
import {
  STREET_ADD_MAX_LEN,
  CITY_STATE_MAX_LEN,
  POSTAL_CODE_MAX_LEN
} from '../../../utils/location-validation-utils'
import { get } from '../../../utils/api-utils'

import { addressTypeObject, addressType, addressErrorsType } from './types'

const AddressInput = ({
  listOfCountries,
  address,
  setAddress,
  fieldErrors,
  setFieldErrors,
  type
}) => {
  const { t } = useTranslation(['location'])

  const [filteredCountries, setFilteredCountries] = useState(listOfCountries)

  const [suggestions, setSuggestions] = useState([])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedChange = useCallback(
    debounce((typedStreetAdd) => {
      const updatedAdd = encodeURIComponent(typedStreetAdd)
      const countryParam = address.country ? `&country=${address.country}` : ''
      const URL = `/locations/v1beta2/locations/address/suggestion?address=${updatedAdd}${countryParam}`
      get(URL, {}, oidcUser.access_token).then(
        (response) => {
          if (response.data && response.data.items) {
            const responseData = [...response.data.items]
            setSuggestions(responseData)
          }
        },
        (/* error */) => {
          setSuggestions([])
        }
      )
    }, 1000),
    [address.country]
  )

  const onSuggestionSelect = (event) => {
    const selectedSuggestion = suggestions.find(
      (s) => s.label === event.suggestion
    )

    setAddress((prevState) => ({
      ...prevState,
      ...{
        street_address: selectedSuggestion.label.split(',')[0],
        city: selectedSuggestion.city,
        state: selectedSuggestion.state,
        postal_code: selectedSuggestion.postalCode,
        country: selectedSuggestion.countryName
      }
    }))
    setSuggestions([])
  }

  return (
    <>
      <FormField
        label={t('address')}
        htmlFor="country"
        data-testid={`country-dropdown-${type}-field`}
        testId={`country-dropdown-${type}`}
        error={fieldErrors.country}
      >
        <Select
          placeholder={t('country')}
          onChange={({ option }) => {
            const updatedValue = { country: option }
            setAddress((prevState) => ({
              ...prevState,
              ...updatedValue
            }))
            if (option !== '') {
              setFieldErrors((prevState) => ({
                ...prevState,
                ...{ country: '' }
              }))
            }
            if (LDFlags['glcp-sdi-address-doctor']) {
              setSuggestions([])
            }
          }}
          value={address.country}
          options={filteredCountries}
          data-testid={`country-dropdown-${type}`}
          onSearch={(searchText) => {
            // The line below escapes regular expression special characters:
            // [ \ ^ $ . | ? * + ( )
            const escapedText = searchText.replace(
              /[-\\^$*+?.()|[\]{}]/g,
              '\\$&'
            )
            const regexp = new RegExp(escapedText, 'i')
            setFilteredCountries(listOfCountries.filter((o) => o.match(regexp)))
          }}
          onClose={() => setFilteredCountries(listOfCountries)}
        />
      </FormField>
      <FormInput
        placeholder={t('street_address')}
        inputType="text"
        value={address.street_address}
        testId={`street-address-${type}`}
        onChange={(e) => {
          const updatedValue = { street_address: e.target.value }
          setAddress((prevState) => ({
            ...prevState,
            ...updatedValue
          }))
          // on change clear any error
          setFieldErrors((prevState) => ({
            ...prevState,
            ...{ street_address: '' }
          }))
          if (
            LDFlags['glcp-sdi-address-doctor'] &&
            e.target.value?.length >= 2 // 2 length is the minimum length for address doctor to return suggestions, to avoid unnecessary calls
          ) {
            handleDebouncedChange(e.target.value)
          }
        }}
        suggestions={
          LDFlags['glcp-sdi-address-doctor']
            ? suggestions.map((s) => s.label)
            : []
        }
        onSuggestionSelect={
          LDFlags['glcp-sdi-address-doctor'] ? onSuggestionSelect : undefined
        }
        required
        error={fieldErrors.street_address}
        maxLength={STREET_ADD_MAX_LEN}
      />
      <FormInput
        inputType="text"
        placeholder={t('street_address_2')}
        value={address.street_address2}
        testId={`street-address-2-${type}`}
        onChange={(e) => {
          const updatedValue = { street_address2: e.target.value }
          setAddress((prevState) => ({
            ...prevState,
            ...updatedValue
          }))
          // on change clear any error
          setFieldErrors((prevState) => ({
            ...prevState,
            ...{ street_address2: '' }
          }))
        }}
        error={fieldErrors.street_address2}
        maxLength={STREET_ADD_MAX_LEN}
      />
      <Box width="100%" direction="row" justify="between" gap="xsmall">
        <Box width="50%">
          <FormInput
            inputType="text"
            placeholder={t('city')}
            value={address.city}
            testId={`city-${type}`}
            onChange={(e) => {
              const updatedValue = { city: e.target.value }
              setAddress((prevState) => ({
                ...prevState,
                ...updatedValue
              }))
              // on change clear any error
              setFieldErrors((prevState) => ({
                ...prevState,
                ...{ city: '' }
              }))
            }}
            required
            error={fieldErrors.city}
            maxLength={CITY_STATE_MAX_LEN}
          />
        </Box>
        <Box width="50%">
          <FormInput
            inputType="text"
            placeholder={t('state_province_region')}
            value={address.state}
            testId={`state-${type}`}
            onChange={(e) => {
              const updatedValue = { state: e.target.value }
              setAddress((prevState) => ({
                ...prevState,
                ...updatedValue
              }))
              // on change clear any error
              setFieldErrors((prevState) => ({
                ...prevState,
                ...{ state: '' }
              }))
            }}
            required
            error={fieldErrors.state}
            maxLength={CITY_STATE_MAX_LEN}
          />
        </Box>
      </Box>
      <FormInput
        inputType="text"
        placeholder={t('zip_code')}
        value={address.postal_code}
        testId={`postal-code-${type}`}
        onChange={(e) => {
          const updatedValue = { postal_code: e.target.value }
          setAddress((prevState) => ({
            ...prevState,
            ...updatedValue
          }))
          // on change clear any error
          setFieldErrors((prevState) => ({
            ...prevState,
            ...{ postal_code: '' }
          }))
        }}
        required
        error={fieldErrors.postal_code}
        maxLength={POSTAL_CODE_MAX_LEN}
      />
    </>
  )
}

AddressInput.propTypes = {
  listOfCountries: PropTypes.array.isRequired,
  setAddress: PropTypes.func.isRequired,
  address: PropTypes.shape(addressTypeObject).isRequired,
  fieldErrors: PropTypes.shape(addressErrorsType).isRequired,
  setFieldErrors: PropTypes.func.isRequired,
  type: addressType.isRequired
}

export { AddressInput }
