// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React } from 'react'
import PropTypes from 'prop-types'
import { Route, Routes, Navigate } from 'react-router-dom'

import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { ChooseAccountPage, ChooseCountryPage, SetUpAccountPage } from './index'

const PostOnboardingRouterContent = ({
  customerAccounts = [],
  custAcctError = {},
  setRefreshLoadAccount = null,
  callListAccountApi = false
}) => {
  return (
    <Routes>
      <Route
        exact
        path="/choose-account"
        element={
          <ChooseAccountPage
            customerAccounts={customerAccounts}
            customerAccountsError={custAcctError}
            setRefreshLoadAccount={setRefreshLoadAccount}
            callListAccountApi={callListAccountApi}
          />
        }
      />
      <Route
        exact
        path="/choose-country"
        element={
          <VisibilityWrapper hideFor={{ deployment: ['COP'] }}>
            <ChooseCountryPage />
          </VisibilityWrapper>
        }
      />
      <Route exact path="/set-up-account" element={<SetUpAccountPage />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

const PostOnboardingRouter = ({
  customerAccounts = [],
  custAcctError = {},
  setRefreshLoadAccount = null,
  callListAccountApi = false
}) => {
  return (
    <PostOnboardingRouterContent
      customerAccounts={customerAccounts}
      custAcctError={custAcctError}
      setRefreshLoadAccount={setRefreshLoadAccount}
      callListAccountApi={callListAccountApi}
    />
  )
}
PostOnboardingRouterContent.propTypes = {
  customerAccounts: PropTypes.array,
  custAcctError: PropTypes.object,
  setRefreshLoadAccount: PropTypes.func,
  callListAccountApi: PropTypes.bool
}

PostOnboardingRouter.propTypes = {
  customerAccounts: PropTypes.array,
  custAcctError: PropTypes.object,
  setRefreshLoadAccount: PropTypes.func,
  callListAccountApi: PropTypes.bool
}
export default PostOnboardingRouter
