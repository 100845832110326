// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import WebhooksRouter from '../webhooks/router'
import Event2Details from '../webhooks/Event2Details'

import AutomationPage from './Automations'

const AutomationsRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<AutomationPage />} />
      <Route exact path="/webhooks" element={<WebhooksRouter />} />
      <Route exact path="/webhooks/:id" element={<Event2Details />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export default AutomationsRouter
