// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Home from './pages/Home'

const WellnessDashboardRouter = () => {
  return (
    <Routes>
      {/* NOTE: Always add any other routes above this route */}
      <Route exact path="/" element={<Home />} />
    </Routes>
  )
}

export default WellnessDashboardRouter
