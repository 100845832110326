// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Typography } from '../../../components'

const DevicesSummary = ({
  numComputeDevices,
  numNetworkingDevices,
  numAccessPoints,
  numGateways,
  numSwitches,
  numStorageDevices,
  numPCEDevices,
  numSensors,
  numBridges
}) => {
  const { t } = useTranslation(['device', 'common'])
  const LDFlags = useFlags()
  const dmPCEFlag = LDFlags['glcp-dm-pce'] // PCE LD is added to check PCE workflow in StandAlone Account just for testing purpose untill all services support PCE changes
  const dmUXIFlag = LDFlags['glcp-dm-uxi']
  const dmBridgeFlag = LDFlags['glcp-5g-bridge']

  const getDeviceStatsCard = (totalStat, breakDownStat, testId) => {
    return (
      <Box direction="row" margin={{ top: 'small' }}>
        <Typography
          type="text"
          size="large"
          weight="bold"
          style={{
            whiteSpace: 'nowrap'
          }}
          testId={`${testId}-total`}
        >
          {totalStat}
        </Typography>
        <Box
          border={{ side: 'left', color: 'border-weak' }}
          alignSelf="center"
          pad={{ left: 'small' }}
          margin={{ left: 'small' }}
        >
          <Typography type="text" size="default" testId={`${testId}-breakdown`}>
            {breakDownStat}
          </Typography>
        </Box>
      </Box>
    )
  }

  const getNetworkingDevicesBreakdown = () => {
    const baseData = {
      num_access_points: numAccessPoints,
      num_gateways: numGateways,
      num_switches: numSwitches
    }

    if (dmBridgeFlag) {
      if (dmUXIFlag) {
        baseData.num_sensors = numSensors
        baseData.num_bridges = numBridges
        return t(
          'tags.manage_tags_dlg_networking_devices_breakdown_sensor_and_bridge',
          baseData
        )
      }
      baseData.num_bridges = numBridges
      return t(
        'tags.manage_tags_dlg_networking_devices_breakdown_bridge',
        baseData
      )
    }

    if (dmUXIFlag) {
      baseData.num_sensors = numSensors
      return t(
        'tags.manage_tags_dlg_networking_devices_breakdown_sensor',
        baseData
      )
    }

    return t('tags.manage_tags_dlg_networking_devices_breakdown', baseData)
  }

  return (
    <Box
      direction="column"
      pad={{ top: 'medium' }}
      data-testid="device-summary-card"
    >
      {getDeviceStatsCard(
        t('device:tags.manage_tags_dlg_compute_devices_total', {
          num_compute_devices: numComputeDevices
        }),
        t('device:tags.manage_tags_dlg_compute_devices_breakdown', {
          num_compute_servers: numComputeDevices
        }),
        'compute-devices'
      )}
      {getDeviceStatsCard(
        t('device:tags.manage_tags_dlg_networking_devices_total', {
          num_networking_devices: numNetworkingDevices
        }),
        getNetworkingDevicesBreakdown(),
        'networking-devices'
      )}
      {getDeviceStatsCard(
        t('device:tags.manage_tags_dlg_storage_devices_total', {
          num_storage_devices: numStorageDevices
        }),
        t('device:tags.manage_tags_dlg_storage_devices_breakdown', {
          num_storage_devices: numStorageDevices
        }),
        'storage-devices'
      )}
      {dmPCEFlag &&
        getDeviceStatsCard(
          t('device:tags.manage_tags_dlg_pce_devices_total', {
            num_pce_devices: numPCEDevices
          }),
          t('device:tags.manage_tags_dlg_pce_devices_breakdown', {
            num_pce_devices: numPCEDevices
          }),
          'pce-devices'
        )}
    </Box>
  )
}

DevicesSummary.propTypes = {
  numComputeDevices: PropTypes.number.isRequired,
  numNetworkingDevices: PropTypes.number.isRequired,
  numAccessPoints: PropTypes.number.isRequired,
  numGateways: PropTypes.number.isRequired,
  numSwitches: PropTypes.number.isRequired,
  numStorageDevices: PropTypes.number.isRequired,
  numPCEDevices: PropTypes.number.isRequired,
  numSensors: PropTypes.number.isRequired,
  numBridges: PropTypes.number.isRequired
}

export { DevicesSummary }
