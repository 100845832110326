// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

import { AppCatalogContextProvider } from '../../context/app-catalog-context'
import { checkGotoChooseAccount } from '../../utils/common-utils'
import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const PublishApplicationPage = lazy(() => import('./PublishApps'))
const InstalledAppsRouter = lazy(() => import('./installed-apps/router'))
const DetailsRouter = lazy(() => import('./app-details/router'))
const ServiceDetailsRouter = lazy(() =>
  import('./app-details/service-catalog/router')
)

const PublishApplicationRouterContent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route
          exact
          path="/installed-apps/:appId/:fromServiceSubs?"
          element={<InstalledAppsRouter />}
        />
        <Route
          exact
          path="/app-details/:appId/:region?"
          element={<DetailsRouter />}
        />
        <Route
          exact
          path="/service-details/:appId/:serviceId?"
          element={<ServiceDetailsRouter />}
        />
        {/* NOTE: Always add any routes above this route */}
        <Route path="/*" element={<PublishApplicationPage />} />
      </Routes>
    </Suspense>
  )
}

const PublishApplicationRouter = () => {
  return (
    <AppCatalogContextProvider>
      <PublishApplicationRouterContent />
    </AppCatalogContextProvider>
  )
}

export default PublishApplicationRouter
