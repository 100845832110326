// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Layer, Box, FormField, CheckBox } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import { Typography, Button } from '../../../components'
import { patch, getErrorMessage } from '../../../utils/api-utils'

const PreferencesModal = ({
  onSetOpen,
  initialSettings = {},
  setPreferenceError,
  setInitialSettings = () => {},
  navigate
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const [checked, setChecked] = useState(
    initialSettings?.optInMail ? initialSettings?.optInMail : false
  )

  const saveSettings = () => {
    setInitialSettings({})
    const requestBody = [
      {
        op: 'replace',
        path: '/settings/optInMail',
        value: checked
      }
    ]
    patch(
      '/notifications-svc/ui/v1alpha1/settings',
      requestBody,
      oidcUser.access_token
    ).then(
      () => {},
      (error) => {
        setPreferenceError(getErrorMessage(error, t))
      }
    )
  }

  return (
    <>
      <Layer
        position="center"
        onClickOutside={() => {
          onSetOpen(false)
          setInitialSettings({})
          navigate('/notifications')
        }}
        onEsc={() => {
          onSetOpen(false)
          setInitialSettings({})
          navigate('/notifications')
        }}
        data-testid="user-preferences-layer"
      >
        <Box pad="medium" gap="small">
          <Box gap="xsmall">
            <Typography type="heading" level="2" margin={{ bottom: 'small' }}>
              {t('notification.preference_label')}
            </Typography>
            <Typography type="text" size="xsmall">
              {t('notification.preference_description')}
            </Typography>
            <Typography type="text" size="xsmall">
              {t('notification.preference_description_second')}
            </Typography>
          </Box>
          <Box>
            <FormField
              name="emailPreference"
              data-testid="banner-notification-checkbox-formfield"
            >
              <CheckBox
                pad="none"
                data-testid="test-banner-checkbox"
                name="emailPreference"
                checked={checked}
                label={t('notification.email_setting_label')}
                onChange={(event) => {
                  setChecked(event.target.checked)
                }}
              />
            </FormField>
          </Box>
          <Box direction="row" justify="end" gap="medium">
            <Button
              default
              label={t('cancel')}
              onClick={() => {
                onSetOpen(false)
                setInitialSettings({})
                navigate('/notifications')
              }}
              testId="cancel-preferences-invite-btn"
            />
            <Button
              primary
              type="submit"
              label={t('save')}
              onClick={() => {
                saveSettings()
                onSetOpen(false)
                navigate('/notifications')
              }}
              testId="save-preferences-btn"
            />
          </Box>
        </Box>
      </Layer>
    </>
  )
}
export { PreferencesModal }

PreferencesModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  setPreferenceError: PropTypes.func.isRequired,
  initialSettings: PropTypes.object,
  setInitialSettings: PropTypes.func,
  navigate: PropTypes.object.isRequired
}
