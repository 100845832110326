// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { FormField, Select, Box, TextArea, TextInput } from 'grommet'

const RoleDynamicFormField = ({ field, value, onChange }) => {
  const {
    type,
    name,
    label,
    required,
    options,
    multiple,
    labelHelper,
    placeholder = '',
    width = 'medium',
    labelKey = 'name',
    valueKey = 'code',
    emptySearchMessage,
    searchPlaceholder
  } = field

  const handleChange = (e) => {
    onChange({ name, value: e.target.value })
  }

  switch (type) {
    case 'select':
      return (
        <Box width={width}>
          <FormField
            name={name}
            label={label}
            required={required}
            data-testid={`dynamic-form_${name}`}
          >
            <Select
              name={name}
              value={value}
              options={options}
              multiple={multiple}
              labelKey={labelKey}
              onChange={handleChange}
              placeholder={placeholder}
              searchPlaceholder={searchPlaceholder}
              emptySearchMessage={emptySearchMessage}
              valueKey={{ key: valueKey, reduce: true }}
            />
          </FormField>
        </Box>
      )

    case 'textarea':
      return (
        <Box width={width}>
          <FormField
            name={name}
            label={label}
            help={labelHelper}
            data-testid={`dynamic-form_${name}`}
          >
            <TextArea
              value={value}
              name={name}
              placeholder={placeholder}
              data-testid={`dynamic-form_${name}_textarea`}
              // height="medium"
              onChange={handleChange}
            />
          </FormField>
        </Box>
      )

    default:
      return (
        <Box width={width}>
          <FormField
            name={name}
            label={label}
            required
            data-testid={`dynamic-form_${name}`}
          >
            <TextInput
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={handleChange}
              data-testid={`dynamic-form_${name}_text`}
            />
          </FormField>
        </Box>
      )
  }
}

RoleDynamicFormField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
}

export default RoleDynamicFormField
