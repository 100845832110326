// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Anchor,
  Button,
  Loader,
  Notification,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import Markdown from '../../../../commoncomponents/Markdown'
import { get, getErrorMessage } from '../../../../utils/api-utils'

const CheckPrerequisiteModal = ({
  onValidateSuccess,
  onValidateFail,
  workspaceName,
  setNotificationInfo
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const navigate = useNavigate()
  const ulStyle = { listStyleType: 'disc' }
  const { oidcUser } = useReactOidc()

  const [loading, setLoading] = useState(true)
  const [deviceMsg, setDeviceMsg] = useState(false)
  const [deviceSubMsg, setDeviceSubMsg] = useState(false)
  const [serviceSubMsg, setServiceSubMsg] = useState(false)
  const [serviceInstancesMsg, setServiceInstancesMsg] = useState(false)

  const warning =
    deviceMsg || deviceSubMsg || serviceSubMsg || serviceInstancesMsg
  const checkFailed = !loading && warning
  const checkOk = !loading && !warning

  const getContent = () => {
    return (
      <>
        {loading && (
          <Box
            width="medium"
            direction="column"
            justify="center"
            align="center"
            margin={{ top: 'medium' }}
          >
            <Box direction="row" gap="small">
              <Loader size="18px" testId="prereq-loader" />
              <Typography type="text" size="medium">
                {t('iam:delete_workspace.prereq_load')}
              </Typography>
            </Box>
          </Box>
        )}
        {checkFailed && (
          <Box gap="small" margin={{ top: 'medium' }}>
            <Notification
              type="inline"
              text={t('common:cannot_perform_action')}
              status="critical"
              testId="delete-wksp-warning"
            />
            <Markdown>
              {t('iam:delete_workspace.prereq_desc', {
                workspaceName,
                workspace: t('common:business_object.wkspc')
              })}
            </Markdown>
            <ul style={{ marginTop: '-10px' }}>
              {deviceMsg && (
                <li>
                  {t('iam:delete_workspace.prereq_device_desc', {
                    devices: t('common:business_object.device_plural'),
                    workspace: t('common:business_object.wkspc')
                  })}
                  <ul>
                    <li style={ulStyle}>
                      <Trans
                        i18nKey="iam:delete_workspace.prereq_device_link"
                        t={t}
                        values={{
                          services: t('common:business_object.service_plural'),
                          workspace: t('common:business_object.wkspc')
                        }}
                      >
                        <Anchor
                          label={t('common:business_object.device_plural')}
                          href=""
                          onClick={(event) => {
                            event.preventDefault()
                            navigate('/devices/inventory-list')
                          }}
                          testId="devices-anchor"
                        />
                      </Trans>
                    </li>
                    <li style={ulStyle}>
                      {t('iam:delete_workspace.prereq_device_help', {
                        devices: t('common:business_object.device_plural')
                      })}
                    </li>
                  </ul>
                </li>
              )}
              {deviceSubMsg && (
                <li>
                  <Trans
                    i18nKey="iam:delete_workspace.prereq_device_sub_link"
                    t={t}
                  >
                    <Anchor
                      label={t(
                        'common:business_object.device_subscription_plural'
                      )}
                      href=""
                      onClick={(event) => {
                        event.preventDefault()
                        navigate('/devices/subscriptions-list')
                      }}
                      testId="devices-sub-anchor"
                    />
                  </Trans>
                </li>
              )}
              {serviceSubMsg && (
                <li>
                  <Trans
                    i18nKey="iam:delete_workspace.prereq_service_sub_link"
                    t={t}
                  >
                    <Anchor
                      label={t(
                        'common:business_object.service_subscription_plural'
                      )}
                      href=""
                      onClick={(event) => {
                        event.preventDefault()
                        navigate('/services/service-subscriptions')
                      }}
                      testId="service-sub-anchor"
                    />
                  </Trans>
                </li>
              )}
              {serviceInstancesMsg && (
                <li>
                  <Trans
                    i18nKey="iam:delete_workspace.prereq_service_instances_link"
                    t={t}
                    values={{
                      workspace: t('common:business_object.wkspc')
                    }}
                  >
                    <Anchor
                      label={t(
                        'common:business_object.service_instance_plural'
                      )}
                      href=""
                      onClick={(event) => {
                        event.preventDefault()
                        navigate('/services/my-services')
                      }}
                      testId="service-instances-anchor"
                    />
                  </Trans>
                </li>
              )}
            </ul>
          </Box>
        )}
      </>
    )
  }

  useEffect(() => {
    let isCurrent = true
    setLoading(true)
    Promise.all([
      get('/ui-doorway/ui/v1/devices', {}, oidcUser.access_token),
      get(
        '/ui-doorway/ui/v1/license/stats?product_type=DEVICE',
        {},
        oidcUser.access_token
      ),
      get(
        '/ui-doorway/ui/v1/license/stats?product_type=SERVICE&product_type=ONPREM_SERVICE',
        {},
        oidcUser.access_token
      ),
      get(
        '/ui-doorway/ui/v1/applications/provisions?provision_status=PROVISIONED',
        {},
        oidcUser.access_token
      )
    ])
      .then(([deviceRes, deviceSubsRes, serviceSubsRes, servicesRes]) => {
        if (!isCurrent) return
        if (deviceRes) {
          setDeviceMsg(deviceRes?.data?.pagination?.total_count > 0)
        }
        if (deviceSubsRes) {
          setDeviceSubMsg(deviceSubsRes?.data?.total_subscriptions > 0)
        }
        if (serviceSubsRes) {
          setServiceSubMsg(serviceSubsRes?.data?.total_subscriptions > 0)
        }
        if (servicesRes) {
          setServiceInstancesMsg(servicesRes?.data?.provisions?.length > 0)
        }
        setLoading(false)
      })
      .catch((error) => {
        if (!isCurrent) return
        setLoading(false)
        onValidateFail()
        setNotificationInfo(getErrorMessage(error, t), 'error')
      })
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (checkOk) {
      onValidateSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkOk])

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2">
              {t('iam:delete_workspace.title', {
                workspace: t('common:business_object.wkspc')
              })}
            </Typography>
          }
        />
      }
      content={getContent()}
      footer={
        <ModalFooter
          right={
            <Button
              primary
              width="small"
              label={t('common:close')}
              onClick={onValidateFail}
              testId="delete-wksp-close-btn"
            />
          }
        />
      }
      onClose={onValidateFail}
      onClickOutside={onValidateFail}
      testId="check-prerequisite-modal"
    />
  )
}

CheckPrerequisiteModal.propTypes = {
  /**
   * callback if prerequisite check is ok
   */
  onValidateSuccess: PropTypes.func.isRequired,

  /**
   * callback if prerequisite check fails
   */
  onValidateFail: PropTypes.func.isRequired,
  /**
   * Workspace name to display in warning
   */
  workspaceName: PropTypes.string,
  /**
   * Display notification
   */
  setNotificationInfo: PropTypes.func
}

CheckPrerequisiteModal.defaultProps = {
  workspaceName: '',
  setNotificationInfo: () => {}
}

export default CheckPrerequisiteModal
