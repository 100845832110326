// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Group, Trash } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import {
  Button,
  DataTable,
  NoDataInfo,
  Typography
} from '../../../../components'
import { get, getErrorMessage } from '../../../../utils/api-utils'
import AssignGroupsToUserModal from '../AssignGroupsToUserModal'
import { useVisibilityContext } from '../../../../context/visibility-context'
import useOrganizationAssociation from '../useOrganizationAssociation'

const AddToGroup = ({
  selectedGroups,
  setSelectedGroups,
  setNotificationInfo = () => {}
}) => {
  const { t } = useTranslation(['iam'])
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const v2GroupReadPerm = v2RbacPolicies?.includes('identity.user-groups.read')
  const rootWkspReadPerm = v2RbacRootWkspcPolicies?.includes(
    'identity.user-groups.read'
  )
  const { isAssociated } = useOrganizationAssociation(setNotificationInfo)
  const [showAssignGroupsToUserModal, setShowAssignGroupsToUserModal] =
    useState(false)

  const columns = [
    {
      property: 'displayName',
      header: t('iam:igc_users.add_user_add_to_group_name_column'),
      primary: true
    },
    {
      property: '',
      header: '',
      align: 'end',
      render: (datum) => {
        return (
          <Button
            icon={<Trash />}
            onClick={() => {
              const filteredGroups = selectedGroups?.filter(
                (group) => group?.displayName !== datum?.displayName
              )
              setSelectedGroups(filteredGroups)
            }}
            testId="add-user-remove-group-btn"
          />
        )
      }
    }
  ]

  const onSubmit = (groupData) => {
    setSelectedGroups(groupData)
    setShowAssignGroupsToUserModal(false)
  }

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true)
      await get('/identity/v2alpha1/scim/v2/Groups', {}).then(
        (response) => {
          if (response?.data) {
            setGroups(response?.data?.Resources)
          }
          setLoading(false)
        },
        (error) => {
          setNotificationInfo(getErrorMessage(error, t), 'error')
          setLoading(false)
        }
      )
    }
    if (
      (!isAssociated && v2GroupReadPerm) ||
      (isAssociated && rootWkspReadPerm)
    ) {
      fetchGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  return (
    <Box direction="column">
      <Typography type="heading" level="2" testId="add-to-group-title">
        {t('iam:igc_users.add_user_assign_to_group_title')}
      </Typography>

      {!loading && (
        <Typography type="text" testId="add-to-group-subtitle">
          {groups?.length > 0
            ? t('iam:igc_users.add_user_assign_to_group_groups')
            : t('iam:igc_users.add_user_assign_to_group_no_groups')}
        </Typography>
      )}
      {groups?.length > 0 || loading ? (
        <Box margin={{ top: 'medium', bottom: 'medium' }}>
          {(selectedGroups?.length > 0 || loading) && (
            <DataTable
              grid={{
                columns,
                data: selectedGroups
              }}
              loading={loading}
              testId="add-user-group-data-table"
            />
          )}
          {!loading && (
            <Box justify="end" align="end" margin={{ top: 'small' }}>
              <Button
                secondary
                label={t('iam:igc_users.add_user_assign_to_group_btn')}
                onClick={() => {
                  setShowAssignGroupsToUserModal(true)
                }}
                testId="add-user-add-group-btn"
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box margin={{ top: 'medium', bottom: 'medium' }}>
          <NoDataInfo
            title={t(
              'iam:igc_users.add_user_assign_to_group_no_groups_icon_title'
            )}
            gap="small"
            icon={<Group size="xlarge" />}
            testId="add-user-empty-group"
          />
        </Box>
      )}
      {showAssignGroupsToUserModal && (
        <AssignGroupsToUserModal
          onSetOpen={setShowAssignGroupsToUserModal}
          onSubmit={onSubmit}
          setNotificationInfo={setNotificationInfo}
          preselectedValues={selectedGroups}
        />
      )}
    </Box>
  )
}

AddToGroup.propTypes = {
  /**
   * Groups selected to be assigned to user upon adding user
   * Needed to display selected groups in data table
   */
  selectedGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,

  /**
   * Set selected groups to be assigned to user upon adding user
   * Needed to select groups to assign
   */
  setSelectedGroups: PropTypes.func.isRequired,

  /**
   * Set notification info
   */
  setNotificationInfo: PropTypes.func
}

export default AddToGroup
