// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { FormDown } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import VisibilityWrapper from '../visibility-wrapper/VisibilityWrapper'
import { ActionButton } from '../../components'
import { isCoP, isGLOP } from '../../utils/feature-flag-utils'

const SubscriptionDetailsActionButton = ({
  onPremWorkSpaceData,
  setManageTagsModal,
  setOnPremWorkSpaceModal,
  manageRateButton,
  setRemoveSubscriptionModal
}) => {
  const { t } = useTranslation(['device', 'manage', 'common'])
  const LDFlags = useFlags()
  const glopDevicesPreOnBoardingFlag = LDFlags['glcp-dscc-dm-sm']
  return (
    <VisibilityWrapper
      hideFor={{
        deployment: ['GLOP']
      }}
      rbac={{
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.edit'
      }}
    >
      <ActionButton
        label={t('common:actions')}
        icon={<FormDown />}
        showOneActionAsDropDown
        actions={[
          {
            label: t('manage_tags'),
            onClick: () => {
              setManageTagsModal(true)
            },
            hidden: isGLOP(),
            testId: 'manage-tags-btn'
          },
          {
            label: t('manage:onprem_workspace.assign_to_onprem'),
            onClick: () => {
              setOnPremWorkSpaceModal(true)
            },
            hidden:
              isCoP() ||
              isGLOP() ||
              !glopDevicesPreOnBoardingFlag ||
              !onPremWorkSpaceData,
            testId: 'assign-to-onprem-workspace-btn'
          },
          {
            label: t('remove_subscription'),
            onClick: () => {
              setRemoveSubscriptionModal(true)
            },
            visibility: {
              hideFor: {
                deployment: ['COP', 'GLOP'],
                feature: 'glcp-enable-unclaim-and-transfer-subscriptions'
              }
            },
            testId: 'remove-subscriptions-btn'
          },
          ...manageRateButton
        ]}
        testId="bulk-actions"
        dropAlign={{
          right: 'right',
          top: 'bottom'
        }}
        reverse
      />
    </VisibilityWrapper>
  )
}

SubscriptionDetailsActionButton.propTypes = {
  onPremWorkSpaceData: PropTypes.array.isRequired,
  manageRateButton: PropTypes.array.isRequired,
  setManageTagsModal: PropTypes.func.isRequired,
  setOnPremWorkSpaceModal: PropTypes.func.isRequired,
  setRemoveSubscriptionModal: PropTypes.func.isRequired
}

export { SubscriptionDetailsActionButton }
