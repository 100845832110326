// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { ShareRounded } from 'grommet-icons'

const RecommendedCardData = () => {
  // need to toggle the content
  // use changing content instead of hideFor: {feautre: ldflag}
  // TODO: once sbuscribe tile is removed in Aquila, use hideFor: {feautre: ldflag}
  const isDev = process.env.NODE_ENV === 'development'
  return [
    // TODO: once whats-new card goes in to pavo/aquila, release notes card should be removed
    {
      id: 1,
      title: 'release_notes.title',
      description: 'release_notes.description',
      background: 'background-back',
      elevation: 'none',
      actionBtnLbl: 'release_notes.btn_lbl',
      actionBtnIcon: <ShareRounded />,
      testId: 'release-notes-card',
      hrefTo: 'https://www.hpe.com/info/hpe-greenlake-platform-release-notes',
      routeTo: '',
      target: '_blank',
      visible: true,
      hideFor: { feature: 'glcp-release-notes-card', deployment: ['COP'] },
      native: false,
      slug: 'GLCP_RELEASE_NOTES'
    },
    {
      id: 2,
      title: 'hpe_greenlake_developer_portal.title',
      description: 'hpe_greenlake_developer_portal.new_description',
      background: 'background-back',
      elevation: 'none',
      actionBtnIcon: <ShareRounded />,
      testId: 'developer-portal-card',
      hrefTo: 'https://developer.greenlake.hpe.com/idp-login',
      visible: true,
      hideFor: {
        feature: 'glcp-service-centric-experience-phase-1',
        deployment: ['COP']
      },
      native: false,
      slug: 'GLC_DEV_PORTAL'
    },
    {
      id: 3,
      title: 'whats_new.card_tile',
      description: 'whats_new.card_description',
      background: 'background-back',
      elevation: 'none',
      actionBtnLbl: 'whats_new.card_action_label',
      testId: 'whats-new-card',
      hrefTo: '',
      routeTo: '/whats-new',
      target: '',
      visible: true,
      hideFor: { feature: 'glcp-whats-new', deployment: ['COP'] },
      native: false,
      slug: 'GLCP_WHATS_NEW'
    },
    {
      id: 4,
      title: 'Feature Flags',
      description: 'Check the details of Feature Flags',
      testId: 'Feature-flags-card',
      hrefTo: '',
      routeTo: '/ldflags',
      background: 'background-back',
      elevation: 'none',
      actionBtnLbl: 'Launch',
      target: '',
      visible: true,
      hideFor: { feature: 'glcp-ld-feature-flags-view' },
      hidden: isDev,
      native: false,
      slug: 'GLCP_Feature_Flags'
    }
  ]
}

export { RecommendedCardData }
