// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { Anchor, Box, PageHeader } from 'grommet'
import { LinkNext, Previous } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Layout } from '../../../commoncomponents/layout/Layout'
import { Typography, Card, Button } from '../../../components'

import WidgetSection from './widgets/widgetSection'
import { AssetsMapView } from './map-view/AssetsMapView'

const AssetManagementDashboardContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'manage'])
  const handleBackBtnClick = () => {
    navigate('/manage-account')
  }

  const mapViewcontent = () => (
    <Box padding>
      <Box width="100%" justify="between" direction="row">
        <Typography type="heading" size="small" testId="map-view-widget-title">
          {t('manage:asset_management.assets_map.assets_by_location')}
        </Typography>

        <Button
          a11yTitle={t(`common:next`)}
          icon={<LinkNext color="brand" />}
          onClick={() => navigate('/manage-account/asset-management/assets')}
          size="medium"
          plain
          testId="map-view-widget-title-btn"
        />
      </Box>
      <Box
        margin={{ top: 'small' }}
        pad={{ vertical: 'xsmall' }}
        height="auto"
        width="1392px"
      >
        <AssetsMapView />
      </Box>
    </Box>
  )

  return (
    <Box
      data-testid="asset-management-page"
      align="center"
      pad={{ bottom: 'small' }}
    >
      <Box margin={{ horizontal: 'xlarge' }}>
        <PageHeader
          title={t('manage:asset_management.asset_management_title')}
          parent={
            <Anchor
              label={t('dashboard.quick_links_card.manage_workspace')}
              icon={<Previous />}
              onClick={handleBackBtnClick}
              data-testid="go-to-manage-account-btn"
            />
          }
          data-testid="asset-management-dashboard-header"
        />
        <Card
          direction="row"
          align="start"
          cardWidth="auto"
          margin={{ top: 'xsmall', bottom: 'medium' }}
          CustomContent={mapViewcontent()}
          testId="map-view-widget"
        />

        <Box margin={{ vertical: 'small' }}>
          <WidgetSection />
        </Box>
      </Box>
    </Box>
  )
}

const AssetManagementDashboard = () => (
  <Layout>
    <AssetManagementDashboardContent />
  </Layout>
)

export default AssetManagementDashboard
