// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text, CheckBox, Menu } from 'grommet'
import {
  CaretDownFill,
  Checkmark,
  CircleInformation,
  Next,
  Previous
} from 'grommet-icons'
import { isEmpty } from 'lodash'
import PropTypes, { string } from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Loader,
  NoDataInfo,
  Notification,
  Typography
} from '../../../../components'
import { isGLOP } from '../../../../utils/feature-flag-utils'
import { FilterOptions } from '../../../../commoncomponents/notifications-dashboard/FilterOptionsAlertsTasks'
import { getFilterAttributes } from '../../../../commoncomponents/notifications-dashboard/utils'
import { readStatus } from '../../../../utils/notification-logs-utils'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import useNotificationsReducer from '../../components/NotificationsReducer'
import {
  fetchAccounts,
  fetchCategories,
  fetchAlertCount,
  fetchAlerts,
  fetchAdvanceFilteredAlertsTasks
} from '../ApiLayer/APiLayerAlertsTasks'
import {
  fetchFilteredNotifications_advanceFilter,
  fetchNotifications,
  fetchNotificationCount,
  fetchCategories as fetchNotificationCategories
} from '../ApiLayer/ApiLayerAnnouncements'
import { bulkUpdateReadStatus } from '../utils'

import { ListNotifications } from './ListsNotifications'

const NotificationsLogsPage = ({
  setErrorMessage,
  type,
  setCount,
  showNotificationComponents,
  setNotificationsExists,
  setIsLoader
}) => {
  const { t } = useTranslation(['manage', 'common'])
  const { stateV, dispatchNotifications } = useNotificationsReducer()
  const itemsPerPage = 10
  const { oidcUser } = useReactOidc()

  const areFiltersApplied = (forItemCount = false) => {
    return (
      !isEmpty(stateV.filterValues) ||
      stateV.searchConfirm.trim().length > 0 ||
      stateV.read === true ||
      stateV.selectedType.length === 1 ||
      (!forItemCount ? stateV.sortDirection === 'asc' : false)
    )
    // add check here to differenitate the filters applied
  }

  useEffect(() => {
    bulkUpdateReadStatus({
      stateV,
      dispatchNotifications,
      oidcUser,
      setErrorMessage,
      t,
      type
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateV.confirmAction])

  const fetchCategoriesCallbacked = useCallback(() => {
    fetchCategories(oidcUser, dispatchNotifications)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser])

  const fetchNotificationCategoriesCallbacked = useCallback(() => {
    fetchNotificationCategories(oidcUser, dispatchNotifications)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser])

  const fetchAccountsCallbacked = useCallback(() => {
    fetchAccounts(oidcUser, dispatchNotifications)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser])

  useEffect(() => {
    if (!isGLOP()) {
      if (type === 'ALERTSTASKS') {
        fetchCategoriesCallbacked(oidcUser, dispatchNotifications)
        fetchAccountsCallbacked(oidcUser, dispatchNotifications)
        fetchAlertCount(oidcUser, setCount, stateV, dispatchNotifications)
      } else {
        fetchNotificationCategoriesCallbacked(oidcUser, dispatchNotifications)
        fetchNotificationCount(
          oidcUser,
          setCount,
          stateV,
          dispatchNotifications
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser])

  // triggerNotificationRequest is for patch operation ...
  const decide_to_call = (nextPageURI, currentPage) => {
    if (type === 'ALERTSTASKS') {
      fetchAlerts({
        nextPageURI,
        currentPage,
        stateV,
        dispatchNotifications,
        itemsPerPage,
        oidcUser,
        setErrorMessage,
        setNotificationsExists,
        setIsLoader
      })
    } else {
      fetchNotifications({
        nextPageURI,
        currentPage,
        stateV,
        dispatchNotifications,
        itemsPerPage,
        oidcUser,
        setErrorMessage,
        t,
        setNotificationsExists,
        setIsLoader
      })
    }
  }
  useEffect(() => {
    if (areFiltersApplied()) {
      if (type === 'ALERTSTASKS') {
        fetchAdvanceFilteredAlertsTasks({
          nextPageURI: '',
          currentPage: 1,
          stateV,
          dispatchNotifications,
          itemsPerPage,
          oidcUser,
          setErrorMessage,
          t
        })
      } else {
        fetchFilteredNotifications_advanceFilter({
          nextPageURI: '',
          currentPage: 1,
          stateV,
          dispatchNotifications,
          itemsPerPage,
          oidcUser,
          setErrorMessage,
          t
        })
      }
    } else {
      // resetting all the stored values to default
      dispatchNotifications({
        type: 'HIDE_NEXT',
        hideNext: true
      })
      decide_to_call('', 1) // this will be called when no filters are applied and no next
    }
    dispatchNotifications({
      type: 'PAGE',
      page: 1
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateV.filterValues,
    stateV.read,
    stateV.searchConfirm,
    stateV.triggerNotificationRequest,
    stateV.sortDirection
  ])

  const handleClickNext = () => {
    dispatchNotifications({
      type: 'PAGE',
      page: stateV.page + 1
    })
    if (areFiltersApplied()) {
      if (type === 'ALERTSTASKS') {
        fetchAdvanceFilteredAlertsTasks({
          nextPageURI: stateV.nextURI,
          currentPage: stateV.page,
          stateV,
          dispatchNotifications,
          itemsPerPage,
          oidcUser,
          setErrorMessage,
          t
        })
      } else {
        fetchFilteredNotifications_advanceFilter({
          nextPageURI: stateV.nextURI,
          currentPage: stateV.page,
          stateV,
          dispatchNotifications,
          itemsPerPage,
          oidcUser,
          setErrorMessage,
          t
        })
      }
    } else {
      decide_to_call(stateV.nextURI, stateV.page)
    }
  }
  const handleClickPrev = () => {
    dispatchNotifications({
      type: 'HIDE_NEXT',
      hideNext: false
    })
    const current_count = stateV.pageData.length
    const startIndex = (stateV.page - 1) * itemsPerPage - itemsPerPage
    const endIndex = (stateV.page - 1) * itemsPerPage
    dispatchNotifications({
      type: 'PAGE_DATA',
      pageData: stateV.allData.slice(startIndex, endIndex)
    })
    const removeNextData = stateV.allData.slice(
      0,
      stateV.allData.length - current_count
    )

    if (stateV.page !== 1) {
      dispatchNotifications({
        type: 'NEXT_URI',
        nextURI: stateV.maintainNextURI[stateV.page - 2]
      })

      dispatchNotifications({
        type: 'PAGE',
        page: stateV.page - 1
      })
      const removeLastUri = stateV.maintainNextURI.slice(
        0,
        stateV.maintainNextURI.length - 1
      )
      dispatchNotifications({
        type: 'MAINTAIN_NEXT_URI',
        maintainNextURI: removeLastUri
      })
    }
    if (stateV.page - 1 === 1) {
      dispatchNotifications({
        type: 'NEXT_URI',
        nextURI: stateV.maintainNextURI[0]
      })
    }

    dispatchNotifications({
      type: 'ALL_DATA',
      allData: removeNextData
    })
  }

  useEffect(() => {
    if (!isEmpty(stateV.selectedReadStatus)) {
      if (!isEmpty(stateV.select) && !isEmpty(stateV.checked)) {
        dispatchNotifications({
          type: 'OPEN_CONFIRMATION',
          openConfirmation: true
        })
      } else {
        dispatchNotifications({
          type: 'SHOW_WARNING_BANNER',
          showWarningBanner: true
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateV.selectedReadStatus])

  useEffect(() => {
    if (!isEmpty(stateV.select) && !isEmpty(stateV.checked)) {
      dispatchNotifications({
        type: 'SHOW_WARNING_BANNER',
        showWarningBanner: false
      })
      if (stateV.showWarningBanner) {
        dispatchNotifications({
          type: 'SELECTED_READ_STATUS',
          selectedReadStatus: ''
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateV.select, stateV.checked])

  const onCheckAll = (event) => {
    if (event.target.checked) {
      const ids = stateV.pageData.map(({ id }) => id)
      dispatchNotifications({
        type: 'CHECKED',
        checked: ids
      })
      dispatchNotifications({
        type: 'SELECT',
        select: ids
      })
    } else {
      dispatchNotifications({
        type: 'CHECKED',
        checked: []
      })
      dispatchNotifications({
        type: 'SELECTED_CHECKBOX_STATUS',
        selectedCheckBoxStatus: ''
      })

      dispatchNotifications({
        type: 'DISABLED_ACTION',
        disabledAction: []
      })
      dispatchNotifications({
        type: 'SELECT',
        select: []
      })
    }
  }

  const onCheckAllNotification = (selectedAll) => {
    if (selectedAll) {
      const ids = stateV.pageData.map(({ id }) => id)
      dispatchNotifications({
        type: 'CHECKED',
        checked: ids
      })
      dispatchNotifications({
        type: 'SELECT',
        select: ids
      })
    } else {
      dispatchNotifications({
        type: 'CHECKED',
        checked: []
      })
      dispatchNotifications({
        type: 'SELECT',
        select: []
      })
    }
  }

  const onCheckReadNotification = (selectedAll) => {
    if (selectedAll) {
      const ids = []
      stateV.pageData?.forEach((notification) => {
        if (notification?.isRead) {
          ids.push(notification?.id)
        }
        dispatchNotifications({
          type: 'CHECKED',
          checked: ids
        })
        dispatchNotifications({
          type: 'SELECT',
          select: ids
        })
      })
    } else {
      dispatchNotifications({
        type: 'CHECKED',
        checked: []
      })
      dispatchNotifications({
        type: 'SELECT',
        select: []
      })
    }
  }

  const onCheckUnReadNotification = (selectedAll) => {
    if (selectedAll) {
      const ids = []
      stateV.pageData?.forEach((notification) => {
        if (!notification?.isRead) {
          ids.push(notification?.id)
        }
        dispatchNotifications({
          type: 'CHECKED',
          checked: ids
        })
        dispatchNotifications({
          type: 'SELECT',
          select: ids
        })
      })
    } else {
      dispatchNotifications({
        type: 'CHECKED',
        checked: []
      })
      dispatchNotifications({
        type: 'SELECT',
        select: []
      })
    }
  }

  useEffect(() => {
    dispatchNotifications({
      type: 'CONFIRM_ACTION',
      confirmAction: false
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateV.selectedCheckBoxStatus])

  const getPaginationInfo = (currentPage) => {
    let count = stateV.countInfo.totalCount
    if (areFiltersApplied(true)) {
      count = stateV.countInfo.totalHits
    }
    if (!count) {
      return ''
    }
    const start = (currentPage - 1) * 10 + 1
    const end = Math.min(start + 9, count)
    return t('notification.alerts_tasks.pagination_info', {
      start,
      end,
      total: count
    })
  }

  return (
    <>
      {stateV.isLoading ? (
        <Box
          direction="row"
          align="center"
          width={{ max: '100%', min: '100%' }}
          justify="center"
        >
          <Loader testId="devices-loader" />
        </Box>
      ) : (
        <>
          {showNotificationComponents ? (
            <Box margin={{ right: 'medium' }}>
              <FilterOptions
                stateV={stateV}
                dispatchNotifications={dispatchNotifications}
                filterAttributes={getFilterAttributes(
                  t,
                  stateV,
                  dispatchNotifications,
                  type
                )}
                type={type}
                areFiltersApplied={areFiltersApplied}
              />
              {stateV.notificationEmpty ? (
                <Box
                  pad={{ vertical: 'xlarge' }}
                  width={{ max: '100%', min: '100%' }}
                >
                  <NoDataInfo
                    icon={<CircleInformation size="large" color="text-weak" />}
                    subtitle={t('notification.alerts_tasks.no_result_info')}
                    secondarySubtitle={t(
                      'notification.alerts_tasks.no_result_help'
                    )}
                    testId="no-available-alerts-or-announcements"
                  />
                </Box>
              ) : (
                <Box pad={{ top: 'medium' }} flex={false}>
                  <Box
                    direction="row"
                    justify="between"
                    pad={{ bottom: 'small' }}
                    flex={false}
                  >
                    <Box direction="row" flex={false}>
                      <CheckBox
                        checked={
                          stateV.checked.length === stateV.pageData?.length &&
                          stateV.pageData.length !== 0
                        }
                        indeterminate={
                          stateV.checked.length > 0 &&
                          stateV.checked.length < stateV.pageData?.length
                        }
                        onChange={onCheckAll}
                        data-testid="checkbox-select-all"
                      />
                      <Menu
                        data-testid="bulk-menu"
                        dropProps={{
                          a11yTitle: 'Select Menu',
                          align: { top: 'bottom', left: 'left' }
                        }}
                        onChange={(value) => {
                          dispatchNotifications({
                            type: 'SELECTED_CHECKBOX_STATUS',
                            selectedCheckBoxStatus: value
                          })
                        }}
                        items={[
                          {
                            label: t('notification.all'),
                            icon:
                              stateV.selectedCheckBoxStatus ===
                              readStatus.all ? (
                                <Checkmark size="small" />
                              ) : (
                                <></>
                              ),
                            reverse: true,
                            onClick: () => {
                              dispatchNotifications({
                                type: 'DISABLED_ACTION',
                                disabledAction: []
                              })
                              if (
                                stateV.selectedCheckBoxStatus === readStatus.all
                              ) {
                                dispatchNotifications({
                                  type: 'SELECTED_CHECKBOX_STATUS',
                                  selectedCheckBoxStatus: ''
                                })
                                onCheckAllNotification(false)
                              } else {
                                dispatchNotifications({
                                  type: 'SELECTED_CHECKBOX_STATUS',
                                  selectedCheckBoxStatus: readStatus.all
                                })
                                onCheckAllNotification(true)
                              }
                            }
                          },
                          {
                            label: t('notification.read'),
                            icon:
                              stateV.selectedCheckBoxStatus ===
                              readStatus.read ? (
                                <Checkmark size="small" />
                              ) : (
                                <></>
                              ),
                            reverse: true,
                            onClick: () => {
                              if (
                                stateV.selectedCheckBoxStatus ===
                                readStatus.read
                              ) {
                                dispatchNotifications({
                                  type: 'SELECTED_CHECKBOX_STATUS',
                                  selectedCheckBoxStatus: ''
                                })
                                dispatchNotifications({
                                  type: 'DISABLED_ACTION',
                                  disabledAction: []
                                })
                                onCheckReadNotification(false)
                              } else {
                                dispatchNotifications({
                                  type: 'DISABLED_ACTION',
                                  disabledAction: [readStatus.mark_read]
                                })
                                dispatchNotifications({
                                  type: 'SELECTED_CHECKBOX_STATUS',
                                  selectedCheckBoxStatus: readStatus.read
                                })

                                onCheckReadNotification(true)
                              }
                            }
                          },
                          {
                            label: t('notification.unread'),
                            icon:
                              stateV.selectedCheckBoxStatus ===
                              readStatus.unread ? (
                                <Checkmark size="small" />
                              ) : (
                                <></>
                              ),
                            reverse: true,
                            onClick: () => {
                              if (
                                stateV.selectedCheckBoxStatus ===
                                readStatus.unread
                              ) {
                                dispatchNotifications({
                                  type: 'SELECTED_CHECKBOX_STATUS',
                                  selectedCheckBoxStatus: ''
                                })
                                onCheckUnReadNotification(false)
                                dispatchNotifications({
                                  type: 'DISABLED_ACTION',
                                  disabledAction: []
                                })
                              } else {
                                dispatchNotifications({
                                  type: 'SELECTED_CHECKBOX_STATUS',
                                  selectedCheckBoxStatus: readStatus.unread
                                })
                                onCheckUnReadNotification(true)
                                dispatchNotifications({
                                  type: 'DISABLED_ACTION',
                                  disabledAction: [readStatus.mark_unread]
                                })
                              }
                            }
                          }
                        ]}
                      >
                        <CaretDownFill />
                      </Menu>
                    </Box>
                    {/* <Dropdown
                  name="actions"
                  testId="actions-dropdown"
                  disabled={stateV.disabledAction}
                  placeholder={t('notification.actions')}
                  options={readStatus}
                  value={stateV.selectedReadStatus}
                  onChangeDropdown={(value) => {
                    dispatchNotifications({
                      type: 'SELECTED_READ_STATUS',
                      selectedReadStatus: value
                    })
                  }}
                  size="small"
                /> */}
                  </Box>
                  {stateV.showWarningBanner && (
                    <Notification
                      type="inline"
                      testId="banner-warning-notification-test-id"
                      backgroundColor="status-warning"
                      text={t('common:data_table_warning_msg')}
                    />
                  )}
                  <ListNotifications
                    stateV={stateV}
                    dispatchNotifications={dispatchNotifications}
                    type={type}
                  />
                </Box>
              )}
              {(stateV.loadingWithFilter ||
                stateV.loadingWithOutFilter ||
                stateV.patchLoader) && (
                <Box direction="row-responsive" align="center" justify="center">
                  <Loader
                    testId="devices-loader"
                    label={t('notification.loading_notification')}
                    orientation="horizontal"
                  />
                </Box>
              )}
              {stateV.openConfirmation && (
                <ConfirmationModal
                  onSetOpen={(value) => {
                    dispatchNotifications({
                      type: 'OPEN_CONFIRMATION',
                      openConfirmation: value
                    })
                  }}
                  setConfirmAction={(value) => {
                    if (value) {
                      dispatchNotifications({
                        type: 'CONFIRM_ACTION',
                        confirmAction: true
                      })
                    }
                  }}
                  selectedCheckBoxStatus={stateV.selectedCheckBoxStatus}
                  setSelectedReadStatus={(value) => {
                    dispatchNotifications({
                      type: 'SELECTED_READ_STATUS',
                      selectedReadStatus: value
                    })
                  }}
                />
              )}
              {!stateV.hidePagination && (
                <Box direction="row" justify="between">
                  <Box
                    margin={{ right: 'small', top: 'small' }}
                    data-testId="pagination-info"
                  >
                    <Typography type="text">
                      {getPaginationInfo(stateV.page)}
                    </Typography>
                  </Box>
                  <Box
                    direction="row-responsive"
                    alignSelf="end"
                    margin={{ bottom: 'large' }}
                  >
                    {stateV.page !== 1 && (
                      <Box
                        align="center"
                        justify="center"
                        direction="row-responsive"
                        margin={{ right: 'small', top: 'small' }}
                        onClick={handleClickPrev}
                        padding="small"
                        focusIndicator={false}
                      >
                        <Previous
                          size="medium"
                          margin={{ vertical: 'xsmall' }}
                        />
                        <Text
                          size="medium"
                          type="text"
                          weight="bold"
                          data-testid="notifications-previous-button-pagination"
                        >
                          {t('notification.prev_btn_label')}
                        </Text>
                      </Box>
                    )}
                    {!stateV.hideNext && (
                      <Box
                        align="center"
                        justify="center"
                        direction="row-responsive"
                        onClick={handleClickNext}
                        padding="small"
                        focusIndicator={false}
                        margin={{ top: 'small' }}
                      >
                        <Text
                          size="medium"
                          type="text"
                          weight="bold"
                          data-testid="notifications-next-button-pagination"
                        >
                          {t('notification.next_btn_label')}
                        </Text>
                        <Next margin={{ vertical: 'small' }} size="medium" />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}
NotificationsLogsPage.propTypes = {
  type: string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setCount: PropTypes.func.isRequired,
  showNotificationComponents: PropTypes.bool.isRequired,
  setNotificationsExists: PropTypes.func.isRequired,
  setIsLoader: PropTypes.func.isRequired
}

export { NotificationsLogsPage }
