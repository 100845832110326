// Copyright 2024 Hewlett Packard Enterprise Development LP
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CCSForm,
  FormInput,
  FormTextArea,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../components'
import { getErrorMessage, post, patch } from '../../../utils/api-utils'
import Markdown from '../../../commoncomponents/Markdown'

const FAKE_SECRET = '*********'
// Define the required patterns
const upperCase = '[A-Z]'
const lowerCase = '[a-z]'
const number = '\\d'
const specialChars = '[!@#$%^&*()_+={}[\\]:;"\'<>,.?/\\\\|~-]'
const disallowedChars = '\\s'

const secretFieldRegex = new RegExp(
  `^(?!.*${disallowedChars})(?=.*${upperCase})(?=.*${lowerCase})(?=.*${number})(?=.*${specialChars})[^\\s]{8,}$`
)
// Regular expression for URL validation
const urlPattern = /^https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/
const descRegex = /^[a-zA-Z0-9 _\-.@,]+$/
const nameRegex = /^[a-zA-Z0-9 _\-.@]+$/

const CreateAndEditEventWebhookSidePanel = ({
  onSetOpen,
  webhookPanelInfo = {
    id: null,
    name: '',
    description: '',
    webhookUrl: '',
    webhookId: null,
    webhookName: '',
    webhookDesc: '',
    webhookDestination: '',
    webhookSecret: ''
  },
  onToastNotification = () => {},
  setLoading,
  isCreateWebhookSidePanel
}) => {
  const { t } = useTranslation(['authz', 'common', 'automations'])
  const { oidcUser } = useReactOidc()
  const [isDisabled, setIsDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')
  const [destinationErrorMessage, setDestinationErrorMessage] = useState('')
  const [secretErrorMessage, setSecretErrorMessage] = useState('')
  const [inputType, setInputType] = useState('password')
  const [isOpenWebhookLimitDialog, setIsOpenWebhookLimitDialog] =
    useState(false)
  const [formValues, setFormValues] = useState({
    webhook_name: webhookPanelInfo?.name,
    webhook_desc: webhookPanelInfo?.description,
    webhook_destination: webhookPanelInfo?.webhookUrl,
    webhook_secret: isCreateWebhookSidePanel ? '' : FAKE_SECRET
  })
  const [initialValues, setInitialValues] = useState({
    webhook_name: webhookPanelInfo?.name,
    webhook_desc: webhookPanelInfo?.description,
    webhook_destination: webhookPanelInfo?.webhookUrl,
    webhook_secret: isCreateWebhookSidePanel ? '' : FAKE_SECRET
  })
  const { id } = webhookPanelInfo

  const onSuccessHandler = (title, message) => {
    setLoading(false)
    onToastNotification({
      title,
      notificationMessage: message,
      severity: 'info'
    })
  }

  const onErrorHandler = () => {
    setLoading(false)
  }

  const validateSecret = (secret) => {
    if (!secret.length) {
      setSecretErrorMessage(t('common:this_is_required'))
      return false
    }

    if (secret !== FAKE_SECRET && !secretFieldRegex.test(secret)) {
      setSecretErrorMessage(t('automations:webhooks.secret_pattern_message'))
      return false
    }
    if (secret.length > 50) {
      setSecretErrorMessage(t('automations:webhooks.max_length_50_char'))
      return false
    }
    setSecretErrorMessage('')
    return true
  }

  const validateForm = () => {
    let isValid = true

    if (!formValues.webhook_name.trim().length) {
      setNameErrorMessage(t('common:this_is_required'))
      isValid = false
    } else if (formValues.webhook_name.trim().length > 50) {
      setNameErrorMessage(t('automations:webhooks.max_length_50_char'))
      isValid = false
    } else if (!nameRegex.test(formValues.webhook_name.trim())) {
      setNameErrorMessage(t('automations:webhooks.invalid_name_message'))
      isValid = false
    } else {
      setNameErrorMessage('')
    }

    if (formValues.webhook_desc && formValues.webhook_desc.trim().length > 0) {
      if (formValues.webhook_desc.trim().length > 100) {
        setDescriptionErrorMessage(
          t('automations:webhooks.max_length_100_char')
        )
        isValid = false
      } else if (!descRegex.test(formValues.webhook_desc.trim())) {
        setDescriptionErrorMessage(
          t('automations:webhooks.invalid_description_message')
        )
        isValid = false
      } else {
        setDescriptionErrorMessage('')
      }
    } else {
      setDescriptionErrorMessage('')
    }

    if (!formValues.webhook_destination.trim().length) {
      setDestinationErrorMessage(t('common:this_is_required'))
      isValid = false
    } else if (!urlPattern.test(formValues.webhook_destination)) {
      setDestinationErrorMessage(t('automations:webhooks.webhook_error_msg'))
      isValid = false
    } else {
      setDestinationErrorMessage('')
    }

    const secret = formValues.webhook_secret?.trim()
    if (!validateSecret(secret)) {
      isValid = false
    }

    return isValid
  }

  const createWebhook = async () => {
    const createSuccessMessage = (
      <Markdown>
        {t('automations:webhooks.create_webhook_success_notif_msg', {
          webhookName: formValues?.webhook_name.trim()
        })}
      </Markdown>
    )

    if (validateForm()) {
      try {
        setIsDisabled(true)
        const requestBody = {
          name: formValues?.webhook_name?.trim(),
          description: formValues?.webhook_desc?.trim(),
          destination: formValues?.webhook_destination?.trim(),
          secret: formValues?.webhook_secret?.trim()
        }
        await post(
          `/events/v1beta1/webhooks`,
          requestBody,
          oidcUser?.access_token
        ).then(() => {
          setIsDisabled(false)
          onSuccessHandler('', createSuccessMessage)
          onSetOpen(false)
        })
      } catch (error) {
        setIsDisabled(false)
        setLoading(false)
        const createErrorMessage = getErrorMessage(error, t)
        setErrorMessage(createErrorMessage)
        onErrorHandler()
      }
    }
  }

  const editWebhook = async () => {
    // const editSuccessTitle = t('workspace_group.edit_workspace_confirm_title')
    const editSuccessMessage = (
      <Markdown>
        {t('automations:webhooks.edit_webhook_success_notif_msg', {
          webhookName: formValues?.webhook_name?.trim()
        })}
      </Markdown>
    )
    const requestBody = {
      name: formValues?.webhook_name?.trim(),
      description: formValues?.webhook_desc?.trim(),
      destination: formValues?.webhook_destination?.trim()
    }

    if (formValues?.webhook_secret !== FAKE_SECRET) {
      requestBody.secret = formValues?.webhook_secret?.trim()
    }

    const isAllSame =
      requestBody.name === initialValues.webhook_name &&
      requestBody.description === initialValues.webhook_desc &&
      requestBody.destination === initialValues.webhook_destination &&
      requestBody.secret === undefined

    try {
      if (!isAllSame && validateForm()) {
        setIsDisabled(true)
        await patch(
          `/events/v1beta1/webhooks/${id}`,
          requestBody,
          oidcUser?.access_token
        ).then(() => {
          onSuccessHandler('', editSuccessMessage)
          setIsDisabled(false)
          onSetOpen(false)
          setInitialValues({
            webhook_name: '',
            webhook_desc: ''
          })
        })
      }
    } catch (error) {
      setIsDisabled(false)
      setLoading(false)
      const editErrorMessage = getErrorMessage(error, t)
      setErrorMessage(editErrorMessage)
      onErrorHandler()
    }
  }

  const handleClose = () => {
    setIsOpenWebhookLimitDialog(false)
    onSetOpen(false)
  }

  const onChangeWebhookName = (event) => {
    if (event.target.value.length <= 50) {
      setNameErrorMessage('')
      setFormValues({
        ...formValues,
        webhook_name: event.target.value
      })
    } else {
      setNameErrorMessage(t('automations:webhooks.max_length_50_char'))
    }
  }

  const onChangeWebhookDesc = (event) => {
    if (event.target.value.length > 100) {
      setDescriptionErrorMessage(t('automations:webhooks.max_length_100_char'))
    } else {
      setDescriptionErrorMessage('')
    }
    setFormValues({
      ...formValues,
      webhook_desc: event?.target?.value
    })
  }

  const onChangeWebhookUrl = (event) => {
    if (event.target.value.length <= 2048) {
      setDestinationErrorMessage('')
      setFormValues({
        ...formValues,
        webhook_destination: event?.target?.value
      })
    } else {
      setDestinationErrorMessage(t('automations:webhooks.max_length_2048_char'))
    }
  }

  const onChangeWebhookSecret = (event) => {
    if (validateSecret(event.target.value)) {
      setFormValues({
        ...formValues,
        webhook_secret: event?.target?.value
      })
    }
  }

  return (
    <>
      <ModalDialog
        header={
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="2"
                testId="events-webhook-group-side-panel-title"
              >
                {isCreateWebhookSidePanel
                  ? t('automations:webhooks.create_event_panel_title')
                  : t('automations:webhooks.edit_event_panel_title')}
              </Typography>
            }
            onClose={() => {
              onSetOpen(false)
            }}
          />
        }
        content={
          <Box
            margin={{ top: 'medium' }}
            width={{ min: 'medium', max: 'full' }}
          >
            <CCSForm
              value={formValues}
              messages={{ required: t('common:this_is_required') }}
              errorMessage={
                errorMessage && (
                  <Box width="medium">
                    <Typography type="text" emphasis>
                      {errorMessage}
                    </Typography>
                  </Box>
                )
              }
              validate="blur"
              onChange={setFormValues}
              onSubmit={isCreateWebhookSidePanel ? createWebhook : editWebhook}
              testId="events-webhook-group-modal-form"
            >
              <Box gap="small">
                <Box gap="xxsmall">
                  <Typography
                    level="4"
                    weight="500"
                    size="xsmall"
                    type="text"
                    data-testid="events-webhook-name-title"
                  >
                    {t('automations:webhooks.create_webhook_name')}*
                  </Typography>

                  {/* Name Input Field */}
                  <FormInput
                    required
                    error={nameErrorMessage}
                    name="webhook_name"
                    inputType="text"
                    value={formValues?.webhook_name}
                    onChange={onChangeWebhookName}
                    testId="events-webhook-name-form-field"
                  />
                </Box>

                {/* Description Input Field */}
                <Box gap="xxsmall">
                  <Box gap="xxsmall">
                    <Typography
                      level="4"
                      size="xsmall"
                      weight="500"
                      type="text"
                      testId="events-webhook-desc-title"
                    >
                      {t('automations:webhooks.create_webhook_description')}
                    </Typography>
                    <Typography
                      level="6"
                      size="xsmall"
                      type="text"
                      testId="events-webhook-description-sub-title"
                    >
                      {t(
                        'automations:webhooks.create_webhook_description_subtitle'
                      )}
                    </Typography>
                  </Box>

                  <FormInput
                    name="webhook_desc"
                    error={descriptionErrorMessage}
                    inputType="text"
                    value={formValues?.webhook_desc}
                    onChange={onChangeWebhookDesc}
                    testId="events-webhook-desc-form-field"
                  />
                </Box>

                {/* Webhook URL Description Field */}
                <Box gap="xxsmall">
                  <Box gap="xxsmall">
                    <Typography
                      level="4"
                      size="xsmall"
                      weight="500"
                      type="text"
                      testId="events-webhook-url-title"
                    >
                      {t('automations:webhooks.create_webhook_url')}
                    </Typography>
                  </Box>

                  <Box gap="medium" data-testid="events-webhook-url">
                    <Box direction="column" gap="xsmall">
                      <FormTextArea
                        required
                        error={destinationErrorMessage}
                        name="webhook_destination"
                        inputType="text"
                        value={formValues?.webhook_destination}
                        onChange={onChangeWebhookUrl}
                        testId="events-webhook-url-form-field"
                      />
                    </Box>
                  </Box>
                </Box>

                {/* Webhook Secret Description Field */}
                <Box gap="xxsmall">
                  <Box gap="xxsmall">
                    <Typography
                      level="4"
                      size="xsmall"
                      weight="500"
                      type="text"
                      testId="events-webhook-secret-title"
                    >
                      {t('automations:webhooks.create_webhook_secret')}
                    </Typography>

                    <Typography
                      required
                      level="6"
                      size="xsmall"
                      type="text"
                      testId="events-webhook-secret-sub-title"
                    >
                      {t(
                        'automations:webhooks.create_webhook_secret_description'
                      )}
                    </Typography>
                  </Box>

                  <Box gap="medium" data-testid="events-webhook-secret">
                    <Box direction="column" gap="xsmall">
                      <FormInput
                        required
                        error={secretErrorMessage}
                        name="webhook_secret"
                        type={inputType}
                        value={formValues?.webhook_secret}
                        onFocus={(event) => {
                          setInputType('text')
                          if (
                            event.target.value === FAKE_SECRET &&
                            !isCreateWebhookSidePanel
                          ) {
                            setFormValues({
                              ...formValues,
                              webhook_secret: ''
                            })
                          }
                        }}
                        onBlur={(event) => {
                          setInputType('password')
                          if (
                            event.target.value.trim() === '' &&
                            !isCreateWebhookSidePanel
                          ) {
                            setFormValues({
                              ...formValues,
                              webhook_secret: FAKE_SECRET
                            })
                          }
                        }}
                        onChange={onChangeWebhookSecret}
                        testId="events-webhook-secret-form-field"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  direction="row"
                  justify="start"
                  gap="medium"
                  margin={{ top: 'medium' }}
                  pad={{ top: 'medium' }}
                >
                  <Button
                    primary
                    label={
                      isCreateWebhookSidePanel
                        ? t('automations:webhooks.create_webhook_btn_label')
                        : t('automations:webhooks.edit_webhook_btn_label')
                    }
                    onClick={
                      isCreateWebhookSidePanel ? createWebhook : editWebhook
                    }
                    disabled={isDisabled}
                    testId="edit_webhook_btn_label"
                  />
                  <Button
                    label={t('automations:webhooks.cancel')}
                    onClick={() => {
                      onSetOpen(false)
                    }}
                    testId="edit-webhook-cancel-btn"
                  />
                </Box>
                <Box pad={{ bottom: 'medium' }} />
              </Box>
            </CCSForm>
          </Box>
        }
        onClose={() => onSetOpen(false)}
        position="right"
        height="100%"
        overflow="hidden"
        testId="edit-webhook-side-panel"
        width="440px"
      />

      {isOpenWebhookLimitDialog ? (
        <ModalDialog
          content={
            <Box>
              <Box direction="column">
                <h2 style={{ fontWeight: '500' }}>
                  {t('automations:webhooks.create_webhook_btn_label')}
                </h2>
              </Box>
              <Box direction="column">
                {t('automations:webhooks.webhook_register_limit_msg')}
              </Box>
            </Box>
          }
          footer={
            <Box style={{ display: 'flex', alignItems: 'end' }}>
              <Button
                primary
                label="Close"
                onClick={handleClose}
                testId="webhook-reach-limit-close-btn"
              />
            </Box>
          }
          onClose={handleClose}
          testId="webhook-reach-limit-modal"
          width="large"
        />
      ) : null}
    </>
  )
}

CreateAndEditEventWebhookSidePanel.propTypes = {
  /**
   * Boolean that specifies if it is create or edit
   */
  isCreateWebhookSidePanel: PropTypes.bool.isRequired,

  /**
   * Callback function to toggle edit webhook display
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * Object that consists of webhookId: string, webhookName: string and webhookDesc: string
   *
   * webhookId: webhook id for updating the webhook group
   *
   * webhookName: webhook name to display
   *
   * webhookDesc: webhook description to display
   */
  webhookPanelInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    webhookUrl: PropTypes.string,
    webhookSecret: PropTypes.string
  }),
  /**
   * Callback to set loading back to caller.
   */
  setLoading: PropTypes.func.isRequired,

  /**
   * Callback function when its success
   */
  onToastNotification: PropTypes.func
}

export default CreateAndEditEventWebhookSidePanel
