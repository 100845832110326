// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, Image, TextInput } from 'grommet'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

/* eslint-enable */
import {
  Anchor,
  Button,
  ButtonGroup,
  CCSForm,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../../components'
import { validateOTP } from '../../../../utils/validation-utils'
import { doActivateMFA } from '../utils/api-utils'
import { post } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'

import appleStoreImage from './brand-apple-app-store.png'
import googlePlayImage from './brand-google-play.png'

const renderStep = (stepNum, stepTxt) => {
  return (
    <>
      <Typography size="xlarge" testId="mfa-setup-step1" type="text">
        <>
          <b>{stepNum}</b> {stepTxt}
        </>
      </Typography>
    </>
  )
}

export const SetupMFAModal = ({
  MFAData = {},
  handleClose,
  handleSuccess,
  showModalBg = true
}) => {
  const [APIError, setAPIError] = useState(null)
  const [oneTimePasscode, setOneTimePasscode] = useState('')
  const [showSecretKey, setShowSecretKey] = useState(false)
  const { t } = useTranslation(['authn'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = () => {
    setErrorMessage('')

    if (MFAData.verificationHash) {
      const body = {
        verification_hash: MFAData.verificationHash,
        device_id: MFAData.deviceId,
        otp: oneTimePasscode
      }
      post('/accounts/ui/v1/user/mfa/reset', body, undefined, undefined, {
        responseType: 'arraybuffer'
      }).then(
        (response) => {
          if (response.status === 200) {
            handleSuccess()
          }
        },
        (error) => {
          setAPIError(error)
        }
      )
    } else {
      const requestBody = {
        idp_user_id: MFAData.idpUserId,
        device_id: MFAData.deviceId,
        otp: oneTimePasscode
      }
      doActivateMFA(
        oidcUser.access_token,
        requestBody,
        handleSuccess,
        setErrorMessage,
        t
      )
    }
  }

  return (
    <>
      {APIError && displayApiError(APIError, t, setAPIError)}
      <ModalDialog
        header={
          <ModalHeader
            subtitle={t('security.mfa_setup_subhdr')}
            title={
              <Typography testId="modal-title" type="heading" level="1">
                {t('security.mfa_setup_header')}
              </Typography>
            }
          />
        }
        content={
          <Box margin={{ top: 'medium' }}>
            <Box margin={{ top: 'small' }}>
              {renderStep(
                t('security.mfa_step1'),
                t('security.mfa_step1_desc')
              )}
              <Box direction="row" gap="small" margin={{ top: 'small' }}>
                <Box width="small">
                  <Anchor
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
                    target="_blank"
                    label=""
                    testId="mfa-play-store"
                  >
                    <Image fill src={googlePlayImage} />
                  </Anchor>
                </Box>
                <Box width="small">
                  <Anchor
                    href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                    target="_blank"
                    label=""
                    testId="mfa-app-store"
                  >
                    <Image fill src={appleStoreImage} />
                  </Anchor>
                </Box>
              </Box>
            </Box>
            <Box margin={{ top: 'small' }}>
              {renderStep(
                t('security.mfa_step2'),
                t('security.mfa_step2_desc')
              )}
              <Box direction="row" gap="medium" align="center">
                <Box width="small">
                  <Image fit="cover" size="medium" fill src={MFAData.imgSrc} />
                </Box>
                <Typography size="large" type="text">
                  {t('security.mfa_or')}
                </Typography>
                <Box>
                  <Button
                    label="Show Secret Key"
                    onClick={() => setShowSecretKey(true)}
                    testId="show-secret-key-btn"
                    secondary
                  />
                  {showSecretKey && MFAData && MFAData.totpSecret && (
                    <Typography
                      size="small"
                      type="text"
                      margin={{ top: 'small' }}
                    >
                      {MFAData && MFAData.totpSecret}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box margin={{ top: 'small' }}>
              {renderStep(
                t('security.mfa_step3'),
                t('security.mfa_step3_desc')
              )}
              <CCSForm
                errorMessage={errorMessage}
                testId="add-device-form"
                onSubmit={handleSubmit}
                messages={{ required: t('security.mfa_required') }}
              >
                <Box
                  margin={{ top: 'small', bottom: 'xxsmall' }}
                  justify="between"
                  gap="small"
                >
                  <FormField
                    data-testid="serial-number-form-field"
                    label={t('security.mfa_onetime_passcode')}
                    name="mfa_one_time_passcode"
                    width="small"
                    required
                    validate={(value) => {
                      if (!validateOTP(value))
                        return t('security.mfa_validation1')
                      return true
                    }}
                  >
                    <TextInput
                      data-testid="serial-number-input"
                      name="mfa_one_time_passcode"
                      placeholder="XXXXXX"
                      value={oneTimePasscode}
                      onChange={(event) =>
                        setOneTimePasscode(event.target.value)
                      }
                    />
                  </FormField>
                  <Box>
                    <ButtonGroup
                      buttonList={[
                        {
                          label: t('security.mfa_cancel_setup'),
                          secondary: true,
                          testId: 'mfa-close-modal-btn',
                          onClick: handleClose
                        },
                        {
                          label: t('security.mfa_complete_setup'),
                          type: 'submit',
                          primary: true,
                          testId: 'mfa-complete-setup-btn'
                        }
                      ]}
                      testId="mfa-modal-action-btns"
                    />
                  </Box>
                </Box>
              </CCSForm>
            </Box>
          </Box>
        }
        onClose={handleClose}
        testId="mfa-setup-modal"
        width="large"
        modal={showModalBg}
      />
    </>
  )
}

SetupMFAModal.propTypes = {
  MFAData: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  showModalBg: PropTypes.bool
}
