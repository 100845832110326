// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import uniqBy from 'lodash/uniqBy'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Box } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import omitBy from 'lodash/omitBy'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { isCoP } from '../../../utils/api-utils'
import {
  DataTable,
  FilterButton,
  Typography,
  Notification,
  ActionButton,
  Dropdown,
  Button
} from '../../../components'
import { validateMACAddress } from '../../../utils/validation-utils'
import {
  CCSManagerActions,
  useCCSManagerContext
} from '../../../context/ccs-manager-context'
import {
  deviceTypeIcon,
  getPaginationShowIdx,
  deviceTypeOptionsDropdown
} from '../../../utils/common-utils'
import { customRenderer } from '../../../commoncomponents/CustomRenderer'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  VGW_DEVICE_PARTNUM,
  VGW_DEVICE_MODEL
} from '../../../utils/dm-sm-common-utils'
import {
  ManageLocationModal,
  ServiceDeliveryContactModal
} from '../../../commoncomponents/service-delivery-info'
import { displayNotification } from '../../../utils/notificiation-utils'
import {
  getAllModelsOptions,
  getFolderList
} from '../../../utils/manage-account-utils'
import { getDevices } from '../utils'
import { ExportModal } from '../../../commoncomponents/export-devices-datatable/ExportModal'
import { getApiErrorMessage } from '../../../utils/error-handling-utils'

import { ConfirmationModal, MoveToFolderModal } from './activate-move-to-folder'
import { UnclaimDeviceModal } from './customer-actions/UnclaimDeviceModal'

const DevicesDataTable = ({
  customerData = undefined,
  refreshCount,
  refreshDataTable
}) => {
  const isCCSManager = true
  const navigate = useNavigate()
  const { dispatchCCSManagerContext } = useCCSManagerContext()
  const LDFlags = useFlags()
  const [exportDevicesModal, setExportDevicesModal] = useState(false)
  const location = useLocation()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['device', 'common'])
  const [viewData, setViewData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [reqData, setReqData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filterOptions, setFilterOptions] = useState({})
  const [moveToFolderModal, setMoveToFolderModal] = useState(false)
  const [unclaimDeviceModal, setUnclaimDeviceModal] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [folderData, setFolderData] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)
  const [openManageLocationModal, setOpenManageLocationModal] = useState(false)
  const [showOverrideGTStatus, setShowOverrideGTStatus] = useState(false)
  const [formattedFilterData, setFormattedFilterData] = useState({})
  const [openServiceDeliveryModal, setOpenServiceDeliveryModal] =
    useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const dmUXIFlag = LDFlags['glcp-dm-uxi']
  const dmSilverPeakFlag = LDFlags['glcp-dm-silver-peak']
  const unclaimFeatureFlag = LDFlags['glcp-sa-unclaim-actions']
  const gtStatusFlag = LDFlags['glcp-be-activate-gt-phase2']
  let showDetailsPage = true

  // To determine the current page - customer detail page / devices page
  const isCustomerDetailPage = useCallback(() => {
    return location?.pathname === '/manage-ccs/customers/customer-details'
  }, [location?.pathname])

  // Logic when row is clicked
  const handleRowClick = (datum) => {
    const { serial_number: serialNumber, mac_address: macAddr } = datum
    const deviceData = {
      serial_number: serialNumber,
      mac_address: macAddr
    }
    sessionStorage.setItem('deviceData', JSON.stringify(deviceData))
    navigate(`/manage-ccs/devices/${datum.serial_number}`)
    dispatchCCSManagerContext({
      type: CCSManagerActions.SET_SELECTED_DEVICE_CUSTOMER_DATA,
      data: { ...customerData, id: datum.platform_customer_id }
    })
  }
  const accountsNotAllowed = (accountId) => {
    let accFlag = false
    if (accountId === 'Aruba-Factory-CCS-Platform') {
      accFlag = true
    }
    return accFlag
  }

  const columns = [
    {
      property: 'serial_number',
      header: t('serial_number'),
      type: 'string',
      primary: true,
      render: (datum) => (
        <Typography
          icon={deviceTypeIcon[datum.display_device_type]}
          size="medium"
          testId="t13"
          type="text"
          weight="bold"
        >
          {datum.serial_number}
        </Typography>
      )
    },
    {
      property: 'device_model',
      type: 'string',
      header: t('model')
    },
    {
      property: 'inv_name',
      type: 'string',
      header: t('device_name')
    },
    {
      property: 'mac_address',
      type: 'string',
      header: t('mac_address')
    },
    ...(isCustomerDetailPage() && LDFlags['glcp-tac-sm-enhancement']
      ? [
          {
            property: 'subscription_tier_description',
            type: 'string',
            header: t('tier')
          }
        ]
      : []),
    {
      property: 'folder_name',
      type: 'string',
      header: t('folder')
    },
    ...(!isCustomerDetailPage()
      ? [
          {
            property: 'platform_customer_id',
            type: 'string',
            header: t('platform_customer_id')
          }
        ]
      : []),
    {
      property: '',
      header: '',
      render: (datum) => (
        <VisibilityWrapper
          ifNotAllowedCallback={() => {
            if (showDetailsPage) showDetailsPage = false
          }}
        >
          <ActionButton
            actions={[
              {
                label: t('view_details'),
                onClick: () => {
                  handleRowClick(datum)
                },
                visibility: {
                  rbac: {
                    permission: 'ccs.activate.view',
                    resource: '/ccs/activate/tac'
                  }
                }
              },
              {
                label: t('Unclaim'),
                onClick: () => {
                  setUnclaimDeviceModal(true)
                  setReqData([
                    {
                      platform_customer_id: datum?.platform_customer_id,
                      serial_number: datum?.serial_number,
                      part_number: datum?.part_number,
                      device_type: datum?.device_type
                    }
                  ])
                },
                hidden:
                  accountsNotAllowed(datum.platform_customer_id) ||
                  !unclaimFeatureFlag,
                visibility: {
                  rbac: {
                    permission: 'ccs.activate.edit',
                    resource: '/ccs/activate/tac'
                  }
                }
              }
            ]}
            showOneActionAsDropDown
            testId="devices-table-action-btn"
            customRenderer={(actionBtn, visibility, idx) => {
              return (
                <VisibilityWrapper key={idx} rbac={visibility.rbac}>
                  {actionBtn}
                </VisibilityWrapper>
              )
            }}
          />
        </VisibilityWrapper>
      )
    }
  ]

  const itemsPerPage = 10
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const [deviceTypeFilterData, setDeviceTypeFilterData] = useState({})
  const [modelOptions, setmodelOptions] = useState([])
  const [searchTermModel, setSearchTermModel] = useState('')
  const [modelFilterData, setModelFilterData] = useState([])
  const [clear, setClear] = useState(false)
  const [folderListResponse, setFolderListResponse] = useState([])
  const [folderFilterData, setFolderFilterData] = useState([])
  // for paginating on scroll folder list
  const foldersPerPage = 50
  const [searchedText, setSearchedText] = useState('')
  const [foldersPage, setFoldersPage] = useState(0)
  const [appendList, setAppendList] = useState(false)
  const [totalfolders, setTotalFolders] = useState(foldersPerPage)
  // for paginating on scroll model list
  const [modelsPage, setModelsPage] = useState(0)
  const [totalModels, setTotalModels] = useState(foldersPerPage)

  useEffect(() => {
    if (location?.state?.folderDetail)
      folderFilterData.push({
        folder_name: location?.state?.folderDetail?.folder_name,
        folder_id: location?.state?.folderDetail?.folder_id
      })
    else setFolderFilterData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.folderDetail])
  useEffect(() => {
    if (isCustomerDetailPage()) {
      const param = {
        limit: foldersPerPage,
        page: foldersPage,
        ...(searchedText ? { search_name: searchedText } : ''),
        ...(isCCSManager && { platform_customer_id: customerData.id })
      }
      getFolderList({
        token: oidcUser.access_token,
        param,
        t,
        setErrorMessage,
        isCCSManager
      }).then((data) => {
        const foldersResponse = data?.folders
        if (foldersResponse) {
          if (appendList) {
            let modifiedResponse = [
              ...folderFilterData,
              ...folderListResponse,
              ...foldersResponse
            ]
            modifiedResponse = uniqBy(modifiedResponse, 'folder_id')
            setFolderListResponse(modifiedResponse)
          } else {
            setFolderListResponse(foldersResponse)
          }
        }
        setTotalFolders(data?.pagination?.total_count || foldersPerPage)
      })
    }
  }, [oidcUser.access_token, foldersPage, t, searchedText, clear]) // eslint-disable-line react-hooks/exhaustive-deps

  const [deviceTypeOptions, setDeviceTypeOptions] = useState(
    deviceTypeOptionsDropdown(t, LDFlags)
  )

  useEffect(() => {
    setDeviceTypeOptions(deviceTypeOptionsDropdown(t, LDFlags))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, dmUXIFlag, dmSilverPeakFlag])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchValue = useCallback(
    debounce((text, dropdownType) => {
      if (dropdownType === 'allDevices') {
        const filteredValues = deviceTypeOptions.filter((o) => {
          return o?.label?.toUpperCase()?.includes(text?.toUpperCase())
        })
        setDeviceTypeOptions(filteredValues)
      }
      if (dropdownType === 'allModels') {
        setSearchTermModel(text)
        setAppendList(false)
        setModelsPage(0)
      }
      if (dropdownType === 'allFolders') {
        setSearchedText(text)
        setAppendList(false)
        setFoldersPage(0)
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (isCustomerDetailPage()) {
      const param = {
        ...(searchTermModel?.length && { search_string: searchTermModel }),
        limit: foldersPerPage,
        page: modelsPage,
        ...(isCCSManager && { platform_customer_id: customerData.id })
      }
      getAllModelsOptions({
        token: oidcUser.access_token,
        t,
        param,
        setErrorMessage,
        setSelectedRows,
        isCCSManager
      }).then((data) => {
        setTotalModels(data?.pagination?.total_count || 0)
        const modelsResponse = data?.device_models
        if (modelsResponse) {
          if (appendList) {
            setmodelOptions([
              ...new Set([...modelFilterData, ...modelsResponse])
            ])
          } else {
            setmodelOptions(modelsResponse)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oidcUser.access_token,
    t,
    refreshCount,
    searchTermModel,
    modelsPage,
    foldersPerPage,
    clear
  ])

  useEffect(() => {
    let isCurrent = true
    getDevices(
      {
        limit: itemsPerPage,
        page: page - 1,
        ...(searchTerm?.length &&
          isCustomerDetailPage() && { search_string: searchTerm }),
        ...(searchTerm?.length > 2 &&
          !isCustomerDetailPage() &&
          (validateMACAddress(searchTerm)
            ? { mac_address: searchTerm }
            : { serial_number: searchTerm })),
        ...(Object.keys(deviceTypeFilterData).length > 0 &&
          deviceTypeFilterData),
        ...(folderFilterData?.length && {
          folder_id: folderFilterData?.map((o) => o.folder_id).join(',')
        }),
        ...(modelFilterData?.length && {
          device_model: modelFilterData.join(',')
        }),
        ...filterOptions,
        ...(isCustomerDetailPage() && { platform_customer_id: customerData.id })
      },
      oidcUser.access_token,
      (response) => {
        if (!isCurrent) return
        setSelectedRows([])
        setViewData(
          response.data.devices.map((val) => ({
            ...val,
            folder_name: val.folder?.folder_name || ''
          }))
        )
        setTotalItems(response.data.pagination.total_count)
      },
      (error) => {
        setViewData([])
        setTotalItems(0)
        setErrorMessage(getApiErrorMessage(error, t))
      } // setLoading
    )

    return () => {
      isCurrent = false
    }
  }, [
    oidcUser.access_token,
    searchTerm,
    filterOptions,
    deviceTypeFilterData,
    modelFilterData,
    folderFilterData,
    page,
    customerData,
    refreshCount,
    LDFlags,
    isCustomerDetailPage,
    t,
    dispatchCCSManagerContext
  ])

  // Request data for Activate Action
  const handleActivateData = () => {
    if (selectedRows?.length) {
      const deviceInfo = viewData?.filter((value) => {
        return selectedRows?.includes(value.serial_number)
      })
      const modifiedDeviceInfo = deviceInfo?.reduce((acc, o) => {
        acc.push({
          serial_number: o.serial_number,
          mac_address: o.mac_address,
          part_number: o.part_number,
          device_type: o.device_type
        })
        return acc
      }, [])
      setReqData(modifiedDeviceInfo || [])
    } else setReqData([])
  }

  const handleExportBtnClick = () => {
    handleActivateData()
    setExportDevicesModal(true)
  }

  // Logic when row checkbox gets selected
  const handleRowCheckBoxSelection = (selectedRow) => {
    setSelectedRows(selectedRow)
  }

  const handleMoveToBtnClick = () => {
    handleActivateData()
    setMoveToFolderModal(true)
  }

  const handleUnassignDeviceClick = () => {
    handleActivateData()
    setUnclaimDeviceModal(true)
  }

  const isDeviceActionAllowed = () => {
    return selectedRows?.every((value) => {
      const result = viewData?.find((obj) => {
        return obj?.serial_number === value
      })
      return (
        result &&
        !(
          result?.device_model === VGW_DEVICE_MODEL ||
          result?.part_number === VGW_DEVICE_PARTNUM
        ) &&
        result?.archived === false
      )
    })
  }

  // Request data for Service Delivery Action
  const handleSdiData = () => {
    if (selectedRows?.length) {
      const deviceInfo = viewData?.filter((value) => {
        return selectedRows?.includes(value.serial_number)
      })
      const modifiedDeviceInfo = deviceInfo?.reduce((acc, o) => {
        acc.push({
          serial_number: o.serial_number,
          mac_address: o.mac_address,
          part_number: o.part_number,
          device_type: o.device_type,
          display_device_type: o.display_device_type
        })
        return acc
      }, [])
      setReqData(modifiedDeviceInfo || [])
    } else setReqData([])
  }

  const getValueLabel = (count) => {
    return (
      <Box
        pad={{
          top: 'xxsmall',
          bottom: 'xxsmall',
          left: 'small'
        }}
        width="small"
        border={{
          color: 'white',
          size: 'small',
          side: 'bottom,top'
        }}
      >
        <Typography type="text" emphasis>
          {t('selected_count', { count })}
        </Typography>
      </Box>
    )
  }

  const GTSfilterOptions = () => {
    let GTSfilterAttributes = [
      {
        name: 'gts_status',
        label: t('device:global_trade.gt_firmware_status_filter'),
        selectionType: 'radio',
        testId: 'gts-field',
        updateValues: (selectedFilter) => {
          if (selectedFilter.gts_status[0] === 'GT_FIRMWARE_LOCK') {
            setShowOverrideGTStatus(true)
          } else if (selectedFilter.gts_status[0] === 'GT_UNLOCK') {
            setShowOverrideGTStatus(false)
          }
        },
        values: [
          {
            valueLabel: t('device:global_trade.blocked'),
            valueName: 'GT_FIRMWARE_LOCK'
          },
          {
            valueLabel: t('device:global_trade.clear'),
            valueName: 'GT_UNLOCK'
          }
        ]
      }
    ]

    if (showOverrideGTStatus) {
      GTSfilterAttributes = [
        ...GTSfilterAttributes,
        {
          label: t('device:global_trade.override_gt_firmware_status_filter'),
          name: 'override_status',
          selectionType: 'radio',
          values: [
            { valueLabel: t('device:global_trade.On'), valueName: 'true' },
            { valueLabel: t('device:global_trade.Off'), valueName: 'false' }
          ]
        }
      ]
    }
    return GTSfilterAttributes
  }

  return (
    <Box pad={{ horizontal: 'xxsmall', top: 'small' }}>
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        filterButton={
          <FilterButton
            filterAttributes={[
              {
                label: t('folder_name'),
                name: 'folder_name',
                selectionType: 'text'
              },
              ...(isCustomerDetailPage()
                ? []
                : [
                    {
                      label: t('platform_customer_id'),
                      name: 'platform_customer_id',
                      selectionType: 'text'
                    }
                  ]),
              {
                label: t('device_type'),
                name: 'external_device_type',
                selectionType: 'radio',
                values: [
                  { valueLabel: t('access_points'), valueName: 'AP' },
                  { valueLabel: t('switches'), valueName: 'SWITCH' },
                  { valueLabel: t('gateways'), valueName: 'GATEWAY' },
                  { valueLabel: t('bridge'), valueName: 'BRIDGE' },
                  ...(!isCoP() && dmSilverPeakFlag
                    ? [
                        {
                          valueLabel: t('sd_wan_gateways'),
                          valueName: 'SD_WAN_GW'
                        }
                      ]
                    : []),
                  { valueLabel: t('servers'), valueName: 'COMPUTE' },
                  { valueLabel: t('storage'), valueName: 'STORAGE' },
                  ...(!isCoP() && dmUXIFlag
                    ? [{ valueLabel: t('sensors'), valueName: 'SENSOR' }]
                    : [])
                ]
              },
              ...(gtStatusFlag ? GTSfilterOptions() : [])
            ]}
            onFilterValuesChange={(filters) => {
              const formattedValues = Object.fromEntries(
                Object.entries(filters).map(([key, val]) => [
                  key,
                  val.map((v) => v.trimStart()).join()
                ])
              )
              if (
                formattedValues.gts_status === 'GT_UNLOCK' &&
                formattedValues.override_status
              ) {
                delete formattedValues.override_status
              }
              if (Object.keys(formattedValues).length === 0) {
                setShowOverrideGTStatus(false)
              }
              setFormattedFilterData(formattedValues)
              setFilterOptions(omitBy(formattedValues, (v) => v.length === 0))
              setPage(1)
            }}
            testId="filter-search-btn"
            onCancel={() => {
              if (formattedFilterData.gts_status === 'GT_FIRMWARE_LOCK') {
                setShowOverrideGTStatus(true)
              } else {
                setShowOverrideGTStatus(false)
              }
            }}
          />
        }
        {...(isCustomerDetailPage() && {
          filterButton: (
            <Box direction="row" align="end" gap="small">
              <Box>
                <Dropdown
                  size="small"
                  multiple
                  testId="folder-name-input"
                  labelKey="folder_name"
                  valueKey={{ key: 'folder_id', reduce: true }}
                  name="folder_name"
                  placeholder={t('all_folders')}
                  searchPlaceholder={t('search')}
                  options={folderListResponse}
                  value={folderFilterData.map((o) => o.folder_id)}
                  valueLabel={
                    folderFilterData?.length > 1
                      ? getValueLabel(folderFilterData?.length)
                      : undefined
                  }
                  onChange={({ option }) => {
                    const isExist = folderFilterData?.find(
                      (o) => o.folder_id === option.folder_id
                    )
                    let modifiedSelection = []
                    if (isExist) {
                      modifiedSelection = folderFilterData.filter(
                        (o) => o.folder_id !== option.folder_id
                      )
                    } else
                      modifiedSelection = [
                        ...folderFilterData,
                        {
                          folder_name: option.folder_name,
                          folder_id: option.folder_id
                        }
                      ]
                    setFolderFilterData(modifiedSelection)
                    setAppendList(true)
                  }}
                  onSearch={(searchText) => {
                    handleDebouncedSearchValue(searchText, 'allFolders')
                  }}
                  onMore={() => {
                    if (
                      totalfolders > foldersPerPage &&
                      foldersPage < totalfolders / foldersPerPage - 1
                    ) {
                      setFoldersPage(foldersPage + 1)
                      setAppendList(true)
                    }
                  }}
                  onClose={() => {
                    if (searchedText) {
                      setFolderListResponse([])
                      setAppendList(false)
                      setFoldersPage(0)
                      setSearchedText('')
                    }
                  }}
                />
              </Box>

              <Box>
                <Dropdown
                  clear={{
                    label: t('clear_selection')
                  }}
                  size="small"
                  options={deviceTypeOptions}
                  value={deviceTypeFilterData?.external_device_type || ''}
                  placeholder={t('all_device_types')}
                  valueKey={{ key: 'value', reduce: true }}
                  labelKey="label"
                  searchPlaceholder={t('search')}
                  onSearch={(text) => {
                    handleDebouncedSearchValue(text, 'allDevices')
                  }}
                  onChange={({ option }) => {
                    let filterData = {}
                    setSelectedRows([])
                    if (option) {
                      filterData = {
                        ...deviceTypeFilterData,
                        external_device_type: option?.value
                      }
                    }
                    setDeviceTypeFilterData(filterData)
                    setPage(1)
                  }}
                  onClose={() => {
                    setDeviceTypeOptions(deviceTypeOptionsDropdown(t, LDFlags))
                  }}
                  testId="device-type-dropdown"
                />
              </Box>

              <Box>
                <Dropdown
                  size="small"
                  multiple
                  options={modelOptions}
                  value={modelFilterData}
                  valueLabel={
                    modelFilterData?.length > 1
                      ? getValueLabel(modelFilterData?.length)
                      : undefined
                  }
                  placeholder={t('all_models')}
                  searchPlaceholder={t('search')}
                  onSearch={(text) => {
                    handleDebouncedSearchValue(text, 'allModels')
                  }}
                  onChange={({ option }) => {
                    setSelectedRows([])
                    const isExist = modelFilterData?.find((o) => o === option)
                    let modifiedSelection = []
                    if (isExist) {
                      modifiedSelection = modelFilterData.filter(
                        (o) => o !== option
                      )
                    } else modifiedSelection = [...modelFilterData, option]
                    setModelFilterData(modifiedSelection)
                    setModelsPage(0)
                    setAppendList(true)
                  }}
                  onMore={() => {
                    if (
                      totalModels > foldersPerPage &&
                      modelsPage < totalModels / foldersPerPage - 1
                    ) {
                      setModelsPage(modelsPage + 1)
                      setAppendList(true)
                    }
                  }}
                  onClose={() => {
                    if (searchTermModel) {
                      setmodelOptions([])
                      setAppendList(false)
                      setModelsPage(0)
                      setSearchTermModel('')
                    }
                  }}
                  testId="all-models-dropdown"
                />
              </Box>
              {!isEmpty(deviceTypeFilterData) ||
              modelFilterData?.length ||
              folderFilterData?.length ? (
                <Box width="small">
                  <Button
                    default
                    alignSelf="center"
                    testId="clear-filter-text"
                    label={t('clear_filters')}
                    onClick={() => {
                      setFolderFilterData([])
                      setModelFilterData([])
                      setDeviceTypeFilterData({})
                      setClear(!clear)
                    }}
                  />
                </Box>
              ) : null}
              {gtStatusFlag && (
                <FilterButton
                  filterAttributes={GTSfilterOptions()}
                  onFilterValuesChange={(filters) => {
                    const formattedValues = Object.fromEntries(
                      Object.entries(filters).map(([key, val]) => [
                        key,
                        val.map((v) => v.trimStart()).join()
                      ])
                    )
                    if (
                      formattedValues.gts_status === 'GT_UNLOCK' &&
                      formattedValues.override_status
                    ) {
                      delete formattedValues.override_status
                    }
                    if (Object.keys(formattedValues).length === 0) {
                      setShowOverrideGTStatus(false)
                    }
                    setFormattedFilterData(formattedValues)
                    setFilterOptions(
                      omitBy(formattedValues, (v) => v.length === 0)
                    )
                    setPage(1)
                  }}
                  testId="filter-search-btn"
                  onCancel={() => {
                    if (formattedFilterData.gts_status === 'GT_FIRMWARE_LOCK') {
                      setShowOverrideGTStatus(true)
                    } else {
                      setShowOverrideGTStatus(false)
                    }
                  }}
                />
              )}
            </Box>
          )
        })}
        search={{
          width: 'medium',
          onSearchValueChange: (val) => {
            setPage(1)
            setSearchTerm(val)
          },
          placeholder: isCustomerDetailPage()
            ? t('search_by_serial_mac_device_name')
            : t('search_by_serial_mac')
        }}
        grid={{
          columns,
          data: viewData,
          onClickRow: (e) => {
            if (showDetailsPage) handleRowClick(e.datum)
          }
        }}
        selection={
          isCustomerDetailPage()
            ? {
                bulkActions: {
                  actionDropdown: {
                    label: t('common:actions'), // label for actionDropdown
                    // rbac permissions for actionDropdown
                    visibility: {
                      rbac: {
                        permission: 'ccs.activate.view',
                        resource: isCCSManager
                          ? '/ccs/activate/tac'
                          : '/ccs/activate/customer'
                      }
                    }
                  },
                  // renderer for individual buttons in Action btn
                  customRenderer: (element, visibility, index) =>
                    customRenderer(element, visibility, index),
                  actions: [
                    {
                      label: t('unclaim_devices'),
                      onClick: handleUnassignDeviceClick,
                      testId: 'unclaim-devices-btn',
                      visibility: {
                        rbac: {
                          permission: 'ccs.activate.edit',
                          resource: isCCSManager
                            ? '/ccs/activate/tac'
                            : '/ccs/activate/customer'
                        }
                      },
                      hidden:
                        accountsNotAllowed(customerData.id) ||
                        !unclaimFeatureFlag
                    },
                    {
                      label: t('move_to_folder'),
                      onClick: handleMoveToBtnClick,
                      testId: 'move-to-folder-btn',
                      visibility: {
                        rbac: {
                          permission: 'ccs.activate.edit',
                          resource: isCCSManager
                            ? '/ccs/activate/tac'
                            : '/ccs/activate/customer'
                        }
                      }
                    },
                    {
                      label: t('export_devices'),
                      onClick: handleExportBtnClick,
                      testId: 'export-selected-btn',
                      visibility: {
                        rbac: {
                          permission: 'ccs.activate.view',
                          resource: isCCSManager
                            ? '/ccs/activate/tac'
                            : '/ccs/activate/customer'
                        }
                      }
                    },
                    {
                      label: t('manage_location'),
                      onClick: () => {
                        handleSdiData()
                        setOpenManageLocationModal(true)
                      },
                      hidden: !isDeviceActionAllowed(),
                      visibility: {
                        hideFor: {
                          feature: 'glcp-glasgow-sdi-ccs-manager'
                        },
                        rbac: {
                          resource: '/ccs/device-management',
                          permission: 'ccs.device-management.edit'
                        }
                      },
                      testId: 'manage-location-btn'
                    },
                    {
                      label: t('service_delivery_contact'),
                      onClick: () => {
                        handleSdiData()
                        setOpenServiceDeliveryModal(true)
                      },
                      hidden: !isDeviceActionAllowed(),
                      visibility: {
                        hideFor: {
                          feature: 'glcp-glasgow-sdi-ccs-manager'
                        },
                        rbac: {
                          resource: '/ccs/device-management',
                          permission: 'ccs.device-management.edit'
                        }
                      },
                      testId: 'service-delivery-contact-btn'
                    }
                  ]
                },
                onSelectionChange: (selectedRow) =>
                  handleRowCheckBoxSelection(selectedRow),
                selectionInfoMessage: t('common:data_table_warning_msg'),
                primaryKey: 'serial_number'
              }
            : undefined
        }
        summary={{
          entityName: t('devices')
        }}
        testId="activate-devices-table"
      />
      {moveToFolderModal && (
        <MoveToFolderModal
          onSetOpen={setMoveToFolderModal}
          openConfirmationModal={(data) => {
            setFolderData(data)
            setConfirmationModal(true)
          }}
          count={reqData?.length}
          customerId={customerData.id}
          isCCSManager
        />
      )}
      {unclaimDeviceModal && (
        <UnclaimDeviceModal
          onSetOpen={setUnclaimDeviceModal}
          count={reqData?.length}
          customerId={customerData?.id || reqData[0]?.platform_customer_id}
          selectedDevices={reqData}
          isCCSManager
          openSuccessModal={() => {
            setSuccessMessage(t('devices_unclaimed_successfully'))
          }}
        />
      )}
      {exportDevicesModal && (
        <ExportModal
          customerData={customerData}
          onSetOpen={setExportDevicesModal}
          selectedDevices={reqData}
        />
      )}
      {confirmationModal && (
        <ConfirmationModal
          onSetOpen={setConfirmationModal}
          folderData={folderData}
          data={reqData}
          refreshDataTable={() => refreshDataTable()}
          setErrorMessage={setErrorMessage}
          customerId={customerData.id}
        />
      )}
      {openManageLocationModal && (
        <ManageLocationModal
          setModalOpen={setOpenManageLocationModal}
          datum={reqData}
          openSuccessModal={() => {
            setSuccessMessage(t('device_updated_successfully'))
          }}
          isCCSManager
          customerId={customerData?.id}
        />
      )}
      {openServiceDeliveryModal && (
        <ServiceDeliveryContactModal
          setModalOpen={setOpenServiceDeliveryModal}
          datum={reqData}
          openSuccessModal={() => {
            setSuccessMessage(t('device_updated_successfully'))
          }}
          isCCSManager
          customerId={customerData?.id}
        />
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical color="text-strong" />}
          onClose={() => setErrorMessage(null)}
          position="top"
          testId="api-notification"
          text={errorMessage}
        />
      )}
      {successMessage &&
        displayNotification(successMessage, 'info', setSuccessMessage)}
    </Box>
  )
}

DevicesDataTable.propTypes = {
  refreshCount: PropTypes.number.isRequired,
  refreshDataTable: PropTypes.func.isRequired,
  customerData: PropTypes.object
}

export { DevicesDataTable }
