// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  Button
} from '../../../components'
import { validateUserInput } from '../../../utils/location-validation-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { get } from '../../../utils/api-utils'

import { addressTypeObject } from './types'
import { AddAddressModalContent } from './AddAddressModalContent'

const validateAddressField = (
  address,
  fieldErrors,
  setFieldErrors,
  t,
  isAddressFlagEnabled
) => {
  let isValidForm = true
  Object.keys(fieldErrors).forEach((key) => {
    // Street address2 is not required
    if (key !== 'street_address2' && address[key] === '') {
      setFieldErrors((prevState) => ({
        ...prevState,
        [key]: t('required')
      }))
      isValidForm = false
    }
    // validate street address, street address2, postalcode, city and state input for address
    if (
      (key === 'street_address2' ||
        key === 'street_address' ||
        key === 'postal_code' ||
        key === 'city' ||
        key === 'state') &&
      address[key] !== ''
    ) {
      const fieldValidation = validateUserInput(
        address[key],
        isAddressFlagEnabled
      )
      if (fieldValidation !== undefined) {
        setFieldErrors((prevState) => ({
          ...prevState,
          [key]: t(fieldValidation.message, {
            notAllowedChars: fieldValidation.notAllowedChars
          })
        }))
        isValidForm = false
      }
    }
  })
  return isValidForm
}

const AddAddressModal = ({
  handleClose,
  setStreetAddress,
  setShippingAddress,
  streetAddress,
  shippingAddress = null,
  addAddresses,
  isEdit,
  locationName = null
}) => {
  const { t } = useTranslation(['location'])
  const [listOfCountries, setListOfCountries] = useState([])

  const [streetFieldErrors, setStreetFieldErrors] = useState({
    country: '',
    street_address: '',
    street_address2: '',
    city: '',
    state: '',
    postal_code: ''
  })

  const [shippingFieldErrors, setShippingFieldErrors] = useState({
    country: '',
    street_address: '',
    street_address2: '',
    city: '',
    state: '',
    postal_code: ''
  })
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [loading, setLoading] = useState(true)
  const LDFlags = useFlags()

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        const { countries } = response.data
        const listOfCountryNames = []
        countries.forEach((country) => {
          listOfCountryNames.push(country.name)
        })
        setListOfCountries(listOfCountryNames)
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
  }, [t])

  return (
    <>
      {!loading && (
        <ModalDialog
          content={
            <Box margin={{ top: 'small' }} flex={{ shrink: 0 }}>
              <AddAddressModalContent
                listOfCountries={listOfCountries}
                setShippingAddress={setShippingAddress}
                setStreetAddress={setStreetAddress}
                streetAddress={streetAddress}
                shippingAddress={shippingAddress}
                streetFieldErrors={streetFieldErrors}
                setFieldErrors={setStreetFieldErrors}
                shippingFieldErrors={shippingFieldErrors}
                setShippingFieldErrors={setShippingFieldErrors}
              />
            </Box>
          }
          footer={
            <ModalFooter
              right={
                <Box direction="row" gap="small">
                  <Button
                    label={t('cancel')}
                    onClick={handleClose}
                    testId="add-address-cancel-btn"
                  />
                  <Button
                    label={isEdit ? t('save') : t('add_address')}
                    onClick={() => {
                      const isValidStreetAdd = validateAddressField(
                        streetAddress,
                        streetFieldErrors,
                        setStreetFieldErrors,
                        t,
                        LDFlags['glcp-sdi-address-doctor']
                      )
                      let isValidShippingAdd = true
                      if (shippingAddress !== null) {
                        isValidShippingAdd = validateAddressField(
                          shippingAddress,
                          shippingFieldErrors,
                          setShippingFieldErrors,
                          t,
                          LDFlags['glcp-sdi-address-doctor']
                        )
                      }
                      if (isValidStreetAdd && isValidShippingAdd) {
                        addAddresses()
                      }
                    }}
                    primary
                    testId="add-address-button"
                  />
                </Box>
              }
            />
          }
          header={
            <ModalHeader
              subtitle={!isEdit ? t('address_description') : ''}
              title={
                <>
                  <Typography size="small" testId="header-title" type="text">
                    {locationName || t('create_location')}
                  </Typography>
                  <Typography level={2} testId="header-title" type="heading">
                    {isEdit ? t('edit_address') : t('add_address')}
                  </Typography>
                </>
              }
            />
          }
          onClose={handleClose}
          testId={isEdit ? 'add-address-modal' : 'edit-address-modal'}
          width="large"
        />
      )}
      {showErrorNotification}
    </>
  )
}

AddAddressModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setStreetAddress: PropTypes.func.isRequired,
  setShippingAddress: PropTypes.func.isRequired,
  streetAddress: PropTypes.shape(addressTypeObject).isRequired,
  shippingAddress: PropTypes.shape(addressTypeObject),
  addAddresses: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  locationName: PropTypes.string
}

export { AddAddressModal }
