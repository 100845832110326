// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useRef, useEffect } from 'react'
import { Video } from 'grommet'
import PropTypes from 'prop-types'

const VideoComponent = ({ item, currentVideoPlaying = null, activeSlide }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    const videoElement = videoRef.current
    if (
      currentVideoPlaying?.current &&
      activeSlide !== currentVideoPlaying?.current.id
    ) {
      currentVideoPlaying?.current.pause()
    }
    currentVideoPlaying.current = videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlide])

  return (
    <Video
      ref={videoRef}
      fill="vertically"
      alignSelf="end"
      autoPlay={false}
      controls="over"
      id={activeSlide}
      data-testid={`video-${item.blogID}`}
      key={item}
    >
      <source src={item?.media} type={item?.mediaType} />
    </Video>
  )
}

VideoComponent.propTypes = {
  item: PropTypes.object.isRequired,
  currentVideoPlaying: PropTypes.object,
  activeSlide: PropTypes.number.isRequired
}

export default VideoComponent
