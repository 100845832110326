// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import {
  StatusCriticalSmall,
  StatusInfoSmall,
  StatusUnknownSmall,
  StatusWarningSmall
} from 'grommet-icons'
import { colors } from 'grommet-theme-hpe'

const SeverityIcon = ({ severity = undefined, size = undefined }) => {
  if (severity === 'critical') {
    return (
      <StatusCriticalSmall
        size={size}
        color={colors['status-critical'].light}
      />
    )
  }
  if (severity === 'warning') {
    return (
      <StatusWarningSmall size={size} color={colors['status-warning'].light} />
    )
  }
  if (severity === 'notice') {
    return <StatusInfoSmall size={size} color={colors.blue.light} />
  }
  return (
    <StatusUnknownSmall size={size} color={colors['status-unknown'].light} />
  )
}

SeverityIcon.propTypes = {
  severity: PropTypes.oneOf(['critical', 'warning', 'notice']),
  size: PropTypes.oneOf(['small', 'medium', 'large'])
}

export default SeverityIcon
