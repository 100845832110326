// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { localStorageWhitelistWithoutPII } from './local-storage-utils'
import { V2_LOGGER } from './log-util'

const accountRelatedSessionItems = new Map([
  [0, 'account'],
  [1, 'swapMspAccount'],
  [2, 'showMspFeature'],
  [3, 'numOfCustomerAccounts'],
  [4, 'orgLaunchInfo'],
  [5, 'core-data']
])

const sessionItemsToRemoveWhenSignOut = new Map([
  [0, 'numOfCustomerAccounts'],
  [1, 'cop-central-url'],
  [2, 'from-central'],
  [3, 'isFederated'],
  [4, 'redirect-query'],
  [5, 'callUpdateTimeline'],
  [6, 'lastInteractionTime'],
  [7, 'report-url'],
  [8, 'lastInteractionTimeBellNotification'],
  [9, 'lastInteractionTimeLayout'],
  [10, 'auditlog_context'],
  [11, 'current-lp-user'],
  [12, 'forcePasswordResetForCOP'],
  [13, 'redirectToLP'],
  [14, 'chatInputMessage']
])

// TODO: Remove this function
export const v2EndSessionCleanup = (logger) => {
  logger?.log({
    ...V2_LOGGER,
    msg: `clearing session storage`
  })
  sessionStorage.removeItem('account')
}

const excludeLocalStorageKeysToRemove = Object.values(
  localStorageWhitelistWithoutPII
)

export const cleanAccountSessionStorage = () => {
  accountRelatedSessionItems.forEach((value) => {
    sessionStorage.removeItem(value)
  })
  const tokenKey = Object.keys(sessionStorage).find((key) =>
    key.startsWith('oidc.user')
  )
  if (tokenKey) {
    sessionStorage.removeItem(tokenKey)
  }
}

const cleanSessionStorage = () => {
  cleanAccountSessionStorage()
  sessionItemsToRemoveWhenSignOut.forEach((value) => {
    sessionStorage.removeItem(value)
  })
}

const cleanLocalStorage = () => {
  const allLocalStorageItemKeys = Object.keys(localStorage)
  if (allLocalStorageItemKeys?.length > 0) {
    allLocalStorageItemKeys.forEach((itemKey) => {
      if (!excludeLocalStorageKeysToRemove.includes(itemKey)) {
        localStorage.removeItem(itemKey)
      }
    })
  }
}

export const cleanClientStorage = () => {
  cleanSessionStorage()
  cleanLocalStorage()
}
