// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { getLink, LINKS } from '../utils/links-util'

import { TilesRow } from './common/TilesRow'
import { CreateCaseModal } from './CreateCaseModal'
import { ViewCasesModal } from './ViewCasesModal'

const CaseManagementSection = memo(() => {
  const [showCreateCaseModal, setShowCreateCaseModal] = useState(false)
  const [showViewCasesModal, setShowViewCasesModal] = useState(false)
  const [currentLink, setCurrentLink] = useState('')
  const { t } = useTranslation(['support_hub'])
  const LDFlags = useFlags()

  // if glcp-support-cases LD flag is On, should open an internal link instead the modal
  const isCreateCaseFlowEnabled = LDFlags['glcp-support-cases']

  const caseManagementTiles = useMemo(
    () => [
      {
        id: 1,
        title: t('case_management_cards.create_case.title'),
        description: t('case_management_cards.create_case.description'),
        testId: 'create_cases-tile',
        href: isCreateCaseFlowEnabled
          ? getLink(LINKS.CASE_CREATION_URL)
          : undefined,
        onClick: !isCreateCaseFlowEnabled
          ? () => setShowCreateCaseModal(true)
          : undefined
      },
      {
        id: 2,
        title: t('case_management_cards.view_cases.title'),
        description: t('case_management_cards.view_cases.description'),
        testId: 'view_cases-tile',
        onClick: () => setShowViewCasesModal(true)
      },
      {
        id: 3,
        title: t('case_management_cards.contact_hpe_support.title'),
        description: t('case_management_cards.contact_hpe_support.description'),
        testId: 'contact-hpe-support-tile',
        href: getLink(LINKS.CONTACT_HPE_SUPPORT),
        target: LINKS.CONTACT_HPE_SUPPORT,
        isLinkExternal: true
      }
    ],
    [t, isCreateCaseFlowEnabled]
  )

  const caseList = useMemo(
    () => [
      {
        key: 1,
        label: t('modal_options.aruba_central'),
        value: LINKS.MODAL_LINKS.ARUBA_CENTRAL
      },
      {
        key: 2,
        label: t('modal_options.billing_metering_subscriptions.title'),
        value: LINKS.MODAL_LINKS.BILLING_METERING_SUBSCRIPTIONS
      },
      {
        key: 3,
        label: t('modal_options.compute_ops_management'),
        value: LINKS.MODAL_LINKS.COMPUTE_OPS_MANAGEMENT
      },
      {
        key: 4,
        label: t('modal_options.data_services.title'),
        value: LINKS.MODAL_LINKS.DATA_SERVICES
      },
      {
        key: 5,
        label: t('modal_options.hpe_greenlake_dev_portal'),
        value: LINKS.MODAL_LINKS.HPE_GREENLAKE_DEV_PORTAL
      },
      {
        key: 6,
        label: t('modal_options.hpe_greenlake_flex_solutions'),
        value: LINKS.MODAL_LINKS.HPE_GREENLAKE_FLEX_SOLUTIONS
      },
      {
        key: 7,
        label: t('modal_options.hpe_managed_cloud_services'),
        value: LINKS.MODAL_LINKS.HPE_MANAGED_CLOUD_SERVICES
      },
      {
        key: 8,
        label: t('modal_options.opsramp'),
        value: LINKS.MODAL_LINKS.OPSRAMP
      },
      {
        key: 9,
        label: t('modal_options.private_cloud'),
        value: LINKS.MODAL_LINKS.PRIVATE_CLOUD
      },
      {
        key: 10,
        label: t('modal_options.workspace_user_onboarding.title'),
        value: LINKS.MODAL_LINKS.WORKSPACE_USER_ONBOARDING
      },
      {
        key: 11,
        label: t('modal_options.other'),
        value: LINKS.MODAL_LINKS.OTHER
      }
    ],
    [t]
  )

  const viewcaseList = useMemo(() => {
    const extraMessages = {
      [LINKS.MODAL_LINKS.BILLING_METERING_SUBSCRIPTIONS]: {
        note1: t('modal_options.billing_metering_subscriptions.note1'),
        note2: t('modal_options.billing_metering_subscriptions.note2')
      },
      [LINKS.MODAL_LINKS.DATA_SERVICES]: {
        note1: t('modal_options.data_services.note1'),
        note2: t('modal_options.data_services.note2')
      },
      [LINKS.MODAL_LINKS.WORKSPACE_USER_ONBOARDING]: {
        note1: t('modal_options.workspace_user_onboarding.note1'),
        note2: t('modal_options.workspace_user_onboarding.note2')
      }
    }
    return caseList.map((caseOption) => {
      if (caseOption.value in extraMessages) {
        return {
          ...caseOption,
          ...extraMessages[caseOption.value]
        }
      }

      return caseOption
    })
  }, [t, caseList])

  const renderModals = () => {
    return (
      <>
        {showCreateCaseModal && (
          <CreateCaseModal
            onDialogChangeState={(modalState) => {
              setShowCreateCaseModal(modalState)
              setCurrentLink(null)
            }}
            onChangeDropdown={setCurrentLink}
            canContinue={!!currentLink}
            currentLink={getLink(currentLink, 'CREATE_CASE')}
            createCaseList={caseList}
          />
        )}
        {showViewCasesModal && (
          <ViewCasesModal
            onDialogChangeState={(modalState) => {
              setShowViewCasesModal(modalState)
              setCurrentLink(null)
            }}
            onChangeDropdown={setCurrentLink}
            canContinue={!!currentLink}
            currentLink={getLink(currentLink, 'VIEW_CASES')}
            viewCasesList={viewcaseList}
          />
        )}
      </>
    )
  }

  const tilesRow = useMemo(
    () => (
      <TilesRow
        title={t('case_management_cards.case_management')}
        testIdTitle="case_management-test-id"
        rows={caseManagementTiles}
      />
    ),
    [t, caseManagementTiles]
  )

  return (
    <>
      {tilesRow}
      {renderModals()}
    </>
  )
})

export { CaseManagementSection }
