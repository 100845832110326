// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { ButtonGroup } from '../../../components'
import { GREENLAKE_SUPPORT_URL } from '../../../utils/error-utils'

import { AddProductsTemplateHeader } from './AddProductsTemplateHeader'

const AddProductsEmptyTemplate = ({ title, description }) => {
  const { t } = useTranslation(['licensing', 'common'])
  const navigate = useNavigate()

  const buttonList = [
    {
      id: 1,
      label: t('common:contact_support'),
      secondary: true,
      testId: 'contact-support-btn',
      onClick: () => window.open(GREENLAKE_SUPPORT_URL, '_blank'),
      target: '_blank'
    },
    {
      id: 2,
      label: t('go_to_dashboard'),
      primary: true,
      testId: 'go-to-dashboard-btn',
      onClick: () => {
        navigate('/home')
      }
    }
  ]

  return (
    <Box align="center" gap="medium" pad={{ bottom: 'medium' }}>
      <AddProductsTemplateHeader
        header={t('add_products.add_products')}
        title={title}
        description={description}
      />

      <Box justify="end" direction="row" gap="medium">
        <ButtonGroup buttonList={buttonList} testId="empty-products-actions" />
      </Box>
    </Box>
  )
}

AddProductsEmptyTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export { AddProductsEmptyTemplate }
