// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { post } from '../../utils/api-utils'

/* This provider listens to and user feedback submitted in the
 * ContextualHelp panel and posts it to qualtrics via the ui-doorway.
 */

export const ContextualHelpFeedbackProvider = ({ children = undefined }) => {
  const { oidcUser } = useReactOidc()

  useEffect(() => {
    const onFeedback = (e) => {
      if (oidcUser?.access_token) {
        post(
          '/ui-doorway/ui/v1/qualtrics',
          e.detail,
          oidcUser.access_token
        ).then(
          (/* response */) => {},
          (/* error */) => {}
        )
      }
    }
    window.addEventListener('ch-feedback', onFeedback)
    return () => {
      window.removeEventListener('ch-feedback', onFeedback)
    }
  }, [oidcUser?.access_token])

  return <>{children}</>
}

ContextualHelpFeedbackProvider.propTypes = {
  children: PropTypes.any
}
