// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Anchor, Box, Grid, ResponsiveContext } from 'grommet'
import isEmpty from 'lodash/isEmpty'

import { get } from '../../../utils/api-utils'
import { Card, Button, Loader, Typography } from '../../../components'
import { useVisibilityContext } from '../../../context/visibility-context'
import VisibilityWrapper, {
  visibilityPermissions
} from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getCustomerAccount, isCoP } from '../../../utils/feature-flag-utils'
import { doLaunchAppAction } from '../utils'
import { LaunchAppModal } from '../components'
import {
  useAppCatalogContext,
  AppCatalogActions
} from '../../../context/app-catalog-context'
import { displayNotification } from '../../../utils/notificiation-utils'

import { FeatureAppsCardData } from './feature-apps-data'

export const FeatureAppCards = ({ setShowFeatured = undefined }) => {
  const [availableApps, setAvailableApps] = useState(null)
  const [launchModal, setLaunchModal] = useState(false)
  const [launchAppId, setLaunchAppId] = useState(null)
  const [load, setLoad] = useState(false)
  const { oidcUser } = useReactOidc()
  const { dispatchAppCatalogContext } = useAppCatalogContext()
  const [showNotification, setShowNotification] = useState(null)

  const navigate = useNavigate()
  const size = useContext(ResponsiveContext)
  const { t } = useTranslation(['common', 'apps'])

  const custAccountLoaded = getCustomerAccount()
  const accountType = custAccountLoaded?.account_type || ''

  const { hideWhen, rbacPolicies } = useVisibilityContext()
  const featuredAppsData = FeatureAppsCardData()
  const appsData =
    availableApps !== null &&
    rbacPolicies !== null &&
    featuredAppsData.filter((app) => {
      const { hideFor, rbac, visible } = app
      let result = visible
      if (result)
        result = visibilityPermissions({
          hideFor,
          hideWhen,
          rbac,
          rbacPolicies
        })
      return result
    })

  useEffect(() => {
    let isCurrent = true
    if (availableApps === null) {
      setLoad(true)
      get(
        `/ui-doorway/ui/v1/applications/provisions`,
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          if (!isCurrent) return
          const { provisions } = response.data
          setLoad(false)
          if (!isEmpty(provisions)) setAvailableApps(provisions)
        },
        (error) => {
          if (!isCurrent) return
          console.log(error)
          // no need to popup notifications, just set availableApps to []
          setAvailableApps([])
          setLoad(false)
        }
      )
    }
    return () => {
      isCurrent = false
    }
  }, [oidcUser, availableApps])

  const navToGetStarted = (item) => {
    if (
      !isEmpty(item.availApp?.name) &&
      !isEmpty(item.availApp?.company_name) &&
      !isEmpty(item.availApp?.application_id)
    ) {
      const appDetails = {
        logo: item.availApp?.logo || '',
        name: item.availApp?.name,
        companyName: item.availApp?.company_name
      }
      sessionStorage.setItem('appDetails', JSON.stringify(appDetails))
      dispatchAppCatalogContext({
        type: AppCatalogActions.SET_APP_DETAIL,
        data: appDetails
      })
      navigate(`/applications/app-details/${item.availApp.application_id}`)
    } else {
      // if can't get application detail data or no appid, popup error
      setShowNotification(
        displayNotification(
          t('apps:no_apps_available'),
          'error',
          setShowNotification
        )
      )
    }
  }
  const nativeAppCardClick = (item) => {
    const provApps = item.app ? item.app : []
    switch (provApps.length) {
      case 0:
        // navigation available apps detail page
        navToGetStarted(item)
        break
      case 1:
        // launch to app directly
        doLaunchAppAction(
          item.app[0]?.application_instance_id,
          oidcUser.access_token,
          item.app[0]?.application_customer_id,
          accountType,
          item.app[0]?.slug
        )
        break
      default:
        // popup Launch options modal
        setLaunchModal(true)
        setLaunchAppId(item.app[0]?.application_id)
    }
  }

  const handleNonNativeCardClick = (routeTo, hrefTo) => {
    if (routeTo) {
      navigate(routeTo)
    } else if (hrefTo) {
      window.open(hrefTo, '_blank')
    }
  }
  const getFeatureAppsCardPanel = (item) => {
    let description = t(item.description)
    let title = t(item.title)
    let buttonLabel = t(item.actionBtnLbl)
    const itemApp = availableApps.filter((application) => {
      // in COP we get slug as "COP" and only one app is present .i.e Aruba Central
      return (
        item.slug.includes(application.slug) ||
        (isCoP() && application?.slug === 'COP')
      )
    })
    if (!item.native || (item.native && !isEmpty(itemApp))) {
      item.availApp = itemApp[0] || null
      const provisionApp = itemApp.filter((application) => {
        return application.provision_status === 'PROVISIONED'
      })
      if (!isEmpty(provisionApp) || !item.native) {
        buttonLabel = t('apps:launch')
        item.app = provisionApp
        if (!isEmpty(item.app) && item.native) {
          title = item.app[0].name === '' ? t(item.title) : item.app[0].name
          description =
            item.app[0].description === ''
              ? t(item.description)
              : item.app[0].description
        }
      } else {
        item.app = []
        title = item.availApp?.name === '' ? t(item.title) : item.availApp?.name
        description =
          item.availApp?.description === ''
            ? t(item.description)
            : item.availApp?.description
        buttonLabel = t('common:get_started')
      }
      return (
        <VisibilityWrapper
          key={item.id}
          hideFor={item.hideFor}
          rbac={item.rbac}
        >
          <Card
            key={item.id}
            action={
              <Box fill="horizontal" gap="small">
                <Button
                  color={item.actionBtnColor}
                  label={buttonLabel}
                  icon={item.actionBtnIcon}
                  reverse
                  primary={Boolean(item.actionBtnColor)}
                  type="button"
                  testId={`${item.testId}-btn`}
                  onClick={() =>
                    item.native
                      ? nativeAppCardClick(item)
                      : handleNonNativeCardClick(item.routeTo, item.hrefTo)
                  }
                />
                {item.anchor && (
                  <Typography textAlign="center" type="text">
                    <Anchor
                      {...item.anchor}
                      label={t(item.anchor.label)}
                      color={item.foreground || 'text'}
                      size="xsmall"
                      target="_blank"
                    />
                  </Typography>
                )}
              </Box>
            }
            foregroundColor={item.foreground}
            align={item.align}
            background={item.background}
            cardWidth="100%"
            direction={item.direction}
            elevation={item.elevation}
            gap={item.gap}
            textAlign={item.textAlign}
            textAlignment={item.textAlignment}
            description={description}
            testId={item.testId}
            title={
              <Typography
                emphasis
                type="heading"
                level="3"
                testId={`${item.testId}-title`}
                color={item.foreground || 'text-strong'}
              >
                {title}
              </Typography>
            }
          />
        </VisibilityWrapper>
      )
    }
    return null
  }

  const primaryFeaturedIds = [1, 2, 3, 4]
  const secondaryFeaturedIds = [5, 6, 7, 8, 9, 10]

  const primaryApps =
    appsData &&
    appsData
      .filter((app) => primaryFeaturedIds.includes(app.id))
      .map((app) => getFeatureAppsCardPanel(app))
      .filter((app) => app !== null)

  const secondaryApps =
    appsData &&
    appsData
      .filter((app) => secondaryFeaturedIds.includes(app.id))
      .map((app) => getFeatureAppsCardPanel(app))
      .filter((app) => app !== null)

  // if there are no feature apps, setShowFeatured to false so no
  // child is rendered in the home page grid for FeatureAppCards.
  useEffect(() => {
    if (
      availableApps !== null &&
      rbacPolicies !== null &&
      appsData &&
      !primaryApps.length &&
      !secondaryApps.length
    ) {
      setShowFeatured(false)
    }
  }, [
    appsData,
    availableApps,
    primaryApps,
    secondaryApps,
    setShowFeatured,
    rbacPolicies
  ])

  const baseCardWidth = '240px' // small (192px) + medium horizontal pad (24px + 24px) = 240px

  const primaryGrid = {
    columns: {
      small: ['auto'],
      medium:
        primaryApps.length > 1
          ? {
              count: 2,
              size: 'auto'
            }
          : [baseCardWidth],
      large:
        primaryApps.length > 1
          ? {
              count: 2,
              size: 'auto'
            }
          : [baseCardWidth],
      xlarge:
        primaryApps.length > 1
          ? {
              count: primaryApps.length,
              size: 'auto'
            }
          : [baseCardWidth]
    },
    rows: ['auto'],
    gap: {
      small: 'medium',
      medium: 'medium',
      large: 'medium',
      xlarge: 'medium'
    }
  }

  const secondaryGrid = {
    columns: {
      small: ['auto'],
      medium: ['auto'],
      large: secondaryApps.length > 1 ? { count: 2, size: 'flex' } : ['auto'],
      xlarge: secondaryApps.length > 1 ? { count: 2, size: 'flex' } : ['auto']
    },
    rows: ['auto'],
    gap: 'medium'
  }

  return (
    <>
      {(primaryApps.length > 0 || secondaryApps.length) > 0 && (
        <>
          <Typography
            type="heading"
            level="2"
            testId="featured_applications"
            margin={{ bottom: 'medium', top: 'none' }}
          >
            {t('featured_applications')}
          </Typography>
          <Box gap="large">
            <Grid
              columns={primaryGrid.columns[size]}
              gap={primaryGrid.gap[size]}
            >
              {primaryApps}
            </Grid>
            <Grid columns={secondaryGrid.columns[size]} gap="medium">
              {secondaryApps}
            </Grid>
          </Box>
        </>
      )}
      {load && (
        <Box
          height="xsmall"
          width="xsmall"
          align="center"
          justify="center"
          alignSelf="center"
        >
          <Loader testId="loader-spinner" />
        </Box>
      )}
      {launchModal && (
        <LaunchAppModal
          handleClose={() => setLaunchModal(false)}
          appId={launchAppId}
        />
      )}
      {showNotification}
    </>
  )
}

FeatureAppCards.propTypes = {
  setShowFeatured: PropTypes.func
}
