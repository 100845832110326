// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { isServiceCentric } from '../../utils/account-utils'
import { displayDeviceTypeEnum } from '../../utils/common-utils'
import {
  getCustomerAccountType,
  isCoP,
  isGLOP
} from '../../utils/feature-flag-utils'
import {
  VGW_DEVICE_MODEL,
  VGW_DEVICE_PARTNUM
} from '../../utils/dm-sm-common-utils'

import {
  BAAS_ATTR_VALUE,
  getDeviceModalForSubscription,
  getDeviceTypeForSubscription
} from './utils'

// To display Assign to application/service action under StandAlone/COI workspace
export const showAssignToAppBtn = (selectedRows, viewData) => {
  return selectedRows?.every((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    return (
      result &&
      !(
        result?.device_model === VGW_DEVICE_MODEL ||
        result?.part_number === VGW_DEVICE_PARTNUM
      ) &&
      !result?.application_instance_id &&
      !result?.on_prem_workspace_rid
    )
  })
}

// To display Assign Devices/Services action under MSP workspace
export const showAssignToAccountBtn = (selectedRows, viewData) => {
  return selectedRows?.every((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    return (
      result &&
      !(
        result?.device_model === VGW_DEVICE_MODEL ||
        result?.part_number === VGW_DEVICE_PARTNUM
      ) &&
      !result?.application_instance_id &&
      !result?.tenant_platform_customer_id &&
      !result?.on_prem_workspace_rid
    )
  })
}

// To display Remove application/Services action
export const showUnassignToAppBtn = (selectedRows, viewData) => {
  return selectedRows?.every((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    return (
      result &&
      !(
        result?.device_model === VGW_DEVICE_MODEL ||
        result?.part_number === VGW_DEVICE_PARTNUM
      ) &&
      result?.application_instance_id
    )
  })
}

// To display Replace Subscription action
export const showReplaceSubsciptionBtn = (
  selectedRows,
  viewData,
  glcpReplaceSubscription
) => {
  return (
    glcpReplaceSubscription &&
    selectedRows?.every((value) => {
      const result = viewData?.find((obj) => {
        return obj?.unique_key === value
      })
      return (
        result &&
        result?.subscriptions?.length === 1 &&
        result?.subscriptions[0]?.key_type === 'BASE'
      )
    })
  )
}
// To display Apply Subscriptions action
export const showApplySubsciptionBtn = (
  selectedRows,
  viewData,
  glcpMultiLicenseSupport = false,
  isAddOnSupported = false
) => {
  return selectedRows?.every((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    return (
      result &&
      !(
        result?.device_model === VGW_DEVICE_MODEL ||
        result?.part_number === VGW_DEVICE_PARTNUM ||
        ([
          displayDeviceTypeEnum.COMPUTE,
          displayDeviceTypeEnum.DHCI_COMPUTE
        ]?.includes(result?.display_device_type) &&
          result?.iaas === true)
      ) &&
      result?.application_instance_id &&
      (glcpMultiLicenseSupport &&
      selectedRows.length === 1 &&
      result?.subscriptions?.length
        ? isAddOnSupported
        : !result?.subscription_tier)
    )
  })
}
// To display Detach Subscriptions action
export const showDetachSubscriptionBtn = (selectedRows, viewData, LDFlags) => {
  return selectedRows?.every((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    const deviceAttribute =
      result?.extra_attributes?.length &&
      result?.extra_attributes?.find(
        (attr) =>
          attr?.name === BAAS_ATTR_VALUE ||
          attr?.attribute_type === BAAS_ATTR_VALUE
      )
    return (
      result &&
      !(
        result?.device_model === VGW_DEVICE_MODEL ||
        result?.part_number === VGW_DEVICE_PARTNUM ||
        ([
          displayDeviceTypeEnum.COMPUTE,
          displayDeviceTypeEnum.DHCI_COMPUTE
        ]?.includes(result?.display_device_type) &&
          result?.iaas === true)
      ) &&
      result?.application_instance_id &&
      ([
        displayDeviceTypeEnum.STORAGE,
        displayDeviceTypeEnum.DHCI_STORAGE
      ].includes(result?.display_device_type)
        ? result?.subscription_tier &&
          ((LDFlags['glcp-capex-subscription'] ? true : deviceAttribute) ||
            isGLOP() ||
            result?.iaas)
        : result?.subscription_tier)
    )
  })
}

// To display Delete Devices action under COP
export const showDeleteBtn = (selectedRows, viewData) => {
  return selectedRows?.every((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    return (
      result &&
      !(
        result?.device_model === VGW_DEVICE_MODEL ||
        result?.part_number === VGW_DEVICE_PARTNUM
      ) &&
      result?.application_instance_id &&
      !result?.subscription_tier
    )
  })
}

// To display Archive Devices action
export const showArchiveBtn = (selectedRows, viewData, hideForOnPremDevice) => {
  return selectedRows?.every((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    return (
      result &&
      !(
        result?.device_model === VGW_DEVICE_MODEL ||
        result?.part_number === VGW_DEVICE_PARTNUM
      ) &&
      (result?.archived === false || result?.archieved === false) &&
      (hideForOnPremDevice ? !result?.on_prem_workspace_rid : true)
    )
  })
}

// To display Unarchive Devices action
export const showUnarchiveBtn = (selectedRows, viewData) => {
  return selectedRows?.every((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    return (
      result &&
      !(
        result?.device_model === VGW_DEVICE_MODEL ||
        result?.part_number === VGW_DEVICE_PARTNUM
      ) &&
      (result?.archived === true || result?.archieved === true)
    )
  })
}

// To hide Replace Subscription action if any IaaS device is selected
export const isIaasSelected = (selectedRows, viewData) => {
  return selectedRows?.some((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    return result?.iaas
  })
}

// To hide Apply/Detach Subscription action if both IaaS & Non IaaS devices is selected
export const areIaasAndNonIaasSelected = (selectedRows, viewData) => {
  let countIaas = 0
  let countNonIaas = 0
  selectedRows?.forEach((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    if (result?.iaas) countIaas += 1
    else countNonIaas += 1
  })
  if (countIaas && countNonIaas) return true
  return false
}

// To check if selected devices has IaaS devices
export const isIaas = (selectedRows, viewData) => {
  let countIaas = 0
  selectedRows?.forEach((value) => {
    const result = viewData?.find((obj) => {
      return obj?.unique_key === value
    })
    if (result?.iaas) countIaas += 1
  })
  return !!countIaas
}

// To format app assignment required devices & navigate into Assign To Application Wizard
export const handleAssignAppAction = ({
  selectedRows,
  viewData,
  LDFlags,
  navigate
}) => {
  const isPceCustomer = getCustomerAccountType() === 'BASIC_ORGANIZATION'
  const {
    'glcp-dm-pce': dmPCEFlag // PCE LD is added to check PCE workflow in StandAlone Account just for testing purpose untill all services support PCE changes
  } = LDFlags
  let deviceDetail = viewData?.filter((value) => {
    return selectedRows.includes(value.unique_key)
  })
  if (selectedRows.length > 0) {
    deviceDetail = deviceDetail.reduce((r, a) => {
      const deviceType = a?.display_device_type
      if (
        deviceType === displayDeviceTypeEnum?.PCE &&
        !(isPceCustomer || dmPCEFlag)
      )
        return r
      if (Object.values(displayDeviceTypeEnum)?.includes(deviceType)) {
        r[deviceType] = r[deviceType] || []
        r[deviceType].push({
          serial_number: a.serial_number,
          device_type: a.device_type,
          part_number: a.part_number
        })
      } else console.error('Invalid device type', a) // To debug if it breaks in future
      return r
    }, Object.create(null))
  }
  sessionStorage.setItem('deviceDetail', JSON.stringify(deviceDetail))
  navigate('/devices/inventory/assign-to-application')
}

const buildSubscriptionDevices = (
  selectedRows,
  viewData,
  t,
  LDFlags,
  dmPCEFlag
) => {
  const subscriptionDevices = selectedRows.reduce((index, value) => {
    const result = viewData.find((obj) => {
      return obj?.unique_key === value
    })

    // if device type is PCE but workspace is not PCE, do not consider device for apply subscription
    if (
      result?.display_device_type === displayDeviceTypeEnum?.PCE &&
      !dmPCEFlag
    )
      return index

    if (
      Object.values(displayDeviceTypeEnum).includes(result?.display_device_type)
    ) {
      const deviceType = getDeviceTypeForSubscription(
        result.display_device_type
      )
      index[deviceType] = index[deviceType] || {}
      const deviceModel = getDeviceModalForSubscription(
        result,
        LDFlags,
        deviceType
      )
      index[deviceType][deviceModel] = index[deviceType][deviceModel] || []
      index[deviceType][deviceModel].push({
        serial_number: result.serial_number,
        part_number: result.part_number,
        device_type: result.device_type,
        application_name: result.application_name,
        device_resource_id: result.resource_id,
        subscriptions: result.subscriptions
      })
      if (deviceType === 'COMPUTE') {
        index.platformSubCatEnum = index.platformSubCatEnum || {}
        index.platformSubCatEnum[
          result.platform_subscription_category || 'ALL'
        ] = result.platform_subscription_category_description || t('all_models')
      }
    } else console.error('Invalid device type', result) // To debug if it breaks in future

    return index
  }, Object.create(null))
  return subscriptionDevices
}

// To format subscription required devices & navigate into Apply Subscription Wizard
export const handleApplySubscriptionAction = ({
  selectedRows,
  viewData,
  t,
  LDFlags,
  navigate
}) => {
  const {
    'glcp-dm-pce': dmPCEFlag // PCE LD is added to check PCE workflow in StandAlone Account just for testing purpose untill all services support PCE changes
  } = LDFlags
  const subscriptionDevices = buildSubscriptionDevices(
    selectedRows,
    viewData,
    t,
    LDFlags,
    dmPCEFlag
  )
  sessionStorage.setItem(
    'subscriptionDevices',
    JSON.stringify(subscriptionDevices)
  )
  sessionStorage.setItem('IsIaas', isIaas(selectedRows, viewData))
  navigate('/devices/inventory/apply-subscription')
}
export const handleReplaceSubscriptionAction = ({
  selectedRows,
  viewData,
  navigate,
  t,
  LDFlags
}) => {
  const subscriptionDevices = buildSubscriptionDevices(
    selectedRows,
    viewData,
    t,
    LDFlags
  )
  sessionStorage.setItem(
    'subscriptionDevices',
    JSON.stringify(subscriptionDevices)
  )
  sessionStorage.setItem('IsIaas', isIaas(selectedRows, viewData))
  navigate('/devices/inventory/replace-subscription')
}

export const getProgressModalTitle = (t, action) => {
  const PROGRESS_MODAL_TITLE = {
    ARCHIVE: t('archive_title'),
    UNARCHIVE: t('unarchive_title'),
    REMOVE_ASSIGNMENT: t('devices_unassigned'),
    DETACH_SUBSCRIPTION: t('detach_subscription'),
    LOCATION_ASSIGNMENT: t('manage_location'),
    SERVICE_DELIVERY_CONTACT_ASSIGNMENT: t('service_delivery_contact'),
    DELETE_DEVICES: t('delete_devices')
  }
  return PROGRESS_MODAL_TITLE[action]
}

// To format sdi (locations & service delivery contact) specific request data
export const buildSdiData = ({ selectedRows, viewData, LDFlags }) => {
  const { 'glcp-un-assign-location': glcpUnAssignLocation } = LDFlags
  if (selectedRows?.length) {
    const deviceInfo = viewData?.filter((value) => {
      return selectedRows?.includes(value?.unique_key)
    })
    const modifiedDeviceInfo = deviceInfo?.reduce((acc, curr) => {
      acc.push({
        serial_number: curr?.serial_number,
        mac_address: curr?.mac_address,
        part_number: curr?.part_number,
        device_type: curr?.device_type,
        display_device_type: curr?.display_device_type,
        ...(glcpUnAssignLocation
          ? {
              location_id: curr?.location_id,
              location_name: curr?.location_name,
              contact_name: curr?.contact_name,
              contact_id: curr?.contact_id,
              contact_type: curr?.contact_type
            }
          : {})
      })
      return acc
    }, [])
    return modifiedDeviceInfo || []
  }
  return []
}

// To format multi license support detach (base or add-on) request data

export const buildDetachData = ({ selectedRows, viewData }) => {
  if (selectedRows?.length) {
    const deviceInfo = viewData?.filter((value) => {
      return selectedRows?.includes(value?.unique_key)
    })
    const modifiedDeviceInfo = deviceInfo?.reduce((acc, curr) => {
      acc.push({
        serial_number: curr?.serial_number,
        part_number: curr?.part_number,
        device_type: curr?.device_type,
        subscriptions: curr?.subscriptions,
        device_resource_id: curr?.resource_id,
        subscription_resource_ids: []
      })
      return acc
    }, [])
    return modifiedDeviceInfo || []
  }
  return []
}

export const showAssignToOnPremWorkspaceBtn = (
  selectedRows,
  viewData,
  onPremWorkSpaceData
) => {
  if (onPremWorkSpaceData?.length) {
    return !selectedRows.some((row) => {
      const correspondingRow = viewData?.find((obj) => obj?.unique_key === row)
      return correspondingRow?.on_prem_workspace_rid
    })
  }
  return false
}

// Devices action used in both devices datatable & device detail page
// Add an new entry under handleActionsOnclick function inside parent for any new actions addition
export const devicesActionsOptions = ({
  t,
  selectedRows,
  viewData,
  LDFlags,
  mode,
  handleActionsOnclick,
  onPremWorkSpaceData,
  rbacPolicies,
  isAddOnSupported
}) => {
  const {
    'glcp-dm-iaas': dmIaaSFlag,
    'glcp-multi-license-support': glcpMultiLicenseSupport,
    'glcp-replace-subscription': glcpReplaceSubscription
  } = LDFlags
  const newDashboardFlag = isServiceCentric(LDFlags)

  const getDIExportPermission = () => {
    const platformCustomerEdit =
      rbacPolicies?.effects?.['/ccs/accounts/platform/customer']?.[
        'ccs.accounts.platform.customer.edit'
      ]
    const reportingEdit =
      rbacPolicies?.effects?.['/ccs/reporting/dashboard']?.[
        'ccs.reporting.edit'
      ]
    return platformCustomerEdit && reportingEdit
  }

  const DEVICES_ACTIONS_BUTTON = {
    ARCHIVE: {
      label: t('archive_text'),
      onClick: () => handleActionsOnclick('ARCHIVE'),
      hidden:
        !showArchiveBtn(selectedRows, viewData, true) ||
        (dmIaaSFlag && isIaas(selectedRows, viewData)),
      testId: 'archive-btn'
    },
    UNARCHIVE: {
      label: t('unarchive_text'),
      onClick: () => handleActionsOnclick('UNARCHIVE'),
      hidden:
        !showUnarchiveBtn(selectedRows, viewData) ||
        (dmIaaSFlag && isIaas(selectedRows, viewData)),
      testId: 'unarchive-btn'
    },
    APP_ASSIGNMENT: {
      label: t(
        newDashboardFlag || isGLOP()
          ? 'assign_to_service'
          : 'assign_to_application'
      ),
      onClick: () => handleActionsOnclick('APP_ASSIGNMENT'),
      hidden:
        !showArchiveBtn(selectedRows, viewData) ||
        !showAssignToAppBtn(selectedRows, viewData),
      visibility: {
        hideFor: {
          account: ['MSP'],
          deployment: ['COP']
        }
      },
      testId: 'assign-to-application-btn'
    },
    APP_ACCOUNT_ASSIGNMENT: {
      label: t('assign_devices'),
      onClick: () => handleActionsOnclick('APP_ACCOUNT_ASSIGNMENT'),
      hidden:
        !showArchiveBtn(selectedRows, viewData) ||
        !showAssignToAccountBtn(selectedRows, viewData),
      visibility: {
        hideFor: {
          account: ['STANDALONE', 'TENANT', 'BASIC_ORGANIZATION'],
          deployment: ['COP']
        }
      },
      testId: 'assign-to-account-app-btn'
    },
    REMOVE_ASSIGNMENT: {
      label: t('remove_assignment'),
      onClick: () => handleActionsOnclick('REMOVE_ASSIGNMENT'),
      hidden:
        !showUnassignToAppBtn(selectedRows, viewData) ||
        (dmIaaSFlag && isIaas(selectedRows, viewData)),
      visibility: {
        hideFor: {
          deployment: ['COP']
        }
      },
      testId: 'unassign-to-application-btn'
    },
    APPLY_SUBSCRIPTION: {
      label: t('apply_subscription'),
      onClick: () => handleActionsOnclick('APPLY_SUBSCRIPTION'),
      hidden:
        !showApplySubsciptionBtn(
          selectedRows,
          viewData,
          glcpMultiLicenseSupport,
          isAddOnSupported
        ) || areIaasAndNonIaasSelected(selectedRows, viewData),
      testId: 'apply-to-subscriptions-btn'
    },
    DETACH_SUBSCRIPTION: {
      label: t('detach_subscription'),
      onClick: () => handleActionsOnclick('DETACH_SUBSCRIPTION'),
      hidden:
        (glcpMultiLicenseSupport && mode === 'SINGLE') ||
        !showDetachSubscriptionBtn(selectedRows, viewData, LDFlags) ||
        (dmIaaSFlag && isIaas(selectedRows, viewData)),
      testId: 'detach-subscription-btn'
    },
    REPLACE_SUBSCRIPTION: {
      label: t('replace_subscription'),
      onClick: () => handleActionsOnclick('REPLACE_SUBSCRIPTION'),
      hidden:
        !showReplaceSubsciptionBtn(
          selectedRows,
          viewData,
          glcpReplaceSubscription
        ) || isIaasSelected(selectedRows, viewData),
      testId: 'replace-subscription-btn'
    },
    TAGS_ASSIGNMENT: {
      label: t('tags.manage_tags_btn'),
      onClick: () => handleActionsOnclick('TAGS_ASSIGNMENT'),
      visibility: {
        hideFor: {
          deployment: ['GLOP']
        }
      },
      testId: 'manage-tags-btn'
    },
    LOCATION_ASSIGNMENT: {
      label: t('manage_location'),
      onClick: () => handleActionsOnclick('LOCATION_ASSIGNMENT'),
      hidden: !showArchiveBtn(selectedRows, viewData),
      visibility: {
        hideFor: {
          deployment: ['COP', 'GLOP'],
          feature: 'glcp-dm-sdi'
        }
      },
      testId: 'manage-location-btn'
    },
    SERVICE_DELIVERY_CONTACT_ASSIGNMENT: {
      label: t('service_delivery_contact'),
      onClick: () =>
        handleActionsOnclick('SERVICE_DELIVERY_CONTACT_ASSIGNMENT'),
      hidden: !showArchiveBtn(selectedRows, viewData),
      visibility: {
        hideFor: {
          deployment: ['COP'],
          feature: 'glcp-dm-sdi'
        }
      },
      testId: 'service-delivery-contact-btn'
    },
    DELETE_DEVICES: {
      label: t('common:delete'),
      onClick: () => handleActionsOnclick('DELETE_DEVICES'),
      hidden: !isCoP() || !showDeleteBtn(selectedRows, viewData),
      testId: 'delete-btn'
    },
    EXPORT_DEVICES: {
      label: t('export'),
      onClick: () => handleActionsOnclick('EXPORT_DEVICES'),
      hidden: mode === 'SINGLE' || !getDIExportPermission(),
      visibility: {
        hideFor: { deployment: ['COP', 'GLOP'] }
      },
      testId: 'export-btn',
      selectionBased: false
    },
    ONPREM_WORKSPACE_ASSIGNMENT: {
      label: t('manage:onprem_workspace.assign_to_onprem'),
      onClick: () => handleActionsOnclick('ONPREM_WORKSPACE_ASSIGNMENT'),

      hidden:
        !showAssignToOnPremWorkspaceBtn(
          selectedRows,
          viewData,
          onPremWorkSpaceData
        ) || !showArchiveBtn(selectedRows, viewData),
      visibility: {
        hideFor: {
          deployment: ['COP', 'GLOP'],
          feature: 'glcp-dscc-dm-sm'
        }
      },
      testId: 'assign-to-onprem-workspace-btn'
    }
  }
  return [
    DEVICES_ACTIONS_BUTTON.ARCHIVE,
    DEVICES_ACTIONS_BUTTON.UNARCHIVE,
    DEVICES_ACTIONS_BUTTON.APP_ASSIGNMENT,
    DEVICES_ACTIONS_BUTTON.APP_ACCOUNT_ASSIGNMENT,
    DEVICES_ACTIONS_BUTTON.REMOVE_ASSIGNMENT,
    DEVICES_ACTIONS_BUTTON.APPLY_SUBSCRIPTION,
    DEVICES_ACTIONS_BUTTON.DETACH_SUBSCRIPTION,
    DEVICES_ACTIONS_BUTTON.REPLACE_SUBSCRIPTION,
    DEVICES_ACTIONS_BUTTON.TAGS_ASSIGNMENT,
    DEVICES_ACTIONS_BUTTON.LOCATION_ASSIGNMENT,
    DEVICES_ACTIONS_BUTTON.SERVICE_DELIVERY_CONTACT_ASSIGNMENT,
    DEVICES_ACTIONS_BUTTON.DELETE_DEVICES,
    DEVICES_ACTIONS_BUTTON.EXPORT_DEVICES,
    DEVICES_ACTIONS_BUTTON.ONPREM_WORKSPACE_ASSIGNMENT
  ]
}
