// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Text, Box, Tip, Anchor, Tag, Markdown } from 'grommet'
import {
  CircleInformation,
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusWarningSmall,
  StatusUnknownSmall
} from 'grommet-icons'
import { capitalize } from 'lodash'
import dayjs from 'dayjs'

import { ActionButton, Typography } from '../../components'
import VisibilityWrapper from '../visibility-wrapper/VisibilityWrapper'

import { targetMap, categoryColumns } from './constants'

export const isSameDomain = (url, type) => {
  const return_value = new URL(url).hostname === window.location.hostname

  if (type === 'target') {
    return return_value ? '_self' : '_blank'
  }
  return return_value ? undefined : 'noopener noreferrer'
}

export const getIcons = (status, iconSize, t, type) => {
  const severity = status
  let element
  switch (severity) {
    case 1:
      element = (
        <Box justify="center" margin={{ right: 'small' }}>
          <Tip content={t('notification.status_critical')}>
            <StatusCriticalSmall size={iconSize} color="status-critical" />
          </Tip>
        </Box>
      )
      break
    case 2:
      element = (
        <Box justify="center" margin={{ right: 'small' }}>
          <Tip content={t('notification.status_warning')}>
            <StatusWarningSmall size={iconSize} color="status-warning" />
          </Tip>
        </Box>
      )
      break
    case 3:
      element = (
        <Box justify="center" margin={{ right: 'small' }}>
          <Tip content={t('notification.status_ok')}>
            <StatusGoodSmall size={iconSize} color="status-ok" />
          </Tip>
        </Box>
      )
      break
    default:
      element = (
        <Box justify="center" margin={{ right: 'small' }}>
          <Tip
            content={
              type === 'ALERTSTASKS'
                ? t('notification.status_unknown')
                : t('notification.status_information')
            }
          >
            {type === 'ALERTSTASKS' ? (
              <StatusUnknownSmall size={iconSize} color="status-unknown" />
            ) : (
              <CircleInformation size={iconSize} />
            )}
          </Tip>
        </Box>
      )
  }
  return element
}

export const getNameForID = (idList, idNameMap) => {
  if (idNameMap) {
    const nameList = idList.map((val) => {
      if (idNameMap[val]) return idNameMap[val]
      return val
    })
    return nameList
  }
  return idList
}

export const getSeverity = (severityEnum, t) => {
  let element
  switch (severityEnum) {
    case 0:
      element = t('notification.status_information')
      break
    case 1:
      element = t('notification.status_critical')
      break
    case 2:
      element = t('notification.status_warning')
      break
    case 3:
      element = t('notification.status_ok')
      break
    default:
      element = t('notification.status_information')
  }
  return element
}
export const getApprovalState = (approvalState, t) => {
  let element
  switch (approvalState) {
    case 'PENDING':
      element = t('notification.approval.pending')
      break
    case 'APPROVED':
      element = t('notification.approval.approved')
      break
    case 'REJECTED':
      element = t('notification.approval.rejected')
      break
    case 'IN REVIEW':
      element = t('notification.approval.in_review')
      break
    default:
      element = '--'
  }
  return element
}

export const getState = (stateEnum) => {
  let element
  switch (stateEnum) {
    case 0:
      element = 'Information'
      break
    case 1:
      element = 'Active'
      break
    case 2:
      element = 'Resolved'
      break
    default:
      element = 'Information'
  }
  return element
}

export const getInternalState = (stateEnum, t) => {
  let element
  switch (stateEnum) {
    case 0:
      element = t('notification.scheduled')
      break
    case 1:
      element = t('notification.queued')
      break
    case 2:
      element = t('notification.expired')
      break
    case 3:
      element = t('notification.archived')
      break
    case 4:
      element = t('notification.deleted')
      break
    case 5:
      element = t('notification.active')
      break
    case 6:
      element = t('notification.expiring')
      break
    case 7:
      element = t('notification.archiving')
      break
    case 8:
      element = t('notification.deleting')
      break
    default:
      element = t('notification.active')
  }
  return element
}
export const displayDuration = (duration) => {
  const days = Math.floor(duration / (60 * 60 * 24))
  const hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60))
  const minutes = Math.floor((duration % (60 * 60)) / 60)
  const seconds = Math.floor(duration % 60)
  let ans = ''
  if (days) ans += `${days} days `
  if (hours || days) ans += `${hours} hours `
  if (minutes || hours || days) ans += `${minutes} minutes `
  if (seconds || minutes || hours || days) ans += `${seconds} seconds`
  return ans
}
export const getTarget = (targetArray, targetType, notificationData) => {
  let targetString = ''
  const accountTarget = notificationData?.account_roles
  if (
    targetType === 'ACCOUNTS' &&
    accountTarget?.length === targetArray?.length
  ) {
    accountTarget?.forEach((account) => {
      targetString = targetString.concat(
        account?.accountName ? account.accountName : account.id,
        ', '
      )
    })
    return targetString.slice(0, -2)
  }
  if (targetType !== 'PLATFORM') {
    targetArray?.forEach((target) => {
      if (targetType === 'CENTRALS') {
        targetString = targetString.concat(targetMap[target], ', ')
      } else if (targetType === 'SERVICES') {
        targetString = notificationData?.application_name
      } else {
        targetString = targetString.concat(target, ', ')
      }
    })
  } else {
    targetString = targetString.concat(targetMap[targetType], ', ')
  }
  return targetType === 'SERVICES' ? targetString : targetString.slice(0, -2)
}
export const getDetailsUrl = (t, notificationData, html, c) => {
  html.push(
    <Text weight="bold" data-testId="notification-detail-url-text" key={c}>
      {t(`notification.${c}`)}
    </Text>
  )
  if (notificationData?.url_text) {
    html.push(
      <Text data-testId={`${c}_value`} key={`${c}value`}>
        <Anchor
          href={notificationData?.url}
          target={isSameDomain(notificationData?.url, 'target')}
          rel={isSameDomain(notificationData?.url, 'rel')}
          color="text-strong"
        >
          {notificationData?.url_text}
        </Anchor>
      </Text>
    )
  } else {
    html.push(
      <Text testId={`notification-detail-${c}`} key={c}>
        <Anchor
          href={notificationData?.url}
          target={isSameDomain(notificationData?.url, 'target')}
          rel={isSameDomain(notificationData?.url, 'rel')}
          color="text-strong"
        >
          {notificationData?.url}
        </Anchor>
      </Text>
    )
  }
}

const getDetailsTarget = (
  t,
  notificationData,
  isAccountRole,
  isUserView,
  html,
  c
) => {
  html.push(
    <Typography
      id={`notification-detail-${c}`}
      key={c}
      type="text"
      size="small"
      color="black"
      weight="bold"
    >
      {t(`notification.${c}`)}
    </Typography>
  )
  if (
    isAccountRole &&
    !isUserView &&
    notificationData?.targetType === 'ACCOUNTS' &&
    notificationData?.account_roles
  ) {
    html.push(
      <Box direction="column">
        {notificationData?.account_roles?.map((accountData) => (
          <Box direction="column">
            <Text
              data-testid={`${accountData?.id}_value`}
              key={`${accountData?.id}value`}
              weight="bold"
            >
              {accountData?.accountName
                ? accountData?.accountName
                : accountData?.id}
            </Text>
            <Text
              data-testid={`${accountData?.id}_role_value`}
              key={`${accountData?.id}rolevalue`}
            >
              ({t('notification.roles')}:{' '}
              {accountData?.roles?.length > 0
                ? getNameForID(
                    accountData?.roles,
                    accountData?.accountRoleNames
                  )?.join(', ')
                : t('notification.all_roles')}
              )
            </Text>
          </Box>
        ))}
      </Box>
    )
  } else if (notificationData?.targetType === 'SERVICES') {
    html.push(
      <Text id={`${c}_value`} key={`${c}value`}>
        {notificationData?.application_name}
      </Text>
    )
  } else {
    html.push(
      <Text id={`${c}_value`} key={`${c}value`}>
        {getTarget(notificationData?.target_ids, notificationData?.targetType)}
      </Text>
    )
  }
}

export function isEmailNotification(is_email) {
  return is_email ? 'Yes' : 'No'
}

export const getDetails = (
  t,
  notificationData,
  colsDisplayed,
  isGlasgow,
  isAccountRole,
  isUserView,
  isEdit
) => {
  const html = []
  colsDisplayed.forEach((c) => {
    let v =
      c === ('severity' || 'state')
        ? toString(notificationData[c])
        : notificationData[c]
    if (c === 'is_banner') {
      v = notificationData?.is_banner ? 'Yes' : 'n/a'
    }

    if (v) {
      switch (c) {
        case 'source':
        case 'id':
        case 'owner':
          html.push(
            <Text weight="bold" testId={`notification-detail-${c}`} key={c}>
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {notificationData[c]}
            </Text>
          )
          break
        case 'target':
          getDetailsTarget(
            t,
            notificationData,
            isAccountRole,
            isUserView,
            html,
            c
          )
          break
        case 'url':
          getDetailsUrl(t, notificationData, html, c)
          break
        case 'regions':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.regions`)}
            </Text>
          )
          if (notificationData?.regions.length) {
            html.push(
              <Box direction="column" key={`${c}value`}>
                {notificationData?.regions?.map((region) => (
                  <Text testId={`${region}_value`} key={`${region}value`}>
                    {capitalize(region)}
                  </Text>
                ))}
              </Box>
            )
          } else {
            html.push(
              <Text id={`${c}_value`} key={`${c}value`}>
                All
              </Text>
            )
          }
          break

        case 'applicationInstances':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t('notification.instances')}
            </Text>
          )
          if (notificationData?.applicationInstances.length) {
            html.push(
              <Box direction="column" key={`${c}value`}>
                {getNameForID(
                  notificationData?.applicationInstances,
                  notificationData?.appInstanceNames
                )?.map((app) => (
                  <Text testId={`${app}_value`} key={`${app}value`}>
                    {capitalize(app)}
                  </Text>
                ))}
              </Box>
            )
          } else {
            html.push(
              <Text id={`${c}_value`} key={`${c}value`}>
                All
              </Text>
            )
          }
          break

        case 'roles':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.${c}`)}
            </Text>
          )
          if (notificationData?.roles.length) {
            html.push(
              <Box direction="column" key={`${c}value`}>
                {getNameForID(
                  notificationData?.roles,
                  notificationData?.appRoleNames
                )?.map((role) => (
                  <Text testId={`${role}_value`} key={`${role}value`}>
                    {capitalize(role)}
                  </Text>
                ))}
              </Box>
            )
          } else {
            html.push(
              <Text id={`${c}_value`} key={`${c}value`}>
                All
              </Text>
            )
          }
          break

        case 'severity':
          html.push(
            <Text
              weight="bold"
              id={`notification-detail-${c}`}
              key={c}
              data-testid="notification-severity"
            >
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Box
              direction="row"
              testId={`${c}_value`}
              key={`${c}value`}
              data-testid="notification-severity-value"
            >
              {getIcons(notificationData[c], 'small', t)}
              <Text margin={{ left: 'xsmall' }} key={`${c}_displayValue`}>
                {getSeverity(notificationData[c], t)}
              </Text>
            </Box>
          )
          break
        case 'state':
          html.push(
            <Text weight="bold" testId={`notification-detail-${c}`} key={c}>
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {isGlasgow && !isUserView && !isEdit
                ? getInternalState(notificationData?.internal_state, t)
                : getState(notificationData?.state)}
            </Text>
          )
          break
        case 'internal_state':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t('notification.notification_state')}
            </Text>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {getInternalState(notificationData?.internal_state, t)}
            </Text>
          )
          break
        case 'channel':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {notificationData[c].join(', ')}
            </Text>
          )
          break
        case 'is_banner':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Text data-testid={`${c}_value`} key={`${c}value`}>
              {v}
            </Text>
          )
          break

        case 'createdAt':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {notificationData?.approval_state || notificationData?.scheduled
                ? t(`notification.created_at`)
                : t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {`${dayjs(notificationData?.createdAt * 1000).format(
                'MM/DD/YYYY HH:mm:ss'
              )}`}
            </Text>
          )
          break

        case 'startTime':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.start_time`)}
            </Text>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {`${dayjs(notificationData?.startTime * 1000).format(
                'MM/DD/YYYY HH:mm:ss'
              )}`}
            </Text>
          )
          break

        case 'ttl':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {`${dayjs(notificationData?.ttl * 1000).format(
                'MM/DD/YYYY HH:mm:ss'
              )}`}
            </Text>
          )
          break

        case 'summary':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t('notification.banner_summary')}
            </Text>
          )
          html.push(
            <Text data-testid={`${c}_value`} key={`${c}value`}>
              {notificationData?.summary}
            </Text>
          )
          break
        case 'description':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t('notification.banner_additional_content')}
            </Text>
          )
          html.push(
            <Markdown
              components={{
                a: {
                  component: Anchor,
                  props: {
                    color: 'text-strong'
                  }
                }
              }}
              data-testid={`${c}_value`}
              key={`${c}value`}
            >
              {notificationData?.description}
            </Markdown>
          )
          break
        case 'is_email':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Text data-testid={`${c}_value`} key={`${c}value`}>
              {isEmailNotification(notificationData?.is_email)}
            </Text>
          )
          break
        case 'emailContentTitle':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.email_content_title`)}
            </Text>
          )
          html.push(
            <Text data-testid={`${c}_value`} key={`${c}value`}>
              {t('notification.email_header')}
              {notificationData?.emailContentTitle}
            </Text>
          )
          break
        case 'emailContentDetails':
          html.push(
            <Text
              weight="bold"
              data-testid={`notification-detail-${c}`}
              key={c}
            >
              {t(`notification.email_content_details`)}
            </Text>
          )
          html.push(
            <Markdown
              components={{
                a: {
                  component: Anchor,
                  props: {
                    color: 'text-strong'
                  }
                }
              }}
              data-testid={`${c}_value`}
              key={`${c}value`}
            >
              {notificationData?.emailContentDetails}
            </Markdown>
          )
          break

        default:
          html.push(<Text />)
      }
    }
  })

  return html
}

export const getHistoryDetails = (t, notificationData, colsDisplayed) => {
  const historyGrid = []
  colsDisplayed.forEach((columnName) => {
    let columnData = notificationData[columnName]

    if (columnName === ('severity' || 'state')) {
      if (columnName === 'severity') {
        if (notificationData?.severity || notificationData?.severity === 0) {
          columnData = toString(notificationData[columnName])
        }
      } else if (notificationData?.state || notificationData?.state === 0) {
        columnData = toString(notificationData[columnName])
      }
    }

    if (columnData) {
      switch (columnName) {
        case 'severity':
          historyGrid.push(
            <Text
              id={`notification-detail-${columnName}`}
              key={columnName}
              data-testid="notification-severity"
            >
              {t(`notification.${columnName}`)}:
            </Text>
          )
          historyGrid.push(
            <Box
              direction="row"
              testId={`${columnName}_value`}
              key={`${columnName}value`}
              data-testid="notification-severity-value"
            >
              {getIcons(notificationData[columnName], 'small', t)}
              <Text
                margin={{ left: 'xsmall' }}
                key={`${columnName}_displayValue`}
              >
                {getSeverity(notificationData[columnName], t)}
              </Text>
            </Box>
          )
          break
        case 'state':
          historyGrid.push(
            <Text testId={`notification-detail-${columnName}`} key={columnName}>
              {t(`notification.${columnName}`)}:
            </Text>
          )
          historyGrid.push(
            <Text testId={`${columnName}_value`} key={`${columnName}value`}>
              {getState(notificationData?.state)}
            </Text>
          )
          break
        case 'ttl':
          historyGrid.push(
            <Text testId={`notification-detail-${columnName}`} key={columnName}>
              {t(`notification.${columnName}`)}
            </Text>
          )
          historyGrid.push(
            <Text testId={`${columnName}_value`} key={`${columnName}value`}>
              {`${dayjs(notificationData?.ttl * 1000).format(
                'MM/DD/YYYY HH:mm:ss'
              )}`}
            </Text>
          )
          break

        case 'summary':
          historyGrid.push(
            <Text
              data-testid={`notification-detail-${columnName}`}
              key={columnName}
            >
              {t('notification.summary')}:
            </Text>
          )
          historyGrid.push(
            <Text
              data-testid={`${columnName}_value`}
              key={`${columnName}value`}
            >
              {notificationData?.summary}
            </Text>
          )

          break
        case 'description':
          historyGrid.push(
            <Text
              data-testid={`notification-detail-${columnName}`}
              key={columnName}
            >
              {t('notification.additional_content')}:
            </Text>
          )
          historyGrid.push(
            <Box>
              <Markdown
                data-testid={`${columnName}_value`}
                key={`${columnName}value`}
              >
                {notificationData?.description}
              </Markdown>
            </Box>
          )

          break
        case 'emailContentTitle':
          historyGrid.push(
            <Text
              data-testid={`notification-detail-${columnName}`}
              key={columnName}
            >
              {t(`notification.email_content_title`)}:
            </Text>
          )
          historyGrid.push(
            <Text
              data-testid={`${columnName}_value`}
              key={`${columnName}value`}
            >
              {t('notification.email_header')}
              {notificationData?.emailContentTitle}
            </Text>
          )
          break
        case 'emailContentDetails':
          historyGrid.push(
            <Text
              data-testid={`notification-detail-${columnName}`}
              key={columnName}
            >
              {t(`notification.email_content_details`)}:
            </Text>
          )
          historyGrid.push(
            <Markdown
              data-testid={`${columnName}_value`}
              key={`${columnName}value`}
            >
              {notificationData?.emailContentDetails}
            </Markdown>
          )
          break

        default:
          historyGrid.push(<Text />)
      }
    }
  })

  return historyGrid
}
export const getDetailsForCategoryEvents = (
  t,
  notificationData,
  colsDisplayed,
  html
) => {
  colsDisplayed.forEach((c) => {
    const v = notificationData[c]
    if (v) {
      switch (c) {
        case 'service':
          html.push(
            <Typography
              level="2"
              size="18px"
              type="heading"
              testId={`notification-detail-${c}`}
            >
              {t(`notification.${c}`)}
            </Typography>
          )
          if (notificationData?.application_name) {
            html.push(
              <Typography
                level="3"
                size="medium"
                type="text"
                testId={`${c}_value`}
              >
                {notificationData?.application_name}
              </Typography>
            )
          } else {
            html.push(
              <Typography
                level="3"
                size="medium"
                type="text"
                testId={`${c}_value`}
              >
                {notificationData?.service}
              </Typography>
            )
          }
          break
        case 'topic':
          html.push(
            <Typography
              level="2"
              size="18px"
              type="heading"
              testId={`notification-detail-${c}`}
            >
              {t(`notification.topics`)}
            </Typography>
          )
          html.push(
            <Typography
              level="3"
              size="medium"
              type="text"
              testId={`${c}_value`}
            >
              {notificationData?.topic}
            </Typography>
          )
          break
        default:
          break
      }
    }
  })
}

export const getDetailsForCategory = (
  t,
  notificationData,
  colsDisplayed,
  isDynamic
) => {
  const html = []
  colsDisplayed.forEach((c) => {
    const v = notificationData[c]
    if (v) {
      switch (c) {
        case 'service':
          html.push(
            <Text weight="bold" testId={`notification-detail-${c}`} key={c}>
              {t(`notification.${c}`)}
            </Text>
          )
          if (notificationData?.application_name) {
            html.push(
              <Text testId={`${c}_value`} key={`${c}value`}>
                {isDynamic
                  ? notificationData?.application_name
                  : notificationData[c]}
              </Text>
            )
          } else {
            html.push(
              <Text testId={`${c}_value`} key={`${c}value`}>
                {isDynamic ? notificationData?.service : notificationData[c]}
              </Text>
            )
          }
          break

        default:
          html.push(
            <Text weight="bold" testId={`notification-detail-${c}`} key={c}>
              {t(`notification.${c}`)}
            </Text>
          )
          html.push(
            <Text testId={`${c}_value`} key={`${c}value`}>
              {notificationData[c]}
            </Text>
          )
      }
    }
  })

  return html
}
const ccsAdditionColumns = (t) => {
  return [
    {
      property: 'target',
      type: 'string',
      header: t('notification.target'),
      render: (datum) => (
        <Box width={{ max: 'medium' }}>
          <Typography size="medium" type="text">
            {(datum.targetType &&
              getTarget(datum?.target_ids, datum?.targetType, datum)) ||
              '--'}
          </Typography>
        </Box>
      )
    },
    {
      property: 'createdBy',
      type: 'string',
      header: t('notification.owner'),
      render: (datum) => (
        <Box width={{ max: 'medium' }}>
          <Typography size="medium" type="text">
            {datum?.createdBy || '--'}
          </Typography>
        </Box>
      )
    }
  ]
}
const getCcsManagerColumns = (t, isGlasgow) => {
  return [
    {
      property: 'state',
      type: 'string',
      header: t('notification.state'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          {isGlasgow ? (
            <Typography size="medium" type="text">
              {datum.internal_state
                ? getInternalState(datum.internal_state, t)
                : '--'}
            </Typography>
          ) : (
            <Typography size="medium" type="text">
              {datum.state ? getState(datum.state) : '--'}
            </Typography>
          )}
        </Box>
      )
    }
  ]
}

const getCcsManagerGlasgowColumns = (
  t,
  isEditEnabled,
  handleEditPublishedNotificationClick,
  setDeleteId,
  setOpenDeleteConfirmation
) => {
  return [
    {
      property: 'id',
      type: 'string',
      primary: true,
      render: () => <></>
    },
    {
      property: '',
      header: '',
      render: (data) => (
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.notification.edit',
            resource: '/ccs/notification'
          }}
        >
          {!(
            data?.internal_state === 2 ||
            data?.internal_state === 3 ||
            data?.internal_state === 4 ||
            data?.internal_state === 6 ||
            data?.internal_state === 7 ||
            data?.internal_state === 8
          ) && (
            <ActionButton
              showOneActionAsDropDown
              actions={
                isEditEnabled && data?.targetType !== 'APP_ROLES'
                  ? [
                      {
                        label: t('edit'),
                        testId: 'edit-all-published-action',
                        onClick: () => {
                          handleEditPublishedNotificationClick(data?.id)
                        }
                      },
                      {
                        label: t('delete_label'),
                        testId: 'delete-action',
                        onClick: () => {
                          setDeleteId(data?.id)
                          setOpenDeleteConfirmation(true)
                        }
                      }
                    ]
                  : [
                      {
                        label: t('delete_label'),
                        testId: 'delete-action',
                        onClick: () => {
                          setDeleteId(data?.id)
                          setOpenDeleteConfirmation(true)
                        }
                      }
                    ]
              }
              testId="admin-table-action-btn"
            />
          )}
        </VisibilityWrapper>
      )
    }
  ]
}

const getUserColumns = (t) => {
  return [
    {
      property: 'state',
      type: 'string',
      header: t('notification.state'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          <Typography size="medium" type="text">
            {datum.state ? getState(datum.state) : '--'}
          </Typography>
        </Box>
      )
    },
    {
      property: 'source',
      type: 'string',
      header: t('notification.owner'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          <Typography size="medium" type="text">
            {datum.source ? datum.source : '--'}
          </Typography>
        </Box>
      )
    }
  ]
}

const getReviewColumns = (t, isNotificationApprover) => {
  return [
    {
      property: 'state',
      type: 'string',
      header: isNotificationApprover
        ? t('notification.approval.approval_state')
        : t('notification.state'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          <Typography size="medium" type="text">
            {datum.approval_state ? datum.approval_state : '--'}
          </Typography>
        </Box>
      )
    },
    {
      property: 'source',
      type: 'string',
      header: t('notification.owner'),
      render: (datum) => (
        <Box width={{ max: 'small' }}>
          <Typography size="medium" type="text">
            {datum.createdBy ? datum.createdBy : '--'}
          </Typography>
        </Box>
      )
    },
    {
      property: 'createdAt',
      type: 'date',
      header: t('notification.approval.submit_date'),
      render: (datum) => (
        <Box width={{ max: 'medium' }} justify="end" align="end" fill>
          <Typography size="medium" type="text">
            {datum.createdAt
              ? dayjs(datum.createdAt * 1000).format('MM/DD/YYYY HH:mm:ss')
              : '--'}
          </Typography>
        </Box>
      ),
      align: 'end'
    },
    {
      property: 'id',
      type: 'string',
      primary: true,
      render: () => <></>
    }
  ]
}

const getNotificationInitiatorColumns = (
  t,
  handleEditNotificationRequestClick,
  setDeleteId,
  setOpenDeleteConfirmation
) => {
  return [
    {
      property: '',
      header: '',
      render: (data) => (
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.notification.edit',
            resource: '/ccs/notification'
          }}
        >
          <ActionButton
            actions={
              data?.approval_state === 'REJECTED'
                ? [
                    {
                      label: t('edit'),
                      testId: 'my-request-edit-action',
                      onClick: () => {
                        handleEditNotificationRequestClick(data?.id)
                      }
                    },
                    {
                      label: t('delete_label'),
                      testId: 'delete-action',
                      onClick: () => {
                        setDeleteId(data?.id)
                        setOpenDeleteConfirmation(true)
                      }
                    }
                  ]
                : [
                    {
                      label: t('delete_label'),
                      testId: 'delete-action',
                      onClick: () => {
                        setDeleteId(data?.id)
                        setOpenDeleteConfirmation(true)
                      }
                    }
                  ]
            }
            showOneActionAsDropDown
            testId="my-requests-table-action-btn"
          />
        </VisibilityWrapper>
      )
    }
  ]
}

const getApproverColumns = (
  t,
  setReviewRequestId,
  setOpenApproveConfirmation,
  setOpenRejectConfirmation
) => {
  return [
    {
      property: '',
      header: '',
      render: (data) => (
        <VisibilityWrapper
          rbac={{
            permission: 'ccs.notification.edit',
            resource: '/ccs/notification'
          }}
        >
          {(data?.approval_state === 'PENDING' ||
            data?.approval_state === 'IN REVIEW') && (
            <ActionButton
              actions={[
                {
                  label: t('notification.approval.approve'),
                  testId: 'approve-action',
                  onClick: () => {
                    setReviewRequestId(data?.id)
                    setOpenApproveConfirmation(true)
                  }
                },
                {
                  label: t('notification.approval.reject'),
                  testId: 'reject-action',
                  onClick: () => {
                    setReviewRequestId(data?.id)
                    setOpenRejectConfirmation(true)
                  }
                }
              ]}
              testId="admin-approval-tab-table-action-btn"
            />
          )}
        </VisibilityWrapper>
      )
    }
  ]
}

export const getColumnsBasedOnUser = (
  isCCSManager,
  t,
  isGlasgow,
  setOpenDeleteConfirmation,
  setDeleteId,
  isReviewNotificationDatatable,
  isNotificationInitiator,
  isNotificationApprover,
  isScheduled,
  setOpenApproveConfirmation,
  setOpenRejectConfirmation,
  setReviewRequestId,
  handleEditNotificationRequestClick,
  handleEditPublishedNotificationClick,
  isEditEnabled
) => {
  let columns = [
    {
      property: 'severity',
      type: 'string',
      header: t('notification.status'),
      render: (datum) => (
        <Box
          width={{ max: 'xsmall' }}
          margin={{ right: isCCSManager || 'medium' }}
          justify="between"
          align="center"
        >
          {getIcons(datum.severity, 'medium', t)}
        </Box>
      )
    },
    {
      property: 'summary',
      type: 'string',
      header: t('notification.content'),
      render: (datum) => (
        <Box
          width={{ max: 'medium' }}
          align="center"
          justify="between"
          direction="row"
        >
          {!datum.isRead && !isCCSManager ? (
            <Box
              align="center"
              justify="start"
              direction="row"
              height={{ max: 'xsmall', min: 'xxsmall' }}
            >
              <Text size="xlarge" weight="bold" margin={{ bottom: 'xsmall' }}>
                .
              </Text>
              <Text size="medium" margin={{ left: 'xsmall' }}>
                {datum.summary || '--'}
              </Text>
            </Box>
          ) : (
            <>
              {isEditEnabled && datum.updated_version > 0 && (
                <Tag value={t('notification.updated')} size="small" />
              )}
              <Typography
                size="medium"
                type="text"
                margin={{ left: 'xsmall' }}
                wordBreak="break-word"
              >
                {datum.summary || '--'}
              </Typography>
            </>
          )}
        </Box>
      )
    }
  ]
  const scheduledColumns = [
    {
      property: 'startTime',
      type: 'date',
      header: t('notification.start_time'),
      render: (datum) => (
        <Box width={{ max: 'medium' }} justify="end" align="end" fill>
          <Typography size="medium" type="text">
            {(datum.startTime &&
              dayjs(datum.startTime * 1000).format('MM/DD/YYYY HH:mm:ss')) ||
              '--'}
          </Typography>
        </Box>
      ),
      align: 'end'
    }
  ]
  const commonColumns = [
    {
      property: 'createdAt',
      type: 'date',
      header: t('notification.publish_date'),
      render: (datum) => (
        <Box width={{ max: 'medium' }} justify="end" align="end" fill>
          <Typography size="medium" type="text">
            {(datum.createdAt &&
              dayjs(datum.createdAt * 1000).format('MM/DD/YYYY HH:mm:ss')) ||
              '--'}
          </Typography>
        </Box>
      ),
      align: 'end'
    }
  ]
  const col_target = ccsAdditionColumns(t)[0]
  const owner_target = ccsAdditionColumns(t)[1]

  let ccsManagerColumns = [
    col_target,
    ...getCcsManagerColumns(t, isGlasgow),
    owner_target
  ]

  ccsManagerColumns = [...ccsManagerColumns, ...commonColumns]

  const ccsManagerGlasgowColumn = getCcsManagerGlasgowColumns(
    t,
    isEditEnabled,
    handleEditPublishedNotificationClick,
    setDeleteId,
    setOpenDeleteConfirmation
  )

  let userColumns = getUserColumns(t)

  userColumns = [...userColumns, ...commonColumns]

  const reviewDatatableColumns = getReviewColumns(t, isNotificationApprover)

  const notificationInitiatorActionColumn = getNotificationInitiatorColumns(
    t,
    handleEditNotificationRequestClick,
    setDeleteId,
    setOpenDeleteConfirmation
  )

  const approverActionColumn = getApproverColumns(
    t,
    setReviewRequestId,
    setOpenApproveConfirmation,
    setOpenRejectConfirmation
  )
  function decideColumns() {
    if (isNotificationInitiator) {
      columns = [
        ...columns,
        ...reviewDatatableColumns,
        ...notificationInitiatorActionColumn
      ]
    } else if (isScheduled) {
      columns = [
        ...columns,
        col_target,
        owner_target,
        ...scheduledColumns,
        ...ccsManagerGlasgowColumn
      ]
    } else if (isReviewNotificationDatatable) {
      columns = [...columns, ...reviewDatatableColumns]
    } else if (isNotificationApprover) {
      columns = [...columns, ...reviewDatatableColumns, ...approverActionColumn]
    } else if (isGlasgow) {
      columns = [...columns, ...ccsManagerColumns, ...ccsManagerGlasgowColumn]
    } else {
      columns = [...columns, ...ccsManagerColumns]
    }
    return columns
  }
  if (isCCSManager) {
    columns = decideColumns()
  } else {
    columns = [...columns, ...userColumns]
  }

  return columns
}

export const getTime = (state) => {
  const timeToReturn = {
    startTime: '',
    endTime: dayjs().unix()
  }

  const dayjsInstance = dayjs
  if (state && state.length > 0) {
    switch (state) {
      case 'Past 3 days': {
        timeToReturn.startTime = dayjsInstance().subtract(3, 'd').unix()
        break
      }
      case 'Past 24 hours': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'd').unix()
        break
      }
      case 'Past 7 days': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'w').unix()
        break
      }
      case 'Past 30 days': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'M').unix()
        break
      }
      default: {
        timeToReturn.startTime = dayjsInstance().subtract({ M: 3 }).unix()
        break
      }
    }
  }

  return timeToReturn
}

export const getDateTime = (state, val) => {
  const dayjsInstance = dayjs

  const timeToReturn = [
    dayjsInstance().subtract(3, 'M').unix(),
    dayjsInstance().unix()
  ]
  switch (state) {
    case 'Past 3 days': {
      timeToReturn[0] = dayjsInstance().subtract(3, 'd').unix()
      break
    }
    case 'Past 24 hours': {
      timeToReturn[0] = dayjsInstance().subtract(1, 'd').unix()
      break
    }
    case 'Past 7 days': {
      timeToReturn[0] = dayjsInstance().subtract(1, 'w').unix()
      break
    }
    case 'Past 30 days': {
      timeToReturn[0] = dayjsInstance().subtract(1, 'M').unix()
      break
    }
    case 'Custom Date Range': {
      if (val) {
        timeToReturn[0] = dayjsInstance(val[0]).startOf('day').unix()
        timeToReturn[1] = dayjsInstance(val[1]).endOf('day').unix()
      }
      break
    }
    default: {
      timeToReturn[0] = dayjsInstance().subtract(3, 'M').unix()
      timeToReturn[1] = dayjsInstance().unix()
      break
    }
  }

  return timeToReturn
}

export const getEnumValue = (selectedStatus) => {
  const severity = selectedStatus?.toUpperCase()
  let element
  switch (severity) {
    case 'INFORMATION':
      element = 0
      break
    case 'CRITICAL':
      element = 1
      break
    case 'WARNING':
      element = 2
      break
    case 'OK':
      element = 3
      break
    default:
      element = 0
  }
  return element
}

const filterStateMap = Object.freeze({
  ACTIVE: 1,
  IGNORED: 4,
  CLEARED: 3,
  INFORMATIONAL: 2,
  DEFAULT: 1
})

export const getAlertState = Object.freeze({
  1: 'ACTIVE',
  2: 'INFORMATIONAL',
  3: 'CLEARED',
  4: 'IGNORED'
})

const taskStateMap = Object.freeze({
  STARTING: 1,
  'IN-PROGRESS': 2,
  SUCCESS: 3,
  FAILURE: 4,
  DEFAULT: 0
})

export const getEnumValueAlertState = (selectedStatus) => {
  return selectedStatus?.map((state) => {
    const upperState = state?.toUpperCase()
    return filterStateMap[upperState] ?? filterStateMap.DEFAULT
  })
}

export const getEnumValueTaskState = (selectedStatus) => {
  return selectedStatus?.map((state) => {
    const upperState = state?.toUpperCase()
    return taskStateMap[upperState] ?? taskStateMap.DEFAULT
  })
}

export const getEnumValueState = (selectedStatus) => {
  selectedStatus =
    selectedStatus &&
    selectedStatus.map((state) => {
      state = state?.toUpperCase()
      let element
      //  LD Flag based condition
      switch (state) {
        case 'ACTIVE':
          element = 1
          break
        case 'RESOLVED':
          element = 2
          break
        default:
          element = 1
      }
      return element
    })
  return selectedStatus
}
export const getCategoryValue = (selectedCategory) => {
  selectedCategory =
    selectedCategory &&
    selectedCategory.map((category) => {
      return category.value
    })
  return selectedCategory
}

export const getWorkspacesId = (selectedWorkspaces = []) => {
  let selectedWorkspacesIds = []
  selectedWorkspacesIds = selectedWorkspaces.map((workspaces) => {
    return workspaces.value
  })
  return selectedWorkspacesIds
}

export const getEnumValueStatus = (selectedStatus) => {
  selectedStatus =
    selectedStatus &&
    selectedStatus.map((severity) => {
      severity = severity?.toUpperCase()
      let element
      switch (severity) {
        case 'INFORMATION':
          element = 0
          break
        case 'UNKNOWN':
          element = 0
          break
        case 'CRITICAL':
          element = 1
          break
        case 'WARNING':
          element = 2
          break
        case 'OK':
          element = 3
          break
        default:
          element = 0
      }
      return element
    })
  return selectedStatus
}
export const getRegionsForApp = (app, regions) => {
  let regionsForApp = []
  regions.forEach((appRegion) => {
    if (appRegion?.service === app) {
      regionsForApp = appRegion?.regions
    }
  })
  return regionsForApp
}

const requestType = Object.freeze({
  ALERT: 'ALERT',
  TASK: 'TASK'
})

export const getRequestType = (selectedType = []) => {
  const request_type = null
  if (selectedType.length === 1) {
    if (selectedType[0].value === 'Alerts') {
      return requestType.ALERT
    }
    return requestType.TASK
  }
  return request_type
}

export const getRegionOptions = (allRegionsForApp) => {
  const options = []
  allRegionsForApp.forEach((region) => {
    const optionId = region.replace(/\s+/g, '-').toLowerCase()
    const option = {
      label: region,
      id: optionId
    }
    options.push(option)
  })
  return options
}

export const getFilterAttributes = (t, stateV, dispatchNotifications, type) => {
  const defaultFilterValuesDate = [
    {
      name: 'Date Range',
      label: 'Date Range',
      selectionType: 'dropdown',
      updateValues: (filter) => {
        if (filter['Date Range'] === 'Custom Date Range') {
          dispatchNotifications({
            type: 'SHOW_DATE',
            showDate: true
          })
        } else if (filter['Date Range'] !== 'Custom Date Range') {
          dispatchNotifications({
            type: 'SHOW_DATE',
            showDate: false
          })
        }
      },
      values: [
        { valueName: 'All', valueLabel: t('notification.all') },
        {
          valueName: 'Past 24 hours',
          valueLabel: t('notification.24_hrs')
        },
        {
          valueName: 'Past 3 days',
          valueLabel: t('notification.3_days')
        },
        {
          valueName: 'Past 7 days',
          valueLabel: t('notification.7_days')
        },
        {
          valueName: 'Past 30 days',
          valueLabel: t('notification.30_days')
        },
        {
          valueName: 'Custom Date Range',
          valueLabel: t('notification.custom_date')
        }
      ]
    },
    ...(stateV.showDate
      ? [
          {
            name: 'Custom Date Range',
            label: 'Custom Date Range',
            selectionType: 'date-range',
            startDate: dayjs().subtract(90, 'd').toISOString(),
            endDate: dayjs().toISOString()
          }
        ]
      : [])
  ]

  if (type === 'ANNOUNCEMENTS') {
    return [
      {
        name: 'Categories',
        label: t('notification.categories'),
        selectionType: 'select-multiple',
        placeholder: t('notification.select_items'),
        searchPlaceholder: t('notification.searchPlaceholder'),
        values: stateV.categories
      },
      {
        name: 'Status',
        label: t('notification.status'),
        values: [
          { valueName: 'OK', valueLabel: t('notification.ok') },
          { valueName: 'Warning', valueLabel: t('notification.warning') },
          { valueName: 'Critical', valueLabel: t('notification.critical') },
          {
            valueName: 'Information',
            valueLabel: t('notification.information')
          }
        ]
      },
      {
        name: 'State',
        label: t('notification.state'),
        values: [
          { valueName: 'Active', valueLabel: t('notification.active') },
          { valueName: 'Resolved', valueLabel: t('notification.resolved') }
        ]
      },
      ...defaultFilterValuesDate
    ]
  }

  const defaultFilterValues = [
    {
      name: 'Categories',
      label: t('notification.categories'),
      help: t('notification.categories_info_alerts_page'),
      selectionType: 'select-multiple',
      placeholder: t('notification.select_items'),
      searchPlaceholder: t('notification.searchPlaceholder'),
      values: stateV.categories
    },
    {
      name: 'Workspaces', // add internationalizatio
      label: t('notification.filter_workspace_title'), // add internationalization
      selectionType: 'select-multiple',
      placeholder: t('notification.select_items'),
      searchPlaceholder: t('notification.searchPlaceholder'),
      values: stateV.workspaces
    },
    {
      name: 'Status',
      label: t('notification.status'),
      values: [
        { valueName: 'OK', valueLabel: t('notification.ok') },
        { valueName: 'Warning', valueLabel: t('notification.warning') },
        { valueName: 'Critical', valueLabel: t('notification.critical') },
        { valueName: 'Unknown', valueLabel: t('notification.status_unknown') } // add internationalization
      ]
    }
  ]

  const defaultFilterValuesAlert = [
    {
      name: 'AlertState',
      label: t('notification.alert_states_title'),
      values: [
        {
          valueName: 'Active',
          valueLabel: t('notification.alert_active')
        },
        {
          valueName: 'Cleared',
          valueLabel: t('notification.alert_cleared')
        },
        {
          valueName: 'Ignored',
          valueLabel: t('notification.alert_ignored')
        },
        {
          valueName: 'Informational',
          valueLabel: t('notification.status_informational')
        }
      ]
    }
  ]

  const defaultFilterValuesTasks = [
    {
      name: 'TaskState',
      label: t('notification.tasks_states_title'),
      values: [
        {
          valueName: 'Starting',
          valueLabel: t('notification.alerts_tasks.starting')
        },
        {
          valueName: 'In-progress',
          valueLabel: t('notification.task_inprogress')
        },
        {
          valueName: 'Success',
          valueLabel: t('notification.alerts_tasks.success')
        },
        {
          valueName: 'Failure',
          valueLabel: t('notification.alerts_tasks.failure')
        }
      ]
    }
  ]

  if (
    stateV.selectedType.length === 1 &&
    stateV.selectedType[0].value === 'Tasks'
  ) {
    return [
      ...defaultFilterValues,
      ...defaultFilterValuesTasks,
      ...defaultFilterValuesDate
    ]
  }
  if (
    stateV.selectedType.length === 1 &&
    stateV.selectedType[0].value === 'Alerts'
  ) {
    return [
      ...defaultFilterValues,
      ...defaultFilterValuesAlert,
      ...defaultFilterValuesDate
    ]
  }
  return [
    ...defaultFilterValues,
    ...defaultFilterValuesAlert,
    ...defaultFilterValuesTasks,
    ...defaultFilterValuesDate
  ]
}

export const getStateTasks = {
  1: 'notification.alerts_tasks.starting',
  2: 'notification.alerts_tasks.inprogress',
  3: 'notification.alerts_tasks.success',
  4: 'notification.alerts_tasks.failure'
}
export const getStateAlerts = {
  1: 'notification.alerts_tasks.active',
  2: 'notification.status_informational',
  3: 'notification.alerts_tasks.cleared',
  4: 'notification.alerts_tasks.ignored'
}
export const getSeverityIcons = (severityEnum, t) => {
  let element
  switch (severityEnum) {
    case 0:
      element = (
        <Typography
          icon={<StatusUnknownSmall size="18px" color="status-unknown" />}
          size="medium"
          type="text"
        >
          {t('notification.status_unknown')}
        </Typography>
      )
      break
    case 1:
      element = (
        <Typography
          icon={<StatusCriticalSmall color="status-critical" />}
          size="medium"
          type="text"
        >
          {t('notification.status_critical')}
        </Typography>
      )
      break
    case 2:
      element = (
        <Typography
          icon={<StatusWarningSmall color="status-warning" />}
          size="medium"
          type="text"
        >
          {t('notification.status_warning')}
        </Typography>
      )
      break
    case 3:
      element = (
        <Typography
          icon={<StatusGoodSmall color="status-ok" />}
          size="medium"
          type="text"
        >
          {t('notification.status_ok')}
        </Typography>
      )
      break
    default:
      break
  }
  return element
}

export const getSubTaskState = {
  0: 'notification.alerts_tasks.starting',
  1: 'notification.alerts_tasks.inprogress',
  2: 'notification.alerts_tasks.success',
  3: 'notification.alerts_tasks.failure'
}

export const getDetailsEvents = (t, notificationData, colsDisplayed) => {
  const html = []
  colsDisplayed?.forEach((c) => {
    switch (c) {
      case 'type':
        html.push(
          <Typography
            level="2"
            size="18px"
            type="heading"
            testId={`${c}_page-title`}
          >
            {t(`notification.alerts_tasks.${c}`)}
          </Typography>
        )
        html.push(
          <Typography level="3" size="medium" type="text" testId={`${c}_value`}>
            {notificationData?.eventType === 'ALERT' ? 'Alert' : 'Task'}
            {/* i18n  */}
          </Typography>
        )
        break
      case 'created_by':
        if (notificationData?.taskState) {
          html.push(
            <Typography
              level="2"
              size="18px"
              type="heading"
              testId={`${c}_page-title`}
            >
              {t(`notification.alerts_tasks.${c}`)}
            </Typography>
          )
          html.push(
            <Typography
              level="3"
              size="medium"
              type="text"
              testId={`${c}_value`}
            >
              {notificationData?.createdBy}
            </Typography>
          )
        }
        break
      case 'description':
        if (notificationData?.description) {
          html.push(
            <Typography
              level="2"
              size="18px"
              type="heading"
              testId={`${c}_page-title`}
            >
              {t(`notification.alerts_tasks.${c}`)}
            </Typography>
          )
          html.push(
            <Typography
              level="3"
              size="medium"
              type="text"
              testId={`${c}_value`}
            >
              {notificationData[c]}
            </Typography>
          )
        }
        break
      case 'workspace':
        html.push(
          <Typography
            level="2"
            size="18px"
            type="heading"
            testId={`${c}_page-title`}
          >
            {t(`notification.alerts_tasks.${c}`)}
          </Typography>
        )
        html.push(
          <Typography level="3" size="medium" type="text" testId={`${c}_value`}>
            {notificationData?.workspaceName}
          </Typography>
        )
        break
      case 'task_duration':
        if (notificationData?.taskState) {
          html.push(
            <Typography
              level="2"
              size="18px"
              type="heading"
              testId={`${c}_page-title`}
            >
              {t(`notification.alerts_tasks.${c}`)}
            </Typography>
          )
          html.push(
            <Typography
              level="3"
              size="medium"
              type="text"
              testId={`${c}_value`}
            >
              {displayDuration(
                notificationData?.updatedAt - notificationData?.createdAt
              ) || '-'}
            </Typography>
          )
        }
        break
      case 'updated_at':
        html.push(
          <Typography
            level="2"
            size="18px"
            type="heading"
            testId={`${c}_page-title`}
          >
            {t(`notification.alerts_tasks.${c}`)}
          </Typography>
        )
        html.push(
          <Typography level="3" size="medium" type="text" testId={`${c}_value`}>
            {`${dayjs(notificationData?.updatedAt * 1000).format(
              'MMM DD, YYYY hh:mm a'
            )}`}
          </Typography>
        )
        break
      case 'category':
        if (notificationData?.category) {
          getDetailsForCategoryEvents(
            t,
            notificationData?.category,
            categoryColumns,
            html
          )
        }
        break
      case 'severity':
        html.push(
          <Typography
            level="2"
            size="18px"
            type="heading"
            testId={`${c}_page-title`}
          >
            {t(`notification.alerts_tasks.${c}`)}
          </Typography>
        )
        html.push(
          <Box
            direction="row"
            testId={`${c}_value`}
            key={`${c}value`}
            data-testid="notification-severity-value"
          >
            <Typography
              icon={getIcons(notificationData[c], 'small', t)}
              level="3"
              size="medium"
              type="text"
              testId={`${c}_value`}
            >
              {getSeverity(notificationData[c], t)}
            </Typography>
          </Box>
        )
        break
      case 'alert_state':
        if (notificationData?.alertState) {
          html.push(
            <Typography
              level="2"
              size="18px"
              type="heading"
              testId={`${c}_page-title`}
            >
              {t(`notification.alerts_tasks.state`)}
            </Typography>
          )
          html.push(
            <Typography
              level="3"
              size="medium"
              type="text"
              testId={`${c}_value`}
            >
              {t(getStateAlerts[notificationData?.alertState])}
            </Typography>
          )
        }
        break

      case 'task_state':
        if (notificationData?.taskState) {
          html.push(
            <Typography
              level="2"
              size="18px"
              type="heading"
              testId={`${c}_page-title`}
            >
              {t(`notification.alerts_tasks.state`)}
            </Typography>
          )
          html.push(
            <Typography
              level="3"
              size="medium"
              type="text"
              testId={`${c}_value`}
            >
              {t(getStateTasks[notificationData?.taskState])}
            </Typography>
          )
        }
        break

      case 'created_at':
        html.push(
          <Typography
            level="2"
            size="18px"
            type="heading"
            testId={`${c}_page-title`}
          >
            {t(`notification.alerts_tasks.${c}`)}
          </Typography>
        )
        html.push(
          <Typography level="3" size="medium" type="text" testId={`${c}_value`}>
            {`${dayjs(notificationData?.createdAt * 1000).format(
              'MMM DD, YYYY hh:mm a'
            )}`}
          </Typography>
        )
        break
      default:
    }
  })

  return html
}
