// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { FormPrevious, CircleAlert } from 'grommet-icons'
import { PageContent, Page, Anchor, Box, PageHeader } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { Loader, NoDataInfo } from '../../components'
import { Layout } from '../../commoncomponents/layout/Layout'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { ChatBot } from '../support-chat/ChatBot'

import { searchEngine } from './common/Engine'
import SearchPage from './components/SearchPage/SearchPage'

const Search = () => {
  const [engine, setEngine] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['support_hub', 'support_hub_search'])
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        const searchInstance = await searchEngine(oidcUser?.access_token)
        if (isMounted) {
          setEngine(searchInstance)
        }
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
    return () => {
      isMounted = false
    }
  }, [oidcUser.access_token])

  let content = null
  if (loading) {
    content = (
      <Box align="center" justify="center">
        <Loader testId="search-page-loader" />
      </Box>
    )
  } else if (error) {
    content = (
      <Box align="center" margin={{ top: 'xlarge' }}>
        <NoDataInfo
          testId="search-page-error"
          title={t('support_hub_search:something_went_wrong_search')}
          subtitle={
            <Trans i18nKey="support_hub_search:error_message_search" t={t}>
              <Anchor
                label={t('support_hub:support_title')}
                weight={500}
                testId="support-hub-anchor"
                onClick={() => navigate('/support')}
              />
            </Trans>
          }
          align="center"
          icon={<CircleAlert size="large" />}
        />
      </Box>
    )
  } else {
    content = <SearchPage engine={engine} />
  }

  return (
    <VisibilityWrapper
      hideFor={{ feature: 'glcp-support-search', deployment: ['COP', 'GLOP'] }}
    >
      <Layout>
        <Page kind="wide" className="App">
          <PageContent>
            <VisibilityWrapper hideFor={{ feature: 'glcp-support-home' }}>
              <PageHeader
                pad={{ top: 'small' }}
                parent={
                  <Anchor
                    data-testid="support-hub-link"
                    icon={<FormPrevious color="brand" />}
                    onClick={() => navigate('/support')}
                    label={t('support_hub:support_title')}
                    weight="normal"
                  />
                }
              />
            </VisibilityWrapper>

            {content}
          </PageContent>
          <VisibilityWrapper hideFor={{ feature: 'glcp-support-chat' }}>
            <ChatBot />
          </VisibilityWrapper>
        </Page>
      </Layout>
    </VisibilityWrapper>
  )
}

export default Search
