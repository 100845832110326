// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import dayjs from 'dayjs'

// Sample array of objects
export const getQueryArray = (arrayOfObjects) => {
  // Extract the "query" key and create a new array of strings
  const arrayOfStrings = arrayOfObjects.map((obj) => obj.query)
  return { items: arrayOfStrings }
}

export const getQueryForList = (arrayOfObjects) => {
  // update the key query to value to access List component
  const updatedArrayOfObjects = arrayOfObjects.map((obj) => {
    return { ...obj, value: obj.query }
  })
  return updatedArrayOfObjects
}

export const GetRenderingHints = (categoryName, renderingHints) => {
  // Find the object in the renderinghints that has the same "categoryName" as the input
  const matchingObj = renderingHints?.find(
    (obj) => obj?.category === categoryName
  )
  return matchingObj
}

export const getprimaryField = (renderingHintsFields) => {
  // Find the object in the renderingHintsFields array where id is true
  const primaryField = renderingHintsFields?.fields?.find((field) => {
    const key = Object.keys(field)[0]
    return field[key]?.id === true
  })
  // Return the key of the primaryFieldObject
  return Object.keys(primaryField)?.[0]
}

export const getActionField = (renderingHintsFields, resourceType) => {
  // Extract the "ActionField" key from the renderingHints object
  const ActionField = renderingHintsFields?.clickEvents?.find((field) => {
    return resourceType
      ? field?.resourceType === resourceType &&
          field?.renderType === 'DETAILS_PAGE'
      : field?.renderType === 'DETAILS_PAGE'
  })
  return ActionField
}

export const getRedirectPath = (actionField, item) => {
  // Replace the path with the actual values from the item
  let path = actionField?.path
  actionField?.params?.forEach((param) => {
    path = path.replace(param?.key, item[param?.value])
  })
  return path
}

export const getFieldLabel = (renderingHintsFields, fieldName) => {
  // Extract the "label" key from the renderingHints object
  const fieldLabel = renderingHintsFields?.fields?.find((field) => {
    const key = Object.keys(field)[0]
    return key === fieldName
  })
  return fieldLabel[fieldName]?.label
}

export const transformDateValues = (renderingHintsFields, item) => {
  // Loop through each field in renderingHintsFields
  renderingHintsFields?.fields?.forEach((field) => {
    const key = Object.keys(field)[0]
    const transform = field[key]?.transform
    if (transform?.to === 'DATE') {
      // Convert the field value to the specific format MMM D, YYYY
      const value = item[key]
      // if the value is null or undefined, set it to '--'
      if (value === 'Invalid Date') {
        item[key] = '--'
      } else {
        const formattedDate = dayjs(value).format('MMM D, YYYY')
        item[key] = formattedDate
      }
    } else if (transform?.to === 'DATE_TIME') {
      // Convert the field value to the specific format MMM D, YYYY hh:mm'
      const value = item[key]
      // if the value is null or undefined, set it to '--'
      if (value === 'Invalid Date') {
        item[key] = '--'
      } else {
        const formattedDate = dayjs(value).format('MMM D, YYYY hh:mm')
        item[key] = formattedDate
      }
    }
  })
  return item
}

export const getLabelsForFalseId = (renderingHintsFields, item) => {
  // Extract the "label" and "value" key from the renderingHints object for fields with id=false and values from item
  const transformFields = transformDateValues(renderingHintsFields, item)
  const labels = renderingHintsFields?.fields?.reduce((acc, field) => {
    const key = Object.keys(field)[0]
    if (!field[key]?.id) {
      acc.push({
        label: field[key]?.label,
        value: transformFields[key] ? transformFields[key] : '--'
      })
    }
    return acc
  }, [])
  return labels
}

export const getCategoriesCount = (
  domainHits,
  countPerCategory,
  renderHints
) => {
  // Return an array of objects where each object has a label from hit?.category and count from countPerCategory where both have the same category
  const categoriesCount = domainHits?.map((hit, index) => {
    const category = hit?.category

    const countSource = renderHints?.find((obj) => obj.category === category)
      ?.renderingHints?.retrieveCountFrom

    let count = 0
    if (countSource === 'SEARCH_RESPONSE') {
      count = hit?.pagination?.totalCount || 0
    } else {
      count =
        countPerCategory?.find((obj) => obj?.category === category)
          ?.totalCount || 0
    }

    return {
      label: count !== 0 ? `${category} (${count})` : category,
      count,
      id: index,
      category,
      domainHit: hit,
      testId: `gs-category-${index}`
    }
  })

  return categoriesCount
}

export const getValueArray = (arrayOfObjects) => {
  // Extract the "query" key and create a new array of strings
  const arrayOfStrings = arrayOfObjects?.map((obj) => obj?.value)
  return { items: arrayOfStrings }
}

export const getRecommondedFaq = (arrayOfStrings) => {
  const arrayOfObjects = arrayOfStrings?.map((str, index) => {
    return { id: index, value: str }
  })
  return arrayOfObjects
}

export const getPaginationContent = (content, newResponseData) => {
  // Find the object from domainHits array where category matches the input category

  const matchedIndex = content?.searchResult?.domainHits.findIndex(
    (result) => result.category === newResponseData?.category
  )
  if (matchedIndex !== -1) {
    content.searchResult.domainHits[matchedIndex].results = [
      ...content.searchResult.domainHits[matchedIndex].results,
      ...newResponseData.results
    ]
    content.searchResult.domainHits[matchedIndex].pagination =
      newResponseData.pagination
  }

  return content
}

export const getErrorMessage = (error) => {
  // Extract the "error details" key from the error object
  return error?.response?.data?.errorDetails
}
