// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, DateInput, MaskedInput, Grid } from 'grommet'
import { PropTypes } from 'prop-types'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { View } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import DetailsBox from '../../../DetailsBox'
import { WizardContext } from '../../../../../../../../components/wizard/WizardContext'
import VisibilityWrapper from '../../../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { validateForm } from '../../../../../../../../utils/validation-utils'
import { Typography, Button } from '../../../../../../../../components'
import PreviewPost from '../../../PreviewPost'
import PreviewAnnouncement from '../../../PreviewAnnouncement'

import { getFormattedData, validateSchedule, getSubHeading } from './utils'

export const validateSchedulePost = (
  formValues,
  schedulePost,
  i18nTranslate
) => {
  const errorObj = validateSchedule(formValues, schedulePost, i18nTranslate)

  if (!isEmpty(errorObj)) {
    return Promise.reject(
      new Error(
        i18nTranslate('whats_new.create_post_form.missing_required_fields')
      )
    )
  }
  return validateForm(formValues, i18nTranslate)
}

const ReviewPost = ({ schedulePost }) => {
  const { t } = useTranslation(['manage'])
  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)

  const [formError, setFormError] = useState({})
  const [showPreview, setShowPreview] = useState(false)
  const [showPreviewAnnouncement, setShowPreviewAnnouncement] = useState(false)
  const LDFlags = useFlags()

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timezoneOffset = dayjs().format('Z')
  const dataDetailsSubHeading = getSubHeading(
    formValues,
    LDFlags['glcp-whatsnew-istanbul'],
    t
  )

  useEffect(() => {
    if (attemptedAdvance) {
      const errorObj = validateSchedule(formValues, schedulePost, t)
      setFormError(errorObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, attemptedAdvance, t])

  useEffect(() => {
    if (!schedulePost) {
      setFormValues({
        ...formValues,
        scheduleTime: '',
        scheduleDate: '',
        timezone: ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulePost])

  const onChange = (event) => {
    const nextValue = event.value
    setFormValues({
      ...formValues,
      scheduleDate: nextValue,
      timezone: timezoneOffset
    })
  }

  return (
    <Box justify="between" align="start" margin={{ bottom: 'medium' }}>
      <Box direction="column" gap="small" width="100%">
        <Box width="100%">
          <DetailsBox
            data={getFormattedData(formValues)}
            detailsSubHeading={dataDetailsSubHeading}
          />
        </Box>
        {schedulePost && (
          <Box width="100%">
            <Box margin={{ bottom: 'medium' }}>
              <Typography
                level="3"
                type="heading"
                testId="schedule-publish-title"
              >
                {t('whats_new.create_post_form.schedule_publish_title')}
              </Typography>
              <Typography
                type="text"
                size="xsmall"
                testId="schedule-publish-desc"
              >
                {t('whats_new.create_post_form.schedule_publish_desc')}
              </Typography>
            </Box>

            <Box>
              <Grid
                columns={['1/3', '2/3']}
                rows={['auto', 'auto']}
                align="center"
                gap={{
                  row: 'small',
                  column: 'small'
                }}
              >
                <Typography
                  type="text"
                  weight="bold"
                  size="medium"
                  testId="schedule-date-title"
                >
                  {t('whats_new.create_post_form.date_label')}
                </Typography>

                <Box margin={{ right: 'large' }}>
                  <FormField
                    testId="schedule-date"
                    name="scheduleDate"
                    error={formError.scheduleDate}
                  >
                    <DateInput
                      format="mm/dd/yyyy"
                      value={formValues?.scheduleDate}
                      onChange={onChange}
                      placeholder="mm/dd/yyyy"
                      data-testid="schedule-date-input"
                      calendarProps={{
                        bounds: [new Date().toISOString(), '2050-12-31'],
                        alignSelf: 'center'
                      }}
                    />
                  </FormField>
                </Box>
                <Typography
                  type="text"
                  weight="bold"
                  size="medium"
                  testId="schedule-time-title"
                >
                  {t('whats_new.create_post_form.time_label')}
                </Typography>
                <Box direction="column">
                  <FormField
                    margin={{ right: 'large' }}
                    name="scheduleTime"
                    data-testid="schedule-time-checkbox-formfield"
                    error={formError.scheduleTime}
                  >
                    <MaskedInput
                      mask={[
                        {
                          length: [1, 2],
                          options: Array.from({ length: 12 }, (v, k) => k + 1),
                          regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                          placeholder: 'hh'
                        },
                        { fixed: ':' },
                        {
                          length: 2,
                          options: ['00', '15', '30', '45'],
                          regexp: /^[0-5][0-9]$|^[0-9]$/,
                          placeholder: 'mm'
                        },
                        { fixed: ' ' },
                        {
                          length: 2,
                          options: ['am', 'pm'],
                          regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                          placeholder: 'ap'
                        }
                      ]}
                      value={formValues.scheduleTime}
                      data-testid="schedule-time-input"
                      onChange={(event) => {
                        setFormValues({
                          ...formValues,
                          scheduleTime: event.target.value
                        })
                      }}
                    />
                  </FormField>

                  <Typography type="text" sime="smalll" testId="timezone-title">
                    {`${timezone} (GMT${timezoneOffset})`}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          </Box>
        )}
        <Box
          align="end"
          margin={{ vertical: 'medium' }}
          direction="row-reverse"
        >
          <Button
            default
            label={t('whats_new.create_post_form.preview_post')}
            icon={<View size="small" />}
            onClick={() => {
              setShowPreview(true)
            }}
            testId="preview-btn"
            reverse
          />
          <VisibilityWrapper hideFor={{ feature: 'glcp-whatsnew-istanbul' }}>
            {formValues?.summaryFlag && (
              <Button
                default
                label={t('whats_new.create_post_form.preview_announcement_btn')}
                icon={<View size="small" />}
                onClick={() => {
                  setShowPreviewAnnouncement(true)
                }}
                testId="preview-announcement-btn"
                reverse
              />
            )}
          </VisibilityWrapper>
        </Box>
      </Box>
      {showPreview && (
        <PreviewPost
          item={getFormattedData(formValues)}
          closePreview={() => {
            setShowPreview(false)
          }}
        />
      )}
      {showPreviewAnnouncement && (
        <PreviewAnnouncement
          Post={getFormattedData(formValues)}
          closeModel={() => {
            setShowPreviewAnnouncement(false)
          }}
        />
      )}
    </Box>
  )
}

ReviewPost.propTypes = {
  schedulePost: PropTypes.bool.isRequired
}

export default ReviewPost
