// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { CheckBoxGroup, FormField, TextInput, Box } from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PasswordInput from './PasswordInput'

const StyledTextInput = styled(TextInput)`
  background: rgba(0, 0, 0, 0.04);
  color: #444444;
  opacity: 1;
  border: none;
`

const StyledReadOnlyInput = styled(TextInput)`
  background: rgb(247, 247, 247);
`

const FormInput = ({
  testId,
  disabled = false,
  label = undefined,
  labelHelper = '',
  inputType,
  checkBoxOptions = undefined,
  action = undefined,
  passwordVisibilityEnabled = false,
  ...rest
}) => {
  const {
    validate,
    required,
    readOnly,
    error,
    name,
    margin,
    htmlFor,
    pad,
    placeholder,
    info,
    width,
    ...inputProps
  } = { ...rest }
  const renderInput = () => {
    if (inputType === 'checkbox') {
      return (
        <CheckBoxGroup
          options={checkBoxOptions}
          name={name}
          data-testid={`${testId}-check-box`}
          {...inputProps}
        />
      )
    }

    if (inputType === 'password' && passwordVisibilityEnabled)
      return (
        <PasswordInput
          name={name}
          placeholder={placeholder}
          testId={testId}
          disabled={disabled}
          {...inputProps}
        />
      )

    if (disabled) {
      return (
        <StyledTextInput
          name={name}
          placeholder={placeholder}
          data-testid={`${testId}-input`}
          disabled={disabled}
          type={inputType}
          {...inputProps}
        />
      )
    }
    // replace with TextInput's readOnly and readOnlyCopy
    // after upgrading to [grommet-theme-hpe v5.3.0]
    if (readOnly) {
      return (
        <StyledReadOnlyInput
          plain
          name={name}
          placeholder={placeholder}
          data-testid={`${testId}-input`}
          disabled={disabled}
          type={inputType}
          readOnly={readOnly}
          {...inputProps}
        />
      )
    }
    return (
      <TextInput
        name={name}
        placeholder={placeholder}
        data-testid={`${testId}-input`}
        disabled={disabled}
        type={inputType}
        {...inputProps}
      />
    )
  }

  const FormInputComponent = (
    <FormField
      required={required}
      validate={validate}
      data-testid={`${testId}-field`}
      label={label}
      error={error}
      name={name}
      margin={margin}
      htmlFor={htmlFor}
      help={labelHelper}
      info={info}
      pad={pad}
      width={width}
    >
      {renderInput()}
    </FormField>
  )

  if (action)
    return (
      <Box direction="row">
        <Box basis="xlarge"> {FormInputComponent}</Box>
        {action}
      </Box>
    )
  return FormInputComponent
}

FormInput.propTypes = {
  testId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  passwordVisibilityEnabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inputType: PropTypes.oneOf(['checkbox', 'password', 'text', 'number'])
    .isRequired,
  checkBoxOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ]),
  labelHelper: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  action: PropTypes.element
}

export default FormInput
