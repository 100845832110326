// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'
import { CircleAlert } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { put } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  ButtonGroup
} from '../../../components'

import {
  AssignLocationContacts,
  validateContactList
} from './AssignLocationContacts'

const AssignLocationContactsModal = ({
  handleClose,
  locationDetailsData,
  setShowEditContactsModal,
  setShowErrorNotification,
  setLocationDetailsContacts,
  isCCSManager = false,
  customerId = null
}) => {
  const { t } = useTranslation(['location'])
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()

  const [contactList, setContactList] = useState(locationDetailsData.contacts)

  return (
    <ModalDialog
      width="large"
      header={
        <ModalHeader
          title={
            <>
              <Typography size="small" testId="header-title" type="text">
                {locationDetailsData.name}
              </Typography>
              <Typography level={2} testId="header-title" type="heading">
                {t('edit_location_contacts')}
              </Typography>
            </>
          }
        />
      }
      content={
        <Box pad={{ top: 'medium' }} flex={{ shrink: 0 }}>
          <AssignLocationContacts
            setFormError={setErrorMessage}
            contactList={contactList}
            setContactList={setContactList}
            isCCSManager={isCCSManager}
            customerId={customerId}
          />
          {errorMessage && (
            <Box
              background="validation-critical"
              pad="small"
              round="small"
              margin={{ bottom: 'medium' }}
              gap="xsmall"
              direction="row"
              align="center"
              data-testid="assign-contact-error"
            >
              <CircleAlert size="small" />
              <Text size="xsmall" testId="assign-contact-error-message">
                {errorMessage}
              </Text>
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  testId: 'cancel-btn',
                  onClick: handleClose
                },
                {
                  id: 1,
                  label: t('save'),
                  primary: true,
                  testId: 'save-btn',
                  onClick: () => {
                    const validation = validateContactList(contactList, t)
                    const updatedContactList = []
                    if (validation.isValid) {
                      // identify deleted contacts
                      const locationId = locationDetailsData.id
                      locationDetailsData.contacts.forEach((contact) => {
                        const checkContactDeleted = contactList.findIndex(
                          (newContact) => {
                            return newContact.id === contact.id
                          }
                        )
                        if (checkContactDeleted === -1) {
                          // contact not present in new list
                          updatedContactList.push({
                            id: contact.id,
                            type: contact.type
                          })
                        }
                      })
                      // identify newly added contacts by checking Id
                      contactList.forEach((newContact) => {
                        if (
                          !Object.prototype.hasOwnProperty.call(
                            newContact,
                            'id'
                          )
                        ) {
                          updatedContactList.push({
                            ...newContact,
                            ...{ location_id: locationId }
                          })
                        }
                      })

                      const url = isCCSManager
                        ? `/support-assistant/v1alpha1/location/${locationId}`
                        : `/ui-doorway/ui/v1/locations/${locationId}`

                      put(
                        url,
                        {
                          name: locationDetailsData.name,
                          description: locationDetailsData.description,
                          type: locationDetailsData.type,
                          contacts: updatedContactList,
                          ...(isCCSManager && {
                            platform_customer_id: customerId
                          })
                        },
                        oidcUser.access_token
                      ).then(
                        () => {
                          setShowEditContactsModal(false)
                          setLocationDetailsContacts(contactList)
                        },
                        (err) => {
                          setShowEditContactsModal(false)
                          setShowErrorNotification(
                            displayApiError(err, t, setShowErrorNotification)
                          )
                        }
                      )
                    } else {
                      setErrorMessage(validation.error)
                    }
                  }
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={handleClose}
      testId="edit-location-contacts-modal"
    />
  )
}

AssignLocationContactsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  locationDetailsData: PropTypes.object.isRequired,
  setShowEditContactsModal: PropTypes.func.isRequired,
  setShowErrorNotification: PropTypes.func.isRequired,
  setLocationDetailsContacts: PropTypes.func.isRequired,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string
}

export { AssignLocationContactsModal }
