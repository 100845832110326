// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, Select } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Trans, useTranslation } from 'react-i18next'

import {
  Button,
  ButtonGroup,
  CCSForm,
  FormInput,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../components'
import { post } from '../../utils/api-utils'
import { getApiErrorMessage } from '../../utils/error-handling-utils'

import AssignToOnPremInfoModal from './AssignToOnPremInfoModal'

function AssignToOnPremWorkSpaceModal({
  setOnPremWorkSpaceModal,
  onPremWorkSpaceData,
  requestPayload,
  onSuccess,
  serviceRegionInfo = [],
  selectedRows,
  type
}) {
  const [assignMsg, setAssignMsg] = useState('')
  const [selectedWorkspaceResourceId, setselectedWorkspaceResourceId] =
    useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()

  const getNumberOfSelectionsMsg = () => {
    switch (type) {
      case 'device_subscriptions':
        return t('common:device_subscriptions_small_case')

      case 'service_subscriptions':
        return t('common:service_subscriptions_small_case')

      default:
        return t('common:devices_small_case')
    }
  }
  const getRequestURL = () => {
    switch (type) {
      case 'devices':
        return '/ui-doorway/ui/v1/devices/onprem-assign'

      default:
        return '/ui-doorway/ui/v1/license/onprem-assign'
    }
  }
  const getRequstBody = () => {
    if (type === 'devices') {
      return requestPayload?.devices?.length
        ? {
            devices: requestPayload?.devices?.map((device) => {
              return {
                ...device,
                onPremWorkspaceResourceId: selectedWorkspaceResourceId
              }
            })
          }
        : {}
    }
    return requestPayload?.subscriptions?.length
      ? {
          subscriptions: requestPayload?.subscriptions?.map((subscription) => {
            return {
              ...subscription,
              onPremWorkspaceResourceId: selectedWorkspaceResourceId
            }
          })
        }
      : {}
  }
  const selectedRowsLength = selectedRows?.length
  const serviceRegionLength = serviceRegionInfo?.length
  const numberOfSelectionsMsg = getNumberOfSelectionsMsg()

  const onSubmit = () => {
    const requestBody = getRequstBody()
    const requestURL = getRequestURL()

    post(requestURL, requestBody, oidcUser?.access_token).then(
      () => {
        onSuccess()
      },
      (error) => {
        setErrorMessage(getApiErrorMessage(error, t))
      }
    )
  }
  const getServiceRegionInfoText = () => {
    if (serviceRegionInfo?.length === 1) {
      return (
        <Typography type="text" size="medium" testId="header-subtitle-2">
          <Trans i18nKey="onprem_workspace.service_region_msg_singular" t={t}>
            <strong>{{ serviceRegionLength }}</strong>
          </Trans>
        </Typography>
      )
    }
    if (serviceRegionInfo?.length === 2) {
      return (
        <Typography type="text" size="medium" testId="header-subtitle-2">
          <Trans i18nKey="onprem_workspace.service_region_msg" t={t}>
            <strong>{{ serviceRegionLength }}</strong>
          </Trans>
        </Typography>
      )
    }
    return <></>
  }
  return serviceRegionLength === selectedRows?.length ? (
    <AssignToOnPremInfoModal
      setOnPremWorkSpaceModal={setOnPremWorkSpaceModal}
    />
  ) : (
    <ModalDialog
      width="medium"
      position="right"
      height="100%"
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2" testId="header-title">
              {t('onprem_workspace.assign_to_onprem')}
            </Typography>
          }
          onClose={() => {
            setOnPremWorkSpaceModal(false)
          }}
        />
      }
      content={
        <Box flex={false}>
          <Box gap="small">
            <Typography type="text" size="medium" testId="header-subtitle-1">
              {t('onprem_workspace.assign_to_onprem_subtitle')}
            </Typography>
            <Typography type="text" size="medium" testId="header-subtitle-2">
              <Trans i18nKey="onprem_workspace.onprem_selection_info" t={t}>
                <strong>{{ selectedRowsLength }}</strong>
                <strong>{{ numberOfSelectionsMsg }}</strong>
              </Trans>
            </Typography>
            {getServiceRegionInfoText()}
          </Box>
          <CCSForm
            errorMessage={errorMessage}
            validate="blur"
            onSubmit={onSubmit}
            testId="assign-on-prem-wkspc-form"
          >
            <>
              <FormField
                name="onprem-workspaces"
                data-testid="onprem-workspaces"
                help={t('onprem_workspace.onprem_workspace_info')}
                label={t('onprem_workspace.onprem_workspace')}
                required
              >
                <Select
                  name="onprem-workspaces"
                  options={onPremWorkSpaceData}
                  valueKey={{ key: 'id', reduce: true }}
                  labelKey="onPremWorkspaceName"
                  onChange={({ option }) => {
                    setselectedWorkspaceResourceId(option?.id)
                  }}
                  value={selectedWorkspaceResourceId}
                >
                  {(option, { selected }) => (
                    <Box>
                      <Button
                        kind="option"
                        label={
                          <Box>
                            {option?.onPremWorkspaceName}
                            <Typography
                              type="text"
                              size="xsmall"
                              weight="normal"
                            >
                              {option?.onPremWorkspaceId}
                            </Typography>
                          </Box>
                        }
                        selected={selected}
                        fill="horizontal"
                        align="start"
                        testId={option.onPremWorkspaceId}
                      />
                    </Box>
                  )}
                </Select>
              </FormField>
              <FormInput
                validate={(value) => {
                  if (value !== t('common:assign_in_caps'))
                    return t('common:confirmation_required')
                  return true
                }}
                name="confirm"
                inputType="text"
                label={t('common:confirm')}
                labelHelper={t('onprem_workspace.assign_input_info')}
                required
                value={assignMsg || ''}
                onChange={(event) => {
                  setAssignMsg(event?.target?.value)
                }}
                testId="assign-text"
              />
              <Box margin={{ vertical: 'small' }}>
                <ButtonGroup
                  buttonList={[
                    {
                      label: t('onprem_workspace.assign_resources'),
                      primary: true,
                      testId: 'assign-resources-btn',
                      type: 'submit'
                    },
                    {
                      label: t('common:cancel'),
                      default: true,
                      testId: 'cancel-modal-btn',
                      onClick: () => {
                        setOnPremWorkSpaceModal(false)
                      }
                    }
                  ]}
                  justifyGroup="start"
                  testId="on-prem-workspace-btns"
                />
              </Box>
            </>
          </CCSForm>
        </Box>
      }
      onClose={() => {
        setOnPremWorkSpaceModal(false)
      }}
      testId="onprem-wkspc-dialog"
    />
  )
}

AssignToOnPremWorkSpaceModal.propTypes = {
  setOnPremWorkSpaceModal: PropTypes.func.isRequired,
  onPremWorkSpaceData: PropTypes.array.isRequired,
  requestPayload: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  serviceRegionInfo: PropTypes.array,
  selectedRows: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
}

export default AssignToOnPremWorkSpaceModal
