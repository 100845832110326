// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import { getErrorMessage, post } from '../../../../utils/api-utils'
import { lifeCycleStateEnum } from '../constants'
import Markdown from '../../../../commoncomponents/Markdown'

const VerifyDomainConfirmation = ({
  domainRequest,
  onSetOpen,
  setApiError,
  refreshParent = () => {}
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const [isVerifying, setIsVerifing] = useState(true)
  const [isVerified, setIsVerified] = useState(false)
  const [isClaimedElsewhere, setIsClaimedElsewhere] = useState(false)
  const { id, name } = domainRequest

  const closeModal = () => {
    onSetOpen(false)
    if (isVerified || isClaimedElsewhere) {
      refreshParent()
    }
  }

  useEffect(() => {
    let isCurrent = true
    const getDomain = async () => {
      try {
        const response = await post(
          `/identity/v1alpha1/domain-requests/${id}/verify`
        )
        if (!isCurrent) return
        if (response?.data) {
          const { lifeCycleState } = response.data
          if (lifeCycleState === lifeCycleStateEnum?.VERIFIED) {
            setIsVerified(true)
          }
          if (lifeCycleState === lifeCycleStateEnum?.CLAIMED_ELSEWHERE) {
            setIsClaimedElsewhere(true)
          }
        }
        setIsVerifing(false)
      } catch (error) {
        setApiError(
          getErrorMessage(error),
          'error',
          t('iam:domains.errors.verify_domain_failed')
        )
        closeModal()
      }
    }
    getDomain()
    return () => {
      isCurrent = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getConfirmationContent = () => {
    let content
    if (isVerifying) {
      content = (
        <Loader
          orientation="horizontal"
          label={
            <Typography type="text">
              <Markdown>
                {t('iam:domains.verifying_domain_claim', { name })}
              </Markdown>
            </Typography>
          }
          testId="domain-request-verifying-loader"
        />
      )
    } else if (isVerified) {
      content = (
        <Markdown>{t('iam:domains.domain_claim_verified', { name })}</Markdown>
      )
    } else {
      content = (
        <Markdown>
          {t('iam:domains.domain_claim_not_verified', { name })}
        </Markdown>
      )
    }
    return (
      <Box width="medium" margin={{ top: 'small' }}>
        {content}
      </Box>
    )
  }

  return (
    <ModalDialog
      onClickOutside={() => {
        closeModal()
      }}
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2">
              {t('iam:domains.verify_domain')}
            </Typography>
          }
        />
      }
      content={getConfirmationContent()}
      footer={
        <ModalFooter
          right={
            isVerifying ? (
              <Button
                label={t('common:cancel')}
                onClick={closeModal}
                testId="cancel-btn"
              />
            ) : (
              <Button
                label={t('common:close')}
                primary
                onClick={closeModal}
                testId="close-btn"
              />
            )
          }
        />
      }
      onClose={closeModal}
      testId="domain-request-verify-dialog"
    />
  )
}

VerifyDomainConfirmation.propTypes = {
  domainRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onSetOpen: PropTypes.func.isRequired,
  setApiError: PropTypes.func.isRequired,
  refreshParent: PropTypes.func
}

export default VerifyDomainConfirmation
