// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

export const analyticsConfig = () => {
  const storedLdConfigFromSession = JSON.parse(
    sessionStorage?.getItem('glp-analytics-config')
  )
  const { autoAnalytics, plugins } = storedLdConfigFromSession
  return {
    ...storedLdConfigFromSession,
    autoAnalytics: {
      ...autoAnalytics,
      bodyAsAnalyticsBaseTag: true
    },
    plugins: {
      ...plugins,
      amplitude: {
        ...plugins.amplitude,
        defaultTracking: true,
        projectId: window.$settings?.aaConfig?.project_id
          ? window.$settings?.aaConfig?.project_id
          : window.$settings?.aPID,
        apiKey: window.$settings?.aaConfig?.api_key
          ? window.$settings?.aaConfig?.api_key
          : window.$settings?.aAPIKey
      }
    }
  }
}
