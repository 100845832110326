// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Trash } from 'grommet-icons'
import { Box } from 'grommet'

import {
  Anchor,
  DataTable,
  Loader,
  Typography
} from '../../../../../components'
import { getUserName } from '../../../utils'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { assignmentSubjectEnum } from '../../constants'
import { getPaginationShowIdx } from '../../../../../utils/common-utils'
import { getScopeInfo } from '../../utils'

const AssignmentContent = ({
  retrievedAssignments = [],
  handleOnDeleteBtnClick,
  page,
  setPage,
  totalItems,
  itemsPerPage,
  setItemsPerPage,
  fromRolesPage = true,
  parentLoading
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation(['authz', 'iam', 'common'])

  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const handleRowClickForSubject = (datum) => {
    setIsLoading(true)
    if (datum?.role_grn) {
      navigate(
        `/manage-account/identity/roles/${encodeURIComponent(datum?.role_grn)}`
      )
    } else if (datum?.subject_type?.toLowerCase() === 'user') {
      navigate(`/manage-account/identity/users/${datum?.subject_id}`)
    } else if (datum?.subject_type?.toLowerCase() === 'user-group') {
      navigate(`/manage-account/identity/groups/${datum?.subject_id}`)
    }
    setIsLoading(false)
  }

  const roleAssignmentTableColumns = [
    ...(fromRolesPage
      ? [
          {
            header: t('iam:assign_roles_side_panel.subject_title'),
            property: 'meta.name',
            sortable: false,
            render: (datum) => {
              return (
                <Typography
                  width="medium"
                  type="text"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Anchor
                    href="#"
                    label={getUserName(datum) || ''}
                    testId="assignment-anchor"
                    onClick={(event) => {
                      event.preventDefault()
                      handleRowClickForSubject(datum)
                    }}
                  />
                </Typography>
              )
            }
          },
          {
            header: t('iam:roles_table_headers.type'),
            property: 'meta.type',
            sortable: false,
            render: (datum) => {
              return (
                <Typography type="text" style={{ whiteSpace: 'nowrap' }}>
                  {assignmentSubjectEnum[datum?.subject_type]}
                </Typography>
              )
            }
          }
        ]
      : [
          {
            header: t('common:business_object.role_capitalized'),
            property: 'meta.role',
            sortable: false,
            render: (datum) => {
              return (
                <Typography
                  width="medium"
                  type="text"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Anchor
                    href="#"
                    label={datum.role_name}
                    testId="assignment-anchor"
                    onClick={(event) => {
                      event.preventDefault()
                      handleRowClickForSubject(datum)
                    }}
                  />
                </Typography>
              )
            }
          },
          {
            header: t('iam:roles_table_headers.service'),
            property: 'service_name'
          }
        ]),
    {
      header: t('iam:role_assignment.scope_capitalized'),
      property: 'meta.scope',
      sortable: false,
      render: (datum) => {
        return getScopeInfo(datum, navigate, t)
      }
    },
    {
      header: '',
      property: '',
      sortable: false,
      render: (datum) => {
        return (
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.authorization.edit'
            }}
          >
            <Trash
              onClick={() => handleOnDeleteBtnClick(datum)}
              style={{ cursor: 'pointer' }}
              data-testid="role-assignments-table-data-delete"
            />
          </VisibilityWrapper>
        )
      }
    },
    {
      property: 'id',
      primary: true,
      render: () => <></>
    }
  ]

  return (
    <>
      {isLoading && (
        <Box justify="center" align="center" flex direction="row">
          <Loader testId="assignments-data-table-loader" />
        </Box>
      )}
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          setItemsPerPage,
          rowDropDownLabel: t('iam:domains.row_drop_down_label'),
          defaultRowsValue: itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        grid={{
          columns: roleAssignmentTableColumns,
          data: retrievedAssignments
        }}
        loading={isLoading || parentLoading}
        testId="role-assignments-data-table"
      />
    </>
  )
}

AssignmentContent.propTypes = {
  handleOnDeleteBtnClick: PropTypes.func.isRequired,
  retrievedAssignments: PropTypes.array,
  setItemsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  fromRolesPage: PropTypes.bool,
  parentLoading: PropTypes.bool.isRequired
}
export default AssignmentContent
