// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { put } from '../../../../../utils/api-utils'
import {
  Button,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../../components'
import Markdown from '../../common-components/Markdown'
import { updateV2RBACPolicies } from '../../../../../utils/rbac-api-utils'
import { useCCSContext } from '../../../../../context/ccs-context'
import { useVisibilityContext } from '../../../../../context/visibility-context'

const RemovePermissionModal = ({
  onSetOpen,
  role,
  permissionName,
  onSuccess = () => {},
  onError = () => {}
}) => {
  const { csrfToken } = useCCSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const { t } = useTranslation(['authz'])
  const [isDisabled, setIsDisabled] = useState(false)
  const removePermission = async () => {
    try {
      setIsDisabled(true)
      const updatedPermissions = role?.permissions
        ?.map((permission) => permission?.name)
        ?.filter((permission) => {
          return permission !== permissionName
        })

      const payload = {
        id: role?.id,
        name: role?.name,
        displayName: role?.displayName,
        description: role?.description,
        releaseStage: '',
        kind: role?.kind,
        managedBy: role?.managedBy,
        permissions: updatedPermissions
      }
      const response = await put(
        `/authorization/v2alpha1/roles/${role?.id}`,
        payload
      )
      // This could affect the current user's permissions hence why we're calling updateV2RBACPolicies
      // updateV2RBACPolicies will not run on local as csrfToken will be null
      updateV2RBACPolicies(dispatchVisibilityContext, csrfToken)
      onSuccess(response)
    } catch (error) {
      onError(error)
      setIsDisabled(false)
    }
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2">
              {t('iam:roles_permission.remove_permission_title')}
            </Typography>
          }
        />
      }
      content={
        <Typography type="text" margin={{ top: 'medium' }}>
          <Markdown>
            {t('iam:roles_permission.remove_permission_msg', {
              permissionName
            })}
          </Markdown>
        </Typography>
      }
      footer={
        <ModalFooter
          right={
            <Box direction="row" gap="medium">
              <Button
                secondary
                label={t('common:cancel')}
                onClick={() => {
                  onSetOpen(false)
                }}
                testId="role-remove-permission-cancel-btn"
              />
              <Button
                primary
                label={t('iam:roles_permission.remove_permission_btn_title')}
                onClick={() => {
                  removePermission()
                  onSetOpen(false)
                }}
                disabled={isDisabled}
                testId="role-remove-permission-submit-btn"
              />
            </Box>
          }
        />
      }
      onClose={() => {
        onSetOpen(false)
      }}
      onClickOutside={() => {
        onSetOpen(false)
      }}
      testId="remove-permission-modal"
    />
  )
}

RemovePermissionModal.propTypes = {
  /**
   *  Callback function to close remove permission modal
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * Role object containing the current state of the role
   * Needed for updating role
   * This modal will take care of updating the role using permissionName
   */
  role: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    releaseStage: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    managedBy: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    linkedRoleRef: PropTypes.string
  }).isRequired,

  /**
   * Name of permission to be removed
   */
  permissionName: PropTypes.string.isRequired,

  /**
   * Callback function for if API call is successful
   * Will be given API response
   */
  onSuccess: PropTypes.func,

  /**
   * Callback function for if API call is unsuccessful
   * Will be given API error response
   */
  onError: PropTypes.func
}

export default RemovePermissionModal
