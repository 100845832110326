// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import {
  Button,
  Typography,
  ModalDialog,
  ModalFooter
} from '../../../components'

const MessageStatusModal = ({
  title = undefined,
  message,
  onSetOpen,
  onAdd = null,
  buttonLabel = undefined,
  cancellable = false
}) => (
  <ModalDialog
    onClickOutside={() => {
      onSetOpen(false)
    }}
    testId="message-status-modal"
    onEsc={() => {
      onSetOpen(false)
    }}
    content={
      <Box width="medium" gap="small">
        {title && (
          <Typography type="heading" level="2">
            {title}
          </Typography>
        )}
        {message && (
          <Typography type="text" margin={{ top: 'medium' }}>
            {message}
          </Typography>
        )}
      </Box>
    }
    footer={
      <ModalFooter
        right={
          <Box direction="row" gap="small">
            <Button
              primary
              label={buttonLabel || 'Ok'}
              onClick={onAdd || onSetOpen.bind({}, false)}
              testId="message-status-modal-button-ok"
            />
            {cancellable && (
              <Button
                secondary
                label="Cancel"
                onClick={() => onSetOpen(false)}
                testId="message-status-modal-button-cancel"
              />
            )}
          </Box>
        }
      />
    }
    onClose={() => onSetOpen(false)}
  />
)
export { MessageStatusModal }

MessageStatusModal.propTypes = {
  /**
   * callback to close the Modal when Add operation is completed
   */
  onSetOpen: PropTypes.func.isRequired,

  /**
   * The diaglog title
   */
  title: PropTypes.string,

  /**
   * The diaglog messgae
   */
  message: PropTypes.string.isRequired,

  /**
   * callback to handle Add operation
   */
  onAdd: PropTypes.func,

  /**
   * The ok button label
   */
  buttonLabel: PropTypes.string,

  /**
   * boolean determining whether modal dialog is cancellable
   */
  cancellable: PropTypes.bool
}
