// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useEffect, useState } from 'react'
import { Group, Previous, User } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Box,
  Grid,
  Page,
  PageContent,
  PageHeader,
  ResponsiveContext
} from 'grommet'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Layout } from '../../../commoncomponents/layout/Layout'
import { Anchor, Card, Loader, Typography } from '../../../components'
import {
  getWorkspaceString,
  WKSPC,
  LIFECYCLE_STATE
} from '../../../utils/common-utils'
import {
  isV2,
  isAssociateWorkspace,
  getOrganizationId
} from '../../../utils/feature-flag-utils'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'

import JoinOrganizationInfoTile from './components/JoinOrganizationInfoTile'
import ActiveOrganizationInfoTile from './components/ActiveOrganizationInfoTile'

const OrganizationGovernance = ({ refreshRouter = () => {} }) => {
  const { t } = useTranslation(['common', 'iam'])
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const [notification, setNotification] = useState(null)
  const orgId = getOrganizationId()
  const [orgInfo, setOrgInfo] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [loading, setLoading] = useState(false)

  const [isAssociated, setIsAssociated] = useState(false)

  const handleOrganizationGovernanceCardClick = (currentItem) => {
    const mainRouter = '/manage-account/organization/'
    const routeTo = `${mainRouter}${currentItem.routeTo}`
    const state = {}
    if (refresh > 0) {
      state.triggerEffect = true
    }
    navigate(routeTo, state)
  }

  const handleBackBtnClick = () => {
    navigate('/manage-account')
  }

  const size = useContext(ResponsiveContext)
  const authzV2Enabled = isV2()

  const parentGrid = {
    columns: {
      small: ['auto'],
      medium: ['flex'],
      large: ['medium', 'auto'],
      xlarge: ['medium', 'auto']
    }
  }
  const childGrid = {
    columns: {
      small: ['flex'],
      medium: { count: 2, size: 'flex' },
      large: { count: 3, size: 'auto' },
      xlarge: { count: 3, size: 'auto' }
    }
  }

  const organizationGovernanceList = [
    {
      id: 1,
      icon: <User size="large" />,
      title: t('iam:organization.organization_users_card_title'),
      description: t('iam:organization.common_user_directory_card_desc', {
        workspaces: t('common:business_object.wkspc_plural')
      }),
      routeTo: 'users',
      isHidden: !authzV2Enabled,
      testId: 'organization-governance-users'
    },
    {
      id: 2,
      icon: <Group size="large" />,
      title: t('common:organization_landing.user_groups_card_title'),
      description: t('iam:organization.user_groups_card_desc', {
        workspaces: t('common:business_object.wkspc_plural')
      }),
      routeTo: 'groups',
      isHidden: !authzV2Enabled,
      testId: 'organization-governance-groups'
    },
    {
      id: 3,
      title: t('iam:organization.workspace_card_title', {
        workspaces: t('common:business_object.wkspc_plural')
      }),
      description: t('common:organization_landing.workspaces_card_desc'),
      routeTo: 'workspaces',
      isHidden: !authzV2Enabled,
      testId: 'organization-governance-workspaces'
    },
    {
      id: 4,
      title: t('common:organization_landing.domains_card_title'),
      description: t('common:organization_landing.domains_card_desc'),
      routeTo: 'domains',
      isHidden: !authzV2Enabled,
      testId: 'organization-governance-domains'
    },
    {
      id: 5,
      title: t('common:organization_landing.sso_profiles_card_title'),
      description: t('iam:organization.sso_card_desc', {
        workspace: t('common:business_object.wkspc')
      }),
      routeTo: 'sso-profiles',
      isHidden: !authzV2Enabled,
      testId: 'organization-governance-sso-profiles'
    }
  ]

  useEffect(() => {
    let isMounted = true
    if (orgId && orgId !== '') {
      setLoading(true)
      get(`/organizations/v2alpha1/organizations/${orgId}`).then(
        (response) => {
          if (response?.data) {
            if (!isMounted) return
            setOrgInfo(response?.data)
            setIsAssociated(
              isAssociateWorkspace(response?.data?.associatedWorkspace?.id)
            )
          }
          setLoading(false)
        },
        (error) => {
          setNotification(displayApiError(error, t, setNotification))
          setLoading(false)
        }
      )
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return (
    <>
      <Page>
        <PageContent data-testid="organization-governance">
          <Box
            direction="row"
            gap="medium"
            data-testid="organization-governance-pageHeader"
          >
            <PageHeader
              responsive
              title={t('iam:organization.governance_title')}
              subtitle={
                <Box width="large">
                  <Typography
                    normalize
                    type="text"
                    size="large"
                    testId="organization-governance-page-subtitle"
                  >
                    <Trans
                      i18nKey="iam:organization.landing_page_subtitle_v3"
                      t={t}
                      values={{
                        workspaces: t('common:business_object.wkspc_plural')
                      }}
                    >
                      <Anchor
                        label={t('common:learn_more')}
                        weight="bold"
                        href="#"
                        onClick={() => dispatchEvent(new Event('context-help'))}
                        testId="learn-more-anchor"
                      />
                      {t('iam:organization.governance')}
                    </Trans>
                  </Typography>
                </Box>
              }
              parent={
                <Anchor
                  label={t('common:button.manage_account', {
                    account: getWorkspaceString(showWorkspaceString, t, WKSPC)
                  })}
                  icon={<Previous />}
                  href="#"
                  onClick={(event) => {
                    event.preventDefault()
                    handleBackBtnClick()
                  }}
                  margin={{ bottom: 'xsmall' }}
                  testId="manage-workspace-back-btn"
                />
              }
            />
          </Box>
          <Grid columns={parentGrid.columns[size]} rows="auto" gap="large">
            <Box width={{ min: 'medium' }}>
              {(orgInfo?.lifecycleState === LIFECYCLE_STATE.INACTIVE ||
                loading) && (
                <Card
                  background="#F7F7F7"
                  cardWidth="auto"
                  testId="manage-account-type"
                  margin={{ top: 'medium', bottom: 'small' }}
                  align={loading ? 'center' : null}
                  CustomContent={
                    loading ? (
                      <Loader testId="inactive-org-info-loader" />
                    ) : (
                      <JoinOrganizationInfoTile
                        refreshParent={() => {
                          setRefresh(refresh + 1)
                        }}
                        setNotification={setNotification}
                        refreshRouter={refreshRouter}
                      />
                    )
                  }
                />
              )}
              {orgInfo?.lifecycleState === LIFECYCLE_STATE.ACTIVE && (
                <Card
                  cardWidth="auto"
                  testId="manage-account-type"
                  margin={{ top: 'medium', bottom: 'small' }}
                  CustomContent={
                    <ActiveOrganizationInfoTile
                      setOrgInfo={setOrgInfo}
                      orgInfo={orgInfo}
                      isAssociated={isAssociated}
                    />
                  }
                />
              )}
            </Box>
            <Grid
              columns={childGrid.columns[size]}
              rows="small"
              gap="medium"
              margin={{ bottom: 'large' }}
            >
              {organizationGovernanceList &&
                organizationGovernanceList.map((item) => {
                  return !item.isHidden ? (
                    <Card
                      key={item.id}
                      cardWidth="auto"
                      width="full"
                      margin={{ top: 'medium', right: 'medium' }}
                      height="small"
                      onClick={() =>
                        orgInfo?.lifecycleState === LIFECYCLE_STATE.ACTIVE
                          ? handleOrganizationGovernanceCardClick(item)
                          : undefined
                      }
                      style={{
                        cursor:
                          orgInfo?.lifecycleState === LIFECYCLE_STATE.ACTIVE
                            ? 'pointer'
                            : 'default',
                        pointerEvents:
                          orgInfo?.lifecycleState === LIFECYCLE_STATE.ACTIVE
                            ? 'auto'
                            : 'none'
                      }}
                      CustomContent={
                        <Box gap="small">
                          <Box
                            direction="row"
                            justify="start"
                            align="center"
                            gap="small"
                          >
                            {item.icon}
                            <Typography
                              type="heading"
                              level="3"
                              wordBreak="break-word"
                            >
                              {item.title}
                            </Typography>
                          </Box>
                          <Typography
                            type="text"
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical'
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      }
                      testId={item.testId}
                    />
                  ) : null
                })}
            </Grid>
          </Grid>
          {notification}
        </PageContent>
      </Page>
    </>
  )
}

OrganizationGovernance.propTypes = {
  refreshRouter: PropTypes.func
}

const OrganizationGovernancePage = ({ refreshRouter = () => {} }) => {
  return (
    <Layout>
      <OrganizationGovernance
        align="start"
        justify="start"
        refreshRouter={refreshRouter}
      />
    </Layout>
  )
}

OrganizationGovernancePage.propTypes = {
  refreshRouter: PropTypes.func
}

export default OrganizationGovernancePage
