// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Notification,
  Typography
} from '../../../../components'
import { getErrorMessage, post } from '../../../../utils/api-utils'

const ReinviteUserModal = ({
  email,
  setShowModal,
  onSuccess = () => {},
  customerId = undefined,
  customerUserName = undefined
}) => {
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const { oidcUser } = useReactOidc()
  const handleReinvite = () => {
    const url = `/support-assistant/v1alpha1/user-re-invite`
    post(
      url,
      {
        email,
        ...(customerId !== undefined && { customer_id: customerId }),
        ...(customerUserName !== undefined && {
          customer_username: customerUserName
        })
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage('')
          setShowModal(false)
          onSuccess()
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
      }
    )
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              testId="re-invite-user-modal-title"
              level="1"
              type="heading"
            >
              {t('resend_invite_email')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography type="text" size="medium" margin={{ bottom: 'small' }}>
            <Trans i18nKey="resend_invite_email_desc" t={t} email={email}>
              <strong>{{ email }}</strong>
            </Trans>
          </Typography>
          {errorMessage.length ? (
            <Notification
              backgroundColor="status-critical"
              onClose={() => {}}
              testId="re-invite-user-error-message"
              text={errorMessage}
              type="inline"
            />
          ) : null}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  secondary: true,
                  testId: 'cancel-btn',
                  onClick: () => setShowModal(false)
                },
                {
                  id: 1,
                  label: t('reinvite'),
                  primary: true,
                  testId: 'reinvite-btn',
                  onClick: handleReinvite
                }
              ]}
              testId="reinvite-buttons"
            />
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="reinvite-user-modal-dialog"
      width="medium"
    />
  )
}

ReinviteUserModal.propTypes = {
  email: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customerUserName: PropTypes.string
}

export { ReinviteUserModal }
