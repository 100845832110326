// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useState } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { Key } from 'grommet-icons'
import PropTypes from 'prop-types'

import { Button, Typography } from '../../../../../components'
import { WizardContext } from '../../../../../components/wizard/WizardContext'
import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../../context/authz-context'
import NewPermissionsModal from '../../NewPermissionsModal'

import SelectedPermissions from './SelectedPermissions'

const AddPermissions = ({
  selectedRole = {
    resourcePolicies: []
  },
  setRole = null,
  inCCSManager = false
}) => {
  const {
    formValues: {
      roleDetails: { appName }
    },
    formValues: { roleDetails },
    formValues,
    setFormValues
  } = useContext(WizardContext)
  const { t } = useTranslation(['authz', 'common'])
  const { dispatchAUTHZContext } = useAUTHZContext()
  const [addPermissionsModalIsOpen, setAddPermissionsModalIsOpen] =
    useState(false)

  const [application, setApplication] = useState({
    selectedApplication: appName,
    applicationId: selectedRole?.application_id
  })
  const [policies, setPolicies] = useState(selectedRole?.resourcePolicies || [])

  const handleAddPermissions = () => {
    const selectedPermissionList = selectedRole?.resourcePolicies?.map(
      (treeRole) => treeRole.resource.matcher
    )
    dispatchAUTHZContext({
      type: AUTHZActions.PERMISSIONS_SELECTED,
      data: selectedPermissionList
    })
    setFormValues({
      ...formValues,
      roleDetails: {
        ...roleDetails,
        selectedRole: { ...selectedRole }
      }
    })
    setAddPermissionsModalIsOpen(true)
    setApplication({
      selectedApplication: appName,
      applicationId: selectedRole?.application_id
    })
  }

  const getAddPermissionsButton = () => {
    return (
      <Button
        secondary
        label={t('assignments.add_permissions')}
        onClick={handleAddPermissions}
        testId="add-permissions-btn"
      />
    )
  }
  const getAddPermissionsCard = () => {
    return (
      <>
        <Box
          width="large"
          height="small"
          align="center"
          background="background-back"
          round={{ size: 'xsmall' }}
        >
          <Box
            direction="row"
            pad={{ top: 'medium', bottom: 'small' }}
            align="center"
            justify="center"
          >
            <Key size="medium" />
          </Box>
          <Box direction="row" pad="small" align="center" justify="center">
            <Typography type="text" margin="1em">
              {' '}
              {t('roles.wiz_step2_button_desc')}
            </Typography>
          </Box>
          <Box
            direction="row"
            pad={{ top: 'small', bottom: 'small' }}
            align="center"
            justify="center"
          >
            {getAddPermissionsButton()}
          </Box>
        </Box>
      </>
    )
  }

  const getStepHeader = () => {
    return (
      <>
        <Box
          direction="row"
          align="start"
          justify="between"
          pad={{ bottom: 'small' }}
        >
          <Box direction="row">
            <Typography
              level="2"
              type="heading"
              testId="selected-permissions-title"
            >
              {`${t('assignments.permission_plural')}`}
            </Typography>
          </Box>
        </Box>
      </>
    )
  }
  return (
    <>
      <Box pad={{ bottom: 'small' }}>
        <Box direction="row" justify="between">
          <Box direction="row" justify="start">
            {getStepHeader()}
          </Box>
          <Box direction="row" justify="end">
            {policies && policies.length > 0 ? getAddPermissionsButton() : null}
          </Box>
        </Box>
        {(policies && policies.length) > 0 ? (
          <SelectedPermissions
            application={application.application_id}
            selectedRole={selectedRole}
            key={policies.slug}
          />
        ) : (
          getAddPermissionsCard()
        )}

        {addPermissionsModalIsOpen && (
          <NewPermissionsModal
            open={addPermissionsModalIsOpen}
            setOpen={setAddPermissionsModalIsOpen}
            application={application}
            selectedRole={selectedRole}
            setRole={setRole}
            setPolicies={setPolicies}
            inCCSManager={inCCSManager}
            rolesPagePolicies={policies}
          />
        )}
      </Box>
    </>
  )
}

export default AddPermissions

AddPermissions.propTypes = {
  selectedRole: PropTypes.any,
  setRole: PropTypes.func,
  inCCSManager: PropTypes.bool
}
