// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box, PageHeader } from 'grommet'
import { Previous } from 'grommet-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Typography, Anchor, Loader } from '../../../../components'
import { Layout } from '../../../../commoncomponents/layout/Layout'
import { displayNotification } from '../../../../utils/notificiation-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import Markdown from '../common-components/Markdown'
import NoViewPermission from '../../../igc-service/commoncomponents/NoViewPermission'
import { useVisibilityContext } from '../../../../context/visibility-context'

import { RolesDataTable } from './RolesDataTable'

const Roles = () => {
  const navigate = useNavigate()
  const { v2RbacPolicies } = useVisibilityContext()
  const v2CSSAuthView = v2RbacPolicies?.includes('ccs.authorization.view')
  const { t } = useTranslation(['common', 'iam'])
  const handleBackBtnClick = () => {
    navigate('/manage-account/identity')
  }
  const location = useLocation()
  const [notification, setNotification] = useState(null)
  useEffect(() => {
    if (location?.state?.roleDeleteNotification) {
      const {
        state: { roleDeleteNotification }
      } = location
      setNotification(
        displayNotification(
          <Markdown>
            {t('iam:roles.delete_role_success_msg', {
              role: roleDeleteNotification?.message
            })}
          </Markdown>,
          roleDeleteNotification.severity,
          setNotification,
          roleDeleteNotification.title
        )
      )
    }
    navigate(location.pathname, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.roleDeleteNotification, navigate])
  return (
    <>
      {notification}
      <Box
        data-testid="roles-page"
        pad={{ horizontal: 'xlarge', bottom: 'large' }}
        width="xxlarge"
        alignSelf="center"
      >
        {/* TODO: Integrate delete success notification with API response */}
        <PageHeader
          title={
            <Typography type="heading" level="1" testId="roles-page-title">
              {t('common:business_object.roles_permission')}
            </Typography>
          }
          subtitle={
            <Box width="70%" margin={{ vertical: 'small' }}>
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.authorization.view'
                }}
              >
                <Typography type="text" testId="roles-page-subtitle">
                  {t('iam:roles.roles_page_subtitle', {
                    Roles: t('common:business_object.role_plural_capitalized'),
                    role: t('common:business_object.role')
                  })}
                </Typography>
              </VisibilityWrapper>
            </Box>
          }
          parent={
            <Anchor
              label={t('iam:workspace_identity.title', {
                Workspace: t('common:business_object:wkspc_capitalized')
              })}
              icon={<Previous />}
              href="#"
              onClick={(event) => {
                event.preventDefault()
                handleBackBtnClick()
              }}
              testId="iam-back-btn"
            />
          }
        />

        <Box fill="horizontal" overflow={{ horizontal: 'auto' }}>
          {v2CSSAuthView ? (
            <RolesDataTable />
          ) : (
            <>
              {v2CSSAuthView === false ? (
                <NoViewPermission />
              ) : (
                <Loader testId="perm-loader" />
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

const RolesPage = () => {
  return (
    <Layout>
      <Roles align="start" justify="start" />
    </Layout>
  )
}

export { RolesPage }
