// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, FormField } from 'grommet'
/* eslint-disable import/no-unresolved */
/* eslint-enable */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import debounce from 'lodash/debounce'

import {
  Typography,
  CCSForm,
  Button,
  Dropdown,
  ModalDialog,
  ModalHeader,
  FormInput
} from '../../../../components'
import { get, post, getErrorMessage } from '../../../../utils/api-utils'
import { validateEmail } from '../../../../utils/validation-utils'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import {
  predefinedRolesPriority,
  getAccessRules
} from '../../../../utils/manage-account-utils'
import { getCCSAppDetails } from '../../../../utils/ccs-manager-utils'
import { AssignRoleDropdownCustomRender } from '../../../../commoncomponents/identity/assign-roles-modal/AssignRoleDropdownCustomRender'

const InviteUserModal = ({
  onSetOpen,
  customerId = undefined,
  customerUserName = undefined,
  inCCSManager = true,
  onSuccess = () => {},
  customerAccountType = undefined
}) => {
  const [formValues, setFormValues] = useState({
    email: '',
    role: '',
    contact_info: ''
  })
  const [allRolesList, setAllRolesList] = useState([])
  const [searchString, setSearchString] = useState('')
  const [rolesList, setRolesList] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [errorOccurred, setErrorOccurred] = useState('')
  const { t: authnT } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()

  const custAccountLoaded = getCustomerAccount()
  const platformCid = custAccountLoaded?.platform_customer_id || ''
  const ccsApplicationId = getCCSAppDetails()?.ccsApplicationId

  const isTACCustomerSelected = customerId === platformCid

  useEffect(() => {
    // show only TAC roles(tac-admin, tac-operator) if TAC customer is selected -- { tag_rules: 'tac' }
    // show all other roles for other customers -- { all non-hidden roles are shown by default, tag_rules: 'activate'}
    const url = '/support-assistant/v1alpha1/app-role-assignments'
    get(
      url,
      {
        platform_customer_id: customerId,
        tag_rules: isTACCustomerSelected ? 'tac' : 'activate',
        application_id: ccsApplicationId,
        ...(searchString?.trim()?.length && { name: searchString?.trim() })
      },
      oidcUser.access_token
    ).then(
      async (response) => {
        const roleItems = response.data.filter(
          (value) => value.application_id === ccsApplicationId
        )
        let roles = roleItems[0].roles.sort(
          (a, b) =>
            predefinedRolesPriority.indexOf(a.slug) -
            predefinedRolesPriority.indexOf(b.slug)
        )
        roles = roles.map((role, index) => {
          return {
            label: role.name,
            name: role.name,
            value: index,
            description: role.description,
            applicationId: role.application_id,
            slug: role.slug,
            predefined: role.predefined
          }
        })
        setRolesList(roles)
        setAllRolesList(roles)
      },
      (error) => {
        console.error(error)
      }
    )
  }, [
    customerId,
    isTACCustomerSelected,
    ccsApplicationId,
    searchString,
    oidcUser.access_token
  ])

  useEffect(() => {
    if (!allUsers || allUsers.length === 0) {
      // fetch users
      const url = '/support-assistant/v1alpha1/users'
      const requestBody = {
        platform_customer_id: customerId,
        include_unverified: true
      }

      get(url, requestBody, oidcUser.access_token).then(
        (response) => {
          const updatedUsers = response.data.users.map((user) => {
            let fullName = `${user.contact.first_name} ${user.contact.last_name}`
            let avatarCharCount = 'two'
            if (
              user.contact.first_name === null ||
              user.contact.last_name === null
            ) {
              fullName = user.contact.email
              avatarCharCount = 'one'
            }
            user.contact.full_name = fullName
            user.avatarCharCount = avatarCharCount
            return user
          })
          setAllUsers(updatedUsers)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }, [oidcUser.access_token, platformCid, customerId, allUsers])

  const handleDebouncedSearchValue = debounce((value) => {
    setSearchString(value)
  }, 500)

  const assignRole = () => {
    const { email, role, contact_info } = formValues
    const selectedRole = rolesList.find(
      (roleName) => roleName.slug === role.slug
    )
    const requestBody = {
      roles: [
        {
          role: {
            application_id: selectedRole.applicationId,
            slug: selectedRole.slug
          },
          access_rules: customerAccountType === 'MSP' ? getAccessRules(0) : null
        }
      ],
      user_names: [email],
      ...(contact_info.length && {
        contact_information: contact_info
      }),
      // send platform_customer_id and customer_username fileds for ccs-manager API

      platform_customer_id: customerId,
      customer_username: customerUserName
    }
    setIsSubmitting(true)

    const url = '/support-assistant/v1alpha1/invite-user'

    post(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200) {
          onSetOpen(false)
          onSuccess()
        }
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, authnT)
        setErrorOccurred(backendErrorMessage)
        setIsSubmitting(false)
      }
    )
  }
  // eslint-disable-next-line no-unused-vars
  const renderOption = ({ label, description, value }) => {
    return (
      <Box direction="column">
        <Box
          direction="row"
          pad={{ top: 'small', left: 'small', right: 'small' }}
          gap="small"
          background="none"
        >
          <Typography type="text" weight="bold" testId={`role-id-${value}`}>
            {label}
          </Typography>
        </Box>
        <Box
          direction="row"
          pad={{ bottom: 'small', left: 'small', right: 'small' }}
          gap="small"
        >
          <Typography type="paragraph">{description}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <ModalDialog
      onClickOutside={() => onSetOpen(false)}
      header={
        <ModalHeader
          title={
            <Typography type="heading" level="2" testId="invite-title">
              {authnT('users.inviteUserTitle')}
            </Typography>
          }
        />
      }
      content={
        <Box gap="medium" margin={{ top: 'xsmall' }}>
          <Typography type="text" size="large" testId="invite-subtitle">
            {authnT('users.inviteUserSubtitle')}
          </Typography>
          <CCSForm
            value={formValues}
            onChange={setFormValues}
            errorMessage={errorOccurred}
            testId="invite-user-form"
            validate="submit"
            onSubmit={assignRole}
            buttons={
              <Box direction="row" justify="end" gap="medium">
                <Button
                  default
                  label={authnT('users.cancel')}
                  onClick={() => {
                    onSetOpen(false)
                  }}
                  testId="cancel-invite-btn"
                />
                <Button
                  primary
                  type="submit"
                  disabled={isSubmitting}
                  label={authnT('users.sendInvite')}
                  testId="send-invite-btn"
                />
              </Box>
            }
          >
            <Box gap="medium" margin={{ bottom: 'medium' }}>
              <Box>
                <FormInput
                  inputType="text"
                  name="email"
                  label={authnT('users.emailFormLabel')}
                  help=""
                  required
                  validate={(value) => {
                    let msg = validateEmail(value)
                    if (msg && typeof msg === 'boolean' && value) {
                      const dupUser = allUsers.find((user) => {
                        return (
                          value.toLocaleLowerCase() ===
                          user.contact.email.toLocaleLowerCase()
                        )
                      })
                      if (dupUser) {
                        msg = authnT('users.email_already_being_used_msg')
                      }
                      // allow only HPE mails to be invited to TAC account
                      if (
                        isTACCustomerSelected &&
                        !value.toLowerCase().endsWith('@hpe.com')
                      ) {
                        msg = authnT('users.only_hpe_users_in_ccs_manager_msg')
                      }
                    }
                    return msg
                  }}
                  testId="email-form-field"
                  placeholder={authnT('users.emailPlaceholder')}
                />
                <Typography type="paragraph" size="small" testId="invite-note">
                  {authnT('users.emailInfo')}
                </Typography>
              </Box>

              <FormField
                name="role"
                label={authnT('users.roleFormLabel')}
                required
                data-testid="role-form-field"
              >
                {rolesList && (
                  <Dropdown
                    id="roles-dropdown"
                    testId="roles-dropdown"
                    name="role_slug"
                    options={rolesList}
                    multiple={false}
                    labelKey="name"
                    value={formValues.role_slug}
                    valueKey={{ key: 'slug', reduce: true }}
                    placeholder={authnT('users.select_role')}
                    onChange={({ option }) => {
                      setFormValues({
                        ...formValues,
                        role: {
                          slug: option.slug
                        },
                        role_slug: option.slug,
                        role_name: option.label,
                        predefined: option.predefined,
                        access_rules: null,
                        access_rules_index: undefined,
                        ...(formValues.limit_resource_access && {
                          resource_restriction_policies: []
                        })
                      })
                    }}
                    onSearch={(searchText) => {
                      if (inCCSManager) {
                        setRolesList(
                          allRolesList?.filter((o) =>
                            o.label.includes(searchText)
                          )
                        )
                      } else {
                        handleDebouncedSearchValue(searchText)
                      }
                    }}
                    onClose={() => {
                      setSearchString('')
                    }}
                    customRender={(option) => (
                      <AssignRoleDropdownCustomRender
                        name={option.name}
                        description={option.description || ''}
                        predefined={option.predefined}
                      />
                    )}
                    noBorder
                  />
                )}
              </FormField>
              <Box>
                <FormInput
                  inputType="text"
                  label={authnT('users.contact_info_label')}
                  labelHelper={authnT('users.contact_info_label_subtext')}
                  name="contact_info"
                  testId="contact-info-form-input"
                  validate={(value) => validateEmail(value)}
                  placeholder={authnT('users.emailPlaceholder')}
                />
                <Typography type="text" size="small" testId="contact-info-note">
                  {authnT('users.contact_info_form_info_message')}
                </Typography>
              </Box>
            </Box>
          </CCSForm>
        </Box>
      }
      onClose={() => onSetOpen(false)}
      testId="invite-user-modal"
      width="medium"
    />
  )
}

InviteUserModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customerUserName: PropTypes.string,
  inCCSManager: PropTypes.bool,
  onSuccess: PropTypes.func,
  customerAccountType: PropTypes.string
}

export { InviteUserModal }
