// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { isGLOP } from '../../../utils/feature-flag-utils'

import { InviteUserSelectionModal } from './InviteUserSelectionModal'
import InviteUserModal from './InviteUserModal'

const InviteUser = ({
  inviteUserOpen,
  path = '/manage-account/identity/users',
  onSetOpen,
  onSuccess = () => {},
  setShowNotification = () => {}
}) => {
  const navigate = useNavigate()
  const [userInviteType, setUserInviteType] = useState('')

  const onSaveInviteUserSelection = (val) => {
    if (val === 'multiple_user') {
      navigate('/manage-account/identity/users/invite-bulk-users', {
        state: { path }
      })
    }
    onSetOpen(false)
    setUserInviteType(val)
  }

  const onInviteUserClose = () => {
    onSetOpen(false)
    setUserInviteType('')
  }

  return (
    <>
      {isGLOP() && inviteUserOpen && (
        <InviteUserSelectionModal
          onSave={onSaveInviteUserSelection}
          onSetOpen={onSetOpen}
        />
      )}
      {userInviteType === 'single_user' || (inviteUserOpen && !isGLOP()) ? (
        <InviteUserModal
          onSetOpen={onInviteUserClose}
          onSuccess={onSuccess}
          setShowNotification={setShowNotification}
        />
      ) : null}
    </>
  )
}

InviteUser.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  inviteUserOpen: PropTypes.bool.isRequired,
  path: PropTypes.string,
  onSuccess: PropTypes.func,
  setShowNotification: PropTypes.func
}

export default InviteUser
