// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import {
  Typography,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ButtonGroup
} from '../../../components'

const ConfirmationModal = ({
  onSetOpen,
  setConfirmAction,
  selectedCheckBoxStatus = '',
  setSelectedReadStatus
}) => {
  const { t } = useTranslation(['manage', 'common'])

  const handleCancel = () => {
    onSetOpen(false)
    setConfirmAction(false)
    setSelectedReadStatus('')
  }

  const handleSave = () => {
    onSetOpen(false)
    setConfirmAction(true)
  }

  return (
    <>
      <ModalDialog
        position="center"
        onClickOutside={() => {
          onSetOpen(false)
          setConfirmAction(false)
          setSelectedReadStatus('')
        }}
        testId="confirmation-dialog"
        onEsc={() => {
          onSetOpen(false)
          setConfirmAction(false)
          setSelectedReadStatus('')
        }}
        content={
          <Box gap="medium" margin={{ top: 'small' }}>
            <Box gap="xsmall">
              {selectedCheckBoxStatus === '' && (
                <Typography type="text" size="small">
                  {t('notification.confirm_selected')}
                </Typography>
              )}
              {selectedCheckBoxStatus === 'All' && (
                <Typography type="text" size="small" testId="confirm-all">
                  {t('notification.confirm_all')}
                </Typography>
              )}
              {selectedCheckBoxStatus === 'Read' && (
                <Typography type="text" size="small" testId="confirm-read">
                  {t('notification.confirm_read')}
                </Typography>
              )}
              {selectedCheckBoxStatus === 'Unread' && (
                <Typography type="text" size="small" testId="confirm-unread">
                  {t('notification.confirm_unread')}
                </Typography>
              )}
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-confirm-btn',
                    onClick: handleCancel
                  },
                  {
                    id: 1,
                    label: t('Yes'),
                    primary: true,
                    testId: 'save-confirm-btn',
                    onClick: handleSave
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
        header={
          <ModalHeader
            title={
              <Typography level="1" type="heading" weight="bold">
                {t('notification.confirm_action')}
              </Typography>
            }
          />
        }
        onClose={handleCancel}
        width="medium"
      />
    </>
  )
}
export { ConfirmationModal }

ConfirmationModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  setConfirmAction: PropTypes.func.isRequired,
  selectedCheckBoxStatus: PropTypes.string,
  setSelectedReadStatus: PropTypes.func.isRequired
}
