// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useReducer, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Hpe } from 'grommet-icons'
import { Layer, Box, FormField, RadioButtonGroup } from 'grommet'
import { isEmpty } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

// /* eslint-enable */
// import { get } from '../../../../../utils/api-utils'
import {
  AUTHZActions,
  useAUTHZContext
} from '../../../../context/authz-context'
import {
  Typography,
  CCSForm,
  Button,
  Dropdown,
  Logo,
  Notification,
  Loader
} from '../../../../components'
import { getCustomerAccount, isCoP } from '../../../../utils/feature-flag-utils'
import ArubaImage from '../../../../images/aruba.png'
import { get } from '../../../../utils/api-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { isServiceCentric } from '../../../../utils/account-utils'

const CreateRoleModal = ({ setOpen, inCCSManager = false }) => {
  const navigate = useNavigate()
  const { dispatchAUTHZContext } = useAUTHZContext()
  const { oidcUser } = useReactOidc()
  const [formValues, setFormValues] = useState({
    name: '',
    createOption: 'create_new',
    application: ''
  })
  const [radioValue, setRadioValue] = useState('create_new')
  const [errorOccurred] = useState('')
  const [rolesOptions, setRolesOptions] = useState([])
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(false)
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']

  const { t } = useTranslation(['authz', 'common'])
  const { roleAppMap } = useAUTHZContext()

  // for paginating on scroll
  const itemsPerPage = 50
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [applicationId, setApplicationId] = useState(null)
  const ccsApplicationId = '00000000-0000-0000-0000-000000000000'
  const [appList, setAppList] = useState([
    {
      label: t('common:platform_application_brand_name'),
      logo: null,
      value: ccsApplicationId
    }
  ])
  // const [appendList, setAppendList] = useState(false)

  const clearForm = {
    name: '',
    createOption: 'create_new',
    application: ''
  }
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        case 'CLEAR_FORM':
          return {
            ...clearForm
          }
        default:
          return currentState
      }
    },
    {
      ...clearForm
    }
  )

  const custAccountLoaded = getCustomerAccount()
  const platformCid = custAccountLoaded?.platform_customer_id || null

  const getRole = useCallback(() => {
    if (radioValue !== 'duplicate_existing' || isEmpty(applicationId)) return

    const param = {
      limit: itemsPerPage,
      offset: page === 1 ? 0 : offset
    }
    get(
      `/authorization/ui/v1/customers/${platformCid}/applications/${applicationId}/roles`,
      param,
      oidcUser.access_token
    ).then(
      (response) => {
        const { data } = response
        const roleToAppForContext = new Map()

        if (data?.roles) {
          data?.roles.forEach((role) => {
            roleToAppForContext.set(role.application_id, {
              duplicateFlag: false,
              provAppName:
                role?.application_id === ccsApplicationId
                  ? t('common:platform_application_brand_name')
                  : role?.application_name,
              appLogo: role.application_logo,
              roleList: roleToAppForContext.get(role.application_id)
                ? [
                    ...roleToAppForContext.get(role.application_id).roleList,
                    {
                      ...role,
                      resourcePolicies: role.resource_policies
                    }
                  ]
                : [
                    {
                      ...role,
                      resourcePolicies: role.resource_policies
                    }
                  ]
            })
          })
        }
        setTotalItems(data?.pagination?.total_count || itemsPerPage)
        setOffset(data?.pagination?.offset || 0)
        if (page !== 1) {
          const existingRolesList = roleAppMap.get(applicationId)?.roleList
          if (existingRolesList) {
            existingRolesList.push(
              ...roleToAppForContext.get(applicationId).roleList
            )
          }
          roleToAppForContext.get(applicationId).roleList = existingRolesList
          roleAppMap.set(applicationId, {
            ...roleToAppForContext.get(applicationId)
          })
        } else {
          roleAppMap.set(applicationId, {
            ...roleToAppForContext.get(applicationId)
          })
        }
        dispatchAUTHZContext({
          type: AUTHZActions.SET_ROLE_APP_MAP_DATA,
          data: roleAppMap
        })
        const roleOpts = roleAppMap
          ?.get(applicationId)
          ?.roleList?.filter((role) =>
            role?.tags
              ? role.tags.readonly === false
              : role.predefined === false
          )
        setRolesOptions(roleOpts || [])
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioValue, roleAppMap, applicationId, page])

  const getApplication = useCallback(() => {
    const url =
      inCCSManager && saCustomRoleLDFlag
        ? `/support-assistant/v1alpha1/customer-provisions`
        : `/ui-doorway/ui/v1/applications/provisions`
    get(
      url,
      {
        provision_status: 'PROVISIONED',
        ...(inCCSManager && saCustomRoleLDFlag
          ? { platform_customer_id: platformCid }
          : null)
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (!isEmpty(response?.data?.provisions)) {
          const data = response?.data?.provisions
          const roleToAppForContext = new Map()

          if (inCCSManager && saCustomRoleLDFlag) {
            roleToAppForContext.set('00000000-0000-0000-0000-000000000000', {
              duplicateFlag: false,
              provAppName: t('common:platform_application_brand_name'),
              appLogo: null
            })
          } else {
            roleToAppForContext.set('00000000-0000-0000-0000-000000000000', {
              duplicateFlag: false,
              provAppName: t('common:platform_application_brand_name'),
              appLogo: null
            })
            data.forEach((app) => {
              roleToAppForContext.set(app.application_id, {
                duplicateFlag: false,
                provAppName:
                  app.application_id === ccsApplicationId
                    ? t('common:platform_application_brand_name')
                    : app.name,
                appLogo: app.logo
              })
            })
          }

          dispatchAUTHZContext({
            type: AUTHZActions.SET_ROLE_APP_MAP_DATA,
            data: roleToAppForContext
          })
          const updatedAppList = []
          roleToAppForContext.forEach((values, index) => {
            updatedAppList.push({
              label: values.provAppName,
              value: index,
              logo: values.appLogo ? values.appLogo : null
            })
          })
          setAppList(updatedAppList)
        }
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, dispatchAUTHZContext])

  useEffect(() => {
    getRole()
  }, [getRole, page])

  useEffect(() => {
    getApplication()
  }, [getApplication])

  const createOptions = [
    { label: t('authz:roles.create_new_role'), value: 'create_new' },
    { label: t('authz:roles.duplicate_role'), value: 'duplicate_existing' }
  ]

  const handleSubmit = () => {
    // Clear the context first
    dispatchAUTHZContext({
      type: AUTHZActions.PERMISSIONS_SELECTED,
      data: []
    })
    dispatchAUTHZContext({
      type: AUTHZActions.SET_TREE_ROLE_PERMISSIONS,
      data: []
    })
    dispatchAUTHZContext({
      type: AUTHZActions.SET_PERMISSIONS_DATA,
      data: null
    })

    if (radioValue === 'create_new' && formValues.application !== '') {
      dispatchAUTHZContext({
        type: AUTHZActions.SET_SELECTED_ROLE,
        data: {
          resourcePolicies: []
        }
      })
      dispatchAUTHZContext({
        type: AUTHZActions.SET_CREATE_ROLE_DATA,
        data: state
      })
      roleAppMap.set(state.application, {
        ...roleAppMap.get(state.application),
        duplicateFlag: false
      })

      navigate(
        `/${
          inCCSManager && saCustomRoleLDFlag ? 'manage-ccs' : 'manage-account'
        }/identity/roles/create/${state.application}`
      )
    } else if (
      radioValue === 'duplicate_existing' &&
      formValues.application !== '' &&
      formValues.name !== ''
    ) {
      const currentSelections = roleAppMap.get(state.application)
      const selectedRoleDetails = currentSelections.roleList.filter(
        (role) => role.name === state.name
      )[0]
      const permissionsSelected = []
      const res = selectedRoleDetails.resourcePolicies
      for (let j = 0; j < res.length; j += 1) {
        for (let i = 0; i < res[j].permissions.length; i += 1) {
          permissionsSelected.push(
            `${res[j].resource.matcher}~${res[j].permissions[i].slug}`
          )
        }
      }

      dispatchAUTHZContext({
        type: AUTHZActions.PERMISSIONS_SELECTED,
        data: permissionsSelected
      })
      roleAppMap.set(state.application, {
        ...roleAppMap.get(state.application),
        duplicateFlag: true
      })
      navigate(
        `/${
          inCCSManager && saCustomRoleLDFlag ? 'manage-ccs' : 'manage-account'
        }/identity/roles/create/${state.application}/${
          selectedRoleDetails?.slug
        }`
      )
    }
  }

  const renderRoleOption = ({ name, description }) => (
    <Box direction="column" pad={{ top: 'small', bottom: 'small' }}>
      <Box
        direction="row"
        pad={{ top: 'small', left: 'small', right: 'small' }}
        gap="small"
        background="none"
      >
        <Typography type="text" weight="bold">
          {name}
        </Typography>
      </Box>
      <Box
        direction="row"
        pad={{ bottom: 'small', left: 'small', right: 'small' }}
        gap="small"
      >
        <Typography type="paragraph">{description}</Typography>
      </Box>
    </Box>
  )
  const renderAppsOption = ({ label, value, logo }) => (
    <Box align="center" pad="small" gap="small" direction="row">
      {value === ccsApplicationId ? (
        <Hpe
          pad={{ top: 'small', bottom: 'small' }}
          color="brand"
          size="small"
        />
      ) : (
        <Logo
          pad={{ top: 'small', bottom: 'small' }}
          size="xxsmall"
          url={isCoP() ? ArubaImage : logo}
          testId="application-logo"
        />
      )}
      <Typography size="medium" type="text">
        {label}
      </Typography>
    </Box>
  )
  return (
    <>
      {showNotification !== null && showNotification}
      <Layer
        position="center"
        onClickOutside={() => setOpen(false)}
        onEsc={() => setOpen(false)}
        data-testid="create-role-layer"
      >
        <Box width="medium" pad="medium" gap="small">
          <Typography type="heading" level="2" testId="create-role-title">
            {t('authz:roles.create_role')}
          </Typography>
          <>
            <CCSForm
              value={formValues}
              onChange={setFormValues}
              errorMessage={errorOccurred}
              testId="create-role-form"
              validate="blur"
              onSubmit={handleSubmit}
              buttons={
                <Box direction="row" justify="end" gap="medium">
                  <Button
                    default
                    label={t('common:cancel')}
                    onClick={() => {
                      setOpen(false)
                    }}
                    testId="cancel-create-role-btn"
                  />
                  <Button
                    primary
                    type="submit"
                    label={t('common:button.next')}
                    testId="create-role-btn"
                  />
                </Box>
              }
            >
              <>
                <Box margin={{ bottom: 'medium' }}>
                  <Box data-testid="create-option-radio-btn">
                    <RadioButtonGroup
                      name="createOption"
                      options={createOptions}
                      testId="role-radio-btn-group"
                      defaultValue={state.createOption}
                      onChange={(event) => setRadioValue(event.target.value)}
                    />
                  </Box>
                </Box>
                <Box margin={{ bottom: 'medium' }}>
                  <FormField
                    htmlFor="application"
                    name="application"
                    label={
                      newDashboardFlag
                        ? t('authz:service_manager')
                        : t('authz:application')
                    }
                    help={
                      newDashboardFlag
                        ? t('authz:roles.choose_service_info')
                        : t('authz:roles.choose_app_info')
                    }
                    data-testid="application-form-field"
                    required
                  >
                    {appList.length > 0 && (
                      <Dropdown
                        name="application"
                        options={appList}
                        multiple={false}
                        placeholder={
                          newDashboardFlag
                            ? t(
                                'authz:roles.choose_service_manager_placeholder'
                              )
                            : t('authz:roles.choose_application_placeholder')
                        }
                        id="application-dropdown"
                        testId="application-dropdown"
                        value={state.application}
                        customRender={(option) => renderAppsOption(option)}
                        onChangeDropdown={(option) => {
                          dispatch({
                            value: option,
                            field: 'application',
                            type: 'CHANGE_FIELD'
                          })
                          setApplicationId(option)
                          setPage(1)
                          // Only custom roles can be duplicated
                          const roleOpts = roleAppMap
                            ?.get(option)
                            ?.roleList?.filter(
                              (role) => role.predefined === false
                            )
                          setRolesOptions(roleOpts)
                          setLoading(
                            option !== applicationId &&
                              radioValue === 'duplicate_existing'
                          )
                        }}
                      />
                    )}
                  </FormField>
                </Box>

                {radioValue === 'duplicate_existing' && (
                  <Box margin={{ bottom: 'medium' }}>
                    {loading ? (
                      <Loader testId="rrp-details-loader" />
                    ) : (
                      <FormField
                        htmlFor="name"
                        name="name"
                        required
                        label={t('authz:roles.role')}
                        help={t('authz:roles.choose_role_info')}
                        data-testid="role-form-field"
                      >
                        {roleAppMap.get(state.application) ? (
                          <Dropdown
                            name="name"
                            options={rolesOptions}
                            multiple={false}
                            noBorder
                            placeholder={t('authz:roles.choose_role')}
                            id="roles-dropdown"
                            testId="roles-dropdown"
                            labelKey="name"
                            valueKey={{ key: 'name', reduce: true }}
                            value={state.name}
                            customRender={renderRoleOption}
                            dropProps={{
                              maxHeight: 'small'
                            }}
                            onMore={() => {
                              if (totalItems > offset) {
                                setPage(page + 1)
                              }
                            }}
                            onChangeDropdown={(option) => {
                              dispatch({
                                value: option,
                                field: 'name',
                                type: 'CHANGE_FIELD'
                              })
                            }}
                          />
                        ) : (
                          <Dropdown
                            name="name"
                            noBorder
                            placeholder={t('authz:roles.choose_role')}
                            testId="roles-dropdown"
                          />
                        )}
                      </FormField>
                    )}
                    <Box margin={{ top: 'small' }}>
                      <Notification
                        type="inline"
                        backgroundColor="status-unknown"
                        text={t('roles.modal_notification')}
                        testId="warning-inline-notification"
                      />
                    </Box>
                  </Box>
                )}
              </>
            </CCSForm>
          </>
        </Box>
      </Layer>
    </>
  )
}
CreateRoleModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  inCCSManager: PropTypes.bool
}

export default CreateRoleModal
