// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Anchor, Box, Grid } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Card, Typography } from '../../../components'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import Markdown from '../../../commoncomponents/Markdown'
import { displayNotification } from '../../../utils/notificiation-utils'
import { isServiceCentric } from '../../../utils/account-utils'

import { HomeQuickActionsData } from './quick-actions-data'

export const RightPanel = () => {
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  const location = useLocation()
  const [modalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState(undefined)
  const [notificationMsg, setNotificationMsg] = useState(null)
  const [notificationTitle, setNotificationTitle] = useState(null)

  useEffect(() => {
    setNotificationTitle(location?.state?.notificationTitle || null)
    const message = location?.state?.notificationDesc ? (
      <Markdown>{location?.state?.notificationDesc}</Markdown>
    ) : null
    setNotificationMsg(message)
    navigate(location.pathname, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])
  const handlePanelClick = (routeTo, modalDialog, hrefTo) => {
    // Modal takes priority. A card should not have both a modal
    // and a routeTo.
    if (modalDialog) {
      setModal(modalDialog)
      setModalOpen(true)
    } else if (routeTo) {
      navigate(routeTo)
    } else if (hrefTo) {
      window.open(hrefTo, '_blank')
    }
  }

  const quickActionPanel = HomeQuickActionsData(setModalOpen)

  return (
    <Box>
      {notificationMsg &&
        displayNotification(
          notificationMsg,
          'info',
          setNotificationMsg,
          notificationTitle
        )}
      <Grid columns="auto" gap="medium">
        {modalOpen && modal}
        <Card
          key={1}
          CustomContent={
            <>
              {quickActionPanel &&
                quickActionPanel.map((item) => (
                  <Box width="100%" key={item.id}>
                    {item.preTitle && (
                      <Box
                        justify="center"
                        margin={{
                          left: 'small',
                          top: newDashboardFlag ? 'none' : 'medium'
                        }}
                      >
                        <Typography
                          type="heading"
                          level={2}
                          testId="right-panel-quickaction-pretitle"
                        >
                          {t(item.preTitle)}
                        </Typography>
                      </Box>
                    )}
                    <VisibilityWrapper hideFor={item.hideFor} rbac={item.rbac}>
                      {!item.hidden && (
                        <Anchor
                          data-testid={item.testId}
                          label={t(item.title)}
                          margin={item.margin}
                          onClick={() => {
                            handlePanelClick(
                              item.routeTo,
                              item.modal,
                              item.hrefTo
                            )
                          }}
                        />
                      )}
                    </VisibilityWrapper>
                    {item.ruler && (
                      <Box key={`${item.ruler.key}-${item.id}`}>
                        {item.ruler}
                      </Box>
                    )}
                  </Box>
                ))}
            </>
          }
          testId="quick-actions-card"
        />
      </Grid>
    </Box>
  )
}
