// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

import { checkGotoChooseAccount } from '../../../utils/common-utils'

import WorkspacesPage from './WorkspacesPage'
import IgcWorkspaceDetailsPage from './components/IgcWorkspaceDetailsPage'

const WorkspacesRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <Routes>
      <Route exact path="/:workspaceId" element={<IgcWorkspaceDetailsPage />} />
      <Route exact path="/" element={<WorkspacesPage />} />
    </Routes>
  )
}

export default WorkspacesRouter
