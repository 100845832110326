// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box, CheckBox } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../components'

const AssignRoleDropdownCustomRender = ({
  name,
  description,
  predefined,
  isSelected = false,
  showCheckbox = false,
  disabled
}) => {
  const { t } = useTranslation(['authn', 'common'])

  return (
    <Box
      direction="row"
      justify="start"
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      align="center"
      data-testid={`option-${name}`}
    >
      {showCheckbox && <CheckBox checked={isSelected} disabled={disabled} />}
      <Box direction="column" width="small">
        <Typography
          type="text"
          emphasis
          color={disabled ? 'status-disabled' : undefined}
        >
          {name}
        </Typography>
        {description && (
          <Typography
            type="text"
            size="xsmall"
            color={disabled ? 'status-disabled' : undefined}
          >
            {description}
          </Typography>
        )}
      </Box>
      {predefined !== undefined && (
        <Box
          background="background-contrast"
          round="xsmall"
          pad={{ horizontal: 'small', vertical: 'xxsmall' }}
        >
          <Typography
            type="text"
            color={disabled ? 'status-disabled' : undefined}
          >
            {predefined ? t('users.built_in') : t('saml_sso.custom')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

AssignRoleDropdownCustomRender.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  predefined: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  disabled: PropTypes.bool
}

export { AssignRoleDropdownCustomRender }
