// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Markdown } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../components/button/Button'

const QueryCorrection = ({ controller }) => {
  const { t } = useTranslation('support_hub_search')
  const [state, setState] = useState(controller.state)

  useEffect(() => {
    const unsubscribe = controller.subscribe(() => {
      setState(controller.state)
    })

    return () => {
      unsubscribe()
    }
  }, [controller])

  const handleCorrection = () => {
    controller.applyCorrection()
  }

  const {
    hasQueryCorrection,
    queryCorrection,
    wasAutomaticallyCorrected,
    wasCorrectedTo,
    originalQuery
  } = state

  if (!hasQueryCorrection) {
    return null
  }
  const didYouMeanCorrectedQuery = queryCorrection.correctedQuery
  const correctedTo = wasCorrectedTo
  const { wordCorrections } = queryCorrection
  const queryOriginalWord =
    wordCorrections && wordCorrections.length > 0 ? originalQuery : ''

  if (wasAutomaticallyCorrected) {
    return (
      <Box
        data-testid="query-correction"
        background="background-back"
        round={{ size: 'xsmall', corner: 'bottom' }}
        gap="xsmall"
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
      >
        <Markdown>
          {t('query_correction_suggested_results', {
            queryOriginalWord,
            correctedTo
          })}
        </Markdown>
      </Box>
    )
  }

  return (
    didYouMeanCorrectedQuery && (
      <Box
        background="background-back"
        direction="row"
        height="36px"
        round={{ size: 'xsmall', corner: 'bottom' }}
        align="center"
        gap="xsmall"
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
      >
        <Button onClick={handleCorrection} testId="did-you-mean-btn">
          <Markdown>
            {t('query_suggestions_didYouMean', {
              didYouMeanCorrectedQuery
            })}
          </Markdown>
        </Button>
      </Box>
    )
  )
}

QueryCorrection.propTypes = {
  controller: PropTypes.shape({
    state: PropTypes.object,
    subscribe: PropTypes.func,
    applyCorrection: PropTypes.func
  }).isRequired
}
export default QueryCorrection
