// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, FormField, Text, TextInput } from 'grommet'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { Typography, Button, CCSForm } from '../../../components'
import { post, getErrorMessage, get } from '../../../utils/api-utils'
import { Layout } from '../../../commoncomponents/layout/Layout'
import {
  passwordValidation,
  confirmPasswordsMatch,
  getPassowrdGuideLinesMessage
} from '../../../utils/validation-utils'

const VerifyUserContent = ({ updatePassword = false }) => {
  const navigate = useNavigate()
  const [verifyError, setVerifyError] = useState('')
  const { t } = useTranslation(['common'])
  const paramList = new URLSearchParams(window.location.search)
  const verificationHash = paramList.get('verification_hash')
  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      password: '',
      confirm_password: ''
    }
  )
  const [passwordGuideLines, setPasswordGuideLines] = useState({})

  useEffect(() => {
    if (!verificationHash) {
      // TODO CCS-1383: Decide on how to display this and what the error wording is.
      setVerifyError(t('invalid_url'))
    }

    get('/accounts/ui/v1/cop-auth/local-authentication/details').then(
      (response) => {
        setPasswordGuideLines(response?.data)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setVerifyError(backendErrorMessage)
      }
    )
  }, [verificationHash, t])

  const sendPasswordAndVerify = () => {
    const url = updatePassword
      ? '/accounts/ui/v1/user/password-reset'
      : '/accounts/ui/v1/user/password'
    post(url, {
      password: state.password,
      verification_hash: verificationHash
    }).then(
      () => {
        if (updatePassword) {
          navigate('/onboarding/reset-password-success')
        } else {
          navigate('/')
        }
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setVerifyError(backendErrorMessage)
      }
    )
  }

  const createPasswordButton = (
    <Button
      label={
        // TODO: CCS-1381 - Replace custom text and padding when Grommet supports an xlarge Button.
        <Box pad={{ vertical: 'xsmall' }}>
          <Text size="xlarge">
            {updatePassword ? t('update_password') : t('finish')}
          </Text>
        </Box>
      }
      type="submit"
      fill="horizontal"
      testId="create-password-button"
      primary
    />
  )

  return (
    <Box margin={{ top: 'xlarge' }} alignSelf="center" width="medium">
      {/*
          For some reason, the Grommet header needs a 'top' margin specified to 0
          when the bottom is set, or else top is set to some large value
      */}
      <Typography
        margin={{ top: 'none', bottom: 'medium' }}
        type="heading"
        level="1"
        testId="create-password-title"
      >
        {updatePassword ? t('create_new_password') : t('create_password')}
      </Typography>
      <CCSForm
        testId="verify-user-form"
        validate="blur"
        onSubmit={sendPasswordAndVerify}
        errorMessage={verifyError}
        buttons={createPasswordButton}
      >
        <>
          <FormField
            margin={{ bottom: 'medium' }}
            label={updatePassword ? t('new_password') : t('create_password')}
            name="create_password"
            data-testid="create-password-form-field"
            required
            validate={
              passwordGuideLines?.password_length
                ? undefined
                : passwordValidation(t)
            }
            info={
              !isEmpty(passwordGuideLines) ? (
                <Typography
                  type="text"
                  size="xsmall"
                  testId="password-guidelines"
                >
                  {getPassowrdGuideLinesMessage(passwordGuideLines, t)}
                </Typography>
              ) : null
            }
          >
            {/*
              Grommet v2 docs don't list type for TextInput but found in storybook and in
              github issue. https://github.com/grommet/grommet/issues/2850
            */}
            <TextInput
              type="password"
              name="create_password"
              onChange={(event) =>
                dispatch({
                  value: event.target.value,
                  field: 'password',
                  type: 'CHANGE_FIELD'
                })
              }
            />
          </FormField>
          <FormField
            margin={{ bottom: 'medium' }}
            label={
              updatePassword ? t('confirm_new_password') : t('confirm_password')
            }
            name="confirm_password"
            data-testid="confirm-password-form-field"
            validate={() =>
              confirmPasswordsMatch(state.password, state.confirm_password, t)
            }
          >
            <TextInput
              type="password"
              name="confirm_password"
              onChange={(event) =>
                dispatch({
                  value: event.target.value,
                  field: 'confirm_password',
                  type: 'CHANGE_FIELD'
                })
              }
            />
          </FormField>
        </>
      </CCSForm>
    </Box>
  )
}
VerifyUserContent.propTypes = {
  updatePassword: PropTypes.bool
}

const VerifyUser = (props) => {
  return (
    <Layout hideHeaderOptions={['no-header']}>
      <VerifyUserContent {...props} />
    </Layout>
  )
}

export default VerifyUser
