// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { Box, Skeleton, Text } from 'grommet'
import {
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusWarningSmall
} from 'grommet-icons'
import { Pin } from 'grommet-leaflet'
import { useTranslation } from 'react-i18next'

const skeletonColors = {
  dark: ['background', 'background-front'],
  light: ['background', 'background-front']
}

export const AssetPopupSkeletionLoader = () => {
  const { t } = useTranslation(['manage'])

  const skeletons = Array(4).fill(
    <Skeleton colors={skeletonColors} margin={{ bottom: 'xsmall' }} />
  )
  return (
    <Box
      width="large"
      background={{
        color: 'background-back'
      }}
    >
      <Text
        margin={{ horizontal: 'small', top: 'small' }}
        weight={500}
        size="large"
      >
        {t('asset_management.assets_map.risk_summary')}
      </Text>
      <Box
        margin={{ horizontal: 'small' }}
        gap="small"
        data-testid="cluster-aggregate-list"
      >
        {skeletons.map((skeleton) => (
          <div key={skeleton.id}>{skeleton}</div>
        ))}
      </Box>
    </Box>
  )
}

const getPinKind = (riskLevel) => {
  return { 1: 'good', 2: 'warning', 3: 'critical' }[riskLevel]
}

const displayRiskSymbol = (riskLevel) => {
  switch (riskLevel) {
    case 3:
      return <StatusCriticalSmall size="small" color="status-critical" />
    case 2:
      return <StatusWarningSmall size="small" color="status-warning" />
    default:
      return <StatusGoodSmall size="small" color="status-ok" />
  }
}

export const displayIcon = (total, riskLevel, testId) => {
  return total === 1 ? (
    <Box data-testid={testId}>
      <Pin kind={getPinKind(riskLevel)} />
    </Box>
  ) : (
    <Box
      border={{
        color: {
          1: 'status-ok',
          2: 'status-warning',
          3: 'status-critical'
        }[riskLevel],
        size: '3px'
      }}
      pad="medium"
      round="full"
      height="48px"
      width="48px"
      justify="center"
      background="white"
      direction="row"
      align="center"
      data-testid={`cluster-count-${total}`}
    >
      <Text size="medium" weight={500}>
        {total}
      </Text>
      {displayRiskSymbol(riskLevel)}
    </Box>
  )
}
