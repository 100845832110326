// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, NameValueList, NameValuePair } from 'grommet'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '../../../../components'
import { AccountLogo } from '../../../../commoncomponents/account-logo/AccountLogo'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import { get } from '../../../../utils/api-utils'
import EditOrganizationDetailsSidePanel from '../../../igc-service/EditOrganizationDetailsSidePanel'
import { useVisibilityContext } from '../../../../context/visibility-context'
import { displayApiError } from '../../../../utils/error-handling-utils'

const ActiveOrganizationInfoTile = ({
  orgInfo = {},
  setOrgInfo = () => {},
  isAssociated = false
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const custAccountLoaded = getCustomerAccount()
  const { v2RbacPolicies, v2RbacRootWkspcPolicies } = useVisibilityContext()
  const [showEditOrganizationDialog, setShowEditOrganizationDialog] =
    useState(false)
  const [notification, setNotification] = useState(null)
  const [showRootWorkspaceName, setShowRootWorkspaceName] = useState('')
  const rbacPerm = 'organization.organization.update'
  const showButton = isAssociated
    ? v2RbacRootWkspcPolicies?.includes(rbacPerm)
    : v2RbacPolicies?.includes(rbacPerm)

  useEffect(() => {
    if (isAssociated) {
      get(
        `/organizations/v2alpha1/workspaces/${orgInfo?.associatedWorkspace?.id}`
      ).then(
        (response) => {
          const responseData = response?.data
          setShowRootWorkspaceName(responseData?.workspaceName)
        },
        (error) => {
          setNotification(displayApiError(error, t, setNotification))
        }
      )
    }
  }, [t, orgInfo, isAssociated])

  return (
    <>
      <Box direction="row" justify="between" align="start">
        {orgInfo && (
          <Box gap="medium">
            <AccountLogo
              account={orgInfo}
              logoOnly
              avatarSize="large"
              title={orgInfo.name}
              testId="organization-account-details"
            />
            <Typography
              truncate
              level="2"
              testId="organization-name"
              type="heading"
            >
              {orgInfo.name}
            </Typography>
          </Box>
        )}
      </Box>
      <Box margin={{ top: 'medium' }}>
        <NameValueList
          pairProps={{ direction: 'column' }}
          style={{ gridTemplateColumns: 'auto', wordBreak: 'break-word' }}
        >
          <NameValuePair
            name={
              <Typography testId="organization-id-lbl" type="text" emphasis>
                {t('iam:organization.organization_Id')}
              </Typography>
            }
          >
            {orgInfo && (
              <Typography testId="organization-id-val" type="paragraph">
                {orgInfo?.id || '--'}
              </Typography>
            )}
          </NameValuePair>
          <NameValuePair
            name={
              <Typography
                testId="organization-description-lbl"
                type="text"
                emphasis
              >
                {t('common:organization_landing.description')}
              </Typography>
            }
          >
            {orgInfo && (
              <Typography testId="organization-description-val" type="text">
                {orgInfo.description || '--'}
              </Typography>
            )}
          </NameValuePair>
          <NameValuePair
            name={
              <Typography
                testId="organization-phone-number-lbl"
                type="text"
                emphasis
              >
                {t('common:organization_landing.Organization_contact_phone')}
              </Typography>
            }
          >
            {orgInfo && (
              <Typography
                testId="organization-phone-number-val"
                type="paragraph"
              >
                {orgInfo.phoneNumber || '--'}
              </Typography>
            )}
          </NameValuePair>
          <NameValuePair
            name={
              <Typography testId="organization-email-lbl" type="text" emphasis>
                {t('common:organization_landing.Organization_contact_email')}
              </Typography>
            }
          >
            {orgInfo && (
              <Typography testId="organization-email-val" type="paragraph">
                {t(orgInfo.email) || '--'}
              </Typography>
            )}
          </NameValuePair>
          <NameValuePair
            name={
              <Typography
                testId="organization-workspace-name-lbl"
                type="text"
                emphasis
              >
                {t('iam:organization.organization_management_workspace')}
              </Typography>
            }
          >
            {orgInfo && (
              <Typography
                testId="organization-workspace-name-val"
                type="paragraph"
              >
                {isAssociated
                  ? showRootWorkspaceName
                  : custAccountLoaded?.company_name}
              </Typography>
            )}
          </NameValuePair>
        </NameValueList>

        {showButton && (
          <Button
            testId="edit-org-details-btn"
            onClick={() => setShowEditOrganizationDialog(true)}
            label={t('common:organization_landing.Edit_organization_details')}
            secondary
            margin={{ top: 'medium' }}
          />
        )}
      </Box>
      {showEditOrganizationDialog && (
        <EditOrganizationDetailsSidePanel
          onSuccess={(details) => setOrgInfo(details)}
          onClose={() => setShowEditOrganizationDialog(false)}
          initialValues={{
            name: orgInfo?.name,
            description: orgInfo?.description,
            phoneNumber: orgInfo?.phoneNumber,
            email: orgInfo?.email
          }}
        />
      )}
      {notification}
    </>
  )
}

ActiveOrganizationInfoTile.propTypes = {
  orgInfo: PropTypes.object,
  setOrgInfo: PropTypes.func,
  isAssociated: PropTypes.bool
}

export default ActiveOrganizationInfoTile
