// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes, Navigate } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const SupportAccessPage = lazy(() => import('./SupportAccessPage'))

const SupportAssistantRouterContent = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route path="/" exact element={<SupportAccessPage />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

const SupportAssistantRouter = () => {
  return <SupportAssistantRouterContent />
}

export default SupportAssistantRouter
