// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { CSVLink } from 'react-csv'
import pako from 'pako'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, RadioButtonGroup } from 'grommet'
import { StatusCritical } from 'grommet-icons'

import { get, post } from '../../utils/api-utils'
import {
  ButtonGroup,
  Notification,
  Loader,
  ModalDialog,
  ModalFooter,
  Typography
} from '../../components'
import { getApiErrorMessage } from '../../utils/error-handling-utils'

const ExportModal = ({
  customerData = undefined,
  onSetOpen,
  selectedDevices = []
}) => {
  const { t } = useTranslation(['device', 'common'])
  const [value, setValue] = useState('allowedlist_cli')
  const { oidcUser } = useReactOidc()
  const exportOptions = [
    { label: t('allowedlist_cli'), value: 'allowedlist_cli' },
    { label: t('legacy_allowedlist_cli'), value: 'legacy_allowedlist_cli' },
    { label: t('inventory_csv'), value: 'inventory_csv' },
    { label: t('allowedlist_csv'), value: 'allowedlist_csv' }
  ]
  const exportSelectedOptions = [
    { label: t('allowedlist_cli'), value: 'allowedlist_cli' },
    { label: t('legacy_allowedlist_cli'), value: 'legacy_allowedlist_cli' }
  ]
  const [errorMessage, setErrorMessage] = useState(null)
  const inventoryCsvLink = useRef()
  const allowedListCsvLink = useRef()
  const [listOfInventoryDevices, setListOfInventoryDevices] = useState([])
  const [listOfAllowedListDevices, setListOfAllowedListDevices] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const LDFlags = useFlags()
  const itemsPerPage = 50

  const inventoryCsvHeaders = [
    { label: t('serial_number'), key: 'serial_number' },
    { label: t('mac_address'), key: 'mac' },
    { label: t('status'), key: 'status' },
    { label: t('part_number'), key: 'part_number' },
    { label: t('part_description'), key: 'part_description' },
    { label: t('folder'), key: 'folder' },
    { label: t('firmware'), key: 'firmware_version' },
    { label: t('mode'), key: 'mode' },
    { label: t('imei'), key: 'imei' },
    { label: t('full_name'), key: 'device_full_name' },
    { label: t('description'), key: 'device_description' },
    { label: t('first_seen'), key: 'first_seen' },
    { label: t('ap_or_device_name'), key: 'device_name' },
    { label: t('ship_date'), key: 'ship_date' }
  ]

  const allowedListCsvHeaders = [
    { label: t('mac_address'), key: 'mac' },
    { label: t('folder'), key: 'folder' },
    { label: t('description'), key: 'device_description' },
    { label: t('ship_date'), key: 'ship_date' }
  ]

  const fetchData = (url) => {
    return new Promise((resolve, reject) => {
      get(
        url,
        {
          ...(customerData && { platform_customer_id: customerData.id }),
          ...(LDFlags['glcp-activate-export-csv-compression'] && {
            new_approach: true
          })
        },
        oidcUser.access_token,
        false,
        {
          ...(LDFlags['glcp-activate-export-csv-compression'] && {
            responseType: 'arraybuffer'
          })
        }
      )
        .then(resolve)
        .catch(reject)
    })
  }

  const processExportResponse = (response, downloadFileName) => {
    const blob = new Blob([response.data], { type: 'text/plain' })
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const fileUrl = URL.createObjectURL(blob)
      link.setAttribute('href', fileUrl)
      link.setAttribute('download', downloadFileName)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(fileUrl)
    }
  }

  const handleErrorAndCleanup = (error) => {
    const backendErrorMessage = getApiErrorMessage(error, t)
    setErrorMessage(backendErrorMessage)
  }

  const handleExport = async () => {
    setIsLoading(true)
    switch (value) {
      case 'inventory_csv':
        if (!window.inventory_csv) {
          window.inventory_csv = true
          const url = customerData
            ? '/support-assistant/v1alpha1/export-inventory-csv'
            : `/ui-doorway/ui/v1/activate/export/inventory-csv`
          await fetchData(url)
            .then(
              (response) => {
                if (LDFlags['glcp-activate-export-csv-compression']) {
                  const outBuffer = pako.inflate(response.data)
                  const decodedString = new TextDecoder().decode(outBuffer)
                  const decodedJSON = JSON.parse(decodedString)
                  setListOfInventoryDevices(decodedJSON?.devices || [])
                } else {
                  setListOfInventoryDevices(response.data?.devices || [])
                }
                inventoryCsvLink.current.link.click()
              },
              (error) => {
                const backendErrorMessage = getApiErrorMessage(error, t)
                setErrorMessage(backendErrorMessage)
              }
            )
            .finally(() => {
              delete window.inventory_csv
              setIsLoading(false)
            })
        }
        break
      case 'allowedlist_csv':
        if (!window.allowlist_csv) {
          window.allowlist_csv = true
          let url = ''

          if (customerData) {
            url = `/support-assistant/v1alpha1/export-allowlist-cli`
          } else {
            url = `/ui-doorway/ui/v1/activate/export/allowlist-csv`
          }
          await fetchData(url)
            .then(
              (response) => {
                if (LDFlags['glcp-activate-export-csv-compression']) {
                  const outBuffer = pako.inflate(response.data)
                  const decodedString = new TextDecoder().decode(outBuffer)
                  const decodedJSON = JSON.parse(decodedString)
                  setListOfAllowedListDevices(decodedJSON?.devices || [])
                } else {
                  setListOfAllowedListDevices(response.data?.devices || [])
                }
                allowedListCsvLink.current.link.click()
              },
              (error) => {
                const backendErrorMessage = getApiErrorMessage(error, t)
                setErrorMessage(backendErrorMessage)
              }
            )
            .finally(() => {
              delete window.allowlist_csv
              setIsLoading(false)
            })
        }
        break
      default:
        if (!window[`${value}`]) {
          window[`${value}`] = true
          let url = ''

          if (customerData) {
            url = `/support-assistant/v1alpha1/export-allowlist-cli`
          } else {
            url = `/ui-doorway/ui/v1/activate/export/allowlist-cli`
          }
          await get(
            url,
            {
              new_format: value === 'allowedlist_cli',
              ...(customerData && { platform_customer_id: customerData.id })
            },
            oidcUser.access_token
          )
            .then(
              (response) => {
                processExportResponse(
                  response,
                  value === 'allowedlist_cli'
                    ? 'allowed_list.txt'
                    : 'legacy_allowed_list.txt'
                )
              },
              (error) => handleErrorAndCleanup(error)
            )
            .finally(() => {
              delete window[`${value}`]
              setIsLoading(false)
            })
        }
    }
  }

  const handleSelectedExport = () => {
    setIsLoading(true)
    switch (value) {
      default:
        if (!window[`${value}`]) {
          window[`${value}`] = true
          let url = `/ui-doorway/ui/v1/${
            customerData ? 'cm/' : ''
          }activate/export/allowlist-cli?limit=${itemsPerPage}&offset=${0}&new_format=${
            value === 'allowedlist_cli'
          }${customerData ? '&platform_customer_id=' : ''}${
            customerData ? customerData.id : ''
          }`
          if (customerData) {
            url = `/support-assistant/v1alpha1/export-allowlist-cli?limit=${itemsPerPage}&offset=${0}&new_format=${
              value === 'allowedlist_cli'
            }${customerData ? '&platform_customer_id=' : ''}${
              customerData ? customerData.id : ''
            }`
          }
          post(
            url,
            {
              device_filter_request: selectedDevices.map((element) => ({
                serial_number: element.serial_number,
                mac_address: element.mac_address
              }))
            },
            oidcUser.access_token
          )
            .then(
              (response) => {
                processExportResponse(
                  response,
                  value === 'allowedlist_cli'
                    ? 'allowed_list.txt'
                    : 'legacy_allowed_list.txt'
                )
              },
              (error) => handleErrorAndCleanup(error)
            )
            .finally(() => {
              delete window[`${value}`]
              setIsLoading(false)
            })
        }
    }
  }

  return (
    <>
      <ModalDialog
        width="medium"
        testId="export-modal-dialog"
        content={
          <Box>
            <Box gap="xsmall">
              <Typography type="heading" level="1" testId="export-modal-title">
                {t('export_device_list')}
              </Typography>
              <Typography
                type="paragraph"
                size="medium"
                testId="export-modal-subtitle"
              >
                {t('export_desc')}
              </Typography>
            </Box>
            <Box
              margin={{ top: 'medium' }}
              border={{ side: 'all', color: 'border-weak' }}
            >
              <Box data-testid="export-option-radio-btn">
                <RadioButtonGroup
                  name="export-option"
                  options={
                    !selectedDevices.length
                      ? exportOptions
                      : exportSelectedOptions
                  }
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                />
              </Box>
            </Box>
            {isLoading && (
              <Box height="xxsmall" align="start" pad={{ top: 'small' }}>
                <Loader
                  label={t('download_loader')}
                  testId="loader-spinner"
                  orientation="horizontal"
                />
              </Box>
            )}
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: () => onSetOpen(false)
                  },
                  {
                    id: 1,
                    label: t('export_label'),
                    primary: true,
                    testId: 'export-action-btn',
                    onClick: !selectedDevices.length
                      ? handleExport
                      : handleSelectedExport
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
        onClose={() => onSetOpen(false)}
      />
      {!selectedDevices.length && (
        <>
          <CSVLink
            data={listOfInventoryDevices}
            headers={inventoryCsvHeaders}
            filename="inventory_report.csv"
            className="hidden"
            ref={inventoryCsvLink}
            target="_blank"
          />
          <CSVLink
            data={listOfAllowedListDevices}
            headers={allowedListCsvHeaders}
            filename="allowedlist_report.csv"
            className="hidden"
            ref={allowedListCsvLink}
            target="_blank"
          />
        </>
      )}
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical color="text-strong" />}
          onClose={() => setErrorMessage(null)}
          text={errorMessage}
          testId="api-notification"
          position="top"
        />
      )}
    </>
  )
}

ExportModal.propTypes = {
  customerData: PropTypes.object,
  onSetOpen: PropTypes.func.isRequired,
  selectedDevices: PropTypes.array
}

export { ExportModal }
