// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Layer, ResponsiveContext } from 'grommet'
import { useRef, useContext, useState, useEffect } from 'react'
import { ThemeContext } from 'styled-components'

import { useSessionContext } from './context/session-context'
import { useChatContext } from './context/chat-context'
import Resizable from './Resizable'
import ChatHeader from './ChatHeader'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'
import ChatBotLogo from './ChatBotLogo'

const ChatBotContainer = () => {
  const chatContainerRef = useRef()
  const { client } = useSessionContext()
  const { open, chat, dispatchChatContext } = useChatContext()
  const [isChatEmpty, setIsChatEmpty] = useState(true)
  const size = useContext(ResponsiveContext)
  useEffect(() => {
    setIsChatEmpty(!chat || chat.length === 0)
  }, [chat])

  const isSmallSize = size === 'small' || size === 'xsmall'

  const getContainerStyles = () => ({
    borderRadius: '0.5rem 0.5rem 0 0',
    overflow: 'hidden',
    width: isSmallSize ? '100%' : '384px',
    height: isSmallSize ? '100%' : '525px',
    bottom: isSmallSize ? '0' : '5.4rem',
    right: isSmallSize ? '0' : '2.75rem',
    position: 'inherit'
  })

  return open ? (
    <ThemeContext.Extend value={{ layer: { zIndex: '111' } }}>
      <Layer
        modal={false}
        background="background-back"
        position="bottom-right"
        margin="small"
        animation={open ? '' : 'fadeOut'}
        ref={chatContainerRef}
        data-testid="chat-bot-container"
        style={getContainerStyles()}
      >
        <Resizable chatContainerRef={chatContainerRef} />
        <ChatHeader
          chatContainerRef={chatContainerRef}
          closeChatBot={dispatchChatContext}
        />
        <Box fill direction="column-reverse" flex>
          <ChatBody chat={chat} client={client} />
        </Box>
        <ChatFooter
          client={client}
          closeChatBot={dispatchChatContext}
          isChatEmpty={isChatEmpty}
        />
      </Layer>

      <ChatBotLogo
        openChatBot={dispatchChatContext}
        close="CLOSE"
        data-testid="close-button"
      />
    </ThemeContext.Extend>
  ) : (
    <ChatBotLogo openChatBot={dispatchChatContext} data-testid="open-button" />
  )
}

export default ChatBotContainer
