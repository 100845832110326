// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { LINKS, getLink } from '../utils/links-util'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import { TilesRow } from './common/TilesRow'

const InsightsSection = memo(() => {
  const { t } = useTranslation(['support_hub'])

  const insightsTiles = [
    {
      id: 1,
      title: t('insights_cards.wellness_dashboard.title'),
      description: t('insights_cards.wellness_dashboard.description'),
      testId: 'wellness_dashboard-tile',
      isIconInTitle: true,
      renderWrapper: (component) => (
        <VisibilityWrapper
          hideFor={{
            feature: 'glcp-unified-wellness-dashboard',
            account: ['TENANT', 'MSP'],
            deployment: ['COP']
          }}
          rbac={{
            resource: '/ccs/wellness-dashboard',
            permission: 'ccs.wellness-dashboard.view'
          }}
        >
          {component()}
        </VisibilityWrapper>
      ),
      href: getLink(LINKS.WELLNESS_DASH)
    },
    {
      id: 2,
      title: t('insights_cards.hpe_infosight.title'),
      description: t('insights_cards.hpe_infosight.description'),
      testId: 'hpe_infoSight-tile',
      isIconInTitle: true,
      isLinkExternal: true,
      href: getLink(LINKS.HPE_INFO_SIGHT),
      target: LINKS.HPE_INFO_SIGHT
    }
  ]

  return (
    <TilesRow
      title={t('insights_cards.insights')}
      testIdTitle="insights-test-id"
      rows={insightsTiles}
      columns={2}
    />
  )
})

export { InsightsSection }
