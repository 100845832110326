// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { PropTypes } from 'prop-types'
import { Box, Text } from 'grommet'
import { StatusWarningSmall } from 'grommet-icons'

const NonGLPUserWarning = ({ t }) => {
  return (
    <Box direction="row" background="validation-warning" gap="xxsmall">
      <Box pad={{ top: 'xxsmall', right: 'xsmall', left: 'xsmall' }}>
        <StatusWarningSmall size="small" color="status-warning" />
      </Box>
      <Text size="small" data-testid="non-glp-user-warning">
        {t('common:non_GLP_user_warning')}
      </Text>
    </Box>
  )
}

NonGLPUserWarning.propTypes = {
  t: PropTypes.func.isRequired
}
export { NonGLPUserWarning }
