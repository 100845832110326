// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import SupportModal from './common/SupportModal'

const CreateCaseModal = ({
  onDialogChangeState,
  onChangeDropdown,
  onCancel = () => {},
  canContinue,
  currentLink = undefined,
  createCaseList
}) => {
  const { t } = useTranslation(['support_hub'])

  return (
    <SupportModal
      title={t('modal_create_case.title')}
      subtitle={t('modal_create_case.subtitle')}
      testId="create-case-modal"
      testIdDropDown="create-case-dropdown"
      options={createCaseList}
      onDialogChangeState={onDialogChangeState}
      onChangeDropdown={onChangeDropdown}
      onCancel={onCancel}
      canContinue={canContinue}
      href={currentLink}
    />
  )
}

CreateCaseModal.propTypes = {
  onDialogChangeState: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  canContinue: PropTypes.bool.isRequired,
  currentLink: PropTypes.string,
  createCaseList: PropTypes.array.isRequired
}

export { CreateCaseModal }
