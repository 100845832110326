// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { Box, DateInput, FormField } from 'grommet'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  CCSForm,
  ModalDialog,
  Typography,
  Dropdown,
  ModalFooter,
  ModalHeader
} from '../../../components'
import { isServiceCentric } from '../../../utils/account-utils'

const FilterDialog = ({
  setFilterDialog,
  timeOfDeployment,
  setTimeOfDeployment,
  customTime,
  setCustomTime,
  setFilterValues,
  filterValues,
  applicationInstanceOptions,
  errorMessage = ''
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const [appInstance, setAppInstance] = useState(
    filterValues?.applicationInstanceId
  )
  const [deploymentTime, setDeploymentTime] = useState(timeOfDeployment)
  const [customDateInput, setCustomDateInput] = useState(customTime)
  const LDFlags = useFlags()
  const newDashboardFlag = isServiceCentric(LDFlags)
  const initialStateOfFields = {
    app_instance: '',
    time_of_deployment: '',
    custom_time: ''
  }
  const [formFieldError, setFormFieldError] = useState({})

  const getTime = (type) => {
    const timeToReturn = {
      startTime: '',
      endTime: dayjs().unix()
    }
    const dayjsInstance = dayjs

    switch (type) {
      case 'LAST_HOUR': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'h').unix()
        break
      }
      case 'LAST_THREE_HOURS': {
        timeToReturn.startTime = dayjsInstance().subtract(3, 'h').unix()
        break
      }
      case 'LAST_DAY': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'd').unix()
        break
      }
      case 'LAST_WEEK': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'w').unix()
        break
      }
      case 'LAST_MONTH': {
        timeToReturn.startTime = dayjsInstance().subtract(1, 'M').unix()
        break
      }
      case 'LAST_THREE_MONTHS': {
        timeToReturn.startTime = dayjsInstance().subtract(3, 'M').unix()
        break
      }
      case 'CUSTOM': {
        if (customDateInput?.length) {
          timeToReturn.endTime = dayjsInstance(customDateInput[1])
            .endOf('day')
            .unix()

          timeToReturn.startTime = dayjsInstance(customDateInput[0])
            .startOf('day')
            .unix()
        }
        break
      }
      default: {
        break
      }
    }

    return timeToReturn
  }
  const handleSubmit = () => {
    const errorMsg = initialStateOfFields
    let isValid = true
    if (!appInstance?.length) {
      isValid = false
      errorMsg.app_instance = t('common:required')
    }
    if (deploymentTime === 'CUSTOM' && !customDateInput?.length) {
      isValid = false
      errorMsg.custom_time = t('common:required')
    }
    setFormFieldError(errorMsg)
    if (isValid) {
      const timeToReturn = getTime(deploymentTime)
      if (timeToReturn?.startTime) {
        setFilterValues({
          ...filterValues,
          ...{
            applicationInstanceId: appInstance,
            applicationInstanceDeployedTime: timeToReturn
          }
        })
      } else {
        setFilterValues({
          ...filterValues,
          ...{
            applicationInstanceId: appInstance
          }
        })
      }
      setTimeOfDeployment(deploymentTime)
      setCustomTime(customDateInput)
      setFilterDialog(false)
    }
  }
  const handleClose = () => {
    setFilterDialog(false)
  }

  return (
    <ModalDialog
      width="medium"
      testId="filter-modal"
      height="100%"
      position="right"
      content={
        <Box gap="small">
          <CCSForm
            errorMessage={errorMessage}
            validate="submit"
            onSubmit={handleSubmit}
            testId="filter-form"
          >
            <>
              <FormField
                data-testid="application-instance-form-field"
                label={
                  newDashboardFlag
                    ? t('customer_account.deployed_service_instance')
                    : t('customer_account.deployed_application_instance')
                }
                name="application-instance"
                error={formFieldError?.app_instance}
              >
                <Dropdown
                  noBorder
                  testId="application-instance-input"
                  name="application-instance"
                  options={applicationInstanceOptions}
                  value={appInstance}
                  onChangeDropdown={(value) => {
                    setFormFieldError({ ...formFieldError, app_instance: '' })
                    setAppInstance(value)
                  }}
                />
              </FormField>
              {appInstance && (
                <FormField
                  data-testid="application-instance-time-form-field"
                  label={
                    newDashboardFlag
                      ? t('customer_account.time_of_service_deployment')
                      : t('customer_account.time_of_app_deployment')
                  }
                  name="application-instance-time"
                  error={formFieldError?.time_of_deployment}
                >
                  <Dropdown
                    noBorder
                    testId="application-instance-time-input"
                    name="application-instance-time"
                    options={[
                      {
                        label: t('customer_account.last_hour'),
                        value: 'LAST_HOUR'
                      },
                      {
                        label: t('customer_account.last_three_hours'),
                        value: 'LAST_THREE_HOURS'
                      },
                      {
                        label: t('customer_account.last_day'),
                        value: 'LAST_DAY'
                      },
                      {
                        label: t('customer_account.last_month'),
                        value: 'LAST_MONTH'
                      },
                      {
                        label: t('customer_account.last_three_months'),
                        value: 'LAST_THREE_MONTHS'
                      },
                      {
                        label: t('customer_account.custom_date_range'),
                        value: 'CUSTOM'
                      }
                    ]}
                    value={deploymentTime}
                    onChangeDropdown={(value) => {
                      setFormFieldError({
                        ...formFieldError,
                        time_of_deployment: ''
                      })
                      if (timeOfDeployment !== 'CUSTOM') {
                        setCustomDateInput([])
                      }
                      setDeploymentTime(value)
                    }}
                  />
                </FormField>
              )}
              {deploymentTime === 'CUSTOM' && (
                <FormField
                  label={t('customer_account.custom_date_range')}
                  name="date"
                  data-testid="custom-date-range-form-field"
                  error={formFieldError?.custom_time}
                >
                  <DateInput
                    name="date"
                    format="mm/dd/yyyy-mm/dd/yyyy"
                    onChange={({ value }) => {
                      setFormFieldError({ ...formFieldError, custom_time: '' })
                      setCustomDateInput(value)
                    }}
                    calendarProps={{
                      bounds: [
                        new Date(2000, 0, 1).toISOString(),
                        new Date().toISOString()
                      ]
                    }}
                    value={customDateInput}
                    data-testid="custom-date-range-input"
                  />
                </FormField>
              )}
            </>
          </CCSForm>
        </Box>
      }
      header={
        <ModalHeader
          title={
            <Typography type="heading" testId="filter-title">
              {t('filter')}
            </Typography>
          }
          onClose={() => handleClose()}
        />
      }
      footer={
        <ModalFooter
          left={
            <Box margin={{ vertical: 'small' }} justify="center">
              <ButtonGroup
                buttonList={[
                  {
                    label: t('common:apply_filter'),
                    primary: true,
                    testId: 'submit-btn',
                    type: 'submit',
                    onClick: handleSubmit
                  },
                  {
                    label: t('common:cancel'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: handleClose
                  }
                ]}
                testId="filter-buttons"
              />
            </Box>
          }
        />
      }
      onClose={handleClose}
    />
  )
}

FilterDialog.propTypes = {
  setFilterDialog: PropTypes.func.isRequired,
  timeOfDeployment: PropTypes.string.isRequired,
  setTimeOfDeployment: PropTypes.func.isRequired,
  customTime: PropTypes.array.isRequired,
  setCustomTime: PropTypes.func.isRequired,
  filterValues: PropTypes.object.isRequired,
  setFilterValues: PropTypes.func.isRequired,
  applicationInstanceOptions: PropTypes.array.isRequired,
  errorMessage: PropTypes.string
}

export { FilterDialog }
