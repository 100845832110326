// Copyright 2024 Hewlett Packard Enterprise Development LP

import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Text,
  Heading,
  Anchor,
  NameValueList,
  NameValuePair,
  ResponsiveContext
} from 'grommet'
import { ShareRounded, CircleAlert } from 'grommet-icons'
import { Trans, useTranslation } from 'react-i18next'
import { useContext, useRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { keyBy, groupBy } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useReactOidc } from '@axa-fr/react-oidc-context'

// TODO put required into address field
import { shimServices } from '../../../shims/services'
import {
  Typography,
  Layout,
  useParams,
  Tabs,
  getCustomerAccountType,
  InstalledAppList,
  VisibilityWrapper,
  FormAlert,
  PageHeader,
  Notification,
  isOrgWorkSpace
} from '../../../shims/imports'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  useAxiosAuth,
  useRecentServices,
  useRegions,
  useServiceRedirect
} from '../../../hooks'
import {
  ServiceLaunchModal,
  ProvisionModal,
  RequestEvalModal,
  EvalButtonGroup,
  RequestEvalStatusText
} from '../../../components'
import ServiceMarkdown from '../../../components/ServiceMarkdown'
import {
  OTHER,
  WELLNESS,
  GLC,
  ALL_REGION,
  EVAL_STATES,
  EVAL_SERVICES,
  DEEP_LINKING
} from '../../../constants'
import { Loader } from '../../../../components'
import { getRegionName } from '../../../utils'
import { getProvisionStatus } from '../../../../pages/applications/utils'
import BackButton from '../../../../pages/services/service-catalog/service-detail/BackButton'

const ServiceDetail = () => {
  const axios = useAxiosAuth()
  const { service, fromServiceSubs } = useParams()
  const accountType = getCustomerAccountType()
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { regions: regionsData, isLoaded: regionsLoaded } = useRegions()
  const timers = useRef([])
  const [ldflagChangeCount, setLdflagChangeCount] = useState(0)

  const [showLaunchModal, setShowLaunchModal] = useState(false)
  const [showProvisionModal, setShowProvisionModal] = useState(false)
  const [showErrorNotification, setShowErrorNotification] = useState(null)

  const [services, setServices] = useState({})
  const [servicesByName, setServicesByName] = useState({})
  const [serviceNotFound, setServiceNotFound] = useState(false)
  const [provisionedInstances, setProvisionedInstances] = useState([])
  const [unprovisionedInstances, setUnprovisionedInstances] = useState([])
  const [normalInstances, setNormalInstances] = useState([])
  const [failedInstances, setFailedInstances] = useState([])
  const [cumulativeService, setCumulativeService] = useState({})
  const [regionLocations, setRegionLocations] = useState({})

  const [activeTabIndex, setActiveTabIndex] = useState(fromServiceSubs ? 1 : 0)
  const [isLoaded, setIsLoading] = useState(false)

  const [evalModalFormOpen, setEvalModalFormOpen] = useState(false)
  const [hasEval, setHasEval] = useState(false)
  // Request Eval
  const [formLoader, setFormLoader] = useState(false)
  const [evalFieldsMetaData, setEvalFieldsMetaData] = useState({})
  const [requestEvalStatus, setRequestEvalStatus] = useState({})
  const [successEval, setSuccessEval] = useState(false)
  const [evalServiceData, setEvalServiceData] = useState({})
  const LDFlags = useFlags()
  const availableRegionDetailFlag = LDFlags['glcp-available-region-detail']
  const evalFlag = LDFlags['glcp-evaluation-service']
  const dynamicEvalFlag = LDFlags['glcp-dynamic-eval-enablement']
  const enablePCAI = LDFlags['glcp-service-pcai']
  const enableServiceDeepLinking = LDFlags['glcp-service-slugs-deep-linking']
  const { t } = useTranslation(['apps', 'common', 'dashboard', 'services'])
  const [evalUrl, setEvalUrl] = useState('')
  const [configureDeviceNotification, setConfigureDeviceNotification] =
    useState(false)
  const dmEnableDefaultRegion = LDFlags['glcp-dm-enable-default-region']

  const [, setRecentServices] = useRecentServices()
  const serviceRedirect = useServiceRedirect()
  const screenSize = useContext(ResponsiveContext)
  const isLargeScreen = screenSize === 'large' || screenSize === 'xlarge'

  const padResponsive = {
    xlarge: 'large',
    large: 'medium',
    medium: 'medium',
    small: 'large',
    xsmall: 'large'
  }
  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])

  useEffect(() => {
    axios.get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setCountries(response.data.countries)
        setListOfCountries(response.data.countries)
      },
      (err) => {
        setShowErrorNotification(
          displayApiError(err, t, setShowErrorNotification)
        )
      }
    )
  }, [t, axios])

  // move to new file
  const getDaysLeft = () => {
    const currentDate = new Date()
    const endDate = Date.parse(requestEvalStatus.endDate)
    const timeDifference = endDate - currentDate
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
  }

  const setServiceData = () => {
    if (regionsData.length === 0) {
      return
    }
    axios.get('/ui-doorway/ui/v1/applications/provisions').then(
      ({ data: { provisions } }) => {
        const { services: newSchemaServices, servicesLegacy } = shimServices(
          provisions,
          LDFlags
        )
        const instances = servicesLegacy
          .filter(({ detailsPath }) => detailsPath === service)
          .map((instanceData) => ({
            ...instanceData,
            ...provisions
              /**
               * Using `instanceData` as filtered from `servicesLegacy`
               * for the name here as it's already gone through the
               * hydration process with our service definitions and contains
               * the desired marketing name. While we want the backend to
               * reflect as much as possible as the source of truth, this
               * becomes a painpoint when marketing changes refect quicker on
               * the frontend than they do in the APIs.
               *
               * @TODO refactor this page to utilize the updated services
               *  (see `newSchemaServices`) and start deprecation of servicesLegacy
               */
              .map(({ slug, ...rest }) => ({
                ...rest,
                slug,
                name: instanceData.name
              }))
              .find((provision) => {
                return (
                  provision.application_instance_id ===
                    instanceData.application_instance_id &&
                  provision.slug === instanceData.slug
                )
              })
          }))

        if (instances.length === 0) {
          setLdflagChangeCount(ldflagChangeCount + 1)
          if (ldflagChangeCount >= 1) {
            setServiceNotFound(true)
          }
        }
        setServices(newSchemaServices)
        setServicesByName(keyBy(newSchemaServices, 'serviceSlug'))

        setProvisionedInstances(
          instances.filter(
            (instance) => instance.provision_status === 'PROVISIONED'
          )
        )

        setUnprovisionedInstances(
          instances.filter((instance) =>
            [
              'PROVISION_FAILED',
              'UNPROVISIONED',
              'UNPROVISION_FAILED',
              ''
            ].includes(instance.provision_status)
          )
        )
        setNormalInstances(
          instances.filter(
            (instance) =>
              (accountType !== 'MSP' &&
                (instance.provision_status === 'PROVISIONED' ||
                  instance.provision_status === 'PROVISION_INITIATED' ||
                  instance.provision_status === 'PROVISION_FAILED' ||
                  instance.provision_status === 'UNPROVISION_INITIATED')) ||
              (accountType === 'MSP' &&
                (instance.provision_status === 'PROVISIONED' ||
                  instance.provision_status === 'PROVISION_INITIATED' ||
                  instance.provision_status === 'PROVISION_FAILED' ||
                  instance.provision_status === 'UNPROVISION_INITIATED') &&
                (instance.msp_conversion_status === '' ||
                  instance.msp_conversion_status === 'MSP_CONVERTED'))
          )
        )

        setFailedInstances(
          instances.filter(
            (instance) =>
              accountType === 'MSP' &&
              (instance.provision_status === 'PROVISIONED' ||
                instance.provision_status === 'UNPROVISION_INITIATED') &&
              (instance.msp_conversion_status === 'MSP_CONVERSION_FAILED' ||
                instance.msp_conversion_status === 'MSP_CONVERSION_INITIATED')
          )
        )

        const serviceObject = instances.reduce(
          (combined, _service) => {
            Object.entries(_service).forEach((property) => {
              if (property[0] === 'region') {
                combined.regions.push(property[1])
              } else if (!Object.keys(combined).includes(property[0])) {
                const [key, value] = property
                combined[key] = value
              }
            })

            return combined
          },
          { regions: [] }
        )
        setCumulativeService(serviceObject)

        setRegionLocations(
          Object.entries(groupBy(instances, 'region')).map(
            ([region, instancesInRegion]) => [
              region,
              instancesInRegion
                .map(({ location }) => location)
                .filter((location) => location)
            ]
          )
        )

        setIsLoading(true)
      },
      (error) => {
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setServiceData, [
    service,
    regionsData,
    LDFlags,
    regionsLoaded,
    ldflagChangeCount
  ])

  const closeModal = () => {
    setShowProvisionModal(false)
  }

  const isServiceManagerWithRedirectUrl = () => {
    const serviceManagerRedirectUrl =
      servicesByName[cumulativeService.serviceManager]?.redirectUrl
    return serviceManagerRedirectUrl != null
  }

  const isServiceManager =
    cumulativeService.slug === cumulativeService.serviceManager

  const isDeepLinking =
    cumulativeService?.featuresSupported?.includes(DEEP_LINKING)

  const isTenantOnlySupported =
    accountType === 'MSP' && cumulativeService.tenant_only_supported
      ? t('dashboard:service_details.enable')
      : t('dashboard:service_details.provision')

  const isServiceManagerProvisioned = () =>
    servicesByName[cumulativeService.serviceManager]?.regions?.filter(
      (region) => region.provisionStatus === 'PROVISIONED'
    ).length >= 1

  const disableLaunchButton =
    (isServiceManager && provisionedInstances.length === 0) || // May be a service manager with no provisions
    cumulativeService.redirectUrl || // May have a redirect URL
    (!isServiceManager &&
      (!isServiceManagerProvisioned() || isServiceManagerWithRedirectUrl())) ||
    (isOrgWorkSpace() && cumulativeService.serviceSlug !== 'PCE') ||
    (accountType === 'MSP' && cumulativeService.tenant_only_supported)

  const isGLC = isServiceManager
    ? cumulativeService.slug === GLC
    : cumulativeService.serviceManagerSlug === GLC

  const isGlobalService = cumulativeService.regions?.some(
    (region) => region === ALL_REGION
  )

  const serviceManagerSlug = isServiceManager
    ? cumulativeService.serviceSlug
    : servicesByName[cumulativeService.serviceManager]?.serviceSlug

  const serviceManagerName = isServiceManager
    ? cumulativeService.name
    : servicesByName[cumulativeService.serviceManager]?.name

  const isOnlyOneInstanceProvisioning = () => {
    const provisioningFlag =
      normalInstances.length === 1 &&
      normalInstances[0].provision_status === 'PROVISION_INITIATED'
    return provisioningFlag
  }

  const [regionsToShow, setRegionsToShow] = useState([])

  useEffect(() => {
    if (!isGlobalService && regionsToShow.length === 0) {
      cumulativeService.regions?.map((region) =>
        setRegionsToShow((oldArray) => [
          ...oldArray,
          {
            id: region,
            name: getRegionName(regionsLoaded, regionsData, region)
          }
        ])
      )
    }
  }, [
    cumulativeService.regions,
    isGlobalService,
    regionsData,
    regionsLoaded,
    regionsToShow.length
  ])

  const findEvalResponse = (evalResponse, slug) => {
    const evalObject = evalResponse.find((item) => item.service === slug) || {
      status: ''
    }
    return evalObject
  }

  const fetchEvalStatus = () => {
    axios
      .get('/ui-doorway/ui/v1/license/eval')
      .then((response) => {
        const evalResponse = response?.data
        const evalObject = findEvalResponse(
          evalResponse,
          cumulativeService.slug
        )
        setRequestEvalStatus(evalObject)
        setEvalUrl(cumulativeService.quoteUrl)
        setEvalServiceData({
          name: cumulativeService.name,
          documentationUrl: cumulativeService.documentationUrl,
          regions: regionsToShow,
          slug: cumulativeService.slug
        })
        setHasEval(true)
      })
      .catch((err) => {
        setShowErrorNotification(
          displayApiError(err, t, setShowErrorNotification)
        )
      })
  }
  const getEvalData = () => {
    setFormLoader(true)
    axios
      .get('/billing/v1beta1/catalog', {
        params: { service: cumulativeService.slug }
      })
      .then((response) => {
        // add the region options.
        setHasEval(true)
        let regionOptions = regionsToShow
        regionOptions = regionOptions.map((region) => ({
          name: region.name,
          code: region.id
        }))
        const metadata = response.data.metadata.marketplaceInfo
        metadata.options.find(
          (option) => option.name === 'region'
        ).allowedValues = regionOptions
        setEvalFieldsMetaData(metadata)
        setFormLoader(false)
        fetchEvalStatus()
      })
      .catch((err) => {
        setFormLoader(false)
        if (err.response?.status !== 404) {
          setShowErrorNotification(
            displayApiError(err, t, setShowErrorNotification)
          )
        }
      })
  }

  useEffect(() => {
    const renderEval = () => {
      // if metadata flag is ON we call metadata API, then if it has Eval we call fetchEvalStatus inside of
      if (dynamicEvalFlag && cumulativeService.slug) {
        getEvalData()
      }
      // if metadata flag is OFF we check if service has Eval using slugs then call fetchEvalStatus
      else if (EVAL_SERVICES.includes(cumulativeService.slug)) {
        fetchEvalStatus()
      }
    }

    if (evalFlag) {
      renderEval()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicEvalFlag, evalFlag, successEval, cumulativeService.slug, hasEval])

  const ServiceOverview = () => {
    return (
      <Box
        margin={{ top: 'small' }}
        pad={{ top: 'medium' }}
        direction={isLargeScreen ? 'row' : 'column'}
        gap={screenSize === 'xlarge' ? 'xlarge' : 'large'}
        justify="between"
      >
        <Box>
          <Text size="large">
            <ServiceMarkdown
              service={cumulativeService}
              serviceManagerName={
                servicesByName[cumulativeService.serviceManager]?.name
              }
              serviceManagerPath={
                servicesByName[cumulativeService.serviceManager]?.detailsPath
              }
              featuresSupported={cumulativeService.featuresSupported}
            />
          </Text>
        </Box>
        <Box width={{ min: 'medium' }}>
          <Heading
            level="2"
            margin={{ horizontal: 'none', top: 'none', bottom: 'medium' }}
          >
            {t('dashboard:service_detail.details')}
          </Heading>
          <NameValueList pairProps={{ direction: 'column' }}>
            {!isServiceManager && cumulativeService.category === OTHER && (
              <NameValuePair name={t('dashboard:common.service_manager')}>
                <Anchor
                  href={`/services/service-catalog/${
                    servicesByName[cumulativeService.serviceManager].detailsPath
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(
                      `/services/service-catalog/${
                        servicesByName[cumulativeService.serviceManager]
                          .detailsPath
                      }`
                    )
                  }}
                  label={servicesByName[cumulativeService.serviceManager].name}
                />
              </NameValuePair>
            )}
            <NameValuePair name={t('dashboard:common.supported_workspaces')}>
              {cumulativeService.workspaceTypes?.map((type) => {
                return (
                  <Text key={type}>
                    {t(`dashboard:workspace_types.${type}`)}
                  </Text>
                )
              })}
            </NameValuePair>
            {isServiceManager && cumulativeService.application_id && (
              <NameValuePair name={t('dashboard:common.service_id')}>
                {cumulativeService.application_id}
              </NameValuePair>
            )}
            <NameValuePair name={t('dashboard:common.documentation')}>
              {cumulativeService.documentationUrl ? (
                <Anchor
                  href={cumulativeService.documentationUrl}
                  target="_blank"
                  rel="noopener nonreferrer"
                  data-testid="documentation-link"
                >
                  {cumulativeService.documentationUrl}
                </Anchor>
              ) : (
                '--'
              )}
            </NameValuePair>
            <NameValuePair name={t('dashboard:common.terms_of_service')}>
              {cumulativeService.termsOfServiceUrl ? (
                <Anchor
                  href={cumulativeService.termsOfServiceUrl}
                  target="_blank"
                  rel="noopener nonreferrer"
                >
                  {cumulativeService.termsOfServiceUrl}
                </Anchor>
              ) : (
                '--'
              )}
            </NameValuePair>
            <NameValuePair name={t('dashboard:common.available_regions')}>
              {!isGlobalService &&
                regionLocations.map(([region, locations]) => {
                  return (
                    <Box
                      key={region}
                      data-testid="region-locations"
                      direction="row"
                    >
                      <Text weight={500}>
                        {getRegionName(regionsLoaded, regionsData, region)}
                      </Text>
                      {availableRegionDetailFlag && locations?.length > 0 && (
                        <Text margin={{ left: 'xxsmall' }}>
                          {` - ${locations.join('; ')}`}
                        </Text>
                      )}
                    </Box>
                  )
                })}
            </NameValuePair>
          </NameValueList>
        </Box>
      </Box>
    )
  }

  const tabs = [
    {
      id: 1,
      label: t('dashboard:service_detail.overview'),
      content: <ServiceOverview />,
      testId: t('dashboard:service_detail.overview')
    },
    ...(!isServiceManager && cumulativeService.serviceManager !== 'OTHER'
      ? [
          {
            id: 2,
            label: t('dashboard:common.service_manager'),
            content: (
              <Box margin={{ top: 'small' }} pad={{ vertical: 'medium' }}>
                <Box width="xlarge">
                  <Heading
                    level="2"
                    size="small"
                    margin={{
                      horizontal: 'none',
                      top: 'none',
                      bottom: 'small'
                    }}
                  >
                    {t('dashboard:common.service_manager')}
                  </Heading>
                  <Typography type="text" size="large">
                    {cumulativeService.serviceManager === 'GLC'
                      ? t(
                          'dashboard:service_detail.glc_service_manager_redirect',
                          {
                            service_name: cumulativeService.name,
                            service_manager_name:
                              servicesByName[cumulativeService.serviceManager]
                                .name,
                            view_details: t('dashboard:common.view_details')
                          }
                        )
                      : t('dashboard:service_detail.service_manager_redirect', {
                          service_name: cumulativeService.name,
                          service_manager_name:
                            servicesByName[cumulativeService.serviceManager]
                              .name,
                          view_details: t('dashboard:common.view_details')
                        })}
                  </Typography>
                  <Box margin={{ top: 'small' }} pad={{ top: 'medium' }}>
                    <Card width="medium">
                      <CardBody>
                        <Box pad={{ bottom: 'small' }}>
                          <Text size="large" weight={500}>
                            {t(
                              servicesByName[cumulativeService.serviceManager]
                                .name
                            )}
                          </Text>
                          <Text size="xsmall">
                            {cumulativeService.serviceManager === 'null'
                              ? t('dashboard:common.category.OTHER')
                              : t(
                                  `dashboard:common.category.${
                                    servicesByName[
                                      cumulativeService.serviceManager
                                    ].category
                                  }`
                                )}
                          </Text>
                        </Box>
                        <Text>
                          {t(
                            `services:${cumulativeService.serviceManagerSlug}.short_description`
                          )}
                        </Text>
                      </CardBody>
                      <CardFooter>
                        <Button
                          as="a"
                          href={`/services/service-catalog/${
                            servicesByName[cumulativeService.serviceManager]
                              ?.detailsPath
                          }`}
                          justify="center"
                          align="center"
                          label={t('dashboard:common.view_details')}
                          secondary
                          onClick={(e) => {
                            e.preventDefault()
                            setIsLoading(true)
                            setActiveTabIndex(0)
                            navigate(
                              `/services/service-catalog/${
                                servicesByName[cumulativeService.serviceManager]
                                  ?.detailsPath
                              }`
                            )
                            setIsLoading(false)
                          }}
                        />
                      </CardFooter>
                    </Card>
                  </Box>
                </Box>
              </Box>
            ),
            testId: t('dashboard:common.service_manager')
          }
        ]
      : []),
    ...(isServiceManager
      ? [
          {
            id: 3,
            label: `${t('dashboard:common.regions')} (${
              normalInstances.filter(
                (instance) => instance.provision_status === 'PROVISIONED'
              ).length
            })`,
            content: (
              <Box margin={{ top: 'medium' }}>
                <Box margin={{ bottom: 'large' }}>
                  <Typography type="heading" level="2">
                    {t('dashboard:service_regions.title')}
                  </Typography>
                  <Typography type="text" size="large">
                    {isGLC
                      ? t('apps:deployment_regions_subtitle_launch_only')
                      : t('dashboard:service_details.region_tab_prompt')}
                  </Typography>
                </Box>
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/app-provision/provisions',
                    permission: 'ccs.app-provision.edit'
                  }}
                >
                  {isServiceManager &&
                    unprovisionedInstances.length === 0 &&
                    !isGLC && (
                      <FormAlert
                        message={t('apps:new_dashboard_unavailable_regions')}
                      />
                    )}
                </VisibilityWrapper>
                {normalInstances.map((instance) => (
                  <InstalledAppList
                    appInfo={instance}
                    key={instance.application_instance_id}
                    appType="installed"
                    refreshAppList={setServiceData}
                  />
                ))}
                {failedInstances.length !== 0 && (
                  <Box direction="column" margin={{ top: 'large' }}>
                    <Box
                      direction="row"
                      border={{ side: 'bottom', color: 'border-weak' }}
                      pad={{ bottom: 'medium', top: 'medium' }}
                      align="center"
                    >
                      <Typography
                        size="xlarge"
                        type="text"
                        emphasis
                        testId="failed-apps-title"
                      >
                        {t('apps:failed_to_convert')}
                      </Typography>
                    </Box>

                    {failedInstances.map((instance) => (
                      <InstalledAppList
                        appInfo={instance}
                        refreshAppList={setServiceData}
                        key={instance.application_instance_id}
                        appType="failed"
                      />
                    ))}
                  </Box>
                )}
              </Box>
            ),
            testId: t('dashboard:common.regions')
          }
        ]
      : [])
  ]

  const CatalogContent = () => {
    return (
      <Tabs
        activeIndex={activeTabIndex}
        onActive={(tabIndex) => {
          setActiveTabIndex(tabIndex)
        }}
        tabsList={tabs}
        testId="tabContainer"
      />
    )
  }

  const pollingInNonServiceManager = async (appStatus, customerId) => {
    let refreshTimer
    if (appStatus === 'PROVISION_INITIATED') {
      getProvisionStatus(
        customerId,
        appStatus,
        oidcUser.access_token,
        setServiceData,
        undefined
      )
      refreshTimer = setTimeout(() => {
        pollingInNonServiceManager(appStatus, customerId)
      }, 5000)
      timers.current.push(refreshTimer)
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const timersRef = timers.current
    if (!isServiceManager) {
      const parentServiceManager =
        servicesByName[cumulativeService.serviceManager]

      if (parentServiceManager) {
        const regionInstances = parentServiceManager.regions

        regionInstances.forEach((instance) => {
          pollingInNonServiceManager(
            instance.provision_status,
            instance.serviceManagerCustomerId
          )
        })
      }
    }

    return () => {
      timersRef.forEach((timer) => {
        clearTimeout(timer)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServiceManager, servicesByName, cumulativeService])

  if (isLoaded && serviceNotFound) navigate('/not-found')

  return (
    <Layout>
      {successEval && (
        <Notification
          testId="eval-notification"
          status="normal"
          text={t('dashboard:eval.eval_success_notification', {
            cumulativeServiceName: cumulativeService.name
          })}
          onClose={() => setSuccessEval(false)}
        />
      )}
      {showErrorNotification !== null && showErrorNotification}

      {isLoaded ? (
        <Box align="center" pad={{ bottom: 'medium' }}>
          {showProvisionModal && (
            <ProvisionModal
              availableRegions={unprovisionedInstances.map(
                ({ region }) => region
              )}
              serviceData={cumulativeService}
              closeModal={closeModal}
              type={provisionedInstances.length === 0 ? 'provision' : 'add'}
              refreshAppList={setServiceData}
              setShowErrorNotification={setShowErrorNotification}
              isServiceManager={isServiceManager}
              setActiveTabIndex={setActiveTabIndex}
              serviceManagerName={
                servicesByName[cumulativeService.serviceManager]?.name
              }
              serviceManagerRegions={
                servicesByName[cumulativeService.serviceManager]?.regions
              }
              setConfigureDeviceNotification={setConfigureDeviceNotification}
              accountType={accountType}
            />
          )}
          {showLaunchModal && provisionedInstances.length !== 0 && (
            <ServiceLaunchModal
              services={services}
              modalInfo={{
                serviceName:
                  isServiceManager || isDeepLinking
                    ? cumulativeService.name
                    : serviceManagerName,
                slug: isServiceManager
                  ? cumulativeService.slug
                  : serviceManagerSlug,
                childServiceName: !isServiceManager
                  ? cumulativeService.name
                  : ''
              }}
              setShowModal={setShowLaunchModal}
              isServiceDetailLaunch
              isServiceManager={isServiceManager}
              isDeepLinking={isDeepLinking}
              serviceOffer={cumulativeService.serviceSlug}
            />
          )}
          <BackButton
            screenSize={screenSize}
            isLargeScreen={isLargeScreen}
            fromServiceSubs={fromServiceSubs}
            navigate={navigate}
            t={t}
          />
          <Box width="xxlarge" pad={{ horizontal: padResponsive[screenSize] }}>
            <Box
              direction={isLargeScreen ? 'row' : 'column'}
              align={isLargeScreen ? 'center' : 'start'}
              justify="between"
              margin={{ bottom: 'medium' }}
              gap="medium"
            >
              <Box>
                <PageHeader
                  primaryHeader={cumulativeService.name}
                  subHeader={`
                ${t(`dashboard:common.category.${cumulativeService.category}`)}
                `}
                  testId="service-centric-services-header"
                />
              </Box>
              <Box gap="small" direction="row" justify="between">
                {cumulativeService.contactSalesUrl && !hasEval && (
                  <Button
                    label={t('dashboard:service_detail.contact_sales')}
                    icon={<ShareRounded />}
                    reverse
                    href={cumulativeService.contactSalesUrl}
                    target="_blank"
                    data-testid={`${cumulativeService.slug}-service-detail-contact-sales-button`}
                  />
                )}
                {hasEval && (
                  <VisibilityWrapper
                    rbac={{
                      permission: 'ccs.orders.edit',
                      resource: '/ccs/orders'
                    }}
                  >
                    <Box direction="row">
                      <EvalButtonGroup
                        serviceSlug={cumulativeService.slug}
                        evalStates={EVAL_STATES}
                        evalUrl={evalUrl}
                        requestEvalStatus={requestEvalStatus}
                        setEvalModalFormOpen={setEvalModalFormOpen}
                      />
                    </Box>
                  </VisibilityWrapper>
                )}
                {cumulativeService.testDriveUrl &&
                  (!isOrgWorkSpace() ||
                    (cumulativeService.serviceSlug === 'PCE' &&
                      provisionedInstances.length === 0)) && (
                    <Button
                      as="a"
                      secondary
                      label={t('dashboard:service_detail.test_drive')}
                      href={cumulativeService.testDriveUrl}
                      target="_blank"
                    />
                  )}
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/app-provision/provisions',
                    permission: 'ccs.app-provision.edit'
                  }}
                >
                  {!isGLC && isOnlyOneInstanceProvisioning() && (
                    <Box direction="row" gap="small" align="center">
                      <Typography
                        type="text"
                        testId="provisioning-spinner-text"
                      >
                        {t('dashboard:service_details.provisioning')}
                      </Typography>
                      <Loader testId="provisioning-spinner-loader" />
                    </Box>
                  )}
                  {!isGLC &&
                    normalInstances.filter(
                      (instance) =>
                        instance.provision_status !== 'PROVISION_FAILED'
                    ).length === 0 &&
                    unprovisionedInstances.length !== 0 &&
                    (!isOrgWorkSpace() || cumulativeService.slug === 'PCE') && (
                      <Button
                        primary
                        label={
                          isServiceManager
                            ? isTenantOnlySupported
                            : t('dashboard:service_details.setup')
                        }
                        onClick={() => setShowProvisionModal(true)}
                      />
                    )}
                </VisibilityWrapper>
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/app-provision/provisions',
                    permission: 'ccs.app-provision.edit'
                  }}
                >
                  {!isGLC &&
                    unprovisionedInstances.length !== 0 &&
                    normalInstances.filter(
                      (instance) => instance.provision_status === 'PROVISIONED'
                    ).length !== 0 &&
                    (!isOrgWorkSpace() || cumulativeService.slug === 'PCE') && (
                      <Box>
                        <Button
                          label={t('dashboard:service_details.add_region')}
                          onClick={() => setShowProvisionModal(true)}
                          primary
                        />
                      </Box>
                    )}
                </VisibilityWrapper>
                {/* Adding icon check for wellness as external launch icon is not needed for that service */}
                {/* Adding target check for wellness as new tab launch is not needed for that service */}
                {(cumulativeService.redirectUrl != null ||
                  isServiceManagerWithRedirectUrl()) &&
                  !isOrgWorkSpace() && (
                    <Button
                      data-testid={`${cumulativeService.slug}-service-detail-static-launch-button`}
                      primary
                      label={
                        isServiceManager ||
                        (!isServiceManager &&
                          cumulativeService.serviceManager === 'OTHER')
                          ? t('dashboard:common.launch')
                          : t('dashboard:common.launch_service_manager')
                      }
                      icon={
                        cumulativeService.serviceSlug !== WELLNESS ? (
                          <ShareRounded />
                        ) : (
                          ''
                        )
                      }
                      reverse
                      href={
                        isServiceManager ||
                        (!isServiceManager &&
                          cumulativeService.serviceManager === 'OTHER')
                          ? cumulativeService.redirectUrl()
                          : servicesByName[
                              cumulativeService.serviceManager
                            ]?.redirectUrl()
                      }
                      target={
                        cumulativeService.serviceSlug === WELLNESS
                          ? '_self'
                          : '_blank'
                      }
                      onClick={() => {
                        setRecentServices(
                          isServiceManager ||
                            cumulativeService.serviceManagerSlug === OTHER
                            ? cumulativeService.serviceSlug
                            : servicesByName[cumulativeService.serviceManager]
                                ?.serviceSlug
                        )
                      }}
                    />
                  )}
                {disableLaunchButton || (
                  <Button
                    primary
                    data-testid={`${cumulativeService.slug}-service-detail-static-launch-button`}
                    label={
                      isServiceManager ||
                      (enablePCAI &&
                        cumulativeService.featuresSupported?.includes(
                          DEEP_LINKING
                        )) ||
                      (enableServiceDeepLinking &&
                        cumulativeService.featuresSupported?.includes(
                          DEEP_LINKING
                        ))
                        ? t('dashboard:common.launch')
                        : t('dashboard:common.launch_service_manager')
                    }
                    onClick={() => {
                      if (provisionedInstances.length === 1) {
                        const [instance] = provisionedInstances
                        serviceRedirect(
                          (enablePCAI &&
                            cumulativeService.featuresSupported?.includes(
                              DEEP_LINKING
                            )) ||
                            (enableServiceDeepLinking &&
                              cumulativeService.featuresSupported?.includes(
                                DEEP_LINKING
                              ))
                            ? servicesByName[cumulativeService.slug]
                            : servicesByName[serviceManagerSlug],
                          instance.region,
                          cumulativeService.slug
                        )
                      } else {
                        setShowLaunchModal(true)
                      }
                    }}
                  />
                )}
              </Box>
            </Box>
            {hasEval && (
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.orders.edit',
                  resource: '/ccs/orders'
                }}
              >
                <RequestEvalStatusText
                  evalStates={EVAL_STATES}
                  getDaysLeft={getDaysLeft}
                  requestEvalStatus={requestEvalStatus}
                />
              </VisibilityWrapper>
            )}
            {isOrgWorkSpace() && cumulativeService.serviceSlug !== 'PCE' && (
              <Notification
                type="inline"
                text={t('dashboard:service_details.service_cannot_be_used')}
                status="info"
                testId="service-not-available-info"
                margin={{ bottom: 'medium' }}
              />
            )}
            {provisionedInstances?.length &&
            configureDeviceNotification &&
            dmEnableDefaultRegion ? (
              <Box
                gap="small"
                direction="row"
                align="center"
                data-testid="device-configuration-anchor-info"
                background="background-back"
                margin={{ bottom: 'medium' }}
                pad="xsmall"
              >
                <CircleAlert size="medium" />
                <Typography
                  type="text"
                  size="small"
                  testId="device-configuration-anchor-label"
                >
                  <Trans
                    i18nKey="dashboard:service_catalog.default_service_notification"
                    t={t}
                  >
                    <Anchor
                      label={t('dashboard:common.device_configuration')}
                      target="_blank"
                      testId="device-configuration-anchor"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/devices/device-configuration')
                      }}
                    />
                  </Trans>
                </Typography>
              </Box>
            ) : null}
            <CatalogContent />
          </Box>
        </Box>
      ) : (
        <Box align="center" pad="small">
          <Loader testId="loader-spinner" />
        </Box>
      )}

      {evalModalFormOpen && (
        <RequestEvalModal
          listOfCountries={listOfCountries}
          countries={countries}
          setCountries={setCountries}
          evalServiceData={evalServiceData}
          setEvalModalFormOpen={setEvalModalFormOpen}
          setShowErrorNotification={setShowErrorNotification}
          setSuccessEval={setSuccessEval}
          evalFieldsMetaData={evalFieldsMetaData}
          formLoader={formLoader}
        />
      )}
    </Layout>
  )
}
export default ServiceDetail
