// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { Suspense, lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { AUTHZContextProvider } from '../../../context/authz-context'
import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import APIRouter from '../api/router'
import { isOrg, isV2 } from '../../../utils/feature-flag-utils'
import useFetchUserRoles from '../../../hooks/ui-doorway/useFetchUserRoles'

import { AuthzV2RolesRouter } from './authzV2-roles/router'
import { WorkspaceUserRouter } from './workspace-users/router'

const RolesRouter = lazy(() => import('./roles/router'))
const UsersRouter = lazy(() => import('./users/router'))
const GroupsRouter = lazy(() => import('../../igc-service/groups/router'))
const ScopeGroupsRouter = lazy(() => import('./scope-groups/router'))
const ScopeGroupsV2Router = lazy(() => import('./scopegroups/router'))

const WorkspaceGroupsRouter = lazy(() => import('./workspace-groups/router'))
const IdentityPage = lazy(() => import('./Identity'))

const IdentityRouterContent = () => {
  useFetchUserRoles()

  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<IdentityPage />} />
        {isV2() && <Route path="/roles/*" element={<AuthzV2RolesRouter />} />}
        {!isV2() && <Route path="/roles/*" element={<RolesRouter />} />}
        {isOrg() && <Route path="/users/*" element={<WorkspaceUserRouter />} />}
        {!isOrg() && <Route path="/users/*" element={<UsersRouter />} />}
        {isOrg() && (
          <Route path="/groups/*" element={<GroupsRouter isWorkspace />} />
        )}
        {isOrg() && (
          <Route path="/scopegroups/*" element={<ScopeGroupsV2Router />} />
        )}
        <Route path="/scope-groups/*" element={<ScopeGroupsRouter />} />
        <Route path="/workspace-groups/*" element={<WorkspaceGroupsRouter />} />
        <Route path="/api-client" element={<APIRouter />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

const IdentityRouter = () => {
  return (
    <AUTHZContextProvider>
      <IdentityRouterContent />
    </AUTHZContextProvider>
  )
}

export default IdentityRouter
