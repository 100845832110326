// #(C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { isUndefined, sortBy as sorting } from 'lodash'

import { Typography } from '../../../../components/typography/Typography'
import { get, patch, post, put } from '../../../../utils/api-utils'
import {
  getCategoryValue,
  getEnumValueAlertState,
  getEnumValueStatus,
  getEnumValueTaskState,
  getWorkspacesId,
  getRequestType
} from '../../../../commoncomponents/notifications-dashboard/utils'
import { paginateNext, errorResponseHandling } from '../utils'

export const fetchAlertCount = (
  oidcUser,
  setCount,
  stateV,
  dispatchNotifications
) => {
  post(
    '/notifications-svc/ui/v1alpha1/alerts-tasks-counter',
    {},
    oidcUser.access_token,
    false
  ).then(
    (response) => {
      const countData = response.data
      setCount({
        CRITICAL: countData.CRITICAL || 0,
        WARNING: countData.WARNING || 0,
        UNKNOWN: countData.UNKNOWN || 0
      })
      dispatchNotifications({
        type: 'COUNT_INFO',
        countInfo: {
          ...stateV.countInfo,
          totalCount: countData.TOTAL_COUNT || null
        }
      })
    },
    (error) => {
      console.log(error)
      setCount({
        CRITICAL: '-',
        WARNING: '-',
        UNKNOWN: '-'
      })
      dispatchNotifications({
        type: 'COUNT_INFO',
        countInfo: {
          ...stateV.countInfo,
          totalCount: null
        }
      })
    }
  )
}

export const fetchAccounts = (oidcUser, dispatchNotifications) => {
  get(
    '/accounts/ui/v1/customer/list-accounts?ignore_current_account=False&count_per_page=300',
    {},
    oidcUser.access_token
  ).then(
    (response) => {
      const accountData = response.data
      const accountList = Object.values(accountData.customers).map(
        (account) => ({
          valueLabel: account.company_name,
          valueName: account.platform_customer_id
        })
      )

      dispatchNotifications({
        type: 'WORKSPACES',
        workspaces: accountList
      })
    },
    (error) => {
      dispatchNotifications({
        type: 'WORKSPACES',
        workspaces: []
      })
      console.log(error)
    }
  )
}

export const fetchCategories = (oidcUser, dispatchNotifications) => {
  get(
    `/notifications-svc/ui/v1alpha1/alerts-tasks-categories`,
    {},
    oidcUser.access_token
  ).then(
    (response) => {
      // mock category as of now
      let appList = []
      const renderList = []
      const categoryData = response.data
      Object.entries(categoryData).forEach((service) => {
        const { service_name } = service[1].metadata
        Object.entries(service[1].topics).forEach((topic) => {
          const topic_name = topic[0]
          appList.push({ service_name, topic_name })
        })
      })
      appList = sorting(appList, ['service_name', 'topic_name'])

      appList.forEach((lists) => {
        const app = {
          valueLabel: (
            <Box>
              <Typography type="text" emphasis>
                {lists.topic_name}
              </Typography>{' '}
              <Typography type="text">{lists.service_name}</Typography>
            </Box>
          ),
          valueName: lists.service_name.concat('~', lists.topic_name),
          searchableLabels: [lists.service_name, lists.topic_name]
        }
        renderList.push(app)
      })
      dispatchNotifications({
        type: 'CATEGORIES',
        categories: renderList
      })
    },
    (error) => {
      dispatchNotifications({
        type: 'CATEGORIES',
        categories: []
      })
      console.log(error)
    }
  )
}

const fetchAlertsHistory = (oidcUser, setHistory, url) => {
  get(url, {}, oidcUser.access_token).then(
    (response) => {
      setHistory(response.data)
    },
    (error) => {
      console.log(error)
    }
  )
}

// Add critical notification incase the patch operation fails with the message
const patchEvents = (oidcUser, requestBody, val, setReadStatus, url) => {
  patch(url, requestBody, oidcUser.access_token).then(
    () => {
      setReadStatus(val)
    },
    (error) => {
      console.log(error)
    }
  )
}

const fetchSubTasks = (id, oidcUser, setSubTasks) => {
  const url = `/notifications-svc/ui/v1alpha1/tasks/${id}/sub-tasks`
  get(url, {}, oidcUser.access_token).then(
    (response) => {
      setSubTasks(response?.data)
    },
    (error) => {
      console.log(error)
    }
  )
}

const fetchAlerts = ({
  nextPageURI,
  currentPage,
  stateV,
  dispatchNotifications,
  itemsPerPage,
  oidcUser,
  setErrorMessage,
  t,
  setNotificationsExists,
  setIsLoader
}) => {
  dispatchNotifications({
    type: 'HIDE_PAGINATION',
    hidePagination: true
  })
  dispatchNotifications({
    type: 'LOADING_WITHOUT_FILTER',
    loadingWithOutFilter: true
  })
  dispatchNotifications({
    type: 'NOTIFICATION_EMPTY',
    notificationEmpty: false
  })
  dispatchNotifications({
    type: 'PAGE_DATA',
    pageData: []
  })
  if (isUndefined(nextPageURI)) {
    nextPageURI = ''
  }
  const sortBy = 'updated_at'
  dispatchNotifications({
    type: 'IS_LOADING',
    isLoading: false
  })
  let request_query_param = ''
  if (stateV.selectedType.length === 1) {
    if (stateV.selectedType[0].value === 'Alerts') {
      request_query_param = `&request_type=ALERT`
    } else {
      request_query_param = `&request_type=TASK`
    }
  }
  get(
    `/notifications-svc/ui/v1alpha1/events?channel=PORTAL&sort=${sortBy}&limit=${itemsPerPage}${nextPageURI}${request_query_param}`,
    '',
    oidcUser.access_token
  ).then(
    (response) => {
      if (response?.data?.count === 0 && request_query_param === '') {
        // key place to check for day zero scenario and responsible in setting the loader
        setNotificationsExists(false)
      } else {
        setNotificationsExists(true)
      }
      paginateNext(
        response,
        nextPageURI,
        currentPage,
        dispatchNotifications,
        stateV
      )
      // update the last seen notification id to the backend
      if (response?.data?.count && !nextPageURI) {
        const notificationData = response?.data?.items
        const lastSeenID = notificationData[0].id
        const notiUpdatedAt = notificationData[0].updatedAt
        let params = {}
        params = {
          event_id: lastSeenID,
          updated_at: notiUpdatedAt
        }
        put(
          `/notifications-svc/ui/v1alpha1/last-seen-alerts-tasks`,
          params,
          oidcUser.access_token
        ).then(
          () => {},
          () => {}
        )
      }
    },
    (error) => {
      setIsLoader(false)
      errorResponseHandling(error, dispatchNotifications, setErrorMessage, t)
    }
  )
}

const fetchAdvanceFilteredAlertsTasks = ({
  nextPageURI,
  currentPage,
  stateV,
  dispatchNotifications,
  itemsPerPage,
  oidcUser,
  setErrorMessage,
  t
}) => {
  dispatchNotifications({
    type: 'PAGE_DATA',
    pageData: []
  })
  dispatchNotifications({
    type: 'LOADING_WITH_FILTER',
    loadingWithFilter: true
  })
  dispatchNotifications({
    type: 'NOTIFICATION_EMPTY',
    notificationEmpty: false
  })
  dispatchNotifications({
    type: 'HIDE_PAGINATION',
    hidePagination: true
  })
  const sortBy = 'updated_at' // look into this
  const params = {
    ...(stateV.searchConfirm.trim().length && {
      search: stateV.searchConfirm.trim()
    }),
    status: getEnumValueStatus(stateV.filterValues.Status),
    alert_state: getEnumValueAlertState(stateV.filterValues.AlertState),
    task_state: getEnumValueTaskState(stateV.filterValues.TaskState),
    category: getCategoryValue(stateV.filterValues.Categories),
    workspaces: getWorkspacesId(stateV.filterValues.Workspaces),
    ...(stateV.selectedType.length === 1 && {
      request_type: stateV.selectedType
    }),
    request_type: getRequestType(stateV.selectedType),
    fromDate: stateV.timeFrame[0],
    toDate: stateV.timeFrame[1],
    read: stateV.read,
    sort_direction: stateV.sortDirection
  }
  if (isUndefined(nextPageURI)) {
    nextPageURI = ''
  }
  function getRequestBodyAlerts() {
    const filters = {
      search: params.search,
      alert_state: params.alert_state,
      task_state: params.task_state,
      status: params.status,
      category: params.category,
      workspace: params.workspaces,
      fromDate: params.fromDate,
      toDate: params.toDate,
      request_type: params.request_type,
      limit: itemsPerPage,
      sort_by: sortBy,
      next: `${nextPageURI?.replace('&next=', '')}`,
      sort_direction: params.sort_direction
      // channel: [`PORTAL`]
    }
    if (params.read === true) {
      filters.read = false
    }
    return filters
  }

  post(
    `/notifications-svc/ui/v1alpha1/events`,
    getRequestBodyAlerts(),
    oidcUser.access_token,
    false
  ).then(
    (response) => {
      paginateNext(
        response,
        nextPageURI,
        currentPage,
        dispatchNotifications,
        stateV
      )
      // response with filters
      dispatchNotifications({
        type: 'COUNT_INFO',
        countInfo: {
          ...stateV.countInfo,
          totalHits: response?.data?.totalHits
        }
      })
    },
    (error) => {
      errorResponseHandling(error, dispatchNotifications, setErrorMessage, t)
    }
  )
}

export {
  fetchAdvanceFilteredAlertsTasks,
  fetchAlerts,
  fetchAlertsHistory,
  fetchSubTasks,
  patchEvents
}
