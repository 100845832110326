// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Trans, useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import {
  Typography,
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Loader
} from '../../../../../components'
import { put } from '../../../../../utils/api-utils'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'

const RemoveRoleAssignmentModal = ({
  setShowRemoveRoleAssignmentModal,
  username,
  selectedRoleAssignment,
  reloadTable,
  inCCSManager = false,
  customerId = undefined,
  setShowNotification = () => {},
  onSuccessRemoval = () => {}
}) => {
  const { oidcUser } = useReactOidc()
  const { t: commonT } = useTranslation(['common'])
  const { t: authzT } = useTranslation(['authz'])

  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [loading, setLoading] = useState(false)

  const successMessage = (
    <Trans
      i18nKey="assignments.remove_role_assignment_success_notification_text"
      t={authzT}
      values={{
        roleName: selectedRoleAssignment.role_name
      }}
      components={[<strong />]}
    />
  )

  const removeRoleAssignmentAPICall = () => {
    setLoading(true)
    const requestBodyRoles = {
      delete: [
        {
          slug: selectedRoleAssignment.slug,
          application_id: selectedRoleAssignment.application_id
        }
      ]
    }

    const requestBody = inCCSManager
      ? {
          roles: requestBodyRoles,
          username,
          platform_customer_id: customerId
        }
      : requestBodyRoles
    const url = inCCSManager
      ? `/support-assistant/v1alpha1/user-roles`
      : `/authorization/ui/v2/customers/users/${username}/roles`

    put(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setLoading(false)
          setShowErrorNotification(null)
          setShowNotification(
            displayNotification(successMessage, 'info', setShowNotification)
          )
          setShowRemoveRoleAssignmentModal(false)
          reloadTable()
          onSuccessRemoval()
        }
      },
      (error) => {
        setLoading(false)
        setShowErrorNotification(
          displayApiError(error, commonT, setShowErrorNotification)
        )
      }
    )
  }

  return (
    <>
      {showErrorNotification}
      <ModalDialog
        width="large"
        testId="remove-role-assignment-modal-dialog"
        header={
          <ModalHeader
            title={
              <Typography type="heading" level="1" testId="delete-title">
                {authzT('assignments.remove_role')}
              </Typography>
            }
          />
        }
        content={
          <Box margin={{ top: 'medium' }} gap="small">
            <Typography type="text" size="large" textId="delete-message">
              <Trans
                i18nKey="assignments.remove_role_assignment_confirm_msg"
                t={authzT}
              >
                <strong>
                  {{ roleName: selectedRoleAssignment.role_name }}
                </strong>
              </Trans>
            </Typography>
            {loading && (
              <Box direction="row" align="center" justify="center">
                <Loader testId="assign-role-loader" />
              </Box>
            )}
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 1,
                    label: authzT('rrp.close'),
                    default: true,
                    testId: 'cancel-btn',
                    onClick: () => setShowRemoveRoleAssignmentModal(false)
                  },
                  {
                    id: 2,
                    label: authzT('assignments.remove_role'),
                    primary: true,
                    testId: 'save-btn',
                    onClick: removeRoleAssignmentAPICall,
                    disabled: loading
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
        onClose={() => setShowRemoveRoleAssignmentModal(false)}
      />
    </>
  )
}

RemoveRoleAssignmentModal.propTypes = {
  setShowRemoveRoleAssignmentModal: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  selectedRoleAssignment: PropTypes.object.isRequired,
  reloadTable: PropTypes.func.isRequired,
  inCCSManager: PropTypes.bool,
  customerId: PropTypes.string,
  setShowNotification: PropTypes.func,
  onSuccessRemoval: PropTypes.func
}

export { RemoveRoleAssignmentModal }
