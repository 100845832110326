// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Box, Markdown, Text } from 'grommet'
import {
  StatusGoodSmall,
  StatusCriticalSmall,
  StatusWarningSmall,
  StatusDisabledSmall
} from 'grommet-icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import { updateStatusName } from '../utils'
import { DataTable, Button, ModalDialog } from '../../../components'
import { displayNotification } from '../../../utils/notificiation-utils'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const WebhookDataTable = ({ data, onOpenDrawerHandler }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['authz'])
  const location = useLocation()
  const [successMessage, setSuccessMessage] = useState(null)
  const [successTitle, setSuccessTitle] = useState(null)
  const [isOpenWebhookLimitDialog, setIsOpenWebhookLimitDialog] =
    useState(false)

  const handleRowClick = (datum) => {
    navigate(`/manage-account/automations/webhooks/${datum?.id}`)
  }

  const handleClose = () => {
    setIsOpenWebhookLimitDialog(false)
  }

  const onCreateWebhook = () => {
    if (data.length >= 5) {
      setIsOpenWebhookLimitDialog(true)
    } else {
      onOpenDrawerHandler(true)
    }
  }

  const columns = [
    {
      property: 'name',
      header: t('automations:webhooks.name_table_header'),
      render: (param) => (
        <Box style={{ color: 'text', fontWeight: 500 }}>{param.name}</Box>
      )
    },
    {
      property: 'destination',
      header: t('automations:webhooks.url_table_header'),
      render: (param) => (
        <Box color="text" style={{ fontWeight: 400 }}>
          {param.destination}
        </Box>
      )
    },
    {
      property: 'status',
      header: t('automations:webhooks.state_table_header'),
      render: (datum) => (
        <Box
          style={{
            display: 'flex',
            gap: '5px',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {datum.status === 'ACTIVE' && (
            <StatusGoodSmall
              style={{ width: ' 12px', height: '12px' }}
              color="#1beaa0"
            />
          )}
          {datum.status === 'ERROR' && (
            <StatusCriticalSmall
              style={{ width: ' 12px', height: '12px' }}
              color="status-critical"
            />
          )}
          {datum.status === 'WARNING' && (
            <StatusWarningSmall
              style={{ width: ' 12px', height: '12px' }}
              color="status-warning"
            />
          )}
          {(datum.status === 'DISABLED' || datum.status === 'PENDING') && (
            <StatusDisabledSmall
              style={{ width: ' 12px', height: '12px' }}
              color="status-unknown"
            />
          )}

          <span>{updateStatusName(datum.status.toLowerCase())}</span>
        </Box>
      )
    },
    {
      property: 'description',
      header: t('automations:webhooks.description_table_header'),
      render: (param) => (
        <span>{param.description ? param.description : '--'}</span>
      )
    }
  ]

  useEffect(() => {
    setSuccessTitle(location?.state?.notificationTitle || null)
    const message = location?.state?.notificationDesc ? (
      <Markdown>{location?.state?.notificationDesc}</Markdown>
    ) : null
    setSuccessMessage(message)
    location.state = {
      ...location.state,
      notificationTitle: null,
      notificationDesc: null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state])

  return (
    <>
      <Box
        flex
        align="start"
        direction="column"
        justify="start"
        maxWidth="100%"
        boxSizing="border-box"
      >
        <Box>
          <Box
            flex
            align="center"
            direction="row"
            justify="between"
            margin={{ bottom: 'medium' }}
          >
            <Box>
              <Text size="xlarge" color="black" style={{ fontWeight: 500 }}>
                {t('automations:webhooks.card_title')}
              </Text>
            </Box>
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/events/webhook',
                permission: 'ccs.events.webhook.create'
              }}
            >
              <Button
                label={t('automations:webhooks.create_webhook_btn_label')}
                onClick={onCreateWebhook}
                testId="create-webhook"
                secondary
              />
            </VisibilityWrapper>
          </Box>
          <DataTable
            grid={{
              columns,
              data,
              pad: {
                top: 'xxsmall',
                bottom: 'xxsmall',
                left: 'small',
                right: 'small'
              },
              onClickRow: (e) => {
                handleRowClick(e.datum)
              }
            }}
            testId="client-api-table"
          />
        </Box>
      </Box>

      {isOpenWebhookLimitDialog ? (
        <ModalDialog
          content={
            <Box>
              <Box direction="column">
                <h2 style={{ fontWeight: '500' }}>
                  {t('automations:webhooks.create_webhook_btn_label')}
                </h2>
              </Box>
              <Box direction="column">
                {t('automations:webhooks.webhook_register_limit_msg')}
              </Box>
            </Box>
          }
          footer={
            <Box style={{ display: 'flex', alignItems: 'end' }}>
              <Button
                primary
                label="Close"
                onClick={handleClose}
                testId="webhook-reach-limit-close-btn"
              />
            </Box>
          }
          onClose={handleClose}
          testId="webhook-reach-limit-modal"
          width="large"
        />
      ) : null}
      {successMessage &&
        displayNotification(
          successMessage,
          'info',
          setSuccessMessage,
          successTitle
        )}
    </>
  )
}

WebhookDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  onOpenDrawerHandler: PropTypes.func.isRequired
}

export { WebhookDataTable }
