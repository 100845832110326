// Copyright 2024 Hewlett Packard Enterprise Development LPs
import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { isFederatedAccount } from '../../utils/common-utils'

import ProfileDetails from './details/ProfileDetails'
import ProfilePreferences from './preferences/ProfilePreferences'
import ProfileSecurity from './security/ProfileSecurity'
import { ResetPassword } from './reset-password'

const ProfileRouter = ({ user }) => {
  return (
    <Routes>
      {!isFederatedAccount() && (
        <>
          <Route path="/password" element={<ResetPassword fromProfile />} />
          <Route
            path="/password:accountlist"
            element={<ResetPassword fromProfile />}
          />
        </>
      )}
      <Route path="/security" element={<ProfileSecurity />} />
      <Route path="/security:accountlist" element={<ProfileSecurity />} />
      <Route path="/details" element={<ProfileDetails user={user} />} />
      <Route
        path="/details:accountlist"
        element={<ProfileDetails user={user} />}
      />
      <Route path="/preferences" element={<ProfilePreferences />} />
      <Route path="/preferences:accountlist" element={<ProfilePreferences />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export default ProfileRouter

ProfileRouter.propTypes = {
  user: PropTypes.object.isRequired
}
