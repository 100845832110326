// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Box, Markdown } from 'grommet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Loader, Typography, Wizard } from '../../../components'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import { get, post } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { IAAS_KEY_TYPE, NOT_CLAIMED_STATUS } from '../utils'
import { SUPPORT_URL } from '../../../utils/error-utils'
import { AUDIT_LOG_CATEGORY } from '../../../utils/common-utils'
import {
  validateAddSubscriptions,
  validateAssignInstance
} from '../validation-utils'
import { ServiceDeploymentWizard } from '../components/ServiceDeploymentWizard'

import { AddSubscriptions, AssignInstance, ReviewAndFinish } from './steps'
import { AddProductsEmptyTemplate } from './components'

const AddProductsWizard = () => {
  const { company_name: workspaceName } = getCustomerAccount()
  const { t } = useTranslation(['licensing', 'common'])
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const { productSquid } = useParams()
  const [showAssignInstance, setShowAssignInstance] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [productList, setProductList] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [activeWizard, setActiveWizard] = useState('')

  useEffect(() => {
    setIsLoading(true)
    get(
      '/ui-doorway/ui/v1/license/orders',
      { squid: productSquid },
      oidcUser.access_token
    )
      .then(
        (response) => {
          const subscriptionList = response?.data?.subscription_keys?.filter(
            (value) => value.claim_status === NOT_CLAIMED_STATUS
          )
          if (!subscriptionList?.length) setActiveWizard('EMPTY_STATE')
          else {
            const serviceRequiredSubscriptionList = subscriptionList?.filter(
              (value) =>
                value.key_type === IAAS_KEY_TYPE &&
                !value.available_applications?.length
            )
            if (serviceRequiredSubscriptionList?.length) {
              setActiveWizard('SERVICE_DEPLOYMENT')
            } else {
              setActiveWizard('ADD_PRODUCTS')
            }
            setProductList(subscriptionList)
          }
        },
        (error) => {
          if (error.response.status === 404)
            navigate('/add-products/invalid-link')
          setErrorMessage(displayApiError(error, t, setErrorMessage))
        }
      )
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token])

  const handlefinish = (formValues) => {
    const requestBody = {
      subscriptions: formValues?.selectedProductList?.map((value) => {
        const applicationInfo =
          formValues?.selectedApplication[value?.subscription_key]
        return {
          subscription_key: value?.subscription_key,
          ...(applicationInfo && {
            application: {
              applicationId: applicationInfo?.application_id,
              region: applicationInfo?.ccs_region
            }
          })
        }
      })
    }
    post(
      '/ui-doorway/ui/v1/license/claim',
      requestBody,
      oidcUser.access_token
    ).then(
      () => {
        navigate('/add-products/success')
      },
      () => {
        navigate('/home', {
          state: {
            bannerErrorMessage: {
              message: 'licensing:subscription_claim_error_msg',
              name: AUDIT_LOG_CATEGORY.SUBSCRIPTION_MANAGEMENT,
              href: SUPPORT_URL
            }
          }
        })
      }
    )
  }

  const handleContinue = () => {
    setActiveWizard('ADD_PRODUCTS')
  }

  const isContinueButtonRequired = () => {
    return !!productList?.find(
      (value) =>
        value.key_type !== IAAS_KEY_TYPE ||
        value?.available_applications?.length
    )
  }

  const getActiveWizard = () => {
    switch (activeWizard) {
      case 'EMPTY_STATE':
        return (
          <AddProductsEmptyTemplate
            title={t('add_products.all_products_claimed')}
            description={t('add_products.all_products_claimed_desc')}
          />
        )
      case 'SERVICE_DEPLOYMENT':
        return (
          <ServiceDeploymentWizard
            handleContinue={handleContinue}
            isContinueButtonRequired={isContinueButtonRequired}
            defaultFormValues={{
              serviceRequiredProductList: productList
            }}
          />
        )
      case 'ADD_PRODUCTS':
        return (
          <Wizard
            actionOnExit={() => {
              navigate('/home')
            }}
            actionOnSubmit={handlefinish}
            formDefaultValues={{
              selectedProductList: [],
              selectedApplication: {}
            }}
            steps={[
              {
                childComponents: (
                  <AddSubscriptions
                    onAssignInstance={(status) => {
                      setShowAssignInstance(status)
                    }}
                  />
                ),
                description: (
                  <Typography
                    type="paragraph"
                    testId="step-description"
                    size="large"
                  >
                    <Markdown>
                      {t('add_products.add_subscriptions_desc', {
                        workspaceName
                      })}
                    </Markdown>
                  </Typography>
                ),
                title: t('add_products.add_subscriptions'),
                validateForm: (formValues) =>
                  validateAddSubscriptions(formValues, t)
              },
              ...(showAssignInstance
                ? [
                    {
                      childComponents: <AssignInstance />,
                      description: (
                        <Typography
                          type="paragraph"
                          testId="step-description"
                          size="large"
                        >
                          <Markdown>
                            {t('add_products.assign_instance_desc')}
                          </Markdown>
                        </Typography>
                      ),
                      title: t('add_products.assign_instance'),
                      validateForm: (formValues) =>
                        validateAssignInstance(formValues, t)
                    }
                  ]
                : []),
              {
                childComponents: <ReviewAndFinish />,
                description: (
                  <Typography
                    type="paragraph"
                    testId="step-description"
                    size="large"
                  >
                    <Markdown>
                      {t('add_products.review_and_finish_desc', {
                        workspaceName
                      })}
                    </Markdown>
                  </Typography>
                ),
                title: t('add_products.review_and_finish')
              }
            ]}
            testId="add-products-wizard"
            title={t('add_products.add_products')}
          />
        )
      default:
        return null
    }
  }

  return (
    <Box>
      {isLoading ? <Loader testId="loader-spinner" /> : getActiveWizard()}
      {errorMessage}
    </Box>
  )
}

export default AddProductsWizard
