// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Menu } from 'grommet'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

import {
  FilterButton,
  ToggleButton,
  Typography,
  Search,
  MultiSelectBox
} from '../../components'
import { readStatus } from '../../utils/notification-logs-utils'

import { getDateTime } from './utils'
import { SortDropButton } from './SortDropButton'

const FilterOptions = ({
  stateV = {},
  dispatchNotifications = () => {},
  areFiltersApplied = () => {},
  filterAttributes = [],
  type = ''
}) => {
  const { t } = useTranslation(['manage'])
  const debouncedSetSearchTerm = debounce((value) => {
    dispatchNotifications({
      type: 'DEBOUNCED_SEARCH_TERM',
      debouncedSearchTerm: value.trim()
    })
  }, 750)
  // values of filter when they undergo reset
  const [intialSetFilterValues, setInitialSetFilterValues] = useState({})
  const hasMounted = useRef(false)
  // when the selected type change we need to reset the filter values
  // this is the main keypoint point for all the reset of the filter values
  useEffect(() => {
    // perform reset on chnage of selected Type

    if (hasMounted.current) {
      dispatchNotifications({
        type: 'FILTER_VALUES',
        filterValues: {}
      })
      setInitialSetFilterValues({})
      dispatchNotifications({
        type: 'TIME_FRAME',
        timeFrame: NaN
      })
      dispatchNotifications({
        type: 'SHOW_DATE',
        showDate: false
      })
    } else {
      hasMounted.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateV.selectedType])

  const [values, setValues] = useState([])
  useEffect(() => {
    setValues([
      {
        label: t('notification.mark_read'),
        onClick: () => {
          dispatchNotifications({
            type: 'SELECTED_READ_STATUS',
            selectedReadStatus: readStatus.mark_read
          })
        },
        disabled: stateV.disabledAction.includes(readStatus.mark_read)
      },
      {
        label: t('notification.mark_unread'),
        onClick: () => {
          dispatchNotifications({
            type: 'SELECTED_READ_STATUS',
            selectedReadStatus: readStatus.mark_unread
          })
        },
        disabled: stateV.disabledAction.includes(readStatus.mark_unread)
      }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateV.selectedCheckBoxStatus, stateV.disabledAction, type])
  useEffect(() => {
    dispatchNotifications({
      type: 'SEARCH_CONFIRM',
      searchConfirm: stateV.debouncedSearchTerm
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateV.debouncedSearchTerm])

  return (
    <Box>
      <Box direction="row-responsive" justify="between">
        <Box
          direction="row-responsive"
          justify="start"
          align="end"
          pad="none"
          gap="small"
          wrap
        >
          <Search
            handleCustomSearch={(value) => {
              if (value.trimStart().length > 2) {
                debouncedSetSearchTerm(value.trim())
              } else {
                debouncedSetSearchTerm('')
              }
            }}
            placeholder={t('notification.search_notifications')}
            data-testid="search-dropdown"
            testId="search-bar"
            width={{ width: 'medium', min: 'xsmall' }}
          />
          <Box direction="row" flex={false}>
            <SortDropButton
              stateV={stateV}
              dispatchNotifications={dispatchNotifications}
            />
            <FilterButton
              dialogPosition="right"
              dialogHeight="100%"
              testId="notification-funnel-button"
              filterAttributes={filterAttributes}
              initSelectedFilterValues={intialSetFilterValues}
              onFilterValuesChange={(filter) => {
                if (!isEqual(filter, stateV.filterValues)) {
                  dispatchNotifications({
                    type: 'FILTER_VALUES',
                    filterValues: filter
                  })

                  if ('Date Range' in filter && 'Custom Date Range' in filter) {
                    dispatchNotifications({
                      type: 'TIME_FRAME',
                      timeFrame: getDateTime(
                        filter['Date Range'],
                        filter['Custom Date Range']
                      )
                    })
                  } else if ('Date Range' in filter) {
                    dispatchNotifications({
                      type: 'TIME_FRAME',
                      timeFrame: getDateTime(filter['Date Range'], NaN)
                    })
                  } else {
                    dispatchNotifications({
                      type: 'TIME_FRAME',
                      timeFrame: NaN
                    })
                  }
                }
              }}
            />
          </Box>
          {type === 'ALERTSTASKS' && (
            <Box width={{ width: 'small', min: 'xsmall' }}>
              <MultiSelectBox
                name="selecttype"
                testId="status-dropdown-filter"
                placeholder={
                  <Typography type="text" color="grey">
                    {t('notification.select_alerts_and_topics')}
                  </Typography>
                }
                options={[
                  {
                    label: t('notification.alerts'),
                    value: 'Alerts'
                  },
                  {
                    label: t('notification.tasks'),
                    value: 'Tasks'
                  }
                ]}
                value={stateV.selectedType}
                onChange={(value) => {
                  if (
                    value !== stateV.selectedType &&
                    Math.abs(value.length - stateV.selectedType.length) === 1
                  ) {
                    dispatchNotifications({
                      type: 'SELECTED_TYPE',
                      selectedType: value
                    })
                    dispatchNotifications({
                      type: 'PAGE',
                      page: 1
                    })
                  }
                }}
              />
            </Box>
          )}

          <Box direction="row">
            <Typography
              type="text"
              width={{ width: 'xxsmall', min: 'xxsmall' }}
              margin={{ top: 'xsmall' }}
            >
              {t('notification.toggle')}
            </Typography>
            <ToggleButton
              onChange={(event) => {
                dispatchNotifications({
                  type: 'READ',
                  read: event.target.checked
                })
              }}
              testId="toggle-btn-unread"
              checked={stateV.read}
            />
          </Box>
        </Box>
        <Box direction="row" justify="end" align="center" gap="small">
          <Menu
            label={t('notification.actions')}
            data-testId="actions-dropdown"
            kind="toolbar"
            placeholder={t('notification.actions')}
            items={values}
            value={stateV.selectedReadStatus}
            onChangeDropdown={(value) => {
              dispatchNotifications({
                type: 'SELECTED_READ_STATUS',
                value
              })
            }}
          />
        </Box>
      </Box>
      {!(stateV.loadingWithFilter || stateV.loadingWithOutFilter) && (
        <Box data-testId="total-count-info">
          {areFiltersApplied(true) ? (
            <Typography type="text">
              {t('notification.alerts_tasks.total_results', {
                totalHits: stateV?.countInfo?.totalHits,
                count: stateV?.countInfo?.totalCount
              })}
            </Typography>
          ) : (
            <Typography type="text">
              {stateV?.countInfo?.totalCount !== null
                ? t('notification.alerts_tasks.total_items', {
                    count: stateV?.countInfo?.totalCount
                  })
                : null}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

FilterOptions.propTypes = {
  stateV: PropTypes.object,
  dispatchNotifications: PropTypes.func,
  areFiltersApplied: PropTypes.func,
  filterAttributes: PropTypes.array,
  type: PropTypes.string
}

export { FilterOptions }
