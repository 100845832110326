// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react'
import { Box, TextInput, FormField } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../components'
import { WizardContext } from '../../../../../components/wizard/WizardContext'

const VerifyAndAddDomainModal = ({
  errorMsg = '',
  setReadable = '',
  setErrorMsg = undefined
}) => {
  const { formValues, setFormValues } = useContext(WizardContext)
  const { t } = useTranslation(['iam'])

  return (
    <Box margin={{ bottom: 'medium' }} width="medium">
      <Box margin={{ top: 'small', bottom: 'small' }}>
        <Typography
          testId="domain-name"
          type="text"
          size="xsmall"
          margin={{ bottom: 'xsmall' }}
          emphasis
        >
          {t('domains.domain_name')}*
        </Typography>
        <Typography type="text" testId="domain-title" size="xsmall">
          {t('domains.domain_title')}
        </Typography>
      </Box>
      <Box>
        {setReadable ? (
          <Typography type="text" testId="domain-name-value" emphasis>
            {setReadable}
          </Typography>
        ) : (
          <FormField
            name="domainName"
            required
            error={errorMsg}
            data-testid="domain-name-form-field"
          >
            <TextInput
              data-testid="domain-name-input"
              name="domainName"
              onChange={(e) => {
                if (e.target.value) {
                  setErrorMsg('')
                  setFormValues({
                    ...formValues,
                    domainName: e.target.value
                  })
                }
              }}
              maxLength={255}
            />
          </FormField>
        )}
      </Box>
    </Box>
  )
}

VerifyAndAddDomainModal.propTypes = {
  setReadable: PropTypes.string,
  errorMsg: PropTypes.string,
  setErrorMsg: PropTypes.func
}

export default VerifyAndAddDomainModal
