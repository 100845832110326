// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Group } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ActionButton,
  AvatarInfo,
  DataTable,
  Loader,
  Typography
} from '../../../../components'
import { get, put } from '../../../../utils/api-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import { getPaginationShowIdx } from '../../../../utils/common-utils'
import { displayApiError } from '../../../../utils/error-handling-utils'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { customRenderer } from '../../../../commoncomponents/CustomRenderer'
import { getPermission } from '../../utils'
import {
  getStatusForMultiStatusResponse,
  getNotificationMessageForMultiStatusResponse
} from '../../../../utils/ccs-manager-utils'

import DeleteUserModal from './DeleteUserModal'
import ConfirmDeleteUserModal from './ConfirmDeleteUserModal'
import ModifyAccessPermissionModalSA from './ModifyAccessPermissionModalSA'

const SupportAccessUsersDatatable = React.memo(
  ({ refreshCount, refreshDataTable }) => {
    dayjs.extend(relativeTime)
    const { t } = useTranslation([
      'authn',
      'support_access_manager',
      'manage',
      'authz'
    ])
    const { oidcUser } = useReactOidc()
    const itemsPerPage = 10
    const [totalItems, setTotalItems] = useState(itemsPerPage)
    const [page, setPage] = useState(1)
    const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)
    const [isLoading, setIsLoading] = useState(true)
    const [apiUserData, setApiUserData] = useState([])
    const [userData, setUserData] = useState([])
    const [showErrorNotification, setShowErrorNotification] = useState(null)
    const [showNotification, setShowNotification] = useState(null)
    const [selectedRowData, setSelectedRowData] = useState({})
    const [isBulkAction, setIsBulkAction] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [searchStr, setSearchStr] = useState('')
    const [notifyComponent, setNotifyComponent] = useState(null)
    const [deleteUsersLayerOpen, setDeleteUsersLayerOpen] = useState(false)
    const [confirmDeleteUsersLayerOpen, setConfirmDeleteUsersLayerOpen] =
      useState(false)
    const LDFlags = useFlags()
    const samLDFlagPhase2 = LDFlags['glcp-support-access-manager-phase2']
    const [
      showModifyAccessPermissionsModal,
      setShowModifyAccessPermissionsModal
    ] = useState(false)
    const uiMsg = {
      write: t('support_access_manager:write_access'),
      read: t('support_access_manager:read_access')
    }
    useEffect(() => {
      setIsLoading(true)
      get(
        `/support-assistant/v1alpha1/support-access-users`,
        {},
        oidcUser.access_token
      )
        .then(
          (resp) => {
            setApiUserData(resp?.data || [])
            setSelectedRows([])
          },
          (error) => {
            setShowErrorNotification(
              displayApiError(error, t, setShowErrorNotification)
            )
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    }, [oidcUser.access_token, refreshCount, t])

    const getUserFullName = (datum) => {
      const firstName = datum?.user_first_name
      const lastName = datum?.user_last_name
      if (firstName && lastName) {
        return `${firstName} ${lastName}`
      }
      return firstName || lastName || datum?.user_name || ''
    }

    const modifyPermissionSupportAccessCb = (supportEngineerInfo) => {
      const requestBody = {
        username: supportEngineerInfo?.email,
        access_type: supportEngineerInfo?.access_type
      }
      put(
        `/support-assistant/v1alpha1/support-role-assignment`,
        requestBody,
        oidcUser.access_token
      ).then(
        () => {
          refreshDataTable()

          const notification = displayNotification(
            t('support_access_manager:modify_access.modify_user_permission'),
            'info',
            setNotifyComponent
          )
          setNotifyComponent(notification)
        },
        (error) => {
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
    }

    useEffect(() => {
      if (apiUserData?.users?.length) {
        let supportUsersResponse = [...(apiUserData?.users || [])]
        if (searchStr?.length) {
          supportUsersResponse = supportUsersResponse.filter((user) => {
            return (
              user?.user_name
                ?.toLowerCase()
                .includes(searchStr.toLowerCase()) ||
              getUserFullName(user)
                .toLowerCase()
                .includes(searchStr.toLowerCase()) ||
              user?.role_name.toLowerCase().includes(searchStr.toLowerCase()) ||
              user?.user_status.toLowerCase().includes(searchStr.toLowerCase())
            )
          })
        }
        setTotalItems(supportUsersResponse?.length || 0)
        const offset = (page - 1) * itemsPerPage
        setUserData(supportUsersResponse.splice(offset, itemsPerPage))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchStr, apiUserData, setTotalItems, page])

    const columns = [
      {
        header: t('authn:customer_account.contact_name_placeholder'),
        type: 'string',
        property: 'full_name',
        render: (datum) => (
          <Box direction="row" align="center" gap="small">
            <Box flex={{ shrink: 0 }}>
              <AvatarInfo
                avatarChar={
                  datum?.user_first_name && datum?.user_last_name
                    ? 'two'
                    : 'one'
                }
                primaryInfo={getUserFullName(datum)}
                secondaryInfo=""
                testId="user-info-avatar-only"
                avatarOnly
              />
            </Box>
            <Typography type="text" testId="username">
              {getUserFullName(datum)}
            </Typography>
          </Box>
        )
      },
      {
        property: 'user_name',
        type: 'string',
        primary: true,
        header: t('authn:customer_account.email')
      },
      {
        property: 'user_status',
        type: 'string',
        header: t('users.status')
      },
      ...(samLDFlagPhase2
        ? [
            {
              align: 'start',
              header: t('authz:assignments.permission'),
              property: 'access_type',
              render: (datum) => {
                const access_type = getPermission(datum?.role_name)
                return <Box>{access_type ? uiMsg[access_type] : '--'}</Box>
              },
              sortable: false
            }
          ]
        : []),
      {
        property: 'role_name',
        type: 'string',
        header: t('users.platform_role'),
        render: (datum) => {
          return <Box width="medium">{datum?.role_name || '--'}</Box>
        }
      },
      ...(samLDFlagPhase2
        ? [
            {
              property: 'last_active',
              type: 'string',
              header: t('authn:users.last_active'),
              render: (datum) => {
                return dayjs(datum?.lastActive).format('MMM DD, YYYY hh:mm A')
              }
            }
          ]
        : []),
      {
        property: 'actions',
        header: '',
        render: (datum) => (
          <Box>
            {samLDFlagPhase2 &&
            (datum?.role_name.includes('observer') ||
              datum?.role_name.includes('operator')) ? (
              <ActionButton
                showOneActionAsDropDown
                actions={[
                  {
                    label: t('users.delete'),
                    id: 1,
                    onClick: () => {
                      setIsBulkAction(false)
                      setSelectedRowData(datum)
                      setDeleteUsersLayerOpen(true)
                    }
                  },
                  {
                    label: t(
                      'support_access_manager:modify_access.modify_action'
                    ),
                    id: 2,
                    onClick: () => {
                      setSelectedRowData(datum)
                      setShowModifyAccessPermissionsModal(true)
                    }
                  }
                ]}
                testId="users-table-action-btn"
                customRenderer={(actionBtn, visibility) => {
                  return (
                    !actionBtn.hidden && (
                      <VisibilityWrapper
                        key={actionBtn.key}
                        rbac={visibility?.rbac}
                      >
                        {actionBtn}
                      </VisibilityWrapper>
                    )
                  )
                }}
              />
            ) : (
              <Box>
                {datum?.role_name.includes('observer') && (
                  <ActionButton
                    showOneActionAsDropDown
                    actions={[
                      {
                        label: t('users.delete'),
                        id: 1,
                        onClick: () => {
                          setIsBulkAction(false)
                          setSelectedRowData(datum)
                          setDeleteUsersLayerOpen(true)
                        }
                      }
                    ]}
                    testId="users-table-action-btn"
                    customRenderer={(actionBtn, visibility) => {
                      return (
                        !actionBtn.hidden && (
                          <VisibilityWrapper
                            key={actionBtn.key}
                            rbac={visibility?.rbac}
                          >
                            {actionBtn}
                          </VisibilityWrapper>
                        )
                      )
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        )
      }
    ]
    const actions = [
      {
        label: t('users.delete'),
        onClick: () => {
          const isAdminRoleAvailable = selectedRows.some((row) =>
            row.role_name.includes('admin')
          )
          if (isAdminRoleAvailable) {
            setShowErrorNotification(
              displayNotification(
                t('support_access_manager:sa_invite_user_delete_admin'),
                'error',
                setShowErrorNotification
              )
            )
          } else {
            setIsBulkAction(true)
            setDeleteUsersLayerOpen(true)
          }
        }
      }
    ]

    return (
      <>
        {notifyComponent}
        {isLoading && (
          <Box
            direction="row"
            align="center"
            justify="center"
            margin={{ top: 'large' }}
          >
            <Loader testId="devices-loader" />
          </Box>
        )}
        {!isLoading && apiUserData?.users?.length > 0 && (
          <DataTable
            loading={isLoading}
            pagination={{
              totalItems,
              itemsPerPage,
              page,
              setPage,
              pageIdxInfo
            }}
            search={{
              onSearchValueChange: (value) => {
                setPage(1)
                setSearchStr(value)
              },
              defaultVal: searchStr,
              placeholder: t('common:search'),
              width: 'medium'
            }}
            grid={{
              columns,
              data: userData,
              pad: { body: { horizontal: 'small', vertical: 'small' } },
              verticalAlign: 'middle'
            }}
            selection={{
              bulkActions: {
                actionDropdown: {
                  label: t('common:actions')
                },
                actions,
                customRenderer: (element, visibility, index) =>
                  customRenderer(element, visibility, index)
              },
              onSelectionChange: (data) => {
                const res = data.map((val) => {
                  return userData.find((obj) => obj?.user_name === val)
                })
                setSelectedRows(res)
              },
              selectionInfoMessage: t('common:data_table_warning_msg'),
              primaryKey: 'user_name'
            }}
            summary={{
              entityName: t('users.users')
            }}
            testId="user-data-table"
          />
        )}
        {!isLoading && apiUserData?.users?.length === 0 && (
          <Box data-testid="support-access" flex direction="column">
            <Box flex align="center" justify="center">
              <Box>
                <Group size="large" />
              </Box>
              <Box>
                <Typography type="text" weight="bold" size="medium">
                  {t('support_access_manager:sa_invite_user')}
                </Typography>
              </Box>
              <Box>
                <Typography type="text" size="small">
                  {t('support_access_manager:sa_invite_user_desc')}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {deleteUsersLayerOpen && (
          <DeleteUserModal
            onSetOpen={setDeleteUsersLayerOpen}
            onConfirmDelete={setConfirmDeleteUsersLayerOpen}
            selectedUsers={isBulkAction ? selectedRows : [selectedRowData]}
            selfDelete={selectedRows
              .map((item) => item.user_name)
              .includes(oidcUser.profile.email)}
          />
        )}
        {confirmDeleteUsersLayerOpen && (
          <ConfirmDeleteUserModal
            onSetOpen={setConfirmDeleteUsersLayerOpen}
            onSetClose={() => {
              refreshDataTable()
            }}
            emails={
              isBulkAction
                ? selectedRows.map((val) => val?.user_name)
                : [selectedRowData?.user_name]
            }
            deleteUsersCb={(response) => {
              if (response && response?.data) {
                let deleteUsersMsg = ''
                let deleteFailedMsg = '\n '
                if (
                  response?.data?.success &&
                  response?.data?.success.length > 0
                ) {
                  deleteUsersMsg = t(
                    'support_access_manager:delete_user.delete_user_success_msg',
                    {
                      users: response?.data?.success.join(', ')
                    }
                  )
                }
                if (
                  response?.data?.failed &&
                  response?.data?.failed?.length > 0
                ) {
                  deleteFailedMsg = deleteFailedMsg.concat(
                    response?.data?.failed.map((failedUser) => {
                      return t(
                        'support_access_manager:delete_user.delete_user_error_msg',
                        {
                          user: Object.keys(failedUser)[0],
                          errorMsg: Object.values(failedUser)[0]
                        }
                      )
                    })
                  )
                }

                const status = getStatusForMultiStatusResponse(response)

                const autoClose = !(
                  Boolean(response?.data?.failed?.length > 0) &&
                  Boolean(response.data?.success?.length > 0)
                )
                const notification = displayNotification(
                  getNotificationMessageForMultiStatusResponse(
                    deleteUsersMsg,
                    t('manage:success'),
                    deleteFailedMsg === '\n ' ? '' : deleteFailedMsg,
                    t('manage:error')
                  ),
                  status,
                  setShowNotification,
                  '',
                  autoClose
                )

                setShowNotification(notification)
              }
            }}
          />
        )}

        {samLDFlagPhase2 && showModifyAccessPermissionsModal && (
          <ModifyAccessPermissionModalSA
            onSetOpen={setShowModifyAccessPermissionsModal}
            detail={selectedRowData}
            cb={modifyPermissionSupportAccessCb}
          />
        )}
        {showErrorNotification}
        {showNotification}
      </>
    )
  }
)

SupportAccessUsersDatatable.propTypes = {
  refreshDataTable: PropTypes.func.isRequired,
  refreshCount: PropTypes.number.isRequired
}

export { SupportAccessUsersDatatable }
