// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const CoreContext = createContext(null)

export const CoreContextProvider = ({ children, initialData = undefined }) => {
  const [data, setData] = useState(initialData)

  return (
    <CoreContext.Provider value={{ data, setData }}>
      {children}
    </CoreContext.Provider>
  )
}

CoreContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initialData: PropTypes.object
}

export const useCoreContext = () => {
  const context = useContext(CoreContext)
  return {
    data: context?.data,
    setData: context?.setData
  }
}
