// Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useState, useReducer, useEffect, useRef } from 'react'
import { Box, Layer } from 'grommet'
import { Previous, StatusGood, CircleAlert } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Preferences } from '../../commoncomponents/profile-preferences/Preferences'
import { useUPSContext } from '../../context/ups-context'
import { CCSActions, useCCSContext } from '../../context/ccs-context'
import {
  Typography,
  CCSForm,
  Button,
  ToggleButton,
  Loader,
  Notification
} from '../../components'
import { displayNotification } from '../../utils/notificiation-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { put, post, getErrorMessage } from '../../utils/api-utils'
import {
  getLocaleMap,
  setDayJSLang,
  useIdleTimeout
} from '../../utils/common-utils'
import { getDataBasedOnSetting, isUPSSupported } from '../../utils/ups-utils'
import ProfileButtons from '../profile/ProfileButtons'
import { Layout } from '../../commoncomponents/layout/Layout'
import { PreferencesIdleTimeout } from '../../commoncomponents/profile-preferences/PreferencesIdleTimeout'
import { getCustomerAccount } from '../../utils/feature-flag-utils'
import { useVisibilityContext } from '../../context/visibility-context'
import {
  getPreferences,
  getPreferencesFromUps
} from '../../commoncomponents/profile-preferences/common-utils'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const SuccessInfo = (tObj) => (
  <Box
    round="4px"
    gap="xsmall"
    direction="row"
    align="center"
    data-testid="success-info-box"
    background={{ light: '#c7fae8' }}
    pad="medium"
  >
    <StatusGood size="medium" />
    <Typography type="text" size="xsmall" testId="success-info-message">
      {tObj('saml_sso.view_edit.upd_success')}
    </Typography>
  </Box>
)
const UPSLoader = (
  <Box
    align="center"
    justify="center"
    alignSelf="center"
    pad={{ vertical: 'large' }}
  >
    <Loader testId="loader-spinner" />
  </Box>
)
const PREF = {
  showGettingStarted: 'showGettingStarted',
  showWhatsNew: 'showWhatsNew',
  showWidgets: 'showWidgets'
}
const i18nStrings = {
  homePagePrefTitle:
    'user_personalisation:common_preferences.profile_pref_title',
  upsErrorNotification:
    'user_personalisation:common_preferences.ups_error_notification',
  upsErrorTitle: 'user_personalisation:common_preferences.ups_error_title',
  upsTryAgain: 'user_personalisation:common_preferences.ups_error_try_again',
  [PREF.showGettingStarted]: {
    label: 'user_personalisation:common_preferences.getting_started.label',
    preferencesEnableError:
      'user_personalisation:common_preferences.getting_started.preferences_enable_error',
    preferencesDisableError:
      'user_personalisation:common_preferences.getting_started.preferences_disable_error',
    preferencesEnableSuccess:
      'user_personalisation:common_preferences.getting_started.preferences_enable_success',
    preferencesDisableSuccess:
      'user_personalisation:common_preferences.getting_started.preferences_disable_success'
  },
  [PREF.showWhatsNew]: {
    label: 'user_personalisation:common_preferences.whats_new.label',
    preferencesEnableError:
      'user_personalisation:common_preferences.whats_new.preferences_enable_error',
    preferencesDisableError:
      'user_personalisation:common_preferences.whats_new.preferences_disable_error',
    preferencesEnableSuccess:
      'user_personalisation:common_preferences.whats_new.preferences_enable_success',
    preferencesDisableSuccess:
      'user_personalisation:common_preferences.whats_new.preferences_disable_success'
  },
  [PREF.showWidgets]: {
    label: 'user_personalisation:common_preferences.widgets.label',
    preferencesEnableError:
      'user_personalisation:common_preferences.widgets.preferences_enable_error',
    preferencesDisableError:
      'user_personalisation:common_preferences.widgets.preferences_disable_error',
    preferencesEnableSuccess:
      'user_personalisation:common_preferences.widgets.preferences_enable_success',
    preferencesDisableSuccess:
      'user_personalisation:common_preferences.widgets.preferences_disable_success'
  }
}
const ProfilePreferencesOnly = () => {
  const { oidcUser } = useReactOidc()
  const [originalState, setOriginalState] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const preferencesFetched = useRef(false)
  const {
    commonpreferences,
    upsDataResolved,
    upsGetApiCallErrored,
    dispatchUPSContext
  } = useUPSContext()
  const {
    'glcp-ups-phase-1': glcpUPSPhase1LD,
    'glcp-customize-widgets': glcpWidgetsLD,
    'glp-acctmgmt-ups-migrated-prefs': glpAcctmgmtUpsMigratedPrefs
  } = useFlags()
  const showGettingStarted = glcpUPSPhase1LD
    ? getDataBasedOnSetting(commonpreferences, PREF.showGettingStarted)?.value
    : false
  const showWhatsNew = glcpUPSPhase1LD
    ? getDataBasedOnSetting(commonpreferences, PREF.showWhatsNew)?.value
    : false
  const showWidgets = glcpWidgetsLD
    ? getDataBasedOnSetting(commonpreferences, PREF.showWidgets)?.value
    : false
  const [showGettingStartedUPS, setShowGettingStartedUPS] =
    useState(showGettingStarted)
  const [showWhatsNewUPS, setShowWhatsNewUPS] = useState(showWhatsNew)
  const [showWidgetsUPS, setShowWidgetsUPS] = useState(showWidgets)
  const [showNotification, setNotification] = useState(null) // to display success notifications
  const { t, i18n } = useTranslation(['authn', 'user_personalisation'])
  const navigate = useNavigate()
  const [idleTimeout, setIdleTimeout] = useState(null)
  const [reset] = useIdleTimeout()
  const { dispatchCCSContext } = useCCSContext()
  const [showLoader, setLoader] = useState(false)
  const minIdleTimeout = 5
  const maxIdleTimeout = 1440
  const { hideWhen } = useVisibilityContext()
  const [apiErrorMessage, setApiErrorMessage] = useState(true)
  const [globalPreferences, setGlobalPreferences] = useState([])

  useEffect(() => {
    reset()
  }, [idleTimeout, reset])

  useEffect(() => {
    setShowWhatsNewUPS(showWhatsNew)
  }, [showWhatsNew])

  useEffect(() => {
    setShowGettingStartedUPS(showGettingStarted)
  }, [showGettingStarted])

  useEffect(() => {
    setShowWidgetsUPS(showWidgets)
  }, [showWidgets])

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value, edited: true }
        case 'FORM_EDITED':
          return { ...currentState, edited: action.value }
        case 'SET_STATE':
          return { ...action.value, edited: false }
        default:
          return currentState
      }
    },
    {
      edited: false
    }
  )

  useEffect(() => {
    if (originalState.language) {
      const lang = getLocaleMap(originalState.language)
      // This will translate dayJS strings to desired language
      setDayJSLang(originalState.language)
      i18n.changeLanguage(lang)
      // Handle immediate update of language to header.  Without this, the header language
      // would only change after page reload to pick up the localStorage i18n language.
      const WCHeader = document.querySelector('greenlake-header')
      if (WCHeader) {
        WCHeader.language = lang
      }
      // TODO: keep this console log until we fully integrate i18n
      console.log(`INFO: User preference locale code is '${lang}'`)
    }
  }, [originalState.language, i18n])

  useEffect(() => {
    if (preferencesFetched.current) {
      return
    }
    const fetchPreferences = () => {
      if (glpAcctmgmtUpsMigratedPrefs) {
        getPreferencesFromUps(
          t,
          oidcUser,
          dispatch,
          setOriginalState,
          setErrorMessage,
          setGlobalPreferences
        )
      } else {
        getPreferences(t, oidcUser, dispatch, setOriginalState, setErrorMessage)
      }
      preferencesFetched.current = true
    }
    fetchPreferences()
  }, [t, oidcUser.access_token, oidcUser]) // eslint-disable-line react-hooks/exhaustive-deps

  // CCS-2747 - Uncomment for the theme field
  // const themeOptions = ['Light', 'Dark', 'Auto']

  const handleDiscardChangesClick = () => {
    dispatch({
      value: originalState,
      type: 'SET_STATE'
    })
  }

  const setPreferenceFunctionMap = {
    [PREF.showGettingStarted]: setShowGettingStartedUPS,
    [PREF.showWhatsNew]: setShowWhatsNewUPS,
    [PREF.showWidgets]: setShowWidgetsUPS
  }

  const handlePreferenceToggle = async (e, pref) => {
    const value = e.target.checked
    const setPreferenceFunction = setPreferenceFunctionMap[pref]
    setPreferenceFunction?.(value)
    setNotification(null)
    setLoader(true)
    try {
      const category = 'commonpreferences'
      const categoryData = getDataBasedOnSetting(commonpreferences, pref)
      const requestBody = {
        category,
        data: [{ ...categoryData, value }]
      }
      const response = await post(
        `/user-prefs/v1alpha1/save-preferences`,
        requestBody,
        oidcUser.access_token
      )
      if (response?.status === 200) {
        let successMsg
        if (value) {
          successMsg = t(i18nStrings[pref]?.preferencesEnableSuccess)
        } else {
          successMsg = t(i18nStrings[pref]?.preferencesDisableSuccess)
        }

        setNotification(
          displayNotification(successMsg, 'info', setNotification)
        )
        dispatchUPSContext({
          type: 'SET_CATEGORY_DATA',
          data: requestBody
        })
      }
    } catch (error) {
      let overrideMessage
      if (value) {
        overrideMessage = t(i18nStrings[pref]?.preferencesEnableError)
      } else {
        overrideMessage = t(i18nStrings[pref]?.preferencesDisableError)
      }
      setPreferenceFunction?.(!value)
      setNotification(
        displayApiError(error, t, setNotification, overrideMessage)
      )
    } finally {
      setLoader(false)
    }
  }
  const handleSubmit = () => {
    setErrorMessage('')

    const timeout = state?.timeout_number * 60
    put(
      '/accounts/ui/v1/user/profile/preferences',
      {
        // include all of the fields, even ununsed ones, since this API is a PUT
        language: state.language,
        idle_timeout: timeout,
        theme: state.theme,
        maintenance_notifications: state.maintenance_notifications,
        update_notifications: state.update_notifications,
        multi_fa_enabled: state.multi_fa_enabled,
        multi_fa_registration: state.multi_fa_registration
        // TODO: CCS-2709 - Add support for console settings
        // console_interface: 'Clean',
        // console_zoom_accessibility: '100% (Default)',
      },
      oidcUser.access_token
    ).then(
      (response) => {
        setOriginalState(state)
        dispatch({
          value: false,
          type: 'FORM_EDITED'
        })
        // TODO: CCS-2501 - show toast notifications
        if (response && response.data) {
          setUpdateSuccess(true)
          dispatchCCSContext({
            type: CCSActions.SET_IDLE_TIMEOUT,
            data: timeout * 1000
          })
          setIdleTimeout(timeout * 1000)
        }
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  const handleSubmitByUps = async () => {
    setErrorMessage('')
    const category = 'globalpreferences'
    const languageData = getDataBasedOnSetting(globalPreferences, 'language')
    const sessionTimeoutData = getDataBasedOnSetting(
      globalPreferences,
      'sessionTimeout'
    )
    const timeout = state?.timeout_number * 60
    const updatedGlobalPreferences = [
      { ...languageData, value: state?.language },
      { ...sessionTimeoutData, value: timeout }
    ]
    const requestBody = {
      category,
      data: updatedGlobalPreferences
    }
    try {
      const response = await post(
        `/user-prefs/v1alpha1/save-preferences`,
        requestBody,
        oidcUser.access_token
      )
      setOriginalState(state)
      dispatch({
        value: false,
        type: 'FORM_EDITED'
      })
      if (response?.status === 200) {
        setUpdateSuccess(true)
        dispatchCCSContext({
          type: CCSActions.SET_IDLE_TIMEOUT,
          data: timeout * 1000
        })
        setIdleTimeout(timeout * 1000)
        setGlobalPreferences(updatedGlobalPreferences)
      }
    } catch (error) {
      const backendErrorMessage = getErrorMessage(error, t)
      setErrorMessage(backendErrorMessage)
    }
  }
  const handleBackBtnClick = () => {
    navigate('/post-onboarding/choose-account')
  }

  const toggleButtonWithLabel = (label, pref, checked, testId, featureFlag) => (
    <VisibilityWrapper hideFor={{ feature: featureFlag }}>
      <Box margin={{ top: 'small' }}>
        <ToggleButton
          label={
            <Typography
              type="text"
              size="xsmall"
              weight={500}
              margin={{ top: 'xsmall' }}
            >
              {label}
            </Typography>
          }
          onChange={(e) => {
            handlePreferenceToggle(e, pref)
          }}
          reverse={false}
          checked={checked}
          testId={testId}
          pad="none"
        />
      </Box>
    </VisibilityWrapper>
  )

  const togglePreferences = [
    {
      pref: PREF.showGettingStarted,
      label: t(i18nStrings.showGettingStarted?.label),
      checked: showGettingStartedUPS,
      testId: 'getting-started-toggle-btn'
    },
    {
      pref: PREF.showWhatsNew,
      label: t(i18nStrings.showWhatsNew?.label),
      checked: showWhatsNewUPS,
      testId: 'whats-new-toggle-btn',
      featureFlag: 'glcp-whatsnew-istanbul'
    },
    {
      pref: PREF.showWidgets,
      label: t(i18nStrings.showWidgets?.label),
      checked: showWidgetsUPS,
      testId: 'widgets-toggle-btn',
      featureFlag: 'glcp-customize-widgets'
    }
  ]

  const renderLoader = (
    <Layer position="center" data-testid="plain-layer" plain>
      <Loader testId="loader-spinner" />
    </Layer>
  )
  const renderCommonPreferences = () => {
    const homePreferencesBlock = (
      <Box
        pad={{ horizontal: 'medium', vertical: 'large' }}
        margin={{ top: 'medium' }}
        border={{ color: 'border-weak' }}
      >
        <Typography
          size="medium"
          type="text"
          weight="bold"
          testId="getting-started-preferences-title"
        >
          {t(i18nStrings.homePagePrefTitle)}
        </Typography>
        {togglePreferences.map(
          ({ label, pref, checked, testId, featureFlag }) =>
            toggleButtonWithLabel(label, pref, checked, testId, featureFlag)
        )}
      </Box>
    )
    const upsErrorBlock = (
      <Box margin={{ top: 'medium' }}>
        {apiErrorMessage && (
          <Notification
            testId="status-warning-notification"
            text={t(i18nStrings.upsErrorNotification)}
            backgroundColor="status-warning"
            type="inline"
            onClose={() => {
              setApiErrorMessage(false)
            }}
          />
        )}
        <Box
          pad={{ horizontal: 'medium', vertical: 'large' }}
          margin={{ top: 'medium', bottom: 'large' }}
          direction="column"
          alignSelf="center"
          justifySelf="center"
          align="center"
          border={{ color: 'border-weak' }}
          width="100%"
        >
          <CircleAlert
            size="xlarge"
            color="status-warning"
            alignSelf="center"
          />
          <Box margin={{ top: 'medium' }}>
            <Typography
              level="2"
              type="heading"
              weight="normal"
              testId="error-message-title"
            >
              {t(i18nStrings.upsErrorTitle)}
            </Typography>
          </Box>
          <Box>
            <Typography
              size="medium"
              type="text"
              weight="normal"
              testId="try-again-description"
            >
              {t(i18nStrings.upsTryAgain)}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
    if (upsDataResolved && !upsGetApiCallErrored) {
      return homePreferencesBlock
    }
    if (upsDataResolved && upsGetApiCallErrored) {
      return upsErrorBlock
    }

    return UPSLoader
  }
  const custAccountLoaded = getCustomerAccount()
  return (
    // TODO: CCS-2747 - Set the width to xlarge to allow the Display column
    <>
      {showLoader && renderLoader}
      {showNotification}
      <Box
        data-testid="preferences-only"
        gap="xsmall"
        margin={{ horizontal: 'xlarge' }}
        pad={{ bottom: 'medium' }}
      >
        {!custAccountLoaded && (
          <Box direction="row" justify="start" pad="small">
            <Button
              default
              margin={{ vertical: 'xsmall', horizontal: 'small' }}
              label={t('authn:preferences.account_selection')}
              icon={<Previous />}
              onClick={handleBackBtnClick}
              testId="account-selection-btn"
            />
          </Box>
        )}

        <Box justify="center" direction="row">
          <Box width="medium" margin={{ top: 'small' }}>
            <Typography
              level="2"
              type="heading"
              weight="normal"
              testId="preferences-title"
            >
              {t('authn:preferences.title')}
            </Typography>
            <Typography
              size="medium"
              type="text"
              weight="normal"
              testId="preferences-description"
            >
              {t('authn:preferences.description')}
            </Typography>
            <Box
              pad={{ horizontal: 'medium', vertical: 'large' }}
              margin={{ top: 'small' }}
              border={{ color: 'border-weak' }}
            >
              <Typography
                size="medium"
                type="text"
                weight="bold"
                testId="preferences-description"
              >
                {t('authn:preferences.general_pref_title')}
              </Typography>
              <CCSForm
                testId="profile-preferences-form"
                validate="blur"
                onSubmit={
                  glpAcctmgmtUpsMigratedPrefs ? handleSubmitByUps : handleSubmit
                }
                onReset={handleDiscardChangesClick}
                errorMessage={errorMessage}
                buttons={
                  <ProfileButtons
                    updateLabel={t('preferences.save_changes')}
                    edited={state.edited}
                  />
                }
              >
                <Box direction="row" gap="xlarge">
                  <Box direction="column" width="medium">
                    <Preferences dispatch={dispatch} state={state} />
                    <Box gap="small">
                      <PreferencesIdleTimeout
                        dispatch={dispatch}
                        state={state}
                        minIdleTimeout={minIdleTimeout}
                        maxIdleTimeout={maxIdleTimeout}
                      />
                    </Box>
                    {updateSuccess && SuccessInfo(t)}
                  </Box>
                </Box>
              </CCSForm>
            </Box>
            {glcpUPSPhase1LD &&
              custAccountLoaded &&
              !(hideWhen?.account === 'TAC') &&
              isUPSSupported() &&
              renderCommonPreferences()}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const ProfilePreferencesOnlyPage = () => {
  const custAccountLoaded = getCustomerAccount()
  const { hideWhen } = useVisibilityContext()
  return custAccountLoaded ? (
    <Layout
      {...(hideWhen?.account === 'TAC' && { hideHeaderOptions: ['apps'] })}
    >
      <ProfilePreferencesOnly />
    </Layout>
  ) : (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps']}>
      <ProfilePreferencesOnly />
    </Layout>
  )
}

export default ProfilePreferencesOnlyPage
