// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import {
  ModalDialog,
  Typography,
  ModalFooter,
  ButtonGroup,
  ModalHeader
} from '../../../components'

const MspModal = ({ onSetOpen, onSuccess = () => {} }) => {
  const { t } = useTranslation(['authn'])

  const handleCancel = () => {
    onSetOpen(false)
  }

  return (
    <>
      <ModalDialog
        width="large"
        testId="msp-confirm-modal"
        header={
          <ModalHeader
            title={
              <Typography type="heading" level={2} testId="modal-title">
                {t('authn:customer_account.msp_modal_title')}
              </Typography>
            }
          />
        }
        content={
          <Box width={{ min: 'full', max: 'medium' }} flex={false}>
            <Box direction="column">
              <Typography type="text" margin={{ top: 'medium' }}>
                {t('authn:customer_account.msp_modal_para1')}
              </Typography>
              <Typography type="text" margin={{ top: 'medium' }}>
                {t('authn:customer_account.msp_modal_para2')}
              </Typography>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <Box direction="row" align="end" gap="medium">
                <ButtonGroup
                  buttonList={[
                    {
                      id: 2,
                      label: t('authn:customer_account.msp_modal_success'),
                      secondary: true,
                      testId: 'standard-btn',
                      onClick: onSuccess
                    },
                    {
                      id: 1,
                      label: t('authn:customer_account.msp_modal_cancel'),
                      primary: true,
                      testId: 'msp-confirm-btn',
                      onClick: handleCancel
                    }
                  ]}
                  testId="two-buttons"
                />
              </Box>
            }
          />
        }
        onClose={() => onSetOpen(false)}
      />
    </>
  )
}

MspModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
}

export { MspModal }
