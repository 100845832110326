// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useState, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { Box, Anchor, Page, PageContent, PageHeader } from 'grommet'
import { Previous } from 'grommet-icons'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { debounce } from 'lodash'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { get } from '../../../utils/api-utils'
import {
  Typography,
  Button,
  DataTable,
  ActionButton,
  FilterButton
} from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

import OVADownloadModal from './OVADownloadModal'
import StatusDisplay from './StatusDisplay'
import { convertStatus } from './utils'
import LoadingContainer from './LoadingContainer'

dayjs.extend(advancedFormat)

const itemsPerPage = 20

const LocalGatewayContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['local-gateway'])
  const { oidcUser } = useReactOidc()
  // Allow HPE theme to spread to Typography instance
  const {
    dataTable: {
      header: { color: headerColor, font: headerFont }
    }
  } = useContext(ThemeContext)
  const handleBackBtnClick = () => {
    navigate('/manage-account')
  }

  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSetSearchTerm = debounce(setSearchTerm, 500)
  const [filters, setFilters] = useState()
  const [gatewayData, setGatewayData] = useState([])
  const [filteredGatewayData, setFilteredGatewayData] = useState([])

  const [loadingGatewayList, setLoadingGatewayList] = useState(true)
  const [loadingGatewayListError, setLoadingGatewayListError] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  // Get data
  useEffect(() => {
    setLoadingGatewayList(true)
    setLoadingGatewayListError(false)
    get(`/platform/acpmgr/getGateways`, {}, oidcUser.access_token)
      .then(
        (response) => {
          if (response.data) {
            // redirect to welcome page if the customer doesnt have any gateways
            if (
              !response.data.gateways ||
              response.data.gateways.length === 0
            ) {
              navigate(`/manage-account/local-gateway/welcome`)
            }
            setGatewayData(response.data.gateways)
          }
        },
        () => {
          setLoadingGatewayListError(true)
        }
      )
      .finally(() => setLoadingGatewayList(false))
  }, [navigate, oidcUser])

  // search and filters
  useEffect(() => {
    let filteredData
    if (searchTerm && searchTerm.length > 0) {
      filteredData = gatewayData.filter(
        (gateway) =>
          gateway.name.includes(searchTerm) ||
          gateway.description.includes(searchTerm)
      )
    }

    if (filters) {
      let dataToFilter = filteredData || gatewayData
      if (filters.status) {
        dataToFilter = dataToFilter.filter((data) =>
          filters.status.includes(convertStatus(data.status))
        )
      }
      filteredData = dataToFilter
    }

    setFilteredGatewayData(filteredData)
  }, [searchTerm, filters, setFilteredGatewayData, gatewayData])

  const columns = [
    {
      primary: true,
      property: 'status',
      type: 'string',
      header: (
        <Typography
          type="text"
          testId="status-table-header"
          color={headerColor}
          {...headerFont}
        >
          {t('status_table_header')}
        </Typography>
      ),
      render: (datum) => {
        return <StatusDisplay status={datum.status} />
      }
    },
    {
      property: 'name',
      type: 'string',
      header: (
        <Typography
          type="text"
          testId="name-table-header"
          color={headerColor}
          {...headerFont}
        >
          {t('name_table_header')}
        </Typography>
      ),
      render: (datum) => {
        return (
          <Box direction="row" align="center">
            <Typography type="text" testId="name-table-text">
              {datum.name}
            </Typography>
          </Box>
        )
      }
    },
    {
      property: 'description',
      type: 'string',
      header: (
        <Typography
          type="text"
          testId="description-table-header"
          color={headerColor}
          {...headerFont}
        >
          {t('description_table_header')}
        </Typography>
      )
    },
    {
      property: 'creationDate',
      type: 'date',
      header: (
        <Typography
          type="text"
          testId="creationDate-table-header"
          color={headerColor}
          {...headerFont}
        >
          {t('creation_date_table_header')}
        </Typography>
      ),
      render: (datum) => {
        return (
          <Box direction="row" align="center">
            <Typography
              type="text"
              testId="description-table-text"
              size="medium"
              margin={{ left: 'small' }}
            >
              {dayjs(datum.creationDate).format('MMMM Do, YYYY')}
            </Typography>
          </Box>
        )
      }
    }
  ]

  const filterAttributes = [
    {
      name: 'status',
      label: t('status_table_header'),
      values: [
        { valueName: 'starting', valueLabel: t('status.starting') },
        { valueName: 'error', valueLabel: t('status.error') },
        { valueName: 'warning', valueLabel: t('status.warning') },
        { valueName: 'running', valueLabel: t('status.running') },
        { valueName: 'unregistered', valueLabel: t('status.unregistered') },
        { valueName: 'offline', valueLabel: t('status.offline') }
      ]
    }
  ]

  const actionButton = (
    <ActionButton
      actions={[
        {
          label: t('download_ova_button'),
          onClick: () => {
            setShowDownloadModal(true)
          }
        }
      ]}
      showOneActionAsDropDown
      dropAlign={{ top: 'bottom', left: 'left' }}
      testId="drop-action-btn"
      margin={{ right: 'medium' }}
    />
  )

  const handleRowClick = (datum) => {
    navigate(`/manage-account/local-gateway/details/${datum.id}`)
  }

  return (
    <Page>
      <PageContent data-testid="local-gateway-card">
        <PageHeader
          responsive
          title={t('list_page_title')}
          subtitle={t('list_page_subheading')}
          parent={
            <Anchor
              label={t('common:manage_account_label', {
                account: getWorkspaceString(
                  showWorkspaceString,
                  t,
                  WKSPC_CAPITALIZED
                )
              })}
              icon={<Previous />}
              onClick={handleBackBtnClick}
              data-testid="manage-account-btn"
              margin={{ bottom: 'small' }}
            />
          }
          actions={
            <Box
              responsive
              direction="row"
              justify="end"
              pad={{ bottom: 'large', right: 'medium' }}
            >
              {actionButton}
              <Button
                primary
                label={t('create_gateway')}
                testId="create-gateway-btn"
                onClick={() => {
                  navigate('/manage-account/local-gateway/create/')
                }}
              />
            </Box>
          }
          data-testid="local-gateway-page-header"
        />
        <DataTable
          summary={{
            entityName:
              (filteredGatewayData || gatewayData).length === 1
                ? t('gateway')
                : t('gateways')
          }}
          pagination={{
            totalItems: (filteredGatewayData || gatewayData).length,
            itemsPerPage,
            page,
            setPage
          }}
          filterButton={
            <FilterButton
              filterAttributes={filterAttributes}
              onFilterValuesChange={(changedFilters) => {
                setFilters(changedFilters)
              }}
              testId="filter-search-btn"
            />
          }
          search={{
            width: 'medium',
            onSearchValueChange: (val) => {
              debouncedSetSearchTerm(val)
              setPage(1)
            },
            placeholder: t('gateway_search_box_placeholder')
          }}
          grid={{
            columns,
            data: (filteredGatewayData || gatewayData).slice(
              (page - 1) * itemsPerPage,
              (page - 1) * itemsPerPage + itemsPerPage
            ),
            onClickRow: (e) => {
              handleRowClick(e.datum)
            }
          }}
          testId="gateways-table"
        />
        <LoadingContainer
          loading={loadingGatewayList}
          loadingError={loadingGatewayListError}
          testId="gateway-list"
        />
        <OVADownloadModal
          setShowDownloadModal={setShowDownloadModal}
          showDownloadModal={showDownloadModal}
        />
      </PageContent>
    </Page>
  )
}
const LocalGatewayList = () => {
  return (
    <Layout>
      <LocalGatewayContent />
    </Layout>
  )
}
export default LocalGatewayList
