// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Layer } from 'grommet'
import { ThemeContext } from 'styled-components'

import { Typography } from '../typography/Typography'

import { DefaultSpinner } from './DefaultSpinner'
import { HPESpinner } from './HPESpinner'

const spinner = {
  default: <DefaultSpinner />,
  hpe: <HPESpinner />
}

export const Loader = ({
  type = 'default',
  size = '36px',
  label = '',
  orientation = 'vertical',
  testId,
  modal = false,
  modalTitle = '',
  modalSubTitle = '',
  modalTimeout = 1000,
  ...rest
}) => {
  const theme = useContext(ThemeContext)
  const [show, setShow] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => setShow(true), modalTimeout)
    return () => clearTimeout(timer)
  }, [modalTimeout])
  const showSpinner = (
    <Box
      align="center"
      justify="center"
      width={orientation === 'vertical' ? size : null}
      direction={orientation === 'horizontal' ? 'row' : 'column'}
      gap={orientation === 'horizontal' ? 'small' : null}
      data-testid={testId}
    >
      <Box align="center" justify="center" width={size} height={size}>
        {spinner[type]}
      </Box>

      {label && (
        <Typography size="xsmall" testId="t9" type="text">
          {label}
        </Typography>
      )}
    </Box>
  )

  const showLoaderModal = (
    <Layer
      plain
      position="center"
      onEsc={null}
      data-testid={`${testId}-modal`}
      {...rest}
    >
      {show && (
        <Box
          background={theme?.layer.background}
          elevation={theme?.layer.container.elevation}
          round={theme?.layer.border.radius}
        >
          <Box
            direction="column"
            gap="medium"
            pad={(rest && rest.pad) || 'medium'}
            overflow="auto"
          >
            <Box direction="row" gap="medium">
              <Box direction="column">
                <Box
                  direction="row"
                  gap="small"
                  align="start"
                  data-testid="loader-modal"
                >
                  <Box direction="column" basis="100%" data-testid="header">
                    {modalTitle && (
                      <Typography
                        type="heading"
                        level="2"
                        weight="bold"
                        style={{
                          whiteSpace: 'nowrap'
                        }}
                        testId="header-title"
                      >
                        {modalTitle}
                      </Typography>
                    )}
                    {modalSubTitle && (
                      <Typography
                        type="paragraph"
                        size="medium"
                        testId="loader-header-subtitle"
                      >
                        {modalSubTitle}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  margin={{ top: 'small' }}
                  size="medium"
                  align="center"
                  data-testid="loader-icon"
                >
                  {showSpinner}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Layer>
  )

  return (
    <>
      {modal && showLoaderModal}
      {!modal && showSpinner}
    </>
  )
}

Loader.propTypes = {
  /**
   * This prop will determine the type of the sponner
   */
  type: PropTypes.oneOf(['hpe', 'default']),

  /**
   * This prop will control the size of the box (i.e width and height).
   * Default will be 36px as per UX.
   * TODO: UX and design system is still working on it as per this https://arubanetworks.slack.com/archives/C016NNY4TA9/p1611080780020500
   */
  size: PropTypes.string,

  /**
   * set display orientation
   */
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),

  /**
   * This prop will show the text of the spinner.
   */
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired,

  /**
   * show loading modal
   * default is false: show loading icon
   * if set to true, show modal pupup with title/subtitle/loading icon,
   * and white background color
   */
  modal: PropTypes.bool,

  /**
   * It will be use for Loader Modal Title
   */
  modalTitle: PropTypes.string,

  /**
   * It will be use for Loader modal Sub Title
   * This is different the label, label is for spinner text
   */
  modalSubTitle: PropTypes.string,

  /**
   * It will be use the timeout to pop the loader modal in millisecond
   * default to 1000 (1 second)
   * When <Loader modal /> initiated, the clear modal blocks the background
   * default 1 second to popup the loader modal dialog
   * If the response get back less than modalTimeout, the background is
   * freezed and the loading dialog will not popup
   * If the response get back more than {modalTimeout}, the background is
   * freezed and the loading dialog will popup after {modalTimeout}
   */
  modalTimeout: PropTypes.number
}
