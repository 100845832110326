// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { Trash } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '../../../components'
import { NON_GLP_USER_NAME } from '../utils'

export const ContactList = ({
  contactList,
  setFormError,
  setContactList,
  setNextStepError
}) => {
  const { t } = useTranslation(['common', 'location'])

  const translateContactType = (type) => {
    if (type === 'shipping_receiving') {
      return t('location:shipping_and_receiving')
    }
    return t(`location:${type}`)
  }

  return (
    <>
      {contactList.length > 0 &&
        contactList.map((contact) => {
          return (
            <Box
              key={`${contact.email}-${contact.type}`}
              testId="contact-list"
              direction="row"
              justify="between"
            >
              <Box
                direction="column"
                justify="start"
                align="start"
                gap="large"
                pad={{ top: 'small', bottom: 'small' }}
              >
                <>
                  <Typography data-testid="contact-name" type="text" emphasis>
                    {contact.name !== NON_GLP_USER_NAME
                      ? contact.name
                      : contact.email}
                  </Typography>
                  <Typography
                    size="small"
                    data-testid="contact-email"
                    type="text"
                  >
                    {contact.name !== NON_GLP_USER_NAME
                      ? contact.email
                      : t('email_outside_platform')}
                  </Typography>
                </>
              </Box>
              <Box
                direction="row"
                justify="end"
                align="center"
                gap="small"
                pad={{ top: 'small', bottom: 'small' }}
              >
                <Typography data-testid="contact-type" type="text">
                  {translateContactType(contact.type)}
                </Typography>
                <Button
                  testId="delete-contact-button"
                  a11yTitle={`${t('delete')} ${contact.type}`}
                  onClick={() => {
                    const newList = contactList.filter(
                      (item) =>
                        Object.entries(item).toString() !==
                        Object.entries(contact).toString()
                    )
                    setContactList(newList)
                    setFormError('')
                    setNextStepError({
                      error: '',
                      isValid: true
                    })
                  }}
                  icon={<Trash />}
                />
              </Box>
            </Box>
          )
        })}
    </>
  )
}

ContactList.propTypes = {
  contactList: PropTypes.array.isRequired,
  setFormError: PropTypes.func.isRequired,
  setContactList: PropTypes.func.isRequired,
  setNextStepError: PropTypes.func.isRequired
}
