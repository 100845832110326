// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { NameValuePair } from 'grommet'
import { PropTypes } from 'prop-types'

import { Typography } from '../../../../components'
import { NON_GLP_USER_NAME } from '../../utils'

export const Contacts = ({
  contacts = [
    {
      id: '',
      type: '',
      name: '',
      email: '',
      phone_number: ''
    }
  ],
  type
}) => {
  return (
    <NameValuePair data-testid={`${type}-contacts-details`} name={type}>
      {contacts.map((contact) => (
        <Typography
          testId={`${contact.type}-contacts`}
          key={`${contact.email}-${contact.type}`}
          type="text"
        >
          {contact.name !== NON_GLP_USER_NAME
            ? `${contact.name} (${contact.email})`
            : contact.email}
        </Typography>
      ))}
    </NameValuePair>
  )
}

Contacts.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string
    })
  ),
  type: PropTypes.string.isRequired
}
