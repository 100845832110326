// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box, CheckBox } from 'grommet'

const MultiSelect = ({
  options,
  values,
  onChange,
  'data-testid': testId = undefined
}) => {
  const onCheckCreator = (value) => (event) => {
    const newValues = new Set(values)
    if (event.target.checked) {
      newValues.add(value)
    } else {
      newValues.delete(value)
    }
    onChange(newValues)
  }
  const optionElements = options.map((option) => {
    return (
      <CheckBox
        key={option.value}
        label={option.label}
        checked={values.has(option.value)}
        onChange={onCheckCreator(option.value)}
      />
    )
  })
  return (
    <Box margin={{ vertical: 'xxsmall' }} data-testid={testId}>
      {optionElements}
    </Box>
  )
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  values: PropTypes.instanceOf(Set).isRequired,
  onChange: PropTypes.func.isRequired,
  'data-testid': PropTypes.string
}

export default MultiSelect
