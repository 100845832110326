// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { useNavigate } from 'react-router-dom'

import { Button, Typography } from '../../components'
import { Layout } from '../../commoncomponents/layout/Layout'

function SessionError() {
  const navigate = useNavigate()
  const sessionApiErrorMsg = sessionStorage.getItem('session-api-error-message')
  return (
    <Box
      align="center"
      justify="center"
      alignContent="center"
      data-testid="no-access"
      pad="xlarge"
    >
      <Box width="large">
        <Typography
          type="text"
          size="xxlarge"
          margin={{ bottom: 'medium' }}
          testId="first-no-access-subtitle"
        >
          {sessionApiErrorMsg && sessionApiErrorMsg !== 'undefined'
            ? sessionApiErrorMsg
            : 'Session limit exceeded. Please contact Administrator or try again later.'}
        </Typography>
        <Button
          primary
          label="Back to Sign in"
          size="large"
          margin={{ vertical: 'small' }}
          onClick={() => {
            sessionStorage.removeItem('session-api-error-message')
            navigate('/sign-out')
          }}
          testId="back-to-signin"
        />
      </Box>
    </Box>
  )
}

const SessionErrorPage = () => {
  return (
    <Layout hideHeaderOptions={['no-header']}>
      <SessionError />
    </Layout>
  )
}

export default SessionErrorPage
