// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useReactOidc } from '@axa-fr/react-oidc-context'
import {
  Anchor,
  Box,
  NameValueList,
  NameValuePair,
  Page,
  PageContent,
  PageHeader
} from 'grommet'
import { FormDown, Previous } from 'grommet-icons'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { ActionButton, Loader, Typography } from '../../../components'
import { updateStatusName } from '../utils'
import { get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { Layout } from '../../../commoncomponents/layout/Layout'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

import SubscriptionInfo from './SubscriptionInfo'
import TestApi from './TestApi'
import RecentDeliveriesInfo from './RecentDeliveriesInfo'
import StatusCard from './StatusCard'
import StatusIcon from './StatusIcon'
import StatusModal from './StatusModal'
import UnregisterWebhookModal from './UnregisterWebhookModal'
import CreateAndEditEventWebhookSidePanel from './CreateAndEditEventWebhookSidePanel'
import DeleteSubscriptionDialog from './DeleteSubscriptionDialog'

const Events2DetailsPage = () => {
  const { t } = useTranslation(['authz', 'automations'])
  const { id } = useParams()
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const [isLoading, setIsLoading] = useState(true)
  const [webhookInfo, setWebhookInfo] = useState({
    name: '',
    description: '',
    webhookUrl: '',
    state: '',
    id: '',
    lastUpdated: null,
    registered: null,
    registeredBy: '',
    status: ''
  })
  const [recentDeliveriesInfo, setRecentDeliveries] = useState([])
  const [subscriptionInfo, setSubscription] = useState({})
  const [showStatusWebhook, setShowStatusWebhook] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [showTestModal, setShowTestModal] = useState(false)
  const [showUnregisterWebhook, setShowUnregisteredWebhook] = useState(false)
  const [deleteSubscriptionDialog, setDeleteSubscriptionDialog] =
    useState(false)
  const [subscriptionId, setSubscriptionId] = useState('')
  const [showEditWebhook, setShowEditWebhook] = useState(false)
  const [webhookDetails, setWebhookDetails] = useState(false)
  const [showNotification, setShowNotification] = useState(null)
  const [successTitle, setSuccessTitle] = useState(null)
  const [message, setMessage] = useState(null)
  const [toastType, setToastType] = useState('info')
  const [webhookStatus, setWebhookStatus] = useState(null)
  const eventsDetails = {
    webhookUrl: t('automations:webhooks.destination'),
    state: t('automations:webhooks.status'),
    id: t('automations:webhooks.webhook_id'),
    lastUpdated: t('automations:webhooks.updated_at'),
    registered: t('automations:webhooks.created_at'),
    registeredBy: t('automations:webhooks.created_by')
  }

  const handleBackBtnClick = () => {
    navigate('/manage-account/automations/webhooks')
  }

  const fetchWebhook = useCallback(() => {
    setIsLoading(true)
    get(
      `/events/v1beta1/webhooks/${id}`,
      {},
      oidcUser?.access_token,
      false,
      {}
    ).then(
      (response) => {
        setWebhookDetails(response?.data)
        setShowStatusWebhook(response?.data?.paused)
        const EventPassedInfo = {
          id: response?.data?.id,
          name: response?.data?.name,
          description: response?.data?.description,
          webhookUrl: response?.data?.destination,
          state: (
            <Box display="flex" direction="row" align="center" gap="8px">
              <StatusIcon status={response?.data?.status} />
              {updateStatusName(response?.data?.status.toLowerCase())}
            </Box>
          ),
          status: response?.data?.status,
          lastUpdated: response?.data?.updatedAt
            ? dayjs(response?.data?.updatedAt).format('MMM D, YYYY, hh:mm A')
            : 'N/A',
          registered: response?.data?.createdAt
            ? dayjs(response.data.createdAt).format('MMM D, YYYY, hh:mm A')
            : 'N/A',
          registeredBy: response?.data?.createdBy
        }
        setWebhookInfo(EventPassedInfo)
        setWebhookStatus(response?.data?.status)
        setIsLoading(false)
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
        setIsLoading(false)
      }
    )
  }, [t, oidcUser, id])

  useEffect(() => {
    fetchWebhook()
  }, [fetchWebhook])

  const fetchSubscription = useCallback(() => {
    setIsLoading(true)
    const filterParams = `webhookId eq '${id}'`
    const sort = 'eventType desc'
    const sortQuery = `&sort=${encodeURIComponent(sort)}`
    get(
      `/events/v1beta1/subscriptions?limit=5&filter=${encodeURIComponent(
        filterParams
      )}${sortQuery}`,
      {},
      oidcUser?.access_token
    ).then(
      (response) => {
        const subscriptionData = response?.data?.items
          ? response.data.items.map((item) => ({
              type: item.type,
              eventType: item.eventType,
              createdBy: item.createdBy,
              serviceManager: item.serviceManager,
              id: item.id
            }))
          : []
        setSubscription(subscriptionData)
        setIsLoading(false)
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
        setIsLoading(false)
      }
    )
  }, [t, oidcUser, id])

  useEffect(() => {
    fetchSubscription()
  }, [fetchSubscription])

  const fetchRecentDeliveries = useCallback(() => {
    setIsLoading(true)
    get(
      `/events/v1beta1/webhooks/${id}/delivery-failures?limit=5`,
      {},
      oidcUser?.access_token
    ).then(
      (response) => {
        const recentDeliveriesData = response?.data?.items || []
        setRecentDeliveries(recentDeliveriesData)

        setIsLoading(false)
      },
      (error) => {
        setShowNotification(displayApiError(error, t, setShowNotification))
        setIsLoading(false)
      }
    )
  }, [t, oidcUser, id])

  useEffect(() => {
    fetchRecentDeliveries()
  }, [fetchRecentDeliveries])

  const onDeleteSubscription = (data) => {
    setDeleteSubscriptionDialog(true)
    setSubscriptionId(data)
  }

  const defaultActions = [
    {
      label: t('automations:webhooks.edit'),
      visibility: {
        rbac: {
          permission: 'ccs.events.webhook.update',
          resource: '/ccs/events/webhook'
        }
      },
      onClick: () => {
        setShowEditWebhook(true)
      }
    },
    {
      label: t('automations:webhooks.test'),
      visibility: {
        rbac: {
          permission: 'ccs.events.webhook.update',
          resource: '/ccs/events/webhook'
        }
      },
      onClick: () => {
        setShowTestModal(true)
      }
    },
    ...(['PENDING', 'ACTIVE', 'WARNING'].includes(webhookStatus)
      ? [
          {
            label: t('automations:webhooks.disable'),
            visibility: {
              rbac: {
                permission: 'ccs.events.webhook.update',
                resource: '/ccs/events/webhook'
              }
            },
            onClick: () => {
              setShowStatusModal(true)
              setShowStatusWebhook(true)
            }
          }
        ]
      : [
          {
            label: t('automations:webhooks.enable'),
            visibility: {
              rbac: {
                permission: 'ccs.events.webhook.update',
                resource: '/ccs/events/webhook'
              }
            },
            onClick: () => {
              setShowStatusModal(true)
              setShowStatusWebhook(false)
            }
          }
        ]),
    {
      label: t('automations:webhooks.unregister'),
      visibility: {
        rbac: {
          permission: 'ccs.events.webhook.delete',
          resource: '/ccs/events/webhook'
        }
      },
      onClick: () => {
        setShowUnregisteredWebhook(true)
      }
    }
  ]

  const webhookEditSuccess = ({ title, notificationMessage }) => {
    fetchWebhook()
    setSuccessTitle(title)
    setMessage(notificationMessage)
  }

  const webhookStateSuccess = ({ notificationTitle, notificationDesc }) => {
    fetchWebhook()
    setSuccessTitle(notificationTitle)
    setMessage(notificationDesc)
  }

  const subscriptionCreateSuccess = ({
    title,
    notificationMessage,
    severity
  }) => {
    fetchSubscription()
    setSuccessTitle(title)
    setMessage(notificationMessage)
    setToastType(severity)
  }

  return (
    <Page data-testid="events2-group-details-page">
      <PageContent>
        {isLoading ? (
          <Box
            align="start"
            justify="center"
            alignSelf="center"
            pad={{ top: 'medium' }}
          >
            <Loader
              orientation="horizontal"
              label={t('automations:loading')}
              testId="events2-group-details-loader"
            />
          </Box>
        ) : (
          <Box margin={{ bottom: 'medium' }}>
            <PageHeader
              direction="row-responsive"
              parent={
                <Anchor
                  label={`${t('automations:webhooks.prev_button_title')}`}
                  icon={<Previous />}
                  onClick={handleBackBtnClick}
                  data-testid="events2-back-btn"
                />
              }
              pad={{ top: 'large' }}
              title={
                <Typography level="1" type="heading" testId="page-title">
                  {webhookInfo?.name}
                </Typography>
              }
              subtitle={
                <Typography level="1" type="paragraph" testId="page-title">
                  {webhookInfo?.description}
                  {/* Collect Security Events */}
                </Typography>
              }
              actions={
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/events/webhook',
                    permission: 'ccs.events.webhook.update'
                  }}
                >
                  <ActionButton
                    label={t('automations:webhooks.actions')}
                    icon={<FormDown />}
                    reverse
                    actions={defaultActions}
                    showOneActionAsDropDown
                    testId="events2-details-action-btn"
                    customRenderer={(actionBtn, visibility, idx) => {
                      return (
                        <VisibilityWrapper key={idx} rbac={visibility.rbac}>
                          {actionBtn}
                        </VisibilityWrapper>
                      )
                    }}
                  />
                </VisibilityWrapper>
              }
            />
            <Box width={{ min: 'medium' }}>
              <Box pad={{ top: 'small', bottom: 'medium' }}>
                <StatusCard
                  webhookStatus={webhookStatus}
                  showCard={
                    webhookStatus === 'WARNING' ||
                    webhookStatus === 'PENDING' ||
                    webhookStatus === 'ERROR'
                  }
                  t={t}
                />
                <Box
                  margin={{ top: 'medium' }}
                  direction="row"
                  pad={{ bottom: 'medium' }}
                >
                  <NameValueList
                    gap={{ row: 'medium', column: 'large' }}
                    responsive
                    nameProps={{ width: 'auto' }}
                    data-testid="events2-details-list"
                  >
                    {Object?.entries(eventsDetails)?.map((datum) => (
                      <NameValuePair
                        name={
                          <Typography
                            type="text"
                            emphasis
                            testId="events2-details-object-key"
                          >
                            {datum[1]}
                          </Typography>
                        }
                        key={datum[1]}
                      >
                        <Typography
                          type="text"
                          testId="events2-details-object-value"
                        >
                          {webhookInfo[datum[0]]}
                        </Typography>
                      </NameValuePair>
                    ))}
                  </NameValueList>
                </Box>
              </Box>
              <SubscriptionInfo
                subscriptionInfo={subscriptionInfo}
                webhookInfo={webhookInfo}
                handleDelete={onDeleteSubscription}
                setLoading={setIsLoading}
                handleBackBtnClick={handleBackBtnClick}
                onToastNotification={subscriptionCreateSuccess}
              />
              <Box justify="between" margin={{ top: 'medium' }}>
                <Typography
                  level="2"
                  size="medium"
                  type="heading"
                  weight="normal"
                  testId="events-recent-del-title"
                >
                  {t('automations:webhooks.recent_deliveries')}
                </Typography>
              </Box>
              <RecentDeliveriesInfo deliveryInfo={recentDeliveriesInfo} />
            </Box>
          </Box>
        )}
        {showTestModal && (
          <TestApi handleHideDialog={setShowTestModal} data={webhookInfo} />
        )}
        {showEditWebhook && (
          <CreateAndEditEventWebhookSidePanel
            isCreateWebhookSidePanel={false}
            onSetOpen={setShowEditWebhook}
            webhookPanelInfo={webhookInfo}
            onToastNotification={webhookEditSuccess}
            setLoading={setIsLoading}
            data={webhookDetails}
          />
        )}
        {showStatusModal && (
          <StatusModal
            onSetOpen={setShowStatusModal}
            webhookInfo={webhookInfo}
            onSuccess={webhookStateSuccess}
            paused={showStatusWebhook}
          />
        )}
        {showUnregisterWebhook && (
          <UnregisterWebhookModal
            onSetOpen={setShowUnregisteredWebhook}
            unregisterWebhookInfo={webhookInfo}
            webhookId={id}
            subscriptionLength={subscriptionInfo ? subscriptionInfo.length : 0}
          />
        )}
        {deleteSubscriptionDialog && (
          <DeleteSubscriptionDialog
            onSetOpen={(event) => {
              if (event === true) {
                setDeleteSubscriptionDialog(false)
                fetchSubscription()
                setShowNotification(
                  displayNotification(
                    `Event subscription removed for event ${subscriptionId.eventType}`,
                    'info',
                    setShowNotification
                  )
                )
              } else {
                setDeleteSubscriptionDialog(event)
              }
            }}
            subscriptionId={subscriptionId}
            webhookId={id}
          />
        )}
        {showNotification}
        {message &&
          displayNotification(message, toastType, setMessage, successTitle)}
      </PageContent>
    </Page>
  )
}
const Event2Details = () => {
  return (
    <Layout>
      <Events2DetailsPage />
    </Layout>
  )
}
export default Event2Details
