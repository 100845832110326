// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Lock } from 'grommet-icons'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import { NoDataInfo } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'

const ReportingNotAuthDashboard = () => {
  const { t } = useTranslation(['reporting_dashboard'])
  return (
    <Box margin={{ top: 'large' }}>
      <NoDataInfo
        icon={<Lock size="xlarge" />}
        title={t('reporting_dashboard:unauthorized.not_auth_title')}
        subtitle={t('reporting_dashboard:unauthorized.not_auth_desc')}
        testId="reporting-no-auth"
      />
    </Box>
  )
}

const ReportingNotAuthDashboardPage = () => {
  return (
    <Layout>
      <ReportingNotAuthDashboard />
    </Layout>
  )
}

export default ReportingNotAuthDashboardPage
