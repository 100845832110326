// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { FormDown, FormNext, FormPrevious, Search } from 'grommet-icons/icons'
import { Box, List, TextInput } from 'grommet'
import PropTypes from 'prop-types'

import { Button } from '../../components/button/Button'
import { Typography } from '../../components/typography/Typography'

export const Tree = ({
  title = '',
  subtitle = '',
  children,
  searchString = '',
  enableLeftColFilter,
  leftColPlaceHolder = '',
  mode = 'column',
  data = [],
  onClickLastParent = () => {},
  ...rest
}) => {
  // The number of levels from the root, while the root is level zero.
  const [depth, setDepth] = useState(0) // initializing to root level
  // an array of the path names
  const [path, setPath] = useState([])
  const [searchStr, setSearchStr] = useState('')
  const [selectedNode, setSelectedNode] = useState(null)
  const [filteredNode, setFilteredNode] = useState(null)
  // treeLevelData is an array of objects from the 'depth' level,
  // and would eventually be translated to a List.
  // treeLevelData should only be updated on useEffect to reflect correct depth
  const [treeLevelData, setTreeLevelData] = useState(data)
  const [filteredTreeLevelData, setFilteredTreeLevelData] = useState(data)
  const [leftColSearchStr, setLeftColSearchStr] = useState('')
  const [selected, setSelected] = useState(null)
  // parentLevel should only be updated on useEffect to reflect correct depth
  const [parentLevel, setParentLevel] = useState()
  const getFilteredTreeLevelData = useCallback(() => {
    const finalItems = []
    if (treeLevelData && treeLevelData.length > 0 && leftColSearchStr !== '') {
      treeLevelData.forEach((item) => {
        const itemName = item.name.toLowerCase()
        if (itemName.indexOf(leftColSearchStr.toLowerCase()) > -1) {
          finalItems.push(item)
        }
      })
      return finalItems
    }
    return treeLevelData
  }, [leftColSearchStr, treeLevelData])
  useEffect(() => {
    let level = data
    let parent
    for (let i = 0; i < depth; i += 1) {
      // follow the breadcrumbs path
      parent = level.find((element) => element.name === path[i])
      if (parent) {
        level = parent.children
      }
    }
    if (selectedNode) {
      setSelected(level.findIndex((item) => item.name === selectedNode.name))
    }
    setParentLevel(parent)
    setTreeLevelData(level)
    setFilteredTreeLevelData(level)
    setLeftColSearchStr('')
  }, [path, data, depth, selectedNode])

  const getFilteredNode = useCallback(() => {
    const finalSubs = []
    const finalResources = []
    if (searchStr !== '') {
      if (selectedNode?.subs?.length > 0) {
        selectedNode.subs.forEach((sub) => {
          const subName = sub.name.toLowerCase()
          if (subName.indexOf(searchStr) > -1) {
            finalSubs.push(sub)
          }
        })
      }
      if (selectedNode?.scope_resource_instances?.length > 0) {
        selectedNode.scope_resource_instances.forEach((sub) => {
          const subName = sub.name.toLowerCase()
          if (subName.indexOf(searchStr) > -1) {
            finalResources.push(sub)
          }
        })
      }
      return {
        ...selectedNode,
        subs: finalSubs,
        scope_resource_instances: finalResources
      }
    }
    return selectedNode
  }, [searchStr, selectedNode])
  useEffect(() => {
    setSearchStr(searchString.toLowerCase())
  }, [searchString])
  useEffect(() => {
    setFilteredTreeLevelData(getFilteredTreeLevelData())
  }, [leftColSearchStr, getFilteredTreeLevelData])
  useEffect(() => {
    setFilteredNode(getFilteredNode())
  }, [selectedNode, getFilteredNode])
  const navigateToNextNode = (currentPath, nodeName) => {
    setPath([...currentPath, nodeName])
    setSelected(undefined)
    setSelectedNode(undefined)
    setDepth(depth + 1)
  }
  const columnNavigationList = (
    <Box width="medium">
      {enableLeftColFilter ? (
        <Box pad="medium">
          <TextInput
            icon={<Search />}
            placeholder={leftColPlaceHolder}
            value={leftColSearchStr}
            onChange={(event) => setLeftColSearchStr(event.target.value)}
            data-testid={`${rest.testId}-left-search`}
          />
        </Box>
      ) : null}
      <Box overflow="auto">
        <List
          data={filteredTreeLevelData}
          onClickItem={(event) => {
            setSelectedNode(event.item)
            setSelected(event.index)
            if (event && event.item && !event.item.subs) {
              navigateToNextNode(path, event.item.name)
            } else {
              onClickLastParent(event.item)
            }
          }}
          itemProps={
            selected >= 0
              ? { [selected]: { background: 'background-back' } }
              : undefined
          }
          {...rest}
          title={title}
          subtitle={subtitle}
        >
          {(datum) => {
            return (
              <Box direction="row" justify="between" align="center" gap="small">
                <Typography type="text" testId={datum.name} size="small">
                  {datum.name}
                </Typography>
                <Box direction="row" gap="hair" align="center">
                  {datum && datum.children && datum.children.length > 0 && (
                    <Typography
                      type="text"
                      testId="children-length"
                      size="xsmall"
                    >
                      {`${datum.children.length} sub-resources`}
                    </Typography>
                  )}
                  <FormNext />
                </Box>
              </Box>
            )
          }}
        </List>
      </Box>
    </Box>
  )

  const NestedNavigationList = () => (
    <List
      data={filteredTreeLevelData}
      onClickItem={(event) => {
        setSelectedNode(event.item)
      }}
    >
      {(datum) => {
        return (
          <Box direction="row" align="center" gap="small">
            {selectedNode === datum ? <FormDown /> : <FormNext />}
            <Typography type="text" testId={datum.name} size="small">
              {datum.name}
            </Typography>
            {/* TODO
            The concept of "nested" differs from  "column" 
            by the following implementations aspects: 
            1. selectedNode should be an array instead of a single value
            2. focus should be per List item which isn't achievable via List
            3. treeLevel and parentLevel are no longer relevant
            4. concept of depth is no longer relevant as we may have 'multiple' 
            5. should we consider leverage the existing mechanism of Collapsible
            */}
          </Box>
        )
      }}
    </List>
  )

  let content
  if (mode === 'column') {
    content = (
      <Box align="start" gap="medium">
        {/* Breadcrumbs */}
        {depth > 0 && path.length > 0 && (
          <Button
            a11yTitle={path[path.length - 1]}
            testId="tree-button"
            label={
              <Typography
                type="text"
                testId="path-length"
                style={{ textDecoration: 'underline' }}
                size="small"
              >
                {path[path.length - 1]}
              </Typography>
            }
            icon={<FormPrevious />}
            plain
            onClick={() => {
              // remove the last breadcrumb item when Previous is selected
              const updatedPath = [...path]
              updatedPath.pop()
              setPath(updatedPath)
              setDepth(depth - 1)
              setSelectedNode(parentLevel)
            }}
          />
        )}
        {/* Layout Containers for List and user content */}
        <Box direction="row" border width="large">
          {columnNavigationList}
          <Box
            border="left"
            align="start"
            gap="large"
            pad="medium"
            overflow="auto"
          >
            {filteredNode && children(filteredNode)}
            {filteredNode &&
              filteredNode.children &&
              filteredNode.children.length > 0 && (
                <Button
                  a11yTitle={`${filteredNode.children.length} sub-resources`}
                  plain
                  label={
                    <Typography
                      type="text"
                      testId="sub-resource-length"
                      size="small"
                    >
                      {`${filteredNode.children.length} sub-resources`}
                    </Typography>
                  }
                  testId={filteredNode.name}
                  icon={<FormNext />}
                  reverse
                  onClick={() => {
                    // add a new selected entry to the breadcrumbs path
                    navigateToNextNode(path, filteredNode.name)
                  }}
                />
              )}
          </Box>
        </Box>
      </Box>
    )
  } else {
    content = (
      <Box align="start">
        <NestedNavigationList />
      </Box>
    )
  }

  return content
}

Tree.propTypes = {
  data: PropTypes.array.isRequired,
  /**
   * It will be used for component reference to test.
   * This is mandatory.
   */
  mode: PropTypes.string,
  leftColPlaceHolder: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  searchString: PropTypes.string,
  testId: PropTypes.string.isRequired,
  onClickLastParent: PropTypes.func.isRequired
}
