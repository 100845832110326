// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Button,
  ButtonGroup,
  Loader,
  ModalDialog,
  ModalHeader,
  Typography
} from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { get } from '../../../../utils/api-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'
import {
  getDeviceTypeEnum,
  displayDeviceTypeEnum
} from '../../../../utils/common-utils'

import { EditAutoSubscription } from './EditAutoSubscription'
import { AutoSubscriptionConfirmationModal } from './AutoSubscriptionConfirmationModal'
import { SetUpAutoSubscriptionModal } from './SetUpAutoSubscriptionModal'

const ManageAutoSubscriptionModal = ({ setShowModal, customerId }) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const [autoSubscribeData, setAutoSubscribeData] = useState([])
  const [tierGroupsObj, setTierGroupsObj] = useState({})
  const [overrideStatus, setOverrideStatus] = useState(false)
  const [editMode, setEditMode] = useState({})
  const [pullRefresh, setPullRefresh] = useState(true)
  const [requestData, setRequestData] = useState({})
  const [setUpAutoSubscribeModalOpen, setSetUpAutoSubscribeModalOpen] =
    useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let isCurrent = true
    setIsLoading(true)
    const url = '/support-assistant/v1alpha1/autolicense'
    get(url, { platform_customer_id: customerId }, oidcUser.access_token)
      .then(
        (response) => {
          const tierTypeGroupObj = {}
          let responseData = response?.data?.autolicenses?.reduce(
            (acc, datum) => {
              const {
                enabled,
                device_type: deviceType,
                auto_license_subscription_tier_group: tierGroup,
                auto_license_subscription_tier_description: tierGroupDesc,
                tier_groups: tierGroups,
                open_seats: available
              } = datum || ''
              // if response include compute device exclude it if glcp-compute-auto-subscribe LD is OFF
              if (
                ['COMPUTE', 'DHCI_COMPUTE'].includes(deviceType) &&
                !LDFlags['glcp-compute-auto-subscribe']
              )
                return acc

              const modifiedTierGroups = tierGroups?.map((tierData) => {
                return {
                  label: t(
                    'auto_subscribe_page.subscription_group_with_count_info',
                    {
                      subscriptionGroup:
                        tierData?.auto_license_subscription_tier_description,
                      availableSeatCount: tierData?.open_seats
                    }
                  ),
                  value: tierData?.auto_license_subscription_tier_group
                }
              })
              if (modifiedTierGroups)
                tierTypeGroupObj[deviceType] = modifiedTierGroups
              editMode[deviceType] = false
              if (enabled === true) {
                acc.push({
                  device_type: deviceType,
                  tierGroup,
                  subscription_tier: t(
                    'auto_subscribe_page.subscription_group_with_count_info',
                    {
                      subscriptionGroup: tierGroupDesc,
                      availableSeatCount: available
                    }
                  )
                })
              }
              return acc
            },
            []
          )

          if (!LDFlags['glcp-dm-silver-peak'] && responseData?.length > 0) {
            responseData = responseData.filter((device) => {
              return device.device_type !== displayDeviceTypeEnum.SD_WAN_GW
            })
          }
          if (!isCurrent) return
          setAutoSubscribeData(responseData)
          setTierGroupsObj(tierTypeGroupObj)
          setOverrideStatus(response.data.show_override)
        },
        (error) => {
          setAutoSubscribeData([])
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
      .then(() => setIsLoading(false))
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, t, LDFlags, customerId, pullRefresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const onUpdateAutoSubscriptionSuccess = () => {
    setConfirmationModalOpen(false)
    setPullRefresh(!pullRefresh)
    setEditMode({
      ...editMode,
      [requestData?.device_type]: false
    })
    setSuccessMessage(t('updated_auto_subscribe_successfully'))
  }

  return (
    <>
      {!confirmationModalOpen && !setUpAutoSubscribeModalOpen && (
        <ModalDialog
          header={
            <ModalHeader
              title={
                <Box gap="medium" pad="xsmall" width="xlarge">
                  <Box direction="row-responsive" justify="between" wrap>
                    <Box width="large">
                      <Typography
                        level="1"
                        type="heading"
                        pad={{ bottom: 'xxlarge' }}
                        color="text-strong"
                        testId="auto-subscibe-title"
                      >
                        {t('auto_subscribe')}
                      </Typography>
                      <Typography
                        size="medium"
                        type="text"
                        testId="auto-subscribe-subtitle"
                      >
                        {t('auto_subscribe_page.subtitle')}
                      </Typography>
                    </Box>
                    <Box
                      direction="row-responsive"
                      gap="medium"
                      justify="between"
                      wrap
                    >
                      <VisibilityWrapper
                        rbac={{
                          resource: '/ccs/device-management',
                          permission: 'ccs.device-management.edit'
                        }}
                      >
                        {!isEqual(autoSubscribeData, []) && (
                          <Box>
                            <Button
                              label={t('common:add')}
                              secondary
                              onClick={() =>
                                setSetUpAutoSubscribeModalOpen(true)
                              }
                              testId="add-btn"
                            />
                          </Box>
                        )}
                      </VisibilityWrapper>
                      <Box>
                        <Button
                          label={t('close')}
                          secondary
                          onClick={() => setShowModal(false)}
                          testId="close-btn"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              }
            />
          }
          content={
            <>
              {isLoading ? (
                <Box margin={{ top: 'large' }}>
                  <Loader testId="auto-subscribe-loader" />
                </Box>
              ) : (
                <Box gap="large" direction="row" margin={{ top: 'large' }}>
                  {isEqual(autoSubscribeData, []) ? (
                    <VisibilityWrapper
                      hideFor={{ account: ['TENANT'] }}
                      rbac={{
                        resource: '/ccs/device-management',
                        permission: 'ccs.device-management.edit'
                      }}
                    >
                      <Box direction="row">
                        <Button
                          label={t('setup_auto_subscribe')}
                          secondary
                          testId="set-up-auto-subscribe-btn"
                          onClick={() => setSetUpAutoSubscribeModalOpen(true)}
                        />
                      </Box>
                    </VisibilityWrapper>
                  ) : (
                    <Box
                      width="large"
                      pad={{ top: 'medium' }}
                      border={{ side: 'top', color: 'border-weak' }}
                      data-testid="auto-subcribe-container"
                    >
                      {autoSubscribeData?.map((value, idx) => (
                        <Box
                          direction="row"
                          gap="medium"
                          margin={{ bottom: 'medium' }}
                          key={
                            idx /* eslint-disable-line react/no-array-index-key */
                          }
                          align="center"
                          data-testid={`${value.device_type}-card`}
                        >
                          {editMode && editMode[value.device_type] ? (
                            <EditAutoSubscription
                              onSave={(subscriptionTierGroup) => {
                                setRequestData({
                                  device_type: value.device_type,
                                  enabled: true,
                                  auto_license_subscription_tier_group:
                                    subscriptionTierGroup
                                })
                                setConfirmationModalOpen(true)
                              }}
                              onClose={() => {
                                setEditMode({
                                  ...editMode,
                                  [value.device_type]: false
                                })
                              }}
                              customerId={customerId}
                              currentData={value}
                              overrideStatus={overrideStatus}
                              tierGroupsObj={tierGroupsObj}
                            />
                          ) : (
                            <Box
                              direction="row-responsive"
                              justify="between"
                              pad={{ bottom: 'medium', horizontal: 'small' }}
                              align="center"
                              width="large"
                              border={{ side: 'bottom', color: 'border-weak' }}
                              wrap
                            >
                              <Box direction="column" gap="xsmall">
                                <Typography
                                  size="large"
                                  testId="device-type"
                                  type="text"
                                  weight="bold"
                                >
                                  {getDeviceTypeEnum(value.device_type, t)}
                                </Typography>
                                <Typography
                                  size="small"
                                  testId="subscription-tier"
                                  type="text"
                                >
                                  {value.subscription_tier}
                                </Typography>
                              </Box>
                              <VisibilityWrapper
                                rbac={{
                                  resource: '/ccs/device-management',
                                  permission: 'ccs.device-management.edit'
                                }}
                              >
                                <ButtonGroup
                                  buttonList={[
                                    {
                                      id: 2,
                                      label: t('delete_label'),
                                      default: true,
                                      testId: 'auto-subscribe-delete-btn',
                                      onClick: () => {
                                        setRequestData({
                                          device_type: value.device_type,
                                          enabled: false,
                                          auto_license_subscription_tier_group:
                                            value.tierGroup
                                        })
                                        setConfirmationModalOpen(true)
                                      }
                                    },
                                    {
                                      id: 1,
                                      label: t('edit'),
                                      default: true,
                                      testId: 'auto-subscribe-edit-btn',
                                      onClick: () => {
                                        setEditMode({
                                          ...editMode,
                                          [value.device_type]: true
                                        })
                                      }
                                    }
                                  ]}
                                  testId="auto-subscribe-action-buttons"
                                />
                              </VisibilityWrapper>
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  )}
                  {successMessage &&
                    displayNotification(
                      successMessage,
                      'info',
                      setSuccessMessage
                    )}
                  {showErrorNotification}
                </Box>
              )}
            </>
          }
          onClose={() => setShowModal(false)}
          testId="manage-auto-subscription-modal"
          width="xlarge"
        />
      )}

      {setUpAutoSubscribeModalOpen && (
        <SetUpAutoSubscriptionModal
          setModalOpen={setSetUpAutoSubscribeModalOpen}
          onSuccess={() => {
            setSetUpAutoSubscribeModalOpen(false)
            setPullRefresh(!pullRefresh)
            setSuccessMessage(t('updated_auto_subscribe_successfully'))
          }}
          tierGroupsObj={tierGroupsObj}
          customerId={customerId}
        />
      )}
      {confirmationModalOpen && (
        <AutoSubscriptionConfirmationModal
          setModalOpen={setConfirmationModalOpen}
          onSuccess={onUpdateAutoSubscriptionSuccess}
          title={t('auto_subscribe_page.modal.title')}
          description={
            overrideStatus
              ? t('auto_subscribe_page.modal.subtitle_for_overide')
              : t('auto_subscribe_page.modal.subtitle')
          }
          customerId={customerId}
          requestData={requestData}
        />
      )}
    </>
  )
}

ManageAutoSubscriptionModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired
}

export { ManageAutoSubscriptionModal }
