// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { visibilityPermissions } from '../../../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { useVisibilityContext } from '../../../../../../../../context/visibility-context'

const useHasShowbackRatesPermission = (isManage) => {
  const { rbacPolicies } = useVisibilityContext()
  const checkingPerm = isManage
    ? 'ccs.device-showback-rate.manage'
    : 'ccs.device-showback-rate.view'

  return visibilityPermissions({
    controlled: true,
    rbacPolicies,
    rbac: {
      resource: '/ccs/device-management/showback-rate',
      permission: checkingPerm
    }
  })
}

export { useHasShowbackRatesPermission }
