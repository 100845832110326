// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import PropTypes from 'prop-types'
import { Markdown as GrommetMarkdown, Text } from 'grommet'

export const emphasisStyle = (size) => {
  return {
    strong: {
      component: Text,
      props: { weight: 500, size }
    }
  }
}

export default function Markdown({ size, ...rest }) {
  return <GrommetMarkdown components={emphasisStyle(size)} {...rest} />
}

Markdown.propTypes = {
  /**
   * size of the the markdown text
   */
  size: PropTypes.string
}

Markdown.defaultProps = {
  size: null
}
