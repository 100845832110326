// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'
import { StatusCritical } from 'grommet-icons'
import dayjs from 'dayjs'
import uniqBy from 'lodash/uniqBy'
import { useNavigate } from 'react-router'

import {
  DataTable,
  FilterButton,
  Notification,
  Typography
} from '../../../../../../components'
import { getPaginationShowIdx } from '../../../../../../utils/common-utils'
import { getTableData } from '../../customer-apis'

const RRPDataTable = ({ customerId }) => {
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['manage', 'common', 'authz'])
  const navigate = useNavigate()

  // for datatable data, search, filters, select
  const [rrpList, setRRPList] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [filterOptions, setFilterOptions] = useState('')

  // for populating apps in filter button
  const [appsList, setAppsList] = useState([])

  // for pagination
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const columns = [
    {
      property: 'name',
      type: 'string',
      header: t('name')
    },
    {
      property: 'description',
      type: 'string',
      header: t('description')
    },

    {
      property: 'application_name',
      type: 'string',
      header: t('customer_details.application_name')
    },
    {
      property: 'created_at',
      type: 'date',
      header: t('customer_details.created_at'),
      render: (datum) => {
        const createdAt = dayjs(datum.created_at).format('MMMM DD, YYYY')
        return (
          <Typography size="medium" type="text">
            {createdAt}
          </Typography>
        )
      }
    }
  ]

  useEffect(() => {
    const request = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      platform_cid: customerId,
      ...filterOptions,
      ...(searchVal?.trim()?.length && { name: searchVal.trim() })
    }

    getTableData(
      'resource-restrictions',
      oidcUser.access_token,
      request,
      setTotalItems,
      setErrorMessage,
      t
    ).then((data) => {
      setRRPList(data)
    })
  }, [customerId, oidcUser.access_token, t, searchVal, page, filterOptions])

  useEffect(() => {
    const request = {
      limit: 2000,
      offset: 0,
      platform_customer_id: customerId,
      provision_status: 'PROVISIONED'
    }
    getTableData(
      'applications',
      oidcUser.access_token,
      request,
      () => {},
      () => {},
      t
    ).then((data) => {
      setAppsList(
        uniqBy(data, 'application_id').map((app) => ({
          application_id: app?.application_id,
          application_name: app?.application_name
        }))
      )
    })
  }, [customerId, oidcUser.access_token, t])

  const handleRowClick = (datum) => {
    navigate(
      `/manage-ccs/customers/scope-groups/${datum.resource_restriction_policy_id}`
    )
  }

  return (
    <Box margin={{ vertical: 'small' }}>
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        grid={{
          columns,
          data: rrpList,
          onClickRow: ({ datum }) => handleRowClick(datum)
        }}
        search={{
          onSearchValueChange: (value) => {
            setPage(1)
            setSearchVal(value)
          },
          placeholder: t('search_box_placeholder')
        }}
        filterButton={
          <FilterButton
            filterAttributes={[
              {
                label: t('customer_details.application_name'),
                name: 'application_id',
                values: appsList.map((data) => ({
                  valueLabel: data?.application_name,
                  valueName: data?.application_id
                })),
                selectionType: 'radio'
              }
            ]}
            onFilterValuesChange={(filters) => {
              const formattedValues = Object.fromEntries(
                Object.entries(filters).map(([key, val]) => [key, val.join()])
              )
              setFilterOptions(formattedValues)
            }}
            testId="rrp-filter-search-btn"
          />
        }
        summary={{ entityName: t('authz:rrp.res_restriction_policy_plural') }}
        testId="ccs-manager-rrp-data-table"
      />
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
    </Box>
  )
}

RRPDataTable.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}
export { RRPDataTable }
