// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { AuthenticationContext, useReactOidc } from '@axa-fr/react-oidc-context'
import { Box } from 'grommet'

import { LogoSpinner } from '../../../icons'
import { Typography } from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { del, get } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { cleanClientStorage } from '../../../utils/client-storage-cleaning-utils'
import useLogger from '../../../hooks/logs/useLogger'
import { V2_LOGGER } from '../../../utils/log-util'
import { isV2 } from '../../../utils/feature-flag-utils'

const SignOut = () => {
  const { t } = useTranslation(['authn'])
  const { userManager } = useContext(AuthenticationContext)
  const [apiError, setApiError] = useState(null)
  const { oidcUser } = useReactOidc()
  const authzV2Enabled = isV2()
  const logger = useLogger()
  const v2Logger = {
    ...V2_LOGGER,
    page: SignOut?.name,
    section: 'sign-out'
  }

  useEffect(() => {
    cleanClientStorage()
    const redirectUrl = async () => {
      if (userManager) {
        const redirectURI = `${window.location.protocol}//${window.location.host}`
        await userManager.signoutRedirect({
          extraQueryParams: {
            TargetResource: redirectURI,
            InErrorResource: redirectURI
          }
        })
      }
    }
    const logout = async () => {
      get('/authn/v1/session/end-session', {}, oidcUser?.access_token).then(
        () => {
          redirectUrl()
        },
        (error) => {
          console.log(error)
          setApiError(error)
        }
      )
      if (authzV2Enabled) {
        try {
          logger?.log({
            ...v2Logger,
            msg: `ending v2 user session`
          })
          await del(`/internal-sessions/v1alpha1/my-ui-session`)
        } catch (err) {
          logger?.log({
            ...v2Logger,
            msg: `couldn't end user session`,
            type: 'error',
            data: err
          })
          console.log(err)
        }
      }
      sessionStorage.removeItem('orgLaunchInfo')
      sessionStorage.removeItem('NewPostModel')
      sessionStorage.removeItem('ccs-cop-login-banner')
    }
    logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManager])

  return (
    <>
      {apiError && displayApiError(apiError, t, setApiError)}
      <Box
        margin={{ top: 'xlarge' }}
        alignSelf="center"
        width="large"
        data-testid="find-accounts"
      >
        <LogoSpinner />
        <Typography
          margin={{ top: 'large', bottom: 'small' }}
          type="heading"
          level="1"
          testId="signing-out"
        >
          {t('acct_onboarding.signing_out')}
        </Typography>
        <Typography type="text" size="xxlarge" testId="find-accounts-subtitle">
          {t('acct_onboarding.securely_signing_out')}
        </Typography>
      </Box>
    </>
  )
}

const SignOutPage = () => {
  return (
    <Layout
      hideHeaderOptions={['no-header']}
      bannerOptions="no-banner"
      hideIpModal
    >
      <SignOut />
    </Layout>
  )
}

export default SignOutPage
