// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Button, Stack } from 'grommet'
import { Previous, StatusGoodSmall } from 'grommet-icons'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { MenuList, Typography } from '../../../components'
import { get } from '../../../utils/api-utils'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'

const props = {
  position: 'absolute',
  top: '-9px',
  right: '-18px'
}

const delay = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const Menu = ({ id, setId }) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const custAccountLoaded = getCustomerAccount()
  const [hasNewAlertsTasks, setHasNewAlertsTasks] = useState(false)
  const [hasNotifications, setHasNotifications] = useState(false)
  const menuData = [
    {
      id: 1,
      label: (
        <Stack anchor="top-right" fill>
          <Typography level="3" type="heading" size="medium">
            {t('notification.alerts_tasks.sub_heading_alerts')}
          </Typography>
          {hasNewAlertsTasks && <StatusGoodSmall style={props} size="10px" />}
        </Stack>
      ),
      testId: 'Alerts & Tasks'
    },
    {
      id: 2,
      label: (
        <Stack anchor="top-right">
          <Typography
            level="3"
            type="heading"
            size="medium"
            weight={600}
            emphasis
          >
            {t('notification.alerts_tasks.sub_heading_announcements')}
          </Typography>
          {hasNotifications && <StatusGoodSmall style={props} size="10px" />}
        </Stack>
      ),
      testId: 'Announcements'
    }
  ]
  function changeHasNewMenuOptions(value, url) {
    if (url.includes('alerts-tasks')) {
      setHasNewAlertsTasks(value)
    } else {
      setHasNotifications(value)
    }
  }
  const handleNew = (url) => {
    if (oidcUser?.access_token && custAccountLoaded !== null) {
      get(url, {}, oidcUser.access_token).then((response) => {
        if (response?.data) {
          changeHasNewMenuOptions(true, url)
        } else {
          changeHasNewMenuOptions(false, url)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  const handleNewEvents = useCallback(() => {
    const headerRootNode = document.querySelector('greenlake-header')
    if (oidcUser.access_token && custAccountLoaded !== null) {
      const url =
        '/notifications-svc/ui/v1alpha1/has-new-events?has_new_type=action'
      get(url, {}, oidcUser.access_token).then(
        (response) => {
          if (response?.data) {
            headerRootNode.hasNotifications = true
          } else {
            headerRootNode.hasNotifications = false
          }
        },
        () => {
          headerRootNode.hasNotifications = false
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleNewEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMenuItemClick = (item) => {
    setId(item.id)
    // Delay to make it sync with last-seen api call. So has-new will get called after last-seen api call.
    // Suggestion  : To separate these things through useReducer and useContext
    if (item.id === 1) {
      delay(1500).then(() => {
        handleNew(
          '/notifications-svc/ui/v1alpha1/has-new-alerts-tasks?has_new_type=action'
        )
      })
    } else if (item.id === 2) {
      delay(1500).then(() => {
        handleNew('/notifications-svc/ui/v2/has-new?has_new_type=action')
      })
    }
  }
  useEffect(() => {
    handleNew(
      '/notifications-svc/ui/v1alpha1/has-new-alerts-tasks?has_new_type=action'
    )
    handleNew('/notifications-svc/ui/v1alpha1/has-new?has_new_type=action')
    const interval = setInterval(() => {
      if (!hasNewAlertsTasks) {
        handleNew(
          '/notifications-svc/ui/v1alpha1/has-new-alerts-tasks?has_new_type=periodic'
        )
      }
      if (!hasNotifications) {
        handleNew('/notifications-svc/ui/v2/has-new?has_new_type=periodic')
      }
      if (!hasNewAlertsTasks || !hasNotifications) handleNewEvents()
    }, 180000)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MenuList
      defaultActiveId={id}
      highlightOnSelect
      menuData={menuData}
      menuItemPadding={{
        left: 'small',
        vertical: 'xsmall'
      }}
      navStyles={{
        padding: { left: 'small', right: 'small', bottom: 'small' },
        gap: 'small',
        responsive: true,
        direction: 'column'
      }}
      onClickMenuItem={handleMenuItemClick}
      testId="menulist"
    />
  )
}

const BackButton = () => {
  const { t } = useTranslation(['manage'])
  const navigate = useNavigate()
  const handleBackBtnClick = () => {
    navigate(-1)
  }

  return (
    <Box direction="row" justify="start" align="start">
      <Button
        default
        label={t('back')}
        icon={<Previous />}
        onClick={handleBackBtnClick}
        data-testid="notification-back-btn"
      />
    </Box>
  )
}

Menu.propTypes = {
  id: PropTypes.number.isRequired,
  setId: PropTypes.func.isRequired
}

export default Menu
export { BackButton }
