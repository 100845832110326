// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Box, NameValuePair } from 'grommet'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'

import { Typography } from '../../../../components'

export const LocationAddress = ({ address }) => {
  const { t } = useTranslation(['location'])
  // Allow HPE theme to spread to Typography instance
  const {
    nameValuePair: { name }
  } = useContext(ThemeContext)
  return (
    <NameValuePair
      data-testid="addresses-details"
      name={
        <Typography
          type="text"
          testId={`location-details-${address.type}-title`}
          {...name}
        >
          {address.type === 'street'
            ? t('street_address')
            : t('shipping_and_receiving')}
        </Typography>
      }
    >
      <Typography testId={`${address.type}-street`} type="text">
        {address.street_address}
      </Typography>
      <Typography testId={`${address.type}-street-2`} type="text">
        {address.street_address2}
      </Typography>
      <Box direction="row" gap="xsmall">
        <Typography testId={`${address.type}-city`} type="text">
          {address.city}
        </Typography>
        <Typography testId={`${address.type}-state`} type="text">
          {address.state}
        </Typography>
        <Typography testId={`${address.type}-postal-code`} type="text">
          {address.postal_code}
        </Typography>
      </Box>
      <Typography testId={`${address.type}-country`} type="text">
        {address.country}
      </Typography>
    </NameValuePair>
  )
}
LocationAddress.propTypes = {
  address: PropTypes.shape({
    type: PropTypes.string.isRequired,
    street_address: PropTypes.string.isRequired,
    street_address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postal_code: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
  }).isRequired
}
