// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom'

import Users from '../../igc-service/users/Users'
import { UserDetails } from '../../igc-service/users/UserDetails'
import GroupsRouter from '../../igc-service/groups/router'
import DomainsPage from '../../igc-service/domains/DomainsPage'
import AddDomain from '../../igc-service/domains/components/AddDomainWizard'
import DomainDetails from '../../igc-service/domains/DomainDetails'
import SsoProfilesRouter from '../../igc-service/sso-profiles/router'
import WorkspacesRouter from '../../igc-service/workspaces/router'
import {
  checkGotoChooseAccount,
  LIFECYCLE_STATE
} from '../../../utils/common-utils'
import { get } from '../../../utils/api-utils'
import { getOrganizationId, isV2 } from '../../../utils/feature-flag-utils'

import OrganizationGovernancePage from './OrganizationGovernance'

const OrganizationGovernanceRouter = () => {
  const orgId = getOrganizationId()
  const v2 = isV2()
  const navigate = useNavigate()
  const location = useLocation() // required to call API within useEffect
  checkGotoChooseAccount(navigate, location)
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(v2)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    // API call will happen only if the V2 session (authzV2Enabled) is established and organization ID is not null
    if (v2 && orgId && orgId !== '') {
      get(`/organizations/v2alpha1/organizations/${orgId}`)
        .then((response) => {
          if (response?.data) {
            setActive(response?.data?.lifecycleState === LIFECYCLE_STATE.ACTIVE)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [v2, orgId, location?.state?.triggerEffect, refresh])

  return (
    <>
      {!loading && v2 && (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <OrganizationGovernancePage
                refreshRouter={() => {
                  setRefresh((prev) => prev + 1)
                }}
              />
            }
          />
          {active ? (
            <>
              <Route exact path="/users/" element={<Users />} />
              <Route exact path="/users/:userId/" element={<UserDetails />} />
              <Route path="/groups/*" element={<GroupsRouter />} />
              <Route exact path="/domains/" element={<DomainsPage />} />
              <Route
                exact
                path="/domains/add-domain/"
                element={<AddDomain />}
              />
              <Route exact path="/domains/:id/" element={<DomainDetails />} />
              <Route path="/sso-profiles/*" element={<SsoProfilesRouter />} />
              <Route path="/workspaces/*" element={<WorkspacesRouter />} />
              <Route path="/*" element={<Navigate to="/not-found" />} />
            </>
          ) : (
            <Route path="/*" element={<Navigate to="/not-found" />} />
          )}
        </Routes>
      )}
    </>
  )
}

export { OrganizationGovernanceRouter }
