// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'

import LdFlagsHomePage from './featureflagsHome'

const LdFlagsApplicationRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)

  return (
    <Routes>
      <Route exact path="/" element={<LdFlagsHomePage />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export default LdFlagsApplicationRouter
