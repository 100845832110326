// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'

import { Card, Typography, Button } from '../../../../components'

const ExploreCard = ({
  title,
  description,
  icon,
  path = '',
  hasEval = undefined,
  setEvalModalFormOpen = undefined,
  testId = undefined
}) => {
  return (
    <Card
      CustomContent={
        <Box gap="small">
          <Box direction="row" justify="between">
            <Typography type="heading" level={3} testId={`${testId}-heading`}>
              {title}
            </Typography>
            {hasEval ? (
              <Button
                icon={icon}
                plain
                onClick={() => setEvalModalFormOpen(true)}
                testId={`${testId}-btn`}
              />
            ) : (
              <Button
                icon={icon}
                plain
                href={path}
                target="_blank"
                testId={`${testId}-btn`}
              />
            )}
          </Box>
          <Typography type="text" testId={`${testId}-description`}>
            {description}
          </Typography>
        </Box>
      }
      testId={testId}
    />
  )
}

ExploreCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  path: PropTypes.string,
  hasEval: PropTypes.bool,
  setEvalModalFormOpen: PropTypes.func,
  testId: PropTypes.string
}

export default ExploreCard
