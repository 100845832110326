// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Meter,
  Heading,
  Notification,
  Text,
  ThemeContext,
  Grid,
  Stack
} from 'grommet'
import PropTypes from 'prop-types'
import { LinkNext, StatusGoodSmall, Checkmark } from 'grommet-icons'
import * as allIcons from 'grommet-icons'

import { Typography } from '../typography/Typography'

import { WidgetLayout } from './WidgetLayout'

const COLOR_MAP = ['graph-0', 'graph-1', 'graph-2', 'graph-3', 'graph-4']

const chartTypeMap = Object.freeze({
  CIRCLE: 'circle',
  PIE: 'pie',
  CARD: 'card',
  BAR: 'bar'
})

export const Widget = ({
  data = [],
  action = '',
  level = 3,
  title,
  subTitle = '',
  testId,
  chartType = '',
  footerNotification = undefined,
  footerMessage = undefined,
  noElevation = false,
  onSelectClick = () => {},
  isWidgetSelected = false,
  widgetBodyCustomStyle = {},
  ...rest
}) => {
  const { children } = rest
  const [selected, setSelected] = useState(isWidgetSelected)
  const { heading } = useContext(ThemeContext) || {}
  const callAction = () => {
    action()
  }
  const cardHeadingStyle = {
    1: heading?.level[2],
    2: heading?.level[2],
    3: heading?.level[2],
    4: heading?.level[2],
    5: heading?.level[2],
    6: heading?.level[2]
  }
  const meterValues = data?.filter((meterItem) => {
    return meterItem.summary !== true
  })

  const meterSummary = data?.find((summaryItem) => {
    return summaryItem.summary === true
  })

  const meterChartData = meterValues?.map((item, index) => {
    return {
      key: `${chartType}_${item?.label?.replace(/\s/g, '-')}_${index}`,
      label: item?.label,
      value: item?.value,
      color: COLOR_MAP[index % 5]
    }
  })

  const circleChartData = [...meterChartData]?.sort((a, b) => {
    return a?.value - b?.value
  })

  const meterSummaryPair = () => (
    <Box
      direction="row"
      border={{ side: 'bottom', color: 'border-weak' }}
      gap="xsmall"
      justify="between"
      pad={{ bottom: 'xxsmall' }}
    >
      <Text size="xsmall" weight={500}>
        {meterSummary?.label}
      </Text>
      <Text weight="bold" size="xsmall">
        {meterSummary?.value}
      </Text>
    </Box>
  )

  const meterPairs = meterChartData?.map((meterPairItem, index) => {
    const showIcon = meterPairItem?.value?.hideicon || true
    const BulletIcon =
      allIcons[
        chartType === chartTypeMap.PIE ? 'StatusGoodSmall' : 'StatusInfoSmall'
      ]
    return (
      <Box
        key={`meter-pair-item-${meterPairItem?.key}`}
        data-testid={`meter-pair-item-${meterPairItem?.key}`}
        direction="row"
        gap="large"
        justify="between"
      >
        <Box
          align="center"
          direction="row"
          gap={chartType === chartType?.PIE ? 'medium' : 'xxsmall'}
        >
          {showIcon && <BulletIcon size="small" color={COLOR_MAP[index % 5]} />}
          <Text size="xsmall">{meterPairItem?.label || ''}</Text>
        </Box>
        <Text size="xsmall">{meterPairItem?.value}</Text>
      </Box>
    )
  })

  const circleNamePairs = () => {
    return (
      <Grid columns={{ count: 'fit', size: ['xsmall', 'flex'] }} gap="xsmall">
        {circleChartData?.map((item) => {
          return (
            <Box
              key={`circle-name-pairs-box-item-${item?.key}`}
              data-testid={`circle-name-pairs-box-item-${item?.key}`}
              align="center"
            >
              <Text size="medium" weight={500} color="dark-1">
                {item?.value}
              </Text>
              <Box
                key={`circle_category_${item?.key}`}
                direction="row"
                gap="xsmall"
              >
                <StatusGoodSmall color={item?.color} />
                <Text size="xsmall">{item?.label}</Text>
              </Box>
            </Box>
          )
        })}
      </Grid>
    )
  }

  const circleBody = () => (
    <Box
      key={`circle-body-main-container-${testId}`}
      data-testid={`circle-body-main-container-${testId}`}
    >
      <Box
        key={`circle-body-sub-container-${testId}`}
        data-testid={`circle-body-sub-container-${testId}`}
        alignSelf="center"
        width="small"
      >
        <Stack
          key={`circle-body-stack-container-${testId}`}
          data-testid={`circle-body-stack-container-${testId}`}
          anchor="center"
        >
          <Meter
            key={`circle-meter-${testId}`}
            data-testid={`circle-meter-${testId}`}
            type={chartType}
            values={circleChartData}
            thickness="small"
            size="small"
          />
          {meterSummary && (
            <Box
              key={`circle-center-data-container-${testId}-${meterSummary?.label}`}
              data-testid={`circle-center-data-container-${testId}-${meterSummary?.label}`}
              align="center"
            >
              <Box
                key={`circle-center-data-summary-${testId}-${meterSummary?.label}`}
                data-testid={`circle-center-data-summary-${testId}-${meterSummary?.label}`}
                direction="column"
                align="center"
                pad={{ bottom: 'xsmall' }}
              >
                <Typography
                  key={`circle-summary-value-${testId}-${meterSummary?.label}`}
                  data-testid={`circle-summary-value-${testId}-${meterSummary?.label}`}
                  type="text"
                  size="2xl"
                  emphasis
                >
                  {meterSummary?.value}
                </Typography>
                <Text
                  key={`circle-summary-title-${testId}-${meterSummary?.circleSubTitle}`}
                  data-testid={`circle-summary-title-${testId}-${meterSummary?.circleSubTitle}`}
                  size="xsmall"
                >
                  {meterSummary?.circleSubTitle || 'Total devices'}
                </Text>
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
      <Box data-testid={`circle-name-pairs-container--${testId}`}>
        {circleNamePairs()}
      </Box>
    </Box>
  )

  const pieBody = () => (
    <Grid
      key={`pie-chart-container-${testId}`}
      data-testid={`pie-chart-container-${testId}`}
      columns={{
        count: 'fit',
        size: ['xsmall', 'auto']
      }}
      gap={{ column: 'medium', row: 'small' }}
      align="center"
      justify="center"
      fill
    >
      <Meter type={chartType} values={meterChartData} size="small" />
      <Box width={{ min: 'xsmall', max: 'small' }} gap="small">
        {meterSummaryPair()}
        {meterPairs}
      </Box>
    </Grid>
  )

  const CardWidget = () => {
    const cardContent = (cardData) => {
      const CardIcon = allIcons[cardData?.icon || 'StatusGood']
      return (
        <Box
          key={`card-container-${cardData?.key}`}
          data-testid={`card-container-${cardData?.key}`}
          background="background-contrast"
          gap="xxsmall"
          pad={{ horizontal: 'xsmall', vertical: 'medium' }}
          round="small"
        >
          <Box
            key={`card-body-${cardData?.key}`}
            data-testid={`card-body--${cardData?.key}`}
            direction="row"
            align="center"
            gap="xsmall"
            justify="center"
          >
            <CardIcon
              key={`card-icon-${cardData?.key}`}
              data-testid={`card-con-icon-${cardData?.key}`}
              color={cardData?.color || ''}
              size="large"
            />
            <Text
              key={`card-template-value-${cardData?.key}`}
              data-testId={`card-template-value-${cardData?.key}`}
              size="2xl"
            >
              <strong>{cardData?.value}</strong>
            </Text>
          </Box>
          <Text
            key={`card-footer-container-label-${cardData?.key}`}
            data-testId={`card-footer-container-label-${cardData?.key}`}
            size="xsmall"
            textAlign="center"
          >
            {cardData?.label || ''}
          </Text>
        </Box>
      )
    }

    const cardValues =
      data
        ?.filter((cardValue) => {
          return cardValue.summary !== true
        })
        ?.map((cardValueItem, index) => {
          return {
            key: `${testId}-${cardValueItem?.label?.replace(
              /\s/g,
              '-'
            )}-${index}`,
            index,
            value: cardValueItem?.value,
            icon: cardValueItem?.icon,
            color: cardValueItem?.color,
            label: cardValueItem?.label
          }
        })
        ?.map((cardData) => cardContent(cardData)) || []
    return (
      <Grid
        data-testid={`card-grid-content-${testId}`}
        columns="1/2"
        gap="small"
      >
        {cardValues}
      </Grid>
    )
  }

  const barBody = () => {
    return (
      <Box
        key={`bar-container-${testId}`}
        data-testid={`bar-container-${testId}`}
      >
        <Box
          key={`bar-header-${testId}`}
          data-testid={`bar-header-${testId}`}
          gap="xsmall"
          direction="row"
          align="baseline"
        >
          <Typography
            data-testid={`bar-meter-summary-value-${testId}`}
            type="text"
            size="3xl"
            emphasis
          >
            {meterSummary?.value}
          </Typography>
          <Typography
            data-testid={`bar-meter-summary-label-${testId}`}
            type="text"
            size="xsmall"
            emphasis
          >
            {meterSummary?.label}
          </Typography>
        </Box>
        <Box
          key={`bar-chart-details-${testId}`}
          data-testid={`bar-chart-details-${testId}`}
          pad={{ vertical: 'medium' }}
          align="center"
        >
          <Meter
            type={chartType}
            values={meterChartData}
            thickness="small"
            direction="horizontal"
            alignSelf="start"
            size="xlarge"
            round
          />
        </Box>
        <Box
          key={`bar-content-${testId}`}
          data-testid={`bar-content-${testId}`}
          gap="medium"
          align="stretch"
          pad={{ vertical: 'medium' }}
        >
          {meterPairs}
        </Box>
      </Box>
    )
  }

  const header = () => (
    <>
      <Box data-testid={`${chartType}-${testId}-header`}>
        {typeof title === 'string' ? (
          <ThemeContext.Extend value={{ heading: { level: cardHeadingStyle } }}>
            <Heading
              level={level}
              margin={{ top: 'xsmall', bottom: 'none' }}
              data-testid={`${chartType}-title`}
            >
              {title}
            </Heading>
          </ThemeContext.Extend>
        ) : (
          { title }
        )}
        {subTitle && (
          <Text data-testid={`${testId}-${chartType}-sub-title`}>
            {subTitle}
          </Text>
        )}
      </Box>
      {action && (
        <Button
          alignSelf="start"
          icon={<LinkNext a11yTitle={`Go to ${title}`} color="brand" />}
          onClick={callAction}
        />
      )}
    </>
  )

  const bodyContent = () => {
    if (chartType === chartTypeMap.CIRCLE) {
      return circleBody()
    }
    if (chartType === chartTypeMap.PIE) {
      return pieBody()
    }
    if (chartType === chartTypeMap.CARD) {
      return CardWidget()
    }
    if (chartType === chartTypeMap.BAR) {
      return barBody()
    }
    return <Box {...rest}>{children}</Box>
  }
  const selectedText = (
    <Box direction="row" align="center" justify="center" gap="xsmall">
      <Checkmark color="white" size="small" />
      <Text>Selected</Text>
    </Box>
  )
  const footer = () => {
    return (
      <Box
        data-testid={`${chartType}-${testId}-footer`}
        pad="small"
        height={{ max: 'medium' }}
      >
        {footerMessage && (
          <Box pad={{ bottom: 'medium' }} width="large" height="xsmall">
            <Text>{footerMessage}</Text>
          </Box>
        )}
        {footerNotification && (
          <Box
            data-testid="footer-notification-container"
            pad="small"
            width="large"
            height={{ max: 'small' }}
          >
            <Notification
              status={footerNotification?.status}
              message={footerNotification?.message}
              actions={footerNotification?.actions}
              fill
            />
          </Box>
        )}
        {onSelectClick !== null && (
          <Box width="large" pad="small">
            <Button
              label={selected ? selectedText : 'Select widget'}
              data-testid="select-btn"
              onClick={() => {
                setSelected(!selected)
                onSelectClick()
              }}
              primary={selected}
              secondary={!selected}
            />
          </Box>
        )}
      </Box>
    )
  }

  const isFooter = footerMessage || footerNotification || onSelectClick

  return (
    <WidgetLayout
      noElevation={noElevation}
      testId={`${chartType}-${testId}`}
      header={header()}
      body={bodyContent()}
      footer={isFooter && footer()}
      widgetBodyCustomStyle={widgetBodyCustomStyle}
    />
  )
}
Widget.propTypes = {
  /**
   * This prop will be used to determine the data for the widget.
   * This is optional.
   * Default value is [].
   * It can be an array of objects.
   * Each object can have label, value, icon, color, summary, hideicon.
   * label, value, summary is mandatory for circle and pie type.
   * label, value, summary, icon and color is mandatory for card type.
   * hideicon is optional.
   * summary can be either true or false.
   * hideicon can be either true or false.
   * It can be used to display the data in the widget.
   * // Example:
   * data: [
   *  { label: 'Total subscriptions', value: 143, summary: false },
   * ]
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * This prop will be used to determine the label for the widget.
       */
      label: PropTypes.string.isRequired,
      /**
       * This prop will be used to determine the value for the widget.
       */
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      icon: PropTypes.string,
      color: PropTypes.string,
      summary: PropTypes.bool,
      hideicon: PropTypes.bool
    })
  ),
  /**
   * This prop will be used to determine the action for the widget.
   * for example: () => navigate(url) or () => window.location.replace(url)
   * This is the function for action icon clicks
   * This is optional.
   * It can be a function.
   * Default value is undefined. if undefined, the action icon will not show
   */
  action: PropTypes.func,
  /**
   * This prop will be used to determine the level of the heading.
   * This is optional.
   * Default value is 3.
   */
  level: PropTypes.number,
  /**
   * title prop is used to display the widget title
   * This is mandatory.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /**
   * subTitle prop is used to display the widget sub title
   */
  subTitle: PropTypes.string,
  /**
   * data-testid, will be used for testing purposes
   * This will be used to identify the widget component
   * in the DOM.
   * This is mandatory.
   */
  testId: PropTypes.string.isRequired,
  /**
   * chartType prop will be used for the type of chart to be displayed
   * This is optional.
   * Default value is ''.
   * It can be either circle/ pie/ card/ bar
   */
  chartType: PropTypes.oneOf([
    chartTypeMap.CIRCLE,
    chartTypeMap.PIE,
    chartTypeMap.CARD,
    chartTypeMap.BAR,
    ''
  ]),
  /**
   * footerNotification prop is used to display the notification in the footer
   * This is optional.
   * Default value is undefined.
   * It can have status and message.
   * status can be either 'info' or 'warning' or 'error' or 'success'
   * message can be string.
   *
   */
  footerNotification: PropTypes.object,
  /**
   * footerMessage prop is used to display the message in the footer
   * This is optional.
   * Default value is undefined.
   * It can be either string or element or node.
   * It can be used to give a high level overview of the footer messages
   * and its functionality.
   */
  footerMessage: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]),
  /**
   * noElevation prop is used to remove the box shadow for the widget
   * This is optional.
   * Default value is false
   * It can be either true or false
   */
  noElevation: PropTypes.bool,
  /**
   * onSelectClick prop is used to handle the select button click event
   * This is optional.
   * Default value is () => {}
   * It can be a function.
   */
  onSelectClick: PropTypes.func,
  /**
   * isWidgetSelected prop is used to determine the selected state of the widget
   * This is optional.
   * Default value is false
   * It can be either true or false
   */
  isWidgetSelected: PropTypes.bool,
  /**
   * This prop will be used to determine the custom styles for the widget body.
   * This is optional. Default value is {}. It can be used when the widget body needs to be customized.
   */
  widgetBodyCustomStyle: PropTypes.object
}
