// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import omit from 'lodash/omit'

import { Button } from '../../../../components'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getCustomerAccount } from '../../../../utils/feature-flag-utils'
import { AUDIT_LOG_CATEGORY } from '../../../../utils/common-utils'
import { AddProductsTemplateHeader } from '../../components/AddProductsTemplateHeader'

const AddProductsSuccessTemplate = () => {
  const { t } = useTranslation(['licensing'])
  const navigate = useNavigate()
  const { company_name: workspaceName } = getCustomerAccount()

  const buttonList = [
    {
      id: 1,
      label: t('view_audit_log'),
      secondary: true,
      testId: 'view-audit-log-btn',
      onClick: () => {
        navigate('/manage-account/auditlogs', {
          state: {
            name: AUDIT_LOG_CATEGORY.SUBSCRIPTION_MANAGEMENT
          }
        })
      },
      rbac: {
        resource: '/ccs/audit-trail',
        permission: 'ccs.audit-trail.view'
      }
    },
    {
      id: 2,
      label: t('go_to_dashboard'),
      primary: true,
      testId: 'go-to-dashboard-btn',
      onClick: () => {
        navigate('/home')
      }
    }
  ]

  return (
    <Box align="center" gap="medium" pad={{ bottom: 'medium' }}>
      <AddProductsTemplateHeader
        header={t('add_products.add_products')}
        title={t('products_successfully_added')}
        description={t('products_successfully_added_desc', { workspaceName })}
      />

      <Box justify="end" direction="row" gap="medium">
        {buttonList.map((button) => (
          <VisibilityWrapper key={button.id} rbac={button.rbac || undefined}>
            <Button {...omit(button, ['rbac'])} />
          </VisibilityWrapper>
        ))}
      </Box>
    </Box>
  )
}

export { AddProductsSuccessTemplate }
