// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import {
  ModalDialog,
  ModalFooter,
  Typography,
  ButtonGroup
} from '../../../components'
import { isGLOP } from '../../../utils/feature-flag-utils'
import { AUDIT_LOG_CATEGORY } from '../../../utils/common-utils'

const SecondStepProgressModal = ({
  onSetOpen,
  title,
  subtitle = undefined,
  secondaryButton = undefined,
  secondaryButtonOnClick = () => {},
  onCloseModal,
  width = 'large'
}) => {
  const { t } = useTranslation(['device'])
  const navigate = useNavigate()
  const buttonList = [
    ...(!isGLOP()
      ? [
          {
            label: t('view_audit_log'),
            secondary: true,
            testId: 'view-audit-log-btn',
            onClick: () => {
              navigate('/manage-account/auditlogs', {
                state: {
                  name: AUDIT_LOG_CATEGORY.DEVICE_MANAGEMENT
                }
              })
            }
          }
        ]
      : []),
    {
      label: t('close'),
      primary: true,
      testId: 'close-btn',
      onClick: onCloseModal
    }
  ]

  if (secondaryButton) {
    buttonList.unshift({
      label: secondaryButton.label,
      secondary: true,
      testId: secondaryButton.testId,
      onClick: secondaryButtonOnClick
    })
  }

  return (
    <ModalDialog
      width={width}
      testId="progress-modal-dialog"
      content={
        <Box>
          <Typography type="heading" testId="progress-modal-title">
            {title}
          </Typography>
          <Typography
            type="text"
            size="medium"
            testId="progress-modal-description"
          >
            {subtitle || t('progress_update_desc_cop')}
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={<ButtonGroup buttonList={buttonList} testId="single-button" />}
        />
      }
      onClose={() => onSetOpen(false)}
    />
  )
}

SecondStepProgressModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  secondaryButton: PropTypes.object,
  secondaryButtonOnClick: PropTypes.func,
  onCloseModal: PropTypes.func.isRequired,
  width: PropTypes.string
}

export { SecondStepProgressModal }
