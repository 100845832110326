// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

/* eslint-disable no-underscore-dangle */
import { Controls, Map, Marker, MarkerCluster, useMap } from 'grommet-leaflet'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { Alert, CircleInformation, StatusWarning } from 'grommet-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'

import { hpeMap } from '../../../device-management/device-map/map-theming'
import { get } from '../../../../utils/api-utils'
import { Loader, NoDataInfo } from '../../../../components'
import { displayIcon } from '../utils'

import { AggregateClusterData } from './AggregateClusterData'
import { SingleClusterData } from './SingleClusterData'
import { SingleAssetData } from './SingleAssetData'

const hpeMapTheme = {
  ...hpeMap,
  pin: {
    ...hpeMap.pin,
    default: {
      container: {
        border: {
          color: 'border',
          size: 'small'
        }
      }
    }
  },
  popup: { border: undefined, pad: undefined }
}

const ClusterValue = ({ cluster }) => {
  const childMarkers = cluster?.getAllChildMarkers()
  const clusterCount = React.useMemo(
    () =>
      childMarkers.reduce(
        (count, marker) => count + marker?.options?.data?.assetStats?.total,
        0
      ),
    [childMarkers]
  )

  const maxRiskLevel = React.useMemo(
    () =>
      childMarkers.reduce(
        (riskLevel, marker) =>
          Math.max(riskLevel, marker?.options?.data?.assetStats?.riskLevel),
        0
      ),
    [childMarkers]
  )
  return displayIcon(clusterCount, maxRiskLevel)
}

const ZoomToBounds = ({ zoom, bounds }) => {
  const map = useMap()
  if (zoom) {
    map.flyToBounds(bounds, { duration: 1.5 })
  }
  return null
}

ZoomToBounds.propTypes = {
  zoom: PropTypes.bool.isRequired,
  bounds: PropTypes.array.isRequired
}

const AssetsMap = () => {
  const { containerRef, mapContainerRef } = React.useRef()
  const [mapData, setMapData] = useState(null)
  const [locationsData, setLocationsData] = useState(null)
  const { oidcUser } = useReactOidc()
  const [isLoading, setIsLoading] = useState(false)
  const [renderMapState, setRenderMapState] = useState('')
  const [mapMounted, setMapMounted] = useState(false)
  const [zoom, setZoom] = useState(false)
  const { t } = useTranslation(['common', 'manage'])
  const FORMAT = process.env.NODE_ENV === 'development' ? 'default' : 'pmtiles'
  const TILE_URL =
    process.env.NODE_ENV === 'development'
      ? 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      : `${window.location.origin}/api/device-map/grommet-leaflet/data/planet/{z}/{x}/{y}.mvt`

  const loadMapData = useCallback(() => {
    if (!mapMounted) {
      setIsLoading(true)
    }
    get('/internal-assets/v1beta1/locations', {}, oidcUser.access_token)
      .then(
        (response) => {
          setMapData(response?.data)
          if (response?.data?.total === 0) {
            setRenderMapState('NO_ASSETS')
          } else if (response?.data?.items?.length === 0) {
            setRenderMapState('NO_LOCATIONS')
          } else {
            setRenderMapState('MAP_DATA')
          }

          const locations = response?.data?.items?.map((asset) => [
            asset?.location?.lat,
            asset?.location?.long
          ])
          setLocationsData(locations)
          setZoom(true)
        },
        (error) => {
          setRenderMapState('API_ERROR')
          console.log(error)
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [oidcUser.access_token, mapMounted])

  useEffect(() => {
    loadMapData()
    setMapMounted(true)
  }, [oidcUser.access_token, loadMapData])

  const renderMap = () => {
    switch (renderMapState) {
      case 'MAP_DATA':
        return (
          <>
            {!isLoading ? (
              <>
                <Box
                  background="background-front"
                  margin={{ top: 'small' }}
                  round="medium"
                  flex={false}
                >
                  <Box gap="small">
                    <Box
                      ref={containerRef}
                      background="background-contrast"
                      height={{ min: 'medium' }}
                      round="small"
                      overflow="hidden"
                    >
                      <Map
                        closePopupOnClick={false}
                        style={{ height: '384px', width: '100%' }}
                        id="map"
                        ref={mapContainerRef}
                        theme={hpeMapTheme}
                        tileLayer={{
                          format: FORMAT,
                          url: TILE_URL,
                          attribution: `&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors`
                        }}
                      >
                        {mapData?.items?.length > 0 ? (
                          <>
                            {locationsData?.length > 0 && (
                              <ZoomToBounds
                                zoom={zoom}
                                bounds={locationsData}
                              />
                            )}
                            {locationsData?.length > 0 && (
                              <Controls locations={locationsData} />
                            )}

                            <MarkerCluster
                              icon={({ cluster }) => (
                                <ClusterValue cluster={cluster} />
                              )}
                              popup={({ cluster }) => {
                                return (
                                  <AggregateClusterData
                                    boundingBoxTopLeftLat={
                                      cluster?._bounds?._northEast?.lat
                                    }
                                    boundingBoxTopLeftLong={
                                      cluster?._bounds?._southWest?.lng
                                    }
                                    boundingBoxBottomRightLat={
                                      cluster?._bounds?._southWest?.lat
                                    }
                                    boundingBoxBottomRightLong={
                                      cluster?._bounds?._northEast?.lng
                                    }
                                    childMarkers={cluster?.getAllChildMarkers()}
                                    token={oidcUser?.access_token}
                                  />
                                )
                              }}
                            >
                              {mapData?.items?.map((asset) => {
                                return (
                                  <Marker
                                    key={`location-${asset?.assetStatsTotal}-${asset?.location?.lat}-${asset?.location?.long}`}
                                    data={asset}
                                    position={[
                                      asset?.location?.lat,
                                      asset?.location?.long
                                    ]}
                                    icon={displayIcon(
                                      asset?.assetStats?.total,
                                      asset?.assetStats?.riskLevel,
                                      `asset-marker-${asset?.location?.lat}-${asset?.location?.long}`
                                    )}
                                    popup={
                                      asset?.assetStats?.total === 1 ? (
                                        <SingleAssetData asset={asset} />
                                      ) : (
                                        <SingleClusterData asset={asset} />
                                      )
                                    }
                                  />
                                )
                              })}
                            </MarkerCluster>
                          </>
                        ) : null}
                      </Map>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Loader testId="assets-map-loader" orientation="horizontal" />
            )}
          </>
        )
      case 'NO_ASSETS':
        return (
          <Box pad={{ vertical: 'xlarge' }} height="384px">
            <NoDataInfo
              icon={<StatusWarning size="xlarge" color="status-warning" />}
              title={t('manage:asset_management.assets_map.no_assets_title')}
              subtitle={t(
                'manage:asset_management.assets_map.no_assets_subtitle'
              )}
              testId="map-no-assets-info"
            />
          </Box>
        )
      case 'NO_LOCATIONS':
        return (
          <Box pad={{ vertical: 'xlarge' }} height="384px">
            <NoDataInfo
              icon={<CircleInformation size="xlarge" color="status-warning" />}
              subtitle={t(
                'manage:asset_management.assets_map.no_location_data_subtitle'
              )}
              testId="map-no-locations-info"
              title={t(
                'manage:asset_management.assets_map.no_location_data_title'
              )}
            />
          </Box>
        )
      case 'API_ERROR':
        return (
          <Box pad={{ vertical: 'xlarge' }} height="384px">
            <NoDataInfo
              icon={<Alert size="large" color="status-warning" />}
              subtitle={t(
                'manage:asset_management.assets_map.no_data_subtitle'
              )}
              testId="map-data-error-info"
              title={t('manage:asset_management.assets_map.no_data_title')}
            />
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <>
      {mapData
        ? renderMap()
        : !isLoading && (
            <NoDataInfo
              icon={<Alert size="large" color="status-warning" />}
              subtitle={t(
                'manage:asset_management.assets_map.no_data_subtitle'
              )}
              testId="map-data-error-info"
              title={t('manage:asset_management.assets_map.no_data_title')}
            />
          )}
    </>
  )
}

export const AssetsMapView = () => {
  return (
    <Box gap="medium" width="xxlarge">
      <Box direction="column" flex="grow">
        <AssetsMap />
      </Box>
    </Box>
  )
}

ClusterValue.propTypes = {
  cluster: PropTypes.object.isRequired
}
