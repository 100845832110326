// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Anchor, Box, Page, PageContent, PageHeader } from 'grommet'
import {
  Previous,
  ShieldSecurity,
  StatusDisabledSmall,
  StatusGoodSmall
} from 'grommet-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ActionButton,
  Button,
  DataTable,
  NoDataInfo,
  Typography,
  Loader
} from '../../../components'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { get, getErrorMessage } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  getPaginationShowIdx,
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED,
  WKSPC_PLURAL
} from '../../../utils/common-utils'

import {
  DeleteIPAccessModal,
  AccessRuleModal,
  AccessRuleModalIPv6,
  EnableIPAccessRuleModal,
  DisableIPAccessRuleModal
} from './components'

const IPAccessRulesContent = ({
  isFromCCSManger = false,
  customerId = '',
  setIPAccessRulesEnabledInCCSManager = false,
  setIsAccessRulesDisabledInCCSManager = false
}) => {
  const navigate = useNavigate()
  const [accessRuleModal, setAccessRuleModal] = useState(null)
  const [data, setData] = useState([])
  const { t } = useTranslation(['authn', 'common'])
  const { oidcUser } = useReactOidc()
  const [selectedRule, setSelectedRule] = useState(null)
  const [notification, setNotification] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [ipAccessRulesEnabled, setIPAccessRulesEnabled] = useState(false)
  const [showDeleteIPAccessModal, setDeleteIPAccessModal] = useState(false)
  const [enableIPAccessRuleModal, setEnableIPAccessRuleModal] = useState(false)
  const [disableIPAccessRuleModal, setDisableIPAccessRuleModal] =
    useState(false)
  const [isIPAccessRulesEnabled, setIsIPAccessRulesEnabled] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const [showInitCount, setInitCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const showIPv6String = LDFlags['glcp-ipv6']
  const rulesPerPage = 10
  const [page, setPage] = useState(1)
  const [totalNoOfRules, setTotalNoOfRules] = useState(rulesPerPage)
  const pageIdxInfo = getPaginationShowIdx(
    page,
    totalNoOfRules,
    rulesPerPage,
    t
  )

  const columns = [
    {
      property: 'access_rule',
      render: (record) => (
        <Typography type="text" testId="access-rule">
          {record.access_rule}
        </Typography>
      ),
      type: 'string',
      header: t('ip_access_rules.allowed_ip_addresses')
    },
    {
      property: 'description',
      type: 'string',
      size: 'large',
      header: t('ip_access_rules.description')
    }
  ]
  if (!isFromCCSManger) {
    columns.push({
      property: 'actions',
      header: '',
      render: (rule) => {
        return (
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/accounts/platform/customer',
              permission: 'ccs.accounts.platform.customer.edit'
            }}
          >
            <ActionButton
              testId="ip-access-rules-action-button"
              actions={[
                {
                  label: t('common:edit'),
                  onClick: () => {
                    setSelectedRule(rule)
                    setAccessRuleModal('edit')
                  }
                },
                {
                  label: t('common:delete'),
                  onClick: () => {
                    setSelectedRows([rule])
                    setDeleteIPAccessModal(true)
                  }
                }
              ]}
            />
          </VisibilityWrapper>
        )
      }
    })
  }

  const fetchIPAccessRules = useCallback(() => {
    const url = isFromCCSManger
      ? '/support-assistant/v1alpha1/ip-access-rule'
      : '/accounts/ui/v1/ip-access-rule'

    get(
      url,
      {
        ...(isFromCCSManger && { platform_customer_id: customerId }),
        limit: rulesPerPage,
        offset: !page ? 0 : (page - 1) * rulesPerPage,
        ...(searchVal.trim().length && { search_string: searchVal.trim() })
      },
      oidcUser.access_token
    ).then(
      (response) => {
        const ipRules = response?.data?.ip_access_rules
        const tableData = ipRules.map((rule) => {
          const returnObj = rule
          if (rule.ip_rule_format === 'SINGLE_IP')
            returnObj.access_rule = rule.single_ip
          else if (rule.ip_rule_format === 'IP_SUBNET_MASK')
            returnObj.access_rule = rule.ip_subnet_mask
          else returnObj.access_rule = `${rule.starting_ip}-${rule.ending_ip}`
          return returnObj
        })
        if (isFromCCSManger) {
          if (!ipRules.length) {
            setIsAccessRulesDisabledInCCSManager(true)
          } else {
            setInitCount(showInitCount + 1)
            setIsAccessRulesDisabledInCCSManager(false)
          }
          setIPAccessRulesEnabledInCCSManager(
            response?.data?.ip_access_rules_enabled
          )
        } else {
          if (ipRules.length) {
            setInitCount(showInitCount + 1)
          }
          setIPAccessRulesEnabled(response?.data?.ip_access_rules_enabled)
        }
        setData(tableData)
        setIsIPAccessRulesEnabled(response?.data?.ip_access_rules_enabled)
        setTotalNoOfRules(response?.data?.pagination?.total_count)
        setIsLoading(false)
      },
      (error) => {
        setNotification(
          displayNotification(
            getErrorMessage(error, t),
            'error',
            setNotification
          )
        )
        setIsLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFromCCSManger,
    customerId,
    page,
    searchVal,
    oidcUser.access_token,
    setIPAccessRulesEnabledInCCSManager,
    setIsAccessRulesDisabledInCCSManager,
    t
  ])

  useEffect(() => {
    fetchIPAccessRules()
  }, [fetchIPAccessRules])

  const handleBackBtnClick = () => {
    navigate('/manage-account')
  }
  const getDeleteActionModal = () => {
    let modal
    if (showDeleteIPAccessModal) {
      const deletingAllRules = selectedRows.length === totalNoOfRules
      modal = (
        <DeleteIPAccessModal
          setDeleteIPAccessModal={setDeleteIPAccessModal}
          selectedRows={selectedRows}
          onSuccess={(msg) => {
            setNotification(displayNotification(msg, 'info', setNotification))
            if (deletingAllRules) {
              setIPAccessRulesEnabled(false)
            }
            fetchIPAccessRules()
            setSelectedRows([])
          }}
          deletingAllRules={deletingAllRules}
          setInitCount={setInitCount}
          ipAccessRulesEnabled={ipAccessRulesEnabled}
          data={data}
        />
      )
    } else {
      modal = <></>
    }
    return modal
  }
  const getNoDataInfo = () => {
    let noDataInfo
    if (!isFromCCSManger && !showInitCount && !isLoading) {
      noDataInfo = (
        <NoDataInfo
          icon={<ShieldSecurity color="black" size="large" />}
          title={t('ip_access_rules.set_up_rules')}
          subtitle={t('ip_access_rules.set_up_rules_subtitle', {
            account: getWorkspaceString(showWorkspaceString, t, WKSPC)
          })} // need to remove the space in langugae translation files in futures and change the cypress accordingly
          action={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/accounts/platform/customer',
                permission: 'ccs.accounts.platform.customer.edit'
              }}
            >
              <Button
                primary
                label={
                  showIPv6String
                    ? t('ip_access_rules.add_access_rule_lowercase')
                    : t('ip_access_rules.add_access_rule')
                }
                testId="add-access-rule-btn"
                onClick={() => setAccessRuleModal('add')}
              />
            </VisibilityWrapper>
          }
          testId="no-data-info"
        />
      )
    } else if (isFromCCSManger && !showInitCount && !isLoading) {
      noDataInfo = (
        <NoDataInfo
          icon={<ShieldSecurity color="black" size="large" />}
          subtitle={t('ip_access_rules.no_rules_info')}
          testId="no-data-info"
        />
      )
    } else {
      noDataInfo = null
    }
    return noDataInfo
  }
  return (
    <Page data-testid="ip-access-rule-page">
      <PageContent>
        {!isFromCCSManger && (
          <PageHeader
            responsive
            parent={
              <Anchor
                label={t('common:manage_account_label', {
                  account: getWorkspaceString(
                    showWorkspaceString,
                    t,
                    WKSPC_CAPITALIZED
                  )
                })}
                icon={<Previous />}
                onClick={handleBackBtnClick}
                data-testid="manage-account-btn"
              />
            }
            title={t('ip_access_rules.title')}
            subtitle={
              showIPv6String
                ? t('ip_access_rules.sub_title_new', {
                    accounts: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_PLURAL
                    )
                  })
                : t('ip_access_rules.sub_title', {
                    accounts: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_PLURAL
                    )
                  })
            }
            actions={
              <VisibilityWrapper
                rbac={{
                  resource: '/ccs/accounts/platform/customer',
                  permission: 'ccs.accounts.platform.customer.edit'
                }}
              >
                {showInitCount || isIPAccessRulesEnabled ? (
                  <Button
                    secondary
                    label={
                      // eslint-disable-next-line no-nested-ternary
                      showIPv6String
                        ? isIPAccessRulesEnabled
                          ? t('ip_access_rules.disabled_access_rules_title')
                          : t('ip_access_rules.enable_ip_access_rules')
                        : t('ip_access_rules.title')
                    }
                    onClick={() =>
                      isIPAccessRulesEnabled
                        ? setDisableIPAccessRuleModal(true)
                        : setEnableIPAccessRuleModal(true)
                    }
                    disabled={!data?.length}
                    testId="enable-ip-access-rules-btn"
                  />
                ) : (
                  ''
                )}
              </VisibilityWrapper>
            }
          />
        )}
        {isLoading && (
          <Box direction="row" align="center" justify="center">
            <Loader size="xxsmall" testId="ip-access-datatable-loader" />
          </Box>
        )}
        {showInitCount && !isLoading ? (
          <Box {...isFromCCSManger}>
            <Box basis="100%" direction="row" pad={{ bottom: 'medium' }}>
              <Box basis="40%">
                <Typography
                  type="text"
                  weight="700"
                  testId="ip-access-rules-text"
                >
                  {t('ip_access_rules.title')}
                </Typography>
              </Box>
              <Box direction="row" gap="xsmall">
                {isIPAccessRulesEnabled ? (
                  <StatusGoodSmall
                    size="medium"
                    color="status-ok"
                    style={{ alignSelf: 'center' }}
                  />
                ) : (
                  <StatusDisabledSmall
                    size="medium"
                    color="status-disabled"
                    style={{ alignSelf: 'center' }}
                  />
                )}
                <Typography type="text" testId="status-text">
                  {isIPAccessRulesEnabled
                    ? t('ip_access_rules.button_lbl_enabled')
                    : t('ip_access_rules.button_lbl_disabled')}
                </Typography>
              </Box>
            </Box>
            <DataTable
              grid={{
                columns,
                data
              }}
              pagination={{
                totalItems: totalNoOfRules,
                itemsPerPage: rulesPerPage,
                page,
                setPage,
                pageIdxInfo
              }}
              summary={{
                entityName: t('ip_access_rules.summary')
              }}
              button={
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/accounts/platform/customer',
                    permission: 'ccs.accounts.platform.customer.edit'
                  }}
                >
                  <Button
                    size="medium"
                    secondary
                    label={
                      <Typography
                        truncate
                        type="text"
                        testId="create-access-rule-lbl"
                        emphasis
                      >
                        {t('ip_access_rules.add_access_rule')}
                      </Typography>
                    }
                    onClick={() => setAccessRuleModal('add')}
                    disabled={!data?.length}
                    reverse
                    testId="add-access-rule-btn"
                  />
                </VisibilityWrapper>
              }
              search={{
                onSearchValueChange: (value) => {
                  setSearchVal(value)
                  setPage(1)
                },
                placeholder: t('ip_access_rules.search_placeholder'),
                size: 'xxlarge',
                width: 'xxlarge'
              }}
              testId="ip-access-rules-datatable"
            />
          </Box>
        ) : (
          getNoDataInfo()
        )}
        {accessRuleModal &&
          (showIPv6String ? (
            <AccessRuleModalIPv6
              setOpen={setAccessRuleModal}
              mode={accessRuleModal}
              ruleData={selectedRule}
              ipAccessRulesEnabled={ipAccessRulesEnabled}
              onSuccess={(msg) => {
                fetchIPAccessRules()
                setNotification(
                  displayNotification(msg, 'info', setNotification)
                )
              }}
            />
          ) : (
            <AccessRuleModal
              setOpen={setAccessRuleModal}
              mode={accessRuleModal}
              ruleData={selectedRule}
              onSuccess={(msg) => {
                fetchIPAccessRules()
                setNotification(
                  displayNotification(msg, 'info', setNotification)
                )
              }}
            />
          ))}

        {getDeleteActionModal()}
        {enableIPAccessRuleModal && (
          <EnableIPAccessRuleModal
            setEnableIPAccessRuleModal={setEnableIPAccessRuleModal}
            onSuccess={(msg) => {
              setNotification(displayNotification(msg, 'info', setNotification))
              setIPAccessRulesEnabled(true)
              setIsIPAccessRulesEnabled(true)
            }}
            setIPAccessRulesEnabled={setIPAccessRulesEnabled}
          />
        )}
        {disableIPAccessRuleModal && (
          <DisableIPAccessRuleModal
            setDisableIPAccessRuleModal={setDisableIPAccessRuleModal}
            onSuccess={(msg) => {
              setNotification(displayNotification(msg, 'info', setNotification))
              setIPAccessRulesEnabled(false)
              setIsIPAccessRulesEnabled(false)
            }}
            setIPAccessRulesEnabled={setIPAccessRulesEnabled}
          />
        )}
        {notification !== null && notification}
      </PageContent>
    </Page>
  )
}

IPAccessRulesContent.propTypes = {
  isFromCCSManger: PropTypes.bool,
  customerId: PropTypes.string,
  setIPAccessRulesEnabledInCCSManager: PropTypes.bool,
  setIsAccessRulesDisabledInCCSManager: PropTypes.bool
}

const IPAccessRules = (props) => {
  const { isFromCCSManger = false } = props
  return isFromCCSManger ? (
    <IPAccessRulesContent {...props} />
  ) : (
    <Layout>
      <IPAccessRulesContent />
    </Layout>
  )
}

IPAccessRules.propTypes = {
  isFromCCSManger: PropTypes.bool
}

export default IPAccessRules
