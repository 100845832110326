// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  Typography
} from '../../components'

const ReuseableDiscardChangesModal = ({
  footerButtonslist,
  closeModal,
  testId,
  footerTestId
}) => {
  const { t } = useTranslation(['device'])
  return (
    <ModalDialog
      width="medium"
      testId={testId}
      content={
        <Box>
          <Typography type="heading" testId="discard-changes-title">
            {t('changes_unsaved')}
          </Typography>
          <Typography
            type="text"
            size="medium"
            testId="discard-changes-description"
            margin={{ top: 'xsmall' }}
          >
            {t('changes_unsaved_description')}
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup buttonList={footerButtonslist} testId={footerTestId} />
          }
        />
      }
      onClose={closeModal}
    />
  )
}
ReuseableDiscardChangesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  footerButtonslist: PropTypes.func.isRequired,
  testId: PropTypes.func.isRequired,
  footerTestId: PropTypes.func.isRequired
}

export default ReuseableDiscardChangesModal
