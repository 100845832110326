// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grommet,
  Pagination as GrommetPagination,
  ResponsiveContext
} from 'grommet'
import { isNumber } from 'lodash'
import { deepMerge } from 'grommet/utils'
import { hpe } from 'grommet-theme-hpe'
import styled from 'styled-components'

import { Typography } from '../typography/Typography'
import { Dropdown } from '../dropdown/Dropdown'

const DisabledGrommetPagiantion = styled(GrommetPagination)`
  button {
    cursor: default;
  }
`

export const Pagination = ({
  totalItems,
  itemsPerPage = 5,
  page = 1,
  setPage = () => {},
  testId,
  pageIdxInfo = '',
  customRender = null,
  setItemsPerPage = undefined,
  rowDropDownLabel = 'Rows per page',
  defaultRowsValue = 20,
  disabled = false,
  ...rest
}) => {
  const rowCountOptions = rest?.rowCountOptions || [10, 20, 50, 100, 200]
  const size = React.useContext(ResponsiveContext)
  const numberMiddlePages = size === 'small' || size === 'xsmall' ? 2 : 3

  // UX wanted minimum number of rows per page as 10
  const minNumberRowsPerPage = 10

  useEffect(() => {
    if (setItemsPerPage && !rowCountOptions?.includes(itemsPerPage)) {
      setItemsPerPage(defaultRowsValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      isNumber(totalItems) &&
      totalItems > 0 &&
      isNumber(itemsPerPage) &&
      itemsPerPage > 0
    ) {
      const maxPage = Math.floor((totalItems - 1) / itemsPerPage) + 1
      if (page > maxPage) {
        setPage(maxPage)
      }
    }
  }, [totalItems, itemsPerPage, setPage, page])

  let paginationDirection = 'row'
  let paginationAlign = 'end'
  if (
    setItemsPerPage &&
    totalItems > minNumberRowsPerPage &&
    pageIdxInfo.length > 0 &&
    totalItems > itemsPerPage
  ) {
    paginationDirection = ['xsmall', 'small', 'medium'].includes(size)
      ? 'column'
      : 'row'

    paginationAlign = paginationDirection === 'column' ? 'start' : 'end'
  }

  const numberItemsDirection = ['xsmall', 'small'].includes(size)
    ? 'column'
    : 'row'

  return (
    <Box direction="column" data-testid={testId}>
      <>
        <Box
          direction={paginationDirection}
          gap={paginationDirection === 'column' ? 'small' : null}
        >
          <Box
            fill
            direction={numberItemsDirection}
            pad={{ horizontal: 'small' }}
            gap="small"
            justify="between"
            align={numberItemsDirection === 'row' ? 'center' : null}
          >
            {pageIdxInfo.length > 0 && totalItems > itemsPerPage && (
              <Typography
                type="text"
                size="small"
                testId="pagination-summary-info"
              >
                {pageIdxInfo}
              </Typography>
            )}
            {setItemsPerPage && totalItems > minNumberRowsPerPage && (
              <Box
                width={{ min: 'fit-content' }}
                align="center"
                direction="row"
                gap="xsmall"
              >
                <Typography
                  size="small"
                  type="text"
                  testId="rows-per-page-text"
                >
                  {rowDropDownLabel}
                </Typography>

                <Box data-testid="rows-per-page-dropdown-box">
                  <Dropdown
                    testId="rows-per-page-dropdown"
                    defaultVal={defaultRowsValue}
                    value={itemsPerPage}
                    options={rowCountOptions}
                    onChangeDropdown={(option) => {
                      setItemsPerPage(option)
                    }}
                    valueLabel={
                      <Box pad={{ horizontal: 'small', vertical: 'xsmall' }}>
                        <Typography type="text">{itemsPerPage}</Typography>
                      </Box>
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
          {totalItems > itemsPerPage && (
            <Box width={{ min: 'auto' }}>
              {disabled ? (
                <Grommet
                  theme={deepMerge(hpe, {
                    pagination: {
                      button: {
                        color: 'text-xweak',
                        hover: {
                          background: {
                            color: 'background'
                          },
                          color: 'text-xweak'
                        },
                        active: {
                          background: {
                            color: 'background'
                          },
                          color: 'text-xweak'
                        }
                      },
                      container: {
                        align: paginationAlign,
                        justify: paginationAlign
                      }
                    }
                  })}
                >
                  <DisabledGrommetPagiantion
                    alignSelf={paginationAlign}
                    step={itemsPerPage}
                    numberItems={totalItems}
                    page={page}
                    numberMiddlePages={numberMiddlePages}
                    onChange={() => {}}
                    {...rest}
                  />
                </Grommet>
              ) : (
                <GrommetPagination
                  alignSelf={paginationAlign}
                  step={itemsPerPage}
                  numberItems={totalItems}
                  page={page}
                  numberMiddlePages={numberMiddlePages}
                  onChange={setPage}
                  data-testid="pagination-navigation"
                  {...rest}
                />
              )}
            </Box>
          )}
        </Box>
      </>
      {customRender}
    </Box>
  )
}

Pagination.propTypes = {
  /**
   * It will be used for component reference to total items count.
   * This is mandatory.
   */
  totalItems: PropTypes.number.isRequired,
  /**
   * It will be used for component reference to items per page.
   */
  itemsPerPage: PropTypes.number,
  /**
   * It will be used for component reference to current page.
   */
  page: PropTypes.number,
  /**
   * This is the function for change current page
   */
  setPage: PropTypes.func,
  /**
   * It will be used for component reference to test.
   */
  testId: PropTypes.string.isRequired,
  /**
   * It will be used for component to show page index information at the left.
   * For example: Showing 1-5 of 12
   */
  pageIdxInfo: PropTypes.string,
  /**
   * This proper will be used as a callback function to customize the Pagination
   */
  customRender: PropTypes.any,
  /**
   * It will be used for component to show rows per page label at the bottom.
   */
  rowDropDownLabel: PropTypes.string,
  /**
   * It will be used for component to show default row value
   */
  defaultRowsValue: PropTypes.number,
  /**
   * It will be used for component to set the value of rows selected.
   */
  setItemsPerPage: PropTypes.func,
  /**
   * It will be used for component to disable the pagination.
   */
  disabled: PropTypes.bool
}
