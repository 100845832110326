// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import { StatusGoodSmall, StatusUnknownSmall } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  DataTable,
  ActionButton,
  Typography,
  Loader
} from '../../../../components'
import useLD from '../../../../hooks/ld/useLD'
import { loadCustomerAccount } from '../../../../utils/account-utils'
import { useCCSContext } from '../../../../context/ccs-context'
import { useUPSContext } from '../../../../context/ups-context'
import { useVisibilityContext } from '../../../../context/visibility-context'
import { displayApiError } from '../../../../utils/error-handling-utils'
import { post } from '../../../../utils/api-utils'
import useLogger from '../../../../hooks/logs/useLogger'

const SupportAccessDatatable = ({
  customerCase,
  caseInfo,
  setNotifyComponent,
  pagination
}) => {
  const { t } = useTranslation(['common', 'support_access_manager', 'authz'])
  const { oidcUser } = useReactOidc()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const navigate = useNavigate()
  const { csrfToken, dispatchCCSContext } = useCCSContext()
  const { dispatchUPSContext } = useUPSContext()
  const [loadingStates, setLoadingStates] = useState({})
  const ld = useLD()
  const logger = useLogger()
  const LDFlags = useFlags()
  const samLDFlagPhase2 = LDFlags['glcp-support-access-manager-phase2']
  dayjs.extend(utc)

  const uiMsg = {
    MATCH_ACCESS: t('support_access_manager:match_level_access'),
    READ: t('support_access_manager:read_only_access')
  }

  const columns = [
    {
      align: 'start',
      header: t('support_access_manager:datatable.case_id'),
      property: 'case_id',
      render: (datum) => {
        return <Box key={datum?.access_granted}>{datum.case_id || ''}</Box>
      },
      sortable: false
    },
    {
      align: 'start',
      header: t('support_access_manager:datatable.status'),
      property: 'status',
      render: (datum) => {
        return (
          <Box flex direction="row" align="center" key={datum?.access_granted}>
            {datum.status.toLowerCase() === 'active' ? (
              <StatusGoodSmall color="status-ok" data-testid="status-ok-icon" />
            ) : (
              <StatusUnknownSmall
                color="status-unknown"
                data-testid="status-unknown-icon"
              />
            )}
            <Typography type="text" testId="status" margin={{ left: 'small' }}>
              {datum?.status || ''}
            </Typography>
          </Box>
        )
      },

      sortable: false
    },
    {
      align: 'start',
      header: t('support_access_manager:datatable.access_granted'),
      property: 'access_granted',
      primary: true,
      sortable: false,
      render: (datum) => {
        return (
          <Box key={datum?.access_granted}>
            <Typography size="medium" type="text" wordBreak="break-word">
              {dayjs
                .unix(datum?.access_granted)
                .format('MMM DD, YYYY, HH:mm:ss') || ''}
            </Typography>
          </Box>
        )
      }
    },
    {
      align: 'start',
      header: t('support_access_manager:datatable.duration'),
      property: 'duration',
      sortable: false,
      render: (datum) => {
        return (
          <Box key={datum?.access_granted} width={{ max: 'small' }}>
            {datum?.duration && datum?.duration < 1
              ? t('support_access_manager:datatable.duration_less_than_1')
              : t('support_access_manager:datatable.duration_in_units', {
                  hours: datum?.duration
                })}
          </Box>
        )
      }
    },
    {
      align: 'start',
      header: t('support_access_manager:datatable.access_expired'),
      property: 'access_expired',
      sortable: false,
      render: (datum) => {
        return (
          <Box key={datum?.access_granted} width={{ max: 'small' }}>
            <Typography size="medium" type="text" wordBreak="break-word">
              {dayjs
                .unix(datum?.access_expired)
                .format('MMM DD, YYYY, HH:mm:ss') || ''}
            </Typography>
          </Box>
        )
      }
    },
    ...(samLDFlagPhase2
      ? [
          {
            align: 'start',
            header: t('authz:assignments.permission'),
            property: 'access_type',
            render: (datum) => {
              return (
                <Box>
                  {datum?.access_type ? uiMsg[datum?.access_type] : '--'}
                </Box>
              )
            },
            sortable: false
          }
        ]
      : []),
    {
      align: 'start',
      header: t('support_access_manager:datatable.user'),
      property: 'user',
      render: (datum) => {
        return (
          <Box width={{ max: 'small' }} key={datum?.access_granted}>
            {datum?.created_user || datum?.user || ''}
          </Box>
        )
      },
      sortable: false
    },
    {
      align: 'start',
      header: t('common:actions'),
      property: '',
      render: (datum) => {
        if (datum.status.toLowerCase() === 'active') {
          const uniqueKey = `${datum?.case_id}-${datum?.pcid}`
          return (
            <Box key={datum?.access_granted}>
              {loadingStates[uniqueKey] ? (
                <Box align="center">
                  <Loader testId="loader-spinner" />
                </Box>
              ) : (
                <ActionButton
                  actions={[
                    {
                      label: t('support_access_manager:launch'),
                      onClick: () => {
                        setLoadingStates((prevStates) => {
                          return {
                            ...prevStates,
                            [uniqueKey]: true
                          }
                        })
                        const requestBody = {
                          case_id: caseInfo?.case_id || datum?.case_id,
                          platform_customer_id: caseInfo?.pcid || datum?.pcid
                        }
                        post(
                          `/support-assistant/v1alpha1/notify-workspace-access`,
                          requestBody,
                          oidcUser.access_token
                        )
                          .then((response) => {
                            setLoadingStates((prevStates) => {
                              return {
                                ...prevStates,
                                [uniqueKey]: false
                              }
                            })
                            sessionStorage.removeItem('redirectEndPoint')
                            sessionStorage.removeItem('orgLaunchInfo')
                            sessionStorage.removeItem('loadingAccountPage')
                            loadCustomerAccount(
                              response?.data,
                              oidcUser,
                              dispatchVisibilityContext,
                              navigate,
                              csrfToken,
                              dispatchCCSContext,
                              ld,
                              dispatchUPSContext,
                              logger
                            )
                          })
                          .catch((error) => {
                            const errorComp = displayApiError(
                              error,
                              t,
                              setNotifyComponent
                            )
                            setNotifyComponent(errorComp)
                          })
                          .finally(() => {
                            setLoadingStates((prevStates) => {
                              return {
                                ...prevStates,
                                [uniqueKey]: false
                              }
                            })
                            // setShowLoader(false)
                          })
                      }
                    }
                  ]}
                  showOneActionAsDropDown
                  testId="sam-table-action-btn"
                />
              )}
            </Box>
          )
        }
        return null
      },
      sortable: false
    }
  ]
  return (
    <Box>
      <DataTable
        pagination={pagination}
        grid={{
          columns,
          data: customerCase || []
        }}
        testId="simple-data-table"
      />
    </Box>
  )
}

SupportAccessDatatable.propTypes = {
  customerCase: PropTypes.array.isRequired,
  caseInfo: PropTypes.object.isRequired,
  setNotifyComponent: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired
}

export default SupportAccessDatatable
