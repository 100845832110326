// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ModalDialog,
  Typography,
  CCSForm,
  ButtonGroup,
  Loader,
  FormInput
} from '../../../../components'
import { get, getErrorMessage, post } from '../../../../utils/api-utils'
import { displayNotification } from '../../../../utils/notificiation-utils'

const GenerateSubscriptionModal = ({
  customerId: customerIdProp = '',
  setShowGenerateModal,
  onSuccess = () => {},
  setShowNotification
}) => {
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const deviceTypes = ['AP', 'SWITCH', 'GATEWAY'].join(',')

  const [inputData, setInputData] = useState({
    platform_customer_id: customerIdProp,
    subscription_tiers: []
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [subscriptionTiers, setSubscriptionTiers] = useState([])
  const [loading, setLoading] = useState(true)
  const [generateSubscriptionsLoading, setGenerateSubscriptionsLoading] =
    useState(false)

  useEffect(() => {
    setLoading(true)
    const url = '/support-assistant/v1alpha1/license-tiers'
    get(
      url,
      {
        device_group: deviceTypes
      },
      oidcUser.access_token
    ).then(
      (response) => {
        const tiers =
          response?.data?.supported_subscription_tiers_by_device_type?.reduce(
            (prev, curr) => {
              return { ...prev, ...curr.subscription_tiers_description }
            },
            {}
          )
        const tierOptions = Object.entries(tiers).map(([value, label]) => ({
          label,
          value
        }))
        setSubscriptionTiers(tierOptions)
        setLoading(false)
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        setLoading(false)
      }
    )
  }, [oidcUser.access_token, t, deviceTypes])

  const handleGenerateSubscriptions = () => {
    setGenerateSubscriptionsLoading(true)
    const url = `/support-assistant/v1alpha1/subscription-eval`
    post(url, inputData, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setErrorMessage('')
          setGenerateSubscriptionsLoading(false)
          setShowGenerateModal(false)
          setShowNotification(
            displayNotification(
              response?.data?.status?.replaceAll('_', ' '),
              response?.data?.status === 'SUCCESS' ? 'info' : 'warning',
              setShowNotification
            )
          )
          onSuccess()
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        setGenerateSubscriptionsLoading(false)
      }
    )
  }
  return (
    <ModalDialog
      width="medium"
      testId="generate-subscription-modal-dialog"
      content={
        <Box gap="small">
          <Typography
            type="heading"
            level="1"
            testId="generate-subscription-title"
          >
            {t('generate_subscriptions')}
          </Typography>
          <Box>
            {loading ? (
              <Box align="center" justify="center" fill height="large">
                <Loader testId="loader-spinner" />
              </Box>
            ) : (
              <CCSForm
                errorMessage={errorMessage}
                testId="generate-subscription-form"
                validate="submit"
                value={inputData}
                onSubmit={handleGenerateSubscriptions}
                buttons={
                  <Box justify="end" margin={{ top: 'medium' }}>
                    <ButtonGroup
                      buttonList={[
                        {
                          id: 2,
                          label: t('cancel'),
                          secondary: true,
                          testId: 'cancel-btn',
                          onClick: () => setShowGenerateModal(false)
                        },
                        {
                          id: 1,
                          label: t('generate_subscriptions'),
                          type: 'submit',
                          primary: true,
                          testId: 'generate-btn',
                          disabled: generateSubscriptionsLoading
                        }
                      ]}
                      testId="generate-subscription-buttons"
                    />
                  </Box>
                }
              >
                <Box gap="medium">
                  {!customerIdProp && (
                    <FormInput
                      inputType="text"
                      label={t('customer_details.platform_customer_id')}
                      name="platform_customer_id"
                      required
                      testId="customer-id-input"
                      value={inputData.platform_customer_id}
                      onChange={(event) =>
                        setInputData({
                          ...inputData,
                          platform_customer_id: event.target.value
                        })
                      }
                    />
                  )}
                  <Box gap="xsmall">
                    <Typography
                      type="text"
                      size="medium"
                      testId="generate-subscriptions-desc"
                    >
                      {t('generate_subscriptions_desc')}
                    </Typography>
                    <FormInput
                      inputType="checkbox"
                      label={t('customer_details.subscription_tier')}
                      name="subscription_tiers"
                      value={inputData.subscription_tiers}
                      onChange={({ value }) => {
                        setInputData({
                          ...inputData,
                          subscription_tiers: value
                        })
                      }}
                      checkBoxOptions={subscriptionTiers}
                      height={{ max: 'small' }}
                      overflow="auto"
                      testId="subscription-tier-checkbox-field"
                      required
                    />
                  </Box>
                  {generateSubscriptionsLoading && (
                    <Box direction="row" align="center" justify="center">
                      <Loader testId="loader" />
                    </Box>
                  )}
                </Box>
              </CCSForm>
            )}
          </Box>
        </Box>
      }
      onClose={() => setShowGenerateModal(false)}
    />
  )
}

GenerateSubscriptionModal.propTypes = {
  customerId: PropTypes.string,
  setShowGenerateModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  setShowNotification: PropTypes.func.isRequired
}

export { GenerateSubscriptionModal }
