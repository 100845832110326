// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Notification,
  Typography
} from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'

const DeleteTenantModal = ({
  tenantData,
  setShowModal,
  refreshTenantsList = () => {}
}) => {
  const { t } = useTranslation(['manage'])
  const [errorMessage, setErrorMessage] = useState('')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { oidcUser } = useReactOidc()
  const handleDelete = () => {
    setDeleteLoading(true)
    const request = {
      username: oidcUser.profile.email,
      customer_id: tenantData.id
    }

    const url = `/support-assistant/v1alpha1/tenant`
    del(url, request, oidcUser.access_token).then(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          setDeleteLoading(false)
          setErrorMessage('')
          setShowModal(false)
          refreshTenantsList()
        }
      },
      (error) => {
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        setDeleteLoading(false)
      }
    )
  }
  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="delete-user-modal-title"
              type="heading"
            >
              {t('delete_tenant')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography type="text" size="medium" margin={{ bottom: 'small' }}>
            <Trans
              i18nKey="delete_tenant_desc"
              t={t}
              company_name={tenantData?.name}
            >
              <strong>{{ company_name: tenantData.name }}</strong>
            </Trans>
          </Typography>
          {errorMessage.length ? (
            <Notification
              backgroundColor="status-critical"
              onClose={() => {}}
              testId="delete-tenant-error-message"
              text={errorMessage}
              type="inline"
            />
          ) : null}
          {deleteLoading && (
            <Box direction="row" align="center" justify="center">
              <Loader testId="delete-tenant-loader" />
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('cancel'),
                  secondary: true,
                  testId: 'cancel-btn',
                  onClick: () => {
                    setShowModal(false)
                  }
                },
                {
                  id: 1,
                  label: t('delete_label'),
                  primary: true,
                  testId: 'delete-btn',
                  onClick: handleDelete,
                  disabled: deleteLoading
                }
              ]}
              testId="reinvite-buttons"
            />
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="delete-tenant-modal-dialog"
      width="medium"
    />
  )
}

DeleteTenantModal.propTypes = {
  tenantData: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  refreshTenantsList: PropTypes.func
}

export { DeleteTenantModal }
