// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import {
  Box,
  FormField,
  Text,
  TextInput,
  Notification as GrommetNotification
} from 'grommet'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import lodashGet from 'lodash/get'

import {
  Button,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Notification,
  Typography
} from '../../../../components'
import { del, getErrorMessage } from '../../../../utils/api-utils'

import { pullSSOConfigStatus } from './add-sso-profile/steps/utils/api-utils'

const DeleteSsoProfileModal = ({
  id = undefined,
  ssoRoutingRule = null,
  setShowModal,
  onSuccess = () => {},
  recoveryAccount = null
}) => {
  const { t } = useTranslation(['common', 'iam'])
  const [errorMessage, setErrorMessage] = useState('')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { oidcUser } = useReactOidc()
  const [formValues, setFormValues] = useState({
    delete: {
      confirmation: ''
    }
  })

  const [fieldsError, setFieldsError] = useState({
    deleteConfirmation: ''
  })

  useEffect(() => {
    if (lodashGet(formValues, 'delete.confirmation') === 'DELETE') {
      setFieldsError({
        deleteConfirmation: ''
      })
    }
  }, [formValues])

  const isEmailInDomain = (email, domain) => {
    if (!email || !domain) return false

    const emailDomain = email.split('@')[1]
    return emailDomain === domain
  }

  const handleSuccess = () => {
    setDeleteLoading(false)
    setErrorMessage('')
    setShowModal(false)
    onSuccess()
  }

  const handleError = (error, domainRemoved) => {
    const errorMsg = getErrorMessage(error, t)
    const message = domainRemoved
      ? t('iam:sso_profiles.delete.sso_profile_remove_err', {
          errorMsg
        })
      : t('iam:sso_profiles.delete.sso_profile_and_domain_remove_failed', {
          errorMsg
        })

    setErrorMessage(message)
    setDeleteLoading(false)
  }

  const deleteSsoProfile = async (domainRemoved) => {
    try {
      const url = `/identity/v1alpha1/sso-profiles/${id}`
      const response = await del(url, {})

      if (response.status === 204) {
        handleSuccess()
      }
    } catch (error) {
      handleError(error, domainRemoved)
    }
  }

  const deleteRoutingRule = async () => {
    try {
      const url = `/internal-identity/v1alpha1/sso-routing-rules/${ssoRoutingRule.id}?remove-users=true`
      const response = await del(url, {})

      if (response.status === 202) {
        await pullSSOConfigStatus(
          response.headers.get('location'),
          oidcUser,
          () => deleteSsoProfile(true),
          (error) => {
            const errorMsg = getErrorMessage(error, t)
            setErrorMessage(
              t('iam:sso_profiles.delete.delete_route_error_msg', {
                domainName: ssoRoutingRule.conditions.domains[0],
                errorMsg
              })
            )
            setDeleteLoading(false)
          }
        )
      } else {
        setErrorMessage(
          t('iam:sso_profiles.delete.delete_route_error_msg', {
            domainName: ssoRoutingRule.conditions.domains[0]
          })
        )
        setDeleteLoading(false)
      }
    } catch (error) {
      const errorMsg = getErrorMessage(error, t)
      setErrorMessage(
        t('iam:sso_profiles.delete.delete_route_error_msg', {
          domainName: ssoRoutingRule.conditions.domains[0],
          errorMsg
        })
      )
      setDeleteLoading(false)
    }
  }

  const handleDelete = async () => {
    if (lodashGet(formValues, 'delete.confirmation') === 'DELETE') {
      setDeleteLoading(true)
      setErrorMessage('')
      if (ssoRoutingRule) {
        await deleteRoutingRule()
      } else {
        await deleteSsoProfile(false)
      }
    } else {
      setFieldsError({
        deleteConfirmation: t('iam:sso_profiles.view_edit_create.required')
      })
    }
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              margin={{
                top: 'none',
                bottom: 'xsmall',
                horizontal: 'xxsmall'
              }}
              testId="confirmation-header-title"
              type="heading"
              level="2"
            >
              {t('iam:sso_profiles.delete.delete_sso_header')}
            </Typography>
          }
        />
      }
      content={
        <Box gap="small" margin={{ horizontal: 'xxsmall' }}>
          <Box>
            <Typography
              size="medium"
              testId="confirmation-header-subtitle"
              type="text"
              color="text-strong"
            >
              <Trans
                i18nKey="iam:sso_profiles.delete.delete_sso_heading"
                t={t}
                components={[<Text weight="500" />]}
              />
            </Typography>
            {recoveryAccount && (
              <>
                <Typography
                  size="medium"
                  testId="delete-recovery-account"
                  type="text"
                  color="text-strong"
                >
                  <Trans
                    i18nKey="iam:sso_profiles.delete.recovery_account"
                    t={t}
                  />
                </Typography>
                <Typography
                  size="medium"
                  testId="delete-recovery-account"
                  type="text"
                  weight="500"
                  color="text-strong"
                >
                  {recoveryAccount}
                </Typography>
              </>
            )}
          </Box>
          <Box>
            {ssoRoutingRule &&
              isEmailInDomain(
                oidcUser.profile.email,
                ssoRoutingRule.conditions.domains[0]
              ) && (
                <GrommetNotification
                  status="critical"
                  margin={{ top: 'xsmall', bottom: 'xsmall' }}
                  message={
                    <Typography
                      type="text"
                      testId="delete-self-account-warning"
                    >
                      <Trans
                        i18nKey="iam:sso_profiles.delete.self_account_warning"
                        t={t}
                      />
                    </Typography>
                  }
                />
              )}
            <GrommetNotification
              status="info"
              margin={{ top: 'xsmall', bottom: 'xsmall' }}
              message={
                <Typography type="text" testId="delete-time-warning">
                  <Trans i18nKey="iam:sso_profiles.delete.time_warning" t={t} />
                </Typography>
              }
            />
          </Box>
          <Box>
            <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
              {t('iam:sso_profiles.delete.confirmation_title')}*
            </Text>
            <Text size="xsmall">
              {t('iam:sso_profiles.delete.confirmation_description')}
            </Text>
            <FormField
              htmlFor="delete_confirmation"
              name="delete_confirmation"
              data-testid="delete-confirmation-form-field"
              error={fieldsError.deleteConfirmation}
              required
            >
              <TextInput
                padding="xxsmall"
                value={lodashGet(formValues, 'delete.confirmation')}
                id="delete_confirmation"
                name="delete_confirmation_value"
                data-testid="delete-confirmation-input"
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    delete: {
                      ...formValues?.delete,
                      confirmation: e.target.value
                    }
                  })
                }}
              />
            </FormField>
          </Box>
          <Typography
            size="medium"
            testId="confirmation-footer-subtitle"
            type="text"
            color="text-strong"
          >
            {t('iam:sso_profiles.delete.delete_sso_profile')}
          </Typography>
          {errorMessage.length ? (
            <Notification
              backgroundColor="status-critical"
              testId="delete-sso-profile-error-message"
              text={errorMessage}
              type="inline"
            />
          ) : null}
          {deleteLoading && (
            <Box margin={{ top: 'small' }} align="center">
              <Loader
                testId="delete-sso-profile-loader"
                label={t('iam:sso_profiles.delete.deleting_sso_profile')}
                orientation="horizontal"
              />
            </Box>
          )}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            !deleteLoading && (
              <Box direction="row" gap="medium">
                <Button
                  label={t('common:cancel')}
                  onClick={() => {
                    setShowModal(false)
                  }}
                  testId="cancel-btn"
                />
                <Button
                  primary
                  label={t('common:delete')}
                  onClick={handleDelete}
                  testId="delete-btn"
                  disabled={deleteLoading}
                />
              </Box>
            )
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="remove-sso-profile-modal-dialog"
      width="large"
    />
  )
}

DeleteSsoProfileModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ssoRoutingRule: PropTypes.object,
  setShowModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  recoveryAccount: PropTypes.string
}

export { DeleteSsoProfileModal }
