// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes, Navigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Layout } from '../../commoncomponents/layout/Layout'
import FolderDetailsPage from '../../commoncomponents/folder-details/FolderDetailsPage'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getCCSAppDetails } from '../../utils/ccs-manager-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { get } from '../../utils/api-utils'
import {
  CCSManagerActions,
  CCSManagerContextProvider,
  useCCSManagerContext
} from '../../context/ccs-manager-context'
import { AUTHZContextProvider } from '../../context/authz-context'
import { Roles } from '../../commoncomponents/identity/Roles'
import CreateRolesWizard from '../../commoncomponents/identity/create-role/CreateRolesWizard'
import RoleDetailsViewEditPage from '../../commoncomponents/identity/roles-details-view-edit/RoleDetailsViewEdit'

import IdentityPage from './pages/identity/IdentityPage'
import SupportAccess from './pages/support-access/SupportAccess'
import CreateLocationPage from './pages/locations/CreateLocationPage'
import CustomerDetails from './pages/customers/components/CustomerDetails'
import ManageCcs from './ManageCcs'
import ActivateDevicesDetailsPage from './pages/customers/components/activate-devices-details/ActivateDevicesDetails'
import UserDetailsPage from './pages/user-details/UserDetails'
import NotificationDetails from './pages/notifications/components/NotificationDetails'
import CreateNotificationWizard from './pages/notifications/components/create-notification/CreateNotificationWizard'
import { AppDetailsPage } from './pages/applications/pages'
import { CustomerUserDetailsPage } from './pages/customers/pages'
import { RRPDetailsPage } from './pages/customers/pages/rrp-details/RRPDetailsPage'
import CCSManagerHomePage from './CCSManagerHomePage'
import WhatsNewPostDetails from './pages/whats-new/WhatsNewPostDetails'
import CCSLocationDetails from './pages/locations/CCSLocationDetails'
import SetUpAccountPage from './pages/set-up-account/set-up-account'
import EditNotificationRequestWizard from './pages/notifications/components/notification-approval-flow/edit-notification-request/EditNotificationRequestWizard'
import NotificationApprovalDetails from './pages/notifications/components/notification-approval-flow/NotificationApprovalDetails'
import EditPublishedNotificationWizard from './pages/notifications/components/EditPublishedNotification/EditPublishedNotificationWizard'
import SupportAccessPage from './pages/support-access/SupportAccessPage'
import SupportAccessUsersPage from './pages/support-access/SupportAccessUsers'
import DocumentCuration from './pages/search-administration/document-curation/DocumentCuration'
import SearchAdministrationPage from './pages/search-administration'
import WhatsNew from './pages/whats-new/WhatsNew'

const CCSManagerContent = () => {
  const { t } = useTranslation(['common'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const mvSvsToSaHomeLD = LDFlags['glcp-sa-mv-svcs']
  const { dispatchCCSManagerContext, userCCSRoles } = useCCSManagerContext()
  const ccsApplicationId = getCCSAppDetails()?.ccsApplicationId

  const [showErrorNotification, setShowErrorNotification] = useState(null)

  useEffect(() => {
    if (!userCCSRoles) {
      const url = `/support-assistant/v1alpha1/tac-user-role-assignments`
      get(url, {}, oidcUser.access_token).then(
        (response) => {
          const roleSlugs =
            response?.data?.roles
              ?.filter((data) => data?.application_id === ccsApplicationId)
              .map((data) => data?.slug) || []
          dispatchCCSManagerContext({
            type: CCSManagerActions.SET_USER_CCS_ROLES,
            data: roleSlugs
          })
        },
        (error) => {
          setShowErrorNotification(displayApiError(error, t))
          dispatchCCSManagerContext({
            type: CCSManagerActions.SET_USER_CCS_ROLES,
            data: []
          })
        }
      )
    }
  }, [
    ccsApplicationId,
    oidcUser.access_token,
    t,
    dispatchCCSManagerContext,
    userCCSRoles
  ])

  return (
    <Routes>
      <Route exact path="/home" element={<CCSManagerHomePage />} />
      <Route
        exact
        path="/identity"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.view'
            }}
            fallbackComponent={<Navigate to="/manage-ccs/home" />}
          >
            <IdentityPage />
          </VisibilityWrapper>
        }
      />

      <Route
        exact
        path="/search-administration"
        element={
          <VisibilityWrapper
            hideFor={{
              deployment: ['GLOP'],
              feature: 'glcp-search-administration'
            }}
            fallbackComponent={<Navigate to="/home" />}
          >
            <SearchAdministrationPage />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/search-administration/document-curation/:docId"
        element={
          <VisibilityWrapper
            hideFor={{
              deployment: ['GLOP'],
              feature: 'glcp-search-administration'
            }}
            fallbackComponent={<Navigate to="/home" />}
          >
            <DocumentCuration />
          </VisibilityWrapper>
        }
      />
      {mvSvsToSaHomeLD && (
        <Route
          exact
          path="/whats-new"
          element={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/whats-new',
                permission: 'ccs.whats-new.view'
              }}
            >
              <WhatsNew />
            </VisibilityWrapper>
          }
        />
      )}
      <Route
        exact
        path="/support-access"
        element={
          <VisibilityWrapper
            hideFor={{
              deployment: ['GLOP'],
              feature: 'glcp-support-access-manager'
            }}
            fallbackComponent={<Navigate to="/home" />}
          >
            <SupportAccess />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/support-access/search-incident"
        element={
          <VisibilityWrapper
            hideFor={{
              deployment: ['GLOP'],
              feature: 'glcp-support-access-manager'
            }}
            fallbackComponent={<Navigate to="/home" />}
          >
            <SupportAccessPage />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/support-access/users"
        element={
          <VisibilityWrapper
            hideFor={{
              deployment: ['GLOP'],
              feature: 'glcp-support-access-manager'
            }}
            fallbackComponent={<Navigate to="/home" />}
          >
            <SupportAccessUsersPage />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/identity/roles"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.view'
            }}
          >
            <Roles inCCSManager />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/identity/roles/create/:applicationId/:slug"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.view'
            }}
          >
            <CreateRolesWizard inCCSManager />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/identity/roles/create/:applicationId"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.view'
            }}
          >
            <CreateRolesWizard inCCSManager />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/identity/roles/roleviewedit/:applicationId/:slug"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.view'
            }}
          >
            <RoleDetailsViewEditPage inCCSManager />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/onboarding/set-up-account"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/accounts/platform/customer',
              permission: 'ccs.accounts.platform.customer.edit'
            }}
          >
            <SetUpAccountPage />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/customers/customer-details"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/accounts/platform/customer',
              permission: 'ccs.accounts.platform.customer.view'
            }}
          >
            <CustomerDetails />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/customers/customer-details/folders/folder-details"
        element={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.activate.view',
              resource: '/ccs/activate/tac'
            }}
          >
            <FolderDetailsPage inCCSManager />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/customers/locations/create-location"
        element={
          <VisibilityWrapper
            hideFor={{
              feature: 'glcp-glasgow-sdi-ccs-manager'
            }}
            rbac={{
              resource: '/ccs/location-management',
              permission: 'ccs.location-management.edit'
            }}
          >
            <CreateLocationPage />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/customers/locations/:locationId"
        element={
          <VisibilityWrapper
            hideFor={{
              feature: 'glcp-glasgow-sdi-ccs-manager'
            }}
            rbac={{
              resource: '/ccs/location-management',
              permission: 'ccs.location-management.view'
            }}
          >
            <CCSLocationDetails />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/customers/users/:username"
        element={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.accounts.users.view.all',
              resource: '/ccs/accounts/user'
            }}
          >
            <CustomerUserDetailsPage />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/devices/:serial"
        element={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.activate.view',
              resource: '/ccs/activate/tac'
            }}
          >
            <ActivateDevicesDetailsPage />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/users/:username"
        element={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.accounts.users.view.all',
              resource: '/ccs/accounts/user'
            }}
          >
            <UserDetailsPage />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/applications/:appId"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/app-catalog/application',
              permission: 'ccs.app-catalog.view'
            }}
          >
            <AppDetailsPage />
          </VisibilityWrapper>
        }
      />
      <Route
        path="/customers/scope-groups/:rrpId"
        element={
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.view'
            }}
          >
            <RRPDetailsPage />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/notifications/create-notification"
        element={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.notification.edit',
              resource: '/ccs/notification'
            }}
          >
            <CreateNotificationWizard />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/notifications/edit-notification-request/:notificationId"
        element={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.notification.edit',
              resource: '/ccs/notification'
            }}
          >
            <EditNotificationRequestWizard />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/notifications/edit-published-notification-request/:notificationId"
        element={
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.notification.edit',
              resource: '/ccs/notification'
            }}
          >
            <EditPublishedNotificationWizard />
          </VisibilityWrapper>
        }
      />
      <Route
        exact
        path="/notifications/approval/:notificationId/:type"
        element={<NotificationApprovalDetails />}
      />
      <Route
        exact
        path="/notifications/scheduled-notification/:notificationId"
        element={<NotificationDetails isSchedule />}
      />
      <Route
        exact
        path="/notifications/:notificationId"
        element={<NotificationDetails />}
      />

      <Route
        exact
        path="/whats-new/:blogID"
        element={<WhatsNewPostDetails />}
      />

      <Route
        path="/*"
        element={<ManageCcs errorNotification={showErrorNotification} />}
      />
      {/* <Route path="/*" element={<Navigate to="/not-found" />} /> */}
    </Routes>
  )
}

const CCSManagerModule = () => {
  return (
    <AUTHZContextProvider>
      <CCSManagerContextProvider>
        <Layout hideHeaderOptions={['apps']}>
          <CCSManagerContent />
        </Layout>
      </CCSManagerContextProvider>
    </AUTHZContextProvider>
  )
}

export default CCSManagerModule
