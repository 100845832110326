// #(C) Copyright 2024 Hewlett Packard Enterprise Development LP
export const statusList = ['All', 'Critical', 'Warning', 'Ok', 'Information']
export const alertLists = ['Alerts', 'Tasks']
export const dateFrameList = [
  'All',
  'Past 24 hours',
  'Past 3 days',
  'Past 7 days',
  'Past 30 days'
]
export const colsDisplayed = [
  'severity',
  'target',
  'state',
  'owner',
  'createdAt',
  'source',
  'id',
  'url',
  'url_text'
]
export const prefDisplayed = [
  'regions',
  'regions-emails',
  'status',
  'status-emails'
]
export const statusOptions = ['Critical', 'Warning', 'Ok', 'Information']

export const adminColsDisplayed = [
  'id',
  'owner',
  'target',
  'state',
  'regions',
  'applicationInstances',
  'roles',
  'severity',
  'channel',
  'is_banner',
  'createdAt',
  'startTime',
  'ttl',
  'summary',
  'description',
  'is_email',
  'emailContentTitle',
  'emailContentDetails',
  'url',
  'url_text'
]
export const adminColsDisplayedEdit = [
  'id',
  'owner',
  'target',
  'state',
  'internal_state',
  'regions',
  'applicationInstances',
  'roles',
  'severity',
  'channel',
  'is_banner',
  'createdAt',
  'startTime',
  'ttl',
  'summary',
  'description',
  'is_email',
  'emailContentTitle',
  'emailContentDetails',
  'url',
  'url_text'
]

export const userColsDisplayed = [
  'severity',
  'target',
  'state',
  'owner',
  'createdAt',
  'regions',
  'source',
  'id',
  'url',
  'url_text'
]

export const categoryColumns = [
  'service',
  'sub_service',
  'topics',
  'sub_topics',
  'topic'
]

export const historyColumns = [
  'state',
  'severity',
  'summary',
  'description',
  'emailContentTitle',
  'emailContentDetails',
  'ttl'
]

export const userHistoryColsDisplayed = [
  'severity',
  'target',
  'state',
  'owner',
  'createdAt',
  'summary',
  'description',
  'regions',
  'source',
  'id',
  'url',
  'url_text'
]
export const alertsTaskDisplayed = [
  'description',
  'type',
  'severity',
  'target',
  'alert_state',
  'task_state',
  'category',
  'workspace',
  'created_by',
  'task_duration',
  'created_at',
  'updated_at'
]

export const targetMap = {
  ARUBA_CENTRAL: 'Aruba Central',
  COMPUTE_CENTRAL: 'Compute Ops Management',
  STORAGE_CENTRAL: 'Data Services Cloud Console',
  PLATFORM: 'HPE GreenLake platform',
  OPS_RAMP: 'OpsRamp',
  SUSTAINABLITY_IC: 'Sustainability Insights Center',
  GREENLAKE_FLEX: 'HPE GreenLake Flex Solutions'
}
