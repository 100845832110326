// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useTranslation } from 'react-i18next'

import {
  isGLOP,
  isOrg,
  isInventoryOwnedWorkspace
} from '../../../utils/feature-flag-utils'

const HomeWidgetsData = () => {
  const { t } = useTranslation(['common'])

  const widgetsData = [
    {
      widgetKey: 'widgets-user-overview',
      visible: false,
      order: 1,
      testId: 'widgets-user-overview',
      title: t('dashboard.widgets.user_overview.title'),
      url: '/ui-doorway/ui/v1/um/stats',
      subtitle: null,
      footerMessage: t('dashboard.widgets.user_overview.description'),
      footerNotification: null,
      chartType: 'pie',
      editMode: false,
      rbac: {
        resource: isOrg() && !isGLOP() ? undefined : '/ccs/authorization',
        permission:
          isOrg() && !isGLOP()
            ? 'ccs.accounts.user.view-all'
            : 'ccs.authorization.view'
      },
      hidden: null,
      actionUrl: '/manage-account/identity/users',
      widgetData: {
        position: 'right', // TODO: is it required?
        listData: [
          {
            label: t('dashboard.widgets.user_overview.total'),
            summary: true,
            value: 232,
            valueKey: 'total_users'
          },
          {
            label: t('dashboard.widgets.user_overview.active'),
            summary: false,
            value: 163,
            valueKey: 'active_users'
          },
          {
            label: t('dashboard.widgets.user_overview.inactive'),
            summary: false,
            value: 40,
            valueKey: 'inactive_users'
          },
          {
            label: t('dashboard.widgets.user_overview.unverified'),
            summary: false,
            value: 19,
            valueKey: 'unverified_users'
          }
        ]
      },
      noDataSummary: {
        icon: 'User',
        message: t('dashboard.widgets.user_overview.no_user'),
        anchorText: t('dashboard.widgets.user_overview.add_users'),
        addButtonRbac: {
          resource: '/ccs/authorization',
          permission: 'ccs.authorization.edit'
        }
      }
    },
    {
      widgetKey: 'widgets-subscription-overview',
      visible: false,
      order: 2,
      testId: 'widgets-subscription-overview',
      title: t('dashboard.widgets.subscription_overview.title'),
      subtitle: t('dashboard.widgets.subscription_overview.subtitle'),
      footerMessage: t('dashboard.widgets.subscription_overview.description'),
      footerNotification: null,
      url: '/ui-doorway/ui/v1/license/stats?product_type=DEVICE',
      chartType: 'card',
      editMode: false,
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      },
      hidden: !isInventoryOwnedWorkspace(),
      actionUrl: '/devices/subscriptions-list',
      widgetData: {
        position: 'center',
        listData: [
          {
            icon: 'StatusCritical',
            color: '#FC5A5A',
            label: t(
              'dashboard.widgets.subscription_overview.expiry_thirty_days'
            ),
            summary: false,
            value: 5,
            valueKey: 'subscriptions_expiring_in_30_days'
          },
          {
            icon: 'Alert',
            color: '#FFBC44',
            label: t(
              'dashboard.widgets.subscription_overview.expiry_ninety_days'
            ),
            summary: false,
            value: 23,
            valueKey: 'subscriptions_expiring_in_90_days'
          },
          {
            icon: 'Alert',
            color: '#FFBC44',
            label: t(
              'dashboard.widgets.subscription_overview.expiry_eval_thirty_days'
            ),
            summary: false,
            value: 44,
            valueKey: 'evaluations_expiring_in_30_days'
          },
          {
            icon: 'Certificate',
            color: '#555555',
            label: t(
              'dashboard.widgets.subscription_overview.total_subscriptions'
            ),
            summary: false,
            value: 143,
            valueKey: 'total_subscriptions'
          }
        ]
      },
      noDataSummary: {
        icon: 'Certificate',
        message: t('dashboard.widgets.subscription_overview.no_subscriptions'),
        anchorText: t(
          'dashboard.widgets.subscription_overview.add_subscriptions'
        ),
        addButtonRbac: {
          resource: '/ccs/device-management',
          permission: 'ccs.device-management.edit'
        }
      }
    },
    {
      widgetKey: 'widgets-device-summary',
      visible: false,
      order: 3,
      testId: 'widgets-device-summary',
      title: t('dashboard.widgets.device_summary.title'),
      subtitle: null,
      footerMessage: t('dashboard.widgets.device_summary.description'),
      footerNotification: null,
      url: '/ui-doorway/ui/v1/devices/stats',
      chartType: 'circle',
      editMode: false,
      rbac: {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      },
      hidden: !isInventoryOwnedWorkspace(),
      actionUrl: '/devices/inventory-list',
      widgetData: {
        position: 'bottom',
        listData: [
          {
            label: t('dashboard.widgets.device_summary.total_devices'),
            summary: true,
            value: 347,
            valueKey: 'total_count'
          },
          {
            label: t('dashboard.widgets.device_summary.require_assignments'),
            summary: false,
            value: 68,
            valueKey: 'unassigned'
          },
          {
            label: t('dashboard.widgets.device_summary.require_subscriptions'),
            summary: false,
            value: 34,
            valueKey: 'unlicensed'
          },
          {
            label: t(
              'dashboard.widgets.device_summary.assigned_and_subscribed'
            ),
            summary: false,
            value: 245,
            valueKey: 'active'
          }
        ]
      },
      noDataSummary: {
        icon: 'Device',
        message: t('dashboard.widgets.device_summary.no_device'),
        anchorText: t('dashboard.widgets.device_summary.add_devices'),
        addButtonRbac: {
          resource: '/ccs/device-management',
          permission: 'ccs.device-management.edit'
        }
      }
    }
  ]

  return widgetsData
}

export { HomeWidgetsData }
