// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import AccessErrorPage from './AccessError'
import SessionErrorPage from './SessionError'
import AppUnavailablePage from './AppUnavailable'
import InvalidLink404Error from './InvalidLink404Error'

const ErrorRouterContent = () => {
  return (
    <Routes>
      <Route path="/access" element={<AccessErrorPage />} />
      <Route path="/session" element={<SessionErrorPage />} />
      <Route path="/app-unavailable" element={<AppUnavailablePage />} />
      <Route path="/invalid-link" element={<InvalidLink404Error />} />

      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

const ErrorRouter = () => {
  return <ErrorRouterContent />
}

export default ErrorRouter
