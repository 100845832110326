// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  isMSP,
  isTenant,
  isGLOP,
  isCoP
} from '../../../utils/feature-flag-utils'

/* glcp-cds-billing-export is a testing only LD flag, the purpose is only enabling the Billing Export 
for local cypress and Mira testing. Once this flag is on, user can access this feature from a MSP main 
account, otherwise this feature only can be accessed under GLOP */

const useIsBillingExportAvailable = () => {
  const LDFlags = useFlags()
  if (LDFlags['glcp-cds-billing-export'] && isMSP() && !isTenant()) {
    return true
  }
  return isMSP() && !isTenant() && isGLOP() && !isCoP()
}

export default useIsBillingExportAvailable
