// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation
} from 'react-router-dom'

import { checkGotoChooseAccount } from '../../utils/common-utils'

import { BookInventoryPage, FeatureFlaggingPage } from './pages'
import TutorialHome from './TutorialHome'

const TutorialApplicationRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  checkGotoChooseAccount(navigate, location)
  console.log('TutorialApplicationRouter enter')
  return (
    <Routes>
      <Route exact path="/" element={<TutorialHome />} />
      <Route exact path="/books" element={<BookInventoryPage />} />
      <Route exact path="/feature-flagging" element={<FeatureFlaggingPage />} />
      <Route path="/*" element={<Navigate to="/not-found" />} />
    </Routes>
  )
}

export default TutorialApplicationRouter
