// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { StatusCritical, StatusGood } from 'grommet-icons'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { getAliases } from '../../customer-apis'
import {
  ActionButton,
  DataTable,
  Notification
} from '../../../../../../components'
import { DeleteAliasModal } from '../DeleteAliasModal'
import { EditAliasModal } from '../EditAliasModal'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { get, post, getErrorMessage } from '../../../../../../utils/api-utils'
import { CheckResourcePermissions } from '../../../../../../utils/ccs-manager-utils'
import { useVisibilityContext } from '../../../../../../context/visibility-context'

const AliasesDataTable = ({
  customerId,
  refreshCount,
  setZtoToggleEnabledInCCSManager,
  setZtoToggleDisabledInCCSManager,
  setZtoToggleVisible,
  setZtoResourceId
}) => {
  const LDFlags = useFlags()
  const saZTOLDFlag = LDFlags['glcp-sa-zto']
  const { t } = useTranslation(['manage', 'common'])
  const { oidcUser } = useReactOidc()
  const [aliases, setAliases] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [showDeleteAliasModal, setDeleteAliasModal] = useState(false)
  const [showEditAliasModal, setEditAliasModal] = useState(false)
  const [aliasRefreshCount, setAliasRefreshCount] = useState(0)
  const [showNotification, setNotification] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const { rbacPolicies } = useVisibilityContext()
  const ztoEditPermission = CheckResourcePermissions(
    { '/ccs/device-management/zto': ['ccs.device-subscription-zto.edit'] },
    rbacPolicies
  )
  const onSuccessfullDeletion = () => {
    setNotification(t('aliases.alias_del_success_msg'))
    setAliasRefreshCount(aliasRefreshCount + 1)
  }

  const onSuccessfullEdit = () => {
    setNotification(t('aliases.alias_rename_success_msg'))
    setAliasRefreshCount(aliasRefreshCount + 1)
  }

  const [selectedRowData, setSelectedRowData] = useState({})
  const columns = [
    {
      property: 'alias',
      type: 'string',
      header: t('aliases.alias_name'),
      primary: true
    },
    {
      property: 'type',
      type: 'string',
      header: t('aliases.type')
    },
    {
      property: 'actions',
      header: '',
      render: (datum) => {
        return (
          <VisibilityWrapper
            rbac={{
              permission: 'ccs.activate.edit',
              resource: '/ccs/activate/tac'
            }}
          >
            <ActionButton
              testId="aliases-action-button"
              actions={[
                {
                  label: t('aliases.delete_alias'),
                  onClick: () => {
                    setSelectedRowData(datum)
                    setDeleteAliasModal(true)
                  }
                },
                {
                  label: t('aliases.edit_alias'),
                  onClick: () => {
                    setSelectedRowData(datum)
                    setEditAliasModal(true)
                  }
                }
              ]}
              showOneActionAsDropDown
            />
          </VisibilityWrapper>
        )
      }
    }
  ]

  useEffect(() => {
    const request = {
      platform_customer_id: customerId,
      ...(searchVal.trim().length && { search_string: searchVal.trim() })
    }
    getAliases(request, oidcUser.access_token, setErrorMessage, t).then(
      (data) => {
        const tableData = data?.customer_aliases
        if (tableData.length > 0 && saZTOLDFlag) {
          const url = `/support-assistant/v1alpha1/customers/${customerId}/zto-settings`
          get(url, {}, oidcUser.access_token).then(
            (ztodata) => {
              if (ztodata.data.items.length > 0) {
                if (ztoEditPermission) {
                  setZtoToggleEnabledInCCSManager(ztodata.data.items[0].enabled)
                  setZtoToggleDisabledInCCSManager(false)
                } else {
                  setZtoToggleEnabledInCCSManager(ztodata.data.items[0].enabled)
                  setZtoToggleDisabledInCCSManager(true)
                }
                setZtoResourceId(ztodata.data.items[0].id)
                setZtoToggleVisible(true)
              } else if (ztoEditPermission) {
                post(
                  `/support-assistant/v1alpha1/customers/${customerId}/zto-settings`,
                  { enabled: true },
                  oidcUser.access_token
                ).then(
                  (ztoSettings) => {
                    setZtoToggleEnabledInCCSManager(ztoSettings.data.enabled)
                    setZtoToggleDisabledInCCSManager(false)
                    setZtoResourceId(ztoSettings.data.id)
                    setZtoToggleVisible(true)
                  },
                  (err) => {
                    setErrorMessage(getErrorMessage(err, t))
                    return err
                  }
                )
              } else {
                setZtoToggleDisabledInCCSManager(true)
                setZtoToggleEnabledInCCSManager(true)
                setZtoToggleVisible(true)
              }
            },
            (err) => {
              setErrorMessage(getErrorMessage(err, t))
              return err
            }
          )
        } else {
          setZtoToggleVisible(false)
        }
        setAliases(tableData)
      }
    )
  }, [
    oidcUser.access_token,
    t,
    customerId,
    refreshCount,
    aliasRefreshCount,
    searchVal,
    setZtoToggleEnabledInCCSManager,
    setZtoToggleDisabledInCCSManager,
    setZtoToggleVisible,
    setZtoResourceId,
    saZTOLDFlag,
    ztoEditPermission
  ])
  return (
    <>
      <DataTable
        grid={{
          columns,
          data: aliases,
          onClickRow: () => {}
        }}
        search={{
          onSearchValueChange: (value) => {
            setSearchVal(value)
          },
          placeholder: t('aliases.search_placeholder')
        }}
        summary={{
          entityName: t('aliases.summary')
        }}
        testId="aliases-data-table"
      />
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
      {showEditAliasModal && (
        <EditAliasModal
          customerId={customerId}
          type={selectedRowData.type}
          aliasName={selectedRowData.alias}
          onSuccess={onSuccessfullEdit}
          setAliasModal={setEditAliasModal}
        />
      )}
      {showDeleteAliasModal && (
        <DeleteAliasModal
          customerId={customerId}
          aliasName={selectedRowData.alias}
          setShowModal={setDeleteAliasModal}
          onSuccess={onSuccessfullDeletion}
        />
      )}
      {showNotification && (
        <Notification
          icon={<StatusGood color="text-strong" />}
          onClose={() => setNotification(false)}
          testId="status-good-notification"
          text={showNotification}
        />
      )}
    </>
  )
}
AliasesDataTable.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  refreshCount: PropTypes.number.isRequired,
  setZtoToggleEnabledInCCSManager: PropTypes.func.isRequired,
  setZtoToggleDisabledInCCSManager: PropTypes.func.isRequired,
  setZtoToggleVisible: PropTypes.func.isRequired,
  setZtoResourceId: PropTypes.func.isRequired
}
export { AliasesDataTable }
