// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../../../components'

const UserDetailsSection = ({ data = {} }) => {
  const getValue = (value) => {
    let fieldValue
    if (value === null || value === undefined) {
      fieldValue = '--'
    } else if (typeof value === 'boolean') {
      if (value) {
        fieldValue = 'True'
      } else {
        fieldValue = 'False'
      }
    } else {
      fieldValue = value
    }
    return fieldValue
  }
  return (
    <Box direction="row-responsive">
      <Box alignSelf="start" justify="between" direction="column">
        {Object.entries(data).map((datum) => (
          <Box
            direction="row-responsive"
            align="start"
            justify="start"
            margin={{ top: 'small' }}
            key={datum[0]}
          >
            <Box align="start" width="small">
              <Typography
                size="small"
                testId="details-key"
                type="text"
                weight="bold"
              >
                {datum[0]}
              </Typography>
            </Box>
            <Box align="start" width="small">
              <Typography
                size="medium"
                color="text-strong"
                testId="details-value"
                type="text"
                wordBreak="break-word"
              >
                {getValue(datum[1])}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

UserDetailsSection.propTypes = {
  data: PropTypes.object
}

export { UserDetailsSection }
