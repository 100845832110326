// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

import OnPremWorkspaces from './OnPremWorkspaces'
import { OnPremWorkspaceDetails } from './onprem-workspace-details/OnPremWorkspaceDetails'

const OnPremWorkspaceRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route
          exact
          path="/:workspaceRId"
          element={<OnPremWorkspaceDetails />}
        />
        <Route exact path="/" element={<OnPremWorkspaces />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default OnPremWorkspaceRouter
