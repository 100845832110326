// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckBoxGroup,
  Box,
  TextInput,
  Accordion,
  AccordionPanel
} from 'grommet'
import { Down, Search } from 'grommet-icons'
import { PropTypes } from 'prop-types'

import { Typography } from '../../../components/typography/Typography'
import { Tree } from '../../tree/Tree'
import { displayNotification } from '../../../utils/notificiation-utils'

const PermissionsBody = ({
  data,
  mode = 'column',
  title = '',
  subtitle = '',
  selectionText = 'View Selected',
  value = [],
  setValue = () => {},
  permissionsSelected
}) => {
  const { t } = useTranslation(['authz'])

  const [searchString, setSearchString] = useState('')
  const [showNotification, setShowNotification] = useState(null)
  const handlePermissionChange = (nextValue, option, datum) => {
    const mandatory = datum?.tags?.mandatory
    const resSlug = datum?.slug
    const options = datum?.subs
    const slugOptions = new Set()
    nextValue.forEach((each) => {
      slugOptions.add(each)
    })
    const mandatorySelected = options.some((each) => {
      return slugOptions.has(`${resSlug}~${each.slug}`)
    })
    if (mandatory && !mandatorySelected) {
      setShowNotification(
        displayNotification(
          t('roles.selected_resource_need_one_permission'),
          'warning',
          setShowNotification
        )
      )
      if (option?.key) {
        nextValue.push(option?.key)
        setValue(nextValue)
      }
    } else {
      setValue(nextValue)
    }
  }
  return (
    <>
      <Tree
        data={data}
        mode={mode}
        title={title}
        subtitle={subtitle}
        selectionText={selectionText}
        value={value}
        setValue={setValue}
        searchString={searchString}
      >
        {(datum) => (
          <>
            <Box
              gap="medium"
              style={{ minHeight: 'auto' }}
              margin={{ bottom: 'small' }}
              basis="full"
            >
              <Box pad={{ bottom: 'medium' }}>
                <TextInput
                  icon={<Search />}
                  placeholder={t('roles.filter_permissions_placeholder')}
                  value={searchString}
                  onChange={(event) => setSearchString(event.target.value)}
                />
              </Box>
              <Typography type="heading" level={3} testId={datum.name}>
                {datum.name}
              </Typography>
              <Typography type="text" testId={datum.description} size="xsmall">
                {datum.description}
              </Typography>
              <Box>
                {datum.subs && (
                  <CheckBoxGroup
                    value={value}
                    gap="xsmall"
                    labelKey="label"
                    valueKey="key"
                    onChange={({ value: nextValue, option }) => {
                      handlePermissionChange(nextValue, option, datum)
                    }}
                    options={datum.subs.map((each) => {
                      const mandatory = each.tags?.mandatory
                      return {
                        label: each.name,
                        parentKey: datum.slug,
                        dataset: datum,
                        key: `${datum.slug}~${each.slug}`,
                        disabled:
                          mandatory === null &&
                          permissionsSelected?.length > 0 &&
                          permissionsSelected.includes(
                            `${datum.slug}~${each.slug}`
                          )
                      }
                    })}
                  />
                )}
              </Box>
            </Box>
            <Box style={{ minHeight: '15vh' }}>
              <Box flex direction="row" pad="xsmall" wrap overflow="auto">
                <Accordion animate multiple>
                  {datum?.children?.map((child) => {
                    return (
                      <AccordionPanel
                        key={child.name}
                        header={
                          <>
                            <Box
                              align="baseline"
                              justify="start"
                              pad={{
                                vertical: 'small'
                              }}
                              direction="row"
                              width="medium"
                            >
                              <Box
                                align="baseline"
                                pad={{
                                  vertical: 'xsmall'
                                }}
                                justify="start"
                                direction="row"
                                width="large"
                              >
                                <Typography
                                  type="heading"
                                  testId={child.name}
                                  level={4}
                                >
                                  {child.name}
                                </Typography>
                              </Box>
                              <Down size="small" margin="small" />
                            </Box>
                          </>
                        }
                      >
                        <Box overflow="auto">
                          <Typography
                            type="text"
                            testId={child.description}
                            size="xsmall"
                          >
                            {child.description}
                          </Typography>
                          {child.subs && (
                            <CheckBoxGroup
                              value={value}
                              gap="xsmall"
                              labelKey="label"
                              valueKey="key"
                              onChange={({ value: nextValue, option }) => {
                                handlePermissionChange(nextValue, option, child)
                              }}
                              options={child.subs.map((each) => {
                                const mandatory = each.tags?.mandatory
                                return {
                                  label: each.name,
                                  parentKey: child.slug,
                                  dataset: child,
                                  key: `${child.slug}~${each.slug}`,
                                  disabled:
                                    mandatory === null &&
                                    permissionsSelected?.length > 0 &&
                                    permissionsSelected.includes(
                                      `${child.slug}~${each.slug}`
                                    )
                                }
                              })}
                            />
                          )}
                        </Box>
                      </AccordionPanel>
                    )
                  })}
                </Accordion>
              </Box>
            </Box>
          </>
        )}
      </Tree>
      {showNotification}
    </>
  )
}
export default PermissionsBody

PermissionsBody.propTypes = {
  data: PropTypes.array.isRequired,
  mode: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectionText: PropTypes.string,
  value: PropTypes.array,
  setValue: PropTypes.func,
  permissionsSelected: PropTypes.array
}
