// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Avatar, Box } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GLCPHeaderProfile, Button, MenuList } from '../../../components'
import { isOkta, isCoP } from '../../../utils/feature-flag-utils'
import VisibilityWrapper from '../../visibility-wrapper/VisibilityWrapper'

const parseCurrentUser = (user) => {
  return {
    firstName: user?.profile?.given_name,
    lastName: user?.profile?.family_name,
    email: user?.profile?.email
  }
}

const getUserNameChars = (user) => {
  return `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
}

const UserProfileContent = () => {
  const { t } = useTranslation(['common'])
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const currentUser = parseCurrentUser(oidcUser)
  const handleMenuItemClick = (item) => {
    if (item.linkTo.includes('http')) {
      window.open(item.linkTo, '_blank', 'noopener')
    } else {
      navigate(item.linkTo)
    }
  }
  const UserProfileData = {
    // Pass if we have lastName
    userInfo: {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email
    },
    profileMenu: (
      <Box margin={{ horizontal: 'medium' }}>
        <MenuList
          navStyles={{
            padding: 'none',
            gap: 'small'
          }}
          menuData={[
            {
              id: 1,
              label: t('user_profile_dropdown.hpe_account_details'),
              linkTo: isOkta()
                ? `${window.$settings.oktaURL}/profile`
                : '/profile/details',
              testId: 'hpe-acc-det-nav-menu'
            },
            {
              id: 2,
              label: t('user_profile_dropdown.hpe_greenlake_preferences'),
              testId: 'hpe-gl-pref-nav-menu',
              linkTo: '/preferences-only',
              isHidden: () => isCoP()
            },

            {
              id: 3,
              label: t('user_profile_dropdown.visit_hpe'),
              linkTo: 'https://www.hpe.com/us/en/home.html',
              testId: 'visit-hpe-com-nav-menu',
              isHidden: () => isCoP()
            },

            {
              id: 4,
              label: t(
                isCoP()
                  ? 'user_profile_dropdown.sign_out_cop'
                  : 'user_profile_dropdown.sign_out'
              ),
              linkTo: '/sign-out',
              testId: 'sign-out-hpe-nav-menu'
            }
          ]}
          onClickMenuItem={handleMenuItemClick}
          testId="profile-menu"
        />
      </Box>
    ),
    primaryProfileAction: (
      <Box margin={{ horizontal: 'medium' }}>
        <VisibilityWrapper hideFor={{ deployment: ['COP'] }}>
          <Button
            primary
            label={t('user_profile_dropdown.my_hpe_account')}
            onClick={() =>
              window.open(
                'https://www.hpe.com/us/en/my-account/overview.html',
                '',
                'noopener'
              )
            }
            testId="header-profile-primary-btn"
          />
        </VisibilityWrapper>
      </Box>
    )
  }
  return <GLCPHeaderProfile {...UserProfileData} />
}

const UserAvatar = () => {
  const { oidcUser } = useReactOidc()
  return (
    <Avatar background="background-contrast">
      {getUserNameChars(parseCurrentUser(oidcUser))}
    </Avatar>
  )
}

const UserData = {
  id: 'user',
  // Avatar is not an icon, instead render as child of button
  // so button doesn't apply button font-weight/other styling to Avatar
  children: <UserAvatar />,
  content: <UserProfileContent />
}
export { UserData }
