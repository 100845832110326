// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Text } from 'grommet'
import {
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusWarningSmall
} from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import { get } from '../../../../utils/api-utils'
import { PopUpError } from '../../../device-management/device-map/components/PopUpError'
import { Typography } from '../../../../components'
import { retryPopUpRender } from '../../../device-management/utils'
import { AssetPopupSkeletionLoader } from '../utils'

export const SingleClusterData = ({ asset }) => {
  const { oidcUser } = useReactOidc()
  const [clusterData, setClusterData] = useState(null)
  const [popUpCase, setPopUpCase] = useState(null)
  const { t } = useTranslation(['manage'])

  const getPopUpData = useCallback(() => {
    const params = {
      boundingBoxTopLeftLat: asset?.location?.lat,
      boundingBoxTopLeftLong: asset?.location?.long,
      boundingBoxBottomRightLat: asset?.location?.lat,
      boundingBoxBottomRightLong: asset?.location?.long
    }

    get(
      '/internal-assets/v1beta1/locations',
      params,
      oidcUser.access_token
    ).then(
      (response) => {
        setClusterData(response.data)
        setPopUpCase('CLUSTER_SUMMARY_DATA')
      },
      (error) => {
        setClusterData([])
        setPopUpCase('CLUSTER_SUMMARY_ERROR')
        console.log(error)
      }
    )
  }, [asset.location.lat, asset.location.long, oidcUser.access_token])

  useEffect(() => {
    getPopUpData()
  }, [getPopUpData])

  const PopupRender = () => {
    switch (popUpCase) {
      case 'CLUSTER_SUMMARY_ERROR':
        return (
          <PopUpError
            heading={t('asset_management.assets_map.risk_summary')}
            onAnchorClick={() =>
              retryPopUpRender(setClusterData, setPopUpCase, getPopUpData)
            }
          />
        )
      case 'CLUSTER_SUMMARY_DATA':
        return (
          <Box data-testid="cluster-single-summary">
            <Box
              margin={{ horizontal: 'xsmall' }}
              border={{ side: 'bottom', color: 'border' }}
            >
              <Text
                margin={{ horizontal: 'small', top: 'small' }}
                weight={500}
                size="large"
                data-testid="cluster-single-heading"
              >
                {clusterData?.items[0].location?.name
                  ? clusterData?.items[0].location?.name
                  : clusterData?.items[0].location?.city}
              </Text>
              <Text
                margin={{ horizontal: 'small', top: 'xsmall', bottom: 'small' }}
                data-testid="cluster-single-sub-heading"
              >
                {`${clusterData?.items[0]?.assetStats?.total} ${t(
                  `asset_management.assets_map.assets`
                )}`}
              </Text>
            </Box>
            {clusterData?.items[0]?.assetStats?.riskLevel === 1 ? (
              <Box
                margin={{ horizontal: 'xsmall', vertical: 'small' }}
                direction="row"
                align="center"
                basis="full"
              >
                <Box align="center" margin={{ right: 'xsmall' }}>
                  <StatusGoodSmall size="small" color="status-ok" />
                </Box>
                <Typography type="text" data-testid="cluster-single-green">
                  {t('asset_management.assets_map.multiple.green')}
                </Typography>
              </Box>
            ) : (
              <Box
                margin={{ horizontal: 'xsmall', vertical: 'small' }}
                pad={{ top: 'xsmall' }}
                gap="xsmall"
                data-testid="cluster-single-list"
              >
                {clusterData?.items[0]?.assetStats?.riskSupportLevel === 3 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusCriticalSmall
                        size="small"
                        color="status-critical"
                      />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-single-red-support"
                    >
                      {`${
                        clusterData?.items[0]?.assetStats?.riskSupportAssetCount
                      } ${t(
                        `asset_management.assets_map.multiple.red_support`
                      )}`}
                    </Typography>
                  </Box>
                )}
                {clusterData?.items[0]?.assetStats?.riskAgeLevel === 3 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusCriticalSmall
                        size="small"
                        color="status-critical"
                      />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-single-red-age"
                    >
                      {`${
                        clusterData?.items[0]?.assetStats?.riskAgeAssetCount
                      } ${t(`asset_management.assets_map.multiple.red_age`)}`}
                    </Typography>
                  </Box>
                )}
                {clusterData?.items[0]?.assetStats?.riskSupportLevel === 2 && (
                  <Box direction="row" align="start" basis="full">
                    <Box margin={{ top: 'xsmall', right: 'xsmall' }}>
                      <StatusWarningSmall size="small" color="status-warning" />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-single-yellow-support"
                    >
                      {`${
                        clusterData?.items[0]?.assetStats?.riskSupportAssetCount
                      } ${t(
                        `asset_management.assets_map.multiple.yellow_support`
                      )}`}
                    </Typography>
                  </Box>
                )}
                {clusterData?.items[0]?.assetStats?.riskAgeLevel === 2 && (
                  <Box direction="row" align="center" basis="full">
                    <Box align="center" margin={{ right: 'xsmall' }}>
                      <StatusWarningSmall size="small" color="status-warning" />
                    </Box>
                    <Typography
                      type="text"
                      data-testid="cluster-single-yellow-age"
                    >
                      {`${
                        clusterData?.items[0]?.assetStats?.riskAgeAssetCount
                      } ${t(
                        `asset_management.assets_map.multiple.yellow_age`
                      )}`}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )
      default:
        return null
    }
  }

  return asset.assetStats.total > 1 && clusterData ? (
    PopupRender()
  ) : (
    <AssetPopupSkeletionLoader />
  )
}

SingleClusterData.propTypes = {
  asset: PropTypes.object.isRequired
}
