// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'

const AllAccountPage = lazy(() => import('./AllAccount'))

const AccountDetailsRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route path="/*" element={<AllAccountPage />} />
      </Routes>
    </Suspense>
  )
}

export default AccountDetailsRouter
