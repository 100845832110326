// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react'
import { Box, ResponsiveContext } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Typography } from '../../../../../../components'
import { isCoP } from '../../../../../../utils/feature-flag-utils'
import {
  getOnPremWOrkpsaceAnchor,
  getValue
} from '../../../../../../utils/dm-sm-common-utils'

function DetailsSection({
  data = {},
  title = '',
  onPremWorkSpaceResourceId = '',
  onPremWorkSpaceDataLength = 0
}) {
  const { t } = useTranslation(['device'])
  const navigate = useNavigate()
  const size = useContext(ResponsiveContext)
  if (!onPremWorkSpaceDataLength) delete data?.onprem_workspace
  return (
    <Box>
      <Box
        direction="row-responsive"
        justify="between"
        border={{ side: 'bottom', color: 'border-weak' }}
      >
        <Box margin={{ bottom: 'small' }}>
          <Typography
            level="3"
            type="heading"
            testId={`details-header-${title}`}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box
        alignSelf="start"
        justify="between"
        direction="column"
        margin={{ top: 'small' }}
      >
        {Object.entries(data).map((datum) => {
          if (isCoP() && datum[0] === 'contractId') return null

          return (
            <Box
              direction={size === 'xsmall' ? 'column' : 'row-responsive'}
              align="start"
              justify="start"
              margin={{ top: 'small' }}
              key={datum[0]}
            >
              <Box width="small" align="start">
                <Typography size="xsmall" testId="details-key" type="text">
                  {t(datum[0])}
                </Typography>
              </Box>
              <Box
                width="medium"
                align="start"
                margin={{ left: 'medium' }}
                pad={{ left: 'small' }}
              >
                {datum[0] === 'addOns' && datum[1]?.length ? (
                  datum[1].map((addOn) => (
                    <Typography
                      key={`addon-${addOn.name}`}
                      size="medium"
                      color="text-strong"
                      testId="details-value"
                      type="text"
                      truncate
                      style
                    >
                      {`${addOn?.name_display || '--'} : ${
                        addOn?.value_display || '--'
                      }`}
                    </Typography>
                  ))
                ) : (
                  <Typography
                    size="medium"
                    color="text-strong"
                    testId="details-value"
                    type="text"
                  >
                    {datum[0] === 'onprem_workspace'
                      ? getOnPremWOrkpsaceAnchor(
                          datum[1],
                          onPremWorkSpaceResourceId,
                          navigate
                        )
                      : getValue(datum[1])}
                  </Typography>
                )}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

DetailsSection.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  onPremWorkSpaceResourceId: PropTypes.string,
  onPremWorkSpaceDataLength: PropTypes.number
}

export default React.memo(DetailsSection)
