// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useTranslation } from 'react-i18next'
import { CircleInformation as ErrorStateIcon } from 'grommet-icons'

import { Loader, Widget, NoDataInfo } from '../../../../components'
import { get } from '../../../../utils/api-utils'

const AssetsByWorkLoad = (props) => {
  const url = `/internal-assets/v1beta1/workload-summary`
  const { t } = useTranslation(['manage'])
  const { oidcUser } = useReactOidc()
  const [isLoading, setIsLoading] = useState(false)
  const [assetByWorkloadsGroupData, setAssetByWorkloadsGroupData] = useState([])
  const [noWorkloadData, setNoWorkloadData] = useState(false)

  const transformWorkloadDataToWidgetData = (workloadData) => {
    const summaryItems = workloadData?.items?.map((workload) => ({
      label: workload?.type,
      value: workload?.total,
      summary: false
    }))
    summaryItems?.push({
      label: t('asset_management.widgets.workload_summary.workloads'),
      value: workloadData?.totalWorkloads,
      summary: true
    })

    return summaryItems || []
  }

  const getAssetByWorkloadsGroupData = () => {
    setIsLoading(true)
    get(url, {}, oidcUser?.access_token)?.then(
      (response) => {
        setIsLoading(false)
        setAssetByWorkloadsGroupData(response?.data)
        setNoWorkloadData(false)
      },
      (error) => {
        setIsLoading(false)
        setNoWorkloadData(true)
        console.error('Failed to fetch asset workload data:', error)
      }
    )
  }

  useEffect(() => {
    getAssetByWorkloadsGroupData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, oidcUser?.access_token])

  const renderWorkloadWidget = () => {
    return !noWorkloadData ? (
      <Widget
        {...props}
        data={transformWorkloadDataToWidgetData(assetByWorkloadsGroupData)}
      />
    ) : (
      <Widget {...props} chartType="">
        <NoDataInfo
          gap="small"
          gapTitleSubtitle={false}
          icon={<ErrorStateIcon size="large" color="text-weak" />}
          title={t('asset_management.widgets.workload_summary.failed_to_load')}
          subtitle={t('asset_management.widgets.workload_summary.try_refresh')}
        />
      </Widget>
    )
  }

  return isLoading ? (
    <Loader testId="workload-loader" isLoading={isLoading} />
  ) : (
    renderWorkloadWidget()
  )
}

export default AssetsByWorkLoad
