// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { Previous } from 'grommet-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import dayjs from 'dayjs'

import { Button, Loader, Typography } from '../../../../../../components'
import { useCCSManagerContext } from '../../../../../../context/ccs-manager-context'
import { get } from '../../../../../../utils/api-utils'
import { displayApiError } from '../../../../../../utils/error-handling-utils'

import { RRPUsers } from './components'

const RRPDetailsPage = () => {
  const { t } = useTranslation(['manage', 'common', 'authz'])
  const navigate = useNavigate()
  const { rrpId } = useParams()
  const { oidcUser } = useReactOidc()
  const { selectedCustomer: customerData } = useCCSManagerContext()

  const [rrpDetails, setRRPDetails] = useState(null)
  const [showNotification, setShowNotification] = useState(null)
  const [loading, setLoading] = useState(true)

  const handleBackBtnClick = () => {
    navigate('/manage-ccs/customers/customer-details', {
      state: { tabName: 'resource-restrictions' }
    })
  }

  useEffect(() => {
    setLoading(true)
    const url = `/support-assistant/v1alpha1/resource-restrictions-detail/${rrpId}`
    get(url, {}, oidcUser.access_token).then(
      (resp) => {
        setRRPDetails(resp?.data)
        setShowNotification(null)
        setLoading(false)
      },
      (error) => {
        setShowNotification(displayApiError(error, t))
        setLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rrpId, oidcUser?.access_token])

  const getDetailField = (label, value, valueKey) => {
    if (typeof value === 'string' && value.trim() === '') {
      value = '--'
    }
    if (value && ['created_at', 'updated_at'].includes(valueKey)) {
      value = dayjs(value).format('MMMM DD, YYYY')
    }
    return (
      <Box margin={{ vertical: 'small' }} key={valueKey} gap="xsmall">
        <Typography type="text" size="xsmall" testId={valueKey}>
          {label}
        </Typography>
        <Typography type="text" size="medium" testId={`${valueKey}-value`}>
          {value || '--'}
        </Typography>
      </Box>
    )
  }

  const getScopeGroupDetail = (data) => {
    const name =
      data?.name === 'generated_via_migration' ? data?.slug : data?.name
    return (
      <Box margin={{ vertical: 'small' }} key={`${data?.slug}-key`}>
        <Typography
          type="text"
          size="medium"
          weight="bold"
          testId={`scope-${data?.slug}`}
        >
          {name}
        </Typography>
        <Typography type="text" size="medium" testId={`type-${data?.slug}`}>
          {data?.scope_type_name || data?.type || '--'}
        </Typography>
      </Box>
    )
  }

  const details = [
    {
      label: t('authz:name'),
      valueKey: 'name'
    },
    {
      label: t('authz:description'),
      valueKey: 'description'
    },
    {
      label: t('authz:application'),
      valueKey: 'application_name'
    },
    {
      label: t('authz:date_created'),
      valueKey: 'created_at'
    },
    {
      label: t('authz:last_updated'),
      valueKey: 'updated_at'
    }
  ]

  return (
    <Box>
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={`${t('customer_details_heading')} - ${customerData?.name}`}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="rrp-details-page-back-btn"
        />
      </Box>
      <Box alignSelf="center" width="large" margin={{ vertical: 'medium' }}>
        <Box justify="start" pad={{ bottom: 'large' }}>
          {rrpDetails && (
            <Typography level="1" type="heading" testId="page-title">
              {rrpDetails?.name}
            </Typography>
          )}
        </Box>
        {loading ? (
          <Loader testId="rrp-details-loader" />
        ) : (
          <>
            <Box
              justify="start"
              pad={{ bottom: 'medium' }}
              border={{ side: 'bottom', color: 'border-weak' }}
              margin={{ bottom: 'medium' }}
            >
              <Typography level="2" type="heading" testId="details-heading">
                {t('details')}
              </Typography>
            </Box>
            {rrpDetails &&
              details.map((detail) =>
                getDetailField(
                  detail.label,
                  rrpDetails[detail.valueKey],
                  detail.valueKey
                )
              )}
            <Box
              justify="start"
              pad={{ bottom: 'medium' }}
              border={{ side: 'bottom', color: 'border-weak' }}
              margin={{ vertical: 'medium' }}
            >
              <Typography level="2" type="heading" testId="resources-heading">
                {t('authz:resources')}
              </Typography>
            </Box>
            {rrpDetails?.scope_resource_instances?.map((data) =>
              getScopeGroupDetail(data)
            )}
            <Box
              justify="start"
              pad={{ bottom: 'medium' }}
              border={{ side: 'bottom', color: 'border-weak' }}
              margin={{ vertical: 'medium' }}
            >
              <Typography level="2" type="heading" testId="users-heading">
                {t('authz:users_title')}
              </Typography>
            </Box>
            <RRPUsers customerId={customerData?.id} rrpId={rrpId} />
          </>
        )}
      </Box>
      {showNotification}
    </Box>
  )
}

export { RRPDetailsPage }
