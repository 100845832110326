// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../commoncomponents/LazyLoading/LazyLoading'

const ReportDownloadsPage = lazy(() => import('./ReportDownloads'))

const ReportsRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<ReportDownloadsPage />} />
      </Routes>
    </Suspense>
  )
}

export default ReportsRouter
