// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import {
  buildSearchEngine,
  getOrganizationEndpoints
} from '@glcp/coveo-headless'

import { getEnvConfig } from '../config/env-config'
import { post } from '../../../utils/api-utils'

// Method for retrieving the token
const getSearchToken = async (access_token) => {
  const url = '/platform-support-frontend-service/v1/coveo/token'
  const response = await post(url, {}, access_token)
  return response.data.token
}
export const searchEngine = async (user_access_token) => {
  const coveoAccessToken = await getSearchToken(user_access_token)

  return buildSearchEngine({
    configuration: {
      organizationId: getEnvConfig().COVEO_ORG_ID,
      accessToken: coveoAccessToken,
      renewAccessToken: coveoAccessToken,
      organizationEndpoints: getOrganizationEndpoints(
        getEnvConfig().COVEO_ORG_ID
      )
    }
  })
}
