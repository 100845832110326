// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import {
  Organization,
  UserManager,
  Group,
  Search,
  BusinessService
} from 'grommet-icons'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Card, Tooltip, Typography } from '../../components'
import { useCCSManagerContext } from '../../context/ccs-manager-context'
import {
  useVisibilityContext,
  VisibilityActions
} from '../../context/visibility-context'
import { getCustomerAccount } from '../../utils/feature-flag-utils'
import { AccountLogo } from '../../commoncomponents/account-logo/AccountLogo'
import SwitchAccountButton from '../../commoncomponents/SwitchAccountButton/SwitchAccountButton'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import AccountDetailsInfo from '../../commoncomponents/account-details/AccountDetailsInfo'
import { useUPSContext } from '../../context/ups-context'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../utils/common-utils'
import { CheckResourcePermissions } from '../../utils/ccs-manager-utils'

import {
  getSamPermissionForWorkspaceAccess,
  samRbac,
  samRoles,
  rolesToFilter
} from './utils'

const CCSManagerHomePage = () => {
  const { t } = useTranslation(['common', 'manage'])
  const navigate = useNavigate()
  const custAccountLoaded = getCustomerAccount()
  const { userCCSRoles } = useCCSManagerContext()
  const { rbacPolicies, dispatchVisibilityContext } = useVisibilityContext()
  const LDFlags = useFlags()
  const mvSvsToSaHomeLD = LDFlags['glcp-sa-mv-svcs']
  const showCCSManagerTile = (() => {
    let showTile = false
    const newRoles = mvSvsToSaHomeLD ? rolesToFilter : samRoles
    const userCCSRolesWithoutSamRoles = userCCSRoles?.filter((role) => {
      return !newRoles.includes(role)
    })
    if (userCCSRolesWithoutSamRoles?.length > 0) {
      showTile = true
    } else {
      showTile = false
    }

    return showTile
  })()

  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const saCustomRoleLDFlag = LDFlags['glcp-istanbul-sa-custom-role-flag']
  const samLDFlag = LDFlags['glcp-support-access-manager']
  const searchAdminLDFlag = LDFlags['glcp-search-administration']
  const { upsDataResolved } = useUPSContext()

  const samPermissionForWorkspaceAccess = getSamPermissionForWorkspaceAccess(
    rbacPolicies?.effects?.[samRbac.resource]
  )
  let showSAMTile = false
  if (samPermissionForWorkspaceAccess.length > 0 && rbacPolicies) {
    showSAMTile = Boolean(
      rbacPolicies?.effects?.[samRbac.resource][samPermissionForWorkspaceAccess]
    )
  }

  useEffect(() => {
    if (
      LDFlags['glcp-support-access-manager'] &&
      rbacPolicies &&
      upsDataResolved
    ) {
      const isSupportEngineer = CheckResourcePermissions(
        {
          '/ccs/workspace-access-se': ['ccs.workspace-access-se.view']
        },
        rbacPolicies
      )
      dispatchVisibilityContext({
        type: VisibilityActions.SET_IS_SUPPORT_ENGINEER,
        data: isSupportEngineer
      })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LDFlags, upsDataResolved])

  const getRouteBasedOnRole = (policies) => {
    let showNotificationTab = false
    let showCustomersTab = false
    let showUsersTab = false
    let showActivateTabs = false
    let showFirmwareTab = false
    let showApplicationsTab = false
    let showWhatsNewTab = false
    if (policies && Object.keys(policies?.effects).length > 0) {
      const notificationsRbac = {
        permission: 'ccs.notification.edit',
        resource: '/ccs/notification'
      }
      const customersRbac = {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.view'
      }

      const usersRbac = {
        permission: 'ccs.accounts.users.view.all',
        resource: '/ccs/accounts/user'
      }

      const activateRbac = {
        resource: '/ccs/device-management',
        permission: 'ccs.device-management.view'
      }

      const firmwareRbac = {
        resource: '/ccs/activate/firmware',
        permission: 'ccs.activate.firmware.view'
      }

      const applicationsRbac = {
        resource: '/ccs/app-catalog/application',
        permission: 'ccs.app-catalog.view'
      }

      const whatsNewRbac = {
        resource: '/ccs/whats-new',
        permission: 'ccs.whats-new.view'
      }

      showNotificationTab =
        policies?.effects?.[notificationsRbac.resource]?.[
          notificationsRbac.permission
        ]

      showCustomersTab =
        policies?.effects?.[customersRbac.resource]?.[customersRbac.permission]

      showUsersTab =
        policies?.effects?.[usersRbac.resource]?.[usersRbac.permission]

      showActivateTabs =
        policies?.effects?.[activateRbac.resource]?.[activateRbac.permission]

      showFirmwareTab =
        policies?.effects?.[firmwareRbac.resource]?.[firmwareRbac.permission]

      showApplicationsTab =
        policies?.effects?.[applicationsRbac.resource]?.[
          applicationsRbac.permission
        ]

      showWhatsNewTab =
        policies?.effects?.[whatsNewRbac.resource]?.[whatsNewRbac.permission]
    }

    let route = '/manage-ccs/notifications'
    if (showNotificationTab) {
      route = '/manage-ccs/notifications'
    } else if (showCustomersTab) {
      route = '/manage-ccs/customers'
    } else if (showUsersTab) {
      route = '/manage-ccs/users'
    } else if (showActivateTabs) {
      route = '/manage-ccs/devices'
    } else if (showFirmwareTab) {
      route = '/manage-ccs/firmware'
    } else if (showApplicationsTab) {
      route = '/manage-ccs/applications'
    } else if (showWhatsNewTab) {
      route = '/manage-ccs/whats-new'
    }

    return route
  }

  const quickActionsList = [
    {
      id: 1,
      icon: <Organization size="medium" />,
      title: (
        <Tooltip
          dropProps={{ align: { bottom: 'top' } }}
          info={t('common:manage_account.account_details_title', {
            account: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_CAPITALIZED
            )
          })}
          testId="account-details-title"
        >
          {t('common:manage_account.account_details_title', {
            account: getWorkspaceString(
              showWorkspaceString,
              t,
              WKSPC_CAPITALIZED
            )
          })}
        </Tooltip>
      ),
      description: (
        <Tooltip
          dropProps={{ align: { bottom: 'top' } }}
          info={t('common:manage_account.account_details_subtitle', {
            account: getWorkspaceString(showWorkspaceString, t, WKSPC)
          })}
          testId="account-details-subtitle"
        >
          {t('common:manage_account.account_details_subtitle', {
            account: getWorkspaceString(showWorkspaceString, t, WKSPC)
          })}
        </Tooltip>
      ),
      routeTo: '/manage-account/account-details',
      testId: 'account_details',
      rbac: {
        resource: '/ccs/accounts/platform/customer',
        permission: 'ccs.accounts.platform.customer.view'
      }
    },
    {
      id: 2,
      icon: <UserManager size="medium" />,
      title: (
        <Tooltip
          dropProps={{ align: { bottom: 'top' } }}
          info={t('common:manage_account.ccs_manager_title')}
          testId="ccs-manager-title"
        >
          {t('common:manage_account.ccs_manager_title')}
        </Tooltip>
      ),
      description: (
        <Tooltip
          dropProps={{ align: { bottom: 'top' } }}
          info={t('common:manage_account.ccs_manager_subtitle')}
          testId="ccs-manager-subtitle"
        >
          {t('common:manage_account.ccs_manager_subtitle')}
        </Tooltip>
      ),
      routeTo: getRouteBasedOnRole(rbacPolicies),
      testId: 'manage-ccs',
      isHidden: !showCCSManagerTile
    },

    ...(saCustomRoleLDFlag
      ? [
          {
            id: 3,
            icon: <Group size="medium" />,
            title: t('common:manage_account.identity_title'),
            description: t('common:manage_account.identity_subtitle'),
            routeTo: '/manage-ccs/identity',
            testId: 'identity',
            isHidden: !showCCSManagerTile,
            rbac: {
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.edit'
            }
          }
        ]
      : []),
    ...(samLDFlag && showSAMTile
      ? [
          {
            id: 4,
            icon: <Group size="medium" />,
            title: t('common:manage_account.sa_sam_card_title'),
            description: t('common:manage_account.sa_sam_card_subtitle'),
            routeTo: '/manage-ccs/support-access',
            testId: 'support-access'
          }
        ]
      : []),
    ...(searchAdminLDFlag
      ? [
          {
            id: 5,
            icon: <Search size="medium" />,
            title: t('manage:global_search_administration.page_title'),
            description: t('manage:global_search_administration.page_subtitle'),
            routeTo: '/manage-ccs/search-administration',
            testId: 'global-search-administration',
            rbac: {
              resource: '/ccs/global-search/administration',
              permission: 'ccs.global-search-administration.view'
            }
          }
        ]
      : []),
    ...(mvSvsToSaHomeLD
      ? [
          {
            id: 6,
            icon: <BusinessService />,
            title: (
              <Typography type="text" weight="bold" size="medium">
                {t('manage:whats_new.title')}
              </Typography>
            ),
            description: t('manage:whats_new.page_desc'),
            routeTo: '/manage-ccs/whats-new',
            testId: 'whatsnew-link',
            rbac: {
              resource: '/ccs/whats-new',
              permission: 'ccs.whats-new.view'
            }
          }
        ]
      : [])
  ]

  const handleAccountCardClick = (currentItem) => {
    navigate(currentItem?.routeTo)
  }

  return (
    <Box
      data-testid="home"
      alignSelf="center"
      gap="large"
      pad="medium"
      width={{ max: 'xxlarge', width: '100%' }}
    >
      <Box justify="between" align="center" direction="row-responsive">
        <Box direction="row" gap="medium" align="center">
          {custAccountLoaded && (
            <>
              <AccountLogo
                account={custAccountLoaded}
                title={custAccountLoaded.company_name}
                logoSize="xsmall"
              />
              <Typography
                type="heading"
                testId="heading-home"
                margin={{ left: 'small' }}
              >
                {custAccountLoaded.company_name}
              </Typography>
            </>
          )}
        </Box>
        <SwitchAccountButton />
      </Box>
      <Typography type="heading" level="2" testId="quick_actions">
        {t('dashboard.quick_actions_card.title')}
      </Typography>
      <Box direction="row-responsive" gap="medium" pad="xsmall">
        <Box width={{ min: 'medium' }}>
          <Card
            background=""
            cardWidth="medium"
            testId="account-details"
            CustomContent={<AccountDetailsInfo />}
          />
        </Box>
        <Box direction="row" wrap>
          {quickActionsList.map((item) =>
            !item.isHidden ? (
              <VisibilityWrapper
                key={item.id}
                hideFor={item.hideFor}
                rbac={item.rbac}
              >
                <Card
                  key={item.id}
                  background=""
                  cardWidth="medium"
                  description={item.description}
                  descriptionEllipsis
                  icon={item.icon}
                  testId={item.testId}
                  title={item.title}
                  titleEllipsis
                  titleSize="medium"
                  margin={{ top: 'medium', right: 'medium' }}
                  height="small"
                  onClick={() => handleAccountCardClick(item)}
                />
              </VisibilityWrapper>
            ) : null
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CCSManagerHomePage
