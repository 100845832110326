// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { PropTypes } from 'prop-types'

import { Typography } from '../../../../components'

export const SectionTitle = ({ title }) => {
  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      border="bottom"
      margin={{ bottom: 'small' }}
      pad={{ top: 'small' }}
    >
      <Typography
        level="3"
        type="heading"
        weight="normal"
        testId={`location-details-section-${title}`}
        margin={{ bottom: 'xsmall' }}
      >
        {title}
      </Typography>
    </Box>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired
}
