// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import dayjs from 'dayjs'

import { Typography } from '../../../../../components'

const CustomTooltip = ({
  active = false,
  payload = [],
  label = 0,
  yAxisUnit = '',
  tooltipText = '',
  toolTipFormat = 'MMM D, YYYY'
}) => {
  if (active && payload?.length) {
    let { value } = payload[0]
    if ((value * 10) % 10 > 0) {
      value = value.toFixed(2)
    }
    return (
      <Box
        margin={{ bottom: 'small' }}
        background="background"
        pad="xsmall"
        boxShadow="medium"
        elevation="medium"
        width={{ min: 'xsmall' }}
        round="xsmall"
      >
        <Typography type="text" size="xsmall" testId="tooltip-date">
          {dayjs.utc(label).local().format(toolTipFormat)}
        </Typography>
        <Box direction="row" gap="xsmall">
          <Typography
            type="text"
            size="xsmall"
            testId="tooltip-unit"
            weight="bold"
          >{`${value} ${yAxisUnit}`}</Typography>
          <Typography type="text" size="xsmall" testId="tooltip-text">
            {tooltipText}
          </Typography>
        </Box>
      </Box>
    )
  }

  return null
}
export default CustomTooltip

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yAxisUnit: PropTypes.string,
  tooltipText: PropTypes.string,
  toolTipFormat: PropTypes.string
}
