// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import dayjs from 'dayjs'

import { Notification } from '../../../../components'
import { calculateExpirationTs, isItWithin30days } from '../../utils'

const ValidationStatusBox = ({
  validated,
  validated_at = null,
  validation_expired,
  validation_cycle = '',
  expired_at = null,
  locationName
}) => {
  const { t } = useTranslation(['location'])

  const [validationStatus, setValidationStatus] = useState('info')
  const [statusMsg, setStatusMsg] = useState('')

  useEffect(() => {
    if (validated && !isItWithin30days(validated_at, validation_cycle)) {
      setValidationStatus('normal')
      setStatusMsg(
        t('validation_status_msg_active', {
          expiration_date: dayjs(
            calculateExpirationTs(validated_at, validation_cycle)
          ).format('MM/DD/YYYY')
        })
      )
    }

    if (validated && isItWithin30days(validated_at, validation_cycle)) {
      setValidationStatus('warning')
      setStatusMsg(
        t('validation_status_msg_expiring_soon', {
          expiration_date: dayjs(
            calculateExpirationTs(validated_at, validation_cycle)
          ).format('MM/DD/YYYY')
        })
      )
    }

    if (validation_expired) {
      setValidationStatus('critical')
      setStatusMsg(
        t('validation_status_msg_expired', {
          expiration_date: dayjs(expired_at).format('MM/DD/YYYY')
        })
      )
    }
  }, [
    expired_at,
    locationName,
    t,
    validated,
    validated_at,
    validation_cycle,
    validation_expired
  ])

  return (
    <Box margin={{ top: 'small' }}>
      <Notification
        status={validationStatus}
        testId="location-validation-status"
        text={
          <>
            <Trans>{statusMsg}</Trans>
            {validationStatus !== 'normal' && (
              <li>
                <Trans>
                  {t('validation_status_revalidate_msg', {
                    locationName
                  })}
                </Trans>
              </li>
            )}
          </>
        }
        type="inline"
      />
    </Box>
  )
}

ValidationStatusBox.propTypes = {
  validated: PropTypes.bool.isRequired,
  validated_at: PropTypes.string,
  validation_expired: PropTypes.bool.isRequired,
  expired_at: PropTypes.string,
  validation_cycle: PropTypes.string,
  locationName: PropTypes.string.isRequired
}

export { ValidationStatusBox }
