// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from 'grommet'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import { Layout } from '../../../commoncomponents/layout/Layout'
import { Wizard, Typography } from '../../../components'
import { get } from '../../../utils/api-utils'

import { defaultGatewayDetails } from './utils'
import RegisterGateway from './RegisterGateway'

const RegistrationWizard = () => {
  const { t } = useTranslation(['local-gateway'])
  const navigate = useNavigate()
  const { gatewayId } = useParams()
  const [details, setDetails] = useState({})
  const { oidcUser } = useReactOidc()

  useEffect(() => {
    get(
      `/platform/acpmgr/gateway/${gatewayId}`,
      {},
      oidcUser.access_token
    ).then((response) => {
      if (response.data) {
        setDetails(response.data)
      }
    })
  }, [gatewayId, oidcUser])

  const handleSubmitClick = () => navigate('/manage-account/local-gateway')

  return (
    <Layout>
      <Box fill>
        <Wizard
          title={t('wizard_title')}
          buttonLabels={{
            finish: t('registration_wizard_complete_button_text')
          }}
          formDefaultValues={{
            ...defaultGatewayDetails
          }}
          actionOnExit={() => navigate(-1)}
          actionOnSubmit={() => handleSubmitClick()}
          cancelStrings={{
            continueLabel: t('step_continue_label'),
            exitLabel: t('step_exit_label'),
            heading: t('step_heading'),
            text: t('step_cancel_text')
          }}
          steps={[
            {
              childComponents: <RegisterGateway />,
              description: (
                <Box pad={{ bottom: 'medium' }}>
                  <Typography
                    type="text"
                    size="large"
                    testId="register_gateway_description"
                  >
                    <Trans
                      i18nKey="register_gateway_description"
                      t={t}
                      values={{
                        gatewayName: details.name || t('the_gateway')
                      }}
                      components={details.name && [<strong />]}
                    />
                  </Typography>
                </Box>
              ),
              title: t('next_steps_title'),
              disableBack: true,
              disableCancel: true,
              disableStepCount: true
            }
          ]}
          testId="review-devices-wizard"
        />
      </Box>
    </Layout>
  )
}
export default RegistrationWizard
