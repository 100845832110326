// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Alert } from 'grommet-icons'

import { Button, Typography, NoDataInfo } from '../../components'
import { Layout } from '../../commoncomponents/layout/Layout'

const Error404 = () => {
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  console.log('render 404')
  return (
    <Box align="center">
      <Box width="large" pad="large">
        <NoDataInfo
          icon={<Alert color="status-warning" />}
          title={
            <Typography type="heading" level="1">
              {t('error404.page_not_found')}
            </Typography>
          }
          subtitle={
            <Box align="center" pad={{ left: 'medium', right: 'medium' }}>
              <Typography
                type="text"
                size="small"
                testId="page-not-found-subtitle"
                margin={{ bottom: 'small' }}
                textAlign="center"
              >
                {t('error404.bad_resource')}
              </Typography>
              <Typography
                type="text"
                size="small"
                testId="second-no-access-subtitle"
                margin={{ bottom: 'small' }}
              >
                {t('error404.file_not_found')}
              </Typography>
            </Box>
          }
          action={
            <Button
              primary
              label={t('error404.return_to_home')}
              size="medium"
              onClick={() => {
                navigate('/home')
              }}
              testId="back-to-home"
            />
          }
          testId="page-not-found"
        />
      </Box>
    </Box>
  )
}

const Error404Page = () => {
  return (
    <Layout>
      <Error404 />
    </Layout>
  )
}

export default Error404Page
