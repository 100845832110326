// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react'
import { Box, Button, TextInput } from 'grommet'
import PropTypes from 'prop-types'
import { Hide, View } from 'grommet-icons'

const PasswordInput = ({ testId, ...rest }) => {
  const { width, pad, margin, disabled, ...inputProps } = { ...rest }
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => {
    setShowPassword((prevShowPwd) => !prevShowPwd)
  }
  return (
    <Box pad={pad} margin={margin} width={width} direction="row" align="center">
      <TextInput
        data-testid={`${testId}-input`}
        type={showPassword ? 'text' : 'password'}
        disabled={disabled}
        plain
        focusIndicator={false}
        {...inputProps}
      />
      <Button
        disabled={disabled}
        data-testid={`${testId}-show-password-toggle-button`}
        pad="xsmall"
        icon={
          showPassword ? (
            <View size="medium" data-testid={`${testId}-show-password-icon`} />
          ) : (
            <Hide size="medium" data-testid={`${testId}-hide-password-icon`} />
          )
        }
        onClick={toggleShowPassword}
      />
    </Box>
  )
}

PasswordInput.propTypes = {
  testId: PropTypes.string.isRequired
}

export default PasswordInput
