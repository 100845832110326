// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Button, ResponsiveContext, Anchor } from 'grommet'
import { ShareRounded, CircleAlert } from 'grommet-icons'
import { Trans, useTranslation } from 'react-i18next'
import { useContext, useEffect, useState, useCallback } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'

import {
  Typography,
  Layout,
  useParams,
  Tabs,
  VisibilityWrapper,
  PageHeader,
  Notification
} from '../../../../dashboard/shims/imports'
import { displayApiError } from '../../../../utils/error-handling-utils'
import {
  useAxiosAuth,
  useRecentServices,
  useNewServiceRedirect as useServiceRedirect,
  usePollServiceDetail,
  useRegions
} from '../../../../dashboard/hooks'
import {
  NewServiceLaunchModal as ServiceLaunchModal,
  NewProvisionModal as ProvisionModal,
  RequestEvalModal,
  EvalButtonGroup,
  RequestEvalStatusText
} from '../../../../dashboard/components'
import { GLC, EVAL_STATES, EVAL_LABEL } from '../../../../dashboard/constants'
import { SERVICE_PROVISION_FEATURE } from '../../../../utils/service-utils'
import { isMSP } from '../../../../utils/feature-flag-utils'
import { Loader } from '../../../../components'

import OverviewTab from './OverviewTab'
import RegionsTab from './RegionsTab'
import ServiceManagerTab from './ServiceManagerTab'
import StaticLaunchButton from './StaticLaunchButton'
import ProvisionButton from './ProvisionButton'
import BackButton from './BackButton'

const getProvisionButtonRbac = (isServiceProvision) => ({
  resource: isServiceProvision
    ? '/ccs/service-catalog/service-provision'
    : '/ccs/app-provision/provisions',
  permission: isServiceProvision
    ? 'ccs.service-catalog.edit'
    : 'ccs.app-provision.edit'
})

const getBoxDirection = (isLargeScreen) => (isLargeScreen ? 'row' : 'column')
const getBoxAlign = (isLargeScreen) => (isLargeScreen ? 'center' : 'start')

const NewServiceDetail = () => {
  const axios = useAxiosAuth()
  const { id, fromServiceSubs } = useParams()
  const navigate = useNavigate()

  const [showLaunchModal, setShowLaunchModal] = useState(false)
  const [showProvisionModal, setShowProvisionModal] = useState(false)
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const [serviceDetailData, setServiceDetailData] = useState({})
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(Boolean(fromServiceSubs))
  )
  const [isLoading, setIsLoading] = useState(true)
  // Todo: handle this regionlist fetch in re-infra
  // no api or codes added for context api to avoid duplication
  useRegions()

  // Request Eval
  const [evalModalFormOpen, setEvalModalFormOpen] = useState(false)
  const [requestEvalStatus, setRequestEvalStatus] = useState({})
  const [successEval, setSuccessEval] = useState(false)
  const [hasEval, setHasEval] = useState(false)
  const [evalServiceData, setEvalServiceData] = useState({})
  const [evalUrl, setEvalUrl] = useState('')
  const [configureDeviceNotification, setConfigureDeviceNotification] =
    useState(false)
  const LDFlags = useFlags()
  const evalFlag = LDFlags['glcp-evaluation-service']
  const dynamicEvalFlag = LDFlags['glcp-dynamic-eval-enablement']
  const serviceDetailFlag = LDFlags['glcp-jhansi-service-detail-page-update']
  const { t } = useTranslation([
    'apps',
    'common',
    'dashboard',
    'services',
    'device'
  ])
  const [formLoader, setFormLoader] = useState(false)
  const [evalFieldsMetaData, setEvalFieldsMetaData] = useState({})
  const [, setRecentServices] = useRecentServices()
  const serviceRedirect = useServiceRedirect()
  const screenSize = useContext(ResponsiveContext)
  const isLargeScreen = ['large', 'xlarge'].includes(screenSize)

  const serviceOffer = serviceDetailData?.service_offer || {}
  const serviceManager = serviceDetailData?.service_manager || {}
  const availableRegions = serviceDetailData?.available_regions || []
  const {
    is_service_manager: isServiceManager,
    name: serviceName,
    slug: serviceSlug,
    categories: serviceCategories = [],
    features_supported: featuresSupported = [],
    test_drive_url: testDriveUrl,
    static_launch_url: staticLaunchUrl,
    contact_sales_url: contactSalesUrl,
    documentation_url: serviceDocumentationUrl,
    eval_url: serviceEvalUrl,
    workspace_types: workspaceTypes = []
  } = serviceOffer
  const { slug: serviceManagerSlug, name: serviceManagerName } = serviceManager
  const dmEnableDefaultRegion = LDFlags['glcp-dm-enable-default-region']

  const isServiceProvision =
    featuresSupported?.includes(SERVICE_PROVISION_FEATURE) &&
    LDFlags['glcp-service-provision']

  const isProvisionSupported =
    !featuresSupported?.includes('SKIP_SERVICE_MGR_PROVISIONING') ||
    isServiceProvision

  const isTenantOnlySupported =
    workspaceTypes?.includes('TENANT') && !workspaceTypes?.includes('MSP')

  const provisions =
    serviceDetailData?.provisions
      ?.filter(({ application_provision, service_provision }) =>
        isServiceProvision ? service_provision : application_provision
      )
      ?.map(({ application_provision, service_provision, name, slug }) => ({
        ...(isServiceProvision ? service_provision : application_provision),
        name,
        slug
      })) || []

  const provisionedInstances = provisions.filter(
    ({ provision_status }) => provision_status === 'PROVISIONED'
  )

  const regionsCanBeProvisioned =
    serviceDetailData.available_regions?.filter(
      (availableRegion) =>
        !provisions
          .filter(
            ({ provision_status }) =>
              ![
                'PROVISION_FAILED',
                'UNPROVISIONED',
                'UNPROVISION_FAILED'
              ].includes(provision_status)
          )
          ?.map(({ region }) => region)
          ?.includes(availableRegion.code)
    ) || []

  const setPageData = useCallback(() => {
    axios
      .get(`/service-catalog/v1alpha1/detailed-service-offers/${id}`)
      .then(
        ({ data }) => {
          setServiceDetailData(data)
        },
        (error) => {
          if (error.status === 404) {
            navigate('/services/service-catalog')
          }
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [axios, id, navigate, t])

  // move to new file
  const getDaysLeft = () => {
    const currentDate = new Date()
    const endDate = Date.parse(requestEvalStatus.endDate)
    const timeDifference = endDate - currentDate
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
  }

  const padResponsive = {
    xlarge: 'large',
    large: 'medium',
    medium: 'medium',
    small: 'large',
    xsmall: 'large'
  }

  useEffect(setPageData, [setPageData])

  const [countries, setCountries] = useState([])
  const [listOfCountries, setListOfCountries] = useState([])
  useEffect(() => {
    axios.get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setCountries(response.data.countries)
        setListOfCountries(response.data.countries)
      },
      (err) => {
        setShowErrorNotification(
          displayApiError(err, t, setShowErrorNotification)
        )
      }
    )
  }, [t, axios])

  // Eval Get Request
  const findEvalResponse = (evalResponse, slug) => {
    const evalObject = evalResponse.find((item) => item.service === slug) || {
      status: ''
    }
    return evalObject
  }

  const fetchEvalStatus = () => {
    axios
      .get('/ui-doorway/ui/v1/license/eval')
      .then((response) => {
        const evalResponse = response?.data
        const evalObject = findEvalResponse(evalResponse, serviceSlug)
        setRequestEvalStatus(evalObject)
        setEvalServiceData({
          name: serviceName,
          documentationUrl: serviceDocumentationUrl,
          regions: availableRegions,
          slug: serviceSlug
        })
        setEvalUrl(serviceEvalUrl)
        setHasEval(true)
      })
      .catch((err) => {
        setShowErrorNotification(
          displayApiError(err, t, setShowErrorNotification)
        )
      })
  }

  const getEvalData = () => {
    setFormLoader(true)
    axios
      .get(`/billing/v1beta1/catalog?service=${serviceSlug}`)
      .then((response) => {
        setHasEval(true)
        // add the region options.
        let regionOptions = availableRegions
        regionOptions = regionOptions.map((region) => ({
          name: region.name,
          code: region.code
        }))
        const metadata = response.data.metadata.marketplaceInfo
        metadata.options.find(
          (option) => option.name === 'region'
        ).allowedValues = regionOptions
        setEvalFieldsMetaData(metadata)
        setFormLoader(false)
        fetchEvalStatus()
      })
      .catch((err) => {
        setFormLoader(false)
        if (err.response?.status !== 404) {
          setShowErrorNotification(
            displayApiError(err, t, setShowErrorNotification)
          )
        }
      })
  }
  useEffect(() => {
    if (featuresSupported?.includes(EVAL_LABEL) && evalFlag) {
      if (dynamicEvalFlag) {
        getEvalData()
      } else {
        fetchEvalStatus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evalFlag, successEval, serviceSlug, dynamicEvalFlag, featuresSupported])

  usePollServiceDetail(setServiceDetailData, provisions, id, isServiceProvision)

  const closeModal = () => {
    setShowProvisionModal(false)
  }

  const isServiceManagerProvisioned = provisionedInstances.length >= 1

  // Disable regular launch button
  const disableLaunchButton =
    (isServiceManager && provisionedInstances.length === 0) ||
    Boolean(staticLaunchUrl) ||
    (!isServiceManager && !isServiceManagerProvisioned) ||
    (isMSP() && isTenantOnlySupported)

  const showStaticLaunch = Boolean(staticLaunchUrl)

  const isGLC = isServiceManager
    ? serviceSlug === GLC
    : serviceManagerSlug === GLC

  const showProvisioningSpinner = () =>
    provisionedInstances.length === 0 &&
    provisions.some(
      ({ provision_status: provisionStatus }) =>
        provisionStatus === 'PROVISION_INITIATED'
    )

  const tabs = [
    {
      id: 1,
      label: t('dashboard:service_detail.overview'),
      content: (
        <OverviewTab
          serviceDetailData={serviceDetailData}
          isServiceProvision={isServiceProvision}
          screenSize={screenSize}
          hasEval={hasEval}
          requestEvalStatus={requestEvalStatus}
          setEvalModalFormOpen={setEvalModalFormOpen}
          t={t}
        />
      ),
      testId: 'overview-tab'
    },
    ...(!isServiceManager &&
    !isServiceProvision &&
    !staticLaunchUrl &&
    serviceManagerSlug &&
    serviceManagerSlug !== 'OTHER'
      ? [
          {
            id: 2,
            label: t('dashboard:common.service_manager'),
            content: (
              <ServiceManagerTab
                serviceDetailData={serviceDetailData}
                setIsLoading={setIsLoading}
                setActiveTabIndex={setActiveTabIndex}
                navigate={navigate}
                t={t}
              />
            ),
            testId: 'service-manager-tab'
          }
        ]
      : []),
    ...(isServiceProvision || (isServiceManager && !staticLaunchUrl)
      ? [
          {
            id: 3,
            label: `${t('dashboard:common.regions')} (${
              provisions.filter(
                (provision) => provision.provision_status === 'PROVISIONED'
              ).length
            })`,
            content: (
              <RegionsTab
                serviceDetailData={serviceDetailData}
                provisions={provisions}
                regionsCanBeProvisioned={regionsCanBeProvisioned}
                setShowProvisionModal={setShowProvisionModal}
                setPageData={setPageData}
                isServiceProvision={isServiceProvision}
                t={t}
              />
            ),
            testId: 'regions-tab'
          }
        ]
      : [])
  ]

  return (
    <Layout>
      {successEval && (
        <Notification
          testId="eval-notification"
          status="normal"
          text={t('dashboard:eval.eval_success_notification', {
            cumulativeServiceName: serviceDetailData.service_offer.name
          })}
          onClose={() => setSuccessEval(false)}
        />
      )}
      {showErrorNotification !== null && showErrorNotification}
      <BackButton
        screenSize={screenSize}
        isLargeScreen={isLargeScreen}
        fromServiceSubs={fromServiceSubs}
        navigate={navigate}
        t={t}
      />
      {isLoading ? (
        <Box align="center" pad="small">
          <Loader testId="loader-spinner" />
        </Box>
      ) : (
        <Box align="center" pad={{ bottom: 'medium' }}>
          {showProvisionModal && (
            <ProvisionModal
              regionsCanBeProvisioned={regionsCanBeProvisioned}
              serviceDetailData={serviceDetailData}
              closeModal={closeModal}
              provisionedInstances={provisionedInstances}
              setPageData={setPageData}
              setShowErrorNotification={setShowErrorNotification}
              isServiceProvision={isServiceProvision}
              setConfigureDeviceNotification={setConfigureDeviceNotification}
              setActiveTabIndex={setActiveTabIndex}
            />
          )}
          {showLaunchModal && provisionedInstances.length !== 0 && (
            <ServiceLaunchModal
              provisions={serviceDetailData?.provisions}
              isServiceManager={isServiceManager}
              serviceManagerName={serviceManagerName}
              setShowModal={setShowLaunchModal}
              isServiceDetailLaunch
              isServiceProvision={isServiceProvision}
            />
          )}
          <Box width="xxlarge" pad={{ horizontal: padResponsive[screenSize] }}>
            <Box
              direction={getBoxDirection(isLargeScreen)}
              align={getBoxAlign(isLargeScreen)}
              justify="between"
              margin={{ bottom: 'medium' }}
              gap="medium"
            >
              <Box>
                <PageHeader
                  testId="service-details-main"
                  primaryHeader={serviceName}
                  subHeader={`
                ${t(`dashboard:common.category.${serviceCategories?.at(0)}`)}
                `}
                />
              </Box>
              <Box gap="small" direction="row" justify="between">
                {!serviceDetailFlag && (
                  <>
                    {contactSalesUrl && !hasEval && (
                      <Button
                        label={t('dashboard:service_detail.contact_sales')}
                        data-testid={`${serviceSlug}-service-detail-contact-sales-button`}
                        icon={<ShareRounded />}
                        reverse
                        href={contactSalesUrl}
                        target="_blank"
                      />
                    )}
                    {hasEval && (
                      <VisibilityWrapper
                        rbac={{
                          permission: 'ccs.orders.edit',
                          resource: '/ccs/orders'
                        }}
                      >
                        <Box direction="row">
                          <EvalButtonGroup
                            serviceSlug={serviceSlug}
                            evalStates={EVAL_STATES}
                            evalUrl={evalUrl}
                            requestEvalStatus={requestEvalStatus}
                            setEvalModalFormOpen={setEvalModalFormOpen}
                          />
                        </Box>
                      </VisibilityWrapper>
                    )}
                  </>
                )}
                {testDriveUrl && provisionedInstances.length === 0 && (
                  <Button
                    as="a"
                    secondary
                    label={t('dashboard:service_detail.test_drive')}
                    href={testDriveUrl}
                    target="_blank"
                    data-testid={`${serviceSlug}-service-detail-test-drive-button`}
                  />
                )}
                <VisibilityWrapper
                  rbac={getProvisionButtonRbac(isServiceProvision)}
                >
                  {isProvisionSupported &&
                    // If there's any provision with provision_status excluding 'UNPROVISION_FAILED' and 'PROVISION_FAILED' then hide the provision button
                    provisions.filter(
                      (instance) =>
                        instance?.provision_status &&
                        !['UNPROVISION_FAILED', 'PROVISION_FAILED'].includes(
                          instance?.provision_status
                        )
                    ).length === 0 &&
                    regionsCanBeProvisioned.length !== 0 && (
                      <ProvisionButton
                        isServiceManager={isServiceManager}
                        isServiceProvision={isServiceProvision}
                        serviceSlug={serviceSlug}
                        setShowProvisionModal={setShowProvisionModal}
                        isTenantOnlySupported={isTenantOnlySupported}
                        t={t}
                      />
                    )}
                  {regionsCanBeProvisioned.length !== 0 &&
                    provisionedInstances.length >= 1 &&
                    isProvisionSupported && (
                      <Box>
                        <Button
                          label={t('dashboard:service_details.add_region')}
                          data-testid={`${serviceDetailData?.service_offer?.slug}-service-detail-add-region-button`}
                          onClick={() => setShowProvisionModal(true)}
                          primary
                          testId="add-region-button"
                        />
                      </Box>
                    )}
                  {!isGLC && showProvisioningSpinner() && (
                    <Box direction="row" gap="small" align="center">
                      <Typography
                        type="text"
                        testId="provisioning-spinner-text"
                      >
                        {t('dashboard:service_details.provisioning')}
                      </Typography>
                      <Loader testId="provisioning-spinner-loader" />
                    </Box>
                  )}
                </VisibilityWrapper>
                {/* Adding icon check for wellness as external launch icon is not needed for that service */}
                {/* Adding target check for wellness as new tab launch is not needed for that service */}
                {/* TO-DO code cleanup iam */}
                {/* added code to control igc as service */}
                {/* while code cleanup clean remove the code and handle in api */}
                <VisibilityWrapper hideFor={{ deployment: ['GLOP'] }}>
                  {showStaticLaunch && (
                    <StaticLaunchButton
                      serviceSlug={serviceSlug}
                      staticLaunchUrl={staticLaunchUrl}
                      setRecentServices={setRecentServices}
                      t={t}
                    />
                  )}
                </VisibilityWrapper>
                {disableLaunchButton || (
                  <Button
                    primary
                    data-testid={`${serviceSlug}-service-detail-launch-button`}
                    label={t('dashboard:common.launch')}
                    onClick={() => {
                      if (provisionedInstances.length === 1) {
                        serviceRedirect(provisionedInstances[0], serviceSlug)
                      } else {
                        setShowLaunchModal(true)
                      }
                    }}
                  />
                )}
              </Box>
            </Box>
            {hasEval && (
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.orders.edit',
                  resource: '/ccs/orders'
                }}
              >
                <RequestEvalStatusText
                  evalStates={EVAL_STATES}
                  getDaysLeft={getDaysLeft}
                  requestEvalStatus={requestEvalStatus}
                />
              </VisibilityWrapper>
            )}
            {provisionedInstances?.length > 0 &&
              configureDeviceNotification &&
              dmEnableDefaultRegion && (
                <Box
                  gap="small"
                  direction="row"
                  align="center"
                  data-testid="device-configuration-anchor-info"
                  background="background-back"
                  margin={{ bottom: 'medium' }}
                  pad="xsmall"
                >
                  <CircleAlert size="medium" />
                  <Typography
                    type="text"
                    size="small"
                    testId="device-configuration-anchor-label"
                  >
                    <Trans
                      i18nKey="dashboard:service_catalog.default_service_notification"
                      t={t}
                    >
                      <Anchor
                        label={t('dashboard:common.device_configuration')}
                        target="_blank"
                        testId="device-configuration-anchor"
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/devices/device-configuration')
                        }}
                      />
                    </Trans>
                  </Typography>
                </Box>
              )}
            <Tabs
              activeIndex={activeTabIndex}
              onActive={(tabIndex) => {
                setActiveTabIndex(tabIndex)
              }}
              tabsList={tabs}
              testId="serviceDetailTabs"
            />
          </Box>
        </Box>
      )}
      {evalModalFormOpen && (
        <RequestEvalModal
          countries={countries}
          setCountries={setCountries}
          listOfCountries={listOfCountries}
          evalServiceData={evalServiceData}
          setEvalModalFormOpen={setEvalModalFormOpen}
          setShowErrorNotification={setShowErrorNotification}
          setSuccessEval={setSuccessEval}
          formLoader={formLoader}
          evalFieldsMetaData={evalFieldsMetaData}
        />
      )}
    </Layout>
  )
}
export default NewServiceDetail
