// Copyright 2024 Hewlett Packard Enterprise Development LP
/* eslint-disable react/prop-types */
import { Box, Button, Markdown } from 'grommet'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useNavigate } from 'react-router-dom'

import { Typography, ModalDialog } from '../../../components'
import { del } from '../../../utils/api-utils'
import { displayNotification } from '../../../utils/notificiation-utils'

const UnregisterWebhookModal = ({
  onSetOpen,
  unregisterWebhookInfo,
  webhookId,
  subscriptionLength
}) => {
  const { t } = useTranslation(['authz', 'common', 'automations'])
  const { oidcUser } = useReactOidc()
  const [isUnregistering, setIsUnregistering] = useState(false)
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const navigate = useNavigate()

  const onCloseDialog = () => {
    onSetOpen(false)
  }

  const webhookUnregisterSuccess = ({ notificationDesc }) => {
    navigate('/manage-account/automations/webhooks', {
      state: {
        notificationDesc
      }
    })
  }

  const unregisterWebhook = async () => {
    try {
      setIsUnregistering(true)
      const unRegisterURl =
        subscriptionLength !== 0
          ? `/events/v1beta1/webhooks/${webhookId}?force=true`
          : `/events/v1beta1/webhooks/${webhookId}?force=false`
      await del(unRegisterURl, {}, oidcUser?.access_token)
      onCloseDialog()
      webhookUnregisterSuccess({
        notificationDesc: t('automations:webhooks.webhook_unregister', {
          webhookName: unregisterWebhookInfo?.name
        }),
        severity: 'info'
      })
    } catch (error) {
      onCloseDialog()
      setIsUnregistering(false)
      setShowErrorNotification(
        displayNotification(error, t, setShowErrorNotification)
      )
    }
  }

  return (
    <ModalDialog
      header={
        <Typography type="heading" level="2">
          {t('automations:webhooks.unregister_webhook_title')}
        </Typography>
      }
      content={
        <Box margin={{ top: 'medium' }} width={{ min: 'full', max: 'medium' }}>
          {showErrorNotification && (
            <Box margin={{ bottom: 'medium' }}>{showErrorNotification}</Box>
          )}
          <Box margin={{ bottom: 'medium' }}>
            <Typography type="text">
              {t('automations:webhooks.unregister_webhook_desc')}
            </Typography>
            <Markdown>
              {t('automations:webhooks.unregister_webhook_question', {
                webhookName: unregisterWebhookInfo?.name
              })}
            </Markdown>
          </Box>
          <Box
            direction="row"
            justify="end"
            gap="medium"
            margin={{ top: 'small' }}
          >
            <Button
              label={t('common:cancel')}
              onClick={onCloseDialog}
              data-testid="unregister-webhook-cancel-btn"
            />
            <Button
              primary
              label={t('automations:webhooks.unregister_webhook_title')}
              onClick={unregisterWebhook}
              disabled={isUnregistering}
              data-testid="unregister-webhook-submit-btn"
            />
          </Box>
        </Box>
      }
      onClose={onCloseDialog}
      testId="unregister-webhook-modal"
    />
  )
}

UnregisterWebhookModal.propTypes = {
  onSetOpen: PropTypes.func.isRequired,
  unregisterWebhookInfo: PropTypes.shape({
    webhookId: PropTypes.string.isRequired
  }).isRequired
}

export default UnregisterWebhookModal
