// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
export const topicsPerPage = {
  mapping: {
    '/activate/activate-documentation': 'glcp-activate-002',
    '/notifications': 'glcp-notifications-007',
    '/notifications/:id': 'glcp-notifications-007',
    '/home': 'glcp-dashboard-004',
    '/applications/my-apps': 'glcp-applications-005',
    '/switch-account': 'glcp-switchworkspace-001',
    '/post-onboarding/set-up-account': 'glcp-switchworkspace-001',
    '/manage-account': 'glcp-manage-005',
    '/manage-account/account-type-overview': 'glcp-manageaccounttype-002',
    '/manage-account/account-type-overview/check-eligibility':
      'glcp-manageaccounttype-002',
    '/manage-account/account-type-overview/review-devices':
      'glcp-manageaccounttype-002',
    '/manage-account/account-type-overview/review-applications':
      'glcp-manageaccounttype-002',
    '/manage-account/account-details': 'glcp-manageaccountdetails-006',
    '/manage-account/identity': 'glcp-iam-007',
    '/manage-account/auditlogs': 'glcp-audit-004',
    '/manage-account/authentication': 'glcp-auth-010',
    '/manage-account/sso/sso-connection': 'glcp-auth-010',
    '/manage-account/tags': 'glcp-tags-004',
    '/manage-account/api': 'glcp-api-005',
    '/manage-account/portal-customization': 'glcp-mspportal-002',
    '/manage-account/subscriptions/device-subscriptions':
      'glcp-subscriptions-002',
    '/manage-account/ip-access-rules': 'glcp-ipaccess-002',
    '/manage-account/consumption-reporting': 'glcp-usage-002',
    '/customer-account': 'glcp-mspcustomers-005',
    '/manage-account/identity/scope-groups': 'glcp-rrp-001',
    '/manage-account/identity/scope-groups/create': 'glcp-rrp-001',
    '/manage-account/identity/users': 'glcp-users-001',
    '/manage-account/identity/users/:email': 'glcp-users-001',
    '/manage-account/identity/roles': 'glcp-roles-001',
    '/manage-account/activate/devices': 'glcp-activate-002',
    '/manage-account/activate/devices-list': 'glcp-activate-002',
    '/manage-account/activate/folders': 'glcp-activate-002',
    '/manage-account/activate/folders-list': 'glcp-activate-002',
    '/manage-account/locations': 'glcp-sdi-004',
    '/manage-account/locations/create-location': 'glcp-sdi-004',
    '/manage-account/account-details/manage-mfa':
      'glcp-manageaccountdetails-007',
    '/manage-account/support-access': 'glcp-sa-006',
    '/manage-account/usage-monitoring': 'glcp-usage-002',
    '/manage-account/zero-touch-onboarding': 'glcp-zto-003',
    '/manage-account/on-prem-workspaces': 'glcp-onprem-001',
    '/whats-new': 'glcp-whatsnew-002',
    '/wellness-dashboard': 'glcp-wellness-004',
    '/preferences-only': 'glcp-userpreferences-002',
    '/devices/inventory': 'glcp-devices-011',
    '/devices/inventory-list': 'glcp-devices-011',
    '/devices/inventory/add-devices': 'glcp-devices-011',
    '/devices/inventory/assign-to-application': 'glcp-devices-011',
    '/devices/inventory/remove-assignment': 'glcp-devices-011',
    '/devices/inventory/apply-subscription': 'glcp-devices-011',
    '/devices/inventory/add-device-subscription': 'glcp-devices-011',
    '/devices/inventory/add-device-subscriptions': 'glcp-devices-018',
    '/devices/auto-subscribe': 'glcp-devices-012',
    '/devices/auto-subscribe/enable-auto-subscription': 'glcp-devices-011',
    '/devices/device-configuration': 'glcp-devices-012',
    '/devices/tags': 'glcp-tags-004',
    '/devices/subscriptions': 'glcp-devices-016',
    '/devices/subscriptions-list': 'glcp-devices-016',
    '/services/my-services': 'glcp-services-005',
    '/services/service-subscriptions': 'glcp-services-006',
    '/services/service-catalog': 'glcp-services-007',
    '/services/add-service-subscriptions': 'glcp-services-012',
    '/support': 'glcp-shub-004',
    '/manage-account/sso/*/details': 'glcp-ssodetails-005',
    '/manage-account/sso/*/edit': 'glcp-ssoedit-002',
    env: 'production',
    hpe_support_domain: 'support.hpe.com',
    '/support/search': 'glcp-shub-004',
    '/organization': 'glcp-igc-001',
    '/organization/users': 'glcp-igc-002',
    '/organization/groups': 'glcp-igc-003',
    '/organization/workspaces': 'glcp-igc-004',
    '/organization/domains': 'glcp-igc-005',
    '/organization/domains/add-domain': 'glcp-igc-006',
    '/organization/sso-profiles': 'glcp-igc-007',
    '/organization/sso-profiles/add-sso-profile': 'glcp-igc-008'
  },
  overrides: {
    v2: {
      '/manage-account/identity': 'glcp-iamv2-001',
      '/manage-account/identity/roles': 'glcp-iamv2-roles-001',
      '/manage-account/identity/users': 'glcp-iamv2-users-001',
      '/manage-account/identity/groups': 'glcp-iamv2-groups-001',
      '/manage-account/identity/api-client': 'glcp-iamv2-apiclient-001',
      '/manage-account/identity/scopegroups': 'glcp-iamv2-scopegroup-001'
    }
  },
  hpe_support_domain: 'ext-itg.support.hpe.com'
}

// Contextual Help Default View Url
export const defaultViewAllUrl = `https://support.hpe.com/hpesc/public/docDisplay?docId=a00120892en_us&page=GUID-49C2FD3B-8EFD-48A0-A84C-E6A15141689A.html`
