// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from 'grommet'
import isEmpty from 'lodash/isEmpty'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { LinkNext, MailOption, StatusCritical } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  getCustomerAccount,
  getNumCustomerAccounts
} from '../../../utils/feature-flag-utils'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { useCCSContext } from '../../../context/ccs-context'
import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import { Button, Notification, Tile, Typography } from '../../../components'
import { updateRBACPolicies } from '../../../utils/rbac-api-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import {
  getWorkspaceString,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

import {
  loadCurrentCustomerAccount,
  launchApplication
} from './app-launch-util'

const COPRedirectionContent = () => {
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['authn', 'common'])
  const { rbacPolicies, dispatchVisibilityContext } = useVisibilityContext()
  const { dispatchCCSContext } = useCCSContext()
  const numOfCustomerAccounts = getNumCustomerAccounts()
  const [custAccountLoaded, setCustAccountLoaded] = useState(null)
  const [errorMessage, setErrorMessage] = useState(
    useLocation()?.state?.errorMessage
  )
  const [launchingApp, setLaunchingApp] = useState(false) // to track if the launch API is in progress - to prevent multiple provision/ launch app API calls
  const [loadingAccount, setLoadingAccount] = useState(false) // to track if load account API is in progress - to prevent multiple calls
  const defaultCustAccount = getCustomerAccount()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']

  const query = new URLSearchParams(sessionStorage.getItem('redirect-query'))
  const appInstanceID =
    query && !isEmpty(query.get('app')) ? query.get('app') : null
  const redirectUrl =
    query && !isEmpty(query.get('redirect_uri'))
      ? query.get('redirect_uri')
      : null
  const accountType = defaultCustAccount?.account_type || ''

  const launchApplicationDirectly = useCallback(() => {
    setLaunchingApp(true)
    launchApplication(
      appInstanceID,
      redirectUrl,
      oidcUser.access_token,
      accountType,
      setErrorMessage,
      t,
      setLaunchingApp
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl, oidcUser, accountType, t, appInstanceID, defaultCustAccount])

  // NOTE: Below useEffect is added only for COP appliance use case as it
  // uses the un-trusted self signed certificates. So, browser by default issues
  // warning on un-trusted CA. Hence added the api domain to be handled by the
  // browser and it should come back with 200 to redirect to the host url back.
  useEffect(() => {
    if (!sessionStorage.getItem('ccs-cop-ss-cert-api')) {
      sessionStorage.setItem('ccs-cop-ss-cert-api', true)
      navigate('/accept-certificate')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // if customer account is not loaded, load account
  useEffect(() => {
    if (
      oidcUser &&
      defaultCustAccount &&
      !custAccountLoaded &&
      !loadingAccount
    ) {
      setLoadingAccount(true)
      loadCurrentCustomerAccount(
        defaultCustAccount,
        oidcUser,
        setErrorMessage,
        t,
        dispatchVisibilityContext
      ).then(
        () => {
          setCustAccountLoaded(getCustomerAccount())
          setLoadingAccount(false)
        },
        () => {
          // if promise is rejected
          setLoadingAccount(false)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oidcUser,
    dispatchCCSContext,
    t,
    custAccountLoaded,
    dispatchVisibilityContext
  ])

  // use effect to either launch app or go to home page
  useEffect(() => {
    if (!JSON.parse(sessionStorage.getItem('from-central')) || appInstanceID) {
      if (custAccountLoaded && !launchingApp) {
        launchApplicationDirectly()
      }
    } else if (
      defaultCustAccount &&
      custAccountLoaded &&
      window.location.pathname === '/cop-redirection'
    ) {
      if (rbacPolicies === null)
        updateRBACPolicies(
          oidcUser,
          custAccountLoaded,
          dispatchVisibilityContext
        )
      navigate(`/home`)
    }
  }, [
    defaultCustAccount,
    appInstanceID,
    redirectUrl,
    launchApplicationDirectly,
    custAccountLoaded,
    launchingApp,
    navigate,
    oidcUser,
    dispatchCCSContext,
    t,
    loadingAccount,
    rbacPolicies,
    dispatchVisibilityContext
  ])

  return (
    <Box
      justify="center"
      direction="row"
      margin={{ top: 'xlarge', bottom: 'large' }}
    >
      <Box direction="column">
        {numOfCustomerAccounts === null || numOfCustomerAccounts > 0 ? (
          <LazyLoading />
        ) : (
          <Tile
            pad="medium"
            background="background-contrast"
            actionBtn={
              <Button
                label={t('authn:choose_accounts.back_to_sign_in')}
                testId="back-to-sign-in-btn"
                icon={<LinkNext />}
                reverse
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/sign-out')
                }}
              />
            }
            layout="row"
            logo={<MailOption color="#6633BC" size="large" />}
            subTitle={t('authn:choose_accounts.error_back_to_sign_in')}
            title={
              <Typography
                testId="account-fallback-title"
                type="heading"
                level="3"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {t(
                  'authn:choose_accounts.user_does_not_have_access_to_the_account',
                  {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  }
                )}
              </Typography>
            }
            testId="account-fallback-tile"
          />
        )}
      </Box>
      {errorMessage ? (
        <Notification
          backgroundColor="status-critical"
          icon={<StatusCritical color="text-strong" />}
          onClose={() => setErrorMessage(null)}
          position="top"
          testId="error-notification"
          text={errorMessage}
        />
      ) : null}
    </Box>
  )
}

const COPRedirectionPage = () => {
  return (
    <Layout hideHeaderOptions={['nav', 'bell', 'apps']}>
      <COPRedirectionContent />
    </Layout>
  )
}

export default COPRedirectionPage
