// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react'
import { Box, Tag } from 'grommet'
import { Trans, useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Notification, Typography } from '../../../../components'
import { WizardContext } from '../../../../components/wizard/WizardContext'
import { ReviewShowbackRatesStep } from '../../../manage-account/subscriptions/pages/service-subscriptions/components/showbackRate'
import VisibilityWrapper from '../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const ReviewAddSubscriptionWizard = () => {
  const { formValues, setFormValues } = useContext(WizardContext)
  const {
    tagsAssigned,
    subscriptionKey,
    selectedApp,
    isIaas,
    isService,
    isOnpremWorkspace,
    workspaceId,
    workspaceName
  } = formValues
  const { t } = useTranslation(['device', 'licensing'])
  const { 'glcp-cds-rate-interface': showbackRateFlag } = useFlags()
  const handleDeleteTag = (tag) => {
    const tagsData = tagsAssigned
    const index = tagsData?.findIndex(
      (val) => val.value === tag?.value && val.name === tag?.name
    )
    tagsData?.splice(index, 1)
    setFormValues({ ...formValues, tagsAssigned: tagsData })
  }

  const getInfo = (content, id, displayText) => {
    // handle single and multiple values for content and displayText
    if (!Array.isArray(content)) {
      content = [content]
      displayText = [displayText]
    }
    return content.map((item, index) => {
      return (
        <Box direction="row">
          <Box width="small">
            <Typography
              type="text"
              emphasis
              testId={index > 0 ? `${id}-${index}-key` : `${id}-key`}
            >
              {displayText[index]}
            </Typography>
          </Box>
          <Box pad={{ left: 'large' }}>
            <Typography
              type="text"
              testId={index > 0 ? `${id}-${index}-value` : `${id}-value`}
            >
              {item}
            </Typography>
          </Box>
        </Box>
      )
    })
  }

  const getInfoSection = (title, content, id, displayText = '') => {
    return (
      <Box margin={{ bottom: 'medium' }}>
        <Box
          margin={{ vertical: 'small' }}
          pad={{ bottom: 'xsmall' }}
          border={{ side: 'bottom', color: 'border-weak' }}
        >
          <Typography size="large" type="text" emphasis testId={`${id}-header`}>
            {title}
          </Typography>
        </Box>

        {id === 'tags' ? (
          content
        ) : (
          <Box margin={{ top: 'small' }}>
            {getInfo(content, id, displayText)}
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box pad={{ top: 'medium' }} direction="column">
      {isService
        ? getInfoSection(
            t('service_subscription_information'),
            subscriptionKey,
            'key',
            t('subscription_key')
          )
        : getInfoSection(
            t('device_subscription_information'),
            subscriptionKey,
            'key',
            t('subscription_key')
          )}
      {isService &&
        isOnpremWorkspace &&
        getInfoSection(
          t('licensing:onprem_workspace_id_and_name'),
          [workspaceId, workspaceName],
          'workspaceInfo',
          [
            t('licensing:onprem_workspace_id'),
            t('licensing:onprem_workspace_name')
          ]
        )}
      {!isService &&
        isIaas &&
        getInfoSection(
          t('deployment_region'),
          selectedApp.label,
          'region',
          t('region')
        )}
      {getInfoSection(
        t('tags.tags_to_be_assigned'),
        <Box
          direction="row"
          height={{ min: showbackRateFlag ? 'xxsmall' : 'small' }}
          wrap
          data-testid="assigned-tags"
          style={{ overflow: 'auto' }}
        >
          {tagsAssigned?.map((item, index) => {
            return (
              <Box
                margin="xsmall"
                alignSelf="start"
                gap="medium"
                data-testid={`assigned-tags-${index}`}
                key={`pill-${index}`} /* eslint-disable-line react/no-array-index-key */
              >
                <Tag
                  name={item?.name}
                  testId={item.value}
                  value={item?.value}
                  onRemove={() => {
                    handleDeleteTag(item)
                  }}
                />
              </Box>
            )
          })}
        </Box>,
        'tags'
      )}
      {formValues.isRatesStepAvailable && (
        <VisibilityWrapper
          hideFor={{
            account: ['MSP', 'TENANT'],
            feature: 'glcp-cds-rate-interface',
            deployment: ['COP', 'GLOP']
          }}
          rbac={{
            resource: '/ccs/device-management/showback-rate',
            permission: 'ccs.device-showback-rate.view'
          }}
        >
          <ReviewShowbackRatesStep
            ratesData={formValues.ratesData}
            currency={formValues.ratesPayload?.currency}
          />
        </VisibilityWrapper>
      )}
      {isService && isOnpremWorkspace && (
        <Notification
          status="info"
          testId="workspace-info-banner-notification"
          text={
            <Trans>{t('licensing:onprem_reviewpage_info_notification')}</Trans>
          }
          type="inline"
          margin={{ bottom: 'large' }}
        />
      )}
    </Box>
  )
}

export { ReviewAddSubscriptionWizard }
