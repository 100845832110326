// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Button, Collapsible, Anchor } from 'grommet'
import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Search, CircleAlert, Alert, FormDown, FormUp } from 'grommet-icons'

import { Typography, Loader, Notification } from '../../../components'

import { RenderDomainComponent } from './RenderDomain'
import { CustomMenuList, FeedbackComponent, ListComponent } from './components'
import { getCategoriesCount, getRecommondedFaq } from './utils'

export const SearchDropDown = ({
  content,
  handleListClick,
  handleActionClick,
  isAutoCompletion,
  showRecentSearch,
  loading,
  showFaq,
  setOpenDropdown,
  i18nTranslation,
  renderHints,
  countData,
  errorMessage,
  onMoreHandler,
  loadingPagination,
  handleFeedBack
}) => {
  const [categoriesCount, setCategoriesCount] = useState([{}])
  const [selectedCategory, setSelectedCategory] = useState({})
  const [openFeedbackMenu, setOpenFeedbackMenu] = useState(false)
  const [loadingCategory, setLoadingCategory] = useState(false)
  const [showNotification, setShowNotification] = useState(
    content?.errorDetails?.isPartialSearch || true
  )

  const MenuButton = ({ label, open, ...rest }) => {
    const Icon = open ? FormUp : FormDown
    return (
      <Button hoverIndicator="background" {...rest}>
        <Box direction="row" align="center" pad="xsmall" justify="between">
          <Typography size="small" type="text" color="text-week">
            {label}
          </Typography>
          <Icon />
        </Box>
      </Button>
    )
  }
  MenuButton.propTypes = {
    label: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired
  }

  useEffect(() => {
    if (content?.searchResult?.domainHits) {
      const categoryObj = getCategoriesCount(
        content?.searchResult?.domainHits,
        countData?.countPerCategory,
        renderHints
      )
      setCategoriesCount(categoryObj)
      setSelectedCategory(categoryObj[0])
    } else {
      setCategoriesCount([{}])
      setSelectedCategory({})
    }
  }, [content, countData, renderHints])

  const handleMenuItemClick = (item) => {
    setSelectedCategory(item)
  }

  const getIcons = (errorIcon) => {
    const icon = errorIcon
    const iconSize = 'large'
    let element
    switch (icon) {
      case 'SEARCH':
        element = (
          <Box align="center" flex={false}>
            <Box
              round="full"
              background="background-back"
              pad="small"
              data-testid="gs-search-icon"
            >
              <Search size={iconSize} color="text-weak" />
            </Box>
          </Box>
        )
        break
      case 'INFO':
        element = (
          <Box align="center" flex={false}>
            <Box
              round="full"
              background="background-back"
              pad="small"
              data-testid="gs-circle-alert-icon"
            >
              <CircleAlert size={iconSize} color="text-weak" />
            </Box>
          </Box>
        )
        break
      case 'WARNING':
        element = (
          <Box align="center" flex={false}>
            <Box
              round="full"
              background="validation-warning"
              pad="small"
              data-testid="gs-alert-icon"
            >
              <Alert size={iconSize} color="text-weak" />
            </Box>
          </Box>
        )
        break
      default:
        element = <></>
    }
    return element
  }

  return (
    <Box
      elevation="large"
      width="large"
      pad={{ horizontal: 'small' }}
      round="small"
      style={{ position: 'absolute', zIndex: 100 }}
      background="background"
      direction="column"
      margin={{ horizontal: 'small' }}
      data-testid="global-search-drop-down-QnA"
    >
      {loading ? (
        <Box align="center" justify="center" margin="large">
          <Loader testId="global-search-loader-QnA" />
        </Box>
      ) : (
        <>
          {!errorMessage ? (
            <Box direction="column">
              {(isAutoCompletion || showFaq) && content && (
                <Box
                  pad={{ bottom: 'small' }}
                  border={{
                    side: 'bottom',
                    color: 'border-weak',
                    size: 'small'
                  }}
                >
                  {showFaq && (
                    <>
                      <Typography
                        size="xsmall"
                        type="text"
                        testId="faq-heading"
                        color="text-weak"
                        margin={{ vertical: 'xsmall', horizontal: 'xsmall' }}
                      >
                        {i18nTranslation('global_search.frequent_searches')}
                      </Typography>
                      <ListComponent
                        content={getRecommondedFaq(content?.items)}
                        handleListClick={handleListClick}
                        handleActionClick={handleActionClick}
                      />
                    </>
                  )}
                  {isAutoCompletion && (
                    <ListComponent
                      content={content?.completion?.suggestions}
                      handleListClick={handleListClick}
                      handleActionClick={handleActionClick}
                    />
                  )}
                </Box>
              )}
              {showRecentSearch && (
                <>
                  {content?.searchResult && (
                    <Box direction="column" gap="small">
                      {content?.errorDetails?.isPartialSearch &&
                        showNotification && (
                          <Box direction="row" pad={{ top: 'small' }}>
                            <Notification
                              type="inline"
                              text={
                                <Typography
                                  type="text"
                                  testId="gs-err-inline-notification-text"
                                >
                                  {content?.errorDetails?.errMessage}
                                </Typography>
                              }
                              backgroundColor="status-warning"
                              testId="gs-err-inline-notification"
                              onClose={() => {
                                setShowNotification(false)
                              }}
                            />
                          </Box>
                        )}
                      <Box
                        direction="column"
                        gap="small"
                        height="auto"
                        width="100%"
                      >
                        <Box
                          gridArea="content"
                          direction="row"
                          gap="small"
                          height="100%"
                          width="100%"
                        >
                          <Box
                            width={{ min: 'fit-content' }}
                            pad={{
                              vertical: 'small',
                              horizontal: 'none'
                            }}
                          >
                            <CustomMenuList
                              highlightOnSelect
                              menuData={categoriesCount}
                              testId="gs-menulist"
                              onClickMenuItem={(item) => {
                                setLoadingCategory(true)
                                handleMenuItemClick(item)
                                setTimeout(() => {
                                  setLoadingCategory(false)
                                }, 100)
                              }}
                            />
                          </Box>
                          <Box
                            direction="column"
                            height={{
                              max: 'large',
                              min: '20vh'
                            }}
                            width="100%"
                            pad={{ top: 'xsmall', bottom: 'xsmall' }}
                            overflow="auto"
                            data-testid="gs-domain-list"
                            fill
                          >
                            {selectedCategory?.category && !loadingCategory && (
                              <>
                                <Box
                                  height="auto"
                                  flex={false}
                                  border={{
                                    side: 'bottom',
                                    color: 'border-weak',
                                    size: 'xxsmall'
                                  }}
                                >
                                  <RenderDomainComponent
                                    content={selectedCategory?.domainHit}
                                    renderHints={renderHints}
                                    handleListClick={handleListClick}
                                    handleActionClick={handleActionClick}
                                    setOpenDropdown={setOpenDropdown}
                                    i18nTranslation={i18nTranslation}
                                    onMoreHandler={onMoreHandler}
                                    loadingPagination={loadingPagination}
                                  />
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <FeedbackComponent
                        i18nTranslation={i18nTranslation}
                        handleFeedBack={handleFeedBack}
                      />
                    </Box>
                  )}
                </>
              )}
              {showFaq && (
                <Box pad={{ vertical: 'xsmall' }}>
                  <MenuButton
                    label={i18nTranslation('global_search.what_to_search')}
                    open={openFeedbackMenu}
                    onClick={() => setOpenFeedbackMenu(!openFeedbackMenu)}
                    data-testid="gs-feedback-menu-button"
                  />
                  <Collapsible open={openFeedbackMenu}>
                    <Box pad="small" gap="small">
                      <Typography
                        type="text"
                        size="small"
                        testId="gs-search-desc"
                      >
                        {i18nTranslation('global_search.what_to_search_desc')}
                      </Typography>
                      <Typography
                        type="text"
                        size="small"
                        testId="gs-search-link-desc"
                      >
                        {i18nTranslation('global_search.feedback_link_desc')}
                        <Anchor
                          label={i18nTranslation(
                            'global_search.feedback_link_label'
                          )}
                          href="https://hpetraining.co1.qualtrics.com/jfe/form/SV_0i8Bsa17cg6MkCy?env=p&pd=gl&tof=gf"
                          target="_blank"
                        />
                      </Typography>
                    </Box>
                  </Collapsible>
                </Box>
              )}
            </Box>
          ) : (
            <Box align="center" justify="center" height="20vh" gap="small">
              {getIcons(errorMessage?.icon)}
              <Box align="center">
                <Typography
                  type="heading"
                  level={3}
                  testId="gs-error-msg-title"
                >
                  {errorMessage?.title}
                </Typography>
                <Typography type="text" testId="gs-error-msg-subtitle">
                  {errorMessage?.subtitle}
                </Typography>
                <Typography type="text" testId="gs-error-msg-secondarytitle">
                  {errorMessage?.secondarySubtitle}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
SearchDropDown.propTypes = {
  content: PropTypes.any.isRequired,
  handleListClick: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  isAutoCompletion: PropTypes.bool.isRequired,
  showRecentSearch: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showFaq: PropTypes.bool.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  i18nTranslation: PropTypes.any.isRequired,
  renderHints: PropTypes.any.isRequired,
  countData: PropTypes.any.isRequired,
  errorMessage: PropTypes.any.isRequired,
  onMoreHandler: PropTypes.func.isRequired,
  loadingPagination: PropTypes.bool.isRequired,
  handleFeedBack: PropTypes.func.isRequired
}
