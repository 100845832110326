// Copyright 2024 Hewlett Packard Enterprise Development LP

import { useContext, useState } from 'react'
import { Anchor, Box, ThemeContext, ResponsiveContext, Grid } from 'grommet'
import {
  AppsRounded,
  UserAdd,
  Certificate,
  UserAdmin,
  ServerCluster
} from 'grommet-icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { displayApiError } from '../../utils/error-handling-utils'
import { Loader } from '../../components'
import { Card, Typography, isGLOP } from '../shims/imports'
import { localStorageWhitelistWithoutPII } from '../../utils/local-storage-utils'
import { post } from '../../utils/api-utils'
import { UPSActions, useUPSContext } from '../../context/ups-context'
import { getDataBasedOnSetting, isUPSSupported } from '../../utils/ups-utils'
import VisibilityWrapper from '../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { displayNotification } from '../../utils/notificiation-utils'

import ImportResourcesLayer from './ImportResourcesLayer'

const GettingStarted = ({ toggle = () => {} }) => {
  const { t } = useTranslation(['dashboard', 'user_personalisation', 'common'])
  const navigate = useNavigate()
  const [successMessage, setSuccessMessage] = useState(null)
  const { 'glcp-ups-phase-1': glcpUPSPhase1LD } = useFlags()
  const [showLoader, setLoader] = useState(false)
  const { global } = useContext(ThemeContext)
  const size = useContext(ResponsiveContext)
  const { oidcUser } = useReactOidc()
  const { commonpreferences, dispatchUPSContext } = useUPSContext()
  const [showImportResourcesLayer, setShowImportResourcesLayer] =
    useState(false)

  const [showNotification, setNotification] = useState(null) // to display success notifications
  const cardData = isGLOP()
    ? [
        {
          anchor: '/system/certificates',
          description: 'dashboard:dashboard.manage_certificates.description',
          icon: <Certificate color="#7630EA" size="xxlarge" />,
          title: 'dashboard:dashboard.manage_certificates.title',
          rbac: {
            resource: '/ccs/system-mgmt/certificates',
            permission: 'ccs.system-mgmt.certificates.read'
          }
        },
        {
          anchor: '/home',
          description: 'dashboard:dashboard.import_resources.description',
          icon: <ServerCluster color={global.colors.orange} size="xxlarge" />,
          title: 'dashboard:dashboard.import_resources.title',
          hideFor: { account: ['TENANT'] },
          rbac: {
            resource: '/ccs/device-management',
            permission: 'ccs.device-management.edit'
          }
        },
        {
          anchor: '/manage-account/identity/users',
          description: 'dashboard:dashboard.manage_users.description',
          icon: <UserAdd color={global.colors.red.dark} size="xxlarge" />,
          title: 'dashboard:dashboard.manage_users.title'
        },
        {
          anchor: '/manage-account/identity/roles',
          description: 'dashboard:dashboard.manage_roles.description',
          icon: <UserAdmin color={global.colors.blue.dark} size="xxlarge" />,
          title: 'dashboard:dashboard.manage_roles.title'
        }
      ]
    : [
        {
          anchor: '/services/service-catalog',
          description: 'dashboard:dashboard.find_services.description',
          icon: <AppsRounded size="xxlarge" color={global.colors.green.dark} />,
          title: 'dashboard:dashboard.find_services.title',
          hideFor: { account: ['TENANT'] }
        },
        {
          anchor: '/manage-account',
          description: 'dashboard:dashboard.manage_workspace.description',
          icon: <UserAdd color="purple" size="large" />,
          title: 'dashboard:dashboard.manage_workspace.title'
        }
      ]
  const responsive = {
    direction: {
      small: 'column',
      medium: 'row',
      large: 'row',
      xlarge: 'column'
    },
    pad: {
      xsmall: 'medium',
      small: 'medium',
      medium: 'small',
      large: 'small',
      xlarge: 'small'
    }
  }

  const grid = {
    columns: {
      xsmall: ['auto'],
      small: ['auto'],
      medium: ['1/2'],
      large: ['1/2'],
      xlarge: ['1/2']
    }
  }
  const handleHideGettingStarted = async (e) => {
    e.preventDefault()
    if (glcpUPSPhase1LD && isUPSSupported()) {
      setLoader(true)
      setNotification(null)
      try {
        const category = 'commonpreferences'
        const showGettingStartedData = getDataBasedOnSetting(
          commonpreferences,
          'showGettingStarted'
        )
        const requestBody = {
          category,
          data: [{ ...showGettingStartedData, value: false }]
        }
        const response = await post(
          `/user-prefs/v1alpha1/save-preferences`,
          requestBody,
          oidcUser.access_token
        )
        if (response?.status === 200) {
          dispatchUPSContext({
            type: UPSActions.SET_CATEGORY_DATA,
            data: requestBody
          })
        }
      } catch (error) {
        const overrideMessage = t(
          'user_personalisation:common_preferences.getting_started.home_hide_error'
        )
        setNotification(
          displayApiError(error, t, setNotification, overrideMessage)
        )
      } finally {
        setLoader(false)
      }
    } else {
      localStorage.removeItem(
        localStorageWhitelistWithoutPII.DASHBOARD_GET_STARTED
      )
      localStorage.setItem(
        localStorageWhitelistWithoutPII.DASHBOARD_GET_STARTED,
        'false'
      )
      toggle(false)
    }
  }
  const handleClose = () => {
    setShowImportResourcesLayer(false)
  }

  return (
    <Box pad={{ top: 'none', bottom: 'large' }}>
      {successMessage &&
        displayNotification(
          successMessage,
          'info',
          setSuccessMessage,
          null,
          true
        )}
      {showLoader && (
        <Box align="center" justify="center" alignSelf="center">
          <Loader testId="loader-spinner" />
        </Box>
      )}
      {showImportResourcesLayer}
      {showNotification}
      {showImportResourcesLayer && (
        <ImportResourcesLayer
          handleClose={handleClose}
          onSuccess={() =>
            setSuccessMessage(
              t('dashboard:dashboard.import_resources.upload_success')
            )
          }
        />
      )}
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{ top: 'none', bottom: 'medium' }}
        margin={{ top: 'none', bottom: 'small' }}
      >
        <Typography
          type="heading"
          level={2}
          margin="none"
          data-testid="getting-started-title"
        >
          {t('dashboard:dashboard.getting_started')}
        </Typography>
        <Anchor
          href="#"
          data-testid="dismiss-button"
          label={t('common.dismiss')}
          onClick={handleHideGettingStarted}
        />
      </Box>
      <Grid
        rows={['auto']}
        columns={{
          count: 'fill',
          size: grid.columns[size]
        }}
        gap="medium"
      >
        {cardData.map(({ anchor, icon, description, title }, index) => (
          <VisibilityWrapper
            hideFor={cardData[index]?.hideFor}
            rbac={cardData[index]?.rbac}
            key={title}
          >
            <Card
              key={title}
              width="full"
              justify="center"
              onClick={() =>
                index === 1 && isGLOP()
                  ? setShowImportResourcesLayer(true)
                  : navigate(anchor)
              }
              CustomContent={
                <Box
                  direction="row"
                  justify="start"
                  align="center"
                  gap={responsive.pad[size]}
                >
                  {icon}
                  <Box>
                    <Typography type="heading" level={3}>
                      {t(title)}
                    </Typography>
                    <Typography type="paragraph">{t(description)}</Typography>
                  </Box>
                </Box>
              }
              testId={`service-centric-getting-started-card-${index}`}
            />
          </VisibilityWrapper>
        ))}
      </Grid>
    </Box>
  )
}

GettingStarted.propTypes = {
  toggle: PropTypes.func
}

export default GettingStarted
