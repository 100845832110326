// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { StatusWarning } from 'grommet-icons'
import { FormField, RadioButtonGroup, Box, ThemeContext } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ModalDialog,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  CCSForm,
  FormInput,
  FormTextArea,
  Typography,
  Notification
} from '../../../../components'
import {
  addAccessRuleDynamicFields,
  formatRuleData,
  handleConfirmation
} from '../utils'

const AccessRuleModalIPv6 = ({
  mode,
  ruleData = undefined,
  setOpen,
  ipAccessRulesEnabled = false,
  onSuccess
}) => {
  const { t } = useTranslation(['authn'])
  const { oidcUser } = useReactOidc()
  const [errorMessage, setErrorMessage] = useState('')
  const protocolVersions = ['IPv4', 'IPv6']
  const ipFormats = [
    { label: t('ip_access_rules.single_ip_address'), value: 'SINGLE_IP' },
    { label: t('ip_access_rules.ip_subnet_mask_new'), value: 'IP_SUBNET_MASK' },
    { label: t('ip_access_rules.ip_range'), value: 'IP_RANGE' }
  ]
  const theme = React.useContext(ThemeContext)
  const LDFlags = useFlags()

  const initialData =
    mode === 'add'
      ? {
          protocolVersion: protocolVersions[0],
          ipFormat: ipFormats[1].value,
          desc: ''
        }
      : {
          ...formatRuleData(ruleData),
          protocolVersion:
            (ruleData.starting_ip && ruleData.starting_ip.includes('.')) ||
            (ruleData.single_ip && ruleData.single_ip.includes('.')) ||
            (ruleData.ip_subnet_mask && ruleData.ip_subnet_mask.includes('.'))
              ? 'IPv4'
              : 'IPv6'
        }

  const [data, dispatch] = useReducer((curr, action) => {
    switch (action.field) {
      case 'protocolVersion':
        return {
          desc: curr.desc,
          ipFormat: curr.ipFormat,
          protocolVersion: action.value,
          ...(curr.ip_access_rule_id && {
            ip_access_rule_id: curr.ip_access_rule_id
          })
        }
      case 'ipFormat':
        return {
          desc: curr.desc,
          protocolVersion: curr.protocolVersion,
          ipFormat: action.value,
          ...(curr.ip_access_rule_id && {
            ip_access_rule_id: curr.ip_access_rule_id
          })
        }
      default:
        return { ...curr, [action.field]: action.value }
    }
  }, initialData)

  return (
    <ModalDialog
      testId="add-access-rule-modal"
      onClose={() => setOpen(false)}
      header={
        <ModalHeader
          onClose={() => setOpen(false)}
          title={
            <Typography
              level={2}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              testId="add-header-title"
              type="heading"
              margin={{ bottom: 'small', top: 'none' }}
            >
              {mode === 'add'
                ? t('ip_access_rules.add_access_rule_new')
                : t('ip_access_rules.edit_access_rule')}
            </Typography>
          }
          subtitle={
            mode === 'add'
              ? t('ip_access_rules.add_access_rule_subtitle_new')
              : undefined
          }
        />
      }
      content={
        <Box margin={{ top: 'small' }}>
          {ipAccessRulesEnabled && mode !== 'add' && (
            <Box margin={{ bottom: 'small' }}>
              <Notification
                type="inline"
                margin={{ top: 'xsmall' }}
                icon={<StatusWarning color="status-warning" size="large" />}
                backgroundColor="status-warning"
                testId="edit-warning-enabled-ip-access-rules-notification"
                status="warning"
                text={t('ip_access_rules.edit_warning_enable_ip_access_rule')}
              />
            </Box>
          )}
          <CCSForm
            errorMessage={errorMessage}
            testId="add-access-rule-form"
            onSubmit={() =>
              handleConfirmation(
                t,
                onSuccess,
                setErrorMessage,
                data,
                mode,
                setOpen,
                oidcUser
              )
            }
            onReset={() => setOpen(false)}
            validate="blur"
          >
            <>
              <Box margin={{ bottom: 'medium' }}>
                <FormField
                  required
                  label="Protocol"
                  name="protocol_version"
                  width="medium"
                  margin={{ bottom: 'xsmall' }}
                >
                  <RadioButtonGroup
                    name="protocol_version"
                    options={protocolVersions}
                    value={data.protocolVersion}
                    onChange={(event) => {
                      dispatch({
                        value: event.value,
                        field: 'protocolVersion'
                      })
                    }}
                  />
                </FormField>
                <FormField
                  required
                  label="Specify by"
                  name="ip_format"
                  width="medium"
                  margin={{ bottom: 'xsmall' }}
                >
                  <RadioButtonGroup
                    name="ip_format"
                    options={ipFormats}
                    value={data.ipFormat}
                    onChange={(event) =>
                      dispatch({ value: event.target.value, field: 'ipFormat' })
                    }
                    data-testid="ipv4-format"
                  />
                </FormField>
                {addAccessRuleDynamicFields(
                  t,
                  data.protocolVersion,
                  LDFlags['glcp-ipv6']
                )[data.ipFormat].map((field) => (
                  <FormInput
                    margin={{ bottom: 'xsmall' }}
                    key={field.name}
                    name={field.name}
                    inputType="text"
                    label={
                      <>
                        <Typography
                          reverse
                          type="text"
                          {...theme.formField.label}
                          testId={`${field.name}-label`}
                        >
                          {field.label}*
                        </Typography>
                        {data.ipFormat === 'IP_SUBNET_MASK' && (
                          <Typography
                            margin={{ top: 'xxsmall', bottom: 'xxsmall' }}
                            type="text"
                            size="xsmall"
                          >
                            {field.ipSubnetMaskSubLabel}
                          </Typography>
                        )}
                      </>
                    }
                    placeholder={field.placeholder[data.protocolVersion]}
                    width="medium"
                    value={data[field.name] || ''}
                    validate={(value) =>
                      field.validate[
                        data.protocolVersion === 'IPv4'
                          ? 'IPV4'
                          : data.protocolVersion
                      ](value)
                    }
                    onChange={(event) =>
                      dispatch({
                        value: event.target.value,
                        field: field.name
                      })
                    }
                    testId={`${field.name}-form-field`}
                  />
                ))}
                <FormTextArea
                  name="description"
                  label={t('ip_access_rules.description')}
                  placeholder={t('ip_access_rules.add_access_rule_desc')}
                  value={data.desc}
                  width="medium"
                  required
                  onChange={(event) =>
                    dispatch({
                      value: event.target.value,
                      field: 'desc'
                    })
                  }
                  testId="description-form-field"
                />
              </Box>
              <ModalFooter
                left={
                  <ButtonGroup
                    buttonList={[
                      {
                        id: 1,
                        label: t(mode === 'add' ? 'add' : 'save', {
                          ns: 'common'
                        }),
                        primary: true,
                        type: 'submit',
                        testId: 'add-btn'
                      },
                      {
                        id: 2,
                        label: t('cancel', { ns: 'common' }),
                        default: true,
                        type: 'reset',
                        testId: 'cancel-btn'
                      }
                    ]}
                    testId="button-group"
                  />
                }
              />
            </>
          </CCSForm>
        </Box>
      }
      position="right"
      height="100%"
      width="medium"
    />
  )
}

AccessRuleModalIPv6.propTypes = {
  mode: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  ruleData: PropTypes.object,
  ipAccessRulesEnabled: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired
}

export default AccessRuleModalIPv6
