// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import LazyLoading from '../../../../../commoncomponents/LazyLoading/LazyLoading'
import { isCoP } from '../../../../../utils/feature-flag-utils'

const SsoConnectionWizard = lazy(() => import('./SsoConnectionWizard'))
const SetupComplete = lazy(() => import('../pages/SetupComplete'))
const SsoConnectionSuccess = lazy(() => import('../pages/SsoConnectionSuccess'))

const SsoConnectionRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route
          exact
          path="/download/:domain"
          element={isCoP() ? <SsoConnectionSuccess /> : <SetupComplete />}
        />
        <Route exact path="/" element={<SsoConnectionWizard />} />
      </Routes>
    </Suspense>
  )
}

export default SsoConnectionRouter
