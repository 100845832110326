// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ShareRounded } from 'grommet-icons'
import { useNavigate } from 'react-router'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  Typography,
  AvatarInfo,
  Button,
  Loader
} from '../../../../../../components'
import { getCustomerRoleAssignedUsersV2 } from '../../../../utils'
import { displayApiError } from '../../../../../../utils/error-handling-utils'
import VisibilityWrapper from '../../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const AssignedUsersTab = ({ roleDetails, customerId }) => {
  const { t } = useTranslation(['manage'])
  const [showLoader, setShowLoader] = useState(true)
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const custRolesV2LDFlag = LDFlags['glcp-kobe-sa-customer-roles-flag']
  const [users, setUsers] = useState(
    custRolesV2LDFlag ? [] : roleDetails?.users
  )
  const [showErrorNotification, setShowErrorNotification] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (custRolesV2LDFlag && roleDetails?.slug && roleDetails?.application_id) {
      setShowLoader(true)
      getCustomerRoleAssignedUsersV2(
        {},
        oidcUser.access_token,
        (response) => {
          setShowLoader(false)
          const formattedUsers = response?.data?.users.map((user) => {
            return {
              user_name: user?.email,
              user_first_name: user?.first_name,
              user_last_name: user?.last_name
            }
          })
          setUsers(formattedUsers || [])
        },
        (error) => {
          setShowLoader(false)
          setShowErrorNotification(
            displayApiError(error, t, setShowErrorNotification)
          )
        },
        roleDetails?.slug,
        roleDetails?.application_id,
        customerId
      )
    }
  }, [
    custRolesV2LDFlag,
    oidcUser.access_token,
    roleDetails,
    t,
    customerId,
    setShowErrorNotification
  ])
  const getUserFullName = (user) => {
    const firstName = user?.user_first_name
    const lastName = user?.user_last_name
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName || user?.user_name
  }
  return (
    <>
      {showErrorNotification}
      <Box
        fill
        gap="medium"
        direction="row"
        justify="between"
        pad={{ bottom: 'medium' }}
        margin={{ bottom: 'small', top: 'large' }}
      >
        <Typography
          level="2"
          type="heading"
          weight="normal"
          testId="dynamic-view-edit-users-form-title"
        >
          <>
            {t('customer_roles.assigned_users')} ({users?.length || 0})
          </>
        </Typography>
      </Box>
      {showLoader && custRolesV2LDFlag ? (
        <Box align="center">
          <Loader testId="loader-spinner" />
        </Box>
      ) : (
        ''
      )}
      {users.length > 0 && (
        <Box>
          {users.map((user) => (
            <Box
              gap="small"
              pad="xsmall"
              align="center"
              direction="row"
              justify="between"
              key={user?.user_name}
              margin={{ bottom: 'small' }}
            >
              <AvatarInfo
                avatarChar="two"
                testId="user-info-avatar-only"
                primaryInfo={getUserFullName(user)}
                secondaryInfo={user?.user_name}
              />
              <VisibilityWrapper
                rbac={{
                  permission: 'ccs.accounts.users.view.all',
                  resource: '/ccs/accounts/user'
                }}
              >
                <Button
                  type="button"
                  icon={<ShareRounded />}
                  testId="open-user-button"
                  onClick={() =>
                    navigate(`/manage-ccs/customers/users/${user?.user_name}`)
                  }
                />
              </VisibilityWrapper>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

AssignedUsersTab.propTypes = {
  roleDetails: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired
}

export { AssignedUsersTab }
