// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { Box, CheckBox, FormField } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useNavigate, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  Logo,
  ModalDialog,
  ModalFooter,
  Typography,
  CCSForm,
  Anchor,
  Dropdown
} from '../../../components'
import { get } from '../../../utils/api-utils'
import { appTermRedirection } from '../utils'
import { displayNotification } from '../../../utils/notificiation-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import { doProvisionAppAction, getRegionNameByRegionCode } from '../utils/utils'
import {
  useAppCatalogContext,
  AppCatalogActions
} from '../../../context/app-catalog-context'
import { isCoP } from '../../../utils/feature-flag-utils'
import ArubaImage from '../../../images/aruba.png'
import { navigateToServicePageViaSubscription } from '../../../utils/common-utils'

const AddApplicationModal = ({
  handleClose,
  appId,
  appName = '',
  appSlug = '',
  setShowErrorNotification,
  fromServiceSubs = false,
  modalTitle,
  modalSubtitle,
  addBtnText
}) => {
  const { t } = useTranslation(['common', 'apps'])
  const appTermLink = appTermRedirection(appName, t)
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const location = useLocation()
  const LDFlags = useFlags()
  const { regionListData, dispatchAppCatalogContext } = useAppCatalogContext()
  const [appData, setAppData] = useState([])
  const [checked, setChecked] = useState(false)
  const [appInfo, setAppInfo] = useState({})
  const [deploymentRegionOptions, setDeploymentRegionOptions] = useState([])
  const [deploymentRegion, setDeploymentRegion] = useState('')
  const [showNotification, setShowNotification] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    async function setRegions() {
      await get('/geo/ui/v1/regions', {}, oidcUser.access_token).then(
        (response) => {
          const { regions } = response.data
          if (regions) {
            dispatchAppCatalogContext({
              type: AppCatalogActions.SET_REGION_LIST,
              data: regions
            })
          } else {
            console.error(t('apps:no_regions_defined'))
            setShowNotification(
              displayNotification(
                t('apps:no_regions_defined'),
                'warning',
                setShowNotification
              )
            )
          }
        },
        (error) => {
          setShowNotification(displayApiError(error, t, setShowNotification))
        }
      )
    }
    if (regionListData.length === 0) {
      setRegions()
    }
  }, [oidcUser.access_token, dispatchAppCatalogContext, regionListData, t])

  const provisionStatus = [
    'PROVISION_FAILED',
    'UNPROVISIONED',
    'UNPROVISION_FAILED',
    ''
  ]

  useEffect(() => {
    get(
      `/ui-doorway/ui/v1/applications/provisions`,
      {
        application_id: appId
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (response.status === 200) {
          setAppData(response.data.provisions)
          const deploymentRegionDropdownOptions = response.data.provisions
            .filter((provision) =>
              provisionStatus.includes(provision.provision_status)
            )
            .map((provisionData) => ({
              label: getRegionNameByRegionCode(
                provisionData.region,
                regionListData
              ),
              value: provisionData.region
            }))
          setDeploymentRegionOptions(deploymentRegionDropdownOptions)
        }
      },
      (error) => {
        handleClose()
        setShowErrorNotification(
          displayApiError(error, t, setShowErrorNotification)
        )
      }
    )
  }, [regionListData]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRedirect = () => {
    if (location?.pathname === `/applications/installed-apps/${appId}`) {
      handleClose()
    } else if (fromServiceSubs) {
      navigateToServicePageViaSubscription({
        navigate,
        appId,
        appName,
        appSlug,
        LDFlags,
        oidcUser
      })
    } else {
      navigate(`/applications/installed-apps/${appId}`)
    }
  }

  const provisionApp = () => {
    doProvisionAppAction(
      appInfo,
      appInfo.region,
      oidcUser.access_token,
      () => {},
      () => {},
      setShowNotification,
      t,
      handleRedirect,
      false,
      undefined,
      () => {},
      undefined
    )
  }

  const invokeError = () => {
    setErrorMessage(t('apps:terms_of_service_text'))
  }

  const handleAddRegion = () => {
    if (checked) {
      provisionApp()
    } else {
      invokeError()
    }
  }

  const handleTerms = (value) => {
    setChecked(value)
    setErrorMessage('')
  }

  return (
    <>
      {showNotification !== null && showNotification}
      <ModalDialog
        content={
          <Box>
            <Box
              direction="column"
              align="center"
              margin={{ top: 'small', bottom: 'medium' }}
            >
              <Logo
                testId="logo"
                size="medium"
                url={isCoP() ? ArubaImage : appData[0]?.logo || ''}
              />
              <Box align="center" gap="xsmall" margin={{ top: 'medium' }}>
                <Typography testId="modal-title" type="heading" level={2}>
                  {modalTitle}
                </Typography>
                <Typography
                  size="large"
                  testId="modal-subtitle"
                  type="paragraph"
                >
                  {modalSubtitle}
                </Typography>
              </Box>
            </Box>

            <Box width="large" margin={{ bottom: 'medium' }}>
              <FormField
                label={t('apps:deployment_region')}
                name="deployment-region"
              >
                <Dropdown
                  name="deployment-region"
                  testId="deployment-region-dropdown"
                  noBorder
                  placeholder={t('apps:select_region')}
                  value={deploymentRegion}
                  options={deploymentRegionOptions}
                  onChangeDropdown={(values) => {
                    const appDataInfo = appData.find((obj) => {
                      return obj.region === values
                    })
                    setAppInfo(appDataInfo)
                    setDeploymentRegion(values)
                  }}
                />
              </FormField>
            </Box>

            <Box gap="medium">
              <CCSForm errorMessage={errorMessage} testId="app-term-form">
                <FormField>
                  <CheckBox
                    id="app-term-checkbox"
                    data-testid="app-term-checkbox"
                    name="app_terms"
                    checked={checked}
                    label={
                      <Typography
                        type="text"
                        size="medium"
                        testId="app-terms-checkbox-label"
                      >
                        <Trans
                          i18nKey="apps:terms_of_service_confirm_text"
                          t={t}
                        >
                          <Anchor
                            label={t('apps:terms_of_service')}
                            href={appTermLink}
                            target="_blank"
                            testId="app-terms-anchor"
                          />
                        </Trans>
                      </Typography>
                    }
                    onChange={(event) => handleTerms(event.target.checked)}
                  />
                </FormField>
              </CCSForm>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={
              <ButtonGroup
                buttonList={[
                  {
                    id: 2,
                    label: t('apps:cancel'),
                    default: true,
                    testId: 'cancel-modal-btn',
                    onClick: handleClose
                  },
                  {
                    id: 1,
                    label: addBtnText,
                    primary: true,
                    testId: 'add-region-btn',
                    onClick: handleAddRegion
                  }
                ]}
                testId="two-buttons"
              />
            }
          />
        }
        onClose={handleClose}
        testId="add-application-modal"
        width="large"
      />
    </>
  )
}

AddApplicationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  appName: PropTypes.string,
  appSlug: PropTypes.string,
  setShowErrorNotification: PropTypes.func.isRequired,
  fromServiceSubs: PropTypes.bool,
  modalTitle: PropTypes.string.isRequired,
  modalSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  addBtnText: PropTypes.string.isRequired
}
export { AddApplicationModal }
