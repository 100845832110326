// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { FormTrash } from 'grommet-icons'

import { DataTable } from '../../../../components'

export const TableItemAdder = ({
  addForm,
  itemList = [],
  columns,
  onDelete,
  testId,
  height = 'medium'
}) => {
  return (
    <Box data-testid={testId}>
      {addForm}
      {itemList?.length > 0 && (
        <Box overflow="auto" height={height} margin={{ top: 'medium' }}>
          <DataTable
            grid={{
              columns,
              data: itemList,
              defaultActions: [
                {
                  icon: <FormTrash />,
                  onClick: (datum) => onDelete(datum),
                  testId: 'delete-btn'
                }
              ],
              pin: 'header'
            }}
            testId="add-table"
          />
        </Box>
      )}
    </Box>
  )
}

TableItemAdder.propTypes = {
  /**
   * Add Component of type AddForm
   */
  addForm: PropTypes.element.isRequired,

  /**
   * Table data
   */
  itemList: PropTypes.arrayOf(PropTypes.object),

  /**
   * Table Column(s)
   */
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Delete handler
   */
  onDelete: PropTypes.func.isRequired,

  /**
   * Test id
   */
  testId: PropTypes.string.isRequired,

  /**
   * Height of the table
   */
  height: PropTypes.string
}
