// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Accordion, AccordionPanel, Box, FormField } from 'grommet'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  Notification,
  CCSForm,
  Dropdown,
  Button
} from '../../components'
import { get, post, patch } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { displayDeviceTypeEnum } from '../../utils/common-utils'

import LocationInfo from './components/LocationInfo'
import { getLocationDetails } from './utils/api-utils'
import { NewLocationDetailsSection } from './components'

const NewManageLocationModal = ({
  setModalOpen,
  datum,
  openSuccessModal,
  isCCSManager = false,
  customerId = undefined
}) => {
  const { t } = useTranslation(['device', 'common'])
  const { oidcUser } = useReactOidc()
  const [assignedDevicesCount, setAssignedDevicesCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [listOfLocation, setListOfLocation] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [formError, setFormError] = useState('')
  const [appendList, setAppendList] = useState(true)
  // for paginating on scroll location
  const itemsPerPage = 50
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [locationDetails, setLocationDetails] = useState({})
  const [pickedLocationDetails, setPickedLocationDetails] = useState({})
  const [locationDatum, setLocationDatum] = useState(datum)
  const removeLocation = () => {
    const updatedLocationDatum = locationDatum?.map((device) => ({
      ...device,
      location_id: '',
      location_name: ''
    }))
    setLocationDatum(updatedLocationDatum)
  }

  const handleDebouncedSearchValue = debounce((value) => {
    setAppendList(false)
    setPage(0)
    setSearchTerm(value)
  }, 500)

  const getLocationCount = (data) => {
    const uniqueLocationIds = new Set(data.map((item) => item.location_id))
    return uniqueLocationIds?.size
  }
  useEffect(() => {
    const locationId = locationDatum[0]?.location_id
    // To fetch assigned location details
    if (getLocationCount(locationDatum) === 1 && locationId) {
      const accessToken = oidcUser?.access_token
      getLocationDetails(
        locationId,
        accessToken,
        setErrorMessage,
        t,
        isCCSManager,
        customerId
      ).then((resp) => {
        if (resp) setPickedLocationDetails(resp)
        else setPickedLocationDetails({})
      })
    } else setPickedLocationDetails({})

    const url = isCCSManager
      ? `/support-assistant/v1alpha1/stats`
      : '/ui-doorway/ui/v1/devices/stats'

    const reqBody = {
      devices:
        locationDatum?.map((value) => {
          return {
            serial_number: value.serial_number,
            part_number: value.part_number,
            mac_address: value.mac_address || ''
          }
        }) || [],
      filter: { assigned_location: true }
    }
    if (isCCSManager) reqBody.platform_customer_id = customerId
    post(url, reqBody, oidcUser.access_token).then(
      (response) => {
        if (response?.data?.count) {
          setAssignedDevicesCount(response?.data?.count)
        }
      },
      () => {
        setAssignedDevicesCount('ERROR')
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // API call to get list of contact
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/locations`
      : '/ui-doorway/ui/v1/locations'

    const request = {
      page_size: itemsPerPage,
      page_id: page || 1,
      ...(searchTerm?.trimStart()?.length && {
        search_string: searchTerm?.trimStart()
      })
    }
    if (isCCSManager) request.platform_customer_id = customerId
    get(url, request, oidcUser.access_token).then(
      (response) => {
        const locationResponse = response?.data?.data
        if (locationResponse) {
          let modifiedLocationResponse = []
          if (appendList) {
            modifiedLocationResponse = [...listOfLocation, ...locationResponse]
            if (selectedLocation)
              modifiedLocationResponse = [
                ...modifiedLocationResponse,
                selectedLocation
              ]
            modifiedLocationResponse = uniqBy(modifiedLocationResponse, 'id')
          } else modifiedLocationResponse = locationResponse
          setListOfLocation(modifiedLocationResponse)
          setTotalItems(response?.data?.pagination?.total_count || totalItems)
        }
      },
      (error) => {
        setErrorMessage(displayApiError(error, t, setErrorMessage))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, page, searchTerm, t])

  useEffect(() => {
    const emptyLocationObject = {
      name: '--',
      description: '--',
      primary_contact: '--',
      street_address: '--',
      shipping_receiving: '--'
    }
    const locationId = selectedLocation?.id
    // To fetch assigned location details
    if (locationId) {
      const accessToken = oidcUser?.access_token
      getLocationDetails(
        locationId,
        accessToken,
        setErrorMessage,
        t,
        isCCSManager,
        customerId
      ).then((resp) => {
        if (resp) setLocationDetails(resp)
        else setLocationDetails(emptyLocationObject)
      })
    } else setLocationDetails(emptyLocationObject)
  }, [oidcUser.access_token, selectedLocation?.id, t, isCCSManager, customerId])

  const handleSave = () => {
    // API call to assign location to selected devices
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/devices`
      : '/ui-doorway/ui/v1/devices'

    const reqBody = {
      devices:
        locationDatum?.map((value) => {
          return {
            serial_number: value?.serial_number,
            part_number: value?.part_number,
            ...([
              displayDeviceTypeEnum.STORAGE,
              displayDeviceTypeEnum.DHCI_STORAGE,
              displayDeviceTypeEnum.COMPUTE,
              displayDeviceTypeEnum.DHCI_COMPUTE,
              displayDeviceTypeEnum.PCE
            ].includes(value?.display_device_type)
              ? {}
              : {
                  mac_address: value?.mac_address
                }),

            device_type: value?.device_type,
            location_id: selectedLocation
              ? selectedLocation?.id
              : value?.location_id,
            location_name: selectedLocation
              ? selectedLocation?.name
              : value?.location_name
          }
        }) || []
    }
    if (isCCSManager) reqBody.platform_customer_id = customerId
    patch(url, reqBody, oidcUser.access_token).then(
      () => {
        setModalOpen(false)
        openSuccessModal()
      },
      (error) => {
        setErrorMessage(displayApiError(error, t, setErrorMessage))
      }
    )
  }

  const getLocationDetailsContent = () => {
    const numberOfLocations = getLocationCount(locationDatum)
    let element
    if (numberOfLocations === 1 && locationDatum[0]?.location_id) {
      element = (
        <Accordion data-testid="location-accordion">
          <AccordionPanel
            data-testid="location-accordion-panel"
            label={pickedLocationDetails?.name}
          >
            <Box pad="medium">
              <LocationInfo pickedLocationDetails={pickedLocationDetails} />
            </Box>
          </AccordionPanel>
        </Accordion>
      )
    } else if (numberOfLocations > 1) {
      element = (
        <Typography type="text" testId="multiple-locations">
          {t('multiple_location')}
        </Typography>
      )
    } else {
      element = (
        <Typography type="text" testId="no-location">
          {t('no_location')}
        </Typography>
      )
    }
    return element
  }

  const buttonList = [
    {
      label: t('cancel'),
      default: true,
      testId: 'cancel-btn',
      onClick: () => setModalOpen(false)
    },
    {
      label: t('common:save'),
      primary: true,
      testId: 'save-btn',
      onClick: handleSave
    }
  ]

  return (
    <>
      <ModalDialog
        width="large"
        height="large"
        testId="manage-location-modal-dialog"
        onClose={() => {
          setModalOpen(false)
        }}
        header={
          <ModalHeader
            title={
              <Typography
                type="heading"
                level="1"
                weight="bold"
                style={{
                  whiteSpace: 'nowrap'
                }}
                testId="manage-location-modal-title"
              >
                {t('manage_location')}
              </Typography>
            }
          />
        }
        content={
          <Box flex={{ shrink: 0 }} gap="small">
            <Typography
              type="text"
              size="large"
              testId="manage-location-modal-subtitle"
            >
              {t('manage_locations_dlg_prompt')}
            </Typography>
            {assignedDevicesCount ? (
              <Box margin={{ top: 'medium', bottom: 'xxsmall' }}>
                <Notification
                  backgroundColor="status-warning"
                  testId="warning-inline-notification"
                  type="inline"
                  text={
                    (assignedDevicesCount === 'ERROR' && // NOSONAR
                      (locationDatum?.length > 1
                        ? t('assign_location_status_error_multi')
                        : t('assign_location_status_error_single'))) ||
                    (assignedDevicesCount === 1 &&
                      datum?.length === 1 &&
                      t('single_device_location_assigned')) || (
                      <Trans i18nKey="new_multi_device_assigned" t={t}>
                        <strong>
                          {{ devicesAssigned: assignedDevicesCount }}
                        </strong>
                        <strong>{{ selectedDevices: datum?.length }}</strong>
                      </Trans>
                    )
                  }
                />
              </Box>
            ) : null}
            <Box justify="between" direction="row" align="center">
              <Typography
                type="heading"
                size="small"
                testId="current-assigned-location"
              >
                {t('current_assigned_location')}
              </Typography>
              {(getLocationCount(locationDatum) > 1 ||
                locationDatum[0]?.location_id) && (
                <Button
                  label={t('remove_current_location')}
                  testId="remove-location"
                  secondary
                  onClick={removeLocation}
                  size="medium"
                />
              )}
            </Box>
            {getLocationDetailsContent()}
            <Box
              justify="between"
              direction="row"
              align="center"
              margin={{ top: 'small' }}
            >
              <Typography type="heading" size="small" testId="change-to">
                {t('change_to')}
              </Typography>
            </Box>

            <Box>
              <CCSForm
                justify="start"
                validate="submit"
                errorMessage=""
                testId="add-form"
              >
                <FormField
                  data-testid="location-form-field"
                  label={t('assign_location')}
                  name="assign_location"
                  width="large"
                  required={{ indicator: false }}
                  error={formError || undefined}
                >
                  <Dropdown
                    name="assign_location"
                    options={listOfLocation}
                    labelKey="name"
                    valueKey="id"
                    value={selectedLocation || ''}
                    searchPlaceholder={t('search')}
                    emptySearchMessage={t('no_data_found')}
                    onSearch={(searchText) => {
                      handleDebouncedSearchValue(searchText)
                    }}
                    onChange={({ option }) => {
                      setFormError('')
                      setSelectedLocation(option)
                    }}
                    noBorder
                    onClose={() => {
                      if (searchTerm) {
                        setListOfLocation([])
                        setPage(0)
                        setSearchTerm('')
                        setAppendList(true)
                      }
                    }}
                    testId="location-input"
                    onMore={() => {
                      if (
                        totalItems > itemsPerPage &&
                        page < totalItems / itemsPerPage
                      ) {
                        setAppendList(true)
                        setPage(page + 1)
                      }
                    }}
                    placeholder={t('select_item')}
                  />
                </FormField>
              </CCSForm>

              <NewLocationDetailsSection
                data={locationDetails}
                title={t('selected_location_detail')}
                placeholder={t('select_location_to_assign')}
              />
            </Box>
          </Box>
        }
        footer={
          <ModalFooter
            right={<ButtonGroup buttonList={buttonList} testId="modal-btns" />}
          />
        }
      />
      {errorMessage}
    </>
  )
}

NewManageLocationModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  datum: PropTypes.array.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string
}

export { NewManageLocationModal }
