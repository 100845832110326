// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useNavigate } from 'react-router-dom'

import { Loader } from '../../../components'
import { del, get, post } from '../../../utils/api-utils'
import { displayApiError } from '../../../utils/error-handling-utils'
import {
  DeleteTenantAccountModal,
  RemoveTenantApplicationModal,
  UnassignTenantDevicesModal,
  UnableToDeleteTenantAccountModal
} from '../delete-customer'

const DeleteCustomer = ({ customer, setDeleteCustomer, onSuccess }) => {
  const { t } = useTranslation(['authn', 'common'])
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()

  // Pagination for fetch devices list post api
  const limit = 1
  const offset = 0

  // To delete Customer
  const [assignedDeviceCount, setAssignedDeviceCount] = useState(null)
  const [unassignDevicesModal, setUnassignDevicesModal] = useState(false)
  const [provisionedAppCount, setProvisionedAppCount] = useState(null)
  const [removeApplicationModal, setRemoveApplicationModal] = useState(false)
  const [deleteAccountModal, setDeleteAccountModal] = useState(false)
  const [errorNotification, setErrorNotification] = useState(null)
  const [unableToDeleteModal, setUnableToDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  // Below func is used to handle the Drop Button Delete Account Action
  // For CUSTOMER_ONWNED_INVENTORY tenant, It checks if Tenant has devices & subscriptions & provisoned App
  // For MSP_ONWNED_INVENTORY tenant, It checks if Tenant has assigned devices & provisoned App
  // If yes appropriate modal is displayed to take action else Delete Tenant Modal will be displayed
  const handleDropActionDeleteAccount = async (customerId) => {
    let checkProvisonedApp = false
    if (customer?.operational_mode === 'CUSTOMER_OWNED_INVENTORY') {
      await get(
        `/ui-doorway/ui/v1/msp/customers/${customerId}/can-delete`,
        {},
        oidcUser.access_token
      )
        .then(
          (response) => {
            if (response?.data?.can_be_deleted) {
              checkProvisonedApp = true
            } else {
              setUnableToDeleteModal(true)
            }
          },
          (error) => {
            setErrorNotification(error)
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      await post(
        `/ui-doorway/ui/v1/devices/filter?limit=${limit}&offset=${offset}`,
        {
          tenant_platform_customer_ids: [customerId],
          archive_visibility: 'HIDE_ARCHIVED',
          unassigned_only: false
        },
        oidcUser.access_token
      )
        .then(
          (response) => {
            if (response?.data?.pagination?.total_count) {
              setAssignedDeviceCount(response.data?.pagination?.total_count)
              setUnassignDevicesModal(true)
            } else {
              checkProvisonedApp = true
            }
          },
          (error) => {
            setErrorNotification(error)
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
    if (checkProvisonedApp) {
      setIsLoading(true)
      get(
        `/ui-doorway/ui/v1/applications/provisions/${customerId}`,
        { provision_status: 'PROVISIONED' },
        oidcUser.access_token
      )
        .then(
          (response) => {
            if (response?.data?.pagination?.total_count) {
              setProvisionedAppCount(response.data?.pagination?.total_count)
              setRemoveApplicationModal(true)
            } else {
              setDeleteAccountModal(true)
            }
          },
          (error) => {
            setErrorNotification(error)
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
  }
  // Below func is used to handle the Delete Account Button action in Delete Tenant Modal
  const handleModalDeleteAccountAction = () => {
    del(
      `/accounts/ui/v1/managed-service/tenant/${customer?.customer_id}`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        setDeleteAccountModal(false)
        onSuccess()
      },
      (error) => {
        setErrorNotification(error)
      }
    )
  }

  useEffect(() => {
    setIsLoading(true)
    handleDropActionDeleteAccount(customer?.customer_id)
  }, [customer?.customer_id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && (
        <Box direction="row" align="center" justify="center">
          <Loader type="default" testId="loader-modal" />
        </Box>
      )}
      {unassignDevicesModal && (
        <UnassignTenantDevicesModal
          handleCancel={() => {
            setUnassignDevicesModal(false)
            setDeleteCustomer(false)
          }}
          handleViewAssignedDevices={() =>
            navigate('/devices/inventory-list', {
              state: {
                accountFilter: [
                  {
                    customer_id: customer?.customer_id,
                    company_name: customer?.company_name
                  }
                ],
                archive_visibility: ['HIDE_ARCHIVED']
              }
            })
          }
          assignedDeviceCount={assignedDeviceCount}
        />
      )}
      {unableToDeleteModal && (
        <UnableToDeleteTenantAccountModal
          handleCancel={() => {
            setUnableToDeleteModal(false)
            setDeleteCustomer(false)
          }}
        />
      )}
      {removeApplicationModal && (
        <RemoveTenantApplicationModal
          handleClose={() => {
            setRemoveApplicationModal(false)
            setDeleteCustomer(false)
          }}
          customer={customer}
          provisionedAppCount={provisionedAppCount}
        />
      )}
      {deleteAccountModal && !isLoading && (
        <DeleteTenantAccountModal
          handleCancel={() => {
            setDeleteAccountModal(false)
            setDeleteCustomer(false)
          }}
          customer={customer}
          handleDeleteCustomerAccount={handleModalDeleteAccountAction}
        />
      )}
      {errorNotification &&
        displayApiError(errorNotification, t, setErrorNotification)}
    </>
  )
}

DeleteCustomer.propTypes = {
  customer: PropTypes.object.isRequired,
  setDeleteCustomer: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export { DeleteCustomer }
