// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { useParams } from 'react-router-dom'

import { Typography } from '../../../components'
import { EmailSent } from '../../../icons'
import { Layout } from '../../../commoncomponents/layout/Layout'

const VerifyEmail = () => {
  const { email } = useParams()
  const messageText = (
    <>
      A confirmation email has been sent to <strong>{email}</strong>
    </>
  )
  return (
    <Box
      fill
      style={{ minHeight: '88vh' }}
      justify-content="center"
      align-items="flex-start"
    >
      <Box width="xlarge" margin={{ left: 'xlarge' }} pad={{ left: 'large' }}>
        <EmailSent />
        <Typography
          type="heading"
          level="1"
          style={{ margin: '36px 0px 20px 0px' }}
          testId="verify-email-header"
        >
          Verify your email to complete sign up
        </Typography>
        <Typography type="text" size="xlarge" testId="verify-email-subheader">
          {messageText}
        </Typography>
      </Box>
    </Box>
  )
}

const VerifyEmailPage = () => {
  return (
    <Layout hideHeaderOptions={['no-header']}>
      <VerifyEmail />
    </Layout>
  )
}

export default VerifyEmailPage
