// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box } from 'grommet'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import dayjs from 'dayjs'

import {
  Anchor,
  Button,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ObjectList,
  Typography
} from '../../../../../components'
import { renameKeys } from '../../../../../utils/common-utils'
import { AppImages } from '../../../../applications/app-details/components'

const InstanceDetailsModal = ({
  instanceDetails: instanceData,
  setShowModal
}) => {
  const { t } = useTranslation(['manage'])
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const instanceDetails = {
    ...omit(instanceData, [
      'languages',
      'rule',
      'created_at',
      'updated_at',
      'cloud_provider'
    ]),
    languages: instanceData?.languages?.join(', '),
    rule: {
      rule_description: instanceData?.rule?.rule_description,
      countries_groups_included:
        instanceData?.rule?.countries_groups_included?.join(','),
      countries_groups_excluded:
        instanceData?.rule?.countries_groups_excluded?.join(', '),
      customers_included: instanceData?.rule?.customers_included?.join(', '),
      customers_excluded: instanceData?.rule?.customers_excluded?.join(', '),
      email_domains_included:
        instanceData?.rule?.email_domains_included?.join(', '),
      email_domains_excluded:
        instanceData?.rule?.email_domains_excluded?.join(', ')
    },
    cloud_provider: {
      name: instanceData?.cloud_provider?.name,
      region: instanceData?.cloud_provider?.region,
      location: instanceData?.cloud_provider?.location,
      zone: instanceData?.cloud_provider?.zone
    },
    created_at: instanceData?.created_at
      ? dayjs(instanceData?.created_at).format('MM/DD/YYYY HH:mm')
      : null,
    updated_at: instanceData?.updated_at
      ? dayjs(instanceData?.updated_at).format('MM/DD/YYYY HH:mm')
      : null
  }

  const otherDetails = [
    'operational_status',
    'operational_summary',
    'version',
    'languages',
    'docs',
    'created_at',
    'updated_at'
  ]

  const renderDetails = ({
    title = null,
    data = {},
    customRender = null,
    testId
  }) => {
    const keysMap = {}
    Object.keys(data).forEach((key) => {
      keysMap[key] = t(`applications.${key}`)
    })
    return (
      <Box>
        {title && (
          <Box
            border={{ side: 'bottom', color: 'border-weak' }}
            margin={{ bottom: 'small' }}
          >
            <Box margin={{ bottom: 'small' }}>
              <Typography level="4" type="heading" testId="details-header">
                {title}
              </Typography>
            </Box>
          </Box>
        )}
        {customRender || (
          <ObjectList
            data={renameKeys(keysMap, data)}
            testId={testId}
            pad={{ vertical: 'xsmall' }}
          />
        )}
      </Box>
    )
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Box pad="small" margin={{ bottom: 'small' }}>
              <Typography
                type="heading"
                level="1"
                testId="app-instance-modal-header-title"
              >
                {t('applications.app_instance_details')}
              </Typography>
            </Box>
          }
        />
      }
      content={
        <Box
          pad={{ horizontal: 'medium', vertical: 'small' }}
          gap="medium"
          overflow="auto"
          flex={{ shrink: 0 }}
        >
          <Box>
            {renderDetails({
              data: pick(instanceDetails, ['instance_id']),
              testId: 'instance-id'
            })}
            <Box pad={{ vertical: 'xsmall' }} direction="row" align="start">
              <Box width="small" flex={false}>
                <Typography type="text" size="xsmall" testId="obj-list-label">
                  {t('applications.application_id')}
                </Typography>
              </Box>
              <Box align="start">
                {pathname === '/manage-ccs/applications' ? (
                  <Anchor
                    testId="anchor-to-app-detail-page"
                    label={instanceDetails?.app_id}
                    href="#"
                    onClick={() => {
                      navigate(
                        `/manage-ccs/applications/${instanceDetails?.app_id}`
                      )
                    }}
                  />
                ) : (
                  <Typography type="text" size="medium" testId="obj-list-value">
                    {instanceDetails?.app_id}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          {renderDetails({
            title: t('details'),
            data: omit(instanceDetails, [
              'cloud_provider',
              'rule',
              'screenshots',
              ...otherDetails
            ]),
            testId: 'details'
          })}
          {renderDetails({
            title: t('applications.screenshots'),
            customRender: instanceDetails?.screenshots?.length ? (
              <AppImages urls={instanceDetails?.screenshots} />
            ) : (
              <Typography type="text" testId="no-screenshots">
                {t('applications.no_screenshots_available')}
              </Typography>
            ),
            testId: 'screenshot'
          })}
          {renderDetails({
            title: t('applications.cloud_provider'),
            data: instanceDetails?.cloud_provider,
            testId: 'cloud-provider'
          })}
          {renderDetails({
            title: t('applications.rule'),
            data: instanceDetails?.rule,
            testId: 'rule'
          })}
          {renderDetails({
            title: t('applications.other_details'),
            data: pick(instanceDetails, otherDetails),
            testId: 'other-details'
          })}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <Box pad="small">
              <Button
                label={t('close')}
                primary
                testId="close-btn"
                onClick={() => setShowModal(false)}
              />
            </Box>
          }
        />
      }
      onClose={() => setShowModal(false)}
      testId="app-instance-details-modal-dialog"
      width="xlarge"
      height="large"
    />
  )
}
InstanceDetailsModal.propTypes = {
  instanceDetails: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired
}

export { InstanceDetailsModal }
