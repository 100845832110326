// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Loader,
  Notification,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../../components'
import Markdown from '../../../../commoncomponents/Markdown'
import { get, getErrorMessage } from '../../../../utils/api-utils'

import CheckPrerequisiteModal from './CheckPrerequisiteModal'

const CheckOrgGovernanceDeletePrereqModel = ({
  onValidateSuccess,
  onValidateFail,
  workspaceName,
  orgName,
  setNotificationInfo
}) => {
  const { t } = useTranslation(['common', 'iam'])

  const [loading, setLoading] = useState(true)
  const [rootWorkspaceMsg, setRootWorkspaceMsg] = useState(false)
  const [prereqMsg, setPrereqMsg] = useState(false)
  const checkOk = !loading && !rootWorkspaceMsg && !prereqMsg

  const getContent = () => {
    return (
      <>
        {loading && (
          <Box
            width="medium"
            direction="column"
            justify="center"
            align="center"
            margin={{ top: 'medium' }}
          >
            <Box direction="row" gap="small">
              <Loader size="18px" testId="prereq-loader" />
              <Typography type="text" size="medium">
                {t('iam:delete_workspace.prereq_load')}
              </Typography>
            </Box>
          </Box>
        )}
        {!loading && rootWorkspaceMsg && (
          <Box gap="small" margin={{ top: 'medium' }}>
            <Notification
              type="inline"
              text={t('common:cannot_perform_action')}
              status="critical"
              testId="delete-wksp-warning"
            />
            <Markdown data-testId="prereq-delete-root-workspace-msg">
              {t('iam:delete_workspace.prereq_delete_root_workspace', {
                workspaceName,
                orgName,
                workspace: t('common:business_object.wkspc'),
                workspaces: t('common:business_object.wkspc_plural')
              })}
            </Markdown>
          </Box>
        )}
      </>
    )
  }

  useEffect(() => {
    if (checkOk) {
      onValidateSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkOk])

  useEffect(() => {
    let isCurrent = true
    // If the workspace selected for deletion is the root workspace AND it has are child workspaces, BLOCK the DELETE action.
    setLoading(true)
    get(`/organizations/v2alpha1/workspaces?offset=0&limit=2`).then(
      (res) => {
        if (!isCurrent) return
        setRootWorkspaceMsg(res?.data?.total > 1)
        setLoading(false)
        if (res?.data?.total === 1) {
          setPrereqMsg(true)
        }
      },
      (err) => {
        if (!isCurrent) return
        setLoading(false)
        setNotificationInfo(getErrorMessage(err, t), 'error')
      }
    )
    return () => {
      isCurrent = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getModalContent = () => {
    const content = rootWorkspaceMsg ? (
      <ModalDialog
        content={getContent()}
        // TODO refactor the CheckPrerequisiteModal to handle both
        // org and non-org workspaces
        header={
          <ModalHeader
            title={
              <Typography type="heading" level="2">
                {t('iam:delete_workspace.title', {
                  workspace: t('common:business_object.wkspc')
                })}
              </Typography>
            }
          />
        }
        footer={
          <ModalFooter
            right={
              <Button
                primary
                width="small"
                label={t('common:close')}
                onClick={onValidateFail}
                testId="delete-wksp-close-btn"
              />
            }
          />
        }
        onClose={onValidateFail}
        onClickOutside={onValidateFail}
        testId="check-prerequisite-modal"
      />
    ) : (
      <CheckPrerequisiteModal
        onValidateSuccess={() => setPrereqMsg(false)}
        onValidateFail={() => {
          setPrereqMsg(false)
          onValidateFail()
        }}
        workspaceName={workspaceName}
        setNotificationInfo={setNotificationInfo}
      />
    )
    return content
  }

  return getModalContent()
}

CheckOrgGovernanceDeletePrereqModel.propTypes = {
  /**
   * callback if prerequisite check is ok
   */
  onValidateSuccess: PropTypes.func.isRequired,

  /**
   * callback if prerequisite check fails
   */
  onValidateFail: PropTypes.func.isRequired,

  /**
   * Workspace name to display in warning
   */
  workspaceName: PropTypes.string,
  /**
   * Display notification
   */
  setNotificationInfo: PropTypes.func,

  /**
   * Organization name
   */
  orgName: PropTypes.string
}

CheckOrgGovernanceDeletePrereqModel.defaultProps = {
  workspaceName: '',
  setNotificationInfo: () => {},
  orgName: ''
}

export default CheckOrgGovernanceDeletePrereqModel
