// Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import { UsageMonitoringContextProvider } from '../../../context/usage-monitoring-context'

const UsageReportPage = lazy(() => import('./UsageReportPage'))

const UsageMonitoringRouterContent = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route exact path="/" element={<UsageReportPage />} />
      </Routes>
    </Suspense>
  )
}

const UsageMonitoringRouter = () => {
  return (
    <UsageMonitoringContextProvider>
      <UsageMonitoringRouterContent />
    </UsageMonitoringContextProvider>
  )
}

export default UsageMonitoringRouter
