// Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box } from 'grommet'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ActionButton, Loader, Typography, Button } from '../../../components'
import {
  doLaunchAppAction,
  doUnprovisionAppAction,
  getRegionNameByRegionCode,
  doRetryConversion,
  doDeleteAppAction,
  doDeleteServiceAction,
  doRetryServiceAction
} from '../utils/utils'
import { usePollProvision } from '../utils/usePollProvision'
import {
  useAppCatalogContext,
  AppCatalogActions
} from '../../../context/app-catalog-context'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import {
  getCustomerAccount,
  getMSPCustomerAccount,
  isInventoryOwnedWorkspace,
  isGLOP,
  isMSP,
  isTenant
} from '../../../utils/feature-flag-utils'
import { post } from '../../../utils/api-utils'
import { isServiceCentric } from '../../../utils/account-utils'
import {
  SERVICES_DISABLE_LAUNCH_FROM_MSP,
  MSP
} from '../../../dashboard/constants'
import { Notification } from '../../../components/notification/Notification'

import { UnassignDevicesModal } from './UnassignDevicesModal'
import { RemoveRegionModal } from './RemoveRegionModal'
import { GoCustAcctModal } from './GoCustAcctModal'

const customRenderer = (actionBtn, visibility, idx) => {
  return (
    <VisibilityWrapper
      key={idx} // eslint-disable-line react/no-array-index-key
      rbac={visibility.rbac}
      hideFor={visibility.hideFor}
    >
      {actionBtn}
    </VisibilityWrapper>
  )
}

const InstalledAppList = ({
  appInfo,
  refreshAppList,
  appType,
  isServiceProvision = false
}) => {
  const { t } = useTranslation(['apps'])
  const { oidcUser } = useReactOidc()
  const LDFlags = useFlags()
  const navigate = useNavigate()

  const { regionListData, dispatchAppCatalogContext } = useAppCatalogContext()
  const swappedMSPAccount = getMSPCustomerAccount()

  const custAccountLoaded = getCustomerAccount()
  const accountType = custAccountLoaded?.account_type || ''
  const { platform_customer_id: pcid } = custAccountLoaded

  const newDashboardFlag = isServiceCentric(LDFlags)

  const [appStatus, setAppStatus] = useState({
    status: appInfo.provision_status
  })
  const [convStatus, setConvStatus] = useState({
    status: appInfo.msp_conversion_status || ''
  })
  const [customerId, setCustomerId] = useState(appInfo.application_customer_id)
  const [showNotification, setShowNotification] = useState(null)
  const [unassignDevicesModal, setUnassignDevicesModal] = useState(false)
  const [removeRegionModal, setRemoveRegionModal] = useState(false)
  const [assignedDeviceCount, setAsignedDeviceCount] = useState(null)
  const [show409Msg, setShow409Msg] = useState(false)
  const [isGLC] = useState(appInfo?.slug === 'GLC')
  const redirectToCustAcct = () => {
    setShow409Msg(false)
    navigate('/customer-account')
  }

  const tenantOnlySupported = appInfo?.tenant_only_supported
  const showLaunchAction = !(accountType === 'MSP' && tenantOnlySupported)
  // pagination for post call to fetch devices list
  const limit = 1
  const offset = 0

  const refreshFunc =
    newDashboardFlag || isGLOP() ? refreshAppList : setAppStatus

  usePollProvision(
    appStatus,
    customerId,
    refreshFunc,
    oidcUser.access_token,
    isServiceProvision ? appInfo.id : undefined
  )

  const unprovisionApp = () => {
    if (isServiceProvision) {
      doDeleteServiceAction(
        appInfo,
        oidcUser.access_token,
        setAppStatus,
        setCustomerId,
        setShowNotification,
        setShow409Msg,
        t,
        isServiceProvision
      )
    } else if (appInfo.provision_status === 'PROVISION_FAILED') {
      doDeleteAppAction(
        appInfo,
        oidcUser.access_token,
        setAppStatus,
        setCustomerId,
        setShowNotification,
        setShow409Msg,
        t,
        isServiceProvision
      )
    } else {
      doUnprovisionAppAction(
        appInfo,
        oidcUser.access_token,
        setAppStatus,
        setCustomerId,
        setShowNotification,
        setShow409Msg,
        t
      )
    }
    setRemoveRegionModal(false)
  }

  useEffect(() => {
    setAppStatus({ status: appInfo.provision_status })
  }, [appInfo])

  useEffect(() => {
    if (
      appStatus.status === '' ||
      convStatus.status === 'MSP_CONVERSION_INITIATED'
    )
      refreshAppList()
  }, [appStatus, convStatus, refreshAppList])

  const handleRetry = () => {
    doRetryConversion(
      appInfo,
      oidcUser.access_token,
      setAppStatus,
      setConvStatus,
      setShowNotification,
      t
    )
  }

  const viewAppDetails = () => {
    const appDetails = {
      logo: appInfo.logo,
      name: appInfo.name,
      companyName: appInfo.company_name
    }
    sessionStorage.setItem('appDetails', JSON.stringify(appDetails))
    dispatchAppCatalogContext({
      type: AppCatalogActions.SET_APP_DETAIL,
      data: appDetails
    })
    navigate(
      `/applications/app-details/${appInfo.application_id}/${appInfo.region}`
    )
  }

  const devicesDetected = () => {
    const requestBody = {
      application_customer_ids: [appInfo.application_customer_id],
      archive_visibility: 'HIDE_ARCHIVED',
      unassigned_only: false
    }
    let url = `/ui-doorway/ui/v1/devices/filter?limit=${limit}&offset=${offset}`
    if (swappedMSPAccount && !isInventoryOwnedWorkspace())
      url += `&msp_platform_customer_id=${swappedMSPAccount?.platform_customer_id}`
    post(url, requestBody, oidcUser.access_token).then(
      (response) => {
        if (response?.data?.pagination?.total_count === 0) {
          setRemoveRegionModal(true)
        } else {
          setAsignedDeviceCount(response.data?.pagination?.total_count)
          setUnassignDevicesModal(true)
        }
      },
      (error) => {
        if (error.response.status === 404) {
          setRemoveRegionModal(true)
        }
      }
    )
  }

  const appActionsMap = {
    launchService: {
      label: t('launch'),
      hidden:
        (!newDashboardFlag && !isGLOP()) ||
        (accountType === MSP &&
          SERVICES_DISABLE_LAUNCH_FROM_MSP?.includes(appInfo?.serviceSlug)),
      testId: `${appInfo.slug}-${appInfo.region}-provision-list-launch-button`,
      visibility: {
        rbac: {
          permission: 'ccs.app-catalog.view',
          resource: '/ccs/app-catalog/application'
        }
      },
      onClick: () => {
        if (appInfo.redirectUrl) {
          window.open(appInfo.redirectUrl())
        } else {
          doLaunchAppAction(
            appInfo.application_instance_id,
            oidcUser.access_token,
            appInfo.application_customer_id,
            accountType,
            pcid,
            appInfo.slug,
            newDashboardFlag
          )
        }
      }
    },
    viewAppDetails: {
      label: t('view_app_details'),
      visibility: {
        rbac: {
          permission: 'ccs.app-catalog.view',
          resource: '/ccs/app-catalog/application'
        }
      },
      hidden: newDashboardFlag || isGLOP(),
      onClick: viewAppDetails
    },
    viewAssignedDevices: {
      label: t('view_assigned_devices'),
      visibility: {
        rbac: {
          permission: 'ccs.device-management.view',
          resource: '/ccs/device-management'
        }
      },
      onClick: () => {
        navigate('/devices/inventory', {
          state: {
            filterOptions: {
              application_customer_ids: [appInfo.application_customer_id],
              archive_visibility: ['HIDE_ARCHIVED']
            }
          }
        })
      },
      hidden:
        (isMSP() && !LDFlags['glcp-dm-msp-app-filter'] && !isGLOP()) ||
        isGLC ||
        !isInventoryOwnedWorkspace() ||
        appInfo.featuresSupported?.includes('HIDE_VIEW_ASSIGNED_DEVICES_BUTTON')
    },
    removeApplication: {
      label: t('remove_region'),
      testId: `${appInfo.slug}-${appInfo.region}-unprovision-button`,
      visibility: {
        rbac: {
          permission: isServiceProvision
            ? 'ccs.service-catalog.delete'
            : 'ccs.app-provision.edit',
          resource: isServiceProvision
            ? '/ccs/service-catalog/service-provision'
            : '/ccs/app-provision/provisions'
        },
        hideFor: { deployment: ['COP'] }
      },
      onClick: devicesDetected,
      hidden:
        (!isInventoryOwnedWorkspace() && !swappedMSPAccount) ||
        isGLC ||
        (isGLOP() && !isTenant()) ||
        appInfo?.features_supported?.includes(
          'SKIP_SERVICE_MGR_UNPROVISIONING'
        ) ||
        appInfo?.features_supported?.includes('SKIP_SERVICE_UNPROVISIONING')
    }
  }
  const renderRetryButton = () => {
    return (
      <Button
        secondary
        label={t('retry')}
        testId="retry-action-btn"
        onClick={() =>
          doRetryServiceAction(
            appInfo,
            appInfo.region,
            oidcUser.access_token,
            setAppStatus,
            setCustomerId,
            setShowNotification,
            t,
            undefined,
            isServiceProvision,
            appInfo.id
          )
        }
      />
    )
  }

  const renderAction = () => {
    if (convStatus.status === 'MSP_CONVERSION_INITIATED')
      return <Loader testId="loader-spinner" />

    if (appStatus.status === 'UNPROVISION_INITIATED')
      return (
        <Box direction="row" gap="medium" align="center">
          <Typography type="text" testId="removing-app">
            {t('removing_region')}
          </Typography>
          <Loader testId="loader-spinner" />
        </Box>
      )
    if (
      appType === 'failed' &&
      convStatus.status === 'MSP_CONVERSION_FAILED' &&
      !isGLC
    ) {
      return (
        <VisibilityWrapper
          rbac={{
            resource: '/ccs/accounts/platform/customer',
            permission: 'ccs.accounts.platform.customer.edit'
          }}
        >
          <Button
            label={t('retry')}
            secondary
            testId="tile-action-btn"
            onClick={handleRetry}
          />
        </VisibilityWrapper>
      )
    }
    // lowering Cognitive Complexity
    const isNotMSPbutProvisioned =
      accountType !== 'MSP' && appStatus.status === 'PROVISIONED'
    const isMSPAndProvisioned =
      accountType === 'MSP' &&
      appStatus.status === 'PROVISIONED' &&
      (convStatus.status === '' || convStatus.status === 'MSP_CONVERTED')

    if (isNotMSPbutProvisioned || isMSPAndProvisioned) {
      return (
        <Box direction="row" gap="medium">
          <ActionButton
            actions={[
              ...(appType === 'installed' && showLaunchAction
                ? [appActionsMap.launchService]
                : []),
              ...(appType === 'installed'
                ? [
                    appActionsMap.viewAppDetails,
                    appActionsMap.viewAssignedDevices
                  ]
                : []),
              appActionsMap.removeApplication
            ]}
            testId="installed-app-list-action-btn"
            dropAlign={{
              right: 'right',
              top: 'bottom'
            }}
            showOneActionAsDropDown
            customRenderer={customRenderer}
          />
          {newDashboardFlag || isGLOP() || (
            <Button
              primary
              label={t('launch')}
              testId="launch-action-btn"
              onClick={() =>
                doLaunchAppAction(
                  appInfo.application_instance_id,
                  oidcUser.access_token,
                  appInfo.application_customer_id,
                  accountType,
                  appInfo.slug
                )
              }
            />
          )}
        </Box>
      )
    }
    if (appStatus.status === 'PROVISION_INITIATED')
      return (
        <Box direction="row" gap="medium" align="center">
          <Typography type="text" testId="installing-app">
            {newDashboardFlag
              ? t('installing_service')
              : t('installing_application')}
          </Typography>
          <Loader testId="loader-spinner" />
        </Box>
      )
    if (appStatus.status === 'PROVISION_FAILED')
      return (
        <Box direction="row" gap="medium">
          <Box direction="row" align="center" gap="small">
            <Box
              width="10px"
              height="10px"
              background="status-critical"
              style={{ transform: 'rotate(45deg)' }}
            />
            <Typography type="text" size="medium" testId="installation-failed">
              {t('installation_failed')}
            </Typography>
          </Box>

          <Box direction="row" align="center" gap="small">
            <ActionButton
              actions={[appActionsMap.removeApplication]}
              testId="installed-app-list-action-btn"
              dropAlign={{
                right: 'right',
                top: 'bottom'
              }}
              showOneActionAsDropDown
              customRenderer={customRenderer}
            />
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/app-provision/provisions',
                permission: 'ccs.app-provision.edit'
              }}
            >
              {renderRetryButton()}
            </VisibilityWrapper>
          </Box>
        </Box>
      )
    return null
  }

  return (
    <>
      {showNotification !== null && showNotification}
      <Box
        width={newDashboardFlag || isGLOP() ? 'none' : 'large'}
        direction="row"
        align="center"
        border={{ side: 'bottom', color: 'border-weak' }}
        pad="medium"
        justify="between"
        data-testid={`installed-app-${appInfo.region}`}
      >
        {appStatus.status === 'PROVISION_FAILED' ? (
          <Box direction="column" gap="small" width="xxlarge">
            <Box direction="row" gap="xlarge" justify="between">
              <Typography emphasis type="text" testId="app-region-orgs-ui">
                {getRegionNameByRegionCode(appInfo.region, regionListData)}
              </Typography>
              {renderRetryButton()}
            </Box>
            {appInfo.reason ? (
              <Notification
                testId="installation-failed-message-reason"
                text={appInfo.reason}
                backgroundColor="status-critical"
                type="inline"
              />
            ) : (
              <Notification
                testId="installation-failed-message-default"
                text={t('installation_failed')}
                backgroundColor="status-critical"
                type="inline"
              />
            )}
          </Box>
        ) : (
          <>
            <Box direction="column" gap="xsmall">
              <Typography type="text" emphasis testId="app-name">
                {appInfo.name}
              </Typography>
              <VisibilityWrapper hideFor={{ deployment: ['COP'] }}>
                <Typography size="small" type="text" testId="app-region">
                  {getRegionNameByRegionCode(appInfo.region, regionListData)}
                </Typography>
              </VisibilityWrapper>
            </Box>
            {renderAction()}
          </>
        )}
      </Box>
      {unassignDevicesModal && (
        <UnassignDevicesModal
          handleCancel={() => setUnassignDevicesModal(false)}
          handleViewAssignedDevices={() =>
            navigate('/devices/inventory', {
              state: {
                filterOptions: {
                  application_customer_ids: [appInfo.application_customer_id],
                  archive_visibility: ['HIDE_ARCHIVED']
                }
              }
            })
          }
          assignedDeviceCount={assignedDeviceCount}
        />
      )}
      {removeRegionModal && (
        <RemoveRegionModal
          handleCancel={() => setRemoveRegionModal(false)}
          appRegion={getRegionNameByRegionCode(appInfo.region, regionListData)}
          appName={appInfo.name}
          appInfo={appInfo}
          unprovisionApp={unprovisionApp}
        />
      )}
      {show409Msg && (
        <GoCustAcctModal
          handleClose={() => setShow409Msg(false)}
          handleRedirect={redirectToCustAcct}
          appRegion={getRegionNameByRegionCode(appInfo.region, regionListData)}
          appName={appInfo.name}
        />
      )}
    </>
  )
}

InstalledAppList.propTypes = {
  appInfo: PropTypes.object.isRequired,
  refreshAppList: PropTypes.func.isRequired,
  appType: PropTypes.string.isRequired,
  isServiceProvision: PropTypes.bool,
  featuresSupported: PropTypes.array
}

export { InstalledAppList }
