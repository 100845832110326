// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { Box, FormField, Select } from 'grommet'
import { Alert, Lock } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import styled from 'styled-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button, Typography, CCSForm, Loader } from '../../../components'
import useLD from '../../../hooks/ld/useLD'
import { useCCSContext, CCSActions } from '../../../context/ccs-context'
import { useUPSContext } from '../../../context/ups-context'
import { get, getErrorMessage, put } from '../../../utils/api-utils'
import { useVisibilityContext } from '../../../context/visibility-context'
import {
  getCustomerAccount,
  setCustomerAccount
} from '../../../utils/feature-flag-utils'
import { loadCustomerAccount } from '../../../utils/account-utils'
import useLogger from '../../../hooks/logs/useLogger'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'

import NotificationContent from './notification-content'

const LoaderBox = styled(Box)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
`

const CountryUpdateForm = ({ admin }) => {
  const { oidcUser } = useReactOidc()
  const { dispatchCCSContext } = useCCSContext()
  const { dispatchUPSContext } = useUPSContext()
  const { dispatchVisibilityContext } = useVisibilityContext()
  const navigate = useNavigate()
  const { t } = useTranslation(['authn', 'common'])
  const [countries, setCountries] = useState([])
  const [accountCountries, setAccountCountries] = useState([])
  const [profileCountries, setProfileCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [loader, setLoader] = useState(false)
  const profileCountryUpdate = JSON.parse(
    sessionStorage.getItem('defaultedProfileCountryCode')
  )
  const postAprilContent = false
  const LDFlags = useFlags()
  const ld = useLD()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const custAccountLoaded = getCustomerAccount()
  const [account] = useState(custAccountLoaded)
  const logger = useLogger()

  const notification = {
    title: {
      postApril: t('authn:notification.post_april'),
      preApril: t('authn:notification.pre_april'),
      nonAdminAccount: t('authn:notification.non_admin_account')
    },
    messages: {
      admin: [
        t('authn:notification.admin_message_1', {
          account: getWorkspaceString(showWorkspaceString, t, WKSPC)
        }),
        t('authn:notification.admin_message_2', {
          account: getWorkspaceString(showWorkspaceString, t, WKSPC)
        })
      ],
      nonAdmin: [t('authn:notification.non_admin')],
      nonAdminAccount: [t('authn:notification.non_admin_account_messages')]
    }
  }

  const labels = {
    button: {
      account: t('authn:labels.button.account', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      }),
      profile: t('authn:labels.button.profile')
    },
    country: {
      account: t('authn:labels.country.account'),
      profile: t('authn:labels.country.profile')
    },
    heading: {
      account: t('authn:labels.heading.account', {
        account: getWorkspaceString(showWorkspaceString, t, WKSPC_CAPITALIZED)
      }),
      profile: t('authn:labels.heading.profile')
    },
    subHeading: {
      account: (
        <Trans i18nKey="authn:labels.subHeading.account" t={t}>
          <strong />
        </Trans>
      ),
      profile: (
        <Trans i18nKey="authn:labels.subHeading.profile" t={t}>
          <strong />
        </Trans>
      )
    }
  }

  useEffect(() => {
    get('/geo/ui/v1/countries', { status: 'AVAILABLE' }).then(
      (response) => {
        setCountries(response.data.countries)
        setAccountCountries(response.data.countries)
        setProfileCountries(response.data.countries)
      },
      (error) => {
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }, [
    setCountries,
    setAccountCountries,
    setProfileCountries,
    setErrorMessage,
    t
  ])

  const [state, dispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case 'CHANGE_FIELD':
          return { ...currentState, [action.field]: action.value }
        default:
          return currentState
      }
    },
    {
      account_country_code: '',
      profile_country_code: ''
    }
  )

  const handleSubmit = () => {
    setErrorMessage('')
    const promises = []
    if (profileCountryUpdate) {
      const profileReqBody = {
        country_code: state.profile_country_code
      }
      if (process.env.NODE_ENV === 'development') {
        profileReqBody.platform_cid = custAccountLoaded?.platform_customer_id
      }
      promises.push(
        put(
          '/accounts/ui/v1/user/profile/country',
          profileReqBody,
          oidcUser.access_token
        )
      )
    } else {
      promises.push(null)
    }
    if (admin && custAccountLoaded?.defaulted_country_code) {
      const accountReqBody = {
        company_name: account.company_name,
        phone_number: account.phone_number,
        address: {
          street_address: account.address.street_address,
          city: account.address.city,
          state_or_region: account.address.state_or_region,
          zip: account.address.zip,
          country_code: state.account_country_code
        }
      }
      // NOTE: API don't need the platform_customer_id
      // but the mockserver needs it to find the customer info from the schema
      if (process.env.NODE_ENV === 'development') {
        accountReqBody.platform_cid = custAccountLoaded?.platform_customer_id
      }
      if (account.email) {
        accountReqBody.email = account.email
      }

      promises.push(
        put(
          '/accounts/ui/v1/customer/profile/contact',
          accountReqBody,
          oidcUser.access_token
        )
      )
    } else {
      promises.push(null)
    }
    setLoader(true)
    Promise.all(promises).then(
      ([profileApi, accountApi]) => {
        if (profileApi) {
          dispatchCCSContext({
            type: CCSActions.SET_PROFILE_COUNTRY_UPDATE_FLAG,
            data: false
          })
          sessionStorage.setItem('defaultedProfileCountryCode', false)
        }
        let currentAccount = custAccountLoaded
        if (accountApi && custAccountLoaded) {
          currentAccount = {
            ...custAccountLoaded,
            defaulted_country_code: false
          }
          currentAccount.address = {
            ...currentAccount.address,
            country_code: state.account_country_code
          }
          setCustomerAccount(currentAccount)
        }

        if (currentAccount) {
          loadCustomerAccount(
            currentAccount,
            oidcUser,
            dispatchVisibilityContext,
            navigate,
            null,
            null,
            ld,
            dispatchUPSContext,
            logger
          ).finally(() => {
            ld.update(currentAccount)
            setLoader(false)
          })
        }
      },
      (error) => {
        setLoader(false)
        const backendErrorMessage = getErrorMessage(error, t)
        setErrorMessage(backendErrorMessage)
      }
    )
  }

  const onSearchAccountCountry = (searchText) => {
    const regexp = new RegExp(searchText, 'i')
    setAccountCountries(countries.filter((o) => o.name.match(regexp)))
  }

  const onSearchProfileCountry = (searchText) => {
    const regexp = new RegExp(searchText, 'i')
    setProfileCountries(countries.filter((o) => o.name.match(regexp)))
  }

  const onChange =
    (field) =>
    ({ option }) => {
      dispatch({
        field,
        value: option.code,
        type: 'CHANGE_FIELD'
      })
    }

  const onClose = () => {
    setAccountCountries(countries)
    setProfileCountries(countries)
  }

  const handleLogoutClick = () => {
    navigate('/sign-out')
  }

  const allowUpdate = admin || profileCountryUpdate
  if (loader)
    return (
      <LoaderBox
        height="xsmall"
        width="xsmall"
        align="center"
        justify="center"
        alignSelf="center"
      >
        <Loader testId="loader-spinner" />
      </LoaderBox>
    )
  return (
    <CCSForm
      value={state}
      onSubmit={handleSubmit}
      testId="country-update-form"
      errorMessage={errorMessage}
    >
      <Box direction="column">
        {admin && custAccountLoaded?.defaulted_country_code && (
          <Box margin={{ bottom: profileCountryUpdate ? 'large' : 'medium' }}>
            <Typography
              type="heading"
              level="2"
              testId="account-country-header"
            >
              {labels.heading.account}
            </Typography>
            <Typography
              type="text"
              size="large"
              margin={{ top: 'xsmall' }}
              testId="account-country-location-text"
            >
              {labels.subHeading.account}
            </Typography>
            <FormField
              label={labels.country.account}
              theme={{ formField: { label: { margin: 'none' } } }}
              name="account_country_code"
              margin={{ top: 'small' }}
              required
              data-testid="set-up-account-country-form-field"
            >
              <Select
                name="account_country_code"
                placeholder={t('customer_account.country_placeholder')}
                options={accountCountries}
                multiple={false}
                labelKey="name"
                value={state.account_country_code}
                valueKey={{ key: 'code', reduce: true }}
                searchPlaceholder={t('customer_account.country')}
                emptySearchMessage={t(
                  'customer_account.country_empty_search_message'
                )}
                onSearch={onSearchAccountCountry}
                onChange={onChange('account_country_code')}
                onClose={onClose}
              />
            </FormField>
          </Box>
        )}
        {profileCountryUpdate && (
          <Box>
            {admin && custAccountLoaded?.defaulted_country_code && (
              <>
                <Typography
                  type="heading"
                  level="2"
                  testId="profile-country-header"
                >
                  {labels.heading.profile}
                </Typography>
                <Typography
                  type="text"
                  size="large"
                  margin={{ top: 'medium' }}
                  testId="profile-country-location-text"
                >
                  {labels.subHeading.profile}
                </Typography>
              </>
            )}
            <FormField
              label={labels.country.profile}
              name="profile_country_code"
              required
              margin={{ bottom: 'medium' }}
              data-testid="set-up-account-country-form-field"
            >
              <Select
                name="profile_country_code"
                placeholder={t('customer_account.country_placeholder')}
                options={profileCountries}
                multiple={false}
                labelKey="name"
                value={state.profile_country_code}
                valueKey={{ key: 'code', reduce: true }}
                searchPlaceholder={t('customer_account.country')}
                emptySearchMessage={t(
                  'customer_account.country_empty_search_message'
                )}
                onSearch={onSearchProfileCountry}
                onChange={onChange('profile_country_code')}
                onClose={onClose}
              />
            </FormField>
          </Box>
        )}

        {allowUpdate ? (
          <Box>
            <NotificationContent
              background={
                postAprilContent && admin ? 'status-critical' : 'status-warning'
              }
              position="top"
              title={
                postAprilContent
                  ? notification.title.postApril
                  : notification.title.preApril
              }
              icon={<Alert size="large" color="black" />}
              messages={
                admin
                  ? notification.messages.admin
                  : notification.messages.nonAdmin
              }
            />
          </Box>
        ) : (
          <NotificationContent
            background="status-unknown"
            icon={<Lock size="large" color="black" />}
            title={notification.title.nonAdminAccount}
            messages={notification.messages.nonAdminAccount}
          />
        )}

        <Box justify={allowUpdate ? 'start' : 'end'} direction="row">
          {allowUpdate ? (
            <Button
              primary
              testId="country-update-button"
              type="submit"
              label={
                !custAccountLoaded?.defaulted_country_code &&
                profileCountryUpdate
                  ? labels.button.profile
                  : labels.button.account
              }
              size="medium"
              margin={{ top: 'medium', bottom: 'small' }}
            />
          ) : (
            <Button
              secondary
              width="xsmall"
              testId="log-out-button"
              onClick={handleLogoutClick}
              label={t('choose_country.log_out')}
              size="medium"
              margin={{ vertical: 'medium' }}
            />
          )}
        </Box>
      </Box>
    </CCSForm>
  )
}

CountryUpdateForm.propTypes = {
  admin: PropTypes.bool.isRequired
}

export default CountryUpdateForm
