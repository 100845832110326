// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StatusCritical } from 'grommet-icons'

import {
  Button,
  Loader,
  Notification,
  Pagination,
  Typography
} from '../../../../../components'
import { get, getErrorMessage } from '../../../../../utils/api-utils'
import {
  CCSManagerActions,
  useCCSManagerContext
} from '../../../../../context/ccs-manager-context'

const StyledButton = styled(Button)`
  font-weight: normal;
  text-align: left;
`

const UserCustomerDetails = ({ username }) => {
  const { t } = useTranslation(['manage'])
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { dispatchCCSManagerContext } = useCCSManagerContext()
  const [customerData, setCustomerData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(true)

  // for pagination
  const itemsPerPage = 5
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)

  useEffect(() => {
    let isCurrent = true
    setLoading(true)
    const url = `/support-assistant/v1alpha1/customers`
    get(
      url,
      {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        username
      },
      oidcUser.access_token
    ).then(
      (response) => {
        if (!isCurrent) return
        const custData = response.data.customers.map((c) => {
          return {
            customer_id: c.customer_id,
            company_name: c.contact.company_name
          }
        })
        setCustomerData(custData)
        setTotalItems(response.data.pagination.total_count)
        setErrorMessage(null)
        setLoading(false)
      },
      (error) => {
        setErrorMessage(getErrorMessage(error, t))
        setCustomerData([])
        setTotalItems(0)
        setLoading(false)
      }
    )
    return () => {
      isCurrent = false
    }
  }, [oidcUser.access_token, username, page, t])

  return (
    <Box direction="row-responsive" data-testid="user-customer-details">
      <Box alignSelf="start" justify="between" direction="column">
        <Typography
          size="small"
          type="text"
          testId="details-header"
          weight="bold"
        >
          {t('customers_list')}
        </Typography>
        <Box align="start" margin={{ top: 'small', bottom: 'medium' }}>
          {loading && <Loader testId="customer-list-loader" />}
          {errorMessage && (
            <Notification
              backgroundColor="status-critical"
              onClose={() => setErrorMessage(null)}
              testId="critical-notification"
              text={errorMessage}
              icon={<StatusCritical size="medium" />}
            />
          )}
          {customerData.map((datum) => (
            <StyledButton
              key={datum.customer_id}
              onClick={() => {
                dispatchCCSManagerContext({
                  type: CCSManagerActions.SET_SELECTED_CUSTOMER,
                  data: { id: datum.customer_id }
                })
                navigate('/manage-ccs/customers/customer-details')
              }}
              default
              label={datum.company_name}
              testId="customer-button"
              size="small"
            />
          ))}
        </Box>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          page={page}
          setPage={({ page: nextPage }) => {
            setPage(nextPage)
          }}
          size="small"
          numberMiddlePages={1}
          testId="user-customer-details-pagination"
        />
      </Box>
    </Box>
  )
}

UserCustomerDetails.propTypes = {
  username: PropTypes.string.isRequired
}

export { UserCustomerDetails }
