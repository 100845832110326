// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Loader, Typography } from '../../../../../../../components'

const CheckingLoader = ({ height = undefined }) => {
  const { t } = useTranslation(['common'])
  return (
    <Box
      fill
      direction="row"
      gap="small"
      align="center"
      justify="center"
      height={{ min: height || 'xsmall' }}
    >
      <Loader testId="showback-rates-checking-subscriptionKey-loader" />
      <Typography
        type="text"
        emphasis
        margin={{ top: '0px', bottom: '0px' }}
        testId="showback-rates-checking-subscription-label"
      >
        {t('common:loading')}
      </Typography>
    </Box>
  )
}

CheckingLoader.propTypes = {
  height: PropTypes.string
}

export { CheckingLoader }
