// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { useTranslation, Trans } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Previous, StatusCritical, StatusGood } from 'grommet-icons'
import { useNavigate, useParams } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  Button,
  Loader,
  Notification,
  Typography
} from '../../../../../components'
import { get, getErrorMessage, del } from '../../../../../utils/api-utils'
import Details from '../../../../../commoncomponents/notifications-dashboard/Details'
import UpdateHistoryDetails from '../../../../../commoncomponents/notifications-dashboard/UpdateHistoryDetails'

import { DeleteConfirmationModal } from './create-notification/DeleteConfirmationModal'

const NotificationDetails = ({ isSchedule = false }) => {
  const [loading, setLoading] = useState(false)
  const [notificationDetails, setNotificationDetails] = useState([])
  const { oidcUser } = useReactOidc()
  const navigate = useNavigate()
  const { notificationId } = useParams()
  const { t } = useTranslation(['manage', 'common'])
  const LDFlags = useFlags()
  const [errorMessage, setErrorMessage] = useState(null)
  const [historyDetails, setHistoryDetails] = useState([])
  const [historyLoading, setHistoryLoading] = useState(false)
  const [updateHistory, setUpdateHistory] = useState([])
  const [originalNoti, setOriginalNoti] = useState([])
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [publishedAction, setPublishedAction] = useState('')
  const [scheduleAction, setScheduleAction] = useState('')

  const publishedActionOptions = [t('edit'), t('delete_label')]
  const scheduleActionOptions = [t('delete_label')]
  const handleBackBtnClick = () => {
    navigate('/manage-ccs/notifications')
  }

  const fetchNotificationDetails = useCallback(() => {
    if (isSchedule === false) {
      setLoading(true)

      const url = '/notifications-svc/ui/v1alpha1/notification-requests/'

      get(`${url}${notificationId}`, {}, oidcUser.access_token).then(
        (response) => {
          setLoading(false)
          setNotificationDetails(response?.data)
        },
        (error) => {
          setNotificationDetails([])
          setLoading(false)
          setErrorMessage(getErrorMessage(error, t))
        }
      )
    } else {
      setLoading(true)
      const url = '/notifications-svc/ui/v1alpha1/scheduled-notifications/'
      get(`${url}${notificationId}`, {}, oidcUser.access_token).then(
        (response) => {
          setLoading(false)
          setNotificationDetails(response?.data)
        },
        (error) => {
          setNotificationDetails([])
          setLoading(false)
          setErrorMessage(getErrorMessage(error, t))
        }
      )
    }
  }, [oidcUser.access_token, notificationId, t, isSchedule])

  const handleEditPublishedNotificationClick = () => {
    navigate(
      `/manage-ccs/notifications/edit-published-notification-request/${notificationId}`
    )
  }

  const processHistory = (historyArr) => {
    for (let i = historyArr.length - 1; i >= 1; i -= 1) {
      if (i > 1) {
        if (
          historyArr[i]?.updated_params?.description ===
          historyArr[i - 1]?.updated_params?.description
        ) {
          const { description, ...rest } = historyArr[i]?.updated_params || {}

          historyArr[i].updated_params = rest
        }
      } else if (i === 1) {
        if (
          historyArr[i]?.updated_params?.description ===
          historyArr[i - 1]?.notification_details?.description
        ) {
          const { description, ...rest } = historyArr[i].updated_params

          historyArr[i].updated_params = rest
        }
      }
    }
    return historyArr
  }

  const fetchUpdatedNotiHistoryDetails = useCallback(() => {
    setHistoryLoading(true)

    const url = `/notifications-svc/ui/v1alpha1/notification-requests/${notificationId}/history`

    get(url, {}, oidcUser.access_token).then(
      (response) => {
        setHistoryLoading(false)
        if (isSchedule === false) {
          setHistoryDetails(response?.data)

          const historyData = response?.data?.history
          const histCopy = [...historyData]
          const modifiedHist = processHistory(histCopy)
          const copy = [...modifiedHist]
          const originalNotiCopy = copy?.shift()
          setUpdateHistory(copy?.reverse())
          setOriginalNoti(originalNotiCopy)
        }
      },
      (error) => {
        setHistoryDetails([])
        setHistoryLoading(false)
        setErrorMessage(getErrorMessage(error, t))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, notificationId, t, isSchedule])

  const handleScheduleDelete = () => {
    del(
      `/notifications-svc/ui/v1alpha1/scheduled-notifications/${notificationId}`,
      {},
      oidcUser.access_token
    ).then(
      () => {
        setDeleteSuccess(true)
        fetchNotificationDetails('')
        setErrorMessage(null)
        setScheduleAction('')
      },
      (error) => {
        setDeleteSuccess(false)
        const errorMsg = getErrorMessage(error, t)
        setErrorMessage(errorMsg)
        setScheduleAction('')
      }
    )
  }

  const handleDelete = () => {
    if (isSchedule) {
      handleScheduleDelete()
    } else {
      del(
        `/notifications-svc/ui/v1alpha1/notification-requests/${notificationId}`,
        {},
        oidcUser.access_token
      ).then(
        () => {
          setDeleteSuccess(true)
          fetchNotificationDetails('')
          fetchUpdatedNotiHistoryDetails('')
          setErrorMessage(null)
          setPublishedAction('')
        },
        (error) => {
          setDeleteSuccess(false)
          const errorMsg = getErrorMessage(error, t)
          setErrorMessage(errorMsg)
          setPublishedAction('')
        }
      )
    }
  }

  useEffect(() => {
    fetchNotificationDetails()
    if (LDFlags['glcp-notifications-feature-edit-istanbul']) {
      fetchUpdatedNotiHistoryDetails()
    }
  }, [LDFlags, fetchNotificationDetails, fetchUpdatedNotiHistoryDetails])

  return (
    <Box data-testid="notification-details" justify="center">
      <Box direction="row" justify="start" pad="small">
        <Button
          default
          margin={{ vertical: 'xsmall', horizontal: 'small' }}
          label={t('notification.notification_tab')}
          icon={<Previous />}
          onClick={handleBackBtnClick}
          testId="notification-prev-btn"
        />
      </Box>
      <Box
        pad={{ horizontal: 'xlarge', top: 'medium', right: 'xsmall' }}
        width="large"
        justify="between"
        align="start"
        alignSelf="center"
      >
        {loading || historyLoading ? (
          <Loader testId="loader-spinner" />
        ) : (
          <>
            <Details
              notificationDetails={notificationDetails}
              handleEditPublishedNotificationClick={
                handleEditPublishedNotificationClick
              }
              publishedActionOptions={publishedActionOptions}
              scheduleActionOptions={scheduleActionOptions}
              publishedAction={publishedAction}
              scheduleAction={scheduleAction}
              setScheduleAction={setScheduleAction}
              setOpenScheduledDeleteConfirmation={setOpenDeleteConfirmation}
              setPublishedAction={setPublishedAction}
              setOpenPublishedDeleteConfirmation={setOpenDeleteConfirmation}
              isPublished
              isSchedule={isSchedule}
            />
            {LDFlags['glcp-notifications-feature-edit-istanbul'] &&
              updateHistory?.length > 0 && (
                <UpdateHistoryDetails
                  historyDetails={historyDetails}
                  isUserView={false}
                  updateHistory={updateHistory}
                  originalNoti={originalNoti}
                />
              )}
          </>
        )}
        {openDeleteConfirmation && (
          <DeleteConfirmationModal
            onSetOpen={setOpenDeleteConfirmation}
            handleDelete={handleDelete}
            isSchedule={isSchedule}
            isReviewNotification={false}
          />
        )}
        {deleteSuccess && (
          <Notification
            icon={<StatusGood color="text-strong" />}
            onClose={() => setDeleteSuccess(false)}
            testId="status-good-notification"
            text={
              <Typography type="text" size="medium">
                <Trans
                  i18nKey="notification.delete_success_message"
                  t={t}
                  boldText={1}
                >
                  <strong>{1}</strong>
                </Trans>
              </Typography>
            }
          />
        )}
        {errorMessage && (
          <Notification
            backgroundColor="status-critical"
            onClose={() => setErrorMessage(null)}
            testId="notification-detail-error"
            text={errorMessage}
            icon={<StatusCritical size="medium" />}
          />
        )}
      </Box>
    </Box>
  )
}
NotificationDetails.propTypes = {
  isSchedule: PropTypes.bool
}

export default NotificationDetails
