// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Route, Routes, Navigate } from 'react-router-dom'
import { React, Suspense, lazy } from 'react'

import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import { Layout } from '../../../commoncomponents/layout/Layout'
import { CCSManagerContextProvider } from '../../../context/ccs-manager-context'

const ArubaActivate = lazy(() => import('./ArubaActivate'))
const FolderDetailsPage = lazy(() =>
  import('../../../commoncomponents/folder-details/FolderDetailsPage')
)
const DeviceDetails = lazy(() =>
  import('./devices/device-details/DeviceDetails')
)

const ArubaActivateRouterContent = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route
          path="/devices"
          element={<Navigate to="/manage-account/activate/devices-list" />}
        />
        <Route
          path="/folders"
          element={<Navigate to="/manage-account/activate/folders-list" />}
        />
        <Route path="/devices/:serial" element={<DeviceDetails />} />
        <Route path="/folders/folder-details" element={<FolderDetailsPage />} />
        <Route path="/*" element={<ArubaActivate />} />
      </Routes>
    </Suspense>
  )
}

const ArubaActivateRouter = () => {
  return (
    <CCSManagerContextProvider>
      <Layout>
        <ArubaActivateRouterContent />
      </Layout>
    </CCSManagerContextProvider>
  )
}

export default ArubaActivateRouter
