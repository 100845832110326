// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types'
import { Box, Button, Text } from 'grommet'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ContactInfo } from 'grommet-icons'

import { Loader, NoDataInfo, Typography } from '../../../../../components'
import { get } from '../../../../../utils/api-utils'
import { displayApiError } from '../../../../../utils/error-handling-utils'
import AssignmentContent from '../components/AssignmentDataTable'
import { assignmentSubjectEnum, glcpServiceID } from '../../constants'
import DeleteRoleAssignmentConfirmation from '../../common-components/DeleteRoleAssignmentConfirmation'
import AssignRoleSideDrawer from '../../common-components/AssignRoleSideDrawer'
import { displayNotification } from '../../../../../utils/notificiation-utils'
import Markdown from '../../common-components/Markdown'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import { getUserName } from '../../../utils'

const AssignmentsTab = ({
  roleName = undefined,
  refreshCount = 1,
  role = undefined,
  appId = undefined,
  setActiveTab = () => {}
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [openDeleteConfirmationDialog, setopenDeleteConfirmationDialog] =
    useState(false)
  const [statusNotification, setStatusNotification] = useState(false)
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
  const [retrievedAssignments, setRetrievedAssignments] = useState([])
  const [retrievedSubjectData, setRetrievedSubjectData] = useState([])
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)
  const [apiError, setApiError] = useState(null)
  const { t } = useTranslation(['authz', 'iam', 'common'])
  const [pullRefresh, setPullRefresh] = useState(0)
  const handleOnDeleteBtnClick = useCallback((datum) => {
    setopenDeleteConfirmationDialog(true)
    setRetrievedSubjectData(datum)
    setStatusNotification(false)
  }, [])

  useEffect(() => {
    setIsLoading(true)
    const handleRoleAssignmentOnActive = async () => {
      try {
        const response = await get(
          `/internal-platform-tenant-ui/v2/role-assignments?role-grn=${encodeURIComponent(
            role?.grn
          )}`,
          {
            limit: itemsPerPage,
            offset: (page - 1) * itemsPerPage
          }
        )
        if (response?.data) {
          setRetrievedAssignments(response?.data?.role_assignments)
          setTotalItems(response?.data?.total)
        }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setTotalItems(0)
        setApiError(displayApiError(error, t, setApiError))
      }
    }
    handleRoleAssignmentOnActive()
  }, [role, t, pullRefresh, refreshCount, itemsPerPage, page])

  const handleAssignRolesClick = () => {
    setStatusNotification(false)
    setShowAssignRoleModal(true)
  }

  return (
    <Box width="xlarge">
      <Box
        direction="row"
        justify="between"
        margin={{ vertical: 'medium', bottom: 'medium', top: 'large' }}
        fill
      >
        <Typography type="heading" level="2" testId="assignments-tab-title">
          {t('authz:assignments.role_assignments_plural')}
        </Typography>
        {retrievedAssignments && retrievedAssignments.length ? (
          <VisibilityWrapper
            rbac={{
              resource: '/ccs/authorization',
              permission: 'ccs.authorization.edit'
            }}
          >
            <Button
              secondary
              label={t('iam:role_assignment.assign_role', {
                roles: t('common:business_object.role')
              })}
              onClick={() => handleAssignRolesClick()}
              testId="add-to-role-assignment-btn"
            />
          </VisibilityWrapper>
        ) : null}
      </Box>
      {(() => {
        if (isLoading) {
          return <Loader testId="role-assignment-content-loader" />
        }
        return retrievedAssignments && retrievedAssignments.length ? (
          <AssignmentContent
            handleOnDeleteBtnClick={handleOnDeleteBtnClick}
            retrievedAssignments={retrievedAssignments} // subjectType={subjectTypesEnum.USER_GROUP}
            page={page}
            setPage={setPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            parentLoading={isLoading}
          />
        ) : (
          <Box margin={{ top: 'large' }}>
            <NoDataInfo
              action={
                <VisibilityWrapper
                  rbac={{
                    resource: '/ccs/authorization',
                    permission: 'ccs.authorization.edit'
                  }}
                >
                  <Button
                    secondary
                    label={t('iam:role_assignment.assign_role', {
                      roles: t('common:business_object.role_plural')
                    })}
                    onClick={() => handleAssignRolesClick()}
                    testId="add-to-role-assignment-btn"
                  />
                </VisibilityWrapper>
              }
              icon={<ContactInfo size="xlarge" />}
              subtitle={t('iam:roles_details.assign_role_subtitle', {
                role: t('common:business_object.role')
              })}
              title={t('iam:role_assignment.no_role_assignment_title', {
                role: t('common:business_object.role')
              })}
              testId="no-data-info"
            />
          </Box>
        )
      })()}
      {openDeleteConfirmationDialog && (
        <DeleteRoleAssignmentConfirmation
          setConfirm={setopenDeleteConfirmationDialog}
          setNotificationInfo={(message, severity) => {
            setStatusNotification({ message, severity })
          }}
          retrievedRA={retrievedSubjectData}
          onRemoved={() => {
            setStatusNotification({
              message: (
                <Markdown>
                  {t('iam:roles_details.remove_assignment_message', {
                    role: t('common:business_object.role'),
                    roleName,
                    subjectName: getUserName(retrievedSubjectData),
                    subjectType:
                      assignmentSubjectEnum[retrievedSubjectData?.subject_type]
                  })}
                </Markdown>
              ),
              severity: 'info',
              title: t('iam:roles_details.assignment_removed_title')
            })
            setPullRefresh((prevPullRefresh) => prevPullRefresh + 1)
          }}
          title={
            appId === glcpServiceID
              ? t('iam:role_assignment.remove_role_assignment_title_v2', {
                  roleAssignment: t('common:business_object.role_assignment')
                })
              : t('iam:role_assignment.remove_assignment_title')
          }
          subtitle={
            <Text testId="confirmation-text">
              {appId === glcpServiceID ? (
                <Box
                  direction="row"
                  testId="role-assignment-delete-confirm-text"
                  wrap
                >
                  <Markdown>
                    {t(
                      'iam:roles_details.remove_role_assignment_platform_confirmation_msg_v2',
                      {
                        roleAssignment: t(
                          'common:business_object.role_assignment'
                        ),
                        users: t('common:business_object.user_plural'),
                        roleName
                      }
                    )}
                  </Markdown>
                  <Typography type="text" margin={{ top: 'medium' }}>
                    {t(
                      'iam:roles_details.remove_role_assignment_confirmation_msg_end'
                    )}
                  </Typography>
                </Box>
              ) : (
                <Box
                  direction="row"
                  testId="role-assignment-delete-confirm-text"
                  wrap
                >
                  <Markdown>
                    {t('iam:roles_details.remove_role_assignment_msg', {
                      roleAssignment: t(
                        'common:business_object.role_assignment'
                      ),
                      roleName,
                      subjectName: getUserName(retrievedSubjectData),
                      subjectType:
                        assignmentSubjectEnum[
                          retrievedSubjectData?.subject_type
                        ]
                    })}
                  </Markdown>
                  <Typography type="text" margin={{ top: 'medium' }}>
                    {t(
                      'iam:roles_details.remove_role_assignment_confirmation_msg_end'
                    )}
                  </Typography>
                </Box>
              )}
            </Text>
          }
        />
      )}
      {showAssignRoleModal && (
        <AssignRoleSideDrawer
          preSelectedRole={role}
          setShowAssignRoleModal={setShowAssignRoleModal}
          setStatusNotification={setStatusNotification}
          appId={appId}
          onAssign={() => {
            setPullRefresh((prevPullRefresh) => prevPullRefresh + 1)
          }}
          setActiveTab={setActiveTab}
        />
      )}
      {statusNotification &&
        displayNotification(
          statusNotification.message,
          statusNotification.severity,
          setStatusNotification,
          statusNotification.title
        )}
      {apiError}
    </Box>
  )
}
AssignmentsTab.propTypes = {
  roleName: PropTypes.string,
  refreshCount: PropTypes.number,
  role: PropTypes.object,
  appId: PropTypes.string,
  setActiveTab: PropTypes.func
}
export { AssignmentsTab }
