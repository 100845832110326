// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { Suspense, lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { ReportingContextProvider } from '../../../context/reporting-context/ReportingContext'
import LazyLoading from '../../../commoncomponents/LazyLoading/LazyLoading'
import VisibilityWrapper from '../../../commoncomponents/visibility-wrapper/VisibilityWrapper'

const ReportDashboardPage = lazy(() => import('./ReportDashboard'))
const ReportsRouter = lazy(() => import('./reports/router'))
const CreateReportRouter = lazy(() => import('./create-report/router'))

const ReportingRouterContent = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route path="/" element={<ReportDashboardPage />} />
        <Route path="/reports/*" element={<ReportsRouter />} />
        <Route
          path="/create-report/*"
          element={
            <VisibilityWrapper
              rbac={{
                resource: '/ccs/reporting/dashboard',
                permission: 'ccs.reporting.edit'
              }}
              fallbackComponent={<Navigate to="/home" />}
            >
              <CreateReportRouter />
            </VisibilityWrapper>
          }
        />
      </Routes>
    </Suspense>
  )
}

const ReportingRouter = () => {
  return (
    <ReportingContextProvider>
      <ReportingRouterContent />
    </ReportingContextProvider>
  )
}

export default ReportingRouter
