// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusCritical, CircleInformation } from 'grommet-icons'
import { Box, Tip } from 'grommet'
import { useNavigate, useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import {
  UPDATE_FOR_UPDATE_REVIEW_REQUEST,
  UPDATE_FOR_CREATE_REVIEW_REQUEST
} from '../../../constants'
import {
  Wizard,
  Notification,
  Loader,
  Typography
} from '../../../../../../../components'
import {
  get,
  getErrorMessage,
  put,
  patch
} from '../../../../../../../utils/api-utils'
import {
  WKSPC,
  WKSPC_PLURAL_CAPITALIZED,
  getWorkspaceString
} from '../../../../../../../utils/common-utils'
import {
  getSeverity,
  getState
} from '../../../../../../../commoncomponents/notifications-dashboard/utils'
import Category, {
  validateCategoryFields
} from '../../create-notification/steps/Category'
import { getTargetEnum } from '../../../utils'
import ScheduleNotification, {
  validateScheduleNotification
} from '../../create-notification/steps/scheduleNotification'
import NotificationContent, {
  validateNotificationContent
} from '../../create-notification/steps/NotificationContent'

import NotificationTarget, {
  validateCreateFields
} from './steps/NotificationTarget'
import Review, { validateFormFields } from './steps/Review'
import BannerNotification, {
  validateBannerMessage
} from './steps/BannerNotification'
import EmailNotification, {
  validateEmailMessageFlorence
} from './steps/EmailNotification'
import PortalNotification, {
  validatePortalMessage
} from './steps/PortalNotification'
import NotificationType, {
  validateNotificationTypeFields
} from './steps/NotificationType'
import EmailType, { validateEmailTypeFields } from './steps/EmailType'
import AccountsType, { validateAccountTypeFields } from './steps/AccountsType'
import AccountTypeTarget, {
  validateAccountTypeTargetFields
} from './steps/AccountTypeTarget'

const EditNotificationRequestWizard = () => {
  const { t } = useTranslation(['manage', 'common'])
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const saCustomerRolesV2 = LDFlags['glcp-sa-customer-roles-v2']
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const [bannerCheck, setBannerCheck] = useState(false)
  const [emailCheck, setEmailCheck] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [regionList, setRegionList] = useState([t('notification.all_regions')])
  const [roleList, setRoleList] = useState([
    {
      value: t('notification.all_roles'),
      label: t('notification.all_roles')
    }
  ])
  const [instanceList, setInstanceList] = useState([
    {
      value: t('notification.all_instances'),
      label: t('notification.all_instances')
    }
  ])
  const [selectedInstance, setSelectedInstance] = useState([])
  const [selectedRole, setSelectedRole] = useState([])
  const { notificationId } = useParams()
  const [selectedType, setSelectedType] = useState(t('notification.services'))
  const [notificationDetails, setNotificationDetails] = useState({})
  const [applicationInstancesDetails, setApplicationInstancesDetails] =
    useState([t('notification.all_instances')])

  const [applicationInstancesName, setApplicationInstancesName] = useState([])
  const [isUpdateFlow, setIsUpdateFlow] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState({})

  const [roleDetails, setRoleDetails] = useState([])
  const [roleName, setRoleName] = useState([])
  const [accountDetails, setAccountDetails] = useState([])
  const [accountName, setAccountName] = useState([])
  const [wizardError, setWizardError] = useState(null)
  const [retriggerRender, setRetriggerRender] = useState(false)
  const [regionDetails, setRegionDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [categoryResponse, setCategoryResponse] = useState({})
  const [optionsLoading, setOptionsLoading] = useState(true)
  const [optionsLoadingRoles, setOptionsLoadingRoles] = useState(true)
  const [accountLoading, setAccountLoading] = useState(true)
  const [appSlug, setAppSlug] = useState('')
  const [targetList, setTargetList] = useState([])
  const [targetLoading, setTargetLoading] = useState(true)

  const fetchTargets = useCallback(() => {
    setTargetLoading(true)
    get(
      `/notifications-svc/ui/v1alpha1/get-all-apps`,
      {},
      oidcUser.access_token
    )
      .then(
        (response) => {
          const allApps = response?.data
          const appList = [
            {
              label: 'HPE GreenLake platform',
              value: 'PLATFORM',
              slug: 'PLATFORM'
            }
          ]
          Object.entries(allApps).forEach((keyVal) => {
            const app = {
              label: keyVal[1]?.name,
              value: keyVal[0],
              slug: keyVal[1]?.slug
            }
            appList.push(app)
          })
          setTargetList(appList)
        },
        () => {}
      )
      .finally(() => {
        setTargetLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  const fetchCategories = useCallback(
    () => {
      get(
        `/notifications-svc/ui/v1alpha1/categories`,
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          setCategoryResponse(response.data)
        },
        (error) => {
          console.log(error)
        }
      )
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oidcUser.access_token, t]
  )

  useEffect(() => {
    if (LDFlags['glcp-notifications-feature-category-istanbul']) {
      fetchCategories()
      if (LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
        fetchTargets()
      } else {
        setTargetLoading(false)
      }
    } else {
      setTargetLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategories])

  useEffect(() => {
    if (selectedType !== t('notification.services')) {
      setOptionsLoading(false)
      setOptionsLoadingRoles(false)
    }
    if (
      selectedType !==
      getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL_CAPITALIZED)
    ) {
      setAccountLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType])

  const fetchRegions = useCallback(
    (centralList) => {
      setOptionsLoading(true)
      get(
        `/notifications-svc/ui/v1alpha1/get-region-app-instances`,
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          const perRegionApps = response.data
          const regionArray = []
          const instanceArray = []
          let regionCodes = [t('notification.all_regions')]
          centralList.forEach((central) => {
            const appArray = [t('notification.all_regions')]
            const regionObject = perRegionApps[central?.id]
            if (regionObject) {
              const regions = Object.keys(regionObject)
              regionCodes = regionCodes.concat(regions)
              const instanceObject = {}
              Object.entries(regionObject).forEach((key) => {
                const instList = key[1]?.map((val) => ({
                  value: val?.instance_id,
                  label: val?.short_name
                }))
                instanceObject[key[0]] = instList
              })
              regionArray.push({
                app_id: central?.id,
                regionList: appArray?.concat(regions),
                central: central?.central
              })
              instanceArray.push({
                app_id: central?.id,
                instanceList: instanceObject,
                central: central?.central
              })
            }
          })
          regionCodes = [...new Set(regionCodes)]
          const allRegion = [
            { app_id: 'PLATFORM', regionList: regionCodes, central: 'PLATFORM' }
          ]
          setRegionList(allRegion.concat(regionArray))
          setInstanceList(instanceArray)
          setTimeout(() => {
            setOptionsLoading(false)
          }, 1000)
        },
        () => {
          setTimeout(() => {
            setOptionsLoading(false)
          }, 1000)
          setWizardError(t('notification.per_region_error'))
          const regionArray = []
          const instanceArray = []
          centralList.forEach((app) => {
            regionArray.push({
              app_id: app.id,
              regionList: [t('notification.all_regions')],
              central: app.central
            })
            instanceArray.push({
              app_id: app.id,
              instanceList: [
                {
                  value: t('notification.all_instances'),
                  label: t('notification.all_instances')
                }
              ],
              central: app.central
            })
          })
          const allRegion = [
            {
              app_id: 'PLATFORM',
              regionList: [t('notification.all_regions')],
              central: 'PLATFORM'
            }
          ]
          const allInstance = [
            {
              app_id: 'PLATFORM',
              regionList: [
                {
                  value: t('notification.all_instances'),
                  label: t('notification.all_instances')
                }
              ],
              central: 'PLATFORM'
            }
          ]
          setRegionList(allRegion.concat(regionArray))
          setInstanceList(allInstance.concat(instanceArray))
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oidcUser.access_token, t]
  )

  const fetchRoles = useCallback(
    (centralList) => {
      setOptionsLoadingRoles(true)
      const roleOptionsArray = []
      get(
        `/notifications-svc/ui/v1alpha1/get-app-roles`,
        {},
        oidcUser.access_token
      ).then(
        (response) => {
          setTimeout(() => {
            setOptionsLoadingRoles(false)
          }, 1000)
          const appRoles = response.data

          centralList?.forEach((central) => {
            const appArray = [
              {
                value: t('notification.all_roles'),
                label: t('notification.all_roles')
              }
            ]
            const roleObject = appRoles[central?.id]
            let mapRoleList = []
            if (roleObject) {
              mapRoleList = roleObject?.roles?.map((val) => ({
                value: val?.slug,
                label: val?.name
              }))
            }
            roleOptionsArray.push({
              app_id: central?.id,
              roleList: appArray?.concat(mapRoleList),
              central: central?.central
            })

            setRoleList(roleOptionsArray)
          })
        },
        () => {
          centralList.forEach((central) => {
            roleOptionsArray.push({
              app_id: central?.id,
              roleList: [
                {
                  value: t('notification.all_roles'),
                  label: t('notification.all_roles')
                }
              ],
              central: central?.central
            })
          })
          setRoleList(roleOptionsArray)
          setWizardError(t('notification.all_roles_error'))
          setTimeout(() => {
            setOptionsLoadingRoles(false)
          }, 1000)
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oidcUser.access_token, t]
  )

  const getStateEnum = (state) => {
    let element
    switch (state) {
      case 'INFORMATION':
        element = 0
        break
      case 'ACTIVE':
        element = 1
        break
      case 'RESOLVED':
        element = 2
        break
      default:
        element = 0
    }
    return element
  }

  const getBannerExpirationDate = (notificationDets) => {
    if (!isNull(notificationDets?.ttl)) {
      return dayjs(notificationDetails?.ttl * 1000).format('MM/DD/YYYY')
    }
    return ''
  }

  const getBannerExpirationTime = (notificationDets) => {
    if (!isNull(notificationDets?.ttl)) {
      return dayjs(notificationDetails?.ttl * 1000).format('hh:mm A')
    }
    return ''
  }

  const getScheduleDate = (notificationDets) => {
    if (!isNull(notificationDets?.startTime)) {
      return dayjs(notificationDetails?.startTime * 1000).format('MM/DD/YYYY')
    }
    return ''
  }

  const getScheduleTime = (notificationDets) => {
    if (!isNull(notificationDets?.startTime)) {
      return dayjs(notificationDetails?.startTime * 1000).format('hh:mm A')
    }
    return ''
  }

  const fetchNotificationDetails = useCallback(() => {
    setLoading(true)
    const url = `/notifications-svc/ui/v1alpha1/reviews/${notificationId}`

    get(`${url}`, {}, oidcUser.access_token).then(
      (response) => {
        const responseData = response?.data
        if (response?.data?.approvalRequestForUpdate) {
          setIsUpdateFlow(true)
        } else {
          setIsUpdateFlow(false)
        }
        const initialVal = {
          status: getSeverity(responseData?.severity, t),
          expirationDate: getBannerExpirationDate(responseData) || '',
          expirationTime: getBannerExpirationTime(responseData) || '',
          summary: responseData?.summary || '',
          description: responseData?.description || '',
          emailContentTitle:
            (responseData?.emailContentTitle
              ? responseData?.emailContentTitle
              : responseData?.summary) || '',
          emailContentDetails:
            (responseData?.emailContentDetails
              ? responseData?.emailContentDetails
              : responseData?.description) || '',
          email: responseData?.is_email || false,
          state: getState(responseData?.state) || ''
        }
        setInitialFormValues(initialVal)
        setNotificationDetails(responseData)
        setAppSlug(responseData?.category?.service)
        setLoading(false)
        if (responseData?.targetType === 'USERS') {
          setSelectedType(t('notification.email_address'))
        } else if (
          LDFlags['glcp-notifications-feature-dynamic-app-istanbul'] &&
          (responseData?.targetType === 'SERVICES' ||
            responseData?.targetType === 'PLATFORM')
        ) {
          get(
            `/notifications-svc/ui/v1alpha1/get-all-apps`,
            {},
            oidcUser.access_token
          ).then(
            (resp) => {
              const allApps = resp?.data
              const appList = [
                {
                  label: 'HPE GreenLake platform',
                  value: 'PLATFORM',
                  slug: 'PLATFORM'
                }
              ]
              const centralList = []
              Object.entries(allApps).forEach((keyVal) => {
                const app = {
                  label: keyVal[1]?.name,
                  value: keyVal[0],
                  slug: keyVal[1]?.slug
                }
                const centralItem = {
                  id: keyVal[0],
                  central: keyVal[1]?.name
                }
                appList.push(app)
                centralList.push(centralItem)
              })
              const centralId = centralList.find(
                (app) => app?.id === responseData?.target_ids?.[0]
              )
              fetchRegions(centralList)
              fetchRoles(centralList)
              setSelectedType(t('notification.services'))
              if (isEmpty(responseData?.regions)) {
                setRegionDetails([t('notification.all_regions')])
                setApplicationInstancesDetails([
                  t('notification.all_instances')
                ])
                setApplicationInstancesName([t('notification.all_instances')])
              } else if (isEmpty(responseData?.applicationInstances)) {
                setApplicationInstancesDetails([
                  t('notification.all_instances')
                ])
                setApplicationInstancesName([t('notification.all_instances')])
                setRegionDetails(responseData?.regions)
              } else {
                get(
                  `/notifications-svc/ui/v1alpha1/get-region-app-instances`,
                  {},
                  oidcUser.access_token
                ).then(
                  (apiResponse) => {
                    const perRegionApps = apiResponse?.data
                    const instanceArray = []
                    const instanceName = []

                    const regionObject = perRegionApps[centralId?.id]
                    const selectedRegions = responseData?.regions

                    if (responseData?.targetType === 'SERVICES') {
                      selectedRegions?.forEach((region) => {
                        const selectedRegion = regionObject[region]
                        responseData?.applicationInstances?.forEach(
                          (instance) => {
                            const selectedAppId = selectedRegion?.find(
                              (currentInstance) =>
                                currentInstance?.instance_id === instance
                            )

                            if (!isUndefined(selectedAppId)) {
                              instanceArray.push(selectedAppId?.instance_id)
                              instanceName.push(selectedAppId?.short_name)
                            }
                          }
                        )
                      })
                    } else {
                      centralList?.forEach((central) => {
                        const regionObj = perRegionApps[central?.id]

                        selectedRegions?.forEach((region) => {
                          const selectedRegion = regionObj?.[region]

                          if (!isUndefined(selectedRegion)) {
                            responseData?.applicationInstances?.forEach(
                              (instance) => {
                                const selectedAppId = selectedRegion?.find(
                                  (currentInstance) =>
                                    currentInstance?.instance_id === instance
                                )

                                if (!isUndefined(selectedAppId)) {
                                  instanceArray.push(selectedAppId?.instance_id)
                                  instanceName.push(selectedAppId?.short_name)
                                }
                              }
                            )
                          }
                        })
                      })
                    }
                    setApplicationInstancesDetails(instanceArray)
                    setApplicationInstancesName(instanceName)
                    setRegionDetails(responseData?.regions)
                  },
                  () => {
                    setApplicationInstancesDetails([])
                    setRoleList([])
                  }
                )
              }
              if (isEmpty(responseData?.roles)) {
                setRoleDetails([t('notification.all_roles')])
                setRoleName([t('notification.all_roles')])
                setRoleList([
                  {
                    value: t('notification.all_roles'),
                    label: t('notification.all_roles')
                  }
                ])
              } else if (
                !isEmpty(responseData?.roles) &&
                responseData?.targetType === 'SERVICES'
              ) {
                get(
                  `/notifications-svc/ui/v1alpha1/get-app-roles`,
                  {},
                  oidcUser.access_token
                ).then(
                  (roleResponse) => {
                    const roleArray = []
                    const roleNameArray = []
                    const roleObject =
                      roleResponse?.data?.[centralId?.id]?.roles

                    const selectedRoles = responseData?.roles

                    selectedRoles?.forEach((role) => {
                      const selectedRoleObject = roleObject?.find(
                        (currentRole) => currentRole?.slug === role
                      )

                      if (!isUndefined(selectedRoleObject)) {
                        roleArray.push(selectedRoleObject?.slug)
                        roleNameArray.push(selectedRoleObject?.name)
                      }
                    })
                    setRoleDetails(roleArray)
                    setRoleName(roleNameArray)
                  },
                  () => {
                    setApplicationInstancesDetails([])
                    setApplicationInstancesName([])
                    setRoleList([])
                    setRoleName([])
                  }
                )
              }
            },
            () => {}
          )
        } else if (
          responseData?.targetType === 'CENTRALS' ||
          responseData?.targetType === 'PLATFORM'
        ) {
          get(
            `/notifications-svc/ui/v1alpha1/centrals`,
            {},
            oidcUser.access_token
          ).then(
            (responseCentrals) => {
              const centralList = responseCentrals?.data
              const centralId = centralList.find(
                (app) => app?.central === responseData?.target_ids?.[0]
              )
              fetchRegions(centralList)
              fetchRoles(centralList)
              setSelectedType(t('notification.services'))
              if (isEmpty(responseData?.regions)) {
                setRegionDetails([t('notification.all_regions')])
                setApplicationInstancesDetails([
                  t('notification.all_instances')
                ])
                setApplicationInstancesName([t('notification.all_instances')])
              } else if (isEmpty(responseData?.applicationInstances)) {
                setApplicationInstancesDetails([
                  t('notification.all_instances')
                ])
                setApplicationInstancesName([t('notification.all_instances')])
                setRegionDetails(responseData?.regions)
              } else {
                get(
                  `/notifications-svc/ui/v1alpha1/get-region-app-instances`,
                  {},
                  oidcUser.access_token
                ).then(
                  (apiResponse) => {
                    const perRegionApps = apiResponse?.data

                    const instanceArray = []
                    const instanceName = []

                    const regionObject = perRegionApps[centralId?.id]

                    const selectedRegions = responseData?.regions

                    if (responseData?.targetType === 'CENTRALS') {
                      selectedRegions?.forEach((region) => {
                        const selectedRegion = regionObject[region]
                        responseData?.applicationInstances?.forEach(
                          (instance) => {
                            const selectedAppId = selectedRegion?.find(
                              (currentInstance) =>
                                currentInstance?.instance_id === instance
                            )

                            if (!isUndefined(selectedAppId)) {
                              instanceArray.push(selectedAppId?.instance_id)
                              instanceName.push(selectedAppId?.short_name)
                            }
                          }
                        )
                      })
                    } else {
                      centralList.forEach((central) => {
                        const regionObj = perRegionApps[central?.id]

                        selectedRegions?.forEach((region) => {
                          const selectedRegion = regionObj[region]
                          if (!isUndefined(selectedRegion)) {
                            responseData?.applicationInstances?.forEach(
                              (instance) => {
                                const selectedAppId = selectedRegion?.find(
                                  (currentInstance) =>
                                    currentInstance?.instance_id === instance
                                )

                                if (!isUndefined(selectedAppId)) {
                                  instanceArray.push(selectedAppId?.instance_id)
                                  instanceName.push(selectedAppId?.short_name)
                                }
                              }
                            )
                          }
                        })
                      })
                    }
                    setApplicationInstancesDetails(instanceArray)
                    setApplicationInstancesName(instanceName)
                    setRegionDetails(responseData?.regions)
                  },
                  () => {
                    setApplicationInstancesDetails([])
                    setRoleList([])
                  }
                )
              }
              if (isEmpty(responseData?.roles)) {
                setRoleDetails([t('notification.all_roles')])
                setRoleName([t('notification.all_roles')])
                setRoleList([
                  {
                    value: t('notification.all_roles'),
                    label: t('notification.all_roles')
                  }
                ])
              } else if (
                !isEmpty(responseData?.roles) &&
                responseData?.targetType === 'CENTRALS'
              ) {
                get(
                  `/notifications-svc/ui/v1alpha1/get-app-roles`,
                  {},
                  oidcUser.access_token
                ).then(
                  (roleResponse) => {
                    const roleArray = []
                    const roleNameArray = []
                    const roleObject =
                      roleResponse?.data?.[centralId?.id]?.roles

                    const selectedRoles = responseData?.roles

                    selectedRoles?.forEach((role) => {
                      const selectedRoleObject = roleObject?.find(
                        (currentRole) => currentRole?.slug === role
                      )

                      if (!isUndefined(selectedRoleObject)) {
                        roleArray.push(selectedRoleObject?.slug)
                        roleNameArray.push(selectedRoleObject?.name)
                      }
                    })
                    setRoleDetails(roleArray)
                    setRoleName(roleNameArray)
                  },
                  () => {
                    setApplicationInstancesDetails([])
                    setApplicationInstancesName([])
                    setRoleList([])
                    setRoleName([])
                  }
                )
              }
            },
            () => {}
          )
        } else if (responseData?.targetType === 'ACCOUNTS') {
          setSelectedType(
            getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL_CAPITALIZED)
          )
          const accountList = []
          const accountNameList = []
          responseData?.account_roles?.forEach((account) => {
            const customerUrl = `/support-assistant/v1alpha1/customers`
            const params = {
              search_string: account?.id
            }
            setAccountLoading(true)
            get(customerUrl, params, oidcUser.access_token).then(
              (responseAccount) => {
                const selectedAccount = responseAccount?.data?.customers[0]
                const accList = {
                  value: selectedAccount?.customer_id,
                  label: selectedAccount?.contact?.company_name
                }
                if (isEmpty(account?.roles)) {
                  setTimeout(() => {
                    setAccountLoading(false)
                  }, 1000)
                  const accountNameObject = {
                    accountName: accList,
                    accountRoles: []
                  }
                  const accountObject = {
                    account: selectedAccount?.contact?.company_name,
                    accountID: selectedAccount?.customer_id,
                    accountOptions: [accList],
                    roleNames: [t('notification.all_roles')],
                    roles: [t('notification.all_roles')]
                  }

                  accountList.push(accountObject)
                  accountNameList.push(accountNameObject)
                } else {
                  const accountRoleArray = []
                  const accountRoleNameArray = []
                  const accountArraySlug = []
                  const accountRoleNameMap = {}
                  const accountRoleUrl = saCustomerRolesV2
                    ? `/support-assistant/v2alpha1/customer-roles/${account?.id}`
                    : `/ui-doorway/ui/v2/cm/customers/${account?.id}/roles`
                  get(accountRoleUrl, {}, oidcUser.access_token).then(
                    (accountRoleResponse) => {
                      setTimeout(() => {
                        setAccountLoading(false)
                      }, 1000)
                      if (
                        accountRoleResponse?.data?.pagination?.total_count > 0
                      ) {
                        account?.roles?.forEach((accountRoleSlug) => {
                          const selectedAccountRole =
                            accountRoleResponse?.data?.roles?.find(
                              (currentRole) =>
                                currentRole?.slug === accountRoleSlug
                            )
                          const accRoleName = `${selectedAccountRole?.application_name} - ${selectedAccountRole?.name}`
                          accountRoleNameArray.push(accRoleName)
                          accountRoleNameMap[selectedAccountRole?.slug] =
                            accRoleName
                          accountRoleArray.push(
                            `${selectedAccountRole?.application_id}_${selectedAccountRole?.slug}`
                          )
                          accountArraySlug.push(selectedAccountRole?.slug)
                        })
                        const accountNameObject = {
                          accountName: accList,
                          accountRoles: accountArraySlug,
                          accountRoleNames: accountRoleNameMap
                        }
                        const accountObject = {
                          account: selectedAccount?.contact?.company_name,
                          accountID: selectedAccount?.customer_id,
                          accountOptions: [accList],
                          roleNames: accountRoleNameArray,
                          roles: accountRoleArray
                        }
                        accountList.push(accountObject)
                        accountNameList.push(accountNameObject)
                      }
                    },
                    (error) => {
                      setErrorMessage(getErrorMessage(error, t))
                      setTimeout(() => {
                        setAccountLoading(false)
                      }, 1000)
                    }
                  )
                }
              },
              () => {
                setTimeout(() => {
                  setAccountLoading(false)
                }, 1000)
              }
            )
          })
          setAccountDetails(accountList)
          setAccountName(accountNameList)
        }
      },
      (error) => {
        setNotificationDetails([])
        setErrorMessage(getErrorMessage(error, t))
        setLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    notificationId,
    oidcUser.access_token,
    t,
    fetchRegions,
    fetchRoles,
    showWorkspaceString,
    saCustomerRolesV2
  ])

  useEffect(() => {
    fetchNotificationDetails()
  }, [fetchNotificationDetails])

  const formExpiration = (formValues) => {
    dayjs.extend(utc)

    const { expirationDate, expirationTime } = formValues
    const expDate = dayjs(expirationDate).format('MM/DD/YYYY')
    const withExpTime = dayjs(`${expDate} ${expirationTime}`).unix()
    const inMilli = withExpTime

    return inMilli
  }
  const formScheduled = (formValues) => {
    dayjs.extend(utc)

    const { start_date, start_time } = formValues
    const expDate = dayjs(start_date).format('MM/DD/YYYY')
    const withExpTime = dayjs(`${expDate} ${start_time}`).unix()
    const inMilli = withExpTime

    return inMilli
  }

  const getStatusEnum = (severity) => {
    let element
    switch (severity) {
      case 'INFORMATION':
        element = 0
        break
      case 'CRITICAL':
        element = 1
        break
      case 'WARNING':
        element = 2
        break
      case 'OK':
        element = 3
        break
      default:
        element = 0
    }
    return element
  }

  const getAccountTarget = (accountArray) => {
    const accountTarget = []
    accountArray?.forEach((account) => {
      if (LDFlags['glcp-notifications-account-role-target']) {
        const accountRoles = [...new Set(account?.accountRoles)]
        let accountObj = {
          id: account?.accountName?.value,
          roles: accountRoles
        }
        if (LDFlags['glcp-notifications-feature-engineering-kobe']) {
          accountObj = {
            accountName: account?.accountName?.label,
            accountRoleNames: account?.accountRoleNames,
            ...accountObj
          }
        }
        accountTarget.push(accountObj)
      } else {
        accountTarget.push({ id: account?.value })
      }
    })
    return accountTarget
  }

  useEffect(() => {
    setRetriggerRender(!retriggerRender)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountDetails,
    accountName,
    applicationInstancesDetails,
    applicationInstancesName,
    roleDetails,
    roleName,
    notificationDetails,
    regionDetails
  ])

  const handleFinish = (formValues) => {
    if (!isUpdateFlow) {
      let notificationType = []

      if (formValues?.banner && formValues?.email) {
        notificationType = ['PORTAL', 'BANNER', 'EMAIL']
      } else if (formValues?.banner && !formValues?.email) {
        notificationType = ['PORTAL', 'BANNER']
      } else if (!formValues?.banner && formValues?.email) {
        notificationType = ['PORTAL', 'EMAIL']
      } else {
        notificationType = ['PORTAL']
      }

      const sendTtl = formExpiration(formValues)
      const startTime = formScheduled(formValues)
      const sendRegions =
        formValues?.region[0] === t('notification.all_regions')
          ? []
          : formValues?.region
      const sendInstances =
        formValues?.instance[0] === t('notification.all_instances')
          ? []
          : formValues?.instance
      const sendRoles =
        formValues?.role[0] === t('notification.all_roles')
          ? []
          : formValues?.role

      let requestBody = {}

      if (!LDFlags['glcp-notifications-feature-glasgow-admin']) {
        requestBody = {
          severity: getStatusEnum(formValues?.status?.toUpperCase()),
          target: getTargetEnum(
            LDFlags,
            formValues?.target,
            sendRegions,
            sendInstances,
            sendRoles
          ),
          channel: notificationType,
          ...requestBody
        }
      } else if (selectedType === t('notification.services')) {
        requestBody = {
          severity: getStatusEnum(formValues?.status?.toUpperCase()),
          target: getTargetEnum(
            LDFlags,
            formValues?.target,
            sendRegions,
            sendInstances,
            sendRoles
          ),
          channel: notificationType,
          ...requestBody
        }
        if (LDFlags['glcp-notifications-feature-engineering-kobe']) {
          requestBody = {
            appInstanceNames: formValues?.instanceIdNameMapping,
            appRoleNames: formValues?.roleSlugNameAppMap,
            ...requestBody
          }
        }
      } else if (selectedType === t('notification.email_address')) {
        const userArray = formValues?.emailAddresses?.replaceAll(' ', '')

        requestBody = {
          severity: getStatusEnum(formValues?.status?.toUpperCase()),
          target: {
            type: 'USERS',
            ids: userArray?.split(',')?.filter((item) => item !== '')
          },
          channel: notificationType,
          ...requestBody
        }
      } else {
        requestBody = {
          severity: getStatusEnum(formValues?.status?.toUpperCase()),
          target: {
            type: 'ACCOUNTS',
            accountTarget: getAccountTarget(formValues?.selectedAccountName)
          },
          channel: notificationType,
          ...requestBody
        }
      }

      if (LDFlags['glcp-notifications-feature-category-istanbul']) {
        let category
        if (!LDFlags['glcp-notifications-feature-dynamic-app-istanbul']) {
          category = {
            topics: formValues?.topic,
            service: formValues?.categoryService
          }
        } else {
          category = {
            topics: formValues?.topic,
            service: formValues?.appSlug,
            application_name: formValues?.appName
          }
        }

        if (!isEmpty(formValues?.subTopic)) {
          category = {
            sub_topics: formValues?.subTopic,
            ...category
          }
        }

        if (
          !isEmpty(formValues?.subService) &&
          formValues?.subService !== t('notification.default')
        ) {
          category = { sub_service: formValues?.subService, ...category }
        }
        requestBody = { category, ...requestBody }
      }
      if (LDFlags['glcp-notifications-feature-schedule-jhansi'] && startTime) {
        requestBody = { startTime, ...requestBody }
      }

      if (formValues?.banner && formValues?.email) {
        requestBody = {
          ttl: sendTtl,
          summary: formValues?.summary,
          description: formValues?.description,
          url: formValues?.url,
          url_text: formValues?.url_text,
          ...requestBody
        }
        if (
          formValues?.emailContentDetails?.toLowerCase() !==
          formValues?.description?.toLowerCase()
        ) {
          requestBody = {
            emailContentDetails: formValues?.emailContentDetails,
            ...requestBody
          }
        }
        if (
          formValues?.emailContentTitle?.toLowerCase() !==
          formValues?.summary?.toLowerCase()
        ) {
          requestBody = {
            emailContentTitle: formValues?.emailContentTitle,
            ...requestBody
          }
        }
      } else if (formValues?.banner && !formValues?.email) {
        requestBody = {
          ttl: sendTtl,
          summary: formValues?.summary,
          description: formValues?.description,
          url: formValues?.url,
          url_text: formValues?.url_text,
          ...requestBody
        }
      } else if (!formValues?.banner && formValues?.email) {
        requestBody = {
          summary: formValues?.summary,
          description: formValues?.description,
          url: formValues?.url,
          url_text: formValues?.url_text,
          emailContentTitle: formValues?.emailContentTitle,
          emailContentDetails: formValues?.emailContentDetails,
          ...requestBody
        }
      } else {
        requestBody = {
          summary: formValues?.summary,
          description: formValues?.description,
          url: formValues?.url,
          url_text: formValues?.url_text,
          ...requestBody
        }
      }
      if (!LDFlags['glcp-notifications-feature-edit-istanbul']) {
        put(
          `/notifications-svc/ui/v1alpha1/reviews/${notificationId}`,
          requestBody,
          oidcUser.access_token
        ).then(
          () => {
            navigate('/manage-ccs/notifications')
          },
          (error) => {
            setErrorMessage(getErrorMessage(error, t))
          }
        )
      } else {
        requestBody = {
          type: UPDATE_FOR_CREATE_REVIEW_REQUEST,
          ...requestBody
        }
        patch(
          `/notifications-svc/ui/v2/reviews/${notificationId}`,
          requestBody,
          oidcUser.access_token
        ).then(
          () => {
            navigate('/manage-ccs/notifications')
          },
          (error) => {
            setErrorMessage(getErrorMessage(error, t))
          }
        )
      }
    } else {
      let requestBody = {}
      if (initialFormValues?.status !== formValues?.status) {
        requestBody = {
          severity: getStatusEnum(formValues?.status?.toUpperCase()),
          ...requestBody
        }
      }
      if (initialFormValues?.summary !== formValues?.summary) {
        requestBody = { summary: formValues?.summary, ...requestBody }
      }
      if (initialFormValues?.description !== formValues?.description) {
        requestBody = { description: formValues?.description, ...requestBody }
      }
      if (initialFormValues?.state !== formValues?.state) {
        requestBody = {
          state: getStateEnum(formValues?.state?.toUpperCase()),
          ...requestBody
        }
      }

      if (formValues?.email && !initialFormValues?.email) {
        requestBody = {
          emailContentDetails: formValues?.emailContentDetails,
          emailContentTitle: formValues?.emailContentTitle,
          ...requestBody
        }
      } else if (formValues?.email && initialFormValues?.email) {
        if (
          initialFormValues?.emailContentTitle !== formValues?.emailContentTitle
        ) {
          requestBody = {
            emailContentTitle: formValues?.emailContentTitle,
            ...requestBody
          }
        }
        if (
          initialFormValues?.emailContentDetails !==
          formValues?.emailContentDetails
        ) {
          requestBody = {
            emailContentDetails: formValues?.emailContentDetails,
            ...requestBody
          }
        }
      }

      const sendTtlNew = formValues?.expirationDate
        ? formExpiration(formValues)
        : null

      const sendTtl = notificationDetails?.ttl

      const sendTtlFormat = dayjs(sendTtl * 1000).format('MM/DD/YYYY hh:mm A')

      const sendTtlNewFormat = dayjs(sendTtlNew * 1000).format(
        'MM/DD/YYYY hh:mm A'
      )

      if (sendTtlNewFormat !== sendTtlFormat) {
        requestBody = {
          ttl: sendTtlNew,
          ...requestBody
        }
      }

      requestBody = {
        type: UPDATE_FOR_UPDATE_REVIEW_REQUEST,
        ...requestBody
      }

      patch(
        `/notifications-svc/ui/v2/reviews/${notificationId}`,
        requestBody,
        oidcUser.access_token
      ).then(
        () => {
          navigate('/manage-ccs/notifications')
        },
        (error) => {
          let detailResponse = error?.response?.request?.response
          detailResponse = JSON.parse(detailResponse)
          if (error?.response?.request?.response && detailResponse?.detail) {
            const message = getErrorMessage(error, t)
            const errorMsg = (
              <Box>
                <Typography size="medium" testId="error-message" type="text">
                  {message}
                </Typography>
                <Typography size="medium" testId="error-message" type="text">
                  {detailResponse?.detail}
                </Typography>
              </Box>
            )
            setErrorMessage(errorMsg)
          } else {
            setErrorMessage(getErrorMessage(error, t))
          }
        }
      )
    }
  }

  let eligibleStep

  let notificationType
  let stepDescription = ''
  let stepTitle = ''
  let formValidation

  if (selectedType === t('notification.services')) {
    stepDescription = t('notification.service_step1_description')
    stepTitle = t('notification.service_step1_title')
    formValidation = validateCreateFields
    notificationType = (
      <NotificationTarget
        setBannerCheck={setBannerCheck}
        setEmailCheck={setEmailCheck}
        regionList={regionList}
        roleList={roleList}
        setRegionList={setRegionList}
        setRoleList={setRoleList}
        setInstanceList={setInstanceList}
        instanceList={instanceList}
        setSelectedRole={setSelectedRole}
        setSelectedInstance={setSelectedInstance}
        wizardError={wizardError}
        setWizardError={setWizardError}
        isUpdateFlow={isUpdateFlow}
        appSlug={appSlug}
        setAppSlug={setAppSlug}
        targetList={targetList}
      />
    )
  } else if (selectedType === t('notification.email_address')) {
    stepDescription = t('notification.email_address_step1_description')
    stepTitle = t('notification.email_address_step1_title')
    formValidation = validateEmailTypeFields
    notificationType = (
      <EmailType
        setBannerCheck={setBannerCheck}
        setEmailCheck={setEmailCheck}
        regionList={regionList}
        roleList={roleList}
        setRegionList={setRegionList}
        setRoleList={setRoleList}
        setInstanceList={setInstanceList}
        instanceList={instanceList}
        setSelectedRole={setSelectedRole}
        setSelectedInstance={setSelectedInstance}
        isUpdateFlow={isUpdateFlow}
      />
    )
  } else {
    stepDescription = t('notification.accounts_step1_description')
    stepTitle = t('notification.accounts_step1_title', {
      Accounts: getWorkspaceString(
        showWorkspaceString,
        t,
        WKSPC_PLURAL_CAPITALIZED
      )
    })
    formValidation = validateAccountTypeFields
    notificationType = (
      <AccountsType
        setBannerCheck={setBannerCheck}
        setEmailCheck={setEmailCheck}
        regionList={regionList}
        roleList={roleList}
        setRegionList={setRegionList}
        setRoleList={setRoleList}
        setInstanceList={setInstanceList}
        instanceList={instanceList}
        setSelectedRole={setSelectedRole}
        setSelectedInstance={setSelectedInstance}
        isUpdateFlow={isUpdateFlow}
      />
    )
  }

  let additionalStep = []
  if (
    selectedType ===
    getWorkspaceString(showWorkspaceString, t, WKSPC_PLURAL_CAPITALIZED)
  ) {
    additionalStep = [
      {
        childComponents: notificationType,
        description: stepDescription,
        title: stepTitle,
        validateForm: (formValues) => formValidation(formValues, t)
      },
      {
        childComponents: (
          <AccountTypeTarget
            setBannerCheck={setBannerCheck}
            setEmailCheck={setEmailCheck}
            regionList={regionList}
            roleList={roleList}
            setRegionList={setRegionList}
            setRoleList={setRoleList}
            setInstanceList={setInstanceList}
            instanceList={instanceList}
            setSelectedRole={setSelectedRole}
            setSelectedInstance={setSelectedInstance}
            isUpdateFlow={isUpdateFlow}
          />
        ),
        description: t('notification.step3_subtitle_account_target'),
        title: t('notification.step3_title_account_target'),
        validateForm: (formValues) =>
          validateAccountTypeTargetFields(formValues, t)
      }
    ]
  } else {
    additionalStep = [
      {
        childComponents: notificationType,
        description: stepDescription,
        title: stepTitle,
        validateForm: (formValues) => formValidation(formValues, t)
      }
    ]
  }
  const initialStep = [
    {
      childComponents: (
        <NotificationType
          setSelectedType={setSelectedType}
          selectedType={selectedType}
          isUpdateFlow={isUpdateFlow}
        />
      ),
      description: t('notification.step1_description'),
      title: t('notification.approval.edit_request_step1_title'),
      validateForm: (formValues) =>
        validateNotificationTypeFields(formValues, t)
    }
  ]

  if (bannerCheck && emailCheck) {
    let categoryStep = []
    if (LDFlags['glcp-notifications-feature-category-istanbul']) {
      categoryStep = [
        {
          childComponents: (
            <Category
              selectedType={selectedType}
              categoryResponse={categoryResponse}
              isUpdateFlow={isUpdateFlow}
              appSlug={appSlug}
              targetList={targetList}
            />
          ),
          description: t('notification.step_category_description'),
          title: (
            <Box direction="row" justify="center">
              {t('notification.step_category_title')}
              <Box justify="center" margin={{ top: 'xxsmall', left: 'xsmall' }}>
                <Tip content={t('notification.category_tip')}>
                  <CircleInformation size="medium" />
                </Tip>
              </Box>
            </Box>
          ),
          validateForm: (formValues) => validateCategoryFields(formValues, t)
        }
      ]
    }
    let endSteps = [
      {
        childComponents: <PortalNotification isUpdateFlow={isUpdateFlow} />,
        description: t('notification.step2_description_portal'),
        title: t('notification.step2_title_portal'),
        validateForm: (formValues) => validatePortalMessage(formValues, t)
      },
      {
        childComponents: <BannerNotification isUpdateFlow={isUpdateFlow} />,
        description: t('notification.step2_description_banner'),
        title: t('notification.step2_title_banner'),
        validateForm: (formValues) => validateBannerMessage(formValues, t)
      },
      {
        childComponents: (
          <EmailNotification
            bannerCheck={bannerCheck}
            isUpdateFlow={isUpdateFlow}
          />
        ),
        description: t('notification.step2_description_email'),
        title: t('notification.step2_title_email'),
        validateForm: (formValues) =>
          validateEmailMessageFlorence(formValues, t)
      },
      {
        childComponents: (
          <Review
            bannerCheck={bannerCheck}
            emailCheck={emailCheck}
            selectedRole={selectedRole}
            selectedInstance={selectedInstance}
            selectedType={selectedType}
            isUpdateFlow={isUpdateFlow}
            notificationDetails={notificationDetails}
            initialFormValues={initialFormValues}
          />
        ),
        description: t('notification.step3_description'),
        title: t('notification.step3_title'),
        validateForm: (formValues) => validateFormFields(formValues, t)
      }
    ]
    if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
      endSteps = [
        {
          childComponents: <NotificationContent isUpdateFlow={isUpdateFlow} />,
          description: t('notification.notification_content_description'),
          title: t('notification.notification_content'),
          validateForm: (formValues) =>
            validateNotificationContent(formValues, t)
        },
        {
          childComponents: <ScheduleNotification isUpdateFlow={isUpdateFlow} />,
          description: t('notification.schedule_description'),
          title: t('notification.schedule'),
          validateForm: (formValues) =>
            validateScheduleNotification(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
              isUpdateFlow={isUpdateFlow}
              notificationDetails={notificationDetails}
              initialFormValues={initialFormValues}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
    }
    eligibleStep = initialStep
      .concat(additionalStep)
      .concat(categoryStep)
      .concat(endSteps)
  } else if (bannerCheck && !emailCheck) {
    let categoryStep = []
    if (LDFlags['glcp-notifications-feature-category-istanbul']) {
      categoryStep = [
        {
          childComponents: (
            <Category
              selectedType={selectedType}
              categoryResponse={categoryResponse}
              isUpdateFlow={isUpdateFlow}
              appSlug={appSlug}
              targetList={targetList}
            />
          ),
          description: t('notification.step_category_description'),
          title: (
            <Box direction="row" justify="center">
              {t('notification.step_category_title')}
              <Box justify="center" margin={{ top: 'xxsmall', left: 'xsmall' }}>
                <Tip content={t('notification.category_tip')}>
                  <CircleInformation size="medium" />
                </Tip>
              </Box>
            </Box>
          ),
          validateForm: (formValues) => validateCategoryFields(formValues, t)
        }
      ]
    }
    let endSteps = [
      {
        childComponents: <PortalNotification isUpdateFlow={isUpdateFlow} />,
        description: t('notification.step2_description_portal'),
        title: t('notification.step2_title_portal'),
        validateForm: (formValues) => validatePortalMessage(formValues, t)
      },
      {
        childComponents: <BannerNotification isUpdateFlow={isUpdateFlow} />,
        description: t('notification.step2_description_banner'),
        title: t('notification.step2_title_banner'),
        validateForm: (formValues) => validateBannerMessage(formValues, t)
      },
      {
        childComponents: (
          <Review
            bannerCheck={bannerCheck}
            emailCheck={emailCheck}
            selectedRole={selectedRole}
            selectedInstance={selectedInstance}
            selectedType={selectedType}
            isUpdateFlow={isUpdateFlow}
            notificationDetails={notificationDetails}
            initialFormValues={initialFormValues}
          />
        ),
        description: t('notification.step3_description'),
        title: t('notification.step3_title'),
        validateForm: (formValues) => validateFormFields(formValues, t)
      }
    ]
    if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
      endSteps = [
        {
          childComponents: <NotificationContent isUpdateFlow={isUpdateFlow} />,
          description: t('notification.notification_content_description'),
          title: t('notification.notification_content'),
          validateForm: (formValues) =>
            validateNotificationContent(formValues, t)
        },
        {
          childComponents: <ScheduleNotification isUpdateFlow={isUpdateFlow} />,
          description: t('notification.schedule_description'),
          title: t('notification.schedule'),
          validateForm: (formValues) =>
            validateScheduleNotification(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
              isUpdateFlow={isUpdateFlow}
              notificationDetails={notificationDetails}
              initialFormValues={initialFormValues}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
    }
    eligibleStep = initialStep
      .concat(additionalStep)
      .concat(categoryStep)
      .concat(endSteps)
  } else if (!bannerCheck && emailCheck) {
    let categoryStep = []
    if (LDFlags['glcp-notifications-feature-category-istanbul']) {
      categoryStep = [
        {
          childComponents: (
            <Category
              selectedType={selectedType}
              categoryResponse={categoryResponse}
              isUpdateFlow={isUpdateFlow}
              appSlug={appSlug}
              targetList={targetList}
            />
          ),
          description: t('notification.step_category_description'),
          title: (
            <Box direction="row" justify="center">
              {t('notification.step_category_title')}
              <Box justify="center" margin={{ top: 'xxsmall', left: 'xsmall' }}>
                <Tip content={t('notification.category_tip')}>
                  <CircleInformation size="medium" />
                </Tip>
              </Box>
            </Box>
          ),
          validateForm: (formValues) => validateCategoryFields(formValues, t)
        }
      ]
    }

    let endSteps = [
      {
        childComponents: <PortalNotification isUpdateFlow={isUpdateFlow} />,
        description: t('notification.step2_description_portal'),
        title: t('notification.step2_title_portal'),
        validateForm: (formValues) => validatePortalMessage(formValues, t)
      },
      {
        childComponents: (
          <EmailNotification
            bannerCheck={bannerCheck}
            isUpdateFlow={isUpdateFlow}
          />
        ),
        description: t('notification.step2_description_email'),
        title: t('notification.step2_title_email'),
        validateForm: (formValues) =>
          validateEmailMessageFlorence(formValues, t)
      },
      {
        childComponents: (
          <Review
            bannerCheck={bannerCheck}
            emailCheck={emailCheck}
            selectedRole={selectedRole}
            selectedInstance={selectedInstance}
            selectedType={selectedType}
            isUpdateFlow={isUpdateFlow}
            notificationDetails={notificationDetails}
            initialFormValues={initialFormValues}
          />
        ),
        description: t('notification.step3_description'),
        title: t('notification.step3_title'),
        validateForm: (formValues) => validateFormFields(formValues, t)
      }
    ]

    if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
      endSteps = [
        {
          childComponents: <NotificationContent isUpdateFlow={isUpdateFlow} />,
          description: t('notification.notification_content_description'),
          title: t('notification.notification_content'),
          validateForm: (formValues) =>
            validateNotificationContent(formValues, t)
        },
        {
          childComponents: <ScheduleNotification isUpdateFlow={isUpdateFlow} />,
          description: t('notification.schedule_description'),
          title: t('notification.schedule'),
          validateForm: (formValues) =>
            validateScheduleNotification(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
              isUpdateFlow={isUpdateFlow}
              notificationDetails={notificationDetails}
              initialFormValues={initialFormValues}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
    }
    eligibleStep = initialStep
      .concat(additionalStep)
      .concat(categoryStep)
      .concat(endSteps)
  } else {
    let categoryStep = []
    if (LDFlags['glcp-notifications-feature-category-istanbul']) {
      categoryStep = [
        {
          childComponents: (
            <Category
              selectedType={selectedType}
              categoryResponse={categoryResponse}
              isUpdateFlow={isUpdateFlow}
              appSlug={appSlug}
              targetList={targetList}
            />
          ),
          description: t('notification.step_category_description'),
          title: (
            <Box direction="row" justify="center">
              {t('notification.step_category_title')}
              <Box justify="center" margin={{ left: 'xsmall' }}>
                <Tip content={t('notification.category_tip')}>
                  <CircleInformation size="medium" />
                </Tip>
              </Box>
            </Box>
          ),
          validateForm: (formValues) => validateCategoryFields(formValues, t)
        }
      ]
    }
    let endSteps = [
      {
        childComponents: <PortalNotification isUpdateFlow={isUpdateFlow} />,
        description: t('notification.step2_description_portal'),
        title: t('notification.step2_title_portal'),
        validateForm: (formValues) => validatePortalMessage(formValues, t)
      },
      {
        childComponents: (
          <Review
            bannerCheck={bannerCheck}
            emailCheck={emailCheck}
            selectedRole={selectedRole}
            selectedInstance={selectedInstance}
            selectedType={selectedType}
            isUpdateFlow={isUpdateFlow}
            notificationDetails={notificationDetails}
            initialFormValues={initialFormValues}
          />
        ),
        description: t('notification.step3_description'),
        title: t('notification.step3_title'),
        validateForm: (formValues) => validateFormFields(formValues, t)
      }
    ]
    if (LDFlags['glcp-notifications-feature-schedule-jhansi']) {
      endSteps = [
        {
          childComponents: <NotificationContent isUpdateFlow={isUpdateFlow} />,
          description: t('notification.notification_content_description'),
          title: t('notification.notification_content'),
          validateForm: (formValues) =>
            validateNotificationContent(formValues, t)
        },
        {
          childComponents: <ScheduleNotification isUpdateFlow={isUpdateFlow} />,
          description: t('notification.schedule_description'),
          title: t('notification.schedule'),
          validateForm: (formValues) =>
            validateScheduleNotification(formValues, t)
        },
        {
          childComponents: (
            <Review
              bannerCheck={bannerCheck}
              emailCheck={emailCheck}
              selectedRole={selectedRole}
              selectedInstance={selectedInstance}
              selectedType={selectedType}
              isUpdateFlow={isUpdateFlow}
              notificationDetails={notificationDetails}
              initialFormValues={initialFormValues}
            />
          ),
          description: t('notification.step3_description'),
          title: t('notification.step3_title'),
          validateForm: (formValues) => validateFormFields(formValues, t)
        }
      ]
    }
    eligibleStep = initialStep
      .concat(additionalStep)
      .concat(categoryStep)
      .concat(endSteps)
  }

  const getTarget = () => {
    let target = ''
    if (notificationDetails?.targetType === 'PLATFORM') {
      target = 'PLATFORM'
    } else if (
      notificationDetails?.targetType === 'CENTRALS' ||
      notificationDetails?.targetType === 'SERVICES'
    ) {
      target = notificationDetails?.target_ids?.[0]
    }
    return target
  }

  const getSubService = () => {
    if (notificationDetails?.category?.sub_service) {
      return notificationDetails?.category?.sub_service
    }
    return t('notification.default')
  }

  const getCategoryService = (targetOptions) => {
    const categorySer = targetOptions.find(
      (app) => app?.label === notificationDetails?.category?.application_name
    )
    const finalCategory = categorySer
    return finalCategory?.value || ''
  }

  return (
    <>
      {loading ||
      optionsLoadingRoles ||
      optionsLoading ||
      accountLoading ||
      targetLoading ? (
        <Box direction="row" align="center" justify="center">
          <Loader testId="devices-loader" />
        </Box>
      ) : (
        <Box>
          <Wizard
            key={retriggerRender}
            testId="create-notication"
            title={t('notification.approval.edit_request_step1_title')}
            actionOnExit={() => {
              navigate('/manage-ccs/notifications')
            }}
            actionOnSubmit={(formValues) => {
              handleFinish(formValues)
            }}
            buttonLabels={{
              finish: LDFlags['glcp-notifications-approval-feature']
                ? t('notification.approval.re_submit_for_approval')
                : t('common:finish')
            }}
            formDefaultValues={{
              target:
                notificationDetails?.targetType === 'CENTRALS' ||
                notificationDetails?.targetType === 'PLATFORM' ||
                notificationDetails?.targetType === 'SERVICES'
                  ? getTarget()
                  : '',
              status: getSeverity(notificationDetails?.severity, t) || '',
              banner: notificationDetails?.is_banner || false,
              expirationDate: getBannerExpirationDate() || '',
              expirationTime: getBannerExpirationTime() || '',
              description: notificationDetails?.description || '',
              summary: notificationDetails?.summary || '',
              url_checkbox: notificationDetails?.url || false,
              url: notificationDetails?.url || '',
              url_text: notificationDetails?.url_text || '',
              instance: applicationInstancesDetails || [
                t('notification.all_instances')
              ],
              role: roleDetails || [t('notification.all_roles')],
              region: regionDetails || [t('notification.all_regions')],
              emailContentTitle:
                (notificationDetails?.emailContentTitle
                  ? notificationDetails?.emailContentTitle
                  : notificationDetails?.summary) || '',
              emailContentDetails:
                (notificationDetails?.emailContentDetails
                  ? notificationDetails?.emailContentDetails
                  : notificationDetails?.description) || '',
              email: notificationDetails?.is_email || false,
              emailAddresses: notificationDetails?.target_ids?.toString() || '',
              selectedInstanceName: applicationInstancesName || [],
              selectedRoleName: roleName || [],
              accounts: accountDetails || [],
              instanceIdNameMapping: notificationDetails?.appInstanceNames,
              roleSlugNameAppMap: notificationDetails?.appRoleNames,
              selectedAccountName: accountName || [],
              workspaceMessage: t('notification.account_validation', {
                account: getWorkspaceString(showWorkspaceString, t, WKSPC),
                an: showWorkspaceString
                  ? t('common:article.a')
                  : t('common:article.an')
              }),
              categoryService: LDFlags[
                'glcp-notifications-feature-dynamic-app-istanbul'
              ]
                ? getCategoryService(targetList)
                : notificationDetails?.category?.service,
              subService: getSubService() || '',
              subTopic: notificationDetails?.category?.sub_topics || '',
              topic: notificationDetails?.category?.topics || '',
              subServiceOptions: [],
              state: getState(notificationDetails?.state),
              isEdit: true,
              isUpdateFlow,
              target_name: notificationDetails?.application_name,
              appSlug: notificationDetails?.category?.service || '',
              appName: notificationDetails?.category?.application_name,
              isDynamic:
                LDFlags['glcp-notifications-feature-dynamic-app-istanbul'],
              targetType: notificationDetails?.targetType,
              start_date_checkbox: notificationDetails?.startTime !== null,
              start_date:
                notificationDetails?.startTime === null
                  ? ''
                  : getScheduleDate(),
              start_time:
                notificationDetails?.startTime === null ? '' : getScheduleTime()
            }}
            steps={eligibleStep}
          />
          {errorMessage && (
            <Notification
              backgroundColor="status-critical"
              onClose={() => setErrorMessage(null)}
              testId="critical-inline-notification"
              text={errorMessage}
              icon={<StatusCritical size="medium" />}
            />
          )}
        </Box>
      )}
    </>
  )
}

export default EditNotificationRequestWizard
