// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useTranslation } from 'react-i18next'

import { isGLOP, isOrg } from '../../../../utils/feature-flag-utils'

import AssetsByAge from './assetsByAge'
import AssetsByRisk from './assetsByRisk'

export const AssetsWidgetsData = () => {
  const { t } = useTranslation(['manage'])

  const widgetsData = [
    {
      widgetKey: 'widgets-workloads-overview',
      visible: true,
      order: 1,
      testId: 'widgets-assets-workload-overview',
      title: t('asset_management.widgets.workload_summary.title'),
      subtitle: null,
      footerMessage: null,
      footerNotification: null,
      chartType: 'bar',
      editMode: false,
      hideFor: null,
      rbac: {
        resource: isOrg() && !isGLOP() ? undefined : '/ccs/authorization',
        permission:
          isOrg() && !isGLOP()
            ? 'ccs.accounts.user.view-all'
            : 'ccs.authorization.view'
      },
      actionUrl: '/manage-account/asset-management/workloads',
      widgetData: {
        listData: [
          {
            label: t('asset_management.widgets.workload_summary.workloads'),
            summary: true,
            value: 232,
            valueKey: 'total_assets'
          },
          {
            label: t(
              'asset_management.widgets.workload_summary.virtual_machines'
            ),
            summary: false,
            value: 162,
            valueKey: 'virtual_machine_assets'
          },
          {
            label: t('asset_management.widgets.workload_summary.containers'),
            summary: false,
            value: 85,
            valueKey: 'containers_assets'
          },
          {
            label: t('asset_management.widgets.workload_summary.bare_metal'),
            summary: false,
            value: 60,
            valueKey: 'bare_metal_assets'
          },
          {
            label: t('asset_management.widgets.workload_summary.public_cloud'),
            summary: false,
            value: 17,
            valueKey: 'public_cloud_assets'
          }
        ]
      }
    },
    {
      testId: 'widgets-assets-by-age',
      title: t('asset_management.widgets.assets_by_age.title'),
      chartType: '',
      children: <AssetsByAge />,
      onSelectClick: null,
      actionUrl: '/manage-account/asset-management/assets'
    },
    {
      testId: 'widgets-assets-by-risk',
      title: t('asset_management.widgets.assets_by_risk.title'),
      chartType: '',
      children: <AssetsByRisk />,
      onSelectClick: null
    }
  ]

  return widgetsData
}
