// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Anchor, Box, NameValueList, NameValuePair } from 'grommet'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { Typography } from '../../../../../components'

const OnPremDetailSection = ({ workspaceDetails }) => {
  const { t } = useTranslation(['manage', 'common'])
  const navigate = useNavigate()

  const onpremDetailI18n = {
    onPremWorkspaceName: t('onprem_workspace.onprem_workspace_name'),
    onPremWorkspaceId: t('onprem_workspace.onprem_workspace_id'),
    subscriptionKey: t('onprem_workspace.subscription_key')
  }

  const getValue = (key) => {
    if (!workspaceDetails[key]) return '--'
    if (key === 'subscriptionKey')
      return (
        <Anchor
          label={workspaceDetails[key]}
          onClick={() =>
            navigate(`/services/service-subscriptions/${workspaceDetails[key]}`)
          }
        />
      )
    return workspaceDetails[key]
  }

  return (
    <Box margin={{ vertical: 'large' }}>
      <Box
        direction="row"
        justify="between"
        border={{ color: 'border-weak', size: 'xsmall', side: 'bottom' }}
        margin={{ bottom: 'medium' }}
        pad={{ bottom: 'small' }}
      >
        <Typography
          level="2"
          testId="workspace-details-section-header"
          type="heading"
        >
          {t('onprem_workspace.workspace_details')}
        </Typography>
      </Box>
      <Box>
        <NameValueList data-testid="workspace-details-section-list">
          {Object.entries(onpremDetailI18n).map(([key, value]) => {
            return (
              <NameValuePair key={key} name={value}>
                {getValue(key)}
              </NameValuePair>
            )
          })}
        </NameValueList>
      </Box>
    </Box>
  )
}

OnPremDetailSection.propTypes = {
  workspaceDetails: PropTypes.object.isRequired
}

export { OnPremDetailSection }
