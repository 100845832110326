// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Box, FormField } from 'grommet'
import { useFlags } from 'launchdarkly-react-client-sdk'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography,
  Notification,
  CCSForm,
  Dropdown,
  Button
} from '../../components'
import { get, post, patch } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'
import { displayDeviceTypeEnum } from '../../utils/common-utils'

import { getNumNetworkingDevices } from './utils/common-methods'
import {
  DevicesSummary,
  WarningModal,
  LocationDetailSection
} from './components'
import { modifiedLocationResponses } from './utils/api-utils'

const ManageLocationModal = ({
  setModalOpen,
  datum,
  mode = 'BULK',
  openSuccessModal,
  isCCSManager = false,
  customerId = undefined
}) => {
  const { t } = useTranslation(['device', 'common'])
  const LDFlags = useFlags()
  const dmUXIFlag = LDFlags['glcp-dm-uxi']
  const dmBridgeFlag = LDFlags['glcp-5g-bridge']
  const { oidcUser } = useReactOidc()
  const initDeviceStats = {
    COMPUTE: 0,
    AP: 0,
    GATEWAY: 0,
    SWITCH: 0,
    STORAGE: 0,
    PCE: 0,
    SENSOR: 0,
    BRIDGE: 0
  }
  const [deviceStats, setDeviceStats] = useState(initDeviceStats)
  const [assignedDevicesCount, setAssignedDevicesCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [listOfLocation, setListOfLocation] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [assignedLocation, setAssignedLocation] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [formError, setFormError] = useState('')
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showLocationModal, setShowLocationModal] = useState(false)
  const [appendList, setAppendList] = useState(true)
  // for paginating on scroll location
  const itemsPerPage = 50
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [locationDetails, setLocationDetails] = useState({})

  const handleAssignbtn = () => {
    setAssignedLocation(selectedLocation)
    setFormError('')
  }

  const handleDebouncedSearchValue = debounce((value) => {
    setAppendList(false)
    setPage(0)
    setSearchTerm(value)
  }, 500)

  useEffect(() => {
    // Logic to show stats in Device Summary Card
    const statsObj = datum?.reduce((a, value) => {
      const dType = value?.display_device_type
      if (Object.values(displayDeviceTypeEnum)?.includes(dType)) {
        switch (dType) {
          case displayDeviceTypeEnum.COMPUTE:
          case displayDeviceTypeEnum.DHCI_COMPUTE:
            a.COMPUTE = (a.COMPUTE || 0) + 1
            break
          case displayDeviceTypeEnum.STORAGE:
          case displayDeviceTypeEnum.DHCI_STORAGE:
            a.STORAGE = (a.STORAGE || 0) + 1
            break
          case displayDeviceTypeEnum.GATEWAY:
          case displayDeviceTypeEnum.SD_WAN_GW:
            a.GATEWAY = (a.GATEWAY || 0) + 1
            break
          case displayDeviceTypeEnum.BRIDGE:
            a.BRIDGE = (a.BRIDGE || 0) + 1
            break
          default:
            a[dType] = (a[dType] || 0) + 1
        }
      } else console.error('Invalid device type', value) // To debug if it breaks in future
      return a
    }, initDeviceStats)
    setDeviceStats(statsObj)

    // API call to get assigned location stats
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/stats`
      : '/ui-doorway/ui/v1/devices/stats'

    const reqBody = {
      devices:
        datum?.map((value) => {
          return {
            serial_number: value.serial_number,
            part_number: value.part_number,
            mac_address: value.mac_address || ''
          }
        }) || [],
      filter: { assigned_location: true }
    }
    if (isCCSManager) reqBody.platform_customer_id = customerId
    post(url, reqBody, oidcUser.access_token).then(
      (response) => {
        if (response?.data?.count) {
          setAssignedDevicesCount(response?.data?.count)
          setShowWarningModal(true)
        } else setShowLocationModal(true)
      },
      () => {
        setAssignedDevicesCount('ERROR')
        setShowWarningModal(true)
      }
    )
  }, [datum, oidcUser.access_token]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // API call to get list of contact
    const url = isCCSManager
      ? `/support-assistant/v1alpha1/locations`
      : '/ui-doorway/ui/v1/locations'

    const request = {
      page_size: itemsPerPage,
      page_id: page || 1,
      ...(searchTerm?.trimStart()?.length && {
        search_string: searchTerm?.trimStart()
      })
    }
    if (isCCSManager) request.platform_customer_id = customerId
    get(url, request, oidcUser.access_token).then(
      (response) => {
        const locationResponse = response?.data?.data
        if (locationResponse) {
          let modifiedLocationResponse = []
          if (appendList) {
            modifiedLocationResponse = [...listOfLocation, ...locationResponse]
            if (selectedLocation)
              modifiedLocationResponse = [
                ...modifiedLocationResponse,
                selectedLocation
              ]
            modifiedLocationResponse = uniqBy(modifiedLocationResponse, 'id')
          } else modifiedLocationResponse = locationResponse
          setListOfLocation(modifiedLocationResponse)
          setTotalItems(response?.data?.pagination?.total_count || totalItems)
        }
      },
      (error) => {
        setErrorMessage(displayApiError(error, t, setErrorMessage))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, page, searchTerm, t])

  useEffect(() => {
    // To fetch assigned location details
    if (assignedLocation?.id) {
      const url = isCCSManager
        ? `/support-assistant/v1alpha1/location`
        : '/ui-doorway/ui/v1/locations'
      const reqBody = {}
      if (isCCSManager) reqBody.platform_customer_id = customerId
      get(
        `${url}/${assignedLocation?.id}`,
        reqBody,
        oidcUser.access_token
      ).then(
        (response) => {
          const locationResponse = response?.data
          if (locationResponse) {
            setLocationDetails(modifiedLocationResponses(locationResponse))
          } else setLocationDetails({})
        },
        (error) => {
          setErrorMessage(displayApiError(error, t, setErrorMessage))
        }
      )
    } else setLocationDetails({})
  }, [oidcUser.access_token, assignedLocation?.id, t, isCCSManager, customerId])

  const handleSave = () => {
    if (assignedLocation) {
      // API call to assign location to selected devices
      const url = isCCSManager
        ? `/support-assistant/v1alpha1/devices`
        : '/ui-doorway/ui/v1/devices'

      const reqBody = {
        devices:
          datum?.map((value) => {
            return {
              serial_number: value?.serial_number,
              part_number: value?.part_number,
              ...([
                displayDeviceTypeEnum.STORAGE,
                displayDeviceTypeEnum.DHCI_STORAGE,
                displayDeviceTypeEnum.COMPUTE,
                displayDeviceTypeEnum.DHCI_COMPUTE,
                displayDeviceTypeEnum.PCE
              ].includes(value?.display_device_type)
                ? {}
                : {
                    mac_address: value?.mac_address
                  }),
              device_type: value?.device_type,
              location_id: assignedLocation?.id,
              location_name: assignedLocation?.name
            }
          }) || []
      }
      if (isCCSManager) reqBody.platform_customer_id = customerId
      patch(url, reqBody, oidcUser.access_token).then(
        () => {
          setModalOpen(false)
          openSuccessModal()
        },
        (error) => {
          setErrorMessage(displayApiError(error, t, setErrorMessage))
        }
      )
    } else setFormError(t('please_select_location'))
  }

  const handleDeleteAction = () => {
    setLocationDetails({})
    setAssignedLocation(null)
  }

  const buttonList = [
    {
      label: t('cancel'),
      default: true,
      testId: 'cancel-btn',
      onClick: () => setModalOpen(false)
    },
    {
      label: t('common:save'),
      primary: true,
      testId: 'save-btn',
      onClick: handleSave
    }
  ]

  return (
    <>
      {showWarningModal && (
        <WarningModal
          title={t('assign_location_title')}
          content={
            (assignedDevicesCount === 'ERROR' && ( // NOSONAR
              <Notification
                backgroundColor="status-warning"
                testId="warning-inline-notification"
                type="inline"
                text={
                  datum?.length > 1
                    ? t('assign_location_status_error_multi')
                    : t('assign_location_status_error_single')
                }
              />
            )) ||
            (assignedDevicesCount === 1 &&
              t('single_device_assign_location_content')) ||
            t('multiple_device_assign_location_content', {
              deviceCount: assignedDevicesCount
            })
          }
          onCancel={() => {
            setShowWarningModal(false)
            setModalOpen(false)
          }}
          onContinue={() => {
            setShowWarningModal(false)
            setShowLocationModal(true)
          }}
        />
      )}
      {showLocationModal && (
        <ModalDialog
          width="large"
          height="large"
          testId="manage-location-modal-dialog"
          onClose={() => {
            setModalOpen(false)
          }}
          header={
            <ModalHeader
              title={
                <Typography
                  type="heading"
                  level="1"
                  weight="bold"
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                  testId="manage-location-modal-title"
                >
                  {t('manage_location')}
                </Typography>
              }
            />
          }
          content={
            <Box flex={{ shrink: 0 }}>
              <>
                <Typography
                  type="text"
                  size="large"
                  margin={{ top: 'xsmall' }}
                  testId="manage-location-modal-subtitle"
                >
                  {t('manage_locations_dlg_prompt')}
                </Typography>
                {assignedDevicesCount ? (
                  <Box margin={{ top: 'medium', bottom: 'xxsmall' }}>
                    <Notification
                      backgroundColor="status-warning"
                      testId="warning-inline-notification"
                      type="inline"
                      text={
                        // check
                        (assignedDevicesCount === 'ERROR' && // NOSONAR
                          (datum?.length > 1
                            ? t('assign_location_status_error_multi')
                            : t('assign_location_status_error_single'))) ||
                        (assignedDevicesCount === 1 &&
                          t('single_device_location_assigned')) || (
                          <Trans i18nKey="multiple_device_sdi_assigned" t={t}>
                            <strong>
                              {{ devicesAssigned: assignedDevicesCount }}
                            </strong>
                          </Trans>
                        )
                      }
                    />
                  </Box>
                ) : null}
                {mode !== 'SINGLE' && datum?.length && (
                  <DevicesSummary
                    numComputeDevices={deviceStats.COMPUTE}
                    numNetworkingDevices={getNumNetworkingDevices(
                      dmUXIFlag,
                      dmBridgeFlag,
                      deviceStats
                    )}
                    numAccessPoints={deviceStats.AP}
                    numGateways={deviceStats.GATEWAY}
                    numSwitches={deviceStats.SWITCH}
                    numStorageDevices={deviceStats.STORAGE}
                    numPCEDevices={deviceStats.PCE}
                    numSensors={dmUXIFlag ? deviceStats.SENSOR : 0}
                    numBridges={dmBridgeFlag ? deviceStats.BRIDGE : 0}
                  />
                )}

                <Box>
                  <CCSForm
                    justify="start"
                    validate="submit"
                    errorMessage=""
                    testId="add-form"
                  >
                    <Box
                      margin={{ top: 'medium', bottom: 'xxsmall' }}
                      direction="row"
                      justify="between"
                      gap="small"
                    >
                      <FormField
                        data-testid="location-form-field"
                        label={t('assign_location')}
                        name="assign_location"
                        width="large"
                        required={{ indicator: false }}
                        error={formError || undefined}
                      >
                        <Dropdown
                          name="assign_location"
                          options={listOfLocation}
                          labelKey="name"
                          valueKey="id"
                          value={selectedLocation || ''}
                          searchPlaceholder={t('search')}
                          emptySearchMessage={t('no_data_found')}
                          onSearch={(searchText) => {
                            handleDebouncedSearchValue(searchText)
                          }}
                          onChange={({ option }) => {
                            setFormError('')
                            setSelectedLocation(option)
                          }}
                          noBorder
                          onClose={() => {
                            if (searchTerm) {
                              setListOfLocation([])
                              setPage(0)
                              setSearchTerm('')
                              setAppendList(true)
                            }
                          }}
                          testId="location-input"
                          onMore={() => {
                            if (
                              totalItems > itemsPerPage &&
                              page < totalItems / itemsPerPage
                            ) {
                              setAppendList(true)
                              setPage(page + 1)
                            }
                          }}
                        />
                      </FormField>
                      <Box width="small" justify="center">
                        <Button
                          testId="assign-btn"
                          label={t('assign')}
                          secondary
                          type="submit"
                          onClick={handleAssignbtn}
                          size="medium"
                          margin={{ vertical: '32px' }} // Using px as an exception case to align Formfield & Button to achieve field level error since it was not achievable with Tshirt size.
                        />
                      </Box>
                    </Box>
                  </CCSForm>

                  <LocationDetailSection
                    data={locationDetails}
                    handleDelete={handleDeleteAction}
                    title={t('assigned_location_title')}
                    placeholder={t('select_location_to_assign')}
                  />
                </Box>
              </>
            </Box>
          }
          footer={
            <ModalFooter
              right={
                <ButtonGroup buttonList={buttonList} testId="modal-btns" />
              }
            />
          }
        />
      )}
      {errorMessage}
    </>
  )
}

ManageLocationModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  datum: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(['BULK', 'SINGLE']),
  openSuccessModal: PropTypes.func.isRequired,
  isCCSManager: PropTypes.bool,
  customerId: PropTypes.string
}

export { ManageLocationModal }
