// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { MenuList } from '../../../components'
import { customRenderer } from '../../CustomRenderer'
import { isInventoryOwnedWorkspace } from '../../../utils/feature-flag-utils'
import { isServiceCentric } from '../../../utils/account-utils'

const MainNav = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation(['common', 'dashboard'])
  const LDFlags = useFlags()
  const serviceCentric = isServiceCentric(LDFlags)
  const mainMenuData = [
    {
      id: 1,
      label: serviceCentric ? t('nav_bar.home') : t('header_nav.dashboard'),
      routeTo: '/home',
      testId: 'dashboard-nav-menu'
    },
    {
      id: 2,
      label: serviceCentric
        ? t('dashboard:common.services')
        : t('header_nav.applications'),
      routeTo: serviceCentric
        ? '/services/my-services'
        : '/applications/my-apps',
      testId: 'application-nav-menu',
      visibility: {
        hideFor: {
          deployment: ['COP']
        }
      }
    },
    {
      id: 3,
      label: t('header_nav.devices'),
      routeTo: '/devices/inventory',
      testId: 'devices-nav-menu',
      visibility: {
        rbac: {
          resource: '/ccs/device-management',
          permission: 'ccs.device-management.view'
        }
      },
      isHidden: () => !isInventoryOwnedWorkspace()
    },
    {
      id: 4,
      label: t('header_nav.manage'),
      routeTo: '/manage-account',
      testId: 'manage-nav-menu'
    },
    {
      id: 5,
      label: t('header_nav.customers'),
      routeTo: '/customer-account',
      testId: 'customers-nav-menu',
      visibility: {
        hideFor: {
          account: ['STANDALONE', 'TENANT']
        }
      }
    }
  ]
  const getSelectedMenu = () => {
    const data = mainMenuData.find((item) => {
      return item.routeTo === pathname
    })
    return data && data.id
  }

  const MainNavigation = (
    <MenuList
      defaultActiveId={getSelectedMenu()}
      navStyles={{
        padding: 'none',
        gap: 'medium',
        direction: 'row'
      }}
      highlightOnSelect
      menuData={mainMenuData}
      menuItemStyles={{
        weight: 500
      }}
      onClickMenuItem={(item) => {
        navigate(item.routeTo)
      }}
      testId="glcp_main_menu"
      customRenderer={customRenderer}
    />
  )
  return MainNavigation
}
export { MainNav }
