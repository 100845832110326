// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormField, TextInput, Text } from 'grommet'
import lodashGet from 'lodash/get'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'

import { Typography } from '../../../../../../components'
import { WizardContext } from '../../../../../../components/wizard/WizardContext'

import { validateConfigureSettingsFields as validateFields } from './utils/validation-utils'

// remove browser default styling
const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  min-inline-size: 0;
`

const Fieldset = ({ legend, ...rest }) => {
  const {
    formField: { label }
  } = useContext(ThemeContext)

  return (
    <StyledFieldset>
      {/* to be read by screenreader, legend needs to be direct
      child of fieldset */}
      <Text as="legend" {...label} margin={{ bottom: 'xsmall' }}>
        {legend}
      </Text>
      <Box gap="xsmall">
        <Box gap="small" {...rest} />
      </Box>
    </StyledFieldset>
  )
}

Fieldset.propTypes = {
  legend: PropTypes.string.isRequired
}

const ConfigureSettings = ({ configValues = null }) => {
  const { t } = useTranslation(['authn', 'iam'])
  const { formValues, setFormValues, attemptedAdvance } =
    useContext(WizardContext)

  const [errorMessage, setErrorMessage] = useState({
    email: '',
    // countryCode: '',
    firstName: '',
    lastName: ''
  })

  useEffect(() => {
    if (attemptedAdvance) {
      const validation = validateFields(formValues, t)
      setErrorMessage(validation)
    }
  }, [formValues, attemptedAdvance, t])

  useEffect(() => {
    if (configValues)
      setFormValues({
        ...formValues,
        attribute_mapping: {
          ...formValues.attribute_mapping,
          ...configValues
        }
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box width="large" pad={{ top: 'xsmall' }}>
      <Box gap="xsmall" pad={{ bottom: 'xsmall' }}>
        <Typography type="text" size="medium" testId="map-saml-description">
          {t('iam:sso_profiles.view_edit_create.saml_attribute_default')}
        </Typography>
      </Box>
      <Box pad={{ top: 'small', bottom: 'medium' }}>
        <FormField
          aria-label="Email"
          name="email"
          data-testid="email-attr-form-field"
          label={t('iam:sso_profiles.view_edit_create.email_attribute_name')}
          width="medium"
          required
          error={errorMessage.email}
        >
          <TextInput
            name="email_value"
            data-testId="email-value-input"
            value={lodashGet(formValues, 'attribute_mapping.email')}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                attribute_mapping: {
                  ...formValues.attribute_mapping,
                  email: event.target.value
                }
              })
            }}
          />
        </FormField>

        {/*
        <>
          <FormField
            name="country_code_attribute"
            data-testid="country-code-form-field"
            width="medium"
            label="SAML attribute name for country code"
            required
            error={errorMessage.countryCode}
          >
            <TextInput
              aria-label="Country Code"
              name="country_code_attribute"
              data-testid="country-code-input"
              value={lodashGet(formValues, 'attribute_mapping.countryCode')}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  attribute_mapping: {
                    ...formValues.attribute_mapping,
                    countryCode: event.target.value
                  }
                })
              }}
            />
          </FormField>
        </>
        */}
      </Box>
      <Box gap="xsmall" pad={{ bottom: 'medium' }}>
        <Box pad={{ bottom: 'small' }}>
          <Typography type="text" testId="optional-attributes-description">
            {t('iam:sso_profiles.view_edit_create.optional_attributes')}
          </Typography>
        </Box>
        <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
          {t('iam:sso_profiles.view_edit_create.first_name_attribute')}*
        </Text>
        <Text size="xsmall">
          {t(
            'iam:sso_profiles.view_edit_create.first_name_attribute_description'
          )}
        </Text>
        <FormField
          error={errorMessage.firstName}
          name="first_name"
          data-testid="first-name-form-field"
          width="medium"
          required
        >
          <TextInput
            aria-label="First Name"
            name="first_name"
            data-testid="first-name-input"
            value={lodashGet(formValues, 'attribute_mapping.firstName')}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                attribute_mapping: {
                  ...formValues.attribute_mapping,
                  firstName: event.target.value
                }
              })
            }}
          />
        </FormField>
        <Text size="xsmall" margin={{ top: 'xxsmall' }} weight={500}>
          {t('iam:sso_profiles.view_edit_create.last_name_attribute')}*
        </Text>
        <Text size="xsmall">
          {t(
            'iam:sso_profiles.view_edit_create.last_name_attribute_description'
          )}
        </Text>
        <FormField
          error={errorMessage.lastName}
          name="last_name"
          data-testid="last-name-form-field"
          width="medium"
          required
        >
          <TextInput
            aria-label="Last Name"
            name="last_name"
            data-testid="last-name-input"
            value={lodashGet(formValues, 'attribute_mapping.lastName')}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                attribute_mapping: {
                  ...formValues.attribute_mapping,
                  lastName: event.target.value
                }
              })
            }}
          />
        </FormField>
      </Box>
    </Box>
  )
}

ConfigureSettings.propTypes = {
  configValues: PropTypes.object
}

export default ConfigureSettings
