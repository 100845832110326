// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { CaseCreationContext } from '../context/CaseCreationContext'
import { Typography } from '../../../../components'

import ContactElements from './ContactElements'

const Contact = ({ showContactPreference, caseFormCallBack = () => {} }) => {
  const { t } = useTranslation(['support_cases'])
  const CaseFormContextData = useContext(CaseCreationContext)
  const { contacts } = CaseFormContextData
  return (
    <>
      <Typography
        level="2"
        type="heading"
        margin={{ bottom: 'medium', top: 'large' }}
      >
        {t('support_cases:create_case.final_details_msg')}
      </Typography>
      <ContactElements
        contactConfig={{
          contactTitle: t('support_cases:contact.primary_contact'),
          toolTipRequired: false,
          contactType: 'Primary',
          contactPreferenceVisible: showContactPreference
        }}
        caseFormCallBack={caseFormCallBack}
      />
      {contacts.length > 1 && (
        <Box margin={{ top: 'medium' }}>
          <ContactElements
            contactConfig={{
              contactTitle: t('support_cases:contact.alternate_contact'),
              toolTipRequired: true,
              contactType: 'Alternate',
              contactPreferenceVisible: showContactPreference
            }}
            caseFormCallBack={caseFormCallBack}
          />
        </Box>
      )}
    </>
  )
}
Contact.propTypes = {
  showContactPreference: PropTypes.bool.isRequired,
  caseFormCallBack: PropTypes.func
}

export default Contact
