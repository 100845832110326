// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import PropTypes from 'prop-types'
import {
  Anchor,
  Box,
  NameValueList,
  Page,
  PageContent,
  PageHeader,
  NameValuePair
} from 'grommet'
import {
  Previous,
  StatusCriticalSmall,
  StatusGoodSmall,
  StatusWarningSmall
} from 'grommet-icons'

import { Typography } from '../../../../components'
import { get, getErrorMessage } from '../../../../utils/api-utils'
import { getDeviceTypeLabel } from '../../../../utils/common-utils'
import { getDateByMonthAbvDayYear } from '../../../../utils/dm-sm-common-utils'

export const AssetsDetailSection = ({ t, title, data }) => {
  const getLocationValue = (value) => {
    let address = []
    if (value?.city) address.push(value?.city)
    if (value?.state) address.push(value?.state)
    if (value?.postalCode) address.push(value?.postalCode)
    if (value?.country) address.push(value?.country)
    address = address?.toString()?.replaceAll(',', ', ')
    return (
      <Box>
        {value?.name && (
          <Typography
            size="medium"
            color="text-strong"
            testId="location-name"
            wordBreak="break-all"
            type="text"
          >
            {value?.name}
          </Typography>
        )}
        {value?.streetAddress && (
          <Typography
            size="medium"
            color="text-strong"
            testId="location-street-address"
            wordBreak="break-all"
            type="text"
          >
            {value?.streetAddress}
          </Typography>
        )}
        {address && (
          <Typography
            size="medium"
            color="text-strong"
            testId="location-country-address"
            wordBreak="break-all"
            type="text"
          >
            {address}
          </Typography>
        )}
      </Box>
    )
  }
  const riskLevelIcon = {
    1: <StatusGoodSmall size="small" color="status-ok" />,
    2: <StatusWarningSmall size="small" color="status-warning" />,
    3: <StatusCriticalSmall size="small" color="status-critical" />
  }

  const getValueContent = (name, value) => {
    switch (name) {
      case 'location':
        return getLocationValue(value)
      case 'manufactured_date':
        return getDateByMonthAbvDayYear(value)
      case 'support_state':
        return (
          <Box>
            <Typography
              icon={riskLevelIcon[value.riskSupportLevel]}
              testId="icon-and-status-value"
              type="text"
              truncate="tip"
              align="left"
            >
              {value.status ? 'Active' : 'Inactive'}
            </Typography>
          </Box>
        )

      case 'support_end_date':
        return getDateByMonthAbvDayYear(value)
      default:
        return value
    }
  }
  return (
    <Box direction="column">
      <Box data-testid="details" margin={{ bottom: 'large' }}>
        <Typography
          level="2"
          type="heading"
          testId={`${title}-section-heading`}
        >
          {title}
        </Typography>
        <Box
          alignSelf="start"
          justify="between"
          direction="column"
          margin={{ top: 'small' }}
          fill="horizontal"
        >
          <NameValueList
            valueProps={{ width: ['auto', 'medium'] }}
            data-testid={`${title}-section-details`}
          >
            {Object.entries(data).map(([name, value]) => {
              return (
                <NameValuePair
                  key={t(`manage:asset_management.assets_table.${name}`)}
                  name={
                    <Typography
                      size="medium"
                      type="text"
                      weight={500}
                      testId={`${name}-key`}
                    >
                      {t(`manage:asset_management.assets_table.${name}`)}
                    </Typography>
                  }
                >
                  <Box data-testid={`${name}-value`}>
                    {getValueContent(name, value)}
                  </Box>
                </NameValuePair>
              )
            })}
          </NameValueList>
        </Box>
      </Box>
    </Box>
  )
}

AssetsDetailSection.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

const AssetsDetailsPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['device', 'manage', 'common'])
  const [loading, setLoading] = useState(true)
  const [assetsData, setAssetData] = useState({})
  const [assetOverviewData, setAssetOverviewData] = useState({})
  const [assetSupportData, setAssetSupportData] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const handleBackBtnClick = () => {
    navigate('/manage-account/asset-management/assets')
  }

  useEffect(() => {
    get(
      `/internal-assets/v1beta1/assets/${id}`,
      {},
      oidcUser.access_token
    ).then(
      (response) => {
        setAssetData(response?.data)
        setAssetOverviewData({
          serial: response?.data.serial,
          model: response?.data.model,
          location: response?.data.location,
          age: response?.data.age,
          manufactured_date: response?.data.manufacturedDate
        })
        setAssetSupportData({
          support_state: {
            riskSupportLevel: response?.data.riskSupportLevel,
            status: response?.data.warranty.supportStatus
          },
          support_level: response?.data.warranty.serviceLevel,
          support_end_date: response?.data.warranty.endDate
        })
        setLoading(false)
      },
      (error) => {
        setErrorMessage(getErrorMessage(error, t))
        setLoading(false)
      }
    )
  }, [t, id, oidcUser.access_token])

  return (
    <Page kind="narrow">
      <PageContent>
        {!loading && !errorMessage && (
          <>
            <PageHeader
              title={assetsData?.serial}
              subtitle={getDeviceTypeLabel(t, assetsData?.category)}
              parent={
                <Anchor
                  label={t('manage:asset_management.assets_back_button')}
                  icon={<Previous />}
                  onClick={handleBackBtnClick}
                  data-testid="go-to-assets-page"
                />
              }
              data-testid="asset-details-header"
            />
            <Box
              direction="column"
              justify="start"
              alignSelf="center"
              width="large"
              pad={{ top: 'small' }}
            >
              <AssetsDetailSection
                t={t}
                title={t(
                  'manage:asset_management.asset_details_overview_heading'
                )}
                data={assetOverviewData}
              />
              <AssetsDetailSection
                t={t}
                title={t(
                  'manage:asset_management.asset_details_support_heading'
                )}
                data={assetSupportData}
              />
            </Box>
          </>
        )}
      </PageContent>
    </Page>
  )
}

export { AssetsDetailsPage }
