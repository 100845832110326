// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { useNavigate } from 'react-router-dom'
import { Box } from 'grommet'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  ButtonGroup,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../../components'
import { launchAccount } from '../common-utils'
import { getCustomerAccount } from '../../../utils/feature-flag-utils'
import {
  getWorkspaceString,
  WKSPC,
  WKSPC_CAPITALIZED
} from '../../../utils/common-utils'
import { isServiceCentric } from '../../../utils/account-utils'

const RemoveApplicationModal = ({
  customer,
  handleClose,
  provisionedAppCount
}) => {
  const { t } = useTranslation(['authn', 'common'])
  const navigate = useNavigate()
  const LDFlags = useFlags()
  const showWorkspaceString = LDFlags['glcp-switch-to-workspace']
  const newDashboardFlag = isServiceCentric(LDFlags)
  const custAccountLoaded = getCustomerAccount()

  const handleLaunch = () => {
    launchAccount(custAccountLoaded, customer, navigate)
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              level="1"
              testId="modal-title"
              type="heading"
              weight="bold"
            >
              {newDashboardFlag
                ? t('customer_account.remove_service_manager')
                : t('customer_account.remove_application')}
            </Typography>
          }
        />
      }
      content={
        <Box margin={{ top: 'medium' }}>
          <Typography type="text" size="medium">
            <Trans
              i18nKey={
                newDashboardFlag
                  ? 'customer_account.remove_service_desc'
                  : 'customer_account.remove_app_desc'
              }
              t={t}
              provisionedAppCount={provisionedAppCount}
            >
              <strong>{{ provisionedAppCount }}</strong>
              <>
                {{ account: getWorkspaceString(showWorkspaceString, t, WKSPC) }}
              </>
            </Trans>
          </Typography>
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 2,
                  label: t('customer_account.cancel'),
                  default: true,
                  testId: 'cancel-btn',
                  onClick: handleClose
                },
                {
                  id: 1,
                  label: t('customer_account.launch_account', {
                    account: getWorkspaceString(
                      showWorkspaceString,
                      t,
                      WKSPC_CAPITALIZED
                    )
                  }),
                  primary: true,
                  testId: 'launch-btn',
                  onClick: handleLaunch
                }
              ]}
              testId="two-buttons"
            />
          }
        />
      }
      onClose={handleClose}
      testId="remove-application-modal"
      width="large"
    />
  )
}

RemoveApplicationModal.propTypes = {
  customer: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  provisionedAppCount: PropTypes.number.isRequired
}

export { RemoveApplicationModal }
