// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../components'

import LocationInfo from './LocationInfo'

function NewLocationDetails({ data = {}, title }) {
  return (
    <Box>
      <Box margin={{ vertical: 'medium' }}>
        <Typography level="3" type="heading" testId="assigned-location-header">
          {title}
        </Typography>
      </Box>

      <LocationInfo pickedLocationDetails={data} />
    </Box>
  )
}

NewLocationDetails.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string.isRequired
}

export default React.memo(NewLocationDetails)
