// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet'
import { CircleInformation } from 'grommet-icons'
import PropTypes, { string } from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { patch, getErrorMessage } from '../../../utils/api-utils'
import { Loader, NoDataInfo } from '../../../components'
import { readStatus } from '../../../utils/notification-logs-utils'

function paginateNext(
  response,
  nextPageURI,
  currentPage,
  dispatchNotifications,
  stateV
) {
  dispatchNotifications({
    type: 'IS_LOADING',
    isLoading: false
  })
  dispatchNotifications({
    type: 'NO_LOADER',
    noLoader: false
  })
  // setErrorMessage(null)
  dispatchNotifications({
    type: 'LOADING_WITH_FILTER',
    loadingWithFilter: false
  })
  dispatchNotifications({
    type: 'HIDE_PAGINATION',
    hidePagination: false
  })
  dispatchNotifications({
    type: 'LOADING_WITHOUT_FILTER',
    loadingWithOutFilter: false
  })
  if (response?.data?.count > 0) {
    if (nextPageURI === '' && currentPage === 1) {
      dispatchNotifications({
        type: 'ALL_DATA',
        allData: response?.data?.items
      })
    } else {
      const newAllData = stateV.allData.slice()
      const newData = response?.data?.items
      const appendData = [...newAllData, ...newData]

      dispatchNotifications({
        type: 'ALL_DATA',
        allData: appendData
      })
    }
    dispatchNotifications({
      type: 'PAGE_DATA',
      pageData: response?.data?.items
    })

    if (response?.data?.next != null) {
      dispatchNotifications({
        type: 'NEXT_URI',
        nextURI: `&next=${response?.data?.next}`
      })

      if (nextPageURI === '' && currentPage === 1) {
        dispatchNotifications({
          type: 'MAINTAIN_NEXT_URI',
          maintainNextURI: [`&next=${response?.data?.next}`]
        })
      } else {
        const listNextURI = stateV.maintainNextURI.slice()

        listNextURI.push(`&next=${response?.data?.next}`)

        setTimeout(() => {
          dispatchNotifications({
            type: 'MAINTAIN_NEXT_URI',
            maintainNextURI: listNextURI
          })
        }, 100)
      }
      dispatchNotifications({
        type: 'HIDE',
        hideNext: false
      })
      dispatchNotifications({
        type: 'HIDE_NEXT',
        hideNext: false
      })
    } else {
      dispatchNotifications({
        type: 'NEXT_URI',
        nextURI: ''
      })
      dispatchNotifications({
        type: 'HIDE_NEXT',
        hideNext: true
      })
      dispatchNotifications({
        type: 'NO_LOADER',
        noLoader: true
      })
      const listNextURI = stateV.maintainNextURI.slice()

      listNextURI.push(`&next=null`)

      setTimeout(() => {
        dispatchNotifications({
          type: 'MAINTAIN_NEXT_URI',
          maintainNextURI: listNextURI
        })
      }, 100)
    }
  } else {
    dispatchNotifications({
      type: 'PAGE_DATA',
      pageData: []
    })
    dispatchNotifications({
      type: 'NEXT_URI',
      nextURI: ``
    })
    dispatchNotifications({
      type: 'HIDE_NEXT',
      hideNext: true
    })
    dispatchNotifications({
      type: 'NO_LOADER',
      noLoader: false
    })
    dispatchNotifications({
      type: 'NOTIFICATION_EMPTY',
      notificationEmpty: true
    })
  }
}

function errorResponseHandling(
  error,
  dispatchNotifications,
  setErrorMessage,
  t
) {
  dispatchNotifications({
    type: 'NO_LOADER',
    noLoader: false
  })
  dispatchNotifications({
    type: 'IS_LOADING',
    isLoading: false
  })
  setErrorMessage(getErrorMessage(error, t))
  dispatchNotifications({
    type: 'NEXT_URI',
    nextURI: ``
  })
  dispatchNotifications({
    type: 'ALL_DATA',
    allData: []
  })
  dispatchNotifications({
    type: 'PAGE_DATA',
    pageData: []
  })
  dispatchNotifications({
    type: 'MAINTAIN_NEXT_URI',
    maintainNextURI: []
  })
  dispatchNotifications({
    type: 'LOADING_WITH_FILTER',
    loadingWithFilter: false
  })
  dispatchNotifications({
    type: 'HIDE_NEXT',
    hideNext: true
  })
  dispatchNotifications({
    type: 'HIDE_PAGINATION',
    hidePagination: false
  })
  dispatchNotifications({
    type: 'LOADING_WITHOUT_FILTER',
    loadingWithOutFilter: false
  })
}

const RenderNoDataInfo = ({ t, type = '' }) => (
  <Box pad={{ vertical: 'xlarge' }} width={{ max: '100%', min: '100%' }}>
    <NoDataInfo
      icon={<CircleInformation size="large" color="text-weak" />}
      subtitle={
        type === 'ALERTSTASKS'
          ? t('notification.alerts_tasks.no_alerts_tasks_exists')
          : t('notification.alerts_tasks.no_announcements_exists')
      }
      secondarySubtitle={
        type === 'ALERTSTASKS'
          ? t('notification.alerts_tasks.no_alerts_tasks_info')
          : t('notification.alerts_tasks.no_announcements_info')
      }
      testId="no-available-alerts-announcements"
    />
  </Box>
)

const NotificationLoader = () => (
  <Box
    direction="row"
    align="center"
    width="100%"
    height="medium"
    justify="center"
  >
    <Loader testId="notification-logs-screen-loader" />
  </Box>
)

const getOmitted = (data, stateV) => {
  const ids = []
  data?.forEach((notification) => {
    if (!stateV.select.includes(notification?.id)) {
      ids.push(notification?.id)
    }
  })
  return ids
}
const getOmittedRead = (data, stateV) => {
  const ids = []
  data?.forEach((notification) => {
    if (!stateV.select.includes(notification?.id) && notification?.isRead) {
      ids.push(notification?.id)
    }
  })
  return ids
}

const getOmittedUnRead = (data, stateV) => {
  const ids = []
  data?.forEach((notification) => {
    if (!stateV.select.includes(notification?.id) && !notification?.isRead) {
      ids.push(notification?.id)
    }
  })
  return ids
}

const getReadStatus = (selectedReadStatus, t, type) => {
  if (type === 'ALERTSTASKS') {
    return selectedReadStatus?.toLowerCase() ===
      readStatus.mark_read?.toLowerCase()
      ? {
          patch: {
            path: 'isRead',
            value: true
          }
        }
      : {
          patch: {
            path: 'isRead',
            value: false
          }
        }
  }
  return selectedReadStatus?.toLowerCase() ===
    readStatus.mark_read?.toLowerCase()
    ? 'READ'
    : 'UNREAD'
}

const bulkUpdateReadStatus = ({
  stateV,
  dispatchNotifications,
  oidcUser,
  setErrorMessage,
  t,
  type
}) => {
  if (stateV.confirmAction && !isEmpty(stateV.selectedReadStatus)) {
    let requestBody
    if (stateV.selectedCheckBoxStatus === '') {
      requestBody = {
        selection: 'NONE',
        omitted: [],
        selected: stateV.select,
        action: getReadStatus(stateV.selectedReadStatus, t, type)
      }
    } else if (stateV.selectedCheckBoxStatus === readStatus.all) {
      requestBody = {
        selection: 'ALL',
        omitted: getOmitted(stateV.pageData, stateV),
        selected: [],
        action: getReadStatus(stateV.selectedReadStatus, t, type)
      }
    } else if (stateV.selectedCheckBoxStatus === readStatus.read) {
      requestBody = {
        selection: 'READ',
        omitted: getOmittedRead(stateV.pageData, stateV),
        selected: [],
        action: getReadStatus(stateV.selectedReadStatus, t, type)
      }
    } else if (stateV.selectedCheckBoxStatus === readStatus.unread) {
      requestBody = {
        selection: 'UNREAD',
        omitted: getOmittedUnRead(stateV.pageData, stateV),
        selected: [],
        action: getReadStatus(stateV.selectedReadStatus, t, type)
      }
    }
    dispatchNotifications({
      type: 'PATCH_LOADER',
      patchLoader: true
    })
    dispatchNotifications({
      type: 'NO_LOADER',
      noLoader: true
    })
    dispatchNotifications({
      type: 'READ',
      read: undefined
    })
    let url = ''
    if (type === 'ALERTSTASKS') {
      url = '/notifications-svc/ui/v1alpha1/events'
    } else {
      url = '/notifications-svc/ui/v1alpha1/notifications'
    }
    patch(url, requestBody, oidcUser.access_token).then(
      () => {
        dispatchNotifications({
          type: 'BULK_UPDATE_RESET'
        })
        setTimeout(() => {
          dispatchNotifications({
            type: 'TRIGGER_NOTIFICATION_REQUEST',
            triggerNotificationRequest: !stateV.triggerNotificationRequest
          })
        }, 400)
        setErrorMessage(null)
      },
      (error) => {
        dispatchNotifications({
          type: 'PATCH_LOADER',
          patchLoader: false
        })
        setErrorMessage(getErrorMessage(error, t))
      }
    )
  }
}

RenderNoDataInfo.propTypes = {
  t: PropTypes.func.isRequired,
  type: string
}

export {
  paginateNext,
  errorResponseHandling,
  RenderNoDataInfo,
  NotificationLoader,
  bulkUpdateReadStatus
}
