// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import { React, Suspense, lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import LazyLoading from '../../../../commoncomponents/LazyLoading/LazyLoading'

import SsoConnectionRouter from './sso-connection/router'

const ViewSSODetails = lazy(() => import('./pages/ViewSsoDetails'))
const EditSSODetails = lazy(() => import('./pages/EditSsoDetails'))

const SsoRouter = () => {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Routes>
        <Route path="/sso-connection/*" element={<SsoConnectionRouter />} />
        <Route exact path="/:domain/details" element={<ViewSSODetails />} />
        <Route exact path="/:domain/edit" element={<EditSSODetails />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  )
}

export default SsoRouter
