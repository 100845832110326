// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, FormField, TextInput } from 'grommet'
import { useTranslation } from 'react-i18next'

import { FormTextArea } from '../../../../../components'
import { WizardContext } from '../../../../../components/wizard/WizardContext'

import { validateDetailsForm } from './utils'

const defaultFieldsError = {
  name: '',
  description: ''
}

const AddRoleDetails = ({
  selectedAppRole = {
    resourcePolicies: []
  }
}) => {
  const {
    formValues,
    formValues: { roleDetails },
    formValues: {
      roleDetails: { name, description }
    },
    attemptedAdvance,
    setFormValues
    /* setAttemptedAdvance */
  } = useContext(WizardContext)

  const { t } = useTranslation(['authz', 'common'])
  const [fieldsError, setFieldsError] = useState(defaultFieldsError)

  useEffect(() => {
    if (attemptedAdvance) {
      setFieldsError(validateDetailsForm(formValues, t))
    }
  }, [attemptedAdvance, formValues, t])

  const setInitialValues = () => {
    if (selectedAppRole?.duplicateFlag && roleDetails?.name === '') {
      setFormValues({
        ...formValues,
        roleDetails: {
          ...roleDetails,
          name: `Copy of ${selectedAppRole.name}`,
          description: `${selectedAppRole?.description}`,
          appName: selectedAppRole?.provAppName,
          appLogo: selectedAppRole?.appLogo,
          appId: selectedAppRole.application_id,
          duplicate: true,
          originalRoleName: `Copy of ${selectedAppRole?.name}`,
          selectedRole: selectedAppRole
        }
      })
    } else {
      setFormValues({
        ...formValues,
        roleDetails: {
          ...roleDetails,
          appName: selectedAppRole?.application_name,
          appLogo: selectedAppRole?.application_logo,
          appId: selectedAppRole?.application_id
        }
      })
    }
  }

  useEffect(() => {
    setInitialValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppRole])

  return (
    <>
      <Box width="medium" margin={{ top: 'xsmall' }}>
        <FormField
          name="role_name"
          label={t('roles.role_name')}
          required
          error={fieldsError.name}
          data-testid="role-name-form-field"
        >
          <TextInput
            value={name}
            name="role-name"
            type="text"
            placeholder={t('roles.role_name')}
            data-testid="role-name-input"
            autoComplete="off"
            onChange={(e) => {
              if (e.target.value.length > 100) {
                return
              }
              setFormValues({
                ...formValues,
                roleDetails: {
                  ...roleDetails,
                  name: e.target.value
                }
              })
            }}
          />
        </FormField>
      </Box>
      <Box width="medium" margin={{ top: 'xsmall', bottom: 'medium' }}>
        <FormTextArea
          value={description}
          label={t('description')}
          name="role-description"
          type="text"
          placeholder={`i.e. ${t('roles.role')} ${t('description')} `}
          testId="role-description-input"
          autoComplete="off"
          height="medium"
          maxLength="256"
          labelHelper={t('desc_max_chars')}
          onChange={(e) => {
            if (e.target.value.length > 256) {
              return
            }
            setFormValues({
              ...formValues,
              roleDetails: {
                ...roleDetails,
                description: e.target.value
              }
            })
          }}
        />
      </Box>
    </>
  )
}

export default AddRoleDetails

AddRoleDetails.propTypes = {
  selectedAppRole: PropTypes.any
}
