// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import { Box, Image, Video, Carousel, Grommet } from 'grommet'
import PropTypes from 'prop-types'
import { StatusCritical, StatusGoodSmall } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import { deepMerge } from 'grommet/utils'
import { hpe } from 'grommet-theme-hpe'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import {
  ModalDialog,
  Typography,
  ModalHeader,
  Notification,
  Anchor,
  Loader
} from '../../../../../components'
import {
  WHATS_NEW_ALLOWED_IMAGE_TYPES,
  WHATS_NEW_ALLOWED_VIDEO_TYPE
} from '../../../../../utils/validation-utils'
import { get, getErrorMessage } from '../../../../../utils/api-utils'
import { getCustomerAccount } from '../../../../../utils/feature-flag-utils'
import VisibilityWrapper from '../../../../../commoncomponents/visibility-wrapper/VisibilityWrapper'
import postBackground from '../../../../../images/BackgroundGradientBrand.png'

const panelTheme = deepMerge(hpe, {
  carousel: {
    icons: {
      current: StatusGoodSmall,
      width: '3px'
    }
  },
  icon: {
    size: {
      medium: '10px'
    }
  }
})

const PreviewAnnouncement = ({ Post, closeModel }) => {
  const { t } = useTranslation(['manage', 'common'])

  const [apiErrorMessage, setApiErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [awsResponse, setawsResponse] = useState({})
  const custAccountLoaded = getCustomerAccount()
  const platformCustomerId = custAccountLoaded?.platform_customer_id || ''
  const [activeSlide, setActiveSlide] = useState(0)
  const { oidcUser } = useReactOidc()

  useEffect(() => {
    const operation = 'view'
    if (Post?.media) {
      setLoading(true)
      get(
        `/whatsnew/v1/blog/medialink?op=${operation}&path=${Post?.media}`,
        {},
        oidcUser.access_token,
        false,
        {},
        {
          'X-Account-ID': platformCustomerId
        }
      ).then(
        (response) => {
          if (response?.data) {
            const data = response?.data
            data.mediaType = Post?.mediaType
            setawsResponse(data)
            setLoading(false)
          }
        },
        (error) => {
          const errorMessage = getErrorMessage(error, t)
          setApiErrorMessage(errorMessage)
          setLoading(false)
        }
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser.access_token, t])

  return (
    <VisibilityWrapper
      hideFor={{ feature: 'glcp-whatsnew-istanbul', deployment: ['COP'] }}
    >
      <Box>
        <ModalDialog
          width="large"
          height="large"
          testId="post-model"
          overflow="hidden"
          background={{
            image: `url(${postBackground})`,
            size: '100% 50%',
            color: 'white',
            position: 'top center'
          }}
          header={
            <ModalHeader
              onClose={() => {
                closeModel()
              }}
            />
          }
          content={
            <>
              <Box
                pad={{ horizontal: 'small', top: 'xsmall' }}
                height="100%"
                width="100%"
              >
                <Box
                  pad={{ horizontal: 'small' }}
                  gap="small"
                  data-testId="model-box"
                  direction="row"
                  width="100%"
                  height="100%"
                >
                  <Box
                    data-testId="model-box-2"
                    pad={{ top: 'xsmall' }}
                    width="100%"
                    height="100%"
                  >
                    <Grommet
                      data-testid="theme"
                      theme={panelTheme}
                      background="unset"
                      style={{ height: '100%', width: '100%' }}
                    >
                      <Box
                        data-testId="carousel-div"
                        align="center"
                        justify="end"
                        fill
                      >
                        <Carousel
                          fill="false"
                          controls={false}
                          activeChild={activeSlide}
                          onChild={setActiveSlide}
                          margin={{ bottom: 'small', top: 'medium' }}
                        >
                          <Box
                            direction="column"
                            pad={{ horizontal: 'small', top: 'small' }}
                            align="end"
                            fill
                          >
                            {loading ? (
                              <Box data-testid="media-blog" justify="end" fill>
                                <Box
                                  direction="row"
                                  alignSelf="center"
                                  align="center"
                                  justify="center"
                                  height="100%"
                                >
                                  <Loader testId="programs-loader" />
                                </Box>
                              </Box>
                            ) : (
                              <Box data-testid="media-blog" justify="end" fill>
                                {WHATS_NEW_ALLOWED_IMAGE_TYPES.includes(
                                  awsResponse?.mediaType
                                ) && (
                                  <Box data-testid="image-div" justify="end">
                                    <Image
                                      fill="vertically"
                                      alignSelf="end"
                                      src={awsResponse?.signedURL}
                                    />
                                  </Box>
                                )}
                                {WHATS_NEW_ALLOWED_VIDEO_TYPE.includes(
                                  awsResponse?.mediaType
                                ) && (
                                  <Box data-testid="video-div" justify="end">
                                    <Video
                                      fill="vertically"
                                      alignSelf="end"
                                      autoPlay={false}
                                      controls="over"
                                    >
                                      <source
                                        src={awsResponse?.signedURL}
                                        type={awsResponse?.mediaType}
                                      />
                                    </Video>
                                  </Box>
                                )}
                              </Box>
                            )}
                            <Box
                              align="center"
                              gap="medium"
                              fill
                              data-testid="content-blog"
                            >
                              <Box pad={{ top: 'medium' }}>
                                <Typography
                                  level="2"
                                  type="heading"
                                  textAlign="center"
                                  testId="post-announcement-title"
                                >
                                  {Post?.title}
                                </Typography>
                              </Box>
                              <Box pad={{ bottom: 'medium' }} align="center">
                                <Typography
                                  type="text"
                                  size="medium"
                                  testId="post-announcement-summary"
                                  textAlign="center"
                                >
                                  {Post?.summary}
                                </Typography>
                                <Anchor
                                  testId="post-announcement-learn-more"
                                  label={t(
                                    'whats_new.create_post_form.learn_more'
                                  )}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Carousel>
                      </Box>
                    </Grommet>
                  </Box>
                </Box>
              </Box>
            </>
          }
          footer={
            <Box
              pad={{ horizontal: 'large', top: 'small' }}
              background="white"
              align="center"
              alignContent="baseline"
              border={{ side: 'top', color: 'border-weak' }}
              // This height property will be removed once Anchor tag is uncommented
              height={{ min: '25px' }}
            >
              {/* Will uncomment the code once user personaliastion is available
              <Anchor
                label={t('whats_new.create_post_form.dont_show_message')}
                color="text-weak"
                size="xsmall"
                testId="post-announcement-dont-show"
              /> */}
            </Box>
          }
        />
        {apiErrorMessage && (
          <Notification
            backgroundColor="status-critical"
            onClose={() => setApiErrorMessage(null)}
            testId="critical-inline-notification"
            text={apiErrorMessage}
            icon={<StatusCritical size="medium" />}
          />
        )}
      </Box>
    </VisibilityWrapper>
  )
}
PreviewAnnouncement.propTypes = {
  Post: PropTypes.array.isRequired,
  closeModel: PropTypes.func.isRequired
}

export default PreviewAnnouncement
