// Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */
import { Box } from 'grommet'
import { CircleInformation, StatusCritical } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'

import {
  DataTable,
  Notification,
  Typography,
  Tooltip
} from '../../../../../../components'
import { getPaginationShowIdx } from '../../../../../../utils/common-utils'
import { getTableData } from '../../customer-apis'

const FoldersDataTable = ({ customerData, refreshCount }) => {
  const type = 'folders'
  const navigate = useNavigate()
  const { oidcUser } = useReactOidc()
  const { t } = useTranslation(['device', 'common'])
  const [tableData, setTableData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const itemsPerPage = 10
  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [page, setPage] = useState(1)
  const pageIdxInfo = getPaginationShowIdx(page, totalItems, itemsPerPage, t)

  const getTipContent = (value) => {
    return (
      <Tooltip
        plain
        info={
          <Box
            align="center"
            background="background"
            round={{ size: 'xsmall' }}
            pad="small"
            border={{
              color: 'background-contrast',
              size: 'xsmall'
            }}
            elevation="xlarge"
            width="small"
          >
            <Typography type="text">
              <Trans i18nKey={value} t={t}>
                <strong />
              </Trans>
            </Typography>
          </Box>
        }
        dropProps={{ align: { left: 'right', top: 'bottom' } }}
        testId="tooltip"
      >
        <Box pad={{ top: 'xsmall' }}>
          <CircleInformation size="small" />
        </Box>
      </Tooltip>
    )
  }

  const columns = [
    {
      property: 'folder_name',
      type: 'string',
      header: t('name'),
      render: (datum) => (
        <Box width="small" align="stretch">
          <Typography
            size="small"
            type="text"
            weight="bold"
            testId={`folder-name-${datum.id}`}
            reverse
            icon={
              (datum.folder_name === 'default' &&
                getTipContent('default_folder_desc')) ||
              (datum?.folder_name?.startsWith('athena') &&
                getTipContent('athena_folder_desc')) ||
              null
            }
          >
            {datum.folder_name}
          </Typography>
        </Box>
      )
    },
    {
      property: 'parent_folder_name',
      type: 'string',
      header: t('parent')
    },
    {
      property: 'description',
      type: 'string',
      header: t('description')
    },
    {
      property: 'num_of_devices',
      type: 'numeric',
      header: t('num_of_devices')
    },
    {
      property: 'num_of_rules',
      type: 'numeric',
      header: t('num_of_rules')
    }
  ]

  useEffect(() => {
    const request = {
      limit: itemsPerPage,
      page: page - 1,
      platform_customer_id: customerData.id,
      ...(searchTerm &&
        searchTerm.trimStart().length > 2 && {
          search_name: searchTerm.trimStart()
        })
    }

    getTableData(
      type,
      oidcUser.access_token,
      request,
      setTotalItems,
      setErrorMessage,
      t
    ).then((data) => {
      setTableData(data)
    })
  }, [
    customerData.id,
    oidcUser.access_token,
    searchTerm,
    page,
    refreshCount,
    t
  ])
  // Logic when row is clicked
  const handleRowClick = (datum) => {
    sessionStorage.setItem('folderDetailParam', JSON.stringify(datum))
    navigate(`folders/folder-details`, {
      state: { customerData }
    })
  }

  return (
    <Box pad={{ horizontal: 'xxsmall', top: 'small' }}>
      {errorMessage && (
        <Notification
          backgroundColor="status-critical"
          onClose={() => setErrorMessage(null)}
          testId="critical-inline-notification"
          text={errorMessage}
          icon={<StatusCritical size="medium" />}
        />
      )}
      <DataTable
        pagination={{
          totalItems,
          itemsPerPage,
          page,
          setPage,
          pageIdxInfo
        }}
        search={{
          width: 'medium',
          onSearchValueChange: (val) => {
            setPage(1)
            setSearchTerm(val)
          },
          placeholder: t('folders_search_box_placeholder')
        }}
        grid={{
          columns,
          data: tableData,
          onClickRow: (e) => {
            handleRowClick(e.datum)
          }
        }}
        summary={{
          entityName: t('folders_tab')
        }}
        testId="folders-table"
      />
    </Box>
  )
}

FoldersDataTable.propTypes = {
  customerData: PropTypes.object.isRequired,
  refreshCount: PropTypes.number.isRequired
}

export { FoldersDataTable }
