// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { Anchor, Box, PageHeader } from 'grommet'
import { Previous } from 'grommet-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Layout } from '../../../../commoncomponents/layout/Layout'

import { WorkloadsDatatable } from './WorkloadsDatatable'

const Workloads = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['manage'])
  const handleBackBtnClick = () => {
    navigate('/manage-account/asset-management')
  }

  return (
    <Layout>
      <Box
        data-testid="workloads-page"
        align="stretch"
        margin={{ horizontal: 'xlarge', bottom: 'xlarge' }}
      >
        <Box margin={{ horizontal: 'xlarge' }}>
          <PageHeader
            title={t('asset_management.workloads_table.workloads_title')}
            subtitle={t('asset_management.workloads_table.workloads_subtitle')}
            parent={
              <Anchor
                label={t('asset_management.asset_management_title')}
                icon={<Previous />}
                onClick={handleBackBtnClick}
                data-testid="go-to-manage-account-btn"
              />
            }
            data-testid="workloads-table-header"
          />
          <WorkloadsDatatable />
        </Box>
      </Box>
    </Layout>
  )
}

export { Workloads }
