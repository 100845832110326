// Copyright 2024 Hewlett Packard Enterprise Development LP
import React from 'react'
import { Box, Card, CardBody, CardFooter, Text, Heading, Button } from 'grommet'
import PropTypes from 'prop-types'

import { Typography } from '../../../../components'

const ServiceManagerTab = ({
  serviceDetailData,
  setIsLoading,
  setActiveTabIndex,
  navigate,
  t
}) => {
  const serviceOffer = serviceDetailData?.service_offer || {}
  const serviceManager = serviceDetailData?.service_manager || {}
  const { name: serviceName } = serviceOffer
  const {
    slug: serviceManagerSlug,
    name: serviceManagerName,
    categories: serviceManagerCategories,
    short_description: serviceManagerShortDescription,
    id: managerServiceId
  } = serviceManager

  return (
    <Box margin={{ top: 'small' }} pad={{ vertical: 'medium' }}>
      <Box width="xlarge">
        <Heading
          level="2"
          size="small"
          margin={{
            horizontal: 'none',
            top: 'none',
            bottom: 'small'
          }}
        >
          {t('dashboard:common.service_manager')}
        </Heading>
        <Typography type="text" size="large">
          {serviceManagerSlug === 'GLC'
            ? t('dashboard:service_detail.glc_service_manager_redirect', {
                service_name: serviceName,
                service_manager_name: serviceManagerName,
                view_details: t('dashboard:common.view_details')
              })
            : t('dashboard:service_detail.service_manager_redirect', {
                service_name: serviceName,
                service_manager_name: serviceManagerName,
                view_details: t('dashboard:common.view_details')
              })}
        </Typography>
        <Box margin={{ top: 'medium' }}>
          <Card width="medium">
            <CardBody>
              <Box pad={{ bottom: 'small' }}>
                <Text size="large" weight={500}>
                  {t(serviceManagerName)}
                </Text>
                <Text size="xsmall">
                  {serviceManagerSlug === 'OTHER'
                    ? t('dashboard:common.category.OTHER')
                    : t(
                        `dashboard:common.category.${serviceManagerCategories?.at(
                          0
                        )}`
                      )}
                </Text>
              </Box>
              <Text>{t(serviceManagerShortDescription)}</Text>
            </CardBody>
            <CardFooter>
              <Button
                as="a"
                href={`/services/service-catalog/${managerServiceId}`}
                justify="center"
                align="center"
                label={t('dashboard:common.view_details')}
                secondary
                onClick={(e) => {
                  e.preventDefault()
                  setIsLoading(true)
                  setActiveTabIndex(0)
                  navigate(`/services/service-catalog/${managerServiceId}`)
                }}
                data-testid={`${serviceManagerSlug}-service-detail-view-details-manager-button`}
              />
            </CardFooter>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

ServiceManagerTab.propTypes = {
  serviceDetailData: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setActiveTabIndex: PropTypes.func.isRequired,
  navigate: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default ServiceManagerTab
