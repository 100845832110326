// (C) Copyright 2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Box } from 'grommet'
import { DocumentCsv, StatusGood } from 'grommet-icons'
import dayjs from 'dayjs'
/* eslint-disable import/no-unresolved */
import { useReactOidc } from '@axa-fr/react-oidc-context'
/* eslint-enable */

import {
  ButtonGroup,
  Loader,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  Typography
} from '../../components'
import { get } from '../../utils/api-utils'
import { displayApiError } from '../../utils/error-handling-utils'

const GeneratingReportsModal = ({ reportsData, onClose }) => {
  const { t } = useTranslation(['common'])
  const { oidcUser } = useReactOidc()
  const [polledReportData, setPolledReportData] = useState(reportsData)
  const [showErrorNotification, setShowErrorNotification] = useState(null)

  const getReportLabel = (type) => {
    switch (type) {
      case 'DEVICE_INVENTORY':
        return t('export_modal.device_inventory_report')
      case 'DEVICE_SUBSCRIPTION':
        return t('export_modal.device_subscription_report')
      case 'CUSTOMER_ACCOUNT':
        return t('export_modal.customer_account_report')
      default:
        return '--'
    }
  }

  // BE poll to fetch In Progress generate reports
  useEffect(() => {
    let refreshTimer = null
    const polling = () => {
      if (polledReportData?.length) {
        get('/ui-doorway/ui/v1/csv-reports', {}, oidcUser.access_token).then(
          (response) => {
            setPolledReportData(response?.data)
          },
          (error) => {
            setShowErrorNotification(displayApiError(error, t))
          }
        )
        refreshTimer = setTimeout(() => {
          polling()
        }, 5000)
      }
    }
    polling()
    return () => {
      clearTimeout(refreshTimer)
    }
  }, [polledReportData?.length, oidcUser.access_token, t])

  const getGenerateReportStatusIcon = (id) => {
    const reportDetails = polledReportData?.find(
      (val) => val.task_tracking_id === id
    )
    if (reportDetails) return <Loader testId="generating-reports-loader" />
    return <StatusGood color="status-ok" />
  }

  return (
    <ModalDialog
      header={
        <ModalHeader
          title={
            <Typography
              testId="geberating-reports-title"
              type="heading"
              level="1"
            >
              {t('export_modal.generating_reports')}
            </Typography>
          }
        />
      }
      content={
        <Box
          gap="medium"
          margin={{ top: 'xsmall' }}
          width={{ max: 'large', min: 'medium' }}
        >
          <Typography type="text" size="large">
            {t('export_modal.generating_reports_modal_desc')}
          </Typography>
          <Box>
            {reportsData?.map((report) => (
              <Box
                pad="small"
                direction="row"
                align="center"
                justify="between"
                key={report.task_tracking_id}
              >
                <Box direction="row" align="center" gap="small">
                  <DocumentCsv size="medium" />
                  <Box>
                    <Typography type="text" weight="bold" size="large">
                      {getReportLabel(report?.type)}
                    </Typography>
                    <Typography type="text">
                      <Trans
                        i18nKey="export_modal.generating_report_info"
                        t={t}
                        values={{
                          email: report?.data?.username || '--',
                          time: report?.data?.datetime
                            ? dayjs
                                .unix(report?.data?.datetime)
                                .format('MM/DD h:mm A')
                            : '--'
                        }}
                        components={{ b: <strong /> }}
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box flex={{ shrink: 0 }}>
                  {getGenerateReportStatusIcon(report.task_tracking_id)}
                </Box>
              </Box>
            ))}
          </Box>
          {showErrorNotification}
        </Box>
      }
      footer={
        <ModalFooter
          right={
            <ButtonGroup
              buttonList={[
                {
                  id: 1,
                  label: t('close'),
                  primary: true,
                  testId: 'close-btn',
                  onClick: onClose
                }
              ]}
              testId="generating-report-modal-buttons"
            />
          }
        />
      }
      onClose={onClose}
      testId="generating-report-modal-dialog"
      width="large"
    />
  )
}

GeneratingReportsModal.propTypes = {
  reportsData: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
}

export default GeneratingReportsModal
